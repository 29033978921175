import React, { FC } from 'react'
import backButtonImage from 'images/icons/voltar.svg'
import { useHistory } from 'react-router-dom'

const BackButton: FC = () => {
    const history = useHistory()

    const handleGoBack = () => {
        // window.history.back()
        // eslint-disable-next-line react-hooks/rules-of-hooks
        history.push('/home')
    }

    return (
        <button onClick={handleGoBack}>
            <img src={backButtonImage} alt='Voltar' />
        </button>
    )
}

export default BackButton
