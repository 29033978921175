import * as React from 'react'
import * as C from 'libs/components'
import { NumberLabel } from 'libs/components/label/NumberLabel'
import { DistribuicaoFinanceiraDto } from 'backend'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { Text } from 'libs/components'

interface Props {
    dados: PromiseRecord<DistribuicaoFinanceiraDto>
    title: string
    situacao: string
}

export class ProporcaoFinanceiroCard extends React.Component<Props> {
    render() {
        const dados = this.props.dados
        const data = dados && dados.data
        const rows = data.rows.filter(row => row.compradoHospital === this.props.situacao)
        const total = data && data.totalProdutos
        const totalLocal = data.totalByTipo[this.props.situacao]
        const percent = total > 0 ? totalLocal / total : 0
        return (
            <C.Card title={this.props.title}>
                <C.LoadingStateContainer readyState={dados.readyState}>
                    {data && (
                        <C.Columns className='is-small spacing-double align-center'>
                            <C.Column size='4'>
                                <C.CircleProgress type='primary' size={110} progress={{ 'is-report-color-1': percent }}>
                                    <div>
                                        <NumberLabel className='is-xx-large ' value={totalLocal} />
                                    </div>
                                    <div style={{ marginTop: '-15px' }}>
                                        <NumberLabel
                                            className='is-small-ter is-grey-darker'
                                            placeholder={'- %'}
                                            value={percent * 100}
                                            sufix='%'
                                            maxDecimalPlaces={0}
                                        />
                                    </div>

                                    {/* <C.ComposedLabel title={totalLocal + ''} labelClassName=''>
                                        <NumberLabel className='is-small-bis' value={percent * 100} sufix='%' maxDecimalPlaces={0} />
                                    </C.ComposedLabel> */}
                                </C.CircleProgress>
                            </C.Column>
                            <C.Column offset='1' size='7'>
                                <div className='is-semibold is-black-ter'>
                                    {rows.map((value, index) => (
                                        <p key={index}>
                                            <NumberLabel
                                                className='is-small is-black-bis is-bold'
                                                value={value.qtdProdutos}
                                            />{' '}
                                            <NumberLabel
                                                className='is-small is-grey-darker is-regular'
                                                placeholder={'- %'}
                                                value={value.qtdProdutos > 0 && (value.qtdProdutos / totalLocal) * 100}
                                                sufix='%'
                                                maxDecimalPlaces={0}
                                            />{' '}
                                            <Text weight='regular' color='grey-darker' size='small-ter'>
                                                {value.motivoName}{' '}
                                            </Text>
                                        </p>
                                    ))}
                                </div>
                            </C.Column>
                        </C.Columns>
                    )}
                </C.LoadingStateContainer>
            </C.Card>
        )
    }
}
