import { match } from '..'
import * as Util from '../Util'

const REGEX_MATCH = /([A-Za-záéíóúÁÉÍÓÚçÇâêôÂÊÔõãÕÃäöüÄÖÜàÀ' ])/

export function nomeRule(oriValue: string) {
    const value = oriValue?.trim()
    if (!Util.isEmpty(value) && value.split(' ').length < 2) {
        return 'Informe nome e sobrenome'
    }

    if (checkNomeSobrenome(value)) {
        return 'Nomes com apenas uma letra não são permitidos'
    }
}

const checkNomeSobrenome = (value: string) => {
    return value?.split(' ').some(problemWithSize)
}

const problemWithSize = (name: string, index: number) => {
    if ((index === 0 || !['E', 'e', 'Y', 'y'].includes(name)) && !Util.isEmpty(name)) {
        return name.length < 2
    }
    return false
}

export const nome = [match(REGEX_MATCH), nomeRule]
