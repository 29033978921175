import React from 'react'
import { Label } from 'libs/components'
import { asClientFormat } from 'libs/util/DateUtil'

interface RecebimentoIntegracaoRowHeaderProps {
    date: string
    lenghtInnerRows: number
}

export const RecebimentoIntegracaoRowHeader = (props: RecebimentoIntegracaoRowHeaderProps) => {
    const { date, lenghtInnerRows } = props
    const countText = lenghtInnerRows === 1 ? '1 REGISTRO' : lenghtInnerRows + ' REGISTROS'
    return (
        <div className='recebimento-integracao-row-header'>
            <Label className='recebimento-integracao-row-header-date'>{asClientFormat(date)}</Label>
            <Label className='recebimento-integracao-row-header-amount'>{countText}</Label>
        </div>
    )
}
