import * as React from 'react'
import { StentUtilizadoVisualizacaoDto } from 'backend'
import { ComposedLabel } from 'libs/components'

interface StentsFalhosProps {
    stent: StentUtilizadoVisualizacaoDto
}

export class StentsFalhosCard extends React.Component<StentsFalhosProps> {
    render() {
        const stent = this.props.stent
        const tipoCausa = stent.tipoFalha ? stent.tipoFalha + ' (' + stent.especFalha + ')' : ''
        const dotipo = stent.tipoFalha ? ' do tipo ' : ''

        return (
            <div className='with-border-left'>
                <ComposedLabel horizontal title={stent.nome} placeholder={' (' + stent.tipoStent + ')'} />
                {stent.lesoesCobertas.map((element, index) => (
                    <ComposedLabel key={element.nomeLesao + index} horizontal title={element.nomeLesao}>
                        {' '}
                        {element.segmentos}{' '}
                    </ComposedLabel>
                ))}
                Motivo:<b> {stent.motivoInsucesso}</b> {dotipo} <b> {tipoCausa}</b>
            </div>
        )
    }
}
