import Rastreabilidade from 'api/Rastreabilidade'
import { promiseReducer, PromiseRecord } from 'redux/middleware/PromiseAction'
import {
    RegistroProcedimentoComponenteDto,
    DadosProdutoFormList,
    RastreabilidadeFormList,
    DadosFinanceirosFormList,
    DadosProdutoDto,
    RastreabilidadeForm,
    DadosFinanceirosForm,
    DadosProdutoForm,
} from 'backend'

export const FIND_PROCEDIMENTO = 'rni/rastreabilidade/FIND_PROCEDIMENTO'
export const SAVE_DADOS_PRODUTO_MEDICO = 'rni/rastreabilidade/SAVE_DADOS_PRODUTO_MEDICO'
export const SAVE_DADOS_PRODUTO_ADMINISTRATIVO = 'rni/rastreabilidade/SAVE_DADOS_PRODUTO_ADMINISTRATIVO'
export const SAVE_DADOS_FINANCEIRO_ADMINISTRATIVO = 'rni/rastreabilidade/SAVE_DADOS_FINANCEIRO_ADMINISTRATIVO'
export const CLEAR_RASTREABILIDADE = 'rni/rastreabilidade/CLEAR_RASTREABILIDADE'
export const CLEAR_FABRICANTES = 'rni/rastreabilidade/CLEAR_FABRICANTES'
export const SET_ONLY_FINANCEIRO = 'rni/rastreabilidade/SET_ONLY_FINANCEIRO'
export const LOAD_DADOS_PRODUTO = 'rni/rastreabilidade/LOAD_DADOS_PRODUTO'
export const LOAD_NOME_MODELO_PRODUTO = 'rni/rastreabilidade/LOAD_NOME_MODELO_PRODUTO'
export const LOAD_FABRICANTES = 'rni/rastreabilidade/LOAD_FABRICANTES'
export const VALIDATE_DADOS_PRODUTO_ADMINISTRATIVO = 'rni/rastreabilidade/VALIDATE_DADOS_PRODUTO_ADMINISTRATIVO'
export const VALIDATE_DADOS_FINANCEIRO_ADMINISTRATIVO = 'rni/rastreabilidade/VALIDATE_DADOS_FINANCEIRO_ADMINISTRATIVO'
export const VALIDATE_DADOS_PRODUTO_MEDICO = 'rni/rastreabilidade/VALIDATE_DADOS_PRODUTO_MEDICO'
export const DELETE_IMAGEM_ETIQUETA = 'rni/rastreabilidade/DELETE_IMAGEM_ETIQUETA'

export interface RastreabilidadeRecord
    extends Readonly<{
        findProcedimento: PromiseRecord<RegistroProcedimentoComponenteDto>
        save: {}
        saveDadosProdutoMedico: PromiseRecord<DadosProdutoFormList>
        saveDadosProdutoAdministrativo: PromiseRecord<RastreabilidadeFormList>
        saveDadosFinanceiroAdministrativo: PromiseRecord<DadosFinanceirosFormList>
        onlyFinanceiro: boolean
        loadDadosProduto: PromiseRecord<DadosProdutoDto>
        loadNomeModeloProduto: PromiseRecord<string[]>
        loadFabricantes: PromiseRecord<string[]>
        deleteImagemEtiqueta: PromiseRecord<void>
        validateDadosProdutoAdministrativo: PromiseRecord<RastreabilidadeForm>
        validateDadosFinanceiroAdministrativo: PromiseRecord<DadosFinanceirosForm>
        validateDadosProdutoMedico: PromiseRecord<DadosProdutoForm>
    }> {}

export const RastreabilidadeRecordInitialState: RastreabilidadeRecord = {
    findProcedimento: {},
    save: {},
    saveDadosProdutoMedico: {},
    saveDadosProdutoAdministrativo: {},
    saveDadosFinanceiroAdministrativo: {},
    onlyFinanceiro: false,
    loadDadosProduto: {},
    loadNomeModeloProduto: {},
    loadFabricantes: {},
    deleteImagemEtiqueta: {},
    validateDadosProdutoAdministrativo: {},
    validateDadosFinanceiroAdministrativo: {},
    validateDadosProdutoMedico: {},
}

export default function reducer(state = RastreabilidadeRecordInitialState, action) {
    switch (action.type) {
        case FIND_PROCEDIMENTO:
            return {
                ...state,
                findProcedimento: promiseReducer(state.findProcedimento, action),
            }
        case SAVE_DADOS_PRODUTO_MEDICO:
            return {
                ...state,
                saveDadosProdutoMedico: promiseReducer(state.saveDadosProdutoMedico, action),
            }
        case SAVE_DADOS_PRODUTO_ADMINISTRATIVO:
            return {
                ...state,
                saveDadosProdutoAdministrativo: promiseReducer(state.saveDadosProdutoAdministrativo, action),
            }
        case SAVE_DADOS_FINANCEIRO_ADMINISTRATIVO:
            return {
                ...state,
                saveDadosFinanceiroAdministrativo: promiseReducer(state.saveDadosFinanceiroAdministrativo, action),
            }
        case SET_ONLY_FINANCEIRO:
            return {
                ...state,
                onlyFinanceiro: action.isFinanceiro,
            }
        case LOAD_DADOS_PRODUTO:
            return {
                ...state,
                loadDadosProduto: promiseReducer(state.loadDadosProduto, action),
            }
        case LOAD_NOME_MODELO_PRODUTO:
            return {
                ...state,
                loadNomeModeloProduto: promiseReducer(state.loadNomeModeloProduto, action),
            }
        case LOAD_FABRICANTES:
            return {
                ...state,
                loadFabricantes: promiseReducer(state.loadFabricantes, action),
            }
        case DELETE_IMAGEM_ETIQUETA:
            return {
                ...state,
                deleteImagemEtiqueta: promiseReducer(state.deleteImagemEtiqueta, action),
            }
        case VALIDATE_DADOS_PRODUTO_ADMINISTRATIVO:
            return {
                ...state,
                validateDadosProdutoAdministrativo: promiseReducer(state.validateDadosProdutoAdministrativo, action),
            }
        case VALIDATE_DADOS_FINANCEIRO_ADMINISTRATIVO:
            return {
                ...state,
                validateDadosFinanceiroAdministrativo: promiseReducer(
                    state.validateDadosFinanceiroAdministrativo,
                    action
                ),
            }
        case VALIDATE_DADOS_PRODUTO_MEDICO:
            return {
                ...state,
                validateDadosProdutoMedico: promiseReducer(state.validateDadosProdutoMedico, action),
            }
        case CLEAR_RASTREABILIDADE:
            return RastreabilidadeRecordInitialState
        case CLEAR_FABRICANTES:
            return {
                ...state,
                loadFabricantes: {},
            }
        default:
            return state
    }
}

export const findProcedimento = filtro => ({
    type: FIND_PROCEDIMENTO,
    promise: Rastreabilidade.getComponentesByProcedimento(filtro),
})

export const saveDadosProdutoMedico = form => ({
    type: SAVE_DADOS_PRODUTO_MEDICO,
    promise: Rastreabilidade.saveDadosProdutoMedico(form),
})

export const updateDadosProdutoMedico = form => ({
    type: SAVE_DADOS_PRODUTO_MEDICO,
    promise: Rastreabilidade.updateDadosProdutoMedico(form),
})

export const saveDadosProdutoAdministrativo = form => ({
    type: SAVE_DADOS_PRODUTO_ADMINISTRATIVO,
    promise: Rastreabilidade.saveDadosProdutoAdministrativo(form),
})

export const updateDadosProdutoAdministrativo = form => ({
    type: SAVE_DADOS_PRODUTO_ADMINISTRATIVO,
    promise: Rastreabilidade.updateDadosProdutoAdministrativo(form),
})

export const saveDadosFinanceiroAdministrativo = form => ({
    type: SAVE_DADOS_FINANCEIRO_ADMINISTRATIVO,
    promise: Rastreabilidade.saveDadosFinanceiroAdministrativo(form),
})

export const updateDadosFinanceiroAdministrativo = form => ({
    type: SAVE_DADOS_FINANCEIRO_ADMINISTRATIVO,
    promise: Rastreabilidade.updateDadosFinanceiroAdministrativo(form),
})

export const isOnlyFinanceiro = isFinanceiro => ({
    type: SET_ONLY_FINANCEIRO,
    isFinanceiro,
})

export const loadDadosProduto = filtro => ({
    type: LOAD_DADOS_PRODUTO,
    promise: Rastreabilidade.loadDadosProduto(filtro),
})

export const loadFabricantes = filtro => ({
    type: LOAD_FABRICANTES,
    promise: Rastreabilidade.loadFabricantes(filtro),
})

export const getNomeModeloProduto = filtro => ({
    type: LOAD_NOME_MODELO_PRODUTO,
    promise: Rastreabilidade.getNomeModeloProduto(filtro),
})

export const deleteImagemEtiqueta = filtro => ({
    type: DELETE_IMAGEM_ETIQUETA,
    promise: Rastreabilidade.deleteImagemEtiqueta(filtro),
})

export const validateDadosProdutoAdministrativo = form => ({
    type: VALIDATE_DADOS_PRODUTO_ADMINISTRATIVO,
    promise: Rastreabilidade.validateDadosProdutoAdministrativo(form),
})

export const validateDadosFinanceiroAdministrativo = form => ({
    type: VALIDATE_DADOS_FINANCEIRO_ADMINISTRATIVO,
    promise: Rastreabilidade.validateDadosFinanceiroAdministrativo(form),
})

export const validateDadosProdutoMedico = form => ({
    type: VALIDATE_DADOS_PRODUTO_MEDICO,
    promise: Rastreabilidade.validateDadosProdutoMedico(form),
})

export const clearRastreabilidade = () => ({
    type: CLEAR_RASTREABILIDADE,
})

export const clearFabricantes = () => ({
    type: CLEAR_FABRICANTES,
})
