import * as React from 'react'
import * as C from 'libs/components'

interface SimNaoNAvaliadoRadioFieldProps {
    label?: string
    name: string
    limpar?: boolean
    required?: boolean
    disabled?: boolean
    disabledNaoAvaliado?: boolean
}

export class SimNaoNAvaliadoRadioField extends React.Component<SimNaoNAvaliadoRadioFieldProps> {
    static defaultProps: SimNaoNAvaliadoRadioFieldProps = {
        label: '',
        name: '',
        required: false,
        disabled: false,
        limpar: true,
        disabledNaoAvaliado: false,
    }

    render() {
        return (
            <C.ControlField label={this.props.label} name={this.props.name} required={this.props.required}>
                <C.StyledRadioField {...this.props} value='SIM' size='small' className='styled-multibuttonpanel-button'>
                    <label>Sim</label>
                </C.StyledRadioField>
                <C.StyledRadioField {...this.props} value='NAO' size='small' className='styled-multibuttonpanel-button'>
                    <label>Não</label>
                </C.StyledRadioField>
                {!this.props.disabledNaoAvaliado && (
                    <C.StyledRadioField
                        {...this.props}
                        value='NAO_AVALIADO'
                        size='small'
                        className='styled-multibuttonpanel-button'
                    >
                        <label>Não avaliado</label>
                    </C.StyledRadioField>
                )}
            </C.ControlField>
        )
    }
}
