import React from 'react'
import { ControlField, SimpleSelect } from 'libs/components'

export class ComponentePrincipal extends React.Component {
    render() {
        return (
            <ControlField
                label={this.props.label}
                name={this.props.name}
                required={this.props.required}
                disabled={this.props.disabled}
            >
                <SimpleSelect
                    isDisabled={this.props.disabled}
                    onChange={(value, action) => this.props.onChangeComponente(value, action)}
                    url='/api/procedimentos/componentes'
                    value={this.props.value}
                    customizeParams={() => {
                        return {
                            tipoProcedimento: this.props.tipoProcedimento,
                            tipoArtroplastia: this.props.tipoArtroplastia,
                            complementar: false,
                        }
                    }}
                />
            </ControlField>
        )
    }
}
