import Procedimento from 'api/Procedimento'
import { CadastroPacienteForm, PacienteSeletor, RascunhoProcedimentoForm } from 'backend'
import { AlertModalSuccess, PageTitle } from 'libs/components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { destroy, SubmissionError } from 'redux-form'
import Perfil from 'redux/model/Perfil'
import { State } from 'redux/modules'
import * as alert from 'redux/modules/alert'
import { setEdicaoProcedimento } from 'redux/modules/edicaoProcedimento'
import {
    clear as novoProcedimentoClear,
    saveRascunhoSuccess as novoProcSaveRascunhoSuccess,
    selecionarPaciente as novoProcedimentoSelecionarPaciente,
    selecionarProcedimento as novoProcedimentoSelecionarProcedimento,
} from 'redux/modules/novoProcedimento'
import { actionCreators as pacienteActionCreators } from 'redux/modules/paciente'
import { usePesquisaSatisfacaoPopup } from 'view/componentes/pesquisaSatisfacao/usePesquisaSatisfacaoPopup'
import CadastroPacienteContainer from 'view/home/paciente/CadastroPacienteContainer'
import { ModalPreencherFichaEdicao } from '../ficha/componentes/ModalPreencherFichaEdicao'
import { DefinirPacienteForm, definirPacienteFormSelector } from './components/DefinirPacienteForm'

export interface DefinirPacienteViewProps {
    next(): void
}

export const DefinirPacienteView = ({ next }: DefinirPacienteViewProps) => {
    const dispatch = useDispatch()
    const { push, location } = useHistory()
    const match = useRouteMatch()
    const { showPesquisa } = usePesquisaSatisfacaoPopup()
    const state = useSelector<State, State>(state => state)
    const [modal, setModal] = useState<boolean>(false)
    const [idFicha, setIdFicha] = useState<number>(state.edicao?.idProcedimento)

    useEffect(() => {
        dispatch(destroy('stentCoronaria'))
    }, [dispatch])

    const cpf = definirPacienteFormSelector(state, 'cpf')
    const cns = definirPacienteFormSelector(state, 'cns')
    const tipoFiltro = definirPacienteFormSelector(state, 'tipoFiltro')
    const perfilDoCadastro = new Perfil(state.auth.user?.perfil)
    const perfilDoProcedimento = new Perfil(state.novoProcedimento.vinculoProfissionalMedicoResponsavel?.perfil)
    const isEndOfRascunho: boolean = perfilDoProcedimento.isCardiologista() && perfilDoCadastro.isTecnico()
    const cpfPreenchido = state.paciente.form?.cpf
    const paciente = state.paciente.form
    const error = state.paciente.error
    const saveRascunhoSuccess = state.novoProcedimento.saveRascunhoSuccess
    const vinculoProfissional = state.novoProcedimento.vinculoProfissionalMedicoResponsavel
    const equipeMedica = state.novoProcedimento.equipeMedica
    const isEditMode: boolean = !!state.edicao

    const showAlertError = () => void dispatch(alert.error({ mensagem: 'Falha ao salvar rascunho' }))
    const guardarPaciente = (pacienteCad: CadastroPacienteForm) =>
        void dispatch(pacienteActionCreators.editPaciente(pacienteCad))

    const onCancel = () => {
        push('/home')
        dispatch(novoProcedimentoClear())
    }

    const onCloseModalSuccess = () => {
        dispatch(novoProcedimentoClear())
        push('/home/aguardandoDadosClinicos')
        isEndOfRascunho && showPesquisa()
    }

    const selecionarPaciente = (paciente: CadastroPacienteForm) => {
        dispatch(novoProcedimentoSelecionarPaciente(paciente))

        if (perfilDoProcedimento.isCardiologista()) {
            if (perfilDoCadastro.isCardiologista()) {
                dispatch(novoProcedimentoSelecionarProcedimento('stentCoronaria', ''))
            }

            const rascunhoForm: RascunhoProcedimentoForm = {
                pacienteId: paciente.id,
                vinculoProfissionalResponsavelId: vinculoProfissional?.id,
                idsVinculosProfsEquipeMedica: equipeMedica.map(vinculoProfissional => {
                    return vinculoProfissional.id
                }),
                tipoProcedimento: 'STENT_CORONARIA',
            }

            if (isEditMode) {
                rascunhoForm.id = state.edicao.idProcedimento
                return Procedimento.updateRascunho(rascunhoForm)
            }

            if (perfilDoCadastro.isTecnico()) {
                return Procedimento.saveRascunho(rascunhoForm)
            }
        }

        next()
        dispatch(pacienteActionCreators.clearFindResult(''))
        return null
    }

    const handlePromiseSelecionarPacienteSuccess = result => {
        if (perfilDoCadastro.isTecnico()) {
            dispatch(novoProcSaveRascunhoSuccess())
        } else if (perfilDoCadastro.isCardiologista() && isEditMode) {
            setIdFicha(result.data.id)
            setModal(true)
        }
    }

    const find = values => {
        const filtro: PacienteSeletor = {}
        let buscar
        if (values.get('tipoFiltro') === 'CPF') {
            filtro.cpf = values.get('cpf')
            buscar = filtro.cpf && filtro.cpf.length === 11
        } else if (values.get('tipoFiltro') === 'CNS') {
            filtro.cns = values.get('cns')
            buscar = filtro.cns && filtro.cns.length === 15
        }

        if (buscar) {
            return dispatch(pacienteActionCreators.find(filtro) as any)
                .then(() => {
                    if (isEditMode) {
                        dispatch(setEdicaoProcedimento(null, null))
                    }
                })
                .catch(error => {
                    throw new SubmissionError(error)
                })
        }
    }
    const ok = () => {
        push(`/home/novoProcedimento/${idFicha}/stentCoronaria`)
    }

    if (location.pathname === `${match.url}/cadastrarPaciente`) {
        return <Route path={`${match.url}/cadastrarPaciente`} component={CadastroPacienteContainer} />
    }
    return (
        <>
            <ModalPreencherFichaEdicao
                active={modal}
                onClose={onCancel}
                onOk={ok}
                mensagem={'Deseja preencher os dados clínicos do procedimento?'}
            />
            <div className='consulta-paciente'>
                <PageTitle title='DEFINIR PACIENTE' subtitle='Busque pelo CPF ou CNS do paciente.' />
                <hr />
                <DefinirPacienteForm
                    cpf={cpf}
                    cns={cns}
                    erro={error}
                    tipoFiltro={!!tipoFiltro ? tipoFiltro : 'CPF'}
                    paciente={paciente}
                    initialValues={{ tipoFiltro: 'CPF', cpf: cpfPreenchido }}
                    guardarPaciente={guardarPaciente}
                    selecionarPaciente={selecionarPaciente}
                    handleRascunhoSuccess={handlePromiseSelecionarPacienteSuccess}
                    showAlertError={showAlertError}
                    cancelar={onCancel}
                    onSubmit={find}
                />
                <AlertModalSuccess
                    onClose={onCloseModalSuccess}
                    active={saveRascunhoSuccess}
                    title={isEditMode ? 'Edição realizada com sucesso!' : 'Cadastro realizado com sucesso!'}
                >
                    Os dados foram salvos corretamente.
                </AlertModalSuccess>
            </div>
        </>
    )
}
