import * as React from 'react'
import * as C from 'libs/components'

export const normalize = value => {
    if (typeof value === 'string') {
        return value.toLowerCase()
    } else {
        return value
    }
}

export interface EmailFieldProps {
    name: string
}

export class EmailField extends React.Component<EmailFieldProps> {
    render() {
        return <C.TextField {...this.props} normalize={normalize} />
    }
}
