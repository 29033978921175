import * as React from 'react'
import * as C from 'libs/components'

interface AbordagemCirurgicaRevisaoQuadrilProps {
    label: string
    name: string
    required?: boolean
}

export class AbordagemCirurgicaRevisaoQuadrilField extends React.Component<AbordagemCirurgicaRevisaoQuadrilProps> {
    render() {
        return (
            <C.ControlField label={this.props.label} name={this.props.name} required={this.props.required} verticalFlow>
                <C.ClearableRadioField name={this.props.name} label='Anterior' value='ANTERIOR' />
                <C.ClearableRadioField name={this.props.name} label='Posterior' value='POSTERIOR' />
                <C.ClearableRadioField name={this.props.name} label='Outra' value='OUTRA' />
            </C.ControlField>
        )
    }
}
