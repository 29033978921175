
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"6 min read","minutes":5.205,"time":312300,"words":1041}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As entidades que forem aprovadas para realizar o consumo de dados no RNI, deverão ser cadastradas no sistema pelo profissional Anvisa.`}</p>
    <p>{`Para realizar o cadastro de uma nova entidade, o profissional da Anvisa deverá seguir os passos:`}</p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique no menu lateral direito.`}</li>
      <li parentName="ol">{`Selecione a opção “Entidades para consumo de dados”.`}</li>
      <li parentName="ol">{`Clique na aba “Gerenciar entidades”.`}</li>
      <li parentName="ol">{`Clique no botão “Adicionar entidade”.`}</li>
    </ol>
    <p><strong parentName="p">{`INFORMAÇÕES DA ENTIDADE`}</strong></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Informe o nome e e-mail da entidade e, se possuir, o CNPJ da entidade.`}</li>
    </ol>
    <p><strong parentName="p">{`PERMISSÕES DE COMPARTILHAMENTO`}</strong></p>
    <p>{`Nesta parte do formulário, serão preenchidas as informações do RNI na qual a entidade poderá acessar ao realizar o consumo de dados.`}</p>
    <p><strong parentName="p">{`Esfera de acesso`}</strong></p>
    <p>{`Nesta seção, deverá ser informado qual esfera a entidade terá acesso.`}</p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Selecione a esfera de acesso.`}</li>
      <li parentName="ol">{`Caso tenha selecionado esfera `}<strong parentName="li">{`Unidade federativa`}</strong>{`, selecione todos os estados que a entidade terá acesso.`}</li>
      <li parentName="ol">{`Caso tenha selecionado a esfera `}<strong parentName="li">{`Municipal`}</strong>{`, selecione o município que a entidade terá acesso, informando a UF correspondente e clique em “Adicionar”. Repita o processo para todos os municípios desejados.`}</li>
      <li parentName="ol">{`Caso tenha selecionado a esfera `}<strong parentName="li">{`Hospital`}</strong>{`, selecione o hospital que a entidade terá acesso, informando a UF e município correspondentes e clique em “Adicionar”. Repita o processo para todos os hospitais desejados.`}</li>
    </ol>
    <p><strong parentName="p">{`Hospitais`}</strong></p>
    <p>{`Nesta seção, deverá ser informado se a entidade terá acesso às informações dos hospitais na qual os procedimentos ocorreram. Caso a entidade não vá ter acesso a estas informações, você pode pular esta seção sem preenche-la.`}</p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Caso a entidade vá possuir acesso às informações institucionais do(s) hospital(is), selecione as informações desejadas na seção “Institucional”.`}</li>
      <li parentName="ol">{`Caso a entidade vá possuir acesso à informações de endereço do(s) hospital(is), selecione as informações desejadas na seção “Endereço”.`}</li>
    </ol>
    <p><strong parentName="p">{`Dados dos pacientes`}</strong></p>
    <p>{`Nesta seção, deverá ser informado se a entidade terá acesso às informações dos pacientes na qual os procedimentos ocorreram. Caso a entidade não vá ter acesso a estas informações, você pode pular esta seção sem preenche-la.`}</p>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol">{`Caso a entidade vá possuir acesso às informações pessoais do(s) paciente(s), selecione as informações desejadas na seção “Pessoal”.`}</li>
      <li parentName="ol">{`Caso a entidade vá possuir acesso às informações de endereço residencial do(s) paciente(s), selecione as informações desejadas na seção “Endereço residencial”.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` As informações `}<strong parentName="p">{`Nome completo`}</strong>{`, `}<strong parentName="p">{`CNS`}</strong>{` e `}<strong parentName="p">{`CPF`}</strong>{` são informações consideradas sensíveis segundo a Lei Geral de Proteção de Dados (Lei nº 13.709/2018). Por este motivo, deve-se manter maior cuidado ao seleciona-las.`}</p>
    </blockquote>
    <p><strong parentName="p">{`Dados dos médicos`}</strong></p>
    <p>{`Nesta seção, deverá ser informado se a entidade terá acesso às informações dos médicos que realizaram os procedimentos. Caso a entidade não vá ter acesso a estas informações, você pode pular esta seção sem preenche-la.`}</p>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol">{`Caso a entidade vá possuir acesso às informações pessoais do(s) médico(s), selecione as informações desejadas na seção “Pessoal”.`}</li>
      <li parentName="ol">{`Caso a entidade vá possuir acesso às informações de endereço residencial do(s) médico(s), selecione as informações desejadas na seção “Endereço residencial”.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` As informações `}<strong parentName="p">{`Nome completo`}</strong>{`, `}<strong parentName="p">{`CRM`}</strong>{` e `}<strong parentName="p">{`CPF`}</strong>{` são informações consideradas sensíveis segundo a Lei Geral de Proteção de Dados (Lei nº 13.709/2018). Por este motivo, deve-se manter maior cuidado ao seleciona-las.`}</p>
    </blockquote>
    <p><strong parentName="p">{`Angioplastia`}</strong></p>
    <p>{`Nesta seção, deverá ser informado se a entidade terá acesso às informações dos procedimentos de `}<strong parentName="p">{`Angioplastia`}</strong>{`. Caso a entidade não vá ter acesso a estas informações, você pode pular esta seção sem preenche-la.`}</p>
    <ol {...{
      "start": 17
    }}>
      <li parentName="ol">{`Caso a entidade vá ter acesso às informações de angioplastia, selecione as informações desejadas.`}</li>
    </ol>
    <p><strong parentName="p">{`Artroplastia de Joelho`}</strong></p>
    <p>{`Nesta seção, deverá ser informado se a entidade terá acesso às informações dos procedimentos de `}<strong parentName="p">{`Artroplastia de joelho`}</strong>{`. Caso a entidade não vá ter acesso a estas informações, você pode pular esta seção sem preenche-la.`}</p>
    <ol {...{
      "start": 18
    }}>
      <li parentName="ol">{`Caso a entidade vá ter acesso às informações de procedimentos primários de joelho, selecione as informações desejadas na seção `}<strong parentName="li">{`Artroplastia primária de joelho`}</strong>{`.`}</li>
      <li parentName="ol">{`Caso a entidade vá ter acesso às informações de procedimentos de revisão de joelho, selecione as informações desejadas na seção `}<strong parentName="li">{`Artroplastia de revisão de joelho`}</strong>{`.`}</li>
    </ol>
    <p><strong parentName="p">{`Artroplastia de Quadril`}</strong></p>
    <p>{`Nesta seção, deverá ser informado se a entidade terá acesso às informações dos procedimentos de `}<strong parentName="p">{`Artroplastia de quadril`}</strong>{`. Caso a entidade não vá ter acesso a estas informações, você pode pular esta seção sem preenche-la.`}</p>
    <ol {...{
      "start": 18
    }}>
      <li parentName="ol">{`Caso a entidade vá ter acesso às informações de procedimentos primários de quadril, selecione as informações desejadas na seção `}<strong parentName="li">{`Artroplastia primária de quadril`}</strong>{`.`}</li>
      <li parentName="ol">{`Caso a entidade vá ter acesso às informações de procedimentos de revisão de quadril, selecione as informações desejadas na seção `}<strong parentName="li">{`Artroplastia de revisão de quadril`}</strong>{`.`}</li>
    </ol>
    <p><strong parentName="p">{`Dados de produto`}</strong></p>
    <p>{`Nesta seção, deverá ser informado se a entidade terá acesso às informações dos produtos implantados nos procedimentos realizados. Caso a entidade não vá ter acesso a estas informações, você pode pular esta seção sem preenche-la.`}</p>
    <ol {...{
      "start": 20
    }}>
      <li parentName="ol">{`Caso a entidade vá ter acesso às informações de identificação do componente, selecione as informações desejadas na seção `}<strong parentName="li">{`Identificação do componente`}</strong>{`.`}</li>
      <li parentName="ol">{`Caso a entidade vá ter acesso às informações de identificação Anvisa, selecione as informações desejadas na seção `}<strong parentName="li">{`Identificação Anvisa`}</strong>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` As informações `}<strong parentName="p">{`Identificador do dispositivo`}</strong>{`, `}<strong parentName="p">{`Código de referência`}</strong>{`, `}<strong parentName="p">{`Número de série`}</strong>{`, `}<strong parentName="p">{`Número do Registro Anvisa`}</strong>{`, `}<strong parentName="p">{`Nome do produto`}</strong>{`, `}<strong parentName="p">{`CNPJ do Detentor do registro`}</strong>{` e `}<strong parentName="p">{`Razão social do Detentor do registro`}</strong>{` são informações consideradas sensíveis segundo a Lei Geral de Proteção de Dados (Lei nº 13.709/2018). Por este motivo, deve-se manter maior cuidado ao seleciona-las.`}</p>
    </blockquote>
    <p><strong parentName="p">{`Dados financeiros`}</strong></p>
    <p>{`Nesta seção, deverá ser informado se a entidade terá acesso às informações financeiras dos produtos implantados, nos procedimentos realizados. Caso a entidade não vá ter acesso a estas informações, você pode pular esta seção sem preenche-la.`}</p>
    <ol {...{
      "start": 22
    }}>
      <li parentName="ol">{`Caso a entidade vá ter acesso às informações de dados financeiros, selecione as informações desejadas.`}</li>
    </ol>
    <p><strong parentName="p">{`Período de vigência para o consumo de dados`}</strong></p>
    <p>{`Nesta seção deverá ser informado o período em que a entidade poderá realizar o consumo de dados do RNI.`}</p>
    <ol {...{
      "start": 23
    }}>
      <li parentName="ol">
        <p parentName="li">{`Selecione o mês e o ano em que o período de consumo se inicia.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Selecione o mês e o ano em que o período de consumo termina. Caso o consumo não possua uma data final determinada, você deve selecionar o campo “Data final indeterminada”.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Após finalizar o preenchimento de todas as informações, clique no botão “Adicionar entidade”.`}</p>
      </li>
    </ol>
    <p>{`Ao finalizar o cadastro da entidade, a mesma será apresentada na aba de entidades `}<strong parentName="p">{`Ativas`}</strong>{`, na qual você poderá visualizar, editar e excluir o referente cadastro.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

