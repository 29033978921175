import { EspecialidadePerfil, EspecialidadesMedicas } from 'api/Perfis'
import { VinculoProfissionalRow } from 'backend'
import classnames from 'classnames'
import { List } from 'immutable'
import { Button, Column, Columns, Form, LetterField, NumberField, TabField, TabPane } from 'libs/components'
import { isEqual } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { FieldArrayFieldsProps, InjectedFormProps } from 'redux-form'
import { getFormValues, reduxForm } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { ResultadoBuscaMedico } from './ResultadoBuscaMedico'

type TipoFiltro = 'NOME' | 'NUMEROREGISTRO'

export const definirMedicoFormName = 'definirMedico'

interface DefinirMedicoFormBodyProps {
    equipeMedicaFields: FieldArrayFieldsProps<any>
    equipeMedica: List<VinculoProfissionalRow>
    setEspecialidadeEquipeMedica: (especialidade: EspecialidadesMedicas) => void
    especialidadeEquipeMedica: EspecialidadesMedicas
}

type Props = DefinirMedicoFormBodyProps & InjectedFormProps<any, DefinirMedicoFormBodyProps>

const DefinirMedicoFormBody: React.FC<Props> = props => {
    const {
        handleSubmit,
        pristine,
        submitting,
        equipeMedicaFields,
        equipeMedica,
        setEspecialidadeEquipeMedica,
        especialidadeEquipeMedica,
    } = props

    const formValues = useSelector<State, any>(state => getFormValues(definirMedicoFormName)(state))

    const tipoFiltro: TipoFiltro = formValues?.get('tipoFiltro')
    const formValueNome: string = formValues?.get('nome')
    const formValueNumeroRegistro: string = formValues?.get('numeroRegistro')

    const nomeMinLenght: number = 2
    const nomeMaxLenght: number = 70
    const numeroRegistroMaxLenght: number = 10

    const shouldSearchNome: boolean =
        tipoFiltro === 'NOME' && formValueNome?.length > nomeMinLenght && formValueNome?.length <= nomeMaxLenght
    const shouldSearchNumeroRegistro: boolean =
        tipoFiltro === 'NUMEROREGISTRO' && formValueNumeroRegistro?.length <= numeroRegistroMaxLenght

    const listaMedico = useSelector<State, VinculoProfissionalRow[]>(state => state.profissional.find.data)
    const hasMoreThanOneResult: boolean = listaMedico?.length > 1

    const medicoSelecionado: number = formValues?.get('medico')

    const vinculoProfissionalSelecionado: VinculoProfissionalRow = listaMedico && listaMedico[medicoSelecionado]
    const especialidadePerfilSelecionado: EspecialidadesMedicas =
        EspecialidadePerfil[vinculoProfissionalSelecionado?.perfil]

    const handleClickAdicionarProfissional = () => {
        setEspecialidadeEquipeMedica(especialidadePerfilSelecionado)
        equipeMedicaFields.push(listaMedico[medicoSelecionado])
    }

    const hasEspecialidadePerfilSelecionado: boolean =
        especialidadePerfilSelecionado !== null && especialidadePerfilSelecionado !== undefined
    const hasEspecialidadeEquipeMedica: boolean =
        especialidadeEquipeMedica !== null && especialidadeEquipeMedica !== undefined

    const disableAdicionarProfissional: boolean =
        medicoSelecionado === undefined ||
        medicoSelecionado === null ||
        equipeMedica?.size === 10 ||
        (hasEspecialidadePerfilSelecionado &&
            hasEspecialidadeEquipeMedica &&
            especialidadePerfilSelecionado !== especialidadeEquipeMedica) ||
        (equipeMedica?.find(vinculoDaEquipe => isEqual(vinculoDaEquipe, vinculoProfissionalSelecionado)) ? true : false)

    return (
        <>
            <Form handleSubmit={handleSubmit}>
                <Columns>
                    <Column>
                        <TabField activeKey={tipoFiltro} name='tipoFiltro'>
                            <TabPane key='NOME' title='Nome' disabled={submitting} maxLength={nomeMaxLenght}>
                                <LetterField name='nome' disabled={submitting} />
                            </TabPane>
                            <TabPane key='NUMEROREGISTRO' title='Nº do registro' disabled={submitting}>
                                <NumberField
                                    name='numeroRegistro'
                                    disabled={submitting}
                                    maxLength={numeroRegistroMaxLenght}
                                />
                            </TabPane>
                        </TabField>
                    </Column>
                    <Column pulledBottom>
                        <Button
                            size='normal'
                            type='primary'
                            className='btn-pesquisar'
                            disabled={pristine || submitting || !(shouldSearchNome || shouldSearchNumeroRegistro)}
                            loading={submitting}
                            onClick={handleSubmit}
                        >
                            Pesquisar
                        </Button>
                    </Column>
                </Columns>
            </Form>
            <Columns className='resultado-busca-medico is-gray-background'>
                <Column className={classnames({ 'unset-vertical-align': hasMoreThanOneResult })}>
                    <ResultadoBuscaMedico listaMedico={listaMedico} submitting={submitting} />
                </Column>
            </Columns>
            <div className='button-adicionar-profissional-wrapper'>
                <Button
                    className='button-adicionar-profissional'
                    onClick={handleClickAdicionarProfissional}
                    disabled={disableAdicionarProfissional}
                >
                    Adicionar profissional
                </Button>
            </div>
        </>
    )
}

export const DefinirMedicoForm = reduxForm<any, DefinirMedicoFormBodyProps>({
    form: definirMedicoFormName,
})(DefinirMedicoFormBody)
