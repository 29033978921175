import { tiposVisualizacaoHistoricoUrlByEnum } from 'api/Visualizacao'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import VisualizacaoRevisaoQuadrilWrapper from './VisualizacaoRevisaoQuadril'

function VisualizacaoEdicaoRevisaoQuadrilView(props: RouteComponentProps<any>) {
    const proced = tiposVisualizacaoHistoricoUrlByEnum['REVISAO_QUADRIL']
    const imprimirFicha = (idProcedimento: number) => {
        window.open('/api/procedimentos/impressaoRegistroHistorico/' + idProcedimento, '_blank')
    }
    return (
        <VisualizacaoRevisaoQuadrilWrapper
            id={props.match.params.idEdicao}
            proced={proced}
            idProced={props.match.params.id}
            imprimirFicha={imprimirFicha}
            {...props}
        />
    )
}

export default VisualizacaoEdicaoRevisaoQuadrilView
