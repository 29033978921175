import * as React from 'react'
import classnames from 'classnames'

interface MultiButtonPanelProps {
    label: string
    name: string
    buttonSet: any
    hasContent?: boolean
    input?: {
        value?: any
    }
}

export class MultiButtonPanel extends React.Component<MultiButtonPanelProps> {
    static defaultProps: MultiButtonPanelProps = {
        label: '',
        buttonSet: '',
        hasContent: true,
        input: {},
        name: '',
    }

    render() {
        const classes = classnames('multibuttonpanel', {
            active: this.props.input.value === 'SIM',
        })

        return (
            <div className={classes}>
                <div className='multibuttonpanel-header'>
                    <span className='title'>{this.props.label}</span>
                    <span className='buttons'>{this.props.buttonSet}</span>
                </div>
                {this.props.hasContent && <div className='multibuttonpanel-content'>{this.props.children}</div>}
            </div>
        )
    }
}
