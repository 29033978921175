/* tslint:disable */
import React from 'react'

const SvgHome = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M16.697 7.259v-.96h1.356v2.176L22 12.017l-.564.637-9.484-8.511-9.388 8.435L2 11.942 11.951 3l4.746 4.259zM16.02 21v-7.412h-3.39V21H4.495l.021-9.038 7.458-6.64 7.457 6.64L19.409 21h-3.39zm-8.813-7.412v3.41h3.39v-3.41h-3.39z' />
    </svg>
)

export default SvgHome
