import * as React from 'react'
import { Columns, Column, Card, LoadingContainer, ComposedLabel, NumberLabel } from 'libs/components'
interface Props {
    numerosComponentesSemRegistro: number
    numerosComponentesComRegistro: number
    isFetching?: boolean
}

export default class CardNumeroProdutosProcedimentos extends React.Component<Props> {
    render() {
        const total = this.props.numerosComponentesSemRegistro + this.props.numerosComponentesComRegistro
        return (
            <Card title='Nº DE PRODUTOS'>
                <LoadingContainer isFetching={this.props.isFetching}>
                    <Columns>
                        <Column className='has-border-right has-text-centered' size='4'>
                            <ComposedLabel title='Produtos' labelClassName='is-muted' inverted big>
                                <NumberLabel value={total} />
                            </ComposedLabel>
                        </Column>
                        <Column className='has-text-centered' size='4'>
                            <ComposedLabel title='Com registro ANVISA' labelClassName='is-muted' inverted big>
                                <NumberLabel value={this.props.numerosComponentesComRegistro} />
                            </ComposedLabel>
                        </Column>
                        <Column className='has-text-centered' size='4'>
                            <ComposedLabel title='Sem registro ANVISA' labelClassName='is-muted' inverted big>
                                <NumberLabel value={this.props.numerosComponentesSemRegistro} />
                            </ComposedLabel>
                        </Column>
                    </Columns>
                </LoadingContainer>
            </Card>
        )
    }
}
