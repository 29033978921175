/* tslint:disable */
import React from 'react'

const SvgOrdenadorCrescente = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M7.303 16.493v.007h1.404v-.018L12 12.964l-.993-1.06-2.3 2.457V7H7.303v7.372l-2.31-2.468L4 12.964l3.303 3.53z' />
    </svg>
)

export default SvgOrdenadorCrescente
