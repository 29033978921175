import * as React from 'react'
import { WrappedFieldProps } from 'redux-form'
import * as C from 'libs/components'
import field, { FieldProps } from 'libs/redux-form/field'

export interface CheckboxFieldProps extends C.CheckboxProps, Partial<WrappedFieldProps> {}

class CheckboxFieldCmp extends React.Component<CheckboxFieldProps> {
    render() {
        return (
            <C.Checkbox
                {...this.props.input}
                label={this.props.label}
                disabled={this.props.disabled}
                testid={this.props.testid}
            >
                {this.props.children}
            </C.Checkbox>
        )
    }
}

// normalize resolve a issue: https://github.com/erikras/redux-form/issues/2922
export const CheckboxField: React.ComponentClass<FieldProps & CheckboxFieldProps> = field({
    type: 'checkbox',
    haswrapper: false,
    normalize: v => !!v,
})(CheckboxFieldCmp)
