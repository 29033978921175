import React from 'react'
import { Columns, Column, ControlField, ChecklistItemField } from 'libs/components'

interface BuscaProcedimentoFiltroCirurgiaProps {
    shouldClassificacao: boolean
}

export const BuscaProcedimentoFiltroCirurgia: React.FC<BuscaProcedimentoFiltroCirurgiaProps> = ({
    shouldClassificacao,
}) => {
    return (
        <Columns>
            <Column size='4'>
                <ControlField label='Tipos de cirurgia' name='tipoCirurgia'>
                    <ChecklistItemField name='tipoCirurgia' label='Joelho' optionValue='JOELHO' />
                    <ChecklistItemField name='tipoCirurgia' label='Quadril' optionValue='QUADRIL' />
                    <ChecklistItemField name='tipoCirurgia' label='Cardiologia' optionValue='CORACAO' />
                </ControlField>
            </Column>

            <Column size='4'>
                <ControlField label='Classificação da cirurgia' name='classificacaoCirurgia'>
                    <ChecklistItemField
                        name='classificacaoCirurgia'
                        label='Primária'
                        optionValue='PRIMARIA'
                        disabled={!shouldClassificacao}
                    />
                    <ChecklistItemField
                        name='classificacaoCirurgia'
                        label='Revisão'
                        optionValue='REVISAO'
                        disabled={!shouldClassificacao}
                    />
                </ControlField>
            </Column>
        </Columns>
    )
}
