import { connect } from 'react-redux'
import BuscaProcedimentoRootView from './BuscaProcedimentoRootView'
import { createPaginator } from 'redux/middleware/PaginationAction'

const url = '/api/report/buscaPacientes'

const mapDispatchToProps = dispatch => {
    const paginator = createPaginator(dispatch, url)
    return {
        clear: () => {
            paginator.clear()
        },
    }
}

const BuscaProcedimentoRootContainer = connect(null, mapDispatchToProps)(BuscaProcedimentoRootView as any)

export default BuscaProcedimentoRootContainer
