import { createValidator, required } from 'libs/validation'

export const MotivoRevisaoValidator = createValidator(
    {
        motivoRevisao: required,
    },
    formValidator
)

function formValidator(form) {
    const errors = {}
    const motivo = form.get('motivoRevisao')
    if (
        motivo &&
        (motivo.includes('INFECCAO_QUADRIL') || motivo.includes('INFECCAO_JOELHO')) &&
        !form.get('tipoInfeccao')
    ) {
        errors.motivoRevisao = 'Preencha a subopção'
    }
    return errors
}
