import { Button, NumberField } from 'libs/components'
import React from 'react'
import { reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import Axios from 'axios'

const formName = 'adicionarProfissionaisForm'

const AdicionarProfissionaisCmp: React.FC<InjectedFormProps> = ({ handleSubmit }) => {
    return (
        <>
            <h1>Adicionar profissionais</h1>
            <p>Insira o número de profissionais que devem ser adicionados na base de dados.</p>
            <p>
                Além dos profissionais serão adicionadas usuários, respostas de relação com empresas e também as
                relações com empresas.
            </p>
            <p>OBS¹: todas as informações adicionadas serão aleatórias</p>
            <p>OBS²: é necessário estar logado para a adição funcionar</p>
            <form>
                <NumberField name='nroProfissionais' />
                <Button onClick={handleSubmit}>Adicionar</Button>
            </form>
        </>
    )
}

const AdicionarProfissionaisWrapperForm = reduxForm({ form: formName })(AdicionarProfissionaisCmp)

const AdicionarProfissionaisForm: React.FC = () => {
    return <AdicionarProfissionaisWrapperForm onSubmit={form => Axios.post('/api/debug/profissional', form)} />
}

export default AdicionarProfissionaisForm
