import iconPaciente from 'images/home/cidadao/ic-paciente.svg'
import * as C from 'libs/components'
import * as DateUtil from 'libs/util/DateUtil'
import * as React from 'react'

interface CardPacienteProps {
    paciente: {
        nome: string
        sexo: string
        dataNascimento: string
    }
    procedimento?: string
    lado?: string
}

export class CardPaciente extends React.Component<CardPacienteProps, any> {
    render() {
        let idade: string = DateUtil.getIdadePorExtenso(this.props.paciente?.dataNascimento)

        let procedimentoName: string
        if (this.props.procedimento === 'primariaQuadril') {
            procedimentoName = 'PRIMÁRIA DE QUADRIL'
        } else if (this.props.procedimento === 'revisaoQuadril') {
            procedimentoName = 'REVISÃO DE QUADRIL'
        } else if (this.props.procedimento === 'primariaJoelho') {
            procedimentoName = 'PRIMÁRIA DE JOELHO'
        } else if (this.props.procedimento === 'revisaoJoelho') {
            procedimentoName = 'REVISÃO DE JOELHO'
        } else if (
            this.props.procedimento === 'stentCoronaria' ||
            this.props.procedimento === 'stentCoronariaComplementar'
        ) {
            procedimentoName = 'STENT DE CORONÁRIA'
        }
        procedimentoName = procedimentoName + ' ' + (this.props.lado ? this.props.lado : '')
        let procedimento: any = this.props.procedimento && (
            <p className='procedimento'>
                <b>{procedimentoName}</b>
            </p>
        )

        return (
            <div className='upper-card-paciente'>
                <C.Image src={iconPaciente} size='64x64' />
                <div className='card-infos'>
                    <p className='nome'>
                        <span className='is-semibold'>{this.props.paciente?.nome}</span>
                    </p>
                    <p className='other'>
                        <span className='is-semibold'>Idade:</span> {idade}{' '}
                        <span className='is-semibold'>&nbsp;&nbsp;Sexo:</span> {this.props.paciente?.sexo.toLowerCase()}
                    </p>
                    {procedimento}
                </div>
            </div>
        )
    }
}
