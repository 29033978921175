/* tslint:disable */
import React from 'react'

const SvgReportRelacaoEmpresas = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
        <path d='M25.6 9.4h-4.8V7c0-1.332-1.068-2.4-2.4-2.4h-4.8A2.392 2.392 0 0011.2 7v2.4H6.4a2.382 2.382 0 00-2.388 2.4L4 25c0 1.332 1.068 2.4 2.4 2.4h19.2c1.332 0 2.4-1.068 2.4-2.4V11.8c0-1.332-1.068-2.4-2.4-2.4zm-7.2 0h-4.8V7h4.8v2.4z' />
    </svg>
)

export default SvgReportRelacaoEmpresas
