import { createValidator, maxLength, required, cnpj } from 'libs/validation'
import moment from 'moment'
import { isImmutable } from 'immutable'

const checkDateRequired = (form, errors) => {
    const anoValidade = form.get('anoValidade')
    const mesValidade = form.get('mesValidade')

    if (!anoValidade && !mesValidade) {
        errors.anoValidade = required(anoValidade)
        errors.validade = 'Esse campo é de preenchimento obrigatório'
    }

    if (anoValidade && !mesValidade) {
        errors.mesValidade = required(mesValidade)
        errors.validade = 'Esse campo é de preenchimento obrigatório'
    }
}

const checkDateRelationship = (form, errors) => {
    const localForm = isImmutable(form) ? form.toJS() : form
    const formDtFabricacao = localForm.dataFabricacao
    const anoFabricacao = formDtFabricacao?.ano
    const anoValidade = localForm.anoValidade

    if (anoFabricacao && anoValidade) {
        let momentFabricacao
        let momentValidade

        const diaFabricacao = formDtFabricacao?.dia
        const diaValidade = localForm.diaValidade
        const mesFabricacao = formDtFabricacao?.mes
        const mesValidade = localForm.mesValidade

        if (diaFabricacao && diaValidade) {
            momentFabricacao = moment({ year: anoFabricacao.id, month: mesFabricacao.id - 1, day: diaFabricacao.id })
            momentValidade = moment({ year: anoValidade.id, month: mesValidade.id - 1, day: diaValidade.id })
        } else if (mesFabricacao && mesValidade) {
            momentFabricacao = moment({ year: anoFabricacao.id, month: mesFabricacao.id - 1 })
            momentValidade = moment({ year: anoValidade.id, month: mesValidade.id - 1 })
        } else {
            momentFabricacao = moment({ year: anoFabricacao.id })
            momentValidade = moment({ year: anoValidade.id })
        }

        if (momentValidade.isBefore(momentFabricacao)) {
            errors.validade = 'Esta data não pode ser anterior à data de fabricação'

            const hint = 'Campo com valor inválido'
            errors.anoValidade = hint
            if (mesValidade) {
                errors.mesValidade = hint
            }
            if (diaValidade) {
                errors.diaValidade = hint
            }
        }
    }
}

const dtFabricacaoAposDataAtual = (form, errors) => {
    let momentFabricacao
    let momentDataAtual

    const formDtFabricacao = form.get('dataFabricacao')
    const anoFabricacao = formDtFabricacao?.get('ano')

    if (anoFabricacao) {
        const diaFabricacao = formDtFabricacao.get('dia')
        const mesFabricacao = formDtFabricacao.get('mes')

        if (diaFabricacao) {
            momentFabricacao = moment({ year: anoFabricacao.id, month: mesFabricacao.id - 1, day: diaFabricacao.id })
            momentDataAtual = moment({ year: moment().year(), month: moment().month(), day: moment().date() })
        } else if (mesFabricacao) {
            momentFabricacao = moment({ year: anoFabricacao.id, month: mesFabricacao.id - 1 })
            momentDataAtual = moment({ year: moment().year(), month: moment().month() })
        } else {
            momentFabricacao = moment({ year: anoFabricacao.id })
            momentDataAtual = moment({ year: moment().year() })
        }

        if (momentDataAtual.isBefore(momentFabricacao)) {
            errors.dataFabricacao = { mes: 'Esta data não pode ser posterior à data atual' }
        }
    }
}

const loteNumero = (form, errors) => {
    if (!form.get('lote') && !form.get('numeroSerie')) {
        errors.loteNumeroSerie = 'É necessário preencher ao menos um dos campos abaixo'
    }
}

const identificadorDispositivo = (form, errors) => {
    if (!form.get('identificadorDispositivo') && !form.get('codigoReferencia')) {
        errors.idDispositivo = 'É necessário preencher ao menos um dos campos abaixo'
    }
}

const DadosProdutoValidator = createValidator(
    {
        codigoReferencia: maxLength(30),
        lote: maxLength(30),
        cnpj: cnpj,
        numeroSerie: maxLength(30),
        validade: required,
    },
    formValidator
)

function formValidator(form) {
    const errors: any = {}

    if (!form.get('semRegistroAnvisa') && !form.get('registroAnvisa')) {
        errors.registroAnvisa = required(form.get('registroAnvisa'))
    }

    const etiqueta = form.get('etiqueta')

    if (form.get('semRegistroAnvisa')) {
        errors.nomeFabricanteImportador = required(form.get('nomeFabricanteImportador'))
        errors.etiqueta = required(etiqueta)
        if (etiqueta) {
            if (etiqueta.erro) {
                errors.etiqueta = 'Esse campo é de preenchimento obrigatório'
            } else if (etiqueta.uploading) {
                errors.etiqueta = 'A foto da etiqueta ainda está sendo enviada'
            }
        }
    }

    const dtFabricacao = form.get('dataFabricacao')
    const anoFabricacao = dtFabricacao && dtFabricacao.get('ano')
    const mesFabricacao = dtFabricacao && dtFabricacao.get('mes')

    if (dtFabricacao != null && anoFabricacao != null && anoFabricacao !== '') {
        const mesError = required(mesFabricacao)
        if (mesError) {
            errors.dataFabricacao = {
                mes: mesError,
            }
        }
    }

    if (form.get('validade') === 'DATA') {
        checkDateRequired(form, errors)
        checkDateRelationship(form, errors)
    }

    dtFabricacaoAposDataAtual(form, errors)
    loteNumero(form, errors)
    identificadorDispositivo(form, errors)
    return errors
}

export default DadosProdutoValidator
