import { RelacaoEmpresasRespostaProfissionalDto } from 'backend'
import { Column, Columns, IconButton, LoadingAnimation, Text } from 'libs/components'
import { maskCpf } from 'libs/util/CpfUtil'
import React from 'react'
import { respostaRelacaoComEmpresaByEnum } from 'redux/enums'
import { ReadyState } from 'redux/requester'
import RelacaoEmpresasReportProfOpenRow from './RelacaoEmpresasReportProfOpenRow'

interface RelacaoEmpresasReportProfRowProps {
    content: RelacaoEmpresasRespostaProfissionalDto
}

const RelacaoEmpresasReportProfRow: React.FC<RelacaoEmpresasReportProfRowProps> = props => {
    const [openCard, setOpenCard] = React.useState<boolean>(false)
    const [readyStateOpenCard, setReadyStateOpenCard] = React.useState<ReadyState>()

    const changeReadyStateOpenCard = (readyState: ReadyState) => {
        setReadyStateOpenCard(readyState)
    }

    return (
        <div className='report-relacao--row'>
            <Columns>
                <Column size='6'>
                    <Text size='small-bis' color='black-bis'>
                        {props.content.nomeProfissional}
                    </Text>
                </Column>
                <Column size='2' customStyle={{ width: '19%' }}>
                    <Text size='small-bis' color='black-bis'>
                        {maskCpf(props.content.cpfProfissional)}
                    </Text>
                </Column>
                <Column size='3'>
                    <Text size='small-bis' color='black-bis'>
                        {respostaRelacaoComEmpresaByEnum[props.content.respostaRelacao]}
                    </Text>
                </Column>
                {props.content.respostaRelacao === 'SIM' && (
                    <Column>
                        {readyStateOpenCard === 'request' ? (
                            <LoadingAnimation size='small' />
                        ) : (
                            <IconButton
                                icon='acessar'
                                className={openCard ? 'rotate-135' : 'rotate-45'}
                                onClick={() => setOpenCard(!openCard)}
                            />
                        )}
                    </Column>
                )}
            </Columns>
            {openCard && (
                <RelacaoEmpresasReportProfOpenRow
                    idResposta={props.content.idResposta}
                    changeReadyState={changeReadyStateOpenCard}
                />
            )}
        </div>
    )
}

export default RelacaoEmpresasReportProfRow
