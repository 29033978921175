import * as React from 'react'
import { MotivosRevisoesRow } from 'backend'
import { Table, TableColumnHeader, Text, NumberLabel, Progress } from 'libs/components'

interface Props {
    rows: MotivosRevisoesRow[]
}

export class MotivosRevisaoTable extends React.Component<Props> {
    render() {
        const { rows } = this.props
        const totalProcedimentos = rows.map(r => r.count).reduce((c1, c2) => c1 + c2, 0)

        return (
            <Table values={rows} className='is-small-bis is-spaced'>
                <TableColumnHeader
                    title='Motivo'
                    render={(row: MotivosRevisoesRow) => {
                        return <Text>{row.motivoName}</Text>
                    }}
                />
                <TableColumnHeader
                    title=''
                    style={{ width: '400px' }}
                    render={(row: MotivosRevisoesRow) => {
                        const count = row.count

                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: (count / totalProcedimentos) * 100 + '%',
                                        marginRight: '10px',
                                    }}
                                >
                                    <Progress value={1} className='is-report-color-2' size='x-small' />
                                </span>
                                <NumberLabel
                                    className='percent'
                                    value={(count / totalProcedimentos) * 100}
                                    maxDecimalPlaces={1}
                                    sufix='%'
                                />
                            </div>
                        )
                    }}
                />
                <TableColumnHeader
                    title='Qtde.'
                    className='has-text-centered'
                    render={(row: MotivosRevisoesRow) => {
                        return (
                            <Text>
                                <NumberLabel value={row.count} />
                            </Text>
                        )
                    }}
                />
            </Table>
        )
    }
}
