import { tiposVisualizacaoProcedimentoUrlByEnum } from 'api/Visualizacao'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import VisualizacaoPrimariaJoelhoWrapper from './VisualizacaoPrimariaJoelho'

function VisualizacaoPrimariaJoelhoView(props: RouteComponentProps<any>) {
    const proced = tiposVisualizacaoProcedimentoUrlByEnum['PRIMARIA_JOELHO']
    const imprimirFicha = (idProcedimento: number) => {
        window.open('/api/procedimentos/impressaoRegistro/' + idProcedimento, '_blank')
    }
    return (
        <VisualizacaoPrimariaJoelhoWrapper
            id={props.match.params.id}
            proced={proced}
            idProced={props.match.params.id}
            imprimirFicha={imprimirFicha}
            {...props}
        />
    )
}

export default VisualizacaoPrimariaJoelhoView
