import React, { DOMAttributes } from 'react'
import classnames from 'classnames'

interface CircleProps extends Partial<DOMAttributes<any>> {
    /**
     * Dimensão do circle (size = width = height)
     */
    size: number

    /**
     * Largura da borda
     */
    strokeWidth?: number

    /**
     * Classname a ser aplicado no `circle` (pode ser usado para definir sua cor)
     */
    circleClass?: string
}

export class Circle extends React.Component<CircleProps> {
    static defaultProps: CircleProps = {
        size: 10,
        strokeWidth: 3,
    }

    render() {
        const radius = this.props.size / 2 - this.props.strokeWidth / 2

        return (
            <span className='circle'>
                <svg
                    width={this.props.size}
                    height={this.props.size}
                    className='circle-svg'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <circle
                        className={classnames('circle-front', this.props.circleClass)}
                        r={radius}
                        cx={this.props.size / 2}
                        cy={this.props.size / 2}
                        strokeWidth={this.props.strokeWidth}
                        strokeLinecap='round'
                        fill='none'
                    />
                </svg>
                {this.props.children && <span className='circle-content'>{this.props.children}</span>}
            </span>
        )
    }
}

interface CircleProgressProps extends CircleProps {
    /**
     * Um número de 0 a 1 indicando o progresso do circle
     */
    progress?: { [colorClass: string]: number }

    /**
     * Tipo do estilo do círculo (define a cor)
     */
    type?: 'primary' | 'secondary'
}

export class CircleProgress extends React.Component<CircleProgressProps> {
    static defaultProps = {
        strokeWidth: 3,
        progress: 1,
    }

    render() {
        const { size, strokeWidth, progress, ...rest } = this.props
        const radius = size / 2 - strokeWidth / 2
        const circunference = 2 * Math.PI * radius

        let progressTotal = 0
        const progresses = Object.keys(progress)
            .map(color => {
                const p = isFinite(progress[color]) && !isNaN(progress[color]) ? progress[color] : 0
                const progressValue = p === 1 ? 1 : circunference * (1 - p - progressTotal)
                progressTotal += p
                return { color, progressValue }
            })
            .sort((a, b) => a.progressValue - b.progressValue)

        return (
            <span className='circle' {...rest}>
                <svg width={size} height={size} className='circle-svg' xmlns='http://www.w3.org/2000/svg'>
                    <circle
                        className='circle-back'
                        r={radius}
                        cx={size / 2}
                        cy={size / 2}
                        strokeWidth={strokeWidth}
                        fill='none'
                    />
                    {progresses.map(({ color, progressValue }) => {
                        return (
                            <circle
                                key={color}
                                data-progress={progress[color]}
                                className={classnames('circle-front', color)}
                                r={radius}
                                cx={size / 2}
                                cy={size / 2}
                                strokeDasharray={circunference}
                                strokeDashoffset={progressValue}
                                strokeWidth={strokeWidth}
                                fill='none'
                            />
                        )
                    })}
                </svg>
                {this.props.children && <span className='circle-content'>{this.props.children}</span>}
            </span>
        )
    }
}
