
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.8,"time":48000,"words":160}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Durante o processo de consumo de dados, a entidade `}<strong parentName="p">{`cadastrada no RNI`}</strong>{` terá que utilizar uma chave de autenticação para realizar o consumo. Esta chave de autenticação deverá ser gerada pelo profissional da Anvisa e, posteriormente, repassada para a entidade referente.`}</p>
    <p>{`Uma chave é composta por um login e uma senha, que podem ser gerados pelo perfil de profissional Anvisa através dos passos abaixo:`}</p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique no menu lateral direito.`}</li>
      <li parentName="ol">{`Selecione a opção “Entidades para consumo de dados”.`}</li>
      <li parentName="ol">{`Clique na aba “Gerenciar chaves”.`}</li>
      <li parentName="ol">{`Na aba de “Login” clique no botão `}<strong parentName="li">{`Gerar novo login`}</strong></li>
      <li parentName="ol">{`Na aba de “Senha” clique no botão `}<strong parentName="li">{`Gerar nova senha`}</strong></li>
    </ol>
    <p>{`Após este processo, você terá uma nova chave gerada. Você poderá copiar o login clicando em `}<strong parentName="p">{`Copiar login`}</strong>{` e copiar a senha clicando em `}<strong parentName="p">{`Copiar senha`}</strong>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Caso seja necessário atualizar a chave de autenticação de uma entidade, basta realizar os passos 1 ao 5, especificados acima.`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

