/* tslint:disable */
import React from 'react'

const SvgForbidden = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 294 294' {...props}>
        <defs>
            <rect id='forbidden_svg__a' width={18} height={30} y={35} rx={2} />
            <rect id='forbidden_svg__b' width={18} height={30} y={85} rx={2} />
        </defs>
        <g>
            <g opacity={0.303} transform='translate(12 11)'>
                <circle cx={144.018} cy={251.018} r={3.018} />
                <path d='M6.819 110.373l-1.959-1.78 1.78-1.96a.89.89 0 10-1.272-1.165l-1.78 1.95-1.95-1.781A.89.89 0 10.463 106.9l1.958 1.78-1.78 1.959a.89.89 0 001.273 1.166l1.78-1.95 1.95 1.78a.89.89 0 101.175-1.263zm250.309-46.225l-1.959-1.78 1.78-1.959a.89.89 0 00-1.264-1.192l-1.78 1.95-1.95-1.781a.89.89 0 10-1.175 1.264l1.959 1.78-1.78 1.959a.89.89 0 001.272 1.166l1.78-1.95 1.95 1.78a.89.89 0 101.175-1.263l-.008.026zM33.181 14.362a5.181 5.181 0 110-10.362 5.181 5.181 0 010 10.362zm0-8.626a3.454 3.454 0 100 6.908 3.454 3.454 0 000-6.908zm210.7 215.027a3.881 3.881 0 113.882-3.882 3.89 3.89 0 01-3.882 3.882zm0-6.036a2.154 2.154 0 102.155 2.154 2.163 2.163 0 00-2.155-2.163v.009zm-233 27.036a3.881 3.881 0 113.882-3.882 3.89 3.89 0 01-3.882 3.882zm0-6.036a2.154 2.154 0 102.155 2.154 2.163 2.163 0 00-2.155-2.154z' />
                <circle cx={272.727} cy={129.727} r={1.727} />
                <circle cx={165.018} cy={3.018} r={3.018} />
            </g>
            <g transform='translate(71 63)'>
                <rect width={153} height={153} strokeLinecap='round' strokeLinejoin='round' strokeWidth={15} rx={4} />
                <rect width={139} height={138} x={7} y={8} rx={7} />
                <use xlinkHref='#forbidden_svg__a' />
                <rect width={12} height={24} x={3} y={38} strokeWidth={6} rx={2} />
                <use xlinkHref='#forbidden_svg__b' />
                <rect width={12} height={24} x={3} y={88} strokeWidth={6} rx={2} />
                <g transform='translate(73 47)'>
                    <circle cx={30} cy={29} r={21} strokeWidth={6} />
                    <path
                        strokeWidth={4}
                        d='M48 29v2l2-2h-1 1l-2-2v2h-8.25H48zM27.5 47.5l2 2v-1 1l2-2h-2v-8.25 8.25zM48 29v2l2-2h-1 1l-2-2v2h-8.25H48zm-4.004 14.496v-2.829l-1.415 1.414-5.833-5.833 6.54 6.54-.707-.707zm-28.992-2.829v2.829l.707-.707-.707.707h2.829l-1.414-1.415 5.833-5.833-5.833 5.833zm27.577 1.414l-1.414 1.415h2.829l-.707-.707.707.707v-2.829l-1.415 1.414-5.833-5.833 5.833 5.833zm-5.833-20.329l6.54-6.54-6.54 6.54zm5.833 20.33l-1.414 1.414h2.829l-.707-.707.707.707v-2.829l-1.415 1.414-5.833-5.833 5.833 5.833zm-1.414-27.578l1.414 1.415.708-.708-6.541 6.541 5.833-5.833z'
                    />
                    <circle cx={30} cy={29} r={12} strokeWidth={6} />
                </g>
                <path d='M18 151h30l-3.42 13.952A4 4 0 0140.695 168h-15.39a4 4 0 01-3.885-3.048L18 151zm88 0h30l-3.42 13.952a4 4 0 01-3.885 3.048h-15.39a4 4 0 01-3.885-3.048L106 151z' />
                <rect width={121} height={120} x={16} y={17} strokeWidth={4} rx={10} />
            </g>
        </g>
    </svg>
)

export default SvgForbidden
