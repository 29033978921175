import * as React from 'react'
import { LesaoVisualizacaoDto } from 'backend'
import * as C from 'libs/components'

interface LesoesTratadasProps {
    lesao: LesaoVisualizacaoDto
}

export class LesoesTratadasCard extends React.Component<LesoesTratadasProps> {
    render() {
        const localLesao = this.props.lesao.local

        const descricaoLocal = localLesao === 'NATIVA' ? '(coronária nativa)' : '(enxerto)'

        return (
            <div className='with-full-border'>
                <C.ComposedLabel horizontal title={this.props.lesao.nome} placeholder={descricaoLocal} />

                {localLesao === 'NATIVA' && <C.TextLabel title='Segmentos' value={this.props.lesao.segmentos} />}
                {localLesao === 'ENXERTO' && <C.TextLabel title='Tipo enxerto' value={this.props.lesao.tipoEnxerto} />}
                {localLesao === 'ENXERTO' && (
                    <C.TextLabel title='Coronária de destino' value={this.props.lesao.enxertoDestino} />
                )}

                <C.Columns>
                    <C.Column size='4'>
                        <C.TextLabel title='AHA/ACC' value={this.props.lesao.ahaAcc} />
                    </C.Column>
                    <C.Column size='4'>
                        <C.TextLabel title='Tipo de lesão' value={this.props.lesao.tipoLesao} />
                    </C.Column>
                    {this.props.lesao.tipoLesao === 'Reestenose' && (
                        <C.Column size='4'>
                            <C.TextLabel title='Tipo de reestenose' value={this.props.lesao.tipoReestenose} />
                        </C.Column>
                    )}
                    {this.props.lesao.tipoLesao === 'Trombose' && (
                        <C.Column size='4'>
                            <C.TextLabel title='Tipo de trombose' value={this.props.lesao.tipoTrombose} />
                        </C.Column>
                    )}
                </C.Columns>
            </div>
        )
    }
}
