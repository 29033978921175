import * as React from 'react'
import HomeConteudo from 'view/componentes/HomeConteudo'
import * as C from 'libs/components'
import forbidden from 'images/icons/forbidden.svg'
import { RouteComponentProps } from 'react-router-dom'

interface ForbiddenViewProps extends RouteComponentProps<any> {}

export default class ForbiddenView extends React.Component<ForbiddenViewProps> {
    render() {
        return (
            <HomeConteudo>
                <div className='container is-vertical-flow is-full-height forbidden'>
                    <C.Columns>
                        <C.Column size='5'>
                            <div className='has-text-centered'>
                                <img src={forbidden} alt='forbidden' />
                            </div>
                        </C.Column>
                        <C.Column size='5'>
                            <div className='acesso-proibido'>Acesso Proibido</div>
                            <div className='nao-possui-permissao-text'>
                                Você não tem permissão para acessar <br />
                                essa página.
                            </div>
                            <div>
                                <C.Button
                                    pulledLeft
                                    icon='voltar'
                                    size='medium'
                                    onClick={() => this.props.history.push('/home')}
                                >
                                    Página inicial
                                </C.Button>
                            </div>
                        </C.Column>
                    </C.Columns>
                </div>
            </HomeConteudo>
        )
    }
}
