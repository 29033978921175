/* tslint:disable */
import React from 'react'

const SvgVoltar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M20.396 11.33h-15.4l6.93-6.897a.6.6 0 00-.85-.82l-7.9 7.896a.6.6 0 000 .85l7.9 7.896a.6.6 0 10.85-.83l-6.93-6.896h15.4a.6.6 0 100-1.2z' />
    </svg>
)

export default SvgVoltar
