import React from 'react'
import { CheckboxField, ChecklistItemField, Column, Columns, Conteudo, Linha, RadioPanelField } from 'libs/components'

const EsferaAcessoFederativaPanel: React.FC = () => {
    return (
        <RadioPanelField
            label='Unidade Federativa'
            name='esferaAcesso'
            value='ESTADUAL'
            showErrors={true}
            testid='radio-estado-esfera'
        >
            <Conteudo>
                <CheckboxField name='todosUfs' label='Selecionar todos' />
                <Linha />
                <Columns>
                    <Column verticalFlow>
                        <span className='bolder'>
                            <CheckboxField name='todosEstadosNordeste' label='Nordeste' />
                        </span>

                        <div className='pl-20 is-vertical-flow'>
                            <ChecklistItemField name='ufsAcesso' label='AL' optionValue='AL' />
                            <ChecklistItemField name='ufsAcesso' label='BA' optionValue='BA' />
                            <ChecklistItemField name='ufsAcesso' label='CE' optionValue='CE' />
                            <ChecklistItemField name='ufsAcesso' label='MA' optionValue='MA' />
                            <ChecklistItemField name='ufsAcesso' label='PE' optionValue='PE' />
                            <ChecklistItemField name='ufsAcesso' label='PI' optionValue='PI' />
                            <ChecklistItemField name='ufsAcesso' label='PB' optionValue='PB' />
                            <ChecklistItemField name='ufsAcesso' label='RN' optionValue='RN' />
                            <ChecklistItemField name='ufsAcesso' label='SE' optionValue='SE' />
                        </div>
                    </Column>
                    <Column verticalFlow>
                        <span className='bolder'>
                            <CheckboxField name='todosEstadosNorte' label='Norte' />
                        </span>

                        <div className='pl-20 is-vertical-flow'>
                            <ChecklistItemField name='ufsAcesso' label='AC' optionValue='AC' />
                            <ChecklistItemField name='ufsAcesso' label='AM' optionValue='AM' />
                            <ChecklistItemField name='ufsAcesso' label='AP' optionValue='AP' />
                            <ChecklistItemField name='ufsAcesso' label='PA' optionValue='PA' />
                            <ChecklistItemField name='ufsAcesso' label='RO' optionValue='RO' />
                            <ChecklistItemField name='ufsAcesso' label='RR' optionValue='RR' />
                            <ChecklistItemField name='ufsAcesso' label='TO' optionValue='TO' />
                        </div>
                    </Column>
                    <Column verticalFlow>
                        <span className='bolder'>
                            <CheckboxField name='todosEstadosCentroOeste' label='Centro-Oeste' />
                        </span>

                        <div className='pl-20 is-vertical-flow'>
                            <ChecklistItemField name='ufsAcesso' label='DF' optionValue='DF' />
                            <ChecklistItemField name='ufsAcesso' label='GO' optionValue='GO' />
                            <ChecklistItemField name='ufsAcesso' label='MT' optionValue='MT' />
                            <ChecklistItemField name='ufsAcesso' label='MS' optionValue='MS' />
                        </div>
                    </Column>
                    <Column verticalFlow>
                        <span className='bolder'>
                            <CheckboxField name='todosEstadosSudeste' label='Sudeste' />
                        </span>

                        <div className='pl-20 is-vertical-flow'>
                            <ChecklistItemField name='ufsAcesso' label='ES' optionValue='ES' />
                            <ChecklistItemField name='ufsAcesso' label='MG' optionValue='MG' />
                            <ChecklistItemField name='ufsAcesso' label='RJ' optionValue='RJ' />
                            <ChecklistItemField name='ufsAcesso' label='SP' optionValue='SP' />
                        </div>
                    </Column>
                    <Column verticalFlow>
                        <span className='bolder'>
                            <CheckboxField name='todosEstadosSul' label='Sul' testid='checkbox-todosEstadosSul' />
                        </span>

                        <div className='pl-20 is-vertical-flow'>
                            <ChecklistItemField name='ufsAcesso' label='PR' optionValue='PR' />
                            <ChecklistItemField name='ufsAcesso' label='RS' optionValue='RS' />
                            <ChecklistItemField name='ufsAcesso' label='SC' optionValue='SC' />
                        </div>
                    </Column>
                </Columns>
            </Conteudo>
        </RadioPanelField>
    )
}

export default EsferaAcessoFederativaPanel
