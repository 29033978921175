/* tslint:disable */
import React from 'react'

const SvgAcessar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M8.7 19a.608.608 0 01-.533-.364.587.587 0 01.108-.629l6.15-6.056-6.15-6.057a.587.587 0 01.096-.72.617.617 0 01.735-.094l6.585 6.454a.59.59 0 010 .844L9.106 18.8a.612.612 0 01-.405.199z' />
    </svg>
)

export default SvgAcessar
