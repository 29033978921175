import cardiologia from 'images/home/perfil/ic-cardiologia.svg'
import ortopedia from 'images/home/perfil/ic-ortopedia.svg'
import segurancaAcesso from 'images/home/perfil/snvs-ico.svg'
import tecnico from 'images/home/perfil/ic-tecnico.svg'
import administrativo from 'images/home/perfil/ic-administrativo.svg'
import anvisa from 'images/home/perfil/ic-anvisa.svg'
import gestorIntegracao from 'images/home/perfil/ic-gestor_integracao.svg'
import gestorServicoSaude from 'images/home/perfil/ic-gestor-servico-saude.svg'
import residenteCardiologia from 'images/home/perfil/ic-residente-cardiologia.svg'
import residenteOrtopedia from 'images/home/perfil/ic-residente-ortopedia.svg'
import { Perfil } from 'backend'

export const PerfilEnum: { [key in Perfil]: Perfil } = {
    GESTOR_SEGURANCA: 'GESTOR_SEGURANCA',
    MEDICO_ORTOPEDISTA: 'MEDICO_ORTOPEDISTA',
    MEDICO_CARDIOLOGISTA: 'MEDICO_CARDIOLOGISTA',
    TECNICO: 'TECNICO',
    ADMINISTRATIVO: 'ADMINISTRATIVO',
    ANVISA: 'ANVISA',
    GESTOR_INTEGRACAO: 'GESTOR_INTEGRACAO',
    GESTOR_SERVICO_SAUDE: 'GESTOR_SERVICO_SAUDE',
    RESIDENTE_CARDIOLOGISTA: 'RESIDENTE_CARDIOLOGISTA',
    RESIDENTE_ORTOPEDISTA: 'RESIDENTE_ORTOPEDISTA',
    SNVS: 'SNVS',
}

export const DescricaoPerfil: { [key in Perfil]: string } = {
    MEDICO_ORTOPEDISTA: 'ORTOPEDISTA',
    MEDICO_CARDIOLOGISTA: 'CARDIOLOGISTA',
    GESTOR_SEGURANCA: 'GESTOR DE SEGURANÇA HOSPITALAR',
    TECNICO: 'TÉCNICO',
    ADMINISTRATIVO: 'ADMINISTRATIVO/FINANCEIRO',
    ANVISA: 'ANVISA',
    GESTOR_INTEGRACAO: 'GESTOR DE INTEGRAÇÃO',
    GESTOR_SERVICO_SAUDE: 'GESTOR DO SERVIÇO DE SAÚDE',
    RESIDENTE_CARDIOLOGISTA: 'RESIDENTE EM CARDIOLOGIA',
    RESIDENTE_ORTOPEDISTA: 'RESIDENTE EM ORTOPEDIA',
    SNVS: 'SISTEMA NACIONAL DE VIGILÂNCIA SANITÁRIA',
}

export const DescricaoPerfilCaptalize: { [key in Perfil]: string } = {
    MEDICO_ORTOPEDISTA: 'Ortopedista',
    MEDICO_CARDIOLOGISTA: 'Cardiologista',
    GESTOR_SEGURANCA: 'Gestor de Segurança Hospitalar',
    TECNICO: 'Técnico',
    ADMINISTRATIVO: 'Administrativo/Financeiro',
    ANVISA: 'ANVISA',
    GESTOR_INTEGRACAO: 'Gestor de Integração',
    GESTOR_SERVICO_SAUDE: 'Gestor do Serviço de Saúde',
    RESIDENTE_CARDIOLOGISTA: 'Residente em Cardiologia',
    RESIDENTE_ORTOPEDISTA: 'Residente em Ortopedia',
    SNVS: 'Gestor Sistema Nacional de Vigilância Sanitária',
}

export const DescricaoCompletaPerfil: { [key in Perfil]: string } = {
    MEDICO_ORTOPEDISTA: 'MÉDICO ORTOPEDISTA',
    MEDICO_CARDIOLOGISTA: 'MÉDICO CARDIOLOGISTA',
    GESTOR_SEGURANCA: 'GESTOR DE SEGURANÇA HOSPITALAR',
    TECNICO: 'TÉCNICO',
    ADMINISTRATIVO: 'FINANCEIRO',
    ANVISA: 'ANVISA',
    GESTOR_INTEGRACAO: 'GESTOR DE INTEGRAÇÃO',
    GESTOR_SERVICO_SAUDE: 'GESTOR DO SERVIÇO DE SAÚDE',
    RESIDENTE_CARDIOLOGISTA: 'RESIDENTE EM CARDIOLOGIA',
    RESIDENTE_ORTOPEDISTA: 'RESIDENTE EM ORTOPEDIA',
    SNVS: 'SNVS',
}

export const ImagemPerfil: { [key in Perfil]: string } = {
    MEDICO_ORTOPEDISTA: ortopedia,
    MEDICO_CARDIOLOGISTA: cardiologia,
    GESTOR_SEGURANCA: segurancaAcesso,
    TECNICO: tecnico,
    ADMINISTRATIVO: administrativo,
    ANVISA: anvisa,
    GESTOR_INTEGRACAO: gestorIntegracao,
    GESTOR_SERVICO_SAUDE: gestorServicoSaude,
    RESIDENTE_CARDIOLOGISTA: residenteCardiologia,
    RESIDENTE_ORTOPEDISTA: residenteOrtopedia,
    SNVS: segurancaAcesso,
}

export const PerfisRni = [
    PerfilEnum.TECNICO,
    PerfilEnum.MEDICO_ORTOPEDISTA,
    PerfilEnum.MEDICO_CARDIOLOGISTA,
    PerfilEnum.ADMINISTRATIVO,
    PerfilEnum.GESTOR_INTEGRACAO,
    PerfilEnum.GESTOR_SERVICO_SAUDE,
    PerfilEnum.RESIDENTE_CARDIOLOGISTA,
    PerfilEnum.RESIDENTE_ORTOPEDISTA,
    PerfilEnum.SNVS,
]

export const PerfisWithLargerHeader = [PerfilEnum.GESTOR_SERVICO_SAUDE, PerfilEnum.ANVISA, PerfilEnum.SNVS]

export const PerfisMedico = [
    PerfilEnum.MEDICO_CARDIOLOGISTA,
    PerfilEnum.MEDICO_ORTOPEDISTA,
    PerfilEnum.RESIDENTE_CARDIOLOGISTA,
    PerfilEnum.RESIDENTE_ORTOPEDISTA,
]

export const PerfisResidentes = [PerfilEnum.RESIDENTE_ORTOPEDISTA, PerfilEnum.RESIDENTE_CARDIOLOGISTA]

export const PerfisOrtopedistas = [PerfilEnum.RESIDENTE_ORTOPEDISTA, PerfilEnum.MEDICO_ORTOPEDISTA]

export const PerfisCardiologistas = [PerfilEnum.RESIDENTE_CARDIOLOGISTA, PerfilEnum.MEDICO_CARDIOLOGISTA]

export type EspecialidadesMedicas = 'ARTROPLASTIA' | 'ANGIOPLASTIA'

type PerfisMedicoType =
    | 'MEDICO_CARDIOLOGISTA'
    | 'MEDICO_ORTOPEDISTA'
    | 'RESIDENTE_CARDIOLOGISTA'
    | 'RESIDENTE_ORTOPEDISTA'

export const EspecialidadePerfil: { [key in PerfisMedicoType]: EspecialidadesMedicas } = {
    MEDICO_ORTOPEDISTA: 'ARTROPLASTIA',
    MEDICO_CARDIOLOGISTA: 'ANGIOPLASTIA',
    RESIDENTE_CARDIOLOGISTA: 'ANGIOPLASTIA',
    RESIDENTE_ORTOPEDISTA: 'ARTROPLASTIA',
}

class Perfis {}

export default new Perfis()
