import * as React from 'react'
import classnames from 'classnames'

export type Size =
    | 'xx-small'
    | 'x-small'
    | 'small-ter'
    | 'small-bis'
    | 'small'
    | 'medium'
    | 'large'
    | 'x-large'
    | 'xx-large'
    | 'big'
    | 'x-big'
    | '13px'
export type Weight = 'regular' | 'semibold' | 'bold'
export type Color = 'neon' | 'green' | 'grey' | 'grey-darker' | 'black' | 'black-bis' | 'black-ter'
export type Transform = 'uppercase' | 'lowercase' | 'capitalize'

interface TextProps {
    color?: Color
    className?: string
    size?: Size
    weight?: Weight
    transform?: Transform
    italic?: boolean
    testid?: string
    style?: React.CSSProperties
}

export class Text extends React.PureComponent<TextProps> {
    render() {
        const classes = classnames('text', this.props.className, {
            'is-neon': this.props.color === 'neon',
            'is-green': this.props.color === 'green',
            'is-grey-darker': this.props.color === 'grey-darker',
            'is-grey': this.props.color === 'grey',
            'is-black': this.props.color === 'black',
            'is-black-bis': this.props.color === 'black-bis',
            'is-black-ter': this.props.color === 'black-ter',
            'is-x-big': this.props.size === 'x-big',
            'is-big': this.props.size === 'big',
            'is-x-large': this.props.size === 'x-large',
            'is-xx-large': this.props.size === 'xx-large',
            'is-large': this.props.size === 'large',
            'is-medium': this.props.size === 'medium',
            'is-small': this.props.size === 'small',
            'is-small-bis': this.props.size === 'small-bis',
            'is-small-ter': this.props.size === 'small-ter',
            'is-x-small': this.props.size === 'x-small',
            'is-xx-small': this.props.size === 'xx-small',
            'is-bold': this.props.weight === 'bold',
            'is-semibold': this.props.weight === 'semibold',
            'is-regular': this.props.weight === 'regular',
            'is-italic': this.props.italic,
            'is-uppercase': this.props.transform === 'uppercase',
            'is-lowercase': this.props.transform === 'lowercase',
            'is-capitalized': this.props.transform === 'capitalize',
        })
        return (
            <span className={classes} data-testid={this.props.testid} style={this.props.style}>
                {this.props.children}
            </span>
        )
    }
}

export default Text
