import React from 'react'
import { Icon, Column, IconSize } from 'libs/components'
import { Icons } from 'libs/components/presentational/elements/icons'

export type IntegracaoRowIconColumnStatus = 'success' | 'inactive' | 'fail'

interface IntegracaoRowIconColumnProps {
    status: IntegracaoRowIconColumnStatus
    icon: Icons
    iconSize: IconSize
}

export const IntegracaoRowIconColumn = (props: IntegracaoRowIconColumnProps) => {
    const { status, icon, iconSize } = props

    let divClassName: string
    let iconClassName: string
    if (status === 'fail') {
        divClassName = 'integracao-icon-box failure'
        iconClassName = 'integracao-icon-image-failure'
    } else if (status === 'success') {
        divClassName = 'integracao-icon-box'
        iconClassName = 'integracao-icon-image'
    } else {
        divClassName = 'integracao-icon-box inactive'
        iconClassName = 'integracao-icon-image-inactive'
    }

    return (
        <Column className='integracao-icon-column'>
            <div className={divClassName}>
                <Icon className={iconClassName} size={iconSize} icon={icon} />
            </div>
        </Column>
    )
}
