import { Column, Columns, GrayField, NumberField, ControlField } from 'libs/components'
import React from 'react'

interface AirKermaFieldProps {
    numberValue: string
}

export const AirKermaField = (props: AirKermaFieldProps) => {
    return (
        <div>
            <ControlField name='airKerma' label='Air Kerma' />
            <Columns horizontalFlow>
                <Column size='2'>
                    <NumberField maxLength={4} name='airKerma' placeholder='(0-9999)' />
                </Column>
                <Column pulledBottom size='3'>
                    <GrayField name='unidadeAirKerma' disabled={!props.numberValue} required={!!props.numberValue} />
                </Column>
            </Columns>
        </div>
    )
}
