import { createValidator, required, senha } from 'libs/validation'

const AlterarSenhaValidator = createValidator(
    {
        senhaAtual: required,
        novaSenha: [required, senha],
        confirmarNovaSenha: required,
    },
    form => {
        const errors = {}

        const senhaAtual = form.get('senhaAtual')
        const novaSenha = form.get('novaSenha')
        const confirmarNovaSenha = form.get('confirmarNovaSenha')

        if (senhaAtual && novaSenha && senhaAtual === novaSenha) {
            errors.novaSenha = 'A nova senha deve ser diferente da senha atual'
        }

        if (novaSenha && confirmarNovaSenha && novaSenha !== confirmarNovaSenha) {
            errors.confirmarNovaSenha = 'A confirmação não confere com a senha informada'
        }

        return errors
    }
)

export default AlterarSenhaValidator
