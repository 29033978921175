import * as React from 'react'
import * as C from 'libs/components'
import { DistClassificacaoSexo, ClassificacaoCount } from 'redux/modules/report/procedimento'
import { Sexo } from 'backend'
import * as enums from 'redux/enums'
import { Icons } from 'libs/components/presentational/elements/icons'
import { InvertedLabel } from 'view/componentes/reports/components/InvertedLabel'

interface Props {
    dist: DistClassificacaoSexo
}

export class DadosUsoSexoCard extends React.Component<Props> {
    render() {
        const dist = this.props.dist
        const result = dist.result
        return (
            <C.Card title='Dados de uso'>
                <C.LoadingStateContainer readyState={dist.readyState}>
                    {result && (
                        <C.Columns>
                            <C.Column className='has-border-right align-center' size='3'>
                                <InvertedLabel title='Registros'>
                                    <C.NumberLabel
                                        value={result.masculino.total + result.feminino.total}
                                        placeholder='-'
                                    />
                                </InvertedLabel>
                            </C.Column>
                            <C.Column className='padded-left-25'>
                                <SexoCardRow sexo='MASCULINO' {...result.masculino} />
                            </C.Column>
                            <C.Column>
                                <SexoCardRow sexo='FEMININO' {...result.feminino} />
                            </C.Column>
                        </C.Columns>
                    )}
                </C.LoadingStateContainer>
            </C.Card>
        )
    }
}

interface SexoCardRowProps extends ClassificacaoCount {
    dontShowBar?: boolean
    sexo: Sexo
}

class SexoCardRow extends React.Component<SexoCardRowProps> {
    render() {
        const quocients = Object.entries(this.props.classificacoes).reduce(
            (obj, [key, value]) => ({
                ...obj,
                [value.className]: value.quocient,
            }),
            {}
        )

        return (
            <C.Columns auto className='sexo-card-row align-center'>
                <div className='flex-3'>
                    <C.Columns pulledLeft auto>
                        <C.CircleProgress strokeWidth={2} size={30} progress={quocients}>
                            <C.Icon
                                size='is-x-small'
                                icon={this.props.sexo.toLowerCase() as Icons}
                                className='is-report-color-2'
                            />
                        </C.CircleProgress>
                        <C.ComposedLabel
                            title={enums.sexoName[this.props.sexo]}
                            labelClassName='is-neon is-uppercase'
                            big
                        >
                            <C.NumberLabel value={this.props.total} placeholder='0' />
                        </C.ComposedLabel>
                    </C.Columns>
                </div>
                {Object.keys(this.props.classificacoes).length > 1 && (
                    <div className='flex-3 justify-center'>
                        <C.Columns auto>
                            {Object.entries(this.props.classificacoes).map(([key, value]) => (
                                <C.ComposedLabel
                                    key={key}
                                    title={value.title}
                                    size='small'
                                    labelClassName={value.className}
                                >
                                    <C.NumberLabel value={value.total} />{' '}
                                    <span className='is-muted is-small-ter'>
                                        <C.NumberLabel maxDecimalPlaces={1} value={value.quocient * 100} />%
                                    </span>
                                </C.ComposedLabel>
                            ))}
                        </C.Columns>
                    </div>
                )}
            </C.Columns>
        )
    }
}
