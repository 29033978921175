import * as React from 'react'
import { Card, CircleProgress, Column, Columns, LoadingStateContainer, NumberLabel } from 'libs/components'

interface Props {
    finalizados: number
    naoFinal: number
    readyState: 'request' | 'success' | 'failure'
}

export class FinalizadosXNaoFinalizadosCard extends React.Component<Props> {
    render() {
        const { finalizados, naoFinal } = this.props
        const total = finalizados + naoFinal
        const quocientF = finalizados / total
        const quocientNF = naoFinal / total
        const higherPercent = total ? (finalizados > naoFinal ? quocientF : quocientNF) : 0
        return (
            <Card title={'Finalizados x não finalizados'}>
                <LoadingStateContainer readyState={this.props.readyState}>
                    <Columns className='is-small align-center card-padding-13'>
                        <Column size='2'>
                            <CircleProgress
                                type='primary'
                                size={55}
                                progress={{ 'is-report-color-1': higherPercent }}
                            />
                        </Column>
                        <Column className='is-semibold is-black-ter'>
                            <Columns>
                                <Column className='is-semibold is-black-ter'>
                                    <p key={'finalizadas'} className={'value-percent-card has-graph'}>
                                        <NumberLabel
                                            className={
                                                finalizados > naoFinal ? 'is-report-color-1 is-13-600' : 'is-13-600'
                                            }
                                            value={finalizados}
                                            placeholder='0'
                                        />
                                        <NumberLabel placeholder='- %' value={quocientF * 100} sufix='%' />
                                        <span className='percent' style={{ paddingLeft: '4px' }}>
                                            Finalizados
                                        </span>
                                    </p>
                                </Column>
                            </Columns>
                            <Columns>
                                <Column className='is-semibold is-black-ter'>
                                    <p key={'naoFinal'} className={'value-percent-card has-graph'}>
                                        <NumberLabel
                                            className={
                                                finalizados < naoFinal ? 'is-report-color-1 is-13-600' : 'is-13-600'
                                            }
                                            value={naoFinal}
                                            placeholder='0'
                                        />
                                        <NumberLabel placeholder='- %' value={quocientNF * 100} sufix='%' />
                                        <span className='percent' style={{ paddingLeft: '4px' }}>
                                            Não finalizados
                                        </span>
                                    </p>
                                </Column>
                            </Columns>
                        </Column>
                    </Columns>
                </LoadingStateContainer>
            </Card>
        )
    }
}
