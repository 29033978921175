import * as React from 'react'
import { TextLabel } from '../elements/label/TextLabel'
import { Column } from '../layout/grid/Column'
import { Columns } from '../layout/grid/Columns'
import { HourField } from './field/normalize/HourField'
import { InputPanel } from './InputPanel'

interface Props {
    duracao?: string
}

export class InicioTerminoPanel extends React.Component<Props> {
    render() {
        return (
            <InputPanel fallback size='medium' dataName='procedimentoCirurgicoAtualForm.hora'>
                <Columns>
                    <Column size='2'>
                        <HourField name='horaInicio' label='Início' placeholder='00:00' required />
                    </Column>
                    <Column size='3'>
                        <HourField name='horaTermino' label='Término' placeholder='00:00' required />
                    </Column>
                    <Column size='4'>
                        <TextLabel
                            title='Duração'
                            value={this.props.duracao}
                            placeholder='Informe início/término'
                            className='padded-bottom'
                        />
                    </Column>
                </Columns>
            </InputPanel>
        )
    }
}
