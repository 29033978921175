import {
    DistribuicaoProdutosFiltro,
    DistribuicaoCardiologiaDto,
    DistribuicaoProdutosDto,
    DistribuicaoProcedimentoFiltro,
    ProdutosProcedimentosRow,
    DistribuicaoProdutosPrimariasDto,
    DistribuicaoProdutosRevisaoDto,
    DistribuicaoProdutosBrasilDto,
} from 'backend'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'
import Report from 'api/Report'

const CHANGE_FILTER = 'rni/report/rastreabilidade/CHANGE_FILTER'
export const FIND_PRODUTOS = 'rni/rerpot/produto/FIND_PRODUTOS'
export const FIND_COMPONENTE_PRIMARIA = 'rni/rerpot/produto/FIND_COMPONENTE_PRIMARIA'
export const FIND_COMPONENTE_REVISAO = 'rni/rerpot/produto/FIND_COMPONENTE_REVISAO'
export const FIND_COMPONENTE_BRASIL = 'rni/rerpot/produto/FIND_COMPONENTE_BRASIL'
export const FIND_DETALHE_PRODUTO_TIPO_REVISAO = 'rni/rerpot/produto/FIND_DETALHE_PRODUTO_TIPO_REVISAO'
export const FIND_COMPONENTE_CARDIOLOGIA = 'rni/rerpot/produto/FIND_COMPONENTE_CARDIOLOGIA'
const FIND_DIST_FILTER = 'rni/report/rastreabilidade/FIND_DIST_FILTER'
export const CLEAR_FIND_RESULT = 'rni/report/rastreabilidade/CLEAR_FIND_RESULT'
const CLEAR_FIND_RESULT_PRODUTOS = 'rni/report/rastreabilidade/CLEAR_FIND_RESULT_PRODUTOS'

export interface ProdutoReportState {
    produtos: PromiseRecord<ProdutosProcedimentosRow>
    findDistribuicaoProdutos: PromiseRecord<DistribuicaoProdutosDto>
    componentePrimaria: PromiseRecord<DistribuicaoProdutosPrimariasDto>
    componenteRevisao: PromiseRecord<DistribuicaoProdutosRevisaoDto>
    componenteBrasil: PromiseRecord<DistribuicaoProdutosBrasilDto>
    detalheProdutoTipoRevisao: PromiseRecord<DistribuicaoProdutosBrasilDto>
    componenteCardiologia: PromiseRecord<DistribuicaoCardiologiaDto>
}

export const initialState: ProdutoReportState = {
    produtos: {},
    findDistribuicaoProdutos: {},
    componentePrimaria: {},
    componenteRevisao: {},
    componenteBrasil: {},
    detalheProdutoTipoRevisao: {},
    componenteCardiologia: {},
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_FILTER:
            return {
                ...state,
                filtro: {
                    ...action.filtro,
                },
            }
        case FIND_PRODUTOS:
            return { ...state, produtos: promiseReducer(state.produtos, action) }
        case FIND_COMPONENTE_PRIMARIA:
            return { ...state, componentePrimaria: promiseReducer(state.componentePrimaria, action) }
        case FIND_COMPONENTE_REVISAO:
            return { ...state, componenteRevisao: promiseReducer(state.componenteRevisao, action) }
        case FIND_COMPONENTE_BRASIL:
            return { ...state, componenteBrasil: promiseReducer(state.componenteBrasil, action) }
        case FIND_DETALHE_PRODUTO_TIPO_REVISAO:
            return { ...state, detalheProdutoTipoRevisao: promiseReducer(state.detalheProdutoTipoRevisao, action) }
        case FIND_COMPONENTE_CARDIOLOGIA:
            return { ...state, componenteCardiologia: promiseReducer(state.componenteCardiologia, action) }
        case FIND_DIST_FILTER:
            return { ...state, findDistribuicaoProdutos: promiseReducer(state.findDistribuicaoProdutos, action) }
        case CLEAR_FIND_RESULT:
            return {
                ...state,
                componentePrimaria: {},
                componenteRevisao: {},
                componenteBrasil: {},
                detalheProdutoTipoRevisao: {},
                componenteCardiologia: {},
            }
        case CLEAR_FIND_RESULT_PRODUTOS:
            return {
                ...state,
                produtos: {},
                componentePrimaria: {},
                componenteRevisao: {},
                componenteBrasil: {},
                detalheProdutoTipoRevisao: {},
                componenteCardiologia: {},
            }
        default:
            return state
    }
}

export default reducer

export const findProdutos = (tipo: DistribuicaoProcedimentoFiltro) => ({
    type: FIND_PRODUTOS,
    promise: Report.getProdutosProcedimentos(tipo),
})

export const findComponentePrimaria = (id: number, tipo: DistribuicaoProcedimentoFiltro) => {
    return {
        type: FIND_COMPONENTE_PRIMARIA,
        promise: Report.getProdutosPrimarias(id, tipo),
    }
}

export const findComponenteCardiologia = (id: number, tipo: DistribuicaoProcedimentoFiltro) => {
    return {
        type: FIND_COMPONENTE_CARDIOLOGIA,
        promise: Report.getProdutosCardiologia(id, tipo),
    }
}

export const findComponenteRevisao = (id: number, tipo: DistribuicaoProcedimentoFiltro) => {
    return {
        type: FIND_COMPONENTE_REVISAO,
        promise: Report.getProdutosRevisao(id, tipo),
    }
}

export const findComponenteBrasil = (id: number, filtro: DistribuicaoProcedimentoFiltro) => {
    return {
        type: FIND_COMPONENTE_BRASIL,
        promise: Report.getProdutosBrasil(id, filtro),
    }
}

export const findDetalheProdutoTipoRevisao = (filtro: DistribuicaoProcedimentoFiltro) => {
    return {
        type: FIND_DETALHE_PRODUTO_TIPO_REVISAO,
        promise: Report.getDetalheProdutoTipoRevisao(filtro),
    }
}

export const findDistribuicaoProdutos = (filtro: DistribuicaoProdutosFiltro) => ({
    type: FIND_DIST_FILTER,
    promise: Report.findDistribuicaoProdutos(filtro),
})

export const clearFindResult = () => ({
    type: CLEAR_FIND_RESULT,
})

export const clearFindResultProdutos = () => ({
    type: CLEAR_FIND_RESULT_PRODUTOS,
})
