import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { HomeGestorServicoSaudeView } from './HomeGestorServicoSaudeView'
import { GestorServicoSaudeDistArtroplastiaView } from './reports/GestorServicoSaudeDistArtroplastiaView'
import { GestorServicoSaudeDistAngioplastiaView } from './reports/GestorServicoSaudeDistAngioplastiaView'
import { GestorServicoSaudeMotivosProcedimentoView } from './reports/GestorServicoSaudeMotivosProcedimentoView'
import { GestorServicoSaudeMotivosProcedimentoDashboardView } from './reports/GestorServicoSaudeMotivosProcedimentoDashboardView'
import { GestorServicoSaudeRastreabilidadeReportFilterView } from './reports/GestorServicoSaudeRastreabilidadeReportFilterView'
import { GestorServicoSaudeSumarioPacienteView } from './reports/GestorServicoSaudeSumarioPacienteView'
import { GestorServicoSaudeRastreabilidadeReportView } from './reports/GestorServicoSaudeRastreabilidadeReportView'
import { GestorServicoSaudeTipoRevisaoView } from './reports/GestorServicoSaudeTipoRevisaoView'
import { GestorServicoSaudeTipoRevisaoDashboardView } from './reports/GestorServicoSaudeTipoRevisaoDashboardView'
import { GestorServicoSaudeUsoSistemaDashboard } from './reports/GestorServicoSaudeUsoSistemaDashboard'
import ReportVisualizacaoFicha from 'view/componentes/reports/ReportVisualizacaoFicha'
import BuscaProcedimentoRootContainer from 'view/componentes/buscaProcedimento/BuscaProcedimentoRootContainer'
import ListaPacientesContainer from '../anvisa/reports/paciente/ListaPacientesContainer'
import TipoRevisaoProdutoDetailContainer from '../anvisa/reports/produtos/TipoRevisaoProdutoDetailContainer'

export const HomeGestorServicoSaude = () => {
    return (
        <Switch>
            <Route exact path='/home'>
                <HomeGestorServicoSaudeView />
            </Route>
            <Route path='/home/relatorios/artroplastia'>
                <GestorServicoSaudeDistArtroplastiaView />
            </Route>
            <Route path='/home/relatorios/angioplastia'>
                <GestorServicoSaudeDistAngioplastiaView />
            </Route>
            <Route exact path='/home/relatorios/motivos'>
                <GestorServicoSaudeMotivosProcedimentoView />
            </Route>
            <Route path='/home/relatorios/motivos/:motivo'>
                <GestorServicoSaudeMotivosProcedimentoDashboardView />
            </Route>
            <Route exact path='/home/relatorios/rastreabilidade'>
                <GestorServicoSaudeRastreabilidadeReportFilterView />
            </Route>

            <Route path='/home/relatorios/rastreabilidade/visualizacao' component={ReportVisualizacaoFicha} />
            <Route path='/home/relatorios/listaPacientes/visualizacao' component={ReportVisualizacaoFicha} />
            <Route
                path='/home/relatorios/rastreabilidade/registro/:registroAnvisa/paciente/:pacienteId/visualizacao'
                component={ReportVisualizacaoFicha}
            />
            <Route path='/home/relatorios/rastreabilidade/registro/:registroAnvisa/paciente/:pacienteId'>
                <GestorServicoSaudeSumarioPacienteView />
            </Route>
            <Route path='/home/relatorios/rastreabilidade/registro/:regAnvisa'>
                <GestorServicoSaudeRastreabilidadeReportView />
            </Route>

            <Route exact path='/home/relatorios/tipoRevisao'>
                <GestorServicoSaudeTipoRevisaoView />
            </Route>
            <Route exact path='/home/relatorios/tipoRevisao/:tipoRevisao'>
                <GestorServicoSaudeTipoRevisaoDashboardView />
            </Route>
            <Route
                exact
                path={'/home/relatorios/tipoRevisao/:tipoRevisao/registro/:registroAnvisa'}
                component={TipoRevisaoProdutoDetailContainer}
            />
            <Route
                exact
                path={
                    '/home/relatorios/tipoRevisao/:tipoRevisao/identificador/:identificadorDispositivo/codigo/:referencia'
                }
                component={TipoRevisaoProdutoDetailContainer}
            />

            <Route path='/home/relatorios/usoSistema'>
                <GestorServicoSaudeUsoSistemaDashboard />
            </Route>
            <Route path='/home/buscaProcedimentos'>
                <BuscaProcedimentoRootContainer />
            </Route>
            <Route exact path={'/home/relatorios/listaPacientes'} component={ListaPacientesContainer} />
        </Switch>
    )
}
