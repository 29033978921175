/* tslint:disable */
import React from 'react'

const SvgNotificacaoAlerta = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <g>
            <path d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 21.828c-5.428 0-9.828-4.4-9.828-9.828 0-5.428 4.4-9.828 9.828-9.828 5.425.007 9.821 4.403 9.828 9.828 0 5.428-4.4 9.828-9.828 9.828z' />
            <path d='M12.363 15.348a1.3 1.3 0 011.305 1.358c.032.69-.604 1.294-1.305 1.294a1.346 1.346 0 01-1.336-1.326c0-.722.61-1.326 1.336-1.326zm-.331-9.31a1.343 1.343 0 011.545.747c.09.213.123.425.123.664-.032.663-.09 1.326-.123 1.989-.059 1.024-.123 2.048-.182 3.071-.032.335-.032.637-.032.966a.996.996 0 01-1 .965.976.976 0 01-1-.933c-.09-1.597-.181-3.162-.272-4.759-.032-.419-.059-.843-.091-1.262 0-.695.396-1.268 1.032-1.449z' />
        </g>
    </svg>
)

export default SvgNotificacaoAlerta
