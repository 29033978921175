import { Column, Columns, GrayField, LengthUnitSquareField, NumberField } from 'libs/components'
import React from 'react'
import { Dot } from 'view/componentes/Dot'
import { GrayFieldClearIndicator } from 'libs/components/presentational/form/field/GrayField'

interface DapFieldProps {
    numberValue: string
}

export const DapField = (props: DapFieldProps) => {
    const selectFieldStyle = {
        option: (defaulStyles, { isFocused }) => ({
            ...defaulStyles,
            backgroundColor: isFocused ? '#f2f9ff' : defaulStyles.backgroundColor,
        }),
        clearIndicator: () => ({ marginRight: 3, '&:hover': { cursor: 'pointer' } }),
        singleValue: defaultStyles => ({ ...defaultStyles, color: '#595959' }),
    }
    return (
        <Columns horizontalFlow pulledBottom>
            <Column size='2'>
                <NumberField maxLength={3} label='DAP' name='dap' placeholder='(1-999)' />
            </Column>
            <Column size='3' pulledBottom>
                <GrayField name='primeiraUnidadeDap' disabled={!props.numberValue} required={!!props.numberValue} />
            </Column>
            <Column
                pulledBottom
                customStyle={{ width: 'unset', padding: '0 0 26px 0', margin: '0 -5px' }}
                alignedCenter
                size='1'
            >
                <Dot />
            </Column>
            <Column size='3' pulledBottom>
                <LengthUnitSquareField
                    styles={selectFieldStyle}
                    components={{ ClearIndicator: GrayFieldClearIndicator }}
                    name='segundaUnidadeDap'
                    disabled={!props.numberValue}
                    required={!!props.numberValue}
                />
            </Column>
        </Columns>
    )
}
