import classNames from 'classnames'
import React, { useMemo } from 'react'

interface BadgeProps {
    /**
     * Define se o Badge é visível ou não
     */
    isVisible: boolean
    /**
     * Conteúdo que deve ser mostrado no Badge, se não existir o badge será do tipo dot badge.
     */
    badgeContent?: number
    /**
     * Define o número máximo que deve ser mostrado do badge content
     */
    max?: number
    /**
     * Define em qual posição o Badge deve aparecer
     */
    anchorOrigin?: {
        vertical: 'top' | 'bottom'
        horizontal: 'left' | 'right'
    }
    /**
     * Define qual o formato do elemento em que o Badge irá ficar sobreposto
     */
    overlap: 'rect' | 'circle'
    style?: React.CSSProperties
}

const Badge: React.FC<BadgeProps> = props => {
    let {
        isVisible = true,
        badgeContent,
        max = 90,
        anchorOrigin = {
            vertical: 'top',
            horizontal: 'right',
        },
        overlap,
        style = {},
    } = props

    if (!badgeContent) {
        anchorOrigin = {
            vertical: 'top',
            horizontal: 'right',
        }
    }

    const displayValue = useMemo(() => {
        return badgeContent > max ? `${max}+` : badgeContent
    }, [badgeContent, max])

    return (
        <div className='badge-wrapper'>
            {props.children}
            <span
                style={isVisible ? style : {}}
                className={classNames(
                    'badge',
                    `anchor-origin-${anchorOrigin.vertical}-${anchorOrigin.horizontal}-${overlap}`,
                    {
                        'dot-badge': !badgeContent,
                        invisible: !isVisible,
                    }
                )}
            >
                {badgeContent && displayValue}
            </span>
        </div>
    )
}

export default Badge
