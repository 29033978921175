import * as React from 'react'
import * as C from 'libs/components'
import Auth from 'api/Auth'

export default class LogoutView extends React.Component {
    componentDidMount() {
        Auth.logout()
    }

    render() {
        return <C.LoadingContainer isFetching loadingText='FAZENDO LOGOUT' />
    }
}
