/* tslint:disable */
import React from 'react'

const SvgEmailEnviado = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 128 128' {...props}>
        <g>
            <path d='M64 0c35.29 0 64 28.709 64 64s-28.71 64-64 64S0 99.291 0 64 28.71 0 64 0zm0 1.707C29.652 1.707 1.707 29.652 1.707 64S29.652 126.293 64 126.293 126.293 98.348 126.293 64 98.348 1.707 64 1.707z' />
            <path d='M99.8 42c1.544 0 2.8 1.256 2.8 2.8v38c0 1.544-1.256 2.8-2.8 2.8h-50a2.803 2.803 0 01-2.8-2.8v-38c0-1.544 1.256-2.8 2.8-2.8h50zm0 1.6h-50c-.01 0-.018.005-.026.006L74.8 66.485l25.026-22.879c-.008-.001-.016-.006-.026-.006zM80.204 63.713l20.768 19.229c.006-.049.028-.091.028-.142v-38c0-.029-.014-.054-.016-.083l-20.78 18.996zm-9.623 1.083L49.804 84h49.955l-20.74-19.204-3.68 3.364a.803.803 0 01-1.08 0l-3.678-3.364zM48.6 82.8c0 .04.018.073.022.113l20.774-19.201-20.78-18.995c-.002.029-.016.054-.016.083v38zM39.8 63a.8.8 0 010 1.6h-18a.801.801 0 010-1.6h18zm0 10a.8.8 0 010 1.6h-11a.801.801 0 010-1.6h11zM14 54.6V53h26.6v1.6H14z' />
        </g>
    </svg>
)

export default SvgEmailEnviado
