import * as React from 'react'
import * as C from 'libs/components'
import { FixedSelectProps } from '../elements/FixedSelect'

const meses = [
    { id: 1, nome: 'Janeiro' },
    { id: 2, nome: 'Fevereiro' },
    { id: 3, nome: 'Março' },
    { id: 4, nome: 'Abril' },
    { id: 5, nome: 'Maio' },
    { id: 6, nome: 'Junho' },
    { id: 7, nome: 'Julho' },
    { id: 8, nome: 'Agosto' },
    { id: 9, nome: 'Setembro' },
    { id: 10, nome: 'Outubro' },
    { id: 11, nome: 'Novembro' },
    { id: 12, nome: 'Dezembro' },
]

const options2 = () => {
    const result = []
    let i
    for (i = 0; i <= new Date().getMonth(); i++) {
        result.push(meses[i])
    }
    return result
}

export interface MesSelectProps extends Partial<FixedSelectProps> {
    ano?: number
}

export class MesSelect extends React.Component<MesSelectProps> {
    render() {
        return (
            <C.FixedSelect {...this.props} options={this.props.ano === new Date().getFullYear() ? options2() : meses} />
        )
    }
}
