import { connect } from 'react-redux'
import { ConfirmacaoEmailView } from './ConfirmacaoEmailView'
import { RouteComponentProps } from 'react-router-dom'
import { State } from 'redux/modules'
import * as cadastro from '../../../redux/modules/cadastro'

const mapStateToProps = (state: State, ownProps: RouteComponentProps<any>) => {
    return {
        idCadastro: ownProps.match.params.idCadastro,
        isValid: state.cadastro.isValid,
        onCancel: () => ownProps.history.push('/'),
        onRetomar: () => ownProps.history.push('/public/profissional'),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        validateEmail: (idCadastro: string) => dispatch(cadastro.validarEmail(idCadastro)),
    }
}

const ConfirmacaoEmailContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmacaoEmailView)

export default ConfirmacaoEmailContainer
