
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.52,"time":31200.000000000004,"words":104}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Na tela inicial clique no ícone `}<img parentName="li" {...{
          "src": "/images/iconeMenu.png#center",
          "alt": "..."
        }}></img>{` e depois selecione “Vínculo”`}</li>
      <li parentName="ol">{`Clique em “Adicionar vínculo”`}</li>
      <li parentName="ol">{`Insira a UF`}</li>
      <li parentName="ol">{`Escolha a instituição e o vínculo desejado`}</li>
      <li parentName="ol">{`Informe o registro em conselho de classe e a UF do conselho, caso seja médico ou médico residente`}</li>
      <li parentName="ol">{`Clique no botão “Solicitar vínculo”`}</li>
    </ol>
    <p>{`O formulário é enviado para o gestor de segurança da instituição que analisará a solicitação. Esta é uma medida de segurança para garantir que apenas os funcionários da instituição terão acesso aos dados.`}</p>
    <p>{`Após a aceitação você poderá utilizar o RNI com o novo vínculo.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

