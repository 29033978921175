import * as React from 'react'
import field from 'libs/redux-form/field'
import * as C from 'libs/components'
import { WrappedFieldProps } from 'redux-form'
import { ProdutoDto } from 'backend'

export interface ProdutoFieldProps extends Partial<C.AsyncSelectProps>, C.FormFieldProps, Partial<WrappedFieldProps> {
    maxLength?: number
}

export class ProdutoFieldCmp extends React.Component<ProdutoFieldProps> {
    handleChange = (newValue: C.FixedSelectOption | C.FixedSelectOption[] | null): void => {
        this.props.input.onChange(newValue as any)
    }

    render() {
        const { onChange, ...inputRest } = this.props.input

        return (
            <C.AsyncSelect
                {...inputRest}
                onInputChange={inputValue =>
                    inputValue.length <= this.props.maxLength ? inputValue : inputValue.substr(0, this.props.maxLength)
                }
                onChange={this.handleChange}
                autosize={this.props.autosize}
                className='combo-produto'
                isDisabled={this.props.disabled}
                minimumInput={3}
                pageSize={3}
                getOptionValue={opt => opt.registroAnvisa}
                defaultOptions={inputRest.value?.toJS ? inputRest.value.toJS() : inputRest.value}
                placeholder={this.props.placeholder as any}
                url='/api/produto'
                formatOptionLabel={(data, meta) => {
                    const result = data as ProdutoDto

                    return meta.context === 'menu' ? (
                        <div className='combo-produto-item'>
                            <div className='registro-anvisa'>{result.registroAnvisa}</div>
                            <div className='nome-produto'>
                                <span className='value-title'>Produto:</span> {result.nomeProduto}
                            </div>
                            <div className='nome-fabricante-importador'>
                                <span className='value-title'>Fabricante/Importador:</span>{' '}
                                {result.nomeFabricanteImportador}
                            </div>
                        </div>
                    ) : (
                        <p>{data.registroAnvisa}</p>
                    )
                }}
            />
        )
    }
}

export const ProdutoField = field()(ProdutoFieldCmp)
