import * as React from 'react'
import * as C from 'libs/components'
import { RegiaoEnum } from 'backend'

interface RegiaoOption {
    id: RegiaoEnum
    nome: string
}

const regioes: RegiaoOption[] = [
    { id: 'CENTRO_OESTE', nome: 'Centro-Oeste' },
    { id: 'SUDESTE', nome: 'Sudeste' },
    { id: 'SUL', nome: 'Sul' },
    { id: 'NORDESTE', nome: 'Nordeste' },
    { id: 'NORTE', nome: 'Norte' },
]

export interface RegiaoFieldProps {
    name: string
    placeholder?: string
    label?: string
}

export class RegiaoField extends React.Component<RegiaoFieldProps> {
    render() {
        return <C.FixedSelectField {...this.props} maxLength={20} options={regioes} />
    }
}
