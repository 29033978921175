import { DistribuicaoProcedimentoFiltro, TipoRevisaoRow } from 'backend'
import Report from 'api/Report'
import { promiseReducer, PromiseRecord } from 'redux/middleware/PromiseAction'
import { State } from 'redux/modules/index'
import { Situacao } from 'redux/enums'

export const FIND_TIPO_REVISAO = 'rni/report/revisao/FIND_TIPO_REVISAO'

export interface TipoRevisaoDto extends PromiseRecord<TipoRevisaoRow[]> {
    counts?: { [key in Situacao]: number }
}

export interface TipoRevisaoReportState {
    tipoRevisao: TipoRevisaoDto
}

export const initialState: TipoRevisaoReportState = {
    tipoRevisao: {},
}

export const reducer = (state = initialState, action) => {
    if (action.type === FIND_TIPO_REVISAO) {
        const promiseRecord = promiseReducer(state.tipoRevisao, action)
        return {
            ...state,
            tipoRevisao: reducePorcents(promiseRecord, action.filtro),
        }
    }
    return state
}

export default reducer

const reducePorcents = (promiseRecord: PromiseRecord<TipoRevisaoRow[]>, filtro: DistribuicaoProcedimentoFiltro) => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }
    const tipos = promiseRecord.data

    const sts = Object.keys(Situacao).filter(s => filtro.tipoCirurgia === Situacao[s].procedimento)
    let counts = {}
    sts.forEach(st => (counts[st] = 0))

    if (tipos && tipos.length > 0) {
        if (filtro.tipoCirurgia === 'JOELHO') {
            counts['JOELHO_CONVENCIONAL'] = tipos[0].qtdConvencional
            counts['JOELHO_NAO_CONV'] = tipos[0].qtdNaoConvencional
        } else {
            counts['QUADRIL_TROCA'] = tipos[0].qtdTroca
            counts['QUADRIL_RETIRADA'] = tipos[0].qtdRetirada
        }
    }

    return {
        ...promiseRecord,
        counts,
    }
}

export const loadAll = () => (dispatch, getState) => {
    const state: State = getState()
    const filtro = state.report.filtro.filtro

    dispatch({
        type: FIND_TIPO_REVISAO,
        filtro,
        promise: Report.getTipoRevisao(filtro),
    })
}
