import * as React from 'react'
import { LoadingStateContainer } from 'libs/components'
import { MotivosRevisaoCard } from 'view/home/anvisa/reports/cards/MotivosRevisaoCard'
import { ReportInfoBlock } from 'view/home/anvisa/reports/components/ReportInfoBlock'
import { SumarioMotivoProcedimentoCard } from 'view/home/anvisa/reports/cards/SumarioMotivoProcedimentoCard'
import { QuadroPadraoChartCard } from 'view/home/anvisa/reports/cards/QuadroPadraoChartCard'
import { DistQuadroPadrao } from 'redux/modules/report/motivos'
import {
    DistribuicaoProcedimentoFiltro,
    MotivosProcedimentosRow,
    MotivoProcedimentoEnum,
    MotivosRevisoesRow,
} from 'backend'
import { PromiseRecord } from 'redux/middleware/PromiseAction'

interface ReportMotivosProcedimentoProps {
    filtro: DistribuicaoProcedimentoFiltro
    motivos: PromiseRecord<MotivosProcedimentosRow[]>
    motivosRevisoes: PromiseRecord<MotivosRevisoesRow[]>
    distQuadroPadrao: DistQuadroPadrao
    goToDetail(mot: MotivoProcedimentoEnum): void
    report(): JSX.Element
}

export const ReportMotivosProcedimento = (props: ReportMotivosProcedimentoProps) => {
    const motivos = props.motivos.data
    const total = motivos && motivos.map(motivo => motivo.cont).reduce((t1, t2) => t1 + t2, 0)

    return (
        <LoadingStateContainer readyState={props.motivos.readyState}>
            {props.filtro.filtroCirurgia === 'REVISOES' &&
                props.motivosRevisoes.data &&
                props.motivosRevisoes.data.length > 0 && (
                    <div>
                        <ReportInfoBlock>
                            Lista de motivos de revisões e a quantidade de vezes em que houve repetição do motivo entre
                            duas ou mais revisões para o mesmo paciente.
                        </ReportInfoBlock>

                        <MotivosRevisaoCard
                            motivos={props.motivosRevisoes.data}
                            isFetching={props.motivosRevisoes.readyState === 'request'}
                            report={props.report}
                        />
                    </div>
                )}
                {props.filtro.filtroCirurgia === 'QUADRO_CLINICO_X_PADRAO_OBSTRUTIVO' &&  props.distQuadroPadrao.readyState === 'success' && props.distQuadroPadrao.result.map((item) => {
                    return (
	                    <div>
	                         <QuadroPadraoChartCard item={item}/>
	                    </div>
             		)
                })}
            	{props.filtro.filtroCirurgia !== 'REVISOES' && props.filtro.filtroCirurgia !== 'QUADRO_CLINICO_X_PADRAO_OBSTRUTIVO' &&
                motivos &&
                motivos.map((item, index) => {
                    return (
                        <SumarioMotivoProcedimentoCard
                            motivo={item}
                            key={index}
                            filtro={props.filtro.filtroCirurgia}
                            total={total}
                            onClick={() => {
                                props.goToDetail(item.motivo)
                            }}
                        />
                    )
                })}
            {props.filtro.filtroCirurgia !== 'REVISOES' && (!motivos || motivos.length < 1) && (
                <div className='has-text-centered'>
                    <p className='is-text-muted'>Nenhum motivo encontrado.</p>
                </div>
            )}
            {props.filtro.filtroCirurgia === 'REVISOES' &&
                (!props.motivosRevisoes.data || props.motivosRevisoes.data.length < 1) && (
                    <div className='has-text-centered'>
                        <p className='is-text-muted'>Nenhum motivo encontrado.</p>
                    </div>
                )}
        </LoadingStateContainer>
    )
}
