import * as React from 'react'
import * as C from 'libs/components'

interface NeurologicaProps {
    disabled?: boolean
}

export class NeurologicaCard extends React.Component<NeurologicaProps> {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Neurológica'
                name='neurologica'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='neurologica' disabled={this.props.disabled} />}
            >
                <C.Conteudo>
                    <C.ControlField label='Tipo AVE' name='neurologicaTipoAve' verticalFlow>
                        <C.ClearableRadioField name='neurologicaTipoAve' label='Isquêmico' value='ISQUEMICO' />
                        <C.ClearableRadioField name='neurologicaTipoAve' label='Hemorrágico' value='HEMORRAGICO' />
                    </C.ControlField>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
