/* tslint:disable */
import React from 'react'

const SvgEmailConfirmado = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 294 294' {...props}>
        <g>
            <g opacity={0.303} transform='translate(31 12)'>
                <circle cx={97.237} cy={273.84} r={3.018} />
                <path d='M34.819 85.373l-1.959-1.78 1.78-1.96a.89.89 0 10-1.272-1.165l-1.78 1.95-1.95-1.781a.89.89 0 10-1.175 1.264l1.958 1.78-1.78 1.959a.89.89 0 001.273 1.166l1.78-1.95 1.95 1.78a.89.89 0 101.175-1.263zm203.309-22.225l-1.959-1.78 1.78-1.959a.89.89 0 00-1.264-1.192l-1.78 1.95-1.95-1.781a.89.89 0 10-1.175 1.264l1.959 1.78-1.78 1.959a.89.89 0 001.272 1.166l1.78-1.95 1.95 1.78a.89.89 0 101.175-1.263l-.008.026zM14.181 13.362a5.181 5.181 0 110-10.362 5.181 5.181 0 010 10.362zm0-8.626a3.454 3.454 0 100 6.908 3.454 3.454 0 000-6.908zm206.7 244.027a3.881 3.881 0 113.882-3.882 3.89 3.89 0 01-3.882 3.882zm0-6.036a2.154 2.154 0 102.155 2.154 2.163 2.163 0 00-2.155-2.163v.009zm-217 2.036a3.881 3.881 0 113.882-3.882 3.89 3.89 0 01-3.882 3.882zm0-6.036a2.154 2.154 0 102.155 2.154 2.163 2.163 0 00-2.155-2.154z' />
                <circle cx={254.831} cy={145.169} r={1.727} />
                <circle cx={174.018} cy={3.018} r={3.018} />
            </g>
            <path d='M144.504 185.985c1.44 1.063 3.82 1.063 5.323 0l89.543-66.314-82.968-61.31c-5.135-3.753-13.463-3.753-18.598 0l-82.906 61.372 89.606 66.252z' />
            <path d='M214.887 203.69H79.382a3.766 3.766 0 01-3.757-3.754V70.748a3.766 3.766 0 013.757-3.753h135.567a3.766 3.766 0 013.757 3.753v129.188c-.062 2.064-1.69 3.753-3.82 3.753z' />
            <path d='M239.433 119.67l-89.606 66.315c-1.44 1.063-3.82 1.063-5.323 0L54.898 119.67c-1.064.688-1.753 1.814-1.753 3.19v111.733a3.766 3.766 0 003.757 3.754h180.59a3.766 3.766 0 003.757-3.754V122.861c-.063-1.376-.814-2.502-1.816-3.19z' />
            <path d='M164.104 175.412l-14.277 10.573c-1.44 1.063-3.82 1.063-5.323 0l-14.652-10.823-75.079 62.56c.501.313 1.065.563 1.69.626h180.403c.814 0 1.503-.188 2.13-.563l-74.891-62.373z' />
            <path d='M56.84 238.348h180.589c.814 0 1.503-.188 2.129-.5l-83.156-61.498c-5.135-3.753-13.463-3.753-18.598 0L54.71 237.847c.627.313 1.378.5 2.13.5z' />
            <path d='M147.76 98.838c15.154 0 27.49 12.324 27.49 27.464 0 15.14-12.336 27.464-27.49 27.464-15.153 0-27.489-12.324-27.489-27.464 0-15.14 12.336-27.464 27.49-27.464zm0 2.502c-13.776 0-24.984 11.199-24.984 24.962s11.208 24.962 24.984 24.962c13.776 0 24.985-11.199 24.985-24.962s-11.209-24.962-24.985-24.962zm13.338 16.641c.5.5.5 1.252 0 1.752L144.316 136.5c-.187.25-.5.376-.876.376-.313 0-.626-.125-.877-.376l-7.577-7.57c-.5-.5-.5-1.25 0-1.751.501-.5 1.253-.5 1.754 0l6.7 6.694 15.905-15.89a1.21 1.21 0 011.753 0z' />
        </g>
    </svg>
)

export default SvgEmailConfirmado
