import React from 'react'
import * as C from 'libs/components'
import { StentCoronariaComplementarVisualizacaoDto } from 'backend'

interface MedicacaoProcedimentoCardProps {
    dadosComplementares: StentCoronariaComplementarVisualizacaoDto
}

export class MedicacaoProcedimentoCard extends React.Component<MedicacaoProcedimentoCardProps> {
    render() {
        const data = this.props.dadosComplementares
        const CapitalizeFirstLetter = function(str) {
            return str.charAt(0).toUpperCase() + str.slice(1).replace('.', '')
        }

        return (
            <div>
                <C.Columns auto>
                    <C.ComposedLabel title={'Antiplaquetário'}>
                        {data.antiplaquetario && CapitalizeFirstLetter(data.antiplaquetario)}
                    </C.ComposedLabel>
                </C.Columns>
                <C.Columns auto>
                    <C.ComposedLabel title={'Anticoagulação'}>{data.anticoagulacaoStr}</C.ComposedLabel>
                </C.Columns>
                <C.Columns auto>
                    <C.ComposedLabel title={'Inibidor GP IIb/IIIa'}>
                        {data.inibidor && CapitalizeFirstLetter(data.inibidor.toLowerCase())}
                    </C.ComposedLabel>
                </C.Columns>
            </div>
        )
    }
}
