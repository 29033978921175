import { connect } from 'react-redux'
import * as alert from 'redux/modules/alert'
import { ManualUsuarioView } from 'view/home/manualUsuario/ManualUsuarioView'
import { State } from 'redux/modules/index'
import { RouteComponentProps } from 'react-router-dom'
import { TodosTopicos } from './TopicoManual'
import * as info from '../../../redux/modules/info'

const mapStateToProps = (state: State, ownProps: RouteComponentProps<any>) => {
    const paiUrl = ownProps.match.params.idPai
    const filhoUrl = ownProps.match.params.idFilho
    const btnUrl = ownProps.match.params.btn
    const homePath = paiUrl === 'inicio' ? 'home.md' : undefined
    const faqPath = paiUrl === 'faq' ? 'faq.md' : undefined
    const homeOrPublic = ownProps.match.url.match('/public/manualUsuario') ? '/public' : '/home'

    let pai = paiUrl && TodosTopicos.find(topico => topico.path === paiUrl)
    const filho = pai && filhoUrl && pai.subTopicos.find(topico => topico.path === filhoUrl)

    let botao
    let arquivo

    if (filho) {
        let keyBotao = filho.botaoManual && Object.keys(filho.botaoManual)
        if (btnUrl) {
            botao = filho.botaoManual[btnUrl] && btnUrl
        } else {
            botao = keyBotao && keyBotao[0]
        }
        arquivo = botao ? filho.botaoManual[botao] : filho.arquivoPath
    }

    let titulo = filho && filho.titulo

    if (homePath) {
        arquivo = homePath
        titulo = 'Central de ajuda RNI'
    } else if (faqPath) {
        arquivo = faqPath
        titulo = 'FAQ'
    }
    return {
        arquivoPath: arquivo,
        titulo: titulo,
        todosTopicos: TodosTopicos,
        subtopicoSelecionado: filho,
        topicoSelecionado: pai,
        botaoSelecionado: botao,
        homeOrPublic: homeOrPublic,
        versao: state.info.versao && state.info.versao.data,
        perfil: state.auth.user && state.auth.user.perfil,
        paiUrl: paiUrl,
        filhoUrl: filhoUrl,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getVersao: () => {
            dispatch(info.getVersao())
        },
    }
}

const ManualUsuarioContainer = connect(
    mapStateToProps,
    alert.compose(mapDispatchToProps)
)(ManualUsuarioView)

export default ManualUsuarioContainer
