import React from 'react'
import * as C from 'libs/components'

const coronariaDestino = [
    { id: 'ENXERTO_CD', nome: 'CD - Coronária Direita', abrev: 'CD' },
    { id: 'ENXERTO_DPD', nome: 'DPD - Descendente Posterior da Coronária Direita', abrev: 'DPD' },
    { id: 'ENXERTO_VPD', nome: 'VPD - Ventricular Posterior da Coronária Direita', abrev: 'VPD' },
    { id: 'ENXERTO_MGD', nome: 'MgD - Marginal da Coronária Direita', abrev: 'Mgd' },
    { id: 'ENXERTO_DA', nome: 'DA - Descendente Anterior', abrev: 'DA' },
    { id: 'ENXERTO_DG1', nome: 'Dg1 - Primeiro Ramo Diagonal', abrev: 'Dg1' },
    { id: 'ENXERTO_DG2', nome: 'Dg2 - Segundo Ramo Diagonal', abrev: 'Dg2' },
    { id: 'ENXERTO_DG3', nome: 'Dg3 - Terceiro Ramo Diagonal', abrev: 'Dg3' },
    { id: 'ENXERTO_DG4', nome: 'Dg4 - Quarto Ramo Diagonal', abrev: 'Dg4' },
    { id: 'ENXERTO_CX', nome: 'Cx - Circunflexa', abrev: 'Cx' },
    { id: 'ENXERTO_MG1', nome: 'Mg1 - Primeiro Ramo Marginal', abrev: 'Mg1' },
    { id: 'ENXERTO_MG2', nome: 'Mg2 - Segundo Ramo Marginal', abrev: 'Mg2' },
    { id: 'ENXERTO_MG3', nome: 'Mg3 - Terceiro Ramo Marginal', abrev: 'Mg3' },
    { id: 'ENXERTO_MG4', nome: 'Mg4 - Quarto Ramo Marginal', abrev: 'Mg4' },
    { id: 'ENXERTO_VPE', nome: 'VPE - Ventricular Posterior da Circunflexa', abrev: 'VPE' },
    { id: 'ENXERTO_DPE', nome: 'DPE - Descendente Posterior da Circunflexa', abrev: 'DPE' },
    { id: 'ENXERTO_DIAGONALIS', nome: 'Diagonalis', abrev: 'Diagonalis' },
    { id: 'ENXERTO_MARGINALIS', nome: 'Marginalis', abrev: 'Marginalis' },
    { id: 'ENXERTO_INTERMEDIO', nome: 'Intermédio', abrev: 'Intermédio' },
]

export class CoronariaDestinoField extends React.Component {
    render() {
        return <C.FixedSelectField {...this.props} options={coronariaDestino} />
    }
}
