import { connect } from 'react-redux'
import Usuario from 'api/Usuario'
import AlterarSenhaView from 'view/home/alterarSenha/AlterarSenhaView'

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        afterSave: () => ownProps.history.push('/home'),
        cancelar: () => ownProps.history.goBack(),
        save: form => Usuario.alterarSenha(form.toJS()),
    }
}

const AlterarSenhaContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AlterarSenhaView)

export default AlterarSenhaContainer
