import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import HomeConteudo from 'view/componentes/HomeConteudo'
import { TermoUsoDTO, UserDTO } from 'backend'
import { AlertModalInfoTimeOut, Button, Column, Columns, LoadingContainer } from 'libs/components'

interface TermoUsoViewProps {
    carregarAceiteTermoUso: () => Promise<any>
    carregarTermoUsoVigente: () => Promise<any>
    aceitarTermoUso: () => Promise<any>
    termoUso: TermoUsoDTO
    user: UserDTO
    isFetchingTermoUso: 'success' | 'failure' | 'request'
}

const TermoUsoView: React.FC<RouteComponentProps<any> & TermoUsoViewProps> = props => {
    useEffect(() => {
        const { carregarTermoUsoVigente, carregarAceiteTermoUso } = props
        carregarTermoUsoVigente()
        carregarAceiteTermoUso()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [showModalSuccessRecusar, setShowModalSuccessRecusar] = useState<boolean>(false)
    const [logoutOnRecusar, setLogoutOnRecusar] = useState<boolean>(false)

    const recusarTermoUso = () => {
        setShowModalSuccessRecusar(true)
        setLogoutOnRecusar(true)
    }

    const handleRecusar = () => {
        if (logoutOnRecusar) {
            props.history.push('/logout')
        } else {
            setShowModalSuccessRecusar(false)
        }
    }
    let texto = ''
    if (props.isFetchingTermoUso === 'success') {
        texto = props.termoUso.texto
    }

    return (
        <LoadingContainer isFetching={props.isFetchingTermoUso !== 'success'} loadingText='CARREGANDO TERMO DE USO'>
            <HomeConteudo className='termo-uso-page'>
                <div className='termo-uso-header'>
                    <div className='container'>
                        <div className='has-text-centered'>
                            <div className='bem-vindo'>Olá, {props.user.nome}</div>
                            <br />
                            <div className='instrucoes'>
                                Você precisa aceitar os termos de uso da aplicação para continuar a usá-la.
                            </div>
                            <div className='instrucoes'>TERMO DE USO</div>
                        </div>
                    </div>
                </div>
                <div className='termo-uso-conteudo container is-vertical-flow is-full-height'>
                    <Columns fullHeight>
                        <Column size='8' offset='2' verticalFlow>
                            <ul>
                                <li className={'termo-uso-row'}>
                                    <div className={'termo-uso-inner-row is-horizontal-flow'}>
                                        <span className='infos is-vertical-flow'>
                                            <span dangerouslySetInnerHTML={{ __html: texto }} />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Column>
                    </Columns>
                </div>

                <div className='termo-uso-footer'>
                    <div className='container'>
                        <div className='has-text-centered'>
                            <Button type='success' size='medium' onClick={props.aceitarTermoUso}>
                                Aceito
                            </Button>
                            <Button type='danger' size='medium' onClick={recusarTermoUso}>
                                Recuso
                            </Button>
                        </div>
                    </div>
                </div>

                <AlertModalInfoTimeOut
                    title='Termo recusado'
                    active={showModalSuccessRecusar}
                    onClose={handleRecusar}
                    ms={5000}
                >
                    Ao Recusar o termo de uso, você será redirecionado para a tela inicial do RNI.
                </AlertModalInfoTimeOut>
            </HomeConteudo>
        </LoadingContainer>
    )
}

export default TermoUsoView
