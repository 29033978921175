import * as React from 'react'
import classnames from 'classnames'

interface AlertaRowProps {
    className?: string
    onClick?()
}

export default class AlertaRow extends React.PureComponent<AlertaRowProps> {
    render() {
        const classes = classnames('alerta-row', this.props.className)
        return (
            <div className={classes} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        )
    }
}
