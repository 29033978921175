
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"3 min read","minutes":2.415,"time":144900,"words":483}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Aguardando dados clínicos” e no procedimento desejado clique no botão `}<strong parentName="li">{`“Preencher”`}</strong>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` apenas profissionais com perfil de “Médico Ortopedista” e “Residente em ortopedia” que partiparam da equipe médica do procedimento, podem registrar dados clínicos de procedimentos de Artroplastia.`}</p>
    </blockquote>
    <p><strong parentName="p">{`MOTIVO DA REVISÃO`}</strong></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Informe o(s) motivo(s) da revisão`}</li>
      <li parentName="ol">{`Caso tenha como motivo “Infecção” informe se foi precoce ou crônica`}</li>
    </ol>
    <p><strong parentName="p">{`PROCEDIMENTO CIRÚRGICO ATUAL`}</strong></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Informe a data da cirurgia e a duração em horas`}</li>
      <li parentName="ol">{`Informe qual foi a abordagem cirúrgica`}</li>
      <li parentName="ol">{`Informe se houve uso de dreno`}</li>
      <li parentName="ol">{`Informe se houve osteotomia femoral`}</li>
      <li parentName="ol">{`Informe se houve exame anatomopatológico`}</li>
      <li parentName="ol">{`Informe se houve transfusão sanguínea`}</li>
      <li parentName="ol">{`Caso tenha sido feita transfusão sanguínea informe se foi autóloga ou homóloga`}</li>
      <li parentName="ol">{`Informe se houve cultura durante o ato cirúrgico`}</li>
      <li parentName="ol">{`Caso tenha sido feita cultura informe quantas`}</li>
      <li parentName="ol">{`Informe se foi utilizado antibiótico`}</li>
      <li parentName="ol">{`Caso tenha sido utilizado antibiótico informe qual o tipo de antibiótico e o período`}</li>
    </ol>
    <p><strong parentName="p">{`TIPO DE REVISÃO`}</strong></p>
    <ol {...{
      "start": 16
    }}>
      <li parentName="ol">{`Informe o tipo de revisão ou se houve retirada`}</li>
    </ol>
    <p><strong parentName="p">{`TÉCNICA OPERATÓRIA`}</strong></p>
    <ol {...{
      "start": 17
    }}>
      <li parentName="ol">
        <p parentName="li">{`Informe a técnica utilizada`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Caso a técnica utilizada for no "Femoral":`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Informe se foi utilizado tela de suporte`}</li>
          <li parentName="ul">{`Informe se foi utilizado centralizador`}</li>
          <li parentName="ul">{`Informe se foi cimentado`}</li>
          <li parentName="ul">{`Caso foi cimentado informe se foi utilizado restritor de cimento`}</li>
          <li parentName="ul">{`Informe qual o tipo de prótese`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Caso a técnica utilizada for no "Acetabular":`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Informe se foi utilizado tela de suporte`}</li>
          <li parentName="ul">{`Informe se foi utilizado anel de reforço`}</li>
          <li parentName="ul">{`Informe se foi utilizado metal trabeculado`}</li>
          <li parentName="ul">{`Informe qual o par tribológico`}</li>
          <li parentName="ul">{`Informe se foi cimentado`}</li>
        </ul>
      </li>
    </ol>
    <p><strong parentName="p">{`CIMENTO ÓSSEO`}</strong></p>
    <ol {...{
      "start": 20
    }}>
      <li parentName="ol">{`Caso informado que foi utilizado cimento em algum dos implantes:`}<ul parentName="li">
          <li parentName="ul">{`Informe o tipo de cimento`}</li>
          <li parentName="ul">{`Informe a técnica de cimentação utilizada`}</li>
          <li parentName="ul">{`Informe se houve lavagem pulsátil`}</li>
        </ul></li>
    </ol>
    <p><strong parentName="p">{`ENXERTIA`}</strong></p>
    <ol {...{
      "start": 21
    }}>
      <li parentName="ol">{`Caso tenha sido realizado implante no femoral informe qual o enxerto utilizado`}</li>
      <li parentName="ol">{`Caso tenha sido realizado implante no acetabular informe qual o enxerto utilizado`}</li>
    </ol>
    <p><strong parentName="p">{` COMPONENTES UTILIZADOS`}</strong></p>
    <ol {...{
      "start": 23
    }}>
      <li parentName="ol">{`Informe quais componentes foram implantados no paciente`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Caso o componente não seja apresentado, verifique se o tipo de artroplastia foi selecionado corretamente`}</p>
    </blockquote>
    <ol {...{
      "start": 24
    }}>
      <li parentName="ol">{`Informe também a quantidade de cada componente utilizado`}</li>
    </ol>
    <p>{`Para remover um componente da lista clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeRecusar.png#center",
        "alt": "Remover"
      }}></img>{` ao lado do componente desejado`}</p>
    <ol {...{
      "start": 25
    }}>
      <li parentName="ol">{`Informe quais componentes complementares foram utilizados `}</li>
      <li parentName="ol">{`Informe também a quantidade de cada componente complementar utilizado`}</li>
    </ol>
    <p>{`Para remover um componente complementar da lista clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeRecusar.png#center",
        "alt": "Remover"
      }}></img>{` ao lado do componente desejado`}</p>
    <ol {...{
      "start": 27
    }}>
      <li parentName="ol">{`Clique no botão “Salvar registro” para registrar o procedimento`}</li>
    </ol>
    <p>{`Após o registro dos dados clínicos este procedimento fica disponível para preenchimento dos dados de produto, ou seja, os dados dos componentes implantados no paciente.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona cada etapa do preenchimento do registro, `}<a parentName="p" {...{
          "href": "../fluxos/fluxoPreenchimentoRegistros"
        }}><strong parentName="a">{`clique aqui`}</strong></a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona o preenchimento de dados clínicos de artroplastia, consulte o vídeo explicativo abaixo:`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

