import * as React from 'react'
import { DadosProdutoVisualizacaoDto } from 'backend'
import * as C from 'libs/components'
import Text from 'libs/components/presentational/typography/Text'
import * as DateUtil from 'libs/util/DateUtil'

interface DadosProdutoProps {
    dadosProduto: DadosProdutoVisualizacaoDto[]
    isAdm: boolean
}

export class DadosProduto extends React.Component<DadosProdutoProps> {
    render() {
        const dadosProduto = this.props.dadosProduto
        const mesmoPreenchedor =
            dadosProduto[0].nomeResponsavelPreenchimento === dadosProduto[0].nomeResponsavelPreenchimentoFinanceiro
        return (
            <C.Conteudo>
                {mesmoPreenchedor && (
                    <div>
                        <Text color='grey-darker' size='small-ter'>
                            Dados de produto e financeiro preenchidos por{' '}
                        </Text>
                        <Text color='grey-darker' size='small-ter' weight='bold'>
                            {dadosProduto[0].nomeResponsavelPreenchimento}{' '}
                        </Text>
                        <Text color='grey-darker' size='small-ter'>
                            em{' '}
                        </Text>
                        <Text color='grey-darker' size='small-ter' weight='bold'>
                            {DateUtil.asClientFormat(dadosProduto[0].dataPreenchimento)}{' '}
                        </Text>
                        <Text color='grey-darker' size='small-ter'>
                            ({dadosProduto[0].perfilResponsavelPreenchimento}){' '}
                        </Text>
                    </div>
                )}
                {!mesmoPreenchedor && (
                    <div>
                        <Text color='grey-darker' size='small-ter'>
                            Dados de produto preenchidos por{' '}
                        </Text>
                        <Text color='grey-darker' size='small-ter' weight='bold'>
                            {dadosProduto[0].nomeResponsavelPreenchimento}{' '}
                        </Text>
                        <Text color='grey-darker' size='small-ter'>
                            em{' '}
                        </Text>
                        <Text color='grey-darker' size='small-ter' weight='bold'>
                            {DateUtil.asClientFormat(dadosProduto[0].dataPreenchimento)}{' '}
                        </Text>
                        <Text color='grey-darker' size='small-ter'>
                            ({dadosProduto[0].perfilResponsavelPreenchimento}){' '}
                        </Text>
                        {dadosProduto[0].nomeResponsavelPreenchimentoFinanceiro && (
                            <div>
                                <Text color='grey-darker' size='small-ter'>
                                    Dados financeiros preenchidos por{' '}
                                </Text>
                                <Text color='grey-darker' size='small-ter' weight='bold'>
                                    {dadosProduto[0].nomeResponsavelPreenchimentoFinanceiro}{' '}
                                </Text>
                                <Text color='grey-darker' size='small-ter'>
                                    em{' '}
                                </Text>
                                <Text color='grey-darker' size='small-ter' weight='bold'>
                                    {DateUtil.asClientFormat(dadosProduto[0].dataPreenchimentoFinanceiro)}{' '}
                                </Text>
                                <Text color='grey-darker' size='small-ter'>
                                    ({dadosProduto[0].perfilResponsavelPreenchimentoFinanceiro}){' '}
                                </Text>
                            </div>
                        )}
                    </div>
                )}
                <div style={{ height: 10 }} />
                {dadosProduto.map((dado, index) => (
                    <div key={index} className='with-full-border'>
                        <C.Columns auto>
                            <C.TextLabel title={dado.nomeVisualizacaoProduto} value={dado.nomeProdutoExibicao}>
                                {' '}
                            </C.TextLabel>
                        </C.Columns>
                        <C.Columns auto>
                            <C.TextLabel
                                title='Registro ANVISA'
                                value={dado.registroAnvisa}
                                placeholder='Etiqueta sem registro da ANVISA'
                            />
                        </C.Columns>
                        {dado.registroAnvisa && (
                            <C.Columns auto>
                                <C.ComposedLabel title='Fabricante(s):' name='fabricante' placeholder='Não cadastrado'>
                                    {dado.fabricantes && dado.fabricantes.length > 0 && (
                                        <ul className='list-circle'>
                                            {dado.fabricantes.map((data, index) => (
                                                <li key={index}> {data.fabricante}</li>
                                            ))}
                                        </ul>
                                    )}
                                </C.ComposedLabel>
                            </C.Columns>
                        )}
                        <C.Columns auto>
                            {!dado.registroAnvisa ? (
                                <C.MaskedLabel
                                    title='CNPJ do Fabricante/Importador'
                                    value={dado.cnpj}
                                    mask='##.###.###/####-##'
                                    placeholder='Não informado'
                                />
                            ) : (
                                <C.MaskedLabel
                                    title='CNPJ do Detentor do registro'
                                    value={dado.cnpj}
                                    mask='##.###.###/####-##'
                                    placeholder='Não informado'
                                />
                            )}
                        </C.Columns>
                        <C.Columns auto>
                            {!dado.registroAnvisa ? (
                                <C.TextLabel
                                    title='Razão social do Fabricante/Importador'
                                    value={dado.fabricanteImportador}
                                />
                            ) : (
                                <C.TextLabel
                                    title='Razão social do Detentor do registro'
                                    value={dado.fabricanteImportador}
                                />
                            )}
                        </C.Columns>
                        {dado.registroAnvisa && (
                            <C.Columns auto>
                                <C.TextLabel
                                    title='Nome técnico do produto'
                                    value={dado.nomeTecnicoProduto}
                                    placeholder='Não informado'
                                />
                            </C.Columns>
                        )}
                        {dado.identificadorDispositivo && (
                            <C.Columns auto>
                                <C.TextLabel
                                    title='Identificador do dispositivo'
                                    value={dado.identificadorDispositivo}
                                />
                            </C.Columns>
                        )}
                        {dado.codigoReferencia && (
                            <C.Columns auto>
                                <C.TextLabel title='Código de referência' value={dado.codigoReferencia} />
                            </C.Columns>
                        )}
                        {dado.lote && (
                            <C.Columns auto>
                                <C.TextLabel title='Lote' value={dado.lote} />
                            </C.Columns>
                        )}
                        {dado.numeroSerie && (
                            <C.Columns auto>
                                <C.TextLabel title='Número de série' value={dado.numeroSerie} />
                            </C.Columns>
                        )}
                        <C.Columns auto>
                            <C.TextLabel title='Data de fabricação' value={dado.dataFabricacao} />
                            <C.TextLabel title='Validade' value={dado.dataValidade} />
                        </C.Columns>
                        {dado.arquivoId && (
                            <div>
                                <C.Label>Foto da etiqueta</C.Label>
                                <div className='with-full-border'>
                                    <C.LazyImage src={'/api/arquivo/imagem/' + dado.arquivoId} />
                                </div>
                            </div>
                        )}

                        {dado.compradoHospital ? (
                            <div>
                                <C.Columns auto>
                                    <C.Label>Dados financeiros do produto</C.Label>
                                </C.Columns>
                                <div className='with-border-left'>
                                    <C.Columns auto>
                                        <div className='composed-label'> {dado.origenComponente} </div>
                                    </C.Columns>
                                    <C.Columns auto>
                                        {dado.ressarcimento && (
                                            <C.TextLabel
                                                title='Ente responsável pelo ressarcimento'
                                                value={dado.ressarcimento}
                                            />
                                        )}
                                    </C.Columns>

                                    {(dado.precoFornecedor || dado.precoCobrado) && (
                                        <C.Columns>
                                            <C.Column>
                                                <C.CurrencyLabel
                                                    number={dado.precoFornecedor}
                                                    title={'Preço pago ao fornecedor'}
                                                />
                                            </C.Column>
                                            <C.Column>
                                                <C.CurrencyLabel
                                                    number={dado.precoCobrado}
                                                    title={'Preço cobrado no ressarcimento'}
                                                />
                                            </C.Column>
                                        </C.Columns>
                                    )}
                                </div>
                            </div>
                        ) : this.props.isAdm ? (
                            <C.TextLabel
                                title='Dados financeiros do produto'
                                placeholder='Aguardando preenchimento'
                                value=''
                            />
                        ) : (
                            <div />
                        )}
                    </div>
                ))}
            </C.Conteudo>
        )
    }
}
