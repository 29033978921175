import { Card, LoadingStateContainer, NumberLabel } from 'libs/components'
import * as React from 'react'
import { InvertedLabel } from 'view/componentes/reports/components/InvertedLabel'

interface Props {
    data: number
    readyState: 'request' | 'success' | 'failure'
}

export class TotalHospitaisCard extends React.Component<Props> {
    render() {
        return (
            <Card title={'Total de hospitais'} contentClassName='is-aligned-center'>
                <LoadingStateContainer readyState={this.props.readyState}>
                    <InvertedLabel title='Hospitais que realizaram registros'>
                        <NumberLabel value={this.props.data} placeholder='-' />
                    </InvertedLabel>
                </LoadingStateContainer>
            </Card>
        )
    }
}
