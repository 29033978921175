/* tslint:disable */
import React from 'react'

const SvgInfoProduto = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
        <path d='M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm-.41-18.286a1.02 1.02 0 100-2.038 1.02 1.02 0 000 2.038zm0 1.715a1.02 1.02 0 00-1.019 1.019v6.795a1.02 1.02 0 102.039 0v-6.795a1.02 1.02 0 00-1.02-1.02z' />
    </svg>
)

export default SvgInfoProduto
