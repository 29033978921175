import React from 'react'
import { RecebimentoIntegracaoDto } from 'backend'
import { Label, Columns, Linha, Column } from 'libs/components'
import {
    viewTextByEnum,
    iconNameByEnum,
    dataTypeByEnum,
} from 'view/home/gestorIntegracao/common/handleRecebimentoEnums'
import { IntegracaoRowIconColumn } from '../../common/IntegracaoRowIconColumn'
import { IntegracaoRowItemHeader } from '../../common/IntegracaoRowItemHeader'

interface RecebimentoIntegracaoInnerRowRecebidoProps {
    recebidos: RecebimentoIntegracaoDto[]
}

export const RecebimentoIntegracaoInnerRowRecebido = (props: RecebimentoIntegracaoInnerRowRecebidoProps) => {
    const { recebidos } = props

    const firstItem: RecebimentoIntegracaoDto = recebidos[0]
    let titleHeader: string = viewTextByEnum(firstItem.tipoProcedimento)
    if (firstItem.tipoProcedimento !== 'STENT_CORONARIA') {
        titleHeader += ' ' + firstItem.lado
    }

    return (
        <div className='recebidos-inner-row'>
            <IntegracaoRowItemHeader title={titleHeader} subtitle={'Paciente ' + firstItem.nomePaciente} />
            <Columns className='recebidos-inner-row-content'>
                {recebidos.map((recebido: RecebimentoIntegracaoDto, index: number, arr: RecebimentoIntegracaoDto[]) => (
                    <div key={recebido.id}>
                        <div className='recebidos-inner-row-content-item'>
                            <IntegracaoRowIconColumn
                                status='success'
                                icon={iconNameByEnum(recebido.tipoRecebimento)}
                                iconSize='medium'
                            />
                            <Column className='recebidos-inner-row-content-item-labels'>
                                <div className='recebimento-integracao-first-line-labels'>
                                    <Label className='recebimento-integracao-label-tipo-recebimento'>
                                        {dataTypeByEnum(recebido.tipoRecebimento)}
                                    </Label>
                                    <Label className='recebimento-integracao-label-sistema'>
                                        Enviado por <span className='is-bold'>{recebido.nomeSistema}</span>
                                    </Label>
                                </div>
                                <Label className='recebimento-integracao-label-mid-text'>Realizado por</Label>
                                <Label className='label-nome-profissional'>{recebido.nomeProfissional}</Label>
                            </Column>
                        </div>
                        {arr.length !== index + 1 && <Linha className='recebidos-inner-row-content-linha' />}
                    </div>
                ))}
            </Columns>
        </div>
    )
}
