/* tslint:disable */
import React from 'react'

const SvgRemover = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M17.652 5.209c.727 0 1.32.586 1.348 1.335v1.093c0 .573-.385 1.065-.93 1.24v10.458c0 .923-.75 1.665-1.68 1.665H7.61c-.93 0-1.68-.742-1.68-1.665V8.863A1.287 1.287 0 015 7.617V6.523c0-.725.595-1.314 1.326-1.314h2.872A2.835 2.835 0 0111.966 3c1.336 0 2.467.93 2.765 2.209h2.92zm-5.686-.913c-.62 0-1.167.372-1.412.913h2.813a1.5 1.5 0 00-1.4-.913zm4.76 4.657H7.21V19.36c0 .205.169.368.376.368h8.783a.375.375 0 00.356-.248V8.953zm.925-2.43c0-.018 0-.02-.024-.02H6.324c-.023 0-.025.002-.025.02v1.094c0 .018.002.02.025.02H17.65l.001-1.114zm-9.09 11.349v-6.909c0-.354.286-.636.64-.636.354 0 .64.282.64.636v6.909a.628.628 0 01-.614.634c-.365.04-.665-.27-.665-.634zm2.809 0v-6.909c0-.354.285-.636.64-.636.353 0 .639.282.639.636v6.909a.628.628 0 01-.613.634c-.364.04-.666-.27-.666-.634zm2.81 0v-6.909c0-.354.285-.636.64-.636.353 0 .639.282.639.636v6.909c0 .353-.286.636-.61.634-.35.042-.67-.275-.67-.634z' />
    </svg>
)

export default SvgRemover
