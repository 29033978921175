/* tslint:disable */
import React from 'react'

const SvgProfissionaishospital = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 36 36' {...props}>
        <path d='M24.197 20.328a7.054 7.054 0 017.046 7.046 1.84 1.84 0 01-1.839 1.838h-4.47c.034.33.052.663.052 1a1.78 1.78 0 01-1.778 1.78H5.778A1.78 1.78 0 014 30.211c0-5.467 4.449-9.916 9.917-9.916h1.152c1.37 0 2.677.28 3.866.786.944-.475 2-.754 3.124-.754h2.138zm5.207 7.795a.75.75 0 00.75-.749 5.963 5.963 0 00-5.957-5.957H22.06c-.658 0-1.29.113-1.881.312a9.929 9.929 0 014.581 6.394h4.645zm-6.196 2.778a.69.69 0 00.689-.688c0-3.387-1.92-6.33-4.726-7.81v3.058c.742.234 1.286.92 1.286 1.739 0 1.01-.822 1.832-1.831 1.832a1.833 1.833 0 01-1.832-1.832c0-.819.544-1.505 1.287-1.739v-3.535a8.763 8.763 0 00-3.012-.54h-1.152c-.764 0-1.501.107-2.209.29l.005 2.296c1.023.224 1.83 1.077 2.078 2.163a1.222 1.222 0 01-.47 2.352 1.22 1.22 0 11-.632-2.266c-.224-.708-.816-1.22-1.517-1.22-.703 0-1.297.515-1.519 1.225.348.216.582.598.582 1.038a1.224 1.224 0 11-1.682-1.133c.25-1.082 1.052-1.931 2.07-2.158l-.003-1.942c-3.238 1.309-5.53 4.479-5.53 8.182 0 .38.31.688.689.688h17.43zm-4.582-4.443a.743.743 0 000 1.484.743.743 0 000-1.484zm-4.133-7.202c-3.997 0-7.25-3.253-7.25-7.25s3.253-7.25 7.25-7.25c2.793 0 5.218 1.591 6.428 3.911a5.597 5.597 0 012.207-.457 5.653 5.653 0 015.647 5.647 5.653 5.653 0 01-5.647 5.646 5.621 5.621 0 01-4.137-1.822 7.213 7.213 0 01-4.498 1.575zm0-13.41a6.168 6.168 0 00-6.16 6.16 6.168 6.168 0 006.16 6.161 6.168 6.168 0 006.16-6.16 6.167 6.167 0 00-6.16-6.16zM23.128 9.3a4.5 4.5 0 00-1.78.368 7.2 7.2 0 01.395 2.34 7.215 7.215 0 01-1.957 4.937 4.538 4.538 0 003.342 1.47 4.563 4.563 0 004.558-4.557A4.563 4.563 0 0023.128 9.3z' />
    </svg>
)

export default SvgProfissionaishospital
