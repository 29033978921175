import { TipoRecebimentoEnum, TipoProcedimento } from 'backend'
import { Icons } from 'libs/components/presentational/elements/icons'

export const dataTypeByEnum = (enumValue: TipoRecebimentoEnum): string => {
    switch (enumValue) {
        case 'DADO_FINANCEIRO':
            return 'Dados financeiros'
        case 'DADO_PRODUTO':
            return 'Dados de produto'
        case 'PRIMARIA_JOELHO':
        case 'PRIMARIA_QUADRIL':
        case 'REVISAO_JOELHO':
        case 'REVISAO_QUADRIL':
        case 'STENT_CORONARIA':
            return 'Dados clínicos'
        default:
            return enumValue
    }
}

export const viewTextByEnum = (enumValue: TipoProcedimento): string => {
    switch (enumValue) {
        case 'PRIMARIA_JOELHO':
            return 'PRIMÁRIA DE JOELHO'
        case 'PRIMARIA_QUADRIL':
            return 'PRIMÁRIA DE QUADRIL'
        case 'REVISAO_JOELHO':
            return 'REVISÃO DE JOELHO'
        case 'REVISAO_QUADRIL':
            return 'REVISÃO DE QUADRIL'
        case 'STENT_CORONARIA':
            return 'ANGIOPLASTIA CORONÁRIA'
        default:
            return enumValue
    }
}

export const iconNameByEnum = (enumValue: TipoRecebimentoEnum): Icons => {
    switch (enumValue) {
        case 'DADO_FINANCEIRO':
            return 'dadosfinanceiros'
        case 'DADO_PRODUTO':
            return 'dadosproduto'
        case 'PRIMARIA_JOELHO':
        case 'PRIMARIA_QUADRIL':
        case 'REVISAO_JOELHO':
        case 'REVISAO_QUADRIL':
        case 'STENT_CORONARIA':
            return 'dadosclinicos'
        default:
            return enumValue
    }
}
