/* tslint:disable */
import React from 'react'

const SvgMotivoProcedimento = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
        <path d='M21.763 13.753a1.397 1.397 0 011.005 2.156l-7.59 11.465a1.396 1.396 0 01-2.55-.951l1.01-7.668-4.4-.509a1.397 1.397 0 01-1.006-2.156l7.59-11.465a1.397 1.397 0 012.55.952l-1.01 7.668 4.401.508z' />
    </svg>
)

export default SvgMotivoProcedimento
