import * as React from 'react'
import { WrappedFieldProps } from 'redux-form'
import * as C from 'libs/components'
import field, { FieldProps } from 'libs/redux-form/field'

export interface ChecklistItemFieldProps extends C.ChecklistItemProps, Partial<WrappedFieldProps> {}

class ChecklistItemFieldCmp extends React.Component<ChecklistItemFieldProps, any> {
    render() {
        return (
            <C.ChecklistItem
                {...this.props.input}
                optionValue={this.props.optionValue}
                label={this.props.label}
                disabled={this.props.disabled}
                testid={this.props.testid}
            >
                {this.props.children}
            </C.ChecklistItem>
        )
    }
}

export const ChecklistItemField: React.ComponentClass<FieldProps & ChecklistItemFieldProps> = field({
    haswrapper: false,
})(ChecklistItemFieldCmp)
