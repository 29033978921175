import { connect } from 'react-redux'
import AlertasView from 'view/home/anvisa/alertas/AlertasView'
import { State } from 'redux/modules'
import { createPaginator, getPage, getFilter } from 'redux/middleware/PaginationAction'
import { AlertaDto, AlertaFiltro } from 'backend'
import { RouteComponentProps } from 'react-router-dom'
import Alerta from 'api/Alerta'
import { Requester } from 'redux/requester'

const url = '/api/alerta'

const alertasNaoLidos = new Requester<number>('alertasNaoLidos', Alerta.getCountNaoLidos)

interface OwnProps extends RouteComponentProps<any> {}

const mapStateToProps = (state: State) => {
    return {
        alertas: getPage<AlertaDto>(url)(state),
        countAlertasNaoLidos: alertasNaoLidos.getResult(state),
        filtro: getFilter<AlertaFiltro>(url)(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps: OwnProps) => {
    const alertasPaginator = createPaginator(dispatch, url)
    return {
        onPageChanged(page?: number) {
            alertasPaginator.page(page)
        },
        onFilter(filtro: AlertaFiltro) {
            alertasPaginator.filter(filtro)
        },
        configurar() {
            ownProps.history.push('/home/alarmes/configurar')
        },
        marcarComoLido(alertaId: number) {
            return Alerta.marcarComoLido({ alertaId }).then(() => {
                alertasPaginator.refresh()
                dispatch(alertasNaoLidos.request())
            })
        },
        refreshAlertasNaoLidos: () => dispatch(alertasNaoLidos.request()),
    }
}

const AlertasContainer = connect(mapStateToProps, mapDispatchToProps)(AlertasView)

export default AlertasContainer
