import * as React from 'react'
import * as C from 'libs/components'

export default class FichaStentCoronariaComplementarSidebar extends React.Component {
    render() {
        return (
            <C.SideNav>
                <C.SideNavItem anchor='antecedentes'>Antecedentes</C.SideNavItem>
                <C.SideNavItem anchor='icpComplementar'>ICP complementar</C.SideNavItem>
                <C.SideNavItem anchor='acessoArterialContraste'>Acesso arterial/contraste</C.SideNavItem>
                <C.SideNavItem anchor='medicacaoProcedimento'>Medicação do procedimento</C.SideNavItem>
                <C.SideNavItem anchor='complicacoesClinicas'>Complicações clínicas</C.SideNavItem>
                <C.SideNavItem anchor='conclusao'>Conclusão</C.SideNavItem>
            </C.SideNav>
        )
    }
}
