import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import reducer from 'redux/modules'
import promiseMiddleware from 'redux/middleware/PromiseAction'
import paginationMiddleware from 'redux/middleware/PaginationAction'
import { routerMiddleware } from 'connected-react-router'
import { history } from 'config/history'

export const middlewares = [thunkMiddleware, promiseMiddleware, paginationMiddleware]

const composeEnhancers =
    process.env.NODE_ENV === 'development' ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose

const store = createStore(
    reducer(history),
    composeEnhancers(applyMiddleware(routerMiddleware(history), ...middlewares))
)

export default store
