
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.84,"time":50400,"words":168}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Os dados financeiros do produto implantado podem ser preenchidos pelo administrativo/financeiro.`}</p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Aguardando dados financeiros”`}</li>
      <li parentName="ol">{`No procedimento desejado clique no botão `}<strong parentName="li">{`“Preencher”`}</strong></li>
    </ol>
    <p><strong parentName="p">{`Dados financeiros`}</strong></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Informe se o componente foi comprado pelo hospital`}</li>
      <li parentName="ol">{`Caso tenha sido comprado pelo hospital informe:`}<ul parentName="li">
          <li parentName="ul">{`O ente responsável pelo ressarcimento`}</li>
          <li parentName="ul">{`O preço pago ao fornecedor`}</li>
          <li parentName="ul">{`O preço cobrado no ressarcimento`}</li>
        </ul></li>
      <li parentName="ol">{`Caso `}<strong parentName="li">{`não`}</strong>{` tenha sido comprado pelo hospital informe quem forneceu`}</li>
      <li parentName="ol">{`Clique no botão “Salvar”`}</li>
    </ol>
    <p>{`Caso tenha mais de um componente clique no botão “Próximo componente”. Informe os dados financeiros de todos os componentes e ao final clique em “Salvar”.`}</p>
    <p>{`Após salvar os dados financeiros, não é mais necessária nenhuma ação sobre este procedimento, ficando ele disponível para visualização e impressão na aba “Histórico”.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona cada etapa do preenchimento do registro, `}<a parentName="p" {...{
          "href": "../fluxos/fluxoPreenchimentoRegistros"
        }}><strong parentName="a">{`clique aqui`}</strong></a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona o preenchimento dos dados financeiros e dados de produto, consulte o vídeo explicativo sobre abaixo:`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

