/* tslint:disable */
import React from 'react'

const SvgGrafico = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path
            strokeWidth={0.5}
            d='M19.876 8c-1.17 0-2.123.905-2.123 2.016 0 .406.127.784.345 1.1-.335.528-.627.91-.876 1.147-.26.247-.733.59-1.418 1.032a2.192 2.192 0 00-1.159-.328c-.442 0-.853.13-1.194.35-.63-.295-1.076-.567-1.339-.817-.252-.24-.578-.701-.979-1.384.218-.317.345-.694.345-1.1C11.478 8.905 10.525 8 9.354 8c-1.17 0-2.123.905-2.123 2.016 0 .406.127.783.345 1.1-.628.366-1.07.672-1.329.917-.252.239-.574.66-.966 1.262a2.191 2.191 0 00-1.157-.328c-1.171 0-2.124.905-2.124 2.017C2 16.095 2.953 17 4.124 17c1.17 0 2.123-.905 2.123-2.016a1.93 1.93 0 00-.345-1.1c.304-.69.58-1.15.825-1.384.264-.25.754-.516 1.47-.795.333.207.73.328 1.158.328.427 0 .824-.12 1.158-.328.388.6.71 1.02.965 1.262.263.25.718.567 1.365.952a1.93 1.93 0 00-.321 1.065c0 1.111.953 2.016 2.123 2.016 1.171 0 2.124-.905 2.124-2.016 0-.406-.127-.783-.345-1.1.703-.435 1.195-.787 1.477-1.055.228-.216.5-.59.818-1.124.333.207.73.328 1.157.328 1.171 0 2.124-.905 2.124-2.017C22 8.905 21.047 8 19.876 8z'
        />
    </svg>
)

export default SvgGrafico
