/* tslint:disable */
import React from 'react'

const SvgHelp = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
        <path d='M8 14A6 6 0 118 2a6 6 0 010 12zm.583-2.767v-.588a.375.375 0 00-.363-.367h-.897a.375.375 0 00-.363.367v.588c0 .196.17.367.363.367h.897a.375.375 0 00.363-.367zm1.43-3.551c.242-.319.363-.71.387-1.127 0-.661-.218-1.175-.654-1.567C9.31 4.596 8.728 4.4 7.953 4.4c-.702 0-1.284.171-1.72.514-.388.294-.581.71-.63 1.274-.024.122.097.245.218.245l1.042.024c.17 0 .315-.098.363-.27.049-.122.097-.244.194-.318a.793.793 0 01.533-.196c.267 0 .46.074.606.245.145.172.218.392.218.662 0 .244-.073.49-.194.685-.121.22-.29.368-.509.515-.436.293-.727.563-.872.783-.121.172-.194.417-.218.735-.024.147.097.27.242.27h1.115c.12 0 .242-.099.242-.221.024-.171.048-.294.097-.416.073-.147.218-.294.412-.441.387-.196.678-.466.92-.808z' />
    </svg>
)

export default SvgHelp
