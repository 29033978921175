import * as React from 'react'
import { ChartOptions, ChartCard } from 'libs/components'
import { DistHospEstado } from 'redux/modules/report/usoSistema'
import { SeriesPieOptions } from 'highcharts'

interface Props {
    readyState: 'request' | 'success' | 'failure'
    distHospEstado: DistHospEstado
}

export class HospitaisEstadoChartCard extends React.Component<Props> {
    render() {
        let config: ChartOptions = {}

        if (this.props.readyState === 'success') {
            config = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                },
                title: {
                    text: 'Distribuição de hospitais por estado',
                },
                tooltip: {
                    pointFormat: '{point.y} | {point.percentage:.1f}%',
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            format:
                                '{point.name} <br> <span style="color:{point.color}">{point.percentage:.1f}%</span>',
                        },
                    },
                },
                series: this.props.distHospEstado.result.series as SeriesPieOptions[],
            }
        }
        return (
            <ChartCard
                defaultViewMode='pie'
                title='Hospitais x estados'
                readyState={this.props.readyState}
                config={config}
                renderActions={false}
                renderTools={false}
            />
        )
    }
}
