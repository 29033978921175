
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.58,"time":34800,"words":116}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`O RNI permite que seja feita a correção/edição de dados dos produtos com algumas condições.`}</p>
    <p><strong parentName="p">{`Regra:`}</strong></p>
    <ol>
      <li parentName="ol">{`Os dados financeiros não podem ter sido preenchidos.`}</li>
      <li parentName="ol">{`Profissionais com perfil de `}<strong parentName="li">{`Técnico`}</strong>{`, o `}<strong parentName="li">{`Médico`}</strong>{` e o `}<strong parentName="li">{`Residente`}</strong>{` da equipe médica procedimento e profissionais com perfil de `}<strong parentName="li">{`Administrativo/Financeiro`}</strong>{` podem realizar a edição dos dados de produto.`}</li>
    </ol>
    <p><strong parentName="p">{`Fluxo:`}</strong></p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Aguardando dados financeiros”`}</li>
      <li parentName="ol">{`No procedimento que deseja editar, clique no ícone `}<img parentName="li" {...{
          "src": "/images/ic-editar-21-px.png#center",
          "alt": "Editar"
        }}></img></li>
      <li parentName="ol">{`Nos dados de produto, edite os dados que precisam de edição e clique no botão “Próximo componente”`}</li>
      <li parentName="ol">{`Repita a operação para cada um dos produtos implantados`}</li>
      <li parentName="ol">{`Após o preenchimento dos campos, clique no botão “Salvar registro”.`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

