import * as React from 'react'
import * as C from 'libs/components'
import { SumarioProcedimentoDto } from 'backend'
import { tiposProcedimentoDescricaoByEnum } from 'api/Procedimento'
import * as DateUtil from 'libs/util/DateUtil'

interface Props {
    procedimento: SumarioProcedimentoDto
    index: number
    onClick: () => void
}

export class SumarioProcedimentoCard extends React.Component<Props> {
    render() {
        const proced = this.props.procedimento
        const hasLado = proced.tipoProcedimento !== 'STENT_CORONARIA'
        const titulo =
            tiposProcedimentoDescricaoByEnum[proced.tipoProcedimento] + (hasLado ? ' ' + proced.lado.toLowerCase() : '')
        const hospital = proced.hospital + ' - ' + proced.cidade + '/' + proced.uf
        const skin =
            proced.tipoProcedimento === 'PRIMARIA_JOELHO' || proced.tipoProcedimento === 'PRIMARIA_QUADRIL'
                ? 'blue'
                : proced.tipoProcedimento === 'STENT_CORONARIA'
                ? 'green'
                : 'red'
        return (
            <div data-name={'row-' + this.props.index} className='padded-botton'>
                <C.Columns className='align-center'>
                    <C.Column offset='1' size='10'>
                        <C.Card>
                            <C.Columns className='align-center'>
                                <C.Column size='1'>
                                    <C.UserInitials size='medium' skin={skin} nome={proced.tipoProcedimento} />
                                </C.Column>
                                <C.Column size='8'>
                                    <C.ComposedLabel labelClassName='procedimento-card-label-title' title={titulo}>
                                        <C.Label className='procedimento-card-label-content'>{hospital} </C.Label>
                                    </C.ComposedLabel>
                                </C.Column>
                                <C.Column>
                                    <span className='card-data'>{DateUtil.asClientFormat(proced.dataCirurgia)} </span>
                                </C.Column>
                                <C.Column size='1'>
                                    <C.Hint
                                        placement='top'
                                        componente={
                                            <C.IconButton
                                                icon='arrowRight'
                                                size='is-x-small'
                                                pulledRight
                                                onClick={this.props.onClick}
                                            />
                                        }
                                    >
                                        Visualizar registro
                                    </C.Hint>
                                </C.Column>
                            </C.Columns>
                        </C.Card>
                    </C.Column>
                </C.Columns>
            </div>
        )
    }
}
