import React from 'react'
import { Font, Image, Page, StyleSheet, Text } from '@react-pdf/renderer'
import OpenSans from './fontes/open.ttf'
import OpenSansBold from './fontes/OpenSans-Bold.ttf'

export function ManualCapaSumarioPDFView() {
    return (
        <Page style={styles.bodyCapa}>
            <Text style={styles.tituloCapa}>Manual de uso RNI</Text>
            <Image style={styles.imageAnvisaCapa} src='/static/logo-anvisa.png' />
            <Image style={styles.imageRniCapa} src='/static/logo-rni.png' />
        </Page>
    )
}

Font.register({
    family: 'Open Sans',
    src: OpenSans,
})

Font.register({
    family: 'Open Sans-Bold',
    src: OpenSansBold,
})

const styles = StyleSheet.create({
    bodyCapa: {
        paddingHorizontal: 150,
        paddingTop: 300,
    },
    tituloCapa: {
        fontFamily: 'Open Sans',
        fontSize: 32,
        textAlign: 'center',
    },
    imageAnvisaCapa: {
        width: '160px',
        height: '42px',
        left: 25,
        top: 25,
    },
    imageRniCapa: {
        width: '74px',
        height: '42px',
        left: 350,
        position: 'absolute',
        top: 365,
    },
    footerCapa: {
        fontFamily: 'Open Sans',
        position: 'absolute',
        fontSize: 12,
        bottom: 40,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: '#333333',
    },
    bodySummary: {
        paddingTop: 30,
        paddingBottom: 65,
        paddingHorizontal: 35,
        textAlign: 'justify',
        fontFamily: 'Open Sans-Bold',
    },
    imageAnvisa: {
        width: '122.1px',
        height: '30px',
    },
    imageRni: {
        width: '52.8px',
        height: '30px',
        position: 'absolute',
        left: 500,
        top: 30,
    },
    blockquote: {
        backgroundColor: '#f7f7f7',
        padding: '10px',
        marginTop: 15,
        marginBottom: 10,
        border: '1pt solid #e6e6e6',
        fontSize: 11,
    },
    item: {
        fontFamily: 'Open Sans',
        fontSize: 9,
        marginLeft: 20,
        marginRight: 20,
    },
})
