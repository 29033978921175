import * as React from 'react'
import { StyledCheckbox, StyledCheckboxProps } from 'libs/components/presentational/input/StyledCheckbox'
import isUndefined from 'lodash/isUndefined'
import isArray from 'lodash/isArray'
import * as Util from 'libs/components/Util'

export interface StyledChecklistItemProps extends StyledCheckboxProps {
    optionValue: any
    className?: string
}

export class StyledChecklistItem extends React.Component<StyledChecklistItemProps, any> {
    constructor(props) {
        super(props)

        this.getCurrentValues = this.getCurrentValues.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    getCurrentValues() {
        const { value } = this.props

        let previousValues = []

        if (!isUndefined(value) && value !== '') {
            previousValues = value
        }

        const currentValues = isArray(previousValues) ? [...previousValues] : [previousValues]

        return currentValues
    }

    handleChange(event) {
        const { onChange } = this.props
        const values = this.getCurrentValues()

        if (event.target.checked) {
            values.push(this.props.optionValue)
        } else {
            values.splice(values.indexOf(this.props.optionValue), 1)
        }

        return onChange(values as any)
    }

    render() {
        const { optionValue, className } = this.props
        const values = this.getCurrentValues()

        const isChecked = values.indexOf(optionValue) > -1
        return (
            <StyledCheckbox
                {...Util.filterProps(this.props, ...excludedProps)}
                onChange={this.handleChange}
                checked={isChecked}
                onBlur={() => {}}
                value={optionValue}
                className={className}
            />
        )
    }
}

const excludedProps = ['optionValue']
