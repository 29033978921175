import * as React from 'react'
import { HeaderContainer } from './HeaderContainer'

interface Props {
    title: string
    subtitle?: string
    containerClass?: string
}

export class ReportHeader extends React.Component<Props> {
    render() {
        return (
            <HeaderContainer containerClass={this.props.containerClass}>
                <div className='report-header-title'>
                    <div className='align-center'>
                        <h1>{this.props.title}</h1>
                        <h2>{this.props.subtitle}</h2>
                    </div>
                </div>

                {this.props.children}
            </HeaderContainer>
        )
    }
}
