import { ProcedimentoDimensions, LocalDimension, LocalFiltro } from 'backend'
import { capitalizeName } from 'libs/util/StringUtil'
import { State } from 'redux/modules/index'
import * as enums from 'redux/enums'
import Report from 'api/Report'
import { promiseStaleReducer, PromiseRecord } from 'redux/middleware/PromiseAction'
import isEqual from 'lodash/isEqual'

export type FiltroType = any
export type LocalType = 'REGIAO' | 'UF' | 'CIDADE' | 'HOSPITAL'

// Action types

export const CHANGE = 'rni/report/filter/CHANGE'
export const DRILL_UP = 'rni/report/filter/DRILL_UP'
export const FIND_LOCAL = 'rni/report/filter/FIND_LOCAL'

// Reducers

export interface FiltroState {
    readonly filtro?: FiltroType
    readonly filtroBkp?: FiltroType
    readonly local?: PromiseRecord<LocalDimension>
}

export const initialState: FiltroState = {
    filtro: {},
    local: null,
}

export const reducer = (state: FiltroState = initialState, action): FiltroState => {
    switch (action.type) {
        case CHANGE:
            return {
                ...state,
                filtroBkp: state.filtro,
                filtro: action.payload,
            }
        case FIND_LOCAL:
            return {
                ...state,
                local: promiseStaleReducer(state.local, action),
            }
        default:
            return state
    }
}

export default reducer

// Action creators

export const change = (filtro?: FiltroType) => (dispatch, getState) => {
    const state: State = getState()
    dispatch({
        type: CHANGE,
        payload: filtro,
    })
    if (!isEqual(filtro.local, state.report.filtro?.filtro?.local)) {
        dispatch(findLocal(filtro.local))
    }
}

export const changeLocal = (id: any, type: LocalType) => (dispatch, getState) => {
    const state: State = getState()
    const currentFilter = state.report.filtro.filtro

    dispatch(
        change({
            ...currentFilter,
            local: {
                regiao: type === 'REGIAO' ? id : undefined,
                uf: type === 'UF' ? id : undefined,
                cidadeId: type === 'CIDADE' ? id : undefined,
                hospitalId: type === 'HOSPITAL' ? id : undefined,
            },
        })
    )
}

export const drillUp = () => (dispatch, getState) => {
    const state: State = getState()
    dispatch(change(getDrillUpLocal(state.report.filtro)))
}

export const findLocal = (filter: LocalFiltro) => ({
    type: FIND_LOCAL,
    payload: filter,
    promise: Report.findLocalidade(filter),
})

// Seletores
export const getCurrentDimension = (localDimension: LocalDimension): ProcedimentoDimensions => {
    return (
        (localDimension.hospital && 'LOCAL_HOSPITAL') ||
        (localDimension.cidade && 'LOCAL_CIDADE') ||
        (localDimension.uf && 'LOCAL_UF') ||
        (localDimension.regiao && 'LOCAL_REGIAO') ||
        null
    )
}

const drills: ProcedimentoDimensions[] = ['LOCAL_REGIAO', 'LOCAL_UF', 'LOCAL_CIDADE', 'LOCAL_HOSPITAL']

export const getDrillUpLocal = (state: FiltroState): FiltroType => {
    const local = state.local?.data || {}
    const dimension = getCurrentDimension(local)
    const index = drills.indexOf(dimension)

    return {
        ...state.filtro,
        local: {
            hospitalId: index === 4 ? local.hospital.id : undefined,
            cidadeId: index === 3 ? local.cidade.id : undefined,
            uf: index === 2 ? local.uf : undefined,
            regiao: index === 1 ? local.regiao : undefined,
        },
    }
}

export const getLocalName = (local: LocalDimension = {}) => {
    return (
        (local.hospital && local.hospital.nome) ||
        (local.cidade && capitalizeName(local.cidade.nome)) ||
        enums.ufName[local.uf] ||
        enums.regiaoName[local.regiao] ||
        'Brasil'
    )
}

export const getParentLocalName = (local: LocalDimension = {}) => {
    return (
        (local.hospital && local.cidade && capitalizeName(local.cidade.nome)) ||
        (local.cidade && enums.ufName[local.uf]) ||
        (local.uf && enums.regiaoName[local.regiao]) ||
        (local.regiao && 'Brasil') ||
        null
    )
}
