import * as React from 'react'
import * as C from 'libs/components'

interface HemorragicaProps {
    disabled?: boolean
}

export class HemorragicaCard extends React.Component<HemorragicaProps> {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Hemorrágica'
                name='hemorragica'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='hemorragica' disabled={this.props.disabled} />}
            >
                <C.Conteudo>
                    <C.Columns>
                        <C.Column>
                            <C.ControlField label={'Sangramento'} name='hemorragicaSangramento' />
                        </C.Column>
                    </C.Columns>
                    <C.Columns>
                        <C.Column size='5' verticalFlow>
                            <C.ChecklistItemField
                                name='hemorragicaSangramento'
                                optionValue='GASTROINTESTINAL'
                                label='Gastrointestinal'
                            />
                            <C.ChecklistItemField
                                name='hemorragicaSangramento'
                                optionValue='GENITURINARIO'
                                label='Geniturinário'
                            />
                            <C.ChecklistItemField
                                name='hemorragicaSangramento'
                                optionValue='INTRACRANIANO'
                                label='Intracraniano'
                            />
                            <C.ChecklistItemField
                                name='hemorragicaSangramento'
                                optionValue='INTRAOCULAR'
                                label='Intraocular'
                            />
                        </C.Column>
                        <C.Column verticalFlow>
                            <C.ChecklistItemField
                                name='hemorragicaSangramento'
                                optionValue='NASAL_HEMERROIDARIO'
                                label='Nasal, hemorroidário'
                            />
                            <C.ChecklistItemField
                                name='hemorragicaSangramento'
                                optionValue='RETROPERITONEAL'
                                label='Retroperitoneal'
                            />
                            <C.ChecklistItemField
                                name='hemorragicaSangramento'
                                optionValue='SITIO_ACESSO'
                                label='Sítio de acesso'
                            />
                        </C.Column>
                    </C.Columns>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
