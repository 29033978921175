import * as React from 'react'
import { Button } from 'libs/components'
import form from 'libs/redux-form/form'
import { InjectedFormProps } from 'redux-form'
import { Columns, Column } from 'libs/components'
import { Map } from 'immutable'
import { ProdutoField } from 'view/home/rastreabilidade/componentes/ProdutoField'

export interface DadosFinanceirosFilterProps extends InjectedFormProps<any, any> {
    onSubmit(values: Map<string, any>): void
}

class DadosFinanceirosFilterCmp extends React.Component<DadosFinanceirosFilterProps> {
    render() {
        return (
            <Columns>
                <Column size='2' />
                <Column size='6'>
                    <ProdutoField
                        maxLength={11}
                        name='registroAnvisa'
                        data-name='registroAnvisa'
                        placeholder='Registro ANVISA'
                    />
                </Column>
                <Column size='2'>
                    <Button type='primary' fullwidth onClick={this.props.handleSubmit}>
                        Pesquisar
                    </Button>
                </Column>
                <Column size='2' />
            </Columns>
        )
    }
}
export const DadosFinanceirosFilter = form({
    form: 'dadosFinanceirosFilter',
    hasSuccessModal: false,
    hasErrorModal: false,
    hasLeaveModal: false,
})(DadosFinanceirosFilterCmp) as any
