import { isImmutable } from 'immutable'
import {
    Button,
    ButtonBar,
    CheckboxField,
    ChecklistItemField,
    CheckPanelField,
    ClearRadioFieldButton,
    Column,
    Columns,
    Conteudo,
    ControlField,
    DatePickerField,
    Form,
    InputPanel,
    Linha,
    PeriodoField,
    RadioField,
    SectionTitle,
    SimNaoRadioField,
    StyledRadioField,
} from 'libs/components'
import { InicioTerminoPanel } from 'libs/components/presentational/form/InicioTerminoPanel'
import form from 'libs/redux-form/form'
import * as DateUtil from 'libs/util/DateUtil'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Scroll from 'react-scroll'
import { FormSection, formValueSelector } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { clearEdicaoProcedimentoData, EdicaoState } from 'redux/modules/edicaoProcedimento'
import { clear as clearNovoProcedimento } from 'redux/modules/novoProcedimento'
import { actionCreators as pacienteActionCreators } from 'redux/modules/paciente'
import { usePesquisaSatisfacaoPopup } from 'view/componentes/pesquisaSatisfacao/usePesquisaSatisfacaoPopup'
import FichaPrimariaQuadrilValidator from 'view/home/novoProcedimento/ficha/quadril/FichaPrimariaQuadrilValidator'
import { FichaFormCommonProps, FichaFormWrapProps } from '../../procedimentos/ProcedimentosView'
import {
    AbordagemCirurgicaPrimariaQuadrilField,
    AntibioticoField,
    CardPaciente,
    ComponenteComplementar,
    ComponentePrincipal,
    ListComponenteFichaField,
    ModalSave,
    ParTribologicoField,
    TipoArtroplastiaField,
    TipoEnxertiaField,
} from '../componentes'
import FichaPromptLeave from '../componentes/FichaPromptLeave'
import { handleErrorResolver } from '../DadoClinicoErrorUtil'

const formName = 'primariaQuadril'

const FichaPrimariaQuadrilView = props => {
    const dispatch = useDispatch()
    useEffect(
        () => () => {
            dispatch(clearNovoProcedimento())
        },
        [dispatch]
    )
    const { showPesquisa } = usePesquisaSatisfacaoPopup()
    const edicaoPrimariaQuadrilData = useSelector<State, EdicaoState>(state => state.edicao)

    const showModalLeave = React.useRef<boolean>(true)

    const handleSubmitFail = error => {
        if (typeof error === 'string' || error?._error?.status === 404) {
            showModalLeave.current = false
            props.goHome()
        }
    }

    if (props.editMode && isEmpty(edicaoPrimariaQuadrilData)) {
        return null
    }
    return (
        <>
            <FichaPromptLeave formName={formName} showModalLeave={showModalLeave} />
            <FichaForm
                onSubmit={props.onSave}
                onSubmitSuccess={() => {
                    props.goHome()
                    showPesquisa()
                }}
                onSubmitFail={handleSubmitFail}
                errorResolver={handleErrorResolver}
                onCancel={props.goHome}
                lado={props.lado}
                paciente={props.paciente}
                procedimento={props.procedimento}
                initialValues={edicaoPrimariaQuadrilData}
                editMode={props.editMode}
            />
        </>
    )
}
export default FichaPrimariaQuadrilView

const MotivoArtroplastiaFormSection = () => {
    return (
        <Conteudo>
            <Columns>
                <Column>
                    <ControlField label={'Motivos da artroplastia'} name='motivoArtroplastia' required />
                </Column>
            </Columns>
            <Columns>
                <Column size='6' verticalFlow>
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='ARTRITE_REUMATOIDE_QUADRIL'
                        label='Artrite reumatóide'
                    />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='FRATURA_COLO_FEMORAL'
                        label='Fratura do colo femoral'
                    />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='LUXACAO_INVETERADA'
                        label='Luxação inveterada'
                    />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='NECROSE_AVASCULAR_CABECA_FEMORAL'
                        label='Necrose avascular da cabeça femoral'
                    />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='OSTEOARTROSE_PRIMARIA_QUADRIL'
                        label='Osteoartrose primária'
                    />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='OUTRAS_DOENCAS_REUMATICAS_QUADRIL'
                        label='Outras doenças reumáticas'
                    />
                </Column>
                <Column verticalFlow>
                    <ChecklistItemField name='motivoArtroplastia' optionValue='POS_TRAUMATICA' label='Pós-traumática' />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='SEQUELA_DISPLASIA'
                        label='Sequela de displasia'
                    />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='POS_FRATURA_ACETABULAR'
                        label='Sequela de fratura do acetábulo'
                    />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='POS_FRATURA_FEMUR_PROXIMAL'
                        label='Sequela de fratura do fêmur proximal'
                    />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='SEQUELA_PERTHES'
                        label='Sequela de Perthes'
                    />
                    <ChecklistItemField name='motivoArtroplastia' optionValue='OUTRO_PRIMARIA_QUADRIL' label='Outro' />
                </Column>
            </Columns>
        </Conteudo>
    )
}

const ProcedimentoCirurgicoPrevioFormSection = props => {
    return (
        <FormSection name='procedimentoCirurgicoPrevio'>
            <Conteudo>
                <Columns>
                    <Column verticalFlow size='5'>
                        <ControlField name='procedimentoPrevio' label={'Procedimentos'} required />
                        <InputPanel fallback>
                            <ChecklistItemField name='procedimentoPrevio' optionValue='NENHUM' label='Nenhum' />
                        </InputPanel>
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='ARTRODESE'
                            label='Artrodese'
                            disabled={props.disabledNenhum}
                        />
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='OSTEOSSINTESE_FEMUR'
                            label='Osteossíntese de fêmur'
                            disabled={props.disabledNenhum}
                        />
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='OSTEOSSINTESE_ACETABULO'
                            label='Osteossíntese do acetábulo'
                            disabled={props.disabledNenhum}
                        />
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='OSTEOTOMIA_FEMORAL'
                            label='Osteotomia femoral'
                            disabled={props.disabledNenhum}
                        />
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='OSTEOTOMIA_PELVICA'
                            label='Osteotomia pélvica'
                            disabled={props.disabledNenhum}
                        />
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='OUTRO'
                            label='Outro'
                            disabled={props.disabledNenhum}
                        />
                    </Column>
                </Columns>
                <Linha size='medium' />
                <Columns>
                    <Column verticalFlow>
                        <SimNaoRadioField label='Implante prévio' name='implantePrevio' required />
                        <InputPanel fallback size='medium' dataName='procedimentoCirurgicoPrevio.estadoImplantePrevio'>
                            <RadioField
                                name='estadoImplantePrevio'
                                disabled={props.disabledImplantePrevio}
                                label='Ainda presente'
                                value='AINDA_PRESENTE'
                            />
                            <RadioField
                                name='estadoImplantePrevio'
                                disabled={props.disabledImplantePrevio}
                                label='Removido previamente'
                                value='REMOVIDO_PREVIAMENTE'
                            />
                        </InputPanel>
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const ProcedimentoCirurgicoAtualFormSection = props => {
    return (
        <FormSection name='procedimentoCirurgicoAtual'>
            <Conteudo>
                <Columns>
                    <Column size='4'>
                        <DatePickerField
                            label='Data da cirurgia'
                            placeholder='dd/mm/aaaa'
                            name='dataCirurgia'
                            required
                        />
                    </Column>
                </Columns>

                <InicioTerminoPanel duracao={props.duracao} />

                <Columns>
                    <Column verticalFlow>
                        <AbordagemCirurgicaPrimariaQuadrilField
                            label='Abordagem cirúrgica'
                            name='abordagemCirurgica'
                            required
                        />
                        <Linha size='medium' />
                        <SimNaoRadioField label='Uso de dreno' name='usoDreno' required />
                        <SimNaoRadioField label='Transfusão sanguínea' name='transfusaoSanguinea' required />
                        <InputPanel fallback size='medium'>
                            <RadioField
                                name='tipoTransfusaoSanguinea'
                                disabled={props.disabledTipoTransfusaoSanguinea}
                                label='Autóloga'
                                value='AUTOLOGA'
                            />
                            <RadioField
                                name='tipoTransfusaoSanguinea'
                                disabled={props.disabledTipoTransfusaoSanguinea}
                                label='Homóloga'
                                value='HOMOLOGA'
                            />
                        </InputPanel>
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <Linha size='medium' />
                        <SimNaoRadioField label='Foi utilizado antibiótico' name='possuiAntibiotico' required />
                        <InputPanel fallback size='medium'>
                            <Columns>
                                <Column size='6'>
                                    <AntibioticoField
                                        label='Antibiótico'
                                        name='antibiotico'
                                        isDisabled={props.disabledAntibiotico}
                                        required={!props.disabledAntibiotico}
                                    />
                                </Column>
                                <Column size='4'>
                                    <PeriodoField
                                        label='Período'
                                        name='periodoAntibiotico'
                                        disabled={props.disabledAntibiotico}
                                        required={!props.disabledAntibiotico}
                                    />
                                </Column>
                            </Columns>
                        </InputPanel>
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const TipoArtroplastiaFormSection = props => {
    return (
        <FormSection name='tipoArtroplastiaForm'>
            <Conteudo>
                <TipoArtroplastiaField
                    label='Tipo'
                    name='tipoArtroplastia'
                    required
                    disabled={props.disabledTipoArtroplastia}
                />
                <InputPanel fallback size='medium'>
                    <ParTribologicoField
                        label='Par tribológico'
                        name='parTribologico'
                        disabled={props.disabledTribologico}
                        required={!props.disabledTribologico}
                    />
                </InputPanel>
            </Conteudo>
        </FormSection>
    )
}

const ImplantesFormSection = props => {
    return (
        <FormSection name='implantes'>
            <Conteudo>
                <ControlField name='femoral' label={'Implantes aplicados'} required />
                <CheckPanelField label='Femoral' name='femoral' required>
                    <Conteudo>
                        <ControlField label='Modelo de haste' name='modeloHaste' required horizontalFlow>
                            <RadioField name='modeloHaste' label='Com colar' value='COM_COLAR' />
                            <RadioField name='modeloHaste' label='Sem colar' value='SEM_COLAR' />
                            <ClearRadioFieldButton name='modeloHaste' />
                        </ControlField>
                        <SimNaoRadioField label='Centralizador distal' name='centralizadorDistal' required />
                        <SimNaoRadioField label='Centralizador proximal' name='centralizadorProximal' required />
                    </Conteudo>
                    <Linha />
                    <Conteudo>
                        <SimNaoRadioField label='Cimentado' name='femoralCimentado' required />
                        <SimNaoRadioField
                            label='Restritor de cimento'
                            name='restritorCimento'
                            disabled={props.disabledRestritorCimentoFemoral}
                            required={!props.disabledRestritorCimentoFemoral}
                        />
                        <InputPanel fallback size='medium' dataName='implantes.tipoRestritorCimento'>
                            <RadioField
                                name='tipoRestritorCimento'
                                disabled={props.disabledTipoRestritorCimentoFemoral}
                                label='Ósseo'
                                value='OSSEO'
                            />
                            <RadioField
                                name='tipoRestritorCimento'
                                disabled={props.disabledTipoRestritorCimentoFemoral}
                                label='Sintético'
                                value='SINTETICO'
                            />
                        </InputPanel>
                    </Conteudo>
                </CheckPanelField>

                <CheckPanelField label='Acetabular' name='acetabular'>
                    <Columns>
                        <Column>
                            <Conteudo>
                                <CheckboxField name='polietileno' label='Polietileno' />
                            </Conteudo>
                            <Conteudo horizontalFlow>
                                <InputPanel dataName='implantes.tipoAcetabularPolietileno'>
                                    <RadioField
                                        name='tipoAcetabularPolietileno'
                                        label='Com flange'
                                        value='COM_FLANGE'
                                        disabled={!props.disabledTipoAcetabularPolietileno}
                                    />
                                    <RadioField
                                        name='tipoAcetabularPolietileno'
                                        label='Sem flange'
                                        value='SEM_FLANGE'
                                        disabled={!props.disabledTipoAcetabularPolietileno}
                                    />
                                </InputPanel>
                            </Conteudo>
                        </Column>
                    </Columns>
                    <Columns>
                        <Column size='5'>
                            <Conteudo verticalFlow>
                                <CheckboxField name='metalico' label='Metálico' />
                                <InputPanel verticalFlow>
                                    <ChecklistItemField
                                        name='tipoAcetabularMetalico'
                                        optionValue='JATEADO'
                                        label='Jateado'
                                        disabled={!props.disabledMetalico}
                                    />
                                    <ChecklistItemField
                                        name='tipoAcetabularMetalico'
                                        optionValue='MICRO_POROSIDADES'
                                        label='Microporosidades'
                                        disabled={!props.disabledMetalico}
                                    />
                                    <ChecklistItemField
                                        name='tipoAcetabularMetalico'
                                        optionValue='HIDROXIAPATITA'
                                        label='Hidroxiapatita'
                                        disabled={!props.disabledMetalico}
                                    />
                                    <ChecklistItemField
                                        name='tipoAcetabularMetalico'
                                        optionValue='TRABECULAR'
                                        label='Trabecular'
                                        disabled={!props.disabledMetalico}
                                    />
                                </InputPanel>
                            </Conteudo>
                        </Column>
                    </Columns>
                    <Linha />
                    <Conteudo>
                        <SimNaoRadioField label='Cimentado' name='acetabularCimentado' required />

                        <ControlField
                            horizontalFlow
                            label='Parafusos'
                            name='parafusos'
                            required={props.disabledParafusos && !props.ceramicaCeramica}
                            disabled={!props.disabledParafusos}
                        >
                            <StyledRadioField
                                skin='square-button'
                                name='parafusos'
                                value='UM'
                                disabled={!props.disabledParafusos}
                            >
                                1
                            </StyledRadioField>
                            <StyledRadioField
                                skin='square-button'
                                name='parafusos'
                                value='DOIS'
                                disabled={!props.disabledParafusos}
                            >
                                2
                            </StyledRadioField>
                            <StyledRadioField
                                skin='square-button'
                                name='parafusos'
                                value='TRES'
                                disabled={!props.disabledParafusos}
                            >
                                3
                            </StyledRadioField>
                            <StyledRadioField
                                skin='square-button'
                                name='parafusos'
                                value='OUTRO'
                                disabled={!props.disabledParafusos}
                            >
                                Outro
                            </StyledRadioField>
                        </ControlField>
                    </Conteudo>
                </CheckPanelField>
            </Conteudo>
        </FormSection>
    )
}

const CimentoOsseoFormSection = props => {
    return (
        <FormSection name='cimentoOsseo'>
            <Conteudo verticalFlow>
                <ControlField
                    label='Tipo'
                    name='tipoCimentoOsseo'
                    required={!props.disabledPossuiCimentoOsseo}
                    disabled={props.disabledPossuiCimentoOsseo}
                >
                    <RadioField
                        name='tipoCimentoOsseo'
                        label='Convencional'
                        value='CONVENCIONAL'
                        disabled={props.disabledPossuiCimentoOsseo}
                    />
                    <RadioField
                        name='tipoCimentoOsseo'
                        label='Com antibiótico'
                        value='COM_ANTIBIOTICO'
                        disabled={props.disabledPossuiCimentoOsseo}
                    />
                    <ClearRadioFieldButton name='tipoCimentoOsseo' />
                </ControlField>
                <ControlField
                    label='Técnica de cimentação'
                    name='tecnicaCimentacao'
                    required={!props.disabledPossuiCimentoOsseo}
                    disabled={props.disabledPossuiCimentoOsseo}
                >
                    <RadioField
                        name='tecnicaCimentacao'
                        label='Manual'
                        value='MANUAL'
                        disabled={props.disabledPossuiCimentoOsseo}
                    />
                    <RadioField
                        name='tecnicaCimentacao'
                        label='Retrógrada'
                        value='RETROGRADA'
                        disabled={props.disabledPossuiCimentoOsseo}
                    />
                    <ClearRadioFieldButton name='tecnicaCimentacao' />
                </ControlField>
                <SimNaoRadioField
                    label='Lavagem pulsátil'
                    name='lavagemPulsatil'
                    disabled={props.disabledPossuiCimentoOsseo}
                    required={!props.disabledPossuiCimentoOsseo}
                />
            </Conteudo>
        </FormSection>
    )
}

const EnxertiaFormSection = props => {
    return (
        <FormSection name='enxertia'>
            <Conteudo>
                <CheckPanelField label='Femoral' name='enxertiaFemoral' disabled={props.disabledPossuiEnxertiaFemoral}>
                    <Conteudo>
                        <TipoEnxertiaField label='Qual?' name='tipoEnxertiaFemoral' />
                    </Conteudo>
                </CheckPanelField>
                <CheckPanelField
                    label='Acetabular'
                    name='enxertiaAcetabular'
                    disabled={props.disabledPossuiEnxertiaAcetabular}
                >
                    <Conteudo>
                        <TipoEnxertiaField label='Qual?' name='tipoEnxertiaAcetabular' />
                    </Conteudo>
                </CheckPanelField>
            </Conteudo>
        </FormSection>
    )
}

const ComponentesUtilizadosFormSection = props => {
    let tipoArtroplastiaId, tipoArtroplastiaNome
    if (isImmutable(props.tipoArtroplastia)) {
        tipoArtroplastiaId = props.tipoArtroplastia.get('id')
        tipoArtroplastiaNome = props.tipoArtroplastia.get('nome')
    } else {
        tipoArtroplastiaId = props.tipoArtroplastia?.id
        tipoArtroplastiaNome = props.tipoArtroplastia?.nome
    }

    const labelTipoArtroplastia = props.tipoArtroplastia && (
        <p className='label-tipo-artroplastia'>{'Tipo de artroplastia: ' + tipoArtroplastiaNome}</p>
    )
    return (
        <FormSection name='componentesUtilizadosForm'>
            <Conteudo>
                <ListComponenteFichaField
                    name='componentesPrincipais'
                    combo={
                        <ComponentePrincipal
                            label='Principais'
                            name='componentesPrincipaisLabel'
                            tipoProcedimento='PRIMARIA_QUADRIL'
                            required
                            disabled={!props.tipoArtroplastia}
                            tipoArtroplastia={tipoArtroplastiaId}
                        />
                    }
                >
                    {labelTipoArtroplastia}
                </ListComponenteFichaField>
                <hr />
                <ListComponenteFichaField
                    name='componentesComplementares'
                    combo={
                        <ComponenteComplementar
                            name='componentesComplementaresLabel'
                            tipoProcedimento='PRIMARIA_QUADRIL'
                            label='Complementares'
                        />
                    }
                />
            </Conteudo>
        </FormSection>
    )
}

interface FichaFormCmpProps extends FichaFormCommonProps {
    disabledNenhum: boolean
    disabledImplantePrevio: boolean
    disabledTipoTransfusaoSanguinea: boolean
    disabledTribologico: boolean
    disabledRestritorCimentoFemoral: boolean
    disabledTipoRestritorCimentoFemoral: boolean
    disabledTipoAcetabularPolietileno: boolean
    disabledMetalico: boolean
    disabledParafusos: boolean
    disabledPossuiCimentoOsseo: boolean
    disabledPossuiEnxertiaFemoral: boolean
    disabledPossuiEnxertiaAcetabular: boolean
    disabledAntibiotico: boolean
    tipoArtroplastia
    disableTipoArtroplastia: boolean
    ceramicaCeramica: boolean
    duracao: string
}

const FichaFormCmp = (props: FichaFormCmpProps) => {
    const dispatch = useDispatch()

    // mimics componentWillUnmount
    useEffect(
        () => () => {
            dispatch(clearEdicaoProcedimentoData())
            dispatch(pacienteActionCreators.clear(''))
        },
        [dispatch]
    )
    return (
        <div>
            <Form>
                <CardPaciente paciente={props.paciente} lado={props.lado} procedimento={props.procedimento} />
                <Scroll.Element name='diagnostico'>
                    <SectionTitle title='DIAGNÓSTICO' />
                    <MotivoArtroplastiaFormSection />
                </Scroll.Element>
                <Scroll.Element name='procedimentoCirurgicoPrevio'>
                    <SectionTitle title='PROCEDIMENTO CIRÚRGICO PRÉVIO' />
                    <ProcedimentoCirurgicoPrevioFormSection
                        disabledNenhum={props.disabledNenhum}
                        disabledImplantePrevio={props.disabledImplantePrevio}
                    />
                </Scroll.Element>
                <Scroll.Element name='procedimentoCirurgicoAtual'>
                    <SectionTitle title='PROCEDIMENTO CIRÚRGICO ATUAL' />
                    <ProcedimentoCirurgicoAtualFormSection
                        duracao={props.duracao}
                        disabledTipoTransfusaoSanguinea={props.disabledTipoTransfusaoSanguinea}
                        disabledAntibiotico={props.disabledAntibiotico}
                    />
                </Scroll.Element>
                <Scroll.Element name='tipoArtroplastiaForm'>
                    <SectionTitle title='TIPO DE ARTROPLASTIA' />
                    <TipoArtroplastiaFormSection
                        disabledTribologico={props.disabledTribologico}
                        disabledTipoArtroplastia={props.disableTipoArtroplastia}
                    />
                </Scroll.Element>
                <Scroll.Element name='implantes'>
                    <SectionTitle title='IMPLANTES' />
                    <ImplantesFormSection
                        disabledRestritorCimentoFemoral={props.disabledRestritorCimentoFemoral}
                        disabledTipoRestritorCimentoFemoral={props.disabledTipoRestritorCimentoFemoral}
                        disabledTipoAcetabularPolietileno={props.disabledTipoAcetabularPolietileno}
                        disabledMetalico={props.disabledMetalico}
                        disabledParafusos={props.disabledParafusos}
                        ceramicaCeramica={props.ceramicaCeramica}
                    />
                </Scroll.Element>
                <Scroll.Element name='cimentoOsseo'>
                    <SectionTitle title='CIMENTO ÓSSEO' />
                    <CimentoOsseoFormSection disabledPossuiCimentoOsseo={props.disabledPossuiCimentoOsseo} />
                </Scroll.Element>
                <Scroll.Element name='enxertia'>
                    <SectionTitle title='ENXERTIA' />
                    <EnxertiaFormSection
                        disabledPossuiEnxertiaAcetabular={props.disabledPossuiEnxertiaAcetabular}
                        disabledPossuiEnxertiaFemoral={props.disabledPossuiEnxertiaFemoral}
                    />
                </Scroll.Element>
                <Scroll.Element name='componentesUtilizados'>
                    <SectionTitle title='COMPONENTES UTILIZADOS' />
                    <ComponentesUtilizadosFormSection tipoArtroplastia={props.tipoArtroplastia} />
                </Scroll.Element>
            </Form>
            <Conteudo>
                <Linha size='medium' />
                <ButtonBar>
                    <Button pulledLeft size='medium' className='simple-button' onClick={props.onCancel}>
                        Cancelar
                    </Button>
                    <Button
                        pulledRight
                        type='success'
                        size='medium'
                        loading={props.submitting}
                        onClick={props.handleSubmit}
                        disabled={props.pristine}
                    >
                        Salvar registro
                    </Button>
                </ButtonBar>
            </Conteudo>
        </div>
    )
}

const selector = formValueSelector(formName)

const FichaForm = ({ editMode, ...rest }: FichaFormWrapProps) => {
    const Form = useMemo(
        () =>
            form<{}, FichaFormCmpProps>({
                form: formName,
                validate: FichaPrimariaQuadrilValidator as any,
                successTitle: editMode ? 'Edição realizada!' : 'Ficha registrada!',
                successModal: ModalSave,
                successContent: (
                    <span>
                        O registro foi salvo com sucesso. Se preferir, você pode imprimi-lo ou voltar à página inicial
                        de seu perfil.
                    </span>
                ),
                hasLeaveModal: false,
            })(
                connect(state => {
                    const procedPrevio = selector(state, 'procedimentoCirurgicoPrevio.procedimentoPrevio')
                    const disabledNenhum = procedPrevio && procedPrevio.includes('NENHUM')

                    const disabledImplantePrevio =
                        selector(state, 'procedimentoCirurgicoPrevio.implantePrevio') !== 'SIM'

                    const disabledTipoTransfusaoSanguinea =
                        selector(state, 'procedimentoCirurgicoAtual.transfusaoSanguinea') !== 'SIM'

                    const disabledAntibiotico =
                        selector(state, 'procedimentoCirurgicoAtual.possuiAntibiotico') !== 'SIM'

                    const tipoArtroplastia = selector(state, 'tipoArtroplastiaForm.tipoArtroplastia')

                    const disabledTribologico = !tipoArtroplastia || tipoArtroplastia.id !== 'CONVENCIONAL'

                    const disabledRestritorCimentoFemoral = selector(state, 'implantes.femoralCimentado') !== 'SIM'
                    const disabledTipoRestritorCimentoFemoral = selector(state, 'implantes.restritorCimento') !== 'SIM'

                    const disabledTipoAcetabularPolietileno = selector(state, 'implantes.polietileno')
                    const disabledMetalico = selector(state, 'implantes.metalico')
                    const disabledParafusos = selector(state, 'implantes.acetabularCimentado') === 'NAO'

                    const disabledPossuiCimentoOsseo =
                        selector(state, 'implantes.acetabularCimentado') !== 'SIM' &&
                        selector(state, 'implantes.femoralCimentado') !== 'SIM'

                    const disabledPossuiEnxertiaFemoral = !selector(state, 'implantes.femoral')
                    const disabledPossuiEnxertiaAcetabular = !selector(state, 'implantes.acetabular')

                    const componentesPrincipais = selector(state, 'componentesUtilizadosForm.componentesPrincipais')
                    const disableTipoArtroplastia = componentesPrincipais && componentesPrincipais.size > 0

                    const parTribologico = selector(state, 'tipoArtroplastiaForm.parTribologico')
                    const ceramicaCeramica = parTribologico && parTribologico.id === 'CERAMICA_CERAMICA'

                    const inicio = selector(state, 'procedimentoCirurgicoAtual.horaInicio')
                    const termino = selector(state, 'procedimentoCirurgicoAtual.horaTermino')
                    const hasHoras = inicio && termino
                    const duracao = hasHoras && DateUtil.calculateDuracaoHoras(inicio, termino)

                    return {
                        disabledNenhum,
                        disabledImplantePrevio,
                        disabledTipoTransfusaoSanguinea,
                        disabledTribologico,
                        disabledRestritorCimentoFemoral,
                        disabledTipoRestritorCimentoFemoral,
                        disabledTipoAcetabularPolietileno,
                        disabledMetalico,
                        disabledParafusos,
                        disabledPossuiCimentoOsseo,
                        disabledPossuiEnxertiaFemoral,
                        disabledPossuiEnxertiaAcetabular,
                        disabledAntibiotico,
                        tipoArtroplastia,
                        disableTipoArtroplastia,
                        ceramicaCeramica,
                        duracao,
                    }
                })(FichaFormCmp)
            ),
        [editMode]
    )
    return <Form {...rest} />
}
