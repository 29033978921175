import * as React from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Prompt, Redirect, Route, Switch } from 'react-router-dom'
import { clearEdicaoProcedimentoData } from 'redux/modules/edicaoProcedimento'
import { actionCreators as pacienteActionCreators } from 'redux/modules/paciente'
import FichaStentCoronariaComplementarContainer from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaComplementarContainer'
import FichaStentCoronariaEssencialContainer from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaEssencialContainer'

interface StentCoronariaProps extends RouteComponentProps<any> {
    destroy(): void
    showLeaving: boolean
    editMode: boolean
}

export const FichaStentCoronariaView = (props: StentCoronariaProps) => {
    // mimics componentWillUnmount
    const dispatch = useDispatch()
    React.useEffect(
        () => () => {
            props.destroy()
            dispatch(clearEdicaoProcedimentoData())
            dispatch(pacienteActionCreators.clear(''))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return (
        <div>
            <Prompt
                message={location => {
                    if (location.pathname.indexOf(props.match.url) === -1 && props.showLeaving) {
                        return 'mensagem não usada'
                    }
                }}
            />
            <Switch>
                <Route
                    exact
                    path={`${props.match.url}`}
                    render={({ match }) => {
                        return <Redirect to={`${match.url}/essencial`} />
                    }}
                />
                <Route
                    path={`${props.match.url}/essencial`}
                    render={() => {
                        return <FichaStentCoronariaEssencialContainer {...props} />
                    }}
                />
                <Route
                    path={`${props.match.url}/complementar`}
                    render={() => {
                        return <FichaStentCoronariaComplementarContainer {...props} />
                    }}
                />
            </Switch>
        </div>
    )
}
