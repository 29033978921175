import * as React from 'react'
import {
    Card,
    CircleProgress,
    Column,
    Columns,
    ComposedLabel,
    Icon,
    LoadingStateContainer,
    NumberLabel,
} from 'libs/components'
import { DistClassificacaoSexo, ClassificacaoCount } from 'redux/modules/report/procedimento'
import { Sexo } from 'backend'
import * as enums from 'redux/enums'
import { Icons } from 'libs/components/presentational/elements/icons'

interface Props {
    dist: DistClassificacaoSexo
    showPrimariaRevisaoRatio?: boolean
}

export class SexoCard extends React.Component<Props> {
    render() {
        const dist = this.props.dist
        const result = dist.result
        let totalSexos = 0
        if (result) {
            totalSexos += result.masculino ? result.masculino.total : 0
            totalSexos += result.feminino ? result.feminino.total : 0
        }

        return (
            <Card title='Procedimentos por sexo'>
                <LoadingStateContainer readyState={dist.readyState}>
                    {result && (
                        <Columns auto>
                            <Column>
                                <SexoCardRow
                                    sexo='MASCULINO'
                                    {...result.masculino}
                                    totalSexos={totalSexos}
                                    showPrimariaRevisaoRatio={this.props.showPrimariaRevisaoRatio}
                                />
                            </Column>
                            <Column>
                                <SexoCardRow
                                    sexo='FEMININO'
                                    {...result.feminino}
                                    totalSexos={totalSexos}
                                    showPrimariaRevisaoRatio={this.props.showPrimariaRevisaoRatio}
                                />
                            </Column>
                        </Columns>
                    )}
                </LoadingStateContainer>
            </Card>
        )
    }
}

interface SexoCardRowProps extends ClassificacaoCount {
    sexo: Sexo
    totalSexos: number
    showPrimariaRevisaoRatio?: boolean
}

class SexoCardRow extends React.Component<SexoCardRowProps> {
    render() {
        const quocients = Object.entries(this.props.classificacoes).reduce(
            (obj, [key, value]) => ({
                ...obj,
                [value.className]: value.quocient,
            }),
            {}
        )

        return (
            <Columns auto className='sexo-card-row align-center'>
                <div className='flex-3'>
                    <Columns className='align-center justify-center' gapless>
                        <div style={{ marginRight: '8px' }} className='flex-0 is-children-center justify-center'>
                            <CircleProgress strokeWidth={2} size={30} progress={quocients} type='primary'>
                                <Icon
                                    size='is-x-small'
                                    icon={this.props.sexo.toLowerCase() as Icons}
                                    className='is-neon'
                                />
                            </CircleProgress>
                        </div>
                        <ComposedLabel
                            className='flex-0'
                            title={enums.sexoName[this.props.sexo]}
                            labelClassName='is-neon is-uppercase'
                            size='small'
                        >
                            <NumberLabel className='is-xx-large' value={this.props.total} placeholder='0' />{' '}
                            <NumberLabel
                                className='is-muted is-small-ter'
                                value={this.props.total > 0 && (this.props.total / this.props.totalSexos) * 100}
                                maxDecimalPlaces={1}
                                sufix='%'
                                placeholder='- %'
                            />
                        </ComposedLabel>
                    </Columns>
                </div>
                {this.props.showPrimariaRevisaoRatio && (
                    <div className='flex-3 justify-center'>
                        <Columns auto>
                            <ComposedLabel
                                key={'PRIMARIA'}
                                title={'Primária'}
                                size='small'
                                labelClassName={'is-report-color-1'}
                            >
                                <NumberLabel value={this.props.classificacoes['PRIMARIA']?.total ?? 0} />{' '}
                                <NumberLabel
                                    className='is-muted is-small-ter'
                                    value={
                                        this.props.classificacoes['PRIMARIA']?.quocient > 0 &&
                                        this.props.classificacoes['PRIMARIA']?.quocient * 100
                                    }
                                    maxDecimalPlaces={1}
                                    sufix='%'
                                    placeholder='- %'
                                />
                            </ComposedLabel>
                            <ComposedLabel
                                key={'REVISAO'}
                                title={'Revisão'}
                                size='small'
                                labelClassName={'is-report-color-2'}
                            >
                                <NumberLabel value={this.props.classificacoes['REVISAO']?.total ?? 0} />{' '}
                                <NumberLabel
                                    className='is-muted is-small-ter'
                                    value={
                                        this.props.classificacoes['REVISAO']?.quocient > 0 &&
                                        this.props.classificacoes['REVISAO']?.quocient * 100
                                    }
                                    maxDecimalPlaces={1}
                                    sufix='%'
                                    placeholder='- %'
                                />
                            </ComposedLabel>
                        </Columns>
                    </div>
                )}
            </Columns>
        )
    }
}
