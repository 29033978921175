import * as React from 'react'
import { Columns, Column, ImageUploaderField } from 'libs/components'

export interface ImageEtiquetaFieldProps {
    alertError: any
}
export const ImageEtiquetaField = ({ alertError }: ImageEtiquetaFieldProps) => (
    <Columns>
        <Column>
            <ImageUploaderField
                name='etiqueta'
                label='Foto da etiqueta'
                information='A foto deve estar no formato JPG ou PNG e deve possuir tamanho de até 5 Mb.'
                text='Arraste e solte ou clique para enviar uma foto da etiqueta do produto'
                alertError={alertError}
                required
            />
        </Column>
    </Columns>
)
