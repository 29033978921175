import * as React from 'react'
import classnames from 'classnames'
import { Text, Size } from 'libs/components/presentational/typography/Text'

export interface InvertedLabelProps {
    title: string | React.ReactElement<any>
    className?: string
    size?: Size
    maxWidth?: number
    testid?: string
}

export class InvertedLabel extends React.Component<InvertedLabelProps> {
    static defaultProps: Partial<InvertedLabelProps> = {
        size: 'big',
    }

    render() {
        return (
            <div
                className={classnames('inverted-label', 'has-text-centered', this.props.className)}
                style={{
                    maxWidth: this.props.maxWidth,
                }}
                data-testid={this.props.testid}
            >
                <Text className='is-block' size={this.props.size}>
                    {this.props.children}
                </Text>
                <Text size='small-ter' color='black-bis'>
                    {this.props.title}
                </Text>
            </div>
        )
    }
}
