import * as React from 'react'
import * as C from 'libs/components'

interface ObitoProps {
    disabled?: boolean
}

export class ObitoCard extends React.Component<ObitoProps> {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Óbito'
                name='obito'
                buttonSet={
                    <C.SimNaoNAvaliadoRadioField
                        name='obito'
                        disabledNaoAvaliado={true}
                        disabled={this.props.disabled}
                    />
                }
            >
                <C.Conteudo>
                    <C.SimNaoRadioField label='Causa cardíaca' name='obitoCausa' />
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
