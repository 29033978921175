import { ProcedimentoDimensions, UsoSistemaFiltro } from 'backend'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { State } from 'redux/modules'
import * as filtroReport from 'redux/modules/report/filtro'
import { useHistory } from 'react-router-dom'
import { clearAll } from 'redux/modules/report/usoSistema'

export function useLoadUsoSistema(load: (filtro: UsoSistemaFiltro) => void, expectedDim: ProcedimentoDimensions) {
    const stateFiltro = useSelector((state: State) => state.report.filtro.filtro)

    const local = useSelector((state: State) => state.report.filtro.local)
    const dimension: ProcedimentoDimensions = filtroReport.getCurrentDimension(local?.data || {})
    const history = useHistory()
    const dispatch = useDispatch()

    const filtroJaAplicado =
        (history.location.search.length > 0 && stateFiltro?.interval !== undefined) ||
        (history.location.search.length === 0 &&
            (stateFiltro?.interval === undefined ||
                Object.values(stateFiltro?.interval).every(value => value === undefined)))

    React.useEffect(() => {
        if ((!local || local?.readyState === 'success') && dimension === expectedDim && filtroJaAplicado) {
            load(stateFiltro)
        }
        return () => dispatch(clearAll())
        // necessário disable pois quebra teste se load está no array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateFiltro, local, filtroJaAplicado, dimension, expectedDim])
}
