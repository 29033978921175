import * as React from 'react'
import { findDOMNode } from 'react-dom'
import { Icon, IconProps, Popover } from 'libs/components'
import classnames from 'classnames'

export interface Props extends Pick<IconProps, 'size'> {
    information?: string
    spaced?: boolean
}

export class InformationIconPopover extends React.Component<Props, any> {
    private icon: Icon

    constructor(props) {
        super(props)
        this.state = {
            showPopover: false,
        }
    }

    render() {
        const className = classnames('information-icon-popover', {
            spaced: this.props.spaced,
        })
        return (
            <>
                <Icon
                    ref={icon => (this.icon = icon)}
                    className={className}
                    icon='info'
                    size={this.props.size}
                    onMouseOver={() => this.setState({ showPopover: true })}
                    onMouseLeave={() => this.setState({ showPopover: false })}
                />
                <Popover show={this.state.showPopover} placement='right' target={props => findDOMNode(this.icon)}>
                    {this.props.information}
                </Popover>
            </>
        )
    }
}
