import { isImmutable } from 'immutable'
import {
    Button,
    ButtonBar,
    Column,
    Columns,
    Conteudo,
    ControlField,
    DatePickerField,
    Form,
    Label,
    Linha,
    SectionTitle,
    SimNaoRadioField,
    StyledRadioField,
} from 'libs/components'
import { InicioTerminoPanel } from 'libs/components/presentational/form/InicioTerminoPanel'
import form from 'libs/redux-form/form'
import * as DateUtil from 'libs/util/DateUtil'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { connect, useSelector } from 'react-redux'
import Scroll from 'react-scroll'
import { FormSection, formValueSelector } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { EdicaoState } from 'redux/modules/edicaoProcedimento'
import FichaStentCoronariaValidator from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaValidator'
import { FichaFormCommonProps } from '../../procedimentos/ProcedimentosView'
import {
    AdicionarLesaoEnxerto,
    AdicionarLesaoNativa,
    AdicionarStent,
    AngioTCCoronariaCard,
    CaraterIcp,
    CardPaciente,
    CintilografiaMiocardicaCard,
    EcoEstresseCard,
    PadraoObstrutivoField,
    QuadroClinicoField,
    TesteErgometricoCard,
} from '../componentes'
import FichaPromptLeave from '../componentes/FichaPromptLeave'

const formName = 'stentCoronaria'

export const options = {
    'segmento-1': '1 - Coronária direita proximal',
    'segmento-2': '2 - Coronária direita terço médio',
    'segmento-3': '3 - Coronária direita terço distal',
    'segmento-4': '4 - Descendente posterior',
    'segmento-5': '5 - Tronco da coronária esquerda',
    'segmento-6': '6 - Descendente anterior proximal',
    'segmento-7': '7 - Descendente anterior terço médio',
    'segmento-8': '8 - Descendente anterior terço distal',
    'segmento-9': '9 - Primeiro ramo diagonal',
    'segmento-9a': '9a - Ramo do primeiro diagonal',
    'segmento-10': '10 - Segundo ramo diagonal',
    'segmento-10a': '10a - Ramo do segundo diagonal',
    'segmento-11': '11 - Circunflexa proximal',
    'segmento-12': '12 - Ramo diagonalis',
    'segmento-12a': '12a - Primeiro ramo marginal',
    'segmento-12b': '12b - Segundo ramo marginal',
    'segmento-13': '13 - Circunflexa distal',
    'segmento-14': '14 - Ramos marginais adicionais',
    'segmento-14a': '14a - Ramos marginais adicionais',
    'segmento-14b': '14b - Ramos marginais adicionais',
    'segmento-15': '15 - Descendente posterior',
    'segmento-16': '16 - Ventricular posterior',
    'segmento-16a': '16a - Ramo da ventricular posterior',
    'segmento-16b': '16b - Ramo da ventricular posterior',
    'segmento-16c': '16c - Ramo da ventricular posterior',
}

const FichaStentCoronariaEssencialView = props => {
    const edicaoStentCoronariaData = useSelector<State, EdicaoState>(state => state.edicao)

    const showModalLeave = React.useRef<boolean>(true)

    const handleSubmitFail = error => {
        if (typeof error === 'string') {
            showModalLeave.current = false
            props.goHome()
        }
    }

    if (props.editMode && isEmpty(edicaoStentCoronariaData)) {
        return null
    }

    return (
        <>
            <FichaPromptLeave formName={formName} showModalLeave={showModalLeave} />
            <FichaForm
                editMode={props.editMode}
                onSubmit={props.onSubmit}
                onSubmitSuccess={props.onSubmitSuccess}
                onCancel={props.goHome}
                onSubmitFail={handleSubmitFail}
                lado={props.lado}
                paciente={props.paciente}
                procedimento={props.procedimento}
                initialValues={edicaoStentCoronariaData}
            />
        </>
    )
}
export default FichaStentCoronariaEssencialView

const DadosProcedimentoFormSection = props => {
    return (
        <FormSection name='dadosProcedimento'>
            <Conteudo>
                <Columns>
                    <Column size='4'>
                        <DatePickerField
                            name='dataCirurgia'
                            label='Data da cirurgia'
                            placeholder='dd/mm/aaaa'
                            required
                        />
                    </Column>
                </Columns>
                <InicioTerminoPanel duracao={props.duracao} />
            </Conteudo>
        </FormSection>
    )
}

const LesaoTratadaFormSection = props => {
    return (
        <FormSection name='lesoesTratadas'>
            <Conteudo>
                <Columns>
                    <Column>
                        <ControlField label='Dominância' name='dominancia' required>
                            <StyledRadioField name='dominancia' value='ESQUERDO' disabled={!props.semLesao}>
                                Esquerda
                            </StyledRadioField>
                            <StyledRadioField name='dominancia' value='DIREITO' disabled={!props.semLesao}>
                                Direita
                            </StyledRadioField>
                        </ControlField>
                    </Column>
                </Columns>
                <Linha />
                <ControlField name='lesoes' label='Lesões tratadas' className='text-is-16' required />
            </Conteudo>

            <AdicionarLesaoNativa
                name='lesoesNativas'
                disabled={props.disabledAddLesao}
                lesoesRelacionadas={props.lesoesRelacionadas}
                options={options}
            />
            <Linha />
            <AdicionarLesaoEnxerto
                name='lesoesEnxerto'
                disabled={props.disabledAddLesao}
                lesoesRelacionadas={props.lesoesRelacionadas}
            />
        </FormSection>
    )
}

const StentUtilizadoFormSection = props => {
    return (
        <FormSection name='stentsUtilizados'>
            <AdicionarStent name='stents' disabled={props.semLesao} options={options} />
        </FormSection>
    )
}

const ApresentacaoClinicaFormSection = props => {
    return (
        <FormSection name='apresentacaoClinica'>
            <Conteudo>
                <Columns>
                    <Column size='6'>
                        <QuadroClinicoField label='Quadro clínico' name='quadroClinico' required />
                    </Column>
                    <Column size='6'>
                        <ControlField
                            label='Classe funcional'
                            name='classeFuncional'
                            required={!props.disableClasseFuncional}
                        >
                            <StyledRadioField
                                skin='square-button'
                                name='classeFuncional'
                                value='I'
                                disabled={props.disableClasseFuncional}
                            >
                                I
                            </StyledRadioField>
                            <StyledRadioField
                                skin='square-button'
                                name='classeFuncional'
                                value='II'
                                disabled={props.disableClasseFuncional}
                            >
                                {' '}
                                II
                            </StyledRadioField>
                            <StyledRadioField
                                skin='square-button'
                                name='classeFuncional'
                                value='III'
                                disabled={props.disableClasseFuncional}
                            >
                                III
                            </StyledRadioField>
                            <StyledRadioField
                                skin='square-button'
                                name='classeFuncional'
                                value='IV'
                                disabled={props.disableClasseFuncional}
                            >
                                IV
                            </StyledRadioField>
                        </ControlField>
                    </Column>
                </Columns>
                <Columns verticalFlow>
                    <Column size='1' />
                    <Column>
                        <Label> Caráter ICP </Label>
                        <CaraterIcp
                            tipoCaraterICP={props.tipoCaraterICP}
                            quadroClinico={props.quadroClinico}
                            tempoTransferencia={props.tempoTransferencia}
                            portaBalao={props.portaBalao}
                            dorPorta={props.dorPorta}
                            showCaraterIcp={props.showCaraterIcp}
                            transferenciaIcp={props.transferenciaIcp}
                            diaSaidaTransferencia={props.diaSaidaTransferencia}
                            diaChegadaTransferencia={props.diaChegadaTransferencia}
                            diaInicioSintomas={props.diaInicioSintomas}
                            diaAdmissaoHospital={props.diaAdmissaoHospital}
                            diaAberturaVaso={props.diaAberturaVaso}
                        />
                    </Column>
                </Columns>
            </Conteudo>
            <Linha />
            <Conteudo>
                <Columns>
                    <Column size='6'>
                        <PadraoObstrutivoField label='Padrão obstrutivo' name='padraoObstrutivo' />
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const IsquemiaImagemFormSection = props => {
    return (
        <FormSection name='isquemia'>
            <Conteudo>
                <SimNaoRadioField
                    label='Foi realizado teste não invasivo de isquemia/imagem'
                    name='testeNaoInvasivo'
                    required={props.requiredIsquemia}
                />
                <AngioTCCoronariaCard
                    disabled={props.disableIsquemiaCards}
                    disableEscoreCalcio={props.disableEscoreCalcio}
                />
                <CintilografiaMiocardicaCard disabled={props.disableIsquemiaCards} />
                <EcoEstresseCard disabled={props.disableIsquemiaCards} />
                <TesteErgometricoCard disabled={props.disableIsquemiaCards} />
            </Conteudo>
        </FormSection>
    )
}

interface FichaFormCmpProps extends FichaFormCommonProps {
    duracao: string
    disabledAddLesao: boolean
    semLesao: boolean
    lesoesRelacionadas: any

    quadroClinico: any
    tipoCaraterICP: any
    disableClasseFuncional: boolean
    transferenciaIcp: boolean
    dorPorta: string
    portaBalao: string
    tempoTransferencia: string
    disableIsquemiaCards: boolean
    disableEscoreCalcio: boolean
    requiredIsquemia: boolean

    diaSaidaTransferencia: string
    diaChegadaTransferencia: string
    diaInicioSintomas: string
    diaAdmissaoHospital: string
    diaAberturaVaso: string

    modalOpen: boolean
    onClose: () => void

    editMode: boolean
}

const FichaStentCoronariaEssencialViewCmp = (props: FichaFormCmpProps) => {
    return (
        <Form>
            <FormSection name='essencial'>
                <CardPaciente paciente={props.paciente} procedimento={props.procedimento} />
                <Scroll.Element name='dados'>
                    <SectionTitle title='DADOS DO PROCEDIMENTO' />
                    <DadosProcedimentoFormSection
                        onClose={props.onClose}
                        modalOpen={props.modalOpen}
                        duracao={props.duracao}
                    />
                </Scroll.Element>
                <Scroll.Element name='lesao'>
                    <SectionTitle title='LESÃO TRATADA' />
                    <LesaoTratadaFormSection
                        semLesao={props.semLesao}
                        disabledAddLesao={props.disabledAddLesao}
                        lesoesRelacionadas={props.lesoesRelacionadas}
                    />
                </Scroll.Element>
                <Scroll.Element name='stent'>
                    <SectionTitle title='STENTS UTILIZADOS' />
                    <StentUtilizadoFormSection semLesao={props.semLesao} />
                </Scroll.Element>
                <Scroll.Element name='apresentacaoClinica'>
                    <SectionTitle title='APRESENTAÇÃO CLÍNICA' />
                    <ApresentacaoClinicaFormSection
                        dorPorta={props.dorPorta}
                        tipoCaraterICP={props.tipoCaraterICP}
                        disableClasseFuncional={props.disableClasseFuncional}
                        tempoTransferencia={props.tempoTransferencia}
                        portaBalao={props.portaBalao}
                        quadroClinico={props.quadroClinico}
                        transferenciaIcp={props.transferenciaIcp}
                        diaSaidaTransferencia={props.diaSaidaTransferencia}
                        diaChegadaTransferencia={props.diaChegadaTransferencia}
                        diaInicioSintomas={props.diaInicioSintomas}
                        diaAdmissaoHospital={props.diaAdmissaoHospital}
                        diaAberturaVaso={props.diaAberturaVaso}
                    />
                </Scroll.Element>
                <Scroll.Element name='isquemia'>
                    <SectionTitle title='TESTE ISQUEMIA/IMAGEM' />
                    <IsquemiaImagemFormSection
                        disableIsquemiaCards={props.disableIsquemiaCards}
                        disableEscoreCalcio={props.disableEscoreCalcio}
                        requiredIsquemia={props.requiredIsquemia}
                    />
                </Scroll.Element>
                <Conteudo>
                    <Column />
                    <Linha size='medium' />
                    <ButtonBar>
                        <Button pulledLeft size='medium' className='simple-button' onClick={props.onCancel}>
                            Cancelar
                        </Button>
                        <Button
                            pulledRight
                            type='success'
                            size='medium'
                            loading={props.submitting}
                            onClick={props.handleSubmit}
                            disabled={!props.editMode && props.pristine}
                        >
                            Salvar registro
                        </Button>
                    </ButtonBar>
                </Conteudo>
            </FormSection>
        </Form>
    )
}

const selector = formValueSelector(formName)

const FichaForm = form<{}, FichaFormCmpProps>({
    form: formName,
    validate: FichaStentCoronariaValidator as any,
    hasLeaveModal: false,
    hasSuccessModal: false,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(
    connect(state => {
        const inicio = selector(state, 'essencial.dadosProcedimento.horaInicio')
        const termino = selector(state, 'essencial.dadosProcedimento.horaTermino')
        const hasHoras = inicio && termino
        const duracao = hasHoras && DateUtil.calculateDuracaoHoras(inicio, termino)

        const disabledAddLesao = selector(state, 'essencial.lesoesTratadas.dominancia') === undefined

        const lesaoNativa = selector(state, 'essencial.lesoesTratadas.lesoesNativas')
        const lesaoEnxerto = selector(state, 'essencial.lesoesTratadas.lesoesEnxerto')
        const semLesao = (!lesaoNativa || lesaoNativa.size === 0) && (!lesaoEnxerto || lesaoEnxerto.size === 0)
        const stent = selector(state, 'essencial.stentsUtilizados.stents')

        const lesoesRelacionadas = []
        stent &&
            stent.forEach(function(element) {
                const asLesoes = element.get('lesoesRegistradas')
                asLesoes &&
                    asLesoes.forEach(function(elementI, indexI, mapI) {
                        const lesao = mapI.get(indexI)
                        if (lesao.length > 0 || lesao.size > 0) {
                            lesoesRelacionadas.push(indexI)
                        }
                    })
                if (element.get('enxertoStent')) {
                    if (isImmutable(element.get('enxertoStent'))) {
                        lesoesRelacionadas.push(element.get('enxertoStent').get('id'))
                    } else {
                        lesoesRelacionadas.push(element.get('enxertoStent').id)
                    }
                }
            })

        const quadroClinico = selector(state, 'essencial.apresentacaoClinica.quadroClinico')
        const tipoCaraterICP = selector(state, 'essencial.apresentacaoClinica.tipoCaraterICP')
        let disableClasseFuncional
        if (isImmutable(quadroClinico)) {
            disableClasseFuncional = quadroClinico?.get('id') !== 'ANGINA_ESTAVEL'
        } else {
            disableClasseFuncional = quadroClinico?.id !== 'ANGINA_ESTAVEL'
        }

        const requiredIsquemia = quadroClinico && quadroClinico.id === 'ASSINTOMATICO'

        const transferenciaIcp = selector(state, 'essencial.apresentacaoClinica.transferenciaIcpPrimaria') === 'SIM'

        const diaInicioSintomas = selector(state, 'essencial.apresentacaoClinica.dataInicioSintomas')
        const horaInicioSintomas = selector(state, 'essencial.apresentacaoClinica.horaInicioSintomas')
        const diaAdmissaoHospital = selector(state, 'essencial.apresentacaoClinica.dataAdmissao')
        const horaAdmissaoHospital = selector(state, 'essencial.apresentacaoClinica.horaAdmissao')
        const diaAberturaVaso = selector(state, 'essencial.apresentacaoClinica.dataAberturaVaso')
        const horaAberturaVaso = selector(state, 'essencial.apresentacaoClinica.horaAberturaVaso')

        const hasDatasDorPorta = diaInicioSintomas && horaInicioSintomas && diaAdmissaoHospital && horaAdmissaoHospital
        const dorPorta =
            hasDatasDorPorta &&
            DateUtil.getPeriodBetweenDates(
                diaInicioSintomas,
                horaInicioSintomas,
                diaAdmissaoHospital,
                horaAdmissaoHospital
            )

        const hasDatasPortaBalao = diaAdmissaoHospital && horaAdmissaoHospital && diaAberturaVaso && horaAberturaVaso
        const portaBalao =
            hasDatasPortaBalao &&
            DateUtil.getPeriodBetweenDates(diaAdmissaoHospital, horaAdmissaoHospital, diaAberturaVaso, horaAberturaVaso)

        const diaSaidaTransferencia = selector(state, 'essencial.apresentacaoClinica.dataContatoHospital')
        const horaSaidaTransferencia = selector(state, 'essencial.apresentacaoClinica.horaContatoHospital')
        const diaChegadaTransferencia = selector(state, 'essencial.apresentacaoClinica.dataChegadaIcp')
        const horaChegadaTransferencia = selector(state, 'essencial.apresentacaoClinica.horaChegadaIcp')

        const hasDatasTempoTransf =
            diaSaidaTransferencia && horaSaidaTransferencia && diaChegadaTransferencia && horaChegadaTransferencia
        const tempoTransferencia =
            hasDatasTempoTransf &&
            DateUtil.getPeriodBetweenDates(
                diaSaidaTransferencia,
                horaSaidaTransferencia,
                diaChegadaTransferencia,
                horaChegadaTransferencia
            )

        const disableIsquemiaCards = selector(state, 'essencial.isquemia.testeNaoInvasivo') !== 'SIM'
        const disableEscoreCalcio = selector(state, 'essencial.isquemia.escoreCalcio') !== 'SIM'

        return {
            duracao,
            disabledAddLesao,
            semLesao,
            lesoesRelacionadas,

            quadroClinico,
            tipoCaraterICP,
            disableClasseFuncional,
            transferenciaIcp,
            dorPorta,
            portaBalao,
            tempoTransferencia,
            disableIsquemiaCards,
            disableEscoreCalcio,
            requiredIsquemia,

            diaSaidaTransferencia,
            diaChegadaTransferencia,
            diaInicioSintomas,
            diaAdmissaoHospital,
            diaAberturaVaso,
        }
    })(FichaStentCoronariaEssencialViewCmp)
)
