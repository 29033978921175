import React from 'react'
import * as C from 'libs/components'
import iconPaciente from 'images/home/cidadao/ic-paciente.svg'

export default class ShowcaseView extends React.Component {
    render() {
        return (
            <div>
                <C.SectionTitle title='CAMPOS' />
                <C.Columns>
                    <C.Column verticalFlow size='2'>
                        <figure className='align-center image is-96x96'>
                            <img src={iconPaciente} alt='paciente' />
                        </figure>
                    </C.Column>
                    <C.Column size='6'>
                        <C.FormField label='Campo ativo'>
                            <C.TextInput placeholder='form-control' />
                        </C.FormField>
                        <C.FormField error='Mensagem de erro' label='Campo com erro'>
                            <C.TextInput placeholder='form-control' />
                        </C.FormField>
                        <C.FormField label='Checkbox'>
                            <C.Checkbox label='label' />
                        </C.FormField>
                        <C.FormField label='Checkbox desabilitado'>
                            <C.Checkbox disabled label='label' />
                        </C.FormField>
                        <C.FormField label='Checkbox marcado desabilitado'>
                            <C.Checkbox checked disabled label='label' />
                        </C.FormField>
                        <C.FormField label='Radio'>
                            <C.RadioButton label='label' />
                        </C.FormField>
                        <C.FormField label='Radio desabilitado'>
                            <C.RadioButton disabled label='label' />
                        </C.FormField>
                        <C.FormField label='Radio marcado desabilitado'>
                            <C.RadioButton checked disabled value='1' label='label' />
                        </C.FormField>
                    </C.Column>
                </C.Columns>
                <C.SectionTitle title='BOTÕES' />
                <C.Columns>
                    <C.Column size='2'>
                        <C.Button type='primary' size='medium'>
                            Primary
                        </C.Button>
                    </C.Column>
                    <C.Column size='2'>
                        <C.Button type='primary' disabled size='medium'>
                            Primary
                        </C.Button>
                    </C.Column>
                    <C.Column size='3'>
                        <C.Button type='primary' outlined size='medium'>
                            Primary outlined
                        </C.Button>
                    </C.Column>
                    <C.Column size='3'>
                        <C.Button type='primary' disabled outlined size='medium'>
                            Primary outlined
                        </C.Button>
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column size='2'>
                        <C.Button size='medium'>Button</C.Button>
                    </C.Column>
                    <C.Column size='2'>
                        <C.Button disabled size='medium'>
                            Button
                        </C.Button>
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column size='3'>
                        <C.Button type='primary' fullwidth>
                            Entrar
                        </C.Button>
                    </C.Column>
                </C.Columns>
                <C.SectionTitle title='MENSAGENS DE FEEDBACK' />
                <C.Button type='success' fullwidth size='medium' onClick={this.success.bind(this)}>
                    Sucesso
                </C.Button>
                <C.Button type='danger' fullwidth size='medium' onClick={this.error.bind(this)}>
                    Erro
                </C.Button>
                <C.Button type='warning' fullwidth size='medium' onClick={this.warn.bind(this)}>
                    Aviso
                </C.Button>
                <C.Button type='info' fullwidth size='medium' onClick={this.info.bind(this)}>
                    Info
                </C.Button>
            </div>
        )
    }

    success() {
        this.props.success('Sucesso')
    }

    error() {
        this.props.error('Error')
    }

    warn() {
        this.props.warn('Warn')
    }

    info() {
        this.props.info('Info')
    }
}
