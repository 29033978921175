import * as React from 'react'
import classnames from 'classnames'

interface HorizontalProps {
    className?: string
}

export default class Horizontal extends React.PureComponent<HorizontalProps> {
    static defaultProps: Partial<HorizontalProps> = {}

    render() {
        const classes = classnames('horizontal', this.props.className)
        return <div className={classes}>{this.props.children}</div>
    }
}
