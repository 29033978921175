import * as React from 'react'
import { TempoAteRevisao } from 'model'
import { IconBadge } from 'libs/components'
import { Icons } from 'libs/components/presentational/elements/icons'

const map: { [key in TempoAteRevisao]: { icon: Icons; className: string } } = {
    MENOS_5_ANOS: { icon: 'invalido', className: 'is-danger' },
    ENTRE_5_E_10_ANOS: { icon: 'invalido', className: 'is-warning' },
    MAIS_10_ANOS: { icon: 'aprovar', className: 'is-neon' },
}

interface Props {
    tempoAteRevisao: TempoAteRevisao
}

export const IconTempoAteRevisao = (props: Props) => {
    const { tempoAteRevisao } = props
    const { icon, className } = map[tempoAteRevisao]

    return <IconBadge icon={icon} className={className} />
}
