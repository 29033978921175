import { Button, Text, Modal, Image } from 'libs/components'
import React from 'react'
import RelacaoEmpresas from 'api/RelacaoEmpresas'
import { useHistory } from 'react-router-dom'
import atualizarRelacaoEmpresas from 'images/home/relacaoEmpresas/image-atualizarRelacaoEmpresas.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
    getRelacaoResposta,
    openTooltipRelacaoEmpresas,
    clearRelacaoResposta,
    showNotificationDot,
} from 'redux/modules/relacaoEmpresas'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { RelacaoRespostaDto } from 'backend'
import { State } from 'redux/modules'
import { serverMoment } from 'libs/util/DateUtil'
import moment from 'moment'

export const RelacaoEmpresasModalNotificacao: React.FC = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getRelacaoResposta())
        return () => dispatch(clearRelacaoResposta())
    }, [dispatch])

    const relacaoResposta = useSelector<State, PromiseRecord<RelacaoRespostaDto>>(
        state => state.relacaoEmpresas.resposta
    )

    const [isOpen, setIsOpen] = React.useState<boolean>()

    React.useEffect(() => {
        const firstTime: boolean = !relacaoResposta?.data?.resposta && relacaoResposta?.readyState === 'success'
        const isNaoRespondeu: boolean = relacaoResposta?.data?.resposta === 'NAO_RESPONDEU'
        const twoMonthsAgo: boolean = serverMoment(relacaoResposta?.data?.dataResposta?.toString()).isBefore(
            moment()
                .subtract(2, 'M')
                .startOf('d')
        )
        const twoMonthsWithoutAnswer: boolean = isNaoRespondeu && twoMonthsAgo

        const shouldOpenModal: boolean = firstTime || twoMonthsWithoutAnswer
        setIsOpen(shouldOpenModal)

        if (!shouldOpenModal && isNaoRespondeu) {
            dispatch(showNotificationDot())
        }
    }, [dispatch, relacaoResposta])

    const handleMaisTarde = () => {
        setIsOpen(false)
        if (!relacaoResposta?.data?.resposta) {
            RelacaoEmpresas.saveSemResposta()
        } else {
            RelacaoEmpresas.updateSemResposta(relacaoResposta.data.idResposta)
        }
        dispatch(openTooltipRelacaoEmpresas())
        dispatch(showNotificationDot())
    }

    const handleAgora = () => {
        setIsOpen(false)
        history.push('/home/relacaoEmpresas')
        dispatch(showNotificationDot())
    }

    return (
        <Modal active={isOpen}>
            <div className='relacao-empresas--notificacao'>
                <Image src={atualizarRelacaoEmpresas} size='200x200' />
                <Text className='relacao-empresas--notificacao-title'>Atualize suas relações com empresas!</Text>
                <Text color='black-ter' className='relacao-empresas--notificacao-subtitle'>
                    Agora é possível adicionar relações com empresas fabricantes de dispositivos médicos. Atualize as
                    informações nas opções do seu perfil.
                </Text>
                <div className='relacao-empresas--notificacao-buttons'>
                    <Button className='notificacao-buttons--mais-tarde' onClick={handleMaisTarde}>
                        Atualizar mais tarde
                    </Button>
                    <Button className='notificacao-buttons--agora' type='primary' onClick={handleAgora}>
                        Atualizar agora
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
