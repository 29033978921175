import React from 'react'
import * as C from 'libs/components'

export class ComponenteComplementar extends React.Component {
    render() {
        return (
            <C.ControlField label={this.props.label} name={this.props.name}>
                <C.SimpleSelect
                    isDisabled={this.props.disabled}
                    onChange={(value, action) => this.props.onChangeComponente(value, action)}
                    url='/api/procedimentos/componentes'
                    value={this.props.value}
                    customizeParams={() => {
                        return {
                            tipoProcedimento: this.props.tipoProcedimento,
                            complementar: true,
                        }
                    }}
                />
            </C.ControlField>
        )
    }
}
