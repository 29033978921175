import { RelacaoEmpresasDto } from 'backend'
import { useAxiosGet } from 'hooks/useApiRequest'
import React from 'react'
import { ReadyState } from 'redux/requester'
import Report from 'api/Report'
import { Linha, Text } from 'libs/components'
import { buildTiposRelacoesText } from 'redux/enums'

interface RelacaoEmpresasReportProfOpenRowProps {
    idResposta: number
    changeReadyState: (readyState: ReadyState) => void
}

const RelacaoEmpresasReportProfOpenRow: React.FC<RelacaoEmpresasReportProfOpenRowProps> = props => {
    const { idResposta, changeReadyState } = props
    const { data, requestState } = useAxiosGet<RelacaoEmpresasDto[]>(Report.getRelacaoEmpresas(idResposta))

    React.useEffect(() => {
        changeReadyState(requestState)
    }, [changeReadyState, requestState])

    if (requestState === 'request' || data === null || data === undefined) {
        return null
    }

    return (
        <div className='report-relacao--inner-row'>
            {data.map((relacaoEmpresa: RelacaoEmpresasDto, index: number) => {
                return (
                    <div key={relacaoEmpresa.idEmpresa}>
                        {index !== 0 && <Linha />}
                        <div>
                            <Text
                                weight='semibold'
                                color='black-bis'
                                size='small-ter'
                                style={{ letterSpacing: '0.2px' }}
                            >
                                {relacaoEmpresa.nomeEmpresa}
                            </Text>
                        </div>
                        <div className='report-inner-row--relacoes-line'>
                            <div>
                                <Text weight='semibold' color='black-bis' size='small-ter'>
                                    Tipo de relação
                                </Text>
                            </div>
                            <div>
                                <Text color='black-bis' size='small-ter'>
                                    {`${buildTiposRelacoesText(relacaoEmpresa.relacoes, ' / ')}`}
                                </Text>
                            </div>
                        </div>
                        <div>
                            <Text weight='semibold' color='black-bis' size='small-ter'>
                                Período de vigência
                            </Text>
                            <Text color='black-bis' size='small-ter'>
                                {` ${relacaoEmpresa.descricaoPeriodoVigencia}`}
                            </Text>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default RelacaoEmpresasReportProfOpenRow
