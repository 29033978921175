import * as React from 'react'
import classnames from 'classnames'

interface StyledControlPanelProps {
    component: React.ReactNode
    hasContent: boolean
}

export class StyledControlPanel extends React.Component<StyledControlPanelProps> {
    public static defaultProps: Partial<StyledControlPanelProps> = {
        hasContent: false,
    }

    render() {
        const classes = classnames('styled-control-panel')

        return (
            <div className={classes}>
                <div className='styled-control-panel-header'>{this.props.component}</div>
                {this.props.hasContent && <div className='styled-control-panel-content'>{this.props.children}</div>}
            </div>
        )
    }
}
