import Paciente from 'api/Paciente'
import { PerfilEnum, PerfisCardiologistas } from 'api/Perfis'
import Procedimento from 'api/Procedimento'
import { connect } from 'react-redux'
import * as alert from 'redux/modules/alert'
import * as novoProcedimento from 'redux/modules/novoProcedimento'
import { actionCreators as pacienteActionCreators } from 'redux/modules/paciente'
import CadastroPacienteView from 'view/home/paciente/CadastroPacienteView'
import { setEdicaoProcedimento } from 'redux/modules/edicaoProcedimento'

const mapStateToProps = state => {
    return {
        perfil: state.auth.user && state.auth.user.perfil,
        vinculoProfissionalMedicoResponsavel: state.novoProcedimento.vinculoProfissionalMedicoResponsavel,
        equipeMedica: state.novoProcedimento.equipeMedica,
        edicao: state.edicao,
        showNotFoundModalError: state.novoProcedimento.showNotFoundModalError,
        showSuccessModal: state.novoProcedimento.showSuccessModal,
        showContinuarModal: state.novoProcedimento.openModal,
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { perfil, vinculoProfissionalMedicoResponsavel, equipeMedica, edicao } = stateProps
    const { dispatch } = dispatchProps
    const isAngioplastia = PerfisCardiologistas.includes(vinculoProfissionalMedicoResponsavel?.perfil)
    const userIsCardiologista = PerfisCardiologistas.includes(perfil)
    const isEdicao = edicao?.idProcedimento

    return {
        ...ownProps,
        ...stateProps,
        onSave: form => Paciente.save(form.toJS()),
        afterSave: result => {
            dispatch(pacienteActionCreators.clearEdit())
            dispatch(novoProcedimento.selecionarPaciente(result.data))
            dispatch(novoProcedimento.showSuccessModal())

            if (isAngioplastia) {
                const novoRegistroFromCardiologista = !isEdicao && userIsCardiologista
                const dispatchSelecionarStentCoronaria = () =>
                    dispatch(novoProcedimento.selecionarProcedimento('stentCoronaria', ''))

                if (novoRegistroFromCardiologista) {
                    dispatchSelecionarStentCoronaria()
                } else {
                    const rascunhoForm = {
                        pacienteId: result.data.id,
                        vinculoProfissionalResponsavelId: vinculoProfissionalMedicoResponsavel.id,
                        tipoProcedimento: 'STENT_CORONARIA',
                        idsVinculosProfsEquipeMedica: equipeMedica.map(vinculoProfissional => {
                            return vinculoProfissional.id
                        }),
                    }
                    const promise = isEdicao
                        ? Procedimento.updateRascunho({ id: stateProps.edicao.idProcedimento, ...rascunhoForm })
                        : Procedimento.saveRascunho(rascunhoForm)

                    promise
                        .then(() => {
                            if (userIsCardiologista) {
                                dispatchSelecionarStentCoronaria()
                                dispatch(novoProcedimento.openModal())
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 404) {
                                dispatch(novoProcedimento.showNotFoundModalError())
                            } else {
                                dispatch(alert.error({ mensagem: 'Falha ao salvar rascunho' }))
                            }
                        })
                }
                return
            }
        },

        onCloseSuccessModal: () => {
            dispatch(novoProcedimento.closeSuccessModal())
            if (!stateProps.showNotFoundModalError && !stateProps.showContinuarModal) {
                const nextURL = ownProps.history.location.pathname.replace('/definirPaciente/cadastrarPaciente', '')
                if (isAngioplastia) {
                    if (userIsCardiologista) {
                        ownProps.history.push(`${nextURL}/stentCoronaria`)
                    } else {
                        ownProps.history.push('/home/aguardandoDadosClinicos')
                    }
                } else {
                    ownProps.history.push(`${nextURL}/procedimentos`)
                }
            }
        },

        onCloseNotFoundModal: () => {
            dispatch(novoProcedimento.closeNotFoundModalError())
            if (userIsCardiologista) {
                ownProps.history.push('/home')
            } else {
                ownProps.history.push('/home/aguardandoDadosClinicos')
            }
        },

        okModalPreencherDadosClinicos: () => {
            const nextURL = ownProps.history.location.pathname.replace('/definirPaciente/cadastrarPaciente', '')
            dispatch(novoProcedimento.closeModal())
            dispatch(setEdicaoProcedimento('stentCoronaria', ''))
            ownProps.history.push(`${nextURL}/stentCoronaria`)
            return
        },

        goHome: () => {
            dispatch(novoProcedimento.clear())
            ownProps.history.push('/home')
        },

        onCancel: () => {
            ownProps.history.goBack()
        },

        clearPaciente: () => {
            dispatch(pacienteActionCreators.clear(''))
        },
    }
}

const CadastroPacienteContainer = connect(mapStateToProps, null, mergeProps)(CadastroPacienteView)

export default CadastroPacienteContainer
