import * as React from 'react'
import { StyledChecklistItem, StyledChecklistItemProps } from 'libs/components/presentational/input/StyledChecklistItem'
import field from 'libs/redux-form/field'
import { WrappedFieldProps } from 'redux-form'

interface StyledChecklistItemFieldProps extends StyledChecklistItemProps, Partial<WrappedFieldProps> {}

class StyledChecklistItemFieldComponent extends React.Component<StyledChecklistItemFieldProps, any> {
    render() {
        return (
            <StyledChecklistItem
                {...this.props.input}
                optionValue={this.props.optionValue}
                label={this.props.label}
                disabled={this.props.disabled}
                className={this.props.className}
            />
        )
    }
}

export const StyledChecklistItemField = field({ haswrapper: false })(StyledChecklistItemFieldComponent)
