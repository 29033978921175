/* eslint-disable import/no-webpack-loader-syntax */
import _Home from '!babel-loader!mdx-loader!./home.md'
import _CadastroTecnico from '!babel-loader!mdx-loader!./acesso/cadastroTecnico.md'
import _CadastroMedico from '!babel-loader!mdx-loader!./acesso/cadastroMedico.md'
import _CadastroMedicoResidente from '!babel-loader!mdx-loader!./acesso/cadastroMedicoResidente.md'
import _CadastroAdministrativo from '!babel-loader!mdx-loader!./acesso/cadastroAdministrativo.md'
import _CadastroColaboradorAnvisa from '!babel-loader!mdx-loader!./acesso/cadastroColaboradorAnvisa.md'
import _CadastroGestorIntegracao from '!babel-loader!mdx-loader!./acesso/cadastroGestorIntegracao.md'
import _CadastroGestorSeguranca from '!babel-loader!mdx-loader!./acesso/cadastroGestorSeguranca.md'
import _CadastroGestorServicoSaude from '!babel-loader!mdx-loader!./acesso/cadastroGestorServicoSaude.md'
import _CadastroSnvs from '!babel-loader!mdx-loader!./acesso/cadastroSnvs.md'
import _FazerLoginTecnico from '!babel-loader!mdx-loader!./acesso/fazerLoginTecnico.md'
import _FazerLoginMedico from '!babel-loader!mdx-loader!./acesso/fazerLoginMedico.md'
import _FazerLoginMedicoResidente from '!babel-loader!mdx-loader!./acesso/fazerLoginMedicoResidente.md'
import _FazerLoginFinanceiro from '!babel-loader!mdx-loader!./acesso/fazerLoginFinanceiro.md'
import _FazerLoginGestorSeguranca from '!babel-loader!mdx-loader!./acesso/fazerLoginGestorSeguranca.md'
import _FazerLoginAnvisa from '!babel-loader!mdx-loader!./acesso/fazerLoginAnvisa.md'
import _FazerLoginGestorServicoSaude from '!babel-loader!mdx-loader!./acesso/fazerLoginGestorServicoSaude.md'
import _FazerLoginGestorIntegracao from '!babel-loader!mdx-loader!./acesso/fazerLoginGestorIntegracao.md'
import _FazerLoginSnvs from '!babel-loader!mdx-loader!./acesso/fazerLoginSnvs.md'
import _AlterarDadosPessoais from '!babel-loader!mdx-loader!./acesso/alterarDadosPessoais.md'
import _RecuperarSenha from '!babel-loader!mdx-loader!./acesso/recuperarSenha.md'
import _AlterarSenha from '!babel-loader!mdx-loader!./acesso/alterarSenha.md'
import _AdicionarVinculo from '!babel-loader!mdx-loader!./acesso/adicionarVinculo.md'
import _AlterarVinculo from '!babel-loader!mdx-loader!./acesso/alterarVinculo.md'
import _CriarCardiologiaTecnico from '!babel-loader!mdx-loader!./criarProcedimento/criarCardiologiaTecnico.md'
import _CriarCardiologiaMedico from '!babel-loader!mdx-loader!./criarProcedimento/criarCardiologiaMedico.md'
import _CriarCardiologiaMedicoResidente from '!babel-loader!mdx-loader!./criarProcedimento/criarCardiologiaMedicoResidente.md'
import _CriarJoelhoPrimariaTecnico from '!babel-loader!mdx-loader!./criarProcedimento/criarJoelhoPrimariaTecnico.md'
import _CriarJoelhoPrimariaMedico from '!babel-loader!mdx-loader!./criarProcedimento/criarJoelhoPrimariaMedico.md'
import _CriarJoelhoPrimariaMedicoResidente from '!babel-loader!mdx-loader!./criarProcedimento/criarJoelhoPrimariaMedicoResidente.md'
import _CriarJoelhoRevisaoTecnico from '!babel-loader!mdx-loader!./criarProcedimento/criarJoelhoRevisaoTecnico.md'
import _CriarJoelhoRevisaoMedico from '!babel-loader!mdx-loader!./criarProcedimento/criarJoelhoRevisaoMedico.md'
import _CriarJoelhoRevisaoMedicoResidente from '!babel-loader!mdx-loader!./criarProcedimento/criarJoelhoRevisaoMedicoResidente.md'
import _CriarQuadrilPrimariaTecnico from '!babel-loader!mdx-loader!./criarProcedimento/criarQuadrilPrimariaTecnico.md'
import _CriarQuadrilPrimariaMedico from '!babel-loader!mdx-loader!./criarProcedimento/criarQuadrilPrimariaMedico.md'
import _CriarQuadrilPrimariaMedicoResidente from '!babel-loader!mdx-loader!./criarProcedimento/criarQuadrilPrimariaMedicoResidente.md'
import _CriarQuadrilRevisaoTecnico from '!babel-loader!mdx-loader!./criarProcedimento/criarQuadrilRevisaoTecnico.md'
import _CriarQuadrilRevisaoMedico from '!babel-loader!mdx-loader!./criarProcedimento/criarQuadrilRevisaoMedico.md'
import _CriarQuadrilRevisaoMedicoResidente from '!babel-loader!mdx-loader!./criarProcedimento/criarQuadrilRevisaoMedicoResidente.md'
import _ExcluirProcedimento from '!babel-loader!mdx-loader!./criarProcedimento/excluirProcedimento.md'
import _EditarDadosClinicos from '!babel-loader!mdx-loader!./criarProcedimento/editarDadosClinicos.md'
import _EditarDadosProduto from '!babel-loader!mdx-loader!./criarProcedimento/editarDadosProduto.md'
import _EditarDadosFinanceiros from '!babel-loader!mdx-loader!./criarProcedimento/editarDadosFinanceiros.md'
import _EditarDadosProdutoFinanceiros from '!babel-loader!mdx-loader!./criarProcedimento/editarDadosProdutoFinanceiros.md'
import _EditarPreFicha from '!babel-loader!mdx-loader!./criarProcedimento/editarPreFicha.md'
import _DadosCardiologia from '!babel-loader!mdx-loader!./dadosClinicos/dadosCardiologia.md'
import _DadosJoelhoPrimaria from '!babel-loader!mdx-loader!./dadosClinicos/dadosJoelhoPrimaria.md'
import _DadosJoelhoRevisao from '!babel-loader!mdx-loader!./dadosClinicos/dadosJoelhoRevisao.md'
import _DadosQuadrilPrimaria from '!babel-loader!mdx-loader!./dadosClinicos/dadosQuadrilPrimaria.md'
import _DadosQuadrilRevisao from '!babel-loader!mdx-loader!./dadosClinicos/dadosQuadrilRevisao.md'
import _DadosProdutoTecnico from '!babel-loader!mdx-loader!./dadosProdutoFinanceiro/dadosProdutoTecnico.md'
import _DadosProdutoMedico from '!babel-loader!mdx-loader!./dadosProdutoFinanceiro/dadosProdutoMedico.md'
import _DadosProdutoFinanceiro from '!babel-loader!mdx-loader!./dadosProdutoFinanceiro/dadosProdutoFinanceiro.md'
import _DadosProdutoMedicoResidente from '!babel-loader!mdx-loader!./dadosProdutoFinanceiro/dadosProdutoMedicoResidente.md'
import _DadosFinanceiros from '!babel-loader!mdx-loader!./dadosProdutoFinanceiro/dadosFinanceiros.md'
import _SemRegistroAnvisa from '!babel-loader!mdx-loader!./dadosProdutoFinanceiro/semRegistroAnvisa.md'
import _ParecerVinculo from '!babel-loader!mdx-loader!./vinculo/parecerVinculo.md'
import _DesativacaoVinculo from '!babel-loader!mdx-loader!./vinculo/desativacaoVinculo.md'
import _VinculosRecusados from '!babel-loader!mdx-loader!./vinculo/vinculosRecusados.md'
import _BuscaProcedimentoHospital from '!babel-loader!mdx-loader!./buscaProcedimentos/buscaProcedimentoHospital.md'
import _BuscaProcedimentoProfissional from '!babel-loader!mdx-loader!./buscaProcedimentos/buscaProcedimentoProfissional.md'
import _BuscaProcedimentoPaciente from '!babel-loader!mdx-loader!./buscaProcedimentos/buscaProcedimentoPaciente.md'
import _DefinirAlarmeEtiquetaSemRegistro from '!babel-loader!mdx-loader!./alarmes/definirAlarmeEtiquetaSemRegistro.md'
import _DefinirAlarmeMotivoRevisao from '!babel-loader!mdx-loader!./alarmes/definirAlarmeMotivoRevisao.md'
import _DefinirAlarmeAumentoDeRegistros from '!babel-loader!mdx-loader!./alarmes/definirAlarmeAumentoDeRegistros.md'
import _DesativarAlarme from '!babel-loader!mdx-loader!./alarmes/desativarAlarme.md'
import _InscricaoEmAlarme from '!babel-loader!mdx-loader!./alarmes/inscricaoEmAlarme.md'
import _Desinscricao from '!babel-loader!mdx-loader!./alarmes/desinscricao.md'
import _VisualizarListaDeAlarmes from '!babel-loader!mdx-loader!./alarmes/visualizarListaDeAlarmes.md'
import _UsoDeProdutoRegistro from '!babel-loader!mdx-loader!./dashboard/usoDeProdutoRegistro.md'
import _UsoDeProdutoCnpj from '!babel-loader!mdx-loader!./dashboard/usoDeProdutoCnpj.md'
import _UsoDeProdutoSemEtiqueta from '!babel-loader!mdx-loader!./dashboard/usoDeProdutoSemEtiqueta.md'
import _InformacoesDeProduto from '!babel-loader!mdx-loader!./dashboard/informacoesDeProduto.md'
import _InformacoesFinanceiras from '!babel-loader!mdx-loader!./dashboard/informacoesFinanceiras.md'
import _FiltrarPeriodo from '!babel-loader!mdx-loader!./dashboard/filtrarPeriodos.md'
import _CadastroAntibiotico from '!babel-loader!mdx-loader!./cadastroAntibioticoStents/cadastroAntibiotico.md'
import _CadastroStent from '!babel-loader!mdx-loader!./cadastroAntibioticoStents/cadastroStent.md'
import _FluxoCadastro from '!babel-loader!mdx-loader!./fluxos/fluxoCadastro.md'
import _FluxoPreenchimentoRegistros from '!babel-loader!mdx-loader!./fluxos/fluxoPreenchimentoRegistros.md'
import _GerarChave from '!babel-loader!mdx-loader!./integracao/gerarChave.md'
import _VisualizarRegistros from '!babel-loader!mdx-loader!./integracao/visualizarRegistros.md'
import _CadastrarSistema from '!babel-loader!mdx-loader!./integracao/cadastrarSistema.md'
import _AtualizarRelacao from '!babel-loader!mdx-loader!./relacao/atualizarRelacao.md'
import _FAQ from '!babel-loader!mdx-loader!./faq.md'
import _GerarChaveConsumo from '!babel-loader!mdx-loader!./consumo/gerarChaveConsumo.md'
import _CadastrarEntidade from '!babel-loader!mdx-loader!./consumo/cadastrarEntidade.md'

export const FAQ = _FAQ
export const Home = _Home
export const CadastroTecnico = _CadastroTecnico
export const CadastroMedico = _CadastroMedico
export const CadastroMedicoResidente = _CadastroMedicoResidente
export const CadastroAdministrativo = _CadastroAdministrativo
export const CadastroColaboradorAnvisa = _CadastroColaboradorAnvisa
export const CadastroGestorIntegracao = _CadastroGestorIntegracao
export const CadastroGestorSeguranca = _CadastroGestorSeguranca
export const CadastroGestorServicoSaude = _CadastroGestorServicoSaude
export const CadastroSnvs = _CadastroSnvs
export const FazerLoginTecnico = _FazerLoginTecnico
export const FazerLoginMedico = _FazerLoginMedico
export const fazerLoginMedicoResidente = _FazerLoginMedicoResidente
export const FazerLoginFinanceiro = _FazerLoginFinanceiro
export const FazerLoginGestorSeguranca = _FazerLoginGestorSeguranca
export const FazerLoginAnvisa = _FazerLoginAnvisa
export const FazerLoginGestorServicoSaude = _FazerLoginGestorServicoSaude
export const FazerLoginGestorIntegracao = _FazerLoginGestorIntegracao
export const FazerLoginSnvs = _FazerLoginSnvs
export const AlterarDadosPessoais = _AlterarDadosPessoais
export const RecuperarSenha = _RecuperarSenha
export const AlterarSenha = _AlterarSenha
export const AdicionarVinculo = _AdicionarVinculo
export const AlterarVinculo = _AlterarVinculo
export const CriarCardiologiaTecnico = _CriarCardiologiaTecnico
export const CriarCardiologiaMedico = _CriarCardiologiaMedico
export const CriarCardiologiaMedicoResidente = _CriarCardiologiaMedicoResidente
export const CriarJoelhoPrimariaTecnico = _CriarJoelhoPrimariaTecnico
export const CriarJoelhoPrimariaMedico = _CriarJoelhoPrimariaMedico
export const criarJoelhoPrimariaMedicoResidente = _CriarJoelhoPrimariaMedicoResidente
export const CriarJoelhoRevisaoTecnico = _CriarJoelhoRevisaoTecnico
export const CriarJoelhoRevisaoMedico = _CriarJoelhoRevisaoMedico
export const criarJoelhoRevisaoMedicoResidente = _CriarJoelhoRevisaoMedicoResidente
export const CriarQuadrilPrimariaTecnico = _CriarQuadrilPrimariaTecnico
export const CriarQuadrilPrimariaMedico = _CriarQuadrilPrimariaMedico
export const CriarQuadrilPrimariaMedicoResidente = _CriarQuadrilPrimariaMedicoResidente
export const CriarQuadrilRevisaoTecnico = _CriarQuadrilRevisaoTecnico
export const CriarQuadrilRevisaoMedico = _CriarQuadrilRevisaoMedico
export const CriarQuadrilRevisaoMedicoResidente = _CriarQuadrilRevisaoMedicoResidente
export const EditarDadosClinicos = _EditarDadosClinicos
export const EditarDadosProduto = _EditarDadosProduto
export const EditarDadosFinanceiros = _EditarDadosFinanceiros
export const EditarDadosProdutoFinanceiros = _EditarDadosProdutoFinanceiros
export const EditarPreFicha = _EditarPreFicha
export const ExcluirProcedimento = _ExcluirProcedimento
export const DadosCardiologia = _DadosCardiologia
export const DadosJoelhoPrimaria = _DadosJoelhoPrimaria
export const DadosJoelhoRevisao = _DadosJoelhoRevisao
export const DadosQuadrilPrimaria = _DadosQuadrilPrimaria
export const DadosQuadrilRevisao = _DadosQuadrilRevisao
export const DadosProdutoTecnico = _DadosProdutoTecnico
export const DadosProdutoMedico = _DadosProdutoMedico
export const DadosProdutoFinanceiro = _DadosProdutoFinanceiro
export const DadosProdutoMedicoResidente = _DadosProdutoMedicoResidente
export const DadosFinanceiros = _DadosFinanceiros
export const SemRegistroAnvisa = _SemRegistroAnvisa
export const ParecerVinculo = _ParecerVinculo
export const DesativacaoVinculo = _DesativacaoVinculo
export const VinculosRecusados = _VinculosRecusados
export const BuscaProcedimentoHospital = _BuscaProcedimentoHospital
export const BuscaProcedimentoProfissional = _BuscaProcedimentoProfissional
export const BuscaProcedimentoPaciente = _BuscaProcedimentoPaciente
export const DefinirAlarmeEtiquetaSemRegistro = _DefinirAlarmeEtiquetaSemRegistro
export const DefinirAlarmeMotivoRevisao = _DefinirAlarmeMotivoRevisao
export const DefinirAlarmeAumentoDeRegistros = _DefinirAlarmeAumentoDeRegistros
export const DesativarAlarme = _DesativarAlarme
export const InscricaoEmAlarme = _InscricaoEmAlarme
export const Desinscricao = _Desinscricao
export const VisualizarListaDeAlarmes = _VisualizarListaDeAlarmes
export const UsoDeProdutoRegistro = _UsoDeProdutoRegistro
export const UsoDeProdutoCnpj = _UsoDeProdutoCnpj
export const UsoDeProdutoSemEtiqueta = _UsoDeProdutoSemEtiqueta
export const InformacoesDeProduto = _InformacoesDeProduto
export const InformacoesFinanceiras = _InformacoesFinanceiras
export const FiltrarPeriodo = _FiltrarPeriodo
export const CadastroAntibiotico = _CadastroAntibiotico
export const CadastroStent = _CadastroStent
export const FluxoCadastro = _FluxoCadastro
export const FluxoPreenchimentoRegistros = _FluxoPreenchimentoRegistros
export const GerarChave = _GerarChave
export const CadastrarSistema = _CadastrarSistema
export const VisualizarRegistros = _VisualizarRegistros
export const AtualizarRelacao = _AtualizarRelacao
export const GerarChaveConsumo = _GerarChaveConsumo
export const CadastrarEntidade = _CadastrarEntidade
