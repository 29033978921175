import * as React from 'react'
import { NumberLabel } from 'libs/components/label/NumberLabel'

export class PercentageLabelProps {
    /**
     * Número de 0 a 1 representando o quociente que será transformado para porcentagem
     */
    value: number
    minDecimalPlaces?: number
    maxDecimalPlaces?: number
    placeholder?: string
    className?: string
    title?: string
}

export class PercentageLabel extends React.Component<PercentageLabelProps> {
    render() {
        const value = isFinite(this.props.value) && !isNaN(this.props.value) ? this.props.value : 0

        return <NumberLabel {...this.props} sufix='%' value={value * 100} />
    }
}
