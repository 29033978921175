import { RecebimentoIntegracaoDto } from 'backend'
import { Modal, Label } from 'libs/components'
import React from 'react'
import { ModalDetalhesErrosRow } from './ModalDetalhesErrosRow'

interface ModalDetalhesErrosProps {
    isOpen: boolean
    onClickClose: () => void
    value: RecebimentoIntegracaoDto
}

const mapFromJson = (
    json: object,
    previousMap: Map<string, string[]> = new Map<string, string[]>()
): Map<string, string[]> => {
    for (let key in json) {
        if (typeof json[key] === 'string') {
            if (previousMap.has(json[key])) {
                let value: string[] = previousMap.get(json[key])
                value.push(key)
                previousMap.set(json[key], value)
            } else {
                previousMap.set(json[key], [key])
            }
        } else {
            mapFromJson(json[key], previousMap)
        }
    }

    return previousMap
}

export const ModalDetalhesErros = (props: ModalDetalhesErrosProps) => {
    const { isOpen, onClickClose, value } = props
    const mapError: Map<string, string[]> = value.motivoRejeicao
        ? mapFromJson(JSON.parse(value.motivoRejeicao))
        : undefined
    return (
        <Modal active={isOpen}>
            <div className='modal-detalhes-erros-wrapper'>
                <div className='modal-card modal-detalhes-erros'>
                    <header className='modal-detalhes-erros-header'>
                        <Label className='header-title'>
                            <span className='is-semibold'>ID {value.identificadorExterno}</span> - Detalhes dos erros
                        </Label>
                    </header>
                    {mapError && (
                        <section className='modal-detalhes-erros-section'>
                            {Array.from(mapError.keys()).map((key: string, index: number, array: string[]) => {
                                return (
                                    <ModalDetalhesErrosRow
                                        key={index}
                                        index={index}
                                        title={key}
                                        items={mapError.get(key)}
                                        numberOfRows={array.length}
                                    />
                                )
                            })}
                        </section>
                    )}
                </div>
                <button className='delete' onClick={onClickClose} />
            </div>
        </Modal>
    )
}
