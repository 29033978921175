import { connect } from 'react-redux'
import { State } from 'redux/modules'
import { DistComponenteProcedimentoView, Props } from './DistComponenteProcedimentoView'
import { withRouter } from 'react-router-dom'
import * as produtos from 'redux/modules/report/produto'
import * as filtroReport from 'redux/modules/report/filtro'
import { DistribuicaoProcedimentoFiltro } from 'backend'

const mapStateToProps = (state: State, ownProps: Props) => ({
    componentePrimaria: state.report.produto.componentePrimaria.data,
    componenteRevisao: state.report.produto.componenteRevisao.data,
    componenteCardiologia: state.report.produto.componenteCardiologia.data,
    filtro: state.report.filtro.filtro,
    idComponente: ownProps.match.params.idComponente,
    goBack: () => ownProps.history.goBack(),
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    loadComponente: value => {
        dispatch(produtos.clearFindResult())
        const filtro: DistribuicaoProcedimentoFiltro = value
        const tipo = ownProps.match.params.tipoProcedimento
        filtro.procedimento = tipo
        if (tipo === 'PRIMARIA_JOELHO' || tipo === 'PRIMARIA_QUADRIL') {
            dispatch(produtos.findComponentePrimaria(+ownProps.match.params.idComponente, filtro))
        } else if (tipo === 'REVISAO_JOELHO' || tipo === 'REVISAO_QUADRIL') {
            dispatch(produtos.findComponenteRevisao(+ownProps.match.params.idComponente, filtro))
        } else if (tipo === 'STENT_CORONARIA') {
            dispatch(produtos.findComponenteCardiologia(+ownProps.match.params.idComponente, filtro))
        }
    },
    clearFindResult: () => {
        dispatch(produtos.clearFindResult())
    },
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(
            filtroReport.change({
                ...filtro,
            })
        )
    },
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DistComponenteProcedimentoView as any))
