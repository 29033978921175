/* tslint:disable */
import React from 'react'

const SvgMasculino = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 18 18' {...props}>
        <path d='M15.191 2.228l.05.008.048.01.048.015c.044.016.044.016.058.023l.037.018.037.022c.026.016.026.016.041.028.061.048.091.078.118.11l.038.052.023.039c.02.04.02.04.024.045l.018.044.016.044.01.04.009.043.006.04a.675.675 0 01.003.066v2.758a.648.648 0 11-1.295 0V4.44l-1.839 1.843.116.155a5.826 5.826 0 011.151 3.485c0 3.227-2.62 5.852-5.841 5.852S2.225 13.15 2.225 9.923c0-3.227 2.62-5.852 5.842-5.852 1.28 0 2.5.416 3.505 1.173l.156.118 1.836-1.84h-1.191a.648.648 0 010-1.297h2.75l.068.003zm-7.124 12.25a4.555 4.555 0 000-9.11 4.555 4.555 0 000 9.11z' />
    </svg>
)

export default SvgMasculino
