import * as React from 'react'
import * as C from 'libs/components'
import { TipoRevisaoRow, TipoCirurgia } from 'backend'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import * as enums from 'redux/enums'
import { NumberLabel } from 'libs/components/label/NumberLabel'

interface Props {
    situacoes: { [key in string]: number }
    tipoRevisao: PromiseRecord<TipoRevisaoRow[]>
    total: number
    proced: TipoCirurgia
}

export class ProporcaoTipoRevisaoCard extends React.Component<Props> {
    render() {
        const total = this.props.total
        const tipos = this.props.tipoRevisao
        const data = tipos.data
        const situacoes = this.props.situacoes

        const title = this.props.proced === 'JOELHO' ? 'CONVENCIONAL X NÃO CONVENCIONAL' : 'TROCA X RETIRADA'

        const keyses = Object.keys(situacoes)
        let higherKey
        let higkerValue

        if (situacoes[keyses[0]] > situacoes[keyses[1]]) {
            higherKey = keyses[0]
            higkerValue = situacoes[keyses[0]]
        } else {
            higherKey = keyses[1]
            higkerValue = situacoes[keyses[1]]
        }
        const higherPercent = this.props.total > 0 ? higkerValue / this.props.total : 0
        return (
            <C.Card title={title} fullHeight>
                <C.LoadingStateContainer readyState={tipos.readyState}>
                    {data && (
                        <C.Columns className='is-small spacing-double align-center'>
                            <C.Column offset='1' size='2'>
                                <C.CircleProgress
                                    type='primary'
                                    size={35}
                                    progress={{ 'is-report-color-2': higherPercent }}
                                />
                            </C.Column>
                            <C.Column offset='1' size='8'>
                                <div className='is-semibold is-black-ter'>
                                    {Object.entries(situacoes).map(([key, value]) => (
                                        <p key={key}>
                                            <NumberLabel
                                                className={
                                                    key === higherKey ? 'is-report-color-2 is-13-600' : 'is-13-600'
                                                }
                                                placeholder='-'
                                                value={(value / total) * 100}
                                                sufix='%'
                                            />{' '}
                                            <span className='percent'> {enums.Situacao[key].descricao} </span>
                                        </p>
                                    ))}
                                </div>
                            </C.Column>
                        </C.Columns>
                    )}
                </C.LoadingStateContainer>
            </C.Card>
        )
    }
}
