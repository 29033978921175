import * as React from 'react'

interface Props {
    title: string
    subtitle?: string
}

export class PageTitle extends React.Component<Props, any> {
    render() {
        return (
            <div className='page-title-wrapper'>
                <p className='page-title'>{this.props.title}</p>
                {this.props.subtitle && <p className='page-subtitle'>{this.props.subtitle}</p>}
            </div>
        )
    }
}
