import React from 'react'

export class List extends React.Component {
    render() {
        const noChildren = !this.props.children || this.props.children.length === 0 || this.props.children.size === 0
        return (
            <section className='list'>
                {this.props.title && <h1>{this.props.title}</h1>}
                <ul>
                    {noChildren && this.props.emptyText && <ListItem>{this.props.emptyText}</ListItem>}
                    {this.props.children}
                </ul>
            </section>
        )
    }
}

export class ListItem extends React.Component {
    render() {
        return <li>{this.props.children}</li>
    }
}
