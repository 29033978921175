import { PacienteProcedimentoReportDto, PacienteProcedimentoReportFiltro, DateInterval, TipoProcedimento} from 'backend'
import semResultado from 'images/home/reports/rastreabilidade-de-produtos-busca-sem-resultados.svg'
import { Paginator, Card, Column, Columns, HospitalField, UfField, CidadeField, LoadingContainer, Button, Text, PeriodoFilter, Icon } from 'libs/components'
import { PacienteField } from 'libs/components/presentational/form/field/PacienteField'
import { TipoProcedimentoField } from 'libs/components/presentational/form/field/TipoProcedimentoField'
import { RouteComponentProps } from 'react-router-dom'
import React from 'react'
import ListaPacientesRow from './ListaPacientesRow'
import BackButton from 'libs/components/presentational/elements/BackButton'
import { Page } from 'redux/middleware/PaginationAction'
import UrlParams from 'libs/router/UrlParams'
import { formValueSelector } from 'redux-form/immutable'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { Link } from 'react-router-dom'
import form from 'libs/redux-form/form'
import { InjectedFormProps, change} from 'redux-form'
import { connect } from 'react-redux'
import { stringify } from 'redux/modules/router'

const formName = 'buscaPacientesProcedimento'

export interface ListaPacientesProps extends RouteComponentProps<any> {
    filtro: PacienteProcedimentoReportFiltro
    handlePageChange(page: number): void
    changeFilter(filtro: PacienteProcedimentoReportFiltro): void
    load(filtro: PacienteProcedimentoReportFiltro): void
    isFetching: boolean
    data: Page<PacienteProcedimentoReportDto>
    visualizarFicha(idProcedimento: number, tipoProcedimento: TipoProcedimento): any
    renderDownloadIcon
}

export class ListaPacientesView extends React.Component<ListaPacientesProps> {
    handlePeriodoChange = (interval: DateInterval) => {
        this.props.changeFilter({
            ...this.props.filtro,
            interval: interval,
        })
    }
    refresh = (filter: PacienteProcedimentoReportFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        this.props.load(filter)
    }
    renderDownloadIcon() {
        return (
            <Link
                to={{
                    pathname: '/api/report/pacientesProcedimentos/csv',
                    search: stringify(this.props.filtro),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }
    render() {
        const { data, filtro, handlePageChange, isFetching} = this.props
        return (
		<div>
			<UrlParams params={filtro} onChange={this.refresh} />
			<ReportClearFilter />
            <div className='report-relacao--header'>
                <h1>Lista de Pacientes</h1>
            </div>
            <ConnectedBuscaForm load={this.props.load} filtro={this.props.filtro} />
            <LoadingContainer isFetching={isFetching}>
                        <div className='container busca-page' style={{padding: 'unset'}}>
                        	<div className='busca-page-header'>
                        		<Column size='6'>
	                              <BackButton />
								</Column>
								<Column size='6' textRight>
									<PeriodoFilter value={filtro?.interval} onChange={this.handlePeriodoChange} />
								</Column>
                            </div>
                            {data?.numberOfElements > 0 ? (
                            <Card title='Lista de Pacientes' noPadding={!isFetching} headerAction={this.renderDownloadIcon()}>
                                <div className='busca-row'>
                                    <Columns>
                                        <Column className='is-narrow' customStyle={{ width: '12%' }}>
                                            <Text weight='semibold' color='grey-darker' size='small-bis'>
                                                CPF
                                            </Text>
                                        </Column>
                                        <Column className='is-narrow' customStyle={{ width: '12%' }}>
                                            <Text weight='semibold' color='grey-darker' size='small-bis'>
                                                Nome
                                            </Text>
                                        </Column>
                                        <Column className='is-narrow' customStyle={{ width: '12%' }}>
                                            <Text weight='semibold' color='grey-darker' size='small-bis'>
                                                Procedimento
                                            </Text>
                                        </Column>
                                        <Column size='3'>
                                            <Text weight='semibold' color='grey-darker' size='small-bis'>
                                                Serviço de saúde
                                            </Text>
                                        </Column>
                                        <Column className='is-narrow' customStyle={{ width: '12%' }}>
                                            <Text weight='semibold' color='grey-darker' size='small-bis'>
                                                Município/UF do serviço de saúde
                                            </Text>
                                        </Column>
                                         <Column className='is-narrow' customStyle={{ width: '12%' }}>
                                            <Text weight='semibold' color='grey-darker' size='small-bis'>
                                                Município/UF do paciente
                                            </Text>
                                        </Column>
                                        <Column className='is-narrow' customStyle={{ width: '12%' }}>
                                            <Text weight='semibold' color='grey-darker' size='small-bis'>
                                                Data do Procedimento
                                            </Text>
                                        </Column>
                                    </Columns>
                                </div>
                                {data.content.map((content, index) => {
                                    return (
                                        <ListaPacientesRow key={index}
                                            content={content} visualizarFicha={this.props.visualizarFicha}
                                        />
                                    )
                                })}
                               <div className='busca-row busca-page-footer'>
					                <Paginator
					                    first={data.first}
					                    last={data.last}
					                    pageNumber={data.number}
					                    totalPages={data.totalPages}
					                    onChange={handlePageChange}
					                />
					            </div>
                            </Card>
                             ) : data?.numberOfElements === 0 ? (
		                        <div className='resultado-busca report-content'>
		                            <div className='has-text-centered sem-resultados report-content'>
		                                <img src={semResultado} alt='noresults' />
		                                <br />
		                            </div>
		                            <p className='text-result has-text-centered report-content'>Sem resultados</p>
		                        </div>
		                    ) : null}
                        </div>
            </LoadingContainer>
        </div>
		)
	}
}

interface BuscaFormProps {
    selectedUf
    filtro: PacienteProcedimentoReportFiltro
    onSubmit(form)
    handleChangeUf(value): void
}

class BuscaFormCmp extends React.Component<BuscaFormProps & InjectedFormProps<{}, BuscaFormProps>> {
    render() {
        return (
            <div>
                <div className='header-busca--container'>
                    <div className='header-busca'>
                        <form onSubmit={this.props.onSubmit}>
						    <Columns>
						    	<Column size='3'>
			                        <UfField
			                            label='UF - Serviço de saúde'
			                            name='uf'
			                            placeholder='UF'
			                            // @ts-ignore
                            			onChange={this.props.handleChangeUf}
			                        />
			                    </Column>
			                    <Column size='4'>
			                        <CidadeField
			                            isDisabled={this.props.selectedUf == null}
			                            ufId={this.props.selectedUf ? this.props.selectedUf.id : ''}
			                            label='Município - Serviço de saúde'
			                            name='cidade'
			                            placeholder='Município'
			                        />
			                    </Column>
						    </Columns>
							<Columns>
								<Column size='3'>
									<TipoProcedimentoField name='tipoProcedimento' label='Procedimento' placeholder='Procedimento'/>
								</Column>
								<Column size='4'>
									<HospitalField name='hospital' label='Serviço de saúde/CNPJ' placeholder='Selecione o serviço de saúde' clearable />
								</Column>
								<Column size='4'>
									<PacienteField name='paciente' label='Paciente/CPF' placeholder='Selecione o paciente' clearable />
								</Column>
						    </Columns>
						</form>
                    </div>
                </div>
                <div className='has-text-centered'>
                    <Button
                        className='btn-pesquisar'
                        type='primary'
                        size='medium'
                        shadow
                        onClick={this.props.handleSubmit}
                    >
                        Pesquisar
                    </Button>
                </div>
            </div>
        )
    }
}

const BuscaForm = form({ form: formName, hasLeaveModal: false, hasSuccessModal: false })(BuscaFormCmp)

const selector = formValueSelector(formName)

type OwnProps = {
    filtro: PacienteProcedimentoReportFiltro
    load(filtro: PacienteProcedimentoReportFiltro): void
}

const ConnectedBuscaForm = connect((state, ownProps: OwnProps) => {
    const selectedUf = selector(state, 'uf')
    return {
		selectedUf,
        initialValues: ownProps.filtro,
        onSubmit: form => {
            const mutable = form.toJS()
	        ownProps.filtro.tipoProcedimento = mutable?.tipoProcedimento?.id
	        ownProps.filtro.cidadeId = mutable?.cidade?.id
	        ownProps.filtro.hospitalId = mutable?.hospital?.id
	        ownProps.filtro.pacienteId = mutable?.paciente?.id
	        ownProps.filtro.uf = mutable?.uf?.id
	        ownProps.load(ownProps.filtro)
        },
    }
	}, dispatch => ({
        handleChangeUf: () => {
            dispatch(change(formName, 'cidade', null))
        },
}))(BuscaForm)
