/* tslint:disable */
import React from 'react'

const SvgAdicionar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 17 16' {...props}>
        <path d='M8 4a.5.5 0 00-.5.5V7H5a.5.5 0 00-.5.5v.925a.5.5 0 00.5.5h2.5V11.5a.5.5 0 00.5.5h.925a.5.5 0 00.5-.5V8.925H12a.5.5 0 00.5-.5V7.5A.5.5 0 0012 7H9.425V4.5a.5.5 0 00-.5-.5H8z' />
    </svg>
)

export default SvgAdicionar
