import React from 'react'
import * as C from 'libs/components'

export class IAMPrevioCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='IAM prévio'
                name='iamPrevio'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='iamPrevio' />}
            >
                <C.Conteudo textCentered>
                    <C.TextLabel name='iam' placeholder='Não assinalar se o primeiro IAM for o da atual internação.' />
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
