import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import { RevisaoJoelhoForm } from 'backend'
import immutableProxy, { ImmutableRootProxy } from 'libs/immutableProxy'

const validaMotivoRevisao = (mutable: ImmutableRootProxy<FormState<RevisaoJoelhoForm>>) => {
    const motivo = mutable.values.motivoRevisao.motivoRevisao.get()
    if (!motivo || (motivo && motivo.indexOf('INFECCAO_JOELHO') === -1)) {
        mutable.values.motivoRevisao.tipoInfeccao.delete()
    }
    return mutable
}
const revisaoJoelho = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<RevisaoJoelhoForm>>(state)
        form = form.withMutations(mutable => {
            validaMotivoRevisao(mutable)
            if (mutable.values.procedimentoCirurgicoAtualRevisao.transfusaoSanguinea.get() !== 'SIM') {
                mutable.values.procedimentoCirurgicoAtualRevisao.tipoTransfusaoSanguinea.delete()
            }

            if (mutable.values.procedimentoCirurgicoAtualRevisao.possuiAntibiotico.get() !== 'SIM') {
                mutable.values.procedimentoCirurgicoAtualRevisao.antibiotico.delete()
                mutable.values.procedimentoCirurgicoAtualRevisao.periodoAntibiotico.delete()
            }
            const tipoRevisao = mutable.values.tipoRevisao.get()
            if (tipoRevisao && tipoRevisao.indexOf('NAO_CONVENCIONAL') > -1) {
                mutable.values.tipoRevisao.set(['NAO_CONVENCIONAL'])
            }
            if (mutable.values.cimentoOsseo.possuiCimentoOsseo.get() !== 'SIM') {
                mutable.values.cimentoOsseo.tipoCimentoOsseo.delete()
                mutable.values.cimentoOsseo.lavagemPulsatil.delete()
            }

            if (mutable.values.enxertia.foiRealizada.get() !== 'SIM') {
                mutable.values.enxertia.femoral.delete()
                mutable.values.enxertia.tibial.delete()
            }
            if (!mutable.values.enxertia.femoral.get()) {
                mutable.values.enxertia.tipoEnxertiaFemoral.delete()
            }
            if (!mutable.values.enxertia.tibial.get()) {
                mutable.values.enxertia.tipoEnxertiaTibial.delete()
            }
        })
        return form.asMap()
    }
    return state
})

export default revisaoJoelho
