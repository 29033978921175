import React from 'react'
import * as C from 'libs/components'

export default class FichaPrimariaJoelhoSidebar extends React.Component {
    render() {
        return (
            <C.SideNav>
                <C.SideNavItem anchor='diagnostico'>Diagnósticos</C.SideNavItem>
                <C.SideNavItem anchor='procedimentoCirurgicoPrevio'>Procedimento cirúrgico prévio</C.SideNavItem>
                <C.SideNavItem anchor='procedimentoCirurgicoAtual'>Procedimento cirúrgico atual</C.SideNavItem>
                <C.SideNavItem anchor='tipoArtroplastiaTitle'>Tipo de artroplastia</C.SideNavItem>
                <C.SideNavItem anchor='cimentoOsseo'>Cimento ósseo</C.SideNavItem>
                <C.SideNavItem anchor='enxertia'>Enxertia</C.SideNavItem>
                <C.SideNavItem anchor='componentesUtilizados'>Componentes utilizados</C.SideNavItem>
            </C.SideNav>
        )
    }
}
