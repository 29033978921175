import { MotivosProcedimentosRow, MotivosRevisoesRow, MotivosQuadroPadraoDto, MotivoProcedimentoEnum } from 'backend'
import { SeriesMapDataOptions } from 'highcharts'
import Report from 'api/Report'
import { promiseReducer, PromiseRecord } from 'redux/middleware/PromiseAction'
import { State } from 'redux/modules/index'
import { MotivosProcedimento, padraoObstrutivoEnum, quadroClinicoEnum } from 'redux/enums'

export const FIND_MOTIVOS = 'rni/report/motivos/FIND_MOTIVOS'
export const FIND_MOTIVOS_REVISOES = 'rni/report/motivos/FIND_MOTIVOS_REVISOES'
export const FIND_QUADRO_PADRAO = 'rni/report/motivos/FIND_QUADRO_PADRAO'
export const CLEAR_ALL_MOTIVOS = 'rni/report/motivos/CLEAR_ALL_MOTIVOS'

export interface MotivosProcedimentosReportState {
    motivos: PromiseRecord<MotivosProcedimentosRow[]>
    motivosRevisoes: PromiseRecord<MotivosRevisoesRow[]>
    distQuadroPadrao: DistQuadroPadrao
}

export const initialState: MotivosProcedimentosReportState = {
    motivos: {},
    motivosRevisoes: {},
    distQuadroPadrao: {},
}

export interface DistQuadroPadrao extends PromiseRecord<MotivosQuadroPadraoDto[]> {
    result?: {
		quadroClinico?: String
        series?: {
            data: SeriesMapDataOptions
        }[]
    }[]
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FIND_MOTIVOS:
            return {
                ...state,
                motivos: exportFunctions.reduceMotivosRevisao(
                    promiseReducer(state.motivos, action),
                    action.procedimento
                ),
            }
        case FIND_MOTIVOS_REVISOES:
            return {
                ...state,
                motivosRevisoes: exportFunctions.reduceMotivosRevisoes(
                    promiseReducer(state.motivosRevisoes, action),
                    action.tipoCirurgia
                ),
            }
        case FIND_QUADRO_PADRAO:
            return {
                ...state,
                distQuadroPadrao: exportFunctions.reduceDistQuadroPadrao(promiseReducer(state.distQuadroPadrao, action)),
            }
        case CLEAR_ALL_MOTIVOS:
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export default reducer

export const loadAll = () => (dispatch, getState) => {
    const state: State = getState()
    const filtro = state.report.filtro.filtro

    dispatch({
        type: FIND_MOTIVOS,
        procedimento: `${filtro.filtroCirurgia}_${filtro.tipoCirurgia}`,
        promise: Report.getMotivosProcedimentos(filtro),
    })
}

export const loadQuadroPadrao = () => (dispatch, getState) => {
    const state: State = getState()
    const filtro = state.report.filtro.filtro

    dispatch({
        type: FIND_QUADRO_PADRAO,
        tipoCirurgia: filtro.tipoCirurgia,
        promise: Report.getQuadroPadrao(filtro),
    })
}

export const loadMotivoRevisoes = () => (dispatch, getState) => {
    const state: State = getState()
    const filtro = state.report.filtro.filtro

    dispatch({
        type: FIND_MOTIVOS_REVISOES,
        tipoCirurgia: filtro.tipoCirurgia,
        promise: Report.getMotivosRevisoes(filtro),
    })
}

export const clearAllMotivos = () => {
    return {
        type: CLEAR_ALL_MOTIVOS,
    }
}

export const reduceMotivosRevisao = (promiseRecord: PromiseRecord<MotivosProcedimentosRow[]>, procedimento: string) => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }

    const motivos = promiseRecord.data

    Object.entries(MotivosProcedimento)
        .filter(motivoEntry => motivoEntry[1].procedimento === procedimento)
        .forEach(motivoEntry => {
            const motivo = motivos.find(motivo => motivo.motivo === motivoEntry[0])
            if (!motivo) {
                motivos.push({
                    motivo: motivoEntry[0] as MotivoProcedimentoEnum,
                    motivoName: motivoEntry[1].descricao,
                    cont: 0,
                    contFem: 0,
                    contMasc: 0,
                })
            }
        })

    return { ...promiseRecord }
}

export const reduceMotivosRevisoes = (promiseRecord: PromiseRecord<MotivosRevisoesRow[]>, tipoCirurgia: string) => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }

    const motivos = promiseRecord.data

    Object.entries(MotivosProcedimento)
        .filter(motivoEntry => motivoEntry[1].procedimento === `REVISAO_${tipoCirurgia}`)
        .forEach(motivoEntry => {
            const motivo = motivos.find(motivo => motivo.motivo === motivoEntry[0])
            if (!motivo) {
                motivos.push({
                    motivo: motivoEntry[0] as MotivoProcedimentoEnum,
                    motivoName: motivoEntry[1].descricao,
                    count: 0,
                })
            }
        })

    return { ...promiseRecord }
}

export const reduceDistQuadroPadrao = (promiseRecord: PromiseRecord<MotivosQuadroPadraoDto[]>): DistQuadroPadrao => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }
    const results = []
    const dataList = promiseRecord.data
    dataList.forEach(data => {
	    if (Object.keys(data.quadroPadrao).length > 0) {
			results.push({
				quadroClinico: quadroClinicoEnum[data.quadroClinico],
				series: [{
					data: Object.keys(data.quadroPadrao).map(reg => ({
						name: padraoObstrutivoEnum[reg].name,
						y: data.quadroPadrao[reg],
						color: padraoObstrutivoEnum[reg].color,
					})),
				},]
			})
	    }
	})
    return {
        ...promiseRecord,
        result: results,
    }
}

export const exportFunctions = {
    loadAll,
    loadMotivoRevisoes,
    reduceMotivosRevisao,
    reduceMotivosRevisoes,
    reduceDistQuadroPadrao,
}
