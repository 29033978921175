import React, { useState, ReactNode, useRef } from 'react'
import classnames from 'classnames'
import { Popover, Placement } from './Popover'
import { findDOMNode } from 'react-dom'

export interface HintProps {
    placement?: Placement
    componente: any
    type?: 'normal' | 'primary'
    className?: string
    children: ReactNode
}

export const Hint = (props: HintProps) => {
    const [showPopover, setShowPopover] = useState(false)
    const triggerRef = useRef(null)
    const containerRef = useRef(null)
    const classes = classnames('hint', props.type || 'normal')

    return (
        <span className={classnames('hint-wrapper', props.className)} ref={containerRef}>
            <Popover
                className={classes}
                color='dark-grey'
                show={showPopover}
                placement={props.placement}
                container={containerRef}
                target={triggerRef as any}
            >
                {props.children}
            </Popover>
            {props.componente &&
                React.cloneElement(props.componente, {
                    onPointerEnter: () => setShowPopover(true),
                    onPointerLeave: () => setShowPopover(false),
                    ref: r => (triggerRef.current = findDOMNode(r)),
                })}
        </span>
    )
}
