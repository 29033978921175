
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.32,"time":19200,"words":64}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI.`}</li>
      <li parentName="ol">{`Clique no botão `}<img parentName="li" {...{
          "src": "/images/icone-alarmes.png#center",
          "alt": "..."
        }}></img>{`.`}</li>
      <li parentName="ol">{`Na central de alarmes selecione a opção “Configurar”.`}</li>
      <li parentName="ol">{`Encontre o alarme que está ativo e que você está inscrito. Ao posicionar o mouse sobre o botão “Inscrito”, ele mudará para “Sair”.`}</li>
      <li parentName="ol">{`Selecione a opção “Sair”.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Ao sair do alarme você não será mais notificado sobre eventos ocorridos no mesmo.`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

