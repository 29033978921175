import { Button, ControlField, Linha, StyledRadioField, Text } from 'libs/components'
import form from 'libs/redux-form/form'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { InjectedFormProps } from 'redux-form'
import { getFormValues } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { RelacaoEmpresasAdicionadasSection } from './form/RelacaoEmpresasAdicionadasSection'
import { RelacaoEmpresasFormValidator } from './RelacaoEmpresasFormValidator'
import { List } from 'immutable'
import Aviso from 'view/componentes/Aviso'

const formName = 'relacaoEmpresas'

const RelacaoEmpresasCmp: React.FC<InjectedFormProps> = props => {
    const { handleSubmit } = props

    const history = useHistory()

    const formValues = useSelector<State, any>(state => getFormValues(formName)(state))

    const enableAddEmpresa: boolean = formValues?.get('possuiRelacao') === 'SIM'

    const relacoesEmpresas: List<any> = formValues?.get('empresas')
    const disableAnswer: boolean = relacoesEmpresas?.size > 0

    return (
        <form className='relacao-empresas--form'>
            <div className='relacao-empresas--content'>
                <Text className='content--section-title'>Relação com empresas</Text>
                <Linha className='content--line' />
                <Aviso>
                    Caso você possua ou tenha possuído alguma relação com fabricantes, importadores, distribuidores e/ou
                    fornecedores de dispositivos médicos, é possível registrar essas relações preenchendo os campos
                    abaixo.
                    <br />
                    Os tipos de relações incluem financiamento para participação de congressos, consultorias,
                    honorários, entre outras opções.
                </Aviso>
                <div className='content--question-box'>
                    <ControlField
                        required
                        name='possuiRelacao'
                        label='Você possui/possuiu relação com empresas de produtos médicos?'
                        className='question-box--title'
                    />
                    <StyledRadioField
                        className='question-box--wraper-btn'
                        innerClassName='question-box--btn'
                        name='possuiRelacao'
                        skin='round-button'
                        value='SIM'
                    >
                        Sim
                    </StyledRadioField>
                    <StyledRadioField
                        className='question-box--wraper-btn'
                        innerClassName='question-box--btn'
                        name='possuiRelacao'
                        skin='round-button'
                        value='NAO'
                        disabled={disableAnswer}
                    >
                        Não
                    </StyledRadioField>
                </div>
                <RelacaoEmpresasAdicionadasSection enableAddEmpresa={enableAddEmpresa} />
            </div>
            <div className='relacao-empresas--footer'>
                <Button className='footer--btn-cancelar' onClick={() => history.goBack()}>
                    Cancelar
                </Button>
                <Button className='footer--btn-salvar' type='primary' onClick={handleSubmit}>
                    Salvar
                </Button>
            </div>
        </form>
    )
}

export const RelacaoEmpresasFormView = form({
    form: formName,
    validate: RelacaoEmpresasFormValidator as any,
    hasSuccessModal: false,
})(RelacaoEmpresasCmp)
