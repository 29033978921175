import * as React from 'react'
import field from 'libs/redux-form/field'
import * as C from 'libs/components'
import { WrappedFieldProps } from 'redux-form'

interface CidadeFieldProps extends Partial<C.FixedSelectProps>, C.FormFieldProps, Partial<WrappedFieldProps> {
    ufId: string
    getRef?(ref): void
}

export class CidadeFieldCmp extends React.Component<CidadeFieldProps> {
    handleChange = (newValue: C.FixedSelectOption | C.FixedSelectOption[] | null): void => {
        this.props.input.onChange(newValue as any)
    }

    render() {
        return (
            <C.AsyncSelect
                {...this.props.input}
                getRef={this.props.getRef}
                url='/api/public/enderecos/localidades'
                isDisabled={this.props.isDisabled}
                placeholder={this.props.placeholder as any}
                onChange={this.handleChange}
                customizeParams={params => {
                    return { ...params, uf: this.props.ufId }
                }}
            />
        )
    }
}

export const CidadeField = field()(CidadeFieldCmp)
