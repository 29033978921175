import { TipoAlerta, AlertaConfig, UsuarioDto, UserDTO } from 'backend'
import { RouteComponentProps } from 'react-router-dom'
import * as React from 'react'
import AutoPopover from 'libs/components/presentational/AutoPopover'
import { withRouter } from 'react-router-dom'
import IconeTipoAlerta from 'view/home/anvisa/alertas/components/IconeTipoAlerta'
import Text from 'libs/components/presentational/typography/Text'
import Menu from 'libs/components/presentational/layout/Menu'
import MenuItem from 'libs/components/presentational/layout/MenuItem'
import { Button, Icon } from 'libs/components'
import { LoadingContainer, Card } from 'libs/components'
import axios from 'axios'
import { PublicAlertaConfigModalFormProps } from 'view/home/anvisa/alertas/components/AlertaConfigModalForm'

interface AlertaConfigCardProps extends RouteComponentProps<any> {
    renderChildren?(config: AlertaConfig)
    tipoAlerta: TipoAlerta
    titulo: string
    baseApiUrl: string
    usuarioLogado: UserDTO
    modalComponent: React.ComponentClass<PublicAlertaConfigModalFormProps>
    tituloModal?: string
    dataName?: string
}

interface AlertaConfigCardState {
    config?: AlertaConfig
    isEditing: boolean
    isFetching: boolean
}

class AlertaConfigCard extends React.Component<AlertaConfigCardProps, AlertaConfigCardState> {
    private autoPopover: AutoPopover

    constructor(props) {
        super(props)

        this.state = {
            isEditing: false,
            isFetching: false,
        }
    }

    componentDidMount() {
        this.loadConfig()
    }

    render() {
        const ModalComponent = this.props.modalComponent
        return (
            <Card noPadding>
                <LoadingContainer isFetching={this.state.isFetching}>
                    {this.state.config && (
                        <div className='alerta-config-card-content'>
                            <IconeTipoAlerta
                                transparency={!this.state.config.ativo && '50'}
                                tipoAlerta={this.props.tipoAlerta}
                            />
                            <div className='config-info'>
                                <div className='line-1'>
                                    <Text weight='semibold' className='card-title'>
                                        {this.props.titulo}
                                    </Text>
                                    <StatusTag ativo={this.state.config.ativo} />
                                    <AutoPopover
                                        ref={elem => (this.autoPopover = elem)}
                                        className='popover-menu'
                                        renderTrigger={open => (
                                            <div data-name='menu' className='alerta-config-card-menu' onClick={open}>
                                                &middot;&middot;&middot;
                                            </div>
                                        )}
                                        rootClose
                                        placement='bottom'
                                    >
                                        <Menu>
                                            <MenuItem
                                                data-name='editar'
                                                icon='editar'
                                                onClick={() => {
                                                    this.setState({ isEditing: true })
                                                    this.autoPopover.close()
                                                }}
                                            >
                                                Editar configuração
                                            </MenuItem>
                                            {this.state.config.ativo && (
                                                <MenuItem
                                                    data-name='desativar'
                                                    icon='desativar'
                                                    onClick={this.desativar}
                                                >
                                                    Desativar
                                                </MenuItem>
                                            )}
                                        </Menu>
                                    </AutoPopover>
                                </div>
                                <div className='children'>
                                    {this.props.renderChildren && this.props.renderChildren(this.state.config)}
                                    <Inscritos inscritos={this.state.config.inscritos} />
                                </div>
                                {this.state.config.ativo && !this.isInscrito() && (
                                    <Button data-name='inscrever-se' fullwidth size='medium' onClick={this.inscreverSe}>
                                        Inscrever-se
                                    </Button>
                                )}
                                {this.state.config.ativo && this.isInscrito() && (
                                    <RemoverInscricaoButton removerInscricao={this.removerInscricao} />
                                )}
                                {!this.state.config.ativo && (
                                    <Button
                                        data-name={this.props.dataName}
                                        fullwidth
                                        size='medium'
                                        type='success'
                                        onClick={this.configurar}
                                    >
                                        Configurar
                                    </Button>
                                )}
                            </div>
                            {this.state.isEditing && (
                                <ModalComponent
                                    config={this.state.config}
                                    fechar={() => this.setState({ isEditing: false })}
                                    onSubmit={this.save}
                                    onSubmitSuccess={() => this.setState({ isEditing: false })}
                                    titulo={this.props.tituloModal}
                                    tipoAlerta={this.props.tipoAlerta}
                                />
                            )}
                        </div>
                    )}
                </LoadingContainer>
            </Card>
        )
    }

    private loadConfig = () => {
        this.setState({ isFetching: true })
        return axios
            .get(this.props.baseApiUrl)
            .then(result => {
                this.setState({ isFetching: false, config: result.data })
            })
            .catch(() => this.setState({ isFetching: false }))
    }

    private configurar = () => {
        this.setState({ isEditing: true })
    }

    private desativar = () => {
        return this.save({ ...this.state.config, ativo: false })
    }

    private isInscrito = () => {
        if (!this.state.config.inscritos) {
            return false
        }
        const inscrito = this.state.config.inscritos.filter(inscrito => {
            return inscrito.id === this.props.usuarioLogado.id
        })
        return inscrito.length > 0
    }

    private inscreverSe = () => {
        let inscritos = []
        if (this.state.config.inscritos) {
            inscritos = this.state.config.inscritos
        }
        return this.save({
            ...this.state.config,
            inscritos: [...inscritos, { id: this.props.usuarioLogado.id, nome: this.props.usuarioLogado.nome }],
        })
    }

    private removerInscricao = () => {
        let inscritos = []
        if (this.state.config.inscritos) {
            inscritos = this.state.config.inscritos
        }
        inscritos = inscritos.filter(inscrito => {
            return inscrito.id !== this.props.usuarioLogado.id
        })
        return this.save({ ...this.state.config, inscritos: inscritos })
    }

    private save = (form: AlertaConfig) => {
        return axios.post(this.props.baseApiUrl, form).then(() => this.loadConfig())
    }
}

const StatusTag: React.SFC<{ ativo: boolean }> = props => {
    if (props.ativo) {
        return (
            <div className='alerta-config-card-status ativo'>
                <Text color='green' size='xx-small' weight='bold'>
                    Ativo
                </Text>
            </div>
        )
    } else {
        return (
            <div className='alerta-config-card-status desativado'>
                <Text color='grey-darker' size='xx-small' weight='bold'>
                    Desativado
                </Text>
            </div>
        )
    }
}

const Inscritos: React.SFC<{ inscritos: UsuarioDto[] }> = props => (
    <div className='inscritos'>
        <Text color='grey-darker'>
            <Icon size='is-x-small' icon='login' />
        </Text>
        {props.inscritos &&
            props.inscritos.map((inscrito, index) => (
                <Text key={inscrito.id} color='grey-darker' size='x-small' weight='semibold'>
                    {inscrito.nome}
                    {index < props.inscritos.length - 1 && '; '}
                </Text>
            ))}
        {(!props.inscritos || props.inscritos.length === 0) && (
            <Text color='grey-darker' size='x-small' weight='semibold'>
                Não possui inscritos
            </Text>
        )}
    </div>
)

interface RemoverInscricaoButtonProps {
    removerInscricao()
}

interface RemoverInscricaoButtonState {
    hover: boolean
}

class RemoverInscricaoButton extends React.Component<RemoverInscricaoButtonProps, RemoverInscricaoButtonState> {
    constructor(props) {
        super(props)

        this.state = {
            hover: false,
        }
    }

    render() {
        return (
            <Button
                data-name='remover-inscricao'
                fullwidth
                size='medium'
                type={this.state.hover ? 'transparent' : 'success'}
                icon={this.state.hover ? 'excluir' : 'aprovar'}
                outlined
                onClick={this.props.removerInscricao}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
            >
                {this.state.hover ? 'Sair' : 'Inscrito'}
            </Button>
        )
    }
}

export default withRouter(AlertaConfigCard)
