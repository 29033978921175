import React from 'react'
import {
    CheckboxField,
    ChecklistItemField,
    CheckPanelField,
    Column,
    Columns,
    Conteudo,
    ControlField,
    Linha,
} from 'libs/components'
import SensivelBadge from '../SensivelBadge'

const PermissoesDadosProdutoFinanceiroFormSection: React.FC<any> = () => {
    return (
        <>
            <ControlField name='dadosProdutoFinanceiro' label={'Dados de produto e financeiros'} required />
            <CheckPanelField
                label='Dados dos produtos'
                name='stDadosProduto'
                showErrors={true}
                testid='checkbox-dados-produto'
            >
                <Conteudo>
                    <CheckboxField name='todosDadosProduto' label='Selecionar todos' testid='checkbox-todos-produto' />
                    <Linha />
                    <Columns>
                        <Column verticalFlow>
                            <span className='bolder'>
                                <CheckboxField
                                    name='todosDadosProdutoIdentComponente'
                                    label='Identificação do componente'
                                    testid='checkbox-idComponente-produto'
                                />
                            </span>

                            <div className='pl-20 is-vertical-flow'>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosProdutoIdentComponente'
                                        optionValue='IDENTIFICADOR_DISPOSITIVO'
                                        label='Identificador do dispositivo'
                                        testid='checkbox-identificador-produto'
                                    />
                                    <SensivelBadge />
                                </div>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosProdutoIdentComponente'
                                        optionValue='CODIGO_REFERENCIA'
                                        label='Código de referência'
                                        testid='checkbox-codigo-produto'
                                    />
                                    <SensivelBadge />
                                </div>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosProdutoIdentComponente'
                                        optionValue='LOTE'
                                        label='Lote'
                                        testid='checkbox-lote-produto'
                                    />
                                    <SensivelBadge />
                                </div>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosProdutoIdentComponente'
                                        optionValue='NUMERO_SERIE'
                                        label='Número de série'
                                        testid='checkbox-numeroSerie-produto'
                                    />
                                    <SensivelBadge />
                                </div>
                                <ChecklistItemField
                                    name='dadosProdutoIdentComponente'
                                    optionValue='FABRICACAO'
                                    label='Fabricação'
                                    testid='checkbox-fabricacao-produto'
                                />
                                <ChecklistItemField
                                    name='dadosProdutoIdentComponente'
                                    optionValue='VALIDADE'
                                    label='Validade'
                                    testid='checkbox-validade-produto'
                                />
                            </div>
                        </Column>
                        <Column verticalFlow>
                            <span className='bolder'>
                                <CheckboxField
                                    name='todosDadosProdutoIdentAnvisa'
                                    label='Identificação Anvisa'
                                    testid='checkbox-idAnvisa-produto'
                                />
                            </span>

                            <div className='pl-20 is-vertical-flow'>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosProdutoIdentAnvisa'
                                        optionValue='NUMERO_REGISTRO_ANVISA'
                                        label='Número do Registro Anvisa'
                                        testid='checkbox-numeroAnvisa-produto'
                                    />
                                    <SensivelBadge />
                                </div>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosProdutoIdentAnvisa'
                                        optionValue='FABRICANTE'
                                        label='Fabricante'
                                        testid='checkbox-fabricante-produto'
                                    />
                                    <SensivelBadge />
                                </div>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosProdutoIdentAnvisa'
                                        optionValue='NOME_PRODUTO'
                                        label='Nome do produto'
                                        testid='checkbox-nome-produto'
                                    />
                                    <SensivelBadge />
                                </div>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosProdutoIdentAnvisa'
                                        optionValue='NOME_TECNICO_PRODUTO'
                                        label='Nome técnico do produto'
                                        testid='checkbox-nomeTecnico-produto'
                                    />
                                </div>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosProdutoIdentAnvisa'
                                        optionValue='CNPJ_DETENTOR_REGISTRO'
                                        label='CNPJ do Detentor do registro'
                                        testid='checkbox-cnpjDetentor-produto'
                                    />
                                    <SensivelBadge />
                                </div>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosProdutoIdentAnvisa'
                                        optionValue='RAZAO_SOCIAL_DETENTOR_REGISTRO'
                                        label='Razão social do Detentor do registro'
                                        testid='checkbox-razaoDetentor-produto'
                                    />
                                    <SensivelBadge />
                                </div>
                            </div>
                        </Column>
                    </Columns>
                </Conteudo>
            </CheckPanelField>
            <CheckPanelField
                label='Dados financeiros'
                name='stDadosFinanceiros'
                showErrors={true}
                testid='checkbox-dados-financeiros'
            >
                <Conteudo>
                    <CheckboxField
                        name='todosDadosFinanceiros'
                        label='Selecionar todos'
                        testid='checkbox-todos-financeiro'
                    />
                    <Linha />
                    <Columns>
                        <Column verticalFlow>
                            <ChecklistItemField
                                name='dadosFinanceiros'
                                label='Ente responsável'
                                optionValue='ENTE_RESPONSAVEL'
                                testid='checkbox-ente-financeiro'
                            />
                            <ChecklistItemField
                                name='dadosFinanceiros'
                                label='Quem forneceu'
                                optionValue='QUEM_FORNECEU'
                                testid='checkbox-forneceu-financeiro'
                            />
                            <ChecklistItemField
                                name='dadosFinanceiros'
                                label='Valor pago'
                                optionValue='VALOR_PAGO'
                                testid='checkbox-valorPago-financeiro'
                            />
                            <ChecklistItemField
                                name='dadosFinanceiros'
                                label='Valor ressarcido'
                                optionValue='VALOR_RESSARCIDO'
                                testid='checkbox-valorRessarcido-financeiro'
                            />
                        </Column>
                    </Columns>
                </Conteudo>
            </CheckPanelField>
        </>
    )
}

export default PermissoesDadosProdutoFinanceiroFormSection
