
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.495,"time":29700,"words":99}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`O RNI possibilita visualizar os registros que foram enviados para o RNI através da integração de dados. `}</p>
    <p>{`A visualização poderá ser realizada através do perfil de Gestor de Integração, seguindo os passos abaixo:`}</p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Controle de recebimento de dados”`}</li>
      <li parentName="ol">{`Na aba de “Dados recebidos” estarão listados todos os registros enviados que foram recebidos com sucesso pelo RNI.`}</li>
      <li parentName="ol">{`Na aba de “Dados recusados” estarão listados todos os registros enviados que foram recusados pelo RNI. É possível visualizar os erros ocorridos em cada registro enviado, clicando no botão `}<strong parentName="li">{`Detalhes dos erros`}</strong>{`.`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

