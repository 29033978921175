import React from 'react'
import * as C from 'libs/components'

const tipoCaraterIcpOptions = [
    { id: 'PRIMARIA_MENOS_12_HORAS_SINTOMA', nome: 'Primária (< 12 horas de sintoma)' },
    { id: 'PRIMARIA_MAIS_12_HORAS_SINTOMA', nome: 'Primária (> 12 horas e < 24 horas; Isquemia persistente)' },
    { id: 'FARMACO_INVASIVA', nome: 'Fármaco invasiva (entre 3 e 24 horas de trombólise)' },
    { id: 'RESGATE', nome: 'Resgate (Após > 90 min trombólise sem critérios sucesso)' },
    { id: 'TARDIA', nome: 'Tardia (> 24 horas, na mesma admissão)' },
]

export class TipoCaraterIcpField extends React.Component {
    render() {
        return <C.FixedSelectField {...this.props} options={tipoCaraterIcpOptions} />
    }
}
