import { Fields } from 'redux/modules/forms'
import { DadosFinanceirosForm } from 'backend'
import { ImmutableField } from 'libs/immutableProxy'

const dadosFinanceiro = (
    mutableValue: ImmutableField<DadosFinanceirosForm, any>,
    mutableField: ImmutableField<Fields<DadosFinanceirosForm>, any>,
    action
) => {
    const compradoHospital = mutableValue.compradoHospital.get()
    if (!compradoHospital) {
        mutableValue.compradoHospital.delete()
        mutableValue.responsavelRessarcimento.delete()
        mutableValue.precoFornecedor.delete()
        mutableValue.precoCobrado.delete()
        mutableValue.fornecedor.delete()
    } else if (compradoHospital === 'NAO') {
        mutableValue.responsavelRessarcimento.delete()
        mutableValue.precoFornecedor.delete()
        mutableValue.precoCobrado.delete()
    } else {
        mutableValue.fornecedor.delete()
    }
}

export default dadosFinanceiro
