import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { AlertModalLeave } from 'libs/components'

export const history = createBrowserHistory({
    getUserConfirmation: (message, callback) => {
        ReactDOM.render(<AlertModalLeave callback={callback} />, document.getElementById('react-modal'))
    },
})
