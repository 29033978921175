import * as React from 'react'
import { DistribuicaoProdutosProcedimentosRow } from 'backend'
import { capitalizeName } from 'libs/util/StringUtil'
import { Page } from 'redux/requester'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Card, LinkIconButton, LoadingContainer, Table, TableColumnHeader, Text } from 'libs/components'

interface Props extends RouteComponentProps<any> {
    distProdutos: Page<DistribuicaoProdutosProcedimentosRow>
    titulo?: string
    isFetching?: boolean
    idComponente: number
    tipoProcedimento: string
    report(): JSX.Element
}

class ComponenteProcedimentoTableComponent extends React.Component<Props> {
    render() {
        const { isFetching, match, location } = this.props
        const distProdutos = this.props.distProdutos
        const total = distProdutos.totalElements + ' produtos com registro ANVISA'
        return (
            <div className='report-content'>
                <Card title={total} headerAction={this.props.report()}>
                    <LoadingContainer isFetching={isFetching}>
                        {distProdutos && (
                            <Table values={distProdutos.content} className='is-spaced is-small-bis'>
                                <TableColumnHeader
                                    title='Registro'
                                    render={(item: DistribuicaoProdutosProcedimentosRow) => {
                                        return <Text>{item.registro}</Text>
                                    }}
                                />
                                <TableColumnHeader
                                    title='Nome'
                                    render={(item: DistribuicaoProdutosProcedimentosRow) => {
                                        return <Text>{capitalizeName(item.nomeComponente)}</Text>
                                    }}
                                />
                                <TableColumnHeader
                                    title='Detentor do registro'
                                    style={{ width: '30%' }}
                                    render={(item: DistribuicaoProdutosProcedimentosRow) => {
                                        return <Text>{capitalizeName(item.nomeDetentor)}</Text>
                                    }}
                                />
                                <TableColumnHeader
                                    title='Proced'
                                    className='has-text-centered'
                                    render={(item: DistribuicaoProdutosProcedimentosRow) => {
                                        return <Text>{item.quantidadeProcedimentos}</Text>
                                    }}
                                />
                                <TableColumnHeader
                                    title=''
                                    className='has-text-centered'
                                    render={(item: DistribuicaoProdutosProcedimentosRow) => {
                                        return (
                                            <LinkIconButton
                                                data-name={'row-' + distProdutos.content.indexOf(item)}
                                                to={{
                                                    pathname: `${match.url}/registro/${item.registro}`,
                                                    search: location.search,
                                                }}
                                                icon='arrowRight'
                                            />
                                        )
                                    }}
                                />
                            </Table>
                        )}
                    </LoadingContainer>
                </Card>
            </div>
        )
    }
}

export const ComponenteProcedimentoTable = withRouter(ComponenteProcedimentoTableComponent)
