
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.475,"time":28500,"words":95}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI.`}</li>
      <li parentName="ol">{`Clique no botão `}<img parentName="li" {...{
          "src": "/images/icone-alarmes.png#center",
          "alt": "..."
        }}></img>{`.`}</li>
      <li parentName="ol">{`O RNI exibe uma lista com todos os alarmes que você já recebeu, diferenciando os que já foram visualizados com os que não foram.`}</li>
      <li parentName="ol">{`Procure o alarme que você recebeu e clique no botão `}<img parentName="li" {...{
          "src": "/images/botaoVisualizarAlarme.png#center",
          "alt": "..."
        }}></img>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` A opção de visualizar para alarmes gerados por “Etiqueta sem registro” e “Motivo de revisão”, encaminham você para a visualização do procedimento em que ocorreu o evento. Para alarmes de aumento de registro, você é encaminhado para uma tela com os dados do aumento de registro.`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

