/* tslint:disable */
import React from 'react'

const SvgAlterarsenha = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 18 18' {...props}>
        <path d='M6.204 7.875V6.603C6.204 5.168 7.417 4 8.907 4c1.503 0 2.725 1.15 2.725 2.563h-.908c0-.93-.815-1.688-1.817-1.688-.99 0-1.794.775-1.794 1.728v1.272h4.746a.67.67 0 01.682.656v4.813a.67.67 0 01-.682.656H5.955a.67.67 0 01-.682-.656V8.53a.67.67 0 01.682-.656h.25zm-.022 5.25h5.45V8.75h-5.45v4.375zm2.294-1.094a.207.207 0 01-.21-.238l.187-1.074-.062-.239c-.084-.323.17-.636.516-.636s.6.313.516.636l-.062.239.186 1.074a.207.207 0 01-.21.238h-.86zm8.388-4.514c.811 4.346-2.084 8.539-6.455 9.347A8.091 8.091 0 018.932 17c-3.807 0-7.204-2.7-7.925-6.553a.44.44 0 01.864-.159c.725 3.872 4.478 6.438 8.378 5.718 3.895-.72 6.475-4.457 5.751-8.33-.77-4.118-4.96-6.278-8.387-5.716-2.455.404-3.995 2.162-4.633 3.054l1.55-.287a.436.436 0 11.16.858l-2.48.46a.442.442 0 01-.329-.07.432.432 0 01-.182-.28L1.24 3.23a.44.44 0 01.862-.16l.246 1.318c.769-1.045 2.447-2.849 5.124-3.29 3.84-.631 8.528 1.798 9.393 6.42z' />
    </svg>
)

export default SvgAlterarsenha
