import * as React from 'react'
import { HomeGestorServicoSaudeHeader } from '../HomeGestorServicoSaudeHeader'
import RastreabilidadeReportFilterContainer from '../../anvisa/reports/rastreabilidade/RastreabilidadeReportFilterContainer'

export const GestorServicoSaudeRastreabilidadeReportFilterView = () => {
    return (
        <>
            <HomeGestorServicoSaudeHeader />
            <RastreabilidadeReportFilterContainer />
        </>
    )
}
