import React from 'react'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import * as C from 'libs/components'
import { AlertItem } from '../../../../redux/modules/alert'

interface AlertBarProps {
    items: AlertItem[]
    removeAlert: (uuid: string) => void
}

export class AlertBar extends React.Component<AlertBarProps> {
    render() {
        const items = this.props.items.map(item => (
            <CSSTransition key={item.uuid} classNames='alert' timeout={200}>
                <C.AlertBarItem key={item.uuid} item={item} removeAlert={this.props.removeAlert} />
            </CSSTransition>
        ))

        return (
            <div className='alertbar'>
                <TransitionGroup>{items}</TransitionGroup>
            </div>
        )
    }
}
