import { connect } from 'react-redux'
import NovoProcedimentoView from 'view/home/novoProcedimento/NovoProcedimentoView'
import Procedimento, { tiposProcedimentoUrlByEnum } from 'api/Procedimento'
import * as novoProcedimento from 'redux/modules/novoProcedimento'
import { usePreFichaData } from '../edicaoProcedimento/usePreFichaData'
import { List } from 'immutable'

const mapStateToProps = state => {
    return {
        perfil: state.auth.user?.perfil,
        procedimentoSelecionado: state.novoProcedimento.procedimento,
        medicoResponsavel: state.novoProcedimento.vinculoProfissionalMedicoResponsavel,
        equipeMedica: state.novoProcedimento.equipeMedica,
        paciente: state.novoProcedimento.paciente,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        preencherDadosClinicos: idFicha => {
            Procedimento.getRascunho(idFicha).then(result => {
                const equipeMedica = List([result.data.medico]).concat(result.data.equipeMedica)
                const tipoProcedimento = tiposProcedimentoUrlByEnum[result.data.tipoProcedimento]
                dispatch(novoProcedimento.definirEquipeMedica(0, equipeMedica))
                dispatch(novoProcedimento.selecionarPaciente(result.data.paciente))
                dispatch(novoProcedimento.selecionarProcedimento(tipoProcedimento, result.data.lado))
                dispatch(novoProcedimento.setIdRascunhoOrigem(idFicha))
                ownProps.history.push(`/home/novoProcedimento/${idFicha}/${tipoProcedimento}`)
            })
        },
    }
}

const NovoProcedimentoContainer = connect(mapStateToProps, mapDispatchToProps)(NovoProcedimentoView)

export default NovoProcedimentoContainer
