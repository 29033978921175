/* tslint:disable */
import React from 'react'

const SvgSair = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
        <path d='M10.398 3.707l-3.878.03c-.499.003-.906-.376-.91-.847-.004-.472.397-.857.895-.86L10.383 2C12.38 1.985 14 3.507 14 5.396v5.137c0 1.886-1.608 3.422-3.602 3.438L6.52 14c-.499.004-.906-.375-.91-.847-.004-.47.397-.856.895-.86l3.878-.03c1.002-.007 1.812-.78 1.812-1.73V5.396c0-.942-.804-1.697-1.797-1.69zm-4.581.988c1.085 1.021 2.169 2.044 3.25 3.07.204.193.204.394 0 .588-1.08 1.02-2.158 2.041-3.237 3.062-.13.123-.279.187-.455.12-.18-.07-.25-.212-.25-.394l.003-1.418v-.197h-.214l-2.4.003C2.132 9.53 2 9.41 2 9.05V7.064c0-.357.133-.484.515-.484.8 0 1.6-.004 2.4-.004h.2c.003-.053.008-.118.008-.17 0-.479-.003-.953.001-1.43.003-.282.193-.457.451-.408.091.02.175.064.242.127z' />
    </svg>
)

export default SvgSair
