import {
    Button,
    ButtonBar,
    CheckboxField,
    ChecklistItemField,
    ClearableRadioField,
    Column,
    Columns,
    Conteudo,
    ControlField,
    DecimalField,
    Form,
    FormField,
    InformationIconPopover,
    InputPanel,
    Label,
    Linha,
    NumberField,
    RadioField,
    SectionTitle,
    SimNaoRadioField,
    TextAreaField,
    TextLabel,
} from 'libs/components'
import form from 'libs/redux-form/form'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import Scroll from 'react-scroll'
import { FormSection, formValueSelector } from 'redux-form/immutable'
import { usePesquisaSatisfacaoPopup } from 'view/componentes/pesquisaSatisfacao/usePesquisaSatisfacaoPopup'
import FichaStentCoronariaValidator from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaValidator'
import { FichaFormCommonProps, FichaFormWrapProps } from '../../procedimentos/ProcedimentosView'
import {
    AnticoagulacaoField,
    CardiacaCard,
    CardPaciente,
    CRMPreviaCard,
    DiabetesCard,
    DislipidemiaCard,
    DoencaCerebrovascularCard,
    DoencaPulmonarCronicaCard,
    DoencaVascularPerifericaCard,
    FibrilacaoAtrialCronicaCard,
    FuncaoVEConhecidaCard,
    HemorragicaCard,
    HipertensaoCard,
    IAMPrevioCard,
    ICCCard,
    ICPPreviaCard,
    InsuficienciaRenalCard,
    IntervencaoValvarPreviaCard,
    IntrodutorField,
    ModalSave,
    NeurologicaCard,
    ObitoCard,
    ReacoesContrasteCard,
    RespiratoriaCard,
    TabagismoCard,
    VascularCard,
    ViaAcessoField,
} from '../componentes'
import { AirKermaField } from '../componentes/cardio/AirKermaField'
import { DapField } from '../componentes/cardio/DapField'
import FichaPromptLeave from '../componentes/FichaPromptLeave'
import { handleErrorResolver } from '../DadoClinicoErrorUtil'

const stentCoronariaFormName = 'stentCoronaria'
const AntecedentesFormSection = props => {
    return (
        <FormSection name='antecedentes'>
            <Conteudo>
                <CRMPreviaCard />
                <DiabetesCard disabledNenhuma={props.disabledNenhumaDiabetes} />
                <DislipidemiaCard disabledNenhuma={props.disabledNenhumaDislipidemia} />
                <DoencaCerebrovascularCard />
                <DoencaPulmonarCronicaCard />
                <DoencaVascularPerifericaCard />
                <FibrilacaoAtrialCronicaCard disabledTipoAnticoagulacao={props.disabledTipoAnticoagulacao} />
                <FuncaoVEConhecidaCard />
                <HipertensaoCard />
                <IAMPrevioCard />
                <ICCCard />
                <ICPPreviaCard />
                <InsuficienciaRenalCard />
                <IntervencaoValvarPreviaCard />
                <TabagismoCard />
                <Linha />
                <SimNaoRadioField label='Índice de massa corporal avaliado' name='indiceMassaCorporalAvaliado' />
                <InputPanel fallback size='medium'>
                    <Columns>
                        <Column size='3'>
                            <NumberField
                                placeholder='(20-250)'
                                maxLength={3}
                                label='Altura'
                                name='altura'
                                unit={'cm'}
                                disabled={props.disabledIndiceMassaCorporal}
                            />
                        </Column>
                        <Column size='4'>
                            <DecimalField
                                placeholder='(0,50-500,00)'
                                label='Peso'
                                name='peso'
                                unit={'kg'}
                                disabled={props.disabledIndiceMassaCorporal}
                                pre={3}
                                pos={2}
                            />
                        </Column>
                        <Column>
                            <Column>
                                <TextLabel
                                    title='IMC'
                                    name='imc'
                                    placeholder='Informe altura e peso.'
                                    value={props.imc}
                                />
                            </Column>
                        </Column>
                    </Columns>
                </InputPanel>
            </Conteudo>
        </FormSection>
    )
}

const ICPComplementarFormSection = props => {
    return (
        <FormSection name='icpComplementar'>
            <Conteudo>
                <Columns>
                    <Column verticalFlow size='7'>
                        <ControlField name='dispositivosAdjuntos' label={'Dispositivos adjuntos'} />
                        <ChecklistItemField
                            name='dispositivosAdjuntos'
                            optionValue='DISPOSITIVO_ASSISTENCIA_MECANICA'
                            label='Dispositivo de assistência mecânica'
                        />
                        <InputPanel fallback>
                            <FormField>
                                <RadioField
                                    name='tipoDispositivo'
                                    label='Balão intra-aórtico'
                                    value='BALAO_INTRA_AORTICO'
                                    disabled={props.disabledDispositivo}
                                />
                                <RadioField
                                    name='tipoDispositivo'
                                    label='Impela'
                                    value='IMPELA'
                                    disabled={props.disabledDispositivo}
                                />
                                <RadioField
                                    name='tipoDispositivo'
                                    label='Outro'
                                    value='OUTRO'
                                    disabled={props.disabledDispositivo}
                                />
                            </FormField>
                        </InputPanel>
                        <ChecklistItemField
                            name='dispositivosAdjuntos'
                            optionValue='CUTTING_BALLON'
                            label='Cutting Ballon'
                        />
                        <ChecklistItemField
                            name='dispositivosAdjuntos'
                            optionValue='FILTRO_PROTECAO_DISTAL'
                            label='Filtro de proteção distal'
                        />
                        <ChecklistItemField name='dispositivosAdjuntos' optionValue='ROTABLATOR' label='Rotablator' />
                        <ChecklistItemField
                            name='dispositivosAdjuntos'
                            optionValue='TROMBECTOMIA_MECANICA'
                            label='Trombectomia mecânica'
                        />
                    </Column>
                    <Column verticalFlow size='4'>
                        <ControlField name='metodosAuxiliares' label={'Métodos auxiliares'} />
                        <div className='is-horizontal-flow align-center'>
                            <ChecklistItemField name='metodosAuxiliares' optionValue='FFR' label='FFR' />
                            <InformationIconPopover information='FFR ( Fluxo Fracionado de Reserva )' spaced />
                        </div>
                        <ChecklistItemField
                            name='metodosAuxiliares'
                            optionValue='HISTOLOGIA_VIRTUAL'
                            label='Histologia virtual'
                        />
                        <div className='is-horizontal-flow align-center'>
                            <ChecklistItemField name='metodosAuxiliares' optionValue='OCT' label='OCT' />
                            <InformationIconPopover information='OCT ( Tomografia de coerência óptica )' spaced />
                        </div>
                        <div className='is-horizontal-flow align-center'>
                            <ChecklistItemField name='metodosAuxiliares' optionValue='USIC' label='USIC' />
                            <InformationIconPopover information='USIC ( Ultrassom Intracoronário )' spaced />
                        </div>
                        <div className='is-horizontal-flow align-center'>
                            <ChecklistItemField name='metodosAuxiliares' optionValue='IFR' label='IFR' />
                            <InformationIconPopover information='IFR ( Instantaneous wave-free ratio )' spaced />
                        </div>
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <Label>Radiação</Label>
                        <InputPanel fallback size='medium'>
                            <ControlField name='fluoroscopiaSegundo' label='Tempo fluoroscopia' />
                            <Columns horizontalFlow>
                                <Column size='3'>
                                    <NumberField
                                        maxLength={2}
                                        name='fluoroscopiaMinuto'
                                        unit={'min'}
                                        placeholder='(0-99)'
                                    />
                                </Column>
                                <Column size='3'>
                                    <NumberField
                                        maxLength={2}
                                        name='fluoroscopiaSegundo'
                                        unit={'seg'}
                                        placeholder='(0-59)'
                                    />
                                </Column>
                            </Columns>
                            <AirKermaField numberValue={props.airKerma} />
                            <DapField numberValue={props.dap} />
                        </InputPanel>
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const AcessoArterialContrasteFormSection = props => {
    return (
        <FormSection name='acessoArterialContraste'>
            <Conteudo>
                <Columns>
                    <Column size='5' verticalFlow>
                        <ViaAcessoField label='Via de acesso' name='viaAcesso' />
                        <p>
                            <CheckboxField label='Crossover' name='crossover' disabled={props.disabledCrossover} />
                            <InformationIconPopover
                                information='Marcar apenas se houver mudança na via de acesso. Marque a via de acesso final'
                                size='small'
                            />
                        </p>
                    </Column>
                    <Column size='5'>
                        <ControlField label='Lado' name='lado'>
                            <ChecklistItemField name='lado' label='Esquerdo' optionValue='ESQUERDO' />
                            <ChecklistItemField name='lado' label='Direito' optionValue='DIREITO' />
                        </ControlField>
                    </Column>
                </Columns>
                <Columns verticalFlow>
                    <Column size='5'>
                        <IntrodutorField label='Introdutor' name='introdutor' />
                    </Column>
                    <Column size='7'>
                        <ControlField label={'Tipo de contraste'} name='tipoContraste'>
                            <ClearableRadioField
                                name='tipoContraste'
                                value='IONICO_HIPEROSMOLAR'
                                label='Iônico hiperosmolar'
                            />
                            <ClearableRadioField
                                name='tipoContraste'
                                value='IONICO_BAIXA_OSMOLARIDADE'
                                label='Iônico baixa osmolaridade'
                            />
                            <ClearableRadioField
                                name='tipoContraste'
                                value='NAO_IONICO_BAIXA_OSMOLARIDADE'
                                label='Não iônico baixa osmolaridade'
                            />
                            <ClearableRadioField
                                name='tipoContraste'
                                value='NAO_IONICO_ISOSMOLAR'
                                label='Não iônico isosmolar'
                            />
                        </ControlField>
                    </Column>
                    <Column size='3'>
                        <NumberField
                            maxLength={3}
                            label='Volume total'
                            name='volume'
                            unit='ml'
                            disabled={props.disableVolumeContraste}
                            placeholder='(1-999)'
                        />
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const MedicaoProcedimentoFormSection = () => {
    return (
        <FormSection name='medicacaoProcedimento'>
            <Conteudo>
                <Columns>
                    <Column size='5' verticalFlow>
                        <ControlField label={'Antiplaquetário'} name='antiplaquetario' />
                        <ChecklistItemField name='antiplaquetario' optionValue='AAS' label='AAS' />
                        <ChecklistItemField name='antiplaquetario' optionValue='CLOPIDOGREL' label='Clopidogrel' />
                        <ChecklistItemField name='antiplaquetario' optionValue='PRASUGREL' label='Prasugrel' />
                        <ChecklistItemField name='antiplaquetario' optionValue='TICAGRELOR' label='Ticagrelor' />
                        <ChecklistItemField name='antiplaquetario' optionValue='TICLOPIDINA' label='Ticlopidina' />
                        <ChecklistItemField name='antiplaquetario' optionValue='OUTRO' label='Outro' />
                    </Column>
                    <Column size='5' verticalFlow>
                        <AnticoagulacaoField name='anticoagulacao' label='Anticoagulação' />
                        <ControlField label='Inibidor GP IIb/IIIa' name='inibidor' verticalFlow>
                            <ClearableRadioField name='inibidor' label='Abxicimab' value='ABXICIMAB' />
                            <ClearableRadioField name='inibidor' label='Tirofiban' value='TIROFIBAN' />
                            <ClearableRadioField name='inibidor' label='Outro' value='OUTRO' />
                        </ControlField>
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const ComplicacoesClinicasFormSection = props => {
    return (
        <FormSection name='complicacoesClinicas'>
            <Conteudo>
                <SimNaoRadioField label='Houve complicações em sala' name='houveComplicacoes' />
                <CardiacaCard disabled={props.disableComplicacoesCards} />
                <HemorragicaCard disabled={props.disableComplicacoesCards} />
                <NeurologicaCard disabled={props.disableComplicacoesCards} />
                <ReacoesContrasteCard disabled={props.disableComplicacoesCards} />
                <RespiratoriaCard disabled={props.disableComplicacoesCards} />
                <VascularCard disabled={props.disableComplicacoesCards} disabledHematoma={props.disabledHematoma} />
                <Linha size='medium' />
                <ObitoCard disabled={props.disableComplicacoesCards} />
            </Conteudo>
        </FormSection>
    )
}

const ConclusaoFormSection = () => {
    return (
        <FormSection name='conclusao'>
            <Conteudo>
                <TextAreaField
                    label='Conclusão do procedimento'
                    name='conclusao'
                    placeholder='Texto explicativo para o médico escrever a conclusão do procedimento. Para incluir na impressão como laudo.'
                />
            </Conteudo>
        </FormSection>
    )
}

interface FichaFormCmpProps extends FichaFormCommonProps {
    imc: string
    disabledNenhumaDiabetes: boolean
    disabledNenhumaDislipidemia: boolean
    disabledTipoAnticoagulacao: boolean
    disabledIndiceMassaCorporal: boolean
    disabledDispositivo: boolean
    airKerma: number
    dap: number
    disabledCrossover: boolean
    disableVolumeContraste: boolean
    disableComplicacoesCards: boolean
    disabledHematoma: boolean
}

const FichaStentCoronariaComplementarViewCmp = (props: FichaFormCmpProps) => {
    return (
        <div>
            <Form>
                <FormSection name='complementar'>
                    <CardPaciente paciente={props.paciente} procedimento={props.procedimento} />
                    <Scroll.Element name='antecedentes'>
                        <SectionTitle title='ANTECEDENTES' />
                        <AntecedentesFormSection
                            imc={props.imc}
                            disabledNenhumaDiabetes={props.disabledNenhumaDiabetes}
                            disabledNenhumaDislipidemia={props.disabledNenhumaDislipidemia}
                            disabledTipoAnticoagulacao={props.disabledTipoAnticoagulacao}
                            disabledIndiceMassaCorporal={props.disabledIndiceMassaCorporal}
                        />
                    </Scroll.Element>
                    <Scroll.Element name='icpComplementar'>
                        <SectionTitle title='ICP - COMPLEMENTAR' />
                        <ICPComplementarFormSection
                            disabledDispositivo={props.disabledDispositivo}
                            airKerma={props.airKerma}
                            dap={props.dap}
                        />
                    </Scroll.Element>
                    <Scroll.Element name='acessoArterialContraste'>
                        <SectionTitle title='ACESSO ARTERIAL/CONTRASTE' />
                        <AcessoArterialContrasteFormSection
                            disableVolumeContraste={props.disableVolumeContraste}
                            disabledCrossover={props.disabledCrossover}
                        />
                    </Scroll.Element>
                    <Scroll.Element name='medicacaoProcedimento'>
                        <SectionTitle title='MEDICAÇÃO DO PROCEDIMENTO' />
                        <MedicaoProcedimentoFormSection />
                    </Scroll.Element>
                    <Scroll.Element name='complicacoesClinicas'>
                        <SectionTitle title='COMPLICAÇÕES CLÍNICAS' />
                        <ComplicacoesClinicasFormSection
                            disableComplicacoesCards={props.disableComplicacoesCards}
                            disabledHematoma={props.disabledHematoma}
                        />
                    </Scroll.Element>
                    <Scroll.Element name='conclusao'>
                        <SectionTitle title='CONCLUSÃO' />
                        <ConclusaoFormSection />
                    </Scroll.Element>
                </FormSection>
            </Form>
            <Conteudo>
                <Column />
                <Linha size='medium' />
                <ButtonBar>
                    <Button pulledLeft size='medium' className='simple-button' onClick={props.onCancel}>
                        Cancelar
                    </Button>
                    <Button
                        pulledRight
                        type='success'
                        size='medium'
                        loading={props.submitting}
                        onClick={props.handleSubmit}
                    >
                        Salvar registro
                    </Button>
                </ButtonBar>
            </Conteudo>
        </div>
    )
}

const selector = formValueSelector('stentCoronaria')

const FichaStentCoronariaComplementarForm = ({ editMode, ...rest }: FichaFormWrapProps) => {
    const Form = useMemo(
        () =>
            form<{}, FichaFormCmpProps>({
                form: stentCoronariaFormName,
                validate: FichaStentCoronariaValidator,
                hasLeaveModal: false,
                successTitle: editMode ? 'Edição realizada!' : 'Ficha registrada!',
                successModal: ModalSave,
                successContent: (
                    <span>
                        O registro foi salvo com sucesso. Se preferir, você pode imprimi-lo ou voltar à página inicial
                        de seu perfil.
                    </span>
                ),
                destroyOnUnmount: false,
                forceUnregisterOnUnmount: true,
            })(
                connect(state => {
                    const peso = selector(state, 'complementar.antecedentes.peso')
                    const altura = selector(state, 'complementar.antecedentes.altura')
                    const imcCalculating = peso && altura && altura !== '0' && peso / ((altura / 100) * (altura / 100))
                    const imc = imcCalculating && imcCalculating.toFixed(2) + ' kg/m²'

                    const terapiaDiabetes = selector(state, 'complementar.antecedentes.diabetesTerapia')
                    const disabledNenhumaDiabetes = terapiaDiabetes && terapiaDiabetes.includes('NENHUMA')

                    const dislipidemiaTerapia = selector(state, 'complementar.antecedentes.dislipidemiaTerapia')
                    const disabledNenhumaDislipidemia = dislipidemiaTerapia && dislipidemiaTerapia.includes('NENHUMA')

                    const disabledTipoAnticoagulacao =
                        selector(state, 'complementar.antecedentes.fibrilacaoAnticoagulacao') !== 'SIM'
                    const disabledIndiceMassaCorporal =
                        selector(state, 'complementar.antecedentes.indiceMassaCorporalAvaliado') !== 'SIM'

                    const dispositivos = selector(state, 'complementar.icpComplementar.dispositivosAdjuntos')
                    const disabledDispositivo =
                        !dispositivos || !dispositivos.includes('DISPOSITIVO_ASSISTENCIA_MECANICA')

                    const airKerma = selector(state, 'complementar.icpComplementar.airKerma')
                    const dap = selector(state, 'complementar.icpComplementar.dap')

                    const disabledCrossover = !selector(state, 'complementar.acessoArterialContraste.viaAcesso')
                    const tipoContraste = selector(state, 'complementar.acessoArterialContraste.tipoContraste')
                    const disableVolumeContraste = !tipoContraste || tipoContraste.length === 0

                    const disableComplicacoesCards =
                        selector(state, 'complementar.complicacoesClinicas.houveComplicacoes') !== 'SIM'

                    const hematoma = selector(state, 'complementar.complicacoesClinicas.vascularQuaisComplicacoes')
                    const disabledHematoma = !hematoma || !hematoma.includes('HEMATOMA')

                    return {
                        imc,
                        disabledNenhumaDiabetes,
                        disabledNenhumaDislipidemia,
                        disabledTipoAnticoagulacao,
                        disabledIndiceMassaCorporal,
                        disabledDispositivo,
                        airKerma,
                        dap,
                        disabledCrossover,
                        disableVolumeContraste,
                        disableComplicacoesCards,
                        disabledHematoma,
                    }
                })(FichaStentCoronariaComplementarViewCmp)
            ),
        [editMode]
    )
    return <Form {...rest} />
}

export const FichaStentCoronariaComplementarView = props => {
    const { showPesquisa } = usePesquisaSatisfacaoPopup()

    const localOnSuccess = () => {
        props.onSubmitSuccess()
        showPesquisa()
    }

    const showModalLeave = React.useRef<boolean>(true)

    const handleSubmitFail = error => {
        if (typeof error === 'string' || error?._error?.status === 404) {
            showModalLeave.current = false
            props.goHome()
        }
    }

    return (
        <>
            <FichaPromptLeave formName={stentCoronariaFormName} showModalLeave={showModalLeave} />
            <FichaStentCoronariaComplementarForm
                {...props}
                editMode={props.editMode}
                onSubmit={props.onSubmit}
                onSubmitSuccess={localOnSuccess}
                onSubmitFail={handleSubmitFail}
                errorResolver={handleErrorResolver}
            />
        </>
    )
}
