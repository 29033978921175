import React from 'react'
import { Label } from 'libs/components'

interface IntegracaoRowItemHeaderProps {
    title: string
    subtitle: string
}

export const IntegracaoRowItemHeader = (props: IntegracaoRowItemHeaderProps) => {
    const { title, subtitle } = props

    return (
        <div className='integracao-row-item-header'>
            <Label className='integracao-row-item-header-title' testid='label-nomeUsuario'>
                {title}
            </Label>
            <Label className='integracao-row-item-header-subtitle' testid='label-dataCadastro'>
                {subtitle}
            </Label>
        </div>
    )
}
