import React, { useCallback, useMemo, useState } from 'react'
import Entidades from 'api/Entidades'
import { CadastroEntidadeConsumidoraForm } from 'backend'
import {
    AlertModal,
    AlertModalButtonBar,
    AlertModalContent,
    Button,
    ButtonBar,
    Conteudo,
    Form,
    Linha,
    SectionTitle,
} from 'libs/components'
import PeriodoVigenciaForm from 'libs/components/presentational/form/composed/PeriodoVigenciaForm'
import form from 'libs/redux-form/form'
import { isEmpty } from 'lodash'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Scroll from 'react-scroll'
import { formValueSelector, getFormSubmitErrors } from 'redux-form/immutable'
import Aviso from 'view/componentes/Aviso'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import AdicionarEntidadesValidator from './AdicionarEntidadesValidator'
import IdentificacaoFormSection from './components/form/IdentificacaoFormSection'
import PermissoesDadosPacientesMedicosFormSection from './components/form/PermissoesDadosPacientesMedicosFormSection'
import PermissoesDadosProcedimentoFormSection from './components/form/PermissoesDadosProcedimentoFormSection'
import PermissoesDadosProdutoFinanceiroFormSection from './components/form/PermissoesDadosProdutoFinanceiroFormSection'
import PermissoesEsferasAcessoFormSection from './components/form/PermissoesEsferasAcessoFormSection'
import PermissoesHospitaisFormSection from './components/form/PermissoesHospitaisFormSection'
import { State } from 'redux/modules'
import { updateSyncErrors } from 'redux-form'

export const adicionarEntidadeFormName = 'adicionarEntidade'

export const selector = formValueSelector(adicionarEntidadeFormName)

interface AdicionarEntidadeViewProps {
    editMode: boolean
    entidadeInitialValue?: CadastroEntidadeConsumidoraForm | null
}

const AdicionarEntidadeView: React.FC<AdicionarEntidadeViewProps> = props => {
    const history = useHistory()

    const goHome = useCallback(() => {
        history.push('/home/entidades/ativas')
    }, [history])

    const afterSave = goHome
    const onCancel = goHome

    const onSave = useCallback(form => {
        const mutableForm = form.toJS()
        return form.get('id') ? Entidades.updateEntidade(mutableForm) : Entidades.saveEntidade(mutableForm)
    }, [])

    if (props.editMode && isEmpty(props.entidadeInitialValue)) {
        return null
    }

    return (
        <div className='adicionarEntidade--page'>
            <ReportHeader title={props.editMode ? 'Editar entidade' : 'Adicionar entidade'} />

            <div className='entidade-form'>
                <Aviso>
                    Ao realizar o preenchimento das informações a serem incluídas para acesso da entidade, o
                    profissional Anvisa deve seguir as diretrizes estabelecidas na{' '}
                    <strong>Lei Geral de Proteção de Dados (Lei no 13.709/2018)</strong>. Os campos contendo a mensagem
                    ”SENSÍVEL” são informações que deverão exigir maior cuidado durante sua manipulação e gerenciamento.
                </Aviso>
                <FichaForm
                    onSubmit={onSave}
                    onSubmitSuccess={() => {
                        afterSave()
                    }}
                    onCancel={onCancel}
                    editMode={props.editMode}
                    initialValues={props.entidadeInitialValue}
                />
            </div>
        </div>
    )
}

export default AdicionarEntidadeView

interface FichaFormCmpProps {
    onCancel: () => any
    submitting: boolean
    handleSubmit: () => any
    pristine: boolean
    valid: boolean

    editMode: boolean

    disabledHospitaisEndereco: boolean
    disabledPacientesEndereco: boolean
    disabledMedicosEndereco: boolean
    isVigenciaIndeterminada: boolean

    dadosSensiveisSelected: boolean
}

const FichaFormCmp: React.FC<FichaFormCmpProps> = props => {
    const dispatch = useDispatch()

    const [showModalDadosSensiveis, setShowModalDadosSensiveis] = useState<boolean>(false)

    const formErrors = useSelector<State, any>(state => getFormSubmitErrors(adicionarEntidadeFormName)(state))

    React.useEffect(() => {
        if (formErrors.get('anoInicio') !== undefined || formErrors.get('mesInicio') !== undefined || formErrors.get('mesFim') !== undefined || formErrors.get('anoFim') !== undefined) {
            dispatch(updateSyncErrors(adicionarEntidadeFormName, {
                periodoVigencia: formErrors.get('anoInicio') || formErrors.get('mesInicio') || formErrors.get('anoFim') || formErrors.get('mesFim')
            }, null))
        }
    }, [formErrors, dispatch])

    function criarEntidade() {
        if (props.dadosSensiveisSelected) {
            if (!props.valid) {
                props.handleSubmit()
            } else {
                setShowModalDadosSensiveis(true)
            }
        } else {
            props.handleSubmit()
        }
    }

    return (
        <>
            <Form>
                <Scroll.Element name='identificacao'>
                    <SectionTitle title='INFORMAÇÕES DA ENTIDADE' />
                    <IdentificacaoFormSection />
                </Scroll.Element>
                <Scroll.Element name='permissoesCompartilhamento'>
                    <SectionTitle title='PERMISSÕES DE COMPARTILHAMENTO ' />
                    <Conteudo>
                        <PermissoesEsferasAcessoFormSection />
                        <div className='padding-separator'></div>
                        <PermissoesHospitaisFormSection disabledHospitaisEndereco={props.disabledHospitaisEndereco} />
                        <div className='padding-separator'></div>
                        <PermissoesDadosPacientesMedicosFormSection
                            disabledPacientesEndereco={props.disabledPacientesEndereco}
                            disabledMedicosEndereco={props.disabledMedicosEndereco}
                        />
                        <div className='padding-separator'></div>
                        <PermissoesDadosProcedimentoFormSection />
                        <div className='padding-separator'></div>
                        <PermissoesDadosProdutoFinanceiroFormSection />
                        <div className='padding-separator'></div>
                        <PeriodoVigenciaForm formName={adicionarEntidadeFormName} />
                    </Conteudo>
                </Scroll.Element>
            </Form>
            <Conteudo>
                <Linha size='medium' />
                <ButtonBar>
                    <Button pulledLeft size='medium' className='simple-button' onClick={props.onCancel}>
                        Cancelar
                    </Button>
                    <Button pulledRight type='primary' size='medium' loading={props.submitting} onClick={criarEntidade}>
                        {props.editMode ? 'Salvar entidade' : 'Adicionar entidade'}
                    </Button>
                </ButtonBar>
            </Conteudo>

            <AlertModal active={showModalDadosSensiveis} info>
                <AlertModalContent title='Dados sensíveis selecionados'>
                    <span>Foram selecionados um ou mais dados sensíveis durante o preenchimento das informações.</span>
                    <br />
                    <br />
                    <span>
                        O compartilhamento, uso e gerenciamento das informações do RNI deve estar de acordo com o que é
                        estabelecido na <strong>Lei Geral de Proteção de Dados (Lei n° 13.709/2018)</strong>.
                    </span>
                    <br />
                    <br />
                    <span>Deseja adicionar a entidade?</span>
                </AlertModalContent>
                <AlertModalButtonBar>
                    <Button size='medium' onClick={() => setShowModalDadosSensiveis(false)}>
                        Revisar dados
                    </Button>
                    <Button
                        type='primary'
                        size='medium'
                        onClick={() => {
                            setShowModalDadosSensiveis(false)
                            props.handleSubmit()
                        }}
                    >
                        Finalizar cadastro
                    </Button>
                </AlertModalButtonBar>
            </AlertModal>
        </>
    )
}

const FichaForm: React.FC<any> = ({ editMode, ...rest }) => {
    const FormWrapper = useMemo(
        () =>
            form<{}, any>({
                form: adicionarEntidadeFormName,
                validate: AdicionarEntidadesValidator,
                successTitle: editMode ? 'Edição realizada!' : 'Entidade adicionada!',
            })(
                connect(state => {
                    const disabledHospitaisEndereco = selector(state, 'enderecoHospitalAtivo') !== true
                    const disabledPacientesEndereco = selector(state, 'enderecoPacienteAtivo') !== true
                    const disabledMedicosEndereco = selector(state, 'enderecoMedicoAtivo') !== true

                    /**
                     * Verifica se tem dados sensíveis selecionados
                     */
                    const dadosPaciente = new Set(selector(state, 'dadosPaciente') || [])
                    const dadosMedicos = new Set(selector(state, 'dadosMedicos') || [])
                    const dadosProdutoIdentComponente = new Set(selector(state, 'dadosProdutoIdentComponente') || [])
                    const dadosProdutoIdentAnvisa = new Set(selector(state, 'dadosProdutoIdentAnvisa') || [])

                    const haveDadosPacienteSensivel =
                        dadosPaciente.has('NOME_COMPLETO') || dadosPaciente.has('CPF') || dadosPaciente.has('CNS')

                    const haveDadosMedicosSensivel =
                        dadosMedicos.has('NOME_COMPLETO') || dadosMedicos.has('CRM') || dadosMedicos.has('CPF')

                    const haveDadosProdutoIdentComponenteSensivel =
                        dadosProdutoIdentComponente.has('IDENTIFICADOR_DISPOSITIVO') ||
                        dadosProdutoIdentComponente.has('CODIGO_REFERENCIA') ||
                        dadosProdutoIdentComponente.has('LOTE') ||
                        dadosProdutoIdentComponente.has('NUMERO_SERIE')

                    const haveDadosProdutoIdentAnvisaSensivel =
                        dadosProdutoIdentAnvisa.has('NUMERO_REGISTRO_ANVISA') ||
                        dadosProdutoIdentAnvisa.has('FABRICANTE') ||
                        dadosProdutoIdentAnvisa.has('NOME_PRODUTO') ||
                        dadosProdutoIdentAnvisa.has('CNPJ_DETENTOR_REGISTRO') ||
                        dadosProdutoIdentAnvisa.has('RAZAO_SOCIAL_DETENTOR_REGISTRO')

                    const dadosSensiveisSelected =
                        haveDadosPacienteSensivel ||
                        haveDadosMedicosSensivel ||
                        haveDadosProdutoIdentComponenteSensivel ||
                        haveDadosProdutoIdentAnvisaSensivel

                    return {
                        disabledHospitaisEndereco,
                        disabledPacientesEndereco,
                        disabledMedicosEndereco,
                        dadosSensiveisSelected,
                        editMode,
                    }
                })(FichaFormCmp)
            ),
        [editMode]
    )
    return <FormWrapper {...rest} />
}
