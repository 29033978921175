import { PerfilEnum } from 'api/Perfis'
import { todosUsuarios } from './ManualCompletoUsuarioView'

export type TopicoManual = {
    path: string
    titulo: string
    subTopicos?: TopicoManual[]
    possuiBotao: boolean
    arquivoPath: any
    botaoManual?: BotaoManual
    perfil?: any
    btnPathPadrao: string
}

export type BotaoManual = {
    medico?: string
    medicoResidente?: string
    anvisa?: string
    tecnico?: string
    financeiro?: string
    gestorSeguranca?: string
    gestorServicoSaude?: string
    gestorIntegracao?: string
    snvs?: string
    registro?: string
    cnpj?: string
    semEtiqueta?: string
    preFicha?: string
    dadosClinicos?: string
    dadosProduto?: string
    dadosFinanceiros?: string
    dadosProdutoFinanceiros?: string
}

export const TodosTopicos: TopicoManual[] = [
    {
        path: 'acesso',
        titulo: 'Acesso, conta, vínculos',
        perfil: todosUsuarios,
        subTopicos: [
            {
                path: 'cadastro',
                titulo: 'Como me cadastro no RNI?',
                subTopicos: [],
                possuiBotao: true,
                arquivoPath: 'acesso/cadastroTecnico.md',
                btnPathPadrao: '/tecnico',
                botaoManual: {
                    tecnico: 'acesso/cadastroTecnico.md',
                    medico: 'acesso/cadastroMedico.md',
                    medicoResidente: 'acesso/cadastroMedicoResidente.md',
                    financeiro: 'acesso/cadastroAdministrativo.md',
                    anvisa: 'acesso/cadastroColaboradorAnvisa.md',
                    gestorSeguranca: 'acesso/cadastroGestorSeguranca.md',
                    gestorServicoSaude: 'acesso/cadastroGestorServicoSaude.md',
                    gestorIntegracao: 'acesso/cadastroGestorIntegracao.md',
                    snvs: 'acesso/cadastroSnvs.md',
                },
            },
            {
                path: 'login',
                titulo: 'Como faço login no RNI?',
                subTopicos: [],
                possuiBotao: true,
                btnPathPadrao: '',
                arquivoPath: 'acesso/fazerLoginTecnico.md',
                botaoManual: {
                    tecnico: 'acesso/fazerLoginTecnico.md',
                    medico: 'acesso/fazerLoginMedico.md',
                    medicoResidente: 'acesso/fazerLoginMedicoResidente.md',
                    financeiro: 'acesso/fazerLoginFinanceiro.md',
                    gestorSeguranca: 'acesso/fazerLoginGestorSeguranca.md',
                    anvisa: 'acesso/fazerLoginAnvisa.md',
                    gestorServicoSaude: 'acesso/fazerLoginGestorServicoSaude.md',
                    gestorIntegracao: 'acesso/fazerLoginGestorIntegracao.md',
                    snvs: 'acesso/fazerLoginSnvs.md',
                },
            },
            {
                path: 'dadosPessoais',
                titulo: 'Como atualizar meus dados pessoais?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'acesso/alterarDadosPessoais.md',
            },
            {
                path: 'recuperarSenha',
                titulo: 'Como recuperar minha senha?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'acesso/recuperarSenha.md',
            },
            {
                path: 'alterarSenha',
                titulo: 'Como alterar minha senha?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'acesso/alterarSenha.md',
            },
            {
                path: 'adicionarVinculo',
                titulo: 'Como adicionar vínculo a outra instituição?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'acesso/adicionarVinculo.md',
            },
            {
                path: 'mudarVinculo',
                titulo: 'Como mudar instituição/vínculo?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'acesso/alterarVinculo.md',
            },
        ],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
    },

    {
        path: 'criarProcedimento',
        titulo: 'Criando um procedimento',
        perfil: todosUsuarios,
        subTopicos: [
            {
                path: 'procedimentoCardiologia',
                titulo: 'Como criar procedimento de cardiologia?',
                subTopicos: [],
                possuiBotao: true,
                btnPathPadrao: '/tecnico',
                arquivoPath: 'criarProcedimento/criarCardiologiaTecnico.md',
                botaoManual: {
                    tecnico: 'criarProcedimento/criarCardiologiaTecnico.md',
                    medico: 'criarProcedimento/criarCardiologiaMedico.md',
                    medicoResidente: 'criarProcedimento/criarCardiologiaMedicoResidente.md',
                },
            },
            {
                path: 'procedimentoJoelhoPrimaria',
                titulo: 'Como criar procedimento de artroplastia primária de joelho?',
                subTopicos: [],
                possuiBotao: true,
                btnPathPadrao: '/tecnico',
                arquivoPath: 'criarProcedimento/criarJoelhoPrimariaTecnico.md',
                botaoManual: {
                    tecnico: 'criarProcedimento/criarJoelhoPrimariaTecnico.md',
                    medico: 'criarProcedimento/criarJoelhoPrimariaMedico.md',
                    medicoResidente: 'criarProcedimento/criarJoelhoPrimariaMedicoResidente.md',
                },
            },
            {
                path: 'procedimentoJoelhoRevisao',
                titulo: 'Como criar procedimento de revisão de artroplastia de joelho?',
                subTopicos: [],
                possuiBotao: true,
                btnPathPadrao: '/tecnico',
                arquivoPath: 'criarProcedimento/criarJoelhoRevisaoTecnico.md',
                botaoManual: {
                    tecnico: 'criarProcedimento/criarJoelhoRevisaoTecnico.md',
                    medico: 'criarProcedimento/criarJoelhoRevisaoMedico.md',
                    medicoResidente: 'criarProcedimento/criarJoelhoRevisaoMedicoResidente.md',
                },
            },
            {
                path: 'procedimentoQuadrilPrimaria',
                titulo: 'Como criar procedimento de artroplastia primária de quadril?',
                subTopicos: [],
                possuiBotao: true,
                btnPathPadrao: '/tecnico',
                arquivoPath: 'criarProcedimento/criarQuadrilPrimariaTecnico.md',
                botaoManual: {
                    tecnico: 'criarProcedimento/criarQuadrilPrimariaTecnico.md',
                    medico: 'criarProcedimento/criarQuadrilPrimariaMedico.md',
                    medicoResidente: 'criarProcedimento/criarQuadrilPrimariaMedicoResidente.md',
                },
            },
            {
                path: 'procedimentoQuadrilRevisao',
                titulo: 'Como criar procedimento de revisão de artroplastia de quadril?',
                subTopicos: [],
                possuiBotao: true,
                btnPathPadrao: '/tecnico',
                arquivoPath: 'criarProcedimento/criarQuadrilRevisaoTecnico.md',
                botaoManual: {
                    tecnico: 'criarProcedimento/criarQuadrilRevisaoTecnico.md',
                    medico: 'criarProcedimento/criarQuadrilRevisaoMedico.md',
                    medicoResidente: 'criarProcedimento/criarQuadrilRevisaoMedicoResidente.md',
                },
            },
            {
                path: 'excluirProcedimento',
                titulo: 'Como excluir um procedimento?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'criarProcedimento/excluirProcedimento.md',
            },
            {
                path: 'editarProcedimento',
                titulo: 'Como editar dados preenchidos em um procedimento?',
                subTopicos: [],
                possuiBotao: true,
                btnPathPadrao: '/preFicha',
                arquivoPath: 'criarProcedimento/editarPreFicha.md',
                botaoManual: {
                    preFicha: 'criarProcedimento/editarPreFicha.md',
                    dadosClinicos: 'criarProcedimento/editarDadosClinicos.md',
                    dadosProduto: 'criarProcedimento/editarDadosProduto.md',
                    dadosFinanceiros: 'criarProcedimento/editarDadosFinanceiros.md',
                    dadosProdutoFinanceiros: 'criarProcedimento/editarDadosProdutoFinanceiros.md',
                },
            },
        ],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
    },

    {
        path: 'dadosClinicos',
        titulo: 'Registrando dados clínicos',
        perfil: todosUsuarios,
        subTopicos: [
            {
                path: 'dadosCardiologia',
                titulo: 'Como registro dados de cardiologia?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'dadosClinicos/dadosCardiologia.md',
            },
            {
                path: 'dadosArtoplastiaJoelhoPrimaria',
                titulo: 'Como registro dados da artroplastia primária de joelho?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'dadosClinicos/dadosJoelhoPrimaria.md',
            },
            {
                path: 'dadosArtoplastiaJoelhoRevisao',
                titulo: 'Como registro dados da revisão de artroplastia de joelho?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'dadosClinicos/dadosJoelhoRevisao.md',
            },
            {
                path: 'dadosArtoplastiaQuadrilPrimaria',
                titulo: 'Como registro dados da artroplastia primária de quadril?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'dadosClinicos/dadosQuadrilPrimaria.md',
            },
            {
                path: 'dadosArtoplastiaQuadrilRevisao',
                titulo: 'Como registro dados da revisão de artroplastia de quadril?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'dadosClinicos/dadosQuadrilRevisao.md',
            },
        ],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
    },

    {
        path: 'dadosProdutoFinanceiro',
        titulo: 'Registrando dados do produto e financeiros',
        perfil: todosUsuarios,
        subTopicos: [
            {
                path: 'dadosProduto',
                titulo: 'Como preencho os dados de produto?',
                subTopicos: [],
                possuiBotao: true,
                btnPathPadrao: '/tecnico',
                arquivoPath: 'dadosProdutoFinanceiro/dadosProdutoTecnico.md',
                botaoManual: {
                    tecnico: 'dadosProdutoFinanceiro/dadosProdutoTecnico.md',
                    medico: 'dadosProdutoFinanceiro/dadosProdutoMedico.md',
                    medicoResidente: 'dadosProdutoFinanceiro/dadosProdutoMedicoResidente.md',
                    financeiro: 'dadosProdutoFinanceiro/dadosProdutoFinanceiro.md',
                },
            },
            {
                path: 'dadosFinanceiros',
                titulo: 'Como preencho os dados financeiros?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'dadosProdutoFinanceiro/dadosFinanceiros.md',
            },
            {
                path: 'semRegistroAnvisa',
                titulo: 'Não encontro/possui registro ANVISA na etiqueta',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'dadosProdutoFinanceiro/semRegistroAnvisa.md',
            },
        ],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
    },

    {
        path: 'vinculo',
        titulo: 'Gerenciando vínculos',
        perfil: todosUsuarios,
        subTopicos: [
            {
                path: 'aceitarRejeitarVinculos',
                titulo: 'Como aceitar/recusar vínculos?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'vinculo/parecerVinculo.md',
            },
            {
                path: 'desativarVinculos',
                titulo: 'Como desativo vínculos?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'vinculo/desativacaoVinculo.md',
            },
            {
                path: 'visualizarVinculos',
                titulo: 'Como visualizo vínculos recusados?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'vinculo/vinculosRecusados.md',
            },
        ],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
    },

    {
        path: 'buscaProcedimentos',
        titulo: 'Buscar procedimentos',
        perfil: [PerfilEnum.ANVISA],
        subTopicos: [
            {
                path: 'porHospital',
                titulo: 'Como visualizo os procedimentos registrados em um hospital?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'buscaProcedimentos/buscaProcedimentoHospital.md',
            },
            {
                path: 'porProfissional',
                titulo: 'Como visualizo os procedimentos registrados por um profissional?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'buscaProcedimentos/buscaProcedimentoProfissional.md',
            },
            {
                path: 'porPaciente',
                titulo: 'Como visualizo os procedimentos que um paciente foi submetido?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'buscaProcedimentos/buscaProcedimentoPaciente.md',
            },
        ],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
    },

    {
        path: 'alarmes',
        titulo: 'Alarmes',
        perfil: [PerfilEnum.ANVISA],
        subTopicos: [
            {
                path: 'etiquetaSemRegistro',
                titulo: 'Como ativo o alarme para etiquetas sem registro da ANVISA?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'alarmes/definirAlarmeEtiquetaSemRegistro.md',
            },
            {
                path: 'motivoRevisao',
                titulo: 'Como ativo o alarme para motivos de revisão?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'alarmes/definirAlarmeMotivoRevisao.md',
            },
            {
                path: 'aumentoDeRegistros',
                titulo: 'Como ativo o alarme para aumento de registro de procedimentos?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'alarmes/definirAlarmeAumentoDeRegistros.md',
            },
            {
                path: 'desativarAlarme',
                titulo: 'Como desativo um alarme ativo?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'alarmes/desativarAlarme.md',
            },
            {
                path: 'inscricaoEmAlarme',
                titulo: 'Como me inscrevo em um alarme para ser notificado?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'alarmes/inscricaoEmAlarme.md',
            },
            {
                path: 'desinscricao',
                titulo: 'Como faço para deixar de ser notificado quando ocorrer o evento de um alarme?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'alarmes/desinscricao.md',
            },
            {
                path: 'visualizarListaDeAlarmes',
                titulo: 'Como visualizo um alarme que recebi?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'alarmes/visualizarListaDeAlarmes.md',
            },
        ],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
    },

    {
        path: 'relatoriosGerenciaisAnvisa',
        titulo: 'Relatórios Gerenciais - Anvisa',
        perfil: [PerfilEnum.ANVISA],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
        subTopicos: [
            {
                path: 'usoDeProdutos',
                titulo: 'Como sei quais pacientes receberam um determinado produto implantável?',
                possuiBotao: true,
                btnPathPadrao: '/registro',
                arquivoPath: 'dashboard/usoDeProdutoRegistro.md',
                botaoManual: {
                    registro: 'dashboard/usoDeProdutoRegistro.md',
                    cnpj: 'dashboard/usoDeProdutoCnpj.md',
                    semEtiqueta: 'dashboard/usoDeProdutoSemEtiqueta.md',
                },
            },
            {
                path: 'informacoesProduto',
                titulo: 'Como sei se um determinado produto está demandando muitas revisões?',
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'dashboard/informacoesDeProduto.md',
            },
            {
                path: 'informacoesFinanceiras',
                titulo: 'Como visualizo as informações financeiras de um determinado produto?',
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'dashboard/informacoesFinanceiras.md',
            },
            {
                path: 'filtrarPeriodos',
                titulo: 'Como filtro os dados de um relatório por um período de tempo?',
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'dashboard/filtrarPeriodos.md',
            },
        ],
    },

    {
        path: 'cadastroAntibioticoStents',
        titulo: 'Cadastrando antibióticos e stents',
        perfil: [PerfilEnum.ANVISA],
        subTopicos: [
            {
                path: 'cadastroAntibiotico',
                titulo: 'Como realizo o cadastro de novos antibióticos?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'cadastroAntibioticoStents/cadastroAntibiotico.md',
            },
            {
                path: 'cadastroStent',
                titulo: 'Como realizo o cadastro de novos stents?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'cadastroAntibioticoStents/cadastroStent.md',
            },
        ],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
    },

    {
        path: 'fluxos',
        titulo: 'Processos de uso do sistema',
        perfil: todosUsuarios,
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
        subTopicos: [
            {
                path: 'fluxoCadastro',
                titulo: 'Como funciona o processo de cadastro no RNI?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'fluxos/fluxoCadastro.md',
            },
            {
                path: 'fluxoPreenchimentoRegistros',
                titulo: 'Como funciona o processo de preenchimento dos registros no RNI?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'fluxos/fluxoPreenchimentoRegistros.md',
            },
        ],
    },

    {
        path: 'integracao',
        titulo: 'Integração de Dados',
        perfil: [PerfilEnum.GESTOR_INTEGRACAO],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
        subTopicos: [
            {
                path: 'cadastrarSistema',
                titulo: 'Como cadastrar sistemas que realizarão a integração de dados no RNI?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'integracao/cadastrarSistema.md',
            },
            {
                path: 'gerarChave',
                titulo: 'Como gerar uma chave para realizar a integração de dados?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'integracao/gerarChave.md',
            },
            {
                path: 'visualizarRegistros',
                titulo: 'Como visualizar os registros enviados na integração de dados?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'integracao/visualizarRegistros.md',
            },
        ],
    },

    {
        path: 'consumo',
        titulo: 'Consumo de Dados',
        perfil: [PerfilEnum.ANVISA],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
        subTopicos: [
            {
                path: 'gerarChaveConsumo',
                titulo: 'Como gerar uma chave para as entidades que consumirão dados do RNI?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'consumo/gerarChaveConsumo.md',
            },
            {
                path: 'cadastrarEntidade',
                titulo: 'Como cadastrar entidades que realizarão o consumo de dados do RNI?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'consumo/cadastrarEntidade.md',
            },
        ],
    },

    {
        path: 'relatoriosGerenciais',
        titulo: 'Relatórios Gerenciais',
        perfil: [PerfilEnum.GESTOR_SERVICO_SAUDE],
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
        subTopicos: [
            {
                path: 'usoDeProdutos',
                titulo: 'Como sei quais pacientes receberam um determinado produto implantável?',
                possuiBotao: true,
                btnPathPadrao: '/registro',
                arquivoPath: 'dashboard/usoDeProdutoRegistro.md',
                botaoManual: {
                    registro: 'dashboard/usoDeProdutoRegistro.md',
                    cnpj: 'dashboard/usoDeProdutoCnpj.md',
                    semEtiqueta: 'dashboard/usoDeProdutoSemEtiqueta.md',
                },
            },
        ],
    },

    {
        path: 'relacao',
        titulo: 'Atualizando relações',
        perfil: todosUsuarios,
        possuiBotao: false,
        btnPathPadrao: '',
        arquivoPath: '',
        subTopicos: [
            {
                path: 'atualizarRelacao',
                titulo: 'Como atualizar relações com empresas de produtos médicos?',
                subTopicos: [],
                possuiBotao: false,
                btnPathPadrao: '',
                arquivoPath: 'relacao/atualizarRelacao.md',
            },
        ],
    },
]
