/* tslint:disable */
import React from 'react'

const SvgUpload = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 54 54' {...props}>
        <path d='M31.216 47.163l-.006-2.108c8.658-1.502 15.539-8.167 17.265-16.654l-10.05-9.932a2.579 2.579 0 00-3.612 0L23.902 29.253l3.167 3.085a3.8 3.8 0 00-1.383.78l-.416.368-6.135-6.003c-.966-.954-2.648-.954-3.613 0l-6.853 6.773a21.445 21.445 0 0016.553 10.998c0 .677-.003 1.391-.006 2.09A23.55 23.55 0 016.733 35.081c-.08-.045-.164-.085-.233-.152a1.013 1.013 0 01-.282-.866A22.863 22.863 0 014 24.227C4 11.419 14.542 1 27.5 1S51 11.42 51 24.227c0 11.557-8.585 21.17-19.784 22.936zM27.5 3.08c-11.798 0-21.396 9.487-21.396 21.147 0 2.843.574 5.555 1.608 8.033l6.322-6.248a4.702 4.702 0 016.589 0l1.791 1.77L33.325 17a4.702 4.702 0 016.589 0l8.915 8.811c.04-.523.067-1.05.067-1.583 0-11.66-9.598-21.147-21.396-21.147zM16.978 22.493c-2.515 0-4.56-2.021-4.56-4.506 0-2.485 2.045-4.507 4.56-4.507 2.514 0 4.56 2.022 4.56 4.507s-2.046 4.506-4.56 4.506zm0-6.933c-1.354 0-2.456 1.089-2.456 2.427s1.102 2.426 2.456 2.426c1.353 0 2.455-1.088 2.455-2.426s-1.102-2.427-2.455-2.427zm16.525 23.026c.46.406.499 1.104.089 1.559-.22.244-.526.368-.833.368a1.12 1.12 0 01-.743-.28l-2.684-2.372V51.44c0 .61-.5 1.104-1.116 1.104a1.11 1.11 0 01-1.115-1.104V37.861l-2.684 2.372a1.124 1.124 0 01-1.576-.088 1.096 1.096 0 01.09-1.559L26.988 35a1.856 1.856 0 012.455 0l4.059 3.586z' />
    </svg>
)

export default SvgUpload
