import { DateInterval, DistribuicaoProcedimentoFiltro } from 'backend'
import { Column, Columns, PeriodoFilter, ScrollToTop } from 'libs/components'
import UrlParams from 'libs/router/UrlParams'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'redux/modules'
import { change } from 'redux/modules/report/filtro'
import { loadAll, clearAllDistribuicoes } from 'redux/modules/report/procedimento'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { ReportDashboardView } from 'view/componentes/reports/cards/ReportDashboardView'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import { baseFilter } from 'view/home/anvisa/reports/artroplastia/DistArtroplastiaContainer'
import { HomeGestorServicoSaudeHeader } from '../HomeGestorServicoSaudeHeader'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import BackButton from 'libs/components/presentational/elements/BackButton'

export const GestorServicoSaudeDistArtroplastiaView = () => {
    const dispatch = useDispatch()
    const filtro = useSelector<State, DistribuicaoProcedimentoFiltro>(state => state.report.filtro.filtro)

    React.useEffect(() => {
        dispatch(loadAll(undefined, true))
        return () => dispatch(clearAllDistribuicoes())
    }, [dispatch, filtro])

    const { group, tipoProcedimento, ...publishFilter } = filtro
    const refresh = (filtro: DistribuicaoProcedimentoFiltro, filtroChanged: boolean) => {
        if (!filtroChanged) {
            changeFiltro(filtro)
        }
    }

    const changeFiltro = (novoFiltro: DistribuicaoProcedimentoFiltro) => {
        dispatch(
            change({
                ...baseFilter,
                ...novoFiltro,
            })
        )
    }

    return (
        <>
            <ScrollToTop onMount />
            <UrlParams params={publishFilter} onChange={refresh} />
            <ReportClearFilter />

            <HomeGestorServicoSaudeHeader />

            <ReportHeader title='Distribuição de artroplastias'>
                <div>
                    <BackButton />
                </div>

                <HorizontalTabMenu>
                    <HorizontalTabMenuItem
                        title='Todos'
                        onClick={() =>
                            changeFiltro({
                                ...filtro,
                                tipoCirurgia: undefined,
                            })
                        }
                        active={!filtro.tipoCirurgia}
                    />
                    <HorizontalTabMenuItem
                        title='Joelho'
                        onClick={() =>
                            changeFiltro({
                                ...filtro,
                                tipoCirurgia: 'JOELHO',
                            })
                        }
                        active={filtro.tipoCirurgia === 'JOELHO'}
                    />
                    <HorizontalTabMenuItem
                        title='Quadril'
                        onClick={() =>
                            changeFiltro({
                                ...filtro,
                                tipoCirurgia: 'QUADRIL',
                            })
                        }
                        active={filtro.tipoCirurgia === 'QUADRIL'}
                    />
                </HorizontalTabMenu>
            </ReportHeader>

            <div className='report-content'>
                <Columns className='report-tabs align-center'>
                    <Column size='6'>
                        <div className='report-tabs-links is-black'>Dashboard</div>
                    </Column>
                    <Column size='6' textRight>
                        <PeriodoFilter
                            value={filtro.interval}
                            onChange={(interval: DateInterval) => {
                                changeFiltro({
                                    ...filtro,
                                    interval: interval,
                                })
                            }}
                        />
                    </Column>
                </Columns>

                <ReportDashboardView dadosUsoTitle={'Número de artroplastias'} />
            </div>
        </>
    )
}
