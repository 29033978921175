import React from 'react'
import axios from 'axios'
import * as C from 'libs/components'
import iconLocal from 'images/home/cidadao/ic-local.svg'
import { FormSection, formValueSelector } from 'redux-form/immutable'
import { change } from 'redux-form'
import { connect } from 'react-redux'

class EnderecoFormView extends React.Component {
    constructor() {
        super()
        this.state = {
            cepNotFound: false,
            loading: false,
        }
        this.onChangeNumber = this.onChangeNumber.bind(this)
        this.handleOnChangeUF = this.handleOnChangeUF.bind(this)
        this.handleCepKeyPress = this.handleCepKeyPress.bind(this)
        this.handleBuscarCep = this.handleBuscarCep.bind(this)
    }

    onChangeNumber(e) {
        this.props.onChangeNumber(e)
    }

    handleBuscarCep(token) {
        this.setState({ cepNotFound: false, loading: true })
        let promise

        if (this.props.public) {
            promise = axios.get('/api/public/enderecos/logradouros/', {
                params: { cep: this.props.cep, recaptchaToken: token },
            })
        } else {
            promise = axios.get('/api/enderecos/logradouros/' + this.props.cep)
        }

        if (promise && promise.then) {
            this.changeEndereco(promise)
            if (this.props.public) {
                this.enderecoRecaptcha.reset()
            }
        }
    }

    changeEndereco(promise) {
        promise
            .then(result => {
                this.setState({ loading: false })
                this.props.handleEnderecoResolved(result)
            })
            .catch(() => {
                this.setState({ cepNotFound: true, loading: false })
                this.props.handleEnderecoRejected()
            })
    }

    handleOnChangeUF() {
        this.props.onChangeUf()
    }

    handleCepKeyPress(e) {
        if (e.key === 'Enter' && this.props.cep.length === 8) {
            this.props.public ? Promise.resolve(this.enderecoRecaptcha.execute()) : this.handleBuscarCep()
        }
    }

    render() {
        const buscarCep = () =>
            this.props.public ? Promise.resolve(this.enderecoRecaptcha.execute()) : this.handleBuscarCep()
        return (
            <FormSection name={this.props.name}>
                <C.Columns>
                    <C.Column verticalFlow size='2'>
                        <C.Image src={iconLocal} alignedCenter size='96x96' />
                    </C.Column>
                    <C.Column>
                        <C.Columns>
                            <C.Column size='2'>
                                <C.MaskedField
                                    required
                                    label='CEP'
                                    onKeyPress={this.handleCepKeyPress}
                                    name='cep'
                                    placeholder='CEP'
                                    mask='99999-999'
                                />
                            </C.Column>
                            <C.Column size='2' pulledBottom>
                                <C.Button
                                    disabled={!(this.props.cep && this.props.cep.length === 8)}
                                    loading={this.state.loading}
                                    onClick={buscarCep}
                                    type='primary'
                                    size='normal'
                                >
                                    Consultar CEP
                                </C.Button>
                            </C.Column>
                            <C.Column pulledBottom>
                                {this.state.cepNotFound && (
                                    <p className='cepNotFound'>Não foi possível localizar o CEP na base.</p>
                                )}
                            </C.Column>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='2'>
                                <C.UfField
                                    required
                                    label='UF'
                                    onChange={this.handleOnChangeUF}
                                    name='uf'
                                    placeholder='UF'
                                />
                            </C.Column>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='6'>
                                <C.CidadeField
                                    isDisabled={!this.props.uf}
                                    ufId={
                                        this.props.uf && this.props.uf.get
                                            ? this.props.uf.get('id')
                                            : this.props.uf && this.props.uf.id
                                    }
                                    required
                                    label='Cidade'
                                    name='cidade'
                                    placeholder='Cidade'
                                />
                            </C.Column>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='6'>
                                <C.TextField
                                    required
                                    maxLength={this.props.paciente ? '72' : '60'}
                                    label='Bairro'
                                    name='bairro'
                                    placeholder='Bairro'
                                />
                            </C.Column>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='6'>
                                <C.TextField
                                    required
                                    maxLength={this.props.paciente ? '250' : '150'}
                                    label='Logradouro'
                                    name='logradouroNome'
                                    placeholder='Rua, Avenida, Estrada, Beco...'
                                />
                            </C.Column>
                            <C.Column size='2'>
                                <C.NumberField
                                    maxLength={this.props.paciente ? '7' : '5'}
                                    required={!this.props.semNumero}
                                    disabled={this.props.semNumero}
                                    label='Número'
                                    name='numero'
                                    placeholder={this.props.semNumero ? 'S/N' : 'Nº'}
                                />
                            </C.Column>
                            <C.Column size='2' pulledBottom>
                                <C.CheckboxField label='Sem número' name='semNumero' onChange={this.onChangeNumber} />
                            </C.Column>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='6'>
                                <C.TextField
                                    maxLength={this.props.paciente ? '50' : '40'}
                                    label='Complemento'
                                    name='complemento'
                                    placeholder='Complemento'
                                />
                            </C.Column>
                        </C.Columns>
                    </C.Column>
                </C.Columns>
                {this.props.public && (
                    <C.ReCaptcha
                        size='invisible'
                        inputRef={elem => (this.enderecoRecaptcha = elem)}
                        onChange={this.handleBuscarCep}
                    />
                )}
            </FormSection>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let selector = formValueSelector(ownProps.form)
    return {
        semNumero: selector(state, ownProps.name + '.semNumero'),
        cep: selector(state, ownProps.name + '.cep'),
        uf: selector(state, ownProps.name + '.uf'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onChangeNumber: event =>
            dispatch(change(ownProps.form, ownProps.name + '.numero', event.target.checked ? null : '')),
        onChangeUf: () => dispatch(change(ownProps.form, ownProps.name + '.cidade', null)),
        handleEnderecoResolved: result => {
            dispatch(change(ownProps.form, ownProps.name + '.uf', result.data.uf))
            dispatch(change(ownProps.form, ownProps.name + '.cidade', result.data.cidade))
            dispatch(change(ownProps.form, ownProps.name + '.bairro', result.data.bairro))
            dispatch(change(ownProps.form, ownProps.name + '.logradouroNome', result.data.logradouroNome))
            dispatch(change(ownProps.form, ownProps.name + '.numero', null, false))
            dispatch(change(ownProps.form, ownProps.name + '.semNumero', false))
            dispatch(change(ownProps.form, ownProps.name + '.complemento', null))
        },
        handleEnderecoRejected: () => {
            dispatch(change(ownProps.form, ownProps.name + '.uf', null))
            dispatch(change(ownProps.form, ownProps.name + '.cidade', null))
            dispatch(change(ownProps.form, ownProps.name + '.bairro', null))
            dispatch(change(ownProps.form, ownProps.name + '.logradouroNome', null))
            dispatch(change(ownProps.form, ownProps.name + '.numero', null, false))
            dispatch(change(ownProps.form, ownProps.name + '.semNumero', false))
            dispatch(change(ownProps.form, ownProps.name + '.complemento', null))
        },
    }
}

export const EnderecoForm = connect(mapStateToProps, mapDispatchToProps)(EnderecoFormView)
