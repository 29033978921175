import { combineReducers } from 'redux'
import buscaLocalidade, { BuscaLocalidadeState } from './buscaLocalidade'
import buscaPaciente, { BuscaPacienteReportState } from './buscaPaciente'
import filtro, { FiltroState } from './filtro'
import financeiro, { DadosFinanceirosReportState } from './financeiro'
import motivos, { MotivosProcedimentosReportState } from './motivos'
import multiplos, { MultiplosProcedimentosReportState } from './multiplos'
import paciente, { PacienteReportState } from './paciente'
import procedimento, { ProcedimentoReportState } from './procedimento'
import rastreabilidade, { RastreabilidadeReportState } from './rastreabilidade'
import revisao, { TipoRevisaoReportState } from './revisao'
import produto, { ProdutoReportState } from './produto'
import usoSistema, { UsoSistemaReportState } from './usoSistema'

export interface ReportState {
    buscaLocalidade: BuscaLocalidadeState
    buscaPaciente: BuscaPacienteReportState
    filtro: FiltroState
    financeiro: DadosFinanceirosReportState
    motivos: MotivosProcedimentosReportState
    multiplos: MultiplosProcedimentosReportState
    paciente: PacienteReportState
    procedimento: ProcedimentoReportState
    rastreabilidade: RastreabilidadeReportState
    revisao: TipoRevisaoReportState
    produto: ProdutoReportState
    usoSistema: UsoSistemaReportState
}

export default combineReducers({
    buscaLocalidade,
    buscaPaciente,
    filtro,
    financeiro,
    motivos,
    multiplos,
    paciente,
    procedimento,
    rastreabilidade,
    revisao,
    produto,
    usoSistema,
})
