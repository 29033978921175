/* tslint:disable */
import React from 'react'

const SvgEndereco = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
        <path d='M12.5 7.298c0 3.123-2.862 6.202-4.5 6.202s-4.5-3.079-4.5-6.202C3.5 4.658 5.506 2.5 8 2.5s4.5 2.158 4.5 4.798zm-1.174 0c0-2.007-1.499-3.62-3.326-3.62s-3.326 1.613-3.326 3.62c0 2.49 2.355 5.023 3.326 5.023.97 0 3.326-2.533 3.326-5.023zM8 8.59a1.569 1.569 0 01-1.565-1.571c0-.868.7-1.572 1.565-1.572.864 0 1.565.704 1.565 1.572 0 .867-.7 1.571-1.565 1.571zm0-1.178a.392.392 0 000-.786.392.392 0 000 .786z' />
    </svg>
)

export default SvgEndereco
