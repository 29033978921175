/* tslint:disable */
import React from 'react'

const SvgRecusar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M13.682 12l4.97-4.97a1.19 1.19 0 10-1.682-1.682L12 10.318l-4.97-4.97A1.19 1.19 0 005.348 7.03l4.97 4.97-4.97 4.97a1.19 1.19 0 001.682 1.682l4.97-4.97 4.97 4.97a1.19 1.19 0 101.682-1.682L13.682 12z' />
    </svg>
)

export default SvgRecusar
