import * as React from 'react'
import { TextField } from 'libs/components'
import { DoubleFieldGroup } from './DoubleFieldGroup'

export const IdentificacaoLoteSerieField = () => (
    <DoubleFieldGroup name='loteNumeroSerie' label='Identificação de lote/série do produto' required>
        <TextField maxLength={30} label='Lote' name='lote' placeholder='Lote do produto' />
        <TextField maxLength={30} label='Nº de série' name='numeroSerie' placeholder='Nº de série do produto' />
    </DoubleFieldGroup>
)
