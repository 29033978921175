import React from 'react'
import * as C from 'libs/components'

const optionsQuadril = [
    { id: 'CONVENCIONAL', nome: 'Convencional' },
    { id: 'HEMIARTROPLASTIA_BIPOLAR', nome: 'Hemiartroplastia bipolar' },
    { id: 'HEMIARTROPLASTIA_MONOPOLAR', nome: 'Hemiartroplastia monopolar' },
    { id: 'NAO_CONVENCIONAL', nome: 'Não convencional' },
    { id: 'RECAPEAMENTO', nome: 'Recapeamento' },
]

const optionsJoelho = [
    { id: 'ARTROPLASTIA_TOTAL_COM_SUBSTITUICAO_PATELAR', nome: 'Artroplastia total com substituição patelar' },
    { id: 'ARTROPLASTIA_TOTAL_SEM_SUBSTITUICAO_PATELAR', nome: 'Artroplastia total sem substituição patelar' },
    { id: 'NAO_CONVENCIONAL', nome: 'Não convencional' },
    { id: 'PATELO_FEMORAL', nome: 'Patelo femoral' },
    { id: 'UNICOMPARTIMENTAL', nome: 'Unicompartimental' },
]

export class TipoArtroplastiaField extends React.Component {
    render() {
        return (
            <C.FixedSelectField
                {...this.props}
                options={this.props.tipo === 'joelho' ? optionsJoelho : optionsQuadril}
            />
        )
    }
}
