import * as React from 'react'
import * as C from 'libs/components'
import UrlParams from 'libs/router/UrlParams'
import { Link, NavLink } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import {
    DistribuicaoProcedimentoFiltro,
    TipoCirurgia,
    DateInterval,
    MotivosProcedimentosRow,
    MotivosQuadroPadraoDto,
    MotivoProcedimentoEnum,
    FiltroCirurgia,
    MotivosRevisoesRow,
} from 'backend'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { ScrollToTop } from 'libs/components'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { ReportMotivosProcedimento } from 'view/componentes/reports/components/ReportMotivosProcedimento'
import BackButton from 'libs/components/presentational/elements/BackButton'
import { stringify } from 'redux/modules/router'

export interface Props extends RouteComponentProps<any> {
    filtro: DistribuicaoProcedimentoFiltro
    motivos: PromiseRecord<MotivosProcedimentosRow[]>
    motivosRevisoes: PromiseRecord<MotivosRevisoesRow[]>
    distQuadroPadrao: PromiseRecord<MotivosQuadroPadraoDto[]>
    changeFilter(filtro: DistribuicaoProcedimentoFiltro): void
    loadMotivos(): void
    loadRevisoes(): void
    loadQuadroPadrao(): void
    goToDetail(mot: MotivoProcedimentoEnum): void
}

export class MotivosProcedimentoView extends React.Component<Props> {
    handlePeriodoChange = (interval: DateInterval) => {
        this.props.changeFilter({
            ...this.props.filtro,
            interval: interval,
        })
    }

    handleTabItemClick = (tipoCirurgia: TipoCirurgia, filtroCirurgia: FiltroCirurgia) => {
        this.props.changeFilter({
            ...this.props.filtro,
            tipoCirurgia: tipoCirurgia,
            filtroCirurgia: filtroCirurgia,
        })
    }

    handleSubTabItemClick = (filtroCirurgia: FiltroCirurgia) => {
        this.props.changeFilter({
            ...this.props.filtro,
            filtroCirurgia: filtroCirurgia,
        })
    }

    refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        if (filter.filtroCirurgia === 'REVISOES') {
            this.props.loadRevisoes()
        } else if(filter.filtroCirurgia === 'QUADRO_CLINICO_X_PADRAO_OBSTRUTIVO'){
			 this.props.loadQuadroPadrao()
		} else {
            this.props.loadMotivos()
        }
    }

    getFilter = () => {
        const currentFilter = this.props.filtro || {}
        return {
            ...currentFilter,
            tipoCirurgia: currentFilter.tipoCirurgia || 'JOELHO',
            filtroCirurgia: currentFilter.filtroCirurgia || 'PRIMARIA',
        } as DistribuicaoProcedimentoFiltro
    }

    renderDownloadIcon = () => {
        const currentFilter = this.props.filtro || {}
        return (
            <Link
                to={{
                    pathname: '/api/report/motivosRevisoes/csv',
                    search: stringify(currentFilter),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <C.Text color='grey-darker'>
                    <C.Icon icon='exportar' />
                </C.Text>
            </Link>
        )
    }

    render() {
        const filtro = this.getFilter()

        return (
            <div>
                <ScrollToTop onMount />
                <UrlParams params={this.getFilter()} onChange={this.refresh} />
                <ReportClearFilter />

                <LocalHeaderContainer />
                <ReportHeader title='Motivos de procedimentos'>
                    <div>
                        <BackButton />
                    </div>

                    <HorizontalTabMenu>
                        <HorizontalTabMenuItem
                            title='Joelho'
                            onClick={() => this.handleTabItemClick('JOELHO', 'PRIMARIA')}
                            active={filtro.tipoCirurgia === 'JOELHO'}
                        />
                        <HorizontalTabMenuItem
                            title='Quadril'
                            onClick={() => this.handleTabItemClick('QUADRIL', 'PRIMARIA')}
                            active={filtro.tipoCirurgia === 'QUADRIL'}
                        />
                        <HorizontalTabMenuItem
                            title='Cardiologia'
                            onClick={() => this.handleTabItemClick('CORACAO', 'QUADRO_CLINICO')}
                            active={filtro.tipoCirurgia === 'CORACAO'}
                        />
                    </HorizontalTabMenu>
                </ReportHeader>

                <div className='report-content'>
                    <HeaderContainer>
                        <HorizontalTabMenu>
                            <HorizontalTabMenuItem
                                hidden={filtro.tipoCirurgia === 'CORACAO'}
                                title='Primária'
                                onClick={() => this.handleSubTabItemClick('PRIMARIA')}
                                active={filtro.filtroCirurgia === 'PRIMARIA'}
                            />
                            <HorizontalTabMenuItem
                                hidden={filtro.tipoCirurgia === 'CORACAO'}
                                title='Revisão'
                                onClick={() => this.handleSubTabItemClick('REVISAO')}
                                active={filtro.filtroCirurgia === 'REVISAO'}
                            />
                            <HorizontalTabMenuItem
                                hidden={filtro.tipoCirurgia === 'CORACAO'}
                                title='Revisões'
                                onClick={() => this.handleSubTabItemClick('REVISOES')}
                                active={filtro.filtroCirurgia === 'REVISOES'}
                            />

                            <HorizontalTabMenuItem
                                hidden={filtro.tipoCirurgia !== 'CORACAO'}
                                title='Quadro clínico'
                                onClick={() => this.handleSubTabItemClick('QUADRO_CLINICO')}
                                active={filtro.filtroCirurgia === 'QUADRO_CLINICO'}
                            />

                            <HorizontalTabMenuItem
                                hidden={filtro.tipoCirurgia !== 'CORACAO'}
                                title='Padrão obstrutivo'
                                onClick={() => this.handleSubTabItemClick('PADRAO_OBSTRUTIVO')}
                                active={filtro.filtroCirurgia === 'PADRAO_OBSTRUTIVO'}
                            />

                            <HorizontalTabMenuItem
                                hidden={filtro.tipoCirurgia !== 'CORACAO'}
                                title='Quadro clínico X Padrão obstrutivo'
                                onClick={() => this.handleSubTabItemClick('QUADRO_CLINICO_X_PADRAO_OBSTRUTIVO')}
                                active={filtro.filtroCirurgia === 'QUADRO_CLINICO_X_PADRAO_OBSTRUTIVO'}
                            />
                        </HorizontalTabMenu>
                    </HeaderContainer>

                    <C.Columns className='report-tabs align-center'>
                        <C.Column size='6'>
                            <div className='report-tabs-links'>
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}`,
                                        search: this.props.location.search,
                                    }}
                                    activeClassName='is-active'
                                >
                                    Lista de motivos
                                </NavLink>
                            </div>
                        </C.Column>
                        <C.Column size='6' textRight>
                            <C.PeriodoFilter value={filtro.interval} onChange={this.handlePeriodoChange} />
                        </C.Column>
                    </C.Columns>

                    <ReportMotivosProcedimento
                        filtro={filtro}
                        motivos={this.props.motivos}
                        motivosRevisoes={this.props.motivosRevisoes}
                        distQuadroPadrao={this.props.distQuadroPadrao}
                        goToDetail={this.props.goToDetail}
                        report={this.renderDownloadIcon}
                    />
                </div>
            </div>
        )
    }
}
