import { FixedSelectOption } from 'libs/components'
import { momentFromYYYYM } from 'libs/util/DateUtil'
import { length, required } from 'libs/validation'
import { notBlank } from 'libs/validation/rules/notBlank'
import moment, { Moment } from 'moment'
import { isImmutable } from 'immutable'

const periodoVigenciaFormValidator = form => {
    const errors: any = {}

    const mesInicioValue: FixedSelectOption = isImmutable(form.mesInicio.get())
        ? form.mesInicio.get().toJS()
        : form.mesInicio.get()
    const anoInicioValue: string = form.anoInicio.get() ? String(form.anoInicio.get()) : undefined
    const inicio: Moment = momentFromYYYYM(anoInicioValue, mesInicioValue?.id)

    if (!form.vigenciaIndeterminada.get()) {
        const anoFimValue: string = form.anoFim.get() ? String(form.anoFim.get()) : undefined
        const mesFimValue: FixedSelectOption = isImmutable(form.mesFim.get())
            ? form.mesFim.get().toJS()
            : form.mesFim.get()

        let errorMesFim: string = required(mesFimValue?.id)
        let errorAnoFim: string = length(4)(anoFimValue) || notBlank(anoFimValue) || required(anoFimValue)

        if (!errorMesFim && !errorAnoFim) {
            const fim: Moment = momentFromYYYYM(anoFimValue, mesFimValue?.id)
            if (inicio.isAfter(fim)) {
                errorMesFim = errorAnoFim = 'A data final do período de vigência não pode ser anterior à data de início'
            }
        }

        errors.periodoVigencia = errorMesFim || errorAnoFim
        errors.mesFim = errorMesFim
        errors.anoFim = errorAnoFim
    }

    errors.mesInicio = required(mesInicioValue?.id)
    errors.anoInicio = required(anoInicioValue) || length(4)(anoInicioValue) || notBlank(anoInicioValue)
    errors.periodoVigencia = errors.mesInicio || errors.anoInicio || errors.periodoVigencia

    if (!errors.periodoVigencia && inicio.isAfter(moment())) {
        errors.periodoVigencia = errors.mesInicio = errors.anoInicio =
            'A data inicial do período de vigência não pode ser superior à data atual'
    }

    return errors
}

export default periodoVigenciaFormValidator
