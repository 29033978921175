import axios, { AxiosPromise } from 'axios'
import {
    HistoricoProcedimentoDetailRow,
    PrimariaJoelhoForm,
    PrimariaQuadrilForm,
    ProcedimentoAnteriorDto,
    ProcedimentoRow,
    RascunhoProcedimentoForm,
    RevisaoJoelhoForm,
    RevisaoQuadrilForm,
    StentCoronariaForm,
    TipoProcedimento,
    RascunhoProcedimentoEdicaoRow,
    TodosProfissionaisProcedimentoSelectDto,
    VinculoProfissionalRow,
} from 'backend'

const url = '/api/procedimentos'

class Procedimento {
    savePrimariaQuadril(primariaQuadrilForm: PrimariaQuadrilForm): AxiosPromise<PrimariaQuadrilForm> {
        return axios.post(url + '/primariaQuadril', primariaQuadrilForm)
    }

    updatePrimariaQuadril(primariaQuadrilForm: PrimariaQuadrilForm): AxiosPromise<PrimariaQuadrilForm> {
        return axios.put(url + '/primariaQuadril', primariaQuadrilForm)
    }

    saveRevisaoQuadril(revisaoQuadrilForm: RevisaoQuadrilForm): AxiosPromise<RevisaoQuadrilForm> {
        return axios.post(url + '/revisaoQuadril', revisaoQuadrilForm)
    }

    updateRevisaoQuadril(revisaoQuadrilForm: RevisaoQuadrilForm): AxiosPromise<RevisaoQuadrilForm> {
        return axios.put(url + '/revisaoQuadril', revisaoQuadrilForm)
    }

    savePrimariaJoelho(primariaJoelhoForm: PrimariaJoelhoForm): AxiosPromise<PrimariaJoelhoForm> {
        return axios.post(url + '/primariaJoelho', primariaJoelhoForm)
    }

    updatePrimariaJoelho(primariaJoelhoForm: PrimariaJoelhoForm): AxiosPromise<PrimariaJoelhoForm> {
        return axios.put(url + '/primariaJoelho', primariaJoelhoForm)
    }

    saveRevisaoJoelho(revisaoJoelhoForm: RevisaoJoelhoForm): AxiosPromise<RevisaoJoelhoForm> {
        return axios.post(url + '/revisaoJoelho', revisaoJoelhoForm)
    }

    updateRevisaoJoelho(revisaoJoelhoForm: RevisaoJoelhoForm): AxiosPromise<RevisaoJoelhoForm> {
        return axios.put(url + '/revisaoJoelho', revisaoJoelhoForm)
    }

    saveStentCoronaria(stentCoronariaForm: StentCoronariaForm): AxiosPromise<StentCoronariaForm> {
        return axios.post(url + '/stentCoronaria', stentCoronariaForm)
    }

    updateStentCoronaria(stentCoronariaForm: StentCoronariaForm): AxiosPromise<StentCoronariaForm> {
        return axios.put(url + '/stentCoronaria', stentCoronariaForm)
    }

    saveRascunho(rascunhoForm: RascunhoProcedimentoForm): AxiosPromise<RascunhoProcedimentoForm> {
        return axios.post(url + '/rascunho', rascunhoForm)
    }

    updateRascunho(rascunhoForm: RascunhoProcedimentoForm): AxiosPromise<RascunhoProcedimentoForm> {
        return axios.put(url + '/rascunho', rascunhoForm)
    }

    getAguardandoDadosClinicos(): AxiosPromise<ProcedimentoRow> {
        return axios.get(url + '/aguardandoDadosClinicosMed')
    }

    getAguardandoDadosProduto(): AxiosPromise<ProcedimentoRow> {
        return axios.get(url + '/aguardandoDadosProduto')
    }

    getAguardandoDadosFinanceiros(): AxiosPromise<ProcedimentoRow> {
        return axios.get(url + '/aguardandoDadosFinanceirosAdm')
    }

    getHistoricoUrl(): string {
        return url + '/historico'
    }

    getHistoricoDetail(registroId: number): AxiosPromise<HistoricoProcedimentoDetailRow[]> {
        return axios.get(url + '/historicoDetail/' + registroId)
    }

    getHistoricoVersaoDetail(registroId: number): AxiosPromise<HistoricoProcedimentoDetailRow[]> {
        return axios.get(url + '/historicoVersaoDetail/' + registroId)
    }

    validateStentCoronaria(stentCoronariaForm: StentCoronariaForm): AxiosPromise<void> {
        return axios.post(url + '/stentCoronariaValidate', stentCoronariaForm)
    }

    findProcedimentosAnteriores(idPaciente: number): AxiosPromise<ProcedimentoAnteriorDto[]> {
        return axios.get(url + '/findProcedimentosAnteriores/' + idPaciente)
    }

    deleteRascunhoById(id: number): AxiosPromise<void> {
        return axios.delete(url + '/deleteRascunho/' + id)
    }

    getEquipeMedicaFromRascunho(
        idRascunho: number
    ): AxiosPromise<TodosProfissionaisProcedimentoSelectDto<VinculoProfissionalRow>> {
        return axios.get(url + '/equipeMedicaRascunho/' + idRascunho)
    }

    getRascunho(idRascunho: number): AxiosPromise<RascunhoProcedimentoEdicaoRow> {
        return axios.get(url + '/aguardandoDadosClinicosById/' + idRascunho)
    }

    getEquipeMedicaEdicao(
        idProcedimento: number
    ): AxiosPromise<TodosProfissionaisProcedimentoSelectDto<VinculoProfissionalRow>> {
        return axios.get(url + '/equipeMedicaEdicao/' + idProcedimento)
    }
}

export default new Procedimento()

export const tiposProcedimentoByRegiao: { [key: string]: TipoProcedimento[] } = {
    joelho: ['PRIMARIA_JOELHO', 'REVISAO_JOELHO'],
    quadril: ['PRIMARIA_QUADRIL', 'REVISAO_QUADRIL'],
}

export const tiposProcedimentoByUrl: { [key: string]: TipoProcedimento | 'STENT_CORONARIA_COMPLEMENTAR' } = {
    primariaJoelho: 'PRIMARIA_JOELHO',
    primariaQuadril: 'PRIMARIA_QUADRIL',
    revisaoJoelho: 'REVISAO_JOELHO',
    revisaoQuadril: 'REVISAO_QUADRIL',
    stentCoronaria: 'STENT_CORONARIA',
    stentCoronariaComplementar: 'STENT_CORONARIA_COMPLEMENTAR',
}

export const tiposProcedimentoUrlByEnum: { [key: string]: string } = {
    PRIMARIA_JOELHO: 'primariaJoelho',
    PRIMARIA_QUADRIL: 'primariaQuadril',
    REVISAO_JOELHO: 'revisaoJoelho',
    REVISAO_QUADRIL: 'revisaoQuadril',
    STENT_CORONARIA: 'stentCoronaria',
    STENT_CORONARIA_COMPLEMENTAR: 'stentCoronariaComplementar',
}

export const tiposProcedimentoDescricaoByEnum: { [key: string]: string } = {
    PRIMARIA_JOELHO: 'Primária de joelho',
    PRIMARIA_QUADRIL: 'Primária de quadril',
    REVISAO_JOELHO: 'Revisão de joelho',
    REVISAO_QUADRIL: 'Revisão de quadril',
    STENT_CORONARIA: 'Stent de coronária',
    STENT_CORONARIA_COMPLEMENTAR: 'Stent de coronária',
}

export const dominanciaByLado: { [key: string]: string } = {
    ESQUERDO: 'Esquerda',
    DIREITO: 'Direita',
}
