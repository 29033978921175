import React, { ReactNode, useState, useEffect } from 'react'
import { ModalCardProps, ModalCardComponent } from './ModalCard'
import { Carousel } from '../carousel/Carousel'
import { Image } from '../elements/Image'
import CSSTransition from 'react-transition-group/CSSTransition'

export interface ModalCardAjudaProps extends Omit<ModalCardProps, 'children'> {
    headerItems: ReactNode[]
    bodyItems: ReactNode[]
}

export const ModalCardAjuda = (props: ModalCardAjudaProps) => {
    const [current, setCurrent] = useState({ index: 0, active: true })

    useEffect(() => {
        if (!current.active) {
            setCurrent({ ...current, active: true })
        }
    }, [current])

    const onIndexUpdated = currentIndex => {
        setCurrent({ index: currentIndex, active: false })
    }

    const CarouselComponent = (
        <Carousel className='flex-1' onIndexUpdated={onIndexUpdated}>
            {props.headerItems}
        </Carousel>
    )

    const SingleComponent = <div className='flex-1'>{props.headerItems}</div>

    return (
        <ModalCardComponent
            {...props}
            className='modal-card-ajuda'
            headerComponent={props.headerItems.length > 1 ? CarouselComponent : SingleComponent}
        >
            <CSSTransition classNames='carousel-item' timeout={750} in={current.active}>
                {props.bodyItems[current.index]}
            </CSSTransition>
        </ModalCardComponent>
    )
}

export const CarouselItem = props => {
    return (
        <div className='is-vertical-flow is-children-center'>
            <Image size='96x96' src={props.image} />
            <p className='is-large'>{props.text}</p>
        </div>
    )
}

export const BodyItem = props => {
    const childrenArray = React.Children.toArray(props.children)
    return (
        <div className='ajuda-card-body-item'>
            {childrenArray.map((child, index) => {
                return (
                    <div key={`body-item-${index}`} style={{ display: 'flex' }}>
                        <span className='index'>{index + 1}.</span>
                        <div style={{ display: 'inline-block' }}>{child}</div>
                    </div>
                )
            })}
        </div>
    )
}
