import axios, { AxiosPromise } from 'axios'
import { SolicitacaoAcessoForm, ValidacaoEmailDto } from 'backend'

class PublicSolicitacaoAcesso {
    solicitarAcesso(profissionalForm: SolicitacaoAcessoForm): AxiosPromise<SolicitacaoAcessoForm> {
        return axios.post('/api/public/solicitacaoAcesso', profissionalForm)
    }

    validarEmail(tokenId: string): AxiosPromise<ValidacaoEmailDto> {
        return axios.get('/api/public/solicitacaoAcesso/validarEmail/' + tokenId)
    }
}

export default new PublicSolicitacaoAcesso()
