import React from 'react'
import * as C from 'libs/components'

const padraoObstrusivoOptions = [
    { id: 'UM_OU_DOIS_VASOS_SEM_DA_PROXIMAL', nome: '1 ou 2 vasos; sem DA proximal' },
    { id: 'UM_VASO_COM_DA_PROXIMAL', nome: '1 vaso; com DA proximal' },
    { id: 'DOIS_VASOS_COM_DA_PROXIMAL', nome: '2 vasos; com DA proximal' },
    { id: 'TRES_VASOS_COM_DA_PROXIMAL', nome: '3 vasos; sem TCE' },
    { id: 'TCE_ISOLADO', nome: 'TCE isolado' },
    { id: 'TCE_UM_DOIS_VASOS', nome: 'TCE + 1 ou 2 vasos' },
    { id: 'TCE_TRES_VASOS', nome: 'TCE + 3 vasos' },
    { id: 'OCLUSAO_CRONICA_ISOLADA', nome: 'Oclusão crônica isolada' },
]

export class PadraoObstrutivoField extends React.Component {
    render() {
        return <C.FixedSelectField {...this.props} options={padraoObstrusivoOptions} />
    }
}
