import * as React from 'react'
import classnames from 'classnames'
import { Icon } from 'libs/components'
import { Icons } from './icons'

export interface IconBadgeProps {
    icon: Icons
    className?: string
}

export const IconBadge = ({ icon, className }: IconBadgeProps) => {
    return (
        <span className={classnames('icon-badge', className)}>
            <Icon icon={icon} size='auto' />
        </span>
    )
}
