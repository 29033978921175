/* tslint:disable */
import React from 'react'

const SvgObrigatorio = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
        <path d='M6.595 11.249l-1.458-1.13c.464-.521.946-1.02 1.449-1.496.2-.193.325-.316.376-.367a26.92 26.92 0 01-1.38-.319c-.548-.135-.908-.235-1.082-.3l.57-1.698c.843.34 1.596.717 2.259 1.13-.155-1.05-.232-1.906-.232-2.569h1.719c0 .47-.087 1.332-.26 2.588.128-.052.405-.177.83-.377.579-.264 1.113-.49 1.602-.676l.512 1.748c-.714.16-1.542.318-2.481.473l1.158 1.303c.232.264.415.48.55.647l-1.477.975-1.303-2.153a43.171 43.171 0 01-1.352 2.221z' />
    </svg>
)

export default SvgObrigatorio
