import * as React from 'react'
import * as C from 'libs/components'
import { Field } from 'redux-form/immutable'

const renderField = props => {
    let visible
    if (props.visibleValue) {
        visible = props.visibleValue === props.input.value
    } else {
        visible = props.input.value
    }
    return (
        (!visible && <span></span>) || (
            <C.Button
                className='btn-clear-radio'
                type='grey'
                size='small'
                disabled={props.disabled}
                onClick={() => props.input.onChange(null)}
            >
                LIMPAR
            </C.Button>
        )
    )
}

interface ClearRadioFieldButtonProps {
    visibleValue?: string
    name: string
}

export class ClearRadioFieldButton extends React.Component<ClearRadioFieldButtonProps> {
    render() {
        return <Field {...this.props} component={renderField} />
    }
}
