import { DescricaoPerfil, ImagemPerfil } from 'api/Perfis'
import { InstituicaoDto, Perfil, UserInstituicaoDTO } from 'backend'
import { Icon, Image, Label, UserInitials } from 'libs/components'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { State } from 'redux/modules'
import { AuthState } from 'redux/modules/auth'
import { MenuCmp } from '../componentes/HomeHeader'

export const HomeGestorServicoSaudeHeader = () => {
    const auth = useSelector<State, AuthState>(state => state.auth)

    const user: UserInstituicaoDTO = (auth.user as unknown) as UserInstituicaoDTO
    const username: string = user.nome
    const userPerfil: Perfil = user.perfil
    const userInstituicao: InstituicaoDto = user.instituicao

    const location = useLocation()
    const showBuscaProcedimento: boolean = location.pathname === '/home'

    return (
        <>
            {showBuscaProcedimento && (
                <div className='gestor-servico-saude--header-link'>
                    <Link to='/home/buscaProcedimentos'>
                        <Icon icon='filtroProcedimento' />
                        <span>Buscar procedimentos</span>
                    </Link>
                </div>
            )}
            <div className='gestor-servico-saude--header-container'>
                <div className='gestor-servico-saude--header-content'>
                    <UserInitials nome={username} size='size-64' />
                    <div className='labels'>
                        <Label className='username'>{username}</Label>
                        <Label className='perfil'>
                            <Image src={ImagemPerfil[userPerfil]} size='14x14' />
                            <span>{DescricaoPerfil[userPerfil]}</span>
                        </Label>
                        <Label className='hospital'>
                            {userInstituicao.razaoSocial.toUpperCase()}
                            <span className='hospital-local'>
                                {' '}
                                - {userInstituicao.cidade} {userInstituicao.uf}
                            </span>
                        </Label>
                    </div>
                    <MenuCmp trocarPerfil />
                </div>
            </div>
        </>
    )
}
