import iconOutros from 'images/home/cidadao/ic-outros.svg'
import iconPaciente from 'images/home/cidadao/ic-paciente.svg'
import * as C from 'libs/components'
import { AlertModalError, AlertModalSuccess } from 'libs/components'
import form from 'libs/redux-form/form'
import { defaultConfig } from 'libs/redux-form/form'
import { notFoundErrorText, notFoundErrorTitle } from 'libs/util/NotFoundErrorUtil'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import CadastroPacienteValidator from 'view/home/paciente/CadastroPacienteValidator'
import ContatoForm from 'view/home/paciente/ContatoForm'
import { ModalPreencherFichaEdicao } from '../novoProcedimento/ficha/componentes/ModalPreencherFichaEdicao'

const formName = 'cadastroPaciente'

let CadastroPacienteForm = props => {
    const [showContinuar, setShowContinuar] = useState(false)
    const [showError, setShowError] = useState(false)
    useEffect(() => {
        setShowContinuar(props.showContinuarModal && !props.showSuccessModal)
    }, [props.showContinuarModal, props.showSuccessModal])
    useEffect(() => {
        setShowError(props.showNotFoundModalError && !props.showSuccessModal)
    }, [props.showNotFoundModalError, props.showSuccessModal])
    return (
        <C.Form {...props}>
            <AlertModalSuccess
                active={props.showSuccessModal}
                title={defaultConfig.successTitle}
                onClose={props.onCloseSuccessModal}
            >
                {defaultConfig.successContent}
            </AlertModalSuccess>
            <AlertModalError active={showError} title={notFoundErrorTitle} onClose={props.onCloseNotFoundModal}>
                {notFoundErrorText}
            </AlertModalError>
            <ModalPreencherFichaEdicao
                active={showContinuar}
                onClose={props.close}
                onOk={props.ok}
                mensagem={'Deseja preencher os dados clínicos do procedimento?'}
            />
            <C.Columns>
                <C.Column verticalFlow size='2'>
                    <C.Image src={iconPaciente} alignedCenter size='96x96' />
                </C.Column>
                <C.Column>
                    <C.Columns>
                        <C.Column size='3'>
                            <C.MaskedField required label='CPF' name='cpf' placeholder='CPF' mask='999.999.999-99' />
                        </C.Column>
                        <C.Column size='3'>
                            <C.NumberField maxLength='15' label='CNS' name='cns' placeholder='CNS' />
                        </C.Column>
                    </C.Columns>
                    <C.Columns>
                        <C.Column size='6'>
                            <C.TextField
                                maxLength='70'
                                required
                                label='Nome completo'
                                name='nome'
                                placeholder='Nome e sobrenome do paciente'
                            />
                        </C.Column>
                        <C.Column size='3'>
                            <C.DatePickerField
                                required
                                name='dataNascimento'
                                placeholder='dd/mm/aaaa'
                                label='Data de nascimento'
                            />
                        </C.Column>
                    </C.Columns>
                    <C.Columns>
                        <C.Column>
                            <C.SexoField required label='Sexo' name='sexo' />
                        </C.Column>
                    </C.Columns>
                    <C.Columns>
                        <C.Column size='6'>
                            <C.TextField
                                maxLength='70'
                                required
                                label='Nome da mãe'
                                name='nomeMae'
                                placeholder='Nome e sobrenome da mãe'
                            />
                        </C.Column>
                    </C.Columns>
                </C.Column>
            </C.Columns>
            <C.SectionTitle title='ENDEREÇO DO PACIENTE' />
            <C.EnderecoForm form={formName} name='enderecoForm' paciente />
            <C.SectionTitle title='INFORMAÇÕES DO CONTATO' />
            <ContatoForm name='contatoForm' />
            <C.SectionTitle title='OUTRAS INFORMAÇÕES' />
            <C.Columns>
                <C.Column verticalFlow size='2'>
                    <C.Image src={iconOutros} alignedCenter size='96x96' />
                </C.Column>
                <C.Column size='4'>
                    <C.ConvenioField label='Convênio médico' name='entePagador' placeholder='Convênio médico' />
                </C.Column>
            </C.Columns>
            <C.Linha />
            <C.ButtonBar>
                <C.Button size='medium' disabled={props.submitting} onClick={props.cancelar}>
                    Cancelar
                </C.Button>
                <C.Button
                    pulledRight
                    type='primary'
                    size='medium'
                    loading={props.submitting}
                    onClick={props.handleSubmit}
                >
                    Salvar
                </C.Button>
            </C.ButtonBar>
        </C.Form>
    )
}

CadastroPacienteForm = form({
    form: formName,
    validate: CadastroPacienteValidator,
    hasSuccessModal: false,
})(CadastroPacienteForm)

CadastroPacienteForm = connect(state => ({
    initialValues: state.paciente.edit,
}))(CadastroPacienteForm)

export default class CadastroPacienteView extends React.Component {
    render() {
        return (
            <>
                <div className='cadastro-paciente'>
                    <C.SectionTitle title='IDENTIFICAÇÃO DO PACIENTE' />
                    <CadastroPacienteForm
                        cancelar={this.props.onCancel}
                        onSubmit={this.props.onSave}
                        onSubmitSuccess={this.props.afterSave}
                        showSuccessModal={this.props.showSuccessModal}
                        onCloseSuccessModal={this.props.onCloseSuccessModal}
                        showContinuarModal={this.props.showContinuarModal}
                        ok={this.props.okModalPreencherDadosClinicos}
                        close={this.props.goHome}
                        showNotFoundModalError={this.props.showNotFoundModalError}
                        onCloseNotFoundModal={this.props.onCloseNotFoundModal}
                    />
                </div>
            </>
        )
    }
}
