/* tslint:disable */
import React from 'react'

const SvgCopyOutline = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 21 21' {...props}>
        <path d='M12 3.35H4.504l-.12.003c-.722.034-1.034.376-1.034 1.147v8.992l.003.125c.034.721.376 1.033 1.147 1.033h3.35v1.842l.003.125c.034.721.376 1.033 1.147 1.033h7.5c.807 0 1.15-.343 1.15-1.15V7.508l-.003-.125c-.034-.721-.376-1.033-1.147-1.033h-3.35V4.508l-.003-.125C13.113 3.662 12.771 3.35 12 3.35zm4.85 3.8v9.7h-8.2v-9.7h8.2zm-4.5-3v2.2H9c-.756 0-1.105.302-1.146 1.004L7.85 7.5v6.35h-3.7v-9.7h8.2z' />
    </svg>
)

export default SvgCopyOutline
