import * as React from 'react'
import * as C from 'libs/components'

export class IdentificacaoSistemaHeader extends React.Component<any, any> {
    render() {
        return (
            <div className='identificao-sistema-wrapper'>
                <div className='container'>
                    <C.Columns>
                        <C.Column>
                            <h1 className='identificacao-sistema'>Registro Nacional de Implantes</h1>
                        </C.Column>
                    </C.Columns>
                </div>
            </div>
        )
    }
}
