/* tslint:disable */
import React from 'react'

const SvgUsoSistema = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
        <g>
            <path fillOpacity={0.01} d='M0 0h32v32H0z' />
            <path d='M13 22.007H8.014C6.671 22.007 6 21.342 6 20.01v-1.995h20v-1.997H6V8.033c0-1.322.667-1.987 2-1.996 1.25-.01 6.596-.077 16.04-.002 1.307.01 1.96.676 1.96 1.998V20.01c0 1.33-.667 1.996-2 1.996h-5l1 1.997h1c.667 0 1 .332 1 .998 0 .665-.333.998-1 .998H11c-.667 0-1-.333-1-.998 0-.666.333-.998 1-.998h1l1-1.997z' />
        </g>
    </svg>
)

export default SvgUsoSistema
