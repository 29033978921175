/* tslint:disable */
import React from 'react'

const SvgVinculospendentes = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 36 36' {...props}>
        <path d='M21.531 18.019c4.902 0 8.89 3.988 8.89 8.89 0 1-.814 1.813-1.815 1.813H17.934a7.17 7.17 0 01-5.752 2.9C8.222 31.622 5 28.399 5 24.439s3.222-7.181 7.182-7.181c1.676 0 3.214.58 4.437 1.545a8.797 8.797 0 013.605-.784h1.307zm7.075 9.568a.68.68 0 00.68-.679c0-4.275-3.479-7.754-7.755-7.754h-1.307c-.952 0-1.857.185-2.7.502a7.118 7.118 0 011.103 7.93l9.98.001zm-16.424 2.9a6.054 6.054 0 006.047-6.048 6.053 6.053 0 00-6.047-6.046 6.054 6.054 0 00-6.047 6.046 6.054 6.054 0 006.047 6.047zm8.696-13.254a6.624 6.624 0 01-6.617-6.617A6.624 6.624 0 0120.878 4a6.624 6.624 0 016.617 6.616 6.625 6.625 0 01-6.617 6.617zm0-12.098a5.487 5.487 0 00-5.482 5.481 5.488 5.488 0 005.482 5.482 5.489 5.489 0 005.482-5.482 5.488 5.488 0 00-5.482-5.48zm-8.269 19.45l2.251 1.648a.569.569 0 01-.671.916l-2.482-1.818a.567.567 0 01-.233-.458v-3.508a.568.568 0 011.135 0v3.22z' />
    </svg>
)

export default SvgVinculospendentes
