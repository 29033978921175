import { connect } from 'react-redux'
import { LocalHeaderAbrangencia } from './LocalHeaderAbrangencia'
import { State } from 'redux/modules/index'
import * as filtro from 'redux/modules/report/filtro'
import * as buscaLocalidade from 'redux/modules/report/buscaLocalidade'

export const mapStateToProps = (state: State) => ({
    localAtual: state.report.filtro.local?.data,
    localidades: state.report.buscaLocalidade.data,
    isFetching: state.report.buscaLocalidade.readyState === 'request',
    user: state.auth.user,
})

export const mapDispatchToProps = dispatch => ({
    onParentClick: () => dispatch(filtro.drillUp()),
    onSearch: (query: string) => dispatch(buscaLocalidade.search(query)),
    onSearchCleared: () => dispatch(buscaLocalidade.clear()),
    onLocalSelected: (id: any, type: filtro.LocalType) => dispatch(filtro.changeLocal(id, type)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LocalHeaderAbrangencia)
