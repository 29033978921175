import React from 'react'
import * as C from 'libs/components'

export class DoencaVascularPerifericaCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Doença vascular periférica'
                name='doencaVascularPeriferica'
                hasContent={false}
                buttonSet={<C.SimNaoNAvaliadoRadioField name='doencaVascularPeriferica' />}
            />
        )
    }
}
