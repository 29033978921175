import moment from 'moment'
import qs from 'qs'
import axios from 'axios'
import 'moment/locale/pt-br'

export default () => {
    moment.locale('pt-BR')

    axios.defaults.paramsSerializer = params => {
        return qs.stringify(params, { skipNulls: true, allowDots: true })
    }
}
