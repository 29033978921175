/* tslint:disable */
import React from 'react'

const SvgReativar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M12.93 4c-3.84 0-7.067 2.868-7.514 6.622l-.08.672-.376-.562-.972-1.453a.542.542 0 00-.747-.15.533.533 0 00-.152.741l2.157 3.22c.087.13.226.214.386.234l.065.004c.134 0 .262-.049.362-.14l2.994-2.675c.222-.2.24-.537.043-.757a.545.545 0 00-.763-.042l-1.924 1.724.074-.652c.366-3.237 3.14-5.715 6.447-5.715 3.58 0 6.492 2.887 6.492 6.438 0 3.551-2.91 6.438-6.488 6.438a6.462 6.462 0 01-4.587-1.886.54.54 0 00-.763 0 .531.531 0 000 .757 7.546 7.546 0 005.35 2.2c4.172 0 7.566-3.37 7.566-7.51S17.105 4 12.93 4z' />
    </svg>
)

export default SvgReativar
