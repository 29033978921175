import React from 'react'
import * as C from 'libs/components'
import * as FC from 'view/home/novoProcedimento/ficha/componentes'
import { StentCoronariaEssencialVisualizacaoDto } from 'backend'
import { dadosComplementaresByEnum } from 'api/Visualizacao'
import * as DateUtil from 'libs/util/DateUtil'

interface ApresentacaoClinicaCardProps {
    dadosComplementares: StentCoronariaEssencialVisualizacaoDto
}

export class ApresentacaoClinicaCard extends React.Component<ApresentacaoClinicaCardProps> {
    render() {
        const data = this.props.dadosComplementares
        const tipoCaraterIcpPrimaria =
            data.tipoCaraterICP === 'PRIMARIA_MENOS_12_HORAS_SINTOMA' ||
            data.tipoCaraterICP === 'PRIMARIA_MAIS_12_HORAS_SINTOMA'

        return (
            <div>
                <C.Columns auto>
                    <FC.ComposedLabelVisualizacao title={'Quadro clínico'}>
                        {dadosComplementaresByEnum[data.quadroClinico]}
                    </FC.ComposedLabelVisualizacao>
                    <FC.ComposedLabelVisualizacao title={'Classe funcional'}>
                        {data.classeFuncional}
                    </FC.ComposedLabelVisualizacao>
                </C.Columns>
                {data.quadroClinico !== 'IAM_COM_SUPRA_ST' ? (
                    <C.Columns>
                        <C.Column>
                            <C.ComposedLabel title={'Caráter ICP'}>
                                {dadosComplementaresByEnum[data.urgenciaInvasiva] ||
                                    dadosComplementaresByEnum[data.eletivaAdhoc]}
                            </C.ComposedLabel>
                        </C.Column>
                    </C.Columns>
                ) : (
                    <div className='paddingbottom'>
                        <C.Columns>
                            <C.Column>
                                <C.ComposedLabel title={'Caráter ICP'}>
                                    {dadosComplementaresByEnum[data.tipoCaraterICP]}
                                </C.ComposedLabel>
                            </C.Column>
                        </C.Columns>
                        {tipoCaraterIcpPrimaria && (
                            <div>
                                {data.transferenciaIcpPrimaria === 'NAO' && (
                                    <C.Columns auto>Transferência para ICP primária não realizada</C.Columns>
                                )}
                                {data.transferenciaIcpPrimaria === 'SIM' && (
                                    <div>
                                        <C.Columns auto>Transferência para ICP primária realizada</C.Columns>
                                        <div className='with-border-left'>
                                            <C.Label>Contato do hospital de origem</C.Label>
                                            <C.Columns auto>
                                                <C.DateLabel
                                                    title='Data'
                                                    value={data.dataContatoHospital}
                                                ></C.DateLabel>
                                                <C.ComposedLabel title='Hora'>
                                                    {data.horaContatoHospital}
                                                </C.ComposedLabel>
                                            </C.Columns>
                                        </div>
                                        <div className='with-border-left'>
                                            <C.Label className=''>Chegada no destino</C.Label>
                                            <C.Columns auto>
                                                <C.DateLabel title='Data' value={data.dataChegadaIcp}></C.DateLabel>
                                                <C.ComposedLabel title='Hora'>{data.horaChegadaIcp}</C.ComposedLabel>
                                            </C.Columns>
                                        </div>
                                        {data.dataContatoHospital &&
                                            data.horaContatoHospital &&
                                            data.dataChegadaIcp &&
                                            data.horaChegadaIcp && (
                                                <C.Columns auto>
                                                    <p className='is-italic is-muted'>
                                                        {'Tempo de transferência: ' +
                                                            DateUtil.getPeriodBetweenDates(
                                                                data.dataContatoHospital,
                                                                data.horaContatoHospital,
                                                                data.dataChegadaIcp,
                                                                data.horaChegadaIcp
                                                            )}
                                                    </p>
                                                </C.Columns>
                                            )}
                                    </div>
                                )}
                                <div className='with-border-left'>
                                    <C.Label className=''>Início dos sintomas</C.Label>
                                    <C.Columns auto>
                                        <C.DateLabel title={'Data'} value={data.dataInicioSintomas}></C.DateLabel>
                                        <C.ComposedLabel title='Hora'>{data.horaInicioSintomas}</C.ComposedLabel>
                                    </C.Columns>
                                </div>
                                <div className='with-border-left'>
                                    <C.Label className=''>Admissão no hospital</C.Label>
                                    <C.Columns auto>
                                        <C.DateLabel title={'Data'} value={data.dataAdmissao}></C.DateLabel>
                                        <C.ComposedLabel title='Hora'>{data.horaAdmissao}</C.ComposedLabel>
                                    </C.Columns>
                                </div>
                                <div className='with-border-left'>
                                    <C.Label className=''>Abertura vaso/balão</C.Label>
                                    <C.Columns auto>
                                        <C.DateLabel title={'Data'} value={data.dataAberturaVaso}></C.DateLabel>
                                        <C.ComposedLabel title='Hora'>{data.horaAberturaVaso}</C.ComposedLabel>
                                    </C.Columns>
                                </div>
                                <C.Columns auto>
                                    {data.dataAdmissao &&
                                        data.horaAdmissao &&
                                        data.dataInicioSintomas &&
                                        data.horaInicioSintomas && (
                                            <p className='is-italic is-muted'>
                                                {'Dor/porta: ' +
                                                    DateUtil.getPeriodBetweenDates(
                                                        data.dataInicioSintomas,
                                                        data.horaInicioSintomas,
                                                        data.dataAdmissao,
                                                        data.horaAdmissao
                                                    )}
                                            </p>
                                        )}
                                    {data.dataAberturaVaso &&
                                        data.horaAberturaVaso &&
                                        data.dataAdmissao &&
                                        data.horaAdmissao && (
                                            <p className='is-italic is-muted'>
                                                {'Porta/balão: ' +
                                                    DateUtil.getPeriodBetweenDates(
                                                        data.dataAdmissao,
                                                        data.horaAdmissao,
                                                        data.dataAberturaVaso,
                                                        data.horaAberturaVaso
                                                    )}
                                            </p>
                                        )}
                                </C.Columns>
                            </div>
                        )}
                    </div>
                )}
                <C.Columns auto>
                    <C.ComposedLabel title={'Padrão obstrutivo'}>
                        {dadosComplementaresByEnum[data.padraoObstrutivo]}
                    </C.ComposedLabel>
                </C.Columns>
            </div>
        )
    }
}
