import * as React from 'react'
import { InfoProdutoDto } from 'backend'
import {
    Card,
    LoadingContainer,
    Column,
    Columns,
    CircleProgress,
    Text,
    PercentageLabel,
    NumberLabel,
} from 'libs/components'
import { TempoLabel } from 'view/home/anvisa/reports/components/TempoLabel'

interface Props {
    info: InfoProdutoDto
    isFetching: boolean
}

export class InfoProdutoDadosGeraisCard extends React.Component<Props> {
    render() {
        const { info, isFetching } = this.props

        return (
            <Card title='Dados Gerais'>
                <LoadingContainer isFetching={isFetching}>
                    {info && (
                        <Columns>
                            <Column size='2' className='has-border-right'>
                                <CircleProgress
                                    size={100}
                                    strokeWidth={4}
                                    progress={{
                                        'is-report-color-1': info.stats.countRevisoes / info.stats.countProcedimentos,
                                    }}
                                >
                                    <Text size='xx-large'>
                                        <NumberLabel value={info.stats.countRevisoes} />
                                    </Text>
                                    <br />
                                    <Text size='small-bis' color='grey-darker'>
                                        <PercentageLabel
                                            value={info.stats.countRevisoes / info.stats.countProcedimentos}
                                            maxDecimalPlaces={1}
                                        />
                                    </Text>
                                </CircleProgress>
                            </Column>
                            <Column size='10'>
                                <Columns className='is-small-bis is-black-bis spacing-one-half'>
                                    <Column size='6'>
                                        <p>
                                            <Text size='large'>
                                                <Text weight='semibold'>
                                                    <NumberLabel value={info.stats.countRevisoes} />
                                                </Text>{' '}
                                                dos <NumberLabel value={info.stats.countProcedimentos} />
                                            </Text>
                                        </p>
                                        <p>procedimentos precisaram de revisão</p>
                                    </Column>
                                    {info.stats.mesesEntreProcedimentos !== null && (
                                        <Column size='6'>
                                            <p>
                                                <Text size='large' weight='semibold'>
                                                    <TempoLabel meses={info.stats.mesesEntreProcedimentos} />
                                                </Text>
                                            </p>
                                            <p>Tempo médio entre revisão</p>
                                        </Column>
                                    )}
                                </Columns>
                                <hr style={{ marginTop: '1rem' }} />
                                <Columns className='is-small-bis is-black-bis spacing-one-half'>
                                    <Column size='6'>
                                        <p>
                                            <Text weight='semibold'>Fabricantes</Text>
                                        </p>
                                        {info.produto.fabricantes?.length > 0 ? (
                                            info.produto.fabricantes.map((fab, idx) => (
                                                <p key={idx}>{fab.fabricante}</p>
                                            ))
                                        ) : (
                                            <Text color='grey-darker' italic>
                                                Não informado
                                            </Text>
                                        )}
                                    </Column>
                                    <Column size='6'>
                                        <p>
                                            <Text weight='semibold'>Detentor do registro</Text>
                                        </p>
                                        <p>{info.produto.nomeFabricanteImportador}</p>
                                    </Column>
                                </Columns>
                            </Column>
                        </Columns>
                    )}
                </LoadingContainer>
            </Card>
        )
    }
}
