import React from 'react'
import { CustomModalConfig, defaultErrorResolver } from 'libs/redux-form/form'
import { notFoundErrorText, notFoundErrorTitle } from 'libs/util/NotFoundErrorUtil'

export const handleErrorResolver = (error: any): CustomModalConfig => {
    if (error?._error?.status === 404) {
        return {
            modalTitle: notFoundErrorTitle,
            modalContent: <span>{notFoundErrorText}</span>,
        }
    }
    return defaultErrorResolver(error)
}
