import { connect } from 'react-redux'
import AlertasConfigView from 'view/home/anvisa/alertas/AlertasConfigView'
import { State } from 'redux/modules'

const mapStateToProps = (state: State) => {
    return {
        usuarioLogado: state.auth.user,
    }
}

const AlertasConfigContainer = connect(mapStateToProps)(AlertasConfigView)

export default AlertasConfigContainer
