import { EspecialidadesMedicas } from 'api/Perfis'
import { VinculoProfissionalRow } from 'backend'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { change, FieldArrayFieldsProps, WrappedFieldArrayProps } from 'redux-form'
import { getFormValues } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { definirEquipeMedicaFormName } from '../DefinirEquipeMedicaForm'
import { EquipeMedicaCard } from './EquipeMedicaCard'

interface EquipeMedicaListProps extends WrappedFieldArrayProps {
    setEquipeMedicaFields: (fields: FieldArrayFieldsProps<any>) => void
    setEspecialidadeEquipeMedica: (especialidade: EspecialidadesMedicas) => void
    userMedicoVinculoId: number
}

export const EquipeMedicaList: React.FC<EquipeMedicaListProps> = props => {
    const { fields, setEquipeMedicaFields, setEspecialidadeEquipeMedica, userMedicoVinculoId } = props
    const dispatch = useDispatch()

    React.useEffect(() => {
        setEquipeMedicaFields(fields)
    }, [fields, setEquipeMedicaFields])

    const definirEquipeMedicaFormValues = useSelector<State, any>(state =>
        getFormValues(definirEquipeMedicaFormName)(state)
    )

    const handleClickDelete = index => {
        fields.remove(index)

        const idMedicoResponsavelSelecionado: number = parseInt(
            definirEquipeMedicaFormValues?.get('medicoResponsavel'),
            10
        )
        const isDeleteMedicoResponsavel: boolean = index === idMedicoResponsavelSelecionado
        const needUpdateIdMedicoResponsavel: boolean = index < idMedicoResponsavelSelecionado

        if (isDeleteMedicoResponsavel) {
            updateIdMedicoResponsavel(null)
        } else if (needUpdateIdMedicoResponsavel) {
            const newId: number = idMedicoResponsavelSelecionado - 1
            updateIdMedicoResponsavel(newId.toString())
        }

        const fieldsWillBeEmpty: boolean = fields.length === 1
        if (fieldsWillBeEmpty) {
            setEspecialidadeEquipeMedica(null)
        }
    }

    const updateIdMedicoResponsavel = (newId: string) => {
        dispatch(change(definirEquipeMedicaFormName, 'medicoResponsavel', newId))
    }

    return (
        <>
            {fields.length ? (
                fields.map((_, index: number) => {
                    const vinculoProfissional: VinculoProfissionalRow = fields.get(index)
                    return (
                        <EquipeMedicaCard
                            key={vinculoProfissional.id}
                            cardValue={index}
                            vinculoProfissional={vinculoProfissional}
                            onClickDelete={() => handleClickDelete(index)}
                            userMedicoVinculoId={userMedicoVinculoId}
                        />
                    )
                })
            ) : (
                <div className='equipe-medica--empty-box text-search'>Não há profissionais adicionados.</div>
            )}
        </>
    )
}
