import * as React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'libs/components'
import Text from 'libs/components/presentational/typography/Text'
import { Icons } from 'libs/components/presentational/elements/icons'

interface Props {
    isBig?: boolean
    titulo: string
    destino: string
    procedimentos?: number
    icon: Icons
    dataName?: string
}

export class RelatorioAnvisaCard extends React.Component<Props> {
    render() {
        const isBig = this.props.isBig
        return (
            <div className='card' style={{ width: isBig ? '380px' : '246px' }}>
                <header className={isBig ? 'home-anvisa-card-header is-big' : 'home-anvisa-card-header'}>
                    <Icon
                        className={
                            isBig ? 'image-header-home-anvisa is-big' : 'image-header-home-anvisa ' + this.props.icon
                        }
                        icon={this.props.icon}
                    />
                </header>

                <Icon icon={this.props.icon} className={'icon-card-home-anvisa ' + this.props.icon} alignedCenter />
                <div className='content'>
                    <div className='margin-top-24'>
                        <Text size={isBig ? 'large' : 'medium'} color='black-bis'>
                            {this.props.titulo}
                        </Text>
                    </div>
                    {!isNaN(this.props.procedimentos) && (
                        <div className='margin-top-24'>
                            <Text size='medium' weight='semibold'>
                                {this.props.procedimentos} <br />
                            </Text>
                            <Text size='small-bis' color='grey-darker'>
                                procedimentos
                            </Text>
                        </div>
                    )}
                    <div className='margin-buttom-acessar'>
                        <Link className='button' data-name={this.props.dataName} to={this.props.destino}>
                            <span data-name={this.props.dataName}>Acessar</span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
