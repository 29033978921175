import React from 'react'
import * as C from 'libs/components'

export class HipertensaoCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Hipertensão'
                name='hipertensao'
                hasContent={false}
                buttonSet={<C.SimNaoNAvaliadoRadioField name='hipertensao' />}
            />
        )
    }
}
