/* tslint:disable */
import React from 'react'

const SvgKey = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 36 36' {...props}>
        <path d='M24.69 5.1l.146.005h5.158c.454 0 .572.096.602.474l.004.155v5.37c0 .45-.096.565-.477.594l-.156.005h-3.323l-.1.1v3.35c0 .45-.094.565-.47.594l-.156.005h-3.333l-3.747 3.689-.02.115.21.42a7.348 7.348 0 01-6.58 10.622 7.348 7.348 0 11.457-14.681l.541.046L24.028 5.432c.144-.157.259-.248.351-.288a.59.59 0 01.177-.038l.135-.006zm4.61 1.204h-4.417l-10.56 10.527-.097.086-.083.064a.601.601 0 01-.275.123l-.185.01-.235.004-.433-.004-.565-.013-.23.005-.225.012A6.147 6.147 0 006.3 23.25a6.148 6.148 0 006.149 6.149 6.148 6.148 0 005.48-8.939l-.231-.427-.07-.15a1.337 1.337 0 01-.129-.51c.002-.155.07-.313.217-.487l4.072-4.063.08-.075c.078-.069.147-.12.206-.155.037-.022.152-.04.31-.04h2.86l.1-.1v-3.35l.002-.107.007-.092c.035-.314.176-.4.6-.4l3.349-.011.1-.1V6.404l-.1-.1zM11.75 22.6a1.35 1.35 0 11-.001 2.701 1.35 1.35 0 01.001-2.701z' />
    </svg>
)

export default SvgKey
