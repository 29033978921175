import * as React from 'react'
import * as C from 'libs/components'

interface ModalSaveProps {
    onClose: () => void
    title: string
    active: boolean
    result: any
}

export class ModalSave extends React.Component<ModalSaveProps> {
    constructor(props) {
        super(props)

        this.onPrint = this.onPrint.bind(this)
    }

    onPrint() {
        window.open('/api/procedimentos/impressaoRegistro/' + this.props.result.data.id, '_blank')
        this.props.onClose()
    }

    render() {
        return (
            <C.AlertModal active={this.props.active} success>
                <C.AlertModalContent title={this.props.title}>{this.props.children}</C.AlertModalContent>
                <C.AlertModalButtonBar>
                    <C.Linha />
                    <C.Button size='medium' onClick={this.props.onClose}>
                        Sair
                    </C.Button>
                    <C.Button type='success' size='medium' className='link-button' onClick={this.onPrint}>
                        Imprimir
                    </C.Button>
                </C.AlertModalButtonBar>
            </C.AlertModal>
        )
    }
}
