/* tslint:disable */
import React from 'react'

const SvgDadosclinicos = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 36 36' {...props}>
        <path d='M28.305 24.835a3.487 3.487 0 012.906 3.43 3.493 3.493 0 01-3.49 3.49 3.493 3.493 0 01-3.489-3.49 3.487 3.487 0 012.906-3.43v-6.33a2.806 2.806 0 00-2.803-2.803 2.806 2.806 0 00-2.802 2.804v8.738h-.024c-.246 2.527-2.466 4.512-5.162 4.512-2.859 0-5.185-2.232-5.185-4.976v-3.44H10.02v-1.11A7.702 7.702 0 014 14.68v-.972h1.142V8.24c0-1.552 1.155-2.849 2.709-3.214A1.49 1.49 0 019.26 4a1.494 1.494 0 010 2.985 1.486 1.486 0 01-1.301-.777c-.957.289-1.652 1.083-1.652 2.032v5.468H7.45v.972a4.3 4.3 0 004.296 4.296 4.3 4.3 0 004.295-4.296v-.972h1.141V8.24c0-.95-.695-1.743-1.651-2.032a1.486 1.486 0 01-2.795-.715 1.488 1.488 0 012.903-.467c1.555.365 2.71 1.662 2.71 3.214v5.468h1.141v.972a7.702 7.702 0 01-6.02 7.55v1.11h-1.14v3.44c0 2.1 1.802 3.81 4.018 3.81 2.216 0 4.019-1.71 4.019-3.81v-8.274c0-2.19 1.78-3.97 3.969-3.97 2.19 0 3.97 1.78 3.97 3.97v6.33zm-.584 5.754a2.326 2.326 0 002.324-2.323 2.326 2.326 0 00-2.324-2.323 2.326 2.326 0 00-2.322 2.323 2.326 2.326 0 002.322 2.323zm0-3.46a1.139 1.139 0 010 2.275 1.139 1.139 0 010-2.276zm0 1.497a.361.361 0 000-.72.36.36 0 000 .72zM5.17 14.875a6.54 6.54 0 005.525 6.298l.492.078v.923h1.116v-.923l.491-.078a6.54 6.54 0 005.526-6.298h-1.116a5.47 5.47 0 01-5.459 5.268 5.47 5.47 0 01-5.46-5.268H5.17zm4.092-9.708a.326.326 0 100 .651.326.326 0 000-.651zm4.966.651a.326.326 0 100-.651.326.326 0 000 .651z' />
    </svg>
)

export default SvgDadosclinicos
