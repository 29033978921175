import axios, { AxiosPromise } from 'axios'
import { AutenticacaoIntegracaoDto, CadastroEntidadeConsumidoraForm } from 'backend'

const baseUrl = '/api/entidadeConsumidora'

class Entidades {
    getEntidade(entidadeId: number, method: 'edicao' | 'visualizacao' = 'edicao'): string {
        return `${baseUrl}/${method === 'edicao' ? 'entidade' : 'visualizarEntidade'}/${entidadeId}`
    }

    getEntidades(filtro: 'ativas' | 'inativas' = 'ativas'): string {
        return baseUrl + `/${filtro === 'ativas' ? 'entidadesAtivas' : 'entidadesInativas'}`
    }

    saveEntidade(entidade: CadastroEntidadeConsumidoraForm): AxiosPromise<CadastroEntidadeConsumidoraForm> {
        return axios.post(baseUrl + '/entidade', entidade)
    }

    updateEntidade(entidade: CadastroEntidadeConsumidoraForm): AxiosPromise<CadastroEntidadeConsumidoraForm> {
        return axios.put(baseUrl + '/entidade', entidade)
    }

    inativarEntidade(entidadeId: number): AxiosPromise<null> {
        return axios.post(baseUrl + `/inativarEntidade/${entidadeId}`)
    }

    getAutenticacoes(): string {
        return `${baseUrl}/autenticacoes`
    }

    generateLogin(entidadeId: number): AxiosPromise<AutenticacaoIntegracaoDto> {
        return axios.post(baseUrl + '/novoLogin/' + entidadeId)
    }

    generateSenha(entidadeId: number): AxiosPromise<AutenticacaoIntegracaoDto> {
        return axios.post(baseUrl + '/novaSenha/' + entidadeId)
    }

    generateEmail(entidadeId: number): AxiosPromise<null> {
        return axios.post(baseUrl + '/gerarEmail/' + entidadeId)
    }

    getEntidadesForm() {
        return axios.get(baseUrl + '/gerarFormulario', { responseType: 'arraybuffer' })
    }
}

export default new Entidades()
