import * as React from 'react'
import classnames from 'classnames'

export interface CardProps extends CardHeaderProps {
    contentClassName?: string
    noPadding?: boolean
    fullHeight?: boolean
}

export class Card extends React.Component<CardProps> {
    render() {
        const { contentClassName, noPadding, children, ...rest } = this.props
        const classes = classnames('card', {
            'full-height': this.props.fullHeight,
        })
        return (
            <div className={classes}>
                {this.props.title && <CardHeader {...rest} />}

                <div className='card-body'>
                    <CardContent contentClassName={contentClassName} noPadding={noPadding}>
                        {children}
                    </CardContent>
                </div>
            </div>
        )
    }
}

export const CardContent = ({ children, noPadding, contentClassName = '' }) => {
    return (
        <div
            className={classnames('card-content', contentClassName, {
                'no-padding': noPadding,
            })}
        >
            {children}
        </div>
    )
}

export interface CardHeaderProps {
    title?: string | JSX.Element
    headerTools?: React.ReactElement<any> | React.ReactElement<any>[]
    headerAction?: React.ReactElement<any>
    headerAction2?: React.ReactElement<any>
    testid?: string
}

export class CardHeader extends React.Component<CardHeaderProps> {
    render() {
        return (
            <header className='card-header'>
                <div className='card-header-content'>
                    <div className='card-header-title' data-testid={this.props.testid}>
                        {this.props.title}
                    </div>

                    {this.props.headerTools && <div className='card-header-tools'>{this.props.headerTools}</div>}
                </div>

                {this.props.headerAction && <div className='card-header-action'>{this.props.headerAction}</div>}
                {this.props.headerAction2 && <div className='card-header-action'>{this.props.headerAction2}</div>}
            </header>
        )
    }
}
