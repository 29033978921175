import * as React from 'react'
import * as C from 'libs/components'
import { TipoRevisaoRow } from 'backend'
import { Progress } from 'libs/components/presentational/elements/Progress'
import { Icon } from 'libs/components'
import { NumberLabel } from 'libs/components/label/NumberLabel'

interface Props {
    tipo: TipoRevisaoRow
    total: number
    onClick: () => void
}

export class SumarioTipoRevisaoCard extends React.Component<Props> {
    render() {
        const tipoRevisao = this.props.tipo
        return (
            <div className='padded-botton'>
                <C.Card>
                    <C.Columns className='align-center'>
                        <C.Column size='6'>
                            <C.Columns alignedCenter>
                                <C.Column size='10'>
                                    <C.Label className='is-black-bis'> {tipoRevisao.tipoRevisaoName}</C.Label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                width: (tipoRevisao.cont / this.props.total) * 100 + '%',
                                                marginRight: '10px',
                                            }}
                                        >
                                            <Progress value={1} className='is-report-color-2' size='x-small' />
                                        </span>
                                        <NumberLabel
                                            className='percent'
                                            value={
                                                tipoRevisao.cont > 0
                                                    ? (tipoRevisao.cont / this.props.total) * 100
                                                    : undefined
                                            }
                                            maxDecimalPlaces={1}
                                            sufix='%'
                                            placeholder='- %'
                                        />
                                    </div>
                                </C.Column>
                                <C.Column size='2' className='has-border-right' alignedCenter textCentered>
                                    <span className='total-proced-count'>{tipoRevisao.cont}</span>
                                </C.Column>
                            </C.Columns>
                        </C.Column>
                        <C.Column size='6'>
                            <C.Columns alignedCenter>
                                <C.Column size='5' horizontalFlow className='justify-center align-center'>
                                    <div className='is-vertical-flow'>
                                        <C.Label className='motivos-card-label'> Média de idade</C.Label>
                                        <NumberLabel
                                            className='motivo-card-content'
                                            value={tipoRevisao.avg}
                                            maxDecimalPlaces={1}
                                            sufix=' anos'
                                            placeholder='-'
                                        />
                                    </div>
                                </C.Column>
                                <C.Column size='5' verticalFlow className='justify-center'>
                                    <span>
                                        <Icon size='is-x-small' icon='masculino' />
                                        <span className='motivos-card-label'>
                                            {' '}
                                            Masculino{' '}
                                            <NumberLabel
                                                className='motivo-card-content'
                                                value={(tipoRevisao.contMasc / tipoRevisao.cont) * 100}
                                                maxDecimalPlaces={1}
                                                sufix='%'
                                                placeholder='- %'
                                            />
                                        </span>
                                    </span>
                                    <span>
                                        <Icon size='is-x-small' icon='feminino' />
                                        <span className='motivos-card-label'>
                                            {' '}
                                            Feminino{' '}
                                            <NumberLabel
                                                className='motivo-card-content'
                                                value={(tipoRevisao.contFem / tipoRevisao.cont) * 100}
                                                maxDecimalPlaces={1}
                                                sufix='%'
                                                placeholder='- %'
                                            />
                                        </span>
                                    </span>
                                </C.Column>
                                <C.Column size='2'>
                                    <C.Hint
                                        placement='top'
                                        componente={
                                            <C.IconButton
                                                disabled={this.props.total < 1}
                                                data-name={'botao_' + tipoRevisao.tipoRevisao}
                                                icon='arrowRight'
                                                size='small'
                                                pulledRight
                                                onClick={this.props.onClick}
                                            />
                                        }
                                    >
                                        Visualizar
                                    </C.Hint>
                                </C.Column>
                            </C.Columns>
                        </C.Column>
                    </C.Columns>
                </C.Card>
            </div>
        )
    }
}
