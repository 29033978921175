import * as React from 'react'
import { Switch, Route, NavLink, Redirect, useLocation, useHistory, useParams } from 'react-router-dom'
import { DistribuicaoProcedimentoFiltro, DateInterval, ProdutoDto } from 'backend'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import UrlParams from 'libs/router/UrlParams'
import { RastreabilidadeReportHeader } from 'view/componentes/reports/components/RastreabilidadeReportHeader'
import { ScrollToTop, Columns, Column, PeriodoFilter } from 'libs/components'
import { HomeGestorServicoSaudeHeader } from '../HomeGestorServicoSaudeHeader'
import { ReportDashboardView } from 'view/componentes/reports/cards/ReportDashboardView'
import ReportPacienteListView from 'view/componentes/reports/fragments/ReportPacienteListView'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { stringify } from 'redux/modules/router'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'redux/modules'
import { change } from 'redux/modules/report/filtro'
import { loadAll } from 'redux/modules/report/procedimento'
import { findProduto, clearFindProduto } from 'redux/modules/report/rastreabilidade'
import { useRouteMatch } from 'react-router-dom'

export const GestorServicoSaudeRastreabilidadeReportView = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const { regAnvisa } = useParams()
    const filtro = useSelector<State, DistribuicaoProcedimentoFiltro>(state => state.report.filtro.filtro)
    const produto = useSelector<State, PromiseRecord<ProdutoDto>>(state => state.report.rastreabilidade.produto)

    const urlSpecificParam = `/home/relatorios/rastreabilidade/registro/${regAnvisa}`
    const urlGeneralParam = '/home/relatorios/rastreabilidade/registro/:regAnvisa'
    const matchPaciente = useRouteMatch(`${urlGeneralParam}/paciente`)
    const matchDashboard = useRouteMatch(`${urlGeneralParam}/dashboard`)

    React.useEffect(() => {
        dispatch(findProduto(regAnvisa))
        dispatch(loadAll())
        return () => dispatch(clearFindProduto())
    }, [dispatch, regAnvisa])

    const changeFilter = (filtro: DistribuicaoProcedimentoFiltro) => {
        const baseFilter: DistribuicaoProcedimentoFiltro = {
            registroAnvisa: regAnvisa,
        }

        dispatch(
            change({
                ...baseFilter,
                ...filtro,
            })
        )
    }

    const loadAllProduto = () => {
        dispatch(findProduto(regAnvisa))
        dispatch(loadAll())
    }

    const handlePeriodoChange = (interval: DateInterval) => {
        changeFilter({
            ...filtro,
            interval: interval,
        })
    }

    const refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            changeFilter(filter)
        }
        loadAllProduto()
    }

    const handleVoltar = () => {
        history.push('/home/relatorios/rastreabilidade')
    }

    const { group, tipoProcedimento, registroAnvisa, ...publishFilter } = filtro

    return (
        <div>
            <ScrollToTop onMount />
            <UrlParams params={publishFilter} onChange={refresh} />
            <ReportClearFilter />

            <HomeGestorServicoSaudeHeader />
            <RastreabilidadeReportHeader produto={produto.data} onVoltar={handleVoltar} />

            <div className='report-content'>
                <Columns className='report-tabs align-center'>
                    <Column size='6'>
                        <div className='report-tabs-links'>
                            <NavLink
                                to={() => {
                                    if (matchDashboard?.isExact) {
                                        return {
                                            pathname: location.pathname,
                                            search: location.search,
                                        }
                                    } else {
                                        return {
                                            pathname: `${urlSpecificParam}/dashboard`,
                                            search: location.search,
                                        }
                                    }
                                }}
                                activeClassName='is-active'
                            >
                                Dashboard
                            </NavLink>
                            <NavLink
                                to={() => {
                                    if (matchPaciente?.isExact) {
                                        return {
                                            pathname: location.pathname,
                                            search: location.search,
                                        }
                                    } else {
                                        return {
                                            pathname: `${urlSpecificParam}/paciente`,
                                            search: location.search,
                                        }
                                    }
                                }}
                                activeClassName='is-active'
                            >
                                Lista de pacientes
                            </NavLink>
                        </div>
                    </Column>
                    <Column size='6' textRight>
                        <PeriodoFilter value={filtro.interval} onChange={handlePeriodoChange} />
                    </Column>
                </Columns>

                <Switch>
                    <Route path={`${urlGeneralParam}/paciente`}>
                        <ReportPacienteListView filtro={filtro} />
                    </Route>

                    <Route path={`${urlGeneralParam}/dashboard`}>
                        <ReportDashboardView dadosUsoTitle={'Número de procedimentos'} />
                    </Route>

                    <Redirect
                        to={{
                            pathname: `${urlSpecificParam}/dashboard`,
                            search: stringify({ ...publishFilter }),
                        }}
                    />
                </Switch>
            </div>
        </div>
    )
}
