import * as React from 'react'
import * as C from 'libs/components'
import { ResumoLocalCard } from '../cards'
import { DistClassificacaoLocal, ResumoLocalItem } from 'redux/modules/report/procedimento'

interface Props {
    distClassificacaoLocal: DistClassificacaoLocal
    showPrimariaRevisaoRatio: boolean
    onItemClick(item: ResumoLocalItem): void
}

export class ResumoLocalList extends React.Component<Props> {
    handleItemClick(item: ResumoLocalItem) {
        this.props.onItemClick(item)
    }

    render() {
        const dist = this.props.distClassificacaoLocal
        const result = dist.result || []
        const maxQuocient = Math.max(...result.map(res => res.quocient ?? 0))

        return (
            <div>
                {result.map((res, idx) => (
                    <C.Columns key={idx} auto>
                        <ResumoLocalCard
                            item={res}
                            maxQuocient={maxQuocient}
                            showPrimariaRevisaoRatio={this.props.showPrimariaRevisaoRatio}
                            onClick={this.handleItemClick.bind(this, res)}
                        />
                    </C.Columns>
                ))}
            </div>
        )
    }
}
