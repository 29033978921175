export default function promiseMiddleware() {
    return next => action => {
        const { promise, ...rest } = action
        if (!promise) {
            return next(action)
        }

        next({ ...rest, readyState: 'request' })
        return promise
            .then(result => {
                next({ ...rest, result, readyState: 'success' })
                return result
            })
            .catch(error => {
                next({ ...rest, error, readyState: 'failure' })
                return Promise.reject(error)
            })
    }
}

export interface PromiseRecord<T> {
    readonly readyState?: 'success' | 'failure' | 'request'
    readonly data?: T
    readonly error?: any
}

export function promiseReducer<T>(state: PromiseRecord<T>, action): PromiseRecord<T> {
    return {
        readyState: action.readyState,
        data: action.result ? action.result.data : undefined,
        error: action.error ? action.error.response : undefined,
    }
}

export function promiseStaleReducer<T>(state: PromiseRecord<T>, action): PromiseRecord<T> {
    return {
        readyState: action.readyState,
        data: action.result ? action.result.data : state?.data,
        error: action.error ? action.error.response : undefined,
    }
}
