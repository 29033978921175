import React from 'react'
import { ControlField, ChecklistItemField, InformationIconPopover } from 'libs/components'

export const RelacaoEmpresasTiposRelacoesChecklist: React.FC = () => {
    return (
        <>
            <ControlField
                required
                className='modal-content--checklist-title'
                label='Tipos de relações'
                name='relacoes'
            />
            <div className='modal-content--checklist-items'>
                <ChecklistItemField
                    name='relacoes'
                    label='Consultoria e honorários'
                    optionValue='CONSULTORIA_HONORARIOS'
                />
                <ChecklistItemField
                    name='relacoes'
                    label='Propriedade intelectual e patentes'
                    optionValue='PROPRIEDADE_INTELECTUAL_PATENTES'
                />
                <ChecklistItemField name='relacoes' label='Royalties' optionValue='ROYALTIES' />
                <div className='modal-content--checklist-item'>
                    <ChecklistItemField
                        name='relacoes'
                        label='Participação em eventos'
                        optionValue='PARTICIPACAO_EVENTOS'
                    />
                    <InformationIconPopover
                        information='Congressos, simpósios, seminários, palestras e demais eventos técnicos-científicos.'
                        spaced
                    />
                </div>
                <div className='modal-content--checklist-item'>
                    <ChecklistItemField name='relacoes' label='Pesquisa científica' optionValue='PESQUISA_CIENTIFICA' />
                    <InformationIconPopover
                        information='Pesquisa, desenvolvimento e/ou publicação de material científico.'
                        spaced
                    />
                </div>
                <div className='modal-content--checklist-item'>
                    <ChecklistItemField name='relacoes' label='Despesas de viagens' optionValue='DESPESA_VIAGENS' />
                    <InformationIconPopover
                        information='Despesas com deslocamento, hospedagem e/ou refeições.'
                        spaced
                    />
                </div>
                <div className='modal-content--checklist-item'>
                    <ChecklistItemField
                        name='relacoes'
                        label='Docente em eventos educacionais'
                        optionValue='DOCENTE_EVENTOS_EDUCACIONAIS'
                    />
                    <InformationIconPopover
                        information='Professor, palestrante e/ou orientador em aulas, EAD, palestras, ou demais eventos educacionais.'
                        spaced
                    />
                </div>
                <ChecklistItemField
                    name='relacoes'
                    label='Proprietário, empregado ou investidor'
                    optionValue='PROPRIETARIO_EMPREGADO_INVESTIDOR'
                />
                <ChecklistItemField name='relacoes' label='Outros' optionValue='OUTROS' />
            </div>
        </>
    )
}
