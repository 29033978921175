import React from 'react'
import * as C from 'libs/components'

export class CRMPreviaCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='CRM prévia'
                name='crmPrevia'
                hasContent={false}
                buttonSet={<C.SimNaoNAvaliadoRadioField name='crmPrevia' />}
            />
        )
    }
}
