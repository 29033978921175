/* tslint:disable */
import React from 'react'

const SvgExportar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M18.212 8.514a.49.49 0 01.038.19v10.301a.496.496 0 01-.498.495h-9.96a.496.496 0 01-.498-.495v-4.017h.996v3.523h8.964V9.198h-3.237a.496.496 0 01-.498-.495V5.49H8.29v6.203h-.996V4.995c0-.273.223-.495.498-.495h6.225a.499.499 0 01.353.145l3.734 3.708a.5.5 0 01.108.16zm-4.87 4.456a.493.493 0 010 .7l-2.095 2.08a.501.501 0 01-.705 0 .493.493 0 010-.7l1.225-1.215H6.248a.497.497 0 01-.498-.495c0-.273.223-.494.498-.494h5.56l-1.225-1.217a.492.492 0 010-.7.5.5 0 01.704 0l2.055 2.041zm1.173-4.761h2.034l-2.034-2.02v2.02z' />
    </svg>
)

export default SvgExportar
