import * as React from 'react'
import { DiaSelect } from 'libs/components/presentational/input/DiaSelect'
import { WrappedFieldProps } from 'redux-form'
import field from 'libs/redux-form/field'
import * as C from 'libs/components'

interface DiaFieldProps extends Partial<WrappedFieldProps> {
    ano: number
    mes: number
    maxDia: boolean
    placeholder: string
    disabled: boolean
    maxMenuHeight: number
}

export class DiaFieldCmp extends React.Component<DiaFieldProps> {
    handleChange = (newValue: C.FixedSelectOption | C.FixedSelectOption[] | null): void => {
        this.props.input.onChange(newValue as any)
    }

    render() {
        // Remove o `onChange` do props.input (do redux-form) pois sua assinatura é incompatível
        // Tratamos o change event manualmente e enviamos ao `onChange` do redux-form via `handleChange`
        const { onChange, ...inputRest } = this.props.input

        return (
            <DiaSelect
                {...inputRest}
                onChange={this.handleChange}
                ano={this.props.ano}
                mes={this.props.mes}
                maxDia={this.props.maxDia}
                placeholder={this.props.placeholder as any}
                isDisabled={this.props.disabled}
                maxMenuHeight={this.props.maxMenuHeight}
            />
        )
    }
}

export const DiaField = field()(DiaFieldCmp)
