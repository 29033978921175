export function capitalizeWord(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1)
}

const prepositions = ['e', 'de', 'do', 'dos', 'da', 'das']

export function capitalizeName(word: string) {
    const words = word.toLowerCase().split(' ')
    const capitalizedWords = words.map(w => {
        if (prepositions.indexOf(w) >= 0) {
            return w
        }

        return w.charAt(0).toUpperCase() + w.slice(1)
    })
    return capitalizedWords.join(' ')
}
