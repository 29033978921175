import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { SumarioProcedimentoCard } from 'view/componentes/reports/cards/SumarioProcedimentoCard'
import { ModalInformacoesPaciente } from 'view/componentes/reports/paciente/ModalInformacoesPaciente'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import { SumarioPacienteDto, TipoProcedimento } from 'backend'
import * as DateUtil from 'libs/util/DateUtil'
import * as C from 'libs/components'
import { LoadingStateContainer } from 'libs/components'

interface State {
    openModal: boolean
}

export interface SumarioPacienteViewProps extends RouteComponentProps<any> {
    sumarioPaciente: PromiseRecord<SumarioPacienteDto>
    loadSumarioPaciente: () => void
    visualizarFicha: (idProcedimento: number, proced: TipoProcedimento) => void
}

export class SumarioPacienteView extends React.Component<SumarioPacienteViewProps, State> {
    constructor(props) {
        super(props)
        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.state = {
            openModal: false,
        }
    }

    componentDidMount() {
        this.props.loadSumarioPaciente()
    }

    render() {
        const paciente = this.props.sumarioPaciente.data && this.props.sumarioPaciente.data.paciente
        const procedimentos = this.props.sumarioPaciente.data && this.props.sumarioPaciente.data.procedimentos
        return (
            <LoadingStateContainer readyState={this.props.sumarioPaciente.readyState}>
                <div>
                    <HeaderContainer>
                        <div className='report-header-title'>
                            {paciente && (
                                <div className='align-center'>
                                    <C.Columns>
                                        <C.Column>
                                            <C.Label className='paciente-name'>{paciente.nome} </C.Label>
                                        </C.Column>
                                        <C.Column size='1'>
                                            <C.ComposedLabel labelClassName='paciente-label-title' title='IDADE'>
                                                <C.Label className='paciente-label-content'>
                                                    {DateUtil.getIdadePorExtenso(paciente.dataNascimento)}{' '}
                                                </C.Label>
                                            </C.ComposedLabel>
                                        </C.Column>
                                        <C.Column size='2'>
                                            <C.ComposedLabel labelClassName='paciente-label-title' title='SEXO'>
                                                <C.Label className='paciente-label-content'>{paciente.sexo} </C.Label>
                                            </C.ComposedLabel>
                                        </C.Column>
                                        <C.Column size='2'>
                                            <C.Button className='ver-cadastro' pulledRight onClick={this.open}>
                                                <p className='ver-cadastro-text'> VER CADASTRO</p>
                                            </C.Button>
                                            <ModalInformacoesPaciente
                                                active={this.state.openModal}
                                                close={this.close}
                                                paciente={paciente}
                                            />
                                        </C.Column>
                                    </C.Columns>
                                </div>
                            )}
                        </div>
                    </HeaderContainer>

                    <div className='report-content'>
                        {procedimentos &&
                            procedimentos.map((item, index) => {
                                return (
                                    <SumarioProcedimentoCard
                                        procedimento={item}
                                        key={index}
                                        index={index}
                                        onClick={() =>
                                            this.props.visualizarFicha(item.procedimentoId, item.tipoProcedimento)
                                        }
                                    />
                                )
                            })}
                    </div>
                </div>
            </LoadingStateContainer>
        )
    }

    open() {
        this.setState({ openModal: true })
    }

    close() {
        this.setState({ openModal: false })
    }
}
