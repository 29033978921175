import React from 'react'
import { Icon } from 'libs/components'

export interface InformacoesProcedimentoProps {
    procedimento: any
    open(e: any): void
}
export const InformacoesProcedimento = (props: InformacoesProcedimentoProps) => {
    let procedimentoName = 'Não informado'

    if (props.procedimento.tipoProcedimento === 'PRIMARIA_QUADRIL') {
        procedimentoName = 'PRIMÁRIA DE QUADRIL ' + props.procedimento.lado
    } else if (props.procedimento.tipoProcedimento === 'REVISAO_QUADRIL') {
        procedimentoName = 'REVISÃO DE QUADRIL ' + props.procedimento.lado
    } else if (props.procedimento.tipoProcedimento === 'PRIMARIA_JOELHO') {
        procedimentoName = 'PRIMÁRIA DE JOELHO ' + props.procedimento.lado
    } else if (props.procedimento.tipoProcedimento === 'REVISAO_JOELHO') {
        procedimentoName = 'REVISÃO DE JOELHO ' + props.procedimento.lado
    } else if (props.procedimento.tipoProcedimento === 'STENT_CORONARIA') {
        procedimentoName = 'STENT DE CORONARIA'
    }

    return (
        <div className='card-procedimento'>
            <div className='card-procedimento-info'>
                <label className='title-inf-proced is-semibold'>Profissional responsável:</label>
                <label className='text-inf-proced'>{props.procedimento.profissionalResponsavel.nome}</label>
                <label className='title-inf-proced is-semibold'>Paciente:</label>
                <label className='text-inf-proced'>{props.procedimento.paciente.nome}</label>
                <label className='procedimento'>{procedimentoName}</label>
            </div>
            <span className='inf-plus' onClick={props.open}>
                {' '}
                <Icon className='icon-inf-proced' icon='vermais' />
            </span>
        </div>
    )
}
