/* tslint:disable */
import React from 'react'

const SvgNotificacaoInformativo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <g>
            <path d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 21.828c-5.428 0-9.828-4.4-9.828-9.828 0-5.428 4.4-9.828 9.828-9.828 5.425.007 9.821 4.403 9.828 9.828 0 5.428-4.4 9.828-9.828 9.828z' />
            <path d='M11.88 9.2a1.35 1.35 0 110-2.7 1.35 1.35 0 010 2.7zm2.287 8.295h-4v-1.861h.733v-3.167h-.723v-1.862H13.434v5.029h.733v1.861z' />
        </g>
    </svg>
)

export default SvgNotificacaoInformativo
