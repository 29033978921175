import React from 'react'
import {
    CheckboxField,
    ChecklistItemField,
    CheckPanelField,
    Column,
    Columns,
    Conteudo,
    ControlField,
    InputPanel,
    Linha,
    RadioField,
} from 'libs/components'

interface PermissoesHospitaisFormSectionProps {
    disabledHospitaisEndereco: boolean
}

const PermissoesHospitaisFormSection: React.FC<PermissoesHospitaisFormSectionProps> = props => {
    return (
        <>
            <ControlField name='hospitaisSection' label={'Hospitais'} required />
            <CheckPanelField
                label='Dados dos hospitais'
                name='stHospital'
                showErrors={true}
                testid='checkbox-dados-hospitais'
            >
                <Conteudo>
                    <CheckboxField
                        name='todosDadosHospital'
                        label='Selecionar todos'
                        testid='checkbox-todos-hospital'
                    />
                    <Linha />
                    <Columns>
                        <Column size='5' verticalFlow>
                            <span className='bolder'>
                                <CheckboxField
                                    name='todosInstitucionalHospital'
                                    label='Institucional'
                                    testid='checkbox-institucional-hospital'
                                />
                            </span>

                            <div className='pl-20 is-vertical-flow'>
                                <ChecklistItemField
                                    name='dadosHospital'
                                    optionValue='RAZAO_SOCIAL'
                                    label='Razão social'
                                    testid='checkbox-razao-hospital'
                                />
                                <ChecklistItemField
                                    name='dadosHospital'
                                    optionValue='NOME_FANTASIA'
                                    label='Nome fantasia'
                                    testid='checkbox-nome-hospital'
                                />
                                <ChecklistItemField
                                    name='dadosHospital'
                                    optionValue='CNPJ'
                                    label='CNPJ do hospital'
                                    testid='checkbox-cnpj-hospital'
                                />
                            </div>
                        </Column>
                        <Column size='5' verticalFlow>
                            <span className='bolder'>
                                <CheckboxField
                                    name='enderecoHospitalAtivo'
                                    label='Endereço'
                                    testid='checkbox-endereco-hospital'
                                />
                            </span>

                            <InputPanel className='is-vertical-flow' size='normal'>
                                <span>
                                    <RadioField
                                        name='enderecoHospital'
                                        label='Estado'
                                        value='ESTADO'
                                        disabled={props.disabledHospitaisEndereco}
                                        testid='radio-endEstado-hospital'
                                    />
                                </span>
                                <span>
                                    <RadioField
                                        name='enderecoHospital'
                                        label='Estado e município'
                                        value='ESTADO_MUNICIPIO'
                                        disabled={props.disabledHospitaisEndereco}
                                        testid='radio-endMunicipio-hospital'
                                    />
                                </span>
                                <span>
                                    <RadioField
                                        name='enderecoHospital'
                                        label='Estado, município e bairro'
                                        value='ESTADO_MUNICIPIO_BAIRRO'
                                        disabled={props.disabledHospitaisEndereco}
                                        testid='radio-endBairro-hospital'
                                    />
                                </span>
                            </InputPanel>
                        </Column>
                    </Columns>
                </Conteudo>
            </CheckPanelField>
        </>
    )
}

export default PermissoesHospitaisFormSection
