import { tiposProcedimentoDescricaoByEnum } from 'api/Procedimento'
import iconPaciente from 'images/home/cidadao/ic-paciente.svg'
import * as C from 'libs/components'
import { AlertModalDelete, AlertModalError } from 'libs/components'
import React from 'react'
import { Route } from 'react-router-dom'
import PrintIcon from 'view/componentes/PrintIcon'
import HistoricoPopover from 'view/home/componentes/HistoricoPopover'
import { TabItem, TabMenu } from 'view/home/componentes/HomeMenu'
import { notFoundErrorTitle, notFoundErrorText } from 'libs/util/NotFoundErrorUtil'

class DadosClinicosCmp extends React.Component {
    constructor(props) {
        super(props)
        this.makeOk = this.makeOk.bind(this)
        this.makeOpen = this.makeOpen.bind(this)
        this.close = this.close.bind(this)
        this.loadTable = this.loadTable.bind(this)

        this.state = {
            openForm: undefined,
        }
    }

    componentDidMount() {
        this.loadTable({})
    }

    loadTable(pageable) {
        this.props.loadDadosClinicos(pageable)
    }

    render() {
        return (
            <div className='home-perfil-conteudo'>
                <C.LoadingContainer isFetching={this.props.isFetching}>
                    <span className='titulo'>Aguardando dados clínicos</span>
                    <C.PagedTable
                        name='dadoClinico'
                        addRowSpacer
                        className='table-home table-home-medico'
                        page={this.props.pendencias}
                        onChangePage={this.loadTable}
                        emptyText='Nenhuma pendência encontrada.'
                    >
                        <C.TableColumnHeader size='1' render={() => <C.Image src={iconPaciente} size='48x48' />} />
                        <C.TableColumnHeader
                            render={value => (
                                <div>
                                    <div className='is-semibold'>{value.paciente.nome}</div>
                                    <span className='procedimento'>
                                        {tiposProcedimentoDescricaoByEnum[value.tipoProcedimento]}
                                        {value.tipoProcedimento !== 'STENT_CORONARIA' && ' ' + value.lado.toLowerCase()}
                                    </span>
                                    <div>
                                        <div className='registrado-por'>
                                            {`Médico responsável: ${value.medicoNome}`}
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                        <C.TableColumnHeader
                            size='2'
                            render={value => (
                                <>
                                    {value.tecnicoId === this.props.tecnicoId && [
                                        <C.Hint
                                            key='1'
                                            placement='top'
                                            componente={
                                                <C.IconButton
                                                    icon='remover'
                                                    type='light'
                                                    pulledRight
                                                    onClick={() => this.makeOpen(value.rascunhoId)}
                                                />
                                            }
                                        >
                                            Excluir
                                        </C.Hint>,
                                        <AlertModalDelete
                                            key='2'
                                            active={this.state.openForm === value.rascunhoId}
                                            onClose={this.close}
                                            onOk={() => this.makeOk(value.rascunhoId)}
                                        />,
                                    ]}
                                    <C.Hint
                                        key='3'
                                        placement='top'
                                        componente={
                                            <C.IconButton
                                                icon='editar'
                                                type='light'
                                                pulledRight
                                                onClick={() =>
                                                    this.props.history.push(
                                                        `/home/edicaoProcedimento/preFicha/${value.rascunhoId}`
                                                    )
                                                }
                                            />
                                        }
                                    >
                                        Editar
                                    </C.Hint>
                                </>
                            )}
                        />
                    </C.PagedTable>
                </C.LoadingContainer>
            </div>
        )
    }

    makeOpen(rascunhoId) {
        this.setState({ openForm: rascunhoId })
    }

    close() {
        this.setState({ openForm: false })
    }

    makeOk(rascunhoId) {
        this.props.removerRascunho(rascunhoId)
        this.setState({ openForm: false })
    }
}

class DadosProduto extends React.Component {
    componentDidMount() {
        this.props.loadDadosProduto()
    }

    render() {
        const preencherDadosProduto = ficha => {
            return () => {
                this.props.preencherDadosProduto(ficha)
            }
        }

        const visualizarFicha = (idProced, procedimento) => {
            return () => {
                this.props.visualizarFicha(idProced, procedimento)
            }
        }

        const imprimirFicha = procedimentoId => {
            return () => {
                this.props.imprimirFicha(procedimentoId)
            }
        }

        return (
            <div className='home-perfil-conteudo'>
                <C.LoadingContainer isFetching={this.props.isFetching}>
                    <span className='titulo'>Aguardando dados de produto</span>
                    <C.Table
                        name='dadoProduto'
                        addRowSpacer
                        className='table-home table-home-medico'
                        values={this.props.pendencias}
                        emptyText='Nenhuma pendência encontrada.'
                    >
                        <C.TableColumnHeader size='1' render={() => <C.Image src={iconPaciente} size='48x48' />} />
                        <C.TableColumnHeader
                            render={value => (
                                <div>
                                    <div className='is-semibold'>{value.paciente.nome}</div>
                                    <span className='procedimento'>
                                        {tiposProcedimentoDescricaoByEnum[value.tipoProcedimento]}
                                        {value.tipoProcedimento !== 'STENT_CORONARIA' && ' ' + value.lado.toLowerCase()}
                                    </span>
                                    <span className='data-procedimento'> ({value.dataCirurgia})</span>
                                    <div>
                                        <HistoricoPopover className='registrado-por' registro={value.procedimentoId}>
                                            <C.Icon icon='historicoalteracoes' size='is-12px' />
                                            {value.profUltimaAlteracaoNome}
                                        </HistoricoPopover>
                                    </div>
                                </div>
                            )}
                        />
                        <C.TableColumnHeader
                            size='4'
                            render={value => [
                                <C.Hint
                                    key='2'
                                    placement='top'
                                    componente={
                                        <C.IconButton
                                            icon='detalhes'
                                            pulledRight
                                            type='light'
                                            onClick={visualizarFicha(value.procedimentoId, value.tipoProcedimento)}
                                        />
                                    }
                                >
                                    Visualizar registro
                                </C.Hint>,
                                <PrintIcon key='3' onClick={imprimirFicha(value.procedimentoId)} />,
                                <C.Hint
                                    key='1'
                                    placement='top'
                                    componente={
                                        <C.Button
                                            className='preencher'
                                            pulledRight
                                            icon='dadosproduto'
                                            onClick={preencherDadosProduto(value)}
                                        >
                                            Preencher
                                        </C.Button>
                                    }
                                >
                                    Preencher dados de produtos
                                </C.Hint>,
                            ]}
                        />
                    </C.Table>
                </C.LoadingContainer>
            </div>
        )
    }
}

class DadosFinanceiros extends React.Component {
    constructor() {
        super()

        this.loadTable = this.loadTable.bind(this)
    }

    componentDidMount() {
        this.loadTable({})
    }

    loadTable(pageable) {
        this.props.loadDadosFinanceiro(pageable)
    }

    render() {
        const editarDadosProduto = ficha => {
            return () => {
                this.props.editarDadosProduto(ficha)
            }
        }

        const visualizarFicha = (idProced, procedimento) => {
            return () => {
                this.props.visualizarFicha(idProced, procedimento)
            }
        }

        const imprimirFicha = procedimentoId => {
            return () => {
                this.props.imprimirFicha(procedimentoId)
            }
        }

        return (
            <div className='home-perfil-conteudo'>
                <C.LoadingContainer isFetching={this.props.isFetching}>
                    <span className='titulo'>Aguardando dados financeiros</span>
                    <C.PagedTable
                        addRowSpacer
                        className='table-home table-home-medico'
                        page={this.props.pendencias}
                        onChangePage={this.loadTable}
                        emptyText='Nenhuma pendência encontrada.'
                    >
                        <C.TableColumnHeader size='1' render={() => <C.Image src={iconPaciente} size='48x48' />} />
                        <C.TableColumnHeader
                            render={value => (
                                <div>
                                    <div className='is-semibold'>{value.paciente.nome}</div>
                                    <span className='procedimento'>
                                        {tiposProcedimentoDescricaoByEnum[value.tipoProcedimento]}
                                        {value.tipoProcedimento !== 'STENT_CORONARIA' && ' ' + value.lado.toLowerCase()}
                                    </span>
                                    <span className='data-procedimento'> ({value.dataCirurgia})</span>
                                    <div>
                                        <HistoricoPopover className='registrado-por' registro={value.procedimentoId}>
                                            <C.Icon icon='historicoalteracoes' size='is-12px' />
                                            {value.profUltimaAlteracaoNome}
                                        </HistoricoPopover>
                                    </div>
                                </div>
                            )}
                        />
                        <C.TableColumnHeader
                            size='3'
                            render={value => [
                                !value.origemExterna && (
                                    <C.Hint
                                        key='1'
                                        placement='top'
                                        componente={
                                            <C.IconButton
                                                icon='editar'
                                                type='light'
                                                pulledRight
                                                onClick={editarDadosProduto(value)}
                                            />
                                        }
                                    >
                                        Editar
                                    </C.Hint>
                                ),
                                <C.Hint
                                    key='2'
                                    placement='top'
                                    componente={
                                        <C.IconButton
                                            icon='detalhes'
                                            pulledRight
                                            type='light'
                                            onClick={visualizarFicha(value.procedimentoId, value.tipoProcedimento)}
                                        />
                                    }
                                >
                                    Visualizar registro
                                </C.Hint>,
                                <PrintIcon key='3' onClick={imprimirFicha(value.procedimentoId)} />,
                            ]}
                        />
                    </C.PagedTable>
                </C.LoadingContainer>
            </div>
        )
    }
}

class DadosHistorico extends React.Component {
    constructor() {
        super()

        this.loadTable = this.loadTable.bind(this)
    }

    componentDidMount() {
        this.loadTable({})
    }

    loadTable(pageable) {
        this.props.loadDadosHistorico(pageable)
    }

    render() {
        const visualizarFicha = (idProced, procedimento) => {
            return () => {
                this.props.visualizarFicha(idProced, procedimento)
            }
        }

        const imprimirFicha = procedimentoId => {
            return () => {
                this.props.imprimirFicha(procedimentoId)
            }
        }

        return (
            <div className='home-perfil-conteudo'>
                <C.LoadingContainer isFetching={this.props.isFetching}>
                    <span className='titulo'>Histórico</span>

                    <C.PagedTable
                        addRowSpacer
                        className='table-home table-home-medico'
                        page={this.props.pendencias}
                        onChangePage={this.loadTable}
                        emptyText='Nenhum procedimento finalizado.'
                    >
                        <C.TableColumnHeader size='1' render={() => <C.Image src={iconPaciente} size='48x48' />} />
                        <C.TableColumnHeader
                            render={value => (
                                <div>
                                    <div className='is-semibold'>{value.paciente.nome}</div>
                                    <span className='procedimento'>
                                        {tiposProcedimentoDescricaoByEnum[value.tipoProcedimento]}
                                        {value.tipoProcedimento !== 'STENT_CORONARIA' && ' ' + value.lado.toLowerCase()}
                                    </span>
                                    <span className='data-procedimento'> ({value.dataCirurgia})</span>
                                    <div>
                                        <HistoricoPopover className='registrado-por' registro={value.procedimentoId}>
                                            <C.Icon icon='historicoalteracoes' size='is-12px' />
                                            {value.profUltimaAlteracaoNome}
                                        </HistoricoPopover>
                                    </div>
                                </div>
                            )}
                        />
                        <C.TableColumnHeader
                            size='2'
                            render={value => [
                                <C.Hint
                                    key='1'
                                    placement='top'
                                    componente={
                                        <C.IconButton
                                            icon='detalhes'
                                            pulledRight
                                            type='light'
                                            onClick={visualizarFicha(value.procedimentoId, value.tipoProcedimento)}
                                        />
                                    }
                                >
                                    Visualizar registro
                                </C.Hint>,
                                <PrintIcon key='2' onClick={imprimirFicha(value.procedimentoId)} />,
                            ]}
                        />
                    </C.PagedTable>
                </C.LoadingContainer>
            </div>
        )
    }
}

export default class HomeTecnicoView extends React.Component {
    render() {
        return (
            <>
                <AlertModalError
                    active={this.props.showNotFoundModalError}
                    title={notFoundErrorTitle}
                    onClose={this.props.onCloseNotFoundModal}
                >
                    {notFoundErrorText}
                </AlertModalError>
                <div className='home-perfil-container is-vertical-flow is-full-height has-text-centered'>
                    <C.Columns fullHeight>
                        <C.Column verticalFlow size='2'>
                            <TabMenu>
                                <TabItem icon='dadosproduto' path={'/home'}>
                                    Aguardando dados de produto
                                </TabItem>
                                <TabItem icon='dadosclinicos' path={'/home/aguardandoDadosClinicos'}>
                                    Aguardando dados clínicos
                                </TabItem>
                                <TabItem icon='dadosfinanceiros' path={'/home/aguardandoDadosFinanceiros'}>
                                    Aguardando dados financeiros
                                </TabItem>
                                <TabItem icon='historico' path={'/home/historico'}>
                                    Histórico
                                </TabItem>
                            </TabMenu>
                        </C.Column>
                        <C.Column offset='1' size='8'>
                            <C.Button
                                className='btn-principal'
                                size='medium'
                                shadow
                                type='primary'
                                onClick={this.props.onSelecionarNovoProcedimento}
                            >
                                Registrar novo procedimento
                            </C.Button>
                            <Route
                                exact
                                path={'/home/aguardandoDadosClinicos'}
                                render={routeProps => {
                                    return (
                                        <DadosClinicosCmp
                                            pendencias={this.props.aguardandoDadosClinicos}
                                            isFetching={this.props.isFetchingAguardandoDadosClinicos}
                                            removerRascunho={this.props.removerRascunho}
                                            loadDadosClinicos={this.props.loadDadosClinicos}
                                            tecnicoId={this.props.tecnicoId}
                                            {...routeProps}
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={'/home'}
                                render={() => {
                                    return (
                                        <DadosProduto
                                            loadDadosProduto={this.props.loadDadosProduto}
                                            preencherDadosProduto={this.props.preencherDadosProduto}
                                            pendencias={this.props.aguardandoDadosProduto}
                                            isFetching={this.props.isFetchingAguardandoDadosProduto}
                                            visualizarFicha={this.props.visualizarFicha}
                                            imprimirFicha={this.props.imprimirFicha}
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={'/home/aguardandoDadosFinanceiros'}
                                render={routeProps => {
                                    return (
                                        <DadosFinanceiros
                                            editarDadosProduto={this.props.editarDadosProduto}
                                            loadDadosFinanceiro={this.props.loadDadosFinanceiro}
                                            pendencias={this.props.aguardandoDadosFinanceiros}
                                            isFetching={this.props.isFetchingAguardandoDadosFinanceiros}
                                            visualizarFicha={this.props.visualizarFicha}
                                            imprimirFicha={this.props.imprimirFicha}
                                            {...routeProps}
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={'/home/historico'}
                                render={() => {
                                    return (
                                        <DadosHistorico
                                            loadDadosHistorico={this.props.loadDadosHistorico}
                                            pendencias={this.props.historico}
                                            isFetching={this.props.isFetchingHistorico}
                                            loadDadosHistoricoDetail={this.props.loadDadosHistoricoDetail}
                                            visualizarFicha={this.props.visualizarFicha}
                                            imprimirFicha={this.props.imprimirFicha}
                                        />
                                    )
                                }}
                            />
                        </C.Column>
                    </C.Columns>
                </div>
            </>
        )
    }
}
