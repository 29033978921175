import React, { useCallback, PropsWithChildren } from 'react'

export interface NivelSatisfacao {
    id: number
    title: string
}

interface SvgIconProps {
    nivel: NivelSatisfacao
    active?: boolean
    error?: boolean
    onClick(nivelSatisfacao: NivelSatisfacao): void
}

const SvgIcon = ({ error, active, nivel, onClick, children }: PropsWithChildren<SvgIconProps>) => {
    const handleActive = useCallback(() => onClick(nivel), [nivel, onClick])
    return (
        <button
            title={nivel.title}
            className={`seletor-satisfacao-icons-icon ${active ? 'seletor-satisfacao-icons-icon-active' : ''} ${
                error ? 'seletor-satisfacao-icons-icon-error' : ''
            }`}
            onClick={handleActive}
        >
            {children}
        </button>
    )
}

export type SeletorSatisfacaoIconProps = SvgIconProps

const SvgSatisfeito = (props: SeletorSatisfacaoIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
            <g fill='none' fillRule='evenodd'>
                <circle cx='14' cy='14' r='14' fill='#F3F3F3' />
                <path
                    fill='#595959'
                    d='M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm0 1C6.82 1 1 6.82 1 14s5.82 13 13 13 13-5.82 13-13S21.18 1 14 1zM8.995 17.051c.849 1.795 2.805 2.99 5.01 2.99 2.197 0 4.147-1.185 5-2.97a.42.42 0 1 1 .758.363c-.996 2.081-3.243 3.448-5.758 3.448-2.525 0-4.78-1.378-5.77-3.472a.42.42 0 0 1 .76-.359zM9.1 8.4c.773 0 1.4.784 1.4 1.75s-.627 1.75-1.4 1.75c-.773 0-1.4-.784-1.4-1.75S8.327 8.4 9.1 8.4zm9.8 0c.773 0 1.4.784 1.4 1.75s-.627 1.75-1.4 1.75c-.773 0-1.4-.784-1.4-1.75s.627-1.75 1.4-1.75z'
                />
            </g>
        </svg>
    </SvgIcon>
)

const SvgMuitoSatisfeito = (props: SeletorSatisfacaoIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
            <g fill='none' fillRule='evenodd'>
                <circle cx='14' cy='14' r='14' fill='#F3F3F3' />
                <path
                    fill='#595959'
                    fillRule='nonzero'
                    d='M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm0 .875C6.751.875.875 6.751.875 14S6.751 27.125 14 27.125 27.125 21.249 27.125 14 21.249.875 14 .875zm6.462 14.451a.42.42 0 0 1 .42.42c0 3.225-3.097 5.805-6.882 5.805-3.785 0-6.882-2.58-6.882-5.805a.42.42 0 0 1 .345-.413l.075-.007h12.924zm-.442.84H7.98l.02.157c.347 2.456 2.888 4.388 6 4.388 3.178 0 5.76-2.015 6.02-4.545zm-9.558-7.901l.066.053 2.284 2.285a.42.42 0 0 1-.528.648l-.066-.054-1.987-1.988-1.988 1.988a.42.42 0 0 1-.648-.528l.054-.066 2.285-2.285a.42.42 0 0 1 .528-.053zm7.538 0l.066.053 2.285 2.285a.42.42 0 0 1-.528.648l-.066-.054-1.988-1.988-1.987 1.988a.42.42 0 0 1-.648-.528l.054-.066 2.284-2.285A.42.42 0 0 1 18 8.265z'
                />
            </g>
        </svg>
    </SvgIcon>
)

const SvgIndiferente = (props: SeletorSatisfacaoIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
            <g fill='none' fillRule='evenodd'>
                <circle cx='14' cy='14' r='14' fill='#F3F3F3' />
                <path
                    fill='#595959'
                    d='M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm0 1C6.82 1 1 6.82 1 14s5.82 13 13 13 13-5.82 13-13S21.18 1 14 1zM8.515 17.5h10.97c.45 0 .815.224.815.5 0 .251-.302.459-.695.495l-.12.005H8.515c-.45 0-.815-.224-.815-.5 0-.251.302-.459.695-.495l.12-.005h10.97zM9.1 8.4c.773 0 1.4.784 1.4 1.75s-.627 1.75-1.4 1.75c-.773 0-1.4-.784-1.4-1.75S8.327 8.4 9.1 8.4zm9.8 0c.773 0 1.4.784 1.4 1.75s-.627 1.75-1.4 1.75c-.773 0-1.4-.784-1.4-1.75s.627-1.75 1.4-1.75z'
                />
            </g>
        </svg>
    </SvgIcon>
)

const SvgInsatisfeito = (props: SeletorSatisfacaoIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
            <g fill='none' fillRule='evenodd'>
                <circle cx='14' cy='14' r='14' fill='#F3F3F3' />
                <path
                    fill='#595959'
                    d='M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm0 1C6.82 1 1 6.82 1 14s5.82 13 13 13 13-5.82 13-13S21.18 1 14 1zm.005 14.734c2.515 0 4.762 1.366 5.758 3.447a.42.42 0 0 1-.757.363c-.854-1.784-2.804-2.97-5-2.97-2.206 0-4.162 1.195-5.01 2.99a.42.42 0 1 1-.76-.359c.99-2.094 3.244-3.471 5.77-3.471zM9.1 8.4c.773 0 1.4.784 1.4 1.75s-.627 1.75-1.4 1.75c-.773 0-1.4-.784-1.4-1.75S8.327 8.4 9.1 8.4zm9.8 0c.773 0 1.4.784 1.4 1.75s-.627 1.75-1.4 1.75c-.773 0-1.4-.784-1.4-1.75s.627-1.75 1.4-1.75z'
                />
            </g>
        </svg>
    </SvgIcon>
)

const SvgMuitoInsatisfeito = (props: SeletorSatisfacaoIconProps) => (
    <SvgIcon {...props}>
        <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
            <g fill='none' fillRule='evenodd'>
                <circle cx='14' cy='14' r='13.067' fill='#F3F3F3' />
                <path
                    fill='#595959'
                    d='M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm0 1C6.82 1 1 6.82 1 14s5.82 13 13 13 13-5.82 13-13S21.18 1 14 1zm0 14.734a5.805 5.805 0 0 1 5.323 3.487.42.42 0 1 1-.77.335 4.965 4.965 0 0 0-9.115.02.42.42 0 0 1-.772-.332A5.805 5.805 0 0 1 14 15.734zm-7.657-7.04l4.419 1.183a.35.35 0 1 1-.182.676l-.485-.13c.25.38.405.901.405 1.477 0 1.16-.627 2.1-1.4 2.1-.773 0-1.4-.94-1.4-2.1 0-.842.33-1.569.808-1.903l-2.346-.628a.35.35 0 1 1 .18-.676zm15.728.317a.42.42 0 0 1-.218.484l-.08.03-2.15.576c.406.368.677 1.036.677 1.799 0 1.16-.627 2.1-1.4 2.1-.773 0-1.4-.94-1.4-2.1 0-.497.115-.954.308-1.313l-.318.086a.42.42 0 0 1-.297-.781l.08-.03 4.283-1.148a.42.42 0 0 1 .515.297z'
                />
            </g>
        </svg>
    </SvgIcon>
)

export const NiveisSatisfacaoIcons = [
    SvgMuitoInsatisfeito,
    SvgInsatisfeito,
    SvgIndiferente,
    SvgSatisfeito,
    SvgMuitoSatisfeito,
]
