/* tslint:disable */
import React from 'react'

const SvgErroExclamacao = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 3 17' {...props}>
        <path d='M1.4 13.886c-.77 0-1.4.648-1.4 1.457C0 16.15.63 16.8 1.4 16.8c.77 0 1.4-.649 1.4-1.457 0-.809-.63-1.457-1.4-1.457zM1.4 0C.907 0 .5.418.5.943V9.17c0 .525.407.943.9.943.493 0 .9-.418.9-.943V.943C2.3.418 1.893 0 1.4 0z' />
    </svg>
)

export default SvgErroExclamacao
