import * as React from 'react'
import { WrappedFieldProps } from 'redux-form'
import * as C from 'libs/components'
import field from 'libs/redux-form/field'
import { isImmutable } from 'immutable'

export type SimpleSelectFieldOption = C.FixedSelectOption

export interface SimpleSelectFieldProps extends C.SimpleSelectProps, Partial<WrappedFieldProps> {}

export class SimpleSelectFieldCmp extends React.Component<SimpleSelectFieldProps> {
    handleChange = (newValue: SimpleSelectFieldOption | SimpleSelectFieldOption[] | null): void => {
        this.props.input.onChange(newValue as any)
    }

    render() {
        // Remove o `onChange` do props.input (do redux-form) pois sua assinatura é incompatível
        // Tratamos o change event manualmente e enviamos ao `onChange` do redux-form via `handleChange`
        const { onChange, ...inputRest } = this.props.input

        return (
            <C.SimpleSelect
                {...inputRest}
                value={isImmutable(this.props.input.value) ? this.props.input.value.toJS() : this.props.input.value}
                onChange={this.handleChange}
                {...this.props}
            />
        )
    }
}

export const SimpleSelectField = field()(SimpleSelectFieldCmp)
