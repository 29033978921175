import * as React from 'react'
import { Overlay } from 'react-overlays'
import classnames from 'classnames'

export type Placement = 'top' | 'right' | 'bottom' | 'left'

export interface PopoverProps {
    className?: string
    color?: string
    onHide?: any
    placement?: Placement
    rootClose?: boolean
    show?: boolean
    target?: any
    popperConfig?: object
    container?: any
}

const defaultPopperConfig = {
    modifiers: {
        preventOverflow: {
            boundariesElement: 'window',
        },
    },
}

export class Popover extends React.Component<PopoverProps> {
    render() {
        const { className, color, popperConfig, ...rest } = this.props
        const mergedPopperConfig = Object.assign(defaultPopperConfig, popperConfig)
        return (
            <Overlay {...rest} popperConfig={mergedPopperConfig}>
                {({ props, placement }) => {
                    const classes = classnames('popover', className, placement, {
                        grey: color && color === 'grey',
                        'dark-grey': color && color === 'dark-grey',
                    })
                    return (
                        <div {...props} className={classes}>
                            <div className='popover-content'>{this.props.children}</div>
                        </div>
                    )
                }}
            </Overlay>
        )
    }
}
