import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import immutableProxy, { ImmutableRootProxy } from 'libs/immutableProxy'
import { CadastroEntidadeConsumidoraForm as _CadastroEntidadeConsumidoraForm } from 'backend'
import { isImmutable } from 'immutable'

interface CadastroEntidadeConsumidoraForm extends _CadastroEntidadeConsumidoraForm {
    esferaMunicipalUf: string
    esferaMunicipalCidade: string
    esferaHospitalUf: string
    esferaHospitalCidade: string
    esferaHospitalHospital: string
}

function allCheckboxHaveBeenFilled(field, qtdCheckboxs: number): boolean {
    const fieldGet: any = field.get()

    return isImmutable(field) && typeof fieldGet?.toJSON === 'function'
        ? fieldGet?.toJSON()?.length === qtdCheckboxs
        : fieldGet?.length === qtdCheckboxs
}

const validaEsferasAcesso = (mutable: ImmutableRootProxy<FormState<CadastroEntidadeConsumidoraForm>>, action) => {
    // Esferas de acesso
    // Unidade Federativa
    if (action.meta?.field === 'todosUfs') {
        if (mutable.values.todosUfs.get()) {
            mutable.values.todosEstadosNordeste.set(true)
            mutable.values.todosEstadosNorte.set(true)
            mutable.values.todosEstadosCentroOeste.set(true)
            mutable.values.todosEstadosSudeste.set(true)
            mutable.values.todosEstadosSul.set(true)
        } else {
            mutable.values.todosEstadosNordeste.set(false)
            mutable.values.todosEstadosNorte.set(false)
            mutable.values.todosEstadosCentroOeste.set(false)
            mutable.values.todosEstadosSudeste.set(false)
            mutable.values.todosEstadosSul.set(false)
        }
    }

    if (action.meta?.field === 'todosEstadosNordeste' || action.meta?.field === 'todosUfs') {
        const ufsAcesso = new Set(mutable.values.ufsAcesso.get())

        if (mutable.values.todosEstadosNordeste.get()) {
            ufsAcesso.add('AL')
            ufsAcesso.add('BA')
            ufsAcesso.add('CE')
            ufsAcesso.add('MA')
            ufsAcesso.add('PE')
            ufsAcesso.add('PI')
            ufsAcesso.add('PB')
            ufsAcesso.add('RN')
            ufsAcesso.add('SE')
        } else {
            ufsAcesso.delete('AL')
            ufsAcesso.delete('BA')
            ufsAcesso.delete('CE')
            ufsAcesso.delete('MA')
            ufsAcesso.delete('PE')
            ufsAcesso.delete('PI')
            ufsAcesso.delete('PB')
            ufsAcesso.delete('RN')
            ufsAcesso.delete('SE')
        }

        mutable.values.ufsAcesso.set(Array.from(ufsAcesso))
    }

    if (action.meta?.field === 'todosEstadosNorte' || action.meta?.field === 'todosUfs') {
        const ufsAcesso = new Set(mutable.values.ufsAcesso.get())

        if (mutable.values.todosEstadosNorte.get()) {
            ufsAcesso.add('AC')
            ufsAcesso.add('AM')
            ufsAcesso.add('AP')
            ufsAcesso.add('PA')
            ufsAcesso.add('RO')
            ufsAcesso.add('RR')
            ufsAcesso.add('TO')
        } else {
            ufsAcesso.delete('AC')
            ufsAcesso.delete('AM')
            ufsAcesso.delete('AP')
            ufsAcesso.delete('PA')
            ufsAcesso.delete('RO')
            ufsAcesso.delete('RR')
            ufsAcesso.delete('TO')
        }

        mutable.values.ufsAcesso.set(Array.from(ufsAcesso))
    }

    if (action.meta?.field === 'todosEstadosCentroOeste' || action.meta?.field === 'todosUfs') {
        const ufsAcesso = new Set(mutable.values.ufsAcesso.get())

        if (mutable.values.todosEstadosCentroOeste.get()) {
            ufsAcesso.add('DF')
            ufsAcesso.add('GO')
            ufsAcesso.add('MT')
            ufsAcesso.add('MS')
        } else {
            ufsAcesso.delete('DF')
            ufsAcesso.delete('GO')
            ufsAcesso.delete('MT')
            ufsAcesso.delete('MS')
        }

        mutable.values.ufsAcesso.set(Array.from(ufsAcesso))
    }

    if (action.meta?.field === 'todosEstadosSudeste' || action.meta?.field === 'todosUfs') {
        const ufsAcesso = new Set(mutable.values.ufsAcesso.get())

        if (mutable.values.todosEstadosSudeste.get()) {
            ufsAcesso.add('ES')
            ufsAcesso.add('MG')
            ufsAcesso.add('RJ')
            ufsAcesso.add('SP')
        } else {
            ufsAcesso.delete('ES')
            ufsAcesso.delete('MG')
            ufsAcesso.delete('RJ')
            ufsAcesso.delete('SP')
        }

        mutable.values.ufsAcesso.set(Array.from(ufsAcesso))
    }

    if (action.meta?.field === 'todosEstadosSul' || action.meta?.field === 'todosUfs') {
        const ufsAcesso = new Set(mutable.values.ufsAcesso.get())

        if (mutable.values.todosEstadosSul.get()) {
            ufsAcesso.add('PR')
            ufsAcesso.add('RS')
            ufsAcesso.add('SC')
        } else {
            ufsAcesso.delete('PR')
            ufsAcesso.delete('RS')
            ufsAcesso.delete('SC')
        }

        mutable.values.ufsAcesso.set(Array.from(ufsAcesso))
    }

    /**
     * Faz lógica para seleção de controladores pais para verificar se todos os estados foram ou não preenchidos
     */
    const ufsAcessoChecked = new Set(mutable.values.ufsAcesso.get())

    const hasAllNordesteChecked =
        ufsAcessoChecked.has('AL') &&
        ufsAcessoChecked.has('BA') &&
        ufsAcessoChecked.has('CE') &&
        ufsAcessoChecked.has('MA') &&
        ufsAcessoChecked.has('PE') &&
        ufsAcessoChecked.has('PI') &&
        ufsAcessoChecked.has('PB') &&
        ufsAcessoChecked.has('RN') &&
        ufsAcessoChecked.has('SE')

    const hasAllNorteChecked =
        ufsAcessoChecked.has('AC') &&
        ufsAcessoChecked.has('AM') &&
        ufsAcessoChecked.has('AP') &&
        ufsAcessoChecked.has('PA') &&
        ufsAcessoChecked.has('RO') &&
        ufsAcessoChecked.has('RR') &&
        ufsAcessoChecked.has('TO')

    const hasAllCentroOesteChecked =
        ufsAcessoChecked.has('DF') &&
        ufsAcessoChecked.has('GO') &&
        ufsAcessoChecked.has('MT') &&
        ufsAcessoChecked.has('MS')

    const hasAllSudesteChecked =
        ufsAcessoChecked.has('ES') &&
        ufsAcessoChecked.has('MG') &&
        ufsAcessoChecked.has('RJ') &&
        ufsAcessoChecked.has('SP')

    const hasAllSulChecked = ufsAcessoChecked.has('PR') && ufsAcessoChecked.has('RS') && ufsAcessoChecked.has('SC')

    const hasAllChecked =
        hasAllNordesteChecked &&
        hasAllNorteChecked &&
        hasAllCentroOesteChecked &&
        hasAllSudesteChecked &&
        hasAllSulChecked

    mutable.values.todosUfs.set(hasAllChecked)

    if (
        action.meta?.field !== 'todosEstadosNordeste' &&
        action.meta?.field !== 'todosEstadosNorte' &&
        action.meta?.field !== 'todosEstadosCentroOeste' &&
        action.meta?.field !== 'todosEstadosSudeste' &&
        action.meta?.field !== 'todosEstadosSul'
    ) {
        mutable.values.todosEstadosNordeste.set(hasAllNordesteChecked)
        mutable.values.todosEstadosNorte.set(hasAllNorteChecked)
        mutable.values.todosEstadosCentroOeste.set(hasAllCentroOesteChecked)
        mutable.values.todosEstadosSudeste.set(hasAllSudesteChecked)
        mutable.values.todosEstadosSul.set(hasAllSulChecked)
    }

    /**
     * Limpa os dados dos panels caso eles não estejam ativos
     */
    if (mutable.values.esferaAcesso.get() !== 'ESTADUAL') {
        mutable.values.ufsAcesso.set([])
        mutable.values.todosUfs.set(false)
        mutable.values.todosEstadosNordeste.set(false)
        mutable.values.todosEstadosNorte.set(false)
        mutable.values.todosEstadosCentroOeste.set(false)
        mutable.values.todosEstadosSudeste.set(false)
        mutable.values.todosEstadosSul.set(false)
    }

    if (mutable.values.esferaAcesso.get() !== 'MUNICIPAL') {
        mutable.values.esferaMunicipalUf.set(null)
        mutable.values.esferaMunicipalCidade.set(null)
        mutable.values.municipiosAcesso.set([])
    }

    if (mutable.values.esferaAcesso.get() !== 'HOSPITAL') {
        mutable.values.esferaHospitalUf.set(null)
        mutable.values.esferaHospitalCidade.set(null)
        mutable.values.esferaHospitalHospital.set(null)
        mutable.values.hospitaisAcesso.set([])
    }

    return mutable
}

const validaDadosHospitais = (mutable: ImmutableRootProxy<FormState<CadastroEntidadeConsumidoraForm>>, action) => {
    // Dados hospitais
    if (action.meta?.field === 'todosDadosHospital') {
        if (mutable.values.todosDadosHospital.get()) {
            mutable.values.todosInstitucionalHospital.set(true)
            mutable.values.enderecoHospitalAtivo.set(true)
        } else {
            mutable.values.todosInstitucionalHospital.set(false)
            mutable.values.dadosHospital.set([])
            mutable.values.enderecoHospitalAtivo.set(false)
        }
    }

    if (action.meta?.field === 'todosInstitucionalHospital' || action.meta?.field === 'todosDadosHospital') {
        if (mutable.values.todosInstitucionalHospital.get()) {
            mutable.values.dadosHospital.set(['RAZAO_SOCIAL', 'NOME_FANTASIA', 'CNPJ'])
        } else {
            mutable.values.dadosHospital.set([])
        }
    }

    /**
     * Lógica para seleção dos checkboxs pais
     */
    const hasAllInstitucionalChecked = allCheckboxHaveBeenFilled(mutable.values.dadosHospital, 3)

    const hasAllChecked = hasAllInstitucionalChecked && mutable.values.enderecoHospitalAtivo.get()

    mutable.values.todosDadosHospital.set(hasAllChecked)

    if (action.meta?.field !== 'todosInstitucionalHospital') {
        mutable.values.todosInstitucionalHospital.set(hasAllInstitucionalChecked)
    }

    /**
     * Limpa dados se desativar seleção dados de hospitais
     */
    if (!mutable.values.stHospital.get()) {
        mutable.values.todosDadosHospital.set(false)
        mutable.values.todosInstitucionalHospital.set(false)
        mutable.values.enderecoHospitalAtivo.set(false)
        mutable.values.dadosHospital.set([])
    }

    if (!mutable.values.enderecoHospitalAtivo.get()) {
        mutable.values.enderecoHospital.delete()
    }

    return mutable
}

const validaDadosPacientes = (mutable: ImmutableRootProxy<FormState<CadastroEntidadeConsumidoraForm>>, action) => {
    // Dados pacientes
    if (action.meta?.field === 'todosDadosPaciente') {
        if (mutable.values.todosDadosPaciente.get()) {
            mutable.values.todosDadosPacientePessoais.set(true)
            mutable.values.enderecoPacienteAtivo.set(true)
        } else {
            mutable.values.todosDadosPacientePessoais.set(false)
            mutable.values.dadosPaciente.set([])
            mutable.values.enderecoPacienteAtivo.set(false)
        }
    }

    if (action.meta?.field === 'todosDadosPacientePessoais' || action.meta?.field === 'todosDadosPaciente') {
        if (mutable.values.todosDadosPacientePessoais.get()) {
            mutable.values.dadosPaciente.set(['NOME_COMPLETO', 'DATA_NASCIMENTO', 'SEXO', 'CPF', 'CNS'])
        } else {
            mutable.values.dadosPaciente.set([])
        }
    }

    /**
     * Lógica para seleção dos checkboxs pais
     */
    const hasAllPessoalChecked = allCheckboxHaveBeenFilled(mutable.values.dadosPaciente, 5)

    const hasAllChecked = hasAllPessoalChecked && mutable.values.enderecoPacienteAtivo.get()

    mutable.values.todosDadosPaciente.set(hasAllChecked)

    if (action.meta?.field !== 'todosDadosPacientePessoais') {
        mutable.values.todosDadosPacientePessoais.set(hasAllPessoalChecked)
    }

    /**
     * Limpa dados se desativar seleção dados de pacientes
     */
    if (!mutable.values.stPaciente.get()) {
        mutable.values.todosDadosPaciente.set(false)
        mutable.values.todosDadosPacientePessoais.set(false)
        mutable.values.dadosPaciente.set([])
        mutable.values.enderecoPacienteAtivo.set(false)
    }

    if (!mutable.values.enderecoPacienteAtivo.get()) {
        mutable.values.enderecoPaciente.delete()
    }

    return mutable
}

const validaDadosMedicos = (mutable: ImmutableRootProxy<FormState<CadastroEntidadeConsumidoraForm>>, action) => {
    // Dados médicos
    if (action.meta?.field === 'todosDadosMedicos') {
        if (mutable.values.todosDadosMedicos.get()) {
            mutable.values.todosDadosMedicosPessoais.set(true)
            mutable.values.enderecoMedicoAtivo.set(true)
        } else {
            mutable.values.todosDadosMedicosPessoais.set(false)
            mutable.values.dadosMedicos.set([])
            mutable.values.enderecoMedicoAtivo.set(false)
        }
    }

    if (action.meta?.field === 'todosDadosMedicosPessoais' || action.meta?.field === 'todosDadosMedicos') {
        if (mutable.values.todosDadosMedicosPessoais.get()) {
            mutable.values.dadosMedicos.set(['NOME_COMPLETO', 'DATA_NASCIMENTO', 'SEXO', 'CPF', 'CRM', 'ESPECIALIDADE'])
        } else {
            mutable.values.dadosMedicos.set([])
        }
    }

    /**
     * Lógica para seleção dos checkboxs pais
     */
    const hasAllPessoalChecked = allCheckboxHaveBeenFilled(mutable.values.dadosMedicos, 6)

    const hasAllChecked = hasAllPessoalChecked && mutable.values.enderecoMedicoAtivo.get()

    mutable.values.todosDadosMedicos.set(hasAllChecked)

    if (action.meta?.field !== 'todosDadosMedicosPessoais') {
        mutable.values.todosDadosMedicosPessoais.set(hasAllPessoalChecked)
    }

    /**
     * Limpa dados se desativar seleção dados de medicos
     */
    if (!mutable.values.stMedicos.get()) {
        mutable.values.todosDadosMedicos.set(false)
        mutable.values.todosDadosMedicosPessoais.set(false)
        mutable.values.dadosMedicos.set([])
        mutable.values.enderecoMedicoAtivo.set(false)
    }

    if (!mutable.values.enderecoMedicoAtivo.get()) {
        mutable.values.enderecoMedicos.delete()
    }

    return mutable
}

const validaDadosProcedimento = (mutable: ImmutableRootProxy<FormState<CadastroEntidadeConsumidoraForm>>, action) => {
    // Dados do procedimento
    /**
     * Angioplastia
     */
    if (action.meta?.field === 'todosDadosAngioplastia') {
        if (mutable.values.todosDadosAngioplastia.get()) {
            mutable.values.dadosAngioStent.set([
                'DADOS_DO_PROCEDIMENTO',
                'LESAO_TRATADA',
                'STENTS_UTILIZADOS',
                'APRESENTACAO_CLINICA',
                'TESTE_ISQUEMIA_IMAGEM',
                'ANTECEDENTES',
                'ICP_COMPLEMENTAR',
                'ACESSO_ARTERIAL_CONTRASTE',
                'MEDICACAO_PROCEDIMENTO',
                'CONCLUSAO',
                'COMPLICACOES_CLINICAS',
            ])
        } else {
            mutable.values.dadosAngioStent.set([])
        }
    }

    // Lógica para seleção dos checkboxs pais
    const hasAllAngioplastiaChecked = allCheckboxHaveBeenFilled(mutable.values.dadosAngioStent, 11)
    mutable.values.todosDadosAngioplastia.set(hasAllAngioplastiaChecked)

    // Limpa dados se desativar seleção dados de angioplasia
    if (!mutable.values.stAngioStent.get()) {
        mutable.values.dadosAngioStent.set([])
        mutable.values.todosDadosAngioplastia.set(false)
    }

    /**
     * Artroplastia de joelho
     */
    if (action.meta?.field === 'todosDadosArtroJoelho') {
        if (mutable.values.todosDadosArtroJoelho.get()) {
            mutable.values.todosDadosPrimariaJoelho.set(true)
            mutable.values.todosDadosRevisaoJoelho.set(true)
        } else {
            mutable.values.todosDadosPrimariaJoelho.set(false)
            mutable.values.todosDadosRevisaoJoelho.set(false)
            mutable.values.dadosPrimariaJoelho.set([])
            mutable.values.dadosRevisaoJoelho.set([])
        }
    }

    if (action.meta?.field === 'todosDadosPrimariaJoelho' || action.meta?.field === 'todosDadosArtroJoelho') {
        if (mutable.values.todosDadosPrimariaJoelho.get()) {
            mutable.values.dadosPrimariaJoelho.set([
                'DIAGNOSTICOS',
                'PROCEDIMENTO_CIRURGICO_PREVIO',
                'PROCEDIMENTO_CIRURGICO_ATUAL',
                'TIPO_ARTROPLASTIA',
                'CIMENTO_OSSEO',
                'ENXERTIA',
                'COMPONENTES_UTILIZADOS',
            ])
        } else {
            mutable.values.dadosPrimariaJoelho.set([])
        }
    }

    if (action.meta?.field === 'todosDadosRevisaoJoelho' || action.meta?.field === 'todosDadosArtroJoelho') {
        if (mutable.values.todosDadosRevisaoJoelho.get()) {
            mutable.values.dadosRevisaoJoelho.set([
                'MOTIVOS_REVISAO',
                'PROCEDIMENTO_CIRURGICO_ATUAL',
                'TIPO_REVISAO',
                'CIMENTO_OSSEO',
                'ENXERTIA',
                'COMPONENTES_UTILIZADOS',
            ])
        } else {
            mutable.values.dadosRevisaoJoelho.set([])
        }
    }

    // Lógica para seleção dos checkboxs pais
    const hasAllPrimariaJoelhoChecked = allCheckboxHaveBeenFilled(mutable.values.dadosPrimariaJoelho, 7)
    const hasAllRevisaoJoelhoChecked = allCheckboxHaveBeenFilled(mutable.values.dadosRevisaoJoelho, 6)

    mutable.values.todosDadosArtroJoelho.set(hasAllPrimariaJoelhoChecked && hasAllRevisaoJoelhoChecked)
    mutable.values.todosDadosPrimariaJoelho.set(hasAllPrimariaJoelhoChecked)
    mutable.values.todosDadosRevisaoJoelho.set(hasAllRevisaoJoelhoChecked)

    // Limpa dados se desativar seleção dados de artro joelho
    if (!mutable.values.stArtroJoelho.get()) {
        mutable.values.dadosPrimariaJoelho.set([])
        mutable.values.dadosRevisaoJoelho.set([])
        mutable.values.todosDadosPrimariaJoelho.set(false)
        mutable.values.todosDadosRevisaoJoelho.set(false)
        mutable.values.todosDadosArtroJoelho.set(false)
    }

    /**
     * Artroplastia de quadril
     */
    if (action.meta?.field === 'todosDadosArtroQuadril') {
        if (mutable.values.todosDadosArtroQuadril.get()) {
            mutable.values.todosDadosPrimariaQuadril.set(true)
            mutable.values.todosDadosRevisaoQuadril.set(true)
        } else {
            mutable.values.todosDadosPrimariaQuadril.set(false)
            mutable.values.todosDadosRevisaoQuadril.set(false)
            mutable.values.dadosPrimariaQuadril.set([])
            mutable.values.dadosRevisaoQuadril.set([])
        }
    }

    if (action.meta?.field === 'todosDadosPrimariaQuadril' || action.meta?.field === 'todosDadosArtroQuadril') {
        if (mutable.values.todosDadosPrimariaQuadril.get()) {
            mutable.values.dadosPrimariaQuadril.set([
                'DIAGNOSTICOS',
                'PROCEDIMENTO_CIRURGICO_PREVIO',
                'PROCEDIMENTO_CIRURGICO_ATUAL',
                'TIPO_ARTROPLASTIA',
                'CIMENTO_OSSEO',
                'ENXERTIA',
                'COMPONENTES_UTILIZADOS',
                'IMPLANTES',
            ])
        } else {
            mutable.values.dadosPrimariaQuadril.set([])
        }
    }

    if (action.meta?.field === 'todosDadosRevisaoQuadril' || action.meta?.field === 'todosDadosArtroQuadril') {
        if (mutable.values.todosDadosRevisaoQuadril.get()) {
            mutable.values.dadosRevisaoQuadril.set([
                'MOTIVOS_REVISAO',
                'PROCEDIMENTO_CIRURGICO_ATUAL',
                'TIPO_REVISAO',
                'CIMENTO_OSSEO',
                'ENXERTIA',
                'COMPONENTES_UTILIZADOS',
                'TECNICA_OPERATORIA',
            ])
        } else {
            mutable.values.dadosRevisaoQuadril.set([])
        }
    }

    // Lógica para seleção dos checkboxs pais
    const hasAllPrimariaQuadrilChecked = allCheckboxHaveBeenFilled(mutable.values.dadosPrimariaQuadril, 8)
    const hasAllRevisaoQuadrilChecked = allCheckboxHaveBeenFilled(mutable.values.dadosRevisaoQuadril, 7)

    mutable.values.todosDadosArtroQuadril.set(hasAllPrimariaQuadrilChecked && hasAllRevisaoQuadrilChecked)
    mutable.values.todosDadosPrimariaQuadril.set(hasAllPrimariaQuadrilChecked)
    mutable.values.todosDadosRevisaoQuadril.set(hasAllRevisaoQuadrilChecked)

    // Limpa dados se desativar seleção dados de artro quadril
    if (!mutable.values.stArtroQuadril.get()) {
        mutable.values.dadosPrimariaQuadril.set([])
        mutable.values.dadosRevisaoQuadril.set([])
        mutable.values.todosDadosPrimariaQuadril.set(false)
        mutable.values.todosDadosRevisaoQuadril.set(false)
        mutable.values.todosDadosArtroQuadril.set(false)
    }

    return mutable
}

const validaDadosProdutoFinanceiro = (
    mutable: ImmutableRootProxy<FormState<CadastroEntidadeConsumidoraForm>>,
    action
) => {
    // Dados de produto e financeiros
    /**
     * Dados de produto
     */
    if (action.meta?.field === 'todosDadosProduto') {
        if (mutable.values.todosDadosProduto.get()) {
            mutable.values.todosDadosProdutoIdentComponente.set(true)
            mutable.values.todosDadosProdutoIdentAnvisa.set(true)
        } else {
            mutable.values.todosDadosProdutoIdentComponente.set(false)
            mutable.values.todosDadosProdutoIdentAnvisa.set(false)
            mutable.values.dadosProdutoIdentComponente.set([])
            mutable.values.dadosProdutoIdentAnvisa.set([])
        }
    }

    if (action.meta?.field === 'todosDadosProdutoIdentComponente' || action.meta?.field === 'todosDadosProduto') {
        if (mutable.values.todosDadosProdutoIdentComponente.get()) {
            mutable.values.dadosProdutoIdentComponente.set([
                'IDENTIFICADOR_DISPOSITIVO',
                'CODIGO_REFERENCIA',
                'LOTE',
                'NUMERO_SERIE',
                'FABRICACAO',
                'VALIDADE',
            ])
        } else {
            mutable.values.dadosProdutoIdentComponente.set([])
        }
    }

    if (action.meta?.field === 'todosDadosProdutoIdentAnvisa' || action.meta?.field === 'todosDadosProduto') {
        if (mutable.values.todosDadosProdutoIdentAnvisa.get()) {
            mutable.values.dadosProdutoIdentAnvisa.set([
                'NUMERO_REGISTRO_ANVISA',
                'FABRICANTE',
                'NOME_PRODUTO',
                'NOME_TECNICO_PRODUTO',
                'CNPJ_DETENTOR_REGISTRO',
                'RAZAO_SOCIAL_DETENTOR_REGISTRO',
            ])
        } else {
            mutable.values.dadosProdutoIdentAnvisa.set([])
        }
    }

    // Lógica para seleção dos checkboxs pais
    const hasAllIdentComponenteChecked = allCheckboxHaveBeenFilled(mutable.values.dadosProdutoIdentComponente, 6)
    const hasAllIdentAnvisaChecked = allCheckboxHaveBeenFilled(mutable.values.dadosProdutoIdentAnvisa, 6)

    mutable.values.todosDadosProduto.set(hasAllIdentComponenteChecked && hasAllIdentAnvisaChecked)
    mutable.values.todosDadosProdutoIdentComponente.set(hasAllIdentComponenteChecked)
    mutable.values.todosDadosProdutoIdentAnvisa.set(hasAllIdentAnvisaChecked)

    // Limpa dados se desativar seleção dados de produto
    if (!mutable.values.stDadosProduto.get()) {
        mutable.values.dadosProdutoIdentAnvisa.set([])
        mutable.values.dadosProdutoIdentComponente.set([])
        mutable.values.todosDadosProdutoIdentComponente.set(false)
        mutable.values.todosDadosProdutoIdentAnvisa.set(false)
        mutable.values.todosDadosProduto.set(false)
    }

    /**
     * Dados de financeiro
     */
    if (action.meta?.field === 'todosDadosFinanceiros') {
        if (mutable.values.todosDadosFinanceiros.get()) {
            mutable.values.dadosFinanceiros.set(['ENTE_RESPONSAVEL', 'QUEM_FORNECEU', 'VALOR_PAGO', 'VALOR_RESSARCIDO'])
        } else {
            mutable.values.dadosFinanceiros.set([])
        }
    }

    // Lógica para seleção dos checkboxs pais
    const hasAllFinanceirosChecked = allCheckboxHaveBeenFilled(mutable.values.dadosFinanceiros, 4)
    mutable.values.todosDadosFinanceiros.set(hasAllFinanceirosChecked)

    // Limpa dados se desativar seleção dados de financeiro
    if (!mutable.values.stDadosFinanceiros.get()) {
        mutable.values.dadosFinanceiros.set([])
        mutable.values.todosDadosFinanceiros.set(false)
    }
    return mutable
}

const adicionarEntidade = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<CadastroEntidadeConsumidoraForm>>(state)
        form = form.withMutations(mutable => {
            validaEsferasAcesso(mutable, action)
            validaDadosHospitais(mutable, action)
            validaDadosPacientes(mutable, action)
            validaDadosMedicos(mutable, action)
            validaDadosProcedimento(mutable, action)
            validaDadosProdutoFinanceiro(mutable, action)
        })
        return form.asMap()
    }
    return state
})

export default adicionarEntidade
