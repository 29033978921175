import * as React from 'react'
import * as C from 'libs/components'
import field from 'libs/redux-form/field'
import { WrappedFieldProps } from 'redux-form'

interface OpcionaisLesaoCardioProps extends Partial<WrappedFieldProps> {
    hasReestenose: boolean
    hasTrombose: boolean
    name: string
}

class OpcionaisLesaoCardioCmp extends React.Component<OpcionaisLesaoCardioProps> {
    render() {
        return (
            <div>
                <C.Columns>
                    <C.Column size='5'>
                        <C.ControlField label='AHA/ACC' name='ahaacc' horizontalFlow>
                            <C.RadioField name='ahaacc' label='A' value='A' />
                            <C.RadioField name='ahaacc' label='B1' value='B1' />
                            <C.RadioField name='ahaacc' label='B2' value='B2' />
                            <C.RadioField name='ahaacc' label='C' value='C' />
                            <C.ClearRadioFieldButton name='ahaacc' />
                        </C.ControlField>
                    </C.Column>
                </C.Columns>
                <C.ControlField label='Tipo de lesão' name='tipoLesao'>
                    <C.Columns>
                        <C.Column size='6' verticalFlow>
                            <C.ClearableRadioField name='tipoLesao' label='De novo' value='DE_NOVO' />
                            <C.ClearableRadioField name='tipoLesao' label='Reestenose' value='REESTENOSE' />
                        </C.Column>
                        <C.Column size='6' verticalFlow>
                            <C.ClearableRadioField name='tipoLesao' label='Oclusão crônica' value='OCLUSAO_CRONICA' />
                            <C.ClearableRadioField name='tipoLesao' label='Trombose' value='TROMBOSE' />
                        </C.Column>
                    </C.Columns>
                </C.ControlField>
                <C.Columns>
                    <C.Column size='6'>
                        <C.ControlField label='Tipo de reestenose' name='tipoReestenose' verticalFlow>
                            <C.ClearableRadioField
                                name='tipoReestenose'
                                label='BMS'
                                value='BMS'
                                disabled={!this.props.hasReestenose}
                            />
                            <C.ClearableRadioField
                                name='tipoReestenose'
                                label='BVS'
                                value='BVS'
                                disabled={!this.props.hasReestenose}
                            />
                            <C.ClearableRadioField
                                name='tipoReestenose'
                                label='DES'
                                value='DES'
                                disabled={!this.props.hasReestenose}
                            />
                            <C.ClearableRadioField
                                name='tipoReestenose'
                                label='Stent prévio não conhecido'
                                value='STENT_PREVIO_DESCONHECIDO'
                                disabled={!this.props.hasReestenose}
                            />
                        </C.ControlField>
                    </C.Column>
                    <C.Column size='6'>
                        <C.ControlField label='Tipo de trombose' name='tipoTrombose' verticalFlow>
                            <C.ClearableRadioField
                                name='tipoTrombose'
                                label='Aguda'
                                value='AGUDA'
                                disabled={!this.props.hasTrombose}
                            />
                            <C.ClearableRadioField
                                name='tipoTrombose'
                                label='Subaguda'
                                value='SUBAGUDA'
                                disabled={!this.props.hasTrombose}
                            />
                            <C.ClearableRadioField
                                name='tipoTrombose'
                                label='Tardia'
                                value='TARDIA'
                                disabled={!this.props.hasTrombose}
                            />
                        </C.ControlField>
                    </C.Column>
                </C.Columns>
            </div>
        )
    }
}

export const OpcionaisLesaoCardio = field({ haswrapper: false })(OpcionaisLesaoCardioCmp)
