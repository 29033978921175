import { useAxiosGet } from 'hooks/useApiRequest'
import { LoadingStateContainer } from 'libs/components'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'redux/modules'
import { EdicaoDadosProdutoState, setEdicaoProcedimentoData } from 'redux/modules/edicaoProcedimento'
import { ProdutoRastreabilidadeView } from '../rastreabilidade/dadosProduto/ProdutoRastreabilidadeView'

export const EdicaoDadosProduto = () => {
    const idProced = useSelector<State, number>(state => state.edicao.idProcedimento)
    const { data, requestState } = useAxiosGet<EdicaoDadosProdutoState>(
        '/api/rastreabilidade/dadosProdutosById/' + idProced
    )
    const dispatch = useDispatch()
    useEffect(() => {
        if (requestState === 'success') {
            dispatch(setEdicaoProcedimentoData(data))
        }
    }, [requestState, dispatch, data])
    return (
        <LoadingStateContainer readyState={requestState}>
            <ProdutoRastreabilidadeView editMode />
        </LoadingStateContainer>
    )
}
