import * as React from 'react'
import classnames from 'classnames'

interface BuscaRowProps {
    className?: string
    onClick?()
}

export default class BuscaRow extends React.PureComponent<BuscaRowProps> {
    render() {
        const classes = classnames('busca-row', this.props.className)
        return (
            <div className={classes} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        )
    }
}
