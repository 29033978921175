import {
    DateInterval,
    DistribuicaoHospitalProcedimentoDto,
    DistribuicaoProcedimentoFiltro,
    DistribuicaoProdutosProcedimentosHospitalDto,
    InstituicaoDto,
} from 'backend'
import { Button, Column, Columns, Icon, PeriodoFilter, ScrollToTop, Text } from 'libs/components'
import UrlParams from 'libs/router/UrlParams'
import * as React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import { RequestState } from 'redux/requester'
import { InfoDadosProcedimentosCard } from './components/InfoDadosProcedimentosCard'
import { InfoQuantidadeProcedimentosEtapaCard } from './components/InfoQuantidadeProcedimentosEtapaCard'
import { Page } from 'redux/requester'
import { ResultadoProdutosComRegistro } from 'view/componentes/reports/fragments/ResultadoProdutosComRegistro'
import { ResultadoProdutosSemRegistro } from 'view/componentes/reports/fragments/ResultadoProdutosSemRegistro'
import { ResultadoProdutosTotal } from 'view/componentes/reports/fragments/ResultadoProdutosTotal'
import { stringify } from 'redux/modules/router'

export interface Props extends RouteComponentProps<any> {
    filtro: DistribuicaoProcedimentoFiltro
    procedimentosHospital: RequestState<DistribuicaoHospitalProcedimentoDto>
    produtosRegistro: RequestState<Page<DistribuicaoProdutosProcedimentosHospitalDto>>
    produtosSemRegistro: RequestState<Page<DistribuicaoProdutosProcedimentosHospitalDto>>
    isFetchingProdutosRegistro: any
    isFetchingProdutosSemRegistro: any
    instituicao: RequestState<InstituicaoDto>
    changeFilter(filtro: DistribuicaoProcedimentoFiltro): void
    load(filtro: DistribuicaoProcedimentoFiltro): void
    resetFilter(current: DistribuicaoProcedimentoFiltro): void
    handlePageChangeRegistro(page: number): void
    handlePageChangeSemRegistro(page: number): void
    goBack(): void
}

export class ProcedimentosHospitalView extends React.Component<Props> {
    handlePeriodoChange = (interval: DateInterval) => {
        this.props.changeFilter({
            ...this.props.filtro,
            interval: interval,
        })
    }

    refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        this.props.load(filter)
    }

    handleVoltar = () => {
        this.props.goBack()
        return true
    }

    renderDownloadIconComRegistro = () => {
        const currentFilter = this.props.filtro || {}
        return (
            <Link
                to={{
                    pathname: '/api/report/distribuicaoProdutosProcedimentosHospitalRegistro/csv',
                    search: stringify(currentFilter),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }

    renderDownloadIconSemRegistro = () => {
        const currentFilter = this.props.filtro || {}
        return (
            <Link
                to={{
                    pathname: '/api/report/distribuicaoProdutosProcedimentosHospitalSemRegistro/csv',
                    search: stringify(currentFilter),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }

    render() {
        const {
            isFetchingProdutosRegistro,
            isFetchingProdutosSemRegistro,
            instituicao,
            produtosSemRegistro,
            produtosRegistro,
            filtro,
            procedimentosHospital,
        } = this.props
        const { ...publishFilter } = filtro
        const procedimento = filtro.tipoProcedimento[0] === 'STENT_CORONARIA' ? 'angioplastias' : 'artroplastias'
        return (
            <div>
                <ScrollToTop onMount />
                <UrlParams params={publishFilter} onChange={this.refresh} />
                <ReportClearFilter />

                {instituicao.readyState === 'success' && (
                    <InfoDetailHeader
                        instituicao={instituicao.result}
                        procedimento={procedimento}
                        onVoltar={this.handleVoltar}
                    />
                )}
                <div className='report-content'>
                    <Columns className='report-tabs align-center'>
                        <Column size='6'>
                            <div className='report-tabs-links'>Lista de registro</div>
                        </Column>
                        <Column size='6' textRight>
                            <PeriodoFilter value={filtro?.interval} onChange={this.handlePeriodoChange} />
                        </Column>
                    </Columns>
                    <Columns auto>
                        <InfoDadosProcedimentosCard
                            info={procedimentosHospital.result}
                            isFetching={procedimentosHospital.readyState === 'request'}
                        />
                    </Columns>

                    <Columns auto>
                        <InfoQuantidadeProcedimentosEtapaCard
                            info={procedimentosHospital.result}
                            readyState={procedimentosHospital.readyState}
                        />
                    </Columns>

                    <Columns auto>
                        {this.props.filtro && (
                            <ResultadoProdutosTotal
                                handlePageChange={this.props.handlePageChangeRegistro}
                                isFetching={isFetchingProdutosRegistro}
                                produtosRegistro={produtosRegistro}
                                produtosSemRegistro={produtosSemRegistro}
                            />
                        )}
                    </Columns>

                    <Columns auto>
                        {this.props.filtro && (
                            <ResultadoProdutosComRegistro
                                handlePageChange={this.props.handlePageChangeRegistro}
                                isFetching={isFetchingProdutosRegistro}
                                produtos={produtosRegistro}
                                report={this.renderDownloadIconComRegistro}
                                showDetalheProduto={false}
                            />
                        )}
                    </Columns>

                    <Columns auto>
                        {this.props.filtro && (
                            <ResultadoProdutosSemRegistro
                                handlePageChange={this.props.handlePageChangeSemRegistro}
                                isFetching={isFetchingProdutosSemRegistro}
                                produtos={produtosSemRegistro}
                                report={this.renderDownloadIconSemRegistro}
                                showDetalheProduto={false}
                            />
                        )}
                    </Columns>
                </div>
            </div>
        )
    }
}

export interface InfoDetailHeaderProps {
    instituicao: InstituicaoDto
    procedimento: string
    onVoltar(): void
}

export const InfoDetailHeader = (props: InfoDetailHeaderProps) => {
    const { instituicao, procedimento, onVoltar } = props
    return (
        <HeaderContainer className='report-header-rastreabilidade'>
            <Button data-name='botao_voltar' className='button-round button-back-motivos' onClick={onVoltar}>
                <Icon icon='voltar' />
            </Button>
            <Text weight='semibold' color='grey-darker' size='small' transform='uppercase'>
                Distribuição de {procedimento}
            </Text>
            <p>
                <Text size='small-ter'>{instituicao.nomeFantasia}</Text>
            </p>
        </HeaderContainer>
    )
}
