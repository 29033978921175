import { Column, Columns, Icon, Label } from 'libs/components'
import React from 'react'

interface Props {
    preenchido: string
    preenchendo: string
}

export const AvisoPreenchimentoFicha = (props: Props) => {
    return (
        <div className='aviso-versao-desatualizada-box'>
            <Columns>
                <Column size='1' customStyle={{ width: '6%' }}>
                    <Icon icon='info' size='is-20px' className='is-neon' />
                </Column>
                <Column size='11'>
                    <Label className='aviso-versao-titulo'>
                        {`Após preencher os ${props.preenchendo}, não será mais possível editar os ${props.preenchido}.`}
                    </Label>
                    <Label className='aviso-preencher-subtitulo'>
                        {`Verifique se todos os componentes lançados estão corretos antes de preencher os ${props.preenchendo}.`}
                    </Label>
                </Column>
            </Columns>
        </div>
    )
}
