import axios, { AxiosPromise } from 'axios'
import { AntibioticoDto } from 'backend'

class Antibioticos {
    loadAll(): AxiosPromise<AntibioticoDto[]> {
        return axios.get('/api/antibiotico/list')
    }

    save(form: AntibioticoDto): AxiosPromise<AntibioticoDto> {
        return axios.post('/api/antibiotico/save', form)
    }
}

export default new Antibioticos()
