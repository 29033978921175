import { useRouter } from 'hooks/useRouter'
import { Column, FloatingContainer, Linha, Step, Steps } from 'libs/components'
import * as React from 'react'
import { PropsWithChildren } from 'react'
import { InformacoesProcedimento } from '.'
import { ProdutoRastreabilidadeViewParams } from '../dadosProduto/ProdutoRastreabilidadeView'

interface LocalProps {
    url: string
    procedimento: any
    modalControl(isOpen: boolean): void
}
export type RastreabilidadeSidebarProps = PropsWithChildren<LocalProps>
export const RastreabilidadeSidebar = ({ url, modalControl, procedimento, children }: RastreabilidadeSidebarProps) => {
    const { match } = useRouter<ProdutoRastreabilidadeViewParams>()
    const openModal = () => modalControl(true)
    return (
        <Column verticalFlow size='4'>
            <div className='rastreabilidade-sidebar is-full-height'>
                <InformacoesProcedimento open={openModal} procedimento={procedimento} />
                <div className='linha-side-bar'>
                    <Linha />
                </div>
                <FloatingContainer stopElement='footer'>{children}</FloatingContainer>
                <Steps title='Componentes utilizados'>
                    {procedimento.componentes.map((componente, index) => {
                        return (
                            <Step
                                key={index}
                                name={componente.nome}
                                linkActive={index < match.params.index}
                                path={`${url}${index}`}
                            />
                        )
                    })}
                </Steps>
            </div>
        </Column>
    )
}
