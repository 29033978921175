import React, { useCallback, useEffect, useState } from 'react'
import Entidades from 'api/Entidades'
import { useAxiosGet } from 'hooks/useApiRequest'
import { AutenticacaoIntegracaoDto } from 'backend'
import { EmptyListItem, LoadingStateContainer } from 'libs/components'
import { AutenticacaoEntidadeRow } from './components/AutenticacaoEntidadeRow'
import { ReadyState } from 'redux/requester'

export interface AutenticacaoEntidadeData extends Omit<AutenticacaoIntegracaoDto, 'sistema'> {
    readyStateLogin: ReadyState
    readyStateSenha: ReadyState
}

const GerenciarChavesView: React.FC = () => {
    let { data: reqData, requestState } = useAxiosGet<AutenticacaoEntidadeData[]>(Entidades.getAutenticacoes())

    const [data, setData] = useState<AutenticacaoEntidadeData[]>([])

    useEffect(() => {
        setData(reqData)
    }, [reqData])

    const setReadyStateLogin = useCallback((index: number, readyState: ReadyState) => {
        setData(oldData => {
            oldData[index].readyStateLogin = readyState
            return [...oldData]
        })
    }, [])

    const setReadyStateSenha = useCallback((index: number, readyState: ReadyState) => {
        setData(oldData => {
            oldData[index].readyStateSenha = readyState
            return [...oldData]
        })
    }, [])

    async function generateLogin(entidadeId: number) {
        const index = data.findIndex(e => e.entidadeConsumidora.id === entidadeId)
        setReadyStateLogin(index, 'request')

        try {
            const novoLogin = await Entidades.generateLogin(entidadeId)

            setData(oldData => {
                oldData[index].login = novoLogin.data.login
                oldData[index].dataRegistroLogin = novoLogin.data.dataRegistroLogin
                oldData[index].ativo = true
                return [...oldData]
            })

            setReadyStateLogin(index, 'success')
        } catch {
            setReadyStateLogin(index, 'failure')
        }
    }

    async function generateSenha(entidadeId: number) {
        const index = data.findIndex(e => e.entidadeConsumidora.id === entidadeId)
        setReadyStateSenha(index, 'request')

        try {
            const novaSenha = await Entidades.generateSenha(entidadeId)

            setData(oldData => {
                oldData[index].senha = novaSenha.data.senha
                oldData[index].dataRegistroSenha = novaSenha.data.dataRegistroSenha
                return [...oldData]
            })

            setReadyStateSenha(index, 'success')
        } catch {
            setReadyStateSenha(index, 'failure')
        }
    }

    return (
        <div className='gerenciar-chaves-entidades autenticacao-integracao report-content'>
            <LoadingStateContainer readyState={requestState}>
                <ul>
                    {data?.length > 0 &&
                        data.map(entidadeAuth => (
                            <li key={entidadeAuth.id}>
                                <AutenticacaoEntidadeRow
                                    autenticacao={entidadeAuth}
                                    generateLogin={generateLogin}
                                    generateSenha={generateSenha}
                                />
                            </li>
                        ))}

                    {data?.length === 0 && (
                        <EmptyListItem label='Para poder gerar uma nova chave, é necessário ter uma entidade ativa' />
                    )}
                </ul>
            </LoadingStateContainer>
        </div>
    )
}

export default GerenciarChavesView
