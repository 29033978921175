/* tslint:disable */
import React from 'react'

const SvgAjuda24 = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 21 21' {...props}>
        <path d='M10.5 2.625a7.875 7.875 0 110 15.75 7.875 7.875 0 010-15.75zm0 1.313a6.563 6.563 0 106.563 6.562A6.571 6.571 0 0010.5 3.937zm.735 9.362v1.313H9.922V13.3h1.313zm.796-6.563a2.625 2.625 0 01.674 2.888 2.301 2.301 0 01-.682.936 1.969 1.969 0 00-.5.613 2.424 2.424 0 00-.244 1.076H9.966c0-.57.132-1.133.385-1.645.194-.374.458-.707.779-.98.152-.11.273-.258.35-.429a1.321 1.321 0 00-.298-1.4 1.164 1.164 0 00-1.487 0c-.195.248-.28.564-.236.875l-1.304.158a2.45 2.45 0 01.595-1.952c.9-.873 2.31-.933 3.281-.14z' />
    </svg>
)

export default SvgAjuda24
