import axios from 'axios'

class Captcha {
    validateRecaptcha(recaptchaToken) {
        return axios.post('/api/public/recaptcha/validate', { recaptchaToken: recaptchaToken })
    }

    validateInvisibleRecaptcha(recaptchaToken) {
        return axios.post('/api/public/recaptcha/invisibleValidate', { recaptchaToken: recaptchaToken })
    }
}

export default new Captcha()
