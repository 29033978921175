
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.705,"time":42300,"words":141}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI.`}</li>
      <li parentName="ol">{`Clique no botão `}<img parentName="li" {...{
          "src": "/images/icone-alarmes.png#center",
          "alt": "..."
        }}></img>{`.`}</li>
      <li parentName="ol">{`Na central de alarmes selecione a opção “Configurar”.`}</li>
      <li parentName="ol">{`O RNI permite a definição de alarmes para o aumento do número de procedimentos. Selecione um procedimento que deseja monitorar e clique no botão “Configurar”.`}</li>
      <li parentName="ol">{`Marque a opção “Ativo” para ativar o envio de alarmes sempre que houver um aumento no número de registros do procedimento em relação ao período selecionado.`}</li>
      <li parentName="ol">{`No modal, marque a periodicidade que deseja monitorar e informe o índice de aumento no número de procedimentos que deve gerar o alarme.`}</li>
      <li parentName="ol">{`Marque em relação a qual período deseja que o sistema compare o número de procedimentos para verificar se houve aumento.`}</li>
      <li parentName="ol">{`Clique no botão “Salvar”.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para ser notificado quando ocorrer o aumento do número de registros, você deve se inscrever no alarme.`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

