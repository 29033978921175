
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.275,"time":16500,"words":55}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Esta é uma central de ajuda sobre o sistema `}<strong parentName="p">{`RNI`}</strong>{`, busque informações através do menu lateral ou pelo do campo de pesquisa. `}</p>
    <p>{`O Registro Nacional de Implantes (RNI) é um sistema informatizado desenvolvido para possibilitar o registro dos procedimentos cirúrgicos para implantação de próteses osteo-articulares (quadril e joelho) e de stent coronariano, realizados no país.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

