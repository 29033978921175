import * as React from 'react'
import { Icon } from 'libs/components'
import { Icons } from '../elements/icons'
import Badge from 'view/componentes/Badge'

interface MenuItemProps {
    icon?: Icons
    onClick()
    className?: string
    showNotificationDot?: boolean
}

const MenuItem: React.FC<MenuItemProps> = props => {
    const { children, icon, className, showNotificationDot, ...rest } = props
    const classname = className ? 'menu-item ' + className : 'menu-item'
    return (
        <li className={classname} {...rest}>
            {icon && (
                <Badge isVisible={showNotificationDot || false} overlap='circle'>
                    <Icon icon={icon} />
                </Badge>
            )}
            {children}
        </li>
    )
}

export default MenuItem
