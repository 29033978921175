import { connect } from 'react-redux'
import { DistribuicaoProcedimentoFiltro } from 'backend'
import { DistAngioplastiaView, Props } from 'view/home/anvisa/reports/angioplastia/DistAngioplastiaView'
import { State } from 'redux/modules'
import { withRouter } from 'react-router-dom'
import * as procedimento from 'redux/modules/report/procedimento'
import * as filtroReport from 'redux/modules/report/filtro'

export const baseFilter: DistribuicaoProcedimentoFiltro = {
    tipoProcedimento: ['STENT_CORONARIA'],
}

const mapStateToProps = (state: State, ownProps: Props) => ({
    filtro: state.report.filtro.filtro,
    distClassificacao: state.report.procedimento.distClassificacao,
    distClassificacaoLocal: state.report.procedimento.distClassificacaoLocal,
    goBack: () => ownProps.history.goBack(),
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(
            filtroReport.change({
                ...baseFilter,
                ...filtro,
            })
        )
    },
    loadAll: () => {
        const requiredDomains = {
            CLASSIFICACAO_CIRURGIA: ['ANGIOPLASTIA'],
            LOCAL_REGIAO: [
                { regiao: 'NORTE', cidade: null, uf: null, hospital: null },
                { regiao: 'NORDESTE', cidade: null, uf: null, hospital: null },
                { regiao: 'CENTRO_OESTE', cidade: null, uf: null, hospital: null },
                { regiao: 'SUDESTE', cidade: null, uf: null, hospital: null },
                { regiao: 'SUL', cidade: null, uf: null, hospital: null },
            ],
        }
        dispatch(procedimento.loadAll(requiredDomains))
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DistAngioplastiaView))
