import Report from 'api/Report'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'
import { SumarioProcedimentoDto, BuscaPacienteReportFiltro } from 'backend'
export const FIND_PROCEDIMENTOS = 'rni/report/buscaPaciente/FIND_PROCEDIMENTOS'
export const SET_FILTER = 'rni/report/buscaPaciente/SET_FILTER'
export const CLEAR = 'rni/report/buscaPaciente/CLEAR'

export interface BuscaPacienteReportState
    extends Readonly<{
        procedimentos: PromiseRecord<SumarioProcedimentoDto[]>
    }> {}

const BuscaPacienteInitialState: BuscaPacienteReportState = {
    // filter: { tipoCirurgia: ['JOELHO', 'QUADRIL', 'CORACAO'], classificacaoCirurgia: ['PRIMARIA', 'REVISAO'] },
    procedimentos: {},
}

export default function reducer(
    state: BuscaPacienteReportState = BuscaPacienteInitialState,
    action
): BuscaPacienteReportState {
    if (action.type === FIND_PROCEDIMENTOS) {
        return {
            ...state,
            procedimentos: promiseReducer(state.procedimentos, action),
        }
    }
    return state
}

export const findSumarioProcedimentos = (filtro: BuscaPacienteReportFiltro) => (dispatch, getState) => {
    dispatch({
        type: FIND_PROCEDIMENTOS,
        promise: Report.findSumarioProcedimentos(filtro),
    })
}

export const setFilter = (filtro?: BuscaPacienteReportFiltro) => (dispatch, getState) => {
    dispatch({
        type: SET_FILTER,
        payload: filtro,
    })
}

export const clear = () => ({
    type: CLEAR,
})
