import { PerfilEnum } from 'api/Perfis'
import { useRouter } from 'hooks/useRouter'
import { Perfil } from 'backend'
import { Column, Columns, ScrollToTop } from 'libs/components'
import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, Redirect, Route, Switch } from 'react-router-dom'
import { change, destroy, initialize } from 'redux-form'
import { hasSubmitSucceeded, isPristine } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { clearEdicaoProcedimentoData, EdicaoDadosProdutoState } from 'redux/modules/edicaoProcedimento'
import * as rastreabState from 'redux/modules/rastreabilidade'
import {
    clearRastreabilidade,
    getNomeModeloProduto,
    saveDadosProdutoMedico,
    updateDadosProdutoMedico,
    validateDadosProdutoMedico,
} from 'redux/modules/rastreabilidade'
import { usePesquisaSatisfacaoPopup } from 'view/componentes/pesquisaSatisfacao/usePesquisaSatisfacaoPopup'
import { ModalPreencherFichaEdicao } from 'view/home/novoProcedimento/ficha/componentes/ModalPreencherFichaEdicao'
import ModalInformacoesProcedimento from '../componentes/ModalInformacoesProcedimento'
import { RastreabilidadeSidebar } from '../componentes/RastreabilidadeSidebar'
import RastreabilidadeForm from '../RastreabilidadeForm'
import DadosProdutoValidator from './DadosProdutoValidator'
import { ProdutoRastreabilidadeForm } from './ProdutoRastreabilidadeForm'
import { handleErrorResolver, handleErrorSubmiting } from '../common/RastreabilidadeErrorUtil'

const formName = 'dadosProdutoForm'

export interface ProdutoRastreabilidadeViewParams {
    index?: number
}
export const ProdutoRastreabilidadeView = ({ editMode = false }) => {
    const path = editMode ? '/home/edicaoProcedimento/dadosProduto/' : '/home/dadosProduto/'
    const { history } = useRouter<ProdutoRastreabilidadeViewParams>()
    const submitSucceeded = useSelector(state => hasSubmitSucceeded(formName)(state))
    const pristine = useSelector(state => isPristine(formName)(state))
    const rastreabilidade = useSelector((state: any) => state.rastreabilidade)
    const procedimento = rastreabilidade?.findProcedimento.data
    const perfil = useSelector<State, Perfil>(state => state.auth.user?.perfil)
    const isPerfilAdm = perfil === PerfilEnum.ADMINISTRATIVO
    const dispatch = useDispatch()
    const showModalLeave = useRef<boolean>(true)
    const { showPesquisa } = usePesquisaSatisfacaoPopup()

    const promptCallback = useCallback(
        location => {
            if (location.pathname.indexOf(path) === -1 && !submitSucceeded && !pristine && showModalLeave.current) {
                return 'mensagem não usada'
            }
        },
        [path, pristine, submitSucceeded]
    )

    // Mimics componentWillUnmount
    useEffect(
        () => () => {
            dispatch(destroy(formName))
            dispatch(clearRastreabilidade())
            dispatch(clearEdicaoProcedimentoData())
        },
        [dispatch]
    )

    const [isModalInfoOpen, setIsModalInfoOpen] = useState<boolean>(false)
    const [atualFormItemName, setAtualFormItemName] = useState<string>('')
    const next = (index: number) => (form: any) => {
        const componente = procedimento.componentes[index]
        const formItemName = 'componente-' + componente.id
        setAtualFormItemName(formItemName)

        let formItem = form.get(formItemName)
        formItem = formItem.set('idProcedimento', procedimento.id)
        formItem = formItem.set('idRegistroProcedimentoComp', componente.id)

        return dispatch(validateDadosProdutoMedico(formItem) as any).then(() => {
            formItem = formItem.set('nomeComponente', componente.nome)
            form = form.set(formItemName, formItem)
            dispatch(change(formName, formItemName, formItem))

            if (index === procedimento.componentes.length - 1) {
                if (editMode) {
                    return dispatch<Promise<any>>(
                        updateDadosProdutoMedico({
                            dadosProduto: form.toArray(),
                            idProcedimento: procedimento.id,
                        }) as any
                    )
                } else {
                    return dispatch<Promise<any>>(
                        saveDadosProdutoMedico({
                            dadosProduto: form.toArray(),
                            idProcedimento: procedimento.id,
                        }) as any
                    )
                }
            }
        })
    }

    const [modalFinanceiro, setModalFinanceiro] = useState<boolean>(false)
    const ok = () => {
        dispatch<Promise<any>>(rastreabState.findProcedimento({ idProcedimento: procedimento.id }) as any).then(() => {
            dispatch(rastreabState.isOnlyFinanceiro(true))
            history.push('/home/dadosFinanceiro')
        })
    }

    const afterSave = (index: number) => () => {
        if (index < procedimento.componentes.length - 1) {
            history.replace(path + (index + 1))
        } else {
            showPesquisa()
            if (isPerfilAdm) {
                setModalFinanceiro(true)
                return
            }
            history.push('/home')
        }
        if (!editMode) {
            dispatch(initialize(formName, {}, { keepDirty: true, keepSubmitSucceeded: false }))
        }
    }
    const onCancel = () => history.push('/home')

    const handleSubmitFail = e => {
        // generic error here always send to /home
        if (e && typeof e[atualFormItemName] === 'string') {
            showModalLeave.current = false
            history.push('/home')
        }
    }

    const localHandleErrorSubmitting = error => {
        return handleErrorSubmiting(error, atualFormItemName)
    }

    const localHandleErrorResolver = error => {
        return handleErrorResolver(error, atualFormItemName)
    }

    const onOpenModalModeloProduto = registroAnvisa => dispatch(getNomeModeloProduto(registroAnvisa))
    const onOpenModeloModal = registroAnvisa => {
        onOpenModalModeloProduto(registroAnvisa)
    }

    const editingValues: EdicaoDadosProdutoState = useSelector(
        (state: State) => state.edicao as EdicaoDadosProdutoState
    )

    let thevalues = {}
    // eslint-disable-next-line
    editingValues?.dadosProduto?.forEach(prod => {
        return (thevalues[`componente-${prod.idRegistroProcedimentoComp}`] = prod)
    })

    return (
        <Columns fullHeight>
            <ScrollToTop />
            <RastreabilidadeSidebar url={path} modalControl={setIsModalInfoOpen} procedimento={procedimento} />
            <ModalPreencherFichaEdicao
                onClose={onCancel}
                onOk={ok}
                active={modalFinanceiro}
                mensagem={'Deseja preencher os dados financeiros do procedimento?'}
            />
            <Column className='procedimento-conteudo'>
                <Prompt message={promptCallback} />
                <Switch>
                    <Route exact path={path} render={() => <Redirect to={path + '0'} />} />
                    {procedimento.componentes.map((componente, index) => (
                        <Route
                            key={index}
                            path={path + `${index}`}
                            render={routeProps => (
                                <RastreabilidadeForm
                                    {...routeProps}
                                    editinValues={thevalues}
                                    destroyOnUnmount={false}
                                    forceUnregisterOnUnmount={true}
                                    formComponent={ProdutoRastreabilidadeForm}
                                    formName={formName}
                                    formSectionName={`componente-${componente.id}`}
                                    validator={DadosProdutoValidator}
                                    onSubmit={next(index)}
                                    onSubmitSuccess={afterSave(index)}
                                    onSubmitFail={handleSubmitFail}
                                    errorResolver={localHandleErrorResolver}
                                    onErrorSubmitting={localHandleErrorSubmitting}
                                    canCopy={index > 0}
                                    canSave={index + 1 === procedimento.componentes.length}
                                    isAdmAndHaveCustomModal={isPerfilAdm}
                                    componente={componente}
                                    cancelar={onCancel}
                                    openModeloModal={onOpenModeloModal}
                                    index={index}
                                    editMode={editMode}
                                    origemExterna={procedimento.origemExterna}
                                />
                            )}
                        />
                    ))}
                </Switch>
            </Column>
            <ModalInformacoesProcedimento
                open={isModalInfoOpen}
                procedimento={procedimento}
                close={() => setIsModalInfoOpen(false)}
            />
        </Columns>
    )
}
