import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { ReadyState } from 'redux/requester'

export interface RequestResponse<T> {
    data: T
    loading: boolean
    error: any
    called: boolean
}

export const useAxiosGet = <T extends object>(url: string) => {
    const [data, setData] = useState<T>(null)
    const [error, setError] = useState(null)
    const [requestState, setRequestState] = useState<ReadyState>('request')
    useEffect(() => {
        axios
            .get<T>(url)
            .then(dt => {
                setData(dt.data)
                setRequestState('success')
            })
            .catch(e => {
                setError(e)
                setRequestState('failure')
            })
    }, [url])

    return {
        data,
        error,
        requestState,
    }
}

export const useLazyAxiosGet = <T extends {}>(url: string): [() => Promise<T>, RequestResponse<T>] => {
    const [data, setData] = useState<T>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState(null)
    const [called, setCalled] = useState<boolean>(false)

    const request = useCallback((): Promise<T> => {
        setLoading(true)
        setCalled(true)
        return axios
            .get<T>(url)
            .then(d => {
                setData(d.data)
                return d.data
            })
            .catch(e => {
                setError(e)
                return Promise.reject(e)
            })
            .finally(() => setLoading(false))
    }, [setLoading, setCalled, setData, setError, url])

    return [
        request,
        {
            data,
            loading,
            error,
            called,
        },
    ]
}
