import * as React from 'react'
import classnames from 'classnames'

export interface LabelProps {
    className?: string
    disabled?: boolean
    testid?: string
}

export class Label extends React.Component<LabelProps, any> {
    render() {
        const classes = classnames('label', this.props.className, {
            'is-disabled': this.props.disabled,
        })

        return (
            <label className={classes} data-testid={this.props.testid}>
                {this.props.children}
            </label>
        )
    }
}
