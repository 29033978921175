import * as React from 'react'
import * as C from 'libs/components'
import { TipoArtroplastiaProcedimentos, TipoProcedimento } from 'backend'

let TiposArtroplastiaPrimariaJoelho = props => {
    const tipos = props.tipos
    let total = 0
    let naoConvencional = 0
    let semSubstituicao = 0
    let pateloFemoral = 0
    let unicompotamental = 0
    let comSubstituicao = 0
    props.tipos &&
        Object.entries(tipos).forEach(([index, value]: [any, any]) => {
            const quantidade = value.quantidadeProcedimentos
            total = total + quantidade
            if (value.tipoArtroplastia === 'ARTROPLASTIA_TOTAL_COM_SUBSTITUICAO_PATELAR') {
                comSubstituicao = quantidade
            } else if (value.tipoArtroplastia === 'ARTROPLASTIA_TOTAL_SEM_SUBSTITUICAO_PATELAR') {
                semSubstituicao = quantidade
            } else if (value.tipoArtroplastia === 'NAO_CONVENCIONAL') {
                naoConvencional = quantidade
            } else if (value.tipoArtroplastia === 'PATELO_FEMORAL') {
                pateloFemoral = quantidade
            } else if (value.tipoArtroplastia === 'UNICOMPARTIMENTAL') {
                unicompotamental = quantidade
            }
        })
    return (
        <div>
            <C.Columns>
                <C.Column className='has-border-right has-text-centered' size='2'>
                    <C.ComposedLabel title='Procedimentos' labelClassName='is-muted' inverted big>
                        <C.NumberLabel value={total} />
                    </C.ComposedLabel>
                </C.Column>
                <C.Column size='10'>
                    <C.Columns horizontalFlow>
                        <C.Column verticalFlow size='6' className='vertical-gutter'>
                            <p className='is-small'>
                                <C.NumberLabel value={comSubstituicao} className='is-semibold' />{' '}
                                <C.PercentageLabel value={comSubstituicao / total} className='is-grey-darker' /> Com
                                substituição patelar
                            </p>
                            <p className='is-small'>
                                <C.NumberLabel value={semSubstituicao} className='is-semibold' />{' '}
                                <C.PercentageLabel value={semSubstituicao / total} className='is-grey-darker' /> Sem
                                substituição patelar
                            </p>
                            <p className='is-small'>
                                {' '}
                                <C.NumberLabel value={naoConvencional} className='is-semibold' />{' '}
                                <C.PercentageLabel value={naoConvencional / total} className='is-grey-darker' /> Não
                                convencional
                            </p>
                        </C.Column>
                        <C.Column verticalFlow size='6' className='vertical-gutter'>
                            <p className='is-small'>
                                <C.NumberLabel value={pateloFemoral} className='is-semibold' />{' '}
                                <C.PercentageLabel value={pateloFemoral / total} className='is-grey-darker' /> Patelo
                                femoral
                            </p>
                            <p className='is-small'>
                                {' '}
                                <C.NumberLabel value={unicompotamental} className='is-semibold' />{' '}
                                <C.PercentageLabel value={unicompotamental / total} className='is-grey-darker' />{' '}
                                Unicompartimental
                            </p>
                        </C.Column>
                    </C.Columns>
                </C.Column>
            </C.Columns>
        </div>
    )
}

let TiposArtroplastiaPrimariaQuadril = props => {
    const tipos = props.tipos
    let total = 0
    let naoConvencional = 0
    let convencional = 0
    let hemiBipolar = 0
    let hemiMonopolar = 0
    let recapeamento = 0
    tipos &&
        Object.entries(tipos).forEach(([index, value]: [any, any]) => {
            const quantidade = value.quantidadeProcedimentos
            total = total + quantidade
            if (value.tipoArtroplastia === 'HEMIARTROPLASTIA_BIPOLAR') {
                hemiBipolar = quantidade
            } else if (value.tipoArtroplastia === 'HEMIARTROPLASTIA_MONOPOLAR') {
                hemiMonopolar = quantidade
            } else if (value.tipoArtroplastia === 'NAO_CONVENCIONAL') {
                naoConvencional = quantidade
            } else if (value.tipoArtroplastia === 'RECAPEAMENTO') {
                recapeamento = quantidade
            } else if (value.tipoArtroplastia === 'CONVENCIONAL') {
                convencional = quantidade
            }
        })
    return (
        <div>
            <C.Columns>
                <C.Column className='has-border-right has-text-centered' size='2'>
                    <C.ComposedLabel title='Procedimentos' labelClassName='is-muted' inverted big>
                        <C.NumberLabel value={total} />
                    </C.ComposedLabel>
                </C.Column>
                <C.Column size='10'>
                    <C.Columns horizontalFlow>
                        <C.Column verticalFlow size='6' className='vertical-gutter'>
                            <p className='is-small'>
                                <C.NumberLabel value={convencional} className='is-semibold' />{' '}
                                <C.PercentageLabel value={convencional / total} className='is-grey-darker' />{' '}
                                Convencional
                            </p>
                            <p className='is-small'>
                                <C.NumberLabel value={recapeamento} className='is-semibold' />{' '}
                                <C.PercentageLabel value={recapeamento / total} className='is-grey-darker' />{' '}
                                Recapeamento
                            </p>
                            <p className='is-small'>
                                <C.NumberLabel value={naoConvencional} className='is-semibold' />{' '}
                                <C.PercentageLabel value={naoConvencional / total} className='is-grey-darker' /> Não
                                convencional
                            </p>
                        </C.Column>
                        <C.Column verticalFlow size='6' className='vertical-gutter'>
                            <p className='is-small'>
                                <C.NumberLabel value={hemiBipolar} className='is-semibold' />{' '}
                                <C.PercentageLabel value={hemiBipolar / total} className='is-grey-darker' />{' '}
                                Hemiartroplastia bipolar
                            </p>
                            <p className='is-small'>
                                <C.NumberLabel value={hemiMonopolar} className='is-semibold' />{' '}
                                <C.PercentageLabel value={hemiMonopolar / total} className='is-grey-darker' />{' '}
                                Hemiartroplastia monopolar
                            </p>
                        </C.Column>
                    </C.Columns>
                </C.Column>
            </C.Columns>
        </div>
    )
}

interface Props {
    onClick?: () => void
    tiposPrimarias?: TipoArtroplastiaProcedimentos[]
    tipoProcedimento: TipoProcedimento
}

export class PorcentagemTipoArtroplastiaPrimariasCard extends React.Component<Props> {
    render() {
        const tipo = this.props.tipoProcedimento
        return tipo === 'PRIMARIA_JOELHO' ? (
            <TiposArtroplastiaPrimariaJoelho tipos={this.props.tiposPrimarias} />
        ) : (
            <TiposArtroplastiaPrimariaQuadril tipos={this.props.tiposPrimarias} />
        )
    }
}
