import { DescricaoPerfilCaptalize } from 'api/Perfis'
import PesquisaSatisfacaoApi, { convertToPesquisaSatisfacaoEnum } from 'api/PesquisaSatisfacao'
import { Button, IconButton, Text } from 'libs/components'
import { TextAreaWithLength } from 'libs/components/presentational/form/field/normalize/TextAreaWithLength'
import { SeletorSatisfacaoField } from 'libs/components/presentational/form/field/SeletorSatisfacaoField'
import reduxForm from 'libs/redux-form/form'
import { createValidator, maxLength, required } from 'libs/validation'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InjectedFormProps } from 'redux-form'
import { State } from 'redux/modules'
import { error, success } from 'redux/modules/alert'
import { SemiHiddenPopup } from '../SemiHiddenPopup'
import { usePesquisaSatisfacaoPopup } from './usePesquisaSatisfacaoPopup'
import { PesquisaSatisfacaoHeaderIcon } from './PesquisaSatisfacaoHeaderIcon'

export const PesquisaSatisfacao = () => {
    const dispatch = useDispatch()
    const { isPesquisaVisible, hidePesquisa } = usePesquisaSatisfacaoPopup()
    if (!isPesquisaVisible) {
        return null
    }
    return (
        <SemiHiddenPopup hiddenHeight={192} popupHeight={500}>
            {(expandPopup, closePopup) => (
                <PesquisaSatisfacaoPopupWithForm
                    expandPopup={expandPopup}
                    closePopup={() => {
                        closePopup().then(hidePesquisa)
                    }}
                    onSubmitSuccess={() => {
                        closePopup().then(hidePesquisa)
                    }}
                    onSubmit={formValues => {
                        const { comentario, nivelSatisfacao } = formValues.toJS()
                        return PesquisaSatisfacaoApi.save({
                            comentario,
                            nivelSatisfacao: convertToPesquisaSatisfacaoEnum(nivelSatisfacao),
                        })
                            .then(_ =>
                                dispatch(success({ mensagem: 'Sua avaliação foi enviada com sucesso. Obrigado!' }))
                            )
                            .catch(e =>
                                dispatch(
                                    error({
                                        mensagem:
                                            'Ocorreu um erro no envio da sua avaliação, não foi possível salvá-la.',
                                    })
                                )
                            )
                    }}
                />
            )}
        </SemiHiddenPopup>
    )
}

interface PesquisaSatisfacaoPopupFormProps extends InjectedFormProps {
    closePopup: () => void
    expandPopup: () => void
}
const PesquisaSatisfacaoPopupForm = ({ closePopup, expandPopup, handleSubmit }: PesquisaSatisfacaoPopupFormProps) => {
    const basePerfil = useSelector<State, string>(state => state.auth?.user?.perfil)
    const readablePerfil = DescricaoPerfilCaptalize[basePerfil]
    return (
        <div className='pesquisa-satisfacao'>
            <div className='pesquisa-satisfacao-header'>
                <div className='pesquisa-satisfacao-header-close-button'>
                    <IconButton icon='recusar' pulledRight onClick={closePopup} />
                </div>
                <PesquisaSatisfacaoHeaderIcon />
                <h1>Ajude-nos a melhorar o RNI!</h1>
            </div>
            <div className='pesquisa-satisfacao-content'>
                <p>
                    <Text weight='bold'>{readablePerfil}</Text>, o quão satisfeito você está com o uso do sistema?
                </p>
                <SeletorSatisfacaoField name='nivelSatisfacao' onChange={expandPopup} />
                <p>O que você considera mais importante para a sua avaliação?</p>
                <TextAreaWithLength
                    maxLength={255}
                    required
                    name='comentario'
                    className='pesquisa-satisfacao-content-comentario-input'
                />
                <Button type='primary' size='medium' onClick={handleSubmit}>
                    Enviar
                </Button>
            </div>
        </div>
    )
}

const PesquisaSatisfacaoPopupWithForm = reduxForm<{}, PesquisaSatisfacaoPopupFormProps>({
    form: 'pesquisaSatisfacao',
    validate: createValidator({ nivelSatisfacao: required, comentario: maxLength(255) }) as any,
    hasSuccessModal: false,
    hasLeaveModal: false,
    hasErrorModal: true,
})(PesquisaSatisfacaoPopupForm)
