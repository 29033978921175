import React from 'react'
import {
    CheckboxField,
    ChecklistItemField,
    CheckPanelField,
    Column,
    Columns,
    Conteudo,
    ControlField,
    InputPanel,
    Linha,
    RadioField,
} from 'libs/components'
import SensivelBadge from '../SensivelBadge'

interface PermissoesDadosPacientesMedicosFormSectionProps {
    disabledPacientesEndereco: boolean
    disabledMedicosEndereco: boolean
}

const PermissoesDadosPacientesMedicosFormSection: React.FC<PermissoesDadosPacientesMedicosFormSectionProps> = props => {
    return (
        <>
            <ControlField name='dadosPacientesMedicos' label={'Dados dos pacientes e dados dos médicos'} required />
            <CheckPanelField
                label='Dados dos pacientes'
                name='stPaciente'
                showErrors={true}
                testid='checkbox-dados-paciente'
            >
                <Conteudo>
                    <CheckboxField
                        name='todosDadosPaciente'
                        label='Selecionar todos'
                        testid='checkbox-todos-paciente'
                    />
                    <Linha />
                    <Columns>
                        <Column size='5' verticalFlow>
                            <span className='bolder'>
                                <CheckboxField
                                    name='todosDadosPacientePessoais'
                                    label='Pessoal'
                                    testid='checkbox-pessoal-paciente'
                                />
                            </span>

                            <div className='pl-20 is-vertical-flow'>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosPaciente'
                                        optionValue='NOME_COMPLETO'
                                        label='Nome completo'
                                        testid='checkbox-nome-paciente'
                                    />
                                    <SensivelBadge />
                                </div>
                                <ChecklistItemField
                                    name='dadosPaciente'
                                    optionValue='DATA_NASCIMENTO'
                                    label='Data de nascimento'
                                    testid='checkbox-nascimento-paciente'
                                />
                                <ChecklistItemField
                                    name='dadosPaciente'
                                    optionValue='SEXO'
                                    label='Sexo'
                                    testid='checkbox-sexo-paciente'
                                />
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosPaciente'
                                        optionValue='CPF'
                                        label='CPF'
                                        testid='checkbox-cpf-paciente'
                                    />
                                    <SensivelBadge />
                                </div>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosPaciente'
                                        optionValue='CNS'
                                        label='CNS'
                                        testid='checkbox-cns-paciente'
                                    />
                                    <SensivelBadge />
                                </div>
                            </div>
                        </Column>
                        <Column size='5' verticalFlow>
                            <span className='bolder'>
                                <CheckboxField
                                    name='enderecoPacienteAtivo'
                                    label='Endereço residencial'
                                    testid='checkbox-endereco-paciente'
                                />
                            </span>

                            <InputPanel className='is-vertical-flow' size='normal'>
                                <span>
                                    <RadioField
                                        name='enderecoPaciente'
                                        label='Estado'
                                        value='ESTADO'
                                        disabled={props.disabledPacientesEndereco}
                                        required={!props.disabledPacientesEndereco}
                                        testid='radio-endEstado-paciente'
                                    />
                                </span>
                                <span>
                                    <RadioField
                                        name='enderecoPaciente'
                                        label='Estado e município'
                                        value='ESTADO_MUNICIPIO'
                                        disabled={props.disabledPacientesEndereco}
                                        required={!props.disabledPacientesEndereco}
                                        testid='radio-endMunicipio-paciente'
                                    />
                                </span>
                                <span>
                                    <RadioField
                                        name='enderecoPaciente'
                                        label='Estado, município e bairro'
                                        value='ESTADO_MUNICIPIO_BAIRRO'
                                        disabled={props.disabledPacientesEndereco}
                                        required={!props.disabledPacientesEndereco}
                                        testid='radio-endBairro-paciente'
                                    />
                                </span>
                            </InputPanel>
                        </Column>
                    </Columns>
                </Conteudo>
            </CheckPanelField>
            <CheckPanelField
                label='Dados dos médicos'
                name='stMedicos'
                showErrors={true}
                testid='checkbox-dados-medico'
            >
                <Conteudo>
                    <CheckboxField name='todosDadosMedicos' label='Selecionar todos' testid='checkbox-todos-medico' />
                    <Linha />
                    <Columns>
                        <Column size='5' verticalFlow>
                            <span className='bolder'>
                                <CheckboxField
                                    name='todosDadosMedicosPessoais'
                                    label='Pessoal'
                                    testid='checkbox-pessoal-medico'
                                />
                            </span>

                            <div className='pl-20 is-vertical-flow'>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosMedicos'
                                        optionValue='NOME_COMPLETO'
                                        label='Nome completo'
                                        testid='checkbox-nome-medico'
                                    />
                                    <SensivelBadge />
                                </div>
                                <ChecklistItemField
                                    name='dadosMedicos'
                                    optionValue='DATA_NASCIMENTO'
                                    label='Data de nascimento'
                                    testid='checkbox-nascimento-medico'
                                />
                                <ChecklistItemField
                                    name='dadosMedicos'
                                    optionValue='SEXO'
                                    label='Sexo'
                                    testid='checkbox-sexo-medico'
                                />
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosMedicos'
                                        optionValue='CRM'
                                        label='CRM'
                                        testid='checkbox-crm-medico'
                                    />
                                    <SensivelBadge />
                                </div>
                                <div className='is-horizontal-flow align-center'>
                                    <ChecklistItemField
                                        name='dadosMedicos'
                                        optionValue='CPF'
                                        label='CPF'
                                        testid='checkbox-cpf-medico'
                                    />
                                    <SensivelBadge />
                                </div>
                                <ChecklistItemField
                                    name='dadosMedicos'
                                    optionValue='ESPECIALIDADE'
                                    label='Especialidade'
                                    testid='checkbox-especialidade-medico'
                                />
                            </div>
                        </Column>
                        <Column size='5' verticalFlow>
                            <span className='bolder'>
                                <CheckboxField
                                    name='enderecoMedicoAtivo'
                                    label='Endereço residencial'
                                    testid='checkbox-endereco-medico'
                                />
                            </span>

                            <InputPanel className='is-vertical-flow' size='normal'>
                                <span>
                                    <RadioField
                                        name='enderecoMedicos'
                                        label='Estado'
                                        value='ESTADO'
                                        disabled={props.disabledMedicosEndereco}
                                        required={!props.disabledMedicosEndereco}
                                        testid='radio-endEstado-medico'
                                    />
                                </span>
                                <span>
                                    <RadioField
                                        name='enderecoMedicos'
                                        label='Estado e município'
                                        value='ESTADO_MUNICIPIO'
                                        disabled={props.disabledMedicosEndereco}
                                        required={!props.disabledMedicosEndereco}
                                        testid='radio-endMunicipio-medico'
                                    />
                                </span>
                                <span>
                                    <RadioField
                                        name='enderecoMedicos'
                                        label='Estado, município e bairro'
                                        value='ESTADO_MUNICIPIO_BAIRRO'
                                        disabled={props.disabledMedicosEndereco}
                                        required={!props.disabledMedicosEndereco}
                                        testid='radio-endBairro-medico'
                                    />
                                </span>
                            </InputPanel>
                        </Column>
                    </Columns>
                </Conteudo>
            </CheckPanelField>
        </>
    )
}

export default PermissoesDadosPacientesMedicosFormSection
