/* tslint:disable */
import React from 'react'

const SvgControleRecebimento = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 36 36' {...props}>
        <g>
            <path d='M17.543 30.57l.084.072a.64.64 0 00.83-.071l4.305-3.3a.641.641 0 10-.91-.903L18.64 28.68V18.271c0-.501-.186-.722-.639-.721-.453.001-.64.224-.641.727l-.08 10.443-3.138-2.358a.642.642 0 00-.91.903l4.31 3.306z' />
            <path d='M27.088 7.1H8.9c-1.268 0-1.8.532-1.8 1.8v12.102l.003.175C7.15 22.386 7.688 22.9 8.9 22.9h6v-1.401l-6.5.001-.1-.1v-5.8l.1-.1 19.2.001.1.1V21.4l-.1.1-6.5-.001V22.9h6c1.265 0 1.8-.56 1.8-1.896V8.9c0-1.267-.536-1.8-1.812-1.8zM11.6 17.6a.9.9 0 100 1.8.9.9 0 000-1.8zM8.4 8.3l19.2.001.1.1V14.2l-.1.1H8.4l-.1-.1V8.4l.1-.1zm3.2 2.3a.9.9 0 100 1.8.9.9 0 000-1.8z' />
        </g>
    </svg>
)

export default SvgControleRecebimento
