import { useLocation, useRouteMatch, useHistory } from 'react-router-dom'

export const useRouter = <T>() => {
    const location = useLocation()
    const match = useRouteMatch<T>()
    const history = useHistory()

    return {
        location,
        match,
        history,
    }
}
