import * as React from 'react'
import { DadosCirurgiaVisualizacaoPrimariaQuadrilDto, ComponenteVisualizacaoDto } from 'backend'
import * as C from 'libs/components'
import * as FC from 'view/home/novoProcedimento/ficha/componentes'

interface DadosPrimariaQuadrilProps {
    dadosCirurgia: DadosCirurgiaVisualizacaoPrimariaQuadrilDto
    componentes: ComponenteVisualizacaoDto
}

export class DadosPrimariaQuadril extends React.Component<DadosPrimariaQuadrilProps> {
    render() {
        const dados = this.props.dadosCirurgia
        const componentes = this.props.componentes
        return (
            <C.Conteudo>
                <C.Label className='with-border-bellow'>Diagnóstico</C.Label>
                <C.Columns auto>
                    <C.TextLabel title='Motivos de artroplastia' value={dados.motivosArtroplastia} />
                </C.Columns>
                <C.Label className='with-border-bellow'>Procedimento cirúrgico prévio</C.Label>
                <C.Columns auto>
                    <C.TextLabel title='Procedimentos prévios' value={dados.procedimentosPrevios} />
                </C.Columns>
                <C.Columns auto>
                    <C.TextLabel title='Implante prévio' value={dados.implantePrevio} />
                </C.Columns>
                <C.Label className='with-border-bellow'>Procedimento cirúrgico atual</C.Label>
                <C.Columns auto>
                    <C.TextLabel title='Abordagem cirúrgica' value={dados.abordagemCirurgica} />
                    <C.TextLabel title='Uso de dreno' value={dados.usoDreno} />
                </C.Columns>
                <C.Columns auto>
                    <C.TextLabel title='Transfusão sanguínea' value={dados.transfusaoSanguinea} />
                </C.Columns>
                <C.Columns auto>
                    <C.TextLabel title='Antibiótico' value={dados.antibiotico} />
                    {dados.periodoAntibiotico && <C.TextLabel title='Período' value={dados.periodoAntibiotico} />}
                </C.Columns>
                <C.Label className='with-border-bellow'>Tipo de artroplastia</C.Label>
                <C.Columns auto>
                    <C.TextLabel title='Tipo de artroplastia' value={dados.tipoArtroplastia} />
                </C.Columns>
                {dados.parTribologico && (
                    <C.Columns auto>
                        <C.TextLabel title='Par tribológico' value={dados.parTribologico} />
                    </C.Columns>
                )}
                <C.Label className='with-border-bellow'>Implantes</C.Label>
                {dados.implanteFemoral && (
                    <div className='with-border-left'>
                        <C.Columns auto>
                            <C.Label>Femoral</C.Label>
                        </C.Columns>
                        <C.Columns auto>
                            <C.TextLabel title='Modelo de haste' value={dados.implanteFemoralModeloHaste} />
                            <C.TextLabel
                                title='Centralizador distal'
                                value={dados.implanteFemoralCentralizadorDistal}
                            />
                            <C.TextLabel
                                title='Centralizador proximal'
                                value={dados.implanteFemoralCentralizadorProximal}
                            />
                        </C.Columns>

                        <C.Columns>
                            <C.Column size='4'>
                                <C.TextLabel title='Cimentado' value={dados.implanteFemoralCimentado} />
                            </C.Column>
                            <C.Column size='4'>
                                {dados.implanteFemoralRestritorCimento && (
                                    <C.TextLabel
                                        title='Restritor de cimento'
                                        value={dados.implanteFemoralRestritorCimento}
                                    />
                                )}
                            </C.Column>
                        </C.Columns>
                    </div>
                )}
                <br /> {/* hue*/}
                {dados.implanteAcetabular && (
                    <div className='with-border-left'>
                        <C.Columns auto>
                            <C.Label>Acetabular</C.Label>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='4'>
                                <C.TextLabel title='Polietileno' value={dados.implanteAcetabularPolietileno} />
                            </C.Column>

                            <C.Column size='7'>
                                <C.TextLabel title='Metálico' value={dados.implanteAcetabularMetalico} />
                            </C.Column>
                        </C.Columns>

                        <C.Columns>
                            <C.Column size='4'>
                                <C.TextLabel title='Cimentado' value={dados.implanteAcetabularCimentado} />
                            </C.Column>
                            <C.Column size='4'>
                                {dados.implanteAcetabularParafusos && (
                                    <C.TextLabel title='Parafusos' value={dados.implanteAcetabularParafusos} />
                                )}
                            </C.Column>
                        </C.Columns>
                    </div>
                )}
                {dados.tipoCimentoOsseo && (
                    <div>
                        <C.Label className='with-border-bellow'>Cimento ósseo</C.Label>
                        <C.Columns auto>
                            <C.TextLabel title='Tipo' value={dados.tipoCimentoOsseo} />
                        </C.Columns>
                        <C.Columns auto>
                            <C.TextLabel title='Técnica de cimentação' value={dados.tecnicaCimentacao} />
                            <C.TextLabel title='Lavagem pulsátil' value={dados.lavagemPulsatil} />
                        </C.Columns>
                    </div>
                )}
                <C.Label className='with-border-bellow'>Enxertia</C.Label>
                <C.Columns auto>
                    {dados.implanteFemoral && <C.TextLabel title='Femoral' value={componentes.enxertiaFemoralOpcao} />}
                    {dados.implanteAcetabular && (
                        <C.TextLabel title='Acetabular' value={componentes.enxertiaAcetabularOpcao} />
                    )}
                </C.Columns>
                <FC.ComponentesUtilizadosArtroplastia
                    principais={componentes.componentesPrincipais}
                    complementares={componentes.componentesComplementares}
                />
            </C.Conteudo>
        )
    }
}
