import { createValidator, required } from 'libs/validation'
import { MotivoRevisaoValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/revisao/MotivoRevisaoValidator'
import { ProcedCirurgicoAtualRevValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/revisao/ProcedCirurgicoAtualRevValidator'
import { TipoRevisaoValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/revisao/TipoRevisaoValidator'
import { TecnicaOperatoriaRevValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/revisao/TecnicaOperatoriaRevValidator'
import { ComponentesValidator } from 'view/home/novoProcedimento/ficha/joelho/validators/ComponentesValidator'
import { RevisaoQuadrilForm } from 'backend'
import immutableProxy from 'libs/immutableProxy'

const FichaRevisaoQuadrilValidator = createValidator(
    {
        motivoRevisao: MotivoRevisaoValidator,
        procedimentoCirurgicoAtualRevisao: ProcedCirurgicoAtualRevValidator,
        tipoRevisaoForm: TipoRevisaoValidator,
        tecnicaOperatoria: TecnicaOperatoriaRevValidator,
        componentesUtilizadosForm: ComponentesValidator,
    },
    formValidator
)

export function formValidator(formMap) {
    const errors: any = {}
    const form = immutableProxy<RevisaoQuadrilForm>(formMap)
    const tecnica = form.tecnicaOperatoria
    if (tecnica.get()) {
        if (tecnica.femoralCimentado.get() === 'SIM' || tecnica.acetabularCimentado.get() === 'SIM') {
            errors.cimentoOsseo = {}
            const cimento = form.cimentoOsseo
            errors.cimentoOsseo.tipoCimentoOsseo = required(cimento.tipoCimentoOsseo.get())
            errors.cimentoOsseo.tecnicaCimentacao = required(cimento.tecnicaCimentacao.get())
            errors.cimentoOsseo.lavagemPulsatil = required(cimento.lavagemPulsatil.get())
        }

        errors.enxertia = {}
        const enxertia = form.enxertia
        if (enxertia) {
            if (tecnica.femoral.get() && enxertia.enxertiaFemoral.get()) {
                errors.enxertia.tipoEnxertiaFemoral = required(enxertia.tipoEnxertiaFemoral.get())
            }
            if (tecnica.acetabular.get() && enxertia.enxertiaAcetabular.get()) {
                errors.enxertia.tipoEnxertiaAcetabular = required(enxertia.tipoEnxertiaAcetabular.get())
            }
        }
    }

    return errors
}

export default FichaRevisaoQuadrilValidator
