import * as React from 'react'
import * as C from 'libs/components'
import UrlParams from 'libs/router/UrlParams'
import { NavLink, useLocation, useHistory, Link } from 'react-router-dom'
import {
    DistribuicaoProcedimentoFiltro,
    TipoCirurgia,
    DateInterval,
    MotivoProcedimentoEnum,
    MotivosQuadroPadraoDto,
    FiltroCirurgia,
    MotivosProcedimentosRow,
    MotivosRevisoesRow,
} from 'backend'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { ScrollToTop, Columns, Column, PeriodoFilter } from 'libs/components'
import { ReportMotivosProcedimento } from 'view/componentes/reports/components/ReportMotivosProcedimento'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'redux/modules'
import { loadAll, loadMotivoRevisoes, clearAllMotivos } from 'redux/modules/report/motivos'
import { change } from 'redux/modules/report/filtro'
import { HomeGestorServicoSaudeHeader } from '../HomeGestorServicoSaudeHeader'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import BackButton from 'libs/components/presentational/elements/BackButton'
import { stringify } from 'redux/modules/router'

export const GestorServicoSaudeMotivosProcedimentoView = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const filtroState = useSelector<State, DistribuicaoProcedimentoFiltro>(state => state.report.filtro.filtro)
    const motivosState = useSelector<State, PromiseRecord<MotivosProcedimentosRow[]>>(
        state => state.report.motivos.motivos
    )
    const distQuadroPadrao  = useSelector<State, PromiseRecord<MotivosQuadroPadraoDto[]>>(
        state => state.report.motivos.distQuadroPadrao
    )
    const motivosRevisoesState = useSelector<State, PromiseRecord<MotivosRevisoesRow[]>>(
        state => state.report.motivos.motivosRevisoes
    )

    const filtro = {
        ...filtroState,
        tipoCirurgia: filtroState.tipoCirurgia || 'JOELHO',
        filtroCirurgia: filtroState.filtroCirurgia || 'PRIMARIA',
    } as DistribuicaoProcedimentoFiltro

    React.useEffect(() => {
        if (filtroState.filtroCirurgia === 'REVISOES') {
            dispatch(loadMotivoRevisoes())
        } else {
            dispatch(loadAll())
        }
        return () => dispatch(clearAllMotivos())
    }, [dispatch, filtroState])

    const changeFilter = (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(change({ ...filtro }))
    }

    const goToDetail = (mot: MotivoProcedimentoEnum) => {
        history.push({
            pathname: `${location.pathname}/${mot}`,
            search: location.search,
        })
    }

    const handlePeriodoChange = (interval: DateInterval) => {
        changeFilter({
            ...filtroState,
            interval: interval,
        })
    }

    const handleTabItemClick = (tipoCirurgia: TipoCirurgia) => {
        changeFilter({
            ...filtroState,
            tipoCirurgia: tipoCirurgia,
        })
    }

    const handleSubTabItemClick = (filtroCirurgia: FiltroCirurgia) => {
        changeFilter({
            ...filtroState,
            filtroCirurgia: filtroCirurgia,
        })
    }

    const refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            changeFilter(filter)
        }
    }

    const renderDownloadIcon = () => {
        return (
            <Link
                to={{
                    pathname: '/api/report/motivosRevisoes/csv',
                    search: stringify(filtro),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <C.Text color='grey-darker'>
                    <C.Icon icon='exportar' />
                </C.Text>
            </Link>
        )
    }

    return (
        <>
            <ScrollToTop onMount />
            <UrlParams params={filtro} onChange={refresh} />
            <ReportClearFilter />

            <HomeGestorServicoSaudeHeader />

            <ReportHeader title='Motivos de procedimentos'>
                <div>
                    <BackButton />
                </div>

                <HorizontalTabMenu>
                    <HorizontalTabMenuItem
                        title='Joelho'
                        onClick={() => handleTabItemClick('JOELHO')}
                        active={filtro.tipoCirurgia === 'JOELHO'}
                    />
                    <HorizontalTabMenuItem
                        title='Quadril'
                        onClick={() => handleTabItemClick('QUADRIL')}
                        active={filtro.tipoCirurgia === 'QUADRIL'}
                    />
                </HorizontalTabMenu>
            </ReportHeader>

            <div className='report-content'>
                <HeaderContainer>
                    <HorizontalTabMenu>
                        <HorizontalTabMenuItem
                            title='Primária'
                            onClick={() => handleSubTabItemClick('PRIMARIA')}
                            active={filtro.filtroCirurgia === 'PRIMARIA'}
                        />
                        <HorizontalTabMenuItem
                            title='Revisão'
                            onClick={() => handleSubTabItemClick('REVISAO')}
                            active={filtro.filtroCirurgia === 'REVISAO'}
                        />
                        <HorizontalTabMenuItem
                            title='Revisões'
                            onClick={() => handleSubTabItemClick('REVISOES')}
                            active={filtro.filtroCirurgia === 'REVISOES'}
                        />
                    </HorizontalTabMenu>
                </HeaderContainer>

                <Columns className='report-tabs align-center'>
                    <Column size='6'>
                        <div className='report-tabs-links'>
                            <NavLink
                                to={{
                                    pathname: location.pathname,
                                    search: location.search,
                                }}
                                activeClassName='is-active'
                            >
                                Lista de motivos
                            </NavLink>
                        </div>
                    </Column>
                    <Column size='6' textRight>
                        <PeriodoFilter value={filtro.interval} onChange={handlePeriodoChange} />
                    </Column>
                </Columns>

                <ReportMotivosProcedimento
                    filtro={filtro}
                    motivos={motivosState}
                    motivosRevisoes={motivosRevisoesState}
                    distQuadroPadrao={distQuadroPadrao}
                    goToDetail={goToDetail}
                    report={renderDownloadIcon}
                />
            </div>
        </>
    )
}
