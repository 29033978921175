/* tslint:disable */
import React from 'react'

const SvgTiposRevisao = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
        <path d='M25.679 10.232l-3.294 3.293-2.881-1.029-1.03-2.881L21.77 6.32a6.205 6.205 0 00-7.896 7.737c-.09.07-.177.146-.26.229L6.85 21.05a2.899 2.899 0 104.1 4.1l6.764-6.765c.083-.082.159-.169.23-.259a6.204 6.204 0 007.736-7.895z' />
    </svg>
)

export default SvgTiposRevisao
