import React from 'react'
import * as C from 'libs/components'

export default class MesFabricacao extends React.Component {
    render() {
        const { name, error, ...rest } = this.props
        return (
            <C.FormField name={name} error={error}>
                <C.MesSelect {...rest} />
            </C.FormField>
        )
    }
}
