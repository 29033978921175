import React from 'react'
import { Field } from 'redux-form/immutable'
import { WrappedFieldProps } from 'redux-form'
import { LoadingStateContainer } from '../../layout/LoadingContainer'
import { usePesquisaSatisfacaoNiveis } from 'api/PesquisaSatisfacao'
import { SeletorSatisfacao } from 'view/componentes/pesquisaSatisfacao/seletorSatisfacao/SeletorSatisfacao'
import { NivelSatisfacao, NiveisSatisfacaoIcons } from 'view/componentes/pesquisaSatisfacao/seletorSatisfacao/icons'

export interface SeletorSatisfacaoFieldProps {
    name: string
    onChange?: (nivelSatisfacao: NivelSatisfacao) => void
}

export const SeletorSatisfacaoField = ({ onChange, name }: SeletorSatisfacaoFieldProps) => (
    <Field component={CustomInputField} name={name} onChange={onChange && (e => onChange(e))} />
)

const CustomInputField = ({ input, meta }: WrappedFieldProps) => {
    const { niveisSatisfacao, requestState } = usePesquisaSatisfacaoNiveis()
    const { onChange, value } = input
    const { error, touched } = meta
    return (
        <LoadingStateContainer readyState={requestState}>
            <SeletorSatisfacao
                active={value?.id}
                niveisSatisfacao={niveisSatisfacao?.map((nivelSatisfacao, i) => ({
                    id: i,
                    title: nivelSatisfacao,
                    icon: NiveisSatisfacaoIcons[i],
                }))}
                onNvSatisfacaoClick={(nivelSatisfacao: NivelSatisfacao) =>
                    value?.id !== nivelSatisfacao.id && onChange(nivelSatisfacao)
                }
                error={touched && error}
            />
        </LoadingStateContainer>
    )
}
