import { createValidator, required } from 'libs/validation'

export const TipoRevisaoValidator = createValidator({}, formValidator)

function formValidator(form) {
    const errors = {}
    if (
        !form.get('tipoRevisao') &&
        (!form.get('componentesRetiradosQuadril') || form.get('componentesRetiradosQuadril').length === 0)
    ) {
        errors.tipoRevisao = required(form.get('tipoRevisao'))
        errors.componentesRetiradosQuadril = required(form.get('componentesRetiradosQuadril'))
    }
    return errors
}
