import React from 'react'
import * as C from 'libs/components'
import * as FichaComponents from 'view/home/novoProcedimento/ficha/componentes'
import isArray from 'lodash/isArray'
import isUndefined from 'lodash/isUndefined'
import { ReactComponent as CardioDireita } from 'images/home/novoProcedimento/cardio-direita.svg'
import { ReactComponent as CardioEsquerda } from 'images/home/novoProcedimento/cardio-esquerda.svg'

export class LesaoSvgItem extends React.Component {
    constructor() {
        super()

        this.getAllSegments = this.getAllSegments.bind(this)
        this.getDisabledSegments = this.getDisabledSegments.bind(this)
        this.getClassname = this.getClassname.bind(this)
        this.getCurrentValues = this.getCurrentValues.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onClickChange = this.onClickChange.bind(this)
        this.renderSelected = this.renderSelected.bind(this)
        this.transformUpper = this.transformUpper.bind(this)
        this.transformLower = this.transformLower.bind(this)
        this.delete = this.delete.bind(this)
    }

    render() {
        const segments = this.getClassname()
        const selectedValues = this.getCurrentValues()
        return (
            <div>
                <C.ControlField name='segmentos' label='Local da lesão' required />
                <div className='sub-header-adicionar-lesao'>
                    <span className='description-adicionar-lesao'>
                        Selecione na imagem os segmentos em que se localiza essa lesão.
                    </span>
                </div>
                {this.props.dominio === 'ESQUERDO' ? (
                    <CardioEsquerda className={segments} onClick={this.onClickChange} />
                ) : (
                    <CardioDireita className={segments} onClick={this.onClickChange} />
                )}
                <C.Linha />
                <div className='sub-header-adicionar-lesao'>
                    <span className='title-adicionar-lesao'>Segmentos selecionados</span>
                </div>
                <div name={'segmentosSelecionados'} className='columns is-multiline'>
                    {selectedValues && selectedValues.length > 0 && this.renderSelected()}
                </div>
                <C.Linha />
                <div className='sub-header-adicionar-lesao'>
                    <span className='title-adicionar-lesao'>Classificação da lesão</span>
                </div>
                <FichaComponents.OpcionaisLesaoCardio
                    name={'opcionaisNativas'}
                    hasReestenose={this.props.hasReestenose}
                    hasTrombose={this.props.hasTrombose}
                />
            </div>
        )
    }

    renderSelected() {
        const values = this.getCurrentValues()
        return values.map((value, index) => (
            <C.StyledCheckboxLabel
                key={index}
                label={this.props.options[this.transformLower(value)]}
                excludable={true}
                onClick={this.delete(value)}
            />
        ))
    }

    onClickChange(event) {
        if (event.target.id !== '' && event.target.parentNode.id.substring(0, 8) === 'segmento') {
            this.handleChange(event.target.parentNode.id)
        } else if (event.target.parentNode.parentNode.parentNode.id.substring(0, 8) === 'segmento') {
            this.handleChange(event.target.parentNode.parentNode.parentNode.id)
        }
    }

    handleChange(targetId) {
        const values = this.getCurrentValues()
        const id = this.transformUpper(targetId)

        const disabledes = this.getDisabledSegments()
        if (values.length === 0 || disabledes.indexOf(id) < 0) {
            if (values.indexOf(id) >= 0) {
                let count = 0
                const adjacent = this.getAllSegments()[id]
                adjacent.forEach(adj => {
                    if (values.includes(adj)) {
                        count++
                    }
                })
                if (count < 2) {
                    values.splice(values.indexOf(id), 1)
                } else {
                    values.splice(0, 3)
                }
            } else {
                if (values.indexOf(id) < 0) {
                    values.push(id)
                }
            }
        }
        return this.props.onChange(values)
    }

    getCurrentValues() {
        const { value, initialValue } = this.props

        let previousValues = []

        if (!isUndefined(value) && value !== '') {
            previousValues = value
        } else if (!isUndefined(initialValue) && initialValue !== '') {
            previousValues = initialValue
        }

        const currentValues = isArray(previousValues) ? [...previousValues] : [previousValues]

        return currentValues
    }

    getClassname() {
        const markedValues = this.getCurrentValues()
        let classname = 'lesao-svg '

        const disabledes = this.getDisabledSegments()

        markedValues.forEach(function(value) {
            classname = classname + this.transformLower(value) + '-selected '
        }, this)

        markedValues.length > 0 &&
            disabledes.forEach(function(value) {
                classname = classname + this.transformLower(value) + '-disabled '
            }, this)

        return classname
    }

    getDisabledSegments() {
        const markedValues = this.getCurrentValues()

        const allSegs = this.getAllSegments()
        const disabledes = Object.keys(allSegs)

        if (markedValues.length === 3) {
            markedValues.forEach(function(value) {
                if (disabledes.indexOf(value) >= 0) {
                    disabledes.splice(disabledes.indexOf(value), 1)
                }
            }, this)
        } else {
            markedValues.forEach(function(value) {
                if (disabledes.indexOf(value) >= 0) {
                    disabledes.splice(disabledes.indexOf(value), 1)
                }
                allSegs[value].forEach(function(intVal) {
                    if (disabledes.indexOf(intVal) >= 0) {
                        disabledes.splice(disabledes.indexOf(intVal), 1)
                    }
                }, this)
            }, this)
        }

        return disabledes
    }

    transformUpper(value) {
        return value.toUpperCase().replace('-', '_')
    }

    transformLower(value) {
        return value.toLowerCase().replace('_', '-')
    }

    delete(segmentoId) {
        return () => {
            this.handleChange(segmentoId)
        }
    }

    getAllSegments() {
        return {
            SEGMENTO_1: ['SEGMENTO_2'],
            SEGMENTO_2: ['SEGMENTO_1', 'SEGMENTO_3'],
            SEGMENTO_3: ['SEGMENTO_2', 'SEGMENTO_4', 'SEGMENTO_16'],
            SEGMENTO_4: ['SEGMENTO_3'],
            SEGMENTO_5: ['SEGMENTO_6', 'SEGMENTO_11', 'SEGMENTO_12'],
            SEGMENTO_6: ['SEGMENTO_5', 'SEGMENTO_7', 'SEGMENTO_9'],
            SEGMENTO_7: ['SEGMENTO_6', 'SEGMENTO_8', 'SEGMENTO_9A', 'SEGMENTO_10'],
            SEGMENTO_8: ['SEGMENTO_7', 'SEGMENTO_10A'],
            SEGMENTO_9: ['SEGMENTO_6'],
            SEGMENTO_9A: ['SEGMENTO_7'],
            SEGMENTO_10: ['SEGMENTO_7'],
            SEGMENTO_10A: ['SEGMENTO_8'],
            SEGMENTO_11: ['SEGMENTO_5', 'SEGMENTO_12A', 'SEGMENTO_12B', 'SEGMENTO_13'],
            SEGMENTO_12: ['SEGMENTO_5'],
            SEGMENTO_12A: ['SEGMENTO_11'],
            SEGMENTO_12B: ['SEGMENTO_11'],
            SEGMENTO_13: ['SEGMENTO_11', 'SEGMENTO_14', 'SEGMENTO_14A', 'SEGMENTO_14B', 'SEGMENTO_15'],
            SEGMENTO_14: ['SEGMENTO_13'],
            SEGMENTO_14A: ['SEGMENTO_13'],
            SEGMENTO_14B: ['SEGMENTO_13'],
            SEGMENTO_15: ['SEGMENTO_13'],
            SEGMENTO_16: ['SEGMENTO_3', 'SEGMENTO_16A', 'SEGMENTO_16B', 'SEGMENTO_16C'],
            SEGMENTO_16A: ['SEGMENTO_16'],
            SEGMENTO_16B: ['SEGMENTO_16'],
            SEGMENTO_16C: ['SEGMENTO_16'],
        }
    }
}
