import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { UsoSistemaNacionalDashboard } from './UsoSistemaNacionalDashboard'
import { UsoSistemaRegiaolDashboard } from './UsoSistemaRegiaolDashboard'
import { useUrlParams } from '../hooks/useUrlParams'
import { UsoSistemaUfDashboard } from './UsoSistemaUfDashboard'
import { UsoSistemaCidadeDashboard } from './UsoSistemaCidadeDashboard'
import { UsoSistemaHospitalDashboard } from './UsoSistemaHospitalDashboard'

export interface UsoSistemaRouteParams {
    regiao: string
    estado: string
    municipio: string
    hospital: string
}

export default function UsoSistemaReportView() {
    const url = '/home/relatorios/usoSistema/'
    useUrlParams(url)
    return (
        <div>
            <ReportClearFilter />
            <Switch>
                <Route component={UsoSistemaRegiaolDashboard} exact path={`${url}:regiao`} />
                <Route component={UsoSistemaUfDashboard} exact path={`${url}:regiao/:estado`} />
                <Route component={UsoSistemaCidadeDashboard} exact path={`${url}:regiao/:estado/:municipio`} />
                <Route
                    component={UsoSistemaHospitalDashboard}
                    exact
                    path={`${url}:regiao/:estado/:municipio/:hospital`}
                />
                <Route component={UsoSistemaNacionalDashboard} />
            </Switch>
        </div>
    )
}
