import React from 'react'
import { VinculoProfissionalRadioField } from './VinculoProfissionalRadioField'
import { VinculoProfissionalRow } from 'backend'

export interface CardMedicoProps {
    listaMedico: VinculoProfissionalRow[]
}

export const CardMedico = ({ listaMedico }: CardMedicoProps) => (
    <div className='radio-medico has-text-centered'>
        {listaMedico.map((vinculoProfissional, index) => (
            <VinculoProfissionalRadioField
                key={vinculoProfissional.id}
                name='medico'
                value={index}
                vinculoProfissional={vinculoProfissional}
            />
        ))}
    </div>
)
