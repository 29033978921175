import { PacienteSeletor } from 'backend'
import { useAxiosGet } from 'hooks/useApiRequest'
import { LoadingStateContainer, Step, Steps } from 'libs/components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { setEdicaoProcedimentoData, EdicaoPrimariaQuadrilState } from 'redux/modules/edicaoProcedimento'
import { actionCreators as pacienteActionCreators } from 'redux/modules/paciente'
import { FormView } from 'view/componentes/FormView'
import FichaPrimariaQuadrilContainer from '../novoProcedimento/ficha/quadril/FichaPrimariaQuadrilContainer'
import FichaPrimariaQuadrilSidebar from '../novoProcedimento/ficha/quadril/FichaPrimariaQuadrilSidebar'
import * as edicaoProcedimento from 'redux/modules/edicaoProcedimento'
import { InformacoesEquipeMedica } from 'view/home/novoProcedimento/procedimentos/components/InformacoesEquipeMedica'
import { State } from 'redux/modules'
import { ModalInformacoesEquipeMedica } from '../novoProcedimento/procedimentos/components/ModalInformacoesEquipeMedica'

export const EdicaoPrimariaQuadril = (props: RouteComponentProps<{ idFicha: string }>) => {
    const idFicha = props.match.params.idFicha
    const medicoResponsavel = useSelector((state: State) => state.edicao?.medico)
    const equipeMedica = useSelector((state: State) => state.edicao?.equipeMedica)
    const [openModal, setOpenModal] = useState(false)
    const { data, requestState } = useAxiosGet<EdicaoPrimariaQuadrilState>(
        '/api/procedimentos/aguardandoDadosProdutoPrimariaQuadrilById/' + idFicha
    )
    const dispatch = useDispatch()
    useEffect(() => {
        if (requestState === 'success') {
            dispatch(setEdicaoProcedimentoData(data))
            dispatch(pacienteActionCreators.find({ id: data.pacienteId } as PacienteSeletor))
            dispatch(edicaoProcedimento.getEquipeMedicaEdicao(parseInt(idFicha)))
        }
    }, [requestState, dispatch, data, idFicha])

    const open = () => setOpenModal(true)

    const close = () => setOpenModal(false)

    return (
        <LoadingStateContainer readyState={requestState}>
            <ModalInformacoesEquipeMedica
                medicoResponsavel={medicoResponsavel}
                equipeMedica={equipeMedica}
                open={openModal}
                close={close}
            />
            <FormView
                sidebarContent={
                    <>
                        <InformacoesEquipeMedica medicoResponsavel={medicoResponsavel} open={open} />
                        <Steps>
                            <Step name='DEFINIR EQUIPE MEDICA' path={`${props.match.url}/definirEquipeMedica`} />
                            <Step name='DEFINIR PACIENTE' path={`${props.match.url}/definirPaciente`} />
                            <Step name='PROCEDIMENTOS' path={`${props.match.url}/procedimentos`} />
                            <Step name='REGISTRO' path={`${props.match.url}`}>
                                <FichaPrimariaQuadrilSidebar />
                            </Step>
                        </Steps>
                    </>
                }
                mainContent={<FichaPrimariaQuadrilContainer {...props} editMode />}
            />
        </LoadingStateContainer>
    )
}
