import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import { DadosFinanceirosForm } from 'backend'
import dadosFinanceiro from 'redux/modules/forms/section/dadosFinanceiro'
import immutableProxy from 'libs/immutableProxy'

const dadosFinanceirosForm = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let wizardName = action.meta.field.split('.')[0]
        let form = immutableProxy<FormState<DadosFinanceirosForm>>(state)
        form = form.withMutations(mutable => {
            dadosFinanceiro(mutable.values[wizardName], mutable.fields[wizardName], action)
        })
        return form.asMap()
    }

    return state
})

export default dadosFinanceirosForm
