import { createValidator, required } from 'libs/validation'
import { ProcedCirurgicoPrevioPrimValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/primaria/ProcedCirurgicoPrevioPrimValidator'
import { ProcedCirurgicoAtualPrimValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/primaria/ProcedCirurgicoAtualPrimValidator'
import { TipoArtroplastiaValidator } from 'view/home/novoProcedimento/ficha/joelho/validators/primaria/TipoArtroplastiaValidator'
import { CimentoOsseoValidator } from 'view/home/novoProcedimento/ficha/joelho/validators/CimentoOsseoValidator'
import { EnxertiaValidator } from 'view/home/novoProcedimento/ficha/joelho/validators/EnxertiaValidator'
import { ComponentesValidator } from 'view/home/novoProcedimento/ficha/joelho/validators/ComponentesValidator'

const FichaPrimariaJoelhoValidator = createValidator({
    motivoArtroplastia: required,
    procedimentoCirurgicoPrevio: ProcedCirurgicoPrevioPrimValidator,
    procedimentoCirurgicoAtualForm: ProcedCirurgicoAtualPrimValidator,
    tipoArtroplastiaForm: TipoArtroplastiaValidator,
    cimentoOsseo: CimentoOsseoValidator,
    enxertia: EnxertiaValidator,
    componentesUtilizadosForm: ComponentesValidator,
})

export default FichaPrimariaJoelhoValidator
