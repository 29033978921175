import {
    CadastroPacienteForm,
    DadosProdutoFormList,
    Lado,
    PrimariaJoelhoForm,
    PrimariaQuadrilForm,
    RevisaoJoelhoForm,
    RevisaoQuadrilForm,
    StentCoronariaForm,
    TipoProcedimento,
    VinculoProfissionalRow,
    DadosFinanceirosFormList,
    RastreabilidadeFormList,
} from 'backend'
import Procedimento from 'api/Procedimento'

interface BaseEdicaoState {
    idProcedimento?: number
    paciente?: CadastroPacienteForm
    tipoProcedimento?: TipoProcedimento
    medico?: VinculoProfissionalRow
    equipeMedica?: VinculoProfissionalRow[]
}

export interface EdicaoPreFichaState extends BaseEdicaoState {
    lado?: Lado
}

export interface EdicaoPrimariaJoelhoState extends PrimariaJoelhoForm, BaseEdicaoState {}

export interface EdicaoRevisaoJoelhoState extends RevisaoJoelhoForm, BaseEdicaoState {}

export interface EdicaoPrimariaQuadrilState extends PrimariaQuadrilForm, BaseEdicaoState {}

export interface EdicaoRevisaoQuadrilState extends RevisaoQuadrilForm, BaseEdicaoState {}

export interface EdicaoStentCoronariaState extends StentCoronariaForm, BaseEdicaoState {
    lado?: Lado
}

export interface EdicaoDadosProdutoState extends DadosProdutoFormList {
    tipoProcedimento?: TipoProcedimento
    paciente?: CadastroPacienteForm
    lado?: Lado
    medico?: VinculoProfissionalRow
    equipeMedica?: VinculoProfissionalRow[]
}

export interface EdicaoDadosFinanceirosState extends DadosFinanceirosFormList {
    tipoProcedimento?: TipoProcedimento
    paciente?: CadastroPacienteForm
    lado?: Lado
    medico?: VinculoProfissionalRow
    equipeMedica?: VinculoProfissionalRow[]
}

export interface EdicaoRastreabilidadeState extends RastreabilidadeFormList {
    tipoProcedimento?: TipoProcedimento
    paciente?: CadastroPacienteForm
    lado?: Lado
    medico?: VinculoProfissionalRow
    equipeMedica?: VinculoProfissionalRow[]
}

type Action = {
    type: string
    data?: EdicaoState
    result?
}

export type EdicaoState =
    | EdicaoPreFichaState
    | EdicaoPrimariaJoelhoState
    | EdicaoRevisaoJoelhoState
    | EdicaoPrimariaQuadrilState
    | EdicaoRevisaoQuadrilState
    | EdicaoStentCoronariaState
    | EdicaoDadosProdutoState
    | EdicaoDadosFinanceirosState
    | EdicaoRastreabilidadeState

export const SET_EDICAO_PROCEDIMENTO_DATA = 'rni/edicaoProcedimento/SET_EDICAO_PROCEDIMENTO_DATA'
export const CLEAR_EDICAO_PROCEDIMENTO_DATA = 'rni/edicaoProcedimento/CLEAR_EDICAO_PROCEDIMENTO_DATA'
export const SET_EDICAO_PROCEDIMENTO_PACIENTE = 'rni/edicaoProcedimento/SET_EDICAO_PROCEDIMENTO_PACIENTE'
export const SET_PROCEDIMENTO_EDICAO_PROCEDIMENTO = 'rni/edicaoProcedimento/SET_PROCEDIMENTO_EDICAO_PROCEDIMENTO'
export const GET_EQUIPE_MEDICA_EDICAO = 'rni/edicaoProcedimento/GET_EQUIPE_MEDICA_EDICAO'

export const reducerEdicao = (state: EdicaoState = null, action: Action): EdicaoState => {
    switch (action.type) {
        case SET_EDICAO_PROCEDIMENTO_DATA:
            return { ...state, ...action.data }
        case SET_PROCEDIMENTO_EDICAO_PROCEDIMENTO:
            return { ...state, tipoProcedimento: action.data?.tipoProcedimento, lado: action.data.lado }
        case CLEAR_EDICAO_PROCEDIMENTO_DATA:
            return null
        case SET_EDICAO_PROCEDIMENTO_PACIENTE:
            return { ...state, paciente: action.data?.paciente }
        case GET_EQUIPE_MEDICA_EDICAO:
            return {
                ...state,
                equipeMedica: action.result?.data.equipeMedica,
                medico: action.result?.data.profissionalResponsavel,
            }
        default:
            return state
    }
}

export const setEdicaoProcedimentoData = (data: EdicaoState): Action => ({
    type: SET_EDICAO_PROCEDIMENTO_DATA,
    data,
})

export const clearEdicaoProcedimentoData = (): Action => ({
    type: CLEAR_EDICAO_PROCEDIMENTO_DATA,
})

export const setPacienteEdicaoProcedimento = (paciente: CadastroPacienteForm): Action => ({
    type: SET_EDICAO_PROCEDIMENTO_PACIENTE,
    data: {
        paciente,
    },
})

export const setEdicaoProcedimento = (tipoProcedimento: TipoProcedimento, lado: Lado): Action => ({
    type: SET_PROCEDIMENTO_EDICAO_PROCEDIMENTO,
    data: {
        tipoProcedimento,
        lado,
    },
})

export const setIdProcedimento = (idProcedimento: number): Action => ({
    type: SET_EDICAO_PROCEDIMENTO_DATA,
    data: {
        idProcedimento,
    },
})

export const getEquipeMedicaEdicao = (idProcedimento: number) => ({
    type: GET_EQUIPE_MEDICA_EDICAO,
    promise: Procedimento.getEquipeMedicaEdicao(idProcedimento),
})
