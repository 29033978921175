import * as React from 'react'
import { WrappedFieldProps } from 'redux-form'
import { PasswordInputProps, PasswordInput } from 'libs/components'
import field, { FieldProps } from 'libs/redux-form/field'

export interface PasswordFieldProps extends PasswordInputProps, Partial<WrappedFieldProps> {}

class PasswordFieldCmp extends React.Component<PasswordFieldProps> {
    render() {
        return <PasswordInput {...this.props.input} {...this.props} placeholder={this.props.placeholder} />
    }
}

export const PasswordField: React.ComponentClass<FieldProps & PasswordFieldProps> = field()(PasswordFieldCmp)
