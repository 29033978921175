import { createValidator, required, cpf, cns, dataNasc, nome } from 'libs/validation'
import * as C from 'libs/components'
import ContatoFormValidator from 'view/home/paciente/ContatoFormValidator'

const CadastroPacienteValidator = createValidator({
    cpf: [required, cpf],
    cns: cns,
    nome: [required, nome],
    dataNascimento: [required, dataNasc],
    sexo: [required],
    nomeMae: [required, nome],
    enderecoForm: C.PacienteEnderecoFormValidator,
    contatoForm: ContatoFormValidator,
})

export default CadastroPacienteValidator
