import * as React from 'react'
import classnames from 'classnames'

interface Props {
    className: string
}

export const Oval = ({ className }: Props) => {
    return <span className={classnames('oval ', className)} />
}
