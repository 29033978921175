/* tslint:disable */
import React from 'react'

const SvgModalSucesso = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 128 128' {...props}>
        <path d='M52.21 89.392l47.89-52.905a1 1 0 011.484 1.342L52.988 91.513a1 1 0 01-1.64-.232.999.999 0 01-.268-.191L29.45 69.444a1 1 0 111.415-1.414l21.346 21.362zM64 0c35.29 0 64 28.709 64 64s-28.71 64-64 64S0 99.291 0 64 28.71 0 64 0zm0 1.707C29.652 1.707 1.707 29.652 1.707 64S29.652 126.293 64 126.293 126.293 98.348 126.293 64 98.348 1.707 64 1.707z' />
    </svg>
)

export default SvgModalSucesso
