import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Switch, Route, NavLink, Redirect } from 'react-router-dom'
import { DistribuicaoProcedimentoFiltro, DateInterval, ProdutoDto, UserDTO } from 'backend'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import UrlParams from 'libs/router/UrlParams'
import { RastreabilidadeReportHeader } from 'view/componentes/reports/components/RastreabilidadeReportHeader'
import * as C from 'libs/components'
import { ReportDashboardView } from 'view/componentes/reports/cards/ReportDashboardView'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { stringify } from 'redux/modules/router'
import { DistClassificacao, DistClassificacaoLocal, ResumoLocalItem } from 'redux/modules/report/procedimento'
import LocalHeaderContainer from 'view/home/anvisa/reports/components/LocalHeaderContainer'
import { ResumoLocalList } from 'view/home/anvisa/reports/components/ResumoLocalList'
import { ResumoLocalTable } from 'view/home/anvisa/reports/components/ResumoLocalTable'
import ReportMapView from 'view/home/anvisa/reports/fragments/ReportMapView'
import ReportPacienteSNVSListView from 'view/componentes/reports/fragments/ReportPacienteSNVSListView'

interface RouteParams {
    registroAnvisa: string
}

export interface Props extends RouteComponentProps<RouteParams> {
    match: any
    filtro: DistribuicaoProcedimentoFiltro
    produto: PromiseRecord<ProdutoDto>
    distClassificacao: DistClassificacao
    distClassificacaoLocal: DistClassificacaoLocal
    changeFilter(filtro: DistribuicaoProcedimentoFiltro): void
    loadAll(): void
    clearProdutoOnUnmount?(): void
    user: UserDTO
}

export class RastreabilidadeSNVSReportView extends React.Component<Props> {
    handleLocalItemClick = (item: ResumoLocalItem) => {
        const local = item.local
        this.props.changeFilter({
            ...this.props.filtro,
            local: {
                regiao: local.regiao,
                uf: local.uf,
                cidadeId: local.cidade ? local.cidade.id : undefined,
                hospitalId: local.hospital ? local.hospital.id : undefined,
            },
        })
    }

    componentWillUnmount() {
        this.props.clearProdutoOnUnmount && this.props.clearProdutoOnUnmount()
    }

    handlePeriodoChange = (interval: DateInterval) => {
        this.props.changeFilter({
            ...this.props.filtro,
            interval: interval,
        })
    }

    refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        this.props.loadAll()
    }

    handleVoltar = () => {
        this.props.history.push('/home/relatorios/rastreabilidade')
    }

    render() {
        const renderMap = (this.props.filtro.local && !this.props.filtro.local.hospitalId) || !this.props.filtro.local
        const { group, tipoProcedimento, registroAnvisa, ...publishFilter } = this.props.filtro

        const groups = this.props.distClassificacaoLocal.data ? this.props.distClassificacaoLocal.data.filtro.group : []
        const isCardView = groups.indexOf('LOCAL_REGIAO') >= 0 || groups.indexOf('LOCAL_UF') >= 0
        const result = this.props.distClassificacao.result
        const showPrimariaRevisao =
            result &&
            (Object.keys(result.classificacoes).includes('PRIMARIA') ||
                Object.keys(result.classificacoes).includes('REVISAO'))

        return (
            <div>
                <C.ScrollToTop onMount />
                <UrlParams params={publishFilter} onChange={this.refresh} />
                <ReportClearFilter />

                <LocalHeaderContainer />
                <RastreabilidadeReportHeader produto={this.props.produto.data} onVoltar={this.handleVoltar} />

                <div className='report-content'>
                    <C.Columns className='report-tabs align-center'>
                        <C.Column size='6'>
                            <div className='report-tabs-links'>
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}/dashboard`,
                                        search: this.props.location.search,
                                    }}
                                    activeClassName='is-active'
                                >
                                    Dashboard
                                </NavLink>
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}/paciente`,
                                        search: this.props.location.search,
                                    }}
                                    activeClassName='is-active'
                                >
                                    Lista de pacientes
                                </NavLink>
                                {renderMap && (
                                    <NavLink
                                        to={{
                                            pathname: `${this.props.match.url}/map`,
                                            search: this.props.location.search,
                                        }}
                                        activeClassName='is-active'
                                    >
                                        Mapa de calor
                                    </NavLink>
                                )}
                            </div>
                        </C.Column>
                        <C.Column size='6' textRight>
                            <C.PeriodoFilter value={this.props.filtro.interval} onChange={this.handlePeriodoChange} />
                        </C.Column>
                    </C.Columns>

                    <Switch>
                        <Route path={`${this.props.match.url}/paciente`}>
                            <ReportPacienteSNVSListView filtro={this.props.filtro} />
                        </Route>
                        <Route path={`${this.props.match.url}/dashboard`}>
                            <ReportDashboardView dadosUsoTitle='Número de procedimentos' />
                            <div className='margin-top-10'>
                                <C.Columns auto>
                                    {isCardView ? (
                                        <ResumoLocalList
                                            distClassificacaoLocal={this.props.distClassificacaoLocal}
                                            showPrimariaRevisaoRatio={showPrimariaRevisao}
                                            onItemClick={this.handleLocalItemClick}
                                        />
                                    ) : (
                                        <ResumoLocalTable
                                            distClassificacaoLocal={this.props.distClassificacaoLocal}
                                            showPrimariaRevisaoRatio={showPrimariaRevisao}
                                            onItemClick={this.handleLocalItemClick}
                                        />
                                    )}
                                </C.Columns>
                            </div>
                        </Route>

                        {renderMap && <Route path={`${this.props.match.url}/map`} render={() => <ReportMapView />} />}
                        <Redirect
                            to={{
                                pathname: `${this.props.match.url}/dashboard`,
                                search: stringify({ ...publishFilter }),
                            }}
                        />
                    </Switch>
                </div>
            </div>
        )
    }
}
