import * as React from 'react'
import * as C from 'libs/components'
import UrlParams from 'libs/router/UrlParams'
import { Switch, Route, NavLink, Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import { DistribuicaoProcedimentoFiltro, TipoCirurgia, DateInterval } from 'backend'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { ReportDashboardView } from 'view/componentes/reports/cards/ReportDashboardView'
import ReportMapView from '../fragments/ReportMapView'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import { ScrollToTop } from 'libs/components'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { stringify } from 'redux/modules/router'
import { ResumoLocalList } from '../components/ResumoLocalList'
import { ResumoLocalTable } from '../components/ResumoLocalTable'
import { DistClassificacao, DistClassificacaoLocal, ResumoLocalItem } from 'redux/modules/report/procedimento'
import BackButton from 'libs/components/presentational/elements/BackButton'
import ReportHospitaisList from '../fragments/ReportHospitaisList'

export interface Props extends RouteComponentProps<any> {
    filtro: DistribuicaoProcedimentoFiltro
    distClassificacao: DistClassificacao
    distClassificacaoLocal: DistClassificacaoLocal
    goBack(): void
    changeFilter(filtro: DistribuicaoProcedimentoFiltro): void
    loadAll(): void
}

export class DistArtroplastiaView extends React.Component<Props> {
    handleLocalItemClick = (item: ResumoLocalItem) => {
        const local = item.local
        this.props.changeFilter({
            ...this.props.filtro,
            local: {
                regiao: local.regiao,
                uf: local.uf,
                cidadeId: local.cidade ? local.cidade.id : undefined,
                hospitalId: local.hospital ? local.hospital.id : undefined,
            },
        })
    }

    handlePeriodoChange = (interval: DateInterval) => {
        this.props.changeFilter({
            ...this.props.filtro,
            interval: interval,
        })
    }

    handleTabItemClick = (tipoCirurgia: TipoCirurgia) => {
        this.props.changeFilter({
            ...this.props.filtro,
            tipoCirurgia: tipoCirurgia,
        })
    }

    refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        this.props.loadAll()
    }

    render() {
        const filtro = this.props.filtro
        const renderMap = (filtro.local && !filtro.local.hospitalId) || !filtro.local
        const { group, tipoProcedimento, ...publishFilter } = this.props.filtro

        const groups = this.props.distClassificacaoLocal.data ? this.props.distClassificacaoLocal.data.filtro.group : []
        const isCardView = groups.indexOf('LOCAL_REGIAO') >= 0 || groups.indexOf('LOCAL_UF') >= 0
        const result = this.props.distClassificacao.result
        const showPrimariaRevisao =
            result &&
            (Object.keys(result.classificacoes).includes('PRIMARIA') ||
                Object.keys(result.classificacoes).includes('REVISAO'))

        return (
            <div>
                <ScrollToTop onMount />
                <UrlParams params={publishFilter} onChange={this.refresh} />
                <ReportClearFilter />

                <LocalHeaderContainer />
                <ReportHeader title='Distribuição de artroplastias'>
                    <div>
                        <BackButton />
                    </div>
                    <HorizontalTabMenu>
                        <HorizontalTabMenuItem
                            title='Todos'
                            onClick={() => this.handleTabItemClick(null)}
                            active={!filtro.tipoCirurgia}
                        />
                        <HorizontalTabMenuItem
                            title='Joelho'
                            onClick={() => this.handleTabItemClick('JOELHO')}
                            active={filtro.tipoCirurgia === 'JOELHO'}
                        />
                        <HorizontalTabMenuItem
                            title='Quadril'
                            onClick={() => this.handleTabItemClick('QUADRIL')}
                            active={filtro.tipoCirurgia === 'QUADRIL'}
                        />
                    </HorizontalTabMenu>
                </ReportHeader>

                <div className='report-content'>
                    <C.Columns className='report-tabs align-center'>
                        <C.Column size='6'>
                            <div className='report-tabs-links'>
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}/dashboard`,
                                        search: this.props.location.search,
                                    }}
                                    activeClassName='is-active'
                                >
                                    Dashboard
                                </NavLink>
                                {renderMap && (
                                    <NavLink
                                        to={{
                                            pathname: `${this.props.match.url}/map`,
                                            search: this.props.location.search,
                                        }}
                                        activeClassName='is-active'
                                    >
                                        Mapa de calor
                                    </NavLink>
                                )}
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}/hospitais`,
                                        search: this.props.location.search,
                                    }}
                                    activeClassName='is-active'
                                >
                                    Hospitais
                                </NavLink>
                            </div>
                        </C.Column>
                        <C.Column size='6' textRight>
                            <C.PeriodoFilter value={this.props.filtro.interval} onChange={this.handlePeriodoChange} />
                        </C.Column>
                    </C.Columns>

                    <Switch>
                        <Route exact path={`${this.props.match.url}/dashboard`}>
                            <ReportDashboardView dadosUsoTitle='Número de artroplastias' />
                            <div className='margin-top-10'>
                                <C.Columns auto>
                                    {isCardView ? (
                                        <ResumoLocalList
                                            distClassificacaoLocal={this.props.distClassificacaoLocal}
                                            showPrimariaRevisaoRatio={showPrimariaRevisao}
                                            onItemClick={this.handleLocalItemClick}
                                        />
                                    ) : (
                                        <ResumoLocalTable
                                            distClassificacaoLocal={this.props.distClassificacaoLocal}
                                            showPrimariaRevisaoRatio={showPrimariaRevisao}
                                            onItemClick={this.handleLocalItemClick}
                                        />
                                    )}
                                </C.Columns>
                            </div>
                        </Route>
                        <Route exact path={`${this.props.match.url}/hospitais`} component={ReportHospitaisList} />
                        {renderMap && <Route path={`${this.props.match.url}/map`} render={() => <ReportMapView />} />}
                        <Redirect
                            to={{
                                pathname: `${this.props.match.url}`,
                                search: stringify({ ...publishFilter }),
                            }}
                        />
                    </Switch>
                </div>
            </div>
        )
    }
}
