import React from 'react'
import * as C from 'libs/components'

const paresTribologicos = [
    { id: 'CERAMICA_CERAMICA', nome: 'Cerâmica / Cerâmica' },
    { id: 'CERAMICA_METAL', nome: 'Cerâmica / Metal' },
    { id: 'CERAMICA_POLIETILENO_CONVENCIONAL', nome: 'Cerâmica / Polietileno convencional' },
    { id: 'CERAMICA_POLIETILENO_CROSSLINKED', nome: 'Cerâmica / Polietileno crosslinked' },
    { id: 'METAL_METAL', nome: 'Metal / Metal' },
    { id: 'METAL_POLIETILENO_CONVENCIONAL', nome: 'Metal / Polietileno convencional' },
    { id: 'METAL_POLIETILENO_CROSSLINKED', nome: 'Metal / Polietileno crosslinked' },
]

export class ParTribologicoField extends React.Component {
    render() {
        return <C.FixedSelectField {...this.props} options={paresTribologicos} />
    }
}
