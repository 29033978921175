import React from 'react'
import * as C from 'libs/components'

export class TabagismoCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Tabagismo'
                name='tabagismo'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='tabagismo' />}
            >
                <C.Conteudo>
                    <C.ControlField horizontalFlow label='Situação' name='tabagismoSituacao'>
                        <C.RadioField name='tabagismoSituacao' label='Ativo' value='ATIVO' />
                        <C.RadioField name='tabagismoSituacao' label='Ex-tabagista' value='EX_TABAGISTA' />
                        <C.ClearRadioFieldButton name='tabagismoSituacao' />
                    </C.ControlField>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
