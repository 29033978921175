/* tslint:disable */
import React from 'react'

const SvgArrowLeft = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M9.105 13.009L15.435 19l1.065-1.009L10.17 12l6.33-5.991L15.434 5l-6.33 5.991-.038-.037L8 11.964l.039.036-.039.037 1.066 1.009.039-.037z' />
    </svg>
)

export default SvgArrowLeft
