import { createValidator } from 'libs/validation'
import FichaStentCoronariaEssencialValidator from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaEssencialValidator'
import FichaStentCoronariaComplementarValidator from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaComplementarValidator'

const FichaStentCoronariaValidator = createValidator({
    essencial: FichaStentCoronariaEssencialValidator,
    complementar: FichaStentCoronariaComplementarValidator,
})

export default FichaStentCoronariaValidator
