import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { parse, stringify } from 'redux/modules/router'
import isEqual from 'lodash/isEqual'

type PublishMode = 'push' | 'replace'

interface Props {
    /**
     * Parâmetros que serão automaticamente publicados na URL.
     * Uma alteração de `params` implica em uma navegação baseada na prop `mode` (que pode ser um 'push' ou 'replace' na history).
     */
    params: any

    /**
     * Disparado quando os parâmetros da URL são alterados, decorrentes de navegação direta ou mudança da props `params`.
     * @param params Parâmetros lidos da URL.
     * @param selfChange Verdadeiro caso o evento tenha sido originado pelo próprio componente (devido a uma mudança da prop `params`)
     */
    onChange(params: any, selfChange: boolean): void

    /**
     * Modo de atualização do history.
     * @default 'push'
     */
    mode?: PublishMode
}

/**
 * Componente utilitário que publica e lê parâmetros da URL, baseado nos métodos de ciclo de vida do React.
 */
class UrlParams extends React.Component<Props & RouteComponentProps<any>> {
    private lastSearchKey: string = null

    static defaultProps: Props = {
        params: {},
        onChange: () => {},
        mode: 'push',
    }

    componentDidMount() {
        const currentParams = this.getUrlParams()
        const newParams = {
            ...this.props.params,
            ...currentParams,
        }

        this.lastSearchKey = this.updateUrl(newParams, 'replace')
        this.props.onChange(newParams, false)
    }

    componentDidUpdate(prevProps: Props & RouteComponentProps<any>) {
        const urlParams = this.getUrlParams()

        if (!isEqual(urlParams, this.props.params) && !isEqual(prevProps.params, this.props.params)) {
            this.lastSearchKey = this.updateUrl(this.props.params, this.props.mode)
        }

        if (!isEqual(prevProps.location.search, this.props.location.search)) {
            const selfChange = this.props.history.action !== 'POP' && this.lastSearchKey === this.props.location.search
            this.props.onChange(urlParams, selfChange)
        }
    }

    getUrlParams() {
        return parse(this.props.location.search)
    }

    updateUrl(params, mode: PublishMode) {
        const search = stringify(params)
        const newLocation = { search }

        if (mode === 'push') {
            this.props.history.push(newLocation)
        } else {
            this.props.history.replace(newLocation)
        }

        return '?' + search
    }

    render() {
        return null
    }
}

export default withRouter(UrlParams)
