import React from 'react'
import { StentCoronariaComplementarVisualizacaoDto } from 'backend'
import * as C from 'libs/components'
import * as FC from 'view/home/novoProcedimento/ficha/componentes'
import { isNullOrUndefined } from 'util'

interface DadosComplementaresCardioProps {
    dadosComplementares: StentCoronariaComplementarVisualizacaoDto[]
}

export class DadosComplementares extends React.Component<DadosComplementaresCardioProps> {
    render() {
        const data = this.props.dadosComplementares
        return (
            <div>
                {data && data.length > 0 && <Content {...this.props} />}
                {(!data || data.length < 1) && <C.Conteudo>Não informado</C.Conteudo>}
            </div>
        )
    }
}

const Content = ({ dadosComplementares: data }: DadosComplementaresCardioProps) => {
    return (
        <>
            <C.Conteudo>
                <C.Label className='with-border-bellow'>Antecedentes</C.Label>
                {data.map((element, idx) => (
                    <FC.AntecedentesCard key={idx} dadosComplementares={element} />
                ))}
            </C.Conteudo>

            <C.Conteudo>
                {data.map((element, idx) => {
                    return (
                        (element.dispositivosAdjuntos ||
                            element.metodosAuxiliares ||
                            !isNullOrUndefined(element.fluoroscopiaMinuto) ||
                            !isNullOrUndefined(element.fluoroscopiaSegundo) ||
                            !isNullOrUndefined(element.airKerma) ||
                            element.dap) && (
                            <div key={idx}>
                                <C.Label className='with-border-bellow'>ICP - complementar</C.Label>
                                <FC.IcpComplementarCard dadosComplementares={element} />
                            </div>
                        )
                    )
                })}
            </C.Conteudo>
            <C.Conteudo>
                {data.map((element, idx) => {
                    return (
                        (element.viaAcesso || element.lado || element.introdutor || element.tipoContraste) && (
                            <div key={idx}>
                                <C.Label className='with-border-bellow'>Acesso arterial/contraste</C.Label>
                                <FC.AcessoArterialCard dadosComplementares={element} />
                            </div>
                        )
                    )
                })}
            </C.Conteudo>
            <C.Conteudo>
                {data.map((element, idx) => {
                    return (
                        (element.antiplaquetario || element.anticoagulacao || element.inibidor) && (
                            <div key={idx}>
                                <C.Label className='with-border-bellow'>Medicação do procedimento</C.Label>
                                <FC.MedicacaoProcedimentoCard dadosComplementares={element} />
                            </div>
                        )
                    )
                })}
            </C.Conteudo>
            <C.Conteudo>
                <C.Label className='with-border-bellow'>Complicações clínicas</C.Label>
                {data.map((element, idx) => (
                    <FC.ComplicacoesClinicasCard key={idx} dadosComplementares={element} />
                ))}
            </C.Conteudo>

            {data && data[0].conclusao && (
                <C.Conteudo>
                    <C.Label className='with-border-bellow'>Conclusão</C.Label>
                    {data[0].conclusao}
                </C.Conteudo>
            )}
        </>
    )
}
