import React from 'react'
import * as C from 'libs/components'

export class DiabetesCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Diabetes Mellitus'
                name='diabetes'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='diabetes' />}
            >
                <C.Conteudo>
                    <C.Columns>
                        <C.Column size='3'>
                            <C.ControlField verticalFlow label='Terapia' name='diabetesTerapia'>
                                <C.InputPanel fallback>
                                    <C.ChecklistItemField
                                        name='diabetesTerapia'
                                        optionValue='NENHUMA'
                                        label='Nenhuma'
                                    />
                                </C.InputPanel>
                                <C.ChecklistItemField
                                    name='diabetesTerapia'
                                    optionValue='DIETA'
                                    label='Dieta'
                                    disabled={this.props.disabledNenhuma}
                                />
                                <C.ChecklistItemField
                                    name='diabetesTerapia'
                                    optionValue='INSULINA'
                                    label='Insulina'
                                    disabled={this.props.disabledNenhuma}
                                />
                                <C.ChecklistItemField
                                    name='diabetesTerapia'
                                    optionValue='ORAL'
                                    label='Oral'
                                    disabled={this.props.disabledNenhuma}
                                />
                                <C.ChecklistItemField
                                    name='diabetesTerapia'
                                    optionValue='OUTRA'
                                    label='Outra'
                                    disabled={this.props.disabledNenhuma}
                                />
                            </C.ControlField>
                        </C.Column>
                    </C.Columns>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
