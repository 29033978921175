import { Button, Column, Columns, Linha, TextField } from 'libs/components'
import form from 'libs/redux-form/form'
import React from 'react'
import { CadastrarSistemaFormValidator } from './CadastrarSistemaFormValidator'
import { InjectedFormProps } from 'redux-form'

interface CadastrarSistemaFormData {
    empresa: string
    sistema: string
}

interface CadastrarSistemaFormCmpProps extends InjectedFormProps<CadastrarSistemaFormData> {
    onClickCancelar: () => void
}

const CadastrarSistemaFormCmp = (props: CadastrarSistemaFormCmpProps) => {
    const { onClickCancelar, handleSubmit } = props
    return (
        <Columns className='modal-cadastrar-sistema-section'>
            <Column className='modal-cadastrar-sistema-inputs'>
                <TextField
                    required
                    className='modal-cadastrar-sistema-input'
                    name='empresa'
                    label='Nome da empresa'
                    placeholder='Nome da empresa'
                    maxLength={50}
                />
                <TextField
                    required
                    className='modal-cadastrar-sistema-input'
                    name='sistema'
                    label='Nome do sistema'
                    placeholder='Nome do sistema'
                    maxLength={50}
                />
            </Column>
            <Linha />
            <Column className='modal-cadastrar-sistema-btns'>
                <Button className='modal-cadastrar-sistema-cancelar' size='medium' onClick={onClickCancelar}>
                    Cancelar
                </Button>
                <Button
                    className='modal-cadastrar-sistema-cadastrar'
                    size='medium'
                    type='primary'
                    onClick={handleSubmit}
                >
                    Cadastrar
                </Button>
            </Column>
        </Columns>
    )
}

export const CadastrarSistemaForm = form<{}, CadastrarSistemaFormCmpProps>({
    form: 'cadastrarSistema',
    validate: CadastrarSistemaFormValidator as any,
    hasErrorModal: false,
    hasLeaveModal: false,
    hasSuccessModal: false,
})(CadastrarSistemaFormCmp)
