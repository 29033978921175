import React from 'react'
import * as C from 'libs/components'
import form from 'libs/redux-form/form'
import SolicitacaoVinculoValidator from 'view/perfis/SolicitacaoVinculoValidator'
import SolicitacaoVinculoFormSection from 'view/public/profissional/components/SolicitacaoVinculoFormSection'
import HomeConteudo from 'view/componentes/HomeConteudo'

const formName = 'solicitacaoVinculoForm'

class SolicitacaoVinculoViewCmp extends React.Component {
    render() {
        return (
            <HomeConteudo>
                <div className='solicitar-vinculo'>
                    <C.Columns>
                        <C.Column>
                            <C.PageTitle title='ADICIONAR VÍNCULO' />
                            <C.Linha />
                        </C.Column>
                    </C.Columns>
                    <C.Columns>
                        <C.Column>
                            <SolicitacaoVinculoFormSection form={formName} name='solicitacaoVinculo' />
                        </C.Column>
                    </C.Columns>
                    <C.Linha />
                    <C.ButtonBar>
                        <C.Button size='medium' onClick={this.props.onCancel}>
                            Cancelar
                        </C.Button>
                        <C.Button
                            size='medium'
                            type='primary'
                            loading={this.props.submitting}
                            onClick={this.props.handleSubmit}
                            pulledRight
                        >
                            Solicitar vínculo
                        </C.Button>
                    </C.ButtonBar>
                </div>
            </HomeConteudo>
        )
    }
}
export const SolicitacaoVinculoView = form({
    form: formName,
    validate: SolicitacaoVinculoValidator,
    successContent: (
        <span>
            Sua solicitação foi encaminhada para o gestor de segurança hospitalar. Assim que ele validar o seu vínculo
            você receberá um aviso por e-mail e já poderá acessar com o perfil solicitado.
        </span>
    ),
    successTitle: 'Novo vínculo solicitado!',
})(SolicitacaoVinculoViewCmp)
