import * as React from 'react'
import * as C from 'libs/components'
import { MouseEventHandler } from 'react'

export interface ModalCardProps {
    active: boolean
    hasCloseButton: boolean
    onClose: MouseEventHandler<any>
    title?: string
    children?: React.ReactNode
}

export const ModalCard = (props: ModalCardProps) => {
    return (
        <ModalCardComponent
            {...props}
            className='modal-card'
            headerComponent={<p className='modal-card-title'>{props.title}</p>}
        />
    )
}

type CoreProps = ModalCardProps & {
    className: string
    headerComponent?: React.ReactNode
}

export const ModalCardComponent = (props: CoreProps) => {
    return (
        <C.Modal active={props.active}>
            <div className={props.className}>
                <ModalCardHead onClose={props.onClose} hasCloseButton={props.hasCloseButton}>
                    {props.headerComponent}
                </ModalCardHead>
                <ModalCardBody>{props.children}</ModalCardBody>
            </div>
        </C.Modal>
    )
}

type ModalCardHeadProps = Pick<ModalCardProps, 'onClose' | 'hasCloseButton'> & {
    children?: React.ReactNode
}

const ModalCardHead = (props: ModalCardHeadProps) => {
    return (
        <header className='modal-card-head'>
            {props.children}
            {props.hasCloseButton && <button className='delete' onClick={props.onClose}></button>}
        </header>
    )
}

const ModalCardBody = props => {
    return (
        <section className='modal-card-body'>
            <div className='modal-card-body-content'>{props.children}</div>
        </section>
    )
}
