import { connect } from 'react-redux'
import { State } from 'redux/modules'
import { DistComponenteBrasilView, Props } from './DistComponenteBrasilView'
import { withRouter } from 'react-router-dom'
import * as produtos from 'redux/modules/report/produto'
import * as filtroReport from 'redux/modules/report/filtro'
import { DistribuicaoProcedimentoFiltro } from 'backend'

const mapStateToProps = (state: State, ownProps: Props) => ({
    componente: state.report.produto.componenteBrasil,
    filtro: state.report.filtro.filtro,
    goBack: () => {
        // Solução temporária para o bug da issue https://github.com/laboratoriobridge/rni/issues/1736
        const filtroLocal = state.report.filtro.filtro.local
        if (filtroLocal == null || Object.values(filtroLocal).every(value => value === undefined)) {
            ownProps.history.go(-1)
        } else {
            ownProps.history.go(-2)
        }
    },
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    loadComponente: value => {
        const filtro: DistribuicaoProcedimentoFiltro = value
        filtro.registroAnvisa = ownProps.match.params.registroAnvisa
        filtro.procedimento = ownProps.match.params.tipoProcedimento
        dispatch(produtos.findComponenteBrasil(+ownProps.match.params.idComponente, filtro))
    },
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(
            filtroReport.change({
                ...filtro,
            })
        )
    },
    clearFindResult: () => {
        dispatch(produtos.clearFindResult())
    },
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DistComponenteBrasilView))
