import axios, { AxiosPromise } from 'axios'
import { UserDTO } from 'backend'

class Auth {
    authenticate(): AxiosPromise<UserDTO> {
        return axios.get('/api/users/current')
    }

    login(user): any {
        const loginFunc = (user, resolve, reject) => {
            new Promise((resolveLocal, rejectLocal) => {
                axios
                    .post('/api/login', `username=${user.username}&password=${user.password}`)
                    .then(() => {
                        resolveLocal('login realizado com sucesso')
                    })
                    .catch(error => {
                        rejectLocal(error)
                    })
            })
                .then(() => {
                    return this.authenticate()
                })
                .then(data => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        }

        return new Promise(loginFunc.bind(this, user))
    }

    logout(): void {
        window.localStorage && window.localStorage.setItem('logout', Date.now().toString())

        window.location.assign('/logout')
    }
}

export default new Auth()
