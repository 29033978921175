import * as C from 'libs/components'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'redux/modules'
import * as procedimento from 'redux/modules/report/procedimento'
import { HeatMapCard } from '../cards'
import isEmpty from 'lodash/isEmpty'

interface Props {}

export default function ReportMapView(props: Props) {
    const dispatch = useDispatch()
    const filtro = useSelector((state: State) => state.report.filtro.filtro)
    const distHospitais = useSelector((state: State) => state.report.procedimento.distHospitais)
    React.useEffect(() => {
        if (!isEmpty(filtro)) {
            dispatch(procedimento.findDistHospitais(filtro))
        }
    }, [dispatch, filtro])

    return (
        <div>
            <C.Columns auto>
                <HeatMapCard dist={distHospitais} />
            </C.Columns>
        </div>
    )
}
