import { connect } from 'react-redux'
import VisualizacaoView from 'view/home/visualizacao/VisualizacaoView'
import * as procedimento from 'redux/modules/procedimento'

const mapDispatchToProps = dispatch => {
    return {
        clear: () => {
            dispatch(procedimento.clear())
        },
    }
}

const VisualizacaoContainer = connect(null, mapDispatchToProps)(VisualizacaoView as any)

export default VisualizacaoContainer
