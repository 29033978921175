import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import immutableProxy from 'libs/immutableProxy'
import { EnxertoForm } from 'backend'

const adicionarLesaoEnxerto = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<EnxertoForm>>(state)
        form = form.withMutations(mutable => {
            const tipoEnxerto = mutable.values.enxertoTipo.get()
            if (!tipoEnxerto || tipoEnxerto !== 'AORTO_CORONARIO') {
                mutable.values.enxertoTipoAorto.delete()
            }
            if (!tipoEnxerto || tipoEnxerto !== 'IN_SITU') {
                mutable.values.enxertoTipoInsitu.delete()
            }

            const tipoLesao = mutable.values.tipoLesao.get()
            if (!tipoLesao || tipoLesao !== 'REESTENOSE') {
                mutable.values.tipoReestenose.delete()
            }
            if (!tipoLesao || tipoLesao !== 'TROMBOSE') {
                mutable.values.tipoTrombose.delete()
            }
        })
        return form.asMap()
    }
    return state
})

export default adicionarLesaoEnxerto
