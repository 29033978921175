import * as React from 'react'
import field from 'libs/redux-form/field'
import * as C from 'libs/components'
import { WrappedFieldProps } from 'redux-form'
import { ComboPacienteDto } from 'backend'
import * as DateUtil from 'libs/util/DateUtil'
import { FormatOptionLabelContext } from 'react-select/src/Select'

interface PacienteFieldProps extends Partial<C.AsyncSelectProps>, C.FormFieldProps, Partial<WrappedFieldProps> {}

export class PacienteFieldCmp extends React.Component<PacienteFieldProps> {
    handleChange = (newValue: C.FixedSelectOption | C.FixedSelectOption[] | null): void => {
        this.props.input.onChange(newValue as any)
    }

    render() {
        // Remove o `onChange` do props.input (do redux-form) pois sua assinatura é incompatível
        // Tratamos o change event manualmente e enviamos ao `onChange` do redux-form via `handleChange`
        const { onChange, ...inputRest } = this.props.input

        return (
            <C.AsyncSelect
                {...inputRest}
                url='/api/pacientes/search'
                isClearable={this.props.clearable}
                isDisabled={this.props.disabled}
                onChange={this.handleChange}
                placeholder={this.props.placeholder as any}
                getOptionValue={opt => opt.nome}
                filterOption={() => true as any}
                formatOptionLabel={this.renderOption.bind(this)}
            />
        )
    }

    renderOption(option, meta) {
        return <PacienteFieldOption option={option} context={meta.context} />
    }
}

export const PacienteField = field()(PacienteFieldCmp)

interface PacienteFieldOptionProps {
    option: ComboPacienteDto
    context: FormatOptionLabelContext
}

export class PacienteFieldOption extends React.Component<PacienteFieldOptionProps> {
    render() {
        const { nome, cpf, dataNasc } = this.props.option
        const { context } = this.props
        return context === 'menu' ? (
            <div className='spacing-one-half'>
                <p>{nome}</p>
                <p className='is-small'>
                    <strong>CPF: </strong>
                    {cpf}
                </p>
                <p className='is-small'>
                    <strong>Data nascimento:</strong> {DateUtil.asClientFormat(dataNasc)}
                </p>
            </div>
        ) : (
            <p>{nome}</p>
        )
    }
}
