import * as React from 'react'
import classnames from 'classnames'
import { format, abbrev } from '../NumberUtil'

export interface NumberLabelProps {
    value: number
    minDecimalPlaces?: number
    maxDecimalPlaces?: number
    placeholder?: string
    className?: string
    title?: string
    abbrev?: boolean
    sufix?: string
}

export class NumberLabel extends React.Component<NumberLabelProps> {
    static defaultProps = {
        placeholder: '',
        sufix: '',
    }

    render() {
        const {
            className,
            value,
            minDecimalPlaces,
            maxDecimalPlaces,
            placeholder,
            title,
            abbrev,
            sufix,
            ...rest
        } = this.props

        return (
            <span {...rest} title={this.renderTitle()} className={classnames('number-label', className)}>
                {this.renderNumber()}
            </span>
        )
    }

    renderTitle() {
        return this.props.title || (this.props.abbrev && format(this.props.value))
    }

    renderNumber() {
        if (typeof this.props.value !== 'number' || isNaN(this.props.value)) {
            return this.props.placeholder
        }

        if (this.props.abbrev) {
            return abbrev(this.props.value, this.props.minDecimalPlaces, this.props.maxDecimalPlaces) + this.props.sufix
        }

        return format(this.props.value, this.props.minDecimalPlaces, this.props.maxDecimalPlaces) + this.props.sufix
    }
}
