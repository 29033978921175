import * as React from 'react'
import classnames from 'classnames'
import { PublicInputProps, Input } from './Input'
import { ErrorIndicator } from '../form/ErrorIndicator'

export interface RadioButtonProps extends PublicInputProps {
    label: string
    name: string
    error?: string
}

export class RadioButton extends React.Component<RadioButtonProps, any> {
    render() {
        const classes = classnames('radio', {
            disabled: this.props.disabled,
        })
        return (
            <span className='radio-wrapper'>
                <label className={classes}>
                    <Input
                        name={this.props.name}
                        disabled={this.props.disabled}
                        checked={this.props.checked}
                        onChange={this.props.onChange}
                        value={this.props.value}
                        testid={this.props.testid}
                        type='radio'
                    />
                    <span className='radio-indicator'></span>
                    <span className='radio-label'>{this.props.label}</span>
                    {this.props.error && this.props.checked && <ErrorIndicator error={this.props.error} />}
                </label>
            </span>
        )
    }
}
