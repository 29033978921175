import * as React from 'react'
import { ControlField, InputPanel, Columns, Column, Label } from 'libs/components'

interface DoubleFieldGroupProps {
    label: string
    name: string
    required?: boolean
}
export const DoubleFieldGroup = ({ children, ...rest }: React.PropsWithChildren<DoubleFieldGroupProps>) => {
    if (!children) {
        return null
    }
    return (
        <ControlField required {...rest}>
            <InputPanel size='medium'>
                <Columns>
                    <Column size={'5'}>{children[0]}</Column>
                    <Column size={'5'}>{children[1]}</Column>
                </Columns>
                {rest.required && (
                    <Label className='group-item-required'>*Obrigatório preenchimento de pelo menos um item</Label>
                )}
            </InputPanel>
        </ControlField>
    )
}
