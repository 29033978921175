import React, { useEffect } from 'react'
import * as C from 'libs/components'
import { TopicoManual } from './TopicoManual'
import { RouteComponentProps } from 'react-router-dom'
import { PerfilEnum } from 'api/Perfis'
import { ManualContent } from './ManualContent'
import { Redirect } from 'react-router-dom'
import { ManualSideBar } from './ManualSideBar'
import { ManualUsuarioSearchView } from './ManualUsuarioSearchView'

interface ManualUsuarioViewProps extends RouteComponentProps<any> {
    arquivoPath: string
    subtopicoSelecionado: TopicoManual
    topicoSelecionado: TopicoManual
    titulo: string
    botaoSelecionado: string
    homeOrPublic: string
    getVersao: () => void
    versao: string
    perfil: string
    paiUrl: string
    filhoUrl: string
}

export function ManualUsuarioView({ getVersao, ...props }: ManualUsuarioViewProps) {
    useEffect(() => {
        getVersao()
    }, [getVersao])

    const topicosAnvisa = ['relatoriosGerenciaisAnvisa', 'alarmes', 'buscaProcedimentos', 'cadastroAntibioticoStents', 'consumo']
    const topicosGestorSaude = ['relatoriosGerenciais']
    const topicosGestorIntegracao = ['integracao']
    if (
        (props.perfil !== PerfilEnum.ANVISA && topicosAnvisa.includes(props.match.params.idPai)) ||
        (props.perfil !== PerfilEnum.GESTOR_SERVICO_SAUDE && topicosGestorSaude.includes(props.match.params.idPai)) ||
        (props.perfil !== PerfilEnum.GESTOR_INTEGRACAO && topicosGestorIntegracao.includes(props.match.params.idPai))
    ) {
        return <Redirect to={`${props.homeOrPublic}/manualUsuario/inicio`} />
    }
    return (
        <C.Columns fullHeight>
            <C.Column verticalFlow size='4'>
                <ManualSideBar
                    homeOrPublic={props.homeOrPublic}
                    perfil={props.perfil}
                    history={props.history}
                    paiUrl={props.paiUrl}
                    match={props.match}
                />
            </C.Column>

            <C.Column className='manual-text-body' size='8'>
                {props.paiUrl !== 'search' ? (
                    <ManualContent
                        titulo={props.titulo}
                        arquivoPath={props.arquivoPath}
                        topicoSelecionado={props.topicoSelecionado}
                        subtopicoSelecionado={props.subtopicoSelecionado}
                        botaoSelecionado={props.botaoSelecionado}
                        homeOrPublic={props.homeOrPublic}
                        history={props.history}
                        perfil={props.perfil}
                    />
                ) : (
                    <ManualUsuarioSearchView
                        history={props.history}
                        location={props.history.location}
                        match={props.match}
                        homeOrPublic={props.homeOrPublic}
                        perfil={props.perfil}
                    />
                )}
            </C.Column>
        </C.Columns>
    )
}
