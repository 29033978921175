import axios, { AxiosPromise } from 'axios'
import { TermoUsoDTO } from 'backend'

class TermoUso {
    carregarAceiteTermoUso(): AxiosPromise<void> {
        return axios.get('/api/users/termo-uso')
    }
    getTermoUsoVigente(): AxiosPromise<TermoUsoDTO> {
        return axios.get('/api/termo-uso/vigente')
    }
    aceitarTermoUso(): AxiosPromise<void> {
        return axios.post('/api/users/termo-uso')
    }
}

export default new TermoUso()
