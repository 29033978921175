import React, { useEffect } from 'react'
import classnames from 'classnames'
import { Modal as OverlayModal } from 'react-overlays'
import ModalManager from 'react-overlays/ModalManager'

export interface ModalProps {
    active: boolean
    children?: React.ReactNode
}

export function Modal(props: ModalProps) {
    const classes = classnames('modal', {
        'is-active': props.active,
    })

    const frozenStyle = 'body-when-opened'

    useEffect(() => {
        if (props.active) {
            document.body.classList.add(frozenStyle)
        } else {
            document.body.classList.remove(frozenStyle)
        }
        return () => document.body.classList.remove(frozenStyle)
    }, [props.active])

    return (
        <OverlayModal
            autoFocus={false}
            className={classes}
            renderBackdrop={backDropProps => <div {...backDropProps} className='modal-background' />}
            show={props.active}
            manager={new ModalManager({ handleContainerOverflow: false })}
        >
            {props.children}
        </OverlayModal>
    )
}
