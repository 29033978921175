import { createValidator, required } from 'libs/validation'

export const ProcedCirurgicoPrevioPrimValidator = createValidator(
    {
        procedimentoPrevio: required,
        implantePrevio: required,
    },
    formValidator
)

function formValidator(form) {
    const errors = {}
    if (form.get('implantePrevio') === 'SIM' && !form.get('estadoImplantePrevio')) {
        errors.implantePrevio = 'Preencha a subopção'
    }
    return errors
}
