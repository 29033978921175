/* tslint:disable */
import React from 'react'

const SvgOrdenadorDecrescente = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M15.303 7.007V7h1.404v.018L20 10.536l-.993 1.06-2.3-2.457V16.5h-1.404V9.128l-2.31 2.468-.993-1.06 3.303-3.53z' />
    </svg>
)

export default SvgOrdenadorDecrescente
