import React from 'react'
import * as C from 'libs/components'

export class DoencaPulmonarCronicaCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Doença pulmonar crônica'
                name='doencaPulmonar'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='doencaPulmonar' />}
            >
                <C.Conteudo>
                    <C.SimNaoRadioField label='Dependente de O2' name='doencaPulmonarDependeODois' />
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
