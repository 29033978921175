import * as React from 'react'
import * as C from 'libs/components'

export interface StyledCheckboxLabelProps {
    label: string
    excludable?: boolean
    onClick?(): void
}

export class StyledCheckboxLabel extends React.Component<StyledCheckboxLabelProps> {
    static defaultProps = {
        excludable: false,
        onClick: () => {},
    }

    render() {
        return (
            <div className='round-corner-label'>
                <span>
                    {this.props.label}
                    {this.props.excludable && <C.IconButton onClick={this.props.onClick} icon='excluir' iconOnly />}
                </span>
            </div>
        )
    }
}
