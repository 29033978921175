import axios, { AxiosPromise } from 'axios'
import { RecuperarSenhaForm } from 'backend'

class PublicUsuario {
    recuperarSenha(form: RecuperarSenhaForm): AxiosPromise<void> {
        return axios.post('/api/public/user/recuperarSenha', form)
    }
}

export default new PublicUsuario()
