import * as React from 'react'
import classnames from 'classnames'
import * as Util from 'libs/components/Util'

export interface ColumnsProps extends Util.UtilProps, Pick<React.DOMAttributes<any>, 'onClick'> {
    className?: string
    gapless?: any

    /**
     * Caso true, distribui automaticamente cada filho do Columns dentro da mesma linha
     */
    auto?: boolean
}

export class Columns extends React.Component<ColumnsProps, any> {
    render() {
        const classes: string = classnames('columns', this.props.className, Util.helpersClassnames(this.props), {
            'is-gapless': this.props.gapless,
            auto: this.props.auto,
        })

        return (
            <div className={classes} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        )
    }
}
