import React from 'react'
import * as C from 'libs/components'

export class FibrilacaoAtrialCronicaCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Fibrilação atrial crônica'
                name='fibrilacaoAtrialCronica'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='fibrilacaoAtrialCronica' />}
            >
                <C.Conteudo>
                    <C.SimNaoRadioField label='Anticoagulação oral' name='fibrilacaoAnticoagulacao' />
                    <C.InputPanel fallback size='medium' dataName='complementar.antecedentes.fibrilacaoTipoCoagulacao'>
                        <C.RadioField
                            name='fibrilacaoTipoCoagulacao'
                            label='Cumarínico'
                            value='CUMARINICO'
                            disabled={this.props.disabledTipoAnticoagulacao}
                        />
                        <C.RadioField
                            name='fibrilacaoTipoCoagulacao'
                            label='Fator Xa'
                            value='FATOR_XA'
                            disabled={this.props.disabledTipoAnticoagulacao}
                        />
                        <C.RadioField
                            name='fibrilacaoTipoCoagulacao'
                            label='Inibidor trombina'
                            value='INIBIDOR_TROMBINA'
                            disabled={this.props.disabledTipoAnticoagulacao}
                        />
                    </C.InputPanel>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
