import * as React from 'react'
import classnames from 'classnames'

interface Props {
    className?: string
    containerClass?: string
}

export class HeaderContainer extends React.Component<Props> {
    static defaultProps = {
        containerClass: 'report-container',
    }

    render() {
        return (
            <div className={classnames('header-container', this.props.className)}>
                <div className={this.props.containerClass}>{this.props.children}</div>
            </div>
        )
    }
}
