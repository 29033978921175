import * as React from 'react'
import * as C from 'libs/components'
import { TextFieldProps } from './TextField'

export const normalize = value => value.replace(/[^A-Za-záéíóúÁÉÍÓÚçÇâêôÂÊÔõãÕÃäöüÄÖÜàÀ' ]/g, '')

export interface LetterFieldProps extends TextFieldProps {
    name: string
}

export class LetterField extends React.Component<LetterFieldProps> {
    render() {
        return <C.TextField {...this.props} normalize={normalize} />
    }
}
