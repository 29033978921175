
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.285,"time":17099.999999999996,"words":57}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI.`}</li>
      <li parentName="ol">{`Na tela inicial clique na opção `}<strong parentName="li">{`“Buscar procedimentos”`}</strong>{` e depois selecione o Hospital que deseja visualizar os procedimentos.`}</li>
      <li parentName="ol">{`Escolha o tipo e a classificação da cirurgia que deseja buscar e clique no botão “Pesquisar”.`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/images/buscaProcedimentosHospital.gif#center",
        "alt": "..."
      }}></img></p>
    <p>{`O sistema exibe uma lista com todos os procedimentos executados pelo hospital, agrupando pelos pacientes. `}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

