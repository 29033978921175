import React from 'react'
import * as C from 'libs/components'

export const TabField = props => (
    <div>
        <div className='tabs is-boxed'>
            <ul>
                {React.Children.count(props.children) > 0 &&
                    React.Children.map(props.children, child => {
                        const key = child.key
                        const title = child.props.title
                        const active = props.activeKey === key
                        return (
                            <li className={active ? 'is-active' : ''}>
                                <C.StyledRadioField
                                    skin='simple-text'
                                    name={props.name}
                                    disabled={child.disabled}
                                    value={key}
                                >
                                    {title}
                                </C.StyledRadioField>
                            </li>
                        )
                    })}
            </ul>
        </div>
        <div className='tab-content-filtros'>
            {React.Children.count(props.children) > 0 &&
                React.Children.map(props.children, child => {
                    const key = child.key
                    const active = props.activeKey === key

                    if (!active) {
                        return
                    }

                    return child
                })}
        </div>
    </div>
)

export const TabPane = props => <div>{props.children}</div>
