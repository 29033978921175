import * as React from 'react'
import * as C from 'libs/components'

interface ReacoesContrasteProps {
    disabled?: boolean
}

export class ReacoesContrasteCard extends React.Component<ReacoesContrasteProps> {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Reação ao contraste'
                name='reacaoContraste'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='reacaoContraste' disabled={this.props.disabled} />}
            >
                <C.Conteudo>
                    <C.ControlField label='Severidade' name='reacaoContrasteSeveridade' verticalFlow>
                        <C.ClearableRadioField name='reacaoContrasteSeveridade' label='Leve' value='LEVE' />
                        <C.ClearableRadioField name='reacaoContrasteSeveridade' label='Moderada' value='MODERADA' />
                        <C.ClearableRadioField name='reacaoContrasteSeveridade' label='Grave' value='GRAVE' />
                    </C.ControlField>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
