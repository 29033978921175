import React from 'react'
import * as C from 'libs/components'
import field from 'libs/redux-form/field'

class SimpleCountFieldCmp extends React.Component {
    render() {
        return <C.SimpleCount {...this.props} {...this.props.input} />
    }
}

export const SimpleCountField = field()(SimpleCountFieldCmp)
