import * as React from 'react'
import { SituacaoAlerta } from 'backend'
import { DropdownSelect } from 'view/home/anvisa/alertas/components/DropdownSelect'
import Text from 'libs/components/presentational/typography/Text'
import { Icon } from 'libs/components'

const descricoesSituacao: { [key in SituacaoAlerta]: string } = {
    LIDO: 'Lidos',
    NAO_LIDO: 'Não lidos',
}

interface SituacaoAlertaSelectProps {
    situacaoSelecionada?: SituacaoAlerta | ''
    onChange(situacaoAlerta: SituacaoAlerta)
}

export default class SituacaoAlertaSelect extends React.PureComponent<SituacaoAlertaSelectProps> {
    render() {
        return (
            <DropdownSelect
                allOptionsLabel='Todos'
                customizeLabel={label => (
                    <Text color='grey-darker' size='small-ter'>
                        Visualizando
                        <b> {label.toLowerCase()}</b> <Icon icon='opcaovisualizacao' />
                    </Text>
                )}
                value={this.props.situacaoSelecionada}
                valueDescriptionMap={descricoesSituacao}
                onChange={this.props.onChange}
            />
        )
    }
}
