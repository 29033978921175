import React from 'react'
import { Column, Columns, Conteudo, MaskedField, TextField } from 'libs/components'

const IdentificacaoFormSection: React.FC = () => {
    return (
        <Conteudo>
            <Columns>
                <Column size='8'>
                    <TextField
                        required
                        name='nomeEntidade'
                        label='Nome da entidade'
                        placeholder='Nome da entidade'
                        maxLength={120}
                        testid='input-nome-entidade'
                    />
                </Column>
            </Columns>
            <Columns>
                <Column size='4'>
                    <MaskedField
                        name='cnpj'
                        label='CNPJ da entidade'
                        placeholder='00.000.000/0000-00'
                        mask='99.999.999/9999-99'
                        testid='input-cnpj-entidade'
                    />
                </Column>
                <Column size='4'>
                    <TextField
                        required
                        name='emailEntidade'
                        label='E-mail da entidade'
                        placeholder='entidade@email.com'
                        maxLength={80}
                        testid='input-email-entidade'
                    />
                </Column>
            </Columns>
        </Conteudo>
    )
}

export default IdentificacaoFormSection
