import * as React from 'react'
import * as C from 'libs/components'
import { TipoRevisaoProcedimentos, TipoProcedimento } from 'backend'

let TiposArtroplastiaRevisaoJoelho = props => {
    const tipos = props.tipos
    let total = props.quantidadeProcedimentos
    let naoConvencional = 0
    let trocaPatelar = 0
    let trocaFemoral = 0
    let trocaComponenteTibial = 0
    let trocaPolietilenoTibial = 0
    tipos &&
        Object.entries(tipos).forEach(([index, value]: [any, any]) => {
            const quantidade = value.quantidadeProcedimentos
            if (value.tipoRevisao === 'TROCA_DO_COMPONENTE_PATELAR') {
                trocaPatelar = quantidade
            } else if (value.tipoRevisao === 'TROCA_DO_COMPONENTE_FEMORAL') {
                trocaFemoral = quantidade
            } else if (value.tipoRevisao === 'NAO_CONVENCIONAL') {
                naoConvencional = quantidade
            } else if (value.tipoRevisao === 'TROCA_DO_COMPONENTE_TIBIAL') {
                trocaComponenteTibial = quantidade
            } else if (value.tipoRevisao === 'TROCA_DO_POLIETILENO_TIBIAL') {
                trocaPolietilenoTibial = quantidade
            }
        })
    return (
        <div>
            <C.Columns>
                <C.Column className='has-border-right has-text-centered' size='2'>
                    <C.ComposedLabel title='Procedimentos' labelClassName='is-muted' inverted big>
                        <C.NumberLabel value={total} />
                    </C.ComposedLabel>
                </C.Column>
                <C.Column size='10'>
                    <C.Columns horizontalFlow>
                        <C.Column verticalFlow size='6' className='vertical-gutter'>
                            <p className='is-small'>
                                <C.NumberLabel value={trocaFemoral} className='is-semibold' />{' '}
                                <C.PercentageLabel value={trocaFemoral / total} className='is-grey-darker' /> Troca do
                                componente femoral
                            </p>
                            <p className='is-small'>
                                <C.NumberLabel value={trocaPatelar} className='is-semibold' />{' '}
                                <C.PercentageLabel value={trocaPatelar / total} className='is-grey-darker' /> Troca do
                                componente patelar
                            </p>
                            <p className='is-small'>
                                {' '}
                                <C.NumberLabel value={naoConvencional} className='is-semibold' />{' '}
                                <C.PercentageLabel value={naoConvencional / total} className='is-grey-darker' /> Não
                                convencional
                            </p>
                        </C.Column>
                        <C.Column verticalFlow size='6' className='vertical-gutter'>
                            <p className='is-small'>
                                {' '}
                                <C.NumberLabel value={trocaComponenteTibial} className='is-semibold' />{' '}
                                <C.PercentageLabel value={trocaComponenteTibial / total} className='is-grey-darker' />{' '}
                                Troca do componente tibial
                            </p>
                            <p className='is-small'>
                                <C.NumberLabel value={trocaPolietilenoTibial} className='is-semibold' />{' '}
                                <C.PercentageLabel value={trocaPolietilenoTibial / total} className='is-grey-darker' />{' '}
                                Troca do polietileno tibial
                            </p>
                        </C.Column>
                    </C.Columns>
                </C.Column>
            </C.Columns>
        </div>
    )
}

let TiposArtroplastiaRevisaoQuadril = props => {
    const tipos = props.tipos
    let total = props.quantidadeProcedimentos
    let hasteFemoral = 0
    let cabecaFemoral = 0
    let insertoAcetabular = 0
    let componenteAcetabular = 0
    let trocaTodos = 0
    let trocaFemorais = 0
    tipos &&
        Object.entries(tipos).forEach(([index, value]: [any, any]) => {
            const quantidade = value.quantidadeProcedimentos
            if (value.tipoRevisao === 'HASTE_FEMORAL') {
                hasteFemoral = quantidade
            } else if (value.tipoRevisao === 'CABECA_FEMORAL') {
                cabecaFemoral = quantidade
            } else if (value.tipoRevisao === 'INSERTO_ACETABULAR') {
                insertoAcetabular = quantidade
            } else if (value.tipoRevisao === 'COMPONENTE_ACETABULAR') {
                componenteAcetabular = quantidade
            } else if (value.tipoRevisao === 'TROCA_TODOS') {
                trocaTodos = quantidade
            } else if (value.tipoRevisao === 'TROCA_FEMORAIS') {
                trocaFemorais = quantidade
            }
        })
    return (
        <C.Columns>
            <C.Column className='has-border-right has-text-centered' size='2' alignedCenter fullHeight>
                <C.ComposedLabel title='Procedimentos' labelClassName='is-muted' inverted big>
                    <C.NumberLabel value={total} />
                </C.ComposedLabel>
            </C.Column>
            <C.Column size='10'>
                <C.Columns horizontalFlow>
                    <C.Column verticalFlow size='6' className='vertical-gutter'>
                        <p className='is-small'>
                            <C.NumberLabel value={hasteFemoral} className='is-semibold' />{' '}
                            <C.PercentageLabel value={hasteFemoral / total} className='is-grey-darker' /> Haste femoral
                        </p>
                        <p className='is-small'>
                            <C.NumberLabel value={insertoAcetabular} className='is-semibold' />{' '}
                            <C.PercentageLabel value={insertoAcetabular / total} className='is-grey-darker' /> Inserto
                            acetabular
                        </p>
                        <p className='is-small'>
                            <C.NumberLabel value={trocaFemorais} className='is-semibold' />{' '}
                            <C.PercentageLabel value={trocaFemorais / total} className='is-grey-darker' /> Troca
                            componentes femorais
                        </p>
                    </C.Column>
                    <C.Column verticalFlow size='6' className='vertical-gutter'>
                        <p className='is-small'>
                            <C.NumberLabel value={cabecaFemoral} className='is-semibold' />{' '}
                            <C.PercentageLabel value={cabecaFemoral / total} className='is-grey-darker' /> Cabeça
                            femoral
                        </p>
                        <p className='is-small'>
                            <C.NumberLabel value={componenteAcetabular} className='is-semibold' />{' '}
                            <C.PercentageLabel value={componenteAcetabular / total} className='is-grey-darker' />{' '}
                            Componente acetabular
                        </p>
                        <p className='is-small'>
                            <C.NumberLabel value={trocaTodos} className='is-semibold' />{' '}
                            <C.PercentageLabel value={trocaTodos / total} className='is-grey-darker' /> Troca todos
                            componentes{' '}
                        </p>
                    </C.Column>
                </C.Columns>
            </C.Column>
        </C.Columns>
    )
}

interface Props {
    onClick?: () => void
    tiposRevisoes?: TipoRevisaoProcedimentos[]
    tipoProcedimento: TipoProcedimento
    quantidadeProcedimentos: number
}

export class PorcentagemTipoArtroplastiaRevisaoCard extends React.Component<Props> {
    render() {
        const tipo = this.props.tipoProcedimento
        return tipo === 'REVISAO_JOELHO' ? (
            <TiposArtroplastiaRevisaoJoelho
                quantidadeProcedimentos={this.props.quantidadeProcedimentos}
                tipos={this.props.tiposRevisoes}
            />
        ) : (
            <TiposArtroplastiaRevisaoQuadril
                quantidadeProcedimentos={this.props.quantidadeProcedimentos}
                tipos={this.props.tiposRevisoes}
            />
        )
    }
}
