import { RecebimentoIntegracaoDto } from 'backend'
import React from 'react'
import { RecebimentoIntegracaoRowHeader } from '../row/RecebimentoIntegracaoRowHeader'
import { RecebimentoIntegracaoInnerRowRecusado } from './RecebimentoIntegracaoInnerRowRecusado'

interface RecebimentoIntegracaoRowRecusadoProps {
    date: string
    value: RecebimentoIntegracaoDto[]
    onClickButton: (row: RecebimentoIntegracaoDto) => (event: React.MouseEvent) => void
}

export const RecebimentoIntegracaoRowRecusado = (props: RecebimentoIntegracaoRowRecusadoProps) => {
    const { date, value, onClickButton } = props
    return (
        <div className='recusados-row'>
            <RecebimentoIntegracaoRowHeader date={date} lenghtInnerRows={value.length} />
            {value.map((row: RecebimentoIntegracaoDto, _: number) => (
                <RecebimentoIntegracaoInnerRowRecusado key={row.id} value={row} onClickButton={onClickButton(row)} />
            ))}
        </div>
    )
}
