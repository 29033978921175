import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { success } from 'redux/modules/alert'
import { RelacaoEmpresasFormView } from './components/RelacaoEmpresasFormView'
import { RelacaoEmpresasHeader } from './components/RelacaoEmpresasHeader'
import RelacaoEmpresas from 'api/RelacaoEmpresas'
import { clearRelacaoEmpresas, getRelacaoEmpresas, hideNotificationDot } from 'redux/modules/relacaoEmpresas'
import { State } from 'redux/modules'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { RelacaoEmpresasForm } from 'backend'

export const RelacaoEmpresasView: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    React.useEffect(() => {
        dispatch(getRelacaoEmpresas())
        return () => dispatch(clearRelacaoEmpresas())
    }, [dispatch])

    const initialValues = useSelector<State, PromiseRecord<RelacaoEmpresasForm>>(state => state.relacaoEmpresas.relacao)

    const handleSubmitSuccess = () => {
        dispatch(success({ mensagem: 'Informações salvas com sucesso.' }))
        history.push('/home')
    }

    const hideNotification = () => dispatch(hideNotificationDot())

    const handleSubmit = form => {
        const updateId: number = form.get('id')
        if (updateId) {
            return RelacaoEmpresas.updateRelacao(updateId, form).then(hideNotification)
        } else {
            return RelacaoEmpresas.saveRelacao(form).then(hideNotification)
        }
    }

    return (
        <>
            <RelacaoEmpresasHeader />
            <RelacaoEmpresasFormView
                initialValues={initialValues?.data}
                onSubmit={handleSubmit}
                onSubmitSuccess={handleSubmitSuccess}
            />
        </>
    )
}
