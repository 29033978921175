/* eslint-disable no-undef */
import * as React from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { DistribuicaoProcedimentoRow } from 'backend'
import { MultiplosProcedimentosFiltro } from 'model'
import { State } from 'redux/modules'
import * as multiplosProcedimentos from 'redux/modules/report/multiplos'
import { HeatMap, Tags, Tag, CardFilterable, LoadingStateContainer } from 'libs/components'
import { Map } from 'immutable'
import { MultiplosProcedimentosMapFilter } from './MultiplosProcedimentosMapFilter'
import * as enums from 'redux/enums'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface Props extends RouteComponentProps {
    map: PromiseRecord<DistribuicaoProcedimentoRow[]>
    filtroGeral: MultiplosProcedimentosFiltro
    load(filtro: MultiplosProcedimentosFiltro): void
}

interface S {
    filter: MultiplosProcedimentosFiltro
}

export class MultiplosProcedimentosMap extends React.Component<Props, S> {
    constructor(props: Props) {
        super(props)
        this.state = {
            filter: {
                procedimentoMode: 'PRIMARIA_REVISAO',
                medicoMode: 'MESMO',
            },
        }
    }

    componentDidMount() {
        this.props.load(this.state.filter)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (!isEqual(this.props.filtroGeral, nextProps.filtroGeral)) {
            this.props.load(this.state.filter)
        }
    }

    onApplyFilter = (values: Map<string, any>) => {
        this.setState(
            {
                filter: values.toJS(),
            },
            () => {
                this.props.load(this.state.filter)
            }
        )
    }

    render() {
        const data = []
        if (this.props.map.data) {
            this.props.map.data.forEach(row => {
                if (row.local.hospital.lat && row.local.hospital.lng) {
                    // LatLng pode ser nula para hospitais cuja geolocalização ainda não foi definida
                    data.push({
                        location: new google.maps.LatLng(row.local.hospital.lat, row.local.hospital.lng),
                        weight: row.total,
                    })
                }
            })
        }

        return (
            <CardFilterable
                noPadding={this.props.map.readyState === 'success'}
                title='Múltiplos procedimentos no mesmo hospital'
                headerTools={this.renderHeaderTools()}
                filter={<MultiplosProcedimentosMapFilter onSubmit={this.onApplyFilter} />}
            >
                <LoadingStateContainer readyState={this.props.map.readyState}>
                    <HeatMap data={data} />
                </LoadingStateContainer>
            </CardFilterable>
        )
    }

    renderHeaderTools() {
        return (
            <div>
                <Tags>
                    <Tag>{enums.procedimentoModeName[this.state.filter.procedimentoMode]}</Tag>
                    <Tag>{enums.medicoModeName[this.state.filter.medicoMode]}</Tag>
                </Tags>
            </div>
        )
    }
}

export const mapStateToProps = (state: State) => ({
    filtroGeral: state.report.filtro.filtro,
    map: state.report.multiplos.map,
})

export const mapDispatchToProps = (dispatch, ownProps) => ({
    load: (filtro: MultiplosProcedimentosFiltro) => {
        dispatch(
            multiplosProcedimentos.findMap({
                ...filtro,
                tipoCirurgia: ownProps.match.params.tipoCirurgia,
                localMode: 'MESMO_HOSPITAL',
            })
        )
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MultiplosProcedimentosMap))
