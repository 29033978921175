import * as React from 'react'
import { HomeGestorServicoSaudeHeader } from '../HomeGestorServicoSaudeHeader'
import TipoRevisaoDashboardView from 'view/componentes/reports/revisao/TipoRevisaoDashboardView'

export const GestorServicoSaudeTipoRevisaoDashboardView = () => {
    return (
        <>
            <HomeGestorServicoSaudeHeader />
            <TipoRevisaoDashboardView />
        </>
    )
}
