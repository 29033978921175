
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.45,"time":27000,"words":90}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Após a solicitação de cadastro o gestor de segurança pode aprovar ou recusar vínculos solicitados a uma determinada instituição.`}</p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Vínculos pendentes”`}</li>
      <li parentName="ol">{`No profissional desejado:`}</li>
    </ol>
    <p><strong parentName="p">{`ACEITAR O VÍNCULO`}</strong></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Clique no ícone `}<img parentName="li" {...{
          "src": "/images/iconeAceitar.png#center",
          "alt": "Aprovar"
        }}></img>{` para aprovar o vínculo do profissional`}</li>
    </ol>
    <p><strong parentName="p">{`RECUSAR O VÍNCULO`}</strong></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Clique no ícone `}<img parentName="li" {...{
          "src": "/images/iconeRecusar.png#center",
          "alt": "Recusar"
        }}></img>{` para recusar o vínculo do profissional`}</li>
      <li parentName="ol">{`Informe a justificativa para rejeição`}</li>
      <li parentName="ol">{`Clique no botão “Salvar”`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como aprovar ou recusar vínculos pendentes no RNI, consulte o vídeo explicativo abaixo:`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

