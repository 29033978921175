import * as React from 'react'
import { ErrorIndicator } from './ErrorIndicator'
import { Icon } from '../elements/Icon'
import { Label } from '../elements/Label'
import { Props as InfoIconProps, InformationIconPopover } from './InformationIconPopover'

export interface FormLabelProps extends Pick<InfoIconProps, 'information'> {
    labelClassName?: string
    error?: any
    label?: string
    required?: boolean
    disabled?: boolean
}

export class FormLabel extends React.Component<FormLabelProps, any> {
    render() {
        const errorIndicator = this.props.error && <ErrorIndicator error={this.props.error} />

        const fieldRequired = this.props.required && !this.props.error && (
            <Icon className='field-required' size='is-x-small' icon='obrigatorio' />
        )

        return (
            <Label className={this.props.labelClassName} disabled={this.props.disabled}>
                <span>{this.props.label}</span>
                {fieldRequired}
                {errorIndicator}
                {this.props.information && (
                    <InformationIconPopover
                        spaced={this.props.error}
                        information={this.props.information}
                        size='auto'
                    />
                )}
            </Label>
        )
    }
}
