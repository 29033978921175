import * as React from 'react'
import {
    AlertaMotivoRevisaoConfigForm,
    UserDTO,
    AlertaAumentoRegistroConfigForm,
    MotivoProcedimentoEnum,
} from 'backend'
import { RouteComponentProps } from 'react-router-dom'
import Text from 'libs/components/presentational/typography/Text'
import AlertaConfigCard from 'view/home/anvisa/alertas/components/AlertaConfigCard'
import EtiquetaSemRegistroModalForm from 'view/home/anvisa/alertas/modals/EtiquetaSemRegistroModalForm'
import MotivoRevisaoJoelhoModalForm from 'view/home/anvisa/alertas/modals/MotivoRevisaoJoelhoModalForm'
import MotivoRevisaoQuadrilModalForm from 'view/home/anvisa/alertas/modals/MotivoRevisaoQuadrilModalForm'
import InternalForm from 'view/home/anvisa/alertas/modals/AumentoRegistroModalForm'
import { MotivosProcedimento } from 'redux/enums'

interface AlertasConfigViewProps extends RouteComponentProps<any> {
    usuarioLogado: UserDTO & { perfilUnico: boolean }
}

export default class AlertasConfigView extends React.PureComponent<AlertasConfigViewProps> {
    render() {
        return (
            <div className='container configurar-alertas-page'>
                <div className='configurar-alertas-page-header'>
                    <Text color='black-bis' size='x-large'>
                        Configurações de alarmes
                    </Text>
                </div>
                <div className='configurar-alertas-page-body'>
                    <div className='title-tipo-alerta'>Etiqueta sem registro da ANVISA</div>
                    <section>
                        <AlertaConfigCard
                            titulo='Etiqueta s/ registro ANVISA'
                            tipoAlerta='ETIQUETA_SEM_REGISTRO'
                            dataName='configurar_etiqueta_sem_registro'
                            baseApiUrl='/api/alerta/config/etiquetaSemRegistro'
                            modalComponent={EtiquetaSemRegistroModalForm}
                            tituloModal='Etiqueta sem registro da ANVISA'
                            usuarioLogado={this.props.usuarioLogado}
                        />
                    </section>
                    <div className='title-tipo-alerta'>Motivos de revisão</div>
                    <section>
                        <AlertaConfigCard
                            titulo='Joelho'
                            tipoAlerta='MOTIVO_REVISAO'
                            dataName='configurar_motivo_revisao_joelho'
                            baseApiUrl='/api/alerta/config/motivoRevisaoJoelho'
                            modalComponent={MotivoRevisaoJoelhoModalForm}
                            tituloModal='Motivo de revisão - Joelho'
                            usuarioLogado={this.props.usuarioLogado}
                            renderChildren={(config: AlertaMotivoRevisaoConfigForm) => (
                                <MotivosSelecionados motivos={config.motivos} />
                            )}
                        />
                        <AlertaConfigCard
                            titulo='Quadril'
                            tipoAlerta='MOTIVO_REVISAO'
                            dataName='configurar_motivo_revisao_quadril'
                            baseApiUrl='/api/alerta/config/motivoRevisaoQuadril'
                            modalComponent={MotivoRevisaoQuadrilModalForm}
                            tituloModal='Motivo de revisão - Quadril'
                            usuarioLogado={this.props.usuarioLogado}
                            renderChildren={(config: AlertaMotivoRevisaoConfigForm) => (
                                <MotivosSelecionados motivos={config.motivos} />
                            )}
                        />
                    </section>
                    <div className='title-tipo-alerta'>Aumento de registros</div>
                    <section>
                        <AlertaConfigCard
                            titulo='Primária - Joelho'
                            tipoAlerta='AUMENTO_DE_REGISTROS'
                            dataName='configurar_aumento_primaria_joelho'
                            baseApiUrl='/api/alerta/config/aumentoRegistroPrimariaJoelho'
                            modalComponent={InternalForm}
                            tituloModal='Aumento de registros - Primária - Joelho'
                            usuarioLogado={this.props.usuarioLogado}
                            renderChildren={(config: AlertaAumentoRegistroConfigForm) => (
                                <PercentualPeriodo config={config} />
                            )}
                        />
                        <AlertaConfigCard
                            titulo='Primária - Quadril'
                            tipoAlerta='AUMENTO_DE_REGISTROS'
                            dataName='configurar_aumento_primaria_quadril'
                            baseApiUrl='/api/alerta/config/aumentoRegistroPrimariaQuadril'
                            modalComponent={InternalForm}
                            tituloModal='Aumento de registros - Primária - Quadril'
                            usuarioLogado={this.props.usuarioLogado}
                            renderChildren={(config: AlertaAumentoRegistroConfigForm) => (
                                <PercentualPeriodo config={config} />
                            )}
                        />
                        <AlertaConfigCard
                            titulo='Revisão - Joelho'
                            tipoAlerta='AUMENTO_DE_REGISTROS'
                            dataName='configurar_aumento_revisao_joelho'
                            baseApiUrl='/api/alerta/config/aumentoRegistroRevisaoJoelho'
                            modalComponent={InternalForm}
                            tituloModal='Aumento de registros - Revisão - Joelho'
                            usuarioLogado={this.props.usuarioLogado}
                            renderChildren={(config: AlertaAumentoRegistroConfigForm) => (
                                <PercentualPeriodo config={config} />
                            )}
                        />
                        <AlertaConfigCard
                            titulo='Revisão - Quadril'
                            tipoAlerta='AUMENTO_DE_REGISTROS'
                            dataName='configurar_aumento_revisao_quadril'
                            baseApiUrl='/api/alerta/config/aumentoRegistroRevisaoQuadril'
                            modalComponent={InternalForm}
                            tituloModal='Aumento de registros - Revisão - Quadril'
                            usuarioLogado={this.props.usuarioLogado}
                            renderChildren={(config: AlertaAumentoRegistroConfigForm) => (
                                <PercentualPeriodo config={config} />
                            )}
                        />
                        <AlertaConfigCard
                            titulo='Stent de coronária'
                            tipoAlerta='AUMENTO_DE_REGISTROS'
                            dataName='configurar_aumento_stent_coronaria'
                            baseApiUrl='/api/alerta/config/aumentoRegistroStentCoronaria'
                            modalComponent={InternalForm}
                            tituloModal='Aumento de registros - Stent de coronária'
                            usuarioLogado={this.props.usuarioLogado}
                            renderChildren={(config: AlertaAumentoRegistroConfigForm) => (
                                <PercentualPeriodo config={config} />
                            )}
                        />
                    </section>
                </div>
            </div>
        )
    }
}

interface MotivosSelecionadosProps {
    motivos: MotivoProcedimentoEnum[]
}

export class MotivosSelecionados extends React.PureComponent<MotivosSelecionadosProps> {
    render() {
        return (
            <div>
                {this.props.motivos &&
                    this.props.motivos
                        .filter((motivo, index) => index < 3)
                        .map((motivo, index) => [
                            <Text key={'nome' + motivo} color='grey-darker' size='x-small'>
                                {MotivosProcedimento[motivo].descricao}
                                {index < this.props.motivos.length - 1 && '; '}
                            </Text>,
                            <br key={'quebra' + motivo} />,
                        ])}
                {this.props.motivos && this.props.motivos.length > 3 && (
                    <Text color='grey-darker' size='x-small' weight='bold'>
                        +{this.props.motivos.length - 3}
                    </Text>
                )}
            </div>
        )
    }
}

interface PercentualPeriodoProps {
    config: AlertaAumentoRegistroConfigForm
}

export class PercentualPeriodo extends React.PureComponent<PercentualPeriodoProps> {
    render() {
        return (
            <span>
                {this.props.config.tipoPeriodo && (
                    <Text color='grey-darker' size='x-small'>
                        +{this.props.config.percentual}% {this.props.config.tipoPeriodo.toLowerCase()}
                    </Text>
                )}
            </span>
        )
    }
}
