import * as React from 'react'
import classnames from 'classnames'
import { Link, Route } from 'react-router-dom'

interface StepsProps {
    className?: string
    subSteps?: boolean
    checked?: boolean
    active?: boolean
    title?: string
}

export class Steps extends React.Component<StepsProps, any> {
    render() {
        const classes = classnames(this.props.className, {
            steps: !this.props.subSteps,
            'sub-steps': this.props.subSteps,
        })

        let checked = !this.props.subSteps || this.props.checked || this.props.active
        const title = this.props.title && <label className='title'>{this.props.title}</label>

        return (
            <div className={classes}>
                {title}
                <ul>
                    {this.props.children &&
                        React.Children.map(this.props.children, (child: any) => {
                            return (
                                <Route
                                    path={child.props.path}
                                    children={({ match }) => {
                                        if (match) {
                                            checked = false
                                        }
                                        return React.cloneElement(child, {
                                            active: match,
                                            checked: checked,
                                            className: this.props.subSteps ? 'sub-step' : 'step',
                                        })
                                    }}
                                />
                            )
                        })}
                </ul>
            </div>
        )
    }
}

interface StepProps extends StepsProps {
    name: string
    path: string
    checked?: boolean
    active?: boolean
    classname?: string
    hasLink?: boolean
    linkActive?: boolean
}

export class Step extends React.Component<StepProps, any> {
    render() {
        const classes = classnames(this.props.className, {
            checked: this.props.checked,
            active: this.props.active,
        })
        const stepTitle = <span className={this.props.className + '-name'}>{this.props.name}</span>
        return (
            <li className={classes}>
                <span className='step-info'>
                    {this.props.hasLink && this.props.linkActive ? (
                        <Link to={this.props.path} replace={true}>
                            {stepTitle}
                        </Link>
                    ) : (
                        stepTitle
                    )}
                </span>
                {this.props.children &&
                    React.Children.map(this.props.children, (child: any) => {
                        return React.cloneElement(child, {
                            active: this.props.active,
                            checked: this.props.checked,
                            subSteps: true,
                        })
                    })}
            </li>
        )
    }
}
