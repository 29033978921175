import { createValidator, maxLength, required, alfaNumerico, msg } from 'libs/validation'
import { notBlank } from 'libs/validation/rules/notBlank'
import periodoVigenciaFormValidator from 'libs/components/presentational/form/composed/PeriodoVigenciaFormValidator'
import immutableProxy from 'libs/immutableProxy'
import { RelacaoEmpresasForm } from 'backend'

const formValidator = (form, _) => {
    const errors = {} as any
    const possuiRelacao = form.get('possuiRelacao')
    if (possuiRelacao === 'NAO_RESPONDEU') {
        errors.possuiRelacao = msg('required', possuiRelacao)
    }
    if (possuiRelacao === 'SIM') {
        errors.empresas = required(form.get('empresas'))
    }
    return errors
}

export const RelacaoEmpresasFormValidator = createValidator({ possuiRelacao: [required] }, formValidator)

const modalValidator = (formMap, errors) => {
    const form = immutableProxy<RelacaoEmpresasForm | any>(formMap)

    const periodoVigenciaErrors = periodoVigenciaFormValidator(form)

    let innerErrors = { ...errors, ...periodoVigenciaErrors }

    return innerErrors
}

export const RelacaoEmpresasModalValidator = createValidator(
    {
        nomeEmpresa: [required, maxLength(100), notBlank, alfaNumerico],
        relacoes: [required],
    },
    modalValidator
)
