import React, { useEffect } from 'react'
import { LoadingStateContainer, Button, Columns, Column } from 'libs/components'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import erroLink from 'images/icons/erro-link.svg'
import expired from 'images/icons/confirmacao-expirado.svg'
import confirmed from 'images/icons/email-confirmado.svg'
import { ValidacaoEmailDto } from 'backend'

interface ConfirmacaoEmailViewProps {
    validateEmail: (idCadastro: string) => void
    onCancel: () => void
    onRetomar: () => void
    isValid: PromiseRecord<ValidacaoEmailDto>
    idCadastro: string
}

export function ConfirmacaoEmailView({ validateEmail, idCadastro, ...props }: ConfirmacaoEmailViewProps) {
    useEffect(() => {
        validateEmail(idCadastro)
    }, [validateEmail, idCadastro])

    return (
        <LoadingStateContainer readyState={props.isValid.readyState}>
            {props.isValid.data && (
                <div className='container is-vertical-flow confirmacao'>
                    {props.isValid.data.tokenValidacao === 'VALIDO' && (
                        <ValidEmailConfirmation voltar={props.onCancel} />
                    )}
                    {props.isValid.data.tokenValidacao === 'EXPIRADO' && (
                        <ExpiredEmailConfirmation voltar={props.onRetomar} />
                    )}
                    {props.isValid.data.tokenValidacao === 'INVALIDO' && (
                        <InvalidEmailConfirmation voltar={props.onCancel} />
                    )}
                </div>
            )}
        </LoadingStateContainer>
    )
}

const ValidEmailConfirmation = props => {
    return (
        <Columns>
            <Column size='5'>
                <div className='has-text-centered'>
                    <img src={confirmed} alt='Email confirmado' />
                </div>
            </Column>
            <Column size='5'>
                <div className='confirmacao-titulo'>Endereço de e-mail confirmado</div>
                <div className='confirmacao-corpo'>
                    O Gestor de Segurança Hospitalar irá analisar sua <br />
                    solicitação de acesso e, em breve, você receberá <br />
                    um e-mail informando o resultado da análise.
                </div>
                <div>
                    <Button pulledLeft icon='voltar' size='medium' onClick={props.voltar}>
                        Página inicial
                    </Button>
                </div>
            </Column>
        </Columns>
    )
}

const InvalidEmailConfirmation = props => {
    return (
        <Columns>
            <Column size='5'>
                <div className='has-text-centered'>
                    <img src={erroLink} alt='Link inválido' />
                </div>
            </Column>
            <Column size='5'>
                <div className='confirmacao-titulo'>Link inválido</div>
                <div className='confirmacao-corpo'>
                    O link que você está tentando acessar é inválido <br />
                    ou está expirado.
                </div>
                <div>
                    <Button pulledLeft icon='voltar' size='medium' onClick={props.voltar}>
                        Página inicial
                    </Button>
                </div>
            </Column>
        </Columns>
    )
}

const ExpiredEmailConfirmation = props => {
    return (
        <Columns>
            <Column size='5'>
                <div className='has-text-centered'>
                    <img src={expired} alt='Link expirado' />
                </div>
            </Column>
            <Column size='5'>
                <div className='confirmacao-titulo'>Prazo de confirmação expirado</div>
                <div className='confirmacao-corpo'>
                    O prazo para a confirmação do <br />
                    endereço de e-mail cadastrado expirou <br />
                    e suas informações de cadastro foram excluídas do sistema. <br />
                    <br />
                    Para realizar seu cadastro, preencha e <br />
                    envie as informações de cadastro
                    <br /> novamente.
                </div>
                <div>
                    <Button pulledLeft icon='voltar' size='medium' onClick={props.voltar}>
                        Refazer cadastro
                    </Button>
                </div>
            </Column>
        </Columns>
    )
}
