import React from 'react'
import * as C from 'libs/components'

export class ICCCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='ICC'
                name='iccPrevia'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='iccPrevia' />}
            >
                <C.Conteudo>
                    <C.ControlField horizontalFlow label='Classe funcional' name='iccPreviaClasseFuncional'>
                        <C.StyledRadioField skin='square-button' name='iccPreviaClasseFuncional' value='I'>
                            I
                        </C.StyledRadioField>
                        <C.StyledRadioField skin='square-button' name='iccPreviaClasseFuncional' value='II'>
                            II
                        </C.StyledRadioField>
                        <C.StyledRadioField skin='square-button' name='iccPreviaClasseFuncional' value='III'>
                            III
                        </C.StyledRadioField>
                        <C.StyledRadioField skin='square-button' name='iccPreviaClasseFuncional' value='IV'>
                            IV
                        </C.StyledRadioField>
                        <C.ClearRadioFieldButton name='iccPreviaClasseFuncional' />
                    </C.ControlField>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
