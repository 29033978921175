/* tslint:disable */
import React from 'react'

const SvgArrowRight = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M15.395 10.991L9.065 5 8 6.009 14.33 12 8 17.991 9.066 19l6.33-5.991.038.037 1.066-1.01-.039-.036.039-.037-1.066-1.009-.039.037z' />
    </svg>
)

export default SvgArrowRight
