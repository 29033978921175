import * as React from 'react'
import classnames from 'classnames'
import * as Util from 'libs/components/Util'
import { CSSProperties } from 'react'

export interface ColumnProps extends Util.UtilProps {
    className?: string
    size?: string
    offset?: any
    customStyle?: CSSProperties
}

export class Column extends React.Component<ColumnProps, any> {
    render() {
        const classes = classnames('column', this.props.className, Util.helpersClassnames(this.props), {
            'is-half': this.props.size && this.props.size === 'half',

            'is-11': this.props.size && this.props.size === '11',
            'is-10': this.props.size && this.props.size === '10',
            'is-9': this.props.size && this.props.size === '9',
            'is-8': this.props.size && this.props.size === '8',
            'is-7': this.props.size && this.props.size === '7',
            'is-6': this.props.size && this.props.size === '6',
            'is-5': this.props.size && this.props.size === '5',
            'is-4': this.props.size && this.props.size === '4',
            'is-3': this.props.size && this.props.size === '3',
            'is-2': this.props.size && this.props.size === '2',
            'is-1': this.props.size && this.props.size === '1',

            'is-offset-one-quarter': this.props.offset && this.props.offset === 'one-quarter',

            'is-offset-1': this.props.offset && this.props.offset === '1',
            'is-offset-2': this.props.offset && this.props.offset === '2',
            'is-offset-3': this.props.offset && this.props.offset === '3',
            'is-offset-4': this.props.offset && this.props.offset === '4',
            'is-offset-5': this.props.offset && this.props.offset === '5',
        })

        return (
            <div style={{ ...this.props.customStyle }} className={classes}>
                {this.props.children}
            </div>
        )
    }
}
