import { useRouter } from 'hooks/useRouter'
import { Column, Columns, FloatingContainer, ScrollToTop, Step, Steps } from 'libs/components'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, Redirect, Route, Switch } from 'react-router-dom'
import { change, destroy, initialize } from 'redux-form'
import { hasSubmitSucceeded, isPristine } from 'redux-form/immutable'
import {
    clearRastreabilidade,
    saveDadosProdutoAdministrativo,
    validateDadosProdutoAdministrativo,
    updateDadosProdutoAdministrativo,
} from 'redux/modules/rastreabilidade'
import { InformacoesProcedimento } from '../componentes'
import ModalInformacoesProcedimento from '../componentes/ModalInformacoesProcedimento'
import RastreabilidadeForm from '../RastreabilidadeForm'
import { RastreabilidadeGeralForm } from './RastreabilidadeGeralForm'
import RastreabilidadeGeralValidator from './RastreabilidadeGeralValidator'
import { usePesquisaSatisfacaoPopup } from 'view/componentes/pesquisaSatisfacao/usePesquisaSatisfacaoPopup'
import { clearEdicaoProcedimentoData, EdicaoRastreabilidadeState } from 'redux/modules/edicaoProcedimento'
import { State } from 'redux/modules'
import { handleErrorResolver, handleErrorSubmiting } from '../common/RastreabilidadeErrorUtil'

const formName = 'rastreabilidadeForm'

export const RastreabilidadeGeralView = ({ editMode = false }) => {
    const path = editMode ? '/home/edicaoProcedimento/dadosProdutoFinanceiro/' : '/home/dadosProdutoFinanceiro/'
    const { location, history } = useRouter()
    const submitSucceeded = useSelector(state => hasSubmitSucceeded(formName)(state))
    const pristine = useSelector(state => isPristine(formName)(state))
    const rastreabilidade = useSelector((state: any) => state.rastreabilidade)
    const procedimento = rastreabilidade?.findProcedimento.data
    const dispatch = useDispatch()
    const showModalLeave = useRef<boolean>(true)
    const { showPesquisa } = usePesquisaSatisfacaoPopup()

    const promptCallback = useCallback(
        location => {
            if (!submitSucceeded && location.pathname.indexOf(path) === -1 && !pristine && showModalLeave.current) {
                return 'mensagem não usada'
            }
        },
        [path, pristine, submitSucceeded]
    )

    // Mimics componentWillUnmount
    useEffect(
        () => () => {
            dispatch(destroy(formName))
            dispatch(clearRastreabilidade())
            dispatch(clearEdicaoProcedimentoData())
        },
        [dispatch]
    )

    const [openModalInformacoesProcedimento, setOpenModalInformacoesProcedimento] = useState<boolean>(false)
    const [atualFormItemName, setAtualFormItemName] = useState<string>('')
    const next = (index: number) => form => {
        const componente = procedimento.componentes[index]
        const formItemName = 'componente-' + componente.id
        setAtualFormItemName(formItemName)

        let formItem = form.get(formItemName)
        formItem = formItem.setIn(['dadosProdutoForm', 'idProcedimento'], procedimento.id)
        formItem = formItem.setIn(['dadosProdutoForm', 'idRegistroProcedimentoComp'], componente.id)
        formItem = formItem.setIn(['dadosFinanceirosForm', 'idProcedimento'], procedimento.id)
        formItem = formItem.set('idRegistroProcedimentoComp', componente.id)

        return dispatch(validateDadosProdutoAdministrativo(formItem) as any).then(() => {
            formItem = formItem.set('nomeComponente', componente.nome)
            form = form.set(formItemName, formItem)
            dispatch(change(formName, formItemName, formItem))

            if (index === procedimento.componentes.length - 1) {
                if (editMode) {
                    return dispatch<Promise<any>>(
                        updateDadosProdutoAdministrativo({
                            rastreabilidadeForm: form.toArray(),
                            idProcedimento: procedimento.id,
                        }) as any
                    )
                } else {
                    return dispatch(
                        saveDadosProdutoAdministrativo({
                            rastreabilidadeForm: form.toArray(),
                            idProcedimento: procedimento.id,
                        }) as any
                    )
                }
            }
        })
    }

    const afterSave = (index: number) => () => {
        if (index < procedimento.componentes.length - 1) {
            history.replace(path + (index + 1))
        } else {
            showPesquisa()
            history.push('/home')
        }

        if (!editMode) {
            dispatch(initialize(formName, {}, { keepDirty: true, keepSubmitSucceeded: false }))
        }
    }

    const onCancel = () => history.push('/home')

    const handleSubmitFail = e => {
        // generic error here always send to /home
        if (e && typeof e[atualFormItemName] === 'string') {
            showModalLeave.current = false
            history.push('/home')
        }
    }

    const localHandleErrorSubmitting = error => {
        return handleErrorSubmiting(error, atualFormItemName)
    }

    const localHandleErrorResolver = error => {
        return handleErrorResolver(error, atualFormItemName)
    }

    const editingValues: EdicaoRastreabilidadeState = useSelector(
        (state: State) => state.edicao as EdicaoRastreabilidadeState
    )

    let thevalues = {}
    // eslint-disable-next-line
    editingValues?.rastreabilidadeForm?.forEach(prod => {
        return (thevalues[`componente-${prod.idRegistroProcedimentoComp}`] = prod)
    })

    const componentesSteps = () => {
        return (
            <Steps title='Componentes utilizados'>
                {procedimento.componentes.map((componente, index) => {
                    const url = location.pathname
                    const urlParam = Number(url.charAt(url.length - 1))
                    return (
                        <Step
                            key={index}
                            name={componente.nome}
                            hasLink
                            linkActive={index < urlParam}
                            path={path + `${index}`}
                        />
                    )
                })}
            </Steps>
        )
    }

    return (
        <Columns fullHeight>
            <ScrollToTop />
            <Column verticalFlow size='4'>
                <div className='rastreabilidade-sidebar is-full-height'>
                    <InformacoesProcedimento
                        open={() => setOpenModalInformacoesProcedimento(true)}
                        procedimento={procedimento}
                    />
                    <FloatingContainer stopElement='footer'>{componentesSteps()}</FloatingContainer>
                </div>
            </Column>
            <Column className='procedimento-conteudo'>
                <Prompt message={promptCallback} />
                <Switch>
                    <Route exact path={path} render={() => <Redirect to={path + '0'} />} />

                    {procedimento.componentes.map((componente, index) => (
                        <Route
                            key={index}
                            path={path + `${index}`}
                            render={renderProps => (
                                <RastreabilidadeForm
                                    {...renderProps}
                                    editinValues={thevalues}
                                    destroyOnUnmount={false}
                                    forceUnregisterOnUnmount={true}
                                    formComponent={RastreabilidadeGeralForm}
                                    formName={formName}
                                    formSectionName={`componente-${componente.id}`}
                                    validator={RastreabilidadeGeralValidator}
                                    onSubmit={next(index)}
                                    onSubmitSuccess={afterSave(index)}
                                    onSubmitFail={handleSubmitFail}
                                    errorResolver={localHandleErrorResolver}
                                    onErrorSubmitting={localHandleErrorSubmitting}
                                    canCopy={index > 0}
                                    canSave={index + 1 === procedimento.componentes.length}
                                    componente={componente}
                                    cancelar={onCancel}
                                    index={index}
                                    editMode={editMode}
                                    origemExterna={procedimento.origemExterna}
                                />
                            )}
                        />
                    ))}
                </Switch>
            </Column>
            <ModalInformacoesProcedimento
                open={openModalInformacoesProcedimento}
                procedimento={procedimento}
                close={() => setOpenModalInformacoesProcedimento(false)}
            />
        </Columns>
    )
}
