/* tslint:disable */
import React from 'react'

const SvgNomeModelo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M17.768 5.475L20.243 3l.757.757-2.475 2.475 1.893 1.894-1.515 4.543L10.574 21 3 13.426l8.33-8.33 4.544-1.514 1.894 1.893zm-.758.758l-1.425-1.426-3.677 1.225-7.393 7.394 6.059 6.059 7.393-7.395 1.225-3.675-1.425-1.425-1.376 1.376a1.61 1.61 0 01-.314 1.829 1.6 1.6 0 01-1.136.47c-.411 0-.823-.156-1.136-.47a1.609 1.609 0 011.136-2.743c.237 0 .475.052.693.157l1.376-1.376zm-1.705 2.432a.534.534 0 00-.743.014.537.537 0 10.772.015l-.029-.029zM8.39 14.272l-.757-.758 3.787-3.787.757.758-3.787 3.787zm2.096 2.096l-.757-.758 3.786-3.786.757.757-3.786 3.787z' />
    </svg>
)

export default SvgNomeModelo
