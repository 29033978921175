/* tslint:disable */
import React from 'react'

const SvgErroLink = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 294 294' {...props}>
        <g>
            <circle cx={128.237} cy={285.84} r={3.018} opacity={0.3} />
            <path
                d='M14.513 112.285l-1.958-1.78 1.78-1.959a.89.89 0 10-1.273-1.166l-1.78 1.95-1.95-1.781a.89.89 0 10-1.175 1.264l1.959 1.78-1.78 1.959a.89.89 0 001.272 1.166l1.78-1.95 1.95 1.781a.89.89 0 101.175-1.264zm264.615-47.137l-1.959-1.78 1.78-1.959a.89.89 0 00-1.264-1.192l-1.78 1.95-1.95-1.781a.89.89 0 10-1.175 1.264l1.959 1.78-1.78 1.959a.89.89 0 001.272 1.166l1.78-1.95 1.95 1.78a.89.89 0 101.175-1.263l-.008.026zM16.516 27.804a5.181 5.181 0 110-10.362 5.181 5.181 0 010 10.362zm0-8.626a3.454 3.454 0 100 6.908 3.454 3.454 0 000-6.908zm232.033 249.775a3.881 3.881 0 113.881-3.882 3.89 3.89 0 01-3.881 3.882zm0-6.036a2.154 2.154 0 102.154 2.154 2.163 2.163 0 00-2.154-2.163v.009zM29.05 209.682a3.881 3.881 0 113.882-3.88 3.89 3.89 0 01-3.882 3.88zm0-6.035a2.154 2.154 0 102.155 2.154 2.163 2.163 0 00-2.155-2.154z'
                opacity={0.3}
            />
            <circle cx={285.831} cy={157.169} r={1.727} opacity={0.3} />
            <circle cx={168.938} cy={12.018} r={3.018} opacity={0.4} />
            <path d='M223.018 40.125h-26.404l-2.154 13.477-5.858 8.849 3.036 6.988-4.22 8.012v-8.012l-4.22-6.988 2.262-2.475 6.178-6.765-7.362-13.095H85.427c-2.314 0-4.193 2.207-4.193 4.923v180.026a4.193 4.193 0 004.193 4.193h137.59a4.193 4.193 0 004.194-4.193V45.083c.009-2.76-1.87-4.958-4.193-4.958z' />
            <path d='M151.298 48.275L55.956 74.242c-2.234.605-3.472 3.231-2.75 5.858l47.305 173.697a4.193 4.193 0 005.146 2.946l132.756-36.15a4.193 4.193 0 002.947-5.146l-47.332-173.67c-.713-2.627-3.107-4.256-5.342-3.65L163.21 45.06l-11.911 3.214z' />
            <path d='M216.018 53.125h-26.404l-2.154 13.477-5.858 8.849 3.036 6.988-4.22 8.012v-8.012l-4.22-6.988 2.262-2.475 6.178-6.765-7.362-13.095H78.427c-2.314 0-4.193 2.207-4.193 4.923v180.026a4.193 4.193 0 004.193 4.193h137.59a4.193 4.193 0 004.194-4.193V58.083c.009-2.76-1.87-4.958-4.193-4.958z' />
            <g opacity={0.7} transform='translate(131.75 134.641)'>
                <circle cx={4.006} cy={3.445} r={3.249} />
                <circle cx={26.938} cy={3.445} r={3.249} />
            </g>
            <path strokeWidth={2} d='M134.136 160.546c7.437-6.71 18.745-6.71 26.182 0' opacity={0.7} />
        </g>
    </svg>
)

export default SvgErroLink
