import { EmpresaForm, RelacaoEmpresasForm, RelacaoRespostaDto } from 'backend'
import axios, { AxiosPromise } from 'axios'

const url = '/api/relacaoEmpresas'

class RelacaoEmpresas {
    getRelacao(): AxiosPromise<RelacaoEmpresasForm> {
        return axios.get(url + '/relacao')
    }

    validateRelacao(form: EmpresaForm) {
        return axios.post(url + '/validarRelacao', form)
    }

    saveRelacao(form: RelacaoEmpresasForm): AxiosPromise<RelacaoEmpresasForm> {
        return axios.post(url + '/relacao', form)
    }

    updateRelacao(id: number, form: RelacaoEmpresasForm): AxiosPromise<RelacaoEmpresasForm> {
        return axios.put(url + `/relacao/${id}`, form)
    }

    saveSemResposta(): AxiosPromise<void> {
        return axios.post(url + '/semResposta')
    }

    getResposta(): AxiosPromise<RelacaoRespostaDto> {
        return axios.get(url + '/resposta')
    }

    updateSemResposta(id: number): AxiosPromise<void> {
        return axios.put(url + `/semResposta/${id}`)
    }
}

export default new RelacaoEmpresas()
