/* tslint:disable */
import React from 'react'

const SvgStent = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M17.145 3l-2.48.3-.235.028-.047.006-1.059.148-9.795 9.795-.122 1.066L3 17.139l3.998-.58-.578 3.983 2.796-.407 1.066-.122 9.796-9.795.148-1.06.005-.046.028-.234.3-2.481-3.985.573.571-3.97zM10.65 16.323l-.289 1.915-.61.611-1.953.279.342-2.456 2.51-.349zm1.93-.303l-.73.73.076-.635.653-.095zm-5.36-3.11l-.349 2.51-2.456.342.28-1.952.61-.611 1.915-.29zm4.02-.593l-.396 2.742-2.754.396.395-2.739 2.755-.399zm3.653-.236l-.29 1.916-.61.61-1.953.278.342-2.456 2.511-.348zm1.93-.303l-.73.73.076-.635.653-.095zm-9.3-.797l-.095.653-.635.076.73-.729zm3.94-2.314l-.349 2.511-2.456.342.278-1.952.61-.611 1.916-.29zm4.021-.593l-.397 2.742-2.755.397.395-2.74 2.757-.399zm3.652-.236l-.29 1.916-.61.61-1.953.279.342-2.456 2.51-.349zm-7.37-1.101l-.095.654-.636.077.731-.731zm3.939-2.312l-.348 2.51-2.456.342.278-1.953.61-.61 1.916-.29z' />
    </svg>
)

export default SvgStent
