import Procedimento from 'api/Procedimento'
import { ProcedimentoAnteriorDto, VinculoProfissionalRow } from 'backend'
import { List } from 'immutable'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'

export const DEFINIR_EQUIPE_MEDICA = 'rni/novoProcedimento/DEFINIR_EQUIPE_MEDICA'
export const SELECIONAR_PACIENTE = 'rni/novoProcedimento/SELECIONAR_PACIENTE'
export const SELECIONAR_PROCEDIMENTO = 'rni/novoProcedimento/SELECIONAR_PROCEDIMENTO'
export const CLEAR = 'rni/novoProcedimento/CLEAR'
export const FIND_PROCEDIMENTOS_ANTERIORES = 'rni/novoProcedimento/FIND_PROCEDIMENTOS_ANTERIORES'
export const SAVE_RASCUNHO_SUCCESS = 'rni/novoProcedimento/SAVE_RASCUNHO_SUCCESS'
export const OPEN_MODAL_PREENCHER_PROCED = 'rni/novoProcedimento/OPEN_MODAL_PREENCHER_PROCED'
export const CLOSE_MODAL_PREENCHER_PROCED = 'rni/novoProcedimento/CLOSE_MODAL_PREENCHER_PROCED'
export const LOAD_EQUIPE_MEDICA_FROM_RASCUNHO = 'rni/novoProcedimento/LOAD_EQUIPE_MEDICA_FROM_RASCUNHO'
export const SHOW_NOT_FOUND_MODAL_ERROR = 'rni/novoProcedimento/SHOW_NOT_FOUND_MODAL_ERROR'
export const CLOSE_NOT_FOUND_MODAL_ERROR = 'rni/novoProcedimento/CLOSE_NOT_FOUND_MODAL_ERROR'
export const SHOW_SUCCESS_MODAL = 'rni/novoProcedimento/SHOW_SUCCESS_MODAL'
export const CLOSE_SUCCESS_MODAL = 'rni/novoProcedimento/CLOSE_SUCCESS_MODAL'
export const SET_ID_RASCUNHO_ORIGEM = 'rni/novoProcedimento/SET_ID_RASCUNHO_ORIGEM'

export interface NovoProcedimentoState
    extends Readonly<{
        vinculoProfissionalMedicoResponsavel?: Partial<VinculoProfissionalRow>
        equipeMedica?: VinculoProfissionalRow[]
        idRascunhoOrigem?: number
        paciente?: any
        lado?: any
        procedimento?: any
        saveRascunhoSuccess?: boolean
        procedimentosAnteriores: PromiseRecord<ProcedimentoAnteriorDto[]>
        openModal?: boolean
        showNotFoundModalError?: boolean
        showSuccessModal?: boolean
    }> {}

const novoProcedimentoInitialState: NovoProcedimentoState = {
    procedimentosAnteriores: {},
}

export default function reducer(
    state: NovoProcedimentoState = novoProcedimentoInitialState,
    action
): NovoProcedimentoState {
    switch (action.type) {
        case DEFINIR_EQUIPE_MEDICA:
            const vinculoProfissionalMedicoResponsavel = action.equipeMedica.get(
                action.vinculoProfissionalMedicoResponsavel
            )
            const equipeMedica = action.equipeMedica.delete(action.vinculoProfissionalMedicoResponsavel)

            return {
                ...state,
                vinculoProfissionalMedicoResponsavel,
                equipeMedica,
            }
        case SELECIONAR_PACIENTE:
            return { ...state, paciente: action.paciente }
        case FIND_PROCEDIMENTOS_ANTERIORES:
            return { ...state, procedimentosAnteriores: promiseReducer(state.procedimentosAnteriores, action) }
        case SELECIONAR_PROCEDIMENTO:
            return { ...state, procedimento: action.procedimento, lado: action.lado }
        case SAVE_RASCUNHO_SUCCESS:
            return { ...state, saveRascunhoSuccess: true }
        case OPEN_MODAL_PREENCHER_PROCED:
            return { ...state, openModal: true }
        case CLOSE_MODAL_PREENCHER_PROCED:
            return { ...state, openModal: false }
        case CLEAR:
            return { ...novoProcedimentoInitialState }
        case LOAD_EQUIPE_MEDICA_FROM_RASCUNHO:
            return {
                ...state,
                equipeMedica: action.result?.data.equipeMedica,
                vinculoProfissionalMedicoResponsavel: action.result?.data.profissionalResponsavel,
            }
        case SHOW_NOT_FOUND_MODAL_ERROR:
            return { ...state, showNotFoundModalError: true }
        case CLOSE_NOT_FOUND_MODAL_ERROR:
            return { ...state, showNotFoundModalError: false }
        case SHOW_SUCCESS_MODAL:
            return { ...state, showSuccessModal: true }
        case CLOSE_SUCCESS_MODAL:
            return { ...state, showSuccessModal: false }
        case SET_ID_RASCUNHO_ORIGEM:
            return { ...state, idRascunhoOrigem: action.idRascunho }
        default:
            return state
    }
}

export const saveRascunhoSuccess = () => ({
    type: SAVE_RASCUNHO_SUCCESS,
})

export const openModal = () => ({
    type: OPEN_MODAL_PREENCHER_PROCED,
})

export const closeModal = () => ({
    type: CLOSE_MODAL_PREENCHER_PROCED,
})

export const definirEquipeMedica = (
    vinculoProfissionalMedicoResponsavel: number,
    equipeMedica: List<VinculoProfissionalRow>
) => ({
    type: DEFINIR_EQUIPE_MEDICA,
    vinculoProfissionalMedicoResponsavel,
    equipeMedica,
})

export const selecionarPaciente = paciente => ({
    type: SELECIONAR_PACIENTE,
    paciente,
})

export const findProcedimentosAnteriores = idPaciente => ({
    type: FIND_PROCEDIMENTOS_ANTERIORES,
    promise: Procedimento.findProcedimentosAnteriores(idPaciente),
})

export const syncSelecionarProcedimento = (procedimento, lado) => ({
    type: SELECIONAR_PROCEDIMENTO,
    procedimento,
    lado,
})

export const clear = () => ({
    type: CLEAR,
})

export function selecionarProcedimento(procedimento, lado) {
    return function(dispatch) {
        dispatch(syncSelecionarProcedimento(procedimento, lado))
        return Promise.resolve()
    }
}

export const loadEquipeMedicaFromRascunho = (idRascunho: number) => ({
    type: LOAD_EQUIPE_MEDICA_FROM_RASCUNHO,
    promise: Procedimento.getEquipeMedicaFromRascunho(idRascunho),
})

export const showNotFoundModalError = () => ({
    type: SHOW_NOT_FOUND_MODAL_ERROR,
})

export const closeNotFoundModalError = () => ({
    type: CLOSE_NOT_FOUND_MODAL_ERROR,
})

export const showSuccessModal = () => ({
    type: SHOW_SUCCESS_MODAL,
})

export const closeSuccessModal = () => ({
    type: CLOSE_SUCCESS_MODAL,
})

export const setIdRascunhoOrigem = (idRascunho: number) => ({
    type: SET_ID_RASCUNHO_ORIGEM,
    idRascunho,
})
