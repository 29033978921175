import * as React from 'react'
import * as C from 'libs/components'
import UrlParams from 'libs/router/UrlParams'
import { Link, NavLink } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import { DateInterval, InfoProdutoFiltro, InfoProdutoDto, TipoCirurgia, MotivosRevisoesRow } from 'backend'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import { ScrollToTop, Columns, Column, PeriodoFilter, Button, Icon, Text } from 'libs/components'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import * as enums from 'redux/enums'
import { InfoProdutoDadosGeraisCard } from './InfoProdutoDadosGeraisCard'
import { MotivosRevisaoCard } from '../cards/MotivosRevisaoCard'
import { RequestState } from 'redux/requester'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { stringify } from 'redux/modules/router'

export interface Props extends RouteComponentProps<any> {
    filtro: InfoProdutoFiltro
    info: RequestState<InfoProdutoDto>
    motivosRevisoes: RequestState<MotivosRevisoesRow[]>
    changeFilter(filtro: InfoProdutoFiltro): void
    load(filtro: InfoProdutoFiltro): void
    resetFilter(current: InfoProdutoFiltro): void
}

export class InfoProdutoDetailView extends React.Component<Props> {
    handlePeriodoChange = (interval: DateInterval) => {
        this.props.changeFilter({
            ...this.props.filtro,
            interval: interval,
        })
    }

    refresh = (filter: InfoProdutoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        this.props.load(filter)
    }

    handleVoltar = () => {
        this.props.resetFilter(this.props.filtro)
        this.props.history.replace({
            pathname: '/home/relatorios/info-produto',
            search: this.props.location.search,
        })
    }

    renderDownloadIcon = () => {
        const currentFilter = this.props.filtro || {}
        return (
            <Link
                to={{
                    pathname: '/api/report/motivosRevisoes/csv',
                    search: stringify(currentFilter),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <C.Text color='grey-darker'>
                    <C.Icon icon='exportar' />
                </C.Text>
            </Link>
        )
    }

    render() {
        const { filtro, info, motivosRevisoes } = this.props
        const { registroAnvisa, ...publishFilter } = filtro

        return (
            <div>
                <ScrollToTop onMount />
                <UrlParams params={publishFilter} onChange={this.refresh} />
                <ReportClearFilter />

                <LocalHeaderContainer />
                <InfoDetailHeader
                    registroAnvisa={registroAnvisa}
                    nomeProduto={info && info.result && info.result.produto && info.result.produto.nomeProduto}
                    tipoCirurgia={filtro.tipoCirurgia}
                    onVoltar={this.handleVoltar}
                />

                <div className='report-content'>
                    <Columns className='report-tabs align-center'>
                        <Column size='6'>
                            <div className='report-tabs-links'>
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}`,
                                        search: this.props.location.search,
                                    }}
                                    className='is-active'
                                >
                                    Dashboard
                                </NavLink>
                            </div>
                        </Column>
                        <Column size='6' textRight>
                            <PeriodoFilter value={this.props.filtro.interval} onChange={this.handlePeriodoChange} />
                        </Column>
                    </Columns>

                    <Columns auto>
                        <InfoProdutoDadosGeraisCard info={info.result} isFetching={info.readyState === 'request'} />
                    </Columns>

                    <Columns auto>
                        <MotivosRevisaoCard
                            motivos={motivosRevisoes.result}
                            isFetching={motivosRevisoes.readyState === 'request'}
                            report={this.renderDownloadIcon}
                        />
                    </Columns>
                </div>
            </div>
        )
    }
}

export interface InfoDetailHeaderProps {
    registroAnvisa: string
    nomeProduto: string
    tipoCirurgia: TipoCirurgia
    onVoltar(): void
}

export const InfoDetailHeader = (props: InfoDetailHeaderProps) => {
    const { registroAnvisa, nomeProduto, tipoCirurgia, onVoltar } = props

    return (
        <HeaderContainer className='report-header-rastreabilidade'>
            <Button data-name='botao_voltar' className='button-round button-back-motivos' onClick={onVoltar}>
                <Icon icon='voltar' />
            </Button>
            <Text weight='semibold' color='grey-darker' size='small' transform='uppercase'>
                Informações de produtos
            </Text>
            <p>
                <Text size='large' weight='semibold'>
                    {registroAnvisa}
                </Text>{' '}
                <Text size='small'>{nomeProduto}</Text>
            </p>
            <p>
                <Text size='small-bis' color='grey-darker' weight='semibold'>
                    em {enums.tipoCirurgiaName[tipoCirurgia]}
                </Text>
            </p>
        </HeaderContainer>
    )
}
