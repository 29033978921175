import * as React from 'react'
import classnames from 'classnames'

export type ProgressSize = 'x-small' | 'small' | 'medium' | 'large'

export class ProgressProps {
    value: number
    size?: ProgressSize
    className?: string
    style?: React.CSSProperties
}

export class Progress extends React.Component<ProgressProps> {
    static defaultProps: ProgressProps = {
        value: 1,
        className: 'is-primary',
    }

    render() {
        return (
            <progress
                value={this.props.value}
                className={classnames('progress', this.props.className, {
                    'is-x-small': this.props.size === 'x-small',
                    'is-small': this.props.size === 'small',
                    'is-medium': this.props.size === 'medium',
                    'is-large': this.props.size === 'large',
                })}
                style={this.props.style}
            />
        )
    }
}

export interface ProgressMultipleProps {
    values: { [className: string]: number }
    size?: ProgressSize
}

export class ProgressMultiple extends React.Component<ProgressMultipleProps> {
    render() {
        return (
            <div className='progress-multiple'>
                {Object.keys(this.props.values).map(className => {
                    const value = this.props.values[className]

                    return (
                        value > 0 && (
                            <Progress
                                key={className}
                                value={1}
                                className={className}
                                size={this.props.size}
                                style={{
                                    width: value * 100 + '%',
                                }}
                            />
                        )
                    )
                })}
            </div>
        )
    }
}
