import { createValidator, required } from 'libs/validation'

const DadosFinanceirosValidator = createValidator(
    {
        compradoHospital: required,
    },
    formValidator
)

function formValidator(form) {
    const errors = {}

    if (form.get('compradoHospital') === 'SIM') {
        errors.responsavelRessarcimento = required(form.get('responsavelRessarcimento'))
    } else if (form.get('compradoHospital') === 'NAO') {
        errors.fornecedor = required(form.get('fornecedor'))
    }

    return errors
}

export default DadosFinanceirosValidator
