import { DateInterval, DistribuicaoProcedimentoFiltro } from 'backend'
import { ScrollToTop, Columns, Column, PeriodoFilter } from 'libs/components'
import UrlParams from 'libs/router/UrlParams'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { State } from 'redux/modules'
import { change } from 'redux/modules/report/filtro'
import { MotivoReportHeader } from 'view/componentes/reports/components/MotivoReportHeader'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import {
    DadosUsoCard,
    FaixaEtariaChartCard,
    PeriodoProcedimentoChartCard,
    SexoCard,
} from 'view/componentes/reports/cards/'
import { ProcedimentoReportState, loadMotivo, clearAllDistribuicoes } from 'redux/modules/report/procedimento'
import { HomeGestorServicoSaudeHeader } from '../HomeGestorServicoSaudeHeader'

export const GestorServicoSaudeMotivosProcedimentoDashboardView = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const { motivo } = useParams()
    const filtro = useSelector<State, DistribuicaoProcedimentoFiltro>(state => state.report.filtro.filtro)
    const procedimentoReportState = useSelector<State, ProcedimentoReportState>(state => state.report.procedimento)
    const distClassificacao = procedimentoReportState.distClassificacao
    const distClassificacaoPeriodo = procedimentoReportState.distClassificacaoPeriodo
    const distClassificacaoSexo = procedimentoReportState.distClassificacaoSexo
    const distClassificacaoIdade = procedimentoReportState.distClassificacaoIdade

    React.useEffect(() => {
        dispatch(loadMotivo(motivo))
        return () => dispatch(clearAllDistribuicoes())
    }, [dispatch, motivo])

    const changeFilter = (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(change(filtro))
    }

    const voltar = () => {
        history.push({
            pathname: '/home/relatorios/motivos',
            search: location.search,
        })
    }

    const handlePeriodoChange = (interval: DateInterval) => {
        changeFilter({
            ...filtro,
            interval: interval,
        })
    }

    const refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            changeFilter(filter)
        }
    }

    return (
        <div>
            <ScrollToTop onMount />
            <UrlParams params={filtro} onChange={refresh} />
            <ReportClearFilter />

            <HomeGestorServicoSaudeHeader />

            <MotivoReportHeader motivo={motivo} onVoltar={voltar} />
            <div className='report-content'>
                <Columns className='report-tabs align-center'>
                    <Column size='6'>
                        <div className='report-tabs-links'>
                            <NavLink
                                to={{
                                    pathname: `${location.pathname}`,
                                    search: location.search,
                                }}
                                activeClassName='is-active'
                            >
                                Dashboard
                            </NavLink>
                        </div>
                    </Column>
                    <Column size='6' textRight>
                        <PeriodoFilter value={filtro.interval} onChange={handlePeriodoChange} />
                    </Column>
                </Columns>

                <Columns auto>
                    <div className='flex-5'>
                        <DadosUsoCard distClassificacao={distClassificacao} title='Número de procedimentos' />
                    </div>
                    <div className='flex-5'>
                        <SexoCard dist={distClassificacaoSexo} />
                    </div>
                </Columns>

                <Columns auto>
                    <PeriodoProcedimentoChartCard dist={distClassificacaoPeriodo} />
                </Columns>

                <Columns auto>
                    <FaixaEtariaChartCard dist={distClassificacaoIdade} />
                </Columns>
            </div>
        </div>
    )
}
