import * as React from 'react'
import { DistClassificacaoPeriodo } from 'redux/modules/report/procedimento'
import { ChartCard } from 'libs/components'

interface Props {
    dist: DistClassificacaoPeriodo
}

export class PeriodoProcedimentoChartCard extends React.Component<Props> {
    render() {
        const result = this.props.dist.result
        let config = {}

        if (this.props.dist.readyState === 'success') {
            config = {
                title: {
                    text: 'Distribuição de procedimentos por período',
                },
                xAxis: {
                    categories: result.categories,
                    type: 'categories',
                    title: {
                        text: 'Período',
                    },
                },
                yAxis: {
                    title: {
                        text: 'N° de procedimentos',
                    },
                },
                series: result.series,
            }
        }

        return <ChartCard title='Procedimentos por data' readyState={this.props.dist.readyState} config={config} />
    }
}
