import * as React from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { Card, MarkerInfoMap, MarkerInfo } from 'libs/components'
import { State } from 'redux/modules'
import * as multiplosProcedimentos from 'redux/modules/report/multiplos'
import { DistribuicaoProcedimentoRow, ProcedimentoMode, TipoCirurgia } from 'backend'
import { MultiplosProcedimentosFiltro, TempoAteRevisao } from 'model'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import * as enums from 'redux/enums'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface Params {
    procedimentoMode: ProcedimentoMode
    tipoCirurgia: TipoCirurgia
    tempoRevisao: TempoAteRevisao
}

interface Props {
    filtro: MultiplosProcedimentosFiltro
    map: PromiseRecord<DistribuicaoProcedimentoRow[]>
    load(filtro: MultiplosProcedimentosFiltro): void
}

export class MultiplosPacientesMap extends React.Component<Props & RouteComponentProps<Params>> {
    componentDidMount() {
        this.props.load(this.props.filtro)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (!isEqual(this.props.filtro, nextProps.filtro)) {
            this.props.load(nextProps.filtro)
        }
    }

    render() {
        const { map } = this.props
        let markers: MarkerInfo[] = []

        if (map.data) {
            map.data.forEach(item => {
                if (item.local.hospital.lat && item.local.hospital.lng) {
                    markers.push({
                        title: item.local.hospital.nome,
                        subtitle: item.local.hospital.cidade,
                        text: `${item.total} procedimentos geraram revisão ${enums.tempoAteRevisaoName[
                            this.props.match.params.tempoRevisao
                        ].toLowerCase()}`,
                        pos: {
                            lat: item.local.hospital.lat,
                            lng: item.local.hospital.lng,
                        },
                    })
                }
            })
        }

        return (
            <Card title='Mapa com pointers' noPadding>
                <MarkerInfoMap markers={markers} />
            </Card>
        )
    }
}

export const mapStateToProps = (state: State) => ({
    filtro: state.report.filtro.filtro,
    map: state.report.multiplos.map,
})

export const mapDispatchToProps = (dispatch, ownProps: RouteComponentProps<Params>) => ({
    load: (filtro: MultiplosProcedimentosFiltro) => {
        dispatch(
            multiplosProcedimentos.findMap({
                ...filtro,
                procedimentoMode: ownProps.match.params.procedimentoMode,
                tipoCirurgia: ownProps.match.params.tipoCirurgia,
                tempoRevisao: ownProps.match.params.tempoRevisao,
            })
        )
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiplosPacientesMap))
