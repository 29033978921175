import React from 'react'
import * as C from 'libs/components'

const viaAcessoOptions = [
    { id: 'AXILAR', nome: 'Axilar' },
    { id: 'BRAQUIAL_DISSECCAO', nome: 'Braquial dissecção' },
    { id: 'BRAQUIAL_PUNCAO', nome: 'Braquial punção' },
    { id: 'FEMORAL', nome: 'Femoral' },
    { id: 'RADIAL', nome: 'Radial' },
    { id: 'ULNAR', nome: 'Ulnar' },
]

export class ViaAcessoField extends React.Component {
    render() {
        return <C.FixedSelectField {...this.props} options={viaAcessoOptions} />
    }
}
