import { DescricaoPerfilCaptalize, ImagemPerfil } from 'api/Perfis'
import { SelecaoPerfilDto, UserDTO } from 'backend'
import classnames from 'classnames'
import * as C from 'libs/components'
import { defaultErrorResolver } from 'libs/redux-form/form'
import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Route } from 'react-router-dom'
import HomeConteudo from 'view/componentes/HomeConteudo'
import SolicitacaoVinculoContainer from 'view/perfis/SolicitacaoVinculoContainer'

interface PerfisHeaderProps extends RouteComponentProps {
    user: UserDTO
    hasPerfisAtivos?: number
}

const PerfisHeader = withRouter<PerfisHeaderProps, any>(props => (
    <div className='perfis-header'>
        <div className='container'>
            {!props.user.perfil && (
                <div className='has-text-centered'>
                    <div className='bem-vindo'>Olá, {props.user.nome}</div>
                    {props.hasPerfisAtivos === 0 && (
                        <div className='instrucoes'>
                            {' '}
                            Você não possui um vínculo ativo no momento, <br /> adicione um novo vínculo e aguarde o
                            gestor aceitar a solicitação.
                        </div>
                    )}
                    {props.hasPerfisAtivos > 1 && (
                        <div className='instrucoes'>
                            Você possui mais de um vínculo registrado. <br /> Selecione qual perfil deseja acessar.
                        </div>
                    )}
                </div>
            )}
            <C.Columns>
                <C.Column size='6' offset='3' horizontalFlow>
                    <Route
                        path='/vinculos'
                        exact
                        children={({ match }) => (
                            <p
                                className={classnames('tab', { active: match })}
                                onClick={() => props.history.push('/vinculos')}
                            >
                                Vínculos ativos
                            </p>
                        )}
                    />
                    <Route
                        path='/vinculos/vinculosInativos'
                        exact
                        children={({ match }) => (
                            <p
                                className={classnames('tab', { active: match })}
                                onClick={() => props.history.push('/vinculos/vinculosInativos')}
                            >
                                Vínculos inativos
                            </p>
                        )}
                    />
                </C.Column>
            </C.Columns>
        </div>
    </div>
))

interface PerfisFooterProps extends RouteComponentProps {
    user: UserDTO
}

const PerfisFooter = withRouter<PerfisFooterProps, any>(props => (
    <div className='perfis-footer'>
        <div className='container'>
            <div className='has-text-centered'>
                {!props.user.perfil && (
                    <C.Button size='medium' onClick={() => props.history.push('/logout')}>
                        Sair
                    </C.Button>
                )}
                {props.user.perfil && (
                    <C.Button size='medium' onClick={() => props.history.push('/home')}>
                        Página inicial
                    </C.Button>
                )}
            </div>
        </div>
    </div>
))

interface PerfilRowProps {
    perfil: SelecaoPerfilDto
    onSelecionarVinculo?: () => void
    desativarVinculo?: (perfil: SelecaoPerfilDto) => void
    reativarVinculo?: (perfil: SelecaoPerfilDto) => void
}

const PerfilRowCmp: React.FunctionComponent<PerfilRowProps> = props => {
    const [openModal, setOpenModal] = useState(undefined)

    const acessivel = props.perfil.ativo && props.perfil.situacaoSolicitacao === 'APROVADO'
    const aguardandoAprovacao = props.perfil.situacaoSolicitacao === 'PENDENTE'

    const close = () => setOpenModal(undefined)
    const makeOpenRtv = (perfil: SelecaoPerfilDto) => setOpenModal(perfil.perfil + perfil.pessoaJuridicaId)
    const makeOkRtv = (perfil: SelecaoPerfilDto) => {
        props.reativarVinculo(perfil)
        setOpenModal(undefined)
    }
    const makeOpenDstv = (perfil: SelecaoPerfilDto) => setOpenModal(perfil.pessoaJuridicaId + perfil.perfil)
    const makeOkDstv = (perfil: SelecaoPerfilDto) => {
        props.desativarVinculo(perfil)
        setOpenModal(undefined)
    }

    return (
        <li
            className={classnames('perfil-row', {
                ativo: acessivel,
                pendente: aguardandoAprovacao,
                inativo: !props.perfil.ativo,
            })}
        >
            <div className={'perfil-inner-row is-horizontal-flow'}>
                <C.Icon icon='hospital' />
                <span className='infos is-vertical-flow'>
                    <span>
                        <C.Image className='imagem-perfil' src={ImagemPerfil[props.perfil.perfil]} size='14x14' />
                        <span className='especializacao'>{DescricaoPerfilCaptalize[props.perfil.perfil]}</span>
                    </span>
                    <span className='hospital'>{props.perfil.pessoaJuridicaNome}</span>
                    <span className='localidade'>
                        {props.perfil.cidade} - {props.perfil.siglaUf}
                    </span>
                </span>
                <span className='acoes'>
                    {!props.perfil.ativo && (
                        <C.Hint
                            key='1'
                            placement='top'
                            componente={
                                <C.IconButton
                                    data-name='botao_reativar'
                                    icon='retornar'
                                    className='com-hover'
                                    size='small'
                                    pulledRight
                                    onClick={() => makeOpenRtv(props.perfil)}
                                />
                            }
                        >
                            Solicitar reativação
                        </C.Hint>
                    )}
                    {acessivel && (
                        <C.Hint
                            key='2'
                            placement='top'
                            componente={
                                <C.IconButton
                                    data-name='botao_desativar'
                                    icon='excluir'
                                    className='com-hover'
                                    size='small'
                                    pulledRight
                                    onClick={() => makeOpenDstv(props.perfil)}
                                />
                            }
                        >
                            Desativar vínculo
                        </C.Hint>
                    )}

                    {acessivel && (
                        <C.Hint
                            key='3'
                            placement='top'
                            componente={
                                <C.IconButton
                                    data-name='botao_acessar'
                                    icon='arrowRight'
                                    className='com-hover'
                                    size='small'
                                    pulledRight
                                    onClick={props.onSelecionarVinculo}
                                />
                            }
                        >
                            Acessar vínculo
                        </C.Hint>
                    )}
                    {aguardandoAprovacao && <span className='aguardando-aprovacao'>Aguardando aprovação</span>}
                </span>
            </div>
            <C.AlertModalConfirmacao
                active={openModal === props.perfil.perfil + props.perfil.pessoaJuridicaId}
                title='Solicitar reativação?'
                onClose={close}
                onOk={() => makeOkRtv(props.perfil)}
            >
                Ao confirmar, será enviada uma solicitação ao gestor de segurança do hospital para que o seu vínculo
                seja reativado. Tem certeza que deseja solicitar a reativação?
            </C.AlertModalConfirmacao>
            <C.AlertModalConfirmacao
                active={openModal === props.perfil.pessoaJuridicaId + props.perfil.perfil}
                title='Desativar vínculo?'
                onClose={close}
                onOk={() => makeOkDstv(props.perfil)}
            >
                Ao confirmar, este vínculo com o hospital será desativado. Tem certeza que deseja desativar o vínculo?
            </C.AlertModalConfirmacao>
        </li>
    )
}

interface VinculosAtivosProps extends RouteComponentProps {
    isFetching: boolean
    makeSelecionarPerfil: (perfil: SelecaoPerfilDto) => () => void
    desativarVinculo: (perfil: SelecaoPerfilDto) => void
    perfisAtivos: SelecaoPerfilDto[]
    user: UserDTO
}

const VinculosAtivos = withRouter<VinculosAtivosProps, any>(props => (
    <C.LoadingContainer
        isFetching={
            props.isFetching ||
            (!props.user.perfil &&
                props.perfisAtivos?.length === 1 &&
                props.perfisAtivos[0].situacaoSolicitacao === 'APROVADO')
        }
        loadingText='CARREGANDO PERFIS'
    >
        <ul>
            {props.perfisAtivos &&
                props.perfisAtivos.map((perfil, index) => (
                    <PerfilRowCmp
                        key={index}
                        perfil={perfil}
                        onSelecionarVinculo={props.makeSelecionarPerfil(perfil)}
                        desativarVinculo={props.desativarVinculo}
                    />
                ))}
        </ul>
        {props.user.rni && (
            <C.Button size='large' square dashed fullwidth onClick={() => props.history.push('/vinculos/adicionar')}>
                + ADICIONAR VÍNCULO
            </C.Button>
        )}
    </C.LoadingContainer>
))

interface VinculosInativosProps {
    perfisInativos: SelecaoPerfilDto[]
    reativarVinculo: (perfil: SelecaoPerfilDto) => void
}

const VinculosInativos: React.SFC<VinculosInativosProps> = props => (
    <ul>
        {(!props.perfisInativos || props.perfisInativos.length === 0) && (
            <div className='has-text-centered'>
                <C.Icon className='vinculo-inativo' icon='vinculoinativo' size='gigantic' />
                <div className='nao-possui-inativos-text'>Você não possui vínculos inativos.</div>
            </div>
        )}
        {props.perfisInativos &&
            props.perfisInativos.map((perfil, index) => (
                <PerfilRowCmp key={index} perfil={perfil} reativarVinculo={props.reativarVinculo} />
            ))}
    </ul>
)

export interface PerfisViewProps {
    loadPerfis: () => Promise<any>
    clearPerfis: () => void
    selecionarPerfil: (perfil: SelecaoPerfilDto) => void
    selecionarPerfilAutomatico: (perfil: any) => void
    getPerfisAcessaveis: (perfis: SelecaoPerfilDto[]) => SelecaoPerfilDto[]
    reativarVinculo: (perfil: SelecaoPerfilDto) => void
    desativarVinculo: () => void
    updateLogin: () => void
    isFetching: boolean
    perfisAtivos: SelecaoPerfilDto[]
    perfisInativos: SelecaoPerfilDto[]
    user: UserDTO
}

export default class PerfisView extends React.Component<RouteComponentProps<any> & PerfisViewProps, any> {
    constructor(props) {
        super(props)

        this.autoSelectPerfil = this.autoSelectPerfil.bind(this)
        this.vinculoErrorResolver = this.vinculoErrorResolver.bind(this)
        this.makeSelecionarPerfil = this.makeSelecionarPerfil.bind(this)
    }

    autoSelectPerfil(eve) {
        if (eve.key === 'perfilSelecionado') {
            this.props.updateLogin()
        }
    }

    componentDidMount() {
        this.props.loadPerfis().then(() => {
            const acessaveis = this.props.getPerfisAcessaveis(this.props.perfisAtivos)
            !this.props.user.perfil &&
                acessaveis &&
                acessaveis.length === 1 &&
                this.props.selecionarPerfilAutomatico(acessaveis[0])
        })
        window.addEventListener('storage', this.autoSelectPerfil, false)
    }

    componentWillUnmount() {
        this.props.clearPerfis()
        window.removeEventListener('storage', this.autoSelectPerfil, false)
    }

    vinculoErrorResolver(err) {
        if (
            err &&
            err.solicitacaoVinculo &&
            err.solicitacaoVinculo.perfil &&
            err.solicitacaoVinculo.perfil.titulo === 'Vínculo duplicado'
        ) {
            return {
                modalTitle: 'Vínculo já existe!',
                modalContent: (
                    <span>
                        Você já possui um vínculo com esse hospital e com esse perfil, é possível que a solicitação
                        esteja aguardando aprovação ou que o vínculo tenha sido desativado. Verifique sua lista de
                        vínculos ativos e inativos.
                    </span>
                ),
            }
        }
        return defaultErrorResolver(err)
    }

    render() {
        if (this.props.location.pathname === '/vinculos/adicionar') {
            return (
                <Route
                    path='/vinculos/adicionar'
                    render={routerProps => (
                        <SolicitacaoVinculoContainer {...routerProps} errorResolver={this.vinculoErrorResolver} />
                    )}
                />
            )
        }

        return (
            <HomeConteudo className='perfis-page'>
                <PerfisHeader
                    user={this.props.user}
                    hasPerfisAtivos={this.props.getPerfisAcessaveis(this.props.perfisAtivos).length}
                />
                <div className='perfis-conteudo container is-vertical-flow is-full-height'>
                    <C.Columns fullHeight>
                        <C.Column size='6' offset='3' verticalFlow>
                            <Route
                                path='/vinculos'
                                exact
                                render={() => (
                                    <VinculosAtivos
                                        user={this.props.user}
                                        isFetching={this.props.isFetching}
                                        perfisAtivos={this.props.perfisAtivos}
                                        makeSelecionarPerfil={this.makeSelecionarPerfil}
                                        desativarVinculo={this.props.desativarVinculo}
                                    />
                                )}
                            />
                            <Route
                                path='/vinculos/vinculosInativos'
                                exact
                                render={() => (
                                    <VinculosInativos
                                        perfisInativos={this.props.perfisInativos}
                                        reativarVinculo={this.props.reativarVinculo}
                                    />
                                )}
                            />
                        </C.Column>
                    </C.Columns>
                </div>
                <PerfisFooter user={this.props.user} />
            </HomeConteudo>
        )
    }

    makeSelecionarPerfil(perfil: SelecaoPerfilDto) {
        return () => {
            this.props.selecionarPerfil(perfil)
        }
    }
}
