import { Fields } from 'redux/modules/forms'
import { DadosProdutoForm } from 'backend'
import { ImmutableField } from 'libs/immutableProxy'

const dadosProduto = (
    mutableValue: ImmutableField<DadosProdutoForm, any>,
    mutableField: ImmutableField<Fields<DadosProdutoForm>, any>,
    action
) => {
    if (mutableValue.semRegistroAnvisa.get()) {
        mutableValue.registroAnvisa.delete()
        mutableField.registroAnvisa.delete()
    } else {
        mutableValue.semRegistroAnvisa.delete()
        mutableValue.cnpj.delete()
        mutableValue.nomeFabricanteImportador.delete()
        mutableValue.etiqueta.delete()
    }

    if (!mutableValue.etiqueta.get()) {
        mutableValue.etiqueta.delete()
    }

    const mesFabricacao = mutableValue.dataFabricacao.mes.get()
    const anoFabricacao = mutableValue.dataFabricacao.ano.get()
    const diaFabricacao = mutableValue.dataFabricacao.dia.get()

    if (!anoFabricacao) {
        mutableValue.dataFabricacao.delete()
    }
    if (!mesFabricacao) {
        mutableValue.dataFabricacao.mes.delete()
        mutableValue.dataFabricacao.dia.delete()
    }

    if (!diaFabricacao) {
        mutableValue.dataFabricacao.dia.delete()
    }

    const validade = mutableValue.validade.get()
    if (!mesFabricacao && validade && validade.includes('PERIODO')) {
        mutableValue.validade.delete()
        mutableValue.periodoValidade.delete()
        mutableValue.dataFabricacao.mes.delete()
    }
    if (!validade) {
        mutableValue.anoValidade.delete()
        mutableValue.mesValidade.delete()
        mutableValue.diaValidade.delete()
        mutableValue.validade.delete()
        mutableValue.periodoValidade.delete()
    } else if (validade === 'DATA') {
        mutableValue.periodoValidade.delete()
        let fieldName = action.meta.field.split('.')[1]
        if (fieldName === 'anoValidade') {
            mutableValue.mesValidade.delete()
            mutableValue.diaValidade.delete()
        } else if (fieldName === 'mesValidade') {
            mutableValue.diaValidade.delete()
        }
        if (!mutableValue.anoValidade.get()) {
            mutableValue.mesValidade.delete()
            mutableValue.diaValidade.delete()
            mutableValue.anoValidade.delete()
        } else if (!mutableValue.mesValidade.get()) {
            mutableValue.mesValidade.delete()
            mutableValue.diaValidade.delete()
        } else if (!mutableValue.diaValidade.get()) {
            mutableValue.diaValidade.delete()
        }
    } else if (validade === 'PERIODO') {
        mutableValue.anoValidade.delete()
        mutableValue.mesValidade.delete()
        mutableValue.diaValidade.delete()
    }
}

export default dadosProduto
