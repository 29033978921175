import Procedimento from 'api/Procedimento'
import { connect } from 'react-redux'
import { destroy } from 'redux-form'
import { FichaStentCoronariaComplementarView } from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaComplementarView'
import { RouteComponentProps } from 'react-router-dom'
import { clear as clearNovoProcedimento } from 'redux/modules/novoProcedimento'

interface FichaStentCoronariaComplementarProps extends RouteComponentProps<any> {
    editMode?: boolean
}

const mapStateToProps = (state, ownProps: FichaStentCoronariaComplementarProps) => {
    if (ownProps?.editMode) {
        return {
            paciente: state.paciente.form,
            procedimento: 'stentCoronaria',
            vinculoProfissionalMedicoResponsavel: state.edicao?.medico,
            equipeMedica: state.edicao?.equipeMedica,
        }
    } else {
        return {
            paciente: state.novoProcedimento.paciente,
            procedimento: state.novoProcedimento.procedimento,
            vinculoProfissionalMedicoResponsavel: state.novoProcedimento.vinculoProfissionalMedicoResponsavel,
            equipeMedica: state.novoProcedimento.equipeMedica,
            idRascunho: state.novoProcedimento.idRascunhoOrigem,
        }
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps: FichaStentCoronariaComplementarProps) => {
    const { paciente, equipeMedica, vinculoProfissionalMedicoResponsavel } = stateProps
    const { dispatch } = dispatchProps

    const pushHome = () => ownProps.history.push('/home')

    return {
        ...ownProps,
        ...stateProps,
        onSubmit: form => {
            const mutable = form.toJS()
            mutable.essencial.pacienteId = paciente.id
            mutable.essencial.idVinculoProfResponsavel = vinculoProfissionalMedicoResponsavel?.id
            mutable.essencial.idsVinculosProfsEquipeMedica = equipeMedica.map(vinculoProfissional => {
                return vinculoProfissional.id
            })

            if (ownProps.editMode) {
                return Procedimento.updateStentCoronaria(mutable)
            } else {
                mutable.essencial.idRascunho = stateProps.idRascunho
                return Procedimento.saveStentCoronaria(mutable)
            }
        },
        onSubmitSuccess: () => {
            dispatch(clearNovoProcedimento())
            pushHome()
        },
        onCancel: () => {
            if (!ownProps.editMode) {
                dispatch(destroy('stentCoronaria.complementar'))
            }
            ownProps.history.replace('essencial')
        },
        goHome: pushHome,
    }
}

const FichaStentCoronariaComplementarContainer = connect(
    mapStateToProps,
    null,
    mergeProps
)(FichaStentCoronariaComplementarView)

export default FichaStentCoronariaComplementarContainer
