import React from 'react'
import * as C from 'libs/components'
import Env from 'libs/util/Environment'
import imgLogoRni from 'images/common/logo-rni.png'
import imgLogoBridge from 'images/common/logo-bridge.svg'
import imgLogoUfsc from 'images/common/logo-ufsc.png'
import imgLogoAnvisa from 'images/common/logo-anvisa.png'
import imgLogoGoverno from 'images/common/logo-governo.png'

export type SobreProps = {
    active?: boolean
    onClose?: any
    hasCloseButton?: boolean
    versao: string
}

export default class SobreModal extends React.Component<SobreProps> {
    render() {
        return (
            <C.Modal active={this.props.active}>
                <div className='modal-card model-sobre'>
                    <header className='modal-sobre-head'>
                        <C.Image src={imgLogoRni} className='is-100x60' />
                        {this.props.hasCloseButton && <button className='delete' onClick={this.props.onClose}></button>}
                    </header>
                    <section className='modal-sobre-body'>
                        <div className='titulo'>RNI - Registro Nacional de Implantes</div>
                        <div className='versao is-neon'>
                            Versão: {!Env.isTest() ? this.props.versao : this.props.versao + '--DEV'}
                        </div>
                        <div className='desenvolvido-por'>SOFTWARE DESENVOLVIDO POR</div>
                        <div className='has-text-centered'>
                            <C.Image src={imgLogoBridge} className='logo-bridge' />
                        </div>
                        <div className='borda-informacoes has-text-centered'>
                            <span>
                                Desenvolvido em parceria com a SBOT - Sociedade Brasileira de Ortopedia e Traumatologia
                                e SBHCI - Sociedade Brasileira de Hemodinâmica e Cardiologia Intervencionista
                            </span>
                        </div>
                    </section>
                    <section className='modal-sobre-footer'>
                        <div className='is-children-center justify-center'>
                            <img src={imgLogoUfsc} className='div' alt='Logo UFSC' />
                            <img src={imgLogoAnvisa} className='div' alt='Logo Anvisa' />
                            <img src={imgLogoGoverno} className='div' alt='Logo Gov Federal' />
                        </div>
                    </section>
                </div>
            </C.Modal>
        )
    }
}
