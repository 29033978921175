import { required, createValidator } from 'libs/validation'

export interface LoginFormValues {
    username?: string
    password?: string
}

export default createValidator<LoginFormValues>({
    username: [required],
    password: [required],
})
