/* tslint:disable */
import React from 'react'

const SvgHistorico = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 36 36' {...props}>
        <path d='M28.7 15.554c1.06 5.703-2.719 11.207-8.423 12.267-.646.12-1.29.179-1.926.179-4.969 0-9.401-3.544-10.342-8.6a.573.573 0 111.127-.21c.946 5.083 5.844 8.45 10.932 7.506 5.084-.945 8.45-5.85 7.506-10.933-1.005-5.405-6.473-8.24-10.945-7.503-3.204.53-5.214 2.837-6.046 4.009l2.023-.377a.572.572 0 11.208 1.126l-3.234.603a.575.575 0 01-.667-.459L8.31 9.927a.572.572 0 111.126-.21l.322 1.73c1.002-1.372 3.192-3.74 6.686-4.317 5.01-.83 11.127 2.359 12.256 8.424zm-10.575-3.306c.316 0 .573.257.573.573v5.309l3.726 2.728c.255.187.31.546.125.8a.574.574 0 01-.802.125l-3.96-2.902a.569.569 0 01-.234-.46v-5.6c0-.316.256-.573.572-.573z' />
    </svg>
)

export default SvgHistorico
