import Auth from 'api/Auth'
import Usuario from 'api/Usuario'
import { promiseReducer } from 'redux/middleware/PromiseAction'
import { SelecaoPerfilDto, UserDTO } from 'backend'
import { PromiseRecord } from '../middleware/PromiseAction'
import { AxiosResponse } from 'axios'
import TermoUso from 'api/TermoUso'

export const LOGIN_REQUEST = 'rni/auth/LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'rni/auth/LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'rni/auth/LOGIN_FAILURE'
export const LOGOUT_SUCCESS = 'rni/auth/LOGOUT_SUCCESS'

export const PERFIL_UNICO_SELECIONADO = 'rni/auth/PERFIL_UNICO_SELECIONADO'

export const FIND_PERFIS = 'rni/auth/FIND_PERFIS'
export const CLEAR_PERFIS = 'rni/auth/CLEAR_PERFIS'

export interface AuthState
    extends Readonly<{
        error?: any
        user?: UserDTO & { perfilUnico: boolean }
        perfis?: PromiseRecord<SelecaoPerfilDto[]>
    }> {}

export const AuthInitialState: AuthState = {
    perfis: {},
}

export default function reducer(state = AuthInitialState, action): AuthState {
    switch (action.type) {
        case LOGIN_REQUEST:
            return { ...state, error: undefined, user: undefined }
        case LOGIN_SUCCESS:
            return { ...state, error: undefined, user: action.user }
        case LOGIN_FAILURE:
            return { ...state, error: action.error, user: undefined }
        case LOGOUT_SUCCESS:
            return { ...state, user: undefined }
        case PERFIL_UNICO_SELECIONADO:
            return { ...state, user: { ...state.user, perfilUnico: true } }
        case FIND_PERFIS:
            return { ...state, perfis: promiseReducer(state.perfis, action) }
        case CLEAR_PERFIS:
            return { ...state, perfis: {} }
        default:
            return state
    }
}

export const loginRequest = () => ({
    type: LOGIN_REQUEST,
})

export const loginSuccess = (user: UserDTO) => ({
    type: LOGIN_SUCCESS,
    user,
})

export const loginFailure = error => ({
    type: LOGIN_FAILURE,
    error,
})

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
})

export const perfilUnicoSelecionado = () => ({
    type: PERFIL_UNICO_SELECIONADO,
})

export function login(credentials) {
    return function(dispatch) {
        dispatch(loginRequest())
        return Auth.login(credentials)
            .then(async result => {
                await TermoUso.carregarAceiteTermoUso()
                return handleAuthSucceeded(dispatch, result, 'login')
            })
            .catch(error => {
                dispatch(loginFailure(error.response.status))
                return Promise.reject(error)
            })
    }
}

export function checkLogin() {
    return async function(dispatch, getState) {
        if (getState().auth.user) {
            return Promise.resolve()
        } else {
            dispatch(loginRequest())
            await TermoUso.carregarAceiteTermoUso()
            return Auth.authenticate()
                .then(result => {
                    dispatch(loginSuccess(result.data))
                    return Promise.resolve(result)
                })
                .catch(error => {
                    dispatch(loginFailure(error.response.status))
                    return Promise.reject(error)
                })
        }
    }
}

export function updateLogin() {
    return function(dispatch) {
        dispatch(loginRequest())
        return Auth.authenticate()
            .then(result => {
                dispatch(loginSuccess(result.data))
                return Promise.resolve(result)
            })
            .catch(error => {
                dispatch(loginFailure(error.response.status))
                return Promise.reject(error)
            })
    }
}

export function selecionarPerfil(perfil: SelecaoPerfilDto) {
    return function(dispatch) {
        return Usuario.selecionarPerfil(perfil).then(() => {
            return doAuthenticate(dispatch)
        })
    }
}

export function limparVinculo() {
    return function(dispatch) {
        return Usuario.limparVinculo().then(() => {
            return doAuthenticate(dispatch)
        })
    }
}

export function doAuthenticate(dispatch) {
    return Auth.authenticate()
        .then(result => handleAuthSucceeded(dispatch, result, 'perfilSelecionado'))
        .catch(error => Promise.reject(error))
}

export function selecionarPerfilAutomatico(perfil: SelecaoPerfilDto) {
    return function(dispatch) {
        return dispatch(selecionarPerfil(perfil)).then(() => {
            dispatch(perfilUnicoSelecionado())
            window.localStorage && window.localStorage.setItem('perfilSelecionado', Date.now().toString())
        })
    }
}

export function logout() {
    return function(dispatch) {
        dispatch(logoutSuccess())
        Auth.logout()
    }
}

export const findPerfis = () => ({
    type: FIND_PERFIS,
    promise: Usuario.getPerfis(),
})

export const clearPerfis = () => ({
    type: CLEAR_PERFIS,
})

const handleAuthSucceeded = (dispatch: any, response: AxiosResponse<UserDTO>, storageItem: string) => {
    dispatch(loginSuccess(response.data))
    window.localStorage && window.localStorage.setItem(storageItem, Date.now().toString())
    return Promise.resolve(response)
}

export function carregarAceiteTermoUso() {
    return function(dispatch) {
        return TermoUso.carregarAceiteTermoUso().then(() => {
            return atualizarTermoAceite(dispatch)
        })
    }
}

export function atualizarTermoAceite(dispatch) {
    return Auth.authenticate()
        .then(result => handleAuthSucceeded(dispatch, result, 'termoAtualizado'))
        .catch(error => Promise.reject(error))
}

export function aceitarTermoUso() {
    return function(dispatch) {
        return TermoUso.aceitarTermoUso().then(() => {
            return atualizarTermoAceite(dispatch)
        })
    }
}
