import { ProdutoDto } from 'backend'
import registroAnvisa from 'images/ajuda/identificacao-anvisa/ic-registroanvisa.svg'
import { ReactComponent as CampoRegistroAnvisa } from 'images/ajuda/identificacao-anvisa/registro-anvisa.svg'
import { ReactComponent as SemRegistroAnvisa } from 'images/ajuda/identificacao-anvisa/semregistro.svg'
import { BodyItem, CarouselItem, CheckboxField, Column, Columns } from 'libs/components'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { clearFabricantes, loadFabricantes } from 'redux/modules/rastreabilidade'
import { SectionWithHelpModal } from 'view/componentes/SectionWithAjudaModal'
import { ProdutoDetalhes, ProdutoField } from '.'
import { ProdutoRastreabilidadeFormDto } from '../common/useDadosProdutoForm'

export interface IdentificacaoAnvisaFragmentProps {
    formFragment: ProdutoRastreabilidadeFormDto
    fabricantes: string[]
}
export const IdentificacaoAnvisaFragment = ({ formFragment, ...rest }: IdentificacaoAnvisaFragmentProps) => {
    const dispatch = useDispatch()
    const onChangeRegistroAnvisa = e => {
        const filtro = e as ProdutoDto
        if (filtro && filtro.registroAnvisa) {
            dispatch(loadFabricantes(filtro.registroAnvisa))
        } else {
            dispatch(clearFabricantes())
        }
    }
    return (
        <SectionWithHelpModal title='IDENTIFICAÇÃO ANVISA' modalBody={modalBodyItems} modalHeader={modalHeaderItems}>
            <Columns>
                <Column size='6'>
                    <ProdutoField
                        maxLength={11}
                        required={!formFragment.semRegistroAnvisa}
                        label='Registro ANVISA'
                        name='registroAnvisa'
                        placeholder='Registro ANVISA'
                        disabled={formFragment.semRegistroAnvisa}
                        onChange={onChangeRegistroAnvisa}
                    />
                </Column>
                <Column pulledBottom>
                    <CheckboxField label='Etiqueta sem registro da ANVISA' name='semRegistroAnvisa' />
                </Column>
            </Columns>
            <ProdutoDetalhes
                semRegistroAnvisa={formFragment.semRegistroAnvisa}
                registroAnvisa={formFragment.registroAnvisa}
                fabricantes={rest.fabricantes}
            />
        </SectionWithHelpModal>
    )
}

const modalHeaderItems = [<CarouselItem key='car-item-cod-anvisa' image={registroAnvisa} text='Registro ANVISA' />]
const modalBodyItems = [
    <BodyItem key='body-item-id-anvisa-1'>
        <div className='is-vertical-flow vertical-gutter'>
            <p>Insira o número do registro da ANVISA do produto no campo.</p>
            <CampoRegistroAnvisa />
        </div>
        <div className='is-vertical-flow vertical-gutter'>
            <p>
                Caso a etiqueta do produto não possua registro, habilite o preenchimento dos campos marcando o checkbox.
            </p>
            <SemRegistroAnvisa />
        </div>
        <p>Preencha os campos apresentados.</p>
    </BodyItem>,
]
