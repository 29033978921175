export class Environment {
    isDev() {
        return process.env.NODE_ENV === 'development'
    }

    isProd() {
        return process.env.NODE_ENV === 'production'
    }

    isTest() {
        return process.env.NODE_ENV === 'test'
    }
}

export default new Environment()
