import { PerfilEnum, PerfisCardiologistas, PerfisMedico, PerfisOrtopedistas } from 'api/Perfis'
import PerfilRoute from 'libs/router/PerfilRoute'
import React from 'react'
import { Switch } from 'react-router-dom'
import RastreabilidadePerfilRoute from '../rastreabilidade/route/RastreabilidadePerfilRoute'
import { EdicaoDadosProduto } from './EdicaoDadosProduto'
import { EdicaoPreFicha } from './EdicaoPreFicha'
import { EdicaoPrimariaJoelho } from './EdicaoPrimariaJoelho'
import { EdicaoPrimariaQuadril } from './EdicaoPrimariaQuadril'
import { EdicaoRevisaoJoelho } from './EdicaoRevisaoJoelho'
import { EdicaoRevisaoQuadril } from './EdicaoRevisaoQuadril'
import { EdicaoStentCoronaria } from './EdicaoStentCoronaria'
import { EdicaoDadosFinanceiro } from './EdicaoDadosFinanceiro'
import { EdicaoRastreabilidade } from './EdicaoRastreabilidade'

export const EdicaoProcedimento = () => (
    <Switch>
        <PerfilRoute
            perfis={[...PerfisMedico, PerfilEnum.TECNICO]}
            path={'/home/edicaoProcedimento/preFicha/:idFicha'}
            component={EdicaoPreFicha}
        />
        <PerfilRoute
            perfis={[...PerfisOrtopedistas]}
            path={'/home/edicaoProcedimento/primariaJoelho/:idFicha'}
            component={EdicaoPrimariaJoelho}
        />
        <PerfilRoute
            perfis={[...PerfisOrtopedistas]}
            path={'/home/edicaoProcedimento/revisaoJoelho/:idFicha'}
            component={EdicaoRevisaoJoelho}
        />
        <PerfilRoute
            perfis={[...PerfisOrtopedistas]}
            path={'/home/edicaoProcedimento/primariaQuadril/:idFicha'}
            component={EdicaoPrimariaQuadril}
        />
        <PerfilRoute
            perfis={[...PerfisOrtopedistas]}
            path={'/home/edicaoProcedimento/revisaoQuadril/:idFicha'}
            component={EdicaoRevisaoQuadril}
        />
        <PerfilRoute
            perfis={[...PerfisCardiologistas]}
            path={'/home/edicaoProcedimento/stentCoronaria/:idFicha'}
            component={EdicaoStentCoronaria}
        />
        <RastreabilidadePerfilRoute
            perfis={[...PerfisMedico, PerfilEnum.TECNICO, PerfilEnum.ADMINISTRATIVO]}
            path={'/home/edicaoProcedimento/dadosProduto/'}
            component={EdicaoDadosProduto}
        />
        <RastreabilidadePerfilRoute
            perfis={[PerfilEnum.ADMINISTRATIVO]}
            path={'/home/edicaoProcedimento/dadosFinanceiro/'}
            component={EdicaoDadosFinanceiro}
        />
        <RastreabilidadePerfilRoute
            perfis={[PerfilEnum.ADMINISTRATIVO]}
            path={'/home/edicaoProcedimento/dadosProdutoFinanceiro/'}
            component={EdicaoRastreabilidade}
        />
    </Switch>
)
