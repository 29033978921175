import * as React from 'react'
import * as C from 'libs/components'
import field from 'libs/redux-form/field'
import * as DateUtil from 'libs/util/DateUtil'
import { WrappedFieldProps } from 'redux-form'

export const parseToStore = (value: string): string => {
    if (Object.prototype.toString.call(value) === '[object Date]') {
        const dataInvertida = new Date(value)
            .toISOString()
            .split('T')[0]
            .split('-')
        const resultDate = new Date(+dataInvertida[0], +dataInvertida[1] - 1, +dataInvertida[2], 0, 0, 0, 0)
        return resultDate.toISOString().split('T')[0]
    }

    if (value) {
        const dataInvertida = value.split('/')
        const resultDate = new Date(+dataInvertida[2], +dataInvertida[1] - 1, +dataInvertida[0], 0, 0, 0, 0)
        return resultDate.toISOString().split('T')[0]
    } else {
        return value
    }
}

export const formatToValue = (state: string): Date => {
    if (state && state.length > 1) {
        return DateUtil.createDateFromString(state)
    }
    return state as any
}

export interface DatePickerFieldProps
    extends C.FormFieldProps,
        Partial<C.DatePickerInputProps>,
        Partial<WrappedFieldProps> {
    placeholder?: string
}

export class DatePickerFieldCmp extends React.Component<DatePickerFieldProps> {
    handleChange(value: Date | null, event: React.SyntheticEvent<any>) {
        this.props.input.onChange(value as any)
    }

    render() {
        // Remove o `onChange` do props.input (do redux-form) pois sua assinatura é incompatível
        // Tratamos o change event manualmente e enviamos ao `onChange` do redux-form via `handleChange`
        const { onChange, ...restInputProps } = this.props.input

        return (
            <C.DatePickerInput
                {...restInputProps}
                onChange={this.handleChange.bind(this)}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                disabled={this.props.disabled}
                placeholderText={this.props.placeholder}
                onBlur={e => this.props.input.onBlur(e)}
            />
        )
    }
}

// DatePicker não aceita o normalize
export const DatePickerField = field({
    format: formatToValue,
    parse: parseToStore,
})(DatePickerFieldCmp)
