import * as React from 'react'
import * as C from 'libs/components'
import { dominanciaByLado } from 'api/Procedimento'
import { DadosGeraisDto } from 'backend'

interface DadosGeraisVisualizacaoProps {
    paciente: DadosGeraisDto
}

export class DadosPaciente extends React.Component<DadosGeraisVisualizacaoProps> {
    render() {
        return (
            <C.Conteudo>
                <C.Columns auto>
                    <C.TextLabel title='Paciente' value={this.props.paciente.nomePaciente} />
                </C.Columns>
                <C.Columns auto>
                    <C.MaskedLabel title='CPF' value={this.props.paciente.cpfPaciente} mask='###.###.###-##' />
                    <C.MaskedLabel
                        title='CNS'
                        value={this.props.paciente.cnsPaciente}
                        mask='###.####.####.####'
                        placeholder='Não informado'
                    />
                </C.Columns>
                <C.Columns auto>
                    <C.DateLabel title='Data de nascimento' value={this.props.paciente.dataNascPaciente} />
                    {this.props.paciente.tipoProcedimento === 'STENT_CORONARIA' && (
                        <C.TextLabel title='Dominância coronária' value={dominanciaByLado[this.props.paciente.lado]} />
                    )}
                </C.Columns>
            </C.Conteudo>
        )
    }
}
