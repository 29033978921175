import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'
import { RelacaoEmpresasForm, RelacaoRespostaDto } from 'backend'
import RelacaoEmpresas from 'api/RelacaoEmpresas'

export interface RelacaoEmpresasState
    extends Readonly<{
        resposta: PromiseRecord<RelacaoRespostaDto>
        relacao: PromiseRecord<RelacaoEmpresasForm>
        openTooltip: boolean
        showNotificationDot: boolean
    }> {}

const GET_RELACAO_RESPOSTA = 'rni/relacaoEmpresas/GET_RELACAO_RESPOSTA'
const CLEAR_RELACAO_RESPOSTA = 'rni/relacaoEmpresas/CLEAR_RELACAO_RESPOSTA'
const GET_RELACAO_EMPRESAS = 'rni/relacaoEmpresas/GET_RELACAO_EMPRESAS'
const CLEAR_RELACAO_EMPRESAS = 'rni/relacaoEmpresas/CLEAR_RELACAO_EMPRESAS'
const OPEN_TOOLTIP = 'rni/relacaoEmpresas/OPEN_TOOLTIP'
const CLOSE_TOOLTIP = 'rni/relacaoEmpresas/CLOSE_TOOLTIP'
const SHOW_NOTIFICATION_DOT = 'rni/relacaoEmpresas/SHOW_NOTIFICATION_DOT'
const HIDE_NOTIFICATION_DOT = 'rni/relacaoEmpresas/HIDE_NOTIFICATION_DOT'

export default function reducer(
    state: RelacaoEmpresasState = {} as RelacaoEmpresasState,
    action
): RelacaoEmpresasState {
    switch (action.type) {
        case GET_RELACAO_RESPOSTA:
            return { ...state, resposta: promiseReducer(state.resposta, action) }
        case CLEAR_RELACAO_RESPOSTA:
            return { ...state, resposta: {} }
        case GET_RELACAO_EMPRESAS:
            return { ...state, relacao: promiseReducer(state.relacao, action) }
        case CLEAR_RELACAO_EMPRESAS:
            return { ...state, relacao: {} }
        case OPEN_TOOLTIP:
            return { ...state, openTooltip: true }
        case CLOSE_TOOLTIP:
            return { ...state, openTooltip: false }
        case SHOW_NOTIFICATION_DOT:
            return { ...state, showNotificationDot: true }
        case HIDE_NOTIFICATION_DOT:
            return { ...state, showNotificationDot: false }
        default:
            return state
    }
}

export const getRelacaoResposta = () => ({
    type: GET_RELACAO_RESPOSTA,
    promise: RelacaoEmpresas.getResposta(),
})

export const clearRelacaoResposta = () => ({
    type: CLEAR_RELACAO_RESPOSTA,
})

export const getRelacaoEmpresas = () => ({
    type: GET_RELACAO_EMPRESAS,
    promise: RelacaoEmpresas.getRelacao(),
})

export const clearRelacaoEmpresas = () => ({
    type: CLEAR_RELACAO_EMPRESAS,
})

export const openTooltipRelacaoEmpresas = () => ({
    type: OPEN_TOOLTIP,
})

export const closeTooltipRelacaoEmpresas = () => ({
    type: CLOSE_TOOLTIP,
})

export const showNotificationDot = () => ({
    type: SHOW_NOTIFICATION_DOT,
})

export const hideNotificationDot = () => ({
    type: HIDE_NOTIFICATION_DOT,
})
