import { connect } from 'react-redux'
import { ListaPacientesView, ListaPacientesProps } from './ListaPacientesView'
import * as filtroReport from 'redux/modules/report/filtro'
import Report from 'api/Report'
import { State } from 'redux/modules'
import { withRouter } from 'react-router-dom'
import { PacienteProcedimentoReportFiltro, PacienteProcedimentoReportDto, TipoProcedimento} from 'backend'
import { PageRequester} from 'redux/requester'
import { tiposProcedimentoUrlByEnum } from 'api/Procedimento'

const requester = new PageRequester<PacienteProcedimentoReportDto, PacienteProcedimentoReportFiltro>('pacientesProcedimentos', filtro =>
    Report.getPacientesProcedimentos(filtro)
)

const mapStateToProps = (state: State, ownProps: ListaPacientesProps) => ({
    filtro: state.report.filtro.filtro as PacienteProcedimentoReportFiltro,
    data: requester.getResult(state),
    isFetching: requester.getIsFetching(state),
})

const mapDispatchToProps = (dispatch, ownProps: ListaPacientesProps) => ({
	changeFilter: (filtro: PacienteProcedimentoReportFiltro) => {
        dispatch(filtroReport.change({ ...filtro }))
    },
    handlePageChange: (page: number) => {
        dispatch(requester.setPageNumber(page))
        dispatch(requester.request())
    },
   	load: (filtro: PacienteProcedimentoReportFiltro) => {
        dispatch(requester.setParams({ ...filtro }))
        dispatch(requester.request())
    },
    visualizarFicha: (idProcedimento: number, tipoProcedimento: TipoProcedimento) => {
        const procedimentoUrl = tiposProcedimentoUrlByEnum[tipoProcedimento]
        ownProps.history.push(`${ownProps.match.url}/visualizacao/${procedimentoUrl}/` + idProcedimento)
    },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(ListaPacientesView))
