/* tslint:disable */
import React from 'react'

const SvgSobre = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
        <path d='M8 14A6 6 0 118 2a6 6 0 010 12zm-.098-9.429a.832.832 0 00-.838.842c0 .47.37.842.838.842a.832.832 0 00.837-.842c0-.47-.37-.842-.837-.842zm-1.06 5.719v1.139h2.315v-1.14H8.74V6.875H6.843v1.138h.418v2.278h-.418z' />
    </svg>
)

export default SvgSobre
