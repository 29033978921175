import React from 'react'
import { FormSection, reduxForm, formValueSelector, FieldArray } from 'redux-form/immutable'
import { connect } from 'react-redux'
import * as C from 'libs/components'
import * as UUID from 'libs/util/UUID'
import * as FichaComponents from 'view/home/novoProcedimento/ficha/componentes'
import StentsUtilizadosModalValidator from 'view/home/novoProcedimento/ficha/cardio/validators/StentsUtilizadosModalValidator'
import { isImmutable } from 'immutable'

const tipoStent = {
    BIOABSORVIVEL: 'Bioabsorvível',
    BIOABSORVIVEL_COM_FARMACO: 'Bioabsorvível e farmacológico',
    CONVENCIONAL: 'Convencional',
    FARMACOLOGICO: 'Farmacológico',
    NAO_SE_APLICA: false,
}

const tipoEnxerto = {
    ARTERIAL: 'Aorto-coronário (arterial)',
    VENOSO: 'Aorto-coronário (venoso)',
    MAMARIA_DIREITA: 'In situ (mamária interna direita)',
    MAMARIA_ESQUERDA: 'In situ (mamária interna esquerda)',
}

export class AdicionarStent extends React.Component {
    render() {
        return (
            <C.Conteudo>
                <C.ControlField name={this.props.name} label='Stents utilizados em lesões tratadas' required />
                <FieldArray
                    name={this.props.name}
                    disabled={this.props.disabled}
                    options={this.props.options}
                    component={StentsUtilizados}
                />
            </C.Conteudo>
        )
    }
}

class StentsUtilizadosCmp extends React.Component {
    constructor() {
        super()
        this.state = { openForm: false }

        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.save = this.save.bind(this)
        this.delete = this.delete.bind(this)
    }

    render() {
        return (
            <ul>
                {(!this.props.fields || this.props.fields.length === 0 || this.props.fields.size === 0) && (
                    <C.EmptyListItem label='Não há stents registrados.' />
                )}
                {this.props.fields.map((name, index) => (
                    <StentCard
                        chave={index}
                        key={index}
                        values={this.props.fields.get(index)}
                        onClickDelete={this.delete(index)}
                        options={this.props.options}
                        lesoes={this.props.lesoes}
                        enxertos={this.props.enxertos}
                    />
                ))}
                {
                    <C.Button
                        type='grey'
                        size='large'
                        square
                        dashed
                        fullwidth
                        disabled={this.props.disabled}
                        onClick={this.open}
                    >
                        + ADICIONAR STENT
                    </C.Button>
                }
                {this.state.openForm && (
                    <StentsUtilizadosForm
                        onSubmit={this.save}
                        close={this.close}
                        options={this.props.options}
                        sucesso={this.props.sucesso}
                        tipoStent={this.props.tipoStent}
                        tipoFalha={this.props.tipoFalha}
                        especFalha={this.props.especFalha}
                        lesoes={this.props.lesoes}
                        enxertos={this.props.enxertos}
                        idTipoFalha={this.props.idTipoFalha}
                        motivoInsucessoFalhaProduto={this.props.motivoInsucessoFalhaProduto}
                        localStent={this.props.localStent}
                    />
                )}
            </ul>
        )
    }

    open() {
        this.setState({ openForm: true })
    }

    close() {
        this.setState({ openForm: false })
    }

    save(form) {
        form = form.set('id', UUID.generateUUID())
        this.props.fields.push(form)
        this.close()
    }
    delete(index) {
        return () => {
            this.props.fields.remove(index)
        }
    }
}

class StentCard extends React.Component {
    constructor() {
        super()
        this.getLesoesStent = this.getLesoesStent.bind(this)
        this.getLesoesEnxerto = this.getLesoesEnxerto.bind(this)
    }

    render() {
        const stent = this.props.values.get('nomeStent')
        let nmStent, tpStent
        if (isImmutable(stent)) {
            tpStent = stent.get('tipo')
            nmStent = stent.get('nome')
        } else {
            tpStent = stent.tipo
            nmStent = stent.nome
        }
        return (
            <li name={'stent_' + (this.props.chave + 1)}>
                <C.Panel>
                    <C.PanelHeader>
                        {nmStent}
                        {tipoStent[tpStent] && <C.PanelSubHeader>{' (' + tipoStent[tpStent] + ')'}</C.PanelSubHeader>}
                        <C.Hint
                            placement='top'
                            componente={
                                <C.IconButton pulledRight onClick={this.props.onClickDelete} icon='delete' iconOnly />
                            }
                        >
                            Excluir stent
                        </C.Hint>
                    </C.PanelHeader>
                    <C.PanelContent>
                        <C.Columns>
                            <C.Column size='4'>
                                <C.TextLabel title='Diâmetro:' value={this.props.values.get('diametro') + 'mm'} />
                            </C.Column>
                            <C.Column size='4'>
                                <C.TextLabel title='Comprimento:' value={this.props.values.get('comprimento') + 'mm'} />
                            </C.Column>
                        </C.Columns>

                        {this.getLesoesStent()}
                        {this.getLesoesEnxerto()}
                        <C.Linha />
                        <C.TextLabel
                            title='Stent implantado com sucesso:'
                            value={this.props.values.get('stentSucesso') === 'SIM' ? 'Sim' : 'Não'}
                        />
                        {this.props.values.get('stentSucesso') === 'NAO' && (
                            <div>
                                <C.Columns>
                                    <C.Column size='4'>
                                        <C.TextLabel
                                            title='Motivo do insucesso:'
                                            value={this.props.values.get('motivoInsucesso').nome}
                                        />
                                    </C.Column>
                                    <C.Column size='8'>
                                        {this.props.values.get('tipoFalha') && (
                                            <C.TextLabel
                                                title='Tipo de falha:'
                                                value={this.props.values.get('tipoFalha').nome}
                                            />
                                        )}
                                    </C.Column>
                                </C.Columns>
                                <C.Columns>
                                    <C.Column>
                                        {this.props.values.get('especificacaoFalha') && (
                                            <C.TextLabel
                                                title='Especificação da falha:'
                                                value={this.props.values.get('especificacaoFalha').nome}
                                            />
                                        )}
                                    </C.Column>
                                </C.Columns>
                            </div>
                        )}
                    </C.PanelContent>
                </C.Panel>
            </li>
        )
    }

    getLesoesStent() {
        const nativas = this.props.values.get('lesoesRegistradas')
        let toPrint
        if (nativas) {
            return Array.from(nativas).map(([index, value]) => {
                return (
                    <div key={value}>
                        {(toPrint = value.length > 0 || value.size > 0)}
                        {toPrint &&
                            this.props.lesoes.map(
                                (lValor, lIndex) =>
                                    lValor.get('id') === index && (
                                        <C.Label key={lIndex}>Lesão {lIndex + 1} (em coronária nativa) </C.Label>
                                    )
                            )}
                        {toPrint &&
                            value.map(lesao => (
                                <C.StyledCheckboxLabel key={lesao} label={this.props.options[transformLower(lesao)]} />
                            ))}
                    </div>
                )
            })
        }
    }

    getLesoesEnxerto() {
        const enxerto = this.props.values.get('enxertoStent')
        if (enxerto) {
            let enxertoId, enxertoNome
            if (isImmutable(enxerto)) {
                enxertoId = enxerto.get('id')
                enxertoNome = enxerto.get('nome')
            } else {
                enxertoId = enxerto.id
                enxertoNome = enxerto.nome
            }
            return (
                <div key={1}>
                    {this.props.enxertos.map(
                        (lValor, lIndex) =>
                            lValor.get('id') === enxertoId && (
                                <C.Label key={lIndex}>Lesão {lIndex + 1} (em enxerto) </C.Label>
                            )
                    )}
                    <C.StyledCheckboxLabel key={2} label={enxertoNome} />
                </div>
            )
        }
    }
}

class StentsUtilizadosFormCmp extends React.Component {
    constructor() {
        super()
        this.getLesoesResgistradas = this.getLesoesResgistradas.bind(this)
        this.getEnxertosRegistrados = this.getEnxertosRegistrados.bind(this)
        this.getCoberturaStent = this.getCoberturaStent.bind(this)
        this.getCoberturaStentOpcoesNativa = this.getCoberturaStentOpcoesNativa.bind(this)
        this.getCoberturaStentOpcoesEnxerto = this.getCoberturaStentOpcoesEnxerto.bind(this)
    }

    getLesoesResgistradas() {
        return (
            <div className='segmento-container'>
                {this.props.lesoes.map((value, index) => (
                    <div key={value.get('id')} className='is-vertical-flow'>
                        <C.Label className='composed-label small'> Lesão {index + 1} </C.Label>
                        <div
                            key={'lesao_' + value.get('id')}
                            data-name={'lesao_' + (index + 1)}
                            className='is-horizontal-flow flex-wrap'
                        >
                            <div className='is-horizontal-flow flex-wrap'>
                                {value.get('segmentos').map(valor => (
                                    <C.StyledChecklistItemField
                                        key={'label_' + valor}
                                        name={value.get('id')}
                                        optionValue={valor}
                                        label={this.props.options[transformLower(valor)]}
                                        className='segmento-checkbox'
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    getEnxertosRegistrados() {
        const options = []
        this.props.enxertos.forEach(value => {
            const tipoEnxertoSelecionado =
                value.get('enxertoTipo') === 'IN_SITU' ? value.get('enxertoTipoInsitu') : value.get('enxertoTipoAorto')
            const nome =
                tipoEnxerto[tipoEnxertoSelecionado] + ' - Coronária de destino: ' + value.get('coronariaDestino').abrev
            options.push({ id: value.get('id'), nome: nome })
        })
        return options
    }

    getCoberturaStent() {
        return (
            <div>
                {this.props.localStent === 'NATIVA' && (!this.props.lesoes || this.props.lesoes.size === 0) && (
                    <C.ControlField name='lesoesRegistradas' label='Segmentos'>
                        <C.TextLabel placeholder='Nenhuma lesão informada para esta opção, por favor selecione outra' />
                    </C.ControlField>
                )}

                {this.props.localStent === 'ENXERTO' && (!this.props.enxertos || this.props.enxertos.size === 0) && (
                    <C.ControlField name='enxertoStent' label='Enxerto'>
                        <C.TextLabel placeholder='Nenhuma lesão informada para esta opção, por favor selecione outra' />
                    </C.ControlField>
                )}
                {this.getCoberturaStentOpcoesNativa()}
                {this.getCoberturaStentOpcoesEnxerto()}
            </div>
        )
    }

    getCoberturaStentOpcoesNativa() {
        return (
            this.props.localStent === 'NATIVA' &&
            this.props.lesoes &&
            this.props.lesoes.size > 0 && (
                <div>
                    <C.ControlField name='lesoesRegistradas' label='Segmentos' required />
                    <FormSection name='lesoesRegistradas'>{this.getLesoesResgistradas()}</FormSection>
                </div>
            )
        )
    }

    getCoberturaStentOpcoesEnxerto() {
        return (
            this.props.localStent === 'ENXERTO' &&
            this.props.enxertos &&
            this.props.enxertos.size > 0 && (
                <FichaComponents.EnxertoField
                    name='enxertoStent'
                    label='Enxerto'
                    required
                    options={this.getEnxertosRegistrados()}
                />
            )
        )
    }

    render() {
        return (
            <C.ModalCard title='Adicionar stent' active onClose={this.props.close}>
                <C.Columns>
                    <C.Column size='6'>
                        <FichaComponents.NomeStentField
                            name='nomeStent'
                            label='Nome'
                            required
                            placeholder='Nome do stent'
                        />
                    </C.Column>
                    <C.Column size='3'>
                        <C.DecimalField
                            label='Diâmetro'
                            name='diametro'
                            unit={'mm'}
                            required
                            pre={1}
                            pos={2}
                            placeholder='(2 - 6)'
                        />
                    </C.Column>
                    <C.Column size='3'>
                        <C.NumberField
                            maxLength='2'
                            label='Comprimento'
                            name='comprimento'
                            unit={'mm'}
                            required
                            placeholder='(8 - 48)'
                        />
                    </C.Column>
                </C.Columns>
                {this.props.tipoStent && tipoStent[this.props.tipoStent] && (
                    <div className='composed-label is-italic small padding-bottom'>
                        {'Tipo de Stent: ' + tipoStent[this.props.tipoStent]}
                    </div>
                )}
                <C.ControlField label='Aplicado em' name='localStent' required horizontalFlow>
                    <C.RadioField name='localStent' label='Coronária nativa' value='NATIVA' />
                    <C.RadioField name='localStent' label='Enxerto' value='ENXERTO' />
                    <C.ClearRadioFieldButton name='localStent' />
                </C.ControlField>

                {this.getCoberturaStent()}

                <C.Linha size='small' />
                <C.SimNaoRadioField name='stentSucesso' label='Stent implantado com sucesso' required />
                {this.props.sucesso && (
                    <C.InputPanel fallback size='medium'>
                        <C.Columns>
                            <C.Column size='4'>
                                <FichaComponents.MotivoInsucessoField
                                    label='Motivo do insucesso'
                                    name='motivoInsucesso'
                                    required
                                />
                            </C.Column>
                            <C.Column size='7'>
                                <FichaComponents.TipoFalhaField
                                    label='Tipo de falha'
                                    name='tipoFalha'
                                    required={this.props.motivoInsucessoFalhaProduto}
                                    disabled={!this.props.motivoInsucessoFalhaProduto}
                                />
                            </C.Column>
                            {this.props.tipoFalha && (
                                <C.Column horizontalFlow alignedCenter className='helper-icon'>
                                    <C.InformationIconPopover
                                        information={this.props.tipoFalha && this.props.tipoFalha.descricao}
                                    />
                                </C.Column>
                            )}
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='11'>
                                <FichaComponents.EspecificacaoFalhaField
                                    label='Especificação da falha'
                                    name='especificacaoFalha'
                                    required={this.props.tipoFalha}
                                    disabled={!this.props.tipoFalha}
                                    tipoFalha={this.props.idTipoFalha}
                                />
                            </C.Column>
                            {this.props.especFalha && (
                                <C.Column alignedCenter className='helper-icon'>
                                    <C.InformationIconPopover
                                        information={this.props.especFalha && this.props.especFalha.descricao}
                                    />
                                </C.Column>
                            )}
                        </C.Columns>
                    </C.InputPanel>
                )}
                <C.Linha size='medium' />
                <C.ButtonBar>
                    <C.Button size='medium' onClick={this.props.close}>
                        Cancelar
                    </C.Button>
                    <C.Button
                        pulledRight
                        disabled={this.props.pristine}
                        type='primary'
                        size='medium'
                        onClick={this.props.handleSubmit}
                    >
                        Salvar
                    </C.Button>
                </C.ButtonBar>
            </C.ModalCard>
        )
    }
}

export const StentsUtilizadosForm = reduxForm({ form: 'adicionarStent', validate: StentsUtilizadosModalValidator })(
    StentsUtilizadosFormCmp
)

function transformLower(value) {
    return value.toLowerCase().replace('_', '-')
}

const selector = formValueSelector('stentCoronaria')
const selector2 = formValueSelector('adicionarStent')

const StentsUtilizados = connect(state => {
    const lesoes = selector(state, 'essencial.lesoesTratadas.lesoesNativas')

    const enxertos = selector(state, 'essencial.lesoesTratadas.lesoesEnxerto')

    const nomeStent = selector2(state, 'nomeStent')
    const tipoStent = nomeStent && nomeStent.tipo

    const sucesso = selector2(state, 'stentSucesso') === 'NAO'

    const motivoInsucesso = selector2(state, 'motivoInsucesso')

    const motivoInsucessoFalhaProduto = motivoInsucesso && motivoInsucesso.id === 'FALHA_PRODUTO'

    const tipoFalha = selector2(state, 'tipoFalha')

    const idTipoFalha = tipoFalha && tipoFalha.id

    const especFalha = selector2(state, 'especificacaoFalha')

    const localStent = selector2(state, 'localStent')
    return {
        lesoes,
        tipoStent,
        sucesso,
        tipoFalha,
        idTipoFalha,
        especFalha,
        motivoInsucessoFalhaProduto,
        localStent,
        enxertos,
    }
})(StentsUtilizadosCmp)
