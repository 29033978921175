import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'
import Captcha from 'api/Captcha'

export const CLEAR_RECAPTCHA = 'rni/recaptcha/CLEAR_RECAPTCHA'
export const VALIDATE_TOKEN = 'rni/recaptcha/VALIDATE_TOKEN'

export interface RecaptchaState
    extends Readonly<{
        valid: PromiseRecord<any>
    }> {}

export const RecaptchaInitialState: RecaptchaState = {
    valid: {},
}

export default function reducer(state = RecaptchaInitialState, action): RecaptchaState {
    switch (action.type) {
        case VALIDATE_TOKEN:
            return { ...state, valid: promiseReducer(state.valid, action) }
        case CLEAR_RECAPTCHA:
            return { ...state, valid: {} }
        default:
            return state
    }
}

export const validateToken = recaptchaToken => ({
    type: VALIDATE_TOKEN,
    promise: Captcha.validateRecaptcha(recaptchaToken),
})

export const validateTokenInvisible = recaptchaToken => ({
    type: VALIDATE_TOKEN,
    promise: Captcha.validateInvisibleRecaptcha(recaptchaToken),
})

export const clearRecaptcha = () => ({
    type: CLEAR_RECAPTCHA,
})
