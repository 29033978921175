import React from 'react'
import * as C from 'libs/components'
import * as FC from 'view/home/novoProcedimento/ficha/componentes'
import { StentCoronariaComplementarVisualizacaoDto } from 'backend'
import { dadosComplementaresByEnum } from 'api/Visualizacao'

interface ComplicacoesClinicasCardProps {
    dadosComplementares: StentCoronariaComplementarVisualizacaoDto
}

export class ComplicacoesClinicasCard extends React.Component<ComplicacoesClinicasCardProps> {
    render() {
        const data = this.props.dadosComplementares
        const tamanhoHematoma: { [key: string]: string } = {
            MENOR_TRES: '< 3cm',
            TRES_A_CINCO: '3 a 5cm',
            CINCO_A_DEZ: '5 a 10cm',
            MAIOR_DEZ: '> 10cm',
        }
        const labelHouveComplicacoesClinicas = (data.cardiaca === 'SIM' ||
            data.hemorragica === 'SIM' ||
            data.neurologica === 'SIM' ||
            data.reacaoContraste === 'SIM' ||
            data.respiratoria === 'SIM' ||
            data.vascular === 'SIM' ||
            data.obito === 'SIM') && (
            <C.Columns auto>
                <C.Label>Complicações clínicas que ocorreram em sala</C.Label>
            </C.Columns>
        )

        return (
            <div>
                {labelHouveComplicacoesClinicas}
                {data.cardiaca === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Cardíaca</C.Label>
                            <C.ComposedLabel title={'Gravidade'}>{data.cardiacaSeveridade}</C.ComposedLabel>
                        </li>
                    </ul>
                )}
                {data.hemorragica === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Hemorrágica</C.Label>
                            <C.ComposedLabel title={'Sangramento'}>{data.hemorragicaSangramento}</C.ComposedLabel>
                        </li>
                    </ul>
                )}
                {data.neurologica === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Neurológica</C.Label>
                            <C.ComposedLabel title={'Tipo AVE'}>
                                {dadosComplementaresByEnum[data.neurologicaTipoAve]}
                            </C.ComposedLabel>
                        </li>
                    </ul>
                )}
                {data.reacaoContraste === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Reação ao contraste</C.Label>
                            <C.ComposedLabel title={'Severidade'}>
                                {dadosComplementaresByEnum[data.reacaoContrasteSeveridade]}
                            </C.ComposedLabel>
                        </li>
                    </ul>
                )}
                {data.respiratoria === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Respiratória</C.Label>
                            <C.ComposedLabel title={'Tipo'}>{data.respiratoriaTipoComplicacao}</C.ComposedLabel>
                        </li>
                    </ul>
                )}
                {data.vascular === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Vascular</C.Label>
                            <C.Columns auto>
                                <C.TextLabel title='Tipo' value={data.vascularQuaisComplicacoes.replace('.', '')} />
                                {data.vascularQuaisComplicacoes.includes('Hematoma') && (
                                    <C.TextLabel
                                        title='Tamanho do hematoma'
                                        value={tamanhoHematoma[data.vascularHematoma]}
                                    />
                                )}
                            </C.Columns>
                        </li>
                    </ul>
                )}
                {data.obito === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Óbito</C.Label>
                            <C.ComposedLabel title={'Causa cardíaca'}>
                                {dadosComplementaresByEnum[data.obitoCausa]}
                            </C.ComposedLabel>
                        </li>
                    </ul>
                )}
                <FC.ComposedLabelVisualizacao title='Complicações clínicas que não ocorreram'>
                    {data.textoComplicacoesAvaliadosNao}
                </FC.ComposedLabelVisualizacao>
                {data.textoComplicacoesNaoAvaliados && (
                    <C.Columns auto>
                        <C.ComposedLabel
                            title='Complicações clínicas que não foram avaliadas'
                            placeholder={data.textoComplicacoesNaoAvaliados}
                        ></C.ComposedLabel>
                    </C.Columns>
                )}
            </div>
        )
    }
}
