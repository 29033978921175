import axios from 'axios'

const url = '/api/visualizacao'

class Visualizacao {
    getVisualizacaoProcedimento(idProcedimento: number, urlProcedimento: string) {
        return axios.get(url + urlProcedimento + idProcedimento)
    }
}

export default new Visualizacao()

export const tiposVisualizacaoProcedimentoUrlByEnum = {
    PRIMARIA_JOELHO: '/visualizacaoPrimariaJoelho/',
    PRIMARIA_QUADRIL: '/visualizacaoPrimariaQuadril/',
    REVISAO_JOELHO: '/visualizacaoRevisaoJoelho/',
    REVISAO_QUADRIL: '/visualizacaoRevisaoQuadril/',
    STENT_CORONARIA: '/visualizacaoStentCoronaria/',
}

export const tiposVisualizacaoHistoricoUrlByEnum = {
    PRIMARIA_JOELHO: '/visualizacaoHistPrimariaJoelho/',
    PRIMARIA_QUADRIL: '/visualizacaoHistPrimariaQuadril/',
    REVISAO_JOELHO: '/visualizacaoHistRevisaoJoelho/',
    REVISAO_QUADRIL: '/visualizacaoHistRevisaoQuadril/',
    STENT_CORONARIA: '/visualizacaoHistStentCoronaria/',
}

export const dadosComplementaresByEnum: { [key: string]: string } = {
    SIM: 'Sim',
    NAO: 'Não',
    CUMARINICO: 'Cumarínico',
    FATOR_XA: 'Fator Xa',
    INIBIDOR_TROMBINA: 'Inibidor trombina',
    Nenhuma: 'Nenhuma',
    Dieta: 'Dieta',
    Insulina: 'Insulina',
    Oral: 'Oral',
    Outra: 'Outra',
    Estatina: 'Estatina',
    Fibrato: 'Fibrato',
    SEM_LESOES: 'Sem lesões (>50%)',
    UM_VASO: '1 vaso',
    DOIS_VASOS: '2 vasos',
    TRES_VASOS: '3 vasos',
    INDETERMINADO: 'Indeterminado',
    NAO_DISPONIVEL: 'Não disponível',
    NEGATIVO: 'Negativo',
    POSITIVO: 'Positivo',
    ALTO: 'Alto',
    MEDIO: 'Médio',
    BAIXO: 'Baixo',
    ANGINA_ESTAVEL: 'Angina estável',
    ANGINA_INSTAVEL: 'Angina instável',
    ASSINTOMATICO: 'Assintomático',
    DOR_TORACICA_ATIPICA: 'Dor torácica atípica',
    EQUIVALENTE_ISQUEMICO: 'Equivalente isquêmico',
    IAM_COM_SUPRA_ST: 'IAM com supra ST',
    IAM_SEM_SUPRA_ST: 'IAM sem supra ST',
    URGENCIA: 'Urgência',
    INVASIVA_PRECOCE: 'Invasiva precoce',
    INVASIVA_RETARDADA_ELETIVA: 'Invasiva retardada/eletiva',
    ELETIVA: 'Eletiva',
    AD_HOC: 'AD-HOC',
    PRIMARIA_MENOS_12_HORAS_SINTOMA: 'Primária (< 12 horas de sintoma)',
    PRIMARIA_MAIS_12_HORAS_SINTOMA: 'Primária (> 12 horas e < 24 horas; Isquemia persistente)',
    FARMACO_INVASIVA: 'Fármaco invasiva (entre 03 e 24 horas de trombólise)',
    RESGATE: 'Resgate (após > 90 min trombólise sem critérios sucesso)',
    TARDIA: 'Tardia (> 24 horas, na mesma admissão)',
    AXILAR: 'Axilar',
    BRAQUIAL_DISSECCAO: 'Braquial dissecção',
    BRAQUIAL_PUNCAO: 'Braquial punção',
    FEMORAL: 'Femoral',
    RADIAL: 'Radial',
    ULNAR: 'Ulnar',
    QUATRO_F: '4F',
    CINCO_F: '5F',
    SEIS_F: '6F',
    SETE_F: '7F',
    OITO_F: '8F',
    OUTRO: 'Outro',
    IONICO_HIPEROSMOLAR: 'Iônico hiperosmolar',
    IONICO_BAIXA_OSMOLARIDADE: 'Iônico baixa osmolaridade',
    NAO_IONICO_BAIXA_OSMOLARIDADE: 'Não iônico baixa osmolaridade',
    NAO_IONICO_ISOSMOLAR: 'Não iônico isosmolar',
    FATAL_DEFINITIVO: 'Fatal definitivo (Evidente)',
    FATAL_PROVAVEL: 'Fatal provável (Suspeita clínica)',
    MENOR_TRES: '< 3.0 mg/dL',
    TRES_A_CINCO: '3.0 a 5.0 mg/dL',
    MAIOR_CINCO: '> 5.0 mg/dL',
    ISQUEMICO: 'Isquêmico',
    HEMORRAGICO: 'Hemorrágico',
    ATIVO: 'Ativo',
    EX_TABAGISTA: 'Ex-tabagista',
    UM_OU_DOIS_VASOS_SEM_DA_PROXIMAL: '1 ou 2 vasos; sem DA proximal',
    UM_VASO_COM_DA_PROXIMAL: '1 vaso; com DA proximal',
    DOIS_VASOS_COM_DA_PROXIMAL: '2 vasos; com DA proximal',
    TRES_VASOS_COM_DA_PROXIMAL: '3 vasos; sem TCE',
    TCE_ISOLADO: 'TCE isolado',
    TCE_UM_DOIS_VASOS: 'TCE + 1 ou 2 vasos',
    TCE_TRES_VASOS: 'TCE + 3 vasos',
    OCLUSAO_CRONICA_ISOLADA: 'Oclusão crônica isolada',
    HEMODIALISE: 'Em hemodiálise',
    NAO_DIALITICA: 'Não dialítica',
    NORMAL: 'Normal',
    DISCRETA: 'Discreta',
    MODERADA: 'Moderada',
    ACENTUADA: 'Acentuada',
    LEVE: 'Leve',
    GRAVE: 'Grave',
}
