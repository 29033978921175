import * as React from 'react'
import * as C from 'libs/components'
import { Field } from 'redux-form/immutable'
import { ControlProps } from 'libs/components'

const hasError = props => {
    return (props.meta.touched || props.meta.dirty) && props.meta.error
}

const renderField = props => (
    <C.FormField error={hasError(props) ? props.meta.error : null} {...props} name={props.input.name} />
)

interface ControlFieldProps extends ControlProps {
    name: string
    label?: string
    labelClassName?: string
    required?: boolean
    disabled?: boolean
}

export class ControlField extends React.Component<ControlFieldProps> {
    render() {
        return <Field {...this.props} component={renderField} />
    }
}
