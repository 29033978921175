
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.405,"time":24300,"words":81}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI.`}</li>
      <li parentName="ol">{`Clique no botão `}<img parentName="li" {...{
          "src": "/images/icone-alarmes.png#center",
          "alt": "..."
        }}></img>{`.`}</li>
      <li parentName="ol">{`Na central de alarmes selecione a opção “Configurar”.`}</li>
      <li parentName="ol">{`Encontre o alarme que está ativo e que você deseja desativar e clique no botão `}<img parentName="li" {...{
          "src": "/images/botao-opcoes.png#center",
          "alt": "..."
        }}></img>{`.`}</li>
      <li parentName="ol">{`Selecione a opção “Editar configuração”.`}</li>
      <li parentName="ol">{`No modal, desmarque a opção “Ativo”.`}</li>
      <li parentName="ol">{`Clique no botão “Salvar”.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observações:`}</strong>{` Após desativado o alarme, o RNI não irá mais realizar o monitoramento dele e nenhum dos inscritos irá receber alarmes de eventos, do alarme desativado.`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

