import { Fields } from 'redux/modules/forms'
import { PerfisMedico } from 'api/Perfis'
import { SolicitacaoVinculoFormSection } from 'backend'
import { ImmutableField } from 'libs/immutableProxy'

const solicitacaoVinculo = (
    mutableValue: ImmutableField<SolicitacaoVinculoFormSection, any>,
    mutableField: ImmutableField<Fields<SolicitacaoVinculoFormSection>, any>
) => {
    const perfil = mutableValue.perfil.get()

    if (!PerfisMedico.includes(perfil)) {
        mutableValue.numeroRegistro.delete()
        mutableValue.ufRegistro.delete()

        mutableField.numeroRegistro.delete()
        mutableField.ufRegistro.delete()
    }
}

export default solicitacaoVinculo
