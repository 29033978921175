import iconPaciente from 'images/home/cidadao/ic-paciente.svg'
import { MaskedLabel, DateLabel, Image } from 'libs/components'
import React from 'react'
import { CadastroPacienteForm } from 'backend'

export interface CardPacienteProps {
    paciente: CadastroPacienteForm
}

export const CardPaciente = ({ paciente }: CardPacienteProps) => (
    <div className='card-paciente has-text-centered'>
        <Image src={iconPaciente} size='64x64' />
        <span>
            <p className='nome'>
                <b>{paciente.nome}</b>
            </p>
            <MaskedLabel mask='###.###.###-##' title='CPF: ' value={paciente.cpf} horizontal />
            <MaskedLabel
                mask='###.####.####.####'
                title='CNS: '
                value={paciente.cns}
                horizontal
                placeholder='Não informado'
            />
            <DateLabel horizontal title='Data de nascimento: ' value={paciente.dataNascimento} />
        </span>
    </div>
)
