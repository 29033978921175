
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.01,"time":60600,"words":202}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Ao realizar o cadastro no sistema RNI, as etapas descritas e ilustradas abaixo deverão ser executadas:`}</p>
    <ol>
      <li parentName="ol">{`Preencha e envie seus dados de cadastro para o RNI.`}</li>
      <li parentName="ol">{`Será enviado um e-mail para confirmar o seu e-mail cadastrado.`}</li>
      <li parentName="ol">{`Acesse o seu e-mail, abra o e-mail enviado pelo RNI e confirme o e-mail cadastrado.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Esta confirmação deverá ocorrer dentro de 24 horas após o recebimento de e-mail de confirmação. Caso você não realize a confirmação dentro do prazo, suas informações serão excluídas do sistema, e será necessário que você preencha e envie as informações de cadastro novamente.`}</p>
    </blockquote>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">
        <p parentName="li">{`Será enviada a sua solicitação para o Gestor de Segurança responsável pela instituição na qual você solicitou o cadastro.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`O Gestor de Segurança da Instituição avaliará a sua solicitação de cadastro e a mesma será aceita (fluxo 5b) ou rejeitada (fluxo 5a).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Será enviado um e-mail informando o resultado da avaliação da sua solicitação. `}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`6a`}</strong>{`: Caso sua solicitação tenha sido rejeitada, será enviado um e-mail apresentando a justificativa para sua solicitação ter sido recusada.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`6b`}</strong>{`: Caso sua solicitação tenha sido aceita, será enviado um e-mail informando uma senha para que você possa acessar o sistema.`}</p>
          </li>
        </ul>
      </li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/images/cadastroUsuario.png",
        "alt": "Cadastro de Usuário"
      }}></img></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

