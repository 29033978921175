import React from 'react'
import * as C from 'libs/components'
import { formValueSelector } from 'redux-form/immutable'
import form from 'libs/redux-form/form'
import { connect } from 'react-redux'
import iconPaciente from 'images/home/cidadao/ic-paciente.svg'
import AlterarDadosPessoaisValidator from 'view/home/dadosPessoais/AlterarDadosPessoaisValidator'
import ContatoProfissionalFormSection from 'view/public/profissional/ContatoProfissionalForm'

const formName = 'alterarDadosPessoais'

class DadosPessoaisForm extends React.Component {
    render() {
        const { handleSubmit, submitting, cancelar, original } = this.props
        return (
            <C.Form {...this.props}>
                <C.SectionTitle title='IDENTIFICAÇÃO' />
                <C.Columns>
                    <C.Column verticalFlow size='2'>
                        <C.Image src={iconPaciente} alignedCenter size='96x96' />
                    </C.Column>
                    <C.Column>
                        <C.Columns>
                            <C.Column size='3'>
                                <C.MaskedField
                                    disabled
                                    label='CPF'
                                    name='cpf'
                                    placeholder='CPF'
                                    mask='999.999.999-99'
                                />
                            </C.Column>
                            <C.Column size='3'>
                                <C.NumberField
                                    disabled={original && original.cns}
                                    maxLength='15'
                                    label='CNS'
                                    name='cns'
                                    placeholder='CNS'
                                />
                            </C.Column>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='6'>
                                <C.TextField
                                    maxLength='70'
                                    required
                                    label='Nome completo'
                                    name='nome'
                                    placeholder='Nome e sobrenome do profissional'
                                />
                            </C.Column>
                            <C.Column size='3'>
                                <C.DatePickerField
                                    required
                                    name='dataNasc'
                                    placeholder='dd/mm/aaaa'
                                    label='Data de nascimento'
                                />
                            </C.Column>
                        </C.Columns>
                        <C.Columns>
                            <C.Column>
                                <C.SexoField required label='Sexo' name='sexo' />
                            </C.Column>
                        </C.Columns>
                    </C.Column>
                </C.Columns>
                <C.SectionTitle title='ENDEREÇO' />
                <C.EnderecoForm form={formName} name='enderecoForm' />
                <C.SectionTitle title='INFORMAÇÕES DE CONTATO' />
                <ContatoProfissionalFormSection
                    name='contatoForm'
                    disableConfirmarEmail={!this.props.enableConfirmacaoEmail}
                />
                <hr />
                <C.ButtonBar>
                    <C.Button size='medium' onClick={cancelar}>
                        Cancelar
                    </C.Button>
                    <C.Button pulledRight type='primary' size='medium' loading={submitting} onClick={handleSubmit}>
                        Salvar
                    </C.Button>
                </C.ButtonBar>
            </C.Form>
        )
    }
}

DadosPessoaisForm = form({
    form: formName,
    validate: AlterarDadosPessoaisValidator,
    successContent: <span>Seus dados pessoais foram atualizados com sucesso.</span>,
    successTitle: 'Atualização realizada com sucesso!',
})(DadosPessoaisForm)

const selector = formValueSelector(formName)

DadosPessoaisForm = connect(state => ({
    initialValues: state.profissional.dadosPessoais.data,
    original: state.profissional.dadosPessoais.data,
    enableConfirmacaoEmail: selector(state, 'contatoForm.email'),
}))(DadosPessoaisForm)

export default class AlterarDadosPessoaisView extends React.Component {
    componentWillUnmount() {
        this.props.clear()
    }

    componentDidMount() {
        this.props.findDadosPessoais()
    }

    render() {
        return (
            <div className='cadastro-paciente'>
                <DadosPessoaisForm
                    cancelar={this.props.cancelar}
                    onSubmit={this.props.save}
                    onSubmitSuccess={this.props.afterSave}
                />
            </div>
        )
    }
}
