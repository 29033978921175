import * as React from 'react'
import * as C from 'libs/components'

export default class FichaStentCoronariaEssencialSidebar extends React.Component {
    render() {
        return (
            <C.SideNav>
                <C.SideNavItem anchor='dados'>Dados do procedimento</C.SideNavItem>
                <C.SideNavItem anchor='lesao'>Lesão tratada</C.SideNavItem>
                <C.SideNavItem anchor='stent'>Stents utilizados</C.SideNavItem>
                <C.SideNavItem anchor='apresentacaoClinica'>Apresentação clínica</C.SideNavItem>
                <C.SideNavItem anchor='isquemia'>Teste isquemia/imagem</C.SideNavItem>
            </C.SideNav>
        )
    }
}
