import React, { Dispatch, SetStateAction, createContext, PropsWithChildren, useState } from 'react'

interface PesquisaSatisfacaoContextValue {
    isPesquisaVisible: boolean
    setIsPesquisaVisible: Dispatch<SetStateAction<boolean>>
}
export const PesquisaSatisfacaoCtx = createContext<PesquisaSatisfacaoContextValue>({
    isPesquisaVisible: false,
    setIsPesquisaVisible: null,
})
export const PesquisaSatisfacaoProvider = ({ children }: PropsWithChildren<{}>) => {
    const [isPesquisaVisible, setIsPesquisaVisible] = useState<boolean>(false)
    return (
        <PesquisaSatisfacaoCtx.Provider value={{ isPesquisaVisible, setIsPesquisaVisible }}>
            {children}
        </PesquisaSatisfacaoCtx.Provider>
    )
}
