import React from 'react'
import * as C from 'libs/components'

export class PrecoProduto extends React.Component {
    render() {
        return (
            <C.Columns>
                <C.Column>
                    <C.FormField label='Preço pago ao fornecedor'>
                        <C.Columns>
                            <C.Column size='6'>
                                <C.PriceField
                                    name='precoFornecedor'
                                    placeholder='R$'
                                    disabled={this.props.disabledInformacoes}
                                />
                            </C.Column>
                        </C.Columns>
                    </C.FormField>
                </C.Column>
                <C.Column>
                    <C.FormField label='Preço cobrado no ressarcimento'>
                        <C.Columns>
                            <C.Column size='6'>
                                <C.PriceField
                                    name='precoCobrado'
                                    placeholder='R$'
                                    disabled={this.props.disabledInformacoes}
                                />
                            </C.Column>
                        </C.Columns>
                    </C.FormField>
                </C.Column>
            </C.Columns>
        )
    }
}
