import { ProdutoDto, Validade } from 'backend'
import { FixedSelectOption } from 'libs/components'
import { useFormValues } from 'hooks/useFormValues'

export interface ProdutoRastreabilidadeIdCmpFormDto {
    dadosEtiqueta: string
    identificadorDispositivo: string
    codigoReferencia: string
    lote: string
    numeroSerie: string
    dataFabricacao: {
        ano: FixedSelectOption
        mes: FixedSelectOption
        dia: FixedSelectOption
    }
    validade: Validade
    periodoValidade: string
    anoValidade: FixedSelectOption
    mesValidade: FixedSelectOption
    diaValidade: FixedSelectOption
    etiqueta: any
}
export interface ProdutoRastreabilidadeAnvisaFormDto {
    registroAnvisa: ProdutoDto
    semRegistroAnvisa: boolean
    cnpj: string
    nomeFabricanteImportador: string
}
export type ProdutoRastreabilidadeFormDto = ProdutoRastreabilidadeAnvisaFormDto & ProdutoRastreabilidadeIdCmpFormDto
const fields = [
    'registroAnvisa',
    'semRegistroAnvisa',
    'cnpj',
    'nomeFabricanteImportador',
    'dadosEtiqueta',
    'identificadorDispositivo',
    'codigoReferencia',
    'lote',
    'numeroSerie',
    'dataFabricacao.ano',
    'dataFabricacao.mes',
    'dataFabricacao.dia',
    'validade',
    'periodoValidade',
    'anoValidade',
    'mesValidade',
    'diaValidade',
    'etiqueta',
]
export const useDadosProdutoForm = (sectionName: string) =>
    useFormValues<ProdutoRastreabilidadeFormDto>('dadosProdutoForm', sectionName, fields)

export const useDadosProdutoFormFromRastreabilidade = (sectionName: string) =>
    useFormValues<ProdutoRastreabilidadeFormDto>('rastreabilidadeForm', (sectionName += '.dadosProdutoForm'), fields)
