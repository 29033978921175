import React from 'react'
import { Document, Font, Image, Page, StyleSheet, Text } from '@react-pdf/renderer'
import OpenSans from './fontes/open.ttf'
import OpenSansBold from './fontes/OpenSans-Bold.ttf'
import Env from '../../../libs/util/Environment'

export interface PDFLayoutProps {
    children: React.ReactNode
    capa?: any
    versao: string
}

export function PDFLayout(props: PDFLayoutProps) {
    const { children, capa } = props
    const versaoSistema = 'Versão: ' + props.versao

    return (
        <Document>
            {capa}
            <Page style={styles.body}>
                <Image style={styles.imageAnvisa} src='/static/logo-anvisa.png' fixed />
                <Image style={styles.imageRni} src='/static/logo-rni.png' fixed />
                <Text style={styles.borderTop} fixed />

                {children}

                <Text style={styles.borderBottom} fixed />
                <Text
                    style={styles.footer}
                    render={() => (!Env.isTest() ? versaoSistema : versaoSistema + '--DEV')}
                    fixed
                />
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                    fixed
                />
            </Page>
        </Document>
    )
}

Font.register({
    family: 'Open Sans',
    src: OpenSans,
})

Font.register({
    family: 'Open Sans-Bold',
    src: OpenSansBold,
})

export const styles = StyleSheet.create({
    body: {
        paddingTop: 30,
        paddingBottom: 65,
        paddingHorizontal: 35,
        textAlign: 'left',
        fontFamily: 'Open Sans',
    },
    pageNumber: {
        fontFamily: 'Open Sans-Bold',
        position: 'absolute',
        fontSize: 7,
        bottom: 30,
        left: 480,
        right: 0,
        textAlign: 'center',
        color: '#999999',
    },
    footer: {
        fontFamily: 'Open Sans',
        paddingTop: '5px',
        position: 'absolute',
        fontSize: 7,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: '#999999',
    },
    imageAnvisa: {
        width: '122.1px',
        height: '30px',
    },
    imageRni: {
        width: '52.8px',
        height: '30px',
        position: 'absolute',
        left: 500,
        top: 30,
    },
    borderTop: {
        marginTop: 10,
        borderBottom: '1pt solid #e6e6e6',
        marginBottom: 10,
    },
    borderBottom: {
        position: 'absolute',
        borderBottom: '1pt solid #e6e6e6',
        bottom: 45,
        left: 35,
        right: 35,
    },
})
