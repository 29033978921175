import { isImmutable } from 'immutable'
import {
    Button,
    ButtonBar,
    ChecklistItemField,
    CheckPanelField,
    ClearRadioFieldButton,
    Column,
    Columns,
    Conteudo,
    ControlField,
    DatePickerField,
    Form,
    InputPanel,
    Label,
    Linha,
    PeriodoField,
    RadioField,
    SectionTitle,
    SimNaoRadioField,
} from 'libs/components'
import { InicioTerminoPanel } from 'libs/components/presentational/form/InicioTerminoPanel'
import form from 'libs/redux-form/form'
import * as DateUtil from 'libs/util/DateUtil'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Scroll from 'react-scroll'
import { FormSection, formValueSelector } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { clearEdicaoProcedimentoData, EdicaoState } from 'redux/modules/edicaoProcedimento'
import { clear as clearNovoProcedimento } from 'redux/modules/novoProcedimento'
import { actionCreators as pacienteActionCreators } from 'redux/modules/paciente'
import { usePesquisaSatisfacaoPopup } from 'view/componentes/pesquisaSatisfacao/usePesquisaSatisfacaoPopup'
import {
    AbordagemCirurgicaJoelhoField,
    AntibioticoField,
    CardPaciente,
    ComponenteComplementar,
    ComponentePrincipal,
    ListComponenteFichaField,
    ModalSave,
    TipoArtroplastiaField,
    TipoEnxertiaField,
} from 'view/home/novoProcedimento/ficha/componentes'
import FichaPrimariaJoelhoValidator from 'view/home/novoProcedimento/ficha/joelho/FichaPrimariaJoelhoValidator'
import { FichaFormCommonProps, FichaFormWrapProps } from '../../procedimentos/ProcedimentosView'
import FichaPromptLeave from '../componentes/FichaPromptLeave'
import { handleErrorResolver } from '../DadoClinicoErrorUtil'

const formName = 'primariaJoelho'

const FichaPrimariaJoelhoView = props => {
    const dispatch = useDispatch()
    useEffect(
        () => () => {
            dispatch(clearNovoProcedimento())
        },
        [dispatch]
    )
    const { showPesquisa } = usePesquisaSatisfacaoPopup()
    const edicaoPrimariaJoelhoData = useSelector<State, EdicaoState>(state => state.edicao)

    const showModalLeave = React.useRef<boolean>(true)

    const handleSubmitFail = error => {
        if (typeof error === 'string' || error?._error?.status === 404) {
            showModalLeave.current = false
            props.goHome()
        }
    }

    if (props.editMode && isEmpty(edicaoPrimariaJoelhoData)) {
        return null
    }

    return (
        <>
            <FichaPromptLeave formName={formName} showModalLeave={showModalLeave} />
            <FichaForm
                onSubmit={props.onSave}
                onSubmitSuccess={() => {
                    props.goHome()
                    showPesquisa()
                }}
                onSubmitFail={handleSubmitFail}
                onCancel={props.goHome}
                errorResolver={handleErrorResolver}
                lado={props.lado}
                paciente={props.paciente}
                procedimento={props.procedimento}
                initialValues={edicaoPrimariaJoelhoData}
                editMode={props.editMode}
            />
        </>
    )
}
export default FichaPrimariaJoelhoView

const MotivoArtroplastiaFormSection = () => {
    return (
        <Conteudo>
            <Columns>
                <Column>
                    <ControlField label={'Motivos da artroplastia'} name='motivoArtroplastia' required />
                </Column>
            </Columns>
            <Columns>
                <Column verticalFlow>
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='ARTRITE_REUMATOIDE_JOELHO'
                        label='Artrite reumatóide'
                    />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='OSTEOARTROSE_PRIMARIA_JOELHO'
                        label='Osteoartrose primária'
                    />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='OSTEOARTROSE_PRIMARIA_UNICOMPARTIMENTAL'
                        label='Osteoartrose primária unicompartimental'
                    />
                    <ChecklistItemField name='motivoArtroplastia' optionValue='OSTEONECROSE' label='Osteonecrose' />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='OUTRAS_DOENCAS_REUMATICAS'
                        label='Outras doenças reumáticas'
                    />
                    <ChecklistItemField
                        name='motivoArtroplastia'
                        optionValue='POS_LESAO_LIGAMENTAR'
                        label='Pós lesão ligamentar'
                    />
                    <ChecklistItemField name='motivoArtroplastia' optionValue='OUTRO_PRIMARIA_JOELHO' label='Outro' />
                </Column>
            </Columns>
        </Conteudo>
    )
}

const ProcedimentoCirurgicoPrevioFormSection = props => {
    return (
        <FormSection name='procedimentoCirurgicoPrevio'>
            <Conteudo>
                <Columns>
                    <Column verticalFlow size='5'>
                        <ControlField label={'Procedimentos'} name='procedimentoPrevio' required />
                        <InputPanel fallback>
                            <ChecklistItemField name='procedimentoPrevio' optionValue='NENHUM' label='Nenhum' />
                        </InputPanel>
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='ARTRODESE'
                            label='Artrodese'
                            disabled={props.disabledNenhum}
                        />
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='FIXACAO_FRATURA_ARTICULAR'
                            label='Fixação de fratura articular'
                            disabled={props.disabledNenhum}
                        />
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='MENISCECTOMIA'
                            label='Meniscectomia'
                            disabled={props.disabledNenhum}
                        />
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='OSTEOTOMIA'
                            label='Osteotomia'
                            disabled={props.disabledNenhum}
                        />
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='RECONSTRUCAO_LIGAMENTAR'
                            label='Reconstrução ligamentar'
                            disabled={props.disabledNenhum}
                        />
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='SINOVECTOMIA'
                            label='Sinovectomia'
                            disabled={props.disabledNenhum}
                        />
                        <ChecklistItemField
                            name='procedimentoPrevio'
                            optionValue='OUTRO'
                            label='Outro'
                            disabled={props.disabledNenhum}
                        />
                    </Column>
                </Columns>
                <Linha size='medium' />
                <Columns>
                    <Column verticalFlow>
                        <SimNaoRadioField label='Implante prévio' name='implantePrevio' required />
                        <InputPanel fallback size='medium'>
                            <RadioField
                                name='estadoImplantePrevio'
                                disabled={props.disabledImplantePrevio}
                                label='Ainda presente'
                                value='AINDA_PRESENTE'
                            />
                            <RadioField
                                name='estadoImplantePrevio'
                                disabled={props.disabledImplantePrevio}
                                label='Removido previamente'
                                value='REMOVIDO_PREVIAMENTE'
                            />
                        </InputPanel>
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const ProcedimentoCirurgicoAtualFormSection = props => {
    return (
        <FormSection name='procedimentoCirurgicoAtualForm'>
            <Conteudo>
                <Columns>
                    <Column size='4'>
                        <DatePickerField
                            label='Data da cirurgia'
                            placeholder='dd/mm/aaaa'
                            name='dataCirurgia'
                            required
                        />
                    </Column>
                </Columns>

                <InicioTerminoPanel duracao={props.duracao} />

                <Columns>
                    <Column verticalFlow>
                        <AbordagemCirurgicaJoelhoField />
                        <Linha size='medium' />
                        <SimNaoRadioField label='Uso de dreno' name='usoDreno' required />
                        <SimNaoRadioField label='Transfusão sanguínea' name='transfusaoSanguinea' required />
                        <InputPanel fallback size='medium'>
                            <RadioField
                                name='tipoTransfusaoSanguinea'
                                label='Autóloga'
                                value='AUTOLOGA'
                                disabled={props.disabledTipoTransfusaoSanguinea}
                            />
                            <RadioField
                                name='tipoTransfusaoSanguinea'
                                label='Homóloga'
                                value='HOMOLOGA'
                                disabled={props.disabledTipoTransfusaoSanguinea}
                            />
                        </InputPanel>
                        <Linha size='medium' />
                        <SimNaoRadioField label='Foi utilizado antibiótico' name='possuiAntibiotico' required />
                        <InputPanel fallback size='medium'>
                            <Columns>
                                <Column size='6'>
                                    <AntibioticoField
                                        label='Antibiótico'
                                        name='antibiotico'
                                        isDisabled={props.disabledAntibiotico}
                                        required={!props.disabledAntibiotico}
                                    />
                                </Column>
                                <Column size='4'>
                                    <PeriodoField
                                        label='Período'
                                        name='periodoAntibiotico'
                                        disabled={props.disabledAntibiotico}
                                        required={!props.disabledAntibiotico}
                                    />
                                </Column>
                            </Columns>
                        </InputPanel>
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const TipoArtroplastiaFormSection = props => {
    return (
        <FormSection name='tipoArtroplastiaForm'>
            <Conteudo>
                <Columns>
                    <Column verticalFlow size='8'>
                        <TipoArtroplastiaField
                            label='Tipo'
                            tipo='joelho'
                            name='tipoArtroplastia'
                            required
                            disabled={props.disabledTipoArtroplastia}
                        />
                        <Label>Fixação</Label>
                        <InputPanel verticalFlow size='medium'>
                            <ControlField label={'Fêmur'} name='femurLabel'>
                                <RadioField name='femur' label='Cimentada' value='CIMENTADA' />
                                <RadioField name='femur' label='Não cimentada' value='NAO_CIMENTADA' />
                                <ClearRadioFieldButton name='femur' />
                            </ControlField>
                            <ControlField label={'Tíbia'} name='tibiaLabel'>
                                <RadioField name='tibia' label='Cimentada' value='CIMENTADA' />
                                <RadioField name='tibia' label='Não cimentada' value='NAO_CIMENTADA' />
                                <ClearRadioFieldButton name='tibia' />
                            </ControlField>
                            <SimNaoRadioField label='Patela' name='patela' disabled={props.disabledPatela} />
                        </InputPanel>
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const CimentoOsseoFormSection = props => {
    return (
        <FormSection name='cimentoOsseo'>
            <Conteudo verticalFlow>
                <SimNaoRadioField label='Foi utilizado' name='possuiCimentoOsseo' required />
                <InputPanel horizontalFlow fallback size='medium'>
                    <RadioField
                        name='tipoCimentoOsseo'
                        label='Convencional'
                        value='CONVENCIONAL'
                        disabled={props.disabledTipoCimentoOsseo}
                    />
                    <RadioField
                        name='tipoCimentoOsseo'
                        label='Com antibiótico'
                        value='COM_ANTIBIOTICO'
                        disabled={props.disabledTipoCimentoOsseo}
                    />
                </InputPanel>
                <SimNaoRadioField
                    label='Lavagem pulsátil'
                    name='lavagemPulsatil'
                    disabled={props.disabledTipoCimentoOsseo}
                    required={!props.disabledTipoCimentoOsseo}
                />
            </Conteudo>
        </FormSection>
    )
}

const EnxertiaFormSection = props => {
    return (
        <FormSection name='enxertia'>
            <Conteudo>
                <SimNaoRadioField label='Foi realizada' name='foiRealizada' required />
                <CheckPanelField label='Femoral' name='femoral' disabled={props.disabledEnxertia}>
                    <Conteudo>
                        <TipoEnxertiaField label='Qual?' name='tipoEnxertiaFemoral' disabled={props.disabledEnxertia} />
                    </Conteudo>
                </CheckPanelField>
                <CheckPanelField label='Tibial' name='tibial' disabled={props.disabledEnxertia}>
                    <Conteudo>
                        <TipoEnxertiaField label='Qual?' name='tipoEnxertiaTibial' disabled={props.disabledEnxertia} />
                    </Conteudo>
                </CheckPanelField>
            </Conteudo>
        </FormSection>
    )
}

const ComponentesUtilizadosFormSection = props => {
    let tipoArtroplastiaId, tipoArtroplastiaNome
    if (isImmutable(props.tipoArtroplastia)) {
        tipoArtroplastiaId = props.tipoArtroplastia.get('id')
        tipoArtroplastiaNome = props.tipoArtroplastia.get('nome')
    } else {
        tipoArtroplastiaId = props.tipoArtroplastia?.id
        tipoArtroplastiaNome = props.tipoArtroplastia?.nome
    }

    const labelTipoArtroplastia = props.tipoArtroplastia && (
        <p className='label-tipo-artroplastia'>{'Tipo de artroplastia: ' + tipoArtroplastiaNome}</p>
    )
    return (
        <FormSection name='componentesUtilizadosForm'>
            <Conteudo>
                <ListComponenteFichaField
                    name='componentesPrincipais'
                    combo={
                        <ComponentePrincipal
                            label='Principais'
                            name='componentesPrincipaisLabel'
                            tipoProcedimento='PRIMARIA_JOELHO'
                            required
                            disabled={!props.tipoArtroplastia}
                            tipoArtroplastia={tipoArtroplastiaId}
                        />
                    }
                >
                    {labelTipoArtroplastia}
                </ListComponenteFichaField>
                <hr />
                <ListComponenteFichaField
                    name='componentesComplementares'
                    combo={
                        <ComponenteComplementar
                            name='componentesComplementaresLabel'
                            tipoProcedimento='PRIMARIA_JOELHO'
                            label='Complementares'
                        />
                    }
                />
            </Conteudo>
        </FormSection>
    )
}

interface FichaFormCmpProps extends FichaFormCommonProps {
    duracao: string
    disabledNenhum: boolean
    disabledImplantePrevio: boolean
    disabledTransfusaoSanguinea: boolean
    disabledAntibiotico: boolean
    disabledPatela: boolean
    disableTipoArtroplastia: boolean
    disabledPossuiCimentoOsseo: boolean
    disabledEnxertia: boolean
    tipoArtroplastia: any
}

const FichaFormCmp = (props: FichaFormCmpProps) => {
    const dispatch = useDispatch()

    // mimics componentWillUnmount
    useEffect(
        () => () => {
            dispatch(clearEdicaoProcedimentoData())
            dispatch(pacienteActionCreators.clear(''))
        },
        [dispatch]
    )

    return (
        <div>
            <Form>
                <CardPaciente paciente={props.paciente} lado={props.lado} procedimento={props.procedimento} />
                <Scroll.Element name='diagnostico'>
                    <SectionTitle title='DIAGNÓSTICOS' />
                    <MotivoArtroplastiaFormSection />
                </Scroll.Element>
                <Scroll.Element name='procedimentoCirurgicoPrevio'>
                    <SectionTitle title='PROCEDIMENTO CIRÚRGICO PRÉVIO' />
                    <ProcedimentoCirurgicoPrevioFormSection
                        disabledNenhum={props.disabledNenhum}
                        disabledImplantePrevio={props.disabledImplantePrevio}
                    />
                </Scroll.Element>
                <Scroll.Element name='procedimentoCirurgicoAtual'>
                    <SectionTitle title='PROCEDIMENTO CIRÚRGICO ATUAL' />
                    <ProcedimentoCirurgicoAtualFormSection
                        disabledTipoTransfusaoSanguinea={props.disabledTransfusaoSanguinea}
                        disabledAntibiotico={props.disabledAntibiotico}
                        duracao={props.duracao}
                    />
                </Scroll.Element>
                <Scroll.Element name='tipoArtroplastiaTitle'>
                    <SectionTitle title='TIPO DE ARTROPLASTIA' />
                    <TipoArtroplastiaFormSection
                        disabledPatela={props.disabledPatela}
                        disabledTipoArtroplastia={props.disableTipoArtroplastia}
                    />
                </Scroll.Element>
                <Scroll.Element name='cimentoOsseo'>
                    <SectionTitle title='CIMENTO ÓSSEO' />
                    <CimentoOsseoFormSection disabledTipoCimentoOsseo={props.disabledPossuiCimentoOsseo} />
                </Scroll.Element>
                <Scroll.Element name='enxertia'>
                    <SectionTitle title='ENXERTIA' />
                    <EnxertiaFormSection disabledEnxertia={props.disabledEnxertia} />
                </Scroll.Element>
                <Scroll.Element name='componentesUtilizados'>
                    <SectionTitle title='COMPONENTES UTILIZADOS' />
                    <ComponentesUtilizadosFormSection tipoArtroplastia={props.tipoArtroplastia} />
                </Scroll.Element>
            </Form>
            <Conteudo>
                <Linha size='medium' />
                <ButtonBar>
                    <Button pulledLeft size='medium' className='simple-button' onClick={props.onCancel}>
                        Cancelar
                    </Button>
                    <Button
                        pulledRight
                        type='success'
                        size='medium'
                        loading={props.submitting}
                        onClick={props.handleSubmit}
                        disabled={props.pristine}
                    >
                        Salvar registro
                    </Button>
                </ButtonBar>
            </Conteudo>
        </div>
    )
}

const selector = formValueSelector(formName)

const FichaForm = ({ editMode, ...rest }: FichaFormWrapProps) => {
    const Form = useMemo(
        () =>
            form<{}, FichaFormCmpProps>({
                form: formName,
                validate: FichaPrimariaJoelhoValidator as any,
                successTitle: editMode ? 'Edição realizada!' : 'Ficha registrada!',
                successModal: ModalSave,
                successContent: (
                    <span>
                        O registro foi salvo com sucesso. Se preferir, você pode imprimi-lo ou voltar à página inicial
                        de seu perfil.
                    </span>
                ),
                hasLeaveModal: false,
            })(
                connect(state => {
                    const procedPrevio = selector(state, 'procedimentoCirurgicoPrevio.procedimentoPrevio')

                    const disabledEnxertia = selector(state, 'enxertia.foiRealizada') !== 'SIM'

                    const disabledNenhum = procedPrevio && procedPrevio.includes('NENHUM')

                    const disabledImplantePrevio =
                        selector(state, 'procedimentoCirurgicoPrevio.implantePrevio') !== 'SIM'

                    const disabledTransfusaoSanguinea =
                        selector(state, 'procedimentoCirurgicoAtualForm.transfusaoSanguinea') !== 'SIM'

                    const disabledAntibiotico =
                        selector(state, 'procedimentoCirurgicoAtualForm.possuiAntibiotico') !== 'SIM'

                    const disabledPossuiCimentoOsseo = selector(state, 'cimentoOsseo.possuiCimentoOsseo') !== 'SIM'

                    const tipoArtroplastia = selector(state, 'tipoArtroplastiaForm.tipoArtroplastia')

                    let disabledPatela = true

                    if (tipoArtroplastia) {
                        const fixacao = selector(state, 'tipoArtroplastiaForm.tipoArtroplastia')
                        let fixacaoId
                        if (isImmutable(fixacao)) {
                            fixacaoId = fixacao.get('id')
                        } else {
                            fixacaoId = fixacao.id
                        }
                        if (
                            fixacaoId === 'ARTROPLASTIA_TOTAL_COM_SUBSTITUICAO_PATELAR' ||
                            fixacaoId === 'PATELO_FEMORAL'
                        ) {
                            disabledPatela = false
                        }
                    }

                    const componentesPrincipais = selector(state, 'componentesUtilizadosForm.componentesPrincipais')
                    const disableTipoArtroplastia = componentesPrincipais && componentesPrincipais.size > 0

                    const inicio = selector(state, 'procedimentoCirurgicoAtualForm.horaInicio')
                    const termino = selector(state, 'procedimentoCirurgicoAtualForm.horaTermino')
                    const hasHoras = inicio && termino
                    const duracao = hasHoras && DateUtil.calculateDuracaoHoras(inicio, termino)

                    return {
                        disabledEnxertia,
                        disabledNenhum,
                        disabledImplantePrevio,
                        disabledTransfusaoSanguinea,
                        disabledPossuiCimentoOsseo,
                        disabledPatela,
                        disabledAntibiotico,
                        tipoArtroplastia,
                        disableTipoArtroplastia,
                        duracao,
                    }
                })(FichaFormCmp)
            ),
        [editMode]
    )
    return <Form {...rest} />
}
