import React from 'react'
import * as C from 'libs/components'

export class IntervencaoValvarPreviaCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Intervenção valvar prévia'
                name='intervencaoValvar'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='intervencaoValvar' />}
            >
                <C.Conteudo>
                    <C.ControlField label='Tipo' name='intervencaoValvarTipo' verticalFlow>
                        <C.ChecklistItemField name='intervencaoValvarTipo' optionValue='CIRURGICA' label='Cirúrgica' />
                        <C.ChecklistItemField name='intervencaoValvarTipo' optionValue='TAVI' label='TAVI' />
                        <C.ChecklistItemField
                            name='intervencaoValvarTipo'
                            optionValue='VALVOPLASTIA'
                            label='Valvoplastia percutânea'
                        />
                        <C.ChecklistItemField name='intervencaoValvarTipo' optionValue='OUTRA' label='Outra' />
                    </C.ControlField>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
