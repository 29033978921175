/* tslint:disable */
import React from 'react'

const SvgDesativar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 21 21' {...props}>
        <path d='M6.313 4.603a.5.5 0 01.608.794A6.203 6.203 0 004.5 10.341c0 3.404 2.689 6.159 6 6.159s6-2.755 6-6.16c0-1.95-.889-3.747-2.372-4.905a.5.5 0 11.615-.788A7.204 7.204 0 0117.5 10.34c0 3.951-3.131 7.159-7 7.159s-7-3.208-7-7.16a7.203 7.203 0 012.813-5.737zM10 3.5a.5.5 0 111 0v8a.5.5 0 11-1 0v-8z' />
    </svg>
)

export default SvgDesativar
