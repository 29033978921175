import { connect } from 'react-redux'
import { State } from 'redux/modules'
import { Props } from './TipoRevisaoProdutoDetailView'
import { withRouter } from 'react-router-dom'
import * as produtos from 'redux/modules/report/produto'
import * as filtroReport from 'redux/modules/report/filtro'
import { DistribuicaoProcedimentoFiltro } from 'backend'
import { TipoRevisaoProdutoDetailView } from './TipoRevisaoProdutoDetailView'

const mapStateToProps = (state: State, ownProps: Props) => ({
    detalhe: state.report.produto.detalheProdutoTipoRevisao,
    filtro: state.report.filtro.filtro,
    goBack: () => {
        ownProps.history.push({
            pathname: `/home/relatorios/tipoRevisao/${ownProps.match.params.tipoRevisao}`,
            search: `${ownProps.history.location.search}`,
        })
    },
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    loadComponente: value => {
        const filtro: DistribuicaoProcedimentoFiltro = value
        const { registroAnvisa, identificadorDispositivo, referencia } = ownProps.match.params
        filtro.registroAnvisa = null
        filtro.identificadorDispositivo = null
        filtro.referencia = null
        if (registroAnvisa) {
            filtro.registroAnvisa = registroAnvisa
        } else {
            if (identificadorDispositivo) {
                filtro.identificadorDispositivo = identificadorDispositivo
            }
            if (referencia) {
                filtro.referencia = referencia
            }
        }
        dispatch(produtos.findDetalheProdutoTipoRevisao(filtro))
    },
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(
            filtroReport.change({
                ...filtro,
            })
        )
    },
    clearFindResult: () => {
        dispatch(produtos.clearFindResult())
    },
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TipoRevisaoProdutoDetailView))
