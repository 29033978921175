import * as React from 'react'
import * as C from 'libs/components'
import { RouteComponentProps, matchPath, match } from 'react-router-dom'
import { Icons } from './elements/icons'
import { Link } from 'react-router-dom'

interface PathDefinition {
    path: string
    title?: string
    icon?: Icons
    exact?: boolean
    strict?: boolean
}

const paths: PathDefinition[] = [
    { path: '/home', icon: 'home' },
    { path: '/home/alarmes', title: 'Central de alarmes' },
    { path: '/home/alarmes/configurar', title: 'Configuração de alarmes' },
    { path: '/home/alarmes/visualizacaoProcedimento', title: 'Visualização do registro' },
    { path: '/home/alterarSenha', title: 'Alterar senha' },
    { path: '/home/atualizarDadosPessoais', title: 'Atualizar dados pessoais' },
    { path: '/home/relacaoEmpresas', title: 'Relação com empresas' },
    { path: '/home/buscaProcedimentos', title: 'Buscar procedimentos' },
    { path: '/home/buscaProcedimentos/visualizacao', title: 'Visualização do registro' },

    { path: '/home/edicaoProcedimento/preFicha/:idFicha/:everything(.*)', title: 'Edição de procedimento' },
    { path: '/home/edicaoProcedimento/primariaJoelho', title: 'Edição de primária de joelho' },
    { path: '/home/edicaoProcedimento/revisaoJoelho', title: 'Edição de revisão de joelho' },
    { path: '/home/edicaoProcedimento/primariaQuadril', title: 'Edição de primária de quadril' },
    { path: '/home/edicaoProcedimento/revisaoQuadril', title: 'Edição de revisão de quadril' },
    {
        path: '/home/edicaoProcedimento/stentCoronaria/:idFicha/essencial',
        title: 'Edição de stent de coronária - Dados essenciais',
    },
    {
        path: '/home/edicaoProcedimento/stentCoronaria/:idFicha/complementar',
        title: 'Edição de stent de coronária - Dados complementares',
    },
    { path: '/home/edicaoProcedimento/dadosProduto', title: 'Edição de dados de produto' },
    { path: '/home/edicaoProcedimento/dadosFinanceiro', title: 'Edição de dados financeiros' },
    { path: '/home/edicaoProcedimento/dadosProdutoFinanceiro', title: 'Edição de dados de produto e financeiros' },

    { path: '/home/novoProcedimento', title: 'Novo procedimento' },
    { path: '/home/novoProcedimento/:idFicha([0-9]+)?/definirEquipeMedica', title: 'Definir equipe médica' },
    { path: '/home/novoProcedimento/:idFicha([0-9]+)?/definirPaciente', title: 'Definir paciente' },
    { path: '/home/novoProcedimento/:idFicha([0-9]+)?/definirPaciente/cadastrarPaciente', title: 'Cadastrar paciente' },
    { path: '/home/novoProcedimento/:idFicha([0-9]+)?/procedimentos', title: 'Selecionar procedimento' },
    { path: '/home/novoProcedimento/:idFicha([0-9]+)?/primariaJoelho', title: 'Primária de joelho' },
    { path: '/home/novoProcedimento/:idFicha([0-9]+)?/primariaQuadril', title: 'Primária de quadril' },
    { path: '/home/novoProcedimento/:idFicha([0-9]+)?/revisaoJoelho', title: 'Revisão de joelho' },
    { path: '/home/novoProcedimento/:idFicha([0-9]+)?/revisaoQuadril', title: 'Revisão de quadril' },
    {
        path: '/home/novoProcedimento/:idFicha([0-9]+)?/stentCoronaria/essencial',
        title: 'Stent de coronária - Dados essenciais',
    },
    {
        path: '/home/novoProcedimento/:idFicha([0-9]+)?/stentCoronaria/complementar',
        title: 'Stent de coronária - Dados complementares',
    },

    { path: '/home/dadosProduto', title: 'Dados de produtos' },
    { path: '/home/dadosFinanceiro', title: 'Dados financeiros' },
    { path: '/home/dadosProdutoFinanceiro', title: 'Dados de produtos e financeiros' },
    { path: '/home/visualizacao', title: 'Visualização do registro' },

    { path: '/home/relatorios/artroplastia', title: 'Distribuição de artroplastias' },
    { path: '/home/relatorios/angioplastia', title: 'Distribuição de angioplastias' },

    { path: '/home/relatorios/:procedimento/hospitais/:idHospital/procedimentos', title: 'Procedimentos hospital' },

    { path: '/home/relatorios/financeiro', title: 'Dados financeiros' },
    { path: '/home/relatorios/motivos', title: 'Motivos de procedimentos' },
    { path: '/home/relatorios/motivos/:motivo', title: 'Visualização do motivo' },
    { path: '/home/relatorios/rastreabilidade', title: 'Rastreabilidade de produtos' },
    {
        path: '/home/relatorios/rastreabilidade/visualizacao/:tipoProcedimento/:idProcedimento',
        title: 'Visualização do registro',
    },
    { path: '/home/relatorios/listaPacientes', title: 'Lista de Pacientes' },
    {
        path: '/home/relatorios/listaPacientes/visualizacao/:tipoProcedimento/:idProcedimento',
        title: 'Visualização do registro',
    },
    { path: '/home/relatorios/rastreabilidade/registro/:registroAnvisa', title: 'Registro ANVISA' },
    { path: '/home/relatorios/rastreabilidade/registro/:registroAnvisa/paciente/:pacienteId', title: 'Paciente' },
    {
        path: '/home/relatorios/rastreabilidade/registro/:registroAnvisa/paciente/:pacienteId/visualizacao',
        title: 'Visualização do registro',
    },
    { path: '/home/relatorios/multiplos/:tipoCirurgia', title: 'Múltiplos procedimentos' },
    {
        path: '/home/relatorios/multiplos/:tipoCirurgia/dashboard/:procedimentoMode/:tempoRevisao/pacientes',
        title: 'Pacientes',
    },
    {
        path:
            '/home/relatorios/multiplos/:tipoCirurgia/dashboard/:procedimentoMode/:tempoRevisao/pacientes/list/:pacienteId',
        title: 'Resumo do paciente',
    },
    {
        path:
            '/home/relatorios/multiplos/:tipoCirurgia/dashboard/:procedimentoMode/:tempoRevisao/pacientes/list/:pacienteId/visualizacao',
        title: 'Visualização do registro',
    },
    { path: '/home/relatorios/tipoRevisao', title: 'Tipos de revisão' },
    { path: '/home/relatorios/tipoRevisao/:tipoRevisao', title: 'Visualização do tipo' },
    { path: '/home/relatorios/tipoRevisao/:tipoRevisao/registro/:registro', title: 'Detalhe produto' },
    {
        path: '/home/relatorios/tipoRevisao/:tipoRevisao/identificador/:identificadorDispositivo/codigo/:referencia',
        title: 'Detalhe produto',
    },
    { path: '/home/relatorios/info-produto', title: 'Informações de produtos' },
    { path: '/home/relatorios/info-produto/:registroAnvisa', title: 'Detalhe do produto' },
    { path: '/home/relatorios/produtos', title: 'Distribuição de produtos' },
    { path: '/home/relatorios/produtos/componente/:idComponente/:tipoProcedimento', title: 'Componente' },
    {
        path: '/home/relatorios/produtos/componente/:idComponente/:tipoProcedimento/registro/:registroAnvisa',
        title: 'Registro',
    },
    { path: '/home/relatorios/usoSistema', title: 'Uso do sistema' },
    { path: '/home/relatorios/relacaoMedicoEmpresa', title: 'Relação médico-empresa' },
    { path: '/home/stents', title: 'Stents' },
    { path: '/home/antibioticos', title: 'Antibióticos' },
    { path: '/home/entidades', title: 'Entidades para o consumo de dados' },
    { path: '/home/entidades/adicionarEntidade', title: 'Adicionar entidade' },
    { path: '/home/entidades/editarEntidade', title: 'Editar entidade' },
    { path: '/home/entidades/visualizarEntidade', title: 'Visualizar entidade' },
]

export class Breadcrumbs extends React.Component<RouteComponentProps<any>> {
    render() {
        const matches = paths
            .map(path => {
                return {
                    path: path,
                    match: matchPath(this.props.location.pathname, {
                        path: path.path,
                        exact: path.exact,
                        strict: path.strict,
                    }),
                }
            })
            .filter(match => match.match)

        if (matches.length === 1 && matches[0].path.path === '/home') {
            // Não mostra somente 'home'
            return null
        }

        return (
            <div className='breadcrumbs'>
                <ul className='container'>
                    {matches.map((item, i, arr) => {
                        return this.renderItem(item, i === arr.length - 1)
                    })}
                </ul>
            </div>
        )
    }

    renderItem(item: { path: PathDefinition; match: match<any> }, active: boolean) {
        const breadcrumbItem = [item.path.icon && <C.Icon key='icon' icon={item.path.icon} />, item.path.title]
        return (
            <li key={item.path.path} className={active ? 'breadcrumb-active' : undefined}>
                {active ? <p>{breadcrumbItem}</p> : <Link to={item.match.url}>{breadcrumbItem}</Link>}
            </li>
        )
    }
}
