import React from 'react'
import { VinculoProfissionalRow } from 'backend'
import { Icon } from 'libs/components'

interface InformacoesEquipeMedicaProps {
    medicoResponsavel: VinculoProfissionalRow
    open: () => void
}

export const InformacoesEquipeMedica: React.FC<InformacoesEquipeMedicaProps> = props => {
    const { medicoResponsavel, open } = props

    return (
        <div className='card-procedimento'>
            <div className='card-procedimento-info'>
                <label className='title-inf-proced is-semibold'>Profissional responsável:</label>
                <label className='text-inf-proced'>{medicoResponsavel?.nome}</label>
            </div>
            <span className='inf-plus' onClick={open}>
                {' '}
                <Icon className='icon-inf-proced' icon='vermais' />
            </span>
        </div>
    )
}
