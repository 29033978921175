import { createValidator, required, range } from 'libs/validation'

const StentsUtilizadosModalValidator = createValidator(
    {
        nomeStent: required,
        diametro: [required, range(2, 6)],
        comprimento: [required, range(8, 48)],
        localStent: required,
        stentSucesso: required,
    },
    formValidator
)

function formValidator(form) {
    const errors = {}
    const lesoes = form.get('lesoesRegistradas')
    const sucesso = form.get('stentSucesso')
    const localStent = form.get('localStent')
    const mInsucesso = form.get('motivoInsucesso')
    const tFalha = form.get('tipoFalha')

    if (localStent === 'NATIVA') {
        let lesoesCount = 0

        lesoes &&
            lesoes.forEach(function(element, index, map) {
                const asLesoes = map.get(index)
                lesoesCount = lesoesCount + asLesoes.length
            }, this)

        if (lesoesCount === 0) {
            errors.lesoesRegistradas = required(undefined)
        }
    } else if (localStent === 'ENXERTO') {
        errors.enxertoStent = required(form.get('enxertoStent'))
    }

    if (sucesso && sucesso === 'NAO') {
        errors.motivoInsucesso = required(form.get('motivoInsucesso'))
        if (mInsucesso && mInsucesso.id === 'FALHA_PRODUTO') {
            errors.tipoFalha = required(form.get('tipoFalha'))
            if (tFalha) {
                errors.especificacaoFalha = required(form.get('especificacaoFalha'))
            }
        }
    }
    return errors
}

export default StentsUtilizadosModalValidator
