import React from 'react'
import { Label, Column } from 'libs/components'
import moment from 'moment'
import { CLIENT_FORMAT } from 'libs/util/DateUtil'
import {
    AutenticacaoIntegracaoInnerRowType,
    AutenticacaoIntegracaoInnerRowConfig,
} from '../AutenticacaoIntegracaoInnerRow'

interface LabelsColumnProps {
    title: AutenticacaoIntegracaoInnerRowType
    value: string
    date: string
    rowConfig: AutenticacaoIntegracaoInnerRowConfig
    isFailure: boolean
    isBlocked: boolean
    isRequest: boolean
    isSuccess: boolean
    isFirstTime: boolean
}

export const LabelsColumn = (props: LabelsColumnProps) => {
    const { title, value, date, rowConfig, isFailure, isBlocked, isRequest, isSuccess, isFirstTime } = props
    return (
        <Column className='labels-column' size='5'>
            <Label
                className={isFailure || isBlocked ? 'label-title failure' : 'label-title'}
                testid='label-title-login-senha'
            >
                {title}
            </Label>
            {isFailure && (
                <>
                    <Label className='label-value-failure'>
                        {`Ocorreu um erro ao gerar ${rowConfig.failureWords}. `}
                    </Label>
                    <Label className='label-value-failure is-bold'>Tente novamente.</Label>
                </>
            )}
            {isRequest && <Label className='integracao-value-request'>Carregando...</Label>}
            {isSuccess && isFirstTime ? (
                <>
                    <Label className='label-value-empty' testid='label-nenhum-login-senha'>
                        {rowConfig.emptyText}
                    </Label>
                    <Label className='label-first-time' testid='label-gerar-login-senha'>
                        {`Para acessar ${rowConfig.seuTitleText}, clique em `}
                        <span className='is-bold'>{rowConfig.gerarText}</span>
                    </Label>
                </>
            ) : (
                isSuccess && (
                    <>
                        <Label className='label-value' testid='label-login-senha-gerada'>
                            {value}
                        </Label>
                        <Label className='label-date' testid='label-login-senha-data'>
                            {title} {rowConfig.gerarWord} em {moment(date).format(CLIENT_FORMAT)}
                        </Label>
                    </>
                )
            )}
        </Column>
    )
}
