import React, { useEffect, useState } from 'react'
import { ScrollToTop } from 'libs/components'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import GerenciarChavesView from './GerenciarChavesView'
import GerenciarEntidadesView from './GerenciarEntidadesView'

type EntidadesTabItems = 'GERENCIAR_ENTIDADES' | 'GERENCIAR_CHAVES'

const EntidadesView: React.FC = () => {
    const location = useLocation()
    const history = useHistory()

    const [tabItemActive, setTabItemActive] = useState<EntidadesTabItems>(
        location.pathname.includes('/chaves') ? 'GERENCIAR_CHAVES' : 'GERENCIAR_ENTIDADES'
    )

    useEffect(() => {
        setTabItemActive(location.pathname.includes('/chaves') ? 'GERENCIAR_CHAVES' : 'GERENCIAR_ENTIDADES')
    }, [location.pathname])

    function handleTabItemClick(tabItem: EntidadesTabItems) {
        setTabItemActive(tabItem)
        history.push({
            pathname: `/home/entidades${tabItem === 'GERENCIAR_CHAVES' ? '/chaves' : ''}`,
        })
    }

    return (
        <>
            <ScrollToTop onMount />

            <ReportHeader title='Entidades para consumo de dados'>
                <HorizontalTabMenu>
                    <HorizontalTabMenuItem
                        title='Gerenciar entidades'
                        onClick={() => handleTabItemClick('GERENCIAR_ENTIDADES')}
                        active={tabItemActive === 'GERENCIAR_ENTIDADES'}
                    />
                    <HorizontalTabMenuItem
                        title='Gerenciar chaves'
                        onClick={() => handleTabItemClick('GERENCIAR_CHAVES')}
                        active={tabItemActive === 'GERENCIAR_CHAVES'}
                    />
                </HorizontalTabMenu>
            </ReportHeader>
            <Switch>
                <Route path='/home/entidades/chaves' exact component={GerenciarChavesView} />
                <Route path='/home/entidades/:filtro' component={GerenciarEntidadesView} />
                <Redirect to='/home/entidades/ativas' />
            </Switch>
        </>
    )
}

export default EntidadesView
