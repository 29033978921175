import { connect } from 'react-redux'
import { DadosFinanceirosView, DadosFinanceirosProps } from './DadosFinanceirosView'
import { State } from 'redux/modules'
import { DistribuicaoProcedimentoFiltro } from 'backend'
import * as filtroReport from 'redux/modules/report/filtro'
import * as financeiro from 'redux/modules/report/financeiro'
import * as rastreabilidade from 'redux/modules/report/rastreabilidade'
import { change } from 'redux-form'

const mapStateToProps = (state: State, ownProps: DadosFinanceirosProps) => ({
    dados: state.report.financeiro.dados,
    filtro: state.report.filtro.filtro,
    produtoAnvisa: state.report.rastreabilidade.produto,
    goBack: () => ownProps.history.goBack(),
})

const mapDispatchToProps = (dispatch, ownProps: DadosFinanceirosProps) => ({
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(filtroReport.change({ ...filtro }))
    },
    doLoad: () => {
        dispatch(financeiro.doLoad())
    },
    loadProdutoAnvisa: (registroAnvisa: string) => {
        dispatch(rastreabilidade.findProduto(registroAnvisa)).then(value =>
            dispatch(change('dadosFinanceirosFilter', 'registroAnvisa', value.data))
        )
    },
    clearProdutoAnvisa: () => dispatch(rastreabilidade.clearFindProduto()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DadosFinanceirosView)
