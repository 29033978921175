import * as React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import isEqual from 'lodash/isEqual'
import Report from 'api/Report'
import { Card, LoadingContainer, LinkIconButton, Progress, Text, Paginator, Hint, Column, Icon } from 'libs/components'
import { MultiplosPacienteDto, ProcedimentoMode, TipoCirurgia } from 'backend'
import { MultiplosProcedimentosFiltro, TempoAteRevisao } from 'model'
import { State } from 'redux/modules'
import { Table, TableColumnHeader } from 'libs/components'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { SERVER_FORMAT, CLIENT_FORMAT } from 'libs/util/DateUtil'
import { Oval } from '../components/Oval'
import { PageRequester, Page } from 'redux/requester'
import { stringify } from 'redux/modules/router'

interface Params {
    procedimentoMode: ProcedimentoMode
    tipoCirurgia: TipoCirurgia
    tempoRevisao: TempoAteRevisao
}

export interface Props extends RouteComponentProps<Params> {
    pacientes: Page<MultiplosPacienteDto>
    isFetching: boolean
    filtro: MultiplosProcedimentosFiltro
    handlePageChange(page: number): void
    load(filtro: MultiplosProcedimentosFiltro): void
}

export class MultiplosPacientesList extends React.Component<Props> {
    componentDidMount() {
        this.props.load(this.props.filtro)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (!isEqual(this.props.filtro, nextProps.filtro)) {
            this.props.load(nextProps.filtro)
        }
    }

    renderDownloadIcon() {
        let filtro = this.props.filtro
        filtro.procedimentoMode = this.props.match.params.procedimentoMode
        filtro.tipoCirurgia = this.props.match.params.tipoCirurgia
        filtro.tempoRevisao = this.props.match.params.tempoRevisao
        return (
            <Link
                to={{
                    pathname: '/api/report/multiplosProcedimentos/pacientes/csv',
                    search: stringify(filtro),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }

    render() {
        const { pacientes, isFetching, handlePageChange } = this.props

        return (
            <Card title={this.renderCardTitle()} noPadding={!isFetching} headerAction={this.renderDownloadIcon()}>
                <LoadingContainer isFetching={isFetching}>
                    {pacientes && (
                        <>
                            {pacientes.content.length > 0 && <PacientesTable {...this.props} />}
                            {pacientes.content.length < 1 && (
                                <Column textCentered borderBottom>
                                    <p style={{ lineHeight: 2 }} className='is-text-muted'>
                                        Nenhum paciente encontrado.
                                    </p>
                                </Column>
                            )}
                            <Paginator
                                first={pacientes.first}
                                last={pacientes.last}
                                pageNumber={pacientes.number}
                                totalPages={pacientes.totalPages}
                                onChange={handlePageChange}
                            />
                        </>
                    )}
                </LoadingContainer>
            </Card>
        )
    }

    renderCardTitle() {
        if (this.props.pacientes) {
            return `${this.props.pacientes.totalElements} pacientes`
        }

        return null
    }
}

export const PacientesTable = (props: Props) => {
    const { pacientes, match } = props
    const maxMeses =
        (match.params.tempoRevisao === 'MENOS_5_ANOS' && 5 * 12) ||
        (match.params.tempoRevisao === 'ENTRE_5_E_10_ANOS' && 10 * 12) ||
        20 * 12
    const className1 = match.params.procedimentoMode === 'PRIMARIA_REVISAO' ? 'is-report-color-1' : 'is-report-color-2'
    const className2 = 'is-report-color-2'

    return (
        <Table values={pacientes.content} className='is-spaced is-small-bis'>
            <TableColumnHeader
                title='Paciente'
                style={{ width: '20%' }}
                render={(item: MultiplosPacienteDto) => {
                    return <Text>{item.nome}</Text>
                }}
            />
            <TableColumnHeader
                title=''
                render={(item: MultiplosPacienteDto) => {
                    const d1 = moment(item.dataProcedimento1, SERVER_FORMAT)
                    const d2 = moment(item.dataProcedimento2, SERVER_FORMAT)
                    const quocient = Math.min(190, 190 * (d2.diff(d1, 'months', true) / maxMeses)) // 190px = 100%

                    return (
                        <div className='multiplos-pacientes--list-progress'>
                            <Text weight='semibold' size='x-small' color='grey-darker'>
                                {d1.format(CLIENT_FORMAT)}
                            </Text>
                            <Oval className={className1} />
                            <Progress
                                value={1}
                                size='small'
                                className={match.params.procedimentoMode && match.params.procedimentoMode.toLowerCase()}
                                style={{ width: `${quocient}px` }}
                            />
                            <Oval className={className2} />
                            <Text weight='semibold' size='x-small' color='grey-darker'>
                                {d2.format(CLIENT_FORMAT)}
                            </Text>
                        </div>
                    )
                }}
            />
            <TableColumnHeader
                title='Revisão em'
                className='has-text-centered'
                style={{ width: '130px' }}
                render={(item: MultiplosPacienteDto) => {
                    const diff = moment(item.dataProcedimento2).from(moment(item.dataProcedimento1), true)
                    return <Text>{diff}</Text>
                }}
            />
            <TableColumnHeader
                title=''
                className='has-text-right row-link'
                style={{ width: '20px' }}
                render={(item: MultiplosPacienteDto) => {
                    return (
                        <Hint
                            placement='top'
                            componente={
                                <LinkIconButton
                                    to={{
                                        pathname: `${match.url}/${item.id}`,
                                        search: props.location.search,
                                    }}
                                    icon='arrowRight'
                                />
                            }
                        >
                            Visualizar
                        </Hint>
                    )
                }}
            />
        </Table>
    )
}

const paginator = new PageRequester<MultiplosPacienteDto, MultiplosProcedimentosFiltro>(
    'multiplosPacientes',
    (filtro, config) => {
        return Report.getMultiplosPacientesPage(filtro, config)
    }
)

const mapStateToProps = (state: State) => ({
    pacientes: paginator.getResult(state),
    isFetching: paginator.getIsFetching(state),
    filtro: state.report.filtro.filtro,
})

const mapDispatchToProps = (dispatch, ownProps: RouteComponentProps<Params>) => ({
    load: (filtro: MultiplosProcedimentosFiltro) => {
        dispatch(
            paginator.setParams({
                ...filtro,
                procedimentoMode: ownProps.match.params.procedimentoMode,
                tipoCirurgia: ownProps.match.params.tipoCirurgia,
                tempoRevisao: ownProps.match.params.tempoRevisao,
            })
        )
        dispatch(paginator.request())
    },
    handlePageChange: (page: number) => {
        dispatch(paginator.setPageNumber(page))
        dispatch(paginator.request())
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiplosPacientesList))
