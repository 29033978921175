import * as React from 'react'
import classnames from 'classnames'
import AutoPopover from 'libs/components/presentational/AutoPopover'

interface DropdownSelectProps<T> {
    allOptionsLabel?: string
    className?: string
    customizeLabel?(label: string)
    value?: T
    valueDescriptionMap: { [key in string]: string }
    onChange(value: T)
}

export class DropdownSelect extends React.PureComponent<DropdownSelectProps<any>> {
    private autoPopover: AutoPopover

    static defaultProps: Partial<DropdownSelectProps<any>> = {
        allOptionsLabel: 'Todos',
    }

    render() {
        return (
            <AutoPopover
                ref={elem => (this.autoPopover = elem)}
                className='dropdown-select-popover'
                placement='bottom'
                rootClose
                renderTrigger={(open, close) => (
                    <div className={classnames('dropdown-select', this.props.className)} onClick={open}>
                        <span>{this.renderLabel()}</span>
                        <span className='arrow-down'></span>
                    </div>
                )}
            >
                {this.renderAllOptions()}
                {Object.entries(this.props.valueDescriptionMap).map(([key, value], index) => {
                    const classes = classnames('dropdown-select-opcao', { selected: this.props.value === key })

                    return (
                        <div key={index} className={classes} onClick={this.makeSelect(key)}>
                            {value}
                        </div>
                    )
                })}
            </AutoPopover>
        )
    }

    private renderAllOptions() {
        const classes = classnames('dropdown-select-opcao', { selected: !this.props.value })

        return (
            <div className={classes} onClick={this.makeSelect(undefined)}>
                {this.props.allOptionsLabel}
            </div>
        )
    }

    private makeSelect(value) {
        return () => {
            this.props.onChange(value)
            this.autoPopover.close()
        }
    }

    private renderLabel = () => {
        const label = this.props.value ? this.props.valueDescriptionMap[this.props.value] : this.props.allOptionsLabel

        return this.props.customizeLabel ? this.props.customizeLabel(label) : label
    }
}
