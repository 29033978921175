import React from 'react'
import Select from 'react-select'
import { Props } from 'react-select/src/Select'

export interface FixedSelectOption {
    id: any
    nome: string
}

export interface FixedSelectProps extends Props<FixedSelectOption> {
    value?: any
    options: FixedSelectOption[]
    getRef?(ref): void
}

export class FixedSelect extends React.Component<FixedSelectProps> {
    static defaultProps: Partial<FixedSelectProps> = {
        value: null,
        backspaceRemovesValue: false,
        getOptionLabel: (opt: any) => {
            return opt.toJS ? opt.toJS().nome : opt.nome
        },
        getOptionValue: (opt: any) => {
            return opt.toJS ? opt.toJS().id : opt.id
        },
        isClearable: true,
        isMulti: false,
        noOptionsMessage: () => 'Nenhum item encontrado.',
        placeholder: '',
        maxMenuHeight: 200,
    }

    constructor(props) {
        super(props)
        this.state = {
            options: [],
        }

        this.blur = this.blur.bind(this)
    }

    render() {
        return (
            <Select
                {...this.props}
                ref={ref => this.props.getRef && this.props.getRef(ref)}
                className='Select'
                classNamePrefix='Select'
                onBlur={this.blur()}
                menuIsOpen={!this.props.isDisabled && this.props.menuIsOpen}
            />
        )
    }

    private blur(): React.EventHandler<React.FocusEvent<{}>> {
        return () => {
            if (this.props.onBlur) {
                if (this.props.value && this.props.getOptionValue(this.props.value)) {
                    this.props.onBlur(this.props.value)
                } else {
                    this.props.onBlur(null)
                }
            }
        }
    }
}
