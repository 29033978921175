import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import PerfisView from 'view/perfis/PerfisView'
import { getPerfisAtivos, getPerfisInativos, isFetchingPerfis } from 'redux/selectors/user'
import * as auth from 'redux/modules/auth'
import * as alert from 'redux/modules/alert'
import * as home from 'redux/modules/home'
import { State } from 'redux/modules'

const mapStateToProps = (state: State) => {
    return {
        user: state.auth.user,
        isFetching: isFetchingPerfis(state),
        perfisAtivos: getPerfisAtivos(state),
        perfisInativos: getPerfisInativos(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps: RouteComponentProps<any>) => {
    return {
        getPerfisAcessaveis: perfis => {
            const acessaveis = []
            perfis &&
                perfis.forEach(function(element) {
                    if (element.situacaoSolicitacao === 'APROVADO') {
                        acessaveis.push(element)
                    }
                })
            return acessaveis
        },
        loadPerfis: () => {
            return dispatch(auth.findPerfis())
        },
        clearPerfis: () => {
            dispatch(auth.clearPerfis())
        },
        selecionarPerfil: perfil => {
            return dispatch(auth.selecionarPerfil(perfil))
                .then(() => {
                    ownProps.history.push('/home')
                })
                .catch(error => {
                    dispatch(alert.error({ mensagem: 'Erro ao selecionar perfil' }))
                    throw new Error(error)
                })
        },
        selecionarPerfilAutomatico: perfil => {
            return dispatch(auth.selecionarPerfilAutomatico(perfil))
                .then(() => {
                    ownProps.history.push('/home')
                })
                .catch(error => {
                    dispatch(alert.error({ mensagem: 'Erro ao selecionar perfil' }))
                    throw new Error(error)
                })
        },
        reativarVinculo: perfil => {
            return dispatch(home.reativarVinculo(perfil)).then(() => {
                dispatch(auth.findPerfis())
                dispatch(alert.success({ mensagem: 'Solicitação de reativação enviada com sucesso!' }))
            })
        },
        desativarVinculo: perfil => {
            return dispatch(home.desativarVinculoProprio(perfil)).then(() => {
                dispatch(auth.limparVinculo())
                dispatch(auth.findPerfis()).then(result => {
                    const acessaveis = []
                    result.data.forEach(function(element) {
                        if (element.ativo && element.situacaoSolicitacao === 'APROVADO') {
                            acessaveis.push(element)
                        }
                    })
                    if (acessaveis.length === 1) {
                        dispatch(auth.selecionarPerfilAutomatico(acessaveis[0])).catch(error => {
                            dispatch(alert.error({ mensagem: 'Erro ao selecionar perfil' }))
                            throw new Error(error)
                        })
                    }
                })

                dispatch(alert.success({ mensagem: 'Este vínculo foi desativado com sucesso!' }))
            })
        },
        updateLogin: () => {
            dispatch(auth.updateLogin())
        },
    }
}

const PerfisContainer = connect(mapStateToProps, mapDispatchToProps)(PerfisView)

export default PerfisContainer
