import React from 'react'
import { Table, TableColumnHeader } from '../presentational/layout/Table'
import { SeriesOptions as HighchartsSeriesOptions } from 'highcharts'

export type SeriesOptions = HighchartsSeriesOptions & {
    color?: string
    data?: any[]
}

export interface ChartTableProps {
    title: string
    categories: any[]
    series?: SeriesOptions[]
}

export class ChartTable extends React.Component<ChartTableProps> {
    render() {
        return (
            <Table values={this.props.categories} className='is-small is-spaced'>
                <TableColumnHeader
                    title={this.props.title}
                    render={(row, idx) => <div className='is-black-bis'>{row}</div>}
                />
                {this.props.series.map(serie => (
                    <TableColumnHeader
                        key={serie.name}
                        title={serie.name}
                        className='has-text-centered'
                        render={(row, idx) => (
                            <div style={{ color: serie.color }} className='has-text-centered is-semibold'>
                                {serie.data[idx][1]}
                            </div>
                        )}
                    />
                ))}
                <TableColumnHeader
                    title='Total'
                    className='has-text-centered'
                    render={(row, idx) => {
                        const total = this.props.series.map(serie => serie.data[idx][1]).reduce((a, b) => a + b, 0)
                        return <div className='has-text-centered is-semibold is-black-bis'>{total}</div>
                    }}
                />
            </Table>
        )
    }
}
