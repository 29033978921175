import { promiseReducer, PromiseRecord } from 'redux/middleware/PromiseAction'
import PublicSolicitacaoAcesso from 'api/PublicSolicitacaoAcesso'
import { ValidacaoEmailDto } from 'backend'

export const VALIDATE_EMAIL = 'rni/cadastro/VALIDATE_EMAIL'

export interface CadastroState
    extends Readonly<{
        isValid: PromiseRecord<ValidacaoEmailDto>
    }> {}

export const CadastroInitialState: CadastroState = {
    isValid: {},
}

export default function reducer(state: CadastroState = CadastroInitialState, action): CadastroState {
    if (action.type === VALIDATE_EMAIL) {
        return { ...state, isValid: promiseReducer(state.isValid, action) }
    } else {
        return state
    }
}

export const validarEmail = (token: string) => ({
    type: VALIDATE_EMAIL,
    promise: PublicSolicitacaoAcesso.validarEmail(token),
})
