import * as React from 'react'
import * as C from 'libs/components'
import { ResumoLocalItem } from 'redux/modules/report/procedimento'
import * as enums from 'redux/enums'

interface Props {
    item: ResumoLocalItem
    maxQuocient: number
    showPrimariaRevisaoRatio: boolean
    onClick: () => void
}

export class ResumoLocalCard extends React.Component<Props> {
    render() {
        const item = this.props.item

        const title = enums.ufName[item.local.uf] || enums.regiaoName[item.local.regiao]

        const quocients = Object.entries(this.props.item.classificacoes).reduce(
            (obj, [key, value]) => ({
                ...obj,
                [value.className]: value.quocient,
            }),
            {}
        )

        return (
            <C.Card>
                <C.Columns auto className='resumo-local-card align-center spacing-one-half'>
                    <div className='flex-3'>
                        <p className='is-x-large'>{title}</p>
                    </div>
                    <div className='flex-4'>
                        <div style={{ width: (item.quocient / this.props.maxQuocient) * 100 + '%' }}>
                            <C.ProgressMultiple size='x-small' values={quocients} />
                        </div>
                    </div>
                    <div className='flex-1 has-text-centered resumo-local-card__total'>
                        <C.NumberLabel title='Total de procedimentos' value={item.total} />
                        <C.NumberLabel value={item.quocient * 100} maxDecimalPlaces={1} placeholder='- %' sufix='%' />
                    </div>
                    {this.props.showPrimariaRevisaoRatio && (
                        <div className='resumo-local-card__numbers'>
                            {Object.entries(item.classificacoes).map(([key, value]) => (
                                <C.ComposedLabel
                                    key={key}
                                    title={value.title}
                                    size='small'
                                    labelClassName={value.className}
                                >
                                    <C.NumberLabel value={value.total} />
                                </C.ComposedLabel>
                            ))}
                        </div>
                    )}
                    <div className='flex-0 has-text-right is-muted'>
                        <C.Hint
                            placement='top'
                            componente={
                                <C.IconButton
                                    icon='arrowRight'
                                    size='small'
                                    pulledRight
                                    disabled={item.total <= 0}
                                    onClick={this.props.onClick}
                                />
                            }
                        >
                            Visualizar
                        </C.Hint>
                    </div>
                </C.Columns>
            </C.Card>
        )
    }
}
