import React from 'react'
import * as C from 'libs/components'

export class AngioTCCoronariaCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Angio TC de coronária'
                name='angioCoronaria'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='angioCoronaria' disabled={this.props.disabled} />}
            >
                <C.Conteudo>
                    <C.ControlField label='Tipo' name='angioCoronariaTipo' verticalFlow />
                    <C.Columns>
                        <C.Column size='5' verticalFlow>
                            <C.ClearableRadioField name='angioCoronariaTipo' label='1 vaso' value='UM_VASO' />
                            <C.ClearableRadioField name='angioCoronariaTipo' label='2 vasos' value='DOIS_VASOS' />
                            <C.ClearableRadioField name='angioCoronariaTipo' label='3 vasos' value='TRES_VASOS' />
                        </C.Column>
                        <C.Column size='6' verticalFlow>
                            <C.ClearableRadioField
                                name='angioCoronariaTipo'
                                label='Não disponível'
                                value='NAO_DISPONIVEL'
                            />
                            <C.ClearableRadioField
                                name='angioCoronariaTipo'
                                label='Indeterminado'
                                value='INDETERMINADO'
                            />
                            <C.ClearableRadioField
                                name='angioCoronariaTipo'
                                label='Sem lesões (>50%)'
                                value='SEM_LESOES'
                            />
                        </C.Column>
                    </C.Columns>
                    <C.SimNaoRadioField label='Escore de cálcio realizado' name='escoreCalcio' />
                    <C.InputPanel fallback size='medium'>
                        <C.Columns>
                            <C.Column size='3'>
                                <C.NumberField
                                    maxLength='4'
                                    label='Valor'
                                    name='valorEscoreCalcio'
                                    placeholder='(0 - 9999)'
                                    disabled={this.props.disableEscoreCalcio}
                                />
                            </C.Column>
                            <C.Column size='3'>
                                <C.NumberField
                                    placeholder='(0-100)'
                                    maxLength='3'
                                    label='Percentil'
                                    name='percentilEscoreCalcio'
                                    unit={'%'}
                                    disabled={this.props.disableEscoreCalcio}
                                />
                            </C.Column>
                        </C.Columns>
                    </C.InputPanel>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
