import * as React from 'react'
import * as C from 'libs/components'
import { DistClassificacaoIdade } from 'redux/modules/report/procedimento'
import { ChartOptions } from 'libs/components'
import { SeriesColumnOptions } from 'highcharts'

interface FaixaEtariaProps {
    dist: DistClassificacaoIdade
}

export class FaixaEtariaChartCard extends React.Component<FaixaEtariaProps> {
    render() {
        let config: ChartOptions = {}

        if (this.props.dist.readyState === 'success') {
            const result = this.props.dist.result

            config = {
                title: {
                    text: 'Distribuição de procedimentos por faixa etária',
                },
                xAxis: {
                    categories: result.categories,
                    type: 'category',
                    title: {
                        text: 'Faixa etária',
                    },
                },
                yAxis: {
                    title: {
                        text: 'N° de procedimentos',
                    },
                },
                tooltip: {
                    formatter: function() {
                        // TODO: remover cast any
                        const self = this as any
                        return self.x + ' anos: <b>' + self.y + '</b>'
                    },
                },
                series: result.series as SeriesColumnOptions[],
            }
        }

        return (
            <C.ChartCard
                defaultViewMode='column'
                title='Procedimentos por faixa etária'
                readyState={this.props.dist.readyState}
                config={config}
            />
        )
    }
}
