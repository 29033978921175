import React, { useState } from 'react'
import { Columns, Column, Icon, IconButton, Hint } from 'libs/components'
import { LocalidadeSearchResultDto, LocalidadeRowItem, LocalDimension } from 'backend'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import * as filtroReport from 'redux/modules/report/filtro'
import * as StringUtil from 'libs/util/StringUtil'
import classnames from 'classnames'

type LocalType = filtroReport.LocalType

interface Props {
    user: any
    localAtual: LocalDimension
    localidades: LocalidadeSearchResultDto
    isFetching: boolean
    onParentClick(): void
    onSearch(query: string): void
    onSearchCleared(): void
    onLocalSelected(id: any, type: LocalType): void
}

interface State {
    query: string
    resultVisible: boolean
    needsFetching: boolean
}

export class LocalHeader extends React.PureComponent<Props, State> {
    private searchTimeout: any

    constructor(props: Props) {
        super(props)
        this.state = {
            query: '',
            needsFetching: false,
            resultVisible: false,
        }
    }

    componentDidMount() {
        this.props.onSearch('')
    }

    handleSearchChange = (e: React.ChangeEvent<any>) => {
        const val = e.target.value

        this.setState({ needsFetching: true })
        this.searchTimeout && clearTimeout(this.searchTimeout)
        this.searchTimeout = setTimeout(() => {
            this.props.onSearch(val)
            this.setState({ query: val, needsFetching: false })
        }, 500)
    }

    handleSearchCleared = () => {
        this.searchTimeout && clearTimeout(this.searchTimeout)
        this.props.onSearchCleared()
        this.setState({
            query: '',
            needsFetching: false,
        })
    }

    handleSearchFocus = () => {
        this.setState({ resultVisible: true })
    }

    handleSearchBlur = () => {
        this.setState({ resultVisible: false })
    }

    handleBlurCaptured = (event: React.FocusEvent<HTMLDivElement>) => {
        const target = event.relatedTarget as HTMLElement
        if (target && target.id === 'local-header-search-button') {
            event.stopPropagation()
        }
    }

    render() {
        const local = filtroReport.getLocalName(this.props.localAtual)
        const parent = filtroReport.getParentLocalName(this.props.localAtual)

        return (
            <div>
                <HeaderContainer className='local-header'>
                    <div
                        className={classnames('local-header--header', {
                            'is-search-visible': this.state.resultVisible,
                        })}
                    >
                        <div className='local-header--title'>
                            <h2>{local}</h2>
                            {parent && (
                                <span onClick={this.props.onParentClick}>
                                    <h3>
                                        Voltar para <strong>{parent}</strong>
                                    </h3>
                                </span>
                            )}
                        </div>
                        <div
                            className='local-header--search'
                            data-name='local-header-search'
                            onBlur={this.handleSearchBlur}
                            onBlurCapture={this.handleBlurCaptured}
                        >
                            <Icon icon='pesquisar' />
                            <SearchInputTextComponent
                                onButtonClick={this.handleSearchCleared}
                                onChange={this.handleSearchChange}
                                onFocus={this.handleSearchFocus}
                                title={this.state.query}
                                buttonVisible={this.state.resultVisible}
                            />
                        </div>
                    </div>
                </HeaderContainer>
                <LocalSearchResult
                    isFetching={this.props.isFetching || this.state.needsFetching}
                    query={this.state.query}
                    result={this.props.localidades}
                    visible={this.state.resultVisible}
                    onLocalSelected={this.props.onLocalSelected}
                />
            </div>
        )
    }
}

export interface SearchInputTextComponentProps {
    onButtonClick(): void
    onChange(e: React.ChangeEvent<any>): void
    onFocus(e: React.FocusEvent<any>): void
    title: string
    buttonVisible: boolean
}

export const SearchInputTextComponent = (props: SearchInputTextComponentProps) => {
    const [value, setValue] = useState('')
    let inputRef

    const onChange = (event: React.ChangeEvent<any>) => {
        event.preventDefault && event.preventDefault()
        const newValue = event.target.value
        setValue(newValue)
        props.onChange(event)
    }

    const handleButtonClick = () => {
        setValue('')
        inputRef.focus()
        props.onButtonClick()
    }

    return (
        <>
            <input
                ref={ref => (inputRef = ref)}
                className='input ellipsis'
                type='text'
                name='local-header-search'
                placeholder='Buscar local'
                onChange={onChange}
                onFocus={props.onFocus}
                title={props.title}
                value={value}
            />
            {props.buttonVisible && (
                <Hint
                    placement='right'
                    componente={
                        <IconButton icon='recusar' id='local-header-search-button' onClick={handleButtonClick} />
                    }
                >
                    Limpar pesquisa
                </Hint>
            )}
        </>
    )
}

interface LocalSearchResultProps {
    query: string
    result: LocalidadeSearchResultDto
    visible: boolean
    isFetching: boolean
    onLocalSelected(id: any, type: filtroReport.LocalType): void
}

export const LocalSearchResult = (props: LocalSearchResultProps) => {
    const { result } = props

    if (!result || !result.regioes) {
        return null
    }

    const handleItemClick = (id: any, localType: LocalType) => {
        props.onLocalSelected(id, localType)
    }

    return (
        <div className={classnames('local-header--result-wrapper', { 'is-visible': props.visible })}>
            <div className='local-header--result'>
                <div className='report-content'>
                    <Columns>
                        <Column size='2'>
                            <h2>Regiões ({result.regioes.length})</h2>
                            <br />
                            <LocalList
                                query={props.query}
                                items={result.regioes}
                                onClick={id => handleItemClick(id, 'REGIAO')}
                            />
                        </Column>
                        <Column size='3'>
                            <h2>Estados ({result.ufs.length})</h2>
                            <br />
                            <LocalList
                                query={props.query}
                                items={result.ufs}
                                onClick={id => handleItemClick(id, 'UF')}
                            />
                        </Column>
                        <Column size='3'>
                            <h2>Cidades ({result.cidades.length})</h2>
                            <br />
                            <LocalList
                                query={props.query}
                                items={result.cidades}
                                onClick={id => handleItemClick(id, 'CIDADE')}
                            />
                        </Column>
                        <Column size='4'>
                            <h2>Hospitais ({result.hospitais.length})</h2>
                            <br />
                            <LocalList
                                query={props.query}
                                items={result.hospitais}
                                onClick={id => handleItemClick(id, 'HOSPITAL')}
                            />
                        </Column>
                    </Columns>
                    {props.isFetching && (
                        <p className='has-text-centered is-grey-darker is-italic is-small'>Carregando...</p>
                    )}
                </div>
            </div>
        </div>
    )
}

interface LocalListProps {
    query: string
    items: LocalidadeRowItem[]
    onClick(id: any): void
}

export const LocalList = (props: LocalListProps) => {
    const { items } = props

    if (!items) {
        return null
    }

    return (
        <ul className='spacing-one-half'>
            {items.length === 0 && <li className='is-grey-darker is-small is-italic'>Sem resultados</li>}
            {items.map(item => (
                <li key={item.id}>
                    <span onClick={() => props.onClick(item.id)} title={item.nome}>
                        <p>
                            <SubstrMarkedText text={StringUtil.capitalizeName(item.nome)} filter={props.query} />
                        </p>
                        {item.parent && (
                            <p className='is-small-ter is-grey-darker'>
                                <SubstrMarkedText text={StringUtil.capitalizeName(item.parent)} filter={props.query} />
                            </p>
                        )}
                    </span>
                </li>
            ))}
        </ul>
    )
}

export const SubstrMarkedText = (props: { text: string; filter: string }) => {
    const words = props.filter.split(' ').filter(value => !!value)
    let text = props.text

    for (const i in words) {
        text = text.replace(new RegExp(words[i], 'ig'), '<strong>$&</strong>')
    }

    return <span dangerouslySetInnerHTML={{ __html: text }} />
}
