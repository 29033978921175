import React from 'react'
import { Icon, Linha, Label } from 'libs/components'

interface ModalDetalhesErrosRowProps {
    index: number
    title: string
    items: string[]
    numberOfRows: number
}

export const ModalDetalhesErrosRow = (props: ModalDetalhesErrosRowProps) => {
    const { index, title, items, numberOfRows } = props
    return (
        <div>
            <div className='row-erro-header'>
                <Icon className='row-erro-icon' icon='notificacaoAlerta' size='is-x-small' />
                <Label className='row-erro-title is-semibold'>{title}</Label>
            </div>
            <ul className='row-erro-list'>
                {items.map((item: string, itemIndex: number) => (
                    <li key={itemIndex} className='row-erro-list-item'>
                        {item}
                    </li>
                ))}
            </ul>
            {numberOfRows !== index + 1 && <Linha className='row-erro-linha' />}
        </div>
    )
}
