import { Map } from 'immutable'

function wrap<P extends object>(root: ImmutableRoot<P>, objToProxy: any): any {
    return new Proxy<any>(objToProxy, {
        get: (target, prop: string) => {
            if (target[prop] === undefined) {
                target[prop] = wrap(root, new ImmutableFieldProxy<any, P>(root, prop, objToProxy))
            }
            return target[prop]
        },
    })
}

export type ImmutableField<T extends object, P extends object> = ImmutableData<T> & ImmutableFieldProxy<T, P>

type ImmutableData<T extends object> = {
    [P in keyof T]?: T[P] extends object ? ImmutableField<T[P], T> : ImmutableFieldProxy<T[P], T>
}

class ImmutableRoot<T extends object> {
    private map: Map<string, any>
    private mutable: boolean
    private proxy: ImmutableRootProxy<T>

    constructor(map: Map<string, any>, mutable: boolean) {
        this.map = map
        this.mutable = mutable
    }

    public asMap(): Map<string, any> {
        return this.map
    }
    public deleteIn(field: ImmutableFieldProxy<any, T>): ImmutableRootProxy<T> {
        return this.evaluateReturn(this.map.deleteIn(field.getAbsolutePath()))
    }
    public getIn<V>(field: ImmutableFieldProxy<V, T>): V {
        return this.map.getIn(field.getAbsolutePath())
    }
    public setIn<V>(field: ImmutableFieldProxy<V, T>, value: V): ImmutableRootProxy<T> {
        return this.evaluateReturn(this.map.setIn(field.getAbsolutePath(), value))
    }
    public withMutations(mutator: (mutable: ImmutableRootProxy<T>) => void): ImmutableRootProxy<T> {
        return this.evaluateReturn(
            this.map.withMutations(mutable => {
                mutator(immutableProxy<T>(mutable, true))
            })
        )
    }

    private evaluateReturn(map: Map<string, any>): ImmutableRootProxy<T> {
        if (this.mutable) {
            return this.proxy
        }
        return immutableProxy(map)
    }

    public wrap(): ImmutableRootProxy<T> {
        return (this.proxy = wrap(this, this))
    }
}

export type ImmutableRootProxy<T extends object> = ImmutableRoot<T> & ImmutableData<T>

class ImmutableFieldProxy<T, P extends object> {
    private root: ImmutableRoot<P>
    readonly alias: string
    private parent: ImmutableFieldProxy<any, P>

    constructor(root: ImmutableRoot<P>, alias?: string, parent?: ImmutableFieldProxy<any, P>) {
        this.root = root
        this.alias = alias
        this.parent = parent
    }

    delete(): ImmutableRootProxy<P> {
        return this.root.deleteIn(this)
    }

    get(): T {
        return this.root.getIn(this)
    }

    set(value: T): ImmutableRootProxy<P> {
        return this.root.setIn(this, value)
    }

    getAbsolutePath(): string[] {
        let path
        if (this.parent && this.parent.getAbsolutePath) {
            path = this.parent.getAbsolutePath()
        } else {
            path = []
        }

        if (this.alias) {
            path.push(this.alias)
        }

        return path
    }
}

export default function immutableProxy<T extends object>(
    map = Map<string, any>(),
    mutable = false
): ImmutableRootProxy<T> {
    return new ImmutableRoot<T>(map, mutable).wrap()
}
