import * as React from 'react'
import {
    AlertaDto,
    AlertaMotivoRevisaoDto,
    AlertaEtiquetaSemRegistroAnvisaDto,
    TipoAlerta,
    AlertaAumentoRegistroDto,
    TipoProcedimento,
    TipoCirurgia,
} from 'backend'
import classnames from 'classnames'
import { Icon, Hint } from 'libs/components'
import AlertaRow from 'view/home/anvisa/alertas/components/AlertaRow'
import { tipoProcedimentoPluralName, tipoPeriodoAlertaAlternateName } from 'redux/enums'
import AlertaAumentaRegistroModal from './AlertaAumentoRegistroModal'
import { Route, withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import { serverMoment } from 'libs/util/DateUtil'
import { Icons } from 'libs/components/presentational/elements/icons'

const urlVisualizacaoEtiquetaSemRegistro: { [key in TipoProcedimento]: string } = {
    PRIMARIA_JOELHO: 'primariaJoelho',
    PRIMARIA_QUADRIL: 'primariaQuadril',
    REVISAO_JOELHO: 'revisaoJoelho',
    REVISAO_QUADRIL: 'revisaoQuadril',
    STENT_CORONARIA: 'stentCoronaria',
}

const urlVisualizacaoMotivo: { [key in TipoCirurgia]: string } = {
    JOELHO: 'revisaoJoelho',
    QUADRIL: 'revisaoQuadril',
    CORACAO: null,
}

interface MotivoRevisaoCardProps extends RouteComponentProps<any> {
    alerta: AlertaDto
    marcarComoLido(alertaId: number)
}

class MotivoRevisaoCard extends React.PureComponent<MotivoRevisaoCardProps> {
    render() {
        const conteudo: AlertaMotivoRevisaoDto = JSON.parse(this.props.alerta.conteudo)

        return (
            <AlertaCardContainer
                alerta={this.props.alerta}
                marcarComoLido={this.props.marcarComoLido}
                onClick={() =>
                    this.props.history.push(
                        '/home/alarmes/visualizacaoProcedimento/' +
                            urlVisualizacaoMotivo[conteudo.tipoCirurgia] +
                            '/' +
                            conteudo.procedimentoId
                    )
                }
            >
                <div className='alerta-card-content-line-1 is-semibold'>
                    Motivo de revisão - {conteudo.tipoCirurgia}
                </div>
                <div className='alerta-card-content-line-2'>
                    <span className='is-semibold'>
                        {conteudo.nomeHospital.toUpperCase()} - {conteudo.cidadeHospital}/{conteudo.ufHospital}
                    </span>
                </div>
            </AlertaCardContainer>
        )
    }
}

interface EtiquetaSemRegistroAnvisaCardProps extends RouteComponentProps<any> {
    alerta: AlertaDto
    marcarComoLido(alertaId: number)
}

class EtiquetaSemRegistroAnvisaCard extends React.PureComponent<EtiquetaSemRegistroAnvisaCardProps> {
    render() {
        const conteudo: AlertaEtiquetaSemRegistroAnvisaDto = JSON.parse(this.props.alerta.conteudo)
        return (
            <AlertaCardContainer
                alerta={this.props.alerta}
                marcarComoLido={this.props.marcarComoLido}
                onClick={() =>
                    this.props.history.push(
                        '/home/alarmes/visualizacaoProcedimento/' +
                            urlVisualizacaoEtiquetaSemRegistro[conteudo.tipoProcedimento] +
                            '/' +
                            conteudo.procedimentoId
                    )
                }
            >
                <div className='alerta-card-content-line-1 is-semibold'>Etiqueta sem registro</div>
                <div className='alerta-card-content-line-2'>
                    <span className='is-semibold'>
                        {conteudo.nomeHospital.toUpperCase()} - {conteudo.cidadeHospital}/{conteudo.ufHospital}
                    </span>
                </div>
            </AlertaCardContainer>
        )
    }
}

interface AumentoRegistroCardProps extends RouteComponentProps<any> {
    alerta: AlertaDto
    marcarComoLido(alertaId: number)
}

class AumentoRegistroCard extends React.PureComponent<AumentoRegistroCardProps> {
    render() {
        const conteudo: AlertaAumentoRegistroDto = JSON.parse(this.props.alerta.conteudo)

        let descricao

        if (conteudo.emRelacaoA === 'PERIODO_ANTERIOR' || conteudo.tipoPeriodo === 'ANUAL') {
            descricao = `O número de ${tipoProcedimentoPluralName[
                conteudo.tipoProcedimento
            ].toLowerCase()} teve um aumento de ${conteudo.percentual}% ou mais em relação ao ${
                tipoPeriodoAlertaAlternateName[conteudo.tipoPeriodo]
            } anterior.`
        } else {
            descricao = `O número de ${tipoProcedimentoPluralName[
                conteudo.tipoProcedimento
            ].toLowerCase()} teve um aumento de ${conteudo.percentual}% ou mais em relação ao mesmo ${
                tipoPeriodoAlertaAlternateName[conteudo.tipoPeriodo]
            } do ano anterior.`
        }

        return (
            <AlertaCardContainer
                alerta={this.props.alerta}
                marcarComoLido={this.props.marcarComoLido}
                onClick={this.abrirModal}
            >
                <div className='alerta-card-content-line-1 is-semibold'>
                    Aumento de {conteudo.percentual}% em{' '}
                    {tipoProcedimentoPluralName[conteudo.tipoProcedimento].toLowerCase()}
                </div>
                <div className='alerta-card-content-line-2'>
                    <span className='is-semibold'>{descricao}</span>
                </div>
                <Route
                    path={`/home/alarmes/detalhe/${this.props.alerta.id}`}
                    render={props => <AlertaAumentaRegistroModal alerta={conteudo} fechar={this.fecharModal} />}
                />
            </AlertaCardContainer>
        )
    }

    private abrirModal = () => this.props.history.push(`/home/alarmes/detalhe/${this.props.alerta.id}`)
    private fecharModal = () => this.props.history.goBack()
}

const Icones: { [key in TipoAlerta]: Icons } = {
    MOTIVO_REVISAO: 'motivoProcedimento',
    ETIQUETA_SEM_REGISTRO: 'rastProdutos',
    AUMENTO_DE_REGISTROS: 'alertasAumentoregistros',
}

const Componentes: {
    [key in TipoAlerta]: React.ComponentClass<{ alerta: AlertaDto; marcarComoLido(alertaId: number) }>
} = {
    MOTIVO_REVISAO: withRouter(MotivoRevisaoCard),
    ETIQUETA_SEM_REGISTRO: withRouter(EtiquetaSemRegistroAnvisaCard),
    AUMENTO_DE_REGISTROS: withRouter(AumentoRegistroCard),
}

interface AlertaCardProps {
    alerta: AlertaDto
    marcarComoLido(alertaId: number)
}

export default class AlertaCard extends React.PureComponent<AlertaCardProps> {
    render() {
        const Componente = Componentes[this.props.alerta.tipo]
        return <Componente alerta={this.props.alerta} marcarComoLido={this.props.marcarComoLido} />
    }
}

interface AlertaCardContainerProps {
    alerta: AlertaDto
    marcarComoLido(alertaId: number)
    onClick?()
}

class AlertaCardContainer extends React.PureComponent<AlertaCardContainerProps> {
    render() {
        const classes = classnames('alerta-card', {
            'motivo-revisao': this.props.alerta.tipo === 'MOTIVO_REVISAO',
            'etiqueta-sem-registro': this.props.alerta.tipo === 'ETIQUETA_SEM_REGISTRO',
            'aumento-registro': this.props.alerta.tipo === 'AUMENTO_DE_REGISTROS',
            lido: this.props.alerta.situacao === 'LIDO',
            'nao-lido': this.props.alerta.situacao === 'NAO_LIDO',
        })
        return (
            <AlertaRow className={classes}>
                <Icon className='icon-tipo-alerta' icon={Icones[this.props.alerta.tipo]} />
                <div className='alerta-card-content'>{this.props.children}</div>
                <div className='data-alerta is-bold'>{serverMoment(this.props.alerta.data).format('DD MMM')}</div>
                <Hint placement='top' componente={<Icon icon='acessar' onClick={this.onClick} />}>
                    Visualizar
                </Hint>
            </AlertaRow>
        )
    }

    private onClick = () => {
        if (this.props.alerta.situacao === 'NAO_LIDO') {
            this.props.marcarComoLido(this.props.alerta.id).then(() => this.props.onClick && this.props.onClick())
        } else {
            this.props.onClick && this.props.onClick()
        }
    }
}
