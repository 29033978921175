import { connect } from 'react-redux'
import { State } from 'redux/modules'
import * as procedimento from 'redux/modules/report/procedimento'
import { withRouter } from 'react-router-dom'
import * as filtroReport from 'redux/modules/report/filtro'
import { MotivosProcedimentoDashboardView, Props } from './MotivosProcedimentoDashboardView'
import {
    DistribuicaoProcedimentoFiltro,
    DistribuicaoProdutosProcedimentosHospitalDto,
    DistribuicaoProdutosProcedimentosHospitalFiltro,
} from 'backend'
import { PageRequester } from 'redux/requester'
import Report from 'api/Report'

type StateFromProps = Pick<
    Props,
    | 'filtro'
    | 'distClassificacao'
    | 'distClassificacaoPeriodo'
    | 'distClassificacaoSexo'
    | 'distClassificacaoIdade'
    | 'produtosRegistro'
    | 'produtosSemRegistro'
    | 'isFetchingProdutosRegistro'
    | 'isFetchingProdutosSemRegistro'
>

type OWnProps = Props

type DispatchProps = Pick<
    Props,
    'loadDetail' | 'changeFilter' | 'handlePageChangeRegistro' | 'handlePageChangeSemRegistro'
>

const distribuicaoProdutosRegistro = new PageRequester<
    DistribuicaoProdutosProcedimentosHospitalDto,
    DistribuicaoProdutosProcedimentosHospitalFiltro
>('distProtudosRegistro', filtro => {
    return Report.findDistribuicaoProdutosProcedimentosHospitalRegistro(filtro)
})

const distribuicaoProdutosSemRegistro = new PageRequester<
    DistribuicaoProdutosProcedimentosHospitalDto,
    DistribuicaoProdutosProcedimentosHospitalFiltro
>('distProtudosSemRegistro', filtro => {
    return Report.findDistribuicaoProdutosProcedimentosHospitalSemRegistro(filtro)
})

const mapStateToProps = (state: State): StateFromProps => ({
    filtro: state.report.filtro.filtro,
    distClassificacao: state.report.procedimento.distClassificacao,
    distClassificacaoPeriodo: state.report.procedimento.distClassificacaoPeriodo,
    distClassificacaoSexo: state.report.procedimento.distClassificacaoSexo,
    distClassificacaoIdade: state.report.procedimento.distClassificacaoIdade,
    produtosRegistro: distribuicaoProdutosRegistro.getRequestState(state),
    produtosSemRegistro: distribuicaoProdutosSemRegistro.getRequestState(state),
    isFetchingProdutosRegistro: distribuicaoProdutosRegistro.getIsFetching(state),
    isFetchingProdutosSemRegistro: distribuicaoProdutosSemRegistro.getIsFetching(state),
})

const mapDispatchToProps = (dispatch, ownProps: Props): DispatchProps => ({
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(filtroReport.change(filtro))
    },
    loadDetail: (filtro: DistribuicaoProcedimentoFiltro) => {
        const f = {
            ...filtro,
            motivo: ownProps.match.params.motivo,
        }

        dispatch(procedimento.loadMotivo(ownProps.match.params.motivo))

        dispatch(distribuicaoProdutosRegistro.setParams(f))
        dispatch(distribuicaoProdutosRegistro.request())

        dispatch(distribuicaoProdutosSemRegistro.setParams(f))
        dispatch(distribuicaoProdutosSemRegistro.request())
    },
    handlePageChangeRegistro: (page: number) => {
        dispatch(distribuicaoProdutosRegistro.setPageNumber(page))
        dispatch(distribuicaoProdutosRegistro.request())
    },
    handlePageChangeSemRegistro: (page: number) => {
        dispatch(distribuicaoProdutosSemRegistro.setPageNumber(page))
        dispatch(distribuicaoProdutosSemRegistro.request())
    },
})

export default withRouter(
    connect<StateFromProps, DispatchProps, OWnProps>(
        mapStateToProps,
        mapDispatchToProps
    )(MotivosProcedimentoDashboardView)
)
