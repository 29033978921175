import { PerfilEnum } from 'api/Perfis'
import Procedimento, { tiposProcedimentoUrlByEnum } from 'api/Procedimento'
import { RascunhoProcedimentoForm, VinculoProfissionalRow } from 'backend'
import { CustomModalConfig, defaultErrorResolver, FormNewProps } from 'libs/redux-form/form'
import { notFoundErrorText, notFoundErrorTitle } from 'libs/util/NotFoundErrorUtil'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { State } from 'redux/modules'
import { clearEdicaoProcedimentoData, EdicaoPreFichaState } from 'redux/modules/edicaoProcedimento'
import {
    findProcedimentosAnteriores,
    selecionarProcedimento,
    clear as clearNovoProcedimento,
} from 'redux/modules/novoProcedimento'
import { usePesquisaSatisfacaoPopup } from 'view/componentes/pesquisaSatisfacao/usePesquisaSatisfacaoPopup'
import { ModalPreencherFichaEdicao } from '../ficha/componentes/ModalPreencherFichaEdicao'
import { ProcedimentosForm } from './components/ProcedimentosForm'

export const ProcedimentosView = () => {
    const { showPesquisa } = usePesquisaSatisfacaoPopup()
    const { push } = useHistory()
    const dispatch = useDispatch()
    const [modal, setModal] = useState<boolean>(false)

    const state = useSelector<State, State>(state => state)
    const perfil = state.auth.user?.perfil
    const procedimentosAnteriores = state.novoProcedimento.procedimentosAnteriores.data
    const edicaoPreFicha: EdicaoPreFichaState = state.edicao as EdicaoPreFichaState
    const isPerfilTecnico = perfil === PerfilEnum.TECNICO
    const paciente = state.novoProcedimento.paciente
    const vinculoProfissional = state.novoProcedimento.vinculoProfissionalMedicoResponsavel
    const equipeMedica: VinculoProfissionalRow[] = state.novoProcedimento.equipeMedica

    const [idFicha, setIdFicha] = useState<number>(edicaoPreFicha?.idProcedimento)

    const onSubmit = (form: any) => {
        const procedimento = form.get('procedimento').split(',')[0]
        const lado = form.get('procedimento').split(',')[1]
        const isEdicao: boolean = !!edicaoPreFicha

        const rascunhoForm: RascunhoProcedimentoForm = {
            pacienteId: paciente?.id,
            vinculoProfissionalResponsavelId: vinculoProfissional?.id,
            idsVinculosProfsEquipeMedica: equipeMedica.map(vinculoProfissional => {
                return vinculoProfissional.id
            }),
            lado,
            tipoProcedimento: procedimento,
        }

        if (isEdicao) {
            rascunhoForm.id = edicaoPreFicha.idProcedimento
            return Procedimento.updateRascunho(rascunhoForm)
        }

        if (!isPerfilTecnico) {
            const procedimentoUrl = tiposProcedimentoUrlByEnum[procedimento]
            dispatch(selecionarProcedimento(procedimentoUrl, lado))
            return form
        }

        return Procedimento.saveRascunho(rascunhoForm)
    }
    const onSuccessTecnico = () => {
        showPesquisa()
        dispatch(clearEdicaoProcedimentoData())
        dispatch(clearNovoProcedimento())
        push('/home')
    }
    const onSuccessMedico = result => {
        const procedimento = edicaoPreFicha
            ? result?.data?.tipoProcedimento
            : result?.get('procedimento')?.split(',')[0]
        const lado = edicaoPreFicha ? result?.data?.lado : result?.get('procedimento')?.split(',')[1]
        const procedimentoUrl = tiposProcedimentoUrlByEnum[procedimento]
        if (edicaoPreFicha) {
            dispatch(selecionarProcedimento(procedimentoUrl, lado))
            setIdFicha(result.data.id)
            setModal(true)
            return
        }
        push(`/home/novoProcedimento/${procedimentoUrl}`)
        dispatch(clearEdicaoProcedimentoData())
    }
    const onCancel = () => {
        push('/home')
    }
    const loadProcedimentosAnteriores = () => {
        dispatch(findProcedimentosAnteriores(paciente?.id))
    }

    const close = () => {
        push('/home')
    }
    const ok = () => {
        push(`/home/novoProcedimento/${idFicha}/${state.novoProcedimento.procedimento}`)
    }

    const handleSubmitFail = () => {
        if (isPerfilTecnico) {
            push('/home/aguardandoDadosClinicos')
        } else {
            push('/home')
        }
        dispatch(clearNovoProcedimento())
    }

    return (
        <>
            <ModalPreencherFichaEdicao
                onClose={close}
                onOk={ok}
                active={modal}
                mensagem={'Deseja preencher os dados clínicos do procedimento?'}
            />
            <ProcedimentosForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                paciente={paciente}
                isPerfilTecnico={isPerfilTecnico}
                editMode={edicaoPreFicha}
                errorResolver={procedimentoCadastradoModalError}
                procedimentosAnteriores={procedimentosAnteriores}
                loadProcedimentosAnteriores={loadProcedimentosAnteriores}
                onSubmitSuccess={isPerfilTecnico ? onSuccessTecnico : onSuccessMedico}
                onSubmitFail={handleSubmitFail}
                edicaoPreFicha={
                    edicaoPreFicha && {
                        idProcedimento: edicaoPreFicha.idProcedimento,
                        tipoProcedimento: edicaoPreFicha.tipoProcedimento,
                        lado: edicaoPreFicha.lado,
                        medico: null,
                        paciente: null,
                    }
                }
                initialValues={
                    edicaoPreFicha && {
                        id: edicaoPreFicha.idProcedimento,
                    }
                }
            />
        </>
    )
}

const procedimentoCadastradoModalError = (error: any): CustomModalConfig => {
    const isErrorDiffFrom400: boolean = !!error?._error
    if (isErrorDiffFrom400) {
        return {
            modalTitle: notFoundErrorTitle,
            modalContent: <span>{notFoundErrorText}</span>,
        }
    } else {
        return defaultErrorResolver(error)
    }
}

// If someday we find some place this props belong, we move it. Until that happens, here is home
export interface FichaFormCommonProps {
    paciente: any
    lado: string
    procedimento: string

    onCancel: () => any
    submitting: boolean
    handleSubmit: () => any
    pristine: boolean
}

export interface FichaFormWrapProps extends FormNewProps<{}, FichaFormCommonProps> {
    editMode: boolean
}
