/* tslint:disable */
import React from 'react'

const SvgVinculosrecusados = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 36 36' {...props}>
        <path d='M21.531 18.019c4.902 0 8.89 3.988 8.89 8.89 0 1-.814 1.813-1.815 1.813H17.933a7.167 7.167 0 01-5.75 2.9C8.221 31.622 5 28.399 5 24.439a7.19 7.19 0 017.182-7.182 7.13 7.13 0 014.437 1.546 8.797 8.797 0 013.605-.784h1.307zm7.075 9.568a.68.68 0 00.68-.679c0-4.275-3.479-7.754-7.755-7.754h-1.307c-.952 0-1.857.185-2.699.502a7.118 7.118 0 011.102 7.93l9.98.001zm-16.424 2.9a6.054 6.054 0 006.047-6.048 6.054 6.054 0 00-6.047-6.047 6.054 6.054 0 00-6.047 6.047 6.054 6.054 0 006.047 6.047zm2.672-8.72a.568.568 0 010 .803l-1.87 1.869 1.87 1.87a.568.568 0 11-.803.802l-1.868-1.87-1.87 1.87a.565.565 0 01-.803 0 .568.568 0 010-.803l1.87-1.87-1.87-1.868a.568.568 0 11.803-.802l1.87 1.869 1.868-1.87a.568.568 0 01.803 0zm6.024-4.534a6.624 6.624 0 01-6.617-6.617A6.624 6.624 0 0120.878 4a6.624 6.624 0 016.617 6.616 6.625 6.625 0 01-6.617 6.617zm0-12.098a5.487 5.487 0 00-5.482 5.481 5.488 5.488 0 005.482 5.482 5.489 5.489 0 005.482-5.482 5.488 5.488 0 00-5.482-5.48z' />
    </svg>
)

export default SvgVinculosrecusados
