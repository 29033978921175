import * as React from 'react'
import * as C from 'libs/components'
import { PropsWithChildren, useState, ReactNode } from 'react'
import { ModalCardAjuda } from 'libs/components'

export interface SectionWithHelpProps {
    title: string
    modalBody: ReactNode[]
    modalHeader: ReactNode[]
}
export const SectionWithHelpModal = (props: PropsWithChildren<SectionWithHelpProps>) => {
    const [state, setState] = useState<{ modalOpen: boolean }>({ modalOpen: false })
    return (
        <>
            <ModalCardAjuda
                hasCloseButton
                onClose={() => setState({ modalOpen: false })}
                active={state.modalOpen}
                bodyItems={props.modalBody}
                headerItems={props.modalHeader}
            />
            <C.SectionTitle title={props.title} onAjudaClick={() => setState({ modalOpen: true })} />
            <C.Conteudo>{props.children}</C.Conteudo>
        </>
    )
}
