import * as React from 'react'
import { DistTempoMedio } from 'redux/modules/report/usoSistema'
import { ChartCard, ChartOptions } from 'libs/components'
import { SeriesColumnOptions } from 'highcharts'

interface Props {
    readyState: 'request' | 'success' | 'failure'
    dist: DistTempoMedio
}

export class TempoMedioChartCard extends React.Component<Props> {
    render() {
        let config: ChartOptions = {}

        if (this.props.readyState === 'success') {
            config = {
                chart: {
                    type: 'bar',
                },

                subtitle: {
                    text:
                        'Este gráfico deve contabilizar apenas as etapas cujo período de aguardo de preenchimento da etapa (desde o momento em que foi finalizado o preenchimento da etapa anterior, até o momento em que foi realizado o preenchimento da etapa em questão) esteja incluso no período de tempo filtrado.',
                    align: 'left',
                },
                xAxis: {
                    categories: this.props.dist.result.categories,
                    title: {
                        text: null,
                    },
                },
                yAxis: {
                    min: 0,
                    minTickInterval: 1,
                    title: {
                        text: null,
                    },
                },
                title: {
                    text: 'Distribuição de tempo médio por etapa',
                },
                tooltip: {
                    pointFormat: 'Tempo médio para concluir preenchimento: <b>{point.y}</b>',
                    valueSuffix: ' dia(s)',
                },
                legend: {
                    layout: 'horizontal',
                    align: 'right',
                    verticalAlign: 'bottom',
                    y: 20,
                    x: -270,
                    floating: true,
                },
                credits: {
                    enabled: false,
                },
                series: this.props.dist.result.series as SeriesColumnOptions[],
            }
        }
        return (
            <ChartCard
                defaultViewMode='bar'
                title='Tempo médio de preenchimento de dados'
                readyState={this.props.readyState}
                config={config}
                renderActions={false}
                renderTools={false}
            />
        )
    }
}
