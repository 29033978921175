
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.2,"time":12000,"words":40}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Na tela login selecione a opção “Gestor de segurança do hospital”.`}</li>
      <li parentName="ol">{`Na tela de login para sistemas da ANVISA (Internet), informe o login e senha já utilizados para acessar outros sistemas da ANVISA.`}</li>
      <li parentName="ol">{`Clique no botão “Entrar”.`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

