import * as React from 'react'
import classnames from 'classnames'

export interface PaginatorProps {
    first: boolean
    last: boolean
    pageNumber: number
    totalPages: number
    onChange?: (page: number) => void
}

export class Paginator extends React.Component<PaginatorProps> {
    constructor(props) {
        super(props)

        this.previous = this.previous.bind(this)
        this.next = this.next.bind(this)
    }

    render() {
        const paginatorClasses = {
            first: classnames({ 'is-disabled': this.props.first }),
            last: classnames({ 'is-disabled': this.props.last }),
        }
        return (
            <div className='paginator has-text-centered'>
                <span className='botoes'>
                    <p className={paginatorClasses.first} onClick={!this.props.first ? this.previous : undefined}>
                        &laquo;
                    </p>
                    {this.props.pageNumber - 2 >= 0 && (
                        <p onClick={this.makeSelectPage(this.props.pageNumber - 2)}>{this.props.pageNumber - 1}</p>
                    )}
                    {this.props.pageNumber - 1 >= 0 && (
                        <p onClick={this.makeSelectPage(this.props.pageNumber - 1)}>{this.props.pageNumber}</p>
                    )}
                    <p className='active'>{this.props.pageNumber + 1}</p>
                    {this.props.pageNumber + 1 < this.props.totalPages && (
                        <p onClick={this.makeSelectPage(this.props.pageNumber + 1)}>{this.props.pageNumber + 2}</p>
                    )}
                    {this.props.pageNumber + 2 < this.props.totalPages && (
                        <p onClick={this.makeSelectPage(this.props.pageNumber + 2)}>{this.props.pageNumber + 3}</p>
                    )}
                    <p className={paginatorClasses.last} onClick={!this.props.last ? this.next : undefined}>
                        &raquo;
                    </p>
                </span>
                <span className='page-indicator is-pulled-right'>
                    Página {this.props.pageNumber + 1} de {this.props.totalPages > 0 ? this.props.totalPages : 1}
                </span>
            </div>
        )
    }

    private previous() {
        this.props.onChange(this.props.pageNumber - 1)
    }

    private next() {
        this.props.onChange(this.props.pageNumber + 1)
    }

    private makeSelectPage(page: number) {
        return () => this.props.onChange(page)
    }
}
