import Paciente from 'api/Paciente'
import { CadastroPacienteForm, PacienteSeletor } from 'backend'
import { actionCreator, isType } from 'libs/redux'

export interface PacienteState
    extends Readonly<{
        form?: CadastroPacienteForm
        edit?: CadastroPacienteForm
        error?: number
    }> {}

export default function reducer(state: PacienteState = {}, action): PacienteState {
    if (isType(action, actionCreators.editPaciente)) {
        return { ...state, error: undefined, edit: action.payload }
    }
    if (isType(action, actionCreators.findSuccess)) {
        return { ...state, error: undefined, form: action.payload }
    }
    if (isType(action, actionCreators.findFailure)) {
        return { ...state, error: action.payload, form: undefined }
    }
    if (isType(action, actionCreators.clearFindResult)) {
        return { ...state, error: undefined, form: undefined }
    }
    if (isType(action, actionCreators.clearEdit)) {
        return { ...state, error: undefined, edit: undefined }
    }
    if (isType(action, actionCreators.clear)) {
        return {}
    }
    return state
}

export const actionCreators = {
    clear: actionCreator<string>('rni/paciente/CLEAR'),
    clearEdit: actionCreator<void>('rni/paciente/CLEAR_EDIT'),
    clearFindResult: actionCreator<string>('rni/paciente/CLEAR_FIND_RESULT'),
    editPaciente: actionCreator<CadastroPacienteForm>('rni/paciente/EDIT_PACIENTE'),
    findFailure: actionCreator<number>('rni/paciente/FIND_FAILURE'),
    findSuccess: actionCreator<CadastroPacienteForm>('rni/paciente/FIND_SUCCESS'),
    find: (filtro: PacienteSeletor) => {
        return dispatch => {
            return Paciente.getByFiltro(filtro)
                .then(res => {
                    dispatch(actionCreators.findSuccess(res.data))
                    return res
                })
                .catch(error => {
                    dispatch(actionCreators.findFailure(error.response.status))
                    return error
                })
        }
    },
}
