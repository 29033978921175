/* tslint:disable */
import React from 'react'

const SvgAprovar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M18.62 6.31c.54-.664 1.49-.75 2.121-.187.604.578.68 1.573.16 2.256l-9.784 10.288a1.604 1.604 0 01-1.1.583h-.061c-.407 0-.775-.164-1.06-.474l-5.717-6.199a1.678 1.678 0 010-2.24 1.424 1.424 0 012.122 0l4.57 4.954 8.75-8.98z' />
    </svg>
)

export default SvgAprovar
