import * as React from 'react'
import * as C from 'libs/components'
import { capitalizeName } from 'libs/util/StringUtil'
import { DistribuicaoProdutosBrasilRow } from 'backend'

interface Props {
    distProdutos: DistribuicaoProdutosBrasilRow[]
    titulo?: string
    isFetching?: boolean
    report(): JSX.Element
}

export class ComponenteBrasilTable extends React.Component<Props> {
    getTitle = distProdutos => {
        if (distProdutos) {
            return distProdutos.length === 1 ? distProdutos.length + ' hospital' : distProdutos.length + ' hospitais'
        }
        return '0 hospitais'
    }

    render() {
        const { isFetching } = this.props
        const distProdutos = this.props.distProdutos

        return (
            <div className='report-content'>
                <C.Card title={this.getTitle(distProdutos)} headerAction={this.props.report()}>
                    <C.LoadingContainer isFetching={isFetching}>
                        {distProdutos && (
                            <C.Table values={distProdutos} className='is-spaced is-small-bis'>
                                <C.TableColumnHeader
                                    title='Hospital'
                                    render={(item: DistribuicaoProdutosBrasilRow) => {
                                        return <C.Text>{capitalizeName(item.nomeHospital)}</C.Text>
                                    }}
                                />
                                <C.TableColumnHeader
                                    title='Cidade'
                                    render={(item: DistribuicaoProdutosBrasilRow) => {
                                        return <C.Text>{capitalizeName(item.cidade)}</C.Text>
                                    }}
                                />
                                <C.TableColumnHeader
                                    title='Estado'
                                    render={(item: DistribuicaoProdutosBrasilRow) => {
                                        return <C.Text>{capitalizeName(item.estado)}</C.Text>
                                    }}
                                />
                                <C.TableColumnHeader
                                    title='Proced.'
                                    className='has-text-centered is-black-bis'
                                    render={(item: DistribuicaoProdutosBrasilRow) => {
                                        return <C.Text>{item.quantidadeProcedimentos}</C.Text>
                                    }}
                                />
                            </C.Table>
                        )}
                    </C.LoadingContainer>
                </C.Card>
            </div>
        )
    }
}
