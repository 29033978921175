import * as UUID from 'libs/util/UUID'
import { Icons } from 'libs/components/presentational/elements/icons'

export const ADD_ALERT = 'rni/alert/ADD_ALERT'
export const REMOVE_ALERT = 'rni/alert/REMOVE_ALERT'

export const DEFAULT_DURATION: number = 5000

export interface AlertItem {
    duration?: number
    icon?: Icons
    mensagem: string
    uuid?: string
    tipo?: 'success' | 'error' | 'warn' | 'info'
}

export interface AlertState
    extends Readonly<{
        items: AlertItem[]
    }> {}

export const AlertInitialState: AlertState = {
    items: [],
}

export default function reducer(state: AlertState = AlertInitialState, action): AlertState {
    switch (action.type) {
        case ADD_ALERT:
            return { ...state, items: state.items.concat(action.alert) }
        case REMOVE_ALERT:
            return { ...state, items: state.items.filter(alert => alert.uuid !== action.uuid) }
        default:
            return state
    }
}

export const addAlert = (alert: AlertItem) => ({
    type: ADD_ALERT,
    alert,
})

export const removeAlert = (uuid: string) => ({
    type: REMOVE_ALERT,
    uuid,
})

export const success = (alert: AlertItem) =>
    addAlert({
        duration: DEFAULT_DURATION,
        uuid: UUID.generateUUID(),
        tipo: 'success',
        icon: 'notificacaoSucesso',
        ...alert,
    })

export const error = (alert: AlertItem) =>
    addAlert({
        duration: DEFAULT_DURATION,
        uuid: UUID.generateUUID(),
        tipo: 'error',
        icon: 'notificacaoErro',
        ...alert,
    })

export const warn = (alert: AlertItem) =>
    addAlert({
        duration: DEFAULT_DURATION,
        uuid: UUID.generateUUID(),
        tipo: 'warn',
        icon: 'notificacaoAlerta',
        ...alert,
    })

export const info = (alert: AlertItem) =>
    addAlert({
        duration: DEFAULT_DURATION,
        uuid: UUID.generateUUID(),
        tipo: 'info',
        icon: 'notificacaoInformativo',
        ...alert,
    })

export const compose = mapDispatchToProps => (dispatch, ownProps) => {
    return Object.assign(
        {},
        {
            success: mensagem => {
                dispatch(success({ mensagem: mensagem }))
            },
            error: mensagem => {
                dispatch(error({ mensagem: mensagem }))
            },
            warn: mensagem => {
                dispatch(warn({ mensagem: mensagem }))
            },
            info: mensagem => {
                dispatch(info({ mensagem: mensagem }))
            },
        },
        mapDispatchToProps(dispatch, ownProps)
    )
}
