import { connect } from 'react-redux'
import { State } from 'redux/modules'
import AlterarDadosPessoaisView from 'view/home/dadosPessoais/AlterarDadosPessoaisView'
import * as profissional from 'redux/modules/profissional'
import Profissional from 'api/Profissional'

const mapStateToProps = (state: State) => {
    return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        afterSave: () => ownProps.history.push('/home'),
        cancelar: () => ownProps.history.goBack(),
        save: form => Profissional.alterarDadosPessoais(form.toJS()),

        findDadosPessoais: () => {
            dispatch(profissional.findDadosPessoais())
        },

        clear: () => {
            dispatch(profissional.clearDadosPessoais())
        },
    }
}

const AlterarDadosPessoaisContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AlterarDadosPessoaisView as any)

export default AlterarDadosPessoaisContainer
