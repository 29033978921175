import React from 'react'
import form from 'libs/redux-form/form'

export default class RastreabilidadeForm extends React.Component {
    constructor(props) {
        super(props)
        this.formWrapped = this.wrapForm(props)
    }

    wrapForm(props) {
        return form({
            form: props.formName,
            hasLeaveModal: false,
            hasSuccessModal: props.canSave && !props.isAdmAndHaveCustomModal,
            successTitle: props.editMode ? 'Edição realizada com sucesso!' : 'Cadastro realizado com sucesso!',
            initialValues: props.editinValues,
            validate: (values, formProps) => {
                const errors = {
                    [props.formSectionName]: props.validator(values.get(props.formSectionName), formProps),
                }
                return errors
            },
        })(props.formComponent)
    }

    render() {
        const FormWrapped = this.formWrapped
        return <FormWrapped {...this.props} />
    }
}
