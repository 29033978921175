import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'libs/components'
import Environment from 'libs/util/Environment'
import { UserDTO } from 'backend'
import ModalSobre from 'view/home/componentes/ModalSobre'
import { Location } from 'history'

export interface HeaderProps {
    user?: UserDTO
    onLogout?: () => void
    location?: Location<any>
    getVersao: () => void
    versao: string
}

interface HeaderState {
    openModalSobre: boolean
}

export function Header({ getVersao, ...props }: HeaderProps, state: HeaderState) {
    const [openModalSobre, setOpenModalSobre] = useState(false)
    useEffect(() => {
        getVersao()
    }, [getVersao])

    const open = () => setOpenModalSobre(true)
    const close = () => setOpenModalSobre(false)

    return (
        <div className='header'>
            <div className='topbar'>
                <div className='container'>
                    <nav className='nav'>
                        {props.location.pathname.match('/manualUsuario') && (
                            <Link className='nav-right nav-retornar' to='/home'>
                                <span className='icon'>
                                    <Icon icon='voltar' size='is-x-small' />
                                </span>
                                Retornar ao RNI
                            </Link>
                        )}
                        <div className='nav-right'>
                            {props.user && Environment.isDev() && (
                                <Link className='nav-item' to='/home/showcase'>
                                    Showcase
                                </Link>
                            )}

                            <p className='nav-item' onClick={open}>
                                <span className='icon'>
                                    <Icon icon='sobre' size='small' />
                                </span>
                                Sobre
                            </p>

                            {props.user && (
                                <Link className='nav-item' to='/home/manualUsuario/inicio'>
                                    <span className='icon'>
                                        <Icon icon='help' size='small' />
                                    </span>
                                    Ajuda
                                </Link>
                            )}
                            {props.user && (
                                <p className='nav-item' onClick={props.onLogout}>
                                    <span className='icon'>
                                        <Icon icon='sair' size='small' />
                                    </span>
                                    Sair
                                </p>
                            )}
                            {!props.user && (
                                <Link className='nav-item' to='/public/manualUsuario/inicio'>
                                    <span className='icon'>
                                        <Icon icon='help' size='small' />
                                    </span>
                                    Ajuda
                                </Link>
                            )}
                        </div>
                    </nav>
                </div>
            </div>
            <ModalSobre versao={props.versao} active={openModalSobre} onClose={close} hasCloseButton />
        </div>
    )
}
