import * as React from 'react'
import * as C from 'libs/components'

export interface ComponenteCopySectionProps {
    cacheComponentes: Array<any>
    fillInComponente(componente: object): void
    indexComponenteAtual: number
}

interface State {
    indexComponenteSelecionado: number
    componenteSelecionado: any
}

export class ComponenteCopySection extends React.Component<ComponenteCopySectionProps, State> {
    constructor(props: ComponenteCopySectionProps) {
        super(props)
        this.state = {
            indexComponenteSelecionado: null,
            componenteSelecionado: null,
        }
    }

    getCopyCacheComponente() {
        const componentes = []
        this.props.cacheComponentes.forEach((componente, index) => {
            if (index >= this.props.indexComponenteAtual) {
                return false
            }

            componentes.push({ id: index, nome: componente.get('nomeComponente') })
        })
        return componentes
    }

    getComponenteFromCache() {
        return this.props.cacheComponentes[this.state.indexComponenteSelecionado]
    }

    copyComponente() {
        if (this.state.indexComponenteSelecionado !== null) {
            const componente = this.getComponenteFromCache()
            this.props.fillInComponente(componente)
        }
    }

    handleChange(selectedOption) {
        this.setState({
            indexComponenteSelecionado: selectedOption ? selectedOption.id : null,
            componenteSelecionado: selectedOption,
        })
    }

    render() {
        return (
            <C.Conteudo>
                <C.Columns>
                    <C.Column size='8'>
                        <C.FormField name='copy' label='Deseja copiar dados de um componente anterior?'>
                            <C.FixedSelect
                                value={this.state.componenteSelecionado}
                                options={this.getCopyCacheComponente()}
                                onChange={this.handleChange.bind(this)}
                            />
                        </C.FormField>
                    </C.Column>
                    <C.Column pulledBottom>
                        <C.Button
                            type='primary'
                            disabled={this.state.indexComponenteSelecionado === null}
                            onClick={this.copyComponente.bind(this)}
                        >
                            Copiar
                        </C.Button>
                    </C.Column>
                </C.Columns>
            </C.Conteudo>
        )
    }
}
