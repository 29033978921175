import { Label, Linha } from 'libs/components'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AutenticacaoState, clearAutenticacoes, getAutenticacoes } from 'redux/modules/integracao'
import { State } from 'redux/modules'
import { AutenticacaoIntegracaoRow } from './componentes/autenticacao/AutenticacaoIntegracaoRow'

export const AutenticacaoIntegracaoView = () => {
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getAutenticacoes())
        return () => dispatch(clearAutenticacoes())
    }, [dispatch])

    const autenticacoes: AutenticacaoState[] = useSelector<State, AutenticacaoState[]>(
        state => state.integracao.autenticacoes
    )
    const hasData: boolean = !!autenticacoes && autenticacoes.length > 0

    return (
        <div className='autenticacao-integracao'>
            <Label className='integracao-header-title'>Chave para envio de dados</Label>
            <Linha className='integracao-header-line' />
            {hasData ? (
                <>
                    {autenticacoes?.map((autenticacao: AutenticacaoState) => (
                        <AutenticacaoIntegracaoRow key={autenticacao.sistema.id} autenticacao={autenticacao} />
                    ))}
                </>
            ) : (
                <div className='autenticacao-empty-label-ctn'>
                    <span className='integracao-empty-label is-italic'>
                        Para poder gerar uma nova chave, cadastre ou reative um sistema.
                    </span>
                </div>
            )}
        </div>
    )
}
