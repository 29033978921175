import * as React from 'react'
import { ComposedLabelProps, ComposedLabel } from './ComposedLabel'
import { asClientFormat } from 'libs/util/DateUtil'

export interface DateLabelProps extends ComposedLabelProps {
    value: string
}

export class DateLabel extends React.Component<DateLabelProps, any> {
    render() {
        const { value, ...rest } = this.props
        return <ComposedLabel {...rest}>{value && asClientFormat(value)}</ComposedLabel>
    }
}
