import { tiposProcedimentoDescricaoByEnum } from 'api/Procedimento'
import iconPaciente from 'images/home/cidadao/ic-paciente.svg'
import * as C from 'libs/components'
import React from 'react'
import { Route } from 'react-router-dom'
import PrintIcon from 'view/componentes/PrintIcon'
import HistoricoPopover from 'view/home/componentes/HistoricoPopover'
import { TabItem, TabMenu } from 'view/home/componentes/HomeMenu'

class DadosProdutoFinanceiro extends React.Component {
    componentDidMount() {
        this.props.loadDadosProdutoAdministrativo()
    }

    render() {
        const preencherDadosProdutoFinanceiro = ficha => {
            return () => {
                this.props.preencherDadosProdutoFinanceiro(ficha)
            }
        }

        const visualizarFicha = (idProced, procedimento) => {
            return () => {
                this.props.visualizarFicha(idProced, procedimento)
            }
        }

        const imprimirFicha = procedimentoId => {
            return () => {
                this.props.imprimirFicha(procedimentoId)
            }
        }

        return (
            <div className='home-perfil-conteudo'>
                <C.LoadingContainer isFetching={this.props.isFetching}>
                    <span className='titulo'>Aguardando dados de produto e financeiro</span>
                    <C.Table
                        name='dadoProdutoFinanceiro'
                        addRowSpacer
                        className='table-home table-home-medico'
                        values={this.props.pendencias}
                        emptyText='Nenhuma pendência encontrada.'
                    >
                        <C.TableColumnHeader size='1' render={() => <C.Image src={iconPaciente} size='48x48' />} />
                        <C.TableColumnHeader
                            render={value => (
                                <div>
                                    <div className='is-semibold'>{value.paciente.nome}</div>
                                    <span className='procedimento'>
                                        {tiposProcedimentoDescricaoByEnum[value.tipoProcedimento]}
                                        {value.tipoProcedimento !== 'STENT_CORONARIA' && ' ' + value.lado.toLowerCase()}
                                    </span>
                                    <span className='data-procedimento'> ({value.dataCirurgia})</span>
                                    <div>
                                        <HistoricoPopover className='registrado-por' registro={value.procedimentoId}>
                                            <C.Icon icon='historicoalteracoes' size='is-12px' />
                                            {value.profUltimaAlteracaoNome}
                                        </HistoricoPopover>
                                    </div>
                                </div>
                            )}
                        />
                        <C.TableColumnHeader
                            size='4'
                            render={value => [
                                <C.Hint
                                    key='2'
                                    placement='top'
                                    componente={
                                        <C.IconButton
                                            icon='detalhes'
                                            pulledRight
                                            type='light'
                                            onClick={visualizarFicha(value.procedimentoId, value.tipoProcedimento)}
                                        />
                                    }
                                >
                                    Visualizar registro
                                </C.Hint>,
                                <PrintIcon key='3' onClick={imprimirFicha(value.procedimentoId)} />,
                                <C.Hint
                                    key='1'
                                    placement='top'
                                    componente={
                                        <C.Button
                                            className='preencher'
                                            pulledRight
                                            icon='produtofinanceiros'
                                            onClick={preencherDadosProdutoFinanceiro(value)}
                                        >
                                            Preencher
                                        </C.Button>
                                    }
                                >
                                    Preencher dados de produto e financeiros
                                </C.Hint>,
                            ]}
                        />
                    </C.Table>
                </C.LoadingContainer>
            </div>
        )
    }
}

class DadosFinanceiro extends React.Component {
    componentDidMount() {
        this.props.loadDadosFinanceiroAdministrativo()
    }

    render() {
        const editarDadosProduto = ficha => {
            return () => {
                this.props.editarDadosProduto(ficha)
            }
        }

        const preencherDadosFinanceiro = ficha => {
            return () => {
                this.props.preencherDadosFinanceiro(ficha)
            }
        }

        const visualizarFicha = (idProced, procedimento) => {
            return () => {
                this.props.visualizarFicha(idProced, procedimento)
            }
        }

        const imprimirFicha = procedimentoId => {
            return () => {
                this.props.imprimirFicha(procedimentoId)
            }
        }

        return (
            <div className='home-perfil-conteudo'>
                <C.LoadingContainer isFetching={this.props.isFetching}>
                    <span className='titulo'>Aguardando dados financeiros</span>
                    <C.Table
                        name='dadoFinanceiro'
                        addRowSpacer
                        className='table-home table-home-medico'
                        values={this.props.pendencias}
                        emptyText='Nenhuma pendência encontrada.'
                    >
                        <C.TableColumnHeader size='1' render={() => <C.Image src={iconPaciente} size='48x48' />} />
                        <C.TableColumnHeader
                            render={value => (
                                <div>
                                    <div className='is-semibold'>{value.paciente.nome}</div>
                                    <span className='procedimento'>
                                        {tiposProcedimentoDescricaoByEnum[value.tipoProcedimento]}
                                        {value.tipoProcedimento !== 'STENT_CORONARIA' && ' ' + value.lado.toLowerCase()}
                                    </span>
                                    <span className='data-procedimento'> ({value.dataCirurgia})</span>
                                    <div>
                                        <HistoricoPopover className='registrado-por' registro={value.procedimentoId}>
                                            <C.Icon icon='historicoalteracoes' size='is-12px' />
                                            {value.profUltimaAlteracaoNome}
                                        </HistoricoPopover>
                                    </div>
                                </div>
                            )}
                        />
                        <C.TableColumnHeader
                            size='5'
                            render={value => [
                                !value.origemExterna && (
                                    <C.Hint
                                        key='4'
                                        placement='top'
                                        componente={
                                            <C.IconButton
                                                icon='editar'
                                                type='light'
                                                pulledRight
                                                onClick={editarDadosProduto(value)}
                                            />
                                        }
                                    >
                                        Editar
                                    </C.Hint>
                                ),
                                <C.Hint
                                    key='2'
                                    placement='top'
                                    componente={
                                        <C.IconButton
                                            icon='detalhes'
                                            pulledRight
                                            type='light'
                                            onClick={visualizarFicha(value.procedimentoId, value.tipoProcedimento)}
                                        />
                                    }
                                >
                                    Visualizar registro
                                </C.Hint>,
                                <PrintIcon key='3' onClick={imprimirFicha(value.procedimentoId)} />,
                                <C.Hint
                                    key='1'
                                    placement='top'
                                    componente={
                                        <C.Button
                                            className='preencher'
                                            pulledRight
                                            icon='dadosfinanceiros'
                                            onClick={preencherDadosFinanceiro(value)}
                                        >
                                            Preencher
                                        </C.Button>
                                    }
                                >
                                    Preencher dados financeiros
                                </C.Hint>,
                            ]}
                        />
                    </C.Table>
                </C.LoadingContainer>
            </div>
        )
    }
}

class DadosHistorico extends React.Component {
    constructor() {
        super()

        this.loadTable = this.loadTable.bind(this)
    }

    componentDidMount() {
        this.loadTable({})
    }

    loadTable(pageable) {
        this.props.loadDadosHistorico(pageable)
    }

    render() {
        const visualizarFicha = (idProced, procedimento) => {
            return () => {
                this.props.visualizarFicha(idProced, procedimento)
            }
        }

        const editarDadosFinanceiro = ficha => {
            return () => {
                this.props.editarDadosFinanceiro(ficha)
            }
        }

        const imprimirFicha = procedimentoId => {
            return () => {
                this.props.imprimirFicha(procedimentoId)
            }
        }

        return (
            <div className='home-perfil-conteudo'>
                <C.LoadingContainer isFetching={this.props.isFetching}>
                    <span className='titulo'>Histórico</span>
                    <C.PagedTable
                        name='historico'
                        addRowSpacer
                        className='table-home table-home-medico'
                        page={this.props.pendencias}
                        onChangePage={this.loadTable}
                        emptyText='Nenhum procedimento finalizado.'
                    >
                        <C.TableColumnHeader size='1' render={() => <C.Image src={iconPaciente} size='48x48' />} />
                        <C.TableColumnHeader
                            render={value => (
                                <div>
                                    <div className='is-semibold'>{value.paciente.nome}</div>
                                    <span className='procedimento'>
                                        {tiposProcedimentoDescricaoByEnum[value.tipoProcedimento]}
                                        {value.tipoProcedimento !== 'STENT_CORONARIA' && ' ' + value.lado.toLowerCase()}
                                    </span>
                                    <span className='data-procedimento'> ({value.dataCirurgia})</span>
                                    <div>
                                        <HistoricoPopover className='registrado-por' registro={value.procedimentoId}>
                                            <C.Icon icon='historicoalteracoes' size='is-12px' />
                                            {value.profUltimaAlteracaoNome}
                                        </HistoricoPopover>
                                    </div>
                                </div>
                            )}
                        />
                        <C.TableColumnHeader
                            size='3'
                            render={value => [
                                !value.origemExterna && (
                                    <C.Hint
                                        key='1'
                                        placement='top'
                                        componente={
                                            <C.IconButton
                                                icon='editar'
                                                pulledRight
                                                type='light'
                                                onClick={editarDadosFinanceiro(value)}
                                            />
                                        }
                                    >
                                        Editar
                                    </C.Hint>
                                ),
                                <C.Hint
                                    key='2'
                                    placement='top'
                                    componente={
                                        <C.IconButton
                                            icon='detalhes'
                                            pulledRight
                                            type='light'
                                            onClick={visualizarFicha(value.procedimentoId, value.tipoProcedimento)}
                                        />
                                    }
                                >
                                    Visualizar registro
                                </C.Hint>,
                                <PrintIcon key='3' onClick={imprimirFicha(value.procedimentoId)} />,
                            ]}
                        />
                    </C.PagedTable>
                </C.LoadingContainer>
            </div>
        )
    }
}

export default class HomeAdministrativoView extends React.Component {
    render() {
        return (
            <div className='home-perfil-container is-vertical-flow is-full-height has-text-centered'>
                <C.Columns fullHeight>
                    <C.Column verticalFlow size='2'>
                        <TabMenu>
                            <TabItem icon='dadosfinanceiros' path={'/home'}>
                                Aguardando dados financeiros
                            </TabItem>
                            <TabItem icon='produtofinanceiros' path={'/home/aguardandoDadosProdutoFinanceiros'}>
                                Aguardando dados de produto e financeiro
                            </TabItem>
                            <TabItem icon='historico' path={'/home/historico'}>
                                Histórico
                            </TabItem>
                        </TabMenu>
                    </C.Column>
                    <C.Column offset='1' size='8'>
                        <Route
                            exact
                            path={'/home/aguardandoDadosProdutoFinanceiros'}
                            render={() => {
                                return (
                                    <DadosProdutoFinanceiro
                                        preencherDadosProdutoFinanceiro={this.props.preencherDadosProdutoFinanceiro}
                                        pendencias={this.props.aguardandoDadosProduto}
                                        isFetching={this.props.isFetchingAguardandoDadosProduto}
                                        loadDadosProdutoAdministrativo={this.props.loadDadosProdutoAdministrativo}
                                        visualizarFicha={this.props.visualizarFicha}
                                        imprimirFicha={this.props.imprimirFicha}
                                    />
                                )
                            }}
                        />
                        <Route
                            exact
                            path={'/home'}
                            render={routeProps => {
                                return (
                                    <DadosFinanceiro
                                        editarDadosProduto={this.props.editarDadosProduto}
                                        loadDadosFinanceiroAdministrativo={this.props.loadDadosFinanceiroAdministrativo}
                                        preencherDadosFinanceiro={this.props.preencherDadosFinanceiro}
                                        pendencias={this.props.aguardandoDadosFinanceiros}
                                        isFetching={this.props.isFetchingAguardandoDadosFinanceiros}
                                        visualizarFicha={this.props.visualizarFicha}
                                        imprimirFicha={this.props.imprimirFicha}
                                        {...routeProps}
                                    />
                                )
                            }}
                        />
                        <Route
                            exact
                            path={'/home/historico'}
                            render={routeProps => {
                                return (
                                    <DadosHistorico
                                        editarDadosFinanceiro={this.props.editarDadosFinanceiro}
                                        pendencias={this.props.historico}
                                        isFetching={this.props.isFetchingHistorico}
                                        loadDadosHistorico={this.props.loadDadosHistorico}
                                        loadDadosHistoricoDetail={this.props.loadDadosHistoricoDetail}
                                        visualizarFicha={this.props.visualizarFicha}
                                        imprimirFicha={this.props.imprimirFicha}
                                        {...routeProps}
                                    />
                                )
                            }}
                        />
                    </C.Column>
                </C.Columns>
            </div>
        )
    }
}
