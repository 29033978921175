import React from 'react'
import { DadosProfissional } from 'view/home/rastreabilidade/componentes/ModalInformacoesProcedimento'
import { Linha, ModalCard } from 'libs/components'
import { VinculoProfissionalRow } from 'backend'

interface ModalInformacoesEquipeMedicaProps {
    medicoResponsavel: VinculoProfissionalRow
    equipeMedica: VinculoProfissionalRow[]
    open: boolean
    close: () => void
}

export const ModalInformacoesEquipeMedica: React.FC<ModalInformacoesEquipeMedicaProps> = props => {
    const { medicoResponsavel, equipeMedica, open, close } = props

    return (
        <ModalCard title='Equipe médica responsável' active={open} onClose={close} hasCloseButton>
            <label className='title-modal-inf-proced'>Equipe médica</label>
            <Linha className='equipe-medica-linha' />
            <DadosProfissional profissional={medicoResponsavel} isResponsavel={true} />
            {equipeMedica?.map((value: VinculoProfissionalRow) => {
                return <DadosProfissional key={value.id} profissional={value} isResponsavel={false} />
            })}
        </ModalCard>
    )
}
