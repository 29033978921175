import * as React from 'react'
import classnames from 'classnames'

interface Props {
    className?: string
}

export class HorizontalTabMenu extends React.Component<Props> {
    render() {
        const classes: string = classnames('horizontal-tab-menu', this.props.className)

        return <nav className={classes}>{this.props.children}</nav>
    }
}

interface ItemProps {
    hidden?: boolean
    title: string
    onClick?(): void
    active?: boolean
}

export class HorizontalTabMenuItem extends React.Component<ItemProps> {
    render() {
        if (this.props.hidden) {
            return <p></p>
        } else {
            return (
                <p
                    className={classnames('horizontal-tab-item', {
                        'is-active': this.props.active,
                    })}
                    onClick={this.props.onClick}
                >
                    {this.props.title}
                </p>
            )
        }
    }
}
