import React from 'react'
import * as C from 'libs/components'

const motivosInsucesso = [
    { id: 'LESAO_RESIDUAL', nome: 'Lesão residual' },
    { id: 'TROMBOSE_AGUDA', nome: 'Trombose aguda' },
    { id: 'NAO_ULTRAPASSOU', nome: 'Não ultrapassou' },
    { id: 'FALHA_PRODUTO', nome: 'Falha do produto' },
]

export class MotivoInsucessoField extends React.Component {
    render() {
        return <C.FixedSelectField {...this.props} options={motivosInsucesso} />
    }
}
