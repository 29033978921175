import React from 'react'
import { Icon, Label } from 'libs/components'

export const SistemasIntegracaoAtivosAviso = () => {
    return (
        <div className='sistemas-aviso'>
            <Icon icon='notificacaoAlertaComBorda' size='is-20px' className='sistemas-aviso-icon' />
            <div className='sistemas-aviso-labels'>
                <Label className='sistemas-aviso-label'>Apenas 3 sistemas podem estar ativos simultaneamente.</Label>
                <Label className='sistemas-aviso-label'>
                    Para cadastrar ou ativar um sistema, é necessário desativar um sistema ativo.
                </Label>
            </div>
        </div>
    )
}
