import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import immutableProxy from 'libs/immutableProxy'
import { SegmentosLesaoForm } from 'backend'

const adicionarLesaoNativa = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<SegmentosLesaoForm>>(state)
        form = form.withMutations(mutable => {
            const tipoLesao = mutable.values.tipoLesao.get()
            if (!tipoLesao || tipoLesao !== 'REESTENOSE') {
                mutable.values.tipoReestenose.delete()
            }
            if (!tipoLesao || tipoLesao !== 'TROMBOSE') {
                mutable.values.tipoTrombose.delete()
            }
        })
        return form.asMap()
    }
    return state
})

export default adicionarLesaoNativa
