import axios, { AxiosPromise } from 'axios'
import { VinculoProfissionalRow, VinculoProfissionalFiltro, DadosPessoaisForm } from 'backend'

class Profissional {
    getMedicosByFiltro(filtro: VinculoProfissionalFiltro): AxiosPromise<VinculoProfissionalRow> {
        return axios.get('/api/profissional/medicoByFiltro', { params: filtro })
    }

    vinculosUrl(): string {
        return '/api/profissional/vinculos'
    }

    vinculoUrl(): string {
        return '/api/profissional/vinculo'
    }

    findDadosPessoais(): AxiosPromise<DadosPessoaisForm> {
        return axios.get('/api/profissional/findDadosPessoais')
    }

    alterarDadosPessoais(form: DadosPessoaisForm): AxiosPromise<void> {
        return axios.post('/api/profissional/atualizarDadosPessoais', form)
    }
}

export default new Profissional()
