import { tiposProcedimentoByRegiao } from 'api/Procedimento'
import { ProcedimentoAnteriorDto, TipoProcedimento } from 'backend'
import classnames from 'classnames'
import { FormField, IconButton, Popover, RadioField } from 'libs/components'
import * as StringUtil from 'libs/util/StringUtil'
import React, { useRef, useState } from 'react'
import { EdicaoPreFichaState } from 'redux/modules/edicaoProcedimento'

export interface OpcaoProcedimentoProps {
    regiao: string
    lado: string
    procedimento: string
    edicaoPreFicha: EdicaoPreFichaState
    procedimentosAnteriores: ProcedimentoAnteriorDto[]
}
export const OpcaoProcedimento = ({ regiao, lado, procedimento, ...props }: OpcaoProcedimentoProps) => {
    const ref = useRef()
    const [openedPopover, setOpenedPopover] = useState<string>(undefined)
    const procedimentoEdicao = props.edicaoPreFicha
    const procedimentosAnteriores = props.procedimentosAnteriores

    const key = `open${regiao}${lado.toLowerCase()}`
    const procedimentoSelecionado = procedimento && (procedimento.split(',')[0] as TipoProcedimento)
    const ladoSelecionado = procedimento && procedimento.split(',')[1]
    const hasProcedimentoSelected =
        procedimentoSelecionado &&
        ladoSelecionado &&
        lado === ladoSelecionado &&
        tiposProcedimentoByRegiao[regiao].indexOf(procedimentoSelecionado) !== -1

    let classBlock = 'corpo-' + regiao + '-' + lado.toLowerCase() + '-block'
    let classNotBlock = 'corpo-' + regiao + '-' + lado.toLowerCase()
    let procedimentoAnterior: string = null
    let revisaoAnterior: string = null
    let hasProcedimentoAnterior = false
    procedimentosAnteriores &&
        procedimentosAnteriores.forEach(procAnterior => {
            if (procAnterior.lado === lado) {
                if (
                    procedimentoEdicao &&
                    procAnterior.status === 'AGUARDANDO_DADOS_CLINICOS' &&
                    procAnterior.id === procedimentoEdicao.idProcedimento
                ) {
                    return null
                }
                if (
                    (regiao === 'quadril' && procAnterior.tipoProcedimento === 'PRIMARIA_QUADRIL') ||
                    (regiao === 'joelho' && procAnterior.tipoProcedimento === 'PRIMARIA_JOELHO')
                ) {
                    hasProcedimentoAnterior = true
                    procedimentoAnterior = procAnterior.tipoProcedimento
                } else if (
                    (regiao === 'joelho' && procAnterior.tipoProcedimento === 'REVISAO_JOELHO') ||
                    (regiao === 'quadril' && procAnterior.tipoProcedimento === 'REVISAO_QUADRIL')
                ) {
                    hasProcedimentoAnterior = true
                    revisaoAnterior = 'revisao'
                }
            }
            return null
        })

    const classes = classnames({
        [`${classNotBlock}`]: !hasProcedimentoAnterior,
        [`${classBlock}`]: hasProcedimentoAnterior,
        hasProcedimentoSelected: hasProcedimentoSelected,
    })

    const openPopover = () => {
        setOpenedPopover(key)
    }
    const closePopover = () => {
        setOpenedPopover(undefined)
    }

    return (
        <div>
            <div className={classes} onClick={openPopover}>
                <div ref={ref} className='corpo-circulo-interno'></div>
            </div>
            <Popover
                show={openedPopover === key}
                onHide={closePopover}
                placement='bottom'
                rootClose={true}
                target={props => ref}
                className='procedimento-popover'
            >
                <span>
                    <span className='procedimento'>
                        {StringUtil.capitalizeWord(regiao)} {lado.toLowerCase()}
                    </span>
                    <IconButton pulledRight icon='excluir' onClick={closePopover} iconOnly />

                    <hr />
                    {procedimentoAnterior && !revisaoAnterior && (
                        <span className='procedimentoAnterior'>Artroplastia primária registrada</span>
                    )}
                    {revisaoAnterior && procedimentoAnterior && (
                        <span className='procedimentoAnterior'>Artroplastia primária e revisão registradas</span>
                    )}
                    {revisaoAnterior && !procedimentoAnterior && (
                        <span className='procedimentoAnterior'>Revisão registrada</span>
                    )}
                    {!revisaoAnterior && !procedimentoAnterior && (
                        <span className='procedimentoAnterior'>Não há artroplastia prévia registrada</span>
                    )}
                </span>
                <FormField verticalFlow>
                    {tiposProcedimentoByRegiao[regiao].map((value, index) => {
                        return (
                            <RadioField
                                key={index}
                                name='procedimento'
                                label={descricao[value]}
                                value={createValue(value, lado)}
                                disabled={hasProcedimentoAnterior && procedimentoAnterior === value}
                            />
                        )
                    })}
                </FormField>
            </Popover>
        </div>
    )
}

const descricao = {
    PRIMARIA_JOELHO: 'Primária',
    REVISAO_JOELHO: 'Revisão',
    PRIMARIA_QUADRIL: 'Primária',
    REVISAO_QUADRIL: 'Revisão',
}

export const createValue = (tipo_procedimento: string, lado: string) => `${tipo_procedimento},${lado}`
