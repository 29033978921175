import React from 'react'
import * as C from 'libs/components'

export class EnteResponsavel extends React.Component {
    render() {
        return (
            <C.ControlField label={this.props.label} name={this.props.name} verticalFlow required={this.props.required}>
                <C.ClearableRadioField
                    name={this.props.name}
                    label='Operadora de saúde'
                    value='OPERADORA_SAUDE'
                    disabled={this.props.disabledInformacoes}
                />
                <C.ClearableRadioField
                    name={this.props.name}
                    label='Particular'
                    value='PARTICULAR'
                    disabled={this.props.disabledInformacoes}
                />
                <C.ClearableRadioField
                    name={this.props.name}
                    label='SUS'
                    value='SUS'
                    disabled={this.props.disabledInformacoes}
                />
                {this.props.name === 'fornecedor' && (
                    <C.ClearableRadioField
                        name={this.props.name}
                        label='Doação'
                        value='DOACAO'
                        disabled={this.props.disabledInformacoes}
                    />
                )}
            </C.ControlField>
        )
    }
}
