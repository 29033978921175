import React from 'react'
import * as C from 'libs/components'
import field from 'libs/redux-form/field'

class ReCaptchaFieldCmp extends React.Component {
    render() {
        return <C.ReCaptcha {...this.props.input} inputRef={this.props.inputRef} size={this.props.size} />
    }
}

export const ReCaptchaField = field()(ReCaptchaFieldCmp)
