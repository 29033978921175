import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { State } from 'redux/modules'
import { DistribuicaoProcedimentoFiltro } from 'backend'
import * as filtroReport from 'redux/modules/report/filtro'
import * as procedimento from 'redux/modules/report/procedimento'
import * as rastreabilidade from 'redux/modules/report/rastreabilidade'
import { RastreabilidadeReportView, Props } from './RastreabilidadeReportView'

const mapStateToProps = (state: State, ownProps: Props) => ({
    filtro: state.report.filtro.filtro,
    produto: state.report.rastreabilidade.produto,
    distClassificacao: state.report.procedimento.distClassificacao,
    distClassificacaoLocal: state.report.procedimento.distClassificacaoLocal,
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        const baseFilter: DistribuicaoProcedimentoFiltro = {
            registroAnvisa: ownProps.match.params.registroAnvisa,
        }

        dispatch(
            filtroReport.change({
                ...baseFilter,
                ...filtro,
            })
        )
    },
    loadAll: () => {
        dispatch(rastreabilidade.findProduto(ownProps.match.params.registroAnvisa))
        dispatch(procedimento.loadAll())
    },
    clearProdutoOnUnmount: () => {
        dispatch(rastreabilidade.clearFindProduto())
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RastreabilidadeReportView))
