import React from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import Report from 'api/Report'
import { DistribuicaoProcedimentoFiltro, DistribuicaoProcedimentoRow } from 'backend'
import { State } from 'redux/modules'
import {
    Card,
    TableColumnHeader,
    Text,
    PagedTable,
    LoadingContainer,
    LinkIconButton,
    Hint,
    Icon,
} from 'libs/components'
import { capitalizeName } from 'libs/util/StringUtil'
import { Page } from 'redux/middleware/PaginationAction'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { PageRequester } from 'redux/requester'
import { stringify } from 'redux/modules/router'

interface Params {}

export interface Props extends RouteComponentProps<Params> {
    distHospitais: Page<DistribuicaoProcedimentoRow>
    isFetching: boolean
    filtro: DistribuicaoProcedimentoFiltro
    handlePageChange(pageable: any): any
    doSearch(filtro: DistribuicaoProcedimentoFiltro): void
    load(filtro: DistribuicaoProcedimentoFiltro): void
}

export class ReportHospitaisList extends React.Component<Props> {
    componentDidMount() {
        this.props.load(this.props.filtro)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (!isEqual(this.props.filtro, nextProps.filtro)) {
            this.props.load(nextProps.filtro)
        }
    }

    getTitle = () => {
        if (this.props.distHospitais) {
            let hospitais =
                this.props.distHospitais && this.props.distHospitais.totalElements === 1 ? ' hospital' : ' hospitais'
            return `${this.props.distHospitais.totalElements} ${hospitais}`
        }
        return '0 hospitais'
    }

    renderDownloadIcon() {
        return (
            <Link
                to={{
                    pathname: '/api/report/distribuicaoHospitais/csv',
                    search: stringify(this.props.filtro),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }

    render() {
        return (
            <div className='report-content'>
                <Card title={this.getTitle()} noPadding headerAction={this.renderDownloadIcon()}>
                    <LoadingContainer isFetching={this.props.isFetching}>
                        {this.props.distHospitais && this.props.distHospitais.content && (
                            <PagedTable
                                page={this.props.distHospitais}
                                values={null}
                                className='is-spaced is-small-bis'
                                onChangePage={this.props.handlePageChange}
                                emptyText={'Nenhum hospital encontrado'}
                            >
                                <TableColumnHeader
                                    title='Hospital'
                                    style={{ width: '50%' }}
                                    render={(item: DistribuicaoProcedimentoRow) => {
                                        return <Text>{capitalizeName(item.local.hospital.razaoSocial)}</Text>
                                    }}
                                />
                                <TableColumnHeader
                                    title='Localização'
                                    style={{ width: '35%' }}
                                    render={(item: DistribuicaoProcedimentoRow) => {
                                        return (
                                            <Text>{capitalizeName(item.local.cidade.nome) + '/' + item.local.uf}</Text>
                                        )
                                    }}
                                />
                                <TableColumnHeader
                                    title='Procedimentos'
                                    render={(item: DistribuicaoProcedimentoRow) => {
                                        return <Text>{item.total}</Text>
                                    }}
                                />
                                <TableColumnHeader
                                    title=''
                                    className='has-text-right row-link'
                                    style={{ width: '20px' }}
                                    render={(item: DistribuicaoProcedimentoRow) => {
                                        return (
                                            <Hint
                                                placement='top'
                                                componente={
                                                    <LinkIconButton
                                                        to={{
                                                            pathname: '/home/relatorios/procedimentos/hospitais',
                                                            search: `idHospital=${item.local.hospital.id}`,
                                                        }}
                                                        icon='arrowRight'
                                                    ></LinkIconButton>
                                                }
                                            >
                                                Visualizar procedimentos
                                            </Hint>
                                        )
                                    }}
                                />
                            </PagedTable>
                        )}
                    </LoadingContainer>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state: State) => ({
    distHospitais: paginator.getResult(state),
    isFetching: paginator.getIsFetching(state),
    filtro: state.report.filtro.filtro,
})

const paginator = new PageRequester<DistribuicaoProcedimentoRow, DistribuicaoProcedimentoFiltro>(
    'procedimentos',
    (filtro, config) => {
        return Report.getDistribuicaoProcedimentosHospitaisPage(filtro, config)
    }
)

const mapDispatchToProps = (dispatch, ownProps: RouteComponentProps<Params>) => ({
    load: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(
            paginator.setParams({
                ...filtro,
            })
        )
        dispatch(paginator.request())
    },
    handlePageChange: (page: number) => {
        dispatch(paginator.setPageNumber(page))
        dispatch(paginator.request())
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportHospitaisList))
