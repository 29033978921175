
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.605,"time":36300,"words":121}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`O RNI permite que seja feita a correção/edição de dados no registro do procedimento, realizado pelo `}<strong parentName="p">{`Técnico`}</strong>{`.`}</p>
    <p><strong parentName="p">{`Regras:`}</strong></p>
    <ol>
      <li parentName="ol">{`Os dados clínicos não podem ter sido preenchidos.`}</li>
      <li parentName="ol">{`Qualquer profissional com perfil de `}<strong parentName="li">{`Técnico`}</strong>{` ou o profissional participante da equipe médica, poderão realizar a edição dos dados.`}</li>
      <li parentName="ol">{`O profissional que está realizando a edição não pode se excluir do procedimento.`}</li>
    </ol>
    <p><strong parentName="p">{`Fluxo:`}</strong></p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Aguardando dados clínicos”`}</li>
      <li parentName="ol">{`No procedimento que deseja editar, clique no ícone `}<img parentName="li" {...{
          "src": "/images/ic-editar-21-px.png#center",
          "alt": "Editar"
        }}></img></li>
      <li parentName="ol">{`Edite os dados que precisam de edição. Nesta etapa é possível editar o paciente, a equipe médica, o médico responsável ou até mesmo o procedimento cadastrado`}</li>
      <li parentName="ol">{`Após o preenchimento dos campos, clique no botão “Salvar”.`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

