import React from 'react'
import * as C from 'libs/components'
import { AnoFabricacao } from 'view/home/rastreabilidade/componentes/AnoFabricacao'
import MesFabricacao from 'view/home/rastreabilidade/componentes/MesFabricacao'
import DiaFabricacao from 'view/home/rastreabilidade/componentes/DiaFabricacao'
import isUndefined from 'lodash/isUndefined'
import Immutable, { isImmutable } from 'immutable'

export default class DataFabricacao extends React.Component {
    handleChangeAnoFabricacao(e) {
        let value = this.getCurrentValue()
        value = value.set('ano', e)
        value = value.set('mes', undefined)
        value = value.set('dia', undefined)
        this.props.input.onChange(value)
    }

    handleChangeMesFabricacao(e) {
        let value = this.getCurrentValue()
        value = value.set('mes', e)
        value = value.set('dia', undefined)
        this.props.input.onChange(value)
    }

    handleChangeDiaFabricacao(e) {
        let value = this.getCurrentValue()
        value = value.set('dia', e)
        this.props.input.onChange(value)
    }

    handleBlurAnoFabricacao(e) {
        let value = this.getCurrentValue()
        if (value.get('ano')) {
            value = value.set('ano', e)
            this.props.input.onBlur(value)
        } else {
            this.props.input.onBlur(undefined)
        }
    }

    handleBlurMesFabricacao(e) {
        let value = this.getCurrentValue()
        value = value.set('mes', e)
        this.props.input.onBlur(value)
    }

    handleBlurDiaFabricacao(e) {
        let value = this.getCurrentValue()
        value = value.set('dia', e)
        this.props.input.onBlur(value)
    }

    getCurrentValue() {
        const { value } = this.props.input

        if (isUndefined(value) || value === '') {
            return new Immutable.Map({
                ano: undefined,
                mes: undefined,
                dia: undefined,
            })
        }

        return value
    }

    render() {
        const value = isImmutable(this.getCurrentValue()) ? this.getCurrentValue().toJS() : this.getCurrentValue()
        const errors = isImmutable(this.props.meta.error) ? this.props.meta.error.toJS() : this.props.meta.error
        const errorMesFabricacao = this.props.meta.touched && (errors?.mes || errors?.dataFabricacao)

        return (
            <div>
                <C.FormField error={errorMesFabricacao} name={this.props.input.name} label='Data de fabricação' />
                <C.Columns>
                    <C.Column size='3'>
                        <AnoFabricacao
                            name='ano'
                            placeholder='Ano'
                            onChange={this.handleChangeAnoFabricacao.bind(this)}
                            onBlur={this.handleBlurAnoFabricacao.bind(this)}
                            value={value?.ano}
                            anoBase={this.props.anoCirurgia}
                        />
                    </C.Column>
                    <C.Column size='4'>
                        <MesFabricacao
                            name='mes'
                            placeholder='Mês'
                            error={errorMesFabricacao}
                            onChange={this.handleChangeMesFabricacao.bind(this)}
                            onBlur={this.handleBlurMesFabricacao.bind(this)}
                            value={value?.mes}
                            ano={value?.ano?.id}
                            isDisabled={!value?.ano || value?.ano === ''}
                        />
                    </C.Column>
                    <C.Column size='3'>
                        <DiaFabricacao
                            maxDia={true}
                            name='dia'
                            placeholder='Dia'
                            onChange={this.handleChangeDiaFabricacao.bind(this)}
                            onBlur={this.handleBlurDiaFabricacao.bind(this)}
                            value={value.dia}
                            ano={value?.ano?.id}
                            mes={value?.mes?.id}
                            isDisabled={!value.mes || value.mes === ''}
                        />
                    </C.Column>
                </C.Columns>
            </div>
        )
    }
}
