import React, { useState, useRef } from 'react'
import iconBarCode from 'images/icons/barcode.svg'
import { InputPanel, Image, Conteudo, Columns, Column, Button, Label } from 'libs/components'
import { LinhaDigitavel } from 'view/home/rastreabilidade/componentes'

export interface CodigoBarraProps {
    onChangeLinhaDigitavel(e): void
}
export const CodigoBarra = ({ onChangeLinhaDigitavel }: CodigoBarraProps) => {
    const input = useRef<any>(null)
    const [open, setOpen] = useState<boolean>()
    const handleOpen = () => {
        setOpen(true)
        setTimeout(() => input.current && input.current.focus()) // EventLoop
    }
    return (
        <InputPanel size='medium'>
            <Conteudo>
                <Columns>
                    <Column size='2'>
                        <Image src={iconBarCode} />
                    </Column>
                    <Column alignedCenter>
                        {!open && (
                            <>
                                <Label>Deseja utilizar o leitor de código de barras?</Label>
                                <Button type='primary' onClick={handleOpen}>
                                    Ler código de barras
                                </Button>
                            </>
                        )}
                        {open && (
                            <LinhaDigitavel
                                inputRef={input}
                                label='Utilize o código de barras para os campos a seguir'
                                name='dadosEtiqueta'
                                placeholder='Linha digitável'
                                horizontalFlow
                                onChangeLinhaDigitavel={onChangeLinhaDigitavel}
                            />
                        )}
                    </Column>
                </Columns>
            </Conteudo>
        </InputPanel>
    )
}
