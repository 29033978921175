import { DateInterval, UsoSistemaFiltro } from 'backend'
import { Column, Columns, PeriodoFilter, ScrollToTop } from 'libs/components'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'redux/modules'
import * as filtroReport from 'redux/modules/report/filtro'
import * as usoSistema from 'redux/modules/report/usoSistema'
import { FinalizadosXNaoFinalizadosCard } from 'view/componentes/reports/cards/FinalizadosXNaoFinalizadosCard'
import { NaoFinalizadosCard } from 'view/componentes/reports/cards/NaoFinalizadosCard'
import { RegistrosFinalizadosChartCard } from 'view/componentes/reports/cards/RegistrosFinalizadosChartCard'
import { TempoMedioChartCard } from 'view/componentes/reports/cards/TempoMedioChartCard'
import { TotalProfissionaisCard } from 'view/componentes/reports/cards/TotalProfissionaisCard'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import UrlParams from 'libs/router/UrlParams'
import { HomeGestorServicoSaudeHeader } from '../HomeGestorServicoSaudeHeader'
import BackButton from 'libs/components/presentational/elements/BackButton'

export function GestorServicoSaudeUsoSistemaDashboard() {
    const dispatch = useDispatch()

    const reducer = useSelector((state: State) => state.report.usoSistema)
    const filtro = useSelector((state: State) => state.report.filtro.filtro)

    React.useEffect(() => {
        dispatch(usoSistema.getDadosUsoSistemaHospital(filtro))
        return () => dispatch(usoSistema.clearAll())
    }, [dispatch, filtro])

    const handlePeriodoChange = (interval: DateInterval) => {
        dispatch(filtroReport.change({ ...filtro, interval: interval }))
    }

    const refresh = (filter: UsoSistemaFiltro, filterChanged: boolean) => {
        if (!filterChanged && filter.interval) {
            handlePeriodoChange(filter.interval)
        }
    }

    const { local, ...publishFilter } = filtro

    return (
        <div>
            <ScrollToTop onMount />

            <HomeGestorServicoSaudeHeader />
            <ReportHeader title='Uso do sistema'>
                <div>
                    <BackButton />
                </div>
            </ReportHeader>

            <UrlParams params={publishFilter} onChange={refresh} />
            <div className='report-content'>
                <Columns className='report-tabs align-center'>
                    <Column size='6'>
                        <div className='report-tabs-links is-black'>Dashboard</div>
                    </Column>
                    <Column size='6' textRight>
                        <PeriodoFilter value={filtro?.interval} onChange={handlePeriodoChange} />
                    </Column>
                </Columns>
                <Columns auto>
                    <TotalProfissionaisCard
                        readyState={reducer.profissionais.readyState}
                        report={reducer.profissionais.data}
                    />
                </Columns>

                <Columns auto>
                    <TempoMedioChartCard dist={reducer.distTempoMedio} readyState={reducer.distTempoMedio.readyState} />
                </Columns>
                <Columns>
                    <Column size='5'>
                        <FinalizadosXNaoFinalizadosCard
                            finalizados={reducer.statusGeral.data?.finalizados}
                            naoFinal={reducer.statusGeral.data?.naoFinalizados}
                            readyState={reducer.statusGeral.readyState}
                        />
                    </Column>
                    <Column>
                        <NaoFinalizadosCard
                            naoFinalizados={reducer.statusGeral.data?.naoFinalizados}
                            countStatus={reducer.statusGeral.data?.countStatus}
                            readyState={reducer.statusGeral.readyState}
                        />
                    </Column>
                </Columns>
                <Columns auto>
                    <RegistrosFinalizadosChartCard
                        dist={reducer.distFinalizadas}
                        readyState={reducer.distFinalizadas.readyState}
                    />
                </Columns>
            </div>
        </div>
    )
}
