import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { IconButton } from '../elements/IconButton'
import CSSTransition from 'react-transition-group/CSSTransition'

interface CarouselProps {
    onIndexUpdated?(currentIndex: number): void
    className?: string
    children: any[]
}

export const Carousel = (props: CarouselProps) => {
    const classes = classnames('carousel', props.className)
    const [current, setCurrent] = useState(0)

    const handleRightClick = () => {
        const nextItem = current + 1 > props.children.length - 1 ? 0 : current + 1
        setCurrent(nextItem)
        props.onIndexUpdated && props.onIndexUpdated(nextItem)
    }

    const handleLeftClick = () => {
        const nextItem = current - 1 < 0 ? props.children.length - 1 : current - 1
        setCurrent(nextItem)
        props.onIndexUpdated && props.onIndexUpdated(nextItem)
    }

    const handleKeyEvent = e => {
        if (e.key === 'ArrowRight') {
            handleRightClick()
        } else if (e.key === 'ArrowLeft') {
            handleLeftClick()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyEvent)
        return () => {
            window.removeEventListener('keydown', handleKeyEvent)
        }
    })

    return (
        <div className={classes}>
            <IconButton icon='arrowLeft' onClick={handleLeftClick} />
            <CarouselContent current={current} children={props.children} />
            <IconButton icon='arrowRight' onClick={handleRightClick} />
        </div>
    )
}

interface CarouselContentProps {
    current: number
    children: any[]
}

const CarouselContent = ({ current, children }: CarouselContentProps) => {
    const [localCurrent, setLocalCurrent] = useState(null)

    useEffect(() => {
        if (current !== localCurrent) {
            setLocalCurrent(current)
        }
    }, [current, localCurrent])

    return (
        <div className='carousel-content'>
            <div className='flex-1 carousel-current-item'>
                {localCurrent !== null && (
                    <CSSTransition classNames='carousel-item' timeout={750} in={localCurrent === current}>
                        {children[localCurrent]}
                    </CSSTransition>
                )}
            </div>
            <div className='is-children-center justify-center'>
                {children.map((value, index) => (
                    <svg key={`carousel-index-${index}`} width={16} height={16}>
                        <circle
                            cx={8}
                            cy={8}
                            r={4}
                            className={index === current ? 'blue-neon-circle' : 'grey-circle'}
                        />
                    </svg>
                ))}
            </div>
        </div>
    )
}
