import { Column, Columns, ScrollToTop } from 'libs/components'
import isEqual from 'lodash/isEqual'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import { State } from 'redux/modules'
import { stringify } from 'redux/modules/router'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import BackButton from 'libs/components/presentational/elements/BackButton'

import MultiplosProcedimentosMap from 'view/home/anvisa/reports/multiplos/MultiplosProcedimentosMap'
import { useReportFilterUrlPublisher } from 'view/home/anvisa/reports/hooks/useReportUrlParams'
import { MultiplosProcedimentosDashboard } from './MultiplosProcedimentosDashboard'
import LocalHeaderContainerAbrangencia from 'view/home/anvisa/reports/components/LocalHeaderContainerAbrangencia'

export interface MultiplosProcedimentosRouteParams {
    tipoCirurgia: string
}

export function MultiplosProcedimentosView() {
    const history = useHistory()
    const match = useRouteMatch<MultiplosProcedimentosRouteParams>()
    const location = useLocation()

    const filtro = useSelector((state: State) => state.report.filtro.filtro, isEqual)

    useReportFilterUrlPublisher()

    const handleTabItemClick = tipoCirurgia => {
        history.push({ pathname: `/home/relatorios/multiplos/${tipoCirurgia}` })
    }
    const renderMap = (filtro.local && !filtro.local.hospitalId) || !filtro.local
    return (
        <div>
            <ScrollToTop onMount />
            <ReportClearFilter />

            <LocalHeaderContainerAbrangencia />
            <ReportHeader title='Múltiplos procedimentos'>
                <div>
                    <BackButton />
                </div>

                <HorizontalTabMenu>
                    <HorizontalTabMenuItem
                        title='Joelho'
                        onClick={() => handleTabItemClick('JOELHO')}
                        active={'JOELHO' === match.params.tipoCirurgia}
                    />
                    <HorizontalTabMenuItem
                        title='Quadril'
                        onClick={() => handleTabItemClick('QUADRIL')}
                        active={'QUADRIL' === match.params.tipoCirurgia}
                    />
                </HorizontalTabMenu>
            </ReportHeader>

            <div className='report-content'>
                <Columns className='report-tabs align-center'>
                    <Column size='6'>
                        <div className='report-tabs-links'>
                            <NavLink
                                to={{
                                    pathname: `${match.url}/dashboard`,
                                    search: location.search,
                                }}
                                activeClassName='is-active'
                            >
                                Dashboard
                            </NavLink>
                            {renderMap && (
                                <NavLink
                                    to={{
                                        pathname: `${match.url}/map`,
                                        search: location.search,
                                    }}
                                    activeClassName='is-active'
                                >
                                    Mapa de calor
                                </NavLink>
                            )}
                        </div>
                    </Column>
                </Columns>

                <Switch>
                    <Route
                        exact
                        path={'/home/relatorios/multiplos/:tipoCirurgia/dashboard'}
                        component={MultiplosProcedimentosDashboard}
                    />
                    {renderMap && (
                        <Route
                            exact
                            path={'/home/relatorios/multiplos/:tipoCirurgia/map'}
                            component={MultiplosProcedimentosMap}
                        />
                    )}
                    <Redirect
                        to={{
                            pathname: `/home/relatorios/multiplos/${match.params.tipoCirurgia}/dashboard`,
                            search: stringify(filtro),
                        }}
                    />
                </Switch>
            </div>
        </div>
    )
}
