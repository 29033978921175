import adicionarLesaoEnxerto from './adicionarLesaoEnxerto'
import adicionarLesaoNativa from './adicionarLesaoNativa'
import adicionarStent from './adicionarStent'
import adicionarEntidade from './adicionarEntidade'
import alterarDadosPessoais from './alterarDadosPessoais'
import buscaProcedimento from './buscaProcedimento'
import cadastroProfissional from './cadastroProfissional'
import dadosFinanceirosForm from './dadosFinanceirosForm'
import dadosProdutoForm from './dadosProdutoForm'
import primariaQuadril from './primariaQuadril'
import primariaJoelho from './primariaJoelho'
import rastreabilidadeForm from './rastreabilidadeForm'
import revisaoQuadril from './revisaoQuadril'
import revisaoJoelho from './revisaoJoelho'
import solicitacaoVinculoForm from './solicitacaoVinculoForm'
import stentCoronaria from './stentCoronaria'
import { Map } from 'immutable'

export type FormState<T> = {
    values: T
    fields: Fields<T>
}

export type Fields<T> = {
    [P in keyof T]?: Fields<T[P]>
}

export function createFormReducer(reducer: (state: Map<string, any>, action) => Map<string, any>) {
    return (state: Map<string, any>, action) => {
        if (state) {
            return reducer(state, action)
        }
        return state
    }
}

const forms = {
    adicionarLesaoEnxerto,
    adicionarLesaoNativa,
    adicionarStent,
    adicionarEntidade,
    alterarDadosPessoais,
    buscaProcedimento,
    cadastroProfissional,
    dadosFinanceirosForm,
    dadosProdutoForm,
    primariaQuadril,
    primariaJoelho,
    rastreabilidadeForm,
    revisaoQuadril,
    revisaoJoelho,
    solicitacaoVinculoForm,
    stentCoronaria,
}

export default forms
