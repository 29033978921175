import { connect } from 'react-redux'
import { getFormValues } from 'redux-form/immutable'
import { initialize } from 'redux-form'
import Procedimento from 'api/Procedimento'
import FichaStentCoronariaEssencialView from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaEssencialView'
import { RouteComponentProps } from 'react-router-dom'

interface FichaStentCoronariaEssencialProps extends RouteComponentProps<any> {
    editMode?: boolean
}

const mapStateToProps = (state, ownProps: FichaStentCoronariaEssencialProps) => {
    if (ownProps?.editMode) {
        return {
            paciente: state.paciente.form,
            procedimento: 'stentCoronaria',
            vinculoProfissionalMedicoResponsavel: state.edicao?.medico,
            equipeMedica: state.edicao?.equipeMedica,
        }
    } else {
        return {
            paciente: state.novoProcedimento.paciente,
            procedimento: state.novoProcedimento.procedimento,
            formInitialValues: getFormValues('stentCoronaria')(state),
        }
    }
}
const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { paciente } = stateProps
    const { dispatch } = dispatchProps

    return {
        ...ownProps,
        ...stateProps,
        onSubmit: form => {
            const mutableForm = form.toJS()
            mutableForm.essencial.pacienteId = paciente.id
            return Procedimento.validateStentCoronaria(mutableForm)
        },
        onSubmitSuccess: () => {
            ownProps.history.replace('complementar')
            if (!ownProps.editMode) {
                dispatch(initialize('stentCoronaria', {}, { keepDirty: true, keepSubmitSucceeded: false }))
            }
        },
        goHome: () => {
            ownProps.history.push('/home')
        },
    }
}

const FichaStentCoronariaEssencialContainer = connect(
    mapStateToProps,
    null,
    mergeProps
)(FichaStentCoronariaEssencialView)

export default FichaStentCoronariaEssencialContainer
