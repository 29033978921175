import { PerfilEnum } from 'api/Perfis'
import { Perfil, UserDTO } from 'backend'
import { MultiplosProcedimentosFiltro } from 'model'

const AbrangenciaSNVS = (filtro: MultiplosProcedimentosFiltro, user: UserDTO) => {
    const userPerfil: Perfil = user.perfil
    if (filtro !== undefined && filtro.local !== undefined && userPerfil === PerfilEnum.SNVS) {
        const { search } = window.location
        if (user.instituicao.abrangencia === 'ESTADUAL' && (!search || search.includes('uf'))) {
            filtro.local = filtro.local || {}
            filtro.local.ufPaciente = user.instituicao.uf
        } else if (user.instituicao.abrangencia === 'MUNICIPAL' && (!search || search.includes('cidade'))) {
            filtro.local = filtro.local || {}
            filtro.local.cidadeIdPaciente = user.instituicao.idCidade
        }
    }
}

export default AbrangenciaSNVS
