/* tslint:disable */
import React from 'react'

const SvgDesbloquear = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M12 3.2c2.484 0 4.516 1.826 4.655 4.113h-1.14c-.137-1.7-1.665-3.038-3.515-3.038-1.92 0-3.485 1.505-3.485 3.361v2.539h8.807c.541 0 .978.42.978.931v8.663c0 .51-.437.931-.978.931H6.678c-.541 0-.978-.42-.978-.931v-8.663c0-.511.437-.931.978-.931h.7V7.636C7.378 5.193 9.45 3.2 12 3.2zM6.837 19.625h10.326V11.25H6.837v8.375zm4.387-2.469c-.084 0-.143-.067-.131-.136l.335-1.934-.004-.105-.112-.429c-.11-.421.225-.833.688-.833.463 0 .797.412.688.832l-.112.43-.004.105.335 1.934c.012.069-.047.136-.13.136h-1.553z' />
    </svg>
)

export default SvgDesbloquear
