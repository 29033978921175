import React from 'react'
import * as C from 'libs/components'
import form from 'libs/redux-form/form'
import trocarSenha from 'images/home/alterarSenha/trocarsenha.png'
import AlterarSenhaValidator from 'view/home/alterarSenha/AlterarSenhaValidator'

let Form = props => {
    const { handleSubmit, submitting, cancelar } = props

    return (
        <form onSubmit={handleSubmit}>
            <C.PageTitle title='ALTERAR SENHA' />
            <hr />
            <C.PasswordField name='senhaAtual' placeholder='Senha atual' label='Senha atual' required />
            <C.PasswordField name='novaSenha' placeholder='Nova senha' label='Nova senha' required />
            <C.PasswordField
                name='confirmarNovaSenha'
                placeholder='Confirmar nova senha'
                label='Confirmar nova senha'
                required
                onPaste={e => e.preventDefault()}
                onDrop={e => e.preventDefault()}
            />
            <br />
            <br />
            <C.ButtonBar>
                <C.Button size='medium' disabled={submitting} onClick={cancelar}>
                    Cancelar
                </C.Button>
                <C.Button type='success' size='medium' loading={submitting} onClick={handleSubmit}>
                    Confirmar
                </C.Button>
            </C.ButtonBar>
        </form>
    )
}

Form = form({
    form: 'alterarSenha',
    successTitle: 'Senha Alterada!',
    successContent: <span>A sua senha foi alterada com sucesso.</span>,
    validate: AlterarSenhaValidator,
})(Form)

export default class AlterarSenhaView extends React.Component {
    render() {
        return (
            <C.Columns className='alterar-senha'>
                <C.Column offset='2' size='4'>
                    <div>
                        <div className='is-vertical-flow'>
                            <C.Image className='imagem-trocar-senha' size='180x180' src={trocarSenha} />
                        </div>
                        <div className='alterar-senha-info'>
                            <C.Label>Sua nova senha deve:</C.Label>
                            <ul>
                                <li>Ser diferente da atual.</li>
                                <li>Utilizar somente letras e números.</li>
                                <li>Ter no mínimo 08 caracteres.</li>
                                <li>Ter ao menos uma letra e um número.</li>
                            </ul>
                        </div>
                    </div>
                </C.Column>
                <C.Column size='4'>
                    <Form
                        onSubmit={this.props.save}
                        onSubmitSuccess={this.props.afterSave}
                        cancelar={this.props.cancelar}
                    />
                </C.Column>
            </C.Columns>
        )
    }
}
