import { PacienteSeletor } from 'backend'
import { useAxiosGet } from 'hooks/useApiRequest'
import { LoadingStateContainer, Step, Steps } from 'libs/components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { EdicaoStentCoronariaState, setEdicaoProcedimentoData } from 'redux/modules/edicaoProcedimento'
import { actionCreators as pacienteActionCreators } from 'redux/modules/paciente'
import { FormView } from 'view/componentes/FormView'
import FichaStentCoronariaComplementarSidebar from '../novoProcedimento/ficha/cardio/FichaStentCoronariaComplementarSidebar'
import FichaStentCoronariaContainer from '../novoProcedimento/ficha/cardio/FichaStentCoronariaContainer'
import FichaStentCoronariaEssencialSidebar from '../novoProcedimento/ficha/cardio/FichaStentCoronariaEssencialSidebar'
import * as edicaoProcedimento from 'redux/modules/edicaoProcedimento'
import { InformacoesEquipeMedica } from 'view/home/novoProcedimento/procedimentos/components/InformacoesEquipeMedica'
import { State } from 'redux/modules'
import { ModalInformacoesEquipeMedica } from '../novoProcedimento/procedimentos/components/ModalInformacoesEquipeMedica'

export const EdicaoStentCoronaria = (props: RouteComponentProps<{ idFicha: string }>) => {
    const idFicha = props.match.params.idFicha
    const medicoResponsavel = useSelector((state: State) => state.edicao?.medico)
    const equipeMedica = useSelector((state: State) => state.edicao?.equipeMedica)
    const [openModal, setOpenModal] = useState(false)
    const { data, requestState } = useAxiosGet<EdicaoStentCoronariaState>(
        '/api/procedimentos/aguardandoDadosProdutoStentCoronariaById/' + idFicha
    )
    const dispatch = useDispatch()
    useEffect(() => {
        if (requestState === 'success') {
            dispatch(setEdicaoProcedimentoData(data))
            dispatch(pacienteActionCreators.find({ id: data.essencial.pacienteId } as PacienteSeletor))
            dispatch(edicaoProcedimento.getEquipeMedicaEdicao(parseInt(idFicha)))
        }
    }, [requestState, dispatch, data, idFicha])

    const open = () => setOpenModal(true)

    const close = () => setOpenModal(false)

    return (
        <LoadingStateContainer readyState={requestState}>
            <ModalInformacoesEquipeMedica
                medicoResponsavel={medicoResponsavel}
                equipeMedica={equipeMedica}
                open={openModal}
                close={close}
            />
            <FormView
                sidebarContent={
                    <>
                        <InformacoesEquipeMedica medicoResponsavel={medicoResponsavel} open={open} />
                        <Steps>
                            <Step name='DEFINIR PACIENTE' path={`${props.match.url}/definirPaciente`} />
                            <Step name='DADOS ESSENCIAIS' path={`${props.match.url}/essencial`}>
                                <FichaStentCoronariaEssencialSidebar />
                            </Step>
                            <Step name='DADOS COMPLEMENTARES' path={`${props.match.url}/complementar`}>
                                <FichaStentCoronariaComplementarSidebar />
                            </Step>
                        </Steps>
                    </>
                }
                mainContent={<FichaStentCoronariaContainer {...props} editMode />}
            />
        </LoadingStateContainer>
    )
}
