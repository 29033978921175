import { useContext, useCallback } from 'react'
import { PesquisaSatisfacaoCtx } from './PesquisaSatisfacaoContext'
import { useUltimaPesquisaSatisfacao } from 'api/PesquisaSatisfacao'
import { useSelector } from 'react-redux'
import { State } from 'redux/modules'
import moment, { Moment } from 'moment'

export const usePesquisaSatisfacaoPopup = () => {
    const [requestUltimaPesquisa, { called }] = useUltimaPesquisaSatisfacao()
    const dataCadastro = useSelector<State, Moment>(
        ({ auth }) => auth.user?.dataCadastro && moment(auth.user?.dataCadastro)
    )
    const { setIsPesquisaVisible, isPesquisaVisible } = useContext(PesquisaSatisfacaoCtx)
    const showPesquisa = useCallback(() => {
        requestUltimaPesquisa().then(ultimaPesquisa => {
            const showPopup =
                dataCadastro?.isBefore(moment().subtract(15, 'day')) &&
                (!ultimaPesquisa || moment(ultimaPesquisa?.dataPesquisa).isBefore(moment().subtract(2, 'month')))
            if (showPopup) {
                setIsPesquisaVisible(true)
            }
        })
    }, [requestUltimaPesquisa, dataCadastro, setIsPesquisaVisible])
    return {
        isPesquisaVisible,
        called,
        showPesquisa,
        hidePesquisa: () => setIsPesquisaVisible(false),
    }
}
