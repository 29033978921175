/* tslint:disable */
import React from 'react'

const SvgAlarme = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M15.534 18.57c-.327 1.681-1.7 2.93-3.333 2.93-1.63 0-3-1.243-3.33-2.917-1.561-.16-2.747-.45-3.566-.88a.962.962 0 01-.247-1.822c.574-.263.996-.994 1.25-2.039a8.365 8.365 0 00.232-1.735V9.853c0-2.22 1.26-4.21 3.184-5.161C9.887 3.457 10.934 2.5 12.2 2.5s2.313.957 2.476 2.192a5.754 5.754 0 013.184 5.16V12.1c.006.416.07 1.067.233 1.742.253 1.045.675 1.776 1.249 2.04a.962.962 0 01-.262 1.824c-.887.415-2.067.701-3.546.864zm1.587-4.492a9.318 9.318 0 01-.261-1.97V9.852a4.749 4.749 0 00-2.858-4.371l-.302-.13-.002-.328C13.695 4.182 13.022 3.5 12.2 3.5s-1.495.682-1.498 1.524l-.001.328-.302.13a4.749 4.749 0 00-2.859 4.37v2.262a9.315 9.315 0 01-.26 1.963c-.304 1.253-.83 2.197-1.676 2.65l.093.051c1.12.625 3.3.953 6.503.953 3.042 0 5.224-.329 6.527-.965l.075-.036c-.85-.452-1.377-1.397-1.681-2.652zM12.2 20.5c1.003 0 1.885-.698 2.24-1.71-.662.04-1.37.06-2.125.06-.85 0-1.635-.023-2.359-.07.353 1.018 1.238 1.72 2.244 1.72z' />
    </svg>
)

export default SvgAlarme
