import * as React from 'react'
import { IconButton } from 'libs/components'

interface State {
    visible: boolean
}

export class ReportInfoBlock extends React.Component<any, State> {
    constructor(props) {
        super(props)
        this.state = { visible: true }
    }

    handleCloseClick = () => {
        this.setState({ visible: false })
    }

    render() {
        return (
            <p className='report-info-block' hidden={!this.state.visible}>
                <IconButton icon='excluir' className='close' onClick={this.handleCloseClick} />
                {this.props.children}
            </p>
        )
    }
}
