import { useRouter } from 'hooks/useRouter'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'redux/modules'
import { UsoSistemaRouteParams } from '../usoSistema/UsoSistemaReportView'

export function useNavigateUsoSistema() {
    const url = '/home/relatorios/usoSistema'
    const { history } = useRouter<UsoSistemaRouteParams>()
    const local = useSelector((state: State) => state.report.filtro.local)
    useEffect(() => {
        if (local?.data != null) {
            const { regiao, uf, cidade, hospital } = local.data
            if (regiao == null) {
                history.push({ pathname: `${url}`, search: `${history.location.search}` })
            } else if (uf == null) {
                history.push({ pathname: `${url}/${regiao}`, search: `${history.location.search}` })
            } else if (cidade == null) {
                history.push({ pathname: `${url}/${regiao}/${uf}`, search: `${history.location.search}` })
            } else if (hospital == null) {
                history.push({ pathname: `${url}/${regiao}/${uf}/${cidade.id}`, search: `${history.location.search}` })
            } else {
                history.push({
                    pathname: `${url}/${regiao}/${uf}/${cidade.id}/${hospital.id}`,
                    search: `${history.location.search}`,
                })
            }
        }
    }, [history, local])
}
