import React from 'react'
import { connect } from 'react-redux'
import * as filtroReport from 'redux/modules/report/filtro'

export interface Props {
    clearFilter(): void
}

class ReportClearFilterCmp extends React.Component<Props> {
    componentWillUnmount() {
        this.props.clearFilter()
    }

    render() {
        return null
    }
}

export const mapDispatchToProps = dispatch => ({
    clearFilter: () => dispatch(filtroReport.change({})),
})

export const ReportClearFilter = connect(null, mapDispatchToProps)(ReportClearFilterCmp)
