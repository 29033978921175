import { createSelector } from 'reselect'
import Perfil from 'redux/model/Perfil'
import { State } from 'redux/modules'
import { UserDTO, SelecaoPerfilDto } from 'backend'
import { PromiseRecord } from 'redux/middleware/PromiseAction'

const getUser = (state: State) => state.auth.user
const getPerfis = (state: State) => state.auth.perfis

export const getUserPerfil = createSelector(getUser, (user: UserDTO) => {
    return new Perfil(user && user.perfil)
})

export const isFetchingPerfis = createSelector(getPerfis, (perfis: PromiseRecord<SelecaoPerfilDto[]>) => {
    return perfis.readyState === 'request'
})

export const getPerfisAtivos = createSelector(getPerfis, (perfis: PromiseRecord<SelecaoPerfilDto[]>) => {
    if (perfis.data) {
        return perfis.data.filter(perfil => {
            return perfil.ativo
        })
    }
    return null
})

export const getPerfisInativos = createSelector(getPerfis, (perfis: PromiseRecord<SelecaoPerfilDto[]>) => {
    if (perfis.data) {
        return perfis.data.filter(perfil => {
            return !perfil.ativo
        })
    }
    return null
})

export const getLoggedUser = createSelector(getUser, (user: UserDTO) => {
    return user
})
