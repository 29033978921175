import { PerfilEnum, PerfisCardiologistas } from 'api/Perfis'
import { Perfil as PerfilType } from 'backend'

export default class Perfil {
    private perfil: PerfilType

    constructor(perfil: string) {
        this.perfil = PerfilEnum[perfil]
    }

    public isTecnico(): boolean {
        return this.perfil === PerfilEnum.TECNICO
    }

    public isCardiologista(): boolean {
        return PerfisCardiologistas.includes(this.perfil)
    }
}
