import * as React from 'react'
import { ChecklistItemField } from 'libs/components'
import { Columns, Column, ControlField } from 'libs/components'
import AlertaConfigModalForm, { PublicAlertaConfigModalFormProps } from '../components/AlertaConfigModalForm'
import MotivoRevisaoModalFormValidator from './MotivoRevisaoModalFormValidator'

const formName = 'configuracaoAlertaMotivoRevisaoQuadril'

interface MotivoRevisaoQuadrilModalFormProps extends PublicAlertaConfigModalFormProps {}

export default class MotivoRevisaoQuadrilModalForm extends React.PureComponent<MotivoRevisaoQuadrilModalFormProps> {
    render() {
        return (
            <AlertaConfigModalForm
                formName={formName}
                validate={MotivoRevisaoModalFormValidator}
                {...this.props}
                renderChildren={ativo => (
                    <div>
                        <Columns>
                            <Column>
                                <ControlField label='Motivos da revisão' name='motivos' required />
                            </Column>
                        </Columns>
                        <Columns>
                            <Column size='6' verticalFlow>
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='COTILOIDITE'
                                    label='Cotiloidite'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='DESGASTE_POLIETILENO'
                                    label='Desgaste de polietileno'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='DOR_COMO_CAUSA_ISOLADA'
                                    label='Dor como causa isolada da revisão'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='FRATURA_PERIPROTETICA_QUADRIL'
                                    label='Fratura periprotética'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='GRANDE_DEFEITO_OSSEO_ACETABULAR'
                                    label='Grande defeito ósseo acetabular'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='GRANDE_DEFEITO_OSSEO_FEMORAL'
                                    label='Grande defeito ósseo femoral'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='INFECCAO_QUADRIL'
                                    label='Infecção'
                                    disabled={!ativo}
                                />
                            </Column>
                            <Column verticalFlow>
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='OSTEOLISE_ACETABULO'
                                    label='Osteólise do acetábulo'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='OSTEOLISE_FEMUR'
                                    label='Osteólise do fêmur'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='OSSIFICACAO_HETEROTOPICA'
                                    label='Ossificação heterotópica'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='PROTUSAO_ACETABULAR'
                                    label='Protrusão acetabular'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='QUEBRA_IMPLANTE_QUADRIL'
                                    label='Quebra do implante'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='SOLTURA_COMPONENTE_ACETABULAR'
                                    label='Soltura do componente acetabular'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='SOLTURA_COMPONENTE_FEMORAL_QUADRIL'
                                    label='Soltura do componente femoral'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='OUTRO_REVISAO_QUADRIL'
                                    label='Outro'
                                    disabled={!ativo}
                                />
                            </Column>
                        </Columns>
                    </div>
                )}
            ></AlertaConfigModalForm>
        )
    }
}
