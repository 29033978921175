import * as React from 'react'
import { connect } from 'react-redux'
import {
    DistribuicaoProcedimentoFiltro,
    DateInterval,
    DistribuicaoProdutosProcedimentosHospitalDto,
    DistribuicaoProdutosProcedimentosHospitalFiltro,
} from 'backend'
import {
    ResumoLocalItem,
    DistClassificacaoPeriodo,
    DistClassificacaoSexo,
    DistClassificacaoIdade,
    DistClassificacao,
} from 'redux/modules/report/procedimento'
import { State } from 'redux/modules'
import { PeriodoProcedimentoChartCard, SexoCard, FaixaEtariaChartCard } from 'view/componentes/reports/cards'
import * as procedimento from 'redux/modules/report/procedimento'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import UrlParams from 'libs/router/UrlParams'
import { DadosUsoCard } from 'view/componentes/reports/cards/DadosUsoCard'
import { NavLink } from 'react-router-dom'
import { TipoRevisaoReportHeader } from 'view/componentes/reports/components/TipoRevisaoReportHeader'
import * as filtroReport from 'redux/modules/report/filtro'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { Column, Columns, Icon, PeriodoFilter, Text } from 'libs/components'
import { ResultadoProdutosSemRegistro } from '../fragments/ResultadoProdutosSemRegistro'
import { PageRequester, RequestState } from 'redux/requester'
import Report from 'api/Report'
import { Page } from 'redux/middleware/PaginationAction'
import { ResultadoProdutosComRegistro } from '../fragments/ResultadoProdutosComRegistro'
import { stringify } from 'redux/modules/router'

export interface Props extends RouteComponentProps<any> {
    filtro: DistribuicaoProcedimentoFiltro
    distClassificacao: DistClassificacao
    distClassificacaoPeriodo: DistClassificacaoPeriodo
    distClassificacaoSexo: DistClassificacaoSexo
    distClassificacaoIdade: DistClassificacaoIdade
    changeFilter(filtro: DistribuicaoProcedimentoFiltro): void
    loadDetail(filter: DistribuicaoProcedimentoFiltro): void
    handlePageChangeRegistro(page: number): void
    handlePageChangeSemRegistro(page: number): void
    produtosRegistro: RequestState<Page<DistribuicaoProdutosProcedimentosHospitalDto>>
    produtosSemRegistro: RequestState<Page<DistribuicaoProdutosProcedimentosHospitalDto>>
    isFetchingProdutosRegistro: any
    isFetchingProdutosSemRegistro: any
}

export class TipoRevisaoDashboardView extends React.Component<Props> {
    handleLocalItemClick(item: ResumoLocalItem) {
        const local = item.local
        this.props.changeFilter({
            ...this.props.filtro,
            local: {
                regiao: local.regiao,
                uf: local.uf,
                cidadeId: local.cidade ? local.cidade.id : undefined,
                hospitalId: local.hospital ? local.hospital.id : undefined,
            },
        })
    }

    voltar = () => {
        this.props.history.push({
            pathname: '/home/relatorios/tipoRevisao',
            search: this.props.location.search,
        })
    }

    handlePeriodoChange = (interval: DateInterval) => {
        this.props.changeFilter({
            ...this.props.filtro,
            interval: interval,
        })
    }

    refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        this.props.loadDetail(filter)
    }

    renderDownloadIconComRegistro = () => {
        const currentFilter = this.props.filtro || {}
        currentFilter.tipoRevisao = this.props.match.params.tipoRevisao
        return (
            <Link
                to={{
                    pathname: '/api/report/distribuicaoProdutosProcedimentosHospitalRegistro/csv',
                    search: stringify(currentFilter),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }

    renderDownloadIconSemRegistro = () => {
        const currentFilter = this.props.filtro || {}
        currentFilter.tipoRevisao = this.props.match.params.tipoRevisao
        return (
            <Link
                to={{
                    pathname: '/api/report/distribuicaoProdutosProcedimentosHospitalSemRegistro/csv',
                    search: stringify(currentFilter),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }

    render() {
        const { ...publishFilter } = this.props.filtro
        return (
            <div>
                <UrlParams params={publishFilter} onChange={this.refresh} />
                <ReportClearFilter />

                <TipoRevisaoReportHeader tipoRevisao={this.props.match.params.tipoRevisao} onVoltar={this.voltar} />
                <div className='report-content'>
                    <Columns className='report-tabs align-center'>
                        <Column size='6'>
                            <div className='report-tabs-links'>
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}`,
                                        search: this.props.location.search,
                                    }}
                                    activeClassName='is-active'
                                >
                                    Dashboard
                                </NavLink>
                            </div>
                        </Column>
                        <Column size='6' textRight>
                            <PeriodoFilter value={this.props.filtro.interval} onChange={this.handlePeriodoChange} />
                        </Column>
                    </Columns>

                    <Columns auto>
                        <div className='flex-5'>
                            <DadosUsoCard
                                distClassificacao={this.props.distClassificacao}
                                title='Número de registros'
                            />
                        </div>
                        <div className='flex-5'>
                            <SexoCard dist={this.props.distClassificacaoSexo} />
                        </div>
                    </Columns>

                    <Columns auto>
                        <PeriodoProcedimentoChartCard dist={this.props.distClassificacaoPeriodo} />
                    </Columns>

                    <Columns auto>
                        <FaixaEtariaChartCard dist={this.props.distClassificacaoIdade} />
                    </Columns>

                    <Columns auto>
                        <ResultadoProdutosComRegistro
                            handlePageChange={this.props.handlePageChangeSemRegistro}
                            isFetching={this.props.isFetchingProdutosRegistro}
                            produtos={this.props.produtosRegistro}
                            report={this.renderDownloadIconComRegistro}
                            showDetalheProduto={true}
                        />
                    </Columns>

                    <Columns auto>
                        <ResultadoProdutosSemRegistro
                            handlePageChange={this.props.handlePageChangeSemRegistro}
                            isFetching={this.props.isFetchingProdutosSemRegistro}
                            produtos={this.props.produtosSemRegistro}
                            report={this.renderDownloadIconSemRegistro}
                            showDetalheProduto={true}
                        />
                    </Columns>
                </div>
            </div>
        )
    }
}

const distribuicaoProdutosRegistro = new PageRequester<
    DistribuicaoProdutosProcedimentosHospitalDto,
    DistribuicaoProdutosProcedimentosHospitalFiltro
>('distProtudosRegistro', filtro => {
    return Report.findDistribuicaoProdutosProcedimentosHospitalRegistro(filtro)
})

const distribuicaoProdutosSemRegistro = new PageRequester<
    DistribuicaoProdutosProcedimentosHospitalDto,
    DistribuicaoProdutosProcedimentosHospitalFiltro
>('distProtudosSemRegistro', filtro => {
    return Report.findDistribuicaoProdutosProcedimentosHospitalSemRegistro(filtro)
})

const mapStateToProps = (state: State, ownProps: RouteComponentProps) => ({
    filtro: state.report.filtro.filtro,
    distClassificacao: state.report.procedimento.distClassificacao,
    distClassificacaoPeriodo: state.report.procedimento.distClassificacaoPeriodo,
    distClassificacaoSexo: state.report.procedimento.distClassificacaoSexo,
    distClassificacaoIdade: state.report.procedimento.distClassificacaoIdade,
    produtosRegistro: distribuicaoProdutosRegistro.getRequestState(state),
    produtosSemRegistro: distribuicaoProdutosSemRegistro.getRequestState(state),
    isFetchingProdutosRegistro: distribuicaoProdutosRegistro.getIsFetching(state),
    isFetchingProdutosSemRegistro: distribuicaoProdutosSemRegistro.getIsFetching(state),
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(filtroReport.change(filtro))
    },
    loadDetail: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(procedimento.loadTipoRevisao(ownProps.match.params.tipoRevisao))

        const f = {
            ...filtro,
            motivo: ownProps.match.params.motivo,
            tipoRevisao: ownProps.match.params.tipoRevisao,
        }
        dispatch(distribuicaoProdutosRegistro.setParams(f))
        dispatch(distribuicaoProdutosRegistro.request())

        dispatch(distribuicaoProdutosSemRegistro.setParams(f))
        dispatch(distribuicaoProdutosSemRegistro.request())
    },
    handlePageChangeRegistro: (page: number) => {
        dispatch(distribuicaoProdutosRegistro.setPageNumber(page))
        dispatch(distribuicaoProdutosRegistro.request())
    },
    handlePageChangeSemRegistro: (page: number) => {
        dispatch(distribuicaoProdutosSemRegistro.setPageNumber(page))
        dispatch(distribuicaoProdutosSemRegistro.request())
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TipoRevisaoDashboardView))
