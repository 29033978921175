import Report from 'api/Report'
import { State } from 'redux/modules/index'
import { promiseReducer, PromiseRecord } from 'redux/middleware/PromiseAction'
import { DistribuicaoFinanceiraDto, DadoFinanceiroRow } from 'backend'
import { DescricaoEntePagador, SituacaoEntePagador } from '../../enums'

export const FIND_FINANCEIRO = 'rni/report/financeiro/FIND_FINANCEIRO'

export interface DadosFinanceirosReportState {
    dados: PromiseRecord<DistribuicaoFinanceiraDto>
}

export const initialState: DadosFinanceirosReportState = {
    dados: {},
}

export const reducer = (state = initialState, action) => {
    if (action.type === FIND_FINANCEIRO) {
        return { ...state, dados: exportFunctions.reduceDadosFinanceiros(promiseReducer(state.dados, action)) }
    }
    return state
}

export default reducer

export const doLoad = () => (dispatch, getState) => {
    const state: State = getState()
    const filtro = state.report.filtro.filtro

    dispatch({
        type: FIND_FINANCEIRO,
        promise: Report.getDadosFinanceiros(filtro),
    })
}

export const reduceDadosFinanceiros = (promiseRecord: PromiseRecord<DistribuicaoFinanceiraDto>) => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }

    const dados = promiseRecord.data

    Object.entries(SituacaoEntePagador).forEach(([tipo, entes]) => {
        if (!dados.totalByTipo[tipo]) {
            dados.totalByTipo[tipo] = 0
        }

        entes.forEach(ente => {
            const descricaoEnte = DescricaoEntePagador[ente]
            const row = dados.rows.find(row => row.motivoName === descricaoEnte && row.compradoHospital === tipo)
            if (!row) {
                const missingRow = {
                    motivoName: descricaoEnte,
                    qtdPagamentos: 0,
                    qtdProdutos: 0,
                    qtdRessarcimentos: 0,
                    compradoHospital: tipo,
                } as DadoFinanceiroRow
                dados.rows.push(missingRow)
            }
        })
    })

    return {
        data: { ...dados },
    }
}

export const exportFunctions = {
    reduceDadosFinanceiros,
}
