import * as React from 'react'
import { WrappedFieldInputProps } from 'redux-form'
import * as C from 'libs/components'

interface SimpleCountProps extends Partial<WrappedFieldInputProps> {
    qtdMax?: number
}

export class SimpleCount extends React.Component<SimpleCountProps, any> {
    componentDidMount() {
        if (!this.props.value) {
            this.props.onChange(1 as any)
        }
    }

    sub() {
        let valor = parseInt(this.props.value) - 1
        if (valor === 0) {
            valor = 1
        }

        this.props.onChange(valor as any)
    }

    add() {
        let valor = parseInt(this.props.value) + 1
        if (valor > this.props.qtdMax) {
            valor = this.props.qtdMax
        }

        this.props.onChange(valor as any)
    }

    render() {
        return (
            <div className='simple-count'>
                <C.Button className='less' name='sub' disabled={this.props.value === 1} onClick={this.sub.bind(this)}>
                    -
                </C.Button>
                <label className='is-children-center'>{this.props.value}</label>
                <C.Button
                    className='plus'
                    name='add'
                    disabled={this.props.value === this.props.qtdMax}
                    onClick={this.add.bind(this)}
                >
                    +
                </C.Button>
            </div>
        )
    }
}
