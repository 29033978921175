import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import immutableProxy from 'libs/immutableProxy'
import { BuscaPacienteReportFiltro } from 'backend'

const buscaProcedimento = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<BuscaPacienteReportFiltro>>(state)
        form = form.withMutations(mutable => {
            const tipoCirurgia = mutable.values.tipoCirurgia.get()
            if (!tipoCirurgia || (!tipoCirurgia.includes('JOELHO') && !tipoCirurgia.includes('QUADRIL'))) {
                mutable.values.classificacaoCirurgia.delete()
            }
        })
        return form.asMap()
    }
    return state
})

export default buscaProcedimento
