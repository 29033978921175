import * as React from 'react'
import InputMask from 'react-input-mask'
import * as C from 'libs/components'
import { WrappedFieldProps } from 'redux-form'
import field, { FieldProps } from 'libs/redux-form/field'

export interface MaskedFieldProps extends C.FormFieldProps, Partial<WrappedFieldProps> {
    mask: string
    placeholder?: string
    onKeyPress?: () => void
    testid?: string
}

const cleanMask = value => value.replace(/[^A-Za-z\d]/g, '')

class MaskedFieldCmp extends React.Component<MaskedFieldProps> {
    render() {
        return (
            <InputMask
                className='input'
                {...this.props.input}
                onKeyPress={this.props.onKeyPress}
                placeholder={this.props.placeholder}
                mask={this.props.mask}
                disabled={this.props.disabled}
                autoComplete='off'
                testid={this.props.testid}
            />
        )
    }
}

export const MaskedField: React.ComponentClass<FieldProps & MaskedFieldProps> = field({ parse: cleanMask })(
    MaskedFieldCmp
)
