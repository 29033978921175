/* tslint:disable */
import React from 'react'

const SvgDistCardio = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
        <path d='M21.088 7.037c-1.614 0-3.36.64-5.07 1.848C14.29 7.64 12.545 7 10.912 7 8.026 7 5.767 8.976 5.16 12.048 4.247 16.6 7.172 22.652 15.733 25.945c.096.036.19.055.285.055.094 0 .19-.019.285-.055C24.846 22.58 27.75 16.546 26.84 12.03c-.626-3.036-2.866-4.992-5.752-4.992z' />
    </svg>
)

export default SvgDistCardio
