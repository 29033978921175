import React from 'react'
import * as C from 'libs/components'

export class ICPPreviaCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='ICP prévia'
                name='icpPrevia'
                hasContent={false}
                buttonSet={<C.SimNaoNAvaliadoRadioField name='icpPrevia' />}
            />
        )
    }
}
