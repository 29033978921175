import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as motivos from 'redux/modules/report/motivos'
import * as filtroReport from 'redux/modules/report/filtro'
import { State } from 'redux/modules'
import { MotivosProcedimentoView, Props } from './MotivosProcedimentoView'
import { DistribuicaoProcedimentoFiltro, MotivoProcedimentoEnum } from 'backend'

const mapStateToProps = (state: State, ownProps: Props) => ({
    filtro: state.report.filtro.filtro,
    motivos: state.report.motivos.motivos,
    motivosRevisoes: state.report.motivos.motivosRevisoes,
    distQuadroPadrao: state.report.motivos.distQuadroPadrao,
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(filtroReport.change({ ...filtro }))
    },
    loadMotivos: () => {
        dispatch(motivos.loadAll())
    },
    loadRevisoes: () => {
        dispatch(motivos.loadMotivoRevisoes())
    },
    loadQuadroPadrao: () => {
        dispatch(motivos.loadQuadroPadrao())
    },
    goToDetail: (mot: MotivoProcedimentoEnum) => {
        ownProps.history.push({
            pathname: `${ownProps.match.url}/${mot}`,
            search: ownProps.location.search,
        })
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MotivosProcedimentoView))
