import * as React from 'react'
import { ProdutosProcedimentosRow } from 'backend'
import { Link } from 'react-router-dom'
import { Page } from 'redux/requester'
import { Card, Column, LoadingContainer, Paginator, Progress, Table, TableColumnHeader, Text } from 'libs/components'
interface Props {
    distProdutos: Page<ProdutosProcedimentosRow>
    titulo?: string
    tipoProcedimento: string
    isFetching?: boolean
    handlePageChange(page: number): void
    report(): JSX.Element
}

export class ProdutosProcedimentosTable extends React.Component<Props> {
    render() {
        const { isFetching } = this.props
        const maxQuocient =
            this.props.distProdutos &&
            Math.max(...this.props.distProdutos.content.map(res => res.quantidadeProcedimentos))
        const tipoProcedimento = this.props.tipoProcedimento
        const distProdutos = this.props.distProdutos
        const total = distProdutos.totalElements + ' componentes'
        return (
            <div className='report-content'>
                <Card title={total} noPadding={!isFetching} headerAction={this.props.report()}>
                    <LoadingContainer isFetching={isFetching}>
                        {distProdutos.totalElements < 1 && (
                            <Column textCentered borderBottom>
                                <p style={{ lineHeight: 2 }} className='is-text-muted'>
                                    Nenhum registro encontrado.
                                </p>
                            </Column>
                        )}
                        {distProdutos.totalElements > 0 && (
                            <Table values={distProdutos.content} className='is-spaced is-small-bis'>
                                <TableColumnHeader
                                    title='Nome'
                                    style={{ width: '50%' }}
                                    render={(item: ProdutosProcedimentosRow) => {
                                        return <Text>{item.nomeProduto}</Text>
                                    }}
                                />
                                <TableColumnHeader
                                    title=''
                                    style={{ width: '40%' }}
                                    render={(item: ProdutosProcedimentosRow) => {
                                        const procedimentos = item.quantidadeProcedimentos
                                        const barWidth = Math.max(procedimentos / maxQuocient)
                                        return (
                                            <Progress
                                                size='x-small'
                                                className='is-primary no-margin'
                                                value={barWidth}
                                            />
                                        )
                                    }}
                                />
                                <TableColumnHeader
                                    title='Proced.'
                                    className='has-text-centered'
                                    render={(item: ProdutosProcedimentosRow) => {
                                        return <Text>{item.quantidadeProcedimentos}</Text>
                                    }}
                                />
                                <TableColumnHeader
                                    title=''
                                    render={(item: ProdutosProcedimentosRow) => {
                                        const idComponente = item.idComponente
                                        return (
                                            <Link
                                                data-name={'row-' + distProdutos.content.indexOf(item)}
                                                to={
                                                    '/home/relatorios/produtos/componente/' +
                                                    idComponente +
                                                    '/' +
                                                    tipoProcedimento
                                                }
                                            >
                                                >
                                            </Link>
                                        )
                                    }}
                                />
                            </Table>
                        )}
                        <Paginator
                            first={distProdutos.first}
                            last={distProdutos.last}
                            pageNumber={distProdutos.number}
                            totalPages={distProdutos.totalPages}
                            onChange={this.props.handlePageChange}
                        />
                    </LoadingContainer>
                </Card>
            </div>
        )
    }
}
