import React from 'react'
import * as C from 'libs/components'

export class InsuficienciaRenalCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Insuficiência renal'
                name='insuficienciaRenal'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='insuficienciaRenal' />}
            >
                <C.Conteudo>
                    <C.Columns verticalFlow>
                        <C.Column size='8'>
                            <C.ControlField label='IRC' name='insuficienciaRenalIrc' horizontalFlow>
                                <C.RadioField name='insuficienciaRenalIrc' value='HEMODIALISE' label='Em hemodiálise' />
                                <C.RadioField
                                    name='insuficienciaRenalIrc'
                                    value='NAO_DIALITICA'
                                    label='Não dialítica'
                                />
                                <C.ClearRadioFieldButton name='insuficienciaRenalIrc' />
                            </C.ControlField>
                        </C.Column>
                        <C.Column size='3'>
                            <C.DecimalField
                                label='Creatinina'
                                name='insuficienciaRenalCreatinina'
                                unit={'mg/dL'}
                                pre={2}
                                pos={1}
                                placeholder={'(0-50)'}
                            />
                        </C.Column>
                    </C.Columns>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
