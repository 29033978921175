import * as React from 'react'
import classnames from 'classnames'
import * as C from 'libs/components'

export interface ComposedLabelVisualizacaoProps {
    horizontal?: boolean
    italic?: boolean
    name?: string
    title: string
    moreOneColumn?: boolean
}

export class ComposedLabelVisualizacao extends React.Component<ComposedLabelVisualizacaoProps> {
    static defaultProps: ComposedLabelVisualizacaoProps = {
        title: '',
    }

    render() {
        const classes = classnames('composed-label', {
            'is-horizontal': this.props.horizontal,
            'is-italic': !this.props.children,
        })

        const title = this.props.title
        return this.props.children && !this.props.moreOneColumn ? (
            <C.Columns auto>
                <div className={classes} data-name={this.props.name}>
                    <span className={classnames('label-title')}>{title}</span>
                    {this.props.children}
                </div>
            </C.Columns>
        ) : this.props.children && this.props.moreOneColumn ? (
            <div className={classes} data-name={this.props.name}>
                <span className={classnames('label-title')}>{title}</span>
                {this.props.children}
            </div>
        ) : null
    }
}
