import { AxiosPromise } from 'axios'
import { CadastroPacienteForm, RascunhoProcedimentoForm } from 'backend'
import { Column, Columns } from 'libs/components'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { InjectedFormProps } from 'redux-form'
import { formValueSelector, reduxForm } from 'redux-form/immutable'
import { actionCreators as pacienteActionCreators } from 'redux/modules/paciente'
import { ButtonsFormPaciente } from './ButtonsFormPaciente'
import { FormBusca } from './FormBusca'
import { ResultadoBusca } from './ResultadoBusca'

interface DefinirPacienteFormBodyProps {
    cpf: string
    cns: string
    tipoFiltro: string
    paciente: CadastroPacienteForm
    erro: any
    guardarPaciente(paciente: any): any
    selecionarPaciente(paciente: any): AxiosPromise<RascunhoProcedimentoForm>
    handleRascunhoSuccess(result: AxiosPromise<RascunhoProcedimentoForm>): void
    showAlertError(): void
    cancelar(): void
}
type Props = DefinirPacienteFormBodyProps & InjectedFormProps<{}, DefinirPacienteFormBodyProps>

const DefinirPacienteFormBody = ({ cpf, cns, tipoFiltro, paciente, erro, ...props }: Props) => {
    const { push } = useHistory()
    const { url } = useRouteMatch()
    const dispatch = useDispatch()
    useEffect(() => () => dispatch(pacienteActionCreators.clearFindResult('')), [dispatch])

    const { handleSubmit, pristine, submitting } = props
    const tipoCpf = tipoFiltro === 'CPF' && cpf && cpf.length === 11
    const tipoCns = tipoFiltro === 'CNS' && cns && cns.length === 15
    const buttonDisabled = pristine || submitting || !(tipoCpf || tipoCns)
    const adicionarPaciente = () => {
        push(`${url}/cadastrarPaciente`)
    }
    const guardarPaciente = () => {
        props.guardarPaciente(paciente)
        push(`${url}/cadastrarPaciente`)
    }

    return (
        <div>
            <FormBusca
                handleSubmit={handleSubmit}
                tipoFiltro={tipoFiltro}
                submitting={submitting}
                buttonDisabled={buttonDisabled}
            />
            <Columns className='resultado-busca-paciente is-gray-background'>
                <Column>
                    <ResultadoBusca
                        paciente={paciente}
                        erro={erro}
                        submitting={submitting}
                        adicionarPaciente={adicionarPaciente}
                        tipoFiltro={tipoFiltro}
                    />
                </Column>
            </Columns>
            <hr />
            <ButtonsFormPaciente
                cancelar={props.cancelar}
                paciente={paciente}
                selecionarPaciente={props.selecionarPaciente}
                guardarPaciente={guardarPaciente}
                handleRascunhoSuccess={props.handleRascunhoSuccess}
                showAlertError={props.showAlertError}
            />
        </div>
    )
}

export const definirPacienteFormSelector = formValueSelector('definirPaciente')
export const DefinirPacienteForm = reduxForm<{}, DefinirPacienteFormBodyProps>({
    form: 'definirPaciente',
    enableReinitialize: true,
})(DefinirPacienteFormBody)
