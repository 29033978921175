import axios, { AxiosPromise } from 'axios'
import {
    ComponenteRastreabilidadeFiltro,
    RegistroProcedimentoComponenteDto,
    ProdutoDto,
    DadosProdutoDto,
    DadosProdutoFiltro,
    RegistroAnvisaSeletor,
    DadosProdutoFormList,
    RastreabilidadeFormList,
    DadosFinanceirosFormList,
    RastreabilidadeForm,
    DadosFinanceirosForm,
    DadosProdutoForm,
} from 'backend'

class Rastreabilidade {
    deleteImagemEtiqueta(idDadosProduto: number): AxiosPromise<void> {
        return axios.post('/api/rastreabilidade/deleteImagemEtiqueta/' + idDadosProduto)
    }

    getByFiltro(filtro: RegistroAnvisaSeletor): AxiosPromise<ProdutoDto[]> {
        return axios.get('/api/rastreabilidade/getFabricanteByFiltro', { params: filtro })
    }

    getComponentesByProcedimento(
        filtro: ComponenteRastreabilidadeFiltro
    ): AxiosPromise<RegistroProcedimentoComponenteDto> {
        return axios.get('/api/rastreabilidade/rastreabilidadeByProcedimento', { params: filtro })
    }

    getNomeModeloProduto(registroAnvisa: string): AxiosPromise<string[]> {
        return axios.get('/api/produto/nomeModelo/' + registroAnvisa)
    }

    saveDadosProdutoMedico(form: DadosProdutoFormList): AxiosPromise<DadosProdutoFormList> {
        return axios.post('/api/rastreabilidade/saveDadosProdutoMedico', form)
    }

    updateDadosProdutoMedico(form: DadosProdutoFormList): AxiosPromise<DadosProdutoFormList> {
        return axios.put('/api/rastreabilidade/updateDadosProdutoMedico', form)
    }

    saveDadosProdutoAdministrativo(form: RastreabilidadeFormList): AxiosPromise<RastreabilidadeFormList> {
        return axios.post('/api/rastreabilidade/saveDadosProdutoAdministrativo', form)
    }

    updateDadosProdutoAdministrativo(form: RastreabilidadeFormList): AxiosPromise<RastreabilidadeFormList> {
        return axios.put('/api/rastreabilidade/updateRastreabilidade', form)
    }

    saveDadosFinanceiroAdministrativo(form: DadosFinanceirosFormList): AxiosPromise<DadosFinanceirosFormList> {
        return axios.post('/api/rastreabilidade/saveDadosFinanceiroAdministrativo', form)
    }

    updateDadosFinanceiroAdministrativo(form: DadosFinanceirosFormList): AxiosPromise<DadosFinanceirosFormList> {
        return axios.put('/api/rastreabilidade/updateDadosFinanceiro', form)
    }

    loadDadosProduto(filtro: DadosProdutoFiltro): AxiosPromise<DadosProdutoDto> {
        return axios.get('/api/rastreabilidade/getDadosProduto', { params: filtro })
    }

    loadFabricantes(registroAnvisa: string): AxiosPromise<string[]> {
        return axios.get('/api/produto/fabricantes/' + registroAnvisa)
    }

    validateDadosProdutoAdministrativo(form: RastreabilidadeForm): AxiosPromise<RastreabilidadeForm> {
        return axios.post('/api/rastreabilidade/validateDadosProdutoAdministrativo', form)
    }

    validateDadosFinanceiroAdministrativo(form: DadosFinanceirosForm): AxiosPromise<DadosFinanceirosForm> {
        return axios.post('/api/rastreabilidade/validateDadosFinanceiroAdministrativo', form)
    }

    validateDadosProdutoMedico(form: DadosProdutoForm): AxiosPromise<DadosProdutoForm> {
        return axios.post('/api/rastreabilidade/validateDadosProdutoMedico', form)
    }
}

export default new Rastreabilidade()
