import * as React from 'react'
import { Icon, Button } from 'libs/components'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import { MotivoProcedimentoEnum } from 'backend'
import { MotivosProcedimento } from 'redux/enums/index'
import { tiposProcedimentoDescricaoByEnum } from 'api/Procedimento'

interface Props {
    motivo: MotivoProcedimentoEnum
    onVoltar(): void
}

export class MotivoReportHeader extends React.Component<Props> {
    render() {
        const { motivo } = this.props

        return (
            <HeaderContainer className='report-header-rastreabilidade'>
                <Button className='button-round button-back-motivos' onClick={this.props.onVoltar}>
                    <Icon icon='voltar' />
                </Button>
                <p className='is-semibold is-grey-darker is-small is-uppercase'>Motivos de procedimentos</p>
                <p className=''>
                    <span className='is-semibold'>{MotivosProcedimento[motivo].descricao}</span>
                </p>
                <p className='is-semibold is-grey-darker is-small'>
                    em {tiposProcedimentoDescricaoByEnum[MotivosProcedimento[motivo].procedimento]}
                </p>
            </HeaderContainer>
        )
    }
}
