import { messages as ptBr } from './messages'

export type MessageKey =
    | 'cep'
    | 'cnpj'
    | 'cns'
    | 'cpf'
    | 'dataNasc'
    | 'maxDate'
    | 'duracao'
    | 'email'
    | 'empty'
    | 'hora'
    | 'length'
    | 'minLength'
    | 'maxLength'
    | 'match'
    | 'nome'
    | 'range'
    | 'minRange'
    | 'maxRange'
    | 'required'
    | 'senha'
    | 'telefone'

export type ValidationMessages = { [key in MessageKey]: (value: any, ...args: any[]) => string }

export const msg = (key: MessageKey, value: any, ...args: any[]): string => ptBr[key](value, ...args)
