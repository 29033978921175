import * as React from 'react'
import { Page } from 'redux/middleware/PaginationAction'
import { RequestState } from 'redux/requester'
import { Card, Column, Columns, LoadingStateContainer, NumberLabel} from 'libs/components'
import { InvertedLabel } from 'view/componentes/reports/components/InvertedLabel'

interface Props {
    produtosRegistro: RequestState<Page<any>>
    produtosSemRegistro: RequestState<Page<any>>
    isFetching: any
    handlePageChange(page: number): void
}

export class ResultadoProdutosTotal extends React.Component<Props> {
    render() {
		const { produtosSemRegistro, produtosRegistro } = this.props
        let total = 0
        let totalRegistro = 0
        let totalSemRegistro = 0
        if (produtosRegistro.readyState === 'success' && produtosSemRegistro.readyState === 'success') {
            total = produtosRegistro.result.totalElements + produtosSemRegistro.result.totalElements
            totalRegistro = produtosRegistro.result.totalElements
            totalSemRegistro = produtosSemRegistro.result.totalElements
        }
        return (
            <Card title='Nº de Produtos'>
                <LoadingStateContainer readyState={this.props.produtosRegistro.readyState}>
                    <Columns>
                        <Column>
                            <InvertedLabel title='Produtos'>
                                <NumberLabel value={total} placeholder='-' />
                            </InvertedLabel>
                        </Column>
                        <Column>
                            <InvertedLabel title='Com registro anvisa'>
                                <NumberLabel value={totalRegistro} placeholder='-' />
                            </InvertedLabel>
                        </Column>
                        <Column>
                            <InvertedLabel title='Sem registro anvisa'>
                                <NumberLabel value={totalSemRegistro} placeholder='-' />
                            </InvertedLabel>
                        </Column>
                    </Columns>
                </LoadingStateContainer>
            </Card>
        )
    }
}
