import React from 'react'
import { withRouter } from 'react-router-dom'
import { Step, Steps } from 'libs/components'
import { Switch, Route, Redirect } from 'react-router-dom'
import { PerfilEnum, PerfisCardiologistas, PerfisOrtopedistas } from 'api/Perfis'
import PerfilRoute from 'libs/router/PerfilRoute'

import FichaPrimariaQuadrilContainer from 'view/home/novoProcedimento/ficha/quadril/FichaPrimariaQuadrilContainer'
import FichaRevisaoQuadrilContainer from 'view/home/novoProcedimento/ficha/quadril/FichaRevisaoQuadrilContainer'
import FichaPrimariaJoelhoContainer from 'view/home/novoProcedimento/ficha/joelho/FichaPrimariaJoelhoContainer'
import FichaRevisaoJoelhoContainer from 'view/home/novoProcedimento/ficha/joelho/FichaRevisaoJoelhoContainer'
import FichaStentCoronariaContainer from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaContainer'

import FichaPrimariaQuadrilSidebar from 'view/home/novoProcedimento/ficha/quadril/FichaPrimariaQuadrilSidebar'
import FichaRevisaoQuadrilSidebar from 'view/home/novoProcedimento/ficha/quadril/FichaRevisaoQuadrilSidebar'
import FichaPrimariaJoelhoSidebar from 'view/home/novoProcedimento/ficha/joelho/FichaPrimariaJoelhoSidebar'
import FichaRevisaoJoelhoSidebar from 'view/home/novoProcedimento/ficha/joelho/FichaRevisaoJoelhoSidebar'
import FichaStentCoronariaEssencialSidebar from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaEssencialSidebar'
import FichaStentCoronariaComplementarSidebar from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaComplementarSidebar'
import { FormView } from 'view/componentes/FormView'
import { DefinirEquipeMedicaForm } from './definirEquipeMedica/DefinirEquipeMedicaForm'
import { ProcedimentosView } from './procedimentos/ProcedimentosView'
import { DefinirPacienteView } from './definirPaciente/DefinirPacienteView'
import { ModalInformacoesEquipeMedica } from 'view/home/novoProcedimento/procedimentos/components/ModalInformacoesEquipeMedica'
import { InformacoesEquipeMedica } from 'view/home/novoProcedimento/procedimentos/components/InformacoesEquipeMedica'

class NovoProcedimentoView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            active: false,
        }

        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
    }

    open() {
        this.setState({ active: true })
    }

    close() {
        this.setState({ active: false })
    }

    componentDidMount() {
        const idFicha = this.props.match.params.idFicha
        if (idFicha && !this.props.isEdicao && !this.props.medicoResponsavel) {
            this.props.preencherDadosClinicos(Number(idFicha))
        }
    }

    render() {
        if (this.props.location.pathname === `${this.props.match.url}/definirPaciente/cadastrarPaciente`) {
            return this.getStepAfterPaciente()
        }

        let steps

        if (this.props.perfil === PerfilEnum.TECNICO) {
            steps = this.tecnicoSteps()
        } else if (PerfisOrtopedistas.includes(this.props.perfil)) {
            steps = this.ortopedistaSteps()
        } else if (PerfisCardiologistas.includes(this.props.perfil)) {
            steps = this.cardiologiaSteps()
        }

        return (
            <>
                <ModalInformacoesEquipeMedica
                    medicoResponsavel={this.props.medicoResponsavel}
                    equipeMedica={this.props.equipeMedica}
                    open={this.state.active}
                    close={this.close}
                />
                <FormView
                    sidebarContent={steps}
                    mainContent={
                        <Switch>
                            <Route
                                exact
                                path={`${this.props.match.url}`}
                                render={({ match }) => {
                                    return <Redirect to={`${match.url}/definirEquipeMedica`} />
                                }}
                            />
                            <ChildRoute
                                path={`${this.props.match.url}/definirEquipeMedica`}
                                component={DefinirEquipeMedicaForm}
                                nextRoute={`${this.props.match.url}/definirPaciente`}
                                isEdicao={this.props.isEdicao}
                            />
                            {this.checkIntegrityEquipeMedica()}
                            {this.getStepAfterPaciente()}
                            {this.checkIntegrityPaciente()}
                            {this.getStepProcedimento()}
                            {this.fichaRouteOrtopedia('primariaJoelho', FichaPrimariaJoelhoContainer)}
                            {this.fichaRouteOrtopedia('revisaoJoelho', FichaRevisaoJoelhoContainer)}
                            {this.fichaRouteOrtopedia('primariaQuadril', FichaPrimariaQuadrilContainer)}
                            {this.fichaRouteOrtopedia('revisaoQuadril', FichaRevisaoQuadrilContainer)}
                            {this.fichaRouteCardiologia('stentCoronaria', FichaStentCoronariaContainer)}
                        </Switch>
                    }
                />
            </>
        )
    }

    checkIntegrityEquipeMedica() {
        if (!this.props.medicoResponsavel) {
            return <Redirect to={`${this.props.match.url}/definirEquipeMedica`} />
        }
    }

    checkIntegrityPaciente() {
        if (!this.props.paciente) {
            return <Redirect to={`${this.props.match.url}/definirPaciente`} />
        }
    }

    getStepProcedimento() {
        return (
            <ChildRoute
                path={`${this.props.match.url}/procedimentos`}
                component={ProcedimentosView}
                nextRoute={`${this.props.match.url}/ficha`}
            />
        )
    }

    getStepAfterPaciente() {
        if (PerfisCardiologistas.includes(this.props.perfil)) {
            return (
                <ChildRoute
                    path={`${this.props.match.url}/definirPaciente`}
                    component={DefinirPacienteView}
                    nextRoute={`${this.props.match.url}/stentCoronaria`}
                />
            )
        } else {
            return (
                <ChildRoute
                    path={`${this.props.match.url}/definirPaciente`}
                    component={DefinirPacienteView}
                    nextRoute={`${this.props.match.url}/procedimentos`}
                />
            )
        }
    }

    tecnicoSteps() {
        if (!PerfisOrtopedistas.includes(this.props.medicoResponsavel?.perfil)) {
            return (
                <Steps location={this.props.location}>
                    <Step name='DEFINIR EQUIPE MÉDICA' path={`${this.props.match.url}/definirEquipeMedica`} />
                    <Step name='DEFINIR PACIENTE' path={`${this.props.match.url}/definirPaciente`} />
                </Steps>
            )
        } else {
            return (
                <Steps location={this.props.location}>
                    <Step name='DEFINIR EQUIPE MÉDICA' path={`${this.props.match.url}/definirEquipeMedica`} />
                    <Step name='DEFINIR PACIENTE' path={`${this.props.match.url}/definirPaciente`} />
                    <Step name='PROCEDIMENTOS' path={`${this.props.match.url}/procedimentos`} />
                </Steps>
            )
        }
    }

    ortopedistaSteps() {
        return (
            <>
                {this.props.match.params.idFicha && this.props.match.path.includes('novoProcedimento') && (
                    <InformacoesEquipeMedica medicoResponsavel={this.props.medicoResponsavel} open={this.open} />
                )}
                <Steps location={this.props.location}>
                    <Step name='DEFINIR EQUIPE MÉDICA' path={`${this.props.match.url}/definirEquipeMedica`} />
                    <Step name='DEFINIR PACIENTE' path={`${this.props.match.url}/definirPaciente`} />
                    <Step name='PROCEDIMENTOS' path={`${this.props.match.url}/procedimentos`} />
                    <Step name='REGISTRO' path={`${this.props.match.url}/${this.props.procedimentoSelecionado}`}>
                        <Switch>
                            <Route
                                path={`${this.props.match.url}/primariaJoelho`}
                                component={FichaPrimariaJoelhoSidebar}
                            />
                            <Route
                                path={`${this.props.match.url}/revisaoJoelho`}
                                component={FichaRevisaoJoelhoSidebar}
                            />
                            <Route
                                path={`${this.props.match.url}/primariaQuadril`}
                                component={FichaPrimariaQuadrilSidebar}
                            />
                            <Route
                                path={`${this.props.match.url}/revisaoQuadril`}
                                component={FichaRevisaoQuadrilSidebar}
                            />
                        </Switch>
                    </Step>
                </Steps>
            </>
        )
    }

    cardiologiaSteps() {
        return (
            <>
                {this.props.match.params.idFicha && this.props.match.path.includes('novoProcedimento') && (
                    <InformacoesEquipeMedica medicoResponsavel={this.props.medicoResponsavel} open={this.open} />
                )}
                <Steps location={this.props.location}>
                    <Step name='DEFINIR EQUIPE MÉDICA' path={`${this.props.match.url}/definirEquipeMedica`} />
                    <Step name='DEFINIR PACIENTE' path={`${this.props.match.url}/definirPaciente`} />
                    <Step name='DADOS ESSENCIAIS' path={`${this.props.match.url}/stentCoronaria/essencial`}>
                        <Route
                            path={`${this.props.match.url}/stentCoronaria/essencial`}
                            component={FichaStentCoronariaEssencialSidebar}
                        />
                    </Step>
                    <Step name='DADOS COMPLEMENTARES' path={`${this.props.match.url}/stentCoronaria/complementar`}>
                        <Route
                            path={`${this.props.match.url}/stentCoronaria/complementar`}
                            component={FichaStentCoronariaComplementarSidebar}
                        />
                    </Step>
                </Steps>
            </>
        )
    }

    fichaRouteOrtopedia(url, component) {
        return (
            <PerfilRoute
                perfis={PerfisOrtopedistas}
                path={`${this.props.match.url}/${url}`}
                render={props => {
                    if (this.props.procedimentoSelecionado) {
                        return React.createElement(component, props)
                    }
                    return <Redirect to={`${this.props.match.url}/procedimentos`} />
                }}
            />
        )
    }

    fichaRouteCardiologia(url, component) {
        return (
            <PerfilRoute
                perfis={PerfisCardiologistas}
                path={`${this.props.match.url}/${url}`}
                render={props => {
                    if (this.props.procedimentoSelecionado) {
                        return React.createElement(component, props)
                    }
                    return <Redirect to={`${this.props.match.url}/definirPaciente`} />
                }}
            />
        )
    }
}

class ChildRoute extends React.Component {
    constructor() {
        super()
        this.next = this.next.bind(this)
    }

    next() {
        this.props.history.push(this.props.nextRoute)
    }

    render() {
        const { component, isEdicao, ...rest } = this.props
        const next = this.next
        return (
            <Route
                {...rest}
                render={props => {
                    return React.createElement(component, { ...props, next, isEdicao })
                }}
            />
        )
    }
}

ChildRoute = withRouter(ChildRoute)

export default NovoProcedimentoView
