import * as React from 'react'
import { connect } from 'react-redux'
import Text from 'libs/components/presentational/typography/Text'
import { UserInitials } from 'libs/components'
import AutoPopover from 'libs/components/presentational/AutoPopover'
import Menu from 'libs/components/presentational/layout/Menu'
import MenuItem from 'libs/components/presentational/layout/MenuItem'
import Badge from 'view/componentes/Badge'

export class Props {
    user: any
    hasAlertasNaoLidos: boolean
    goBuscaProcedimentos: () => void
    goAlarmes: () => void
    goVinculos: () => void
    goAlterarSenha: () => void
}

export class HeaderSNVSAnvisa extends React.Component<Props> {
    render() {
        const { user } = this.props

        if (!user) {
            return null
        }

        return (
            <div className='header-anvisa--container'>
                <div className='header-anvisa'>
                    <div>
                        <UserInitials nome={user.nome} />
                    </div>
                    <div style={{ marginRight: 'auto' }}>
                        <Text color='black' weight='bold' size='large'>
                            {user.nome}
                        </Text>
                        <br />
                        <Text color='grey-darker' weight='semibold' size='small-ter'>
                            {user.perfil}
                        </Text>
                    </div>

                    <AutoPopover
                        className='popover-menu'
                        renderTrigger={open => (
                            <div onClick={open}>
                                <Badge
                                    isVisible={this.props.hasAlertasNaoLidos}
                                    overlap='circle'
                                    style={{ transform: 'translate(6px, 0px) scale(1.5)' }}
                                >
                                    <div className='menu-header' data-testid='menu-header-anvisa'>
                                        &middot;&middot;&middot;
                                    </div>
                                </Badge>
                            </div>
                        )}
                        rootClose
                        placement='bottom'
                    >
                        <Menu>
                            <MenuItem
                                className='smaller'
                                data-name='alterarVinculo'
                                icon='alterarperfil'
                                onClick={this.props.goVinculos}
                            >
                                Vínculos
                            </MenuItem>
                            <MenuItem
                                className='smaller'
                                data-name='alterarSenha'
                                icon='alterarsenha'
                                onClick={this.props.goAlterarSenha}
                            >
                                Alterar senha
                            </MenuItem>
                            <MenuItem
                                className='is-disabled smaller'
                                data-name='botao_busca_procedimentos'
                                icon='filtroProcedimento'
                                onClick={this.props.goBuscaProcedimentos}
                            >
                                Buscar procedimentos
                            </MenuItem>
                            <MenuItem
                                className='is-disabled smaller'
                                data-name='botao_alarmes'
                                icon='alarme'
                                onClick={this.props.goAlarmes}
                                showNotificationDot={this.props.hasAlertasNaoLidos}
                            >
                                Alarmes
                            </MenuItem>
                        </Menu>
                    </AutoPopover>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

const HomeHeader = connect(mapStateToProps)(HeaderSNVSAnvisa)

export default HomeHeader
