import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import { PrimariaJoelhoForm } from 'backend'
import immutableProxy, { ImmutableRootProxy } from 'libs/immutableProxy'
import { isImmutable } from 'immutable'

const validaProcedimentoCirurgicoPrevio = (mutable: ImmutableRootProxy<FormState<PrimariaJoelhoForm>>) => {
    const proceds = mutable.values.procedimentoCirurgicoPrevio.procedimentoPrevio.get()
    if (proceds && proceds.indexOf('NENHUM') > -1) {
        mutable.values.procedimentoCirurgicoPrevio.procedimentoPrevio.set(['NENHUM'])
    }

    if (mutable.values.procedimentoCirurgicoPrevio.implantePrevio.get() !== 'SIM') {
        mutable.values.procedimentoCirurgicoPrevio.estadoImplantePrevio.delete()
    }
    return mutable
}
const primariaJoelho = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<PrimariaJoelhoForm>>(state)
        form = form.withMutations(mutable => {
            validaProcedimentoCirurgicoPrevio(mutable)

            if (mutable.values.procedimentoCirurgicoAtualForm.possuiAntibiotico.get() !== 'SIM') {
                mutable.values.procedimentoCirurgicoAtualForm.antibiotico.delete()
                mutable.values.procedimentoCirurgicoAtualForm.periodoAntibiotico.delete()
            }

            if (mutable.values.procedimentoCirurgicoAtualForm.transfusaoSanguinea.get() !== 'SIM') {
                mutable.values.procedimentoCirurgicoAtualForm.tipoTransfusaoSanguinea.delete()
            }

            const tipoArtroplastia = mutable.values.tipoArtroplastiaForm.tipoArtroplastia.get()
            let tipoArtroplastiaId
            if (isImmutable(tipoArtroplastia)) {
                tipoArtroplastiaId = tipoArtroplastia?.get('id')
            } else {
                tipoArtroplastiaId = tipoArtroplastia?.id
            }
            if (
                tipoArtroplastiaId !== 'ARTROPLASTIA_TOTAL_COM_SUBSTITUICAO_PATELAR' &&
                tipoArtroplastiaId !== 'PATELO_FEMORAL'
            ) {
                mutable.values.tipoArtroplastiaForm.patela.delete()
            }

            if (mutable.values.enxertia.foiRealizada.get() !== 'SIM') {
                mutable.values.enxertia.femoral.delete()
                mutable.values.enxertia.tibial.delete()
            }

            if (!mutable.values.enxertia.femoral.get()) {
                mutable.values.enxertia.tipoEnxertiaFemoral.delete()
            }

            if (!mutable.values.enxertia.tibial.get()) {
                mutable.values.enxertia.tipoEnxertiaTibial.delete()
            }

            if (mutable.values.cimentoOsseo.possuiCimentoOsseo.get() !== 'SIM') {
                mutable.values.cimentoOsseo.tipoCimentoOsseo.delete()
                mutable.values.cimentoOsseo.lavagemPulsatil.delete()
            }
        })
        return form.asMap()
    }
    return state
})

export default primariaJoelho
