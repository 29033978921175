
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.23,"time":13800,"words":46}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI `}</li>
      <li parentName="ol">{`Clique no ícone `}<img parentName="li" {...{
          "src": "/images/iconeCadastro.png#center",
          "alt": "Cadastro"
        }}></img></li>
      <li parentName="ol">{`Clique no ícone `}<img parentName="li" {...{
          "src": "/images/iconeStents.png#center",
          "alt": "Stents"
        }}></img></li>
      <li parentName="ol">{`Clique no botão “Registrar novo stent”`}</li>
      <li parentName="ol">{`Informe o nome do stent a ser adicionado`}</li>
      <li parentName="ol">{`Selecione o tipo do stent que está sendo adicionado`}</li>
      <li parentName="ol">{`Clique no botão “Salvar”`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

