import { ReactComponent as EtiquetaBarras } from 'images/ajuda/identificacao-componente/codigodebarras.svg'
import { ReactComponent as EtiquetaReferencia } from 'images/ajuda/identificacao-componente/etiquetacodigoreferencia.svg'
import { ReactComponent as EtiquetaLote } from 'images/ajuda/identificacao-componente/etiquetalote.svg'
import { ReactComponent as EtiquetaValidade } from 'images/ajuda/identificacao-componente/etiquetavalidade.svg'
import { ReactComponent as FormatoValidade } from 'images/ajuda/identificacao-componente/formatoValidade.svg'
import codigoBarras from 'images/ajuda/identificacao-componente/ic-codigodebarras.svg'
import codigoReferencia from 'images/ajuda/identificacao-componente/ic-codigoreferencia.svg'
import dataValidade from 'images/ajuda/identificacao-componente/ic-datavalidade.svg'
import lote from 'images/ajuda/identificacao-componente/ic-lote.svg'
import { BodyItem, CarouselItem, Conteudo, Icon, Linha } from 'libs/components'
import * as React from 'react'
import { SectionWithHelpModal } from 'view/componentes/SectionWithAjudaModal'
import { CodigoBarra, DataFabricacaoField } from 'view/home/rastreabilidade/componentes'
import { IdentificacaoLoteSerieField } from './IdentificacaoLoteSerieField'
import { IdentificacaoProdutoField } from './IdentificacaoProdutoField'
import { ImageEtiquetaField, ImageEtiquetaFieldProps } from './ImageEtiquetaField'
import { ValidadeField } from './ValidadeField'
import { ProdutoRastreabilidadeFormDto } from '../common/useDadosProdutoForm'
import { isImmutable } from 'immutable'
import moment from 'moment'

export interface IdentificacaoComponenteFragmentProps extends ImageEtiquetaFieldProps {
    formFragment: ProdutoRastreabilidadeFormDto
    handleChangeLinhaDigitavel(e: any): void
    dtCirurgia: string
}
export const IdentificacaoComponenteFragment = (props: IdentificacaoComponenteFragmentProps) => {
    const { handleChangeLinhaDigitavel, alertError, formFragment, dtCirurgia } = props
    const anoCirurgia = moment(dtCirurgia)
        .toDate()
        .getFullYear()
    const anoValidade = isImmutable(formFragment.anoValidade)
        ? formFragment.anoValidade.toJS()
        : formFragment.anoValidade
    const mesValidade = isImmutable(formFragment.mesValidade)
        ? formFragment.mesValidade.toJS()
        : formFragment.mesValidade

    return (
        <SectionWithHelpModal title='IDENTIFICAÇÃO DO COMPONENTE' modalBody={bodyItems} modalHeader={headerItems}>
            <CodigoBarra onChangeLinhaDigitavel={handleChangeLinhaDigitavel} />
            <Conteudo>
                <Linha />
                <IdentificacaoProdutoField />
                <IdentificacaoLoteSerieField />
                <DataFabricacaoField name='dataFabricacao' anoCirurgia={anoCirurgia} />
                <ValidadeField
                    anoCirurgia={anoCirurgia}
                    validade={formFragment.validade}
                    ano={anoValidade?.id}
                    mes={mesValidade?.id}
                    disabledMes={!anoValidade}
                    disabledDia={!mesValidade}
                    disabledPeriodo={!formFragment.dataFabricacao || !formFragment.dataFabricacao.mes}
                />
                {formFragment.semRegistroAnvisa && <ImageEtiquetaField alertError={alertError} />}
            </Conteudo>
        </SectionWithHelpModal>
    )
}

const headerItems = [
    <CarouselItem key='car-item-cod-barras' image={codigoBarras} text='Código de barras' />,
    <CarouselItem key='car-item-cod-ref' image={codigoReferencia} text='Código de referência' />,
    <CarouselItem key='car-item-lote' image={lote} text='Lote ou nº de série' />,
    <CarouselItem key='car-item-validade' image={dataValidade} text='Data de validade' />,
]
const bodyItems = [
    <BodyItem key='body-item-id-campo-1'>
        <p>
            Clique no botão <b>Deseja utilizar o leitor de código de barras?</b>, em seguida aponte o seu leitor para o
            código de barras na caixa do produto implantado. Os dados existentes no código de barras serão preenchidos
            em seus respectivos campos.
        </p>
        <div className='is-vertical-flow vertical-gutter'>
            <p>O RNI aceita os seguintes padrões de códigos de barras:</p>
            <EtiquetaBarras />
        </div>
    </BodyItem>,
    <BodyItem key='body-item-id-campo-2'>
        <div className='is-vertical-flow vertical-gutter'>
            <p>
                Encontre o código de referência na etiqueta ou caixa do produto. É comum o número de referência vir
                acompanhado inicialmente pelos termos: <b>Ref, Referência, Código, Cód…</b>
            </p>
            <EtiquetaReferencia />
        </div>
        <p>Insira o código de referência do produto no sistema.</p>
    </BodyItem>,
    <BodyItem key='body-item-id-campo-3'>
        <div className='is-vertical-flow vertical-gutter'>
            <p>
                Encontre o lote ou nº de série na etiqueta ou caixa do produto. É obrigatório o preenchimento de pelo
                menos um destes dois dados, e eles são sinalizados pelos termos: <b>Lot, Lote…</b>
            </p>
            <EtiquetaLote />
        </div>
        <p>Insira o lote ou nº de série do produto no sistema.</p>
    </BodyItem>,
    <BodyItem key='body-item-id-campo-4'>
        <div className='is-vertical-flow vertical-gutter'>
            <p>
                Encontre a validade na etiqueta ou caixa do produto. É comum a validade vir acompanhada do símbolo (
                <Icon icon='ampulheta' size='is-x-small' />
                ), ou pelos termos: <b>Validade, Val, Válido…</b>
            </p>
            <EtiquetaValidade />
        </div>
        <div className='is-vertical-flow vertical-gutter'>
            <p>
                Selecione o formato da validade.
                <br />
                <b>Obs.</b>: Caso a validade seja exposta em formato de período,
                <b> (ex.: 3 anos após a fabricação)</b>, deve-se preencher primeiramente a data de fabricação do
                produto.
            </p>
            <FormatoValidade />
        </div>
        <p>Preencha o campo da validade.</p>
    </BodyItem>,
]
