import Antibioticos from 'api/Antibioticos'
import { promiseReducer, PromiseRecord } from 'redux/middleware/PromiseAction'
import { AntibioticoDto } from 'backend'
import { error } from 'redux/modules/alert'

export const LOAD_ANTIBIOTICOS = 'rni/antibioticos/LOAD_ANTIBIOTICOS'
export const EDIT_ANTIBIOTICOS = 'rni/antibioticos/EDIT_ANTIBIOTICOS'
export const CLEAR = 'rni/antibioticos/CLEAR'
export const NEW_ANTIBIOTICO_SUCCESS = 'rni/antibioticos/NEW_ANTIBIOTICO_SUCCESS'
export const EDIT_ANTIBIOTICO_SUCCESS = 'rni/antibioticos/EDIT_ANTIBIOTICO_SUCCESS'
export const OTM_CHANGE_ATIVO = 'rni/antibioticos/OTM_CHANGE_ATIVO'
export const ATIVO_ROLLBACK = 'rni/antibioticos/ATIVO_ROLLBACK'

export interface AntibioticosState
    extends Readonly<{
        antibioticos: PromiseRecord<AntibioticoDto[]>
        editinAntibiotico: AntibioticoDto
        openModal: boolean
    }> { }

const AntibioticosInitialState: AntibioticosState = {
    antibioticos: {},
    editinAntibiotico: undefined,
    openModal: false,
}

export default function reducer(state = AntibioticosInitialState, action): AntibioticosState {
    switch (action.type) {
        case LOAD_ANTIBIOTICOS:
            return { ...state, antibioticos: promiseReducer(state.antibioticos, action) }
        case EDIT_ANTIBIOTICOS:
            return { ...state, openModal: true, editinAntibiotico: action.antibiotico }
        case NEW_ANTIBIOTICO_SUCCESS:
            return {
                ...state,
                openModal: false,
                antibioticos: addAntibioticoReducer(state.antibioticos, action.antibiotico),
            }
        case EDIT_ANTIBIOTICO_SUCCESS:
            return { ...state, openModal: false, antibioticos: editReducer(state.antibioticos, action.antibiotico) }
        case OTM_CHANGE_ATIVO:
            return { ...state, antibioticos: changeAtivoReducer(state.antibioticos, action.id) }
        case ATIVO_ROLLBACK:
            return { ...state, antibioticos: changeAtivoReducer(state.antibioticos, action.id) }
        case CLEAR:
            return { ...state, openModal: false, editinAntibiotico: undefined }
        default:
            return state
    }
}

export function addAntibioticoReducer(antibioticos: PromiseRecord<AntibioticoDto[]>, antibiotico: AntibioticoDto) {
    return {
        ...antibioticos,
        data: [antibiotico, ...antibioticos.data],
    }
}

export function editReducer(antibioticos: PromiseRecord<AntibioticoDto[]>, antibiotico: AntibioticoDto) {
    const edited: AntibioticoDto = antibioticos.data.find(elem => elem.id === antibiotico.id)
    edited.nome = antibiotico.nome
    return {
        ...antibioticos,
        data: [...antibioticos.data],
    }
}

export const changeAtivoReducer = (antibioticos: PromiseRecord<AntibioticoDto[]>, id: number) => {
    const antibiotico: AntibioticoDto = antibioticos.data.find(elem => elem.id === id)
    antibiotico.ativo = !antibiotico.ativo
    return {
        ...antibioticos,
        data: [...antibioticos.data],
    }
}

export const loadAntibioticos = () => ({
    type: LOAD_ANTIBIOTICOS,
    promise: Antibioticos.loadAll(),
})

export const setAntibioticoEdition = (antibiotico: AntibioticoDto) => ({
    type: EDIT_ANTIBIOTICOS,
    antibiotico,
})

export const clearEditin = () => ({
    type: CLEAR,
})

export const newAntibioticoSuccess = (antibiotico: AntibioticoDto) => ({
    type: NEW_ANTIBIOTICO_SUCCESS,
    antibiotico,
})

export const editAntibioticoSuccess = (antibiotico: AntibioticoDto) => ({
    type: EDIT_ANTIBIOTICO_SUCCESS,
    antibiotico,
})

export const otimistChangeAtivoAntibiotico = (id: number) => ({
    type: OTM_CHANGE_ATIVO,
    id,
})

export const effectiveChangeAtivoAntibiotico = (id: number) => (dispatch, getState) => {
    const antibiotico: AntibioticoDto = getState().antibioticos.antibioticos.data.find(elem => elem.id === id)
    Antibioticos.save(antibiotico).catch(res => {
        dispatch(antibioticoAtivoRollback(id))
        dispatch(
            error({
                mensagem:
                    `Erro ao ativar/desativar ${antibiotico.nome}`,
            })
        )
    })
}

export const antibioticoAtivoRollback = (id: number) => ({
    type: ATIVO_ROLLBACK,
    id,
})
