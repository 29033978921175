/* tslint:disable */
import React from 'react'

const SvgInfo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M12 21a9 9 0 110-18 9 9 0 010 18zm0-16.5a7.5 7.5 0 107.5 7.5A7.51 7.51 0 0012 4.5zm-.65 3.14h1.3a.1.1 0 01.1.1v1.3a.1.1 0 01-.1.1h-1.3a.1.1 0 01-.1-.1v-1.3a.1.1 0 01.1-.1zm0 2.92h1.3a.1.1 0 01.1.1v5.6a.1.1 0 01-.1.1h-1.3a.1.1 0 01-.1-.1v-5.6a.1.1 0 01.1-.1z' />
    </svg>
)

export default SvgInfo
