import * as React from 'react'
import * as C from 'libs/components'
import { FormFieldProps } from 'libs/components'

interface TipoEnxertiaFieldProps extends FormFieldProps {
    label: string
    name: string
    required?: boolean
}

export class TipoEnxertiaField extends React.Component<TipoEnxertiaFieldProps> {
    render() {
        return (
            <C.ControlField label={this.props.label} name={this.props.name} verticalFlow required>
                <C.RadioField name={this.props.name} label='Autóloga particulada' value='AUTOLOGA_PARTICULADA' />
                <C.RadioField name={this.props.name} label='Autóloga bloco' value='AUTOLOGA_BLOCO' />
                <C.RadioField name={this.props.name} label='Homóloga particulada' value='HOMOLOGA_PARTICULADA' />
                <C.RadioField name={this.props.name} label='Homóloga bloco' value='HOMOLOGA_BLOCO' />
                <C.RadioField name={this.props.name} label='Outro' value='OUTRO' />
            </C.ControlField>
        )
    }
}
