/* tslint:disable */
import React from 'react'

const SvgNotificacaoSucesso = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <g>
            <path d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 21.828c-5.428 0-9.828-4.4-9.828-9.828 0-5.428 4.4-9.828 9.828-9.828 5.425.007 9.821 4.403 9.828 9.828 0 5.428-4.4 9.828-9.828 9.828z' />
            <path d='M10.504 13.604L6.9 9.999l-1.9 1.9 5.55 5.55 1.9-1.9-.046-.046L19.008 8.9l-1.9-1.9-6.604 6.604z' />
        </g>
    </svg>
)

export default SvgNotificacaoSucesso
