import * as React from 'react'
import { HomeGestorServicoSaudeHeader } from '../HomeGestorServicoSaudeHeader'
import TipoRevisaoView from 'view/componentes/reports/revisao/TipoRevisaoView'

export const GestorServicoSaudeTipoRevisaoView = () => {
    return (
        <>
            <HomeGestorServicoSaudeHeader />
            <TipoRevisaoView />
        </>
    )
}
