import * as React from 'react'
import { EnteResponsavel } from './EnteResponsavel'
import { Linha, SimNaoRadioField, InputPanel, Column, Columns } from 'libs/components'
import { PrecoProduto } from './PrecoProduto'
import { FormSection } from 'redux-form/immutable'

interface DadosFinanceirosFormSectionProps {
    compradoHospital: string
    disabledInformacoes: boolean
}

export const DadosFinanceirosFormSection = (props: DadosFinanceirosFormSectionProps) => {
    const compradoHospital = props.compradoHospital

    return (
        <FormSection name='dadosFinanceirosForm'>
            <SimNaoRadioField label='O componente foi comprado pelo hospital' name='compradoHospital' required />
            <InputPanel size='medium'>
                <Columns>
                    <Column>
                        <EnteResponsavel
                            required
                            name={compradoHospital === 'NAO' ? 'fornecedor' : 'responsavelRessarcimento'}
                            label={compradoHospital === 'NAO' ? 'Quem forneceu' : 'Ente responsável pelo ressarcimento'}
                            disabledInformacoes={props.disabledInformacoes}
                        />
                    </Column>
                </Columns>
                {(!compradoHospital || compradoHospital === 'SIM') && [
                    <Linha key='1' />,
                    <PrecoProduto key='2' disabledInformacoes={props.disabledInformacoes} />,
                ]}
            </InputPanel>
        </FormSection>
    )
}
