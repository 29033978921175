import { createValidator, required } from 'libs/validation'

export const EnxertiaValidator = createValidator(
    {
        foiRealizada: [required],
    },
    formValidator
)

function formValidator(form) {
    const errors = {}
    if (form.get('foiRealizada') === 'SIM') {
        if (!form.get('femoral') && !form.get('tibial')) {
            errors.foiRealizada = required(undefined)
        }
        if (form.get('femoral')) {
            errors.tipoEnxertiaFemoral = required(form.get('tipoEnxertiaFemoral'))
        }
        if (form.get('tibial')) {
            errors.tipoEnxertiaTibial = required(form.get('tipoEnxertiaTibial'))
        }
        return errors
    }
}
