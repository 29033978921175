import React, { useState, useEffect } from 'react'
import { findDOMNode } from 'react-dom'
import { withRouter } from 'react-router-dom'
import { connect, useSelector, useDispatch } from 'react-redux'
import { DescricaoPerfil, ImagemPerfil, PerfisMedico } from 'api/Perfis'
import PerfilRoute from 'libs/router/PerfilRoute'
import { PerfilEnum, PerfisRni } from 'api/Perfis'
import { closeTooltipRelacaoEmpresas } from 'redux/modules/relacaoEmpresas'
import { Icon, Popover, IconButton, UserInitials, Image } from 'libs/components'

const MenuItem = withRouter(props => {
    const onClick = () => {
        props.history.push(props.url)
    }
    return (
        <li onClick={onClick} name={props.name}>
            <Icon icon={props.icon} />
            {props.showNotificationDot && <span className='notificacao' />}
            {props.text}
        </li>
    )
})

export const MenuCmp = props => {
    const dispatch = useDispatch()
    const [openedMenu, setOpenedMenu] = useState(undefined)
    const [menuDiv, setMenuDiv] = useState(undefined)
    const [target, setTarget] = useState(undefined)

    useEffect(() => {
        setTarget(findDOMNode(menuDiv))
    }, [menuDiv])

    const relacaoEmpresas = useSelector(state => state.relacaoEmpresas)
    const showNotificationDot = relacaoEmpresas?.showNotificationDot
    const openTooltipRelacaoEmpresas = relacaoEmpresas?.openTooltip

    const openMenu = () => {
        if (openTooltipRelacaoEmpresas) {
            handleCloseTooltip()
        }
        setOpenedMenu(true)
    }

    const handleCloseTooltip = () => dispatch(closeTooltipRelacaoEmpresas())

    const closeMenu = () => setOpenedMenu(false)

    return (
        <div>
            <div className='menu-header' ref={setMenuDiv} name='menuHeader' onClick={openMenu}>
                &middot;&middot;&middot;
                {showNotificationDot && <span className='menu-header--notificacao' />}
            </div>
            <Popover
                className='relacao-empresas--tooltip'
                onHide={handleCloseTooltip}
                placement='bottom'
                rootClose={false}
                show={openTooltipRelacaoEmpresas}
                target={target}
            >
                <>
                    Atualize suas <span className='tooltip--text-highlight'>relações com empresas</span>!
                    <IconButton pulledRight onClick={handleCloseTooltip} icon='excluir' />
                </>
            </Popover>
            <Popover
                className='home-menu'
                onHide={closeMenu}
                placement='bottom'
                rootClose={true}
                show={openedMenu}
                target={target}
            >
                <ul>
                    {props.trocarPerfil && (
                        <MenuItem icon='alterarperfil' name='alterarVinculo' text='Vínculos' url='/vinculos' />
                    )}
                    <PerfilRoute
                        perfis={PerfisRni}
                        render={() => (
                            <MenuItem
                                icon='alterarsenha'
                                name='alterarSenha'
                                text='Alterar senha'
                                url='/home/alterarSenha'
                            />
                        )}
                    />
                    <PerfilRoute
                        perfis={PerfisRni}
                        render={() => (
                            <MenuItem
                                icon='dadospessoais'
                                name='atualizarDadosPessoais'
                                text='Atualizar dados pessoais'
                                url='/home/atualizarDadosPessoais'
                            />
                        )}
                    />
                    <PerfilRoute
                        perfis={PerfisMedico}
                        render={() => (
                            <MenuItem
                                icon='relacaoEmpresas'
                                name='relacaoEmpresas'
                                text='Atualizar relações com empresas'
                                url='/home/relacaoEmpresas'
                                showNotificationDot={showNotificationDot}
                            />
                        )}
                    />
                </ul>
            </Popover>
        </div>
    )
}

class Header extends React.Component {
    render() {
        const userRni = PerfisRni.includes(this.props.user.perfil)
        const showMenu = userRni || (!this.props.user.perfilUnico && this.props.user.perfil !== PerfilEnum.ANVISA)

        return (
            <div className='home-header'>
                <UserInitials nome={this.props.user.nome} />
                <div className='content'>
                    <div className='row1'>
                        <div className='info'>
                            <label className='nome'>{this.props.user.nome}</label>
                            <Image className='imagem-perfil' src={ImagemPerfil[this.props.user.perfil]} size='14x14' />
                            <label className='especializacao'>{DescricaoPerfil[this.props.user.perfil]}</label>
                        </div>
                        {showMenu && <MenuCmp user={this.props.user} trocarPerfil={showMenu} />}
                    </div>
                    {this.props.user.hospital && (
                        <span className='estabelecimento'>
                            <label className='local'>{this.props.user.hospital.razaoSocial.toUpperCase()}</label>
                            <label className='cidade'>
                                - {this.props.user.hospital.cidade} {this.props.user.hospital.uf}
                            </label>
                        </span>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
}

const HomeHeader = connect(mapStateToProps)(Header)

export default HomeHeader
