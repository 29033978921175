import { RecebimentoIntegracaoDto } from 'backend'

export const groupRecebimentoContentByDate = (
    recebimentos: RecebimentoIntegracaoDto[]
): Map<string, RecebimentoIntegracaoDto[]> => {
    let result = new Map<string, RecebimentoIntegracaoDto[]>()
    for (let recebimento of recebimentos) {
        if (result.has(recebimento.dataRecebimento)) {
            let value: RecebimentoIntegracaoDto[] = result.get(recebimento.dataRecebimento)
            value.push(recebimento)
            result.set(recebimento.dataRecebimento, value)
        } else {
            result.set(recebimento.dataRecebimento, [recebimento])
        }
    }
    return result
}
