/* tslint:disable */
import React from 'react'

const SvgImprimir = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M18.898 7.4c.928 0 1.684.758 1.684 1.688v6.572c0 .932-.755 1.687-1.684 1.687h-1.613v2.699c0 .635-.518 1.154-1.152 1.154H7.449a1.156 1.156 0 01-1.152-1.154v-2.7H4.684A1.688 1.688 0 013 15.66V9.088c0-.93.757-1.687 1.684-1.684h1.609v-3.25C6.293 3.52 6.811 3 7.445 3h8.684c.635 0 1.153.519 1.153 1.154v3.247h1.616zm-2.711-3.246a.055.055 0 00-.054-.054H7.449a.055.055 0 00-.054.054v3.25h8.792v-3.25zm-.004 8.77H7.395v7.122c0 .029.025.054.054.054h8.684a.055.055 0 00.054-.05l-.004-7.126zm-9.886-.548a.55.55 0 01.55-.552h9.887c.306 0 .552.248.548.552v3.87h1.612a.588.588 0 00.586-.586V9.088a.588.588 0 00-.586-.587H4.684a.588.588 0 00-.586.587v6.572c0 .323.264.587.586.587h1.613v-3.871zm-.94-2.61a.565.565 0 01.78 0 .557.557 0 010 .781.549.549 0 01-.94-.39.55.55 0 01.16-.39zm8.983 6.198a.551.551 0 11-.004 1.104H9.242a.551.551 0 010-1.104h5.098zm0 2.038a.551.551 0 11-.004 1.104H9.242a.551.551 0 010-1.104h5.098zm0-4.08a.551.551 0 11-.004 1.104l-5.094-.001a.551.551 0 010-1.104h5.098z' />
    </svg>
)

export default SvgImprimir
