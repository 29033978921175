import { createValidator, required } from 'libs/validation'

const MotivoRevisaoModalFormValidator = createValidator({}, formValidator)

function formValidator(form) {
    const errors: any = {}

    if (form.get('ativo')) {
        errors.motivos = required(form.get('motivos'))
    }
    return errors
}

export default MotivoRevisaoModalFormValidator
