import TermoUso from 'api/TermoUso'
import { TermoUsoDTO } from 'backend'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'

export const FIND_TERMO_USO_VIGENTE = 'rni/termoUso/FIND_TERMO_USO_VIGENTE'

export interface TermoUsoState
    extends Readonly<{
        error?: any
        termoUso?: PromiseRecord<TermoUsoDTO>
    }> {}

export const TermoUsoInitialState: TermoUsoState = {
    termoUso: {},
}

export default function reducer(state = TermoUsoInitialState, action): TermoUsoState {
    switch (action.type) {
        case FIND_TERMO_USO_VIGENTE:
            return { ...state, termoUso: promiseReducer(state.termoUso, action) }
        default:
            return state
    }
}

export const carregarTermoUsoVigente = () => ({
    type: FIND_TERMO_USO_VIGENTE,
    promise: TermoUso.getTermoUsoVigente(),
})
