import { required, telefone, email, createValidator } from 'libs/validation'

const ContatoProfissionalValidator = createValidator(
    {
        fone1: [required, telefone],
        fone2: telefone,
        email: [required, email],
        confirmacaoEmail: required,
    },
    formValidator
)

function formValidator(form) {
    const errors = {}
    if (form.get('confirmacaoEmail') && form.get('email') !== form.get('confirmacaoEmail')) {
        errors.confirmacaoEmail = 'O e-mail é diferente do informado previamente'
    }
    return errors
}

export default ContatoProfissionalValidator
