/* tslint:disable */
import React from 'react'

const SvgTabela = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M9.125 7h10v2h-10zM5 7h2v2H5zm4.125 4.125h10v2h-10zm-4.125 0h2v2H5zm4.125 4.125h10v2h-10zM5 15.25h2v2H5z' />
    </svg>
)

export default SvgTabela
