import Report from 'api/Report'
import { RelacaoEmpresasRespostaProfissionalDto } from 'backend'
import { useAxiosGet } from 'hooks/useApiRequest'
import emptyIcon from 'images/home/reports/relacaoempresas-empty-state.svg'
import { Card, Column, Columns, Icon, Image, LoadingContainer, Text } from 'libs/components'
import React from 'react'
import RelacaoEmpresasReportProfRow from './RelacaoEmpresasReportProfRow'
import BackButton from 'libs/components/presentational/elements/BackButton'
import { Link } from 'react-router-dom'

const RelacaoEmpresasReportView: React.FC = () => {
    const { data, requestState } = useAxiosGet<RelacaoEmpresasRespostaProfissionalDto[]>(
        Report.getRelacaoEmpresasRespostasProfissionaisUrl()
    )

    const renderTitle: (number) => JSX.Element = (dataLength: number) => {
        const word: string = dataLength > 1 ? 'profissionais' : 'profissional'
        return (
            <Text color='black-bis' weight='semibold' transform='lowercase' size='medium'>
                {`${dataLength} ${word}`}
            </Text>
        )
    }

    const renderDownloadIcon = () => {
        return (
            <Link
                to={{
                    pathname: '/api/report/relacaoEmpresasRespostasProfissionais/csv',
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }

    return (
        <>
            <div className='report-relacao--header'>
                <h1>Relação entre médico e empresa</h1>
            </div>

            <div>
                <BackButton />
            </div>

            <LoadingContainer isFetching={requestState === 'request'} styleContainer={{ marginTop: '84px' }}>
                <>
                    {data?.length > 0 ? (
                        <div className='report-relacao--container'>
                            <Text color='black' weight='bold' size='small-ter'>
                                LISTA DE PROFISSIONAIS
                            </Text>
                            <Card title={renderTitle(data.length)} noPadding headerAction={renderDownloadIcon()}>
                                <div className='report-relacao--subheader'>
                                    <Columns>
                                        <Column size='6'>
                                            <Text weight='semibold' color='grey-darker' size='small-bis'>
                                                Nome
                                            </Text>
                                        </Column>
                                        <Column size='2' customStyle={{ width: '19%' }}>
                                            <Text weight='semibold' color='grey-darker' size='small-bis'>
                                                CPF
                                            </Text>
                                        </Column>
                                        <Column>
                                            <Text weight='semibold' color='grey-darker' size='small-bis'>
                                                Possui / Possuiu relação
                                            </Text>
                                        </Column>
                                    </Columns>
                                </div>
                                {data.map(respostaProfissional => {
                                    return (
                                        <RelacaoEmpresasReportProfRow
                                            key={respostaProfissional.idResposta}
                                            content={respostaProfissional}
                                        />
                                    )
                                })}
                            </Card>
                        </div>
                    ) : (
                        <div className='report-relacao--empty-result'>
                            <Image src={emptyIcon} size='200x200' />
                            <Text color='grey-darker'>Sem resultados</Text>
                        </div>
                    )}
                </>
            </LoadingContainer>
        </>
    )
}

export default RelacaoEmpresasReportView
