import React from 'react'
import * as C from 'libs/components'
import iconContato from 'images/home/cidadao/ic-phone.svg'
import { FormSection } from 'redux-form/immutable'

export default class ContatoForm extends React.Component {
    render() {
        return (
            <FormSection name={this.props.name}>
                <C.Columns>
                    <C.Column verticalFlow size='2'>
                        <C.Image src={iconContato} alignedCenter size='96x96' />
                    </C.Column>
                    <C.Column size='8'>
                        <C.Columns>
                            <C.Column size='3'>
                                <C.MaskedField
                                    required
                                    label='Telefone 1'
                                    name='fone1'
                                    placeholder='(00) 99999-9999'
                                    mask='(99) 99999-9999'
                                />
                            </C.Column>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='3'>
                                <C.MaskedField
                                    label='Telefone 2'
                                    name='fone2'
                                    placeholder='(00) 99999-9999'
                                    mask='(99) 99999-9999'
                                />
                            </C.Column>
                        </C.Columns>
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column size='8' offset='2'>
                        <hr />
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column size='6' offset='2'>
                        <C.EmailField maxLength='80' label='E-mail' name='email' placeholder='exemplo@rni.com.br' />
                    </C.Column>
                </C.Columns>
            </FormSection>
        )
    }
}
