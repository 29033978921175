import * as React from 'react'
import { Columns, Column, NumberLabel } from 'libs/components'

interface Props {
    value: number
    total: number
    text: string
    testid?: string
}

export class ValuePercentLabel extends React.Component<Props> {
    render() {
        const { value, total, text, testid } = this.props
        return (
            <Columns className={'card-padding-left-20'}>
                <Column className='is-semibold is-black-ter'>
                    <p key={text} className={'value-percent-card'} data-testid={testid}>
                        <NumberLabel className={'is-black-bis-16'} value={value} placeholder='0' />
                        <NumberLabel className='is-13-600' placeholder='- %' value={(value / total) * 100} sufix='%' />
                        <span className='percent' style={{ paddingLeft: '4px' }}>
                            {' '}
                            {text}{' '}
                        </span>
                    </p>
                </Column>
            </Columns>
        )
    }
}
