import {
    Column,
    Columns,
    Linha,
    SimNaoRadioField,
    InputPanel,
    Label,
    Form,
    TextLabel,
    ComposedLabel,
    MaskedLabel,
    Conteudo,
    Panel,
    SectionTitle,
    ButtonBar,
    Button,
    IconButton,
} from 'libs/components'
import React, { useEffect, useState } from 'react'
import { FormSection, getFormValues } from 'redux-form/immutable'
import { AjudaFinanceiro } from '../ajuda/AjudaFinanceiro'
import { EnteResponsavel, PrecoProduto } from '../componentes'
import { useSelector } from 'react-redux'
import { AvisoPreenchimentoFicha } from 'view/home/novoProcedimento/ficha/componentes/visualizacao/AvisoPreenchimentoFicha'

const DadosFinanceiros = props => {
    const compradoHospital = props.compradoHospital
    const enteResponsavel =
        compradoHospital && compradoHospital === 'NAO' ? (
            <EnteResponsavel
                required
                name='fornecedor'
                label='Quem forneceu'
                disabledInformacoes={props.disabledInformacoes}
            />
        ) : (
            <EnteResponsavel
                required
                name='responsavelRessarcimento'
                label='Ente responsável pelo ressarcimento'
                disabledInformacoes={props.disabledInformacoes}
            />
        )
    const precoProduto = (!compradoHospital || compradoHospital === 'SIM') && [
        <Linha key='1' />,
        <PrecoProduto key='2' disabledInformacoes={props.disabledInformacoes} />,
    ]

    return (
        <Columns>
            <Column>
                <SimNaoRadioField label='O componente foi comprado pelo hospital' name='compradoHospital' required />
                <InputPanel size='medium'>
                    <Columns>
                        <Column>{enteResponsavel}</Column>
                    </Columns>
                    {precoProduto}
                </InputPanel>
            </Column>
        </Columns>
    )
}

const Etiqueta = props => {
    const { componente, dadosProduto, open } = props

    return (
        <Columns>
            <Column>
                <Label>Foto da etiqueta:</Label>
                <div className='image-dropzone'>
                    <div className='imagem-upload'>
                        <div className='dropzone-upload'>
                            {
                                <img
                                    className='imagem-active'
                                    src={'/api/arquivo/thumbnail/' + dadosProduto.data.imagem.id}
                                    alt=''
                                />
                            }
                        </div>
                        <span className='file-info'>
                            <span className='nome-imagem'> {componente.nome}</span>
                        </span>
                        <div className='icon-detalhes'>{<IconButton icon='detalhes' onClick={open} iconOnly />}</div>
                    </div>
                </div>
            </Column>
        </Columns>
    )
}

const DataFabricacao = props => {
    const { dadosProduto } = props

    return (
        <Columns>
            <Column size='5'>
                <TextLabel
                    name='dataFabricacao'
                    title='Data de fabricação:'
                    value={dadosProduto.data && dadosProduto.data.dataFabricacao}
                />
            </Column>
        </Columns>
    )
}

const DadosFabricante = props => {
    const { dadosProduto } = props
    const registroAnvisa = dadosProduto.data && dadosProduto.data.registroAnvisa
    const labelFabricante = registroAnvisa ? 'Razão social do Detentor do registro:' : 'Fabricante/Importador:'
    const labelCNPJ = registroAnvisa ? 'CNPJ do Detentor do registro:' : 'CNPJ do Fabricante/Importador:'
    const fabricantes = dadosProduto.data && dadosProduto.data.fabricantes

    return (
        <div>
            <Columns auto>
                {registroAnvisa && (
                    <ComposedLabel title='Fabricante(s):' name='fabricante' placeholder='Não cadastrado'>
                        {fabricantes && fabricantes.length > 0 && (
                            <ul className='list-circle'>
                                {fabricantes.map((data, index) => (
                                    <li key={index}> {data.fabricante}</li>
                                ))}
                            </ul>
                        )}
                    </ComposedLabel>
                )}
            </Columns>
            <Columns>
                <Column>
                    {dadosProduto.data && (
                        <MaskedLabel
                            mask='##.###.###/####-##'
                            title={labelCNPJ}
                            value={dadosProduto.data.cnpj}
                            placeholder='Não informado'
                        />
                    )}
                </Column>
            </Columns>
            <Columns>
                <Column className='fabricanteImportador'>
                    <TextLabel
                        name='fabricanteImportador'
                        title={labelFabricante}
                        value={dadosProduto.data && dadosProduto.data.nomeFabricanteImportador}
                        placeholder='Não informado'
                    />
                </Column>
            </Columns>
        </div>
    )
}

const DadosTecnicosProduto = props => {
    const { dadosProduto } = props

    return (
        <div>
            <Columns>
                <Column>
                    <TextLabel
                        name='nomeTecnicoProduto'
                        title='Nome técnico do produto:'
                        value={dadosProduto.data && dadosProduto.data.nomeTecnicoProduto}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <TextLabel
                        name='nomeProduto'
                        title='Nome do produto:'
                        value={dadosProduto.data && dadosProduto.data.nomeProduto}
                    />
                </Column>
            </Columns>
        </div>
    )
}

const IdentificacaoProduto = props => {
    const { dadosProduto } = props

    return (
        <div>
            <Columns>
                <Column size='5'>
                    <TextLabel
                        name='lote'
                        title='Lote:'
                        value={dadosProduto.data && dadosProduto.data.lote}
                        placeholder='Não informado'
                    />
                </Column>
                <Column size='5'>
                    <TextLabel
                        name='numeroSerie'
                        title='Nº de série:'
                        value={dadosProduto.data && dadosProduto.data.numeroSerie}
                        placeholder='Não informado'
                    />
                </Column>
            </Columns>
        </div>
    )
}

const DadosProduto = props => {
    const { componente, dadosProduto, open } = props
    const registroAnvisa = dadosProduto.data && dadosProduto.data.registroAnvisa

    return (
        <Conteudo>
            <Columns>
                <Column size='5'>
                    <TextLabel
                        name='registroAnvisa'
                        title='Registro ANVISA:'
                        placeholder='Etiqueta sem registro da ANVISA'
                        value={registroAnvisa}
                    />
                </Column>
            </Columns>
            <DadosFabricante dadosProduto={dadosProduto} />
            {registroAnvisa && <DadosTecnicosProduto dadosProduto={dadosProduto} />}
            <Columns>
                <Column size='5'>
                    <TextLabel
                        name='identificadorDispositivo'
                        title='Identificador do dispositivo:'
                        value={dadosProduto.data && dadosProduto.data.identificadorDispositivo}
                        placeholder='Não informado'
                    />
                </Column>
                <Column>
                    <TextLabel
                        name='codigoReferencia'
                        title='Código de referência:'
                        value={dadosProduto.data && dadosProduto.data.codigoReferencia}
                        placeholder='Não informado'
                    />
                </Column>
            </Columns>
            <IdentificacaoProduto dadosProduto={dadosProduto} />
            <DataFabricacao dadosProduto={dadosProduto} />
            <Columns>
                <Column size='6'>
                    <TextLabel
                        name='validade'
                        title='Validade:'
                        value={
                            dadosProduto.data && (dadosProduto.data.periodoValidade || dadosProduto.data.dataValidade)
                        }
                    />
                </Column>
            </Columns>
            {dadosProduto && dadosProduto.data && dadosProduto.data.imagem && (
                <Etiqueta dadosProduto={dadosProduto} componente={componente} open={open} />
            )}
        </Conteudo>
    )
}

export interface FinanceiroRastreabilidadeFormProps {
    cancelar(e: any): void
    canSave: boolean
    componente: any
    formName: string
    formSectionName: string
    handleSubmit(): void
    loadDadosProduto(idRegistroProcedimentoComponente: number)
    open(): void
    submitting: boolean
    editMode: boolean
    origemExterna: boolean
}

export const FinanceiroRastreabilidadeForm = (props: FinanceiroRastreabilidadeFormProps) => {
    const [showAjuda, setShowAjuda] = useState<boolean>(false)
    const dadosProduto = useSelector((state: any) => state.rastreabilidade.loadDadosProduto)
    const formValues: any = useSelector(state => getFormValues(props.formName)(state))
    const dadosFinanceirosFormValues: any = formValues?.get(props.formSectionName)
    const compradoHospital: string = dadosFinanceirosFormValues?.get('compradoHospital')
    const disabledDadosEconomicos: boolean = compradoHospital === undefined

    // eslint-disable-next-line
    useEffect(() => props.loadDadosProduto(props.componente.id), [])

    const onAjudaChanged = () => setShowAjuda(!showAjuda)

    return (
        <>
            {showAjuda && <AjudaFinanceiro onClose={onAjudaChanged} active={showAjuda} />}
            <Form {...props}>
                <FormSection name={props.formSectionName}>
                    <Panel className='nome-componente'>{props.componente?.nome}</Panel>
                    {!props.origemExterna && !props.editMode && (
                        <AvisoPreenchimentoFicha preenchendo='dados financeiros' preenchido='dados de produto' />
                    )}
                    <SectionTitle title='DADOS DO PRODUTO' />
                    <DadosProduto dadosProduto={dadosProduto} componente={props.componente} open={props.open} />
                    <SectionTitle title='DADOS FINANCEIROS' onAjudaClick={onAjudaChanged} />
                    <Conteudo>
                        <DadosFinanceiros
                            disabledInformacoes={disabledDadosEconomicos}
                            compradoHospital={compradoHospital}
                        />
                        <Linha />
                        <ButtonBar>
                            <Button size='medium' disabled={props.submitting} onClick={props.cancelar}>
                                Cancelar
                            </Button>
                            <Button
                                size='medium'
                                pulledRight
                                type={props.canSave ? 'success' : 'primary'}
                                loading={props.submitting}
                                onClick={props.handleSubmit}
                            >
                                {props.canSave ? 'Salvar' : 'Próximo componente'}
                            </Button>
                        </ButtonBar>
                    </Conteudo>
                </FormSection>
            </Form>
        </>
    )
}
