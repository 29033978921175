import { defaultErrorResolver } from 'libs/redux-form/form'

export const handleErrorSubmiting = (error, atualFormItemName) => {
    let localErrors = {}
    localErrors[atualFormItemName] = error
    return localErrors
}

export const handleErrorResolver = (error, atualFormItemName) => {
    const localErrors = error ? error[atualFormItemName] : undefined
    return defaultErrorResolver(localErrors)
}
