import classnames from 'classnames'
import * as hibc from 'hibc-parser'
import * as C from 'libs/components'
import * as Util from 'libs/components/Util'
import React from 'react'
import field from 'libs/redux-form/field'
import { decodeGS1 } from 'barcodeParser/gs1Decoder'
import { ProdutoRastreabilidadeIdCmpFormDto } from '../common/useDadosProdutoForm'
import moment from 'moment'

interface State {
    linhaDigitavel: string
}
interface LinhaDigitavelCmpProps {
    disabled: boolean
    maxLength: number
    placeholder: string
    inputRef?: any
    onChangeLinhaDigitavel(values: Partial<ProdutoRastreabilidadeIdCmpFormDto>): void
}
export class LinhaDigitavelCmp extends React.Component<LinhaDigitavelCmpProps, State> {
    constructor(props) {
        super(props)
        this.state = {
            linhaDigitavel: '',
        }
    }
    handleChange(event): void {
        this.setState({ linhaDigitavel: event.target.value })
    }

    handKeypress(event: React.KeyboardEvent<any>): void {
        if (event.key === 'Enter') {
            this.decodeBarcode()
        }
    }

    decodeBarcode(): void {
        const codigoBarra: string = this.state.linhaDigitavel && this.state.linhaDigitavel.replace(';', '/')
        const dadosEtiqueta: Partial<ProdutoRastreabilidadeIdCmpFormDto> = {}
        let valueDecoded = hibc.decode(codigoBarra)

        if (!valueDecoded.error) {
            if (valueDecoded.type === 1) {
                dadosEtiqueta.identificadorDispositivo = codigoBarra.split('/')[0]
            } else if (valueDecoded.type === 2) {
                dadosEtiqueta.identificadorDispositivo = codigoBarra
            }

            const dataValidade = valueDecoded.date && moment(valueDecoded.date)
            if (dataValidade) {
                dadosEtiqueta.validade = 'DATA'
                dadosEtiqueta.anoValidade = { id: dataValidade.year(), nome: dataValidade.year().toString() }
                dadosEtiqueta.mesValidade = meses[dataValidade.month()]
                dadosEtiqueta.diaValidade = { id: dataValidade.date(), nome: dataValidade.date().toString() }
            }
            dadosEtiqueta.codigoReferencia = valueDecoded.product
            dadosEtiqueta.lote = valueDecoded.lot
            dadosEtiqueta.numeroSerie = valueDecoded.serial
        } else {
            const { GTIN, referencia, lote, serial, validade, fabricacao } = decodeGS1(codigoBarra)
            if (lote && lote.substr(lote.length - 1) === 'w') {
                dadosEtiqueta.lote = lote.substr(0, lote.length - 1)
            } else {
                dadosEtiqueta.lote = lote
            }
            dadosEtiqueta.identificadorDispositivo = GTIN
            dadosEtiqueta.codigoReferencia = referencia
            dadosEtiqueta.numeroSerie = serial

            if (fabricacao) {
                const dtFab = fabricacao
                dadosEtiqueta.dataFabricacao = {
                    ano: { id: dtFab.year(), nome: dtFab.year().toString() },
                    mes: meses[dtFab.month()],
                    dia: { id: dtFab.date(), nome: dtFab.date().toString() },
                }
            }
            if (validade) {
                const dtVal = validade
                dadosEtiqueta.validade = 'DATA'
                dadosEtiqueta.anoValidade = { id: dtVal.year(), nome: dtVal.year().toString() }
                dadosEtiqueta.mesValidade = meses[dtVal.month()]
                dadosEtiqueta.diaValidade = { id: dtVal.date(), nome: dtVal.date().toString() }
            }
        }

        this.props.onChangeLinhaDigitavel(dadosEtiqueta)
        this.setState({ linhaDigitavel: '' })
    }

    render() {
        const classes = classnames('linha-digitavel', Util.helpersClassnames(this.props as any))

        return (
            <div className={classes}>
                <C.TextInput
                    disabled={this.props.disabled}
                    maxLength={this.props.maxLength}
                    placeholder={this.props.placeholder}
                    onChange={this.handleChange.bind(this)}
                    onKeyPress={this.handKeypress.bind(this)}
                    value={this.state.linhaDigitavel}
                    ref={this.props.inputRef}
                />
                <C.Button type='primary' onClick={() => this.decodeBarcode()}>
                    Aplicar
                </C.Button>
            </div>
        )
    }
}
const meses = [
    { id: 1, nome: 'Janeiro' },
    { id: 2, nome: 'Fevereiro' },
    { id: 3, nome: 'Março' },
    { id: 4, nome: 'Abril' },
    { id: 5, nome: 'Maio' },
    { id: 6, nome: 'Junho' },
    { id: 7, nome: 'Julho' },
    { id: 8, nome: 'Agosto' },
    { id: 9, nome: 'Setembro' },
    { id: 10, nome: 'Outubro' },
    { id: 11, nome: 'Novembro' },
    { id: 12, nome: 'Dezembro' },
]

export const LinhaDigitavel = field()(LinhaDigitavelCmp)
