import * as React from 'react'
import * as C from 'libs/components'
import UrlParams from 'libs/router/UrlParams'
import { Link, NavLink } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import { TipoCirurgia, DateInterval, InfoProdutoFiltro, InfoProdutoRow, DistribuicaoProcedimentoFiltro } from 'backend'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import {
    ScrollToTop,
    Columns,
    Column,
    PeriodoFilter,
    LoadingContainer,
    Paginator,
    CardFilterable,
} from 'libs/components'
import { InfoProdutoTable } from './InfoProdutoTable'
import { InfoProdutoListFilter } from './InfoProdutoListFilter'
import { Map } from 'immutable'
import { Page } from 'redux/requester'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { ReportInfoBlock } from '../components/ReportInfoBlock'
import BackButton from 'libs/components/presentational/elements/BackButton'
import { stringify } from 'redux/modules/router'

export interface Props extends RouteComponentProps<any> {
    filtro: InfoProdutoFiltro
    page: Page<InfoProdutoRow>
    isFetching: boolean
    changeFilter(filtro: InfoProdutoFiltro): void
    handlePageChange(page: number): void
    load(filtro: InfoProdutoFiltro): void
}

export class InfoProdutoListView extends React.Component<Props> {
    handlePeriodoChange = (interval: DateInterval) => {
        this.props.changeFilter({
            ...this.props.filtro,
            interval: interval,
        })
    }

    handleTabItemClick = (tipoCirurgia: TipoCirurgia) => {
        this.props.changeFilter({
            ...this.props.filtro,
            tipoCirurgia: tipoCirurgia,
        })
    }

    handleFilterSubmit = (values: Map<string, any>) => {
        const { componente, ...v } = values.toJS() as any
        this.props.changeFilter({
            ...this.props.filtro,
            ...v,
            componenteId: componente && componente.id,
        })
    }

    refresh = (filter: InfoProdutoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        this.props.load(filter)
    }

    getFilter = () => {
        const { registroAnvisa, ...currentFilter } = this.props.filtro || {}
        return {
            ...currentFilter,
            tipoCirurgia: currentFilter.tipoCirurgia || 'JOELHO',
        } as DistribuicaoProcedimentoFiltro
    }

    render() {
        const filtro = this.getFilter()
        const { isFetching, page, handlePageChange } = this.props

        return (
            <div>
                <ScrollToTop onMount />
                <UrlParams params={filtro} onChange={this.refresh} />
                <ReportClearFilter />

                <LocalHeaderContainer />
                <ReportHeader title='Informações de produtos'>
                    <div>
                        <BackButton />
                    </div>
                    <HorizontalTabMenu>
                        <HorizontalTabMenuItem
                            title='Joelho'
                            onClick={() => this.handleTabItemClick('JOELHO')}
                            active={filtro.tipoCirurgia === 'JOELHO'}
                        />
                        <HorizontalTabMenuItem
                            title='Quadril'
                            onClick={() => this.handleTabItemClick('QUADRIL')}
                            active={filtro.tipoCirurgia === 'QUADRIL'}
                        />
                    </HorizontalTabMenu>
                </ReportHeader>

                <div className='report-content'>
                    <Columns className='report-tabs align-center'>
                        <Column size='6'>
                            <div className='report-tabs-links'>
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}`,
                                        search: this.props.location.search,
                                    }}
                                    className='is-active'
                                >
                                    Dashboard
                                </NavLink>
                            </div>
                        </Column>
                        <Column size='6' textRight>
                            <PeriodoFilter value={filtro.interval} onChange={this.handlePeriodoChange} />
                        </Column>
                    </Columns>

                    <ReportInfoBlock>
                        Lista de registros ANVISA com a porcentagem de vezes em que foi utilizado e demandou uma
                        cirurgia de revisão, assim como o tempo médio para realização das cirurgias de revisão.
                    </ReportInfoBlock>

                    <CardFilterable
                        title={`${(page && page.totalElements) || 0} registros`}
                        noPadding={!isFetching}
                        filter={<InfoProdutoListFilter filtro={filtro} onSubmit={this.handleFilterSubmit} />}
                        report={
                            <Link
                                to={{
                                    pathname: '/api/report/infoProduto/csv',
                                    search: stringify(this.props.filtro),
                                }}
                                target='_blank'
                                title='Exportar para CSV'
                            >
                                <C.Text color='grey-darker'>
                                    <C.Icon icon='exportar' />
                                </C.Text>
                            </Link>
                        }
                    >
                        <LoadingContainer isFetching={isFetching}>
                            {page && (
                                <>
                                    {page.content.length > 0 && <InfoProdutoTable rows={page.content} />}
                                    {page.content.length < 1 && (
                                        <Column textCentered borderBottom>
                                            <p style={{ lineHeight: 2 }} className='is-text-muted'>
                                                Nenhum registro encontrado.
                                            </p>
                                        </Column>
                                    )}
                                    <Paginator
                                        first={page.first}
                                        last={page.last}
                                        pageNumber={page.number}
                                        totalPages={page.totalPages}
                                        onChange={handlePageChange}
                                    />
                                </>
                            )}
                        </LoadingContainer>
                    </CardFilterable>
                </div>
            </div>
        )
    }
}
