import React from 'react'
import * as C from 'libs/components'

export class CintilografiaMiocardicaCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Cintilografia miocárdica'
                name='cintilografia'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='cintilografia' disabled={this.props.disabled} />}
            >
                <C.Conteudo>
                    <C.Columns>
                        <C.Column size='5' verticalFlow>
                            <C.ControlField label='Avaliação' name='cintilografiaAvaliacao' verticalFlow>
                                <C.ClearableRadioField
                                    name='cintilografiaAvaliacao'
                                    label='Negativo'
                                    value='NEGATIVO'
                                />
                                <C.ClearableRadioField
                                    name='cintilografiaAvaliacao'
                                    label='Positivo'
                                    value='POSITIVO'
                                />
                                <C.ClearableRadioField
                                    name='cintilografiaAvaliacao'
                                    label='Indeterminado'
                                    value='INDETERMINADO'
                                />
                                <C.ClearableRadioField
                                    name='cintilografiaAvaliacao'
                                    label='Não disponível'
                                    value='NAO_DISPONIVEL'
                                />
                            </C.ControlField>
                        </C.Column>
                        <C.Column size='5' verticalFlow>
                            <C.RiscoField name='cintilografiaRisco' />
                        </C.Column>
                    </C.Columns>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
