import { connect } from 'react-redux'
import { State } from 'redux/modules'
import * as antibioticos from 'redux/modules/antibioticos'
import AntibioticosView from './AntibioticosView'
import { AntibioticoDto } from 'backend'

const mapStateToProps = (state: State, ownProps) => {
    return {
        openModal: state.antibioticos.openModal,
        antibioticos: state.antibioticos.antibioticos.data,
        readyState: state.antibioticos.antibioticos.readyState,
        voltar: () => ownProps.history.push('/home'),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        load: () => dispatch(antibioticos.loadAntibioticos()),
        edit: (item: AntibioticoDto) => dispatch(antibioticos.setAntibioticoEdition(item)),
        clear: () => dispatch(antibioticos.clearEditin()),
        saveAtivoAntibiotico: (id: number) => {
            dispatch(antibioticos.otimistChangeAtivoAntibiotico(id))
            dispatch(antibioticos.effectiveChangeAtivoAntibiotico(id))
        },
    }
}

const AntibioticosContainer = connect(mapStateToProps, mapDispatchToProps)(AntibioticosView)

export default AntibioticosContainer
