import React from 'react'
import * as C from 'libs/components'
import field from 'libs/redux-form/field'

class NomeStentFieldCmp extends React.Component {
    render() {
        return (
            <C.AsyncSelect
                {...this.props.input}
                isDisabled={this.props.disabled}
                url='/api/procedimentos/stents'
                placeholder={this.props.placeholder}
            />
        )
    }
}
export const NomeStentField = field()(NomeStentFieldCmp)
