import React from 'react'
import { Column, Columns, EmailField, Image, MaskedField } from 'libs/components'
import iconContato from 'images/home/cidadao/ic-phone.svg'
import { FormSection } from 'redux-form/immutable'

export default class ContatoProfissionalForm extends React.Component {
    render() {
        return (
            <FormSection name={this.props.name}>
                <Columns>
                    <Column verticalFlow size='2'>
                        <Image src={iconContato} alignedCenter size='96x96' />
                    </Column>
                    <Column size='8'>
                        <Columns>
                            <Column size='3'>
                                <MaskedField
                                    required
                                    label='Telefone 1'
                                    name='fone1'
                                    placeholder='(00) 99999-9999'
                                    mask='(99) 99999-9999'
                                />
                            </Column>
                        </Columns>
                        <Columns>
                            <Column size='3'>
                                <MaskedField
                                    label='Telefone 2'
                                    name='fone2'
                                    placeholder='(00) 99999-9999'
                                    mask='(99) 99999-9999'
                                />
                            </Column>
                        </Columns>
                    </Column>
                </Columns>
                <Columns>
                    <Column size='8' offset='2'>
                        <hr />
                    </Column>
                </Columns>
                <Columns>
                    <Column size='6' offset='2'>
                        <EmailField
                            required
                            label='E-mail'
                            name='email'
                            placeholder='exemplo@rni.com.br'
                            maxLength='80'
                        />
                    </Column>
                </Columns>
                <Columns>
                    <Column size='6' offset='2'>
                        <EmailField
                            required
                            disabled={this.props.disableConfirmarEmail}
                            label='Confirme o seu e-mail'
                            maxLength='80'
                            name='confirmacaoEmail'
                            placeholder='exemplo@rni.com.br'
                            onPaste={e => e.preventDefault()}
                            onDrop={e => e.preventDefault()}
                        />
                    </Column>
                </Columns>
            </FormSection>
        )
    }
}
