import React from 'react'
import * as C from 'libs/components'
import * as DateUtil from 'libs/util/DateUtil'
import * as Perfil from 'api/Perfis'

export default class ModalJustificativa extends React.Component {
    render() {
        const conselhoClasse =
            this.props.vinculo &&
            this.props.vinculo.numeroRegistro &&
            `${this.props.vinculo.nomeConselhoClasse} ${this.props.vinculo.numeroRegistro}/${this.props.vinculo.ufRegistro}`

        return (
            <C.ModalCard
                title='Vínculo rejeitado'
                active={this.props.active}
                onClose={this.props.onClose}
                hasCloseButton
            >
                <C.Columns>
                    <C.Column>
                        <C.TextLabel title='Profissional' value={this.props.vinculo && this.props.vinculo.nome} />
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column>
                        <C.TextLabel placeholder='Não informado' title='Registro em conselho' value={conselhoClasse} />
                    </C.Column>
                    <C.Column>
                        <C.TextLabel
                            title='Especialidade'
                            value={this.props.vinculo && Perfil.DescricaoPerfilCaptalize[this.props.vinculo.perfil]}
                        />
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column>
                        <C.MaskedLabel
                            title='CPF'
                            value={this.props.vinculo && this.props.vinculo.cpf}
                            mask='###.###.###-##'
                        />
                    </C.Column>
                    <C.Column>
                        <C.MaskedLabel
                            title='CNS'
                            value={this.props.vinculo && this.props.vinculo.cns}
                            mask='###.####.####.####'
                            placeholder='Não informado'
                        />
                    </C.Column>
                </C.Columns>
                <C.Linha />
                <C.Columns>
                    <C.Column>
                        <C.TextLabel
                            title='Justificativa para rejeição'
                            value={this.props.vinculo && this.props.vinculo.motivoRecusa}
                        />
                    </C.Column>
                </C.Columns>
                <C.Label className='label-prof-recusa'>
                    {`Vínculo rejeitado por ${this.props.vinculo && this.props.vinculo.nomeProfissionalRecusa} em ${this
                        .props.vinculo && DateUtil.asClientFormat(this.props.vinculo.dataRecusa)}`}
                </C.Label>
            </C.ModalCard>
        )
    }
}
