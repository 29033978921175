/* tslint:disable */
import React from 'react'

const SvgFiltroProcedimento = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M10.316 18.85c.152.411.356.797.604 1.15H5a1 1 0 01-1-1V4a1 1 0 011-1h12a1 1 0 011 1v8.92a5.069 5.069 0 00-1.15-.604V4.15H14V5a1 1 0 01-1 1H9a1 1 0 01-1-1v-.85H5.15v14.7h5.166zm.705-4.823a.575.575 0 01.229-1.102h.909a5.112 5.112 0 00-1.138 1.102zm.229-3.952a.575.575 0 010-1.15h3.5a.575.575 0 010 1.15h-3.5zM8 8h1a1 1 0 011 1v1a1 1 0 01-1 1H8a1 1 0 01-1-1V9a1 1 0 011-1zm.15 1.85h.7v-.7h-.7v.7zM8 12h1a1 1 0 011 1v1a1 1 0 01-1 1H8a1 1 0 01-1-1v-1a1 1 0 011-1zm.15 1.85h.7v-.7h-.7v.7zm1-9h3.7v-.7h-3.7v.7zm5.933 15.694c-1.842 0-3.333-1.522-3.333-3.397 0-1.875 1.491-3.397 3.333-3.397s3.333 1.522 3.333 3.397a3.43 3.43 0 01-.599 1.94l1.373 1.401a.604.604 0 010 .843.587.587 0 01-.842 0l-1.366-1.394a3.272 3.272 0 01-1.899.607zm-.01-.974c1.295 0 2.349-1.083 2.349-2.423s-1.054-2.422-2.349-2.422c-1.295 0-2.348 1.083-2.348 2.422 0 1.34 1.053 2.423 2.348 2.423z' />
    </svg>
)

export default SvgFiltroProcedimento
