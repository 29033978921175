import { required, nome, dataNasc, cns, createValidator } from 'libs/validation'
import { ProfissionalEnderecoFormValidator } from 'libs/components/presentational/form/composed/EnderecoFormValidator'
import ContatoProfissionalValidator from 'view/public/profissional/ContatoProfissionalValidator'

const AlterarDadosPessoaisValidator = createValidator({
    cns: cns,
    nome: [required, nome],
    sexo: [required],
    dataNasc: [required, dataNasc],
    enderecoForm: ProfissionalEnderecoFormValidator,
    contatoForm: ContatoProfissionalValidator,
})

export default AlterarDadosPessoaisValidator
