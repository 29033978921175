import { Perfil, UsoSistemaProfissionaisDto } from 'backend'
import { Card, Column, Columns, LoadingStateContainer, NumberLabel } from 'libs/components'
import * as React from 'react'
import { InvertedLabel } from 'view/componentes/reports/components/InvertedLabel'
import { ValuePercentLabel } from 'view/componentes/reports/components/ValuePercentLabel'

interface Props {
    report: UsoSistemaProfissionaisDto
    readyState: 'request' | 'success' | 'failure'
}

export class TotalProfissionaisCard extends React.Component<Props> {
    render() {
        const getQuantMedicos = (profCardio: Perfil, profOrto: Perfil) => {
            if (report?.quantidadeProfMap[profCardio] && report?.quantidadeProfMap[profOrto]) {
                return report?.quantidadeProfMap[profCardio] + report?.quantidadeProfMap[profOrto]
            } else if (report?.quantidadeProfMap[profCardio]) {
                return report?.quantidadeProfMap[profCardio]
            } else if (report?.quantidadeProfMap[profOrto]) {
                return report?.quantidadeProfMap[profOrto]
            }
            return null
        }

        const { report, readyState } = this.props
        return (
            <Card title={'Total de profissionais'} testid={'card-totalProfissionais'}>
                <LoadingStateContainer readyState={readyState}>
                    <Columns>
                        <Column size='6' alignedCenter>
                            <InvertedLabel
                                title='Profissionais que realizaram registros'
                                testid={'invertedLabel-totalProfissionais'}
                            >
                                <NumberLabel value={report?.quantidadeProfissionais} placeholder='-' />
                            </InvertedLabel>
                        </Column>
                        <Column className='is-semibold is-black-ter'>
                            <ValuePercentLabel
                                value={getQuantMedicos('MEDICO_CARDIOLOGISTA', 'MEDICO_ORTOPEDISTA')}
                                total={report?.quantidadeProfissionais}
                                text={'Médicos'}
                                testid={'valuePercentLabel-medicos'}
                            />
                            <ValuePercentLabel
                                value={getQuantMedicos('RESIDENTE_CARDIOLOGISTA', 'RESIDENTE_ORTOPEDISTA')}
                                total={report?.quantidadeProfissionais}
                                text={'Residentes'}
                                testid={'valuePercentLabel-residentes'}
                            />
                            <ValuePercentLabel
                                value={report?.quantidadeProfMap['TECNICO']}
                                total={report?.quantidadeProfissionais}
                                text={'Técnicos'}
                                testid={'valuePercentLabel-tecnicos'}
                            />
                            <ValuePercentLabel
                                value={report?.quantidadeProfMap['ADMINISTRATIVO']}
                                total={report?.quantidadeProfissionais}
                                text={'Administrativo'}
                                testid={'valuePercentLabel-administrativo'}
                            />
                        </Column>
                    </Columns>
                </LoadingStateContainer>
            </Card>
        )
    }
}
