import * as React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

interface ReCaptchaProps {
    onChange?: () => void
    inputRef?: () => void
    size?: string
}

export class ReCaptcha extends React.Component<ReCaptchaProps> {
    private keys: {}

    constructor(props) {
        super(props)
        this.keys = {
            visibleKey: '6LfW-CUUAAAAAJ1d9mS-UufVpcdV64zneiIVTzGi',
            invisibleKey: '6LdWoi4UAAAAAJ-ZK9hbKmOVaq_jxCZwDa7pTNxa',
        }
    }

    render() {
        return (
            <ReCAPTCHA
                ref={this.props.inputRef}
                sitekey={this.props.size === 'invisible' ? this.keys['invisibleKey'] : this.keys['visibleKey']}
                size={this.props.size}
                onChange={this.props.onChange}
            />
        )
    }
}
