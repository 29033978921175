
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.98,"time":58800,"words":196}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "técnico"
    }}>{`Técnico`}</h3>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique no botão “Registrar novo procedimento”`}</li>
      <li parentName="ol">{`Busque o profissional que participou da equipe médica do procedimento, por meio do “Nome” ou “Nº do registro” e clique no botão “Pesquisar”`}</li>
      <li parentName="ol">{`Selecione o profissional e clique em “Adicionar profissional"`}</li>
      <li parentName="ol">{`Repita os passos 3 e 4 até adicionar todos os profissionais na equipe médica`}</li>
      <li parentName="ol">{`A partir dos profissionais adicionados, selecione o médico responsável pelo procedimento e clique em “Confirmar equipe médica”`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` O médico residente não pode ser o responsável pelo procedimento.`}</p>
    </blockquote>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Procure o paciente através do “CPF” ou “CNS” e clique no botão “Pesquisar”`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{`  Se o sistema não encontrar o paciente, você deverá cadastrá-lo acionando a opção “Adicionar paciente”.`}</p>
    </blockquote>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Verifique se o paciente é o correto e clique no botão “Confirmar paciente”`}</li>
    </ol>
    <p>{`Após a confirmação, o procedimento é adicionado à lista de “Aguardando dados clínicos” e estará disponível para um profissional da equipe médica preencher os dados clínicos.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona cada etapa do preenchimento do registro, `}<a parentName="p" {...{
          "href": "../../fluxos/fluxoPreenchimentoRegistros"
        }}><strong parentName="a">{`clique aqui`}</strong></a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona o registro de um novo procedimento de angioplastia, consulte o vídeo explicativo abaixo:`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "técnico",
    level: 3,
    title: "Técnico",
    children: [
        
      ]
  }
]

export const frontMatter = {}

