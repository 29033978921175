import * as React from 'react'
import * as C from 'libs/components'
import { capitalizeName } from 'libs/util/StringUtil'
import { DistClassificacaoLocal, ResumoLocalItem } from 'redux/modules/report/procedimento'
import * as enums from 'redux/enums'

interface Props {
    distClassificacaoLocal: DistClassificacaoLocal
    showPrimariaRevisaoRatio: boolean
    onItemClick(item: ResumoLocalItem): void
}

export class ResumoLocalTable extends React.Component<Props> {
    handleItemClick(item: ResumoLocalItem) {
        this.props.onItemClick(item)
    }

    render() {
        const dist = this.props.distClassificacaoLocal
        const result = dist.result || []
        const maxQuocient = Math.max(...result.map(res => res.quocient))

        if (!result || result.length === 0) {
            return null
        }

        const groups = this.props.distClassificacaoLocal.data ? this.props.distClassificacaoLocal.data.filtro.group : []
        let localTitle = ''
        if (groups.indexOf('LOCAL_HOSPITAL') >= 0) {
            localTitle = 'Hospital'
        } else if (groups.indexOf('LOCAL_CIDADE') >= 0) {
            localTitle = 'Município'
        } else if (groups.indexOf('LOCAL_UF') >= 0) {
            localTitle = 'UF'
        } else {
            localTitle = 'Região'
        }

        return (
            <C.Card title={'Por ' + localTitle}>
                <table className='table is-borderless is-small is-semibold spacing-one-half'>
                    <thead>
                        <tr>
                            <th>{localTitle}</th>
                            <th></th>
                            <th>
                                <p className='has-text-centered'>Total</p>
                            </th>
                            {this.props.showPrimariaRevisaoRatio &&
                                Object.entries(result[0].classificacoes).map(([key, value]) => (
                                    <th key={key} title={value.title}>
                                        <p className='has-text-centered'>{value.title}</p>
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {result.map((res, idx) => {
                            const quocients = Object.entries(res.classificacoes).reduce(
                                (obj, [key, value]) => ({
                                    ...obj,
                                    [value.className]: value.quocient,
                                }),
                                {}
                            )

                            const title =
                                (res.local.hospital &&
                                    (res.local.hospital.nomeFantasia || res.local.hospital.razaoSocial)) ||
                                (res.local.cidade && capitalizeName(res.local.cidade.nome)) ||
                                enums.ufName[res.local.uf] ||
                                enums.regiaoName[res.local.regiao]
                            const barWidth = Math.max(res.quocient / maxQuocient, 0.05) // Mínimo de width para não quebrar o layout

                            return (
                                <tr key={idx}>
                                    <td className='is-black-bis'>
                                        <p className='table-link' onClick={this.handleItemClick.bind(this, res)}>
                                            {title}
                                            <C.Icon icon='acessar' />
                                        </p>
                                    </td>
                                    <td style={{ width: '40%' }}>
                                        <div style={{ width: barWidth * 100 + '%' }}>
                                            <C.ProgressMultiple size='x-small' values={quocients} />
                                        </div>
                                    </td>
                                    <td className='has-text-centered is-black-bis'>{res.total}</td>
                                    {this.props.showPrimariaRevisaoRatio &&
                                        Object.entries(result[0].classificacoes).map(([key, value]) => (
                                            <td
                                                key={key}
                                                title={value.title}
                                                className={'has-text-centered ' + value.className}
                                            >
                                                {res.classificacoes[key].total}
                                            </td>
                                        ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </C.Card>
        )
    }
}
