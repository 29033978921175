import React from 'react'
import axios from 'axios'
import { Switch, Route, Redirect } from 'react-router-dom'
import PrivateRoute from 'libs/router/PrivateRoute'
import LoginContainer from 'view/login/LoginContainer'
import HomeContainer from 'view/home/HomeContainer'
import PerfisContainer from 'view/perfis/PerfisContainer'
import TermoUsoContainer from 'view/termoUso/TermoUsoContainer'
import LogoutView from 'view/logout/LogoutView'
import PublicView from 'view/public/PublicView'
import ForbiddenView from 'view/public/ForbiddenView'
import DebugView from 'view/debug/DebugView'
import { HeaderContainer } from 'libs/components/container/HeaderContainer'
import { AlertBarContainer } from 'libs/components/container/alert/AlertBarContainer'

import Store from 'redux/Store'
import config from 'config.json'
import env from 'env.json'

const HomeRoute = ({ component, ...rest }) => (
    <PrivateRoute
        {...rest}
        render={props => {
            if (!Store.getState().auth.user.perfil) {
                return <Redirect to={{ pathname: '/vinculos' }} />
            } else if (!Store.getState().auth.user.aceitouTermo) {
                return <Redirect to={{ pathname: '/termo' }} />
            } else {
                return React.createElement(component, props)
            }
        }}
    />
)

export default class RootView extends React.Component {
    sessionTimer

    constructor(props) {
        super(props)

        this.autoLogout = this.autoLogout.bind(this)
    }

    autoLogout(eve) {
        if (eve.key === 'logout') {
            this.props.finishLogout()
        } else if (eve.key === 'login') {
            this.props.history.push('/home')
        }
    }

    componentDidMount() {
        let timeoutProp = env.modified['AXIOS_TIMEOUT']
        let timeout = timeoutProp && Number(timeoutProp) ? Number(timeoutProp) : 20000

        axios.defaults.timeout = timeout
        axios.interceptors.response.use(
            response => {
                // Do something with response data
                this.checkTimeout()
                return response
            },
            error => {
                // Do something with response error
                if (error.response && 401 === error.response.status) {
                    this.props.logout()
                } else {
                    this.checkTimeout()
                }

                return Promise.reject(error)
            }
        )
        window.addEventListener('storage', this.autoLogout, false)
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.autoLogout, false)
    }

    render() {
        return (
            <div className='is-vertical-flow is-full-height'>
                <HeaderContainer />
                <AlertBarContainer />
                <Switch>
                    <Redirect from='/login' to='/' />
                    <Route exact path='/' component={LoginContainer} />
                    <Route exact path='/recuperarSenha' component={LoginContainer} />
                    <HomeRoute path='/home' component={HomeContainer} />
                    <PrivateRoute path='/vinculos' component={PerfisContainer} />
                    <PrivateRoute path='/termo' component={TermoUsoContainer} />
                    <PrivateRoute path='/logout' component={LogoutView} />
                    <Route path='/public' component={PublicView} />
                    <Route path='/forbidden' component={ForbiddenView} />
                    <Route path='/debug' component={DebugView} />
                </Switch>
            </div>
        )
    }

    /**
     * Timer que expira quando a sessão expirar.
     * É reiniciado a cada requisição que o servidor responde.
     * Não funciona com multitab.
     */
    checkTimeout() {
        if (this.sessionTimer) {
            clearTimeout(this.sessionTimer)
        }
        this.sessionTimer = setTimeout(() => {
            this.props.logout()
        }, config.session.timeout)
    }
}
