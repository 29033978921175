import { PerfisOrtopedistas } from 'api/Perfis'
import { tiposProcedimentoDescricaoByEnum, tiposProcedimentoUrlByEnum } from 'api/Procedimento'
import iconPaciente from 'images/home/cidadao/ic-paciente.svg'
import * as C from 'libs/components'
import { AlertModalDelete, AlertModalError } from 'libs/components'
import { notFoundErrorText, notFoundErrorTitle } from 'libs/util/NotFoundErrorUtil'
import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import PrintIcon from 'view/componentes/PrintIcon'
import HistoricoPopover from 'view/home/componentes/HistoricoPopover'
import { TabItem, TabMenu } from 'view/home/componentes/HomeMenu'

const DadosClinicosCmp = props => {
    const { loadDadosClinicos } = props
    useEffect(() => {
        loadDadosClinicos()
    }, [loadDadosClinicos])
    const [openForm, setOpenForm] = useState(undefined)

    const preencherDadosClinicos = ficha => props.preencherDadosClinicos(ficha)
    const makeOpen = rascunhoId => setOpenForm(rascunhoId)
    const close = () => setOpenForm(undefined)
    const makeOk = rascunhoId => {
        props.removerRascunho(rascunhoId)
        setOpenForm(false)
    }

    return (
        <div className='home-perfil-conteudo'>
            <C.LoadingContainer isFetching={props.isFetching}>
                <span className='titulo'>Aguardando dados clínicos</span>
                <C.Table
                    name='dadoClinico'
                    addRowSpacer
                    className='table-home table-home-medico'
                    values={props.pendencias}
                    emptyText='Nenhuma pendência encontrada.'
                >
                    <C.TableColumnHeader size='1' render={() => <C.Image src={iconPaciente} size='48x48' />} />
                    <C.TableColumnHeader
                        render={value => (
                            <div>
                                <div className='is-semibold'>{value.paciente.nome}</div>
                                {props.ortopedista && (
                                    <span className='procedimento'>
                                        {tiposProcedimentoDescricaoByEnum[value.tipoProcedimento]}
                                        {value && value.lado && ' ' + value.lado.toLowerCase()}
                                    </span>
                                )}
                                <div>
                                    <div className='registrado-por'>
                                        <C.Icon icon='historicoalteracoes' size='is-12px' />
                                        {value.tecnicoNome}
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                    <C.TableColumnHeader
                        size='4'
                        render={value => [
                            <C.Hint
                                key='2'
                                placement='top'
                                componente={
                                    <C.IconButton
                                        icon='remover'
                                        type='light'
                                        pulledRight
                                        onClick={() => makeOpen(value.rascunhoId)}
                                    />
                                }
                            >
                                Excluir
                            </C.Hint>,
                            <AlertModalDelete
                                key='3'
                                active={openForm === value.rascunhoId}
                                onClose={close}
                                onOk={() => makeOk(value.rascunhoId)}
                            />,
                            <C.Hint
                                key='4'
                                placement='top'
                                componente={
                                    <C.IconButton
                                        icon='editar'
                                        type='light'
                                        pulledRight
                                        onClick={() =>
                                            props.history.push(`/home/edicaoProcedimento/preFicha/${value.rascunhoId}`)
                                        }
                                    />
                                }
                            >
                                Editar
                            </C.Hint>,
                            <C.Hint
                                key='1'
                                placement='top'
                                componente={
                                    <C.Button
                                        className='preencher'
                                        pulledRight
                                        icon='dadosclinicos'
                                        onClick={() => preencherDadosClinicos(value)}
                                    >
                                        Preencher
                                    </C.Button>
                                }
                            >
                                Preencher dados clínicos
                            </C.Hint>,
                        ]}
                    />
                </C.Table>
            </C.LoadingContainer>
        </div>
    )
}

class DadosProduto extends React.Component {
    componentDidMount() {
        this.props.loadDadosProduto()
    }

    render() {
        const preencherDadosProduto = ficha => {
            return () => {
                this.props.preencherDadosProduto(ficha)
            }
        }

        const visualizarFicha = (idProced, procedimento) => {
            return () => {
                this.props.visualizarFicha(idProced, procedimento)
            }
        }

        const imprimirFicha = idProced => {
            return () => {
                this.props.imprimirFicha(idProced)
            }
        }

        return (
            <div className='home-perfil-conteudo'>
                <C.LoadingContainer isFetching={this.props.isFetching}>
                    <span className='titulo'>Aguardando dados de produto</span>
                    <C.Table
                        name='dadoProduto'
                        addRowSpacer
                        className='table-home table-home-medico'
                        values={this.props.pendencias}
                        emptyText='Nenhuma pendência encontrada.'
                    >
                        <C.TableColumnHeader size='1' render={() => <C.Image src={iconPaciente} size='48x48' />} />
                        <C.TableColumnHeader
                            render={value => (
                                <div>
                                    <div className='is-semibold'>{value.paciente.nome}</div>
                                    {this.props.ortopedista && (
                                        <span className='procedimento'>
                                            {tiposProcedimentoDescricaoByEnum[value.tipoProcedimento]}
                                            {value && value.lado && ' ' + value.lado.toLowerCase()}
                                        </span>
                                    )}
                                    <span className='data-procedimento'> ({value.dataCirurgia})</span>
                                    <div>
                                        <HistoricoPopover className='registrado-por' registro={value.procedimentoId}>
                                            <C.Icon icon='historicoalteracoes' size='is-12px' />
                                            {value.profUltimaAlteracaoNome}
                                        </HistoricoPopover>
                                    </div>
                                </div>
                            )}
                        />
                        <C.TableColumnHeader
                            size='5'
                            render={value => [
                                !value.origemExterna && (
                                    <C.Hint
                                        key='1'
                                        placement='top'
                                        componente={
                                            <C.IconButton
                                                icon='editar'
                                                type='light'
                                                pulledRight
                                                onClick={() => {
                                                    const tipoProcedimento =
                                                        tiposProcedimentoUrlByEnum[value.tipoProcedimento]
                                                    this.props.history.push(
                                                        `/home/edicaoProcedimento/${tipoProcedimento}/${value.procedimentoId}`
                                                    )
                                                }}
                                            />
                                        }
                                    >
                                        Editar
                                    </C.Hint>
                                ),
                                <C.Hint
                                    key='2'
                                    placement='top'
                                    componente={
                                        <C.IconButton
                                            icon='detalhes'
                                            pulledRight
                                            type='light'
                                            onClick={visualizarFicha(value.procedimentoId, value.tipoProcedimento)}
                                        />
                                    }
                                >
                                    Visualizar registro
                                </C.Hint>,
                                <PrintIcon key='3' onClick={imprimirFicha(value.procedimentoId)} />,
                                <C.Hint
                                    key='4'
                                    placement='top'
                                    componente={
                                        <C.Button
                                            className='preencher'
                                            pulledRight
                                            icon='dadosproduto'
                                            onClick={preencherDadosProduto(value)}
                                        >
                                            Preencher
                                        </C.Button>
                                    }
                                >
                                    Preencher dados de produtos
                                </C.Hint>,
                            ]}
                        />
                    </C.Table>
                </C.LoadingContainer>
            </div>
        )
    }
}

class DadosFinanceiros extends React.Component {
    constructor() {
        super()

        this.loadTable = this.loadTable.bind(this)
    }

    componentDidMount() {
        this.loadTable({})
    }

    loadTable(pageable) {
        this.props.loadDadosFinanceiro(pageable)
    }

    render() {
        const editarDadosProduto = ficha => {
            return () => {
                this.props.editarDadosProduto(ficha)
            }
        }

        const visualizarFicha = (idProced, procedimento) => {
            return () => {
                this.props.visualizarFicha(idProced, procedimento)
            }
        }

        const imprimirFicha = idProced => {
            return () => {
                this.props.imprimirFicha(idProced)
            }
        }

        return (
            <div className='home-perfil-conteudo'>
                <C.LoadingContainer isFetching={this.props.isFetching}>
                    <span className='titulo'>Aguardando dados financeiros</span>
                    <C.PagedTable
                        addRowSpacer
                        className='table-home table-home-medico'
                        page={this.props.pendencias}
                        onChangePage={this.loadTable}
                        emptyText='Nenhuma pendência encontrada.'
                    >
                        <C.TableColumnHeader size='1' render={() => <C.Image src={iconPaciente} size='48x48' />} />
                        <C.TableColumnHeader
                            render={value => (
                                <div>
                                    <div className='is-semibold'>{value.paciente.nome}</div>
                                    {this.props.ortopedista && (
                                        <span className='procedimento'>
                                            {tiposProcedimentoDescricaoByEnum[value.tipoProcedimento]}
                                            {value && value.lado && ' ' + value.lado.toLowerCase()}
                                        </span>
                                    )}
                                    <span className='data-procedimento'> ({value.dataCirurgia})</span>
                                    <div>
                                        <HistoricoPopover className='registrado-por' registro={value.procedimentoId}>
                                            <C.Icon icon='historicoalteracoes' size='is-12px' />
                                            {value.profUltimaAlteracaoNome}
                                        </HistoricoPopover>
                                    </div>
                                </div>
                            )}
                        />
                        <C.TableColumnHeader
                            size='3'
                            render={value => [
                                !value.origemExterna && (
                                    <C.Hint
                                        key='1'
                                        placement='top'
                                        componente={
                                            <C.IconButton
                                                icon='editar'
                                                type='light'
                                                pulledRight
                                                onClick={editarDadosProduto(value)}
                                            />
                                        }
                                    >
                                        Editar
                                    </C.Hint>
                                ),
                                <C.Hint
                                    key='2'
                                    placement='top'
                                    componente={
                                        <C.IconButton
                                            icon='detalhes'
                                            pulledRight
                                            type='light'
                                            onClick={visualizarFicha(value.procedimentoId, value.tipoProcedimento)}
                                        />
                                    }
                                >
                                    Visualizar registro
                                </C.Hint>,
                                <PrintIcon key='3' onClick={imprimirFicha(value.procedimentoId)} />,
                            ]}
                        />
                    </C.PagedTable>
                </C.LoadingContainer>
            </div>
        )
    }
}

class DadosHistorico extends React.Component {
    constructor() {
        super()

        this.loadTable = this.loadTable.bind(this)
    }

    componentDidMount() {
        this.loadTable({})
    }

    loadTable(pageable) {
        this.props.loadDadosHistorico(pageable)
    }

    render() {
        const visualizarFicha = (idProced, procedimento) => {
            return () => {
                this.props.visualizarFicha(idProced, procedimento)
            }
        }

        const imprimirFicha = idProced => {
            return () => {
                this.props.imprimirFicha(idProced)
            }
        }

        return (
            <div className='home-perfil-conteudo'>
                <C.LoadingContainer isFetching={this.props.isFetching}>
                    <span className='titulo'>Histórico</span>
                    <C.PagedTable
                        name='historico'
                        addRowSpacer
                        className='table-home table-home-medico'
                        page={this.props.pendencias}
                        onChangePage={this.loadTable}
                        emptyText='Nenhum procedimento finalizado.'
                    >
                        <C.TableColumnHeader size='1' render={() => <C.Image src={iconPaciente} size='48x48' />} />
                        <C.TableColumnHeader
                            render={value => (
                                <div>
                                    <div className='is-semibold'>{value.paciente.nome}</div>
                                    {this.props.ortopedista && (
                                        <span className='procedimento'>
                                            {tiposProcedimentoDescricaoByEnum[value.tipoProcedimento]}
                                            {value.tipoProcedimento !== 'STENT_CORONARIA' &&
                                                ' ' + value.lado.toLowerCase()}
                                        </span>
                                    )}
                                    <span className='data-procedimento'> ({value.dataCirurgia})</span>
                                    <div>
                                        <HistoricoPopover className='registrado-por' registro={value.procedimentoId}>
                                            <C.Icon icon='historicoalteracoes' size='is-12px' />
                                            {value.profUltimaAlteracaoNome}
                                        </HistoricoPopover>
                                    </div>
                                </div>
                            )}
                        />
                        <C.TableColumnHeader
                            size='2'
                            render={value => [
                                <C.Hint
                                    key='1'
                                    placement='top'
                                    componente={
                                        <C.IconButton
                                            icon='detalhes'
                                            pulledRight
                                            type='light'
                                            onClick={visualizarFicha(value.procedimentoId, value.tipoProcedimento)}
                                        />
                                    }
                                >
                                    Visualizar registro
                                </C.Hint>,
                                <PrintIcon key='2' onClick={imprimirFicha(value.procedimentoId)} />,
                            ]}
                        />
                    </C.PagedTable>
                </C.LoadingContainer>
            </div>
        )
    }
}

export default class HomeMedicoView extends React.Component {
    render() {
        const isOrtopedista = PerfisOrtopedistas.includes(this.props.perfil)
        return (
            <>
                <AlertModalError
                    active={this.props.showNotFoundModalError}
                    title={notFoundErrorTitle}
                    onClose={this.props.onCloseNotFoundModal}
                >
                    {notFoundErrorText}
                </AlertModalError>
                <div className='home-perfil-container is-vertical-flow is-full-height has-text-centered'>
                    <C.Columns fullHeight>
                        <C.Column verticalFlow size='2'>
                            <TabMenu>
                                <TabItem icon='dadosclinicos' path={'/home'}>
                                    Aguardando dados clínicos
                                </TabItem>
                                <TabItem icon='dadosproduto' path={'/home/aguardandoDadosProduto'}>
                                    Aguardando dados de produto
                                </TabItem>
                                <TabItem icon='dadosfinanceiros' path={'/home/aguardandoDadosFinanceiros'}>
                                    Aguardando dados financeiros
                                </TabItem>
                                <TabItem icon='historico' path={'/home/historico'}>
                                    Histórico
                                </TabItem>
                            </TabMenu>
                        </C.Column>
                        <C.Column offset='1' size='8'>
                            <C.Button
                                className='btn-principal'
                                type='primary'
                                size='medium'
                                shadow
                                onClick={this.props.onSelecionarNovoProcedimento}
                            >
                                Registrar novo procedimento
                            </C.Button>
                            <Route
                                exact
                                path={'/home'}
                                render={routeProps => (
                                    <DadosClinicosCmp
                                        ortopedista={isOrtopedista}
                                        preencherDadosClinicos={this.props.preencherDadosClinicos}
                                        removerRascunho={this.props.removerRascunho}
                                        pendencias={this.props.aguardandoDadosClinicos}
                                        isFetching={this.props.isFetchingAguardandoDadosClinicos}
                                        loadDadosClinicos={this.props.loadDadosClinicos}
                                        {...routeProps}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={'/home/aguardandoDadosProduto'}
                                render={routeProps => {
                                    return (
                                        <DadosProduto
                                            ortopedista={isOrtopedista}
                                            preencherDadosProduto={this.props.preencherDadosProduto}
                                            pendencias={this.props.aguardandoDadosProduto}
                                            isFetching={this.props.isFetchingAguardandoDadosProduto}
                                            loadDadosProduto={this.props.loadDadosProduto}
                                            visualizarFicha={this.props.visualizarFicha}
                                            imprimirFicha={this.props.imprimirFicha}
                                            {...routeProps}
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={'/home/aguardandoDadosFinanceiros'}
                                render={routeProps => {
                                    return (
                                        <DadosFinanceiros
                                            editarDadosProduto={this.props.editarDadosProduto}
                                            ortopedista={isOrtopedista}
                                            pendencias={this.props.aguardandoDadosFinanceiros}
                                            isFetching={this.props.isFetchingAguardandoDadosFinanceiros}
                                            loadDadosFinanceiro={this.props.loadDadosFinanceiro}
                                            visualizarFicha={this.props.visualizarFicha}
                                            imprimirFicha={this.props.imprimirFicha}
                                            {...routeProps}
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={'/home/historico'}
                                render={() => {
                                    return (
                                        <DadosHistorico
                                            ortopedista={isOrtopedista}
                                            pendencias={this.props.historico}
                                            isFetching={this.props.isFetchingHistorico}
                                            loadDadosHistorico={this.props.loadDadosHistorico}
                                            visualizarFicha={this.props.visualizarFicha}
                                            imprimirFicha={this.props.imprimirFicha}
                                        />
                                    )
                                }}
                            />
                        </C.Column>
                    </C.Columns>
                </div>
            </>
        )
    }
}
