import React from 'react'
import classnames from 'classnames'
import { Route } from 'react-router-dom'
import * as C from 'libs/components'

export const TabMenu = props => {
    return <ul className='sidebar is-full-height'>{props.children}</ul>
}

export const TabItem = props => {
    return (
        <Route
            exact={props.exact ?? true}
            path={props.path}
            children={({ match, history }) => {
                const classes = classnames(props.className, {
                    active: match,
                })
                const onClick = () => history.push(props.path)
                return (
                    <li className={classes}>
                        <span onClick={onClick}>
                            <C.Icon icon={props.icon} size={props.sizeIcon ?? 'medium'} />
                            <div className='sidebar-item--text'>{props.children}</div>
                        </span>
                    </li>
                )
            }}
        />
    )
}
