/* tslint:disable */
import React from 'react'

const SvgRemoverVinculo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M13.16 20.6H4.384A1.384 1.384 0 013 19.195c.006-.354.088-2.176 1.174-3.263.491-.49 1.312-.917 2.605-1.563.653-.326 1.738-.868 2.001-1.117-1.103-1.353-1.742-2.88-1.742-4.219 0-.907 0-2.037.51-3.045C8.006 5.081 9.03 4 11.308 4c2.278 0 3.302 1.081 3.76 1.988.509 1.008.509 2.138.509 3.046 0 1.337-.639 2.866-1.742 4.218.214.202.968.596 1.594.912-.474.23-.906.534-1.278.898-.637-.336-1.028-.578-1.267-.804l-.934-.882.811-.996c.91-1.117 1.432-2.336 1.432-3.346 0-.802 0-1.709-.36-2.422-.418-.827-1.245-1.229-2.525-1.229-1.28 0-2.107.402-2.525 1.229-.36.713-.36 1.62-.36 2.421 0 1.011.522 2.23 1.431 3.347l.811.995-.934.882c-.35.333-1.032.7-2.333 1.35-1.1.55-1.905.965-2.244 1.304-.587.587-.76 1.726-.77 2.307h8.358c.07.488.213.952.417 1.382zm2.302-2.767H21v1.383h-5.538v-1.383z' />
    </svg>
)

export default SvgRemoverVinculo
