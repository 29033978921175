import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import immutableProxy, { ImmutableRootProxy } from 'libs/immutableProxy'
import { StentLesaoForm } from 'backend'

const falhaStent = (mutable: ImmutableRootProxy<FormState<StentLesaoForm>>, action) => {
    const motivoInsucesso = mutable.values.motivoInsucesso.get()
    if (!motivoInsucesso || motivoInsucesso.id !== 'FALHA_PRODUTO') {
        mutable.values.tipoFalha.delete()
        mutable.values.especificacaoFalha.delete()
    }
    if (!mutable.values.tipoFalha.get()) {
        mutable.values.especificacaoFalha.delete()
    }
    if (action.meta && action.meta.field === 'tipoFalha') {
        mutable.values.especificacaoFalha.delete()
    }
    return mutable
}

const adicionarStent = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<StentLesaoForm>>(state)
        form = form.withMutations(mutable => {
            const localStent = mutable.values.localStent.get()
            if (!localStent || localStent !== 'NATIVA') {
                mutable.values.lesoesRegistradas.delete()
            }
            if (!localStent || localStent !== 'ENXERTO') {
                mutable.values.enxertoStent.delete()
            }

            if (mutable.values.lesoesRegistradas.get()) {
                const asLesoes = mutable.values.lesoesRegistradas.get()['_root'] as any
                asLesoes.entries = asLesoes.entries.filter(([_, valor]) => valor.length > 0)
            }

            const stentSucesso = mutable.values.stentSucesso.get()
            if (!stentSucesso || stentSucesso === 'SIM') {
                mutable.values.motivoInsucesso.delete()
                mutable.values.tipoFalha.delete()
                mutable.values.especificacaoFalha.delete()
            }

            falhaStent(mutable, action)
        })
        return form.asMap()
    }
    return state
})
export default adicionarStent
