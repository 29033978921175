import React, { useState, useRef } from 'react'
import classnames from 'classnames'
import { Overlay as OverlayCmp } from 'react-overlays'
import { formatPeriodo, MomentInterval, toDateInterval, toMomentInterval, isSame } from 'libs/util/DateUtil'
import { DateInterval } from 'backend'
import { Columns, DatePickerInput } from 'libs/components'
import { Button, FormLabel, Icon } from 'libs/components'

export type PeriodoEnum =
    | 'TODO_PERIODO'
    | 'ULTIMOS_30_DIAS'
    | 'ULTIMOS_60_DIAS'
    | 'ULTIMOS_90_DIAS'
    | 'ULTIMOS_180_DIAS'
    | 'ULTIMOS_365_DIAS'

export const periodoNames: { [key in PeriodoEnum]: string } = {
    ULTIMOS_30_DIAS: 'Últimos 30 dias',
    ULTIMOS_60_DIAS: 'Últimos 60 dias',
    ULTIMOS_90_DIAS: 'Últimos 90 dias',
    ULTIMOS_180_DIAS: 'Últimos 180 dias',
    ULTIMOS_365_DIAS: 'Últimos 365 dias',
    TODO_PERIODO: 'Todo o período',
}

export const periodoValues: { [key in PeriodoEnum]: () => MomentInterval } = {
    ULTIMOS_30_DIAS: () => ({ start: addDays(new Date(), -30), end: new Date() }),
    ULTIMOS_60_DIAS: () => ({ start: addDays(new Date(), -60), end: new Date() }),
    ULTIMOS_90_DIAS: () => ({ start: addDays(new Date(), -90), end: new Date() }),
    ULTIMOS_180_DIAS: () => ({ start: addDays(new Date(), -180), end: new Date() }),
    ULTIMOS_365_DIAS: () => ({ start: addDays(new Date(), -365), end: new Date() }),
    TODO_PERIODO: () => ({}),
}

const addDays = (date: Date, days: number) => {
    date.setDate(date.getDate() + days)
    return date
}

export const isSameInterval = (interval: MomentInterval, periodo: PeriodoEnum) => {
    const value = periodoValues[periodo]()

    if (!interval.start && !interval.end && !value.start && !value.end) {
        // TODO_PERIODO
        return true
    }

    return !!(interval.start && value.start && isSame(value.start, interval.start)) && isSame(value.end, interval.end)
}

export const periodoFromMomentInterval = (interval: MomentInterval): PeriodoEnum => {
    let r: PeriodoEnum = null
    Object.keys(periodoNames).forEach((name: PeriodoEnum) => {
        if (isSameInterval(interval, name)) {
            r = name
        }
    })
    return r
}

export const nameFromMoment = (interval: MomentInterval): string => {
    const periodoEnum = periodoFromMomentInterval(interval)
    return periodoEnum ? periodoNames[periodoEnum] : null
}

interface Props {
    value: DateInterval
    className?: string
    onChange(periodo: DateInterval): void
}

export const PeriodoFilter = (props: Props) => {
    const [visible, setVisible] = useState(false)
    const triggerRef = useRef(null)
    const containerRef = useRef(null)
    const value = props.value || {}

    const handleChange = (interval: MomentInterval) => {
        setVisible(false)
        props.onChange(toDateInterval(interval))
    }

    const toggleMenuVisibility = () => {
        setVisible(!visible)
    }

    const renderValue = () => {
        const momentInterval = toMomentInterval(value)
        const name = nameFromMoment(momentInterval)
        return name || formatPeriodo(momentInterval.start, momentInterval.end)
    }

    return (
        <div className='periodo-filter-wrapper' ref={containerRef}>
            <span ref={triggerRef} className={classnames('periodo-filter', props.className)}>
                <p onClick={toggleMenuVisibility}>
                    <Icon icon='data' />
                    {renderValue()}
                    <span className='arrow-down'></span>
                </p>
            </span>

            <OverlayCmp
                show={visible}
                onHide={() => setVisible(false)}
                rootClose
                target={triggerRef as any}
                container={containerRef as any}
            >
                {props => {
                    return <PeriodoFilterMenu {...props} onChange={handleChange} value={toMomentInterval(value)} />
                }}
            </OverlayCmp>
        </div>
    )
}

interface MenuProps {
    value: MomentInterval
    onChange(periodo: MomentInterval): void
}

interface MenuState {
    interval: MomentInterval
    periodoEnum: PeriodoEnum
}

export class PeriodoFilterMenu extends React.Component<MenuProps, MenuState> {
    constructor(props: MenuProps) {
        super(props)
        this.state = {
            interval: props.value || {},
            periodoEnum: null,
        }
    }

    UNSAFE_componentWillReceiveProps(props: MenuProps) {
        this.setState({
            interval: props.value,
            periodoEnum: null,
        })
    }

    handleEnumChange(periodoEnum: PeriodoEnum) {
        this.setState({
            interval: periodoValues[periodoEnum](),
            periodoEnum: periodoEnum,
        })
    }

    handleInicioPickerChange(date: Date) {
        this.setState({
            periodoEnum: null,
            interval: {
                ...this.state.interval,
                start: date,
            },
        })
    }

    handleFimPickerChange(date: Date) {
        this.setState({
            periodoEnum: null,
            interval: {
                ...this.state.interval,
                end: date,
            },
        })
    }

    aplicar() {
        this.props.onChange(this.state.interval)
    }

    render() {
        const handleInicioPickerChange = this.handleInicioPickerChange.bind(this)
        const handleFimPickerChange = this.handleFimPickerChange.bind(this)
        const aplicar = this.aplicar.bind(this)

        return (
            <div className='periodo-filter-menu'>
                <div className='periodo-filter-menu-items'>
                    {Object.keys(periodoNames).map((key: PeriodoEnum) => {
                        const handleEnumChange = this.handleEnumChange.bind(this, key)

                        return (
                            <p
                                key={key}
                                onClick={handleEnumChange}
                                className={classnames({
                                    'is-active': isSameInterval(this.state.interval, key),
                                })}
                            >
                                {periodoNames[key]}
                            </p>
                        )
                    })}
                </div>
                <div className='periodo-filter-menu-custom'>
                    <Columns auto>
                        <div data-name='periodo-inicio'>
                            <FormLabel label='De:' />
                            <DatePickerInput
                                maxDate={new Date()}
                                onChange={handleInicioPickerChange}
                                value={this.state.interval.start as any}
                            />
                        </div>

                        <div data-name='periodo-fim'>
                            <FormLabel label='Até:' />
                            <DatePickerInput
                                maxDate={new Date()}
                                onChange={handleFimPickerChange}
                                value={this.state.interval.end as any}
                            />
                        </div>
                    </Columns>
                    <Button type='neon' square fullwidth onClick={aplicar}>
                        Aplicar
                    </Button>
                </div>
            </div>
        )
    }
}
