import React from 'react'
import * as C from 'libs/components'

const quadroClinicoOptions = [
    { id: 'ANGINA_ESTAVEL', nome: 'Angina estável' },
    { id: 'ANGINA_INSTAVEL', nome: 'Angina instável' },
    { id: 'ASSINTOMATICO', nome: 'Assintomático' },
    { id: 'DOR_TORACICA_ATIPICA', nome: 'Dor torácica atípica' },
    { id: 'EQUIVALENTE_ISQUEMICO', nome: 'Equivalente isquêmico' },
    { id: 'IAM_COM_SUPRA_ST', nome: 'IAM com supra ST' },
    { id: 'IAM_SEM_SUPRA_ST', nome: 'IAM sem supra ST' },
]

export class QuadroClinicoField extends React.Component {
    render() {
        return <C.FixedSelectField {...this.props} options={quadroClinicoOptions} />
    }
}
