import React from 'react'
import { FixedSelectField, FixedSelectFieldOption } from 'libs/components'

export const AnoDataValidadeField = props => {
    let options: FixedSelectFieldOption[] = []

    for (let i = 0; i <= 10; i++) {
        let value = props.anoBase + i
        options.push({ id: value, nome: value.toString() })
    }

    return <FixedSelectField {...props} options={options} />
}
