import * as React from 'react'
import * as C from 'libs/components'
import classnames from 'classnames'
import * as Util from 'libs/components/Util'
import { Page } from 'redux/middleware/PaginationAction'

interface PagedTableProps extends TableProps {
    page: Page<any>
    onChangePage?: (page: number) => void
}

export class PagedTable extends React.Component<PagedTableProps, any> {
    constructor(props) {
        super(props)
        this.onChangePage = this.onChangePage.bind(this)
    }

    render() {
        const { page } = this.props
        const values = page ? page.content : undefined
        const sort = page ? page.sort : undefined
        const first = page ? page.first : undefined
        const last = page ? page.last : undefined
        const pageNumber = page ? page.number : undefined
        const totalPages = page ? page.totalPages : undefined

        const classes = classnames('table', this.props.className)

        return (
            <div>
                <table className={classes}>
                    <thead>
                        <tr>
                            {React.Children.map(this.props.children, (child: any) => {
                                if (!child) {
                                    return
                                }
                                return React.cloneElement(child, { sort })
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {(!values || values.length === 0) && this.props.emptyText && (
                            <tr>
                                <td
                                    className='is-text-muted has-text-centered'
                                    colSpan={getEmptyTextColSpan(this.props.children)}
                                >
                                    {this.props.emptyText}
                                </td>
                            </tr>
                        )}
                        {values &&
                            values.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {this.props.addRowSpacer && <tr className='spacer' />}
                                        <TableRow name={this.props.name + index} value={value}>
                                            {this.props.children}
                                        </TableRow>
                                    </React.Fragment>
                                )
                            })}
                    </tbody>
                </table>
                {totalPages > 0 && (
                    <C.Paginator
                        first={first}
                        last={last}
                        pageNumber={pageNumber}
                        onChange={this.onChangePage}
                        totalPages={totalPages}
                    />
                )}
            </div>
        )
    }

    onChangePage(page: number) {
        this.props.onChangePage(page)
    }
}

interface TableProps {
    addRowSpacer?: boolean
    className?: string
    emptyText?: string
    values: any
    name?: string
}

export class Table extends React.Component<TableProps, any> {
    render() {
        const classes = classnames('table', this.props.className)
        return (
            <table className={classes}>
                <thead>
                    <tr>{this.props.children}</tr>
                </thead>
                <tbody>
                    {(!this.props.values || this.props.values.length === 0 || this.props.values.size === 0) &&
                        this.props.emptyText && (
                            <tr data-name={this.props.name}>
                                <td
                                    className='is-text-muted has-text-centered'
                                    colSpan={getEmptyTextColSpan(this.props.children)}
                                >
                                    {this.props.emptyText}
                                </td>
                            </tr>
                        )}
                    {this.props.values &&
                        this.props.values.map((value, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {this.props.addRowSpacer && <tr className='spacer' />}
                                    <TableRow name={this.props.name + index} value={value} index={index}>
                                        {this.props.children}
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                </tbody>
            </table>
        )
    }
}

class TableRow extends React.Component<any, any> {
    render() {
        return (
            <tr data-name={String(this.props.name)} className='row'>
                {React.Children.map(this.props.children, (child: any) => {
                    if (!child) {
                        return
                    }
                    return <td>{child.props.render(this.props.value, this.props.index)}</td>
                })}
            </tr>
        )
    }
}

interface TableColumnHeaderProps {
    render: (props: any, idx: number) => JSX.Element
    title: string
    className?: string
    size?: string
    offset?: string
    style?: any // TODO verificar
}

export class TableColumnHeader extends React.Component<TableColumnHeaderProps> {
    render() {
        const { render, title, size, offset, ...cellProps } = this.props

        return (
            <th className={resolveColumnClass(this.props)} {...cellProps}>
                {title}
            </th>
        )
    }
}

interface TableSortableColumnHeaderProps extends TableColumnHeaderProps {
    sortProp: string
    /**
     * Props passadas pela table
     */
    sort?: any
    onChangeSort?: (sort: string) => void
}

export class TableSortableColumnHeader extends React.Component<TableSortableColumnHeaderProps, any> {
    render() {
        let sortItem
        if (this.props.sort) {
            this.props.sort.forEach(item => {
                if (item.property === this.props.sortProp) {
                    sortItem = item
                }
            })
        }

        const onClick = () => {
            const sortString = this.props.sortProp
            const directionString = !sortItem || !sortItem.ascending ? 'asc' : 'desc'

            this.props.onChangeSort(sortString + ',' + directionString)
        }

        const classes = classnames(resolveColumnClass(this.props), 'is-sortable', {
            'is-ascending': sortItem && sortItem.ascending,
            'is-descending': sortItem && !sortItem.ascending,
        })
        return (
            <th className={classes}>
                <span onClick={onClick}>
                    {this.props.title}
                    <C.Icon icon='ordenadorCrescente' />
                    <C.Icon icon='ordenadorDecrescente' />
                </span>
            </th>
        )
    }
}

const resolveColumnClass = props => {
    return classnames(props.className, Util.helpersClassnames(props), {
        'is-half': props.size && props.size === 'half',

        'is-10': props.size && props.size === '10',
        'is-9': props.size && props.size === '9',
        'is-8': props.size && props.size === '8',
        'is-7': props.size && props.size === '7',
        'is-6': props.size && props.size === '6',
        'is-5': props.size && props.size === '5',
        'is-4': props.size && props.size === '4',
        'is-3': props.size && props.size === '3',
        'is-2': props.size && props.size === '2',
        'is-1': props.size && props.size === '1',

        'is-offset-one-quarter': props.offset && props.offset === 'one-quarter',

        'is-offset-1': props.offset && props.offset === '1',
        'is-offset-2': props.offset && props.offset === '2',
        'is-offset-3': props.offset && props.offset === '3',
        'is-offset-4': props.offset && props.offset === '4',
        'is-offset-5': props.offset && props.offset === '5',
    })
}

const getEmptyTextColSpan = children => {
    let colSpan = 0
    children &&
        Object.values(children).forEach(child => {
            if (Array.isArray(child)) {
                colSpan += child.length
            } else {
                colSpan += 1
            }
        })
    return colSpan
}
