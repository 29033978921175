import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import solicitacaoVinculo from 'redux/modules/forms/section/solicitacaoVinculo'
import { SolicitacaoVinculoForm } from 'backend'
import immutableProxy from 'libs/immutableProxy'

const solicitacaoVinculoForm = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<SolicitacaoVinculoForm>>(state)
        form = form.withMutations(mutable => {
            solicitacaoVinculo(mutable.values.solicitacaoVinculo, mutable.fields.solicitacaoVinculo)
        })
        return form.asMap()
    }
    return state
})

export default solicitacaoVinculoForm
