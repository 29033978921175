import React, { ReactNode } from 'react'
import { Text } from 'libs/components'

interface ListSectionProps {
    label?: string
    list: { [key: string]: any[] | null } | any[]
    /**
     * Componente que irá ser colocado como pai da lista
     */
    wrapper?: (listSectionChildren: ReactNode) => JSX.Element
    testid?: string
}

const ListSection: React.FC<ListSectionProps> = React.memo(props => {
    // Caso receba nulo na lista
    if (!props.list) {
        return null
    }

    // Caso seja uma lista sem valores
    if (Array.isArray(props.list) && props.list.length === 0) {
        return null
    }

    // Caso seja um objeto e suas keys não tenham valores
    if (
        !Array.isArray(props.list) &&
        Object.values(props.list).reduce((acc, obj) => {
            if (obj) {
                return obj.length + acc
            } else {
                return acc
            }
        }, 0) === 0
    ) {
        return null
    }

    // Usado para envelopar a lista
    let WrapperListSection = children =>
        props.wrapper ? props.wrapper(children) : <React.Fragment>{children}</React.Fragment>

    if (Array.isArray(props.list)) {
        return WrapperListSection(
            <div>
                {props.label && <Text weight='bold'>{props.label}</Text>}

                <div className='list-item' data-testid={props.testid}>
                    <ul>
                        {props.list.map(dado => (
                            <li key={dado}>{dado}</li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    } else {
        return WrapperListSection(
            <>
                {props.label && <Text weight='bold'>{props.label}</Text>}

                <div className='list-item' data-testid={props.testid}>
                    {props.list &&
                        Object.entries(props.list).map(([label, list]) => {
                            if (list?.length > 0) {
                                return (
                                    <ul key={label}>
                                        <li>{label}</li>
                                        <ul>
                                            {list.map(dado => (
                                                <li key={dado}>{dado}</li>
                                            ))}
                                        </ul>
                                    </ul>
                                )
                            }

                            return null
                        })}
                </div>
            </>
        )
    }
})

export default ListSection
