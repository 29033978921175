import * as React from 'react'
import { WrappedFieldProps } from 'redux-form'
import * as C from 'libs/components'
import field from 'libs/redux-form/field'

export type FixedSelectFieldOption = C.FixedSelectOption

export interface FixedSelectFieldProps extends C.FixedSelectProps, Partial<WrappedFieldProps> {
    options: FixedSelectFieldOption[]
    maxLength?: number
}

export class FixedSelectFieldCmp extends React.Component<FixedSelectFieldProps> {
    public handleChange(newValue: FixedSelectFieldOption | FixedSelectFieldOption[] | null): void {
        this.props.input.onChange(newValue as any)
    }

    render() {
        // Remove o `onChange` do props.input (do redux-form) pois sua assinatura é incompatível
        // Tratamos o change event manualmente e enviamos ao `onChange` do redux-form via `handleChange`
        const { onChange, ...inputRest } = this.props.input
        const handleChange = this.handleChange.bind(this)

        return (
            <C.FixedSelect
                inputProps={{ maxLength: this.props.maxLength }}
                {...inputRest}
                onChange={handleChange}
                isDisabled={this.props.disabled}
                {...this.props}
            />
        )
    }
}

export const FixedSelectField = field()(FixedSelectFieldCmp)
