import React from 'react'
import Env from 'libs/util/Environment'
import HomeConteudo from 'view/componentes/HomeConteudo'
import { Label } from 'libs/components'
import ProcessarAumentoRegistrosForm from './ProcessarAumentoRegistrosForm'
import AdicionarProfissionaisForm from './AdicionarProfissionaisForm'

export default class DebugView extends React.Component {
    render() {
        if (!Env.isDev()) {
            return null
        } else {
            return (
                <HomeConteudo>
                    <Label>Funções de DEBUG</Label>
                    <ProcessarAumentoRegistrosForm />
                    <AdicionarProfissionaisForm />
                </HomeConteudo>
            )
        }
    }
}
