import React, { useEffect } from 'react'
import {
    Button,
    Icon,
    Card,
    Column,
    Table,
    TableColumnHeader,
    Text,
    Checkbox,
    LoadingStateContainer,
    ModalCard,
    TextField,
    ControlField,
    RadioField,
    Linha,
    ButtonBar,
} from 'libs/components'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import { StentDto } from 'backend'
import { InjectedFormProps } from 'redux-form'
import { connect } from 'react-redux'
import { State } from 'redux/modules'
import { createValidator, required, minLength, maxLength } from 'libs/validation'
import form from 'libs/redux-form/form'
import Componentes from 'api/Componentes'
import { newStentSuccess, editStentSuccess } from 'redux/modules/componentes'
import { notBlank } from 'libs/validation/rules/notBlank'
import { error } from 'redux/modules/alert'

interface StentsViewProps {
    load(): void
    edit(item: StentDto): void
    voltar(): void
    clear(): void
    saveAtivoStent(id: number): void
    readyState: any
    stents: StentDto[]
    openModal: boolean
}

export default function StentsView({ load, ...props }: StentsViewProps) {
    useEffect(() => {
        load()
    }, [load])

    const open = (item: StentDto) => {
        props.edit(item)
    }

    const close = () => {
        props.clear()
    }

    const renderCardTitle = () => {
        return (
            <Text color='black-bis' size='medium' weight='semibold' transform='lowercase'>
                {`${(props.stents && props.stents.length) || 0}  stents`}
            </Text>
        )
    }

    const { stents, readyState, voltar, saveAtivoStent } = props

    return (
        <div className='is-vertical-flow is-full-height'>
            <HeaderContainer className='report-header-rastreabilidade'>
                <Button className='button-round button-back-stent' onClick={voltar}>
                    <Icon icon='voltar' />
                </Button>
                <p className='is-semibold is-black-bis is-small is-uppercase'>Lista de stents</p>
            </HeaderContainer>
            <div className='has-text-centered'>
                <Button className='btn-pesquisar' type='primary' size='medium' shadow onClick={() => open(null)}>
                    Registrar novo stent
                </Button>
            </div>
            <div className='report-content'>
                <Card title={renderCardTitle()} noPadding>
                    <LoadingStateContainer readyState={readyState}>
                        {stents && (
                            <>
                                {stents.length > 0 && (
                                    <StentsTable stents={stents} edit={open} changeAtivo={saveAtivoStent} />
                                )}
                                {stents.length < 1 && (
                                    <Column textCentered borderBottom>
                                        <p style={{ lineHeight: 2 }} className='is-text-muted'>
                                            Nenhum stent cadastrado.
                                        </p>
                                    </Column>
                                )}
                            </>
                        )}
                    </LoadingStateContainer>
                </Card>
                {props.openModal && <StentModal close={close} />}
            </div>
        </div>
    )
}

interface StentsTableProps {
    edit: (item: StentDto) => void
    changeAtivo: (id: number) => void
    stents: StentDto[]
}

export const StentsTable = (props: StentsTableProps) => {
    const { stents } = props
    return (
        <Table values={stents} className='is-spaced is-small-bis'>
            <TableColumnHeader
                title='Nome do stent'
                style={{ width: '40%' }}
                render={(item: StentDto) => {
                    return <Text>{item.nome}</Text>
                }}
            />
            <TableColumnHeader
                title='Tipo do stent'
                render={(item: StentDto) => {
                    return <Text>{item.descricaoTipoStent}</Text>
                }}
            />
            <TableColumnHeader
                title='Ativo'
                className='has-text-right row-link'
                style={{ width: '20px' }}
                render={(item: StentDto) => {
                    return <Checkbox checked={item.ativo} onChange={() => props.changeAtivo(item.id)} />
                }}
            />
        </Table>
    )
}

interface StentModalProps {
    close: () => void
}

function StentModalCmp(props: StentModalProps & InjectedFormProps<StentDto, StentModalProps>) {
    return (
        <ModalCard title='Adicionar stent' active onClose={props.close} hasCloseButton={false}>
            <TextField maxLength={50} name='nome' label='Nome do stent' required />

            <ControlField label='Tipo do stent' name='tipoStent' required horizontalFlow>
                <RadioField name='tipoStent' label='Farmacológico' value='FARMACOLOGICO' />
                <RadioField name='tipoStent' label='Bioabsorvível' value='BIOABSORVIVEL' />
                <RadioField name='tipoStent' label='Bioabsorvível e farmacológico' value='BIOABSORVIVEL_COM_FARMACO' />
                <RadioField name='tipoStent' label='Convencional' value='CONVENCIONAL' />
            </ControlField>

            <Linha size='medium' />
            <ButtonBar>
                <Button size='medium' onClick={props.close}>
                    {' '}
                    Cancelar
                </Button>
                <Button pulledRight type='primary' size='medium' onClick={props.handleSubmit}>
                    Salvar
                </Button>
            </ButtonBar>
        </ModalCard>
    )
}

const validator = createValidator<any>({
    nome: [required, notBlank, minLength(2), maxLength(50)],
    tipoStent: required,
})

const StentModalForm = form<StentDto, StentModalProps & InjectedFormProps<StentDto, StentModalProps>>({
    form: 'stent',
    validate: validator,
    hasErrorModal: false,
    hasLeaveModal: false,
    hasSuccessModal: false,
})(StentModalCmp)

const mapStateToProps = (state: State) => ({
    editinStent: state.componentes.editinStent,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { editinStent } = stateProps
    const { dispatch } = dispatchProps
    return {
        ...ownProps,
        ...stateProps,
        initialValues: editinStent || ({} as StentDto),
        onSubmit: form => {
            const formJS = form.toJS()
            return Componentes.saveStent(formJS)
                .then(res => {
                    if (formJS.id) {
                        dispatch(editStentSuccess(res.data))
                    } else {
                        dispatch(newStentSuccess(res.data))
                    }
                })
                .catch(res => {
                    dispatch(
                        error({
                            mensagem: `Não foi possível salvar o stent ${formJS.nome}`,
                        })
                    )
                })
        },
    }
}

const StentModal = connect(mapStateToProps, null, mergeProps)(StentModalForm)
