import * as React from 'react'
import { WrappedFieldProps } from 'redux-form'
import { FormFieldProps } from 'libs/components'
import field, { FieldProps, FieldConfig } from 'libs/redux-form/field'
import { TextInput, TextInputProps } from 'libs/components/presentational/input/TextInput'

export interface TextFieldProps extends FormFieldProps, TextInputProps, Partial<WrappedFieldProps> {}

export const normalize = value => value.replace(/^\s*$/, '')

const fieldConfig: FieldConfig = {
    normalize: normalize,
}

const TextFieldCmp = (props: TextFieldProps) => {
    return (
        <TextInput
            {...props.input}
            {...props}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            disabled={props.disabled}
        />
    )
}

export const TextField: React.ComponentClass<FieldProps & TextFieldProps> = field(fieldConfig)(TextFieldCmp)
