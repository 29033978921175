import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import { useAxiosGet } from 'hooks/useApiRequest'
import { EntidadeConsumidoraSelectDto } from 'backend'
import Entidades from 'api/Entidades'
import { Button, EmptyListItem, Icon, LoadingStateContainer } from 'libs/components'
import EntidadeRow from './components/EntidadeRow'
import { error } from 'redux/modules/alert'
import { useDispatch } from 'react-redux'
import { printFile } from 'libs/util/PrintFileUtil'

type GerenciarEntidadesFiltro = 'ativas' | 'inativas'

interface GerenciarEntidadesViewRouteParams {
    filtro: GerenciarEntidadesFiltro
}

const GerenciarEntidadesView: React.FC = () => {
    const history = useHistory()
    const params = useParams<GerenciarEntidadesViewRouteParams>()

    const dispatch = useDispatch()

    /**
     * States
     */
    const [filtro, setFiltro] = useState<GerenciarEntidadesFiltro>(params.filtro ? params.filtro : 'ativas')

    let { data: requestData, requestState } = useAxiosGet<EntidadeConsumidoraSelectDto[]>(
        Entidades.getEntidades(filtro)
    )

    const [data, setData] = useState<EntidadeConsumidoraSelectDto[]>([])

    /**
     * Effects
     */
    useEffect(() => {
        if (params.filtro && params.filtro !== filtro) {
            setFiltro(params.filtro)
        }
    }, [params, history, filtro])

    useEffect(() => {
        setData(requestData)
    }, [requestData])

    /**
     * Functions
     */
    function deleteFromList(entidadeId: number) {
        setData(oldData => oldData.filter((entidade: EntidadeConsumidoraSelectDto) => entidade.id !== entidadeId))
    }

    /**
     * Handles
     */
    function handleSubTabItemClick(selectedFiltro: GerenciarEntidadesFiltro) {
        setFiltro(selectedFiltro)
        history.push({
            pathname: `/home/entidades/${selectedFiltro}`,
        })
    }

    function handlePrintForm() {
        Entidades.getEntidadesForm()
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                printFile(blob, 'Formulário de consumo de dados')
            })
            .catch(err => {
                dispatch(
                    error({
                        mensagem: 'Erro ao baixar o arquivo',
                    })
                )
            })
    }

    return (
        <div className='lista-entidades report-content'>
            <HeaderContainer>
                <HorizontalTabMenu>
                    <HorizontalTabMenuItem
                        title='Ativas'
                        onClick={() => handleSubTabItemClick('ativas')}
                        active={filtro === 'ativas'}
                    />
                    <HorizontalTabMenuItem
                        title='Inativas'
                        onClick={() => handleSubTabItemClick('inativas')}
                        active={filtro === 'inativas'}
                    />
                </HorizontalTabMenu>
            </HeaderContainer>

            {filtro === 'ativas' && (
                <div className='header-buttons is-horizontal-flow'>
                    <Button
                        size='large'
                        square
                        dashed
                        fullwidth
                        onClick={() => history.push('/home/entidades/adicionarEntidade')}
                        testid='button-adicionarEntidade'
                    >
                        <Icon icon='adicionar' />
                        <span>ADICIONAR ENTIDADE</span>
                    </Button>
                    <Button
                        size='large'
                        square
                        dashed
                        fullwidth
                        onClick={handlePrintForm}
                        testid='button-imprimirFormulario'
                    >
                        <Icon icon='imprimir' />
                        <span>IMPRIMIR FORMULÁRIO</span>
                    </Button>
                </div>
            )}

            <LoadingStateContainer readyState={requestState}>
                {data && (
                    <ul>
                        {filtro === 'ativas' &&
                            data.map(entidade => (
                                <EntidadeRow
                                    key={entidade.id}
                                    entidade={entidade}
                                    ativo={true}
                                    onInactiveEntity={deleteFromList}
                                />
                            ))}

                        {filtro === 'inativas' &&
                            data.map(entidade => (
                                <EntidadeRow
                                    key={entidade.id}
                                    entidade={entidade}
                                    ativo={false}
                                    onInactiveEntity={deleteFromList}
                                />
                            ))}

                        {data.length === 0 && (
                            <EmptyListItem label={`Não há entidades ${filtro}`} style={{ marginTop: 20 }} />
                        )}
                    </ul>
                )}
            </LoadingStateContainer>
        </div>
    )
}

export default GerenciarEntidadesView
