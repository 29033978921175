/* tslint:disable */
import React from 'react'

const SvgArrowBottom = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M13.259 15.145l5.991-6.33-1.009-1.065-5.991 6.33-5.991-6.33L5.25 8.816l5.991 6.33-.037.038 1.01 1.066.036-.039.037.039 1.009-1.066-.037-.039z' />
    </svg>
)

export default SvgArrowBottom
