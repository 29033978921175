import * as React from 'react'
import * as C from 'libs/components'

interface CabecalhoFichaProps {
    tipoRegistro: string
    tipoProcedimento: string
}

export class CabecalhoFicha extends React.Component<CabecalhoFichaProps> {
    render() {
        return (
            <div className='borderless'>
                <C.Label className='registro-titulo'>{this.props.tipoRegistro}</C.Label>
                <C.Label className='registro-subtitulo'>{this.props.tipoProcedimento}</C.Label>
            </div>
        )
    }
}
