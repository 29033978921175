import axios, { AxiosPromise } from 'axios'
import { SelecaoPerfilDto, AlterarSenhaForm } from 'backend'

class Usuario {
    getPerfis(): AxiosPromise<SelecaoPerfilDto[]> {
        return axios.get('/api/users/vinculos')
    }

    selecionarPerfil(perfil: SelecaoPerfilDto): AxiosPromise<void> {
        return axios.post('/api/users/selecionarVinculo', perfil)
    }

    limparVinculo(): AxiosPromise<void> {
        return axios.post('/api/users/limparVinculo')
    }

    alterarSenha(form: AlterarSenhaForm): AxiosPromise<void> {
        return axios.post('/api/users/alterarSenha', form)
    }
}

export default new Usuario()
