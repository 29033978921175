import * as React from 'react'
import { DistFinalizadas } from 'redux/modules/report/usoSistema'
import { ChartCard, ChartOptions } from 'libs/components'
import { SeriesColumnOptions } from 'highcharts'

interface Props {
    readyState: 'request' | 'success' | 'failure'
    dist: DistFinalizadas
}

export class RegistrosFinalizadosChartCard extends React.Component<Props> {
    render() {
        let config: ChartOptions = {}

        if (this.props.readyState === 'success') {
            config = {
                chart: {
                    type: 'grafico',
                },
                xAxis: {
                    categories: this.props.dist.result.categories,
                    title: {
                        text: null,
                    },
                },
                yAxis: {
                    min: 0,
                    minTickInterval: 1,
                    title: {
                        text: null,
                    },
                },
                title: {
                    text: 'Distribuição de registros finalizados por período',
                },
                legend: {
                    layout: 'horizontal',
                    align: 'right',
                    verticalAlign: 'bottom',
                    y: 20,
                    x: -270,
                    floating: true,
                },
                credits: {
                    enabled: false,
                },
                series: this.props.dist.result.series as SeriesColumnOptions[],
            }
        }
        return (
            <ChartCard
                defaultViewMode='grafico'
                title='Registros finalizados'
                readyState={this.props.readyState}
                config={config}
            />
        )
    }
}
