/* tslint:disable */
import React from 'react'

const SvgLogin = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M11.995 11.78c-2.232-.16-3.92-2.119-3.785-4.39C8.075 5.119 9.763 3.16 11.995 3c2.231.16 3.92 2.119 3.785 4.39.135 2.271-1.554 4.23-3.785 4.39zm0-7.58c-1.552.203-2.678 1.603-2.566 3.19-.112 1.587 1.014 2.987 2.566 3.19 1.567-.183 2.715-1.589 2.605-3.19.11-1.601-1.038-3.007-2.605-3.19zm0 16.8a12.804 12.804 0 01-6.744-1.94 1.59 1.59 0 01-.738-1.56A6.04 6.04 0 016.214 14a5.85 5.85 0 014.198-1.79h3.175c2.997-.023 5.535 2.244 5.9 5.27a1.62 1.62 0 01-.719 1.57A12.811 12.811 0 0112.545 21h-.55zm-1.583-7.59a4.66 4.66 0 00-3.342 1.41 4.892 4.892 0 00-1.377 2.84.383.383 0 00.167.38 11.492 11.492 0 0012.25 0 .403.403 0 00.167-.4c-.297-2.41-2.311-4.219-4.7-4.22l-3.165-.01z' />
    </svg>
)

export default SvgLogin
