import { FixedSelect, FixedSelectFieldOption, FormField } from 'libs/components'
import React from 'react'

export const AnoFabricacao = props => {
    let options: FixedSelectFieldOption[] = []

    for (let i = 0; i <= 10; i++) {
        let value = props.anoBase - i
        options.push({ id: value, nome: value.toString() })
    }

    return (
        <FormField name={props.name} error={props.error}>
            <FixedSelect
                options={options}
                placeholder={props.placeholder}
                onBlur={props.onBlur}
                onChange={props.onChange}
                value={props.value}
            />
        </FormField>
    )
}
