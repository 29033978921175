import { connect } from 'react-redux'
import { BuscaProcedimentoListView, BuscaProcedimentoListProps } from './BuscaProcedimentoListView'
import { State } from 'redux/modules'
import { withRouter } from 'react-router-dom'
import * as buscaPaciente from 'redux/modules/report/buscaPaciente'
import { tiposProcedimentoUrlByEnum } from 'api/Procedimento'
import { TipoProcedimento, BuscaPacienteReportFiltro } from 'backend'

const mapStateToProps = (state: State, ownProps: BuscaProcedimentoListProps) => ({
    procedimentos: state.report.buscaPaciente.procedimentos,
})

const mapDispatchToProps = (dispatch, ownProps: BuscaProcedimentoListProps) => ({
    buscaProcedimentos: (filtro: BuscaPacienteReportFiltro) => {
        dispatch(buscaPaciente.findSumarioProcedimentos(filtro))
    },
    visualizarFicha: (idProcedimento: number, proced: TipoProcedimento) => {
        const procedimentoUrl = tiposProcedimentoUrlByEnum[proced]
        ownProps.history.push(`${ownProps.match.url}/visualizacao/${procedimentoUrl}/` + idProcedimento)
    },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(BuscaProcedimentoListView))
