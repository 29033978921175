import * as React from 'react'
import field from 'libs/redux-form/field'
import * as C from 'libs/components'
import { WrappedFieldProps } from 'redux-form'
import { MesSelect } from 'libs/components/presentational/input/MesSelect'

interface MesFieldProps extends Partial<WrappedFieldProps> {
    ano?: number
    placeholder: string
    disabled: boolean
    maxMenuHeight?: number
}

export function MesFieldCmp(props: MesFieldProps) {
    const handleChange = (newValue: C.FixedSelectOption | C.FixedSelectOption[] | null): void => {
        props.input.onChange(newValue as any)
    }

    // Remove o `onChange` do props.input (do redux-form) pois sua assinatura é incompatível
    // Tratamos o change event manualmente e enviamos ao `onChange` do redux-form via `handleChange`
    const { onChange, ...inputRest } = props.input

    return (
        <MesSelect
            {...inputRest}
            onChange={handleChange}
            ano={props.ano}
            placeholder={props.placeholder}
            isDisabled={props.disabled}
            maxMenuHeight={props.maxMenuHeight}
        />
    )
}

export const MesField = field()(MesFieldCmp)
