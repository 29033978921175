/* tslint:disable */
import React from 'react'

const SvgAlertasMotivorevisao = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M17.51 10.222c.383.043.718.279.883.625.167.35.137.76-.079 1.081l-6.072 9.076a1.12 1.12 0 01-1.317.429 1.104 1.104 0 01-.723-1.182l.809-6.07-3.521-.403a1.117 1.117 0 01-.883-.625 1.093 1.093 0 01.078-1.081l6.073-9.077a1.128 1.128 0 011.318-.428c.49.179.79.668.722 1.182l-.809 6.07 3.522.403z' />
    </svg>
)

export default SvgAlertasMotivorevisao
