import configApp from 'config/index'
import 'css/main.sass'
import 'images/icons/rnicons.font'

import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'

configApp()
ReactDOM.render(<App />, document.getElementById('react-target'))
