import * as React from 'react'
import * as C from 'libs/components'

interface AbordagemCirurgicaJoelhoProps {}

export class AbordagemCirurgicaJoelhoField extends React.Component<AbordagemCirurgicaJoelhoProps> {
    render() {
        return (
            <C.ControlField label='Abordagem cirúrgica' name='abordagemCirurgica' verticalFlow required>
                <C.ClearableRadioField name='abordagemCirurgica' label='Midvasto' value='MIDVASTO' />
                <C.ClearableRadioField name='abordagemCirurgica' label='Subvasto' value='SUBVASTO' />
                <C.ClearableRadioField
                    name='abordagemCirurgica'
                    label='Parapatelar medial'
                    value='PARAPATELAR_MEDIAL'
                />
                <C.ClearableRadioField
                    name='abordagemCirurgica'
                    label='Parapatelar lateral'
                    value='PARAPATELAR_LATERAL'
                />
                <C.ClearableRadioField name='abordagemCirurgica' label='Outra' value='OUTRA' />
            </C.ControlField>
        )
    }
}
