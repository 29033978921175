/* tslint:disable */
import React from 'react'

const SvgModalAtencao = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 128 128' {...props}>
        <path d='M64 0c35.29 0 64 28.709 64 64s-28.71 64-64 64S0 99.291 0 64 28.71 0 64 0zm0 1.707C29.652 1.707 1.707 29.652 1.707 64S29.652 126.293 64 126.293 126.293 98.348 126.293 64 98.348 1.707 64 1.707zm-.98 34.56a.98.98 0 011.96 0V80.64a.98.98 0 01-1.96 0V36.267zM64 93.139a1.833 1.833 0 110-3.665 1.833 1.833 0 010 3.665z' />
    </svg>
)

export default SvgModalAtencao
