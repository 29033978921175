import * as React from 'react'
import field from 'libs/redux-form/field'
import * as C from 'libs/components'
import { WrappedFieldProps } from 'redux-form'
import { ComboProfissionalDto } from 'backend'
import { FormatOptionLabelContext } from 'react-select/src/Select'

interface ProfissionalFieldProps extends Partial<C.AsyncSelectProps>, C.FormFieldProps, Partial<WrappedFieldProps> {}

export class ProfissionalFieldCmp extends React.Component<ProfissionalFieldProps> {
    handleChange = (newValue: C.FixedSelectOption | C.FixedSelectOption[] | null): void => {
        this.props.input.onChange(newValue as any)
    }

    render() {
        // Remove o `onChange` do props.input (do redux-form) pois sua assinatura é incompatível
        // Tratamos o change event manualmente e enviamos ao `onChange` do redux-form via `handleChange`
        const { onChange, ...inputRest } = this.props.input

        return (
            <C.AsyncSelect
                {...inputRest}
                url='/api/profissional/search'
                isClearable={this.props.clearable}
                isDisabled={this.props.disabled}
                onChange={this.handleChange}
                placeholder={this.props.placeholder as any}
                getOptionValue={opt => opt.nome}
                filterOption={() => true as any}
                formatOptionLabel={this.renderOption.bind(this)}
            />
        )
    }

    renderOption(option, meta) {
        return <ProfissionalFieldOption option={option} context={meta.context} />
    }
}

export const ProfissionalField = field()(ProfissionalFieldCmp)

interface ProfissionalFieldOptionProps {
    option: ComboProfissionalDto
    context: FormatOptionLabelContext
}

export class ProfissionalFieldOption extends React.Component<ProfissionalFieldOptionProps> {
    render() {
        const { nome, cpf, numeroConselho } = this.props.option
        const { context } = this.props
        return context === 'menu' ? (
            <div className='spacing-one-half'>
                <p>{nome}</p>
                <p className='is-small'>
                    <strong>CPF: </strong>
                    {cpf}
                </p>
                <p className='is-small'>
                    <strong>Numero conselho:</strong> {numeroConselho}
                </p>
            </div>
        ) : (
            <p>{nome}</p>
        )
    }
}
