import { createValidator, required, cep, maxLength, match } from 'libs/validation'

const REGEX = /^([A-Za-z0-9áéíóúÁÉÍÓÚçÇâêôÂÊÔõãÕÃäöüÄÖÜàÀ()\-,/' ]+)$/
const REGEX_NUMERO_LOGRADOURO = /^(?=.*[1-9])[0-9]+$|(S\/N)/

export const PacienteEnderecoFormValidator = createValidator({
    cep: [required, cep],
    uf: required,
    cidade: required,
    bairro: [required, maxLength(72), match(REGEX)],
    logradouroNome: [required, maxLength(250), match(REGEX)],
    numero: [maxLength(7), match(REGEX_NUMERO_LOGRADOURO)],
    complemento: [maxLength(50), match(REGEX)],
})

export const ProfissionalEnderecoFormValidator = createValidator({
    cep: [required, cep],
    uf: required,
    cidade: required,
    bairro: [required, maxLength(60), match(REGEX)],
    logradouroNome: [required, maxLength(150), match(REGEX)],
    numero: [maxLength(5), match(REGEX_NUMERO_LOGRADOURO)],
    complemento: [maxLength(40), match(REGEX)],
})
