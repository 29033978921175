/* tslint:disable */
import React from 'react'

const SvgVermais = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 21 21' {...props}>
        <path d='M18.295 17.503l.03-.03a.603.603 0 010 .85.597.597 0 01-.847 0l-3.667-3.62a6.867 6.867 0 01-9.502-.624 6.928 6.928 0 01.208-9.555 6.867 6.867 0 019.52-.208 6.928 6.928 0 01.621 9.537l3.637 3.65zm-8.888-2.4c3.135-.006 5.674-2.555 5.68-5.7 0-3.148-2.543-5.7-5.68-5.7-3.136 0-5.679 2.552-5.679 5.7s2.543 5.7 5.68 5.7zm2.162-6.3a.599.599 0 010 1.2h-1.564v1.57a.599.599 0 11-1.196 0v-1.57H7.245a.599.599 0 010-1.2H8.81v-1.57a.599.599 0 111.196 0v1.57h1.564z' />
    </svg>
)

export default SvgVermais
