
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.275,"time":16500,"words":55}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`O desativamento de um vínculo representa o término da relação do profissional com o hospital. Após o vínculo desativado o profissional não poderá mais cadastrar novos procedimentos naquela instituição.`}</p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Profissionais da instituição”`}</li>
      <li parentName="ol">{`No profissional desejado clique no ícone `}<img parentName="li" {...{
          "src": "/images/iconeRecusar.png#center",
          "alt": "Desativar vínculo"
        }}></img></li>
      <li parentName="ol">{`Clique no botão “Confirmar”`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

