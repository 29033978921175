/* tslint:disable */
import React from 'react'

const SvgNotificacaoErro = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <g>
            <path d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 21.828c-5.428 0-9.828-4.4-9.828-9.828 0-5.428 4.4-9.828 9.828-9.828 5.425.007 9.821 4.403 9.828 9.828 0 5.428-4.4 9.828-9.828 9.828z' />
            <path d='M11.977 10.068L8.91 7 7 8.91l3.068 3.067L7 15.045l1.91 1.91 3.067-3.069 3.068 3.069 1.91-1.91-3.069-3.068 3.069-3.068L15.045 7l-3.068 3.068z' />
        </g>
    </svg>
)

export default SvgNotificacaoErro
