import { createValidator, required } from 'libs/validation'

export const CimentoOsseoValidator = createValidator(
    {
        possuiCimentoOsseo: [required],
    },
    formValidator
)

function formValidator(form) {
    const errors = {}
    if (form.get('possuiCimentoOsseo') === 'SIM') {
        errors.lavagemPulsatil = required(form.get('lavagemPulsatil'))
        if (!form.get('tipoCimentoOsseo')) {
            errors.possuiCimentoOsseo = 'Preencha a subopção'
        }
    }

    return errors
}
