import axios from 'axios'
import { Dispatch } from 'redux'
import { State } from 'redux/modules'

export const FILTER: string = 'bridge/pagination/FILTER'
export const PAGE: string = 'bridge/pagination/PAGE'
export const SORT: string = 'bridge/pagination/SORT'
export const CLEAR: string = 'bridge/pagination/CLEAR'

export default function paginationMiddleware() {
    return next => action => {
        if (action.type !== FILTER && action.type !== PAGE && action.type !== SORT) {
            return next(action)
        }

        next({ ...action, readyState: 'request' })
        return axios.get(action.url, { params: { size: 10, page: action.page, ...action.filter } }).then(
            result => {
                next({ ...action, result, readyState: 'success' })
                return result
            },
            error => {
                next({ ...action, error, readyState: 'failure' })
                return error
            }
        )
    }
}

export type Page<T> = {
    content: T[]
    first: boolean
    last: boolean
    number: number
    numberOfElements: number
    size: number
    sort: string
    totalPages: number
    totalElements: number
}

interface Sort {
    prop: string
    direction: 'asc' | 'desc'
}

export interface Paginator {
    filter: (filter) => any
    page: (pageNumber?: number) => any
    refresh: () => any
    sort: (sort: Sort) => any
    clear(): void
}

export function createPaginator(dispatch: Dispatch<any>, url: string): Paginator {
    return {
        filter: filter => {
            return dispatch({
                type: FILTER,
                filter,
                url,
            })
        },
        page: (page = 0) => {
            return dispatch((dispatch, getState) => {
                const paginator = getState().pagination[url]

                let filter
                let sort
                if (paginator) {
                    filter = paginator.filter
                    sort = paginator.sort
                }

                dispatch({
                    type: PAGE,
                    filter,
                    url,
                    page,
                    sort,
                })
            })
        },
        refresh: () => {
            return dispatch((dispatch, getState) => {
                const paginator = getState().pagination[url]

                let filter
                let page
                let sort
                if (paginator) {
                    filter = paginator.filter
                    page = paginator.page.number
                    sort = paginator.sort
                }

                dispatch({
                    type: PAGE,
                    filter,
                    url,
                    page,
                    sort,
                })
            })
        },
        sort: (sort: Sort) => {
            return dispatch((dispatch, getState) => {
                const paginator = getState().pagination[url]

                let filter
                if (paginator) {
                    filter = paginator.filter
                }

                dispatch({
                    type: SORT,
                    filter,
                    sort,
                    url,
                })
            })
        },
        clear: () => {
            return dispatch({
                type: CLEAR,
            })
        },
    }
}

export const getFilter = <T>(url: string) => (state: State): T => {
    return state.pagination[url] && state.pagination[url].filter
}

export const getPage = <T>(url: string) => (state: State): Page<T> => {
    return state.pagination[url] && state.pagination[url].page
}

export const isFetching = (url: string) => (state: State): boolean => {
    return state.pagination[url] && state.pagination[url].readyState === 'request'
}

interface PaginatorState {
    filter: any
    page: Page<any>
    readyState: 'success' | 'failure' | 'request'
    sort: Sort
}

export interface PaginationState {
    [index: string]: PaginatorState
}

export function reducer(state: PaginationState = {}, action): PaginationState {
    switch (action.type) {
        case FILTER:
        case PAGE:
        case SORT:
            const newState: PaginationState = {
                [action.url]: {
                    filter: action.filter,
                    page: action.result ? action.result.data : undefined,
                    readyState: action.readyState,
                    sort: action.sort,
                },
            }

            return { ...state, ...newState }
        case CLEAR:
            return {}
        default:
            return state
    }
}
