import React from 'react'
import { StentCoronariaComplementarVisualizacaoDto } from 'backend'
import { Columns, ComposedLabel, Label } from 'libs/components'
import { isNullOrUndefined } from 'util'

interface IcpComplementarCardProps {
    dadosComplementares: StentCoronariaComplementarVisualizacaoDto
}

export class IcpComplementarCard extends React.Component<IcpComplementarCardProps> {
    render() {
        const data = this.props.dadosComplementares
        const minutosTempoFluoroscopia = !isNullOrUndefined(data.fluoroscopiaMinuto) && data.fluoroscopiaMinuto + ' min'
        const segundosTempoFluoroscopia =
            !isNullOrUndefined(data.fluoroscopiaSegundo) && data.fluoroscopiaSegundo + ' seg'
        const minutosSegundosTempoFluoroscopia =
            !isNullOrUndefined(data.fluoroscopiaMinuto) &&
            !isNullOrUndefined(data.fluoroscopiaSegundo) &&
            minutosTempoFluoroscopia + ' e ' + segundosTempoFluoroscopia

        const hasAirKerma = !isNullOrUndefined(data.airKerma) && data.unidadeAirKerma
        const hasDap = data.dap && data.primeiraUnidadeDap && data.segundaUnidadeDap

        return (
            <div>
                <Columns auto>
                    <ComposedLabel title={'Dispositivos adjuntos'}>{data.dispositivosAdjuntos}</ComposedLabel>
                </Columns>
                <Columns auto>
                    <ComposedLabel title={'Métodos auxiliares'}>{data.metodosAuxiliares}</ComposedLabel>
                </Columns>
                <Columns auto>
                    <Label>Radiação</Label>
                </Columns>
                <Columns auto>
                    <div className='with-border-left'>
                        <ComposedLabel title={'Tempo de fluoroscopia'} placeholder='Não informado'>
                            {minutosSegundosTempoFluoroscopia || minutosTempoFluoroscopia || segundosTempoFluoroscopia}
                        </ComposedLabel>
                    </div>
                    <div className='with-border-left'>
                        <ComposedLabel title={'Air Kerma'}>
                            {hasAirKerma && data.airKerma + ' ' + data.unidadeAirKerma}
                        </ComposedLabel>
                    </div>
                    <div className='with-border-left'>
                        <ComposedLabel title={'DAP'}>
                            {hasDap && data.dap + ' ' + data.primeiraUnidadeDap + ' · ' + data.segundaUnidadeDap}
                        </ComposedLabel>
                    </div>
                </Columns>
            </div>
        )
    }
}
