import { AlertModalConfirmacao, Button, ButtonBar, Conteudo, Form, Linha, Panel, SectionTitle } from 'libs/components'
import * as React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormSection, getFormValues } from 'redux-form/immutable'
import { error } from 'redux/modules/alert'
import { AjudaFinanceiro } from '../ajuda/AjudaFinanceiro'
import { ComponenteCopySection } from '../componentes'
import { DadosFinanceirosFormSection } from '../componentes/DadosFinanceirosFormSection'
import { IdentificacaoAnvisaFragment } from '../componentes/IdentificacaoAnvisaFragment'
import { IdentificacaoComponenteFragment } from '../componentes/IdentificacaoComponenteFragment'
import {
    ProdutoRastreabilidadeIdCmpFormDto,
    useDadosProdutoFormFromRastreabilidade,
} from '../common/useDadosProdutoForm'
import { State } from 'redux/modules'
import { AvisoPreenchimentoFicha } from 'view/home/novoProcedimento/ficha/componentes/visualizacao/AvisoPreenchimentoFicha'

export interface RastreabilidadeGeralFormProps {
    cancelar(e: any): void
    canCopy: boolean
    canSave: boolean
    change(formSectionName: string, cmp: {}): void
    componente: any
    formName: string
    formSectionName: string
    handleSubmit(): void
    index: number
    onChangeRegistroAnvisa(filtro): void
    submitting: boolean
    semRegistroAnvisa: boolean
    showModalCopy: boolean
    showModalCopyBack: boolean
    editMode: boolean
    origemExterna: boolean
}

export const RastreabilidadeGeralForm = (props: RastreabilidadeGeralFormProps) => {
    const dispatch = useDispatch()
    const [currentAjuda, setCurrentAjuda] = useState<string>(undefined)
    const [cmpCopy, setCmpCopy] = useState<{ cmp: {}; openModal: boolean }>({ cmp: null, openModal: false })
    const fabricantes = useSelector((state: any) => state.rastreabilidade?.loadFabricantes.data)
    const dtCirurgia = useSelector<State, string>(state => state.rastreabilidade?.findProcedimento?.data?.dtCirurgia)

    const formValues: any = useSelector(state => getFormValues(props.formName)(state))
    const dadosFinanceirosFormValues: any = formValues?.get(props.formSectionName)?.get('dadosFinanceirosForm')
    const compradoHospital: string = dadosFinanceirosFormValues?.get('compradoHospital')
    const disabledDadosEconomicos: boolean = compradoHospital === undefined

    const alertError = (mensagem: string) => dispatch(error({ mensagem }))

    const copyComponente = (componente, id?) => {
        componente = componente.get('dadosProdutoForm')
        componente = componente.delete('etiqueta')
        props.change(props.formSectionName + '.dadosProdutoForm', componente)
        id && props.change(`${props.formSectionName}.dadosProdutoForm.id`, id)
    }

    const confirmCopyComponente = componente => {
        if ((!props.showModalCopy || props.showModalCopyBack) && !formValues.toJS()[props.formSectionName]) {
            copyComponente(componente)
        } else {
            setCmpCopy({ cmp: componente, openModal: true })
        }
    }

    const okModalCopyComponente = () => {
        const id = formValues.toJS()[props.formSectionName].dadosProdutoForm.id
        copyComponente(cmpCopy.cmp, id)
        setCmpCopy(prev => ({ ...prev, openModal: false }))
    }

    const closeModalCopyComponente = () => setCmpCopy({ cmp: null, openModal: false })

    const handleChangeLinhaDigitavel = (dadosEtiqueta: Partial<ProdutoRastreabilidadeIdCmpFormDto>) => {
        if (!dadosEtiqueta) {
            return
        }
        const {
            identificadorDispositivo,
            codigoReferencia,
            lote,
            numeroSerie,
            validade,
            dataFabricacao,
            anoValidade,
            mesValidade,
            diaValidade,
        } = dadosEtiqueta
        const { formSectionName, change } = props
        identificadorDispositivo &&
            change(`${formSectionName}.dadosProdutoForm.identificadorDispositivo`, identificadorDispositivo)
        codigoReferencia && change(`${formSectionName}.dadosProdutoForm.codigoReferencia`, codigoReferencia)
        lote && change(`${formSectionName}.dadosProdutoForm.lote`, lote)
        numeroSerie && change(`${formSectionName}.dadosProdutoForm.numeroSerie`, numeroSerie)
        validade && change(`${formSectionName}.dadosProdutoForm.validade`, validade)
        if (dataFabricacao) {
            dataFabricacao.ano && change(`${formSectionName}.dadosProdutoForm.dataFabricacao.ano`, dataFabricacao.ano)
            dataFabricacao.mes && change(`${formSectionName}.dadosProdutoForm.dataFabricacao.mes`, dataFabricacao.mes)
            dataFabricacao.dia && change(`${formSectionName}.dadosProdutoForm.dataFabricacao.dia`, dataFabricacao.dia)
        }
        anoValidade && change(`${formSectionName}.dadosProdutoForm.anoValidade`, anoValidade)
        mesValidade && change(`${formSectionName}.dadosProdutoForm.mesValidade`, mesValidade)
        diaValidade && change(`${formSectionName}.dadosProdutoForm.diaValidade`, diaValidade)
    }

    const formFragment = useDadosProdutoFormFromRastreabilidade(props.formSectionName)

    return (
        <>
            {currentAjuda === 'FINANCEIRO' && (
                <AjudaFinanceiro onClose={() => setCurrentAjuda(undefined)} active={currentAjuda === 'FINANCEIRO'} />
            )}
            <Form {...props}>
                <FormSection name={props.formSectionName}>
                    <AlertModalConfirmacao
                        active={cmpCopy.openModal}
                        title='Deseja copiar os dados do componente?'
                        onOk={okModalCopyComponente}
                        onClose={closeModalCopyComponente}
                    >
                        Você preencheu alguns dados deste componente. Caso confirme a cópia, esses dados serão perdidos.
                    </AlertModalConfirmacao>
                    <Panel className='nome-componente'>{props.componente && props.componente.nome}</Panel>
                    {!props.origemExterna && !props.editMode && (
                        <AvisoPreenchimentoFicha
                            preenchendo='dados de produto e financeiro'
                            preenchido='dados clínicos'
                        />
                    )}
                    <FormSection name='dadosProdutoForm'>
                        {props.canCopy && (
                            <div>
                                <Linha />
                                <ComponenteCopySection
                                    indexComponenteAtual={props.index}
                                    cacheComponentes={formValues.toArray()}
                                    fillInComponente={confirmCopyComponente}
                                />
                                <Linha />
                            </div>
                        )}
                        <IdentificacaoAnvisaFragment formFragment={formFragment} fabricantes={fabricantes} />
                        <IdentificacaoComponenteFragment
                            formFragment={formFragment}
                            alertError={alertError}
                            handleChangeLinhaDigitavel={handleChangeLinhaDigitavel}
                            dtCirurgia={dtCirurgia}
                        />
                    </FormSection>
                    <SectionTitle title='DADOS FINANCEIROS' onAjudaClick={() => setCurrentAjuda('FINANCEIRO')} />
                    <Conteudo>
                        <DadosFinanceirosFormSection
                            disabledInformacoes={disabledDadosEconomicos}
                            compradoHospital={compradoHospital}
                        />
                        <Linha />
                        <ButtonBar>
                            <Button size='medium' disabled={props.submitting} onClick={props.cancelar}>
                                Cancelar
                            </Button>
                            <Button
                                size='medium'
                                pulledRight
                                type={props.canSave ? 'success' : 'primary'}
                                loading={props.submitting}
                                onClick={props.handleSubmit}
                            >
                                {props.canSave ? 'Salvar' : 'Próximo componente'}
                            </Button>
                        </ButtonBar>
                    </Conteudo>
                </FormSection>
            </Form>
        </>
    )
}
