import { createValidator, required } from 'libs/validation'

const LesoesEnxertoModalValidator = createValidator(
    {
        enxertoTipo: required,
        coronariaDestino: required,
    },
    formValidator
)

function formValidator(form) {
    const errors = {}

    const enxertoTipo = form.get('enxertoTipo')

    if (
        (enxertoTipo === 'IN_SITU' && !form.get('enxertoTipoInsitu')) ||
        (enxertoTipo === 'AORTO_CORONARIO' && !form.get('enxertoTipoAorto'))
    ) {
        errors.enxertoTipo = 'Preencha a subopção'
    }
    return errors
}

export default LesoesEnxertoModalValidator
