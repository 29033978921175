import * as React from 'react'
import VisualizacaoContainer from 'view/home/visualizacao/VisualizacaoContainer'

export const ReportVisualizacaoFicha = props => {
    return (
        <div className='container home-conteudo is-vertical-flow is-full-height'>
            <VisualizacaoContainer {...props} />
        </div>
    )
}

export default ReportVisualizacaoFicha
