import { EstadoVersaoFichaEnum, HistoricoProcedimentoDetailRow } from 'backend'
import classnames from 'classnames'
import { ControlProps, LoadingStateContainer, Placement, Popover } from 'libs/components'
import { IconButton } from 'libs/components/presentational/elements/IconButton'
import * as Util from 'libs/components/Util'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { State } from 'redux/modules'
import * as home from 'redux/modules/home'

interface HistoricoPopoverProps extends ControlProps {
    registro: number
    idAtual?: number
    placement?: Placement
}

const HistoricoFichaPopover: React.FunctionComponent<React.PropsWithChildren<HistoricoPopoverProps>> = props => {
    const detail = useSelector<State, PromiseRecord<HistoricoProcedimentoDetailRow[]>>(
        (state: State) => state.home.detailHistoricoPopover
    )
    const estadoFicha = useSelector<State, EstadoVersaoFichaEnum>(
        state => state.procedimento.visualizacaoProcedimento.data?.dadosGerais?.estadoFicha
    )
    const dispatch = useDispatch()
    const history = useHistory()
    const { url } = useRouteMatch()
    const elemRef = React.useRef()

    const classes = classnames('label historico', props.className, Util.helpersClassnames(props))

    const [openedPopover, setOpenedPopover] = React.useState<number>(undefined)

    const openPopover = () => {
        dispatch(home.findHistoricoVersaoDetail(props.registro))
        setOpenedPopover(props.registro)
    }

    const closePopover = () => {
        setOpenedPopover(undefined)
    }

    const onClickEdicao = (index: number, idEdicao?: number) => {
        if (index === 0 && estadoFicha === 'DESATUALIZADA') {
            history.push(url.slice(0, url.lastIndexOf('/')))
        } else if (idEdicao) {
            if (estadoFicha === 'DESATUALIZADA') {
                history.replace(`${idEdicao}`)
            } else {
                history.push(`${url}/${idEdicao}`)
            }
        }
    }

    const getClassName = (element: HistoricoProcedimentoDetailRow, index: number) => {
        let classnamePopover = 'content '
        if ((index === 0 && !props.idAtual) || (index !== 0 && +props.idAtual === element.idHistoricoEdicao)) {
            classnamePopover = classnamePopover + 'atual '
        }
        if (index === 0 || element.idHistoricoEdicao) {
            classnamePopover = classnamePopover + 'is-link'
        }
        return classnamePopover
    }

    return (
        <span>
            <div ref={elemRef} className={classes} onClick={openPopover}>
                {props.children}
            </div>
            <Popover
                className='historico-ficha-popover'
                onHide={closePopover}
                show={openedPopover === props.registro}
                placement={props.placement}
                rootClose={true}
                target={elemRef}
            >
                <LoadingStateContainer readyState={detail.readyState}>
                    <IconButton pulledRight onClick={closePopover} icon='excluir' />
                    <div className='historico-ficha-popover-wrapper'>
                        {detail.readyState === 'success' &&
                            detail.data.map((element, index) => (
                                <div
                                    key={index}
                                    className={getClassName(element, index)}
                                    onClick={() => onClickEdicao(index, element.idHistoricoEdicao)}
                                >
                                    <div className='header'>{element.textoRegistro}</div>
                                    <span className='middle'>{element.usuarioCadastroNome}</span>
                                    <span className='specification'>{element.perfil}</span>
                                    <div className='foot'>{element.dataUltimaModificacao}</div>
                                </div>
                            ))}
                    </div>
                </LoadingStateContainer>
            </Popover>
        </span>
    )
}

HistoricoFichaPopover.defaultProps = {
    placement: 'right',
}

export default HistoricoFichaPopover
