import * as React from 'react'
import * as Util from 'libs/components/Util'
import classnames from 'classnames'

interface Props extends Util.UtilProps {
    label: string
    style?: React.CSSProperties
}

export class EmptyListItem extends React.Component<Props, any> {
    render() {
        const classes = classnames('empty-list')
        return (
            <li style={this.props.style} className={classes}>
                {this.props.label}
            </li>
        )
    }
}
