/* tslint:disable */
import React from 'react'

const SvgConfiguracao = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M12.802 20.857h-1.935a.563.563 0 01-.551-.472l-.349-2.15a6.671 6.671 0 01-1.294-.528l-1.8 1.249a.563.563 0 01-.72-.068l-1.35-1.406a.562.562 0 01-.056-.72l1.26-1.766a6.491 6.491 0 01-.54-1.294l-2.149-.337a.563.563 0 01-.46-.552v-1.935a.562.562 0 01.472-.55l2.137-.36c.133-.454.314-.891.54-1.306L4.758 6.885a.563.563 0 01.068-.72l1.406-1.362a.562.562 0 01.72-.056l1.733 1.26a6.649 6.649 0 011.293-.529l.35-2.148a.562.562 0 01.55-.473h1.924c.271.001.503.195.551.461l.372 2.16c.445.135.875.316 1.282.54l1.766-1.27a.562.562 0 01.72 0l1.373 1.36a.563.563 0 01.067.72l-1.248 1.868c.219.405.396.83.528 1.271l2.172.36c.27.044.47.277.472.551v1.935a.563.563 0 01-.461.552l-2.171.382c-.129.44-.302.867-.518 1.271l1.283 1.79a.563.563 0 01-.057.72l-1.36 1.36a.563.563 0 01-.72.068l-1.8-1.26c-.412.225-.845.406-1.295.54l-.37 2.149a.563.563 0 01-.586.472zm-1.462-1.125h.99l.337-1.957a.686.686 0 01.428-.53 5.625 5.625 0 001.586-.652.686.686 0 01.686 0l1.71 1.193.686-.686-1.215-1.688a.562.562 0 010-.607c.3-.493.524-1.028.664-1.587a.686.686 0 01.473-.495l2.047-.382v-.979l-1.969-.326a.709.709 0 01-.54-.416 5.816 5.816 0 00-.64-1.598.697.697 0 010-.686l1.192-1.721-.698-.687-1.699 1.215a.562.562 0 01-.618 0 5.625 5.625 0 00-1.575-.675.686.686 0 01-.495-.472l-.36-2.014h-.98l-.337 2.036a.563.563 0 01-.427.462 5.625 5.625 0 00-1.598.64.686.686 0 01-.675 0L6.637 5.929l-.686.687 1.181 1.698a.562.562 0 010 .62 5.625 5.625 0 00-.675 1.597.686.686 0 01-.472.495l-2.003.337v.979l1.935.337c.24.04.44.202.529.428.137.563.361 1.102.664 1.597a.686.686 0 010 .687l-1.182 1.676.687.697 1.687-1.125a.562.562 0 01.619 0c.489.297 1.02.517 1.575.653a.686.686 0 01.495.472l.349 1.97zm.517-4.331a3.544 3.544 0 11-.022-7.088 3.544 3.544 0 01.022 7.088zm0-5.963a2.419 2.419 0 10-.022 4.838 2.419 2.419 0 00.022-4.838z' />
    </svg>
)

export default SvgConfiguracao
