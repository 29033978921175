import React from 'react'
import Async from 'react-select/async'
import axios from 'axios'
import { Props } from 'react-select/src/Select'
import { LoadingAnimation } from '../elements/LoadingAnimation'

export interface AsyncSelectProps extends Partial<Props<any>> {
    url: string
    value?: any
    pageSize?: number
    searchDelay?: number
    customizeParams?: any
    maxLength?: number
    getRef?(ref): void
}

export class AsyncSelect extends React.Component<AsyncSelectProps> {
    private typingTimer: NodeJS.Timer

    static defaultProps: Partial<AsyncSelectProps> = {
        backspaceRemovesValue: false,
        getOptionLabel: option => option.nome,
        getOptionValue: opt => opt.id,
        isClearable: true,
        isMulti: false,
        loadingMessage: () => 'Carregando...',
        maxMenuHeight: 300,
        noOptionsMessage: props => (props.inputValue ? 'Nenhum item encontrado.' : 'Digite para pesquisar'),
        pageSize: 10,
        placeholder: '',
        searchDelay: 500,
    }

    constructor(props) {
        super(props)
        this.loadOptions = this.loadOptions.bind(this)
        this.blur = this.blur.bind(this)
    }

    render() {
        const { value, onBlur, ...rest } = this.props
        return (
            <Async
                {...rest}
                ref={ref => this.props.getRef && this.props.getRef(ref)}
                className='Select'
                classNamePrefix='Select'
                components={{ LoadingIndicator }}
                inputProps={{ maxLength: this.props.maxLength }}
                value={value?.toJS ? value.toJS() : value}
                defaultOptions={value?.toJS ? value.toJS() : value}
                onBlur={this.blur()}
                loadOptions={this.loadOptions}
                menuIsOpen={!this.props.isDisabled && this.props.menuIsOpen}
            />
        )
    }

    private loadOptions(query, callback) {
        clearTimeout(this.typingTimer)
        if (this.typingTimer) {
            clearTimeout(this.typingTimer)
        }
        this.typingTimer = setTimeout(this.getPage.bind(this, query, callback), this.props.searchDelay)
    }

    private getPage(query, callback) {
        let params = {
            query: query,
            pageSize: this.props.pageSize,
        }

        params = this.props.customizeParams ? this.props.customizeParams(params) : params

        return axios
            .get(this.props.url, { params: params })
            .then(data => {
                callback(data.data)
            })
            .catch(error => {
                callback(error)
            })
    }

    private blur(): React.EventHandler<React.FocusEvent<{}>> {
        return () => {
            const value = this.props.value?.toJS ? this.props.value.toJS() : this.props.value
            if (this.props.onBlur) {
                if (value && this.props.getOptionValue(value)) {
                    this.props.onBlur(this.props.value)
                } else {
                    this.props.onBlur(null)
                }
            }
        }
    }
}

const LoadingIndicator = props => {
    return <LoadingAnimation {...props} />
}
