import * as React from 'react'
import { TextField, TextFieldProps } from './TextField'

export const normalize = value => value.replace(/[^A-Za-z\dáéíóúÁÉÍÓÚçÇâêôÂÊÔõãÕÃäöüÄÖÜàÀ ]|^\s*$/g, '')

export interface AlfaNumberFieldProps extends TextFieldProps {
    name: string
}

export const AlfaNumberField = (props: AlfaNumberFieldProps) => {
    return <TextField {...props} normalize={normalize} />
}
