import { Cidade } from 'backend'
import { IconButton } from 'libs/components'
import React from 'react'

interface MunicipioBadgeProps {
    municipio: Cidade
    handleDeleteMunicipio: (municipio: Cidade) => void
}

const MunicipioBadge: React.FC<MunicipioBadgeProps> = props => {
    return (
        <li className='municipio-badge is-horizontal-flow'>
            <span>
                {props.municipio.nome}/{props.municipio.uf}
            </span>
            <IconButton icon='excluir' onClick={() => props.handleDeleteMunicipio(props.municipio)} />
        </li>
    )
}

export default MunicipioBadge
