import React, { useEffect, useState } from 'react'
import { TextField } from '../form/field/normalize/TextField'
import { Columns } from '../layout/grid/Columns'
import { Column } from '../layout/grid/Column'
import { Button } from '../elements/Button'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, Form } from 'redux-form/immutable'
import { ChecklistItemField } from '../form/field/normalize/ChecklistItemField'
import { ControlField } from '../form/field/ControlField'
import { VinculoProfissionalFiltro } from 'backend'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'
import { State } from 'redux/modules'
import { PerfisRni } from 'api/Perfis'

interface OwnProps {
    handler: (content: VinculoProfissionalFiltro) => void
    clear: () => void
}

export type Props = OwnProps & InjectedFormProps<{}, OwnProps>

const BuscaProfissional = ({ clear, ...props }: Props) => {
    useEffect(
        () => () => {
            clear()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const [showFilters, setShowFilters] = useState<boolean>(false)
    const handleFilters = () => {
        setShowFilters(!showFilters)
    }

    return (
        <Form onSubmit={props.handleSubmit} className='filter-profissional'>
            <Columns className='filter-profissional-columns'>
                <Column size='7'>
                    <TextField name='nome' placeholder='Digite o nome do profissional' />
                </Column>
                <Column>
                    <Button onClick={props.handleSubmit} type='primary'>
                        Pesquisar
                    </Button>
                </Column>
                <Column>
                    {showFilters ? (
                        <Button onClick={handleFilters} className='button-show-filters' icon='arrowTop'>
                            Esconder filtros
                        </Button>
                    ) : (
                            <Button onClick={handleFilters} className='button-show-filters' icon='arrowBottom'>
                                Exibir filtros
                            </Button>
                        )}
                </Column>
            </Columns>
            <ControlField name='perfis' className={showFilters ? 'show-filters' : 'hide-filters'}>
                <Columns>
                    <Column className='low-padding-bottom'>
                        <ChecklistItemField name='perfis' optionValue='MEDICO_ORTOPEDISTA' label='Ortopedista' />
                    </Column>
                    <Column className='low-padding-bottom'>
                        <ChecklistItemField name='perfis' optionValue='MEDICO_CARDIOLOGISTA' label='Cardiologista' />
                    </Column>
                    <Column className='low-padding-bottom'>
                        <ChecklistItemField name='perfis' optionValue='TECNICO' label='Técnico' />
                    </Column>
                </Columns>
                <Columns>
                    <Column className='low-padding'>
                        <ChecklistItemField
                            name='perfis'
                            optionValue='RESIDENTE_ORTOPEDISTA'
                            label='Residente em ortopedia'
                        />
                    </Column>
                    <Column className='low-padding'>
                        <ChecklistItemField
                            name='perfis'
                            optionValue='RESIDENTE_CARDIOLOGISTA'
                            label='Residente em cardiologia'
                        />
                    </Column>
                    <Column className='low-padding'>
                        <ChecklistItemField
                            name='perfis'
                            optionValue='ADMINISTRATIVO'
                            label='Administrativo/Financeiro'
                        />
                    </Column>
                </Columns>
                <Columns>
                    <Column size='4' className='low-padding-top'>
                        <ChecklistItemField
                            name='perfis'
                            optionValue='GESTOR_INTEGRACAO'
                            label='Gestor de integração'
                        />
                    </Column>
                    <Column size='4' className='low-padding-top'>
                        <ChecklistItemField
                            name='perfis'
                            optionValue='GESTOR_SERVICO_SAUDE'
                            label='Gestor do serviço de saúde'
                        />
                    </Column>
                </Columns>
            </ControlField>
        </Form>
    )
}

const ProfissionalFilterForm = reduxForm<{}, OwnProps>({
    form: 'filtroProfissional',
})(BuscaProfissional)

const selector = formValueSelector('filtroProfissional')
export const ProfissionalFilterCard = connect((state: State, ownProps: OwnProps) => {
    const perfis = selector(state, 'perfis')
    const initialValues = perfis || {
        perfis: PerfisRni,
    }
    return {
        initialValues,
        onSubmit: (values: Map<string, any>) => ownProps.handler(values.toJS()),
        clear: ownProps.clear,
    }
})(ProfissionalFilterForm)
