import React, { useEffect, useState } from 'react'
import * as C from 'libs/components'
import { Route } from 'react-router-dom'
import { TabMenu, TabItem } from 'view/home/componentes/HomeMenu'
import ModalJustificativa from 'view/home/componentes/ModalJustificativa'
import { MotivoRecusaModal } from 'view/home/componentes/MotivoRecusaModal'
import { ProfissionalFilterCard } from 'libs/components/presentational/card/BuscaProfissionalCardFilterable'
import { usePesquisaSatisfacaoPopup } from 'view/componentes/pesquisaSatisfacao/usePesquisaSatisfacaoPopup'
import { destroy } from 'redux-form'
import { useDispatch } from 'react-redux'
import { maskCpf } from 'libs/util/CpfUtil'

export const DescricaoPerfil = {
    MEDICO_ORTOPEDISTA: 'MÉDICO - ORTOPEDISTA',
    MEDICO_CARDIOLOGISTA: 'MÉDICO - CARDIOLOGISTA',
    TECNICO: 'TÉCNICO',
    ADMINISTRATIVO: 'ADMINISTRATIVO E FINANCEIRO',
    GESTOR_INTEGRACAO: 'GESTOR DE INTEGRAÇÃO',
    GESTOR_SERVICO_SAUDE: 'GESTOR DO SERVIÇO DE SAÚDE',
    RESIDENTE_ORTOPEDISTA: 'RESIDENTE EM ORTOPEDIA',
    RESIDENTE_CARDIOLOGISTA: 'RESIDENTE EM CARDIOLOGIA',
    SNVS: 'GESTOR SISTEMA NACIONAL DE VIGILÂNCIA SANITÁRIA',
}

const renderRegistroConselho = value => {
    let registroConselho = ' -- '

    if (value.numeroRegistro) {
        registroConselho = value.numeroRegistro + '/' + value.ufRegistro
    }

    return registroConselho
}

const renderDefaultTableColumns = () => [
    <C.TableColumnHeader
        key='1'
        size='1'
        render={value => <C.UserInitials skin='2' size='medium' nome={value.nome} />}
    />,
    <C.TableColumnHeader
        key='2'
        size='9'
        render={value => (
            <div className='is-horizontal-flow'>
                <div>
                    <div className='perfil'>{DescricaoPerfil[value.perfil]}</div>
                    <div className='nome is-semibold'>{value.nome}</div>
                    <span className='cpf-title'>CPF: </span>
                    <span className='cpf'> {maskCpf(value.cpf)} </span>
                    {value.numeroRegistro && (
                        <span>
                            {' '}
                            <span className='cpf-title'>CRM: </span>{' '}
                            <span className='cpf'>{renderRegistroConselho(value)}</span>
                        </span>
                    )}
                </div>
            </div>
        )}
    />,
]

export default class HomeGestorSegurancaView extends React.Component {
    render() {
        return (
            <div className='home-perfil-container is-vertical-flow is-full-height has-text-centered'>
                <C.Columns fullHeight>
                    <C.Column verticalFlow size='2'>
                        <TabMenu>
                            <TabItem icon='profissionaishospital' path={'/home'}>
                                Profissionais da instituição
                            </TabItem>
                            <TabItem icon='vinculospendentes' path={'/home/vinculosPendentes'}>
                                Vínculos pendentes
                            </TabItem>
                            <TabItem icon='vinculosrecusados' path={'/home/vinculosRecusados'}>
                                Vínculos recusados
                            </TabItem>
                        </TabMenu>
                    </C.Column>
                    <C.Column offset='1' size='8'>
                        <Route
                            exact
                            path={'/home'}
                            render={() => {
                                return (
                                    <ProfissionaisHospital
                                        loadProfissionaisHospital={this.props.loadProfissionaisHospital}
                                        filterProfissionaisHospital={this.props.filterProfissionaisHospital}
                                        profissionaisHospital={this.props.profissionaisHospital}
                                        desativarVinculo={this.props.desativarVinculo}
                                        clearCommand={this.props.clearFilters}
                                        paginator={this.props.profissionaisHospitalPaginator}
                                        isFetching={this.props.isFetchingProfissionaisHospital}
                                    />
                                )
                            }}
                        />
                        <Route
                            exact
                            path={'/home/vinculosPendentes'}
                            render={() => {
                                return (
                                    <VinculosPendentes
                                        loadVinculosPendentesHospital={this.props.loadVinculosPendentesHospital}
                                        filterVinculosPendentesHospital={this.props.filterVinculosPendentesHospital}
                                        aprovarSolicitacaoVinculo={this.props.aprovarSolicitacaoVinculo}
                                        recusarSolicitacaoVinculo={this.props.recusarSolicitacaoVinculo}
                                        vinculosPendentesHospital={this.props.vinculosPendentesHospital}
                                        clearCommand={this.props.clearFilters}
                                        paginator={this.props.vinculosPendentesPaginator}
                                        isFetching={this.props.isFetchingVinculosPendentesHospital}
                                    />
                                )
                            }}
                        />
                        <Route
                            exact
                            path={'/home/vinculosRecusados'}
                            render={() => {
                                return (
                                    <VinculosRecusados
                                        loadVinculosRecusadosHospital={this.props.loadVinculosRecusadosHospital}
                                        filterVinculosRecusadosHospital={this.props.filterVinculosRecusadosHospital}
                                        vinculosRecusadosHospital={this.props.vinculosRecusadosHospital}
                                        vinculoRecusado={this.props.vinculoRecusado}
                                        selecionarVinculoRecusado={this.props.selecionarVinculoRecusado}
                                        clearCommand={this.props.clearFilters}
                                        paginator={this.props.vinculosRecusadosPaginator}
                                        isFetching={this.props.isFetchingVinculosRecusadosHospital}
                                    />
                                )
                            }}
                        />
                    </C.Column>
                </C.Columns>
            </div>
        )
    }
}

export function ProfissionaisHospital({ loadProfissionaisHospital: loadTable, ...props }) {
    const { showPesquisa } = usePesquisaSatisfacaoPopup()
    useEffect(() => {
        loadTable({})
    }, [loadTable])

    const [openModal, setOpenModal] = useState(undefined)

    const makeOpen = vinculoId => {
        return () => setOpenModal(vinculoId)
    }

    const close = () => {
        setOpenModal(undefined)
    }

    const makeOk = vinculoId => {
        return () => {
            props.desativarVinculo(vinculoId)
            setOpenModal(false)
            showPesquisa()
        }
    }

    return (
        <div className='home-perfil-conteudo'>
            <span className='titulo'>Profissionais da instituição</span>
            <ProfissionalFilterCard
                handler={props.filterProfissionaisHospital}
                clear={() => props.clearCommand(props.paginator)}
            />
            <C.LoadingContainer isFetching={props.isFetching}>
                <C.PagedTable
                    addRowSpacer
                    className='table-home table-home-gestor'
                    page={props.profissionaisHospital}
                    onChangePage={loadTable}
                    emptyText='Nenhum profissional encontrado.'
                >
                    {renderDefaultTableColumns()}
                    <C.TableColumnHeader
                        render={value => [
                            <C.Hint
                                key='1'
                                placement='top'
                                componente={
                                    <C.IconButton
                                        type='light'
                                        onClick={makeOpen(value.id)}
                                        pulledRight
                                        icon='excluir'
                                    />
                                }
                            >
                                Desativar vínculo
                            </C.Hint>,
                            <C.AlertModalConfirmacao
                                active={openModal === value.id}
                                key='2'
                                title='Desativar vínculo?'
                                onClose={close}
                                onOk={makeOk(value.id)}
                            >
                                Ao confirmar, este vínculo com o hospital será desativado. Tem certeza que deseja
                                desativar o vínculo?
                            </C.AlertModalConfirmacao>,
                        ]}
                    />
                </C.PagedTable>
            </C.LoadingContainer>
        </div>
    )
}

export function VinculosPendentes({ loadVinculosPendentesHospital: loadTable, ...props }) {
    const [showMotivoRecusa, setShowMotivoRecusa] = useState(false)
    const [idVinculoRecusado, setIdVinculoRecusado] = useState(undefined)
    const dispatch = useDispatch()
    const { showPesquisa } = usePesquisaSatisfacaoPopup()

    useEffect(() => {
        loadTable({})
    }, [loadTable])

    const aprovarSolicitacaoVinculo = solicitacaoVinculoId => {
        return () => {
            props.aprovarSolicitacaoVinculo(solicitacaoVinculoId)
            showPesquisa()
        }
    }

    const recusarSolicitacaoVinculo = solicitacaoVinculoId => {
        return form => {
            form = form.set('idVinculoRecusado', solicitacaoVinculoId)
            return props.recusarSolicitacaoVinculo(form)
        }
    }

    const showMotivoRecusaModal = solicitacaoVinculoId => {
        return () => {
            setShowMotivoRecusa(true)
            setIdVinculoRecusado(solicitacaoVinculoId)
        }
    }

    const hideMotivoRecusaModal = () => {
        setShowMotivoRecusa(false)
        setIdVinculoRecusado(undefined)
        dispatch(destroy('motivoRecusa'))
    }
    const hideMotivoRecusaModalSucess = () => {
        hideMotivoRecusaModal()
        showPesquisa()
    }

    return (
        <div className='home-perfil-conteudo'>
            <span className='titulo'>Vínculos pendentes</span>
            <ProfissionalFilterCard
                handler={props.filterVinculosPendentesHospital}
                clear={() => props.clearCommand(props.paginator)}
            />
            <C.LoadingContainer isFetching={props.isFetching}>
                <C.PagedTable
                    addRowSpacer
                    className='table-home table-home-gestor'
                    page={props.vinculosPendentesHospital}
                    onChangePage={loadTable}
                    emptyText='Nenhum vínculo pendente.'
                >
                    {renderDefaultTableColumns()}
                    <C.TableColumnHeader
                        size='2'
                        render={value => [
                            <C.Hint
                                key='1'
                                placement='top'
                                componente={
                                    <C.IconButton
                                        icon='aprovar'
                                        type='success'
                                        pulledRight
                                        onClick={aprovarSolicitacaoVinculo(value.id)}
                                    />
                                }
                            >
                                Aprovar vínculo
                            </C.Hint>,

                            <C.Hint
                                key='2'
                                placement='top'
                                componente={
                                    <C.IconButton
                                        icon='recusar'
                                        type='light'
                                        pulledRight
                                        onClick={showMotivoRecusaModal(value.id)}
                                    />
                                }
                            >
                                Recusar vínculo
                            </C.Hint>,
                        ]}
                    />
                </C.PagedTable>
            </C.LoadingContainer>
            <MotivoRecusaModal
                active={showMotivoRecusa}
                onClose={hideMotivoRecusaModal}
                onSubmit={recusarSolicitacaoVinculo(idVinculoRecusado)}
                onSubmitSuccess={hideMotivoRecusaModalSucess}
            />
        </div>
    )
}

export function VinculosRecusados({ loadVinculosRecusadosHospital: loadTable, ...props }) {
    useEffect(() => {
        loadTable({})
    }, [loadTable])

    const [openModal, setOpenModal] = useState(undefined)

    const visualizarVinculoRecusado = idVinculo => {
        return () => {
            setOpenModal(idVinculo)
        }
    }

    return (
        <div className='home-perfil-conteudo'>
            <span className='titulo'>Vínculos recusados</span>
            <ProfissionalFilterCard
                handler={props.filterVinculosRecusadosHospital}
                clear={() => props.clearCommand(props.paginator)}
            />
            <C.LoadingContainer isFetching={props.isFetching}>
                <C.PagedTable
                    addRowSpacer
                    className='table-home table-home-gestor'
                    page={props.vinculosRecusadosHospital}
                    onChangePage={loadTable}
                    emptyText='Nenhum vínculo recusado.'
                >
                    {renderDefaultTableColumns()}
                    <C.TableColumnHeader
                        render={value => [
                            <C.Hint
                                key='1'
                                placement='top'
                                componente={
                                    <C.IconButton
                                        pulledRight
                                        type='light'
                                        icon='detalhes'
                                        onClick={visualizarVinculoRecusado(value.id)}
                                    />
                                }
                            >
                                Visualizar
                            </C.Hint>,
                            <ModalJustificativa
                                key='2'
                                vinculo={value}
                                active={openModal === value.id}
                                onClose={() => setOpenModal(undefined)}
                            />,
                        ]}
                    />
                </C.PagedTable>
            </C.LoadingContainer>
        </div>
    )
}
