import { Validade } from 'backend'
import { ClearRadioFieldButton, Column, Columns, ControlField, InputPanel, RadioField } from 'libs/components'
import * as React from 'react'
import { DataValidade } from './DataValidade'
import { PeriodoValidade } from './PeriodoValidade'

export interface ValidadeFieldProps {
    anoCirurgia: number
    validade: Validade
    disabledPeriodo: boolean
    disabledMes: boolean
    disabledDia: boolean
    ano: any
    mes: any
}
export const ValidadeField = ({ disabledPeriodo, validade, ...rest }: ValidadeFieldProps) => (
    <>
        <Columns>
            <Column>
                <ControlField label='Validade' name='validade' required>
                    <RadioField name='validade' label='Data' value='DATA' />
                    <RadioField name='validade' label='Período' value='PERIODO' disabled={disabledPeriodo} />
                    <ClearRadioFieldButton name='validade' />
                </ControlField>
            </Column>
        </Columns>
        <InputPanel size='medium'>
            {validade === 'PERIODO' ? (
                <PeriodoValidade />
            ) : (
                <DataValidade disabledAno={validade !== 'DATA'} {...rest} />
            )}
        </InputPanel>
    </>
)
