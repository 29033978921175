import Componentes from 'api/Componentes'
import { promiseReducer, PromiseRecord } from 'redux/middleware/PromiseAction'
import { StentDto } from 'backend'
import { error } from 'redux/modules/alert'

export const LOAD_STENTS = 'rni/componentes/LOAD_STENTS'
export const EDIT_STENTS = 'rni/componentes/EDIT_STENTS'
export const CLEAR = 'rni/componentes/CLEAR'
export const NEW_STENT_SUCCESS = 'rni/componentes/NEW_STENT_SUCCESS'
export const EDIT_STENT_SUCCESS = 'rni/componentes/EDIT_STENT_SUCCESS'
export const OTM_CHANGE_ATIVO = 'rni/componentes/OTM_CHANGE_ATIVO'
export const ATIVO_ROLLBACK = 'rni/componentes/ATIVO_ROLLBACK'

export interface ComponentesState
    extends Readonly<{
        stents: PromiseRecord<StentDto[]>
        editinStent: StentDto
        openModal: boolean
    }> {}

const ComponentesInitialState: ComponentesState = {
    stents: {},
    editinStent: undefined,
    openModal: false,
}

export default function reducer(state = ComponentesInitialState, action): ComponentesState {
    switch (action.type) {
        case LOAD_STENTS:
            return { ...state, stents: promiseReducer(state.stents, action) }
        case EDIT_STENTS:
            return {
                ...state,
                openModal: true,
                editinStent: action.stent,
            }
        case NEW_STENT_SUCCESS:
            return {
                ...state,
                openModal: false,
                stents: addStentReducer(state.stents, action.stent),
            }
        case EDIT_STENT_SUCCESS:
            return {
                ...state,
                openModal: false,
                stents: editReducer(state.stents, action.stent),
            }
        case OTM_CHANGE_ATIVO:
            return { ...state, stents: changeAtivoReducer(state.stents, action.id) }
        case ATIVO_ROLLBACK:
            return { ...state, stents: changeAtivoReducer(state.stents, action.id) }
        case CLEAR:
            return {
                ...state,
                openModal: false,
                editinStent: undefined,
            }
        default:
            return state
    }
}

export function addStentReducer(stents: PromiseRecord<StentDto[]>, stent: StentDto) {
    return {
        ...stents,
        data: [stent, ...stents.data],
    }
}

export function editReducer(stents: PromiseRecord<StentDto[]>, stent: StentDto) {
    const edited: StentDto = stents.data.find(elem => elem.id === stent.id)
    edited.descricaoTipoStent = stent.descricaoTipoStent
    edited.nome = stent.nome
    edited.tipoStent = stent.tipoStent
    return {
        ...stents,
        data: [...stents.data],
    }
}

export const changeAtivoReducer = (stents: PromiseRecord<StentDto[]>, id: number) => {
    const stent: StentDto = stents.data.find(elem => elem.id === id)
    stent.ativo = !stent.ativo
    return {
        ...stents,
        data: [...stents.data],
    }
}

export const loadStents = () => ({
    type: LOAD_STENTS,
    promise: Componentes.loadStents(),
})

export const setStentEdition = (stent: StentDto) => ({
    type: EDIT_STENTS,
    stent,
})

export const clearEditin = () => ({
    type: CLEAR,
})

export const newStentSuccess = (stent: StentDto) => ({
    type: NEW_STENT_SUCCESS,
    stent,
})

export const editStentSuccess = (stent: StentDto) => ({
    type: EDIT_STENT_SUCCESS,
    stent,
})

export const otimistChangeAtivoStent = (id: number) => ({
    type: OTM_CHANGE_ATIVO,
    id,
})

export const effectiveChangeAtivoStent = (id: number) => (dispatch, getState) => {
    const stent: StentDto = getState().componentes.stents.data.find(elem => elem.id === id)
    Componentes.saveStent(stent).catch(res => {
        dispatch(stentAtivoRollback(id))
        dispatch(
            error({
                mensagem: `Erro ao ativar/desativar ${stent.nome}`,
            })
        )
    })
}

export const stentAtivoRollback = (id: number) => ({
    type: ATIVO_ROLLBACK,
    id,
})
