import * as React from 'react'
import classnames from 'classnames'
import { Chart, ChartProps, ChartOptions } from './Chart'
import { ChartTable } from './ChartTable'
import { Card } from '../presentational/card/Card'
import { LoadingStateContainer, Icon } from 'libs/components'
import AutoPopover from 'libs/components/presentational/AutoPopover'
import Menu from 'libs/components/presentational/layout/Menu'
import MenuItem from 'libs/components/presentational/layout/MenuItem'

export type ViewMode = 'grafico' | 'column' | 'tabela' | 'bar' | 'pie'

export interface ChartCardProps extends ChartProps {
    title: string
    readyState: 'request' | 'success' | 'failure'
    defaultViewMode?: ViewMode
    renderTools?: boolean
    renderActions?: boolean
}

interface State {
    viewMode: ViewMode
    config: ChartOptions
}

export class ChartCard extends React.Component<ChartCardProps, State> {
    private menuPopover: AutoPopover

    static defaultProps: Partial<ChartCardProps> = {
        defaultViewMode: 'grafico',
        renderTools: true,
        renderActions: true,
    }

    constructor(props: ChartCardProps) {
        super(props)
        this.state = {
            viewMode: props.defaultViewMode,
            config: {
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        },
                    },
                },
            },
        }
    }

    render() {
        const { title, readyState, renderActions, renderTools, ...rest } = this.props
        const hasData = rest.config && rest.config.series && rest.config.series.length > 0

        return (
            <Card
                title={title}
                headerTools={renderTools && this.renderTools()}
                headerAction={renderActions && this.renderHeaderAction()}
                noPadding={this.state.viewMode === 'tabela' && hasData}
            >
                <LoadingStateContainer readyState={readyState}>
                    {hasData ? (
                        this.renderContent()
                    ) : (
                        <p className='has-text-centered is-text-muted'>Nenhum procedimento encontrado.</p>
                    )}
                </LoadingStateContainer>
            </Card>
        )
    }

    renderContent() {
        const config = this.props.config as any

        if (this.state.viewMode === 'tabela') {
            return <ChartTable title='Período' categories={config.xAxis.categories} series={config.series} />
        } else {
            let config: ChartOptions = {
                ...this.state.config,
                ...this.props.config,
                chart: {
                    type: this.state.viewMode === 'grafico' ? 'spline' : this.state.viewMode,
                },
            }

            return (
                <div>
                    <Chart config={config} />
                </div>
            )
        }
    }

    renderTools() {
        return (
            <div>
                <p
                    key='grafico'
                    title='Visualizar gráfico em linha'
                    onClick={this.changeViewMode.bind(this, 'grafico')}
                >
                    <Icon icon='grafico' className={classnames({ 'is-neon': this.state.viewMode === 'grafico' })} />
                </p>
                <p
                    key='colunas'
                    title='Visualizar gráfico em coluna'
                    onClick={this.changeViewMode.bind(this, 'column')}
                >
                    <Icon icon='colunas' className={classnames({ 'is-neon': this.state.viewMode === 'column' })} />
                </p>
                <p key='tabela' title='Visualizar tabela de dados' onClick={this.changeViewMode.bind(this, 'tabela')}>
                    <Icon icon='tabela' className={classnames({ 'is-neon': this.state.viewMode === 'tabela' })} />
                </p>
            </div>
        )
    }

    renderHeaderAction() {
        return (
            <AutoPopover
                ref={elem => (this.menuPopover = elem)}
                renderTrigger={open => (
                    <p onClick={open}>
                        <Icon icon='filtro' />
                    </p>
                )}
                rootClose
                placement='bottom'
                className='popover-menu'
            >
                <Menu>
                    <MenuItem onClick={this.handleMostrarTotalizadores}>
                        {!Array.isArray(this.state.config.plotOptions.series.dataLabels) &&
                        this.state.config.plotOptions.series.dataLabels.enabled
                            ? 'Esconder totalizadores'
                            : 'Mostrar totalizadores'}
                    </MenuItem>
                </Menu>
            </AutoPopover>
        )
    }

    changeViewMode(viewMode: ViewMode) {
        this.setState({ viewMode })
    }

    handleMostrarTotalizadores = () => {
        this.menuPopover.close()
        this.setState({
            config: {
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled:
                                !Array.isArray(this.state.config.plotOptions.series.dataLabels) &&
                                !this.state.config.plotOptions.series.dataLabels.enabled,
                        },
                    },
                },
            },
        })
    }
}
