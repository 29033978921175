/* tslint:disable */
import React from 'react'

const SvgEditar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 18 18' {...props}>
        <path d='M15.603 5.278c.53.532.53 1.394 0 1.926l-8.679 8.655a.468.468 0 01-.327.141H2.468A.468.468 0 012 15.53v-4.14a.47.47 0 01.14-.289l8.67-8.702a1.355 1.355 0 011.92 0l2.873 2.88zM6.402 15.1l5.806-5.822-3.465-3.475-5.807 5.822V15.1h3.466zm8.537-8.562a.423.423 0 000-.626l-2.872-2.856a.42.42 0 00-.6 0L9.397 5.13l3.473 3.482 2.068-2.074z' />
    </svg>
)

export default SvgEditar
