import { Button, ControlField, Linha } from 'libs/components'
import React from 'react'
import { FieldArray } from 'redux-form/immutable'
import { RelacaoEmpresasList } from './RelacaoEmpresasList'

interface RelacaoEmpresasAdicionadasSectionProps {
    enableAddEmpresa: boolean
}

export const RelacaoEmpresasAdicionadasSection: React.FC<RelacaoEmpresasAdicionadasSectionProps> = ({
    enableAddEmpresa,
}) => {
    const [openModal, setOpenModal] = React.useState<boolean>(false)
    const handleOpenModal = () => setOpenModal(true)
    return (
        <>
            <ControlField
                name='empresas'
                label='Empresas adicionadas'
                labelClassName='content--section-title'
                required={enableAddEmpresa}
            />
            <Linha className='content--line' />
            <Button
                type='grey'
                square
                dashed
                size='large'
                fullwidth
                disabled={!enableAddEmpresa}
                onClick={handleOpenModal}
            >
                + ADICIONAR EMPRESA
            </Button>
            <FieldArray
                name='empresas'
                component={RelacaoEmpresasList}
                openModal={openModal}
                onClickEditCard={handleOpenModal}
                onCloseModal={() => setOpenModal(false)}
            />
        </>
    )
}
