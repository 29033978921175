import { dadosComplementaresByEnum } from 'api/Visualizacao'
import { Columns, ComposedLabel } from 'libs/components'
import React from 'react'
import { ComposedLabelVisualizacao } from '../ComposedLabelVisualizacao'
import { StentCoronariaComplementarVisualizacaoDto } from 'backend'
import { isNullOrUndefined } from 'util'

interface AcessoArterialCardProps {
    dadosComplementares: StentCoronariaComplementarVisualizacaoDto
}

export class AcessoArterialCard extends React.Component<AcessoArterialCardProps> {
    render() {
        const data = this.props.dadosComplementares
        const volume = data.volume && data.volume + ' ml'
        const CapitalizeFirstLetter = function(str) {
            return str.charAt(0).toUpperCase() + str.slice(1).replace('.', '')
        }

        return (
            <div>
                <Columns auto>
                    <ComposedLabel title={'Via de acesso'}>
                        {data.viaAcesso && dadosComplementaresByEnum[data.viaAcesso]}
                    </ComposedLabel>
                    <ComposedLabel title={'Lados'}>{data.lado && CapitalizeFirstLetter(data.lado)}</ComposedLabel>
                    {data.viaAcesso && (
                        <ComposedLabelVisualizacao moreOneColumn title={'Crossover'}>
                            {data.crossover ? 'Sim' : 'Não'}
                        </ComposedLabelVisualizacao>
                    )}
                </Columns>
                <Columns auto>
                    <ComposedLabel title={'Introdutor'}>{dadosComplementaresByEnum[data.introdutor]}</ComposedLabel>
                </Columns>
                <Columns auto>
                    <ComposedLabel title={'Tipo de contraste'}>
                        {data.tipoContraste && dadosComplementaresByEnum[data.tipoContraste]}
                    </ComposedLabel>
                    {!isNullOrUndefined(data.tipoContraste) && (
                        <ComposedLabel title={'Volume total'}>{volume}</ComposedLabel>
                    )}
                </Columns>
            </div>
        )
    }
}
