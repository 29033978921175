import * as React from 'react'
import classnames from 'classnames'

interface Props {
    nome: string
    skin?: string
    size?: string
}

export class UserInitials extends React.Component<Props, any> {
    static defaultProps: Props = {
        nome: '',
        skin: '1',
        size: 'large',
    }

    render() {
        const { nome } = this.props

        const nameParts = nome.split(' ')

        const initials =
            nameParts.length > 1
                ? nameParts[0].substring(0, 1) + nameParts[nameParts.length - 1].substring(0, 1)
                : nameParts[0].substring(0, 1)

        const classes = classnames('user-initials', {
            large: this.props.size === 'large',
            medium: this.props.size === 'medium',
            'size-64': this.props.size === 'size-64',
            'skin-1': this.props.skin === '1',
            'skin-2': this.props.skin === '2',
            'skin-blue': this.props.skin === 'blue',
            'skin-red': this.props.skin === 'red',
            'skin-green': this.props.skin === 'green',
        })

        return <div className={classes}>{initials.toUpperCase()}</div>
    }
}
