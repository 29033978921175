import React, { useState } from 'react'
import {
    Button,
    CidadeField,
    Column,
    Columns,
    Conteudo,
    EmptyListItem,
    HospitalField,
    Linha,
    UfField,
} from 'libs/components'
import { RadioPanelField } from 'libs/components/presentational/form/field/RadioPanelField'
import { useDispatch, useSelector } from 'react-redux'
import { Cidade, Hospital, Uf } from 'backend'
import { change } from 'redux-form'
import { adicionarEntidadeFormName, selector } from '../AdicionarEntidadeView'
import HospitalBadge from './HospitalBadge'
import { isImmutable } from 'immutable'

const EsferaAcessoHospitalPanel: React.FC = () => {
    const state = useSelector(state => state)
    const dispatch = useDispatch()

    let listaHospitais = selector(state, 'hospitaisAcesso') || []

    const [selectedUf, setSelectedUf] = useState<Uf>(null)
    const [selectedMunicipio, setSelectedMunicipio] = useState<Cidade>(null)
    const [selectedHospital, setSelectedHospital] = useState<Hospital>(null)
    const [canAdd, setCanAdd] = useState<boolean>(false)

    if (isImmutable(listaHospitais)) {
        listaHospitais = listaHospitais.toJSON()
    }

    function handleChangeUf(uf) {
        setSelectedUf(uf)
        setCanAdd(false)
        setSelectedMunicipio(null)
        setSelectedHospital(null)
        dispatch(change(adicionarEntidadeFormName, 'esferaHospitalCidade', null))
        dispatch(change(adicionarEntidadeFormName, 'esferaHospitalHospital', null))
    }

    function handleChangeMunicipio(municipio) {
        setSelectedMunicipio(municipio)
        setCanAdd(false)
        setSelectedHospital(null)
        dispatch(change(adicionarEntidadeFormName, 'esferaHospitalHospital', null))
    }

    function handleChangeHospital(hospital) {
        setSelectedHospital(hospital)
        if (hospital !== null) {
            const wasAdded = listaHospitais.findIndex(item => item.id === hospital.id)
            setCanAdd(wasAdded === -1)
        } else {
            setCanAdd(false)
        }
    }

    function handleAddHospital() {
        dispatch(change(adicionarEntidadeFormName, 'hospitaisAcesso', [...listaHospitais, selectedHospital]))
        dispatch(change(adicionarEntidadeFormName, 'esferaHospitalUf', null))
        dispatch(change(adicionarEntidadeFormName, 'esferaHospitalCidade', null))
        dispatch(change(adicionarEntidadeFormName, 'esferaHospitalHospital', null))

        setSelectedUf(null)
        setSelectedMunicipio(null)
        setSelectedHospital(null)
        setCanAdd(false)
    }

    function handleDeleteHospital(hospital) {
        dispatch(
            change(
                adicionarEntidadeFormName,
                'hospitaisAcesso',
                listaHospitais.filter(hosp => hosp.id !== hospital.id)
            )
        )
    }

    function handleClearHospitais() {
        dispatch(change(adicionarEntidadeFormName, 'hospitaisAcesso', []))
    }

    return (
        <RadioPanelField
            label='Hospital'
            name='esferaAcesso'
            value='HOSPITAL'
            showErrors={true}
            testid='radio-hospital-esfera'
        >
            <Conteudo>
                <Columns>
                    <Column size='3'>
                        <UfField
                            label='UF'
                            name='esferaHospitalUf'
                            placeholder='UF'
                            required
                            // @ts-ignore
                            onChange={handleChangeUf}
                        />
                    </Column>
                    <Column size='4'>
                        <CidadeField
                            isDisabled={selectedUf === null}
                            ufId={selectedUf ? selectedUf.id : ''}
                            required
                            label='Município'
                            name='esferaHospitalCidade'
                            placeholder='Município'
                            onChange={handleChangeMunicipio}
                        />
                    </Column>
                </Columns>
                <Columns>
                    <Column size='7'>
                        <HospitalField
                            isDisabled={selectedMunicipio === null}
                            label='Hospital'
                            name='esferaHospitalHospital'
                            placeholder='Digite o nome do hospital para pesquisar'
                            required
                            cidadeId={selectedMunicipio ? String(selectedMunicipio.id) : null}
                            onChange={handleChangeHospital}
                        />
                    </Column>
                    <Column className='align-end' size='4'>
                        <Button
                            type='primary'
                            size='normal'
                            onClick={handleAddHospital}
                            disabled={!canAdd}
                            testid='button-add-hospital-esfera'
                        >
                            Adicionar
                        </Button>
                    </Column>
                </Columns>
                <Linha />
                <div className='lista-hospitais'>
                    <div className='lista-header is-horizontal-flow'>
                        <h1>Hospitais adicionados</h1>
                        {listaHospitais.length > 0 && (
                            <Button className='btn-clear' type='grey' size='small' onClick={handleClearHospitais}>
                                LIMPAR TODOS
                            </Button>
                        )}
                    </div>
                    <ul className='lista-content is-horizontal-flow'>
                        {listaHospitais.map(hospital => (
                            <HospitalBadge
                                key={hospital.id}
                                hospital={hospital}
                                handleDeleteHospital={handleDeleteHospital}
                            />
                        ))}

                        {listaHospitais.length === 0 && <EmptyListItem label='Nenhum hospital adicionado' />}
                    </ul>
                </div>
            </Conteudo>
        </RadioPanelField>
    )
}

export default EsferaAcessoHospitalPanel
