/* tslint:disable */
import React from 'react'

const SvgPesquisar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 21 21' {...props}>
        <path d='M18.472 17.025a.1.1 0 01-.03.159l-1.288 1.288a.1.1 0 01-.139 0l-4.578-4.608v-.585l-.754-.753a5.64 5.64 0 11.843-.843l.743.754h.585l4.618 4.588zm-13.39-5.728a4.4 4.4 0 006.205 0 4.39 4.39 0 10-6.204 0z' />
    </svg>
)

export default SvgPesquisar
