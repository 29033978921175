import { connect } from 'react-redux'
import * as actions from 'redux/modules/alert'
import * as C from 'libs/components'
import { State } from 'redux/modules'

const mapStateToProps = (state: State) => {
    return {
        items: state.alert.items,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removeAlert: uuid => {
            dispatch(actions.removeAlert(uuid))
        },
    }
}

export const AlertBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(C.AlertBar)
