import * as React from 'react'
import { HomeGestorServicoSaudeHeader } from '../HomeGestorServicoSaudeHeader'
import SumarioPacienteContainer from '../../anvisa/reports/paciente/SumarioPacienteContainer'

export const GestorServicoSaudeSumarioPacienteView = () => {
    return (
        <>
            <HomeGestorServicoSaudeHeader />
            <SumarioPacienteContainer />
        </>
    )
}
