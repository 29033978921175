import React, { useCallback, useEffect } from 'react'
import { CheckboxField, Column, Columns, ControlField, MesField, NumberField, Text } from 'libs/components'
import { useDispatch, useSelector } from 'react-redux'
import { change, untouch } from 'redux-form'
import { formValueSelector } from 'redux-form/immutable'
import classnames from 'classnames'

interface PeriodoVigenciaFormProps {
    formName: string
    /**
     * Aplica classes no ControlField
     */
    controlFieldClassName?: string
    /**
     * Aplica classes no wrapper externo dos inputs
     */
    contentClassName?: string
    /**
     * Aplica clases nas labels de input
     */
    inputLabelClassName?: string
}

const PeriodoVigenciaForm: React.FC<PeriodoVigenciaFormProps> = props => {
    const { controlFieldClassName = null, contentClassName = null, inputLabelClassName = null } = props

    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const selector = formValueSelector(props.formName)

    const isVigenciaIndeterminada = selector(state, 'vigenciaIndeterminada') === true || false
    const mesFimIsNulled = selector(state, 'mesFim') === null
    const anoFimIsNulled = selector(state, 'anoFim') === null

    const clearField = useCallback(
        (fieldName: string) => {
            dispatch(change(props.formName, fieldName, null))
            dispatch(untouch(props.formName, fieldName))
        },
        [dispatch, props.formName]
    )

    useEffect(() => {
        if (isVigenciaIndeterminada) {
            if (!mesFimIsNulled) {
                clearField('mesFim')
            }
            if (!anoFimIsNulled) {
                clearField('anoFim')
            }
        }
    }, [isVigenciaIndeterminada, mesFimIsNulled, anoFimIsNulled, clearField])

    return (
        <>
            <ControlField
                required
                className={controlFieldClassName}
                label='Período de vigência'
                name='periodoVigencia'
            />
            <Columns className={classnames('periodo-vigencia-form-section', contentClassName)}>
                <Column>
                    <Text className={inputLabelClassName} weight='bold'>
                        Desde
                    </Text>
                    <MesField name='mesInicio' disabled={false} placeholder='Mês' />
                </Column>
                <Column>
                    <NumberField name='anoInicio' placeholder='Ano' maxLength={4} testid='numberField-anoInicio' />
                </Column>
                <Column>
                    <Text className={inputLabelClassName} weight='bold'>
                        Até
                    </Text>
                    <MesField name='mesFim' disabled={isVigenciaIndeterminada} placeholder='Mês' />
                </Column>
                <Column>
                    <NumberField
                        name='anoFim'
                        disabled={isVigenciaIndeterminada}
                        placeholder='Ano'
                        maxLength={4}
                        testid='numberField-anoFim'
                    />
                </Column>
            </Columns>
            <CheckboxField
                name='vigenciaIndeterminada'
                label='Data final indeterminada'
                testid='checkbox-dataIndeterminada'
            />
        </>
    )
}

export default PeriodoVigenciaForm
