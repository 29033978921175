import React from 'react'
import { FixedSelectField, FixedSelectFieldOption } from 'libs/components'

const options: FixedSelectFieldOption[] = [
    { id: 'M2', nome: 'm²' },
    { id: 'CM2', nome: 'cm²' },
]

export const LengthUnitSquareField = props => {
    return <FixedSelectField {...props} options={options} />
}
