import * as React from 'react'
import { connect } from 'react-redux'
import { State } from 'redux/modules/index'
import { Card, LoadingContainer, IconButton, NoWrap, Tag, Paginator } from 'libs/components'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { PacienteReportDto, DistribuicaoProcedimentoFiltro, PacienteReportFiltro } from 'backend'
import { Table, TableColumnHeader } from 'libs/components'
import * as enums from 'redux/enums'
import * as DateUtil from 'libs/util/DateUtil'
import isEqual from 'lodash/isEqual'
import { Page, PageRequester } from 'redux/requester'
import Report from 'api/Report'

interface StateProps {
    pacientes: Page<PacienteReportDto>
    isFetching: boolean
}

interface DispatchProps {
    load(filtro: PacienteReportFiltro): void
    handlePageChange(page: number): void
}

interface Props {
    filtro: DistribuicaoProcedimentoFiltro
}

type AllProps = Props & StateProps & DispatchProps & RouteComponentProps<void>

export class ReportPacienteListView extends React.Component<AllProps> {
    componentDidMount() {
        this.props.load(this.props.filtro)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (!isEqual(this.props.filtro, nextProps.filtro)) {
            this.props.load(nextProps.filtro)
        }
    }

    render() {
        const { pacientes, match, isFetching } = this.props

        return (
            <Card title={this.renderCardTitle()} noPadding={!isFetching}>
                <LoadingContainer isFetching={isFetching}>
                    {pacientes && (
                        <Table name='paciente' values={pacientes.content} className='is-small is-spaced'>
                            <TableColumnHeader
                                title='Nome'
                                render={(row: PacienteReportDto) => {
                                    return <span>{row.nome}</span>
                                }}
                            />
                            <TableColumnHeader
                                title='Procedimentos'
                                render={(row: PacienteReportDto) => {
                                    return this.renderProcedimentos(row)
                                }}
                            />
                            <TableColumnHeader
                                title='Sexo'
                                render={(row: PacienteReportDto) => {
                                    return <NoWrap>{enums.sexoName[row.sexo]}</NoWrap>
                                }}
                            />
                            <TableColumnHeader
                                title='Idade'
                                render={(row: PacienteReportDto) => {
                                    return <NoWrap>{row.idade} anos</NoWrap>
                                }}
                            />
                            <TableColumnHeader
                                className='nowrap'
                                title='Último proced.'
                                render={(row: PacienteReportDto) => {
                                    return <Tag>{DateUtil.asClientFormat(row.ultimoProcedimento)}</Tag>
                                }}
                            />
                            <TableColumnHeader
                                title=''
                                className='has-text-right row-link'
                                style={{ width: '20px' }}
                                render={(row: PacienteReportDto) => {
                                    return (
                                        <Link to={`${match.url}/${row.pacienteId}`}>
                                            <IconButton icon='acessar' />
                                        </Link>
                                    )
                                }}
                            />
                        </Table>
                    )}

                    {pacientes && (
                        <Paginator
                            first={pacientes.first}
                            last={pacientes.last}
                            pageNumber={pacientes.number}
                            totalPages={pacientes.totalPages}
                            onChange={this.props.handlePageChange}
                        />
                    )}
                </LoadingContainer>
            </Card>
        )
    }

    renderCardTitle() {
        if (this.props.pacientes) {
            let pacientes = this.props.pacientes && this.props.pacientes.totalElements === 1 ? 'paciente' : 'pacientes'
            return `${this.props.pacientes.totalElements} ${pacientes}`
        }
        return '0 pacientes'
    }

    renderProcedimentos(row: PacienteReportDto) {
        const r = []
        for (let i = 0; i < row.countPrimaria; i++) {
            r.push(
                <Tag key={`P${i}`} className='is-report-color-1 is-small' title='Primária'>
                    P
                </Tag>
            )
        }
        for (let i = 0; i < row.countRevisao; i++) {
            r.push(
                <Tag key={`R${i}`} className='is-report-color-2 is-small' title='Revisão'>
                    R
                </Tag>
            )
        }
        for (let i = 0; i < row.countAngio; i++) {
            r.push(
                <Tag key={`C${i}`} className='is-report-color-3 is-small' title='Cardio'>
                    C
                </Tag>
            )
        }
        return <div className='tags'>{r}</div>
    }
}

const requester = new PageRequester<PacienteReportDto, PacienteReportFiltro>('reportPaciente', (filtro, config) => {
    return Report.getPacientePage(filtro, config)
})

const mapStateToProps = (state: State, ownProps: Props) => ({
    pacientes: requester.getResult(state),
    isFetching: requester.getIsFetching(state),
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    load: (filtro: PacienteReportFiltro) => {
        dispatch(requester.setParams(filtro))
        dispatch(requester.request())
    },
    handlePageChange: (page: number) => {
        dispatch(requester.setPageNumber(page))
        dispatch(requester.request())
    },
})

export default withRouter(
    connect<StateProps, DispatchProps, Props>(mapStateToProps, mapDispatchToProps)(ReportPacienteListView)
)
