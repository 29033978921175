import Environment from 'libs/util/Environment'

if (Environment.isTest()) {
    // É necessário adicionar um polifyll para o contexto de teste a fim de disponibilizar o locale 'pt-BR',
    // já que Node não possui locales do Intl, a não ser o 'en'
    global.Intl = require('intl')
}

export function format(valor: number, minimumFractionDigits: number = 0, maximumFractionDigits: number = 2) {
    if (minimumFractionDigits > maximumFractionDigits) {
        maximumFractionDigits = minimumFractionDigits
    }

    return new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(valor)
}

export function formatCurrency(valor: number, casasDecimais: number = 2) {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: casasDecimais,
    }).format(valor)
}

export function abbrev(value: number, minimumFractionDigits: number = 0, maximumFractionDigits: number = 1) {
    if (!value) {
        return value
    }

    if (value >= 1000000000000) {
        return format(value / 1000000000000, minimumFractionDigits, maximumFractionDigits) + 't'
    }

    if (value >= 1000000000) {
        return format(value / 1000000000, minimumFractionDigits, maximumFractionDigits) + 'b'
    }

    if (value >= 1000000) {
        return format(value / 1000000, minimumFractionDigits, maximumFractionDigits) + 'm'
    }

    if (value >= 1000) {
        return format(value / 1000, minimumFractionDigits, maximumFractionDigits) + 'k'
    }

    return value.toString()
}
