import React from 'react'
import field from 'libs/redux-form/field'
import * as FichaComponents from 'view/home/novoProcedimento/ficha/componentes'

class LesaoSvgPanelCmp extends React.Component {
    render() {
        return (
            <FichaComponents.LesaoSvgItem
                {...this.props.input}
                dominio={this.props.dominio}
                hasTrombose={this.props.hasTrombose}
                hasReestenose={this.props.hasReestenose}
                name={this.props.name}
                label={this.props.label}
                disabled={this.props.disabled}
                options={this.props.options}
            />
        )
    }
}
export const LesaoSvgPanel = field({ hasWrapper: false })(LesaoSvgPanelCmp)
