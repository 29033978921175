/* tslint:disable */
import React from 'react'

const SvgConfirmacaoExpirado = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 294 294' {...props}>
        <g>
            <g opacity={0.303} transform='translate(31 12)'>
                <circle cx={97.237} cy={273.84} r={3.018} />
                <path d='M34.819 85.373l-1.959-1.78 1.78-1.96a.89.89 0 10-1.272-1.165l-1.78 1.95-1.95-1.781a.89.89 0 10-1.175 1.264l1.958 1.78-1.78 1.959a.89.89 0 001.273 1.166l1.78-1.95 1.95 1.78a.89.89 0 101.175-1.263zm203.309-22.225l-1.959-1.78 1.78-1.959a.89.89 0 00-1.264-1.192l-1.78 1.95-1.95-1.781a.89.89 0 10-1.175 1.264l1.959 1.78-1.78 1.959a.89.89 0 001.272 1.166l1.78-1.95 1.95 1.78a.89.89 0 101.175-1.263l-.008.026zM14.181 13.362a5.181 5.181 0 110-10.362 5.181 5.181 0 010 10.362zm0-8.626a3.454 3.454 0 100 6.908 3.454 3.454 0 000-6.908zm206.7 244.027a3.881 3.881 0 113.882-3.882 3.89 3.89 0 01-3.882 3.882zm0-6.036a2.154 2.154 0 102.155 2.154 2.163 2.163 0 00-2.155-2.163v.009zm-217 2.036a3.881 3.881 0 113.882-3.882 3.89 3.89 0 01-3.882 3.882zm0-6.036a2.154 2.154 0 102.155 2.154 2.163 2.163 0 00-2.155-2.154z' />
                <circle cx={254.831} cy={145.169} r={1.727} />
                <circle cx={174.018} cy={3.018} r={3.018} />
            </g>
            <g transform='translate(57 60)'>
                <ellipse cx={85} cy={87} rx={85} ry={87} />
                <ellipse cx={85} cy={87} rx={70.833} ry={72.5} />
                <path d='M86.966 89.9h-1.098c-2.044 0-3.701-1.696-3.701-3.788V40.038c0-2.092 1.656-3.788 3.7-3.788h1.099c2.043 0 3.7 1.696 3.7 3.788v46.074c0 2.092-1.657 3.788-3.7 3.788z' />
                <path d='M84.643 86.608l.74.758a3.673 3.673 0 010 5.108l-24.785 25.368a3.473 3.473 0 01-4.99 0l-.741-.758a3.673 3.673 0 010-5.108l24.785-25.368a3.473 3.473 0 014.99 0z' />
                <path d='M135.954 72.404c.207.955-.305 1.919-1.142 2.154l-49.294 13.84c-.837.235-1.683-.348-1.889-1.302-.206-.955.305-1.919 1.142-2.154l49.295-13.84c.836-.235 1.682.348 1.888 1.302z' />
                <ellipse cx={85.708} cy={87.725} rx={6.375} ry={6.525} />
                <path d='M86.688 30.45h-.543c-2.197 0-3.978-1.6-3.978-3.576v-4.448c0-1.975 1.78-3.576 3.978-3.576h.543c2.197 0 3.979 1.6 3.979 3.576v4.448c0 1.975-1.782 3.576-3.98 3.576zm0 124.7h-.543c-2.197 0-3.978-1.6-3.978-3.576v-4.448c0-1.975 1.78-3.576 3.978-3.576h.543c2.197 0 3.979 1.6 3.979 3.576v4.448c0 1.975-1.782 3.576-3.98 3.576z' />
                <path d='M140.25 88.728v-.556c0-2.249 1.564-4.072 3.494-4.072h4.346c1.93 0 3.493 1.823 3.493 4.072v.556c0 2.249-1.564 4.072-3.493 4.072h-4.346c-1.93 0-3.494-1.824-3.494-4.072z' />
                <path d='M18.417 88.728v-.556c0-2.249 1.564-4.072 3.493-4.072h4.346c1.93 0 3.494 1.823 3.494 4.072v.556c0 2.249-1.564 4.072-3.494 4.072H21.91c-1.929 0-3.493-1.824-3.493-4.072z' />
            </g>
            <g>
                <path
                    strokeWidth={2}
                    d='M184.895 225.222v-14.899a18.61 18.61 0 015.024-12.695l13.72-14.706a4.27 4.27 0 001.158-2.897l-.006-.24a4.286 4.286 0 00-1.153-2.707l-13.72-14.706a18.607 18.607 0 01-5.023-12.695v-14.9h-.062c-1.494 0-2.714-1.19-2.827-2.738l-.006-.187v-1.926c0-1.52 1.13-2.797 2.65-2.92l.183-.006h51.334c1.494 0 2.714 1.192 2.827 2.739l.006.187v1.926a2.93 2.93 0 01-1.447 2.545v15.28c0 4.701-1.794 9.231-5.024 12.695l-13.72 14.706a4.271 4.271 0 00-1.158 2.897l.006.24a4.286 4.286 0 001.152 2.707l13.72 14.706a18.607 18.607 0 015.024 12.695v15.274c.805.47 1.366 1.339 1.44 2.364l.007.187v1.926c0 1.52-1.13 2.797-2.65 2.92l-.183.006h-51.334c-1.494 0-2.714-1.192-2.827-2.739l-.006-.187v-1.926c0-1.52 1.13-2.797 2.65-2.92l.183-.006h.062z'
                />
                <path d='M236.053 128h-51.106a1.822 1.822 0 00-1.826 1.818v1.818c0 1.004.818 1.818 1.826 1.818h51.106a1.822 1.822 0 001.826-1.818v-1.818a1.822 1.822 0 00-1.826-1.818zm-51.106 104h51.106a1.822 1.822 0 001.826-1.818v-1.818a1.822 1.822 0 00-1.826-1.818h-51.106a1.822 1.822 0 00-1.826 1.818v1.818c0 1.004.818 1.818 1.826 1.818z' />
                <path d='M218.078 176.396l13.72-14.706a17.608 17.608 0 004.755-12.013v-15.9h-50.658v15.9a17.61 17.61 0 004.755 12.013l13.72 14.706a5.282 5.282 0 011.426 3.604c0 1.334-.509 2.62-1.426 3.604l-13.72 14.706a17.608 17.608 0 00-4.755 12.013v15.9h50.658v-15.9c0-4.448-1.697-8.734-4.756-12.013l-13.72-14.706a5.282 5.282 0 01-1.426-3.604c0-1.334.51-2.62 1.427-3.604z' />
                <path d='M207.124 199.319l4.369-14.937c.292-.999.454-2.305.454-3.66s-.162-2.661-.454-3.66l-4.369-14.936c-.974-3.33-1.514-7.684-1.514-12.2v-16.148h-19.715v16.147c0 4.518 1.68 8.87 4.71 12.2l13.592 14.937a5.439 5.439 0 011.413 3.66 5.44 5.44 0 01-1.413 3.66l-13.591 14.937a18.13 18.13 0 00-4.711 12.2v16.148h19.715v-16.148c0-4.517.54-8.87 1.514-12.2z' />
                <path d='M202.822 131.636v-1.818c0-1.004.802-1.818 1.79-1.818H184.91c-.989 0-1.79.814-1.79 1.818v1.818c0 1.004.801 1.818 1.79 1.818h19.703c-.99 0-1.791-.814-1.791-1.818zm0 98.546v-1.818c0-1.004.802-1.818 1.79-1.818H184.91c-.989 0-1.79.814-1.79 1.818v1.818c0 1.004.801 1.818 1.79 1.818h19.703c-.99 0-1.791-.814-1.791-1.818z' />
                <path d='M227.374 203.712l-3.382-3.681a1.843 1.843 0 00-1.357-.596h-9.372a1.84 1.84 0 01-1.842-1.839v-18.571c0-1.973.628-3.94 1.959-5.4l.025-.028 10.04-10.93h-23.796c-.801 0-1.22.95-.68 1.54l8.626 9.39.025.028c1.33 1.46 1.959 3.427 1.959 5.4v18.57a1.84 1.84 0 01-1.842 1.84h-9.372a1.84 1.84 0 00-1.357.595l-3.382 3.681a12.855 12.855 0 00-3.39 8.696v10.007c0 .508.413.92.922.92h38.684a.92.92 0 00.921-.92v-10.007c0-3.219-1.21-6.322-3.39-8.695z' />
                <path d='M199.247 164.348l8.712 9.336.026.028c1.344 1.451 1.978 3.407 1.978 5.368v10.573l1.456-4.853c.197-.66.33-1.459.405-2.311V179.7c-.074-.852-.208-1.652-.405-2.312l-4.371-14.572h-7.115c-.81 0-1.233.945-.686 1.531zm6.064 47.922c0-4.573.561-8.98 1.572-12.35l.165-.548h-8.412c-.521 0-1.019.215-1.371.593l-3.416 3.66a12.67 12.67 0 00-3.423 8.645v9.95c0 .504.416.914.93.914h13.955V212.27z' />
            </g>
        </g>
    </svg>
)

export default SvgConfirmacaoExpirado
