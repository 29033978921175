import * as React from 'react'
import { Page } from 'redux/middleware/PaginationAction'
import { RequestState } from 'redux/requester'
import {
    Card,
    Column,
    LinkIconButton,
    LoadingContainer,
    NumberLabel,
    Paginator,
    Table,
    TableColumnHeader,
    Text,
} from 'libs/components'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface Props extends RouteComponentProps<any> {
    showDetalheProduto: Boolean
    produtos: RequestState<Page<any>>
    isFetching: any
    handlePageChange(page: number): void
    report(): JSX.Element
}

class ResultadoProdutos extends React.Component<Props> {
    render() {
        const { match, location } = this.props
        if (!this.props.produtos || !this.props.produtos.result || this.props.produtos.result.totalElements < 1) {
            return (
                <Card title={0 + ' Sem registro anvisa'} noPadding={!this.props.isFetching}>
                    <LoadingContainer isFetching={this.props.isFetching}>
                        <Column textCentered borderBottom>
                            <p style={{ lineHeight: 2 }} className='is-text-muted'>
                                Nenhum registro encontrado.
                            </p>
                        </Column>
                    </LoadingContainer>
                </Card>
            )
        } else if (this.props.produtos) {
            return (
                <Card
                    title={this.props.produtos.result.totalElements + ' Com registro anvisa'}
                    noPadding={!this.props.isFetching}
                    headerAction={this.props.report()}
                >
                    <LoadingContainer isFetching={this.props.isFetching}>
                        {this.props.produtos.result.totalElements < 1 && (
                            <Column textCentered borderBottom>
                                <p style={{ lineHeight: 2 }} className='is-text-muted'>
                                    Nenhum registro encontrado.
                                </p>
                            </Column>
                        )}
                        {this.props.produtos.result.totalElements > 0 && (
                            <Table values={this.props.produtos.result.content} className='is-spaced is-small-bis'>
                                <TableColumnHeader
                                    title='Registro'
                                    style={{ width: '10%' }}
                                    render={(item: any) => {
                                        return <Text>{item.registroAnvisa}</Text>
                                    }}
                                />
                                <TableColumnHeader
                                    title='Nome'
                                    style={{ width: '40%' }}
                                    render={(item: any) => {
                                        return <Text>{item.nomeProduto}</Text>
                                    }}
                                />
                                <TableColumnHeader
                                    title='Detentor do registro'
                                    style={{ width: '40%' }}
                                    render={(item: any) => {
                                        return <Text>{item.nomeFabricante}</Text>
                                    }}
                                />
                                <TableColumnHeader
                                    title='Proced.'
                                    style={{ width: '10%' }}
                                    className='has-text-centered'
                                    render={(item: any) => {
                                        return <NumberLabel value={item.quantidadeProcedimentos}></NumberLabel>
                                    }}
                                />
                                {this.props.showDetalheProduto && (
                                    <TableColumnHeader
                                        title=''
                                        className='has-text-centered'
                                        render={(item: any) => {
                                            return (
                                                <LinkIconButton
                                                    data-name={'row-' + item}
                                                    to={{
                                                        pathname: `${match.url}/registro/${item.registroAnvisa}`,
                                                        search: location.search,
                                                    }}
                                                    icon='arrowRight'
                                                />
                                            )
                                        }}
                                    />
                                )}
                            </Table>
                        )}
                        <Paginator
                            first={this.props.produtos.result.first}
                            last={this.props.produtos.result.last}
                            pageNumber={this.props.produtos.result.number}
                            totalPages={this.props.produtos.result.totalPages}
                            onChange={this.props.handlePageChange}
                        />
                    </LoadingContainer>
                </Card>
            )
        }
    }
}

export const ResultadoProdutosComRegistro = withRouter(ResultadoProdutos)
