
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"3 min read","minutes":2.705,"time":162300,"words":541}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Aguardando dados clínicos” e no procedimento desejado clique no botão `}<strong parentName="li">{`“Preencher”`}</strong>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` apenas profissionais com perfil de “Médico Ortopedista” e “Residente em ortopedia” que partiparam da equipe médica do procedimento, podem registrar dados clínicos de procedimentos de Artroplastia.`}</p>
    </blockquote>
    <p><strong parentName="p">{`DIAGNÓSTICO`}</strong></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Informe o(s) motivo(s) da artroplastia`}</li>
    </ol>
    <p><strong parentName="p">{`PROCEDIMENTO CIRÚRGICO PRÉVIO`}</strong></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Informe se houve procedimento cirúrgico prévio`}</li>
      <li parentName="ol">{`Caso tenha tido procedimento cirúrgico prévio informe qual(is)`}</li>
      <li parentName="ol">{`Informe se o paciente já possuía um implante prévio`}</li>
      <li parentName="ol">{`Caso tenha uma implante prévio informe se foi removido durante o procedimento atual`}</li>
    </ol>
    <p><strong parentName="p">{`PROCEDIMENTO CIRÚRGICO ATUAL`}</strong></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Preencha a data da cirurgia e a duração em horas`}</li>
      <li parentName="ol">{`Informe qual foi a abordagem cirúrgica`}</li>
      <li parentName="ol">{`Informe se houve utilização de dreno`}</li>
      <li parentName="ol">{`Informe se houve transfusão sanguínea`}</li>
      <li parentName="ol">{`Caso tenha sido feita transfusão sanguínea informe se foi autóloga ou homóloga`}</li>
      <li parentName="ol">{`Informe se foi utilizado antibiótico `}</li>
      <li parentName="ol">{`Caso tenha sido utilizado antibiótico informe qual foi o tipo de antibiótico e qual o período`}</li>
    </ol>
    <p><strong parentName="p">{`TIPO DE ARTROPLASTIA`}</strong></p>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol">{`Informe o tipo de artroplastia`}</li>
      <li parentName="ol">{`Caso tenha tipo de artroplastia “Convencional” informe o par tribológico`}</li>
    </ol>
    <p><strong parentName="p">{` IMPLANTES `}</strong></p>
    <ol {...{
      "start": 17
    }}>
      <li parentName="ol">
        <p parentName="li">{`Informe os implantes aplicados`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Caso o implante aplicado for “Femoral” :`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Informe o modelo de haste`}</li>
          <li parentName="ul">{`Informe se possui centralizador distal`}</li>
          <li parentName="ul">{`Informe se possui centralizador proximal`}</li>
          <li parentName="ul">{`Informe se foi cimentado`}</li>
          <li parentName="ul">{`Caso tenha sido cimentado informe se foi utilizado restritor de cimento`}</li>
          <li parentName="ul">{`Caso tenha sido utilizado restritor de cimento informe se foi ósseo ou sintético`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Caso o implante aplicado for "Acetabular":`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Informe se foi Polietileno`}</li>
          <li parentName="ul">{`Caso foi polietileno informe se foi com ou sem flange`}</li>
          <li parentName="ul">{`Informe se foi Metálico`}</li>
          <li parentName="ul">{`Caso foi metálico informe quais foram utilizados`}</li>
          <li parentName="ul">{`Informe se foi cimentado`}</li>
          <li parentName="ul">{`Caso não tenha sido cimentado informe a quantidade de parafusos utilizado`}</li>
        </ul>
      </li>
    </ol>
    <p><strong parentName="p">{`CIMENTO ÓSSEO`}</strong></p>
    <ol {...{
      "start": 20
    }}>
      <li parentName="ol">{`Caso informado que foi utilizado cimento em algum dos implantes:`}<ul parentName="li">
          <li parentName="ul">{`Informe qual o tipo de cimento ósseo`}</li>
          <li parentName="ul">{`Informe a técnica de cimentação utilizada`}</li>
          <li parentName="ul">{`Informe se houve lavagem pulsátil`}</li>
        </ul></li>
    </ol>
    <p><strong parentName="p">{`ENXERTIA`}</strong></p>
    <ol {...{
      "start": 21
    }}>
      <li parentName="ol">{`Caso tenha sido realizado implante femoral informe se foi feito enxertia`}</li>
      <li parentName="ol">{`Caso tenha sido feito enxertia no implante femoral informe qual o tipo`}</li>
      <li parentName="ol">{`Caso tenha sido realizado implante acetabular informe se foi feito enxertia`}</li>
      <li parentName="ol">{`Caso tenha sido feito enxertia no implante acetabular informe qual o tipo`}</li>
    </ol>
    <p><strong parentName="p">{`COMPONENTES UTILIZADOS`}</strong></p>
    <ol {...{
      "start": 25
    }}>
      <li parentName="ol">{`Informe quais componentes foram implantados no paciente`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Caso o componente não seja apresentado, verifique se o tipo de artroplastia foi selecionado corretamente`}</p>
    </blockquote>
    <ol {...{
      "start": 26
    }}>
      <li parentName="ol">{`Informe também a quantidade de cada componente utilizado`}</li>
    </ol>
    <p>{`Para remover um componente da lista clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeRecusar.png#center",
        "alt": "Remover"
      }}></img>{` ao lado do componente desejado`}</p>
    <ol {...{
      "start": 27
    }}>
      <li parentName="ol">{`Informe quais componentes complementares foram utilizados `}</li>
      <li parentName="ol">{`Informe também a quantidade de cada componente complementar utilizado`}</li>
    </ol>
    <p>{`Para remover um componente da lista clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeRecusar.png#center",
        "alt": "Remover"
      }}></img>{` ao lado do componente desejado`}</p>
    <ol {...{
      "start": 29
    }}>
      <li parentName="ol">{`Clique no botão “Salvar registro” para registrar o procedimento`}</li>
    </ol>
    <p>{`Após o registro dos dados clínicos este procedimento fica disponível para preenchimento dos dados de produto, ou seja, os dados dos componentes implantados no paciente.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona cada etapa do preenchimento do registro, `}<a parentName="p" {...{
          "href": "../fluxos/fluxoPreenchimentoRegistros"
        }}><strong parentName="a">{`clique aqui`}</strong></a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona o preenchimento de dados clínicos de artroplastia, consulte o vídeo explicativo abaixo:`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

