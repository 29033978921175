import React from 'react'
import { DatePickerField, Button, Columns, Column } from 'libs/components'
import form from 'libs/redux-form/form'
import Axios from 'axios'

interface ProcessarAumentoRegistrosFormProps {
    handleSubmit?()
    disabled?: boolean
}

class ProcessarAumentoRegistrosFormCmp extends React.PureComponent<ProcessarAumentoRegistrosFormProps> {
    render() {
        return (
            <div>
                <Columns>
                    <Column>
                        <p>- Processar alertas de aumento de registro:</p>
                        <p>
                            Inserir data base posterior ao período desejado. Por exemplo, para aumento mensal, data base
                            01/03/2020 calcula aumento de Fevereiro em relação a Janeiro.
                        </p>
                    </Column>
                </Columns>
                <Columns horizontalFlow>
                    <Column size='3'>
                        <DatePickerField name='dataBase' placeholder='dd/mm/aaaa' label='Data base' />
                    </Column>
                    <Column pulledBottom>
                        <Button onClick={this.props.handleSubmit}>Processar</Button>
                    </Column>
                </Columns>
            </div>
        )
    }
}

const ProcessarAumentoRegistrosWrappedForm = form({
    form: 'processarAumentoRegistros',
})(ProcessarAumentoRegistrosFormCmp) as any

const ProcessarAumentoRegistrosForm = () => (
    <ProcessarAumentoRegistrosWrappedForm
        onSubmit={form => Axios.post('/api/alerta/processarAumentoRegistro', form.toJS())}
    />
)

export default ProcessarAumentoRegistrosForm
