import * as React from 'react'
import * as C from 'libs/components'

interface VascularProps {
    disabled?: boolean
    disabledHematoma: boolean
}

export class VascularCard extends React.Component<VascularProps> {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Vascular'
                name='vascular'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='vascular' disabled={this.props.disabled} />}
            >
                <C.Conteudo>
                    <C.Columns>
                        <C.Column>
                            <C.ControlField label={'Quais'} name='vascularQuaisComplicacoes' />
                        </C.Column>
                    </C.Columns>
                    <C.Columns>
                        <C.Column size='5' verticalFlow>
                            <C.ChecklistItemField
                                name='vascularQuaisComplicacoes'
                                optionValue='DISSECCAO_TRAJETO_VASCULAR'
                                label='Dissecção trajeto vascular'
                            />
                            <C.ChecklistItemField
                                name='vascularQuaisComplicacoes'
                                optionValue='FISTULA_AV'
                                label='Fístula AV'
                            />
                            <C.ChecklistItemField
                                name='vascularQuaisComplicacoes'
                                optionValue='HEMATOMA'
                                label='Hematoma'
                            />
                        </C.Column>
                        <C.Column verticalFlow>
                            <C.ChecklistItemField
                                name='vascularQuaisComplicacoes'
                                optionValue='ISQUEMIA_MEMBRO'
                                label='Isquemia membro'
                            />
                            <C.ChecklistItemField
                                name='vascularQuaisComplicacoes'
                                optionValue='PSEUDOANEURISMA'
                                label='Pseudoaneurisma'
                            />
                            <C.ChecklistItemField
                                name='vascularQuaisComplicacoes'
                                optionValue='TROMBOSE_ARTERIAL'
                                label='Trombose arterial'
                            />
                        </C.Column>
                    </C.Columns>
                    <C.Linha size='medium' />
                    <C.ControlField
                        horizontalFlow
                        label='Tamanho do hematoma'
                        name='vascularHematoma'
                        disabled={this.props.disabledHematoma}
                    >
                        <C.RadioField
                            name='vascularHematoma'
                            label='< 3cm'
                            value='MENOR_TRES'
                            disabled={this.props.disabledHematoma}
                        />
                        <C.RadioField
                            name='vascularHematoma'
                            label='3 a 5cm'
                            value='TRES_A_CINCO'
                            disabled={this.props.disabledHematoma}
                        />
                        <C.RadioField
                            name='vascularHematoma'
                            label='5 a 10cm'
                            value='CINCO_A_DEZ'
                            disabled={this.props.disabledHematoma}
                        />
                        <C.RadioField
                            name='vascularHematoma'
                            label='> 10cm'
                            value='MAIOR_DEZ'
                            disabled={this.props.disabledHematoma}
                        />
                        <C.ClearRadioFieldButton name='vascularHematoma' />
                    </C.ControlField>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
