import React from 'react'
import { CadastroEntidadeConsumidoraForm } from 'backend'
import { useParams } from 'react-router-dom'
import { LoadingStateContainer } from 'libs/components'
import { useAxiosGet } from 'hooks/useApiRequest'
import AdicionarEntidadeView from './AdicionarEntidadeView'
import Entidades from 'api/Entidades'

interface EdicaoEntidadeViewRouteParams {
    id: string
}

const EdicaoEntidadeView: React.FC = () => {
    const routeParams = useParams<EdicaoEntidadeViewRouteParams>()
    const { data, requestState } = useAxiosGet<CadastroEntidadeConsumidoraForm>(
        Entidades.getEntidade(Number(routeParams.id))
    )

    return (
        <LoadingStateContainer readyState={requestState}>
            <AdicionarEntidadeView editMode entidadeInitialValue={data} />
        </LoadingStateContainer>
    )
}

export default EdicaoEntidadeView
