import React from 'react'
import axios from 'axios'
import Select from 'react-select'
import { Props } from 'react-select/src/Select'
import { OptionsType } from 'react-select/src/types'

export interface SimpleSelectProps extends Partial<Props<any>> {
    url: string
    value?: any
    customizeParams?: any
}

interface SimpleSelectState {
    listOptions: OptionsType<any>
}

export class SimpleSelect extends React.Component<SimpleSelectProps, SimpleSelectState> {
    static defaultProps: Partial<SimpleSelectProps> = {
        backspaceRemovesValue: false,
        getOptionLabel: opt => {
            return opt.nome
        },
        getOptionValue: opt => {
            return opt.id
        },
        isMulti: false,
        noOptionsMessage: () => 'Nenhum item encontrado.',
        placeholder: '',
    }

    constructor(props) {
        super(props)
        this.state = {
            listOptions: [],
        }
        this.loadOptions()
        this.blur = this.blur.bind(this)
    }

    UNSAFE_componentWillReceiveProps() {
        this.loadOptions()
    }

    render() {
        return (
            <Select
                {...this.props}
                className='Select'
                classNamePrefix='Select'
                onBlur={this.blur()}
                options={this.state.listOptions}
            />
        )
    }

    loadOptions() {
        const params = this.props.customizeParams && this.props.customizeParams()

        axios.get(this.props.url, { params: params }).then(data => {
            this.setState({
                listOptions: data.data,
            })
        })
    }

    private blur(): React.EventHandler<React.FocusEvent<{}>> {
        return () => {
            if (this.props.onBlur) {
                if (this.props.value && this.props.getOptionValue(this.props.value)) {
                    this.props.onBlur(this.props.value)
                } else {
                    this.props.onBlur(null)
                }
            }
        }
    }
}
