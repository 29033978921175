import { ProcedimentoAnteriorDto } from 'backend'
import corpo from 'images/home/novoProcedimento/corpo.png'
import { Button, ButtonBar, Conteudo, Form, Linha } from 'libs/components'
import form, { FormNewProps } from 'libs/redux-form/form'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InjectedFormProps } from 'redux-form'
import { formValueSelector } from 'redux-form/immutable'
import { clearEdicaoProcedimentoData, EdicaoPreFichaState } from 'redux/modules/edicaoProcedimento'
import { CardPaciente } from '../../ficha/componentes/CardPaciente'
import { createValue, OpcaoProcedimento } from './OpcaoProcedimento'

interface ProcedimentoFormBodyProps {
    paciente: any
    edicaoPreFicha: EdicaoPreFichaState
    procedimentosAnteriores: ProcedimentoAnteriorDto[]
    onCancel(): void
    loadProcedimentosAnteriores(): void
}
type Props = ProcedimentoFormBodyProps & InjectedFormProps<{}, ProcedimentoFormBodyProps>

const ProcedimentoFormBody = ({ paciente, onCancel, ...props }: Props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        props.loadProcedimentosAnteriores()
        return () => dispatch(clearEdicaoProcedimentoData())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const procedimento = procedimentosFormSelector(
        useSelector(state => state),
        'procedimento'
    )

    return (
        <div>
            <CardPaciente paciente={paciente} />
            <div className='novo-procedimento-container'>
                <div className='novo-procedimento-header'>
                    <div className='novo-procedimento-title'>PROCEDIMENTOS</div>
                    <div className='novo-procedimento-subtitle'>Escolha o procedimento que será registrado.</div>
                </div>
            </div>
            <Form>
                <div className='procedimento-corpo'>
                    <img src={corpo} className='body' useMap='#image-map' width='529' height='539' alt='body' />
                    <OpcaoProcedimento {...props} procedimento={procedimento} regiao={'quadril'} lado={'ESQUERDO'} />
                    <OpcaoProcedimento {...props} procedimento={procedimento} regiao={'quadril'} lado={'DIREITO'} />
                    <OpcaoProcedimento {...props} procedimento={procedimento} regiao={'joelho'} lado={'ESQUERDO'} />
                    <OpcaoProcedimento {...props} procedimento={procedimento} regiao={'joelho'} lado={'DIREITO'} />
                </div>
                <Linha />
            </Form>
            <div className='legenda'>
                <div className='circle-black' />
                <label className='informacoes'>SEM REGISTRO</label>
                <div className='circle-red' />
                <label className='informacoes'>ARTROPLASTIA REGISTRADA</label>
                <div className='circle-blue' />
                <label className='informacoes'>SELECIONADO</label>
            </div>
            <Linha size='medium' />
            <Conteudo>
                <ButtonBar>
                    <Button size='medium' onClick={onCancel}>
                        Cancelar
                    </Button>
                    <Button
                        pulledRight
                        type='primary'
                        size='medium'
                        disabled={!procedimento}
                        loading={props.submitting}
                        onClick={props.handleSubmit}
                    >
                        Confirmar procedimento
                    </Button>
                </ButtonBar>
            </Conteudo>
        </div>
    )
}

export interface ProcedimentosFormProps extends FormNewProps<{}, ProcedimentoFormBodyProps> {
    editMode
    isPerfilTecnico: boolean
}
export const procedimentosFormSelector = formValueSelector('definirProcedimento')
export const ProcedimentosForm = ({ isPerfilTecnico, editMode, ...rest }: ProcedimentosFormProps) => {
    const Form = useMemo(
        () =>
            form<{}, ProcedimentoFormBodyProps>({
                form: 'definirProcedimento',
                hasSuccessModal: isPerfilTecnico,
                successTitle: editMode ? 'Edição realizada com sucesso!' : 'Ficha registrada!',
                enableReinitialize: true,
                hasLeaveModal: false,
            })(ProcedimentoFormBody),
        [isPerfilTecnico, editMode]
    )

    const initialValues =
        editMode && rest.edicaoPreFicha?.tipoProcedimento && rest.edicaoPreFicha?.lado
            ? { procedimento: createValue(rest.edicaoPreFicha.tipoProcedimento, rest.edicaoPreFicha.lado) }
            : {}

    return <Form {...rest} initialValues={initialValues} />
}
