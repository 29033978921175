import { createValidator, required, hora, maxDate, nome, range } from 'libs/validation'
import * as DateUtil from 'libs/util/DateUtil'
import immutableProxy from 'libs/immutableProxy'
import { StentCoronariaEssencialForm, EnxertoForm, StentLesaoForm, SegmentosLesaoForm } from 'backend'
import { fromJS } from 'immutable'

const mensagemDataAnterior = 'Esta data não pode ser posterior à data da cirurgia'

const FichaStentCoronariaEssencialValidator = createValidator(
    {
        dadosProcedimento: dadosProcedimento(),
        lesoesTratadas: lesoesTratadas(),
        stentsUtilizados: stentsUtilizados(),
        apresentacaoClinica: apresentacaoClinica(),
        isquemia: isquemia(),
    },
    formValidator
)

function dadosProcedimento() {
    return createValidator({
        dataCirurgia: [required, maxDate],
        horaInicio: [required, hora],
        horaTermino: [required, hora],
    })
}

function stentsUtilizados() {
    return createValidator({
        stents: required,
    })
}

function lesoesTratadas() {
    return createValidator(
        {
            dominancia: required,
        },
        lesoesFormValidator
    )
}

function apresentacaoClinica() {
    return createValidator({
        quadroClinico: required,
        horaAdmissao: hora,
        dataAdmissao: maxDate,
        horaAberturaVaso: hora,
        dataAberturaVaso: maxDate,
        horaInicioSintomas: hora,
        dataInicioSintomas: maxDate,
        horaContatoHospital: hora,
        dataContatoHospital: maxDate,
        horaChegadaIcp: hora,
        dataChegadaIcp: maxDate,
        medicoAuxiliar: nome,
    })
}

function isquemia() {
    return createValidator({
        valorEscoreCalcio: [range(0, 9999)],
        percentilEscoreCalcio: [range(0, 100)],
    })
}

export function lesoesFormValidator(form) {
    const errors: any = {}
    const nativas = form.get('lesoesNativas')
    const enxerto = form.get('lesoesEnxerto')

    if ((!nativas || nativas.size === 0) && (!enxerto || enxerto.size === 0)) {
        errors.lesoes = required(undefined)
    }

    return errors
}

function formValidator(formMap, errors) {
    const form = immutableProxy<StentCoronariaEssencialForm>(formMap)

    const apresentacaoClinica = form.apresentacaoClinica

    const dadosProcedimento = form.dadosProcedimento

    if (dadosProcedimento.dataCirurgia.get()) {
        errors.apresentacaoClinica = errors.apresentacaoClinica || {}

        const dataCirurgia = dadosProcedimento.dataCirurgia.get()

        const dataInicioSintomas = apresentacaoClinica.dataInicioSintomas.get()
        const dataAdmissao = apresentacaoClinica.dataAdmissao.get()
        const dataAberturaVaso = apresentacaoClinica.dataAberturaVaso.get()
        const dataChegadaIcp = apresentacaoClinica.dataChegadaIcp.get()
        const dataContatoHospital = apresentacaoClinica.dataContatoHospital.get()

        // Data abertura
        if (dataAberturaVaso && dataCirurgia < dataAberturaVaso) {
            errors.apresentacaoClinica.dataAberturaVaso = mensagemDataAnterior
        }
        if (dataAdmissao && dataCirurgia < dataAdmissao) {
            errors.apresentacaoClinica.dataAdmissao = mensagemDataAnterior
        }
        if (dataChegadaIcp && dataCirurgia < dataChegadaIcp) {
            errors.apresentacaoClinica.dataChegadaIcp = mensagemDataAnterior
        }
        if (dataContatoHospital && dataCirurgia < dataContatoHospital) {
            errors.apresentacaoClinica.dataContatoHospital = mensagemDataAnterior
        }
        if (dataInicioSintomas && dataCirurgia < dataInicioSintomas) {
            errors.apresentacaoClinica.dataInicioSintomas = mensagemDataAnterior
        }

        // Inicio sintomas x Admissão hospital
        if (apresentacaoClinica.horaInicioSintomas.get() && apresentacaoClinica.horaAdmissao.get()) {
            const period = DateUtil.getPeriodBetweenDates(
                dataInicioSintomas,
                apresentacaoClinica.horaInicioSintomas.get(),
                dataAdmissao,
                apresentacaoClinica.horaAdmissao.get()
            )
            if (period === '') {
                errors.apresentacaoClinica.horaInicioSintomas =
                    'Início dos sintomas não pode ser posterior à hora de Admissão no hospital'
            }
        } else if (dataInicioSintomas && dataAdmissao && dataAdmissao < dataInicioSintomas) {
            errors.apresentacaoClinica.dataAdmissao = 'Esta data não pode ser anterior à data de Início dos sintomas'
        }

        // Inicio sintomas x Abertura vaso
        if (apresentacaoClinica.horaAberturaVaso.get() && apresentacaoClinica.horaInicioSintomas.get()) {
            const period = DateUtil.getPeriodBetweenDates(
                dataInicioSintomas,
                apresentacaoClinica.horaInicioSintomas.get(),
                dataAberturaVaso,
                apresentacaoClinica.horaAberturaVaso.get()
            )
            if (period === '') {
                errors.apresentacaoClinica.horaAberturaVaso =
                    'Esta hora não pode ser anterior à hora de Início dos sintomas'
            }
        } else if (dataAberturaVaso && dataInicioSintomas && dataAberturaVaso < dataInicioSintomas) {
            errors.apresentacaoClinica.dataAberturaVaso =
                'Esta data não pode ser anterior à data de Início dos sintomas'
        }

        // Contato hospital x chegada
        if (apresentacaoClinica.horaContatoHospital.get() && apresentacaoClinica.horaChegadaIcp.get()) {
            const period = DateUtil.getPeriodBetweenDates(
                dataContatoHospital,
                apresentacaoClinica.horaContatoHospital.get(),
                dataChegadaIcp,
                apresentacaoClinica.horaChegadaIcp.get()
            )
            if (period === '') {
                errors.apresentacaoClinica.horaChegadaIcp =
                    'Esta hora não pode ser anterior à hora de contato com o hospital'
            }
        } else if (dataChegadaIcp && dataContatoHospital && dataChegadaIcp < dataContatoHospital) {
            errors.apresentacaoClinica.dataChegadaIcp =
                'Esta data não pode ser anterior à data de contato com o hospital'
        }

        // Chegada x Admissão
        if (apresentacaoClinica.horaChegadaIcp.get() && apresentacaoClinica.horaAdmissao.get()) {
            const period = DateUtil.getPeriodBetweenDates(
                dataChegadaIcp,
                apresentacaoClinica.horaChegadaIcp.get(),
                dataAdmissao,
                apresentacaoClinica.horaAdmissao.get()
            )
            if (period === '') {
                errors.apresentacaoClinica.horaAdmissao =
                    'Esta hora não pode ser anterior à hora de chegada no destino ICP'
            }
        } else if (dataChegadaIcp && dataAdmissao && dataAdmissao < dataChegadaIcp) {
            errors.apresentacaoClinica.dataAdmissao = 'Esta data não pode ser anterior à data de chegada no destino ICP'
        }

        // Chegada x Abertura vaso
        if (apresentacaoClinica.horaAberturaVaso.get() && apresentacaoClinica.horaChegadaIcp.get()) {
            const period = DateUtil.getPeriodBetweenDates(
                dataChegadaIcp,
                apresentacaoClinica.horaChegadaIcp.get(),
                dataAberturaVaso,
                apresentacaoClinica.horaAberturaVaso.get()
            )
            if (period === '') {
                errors.apresentacaoClinica.horaAberturaVaso =
                    'Esta hora não pode ser anterior à hora de chegada no destino ICP'
            }
        } else if (dataAberturaVaso && dataChegadaIcp && dataAberturaVaso < dataChegadaIcp) {
            errors.apresentacaoClinica.dataAberturaVaso =
                'Esta data não pode ser anterior à data de chegada no destino ICP'
        }

        // Admissão x Abertura vaso
        if (apresentacaoClinica.horaAberturaVaso.get() && apresentacaoClinica.horaAdmissao.get()) {
            const period = DateUtil.getPeriodBetweenDates(
                dataAdmissao,
                apresentacaoClinica.horaAdmissao.get(),
                dataAberturaVaso,
                apresentacaoClinica.horaAberturaVaso.get()
            )
            if (period === '') {
                errors.apresentacaoClinica.horaAberturaVaso =
                    'Esta hora não pode ser anterior à hora de Admissão no hospital'
            }
        } else if (dataAberturaVaso && dataAdmissao && dataAberturaVaso < dataAdmissao) {
            errors.apresentacaoClinica.dataAberturaVaso =
                'Esta data não pode ser anterior à data de Admissão no hospital'
        }
    }

    const quadro = apresentacaoClinica && apresentacaoClinica.quadroClinico.get()

    if (
        dadosProcedimento.horaInicio.get() &&
        dadosProcedimento.horaInicio.get() === dadosProcedimento.horaTermino.get()
    ) {
        errors.dadosProcedimento = errors.dadosProcedimento || {}
        errors.dadosProcedimento.horaInicio = 'Início e término não podem ser iguais'
        errors.dadosProcedimento.horaTermino = 'Início e término não podem ser iguais'
    }

    if (quadro && quadro.id === 'ANGINA_ESTAVEL') {
        errors.apresentacaoClinica = {}
        errors.apresentacaoClinica.classeFuncional = required(apresentacaoClinica.classeFuncional.get())
    }

    if (quadro && quadro.id === 'ASSINTOMATICO') {
        errors.isquemia = {}
        errors.isquemia.testeNaoInvasivo = required(form.isquemia.testeNaoInvasivo.get())
    }

    const stentsUtilizados = form.stentsUtilizados.stents.get()
    if (!errors.stentsUtilizados || !errors.stentsUtilizados.stents) {
        const lesoesNativasSemStent = exportFunctions.getLesoesNativasSemStent(
            form.lesoesTratadas.lesoesNativas.get(),
            stentsUtilizados
        )

        const lesoesEnxertoSemStent = exportFunctions.getLesoesEnxertoSemStent(
            form.lesoesTratadas.lesoesEnxerto.get(),
            stentsUtilizados
        )

        if (lesoesNativasSemStent > 0 || lesoesEnxertoSemStent > 0) {
            errors.stentsUtilizados = {
                stents: 'Uma ou mais lesões não estão relacionadas a um stent',
            }
        }
    }
    return errors
}

function getLesoesEnxertoSemStent(lesoesEnxerto: EnxertoForm[], stentsUtilizados: StentLesaoForm[]) {
    if (lesoesEnxerto && stentsUtilizados) {
        return lesoesEnxerto
            .map(enxerto => fromJS(enxerto))
            .map(enxerto => enxerto.get('id'))
            .reduce((total, current) => {
                const found = stentsUtilizados
                    .map(stent => fromJS(stent))
                    .some(
                        stent =>
                            stent.get('localStent') === 'ENXERTO' &&
                            fromJS(stent.get('enxertoStent')).get('id') === current
                    )
                if (!found) {
                    total += 1
                }
                return total
            }, 0)
    }
}

function getLesoesNativasSemStent(lesoesNativas: SegmentosLesaoForm[], stentsUtilizados: StentLesaoForm[]) {
    if (lesoesNativas && stentsUtilizados) {
        return lesoesNativas
            .map(nativa => fromJS(nativa))
            .map(nativa => nativa.get('id'))
            .reduce((total, current) => {
                const found = stentsUtilizados
                    .map(stent => fromJS(stent))
                    .some(stent => stent.get('localStent') === 'NATIVA' && stent.get('lesoesRegistradas').get(current))
                if (!found) {
                    total += 1
                }
                return total
            }, 0)
    }
}

export default FichaStentCoronariaEssencialValidator

export const exportFunctions = {
    formValidator,
    getLesoesEnxertoSemStent,
    getLesoesNativasSemStent,
}
