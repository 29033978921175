/* tslint:disable */
import React from 'react'

const SvgDistArtro = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
        <path d='M24.46 7.543a3.398 3.398 0 00-5.805-2.541A3.372 3.372 0 0018 8.892l-9.108 9.109a3.374 3.374 0 00-3.891.655 3.399 3.399 0 002.541 5.804 3.398 3.398 0 005.805 2.539A3.372 3.372 0 0014 23.107l9.108-9.106c1.29.631 2.84.395 3.891-.656a3.402 3.402 0 00.006-4.814 3.388 3.388 0 00-2.545-.989z' />
    </svg>
)

export default SvgDistArtro
