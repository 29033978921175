import { ValidationMessages } from '..'

export const messages: ValidationMessages = {
    cep: (value, ...args) => 'O CEP informado é inválido',
    cnpj: (value, ...args) => 'O CNPJ informado é inválido',
    cns: (value, ...args) => 'O CNS informado é inválido',
    cpf: (value, ...args) => 'O CPF informado é inválido',
    dataNasc: (value, ...args) => 'Idade máxima permitida é 130 anos',
    maxDate: (value, ...args) => 'A data não pode ser superior à data atual',
    duracao: (value, ...args) => 'Duração informada é inválida',
    email: (value, ...args) => 'E-mail informado é inválido',
    empty: (value, ...args) => 'Campo não deve ser preenchido',
    hora: (value, ...args) => 'Horário informado é inválido',
    length: (value, ...args) => `Campo deve possuir ${args[0]} caracteres`,
    minLength: (value, ...args) => `Campo deve possuir ao menos ${args[0]} caracteres`,
    maxLength: (value, ...args) => `Campo deve possuir no máximo ${args[0]} caracteres`,
    match: (value, ...args) => 'Um ou mais caracteres informados não são permitidos para esse campo',
    nome: (value, ...args) => 'Informe nome e sobrenome',
    range: (value, ...args) => `Campo deve ter valor entre ${args[0]} e ${args[1]}`,
    minRange: (value, ...args) => `Valor inserido deve ser maior ou igual a ${args[0]}`,
    maxRange: (value, ...args) => `Valor inserido deve ser menor ou igual a ${args[0]}`,
    required: (value, ...args) => 'Campo é de preenchimento obrigatório',
    senha: (value, ...args) => 'Senha deve possuir ao menos uma letra e um número',
    telefone: (value, ...args) => 'Telefone informado é inválido',
}
