import React from 'react'
import { Redirect, RouteProps } from 'react-router-dom'
import PerfilRoute, { PerfilRouteProps } from 'libs/router/PerfilRoute'
import { connect } from 'react-redux'
import { State } from 'redux/modules'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { RegistroProcedimentoComponenteDto } from 'backend'

type Props = Pick<PerfilRouteProps, 'perfis'> &
    RouteProps & {
        procedimento?: PromiseRecord<RegistroProcedimentoComponenteDto>
    }

const RastreabilidadePerfilRoute = (props: Props) => {
    const { component: Component, procedimento, ...rest } = props

    return (
        <PerfilRoute
            {...rest}
            render={props =>
                procedimento.data && procedimento.data.componentes ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/home',
                        }}
                    />
                )
            }
        />
    )
}

export default connect((state: State) => ({ procedimento: state.rastreabilidade.findProcedimento }))(
    RastreabilidadePerfilRoute
)
