import React from 'react'
import classnames from 'classnames'

export class Panel extends React.Component {
    render() {
        const classes = classnames('rnipanel', this.props.className)

        return <div className={classes}>{this.props.children}</div>
    }
}

export class PanelHeader extends React.Component {
    render() {
        return <div className='rnipanel-header'>{this.props.children}</div>
    }
}

export class PanelSubHeader extends React.Component {
    render() {
        return <span className='rnipanel-subpanel'>{this.props.children}</span>
    }
}

export class PanelContent extends React.Component {
    render() {
        return <div className='rnipanel-content'>{this.props.children}</div>
    }
}
