/* tslint:disable */
import React from 'react'

const SvgDelete = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M18 5.778h-3L14.143 5H9.857L9 5.778H6v1.555h12V5.778zM6.857 17.444c0 .86.768 1.556 1.714 1.556h6.858c.946 0 1.714-.696 1.714-1.556V8.111H6.857v9.333z' />
    </svg>
)

export default SvgDelete
