/* tslint:disable */
import React from 'react'

const SvgDownload = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
        <path d='M8.7 8.24l2.45-2.323.85.872L8.085 10.5 4 6.564l.857-.865L7.45 8.197V2H8.7v6.24zm3.675 3.135V9a.625.625 0 111.25 0v3c0 .345-.28.625-.625.625H3A.625.625 0 012.375 12V9a.625.625 0 111.25 0v2.375h8.75z' />
    </svg>
)

export default SvgDownload
