import * as React from 'react'
import * as C from 'libs/components'
import { DistribuicaoProdutosRow } from 'backend'
import cardiologia from 'images/home/perfil/ic-cardiologia.svg'
import ortopedia from 'images/home/perfil/ic-ortopedia.svg'
import { Link } from 'react-router-dom'

interface Props {
    distProdutos: DistribuicaoProdutosRow[]
    titulo?: string
    report(): JSX.Element
}

export const RastreabilidadeTable = ({ distProdutos, titulo, report }: Props) => {
    const maxQuocient = distProdutos && Math.max(...distProdutos.map(res => res.quantidadeProcedimentos))
    return (
        <div className='report-content'>
            <C.Card title={titulo} headerAction={report()}>
                {distProdutos && distProdutos.length < 1 && (
                    <p className='is-text-muted has-text-centered'>Nenhum registro encontrado.</p>
                )}
                {distProdutos && distProdutos.length > 0 && (
                    <table className='table is-medium table-produtos'>
                        <thead>
                            <tr>
                                <th>Registro</th>
                                <th></th>
                                <th>Nome</th>
                                <th></th>
                                <th>Proced</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(distProdutos).map(([index, value]) => {
                                const registroAnvisa = value.registroAnvisa
                                const procedimentos = value.quantidadeProcedimentos
                                const barWidth = Math.max(procedimentos / maxQuocient)
                                return (
                                    <tr key={index} data-name={'row-' + index}>
                                        <td>{registroAnvisa}</td>
                                        <td>
                                            {value.tipoProcedimento === 'STENT_CORONARIA' ? (
                                                <C.Image className='imagem-perfil' src={cardiologia} size='14x14' />
                                            ) : (
                                                <C.Image className='imagem-perfil' src={ortopedia} size='14x14' />
                                            )}
                                        </td>
                                        <td>{value.nomeProduto}</td>
                                        <td style={{ width: '30%' }}>
                                            <C.Progress
                                                size='x-small'
                                                className='is-primary no-margin'
                                                value={barWidth}
                                            />
                                        </td>
                                        <td className='has-text-centered is-black-bis'>{procedimentos}</td>
                                        <td>
                                            <Link
                                                to={
                                                    '/home/relatorios/rastreabilidade/registro/' +
                                                    registroAnvisa +
                                                    '/dashboard'
                                                }
                                            >
                                                >
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}
            </C.Card>
        </div>
    )
}
