import * as React from 'react'
import classnames from 'classnames'
import { UtilProps, helpersClassnames } from 'libs/components/Util'

export interface ButtonBarProps extends UtilProps {
    className?: string
    style?: React.CSSProperties
}

export class ButtonBar extends React.Component<ButtonBarProps> {
    render() {
        const classes: string = classnames('button-bar', this.props.className, helpersClassnames(this.props))

        return (
            <div style={this.props.style} className={classes}>
                {this.props.children}
            </div>
        )
    }
}
