/* tslint:disable */
import React from 'react'

const SvgMultProcedimentos = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
        <path d='M23.038 23.47V10.8c0-1.568-1.28-2.838-2.86-2.838h-8.724A3.001 3.001 0 0114.269 6H22a3 3 0 013 3v11.654a3.001 3.001 0 01-1.962 2.815zM9.615 9.27h10.5a1.61 1.61 0 011.616 1.604v14.444a1.61 1.61 0 01-1.616 1.605h-10.5A1.61 1.61 0 018 25.318V10.874A1.61 1.61 0 019.615 9.27z' />
    </svg>
)

export default SvgMultProcedimentos
