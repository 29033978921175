import { connect } from 'react-redux'
import { State } from 'redux/modules'
import { withRouter } from 'react-router-dom'
import { ProcedimentosHospitalView, Props } from './ProcedimentosHospitalView'
import {
    DistribuicaoProcedimentoFiltro,
    DistribuicaoHospitalProcedimentoDto,
    DistribuicaoProdutosProcedimentosHospitalDto,
    DistribuicaoProdutosProcedimentosHospitalFiltro,
    InstituicaoDto,
} from 'backend'
import * as filtroReport from 'redux/modules/report/filtro'
import { PageRequester, Requester } from 'redux/requester'
import Report from 'api/Report'

const distHospitalProcedimento = new Requester<DistribuicaoHospitalProcedimentoDto, DistribuicaoProcedimentoFiltro>(
    'distHospitalProcedimento',
    (filtro, config) => {
        return Report.getDistribuicaoHospitalProcedimento(filtro, config)
    }
)

const distribuicaoProdutosRegistro = new PageRequester<
    DistribuicaoProdutosProcedimentosHospitalDto,
    DistribuicaoProdutosProcedimentosHospitalFiltro
>('distProtudosRegistro', filtro => {
    return Report.findDistribuicaoProdutosProcedimentosHospitalRegistro(filtro)
})

const distribuicaoProdutosSemRegistro = new PageRequester<
    DistribuicaoProdutosProcedimentosHospitalDto,
    DistribuicaoProdutosProcedimentosHospitalFiltro
>('distProtudosSemRegistro', filtro => {
    return Report.findDistribuicaoProdutosProcedimentosHospitalSemRegistro(filtro)
})

const instituicao = new Requester<InstituicaoDto, DistribuicaoProcedimentoFiltro>(
    'getInstituicao',
    (filtro, config) => {
        return Report.getInstituicao(filtro, config)
    }
)

const mapStateToProps = (state: State, ownProps: Props) => ({
    filtro: state.report.filtro.filtro as DistribuicaoProcedimentoFiltro,
    procedimentosHospital: distHospitalProcedimento.getRequestState(state),
    instituicao: instituicao.getRequestState(state),
    produtosRegistro: distribuicaoProdutosRegistro.getRequestState(state),
    produtosSemRegistro: distribuicaoProdutosSemRegistro.getRequestState(state),
    isFetchingProdutosRegistro: distribuicaoProdutosRegistro.getIsFetching(state),
    isFetchingProdutosSemRegistro: distribuicaoProdutosSemRegistro.getIsFetching(state),
    goBack: () => ownProps.history.goBack(),
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(
            filtroReport.change({
                ...filtro,
            } as DistribuicaoProcedimentoFiltro)
        )
    },
    load: (filtro: DistribuicaoProcedimentoFiltro) => {
        const f = {
            ...filtro,
        }

        dispatch(distHospitalProcedimento.setParams(f))
        dispatch(distHospitalProcedimento.request())

        dispatch(distribuicaoProdutosRegistro.setParams(f))
        dispatch(distribuicaoProdutosRegistro.request())

        dispatch(distribuicaoProdutosSemRegistro.setParams(f))
        dispatch(distribuicaoProdutosSemRegistro.request())

        dispatch(instituicao.setParams(f))
        dispatch(instituicao.request())
    },
    handlePageChangeRegistro: (page: number) => {
        dispatch(distribuicaoProdutosRegistro.setPageNumber(page))
        dispatch(distribuicaoProdutosRegistro.request())
    },
    handlePageChangeSemRegistro: (page: number) => {
        dispatch(distribuicaoProdutosSemRegistro.setPageNumber(page))
        dispatch(distribuicaoProdutosSemRegistro.request())
    },
    resetFilter: (currentFilter: DistribuicaoProcedimentoFiltro) => {
        const { idHospital, tipoProcedimento, ...rest } = currentFilter
        dispatch(filtroReport.change({ ...rest }))
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProcedimentosHospitalView))
