/* tslint:disable */
import React from 'react'

const SvgOpcaovisualizacao = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M20.553 11.34c.596.605.596 1.715 0 2.32C18.96 15.276 15.778 18 12 18c-3.779 0-6.961-2.725-8.552-4.34-.597-.605-.597-1.715 0-2.32C5.038 9.724 8.22 7 12 7s6.961 2.725 8.553 4.34zm-.697 1.513c.299-.303.299-.706 0-.908C18.663 10.734 15.58 7.908 12 7.908s-6.663 2.826-7.856 4.037c-.299.303-.299.706 0 .908C5.337 14.064 8.42 16.89 12 16.89s6.663-2.826 7.856-4.037zM12 9.5c1.68 0 3 1.32 3 3s-1.32 3-3 3-3-1.32-3-3 1.32-3 3-3zm0 4.8c.96 0 1.8-.84 1.8-1.8 0-.96-.84-1.8-1.8-1.8-.96 0-1.8.84-1.8 1.8 0 .96.84 1.8 1.8 1.8z' />
    </svg>
)

export default SvgOpcaovisualizacao
