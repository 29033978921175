import * as React from 'react'
import { ButtonBar } from 'libs/components'
import { Button } from 'libs/components'
import { AlertaModal } from 'view/home/anvisa/alertas/components/AlertaModal'
import {
    AlertaAumentoRegistroDto,
    AlertaAumentoRegistroItemDto,
    TipoPeriodoAlertaEnum,
    TipoProcedimento,
    TipoCirurgia,
    DistribuicaoProcedimentoFiltro,
} from 'backend'
import { tipoProcedimentoPluralName, tipoPeriodoAlertaAlternateName } from 'redux/enums'
import Text from 'libs/components/presentational/typography/Text'
import classnames from 'classnames'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { stringify } from 'redux/modules/router'

interface AlertaAumentaRegistroModalProps extends RouteComponentProps<any> {
    alerta: AlertaAumentoRegistroDto
    fechar()
}

class AlertaAumentaRegistroModal extends React.PureComponent<AlertaAumentaRegistroModalProps> {
    render() {
        const periodoNome = tipoPeriodoAlertaAlternateName[this.props.alerta.tipoPeriodo]
        const tipoProcedimentoNome = tipoProcedimentoPluralName[this.props.alerta.tipoProcedimento]

        const soma = this.props.alerta.periodoAnterior.quantidade + this.props.alerta.periodoAlvo.quantidade

        let descricao

        if (this.props.alerta.emRelacaoA === 'PERIODO_ANTERIOR' || this.props.alerta.tipoPeriodo === 'ANUAL') {
            descricao = `O número de ${tipoProcedimentoNome.toLowerCase()} teve um aumento de ${
                this.props.alerta.percentual
            }% ou mais em relação ao ${periodoNome} anterior.`
        } else {
            descricao = `O número de ${tipoProcedimentoNome.toLowerCase()} teve um aumento de ${
                this.props.alerta.percentual
            }% ou mais em relação ao mesmo ${periodoNome} do ano anterior.`
        }

        const tipoCirurgia = tipoCirurgiaByTipoProcedimento[this.props.alerta.tipoProcedimento]
        const descricaoRelatorio = descricaoRelatorioByByTipoCirurgia[tipoCirurgia]
        const percentual: number =
            (this.props.alerta.periodoAlvo.quantidade * 100) / this.props.alerta.periodoAnterior.quantidade - 100

        return (
            <AlertaModal
                active
                className='modal-aumento-registro'
                fechar={this.props.fechar}
                tipoAlerta='AUMENTO_DE_REGISTROS'
                titulo={'Aumento de registro - ' + tipoProcedimentoNome}
                subtitulo={descricao}
                renderFooter={() => (
                    <ButtonBar>
                        <Button size='medium' onClick={this.props.fechar}>
                            Sair
                        </Button>
                        <Button size='medium' type='primary' onClick={this.irParaRelatorioDistribuicao}>
                            Acessar distrib. de {descricaoRelatorio}
                        </Button>
                    </ButtonBar>
                )}
            >
                <div className='percentual'>
                    <Text color='black' size='x-big' weight='semibold'>
                        {this.props.alerta.periodoAnterior.quantidade === 0 ? '100%' : `${percentual.toFixed(1)}%`}
                    </Text>
                </div>
                <hr />
                <DadosPeriodo
                    dados={this.props.alerta.periodoAnterior}
                    tipoPeriodo={this.props.alerta.tipoPeriodo}
                    soma={soma}
                />
                <DadosPeriodo
                    alvo
                    dados={this.props.alerta.periodoAlvo}
                    tipoPeriodo={this.props.alerta.tipoPeriodo}
                    soma={soma}
                />
            </AlertaModal>
        )
    }

    private irParaRelatorioDistribuicao = () => {
        const tipoCirurgia = tipoCirurgiaByTipoProcedimento[this.props.alerta.tipoProcedimento]

        if (tipoCirurgia === 'CORACAO') {
            this.props.history.push('/home/relatorios/angioplastia/dashboard')
        } else {
            this.props.history.push({
                pathname: '/home/relatorios/artroplastia/dashboard',
                search: stringify({ tipoCirurgia } as DistribuicaoProcedimentoFiltro),
            })
        }
    }
}

export default withRouter(AlertaAumentaRegistroModal)

interface DadosPeriodoProps {
    alvo?: boolean
    dados: AlertaAumentoRegistroItemDto
    tipoPeriodo: TipoPeriodoAlertaEnum
    soma: number
}

const DadosPeriodo: React.SFC<DadosPeriodoProps> = props => (
    <div className={classnames('dados-periodo', { alvo: props.alvo })}>
        {props.tipoPeriodo !== 'ANUAL' && (
            <Text color='black' size='medium' weight='semibold'>
                {descricoesPeriodos[props.tipoPeriodo][props.dados.periodo]}
            </Text>
        )}
        <span className='ano'>
            <Text color='grey-darker' size='x-small' weight='bold'>
                {props.dados.ano}
            </Text>
        </span>
        <span className='quantidade'>
            <Text color='black' size='medium' weight='semibold'>
                {props.dados.quantidade}
            </Text>
        </span>
        <Progresso progresso={props.dados.quantidade} max={props.soma} />
    </div>
)

interface ProgressoProps {
    progresso: number
    max: number
}

const Progresso: React.SFC<ProgressoProps> = props => (
    <progress className='progress' value={props.progresso} max={props.max}>
        {props.progresso || '0'}
    </progress>
)

const tipoCirurgiaByTipoProcedimento: { [key in TipoProcedimento]: TipoCirurgia } = {
    PRIMARIA_JOELHO: 'JOELHO',
    PRIMARIA_QUADRIL: 'QUADRIL',
    REVISAO_JOELHO: 'JOELHO',
    REVISAO_QUADRIL: 'QUADRIL',
    STENT_CORONARIA: 'CORACAO',
}

const descricaoRelatorioByByTipoCirurgia: { [key in TipoCirurgia]: string } = {
    JOELHO: 'artro.',
    QUADRIL: 'artro.',
    CORACAO: 'angio.',
}

const descricaoMeses: { [key in string]: string } = {
    '1': 'Janeiro',
    '2': 'Fevereiro',
    '3': 'Março',
    '4': 'Abril',
    '5': 'Maio',
    '6': 'Junho',
    '7': 'Julho',
    '8': 'Agosto',
    '9': 'Setembro',
    '10': 'Outubro',
    '11': 'Novembro',
    '12': 'Dezembro',
}

const descricaoBimestres: { [key in string]: string } = {
    '1': 'Jan e Fev',
    '2': 'Mar e Abr',
    '3': 'Mai e Jun',
    '4': 'Jul e Ago',
    '5': 'Set e Out',
    '6': 'Nov e Dez',
}

const descricaoTrimestres: { [key in string]: string } = {
    '1': 'Jan, Fev e Mar',
    '2': 'Abr, Mai e Jun',
    '3': 'Jul, Ago e Set',
    '4': 'Out, Nov e Dez',
}

const descricaoSemestres: { [key in string]: string } = {
    '1': '1º Semestre',
    '2': '2º Semestre',
}

const descricoesPeriodos: { [key in TipoPeriodoAlertaEnum]: { [key in string]: string } } = {
    MENSAL: descricaoMeses,
    BIMESTRAL: descricaoBimestres,
    TRIMESTRAL: descricaoTrimestres,
    SEMESTRAL: descricaoSemestres,
    ANUAL: null,
}
