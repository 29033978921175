import * as React from 'react'
import { Marker } from 'react-google-maps'
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'
import { Map } from './Map'

export interface MarkerInfo {
    pos: google.maps.LatLngLiteral | google.maps.LatLng
    title: string
    subtitle?: string
    text?: string
}

export class MarkerMapProps {
    markers: MarkerInfo[]
    onMarkerClick?(marker: google.maps.Marker): void
}

interface State {
    visibleId: number
}

export class MarkerInfoMap extends React.Component<MarkerMapProps, State> {
    constructor(props) {
        super(props)
        this.state = {
            visibleId: null,
        }
    }

    handleMarkerClick = id => {
        if (this.state.visibleId === id) {
            this.setState({ visibleId: null })
        } else {
            this.setState({ visibleId: id })
        }
    }

    render() {
        return (
            <Map>
                {this.props.markers.map((marker, idx) => (
                    <Marker key={idx} position={marker.pos} onClick={() => this.handleMarkerClick(idx)}>
                        <MarkerInfoBox
                            visible={this.state.visibleId === idx}
                            title={marker.title}
                            subtitle={marker.subtitle}
                            text={marker.text}
                        />
                    </Marker>
                ))}
            </Map>
        )
    }
}

export const MarkerInfoBox = ({ title, subtitle, text, visible }) => {
    return (
        <InfoBox
            options={{
                closeBoxURL: '',
                // pixelOffset: new google.maps.Size(10, -20),
                boxStyle: {
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                },
            }}
            visible={visible}
        >
            <div
                style={{
                    fontFamily: 'Open Sans',
                    width: '220px',
                    backgroundColor: '#fff',
                    padding: '15px 24px',
                    fontSize: '12px',
                    color: '#333',
                    borderRadius: '2px',
                }}
            >
                <h1 style={{}}>{title}</h1>
                {subtitle && (
                    <h2
                        style={{
                            color: '#999',
                            fontSize: '9px',
                            textTransform: 'uppercase',
                            letterSpacing: '0.4px',
                            fontWeight: 600,
                        }}
                    >
                        {subtitle}
                    </h2>
                )}
                {text && <p style={{ color: '#595959' }}>{text}</p>}
            </div>
        </InfoBox>
    )
}
