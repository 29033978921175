import { PerfilEnum } from 'api/Perfis'
import { BuscaPacienteReportFiltro, PacienteReportDto } from 'backend'
import semResultado from 'images/home/reports/rastreabilidade-de-produtos-busca-sem-resultados.svg'
import { Button, LoadingContainer } from 'libs/components'
import Text from 'libs/components/presentational/typography/Text'
import form from 'libs/redux-form/form'
import PerfilRoute from 'libs/router/PerfilRoute'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { InjectedFormProps } from 'redux-form'
import { formValueSelector } from 'redux-form/immutable'
import { createPaginator, getFilter, getPage, isFetching, Page } from 'redux/middleware/PaginationAction'
import { State } from 'redux/modules/index'
import { BuscaProcedimentoForm } from 'view/home/anvisa/buscaprocedimento/BuscaProcedimentoForm'
import { GestorServicoSaudeBuscaProcedimentoForm } from 'view/home/gestorServicoSaude/buscaProcedimento/GestorServicoSaudeBuscaProcedimentoForm'
import BuscaProcedimentoListContainer from './BuscaProcedimentoListContainer'

const formName = 'buscaProcedimento'

export interface BuscaProcedimentoProps extends RouteComponentProps<void> {
    doSearch(form): void
    handlePageChange(page: number): void
    isFetching: boolean
    pacientes: Page<PacienteReportDto>
    filtro: BuscaPacienteReportFiltro
}

class BuscaProcedimentoView extends React.Component<BuscaProcedimentoProps> {
    render() {
        const { pacientes, isFetching } = this.props
        return (
            <div>
                <ConnectedBuscaForm onSubmit={this.props.doSearch} filtro={this.props.filtro} />
                <LoadingContainer isFetching={isFetching}>
                    {pacientes?.numberOfElements > 0 ? (
                        <div className='container busca-page'>
                            <div className='busca-page-header'>
                                <Text size='x-large' color='black'>
                                    Lista de Pacientes
                                </Text>

                                <div className='flex-spacer'></div>
                                <PageInfo page={pacientes} />
                            </div>

                            <BuscaProcedimentoListContainer
                                pacientes={pacientes}
                                isFetching={isFetching}
                                handlePageChange={this.props.handlePageChange}
                                filtro={this.props.filtro}
                            />
                        </div>
                    ) : pacientes?.numberOfElements === 0 ? (
                        <div className='resultado-busca report-content'>
                            <div className='has-text-centered sem-resultados report-content'>
                                <img src={semResultado} alt='noresults' />
                                <br />
                            </div>
                            <p className='text-result has-text-centered report-content'>Sem resultados</p>
                        </div>
                    ) : null}
                </LoadingContainer>
            </div>
        )
    }
}

const url = '/api/report/buscaPacientes'

const mapStateToProps = (state: State, ownProps: BuscaProcedimentoProps) => ({
    pacientes: getPage<PacienteReportDto>(url)(state),
    filtro: getFilter<BuscaPacienteReportFiltro>(url)(state),
    isFetching: isFetching(url)(state),
})

const mapDispatchToProps = (dispatch, ownProps: BuscaProcedimentoProps) => {
    const paginator = createPaginator(dispatch, url)

    return {
        doSearch: form => {
            paginator.filter(form.toJS())
        },
        handlePageChange: (page: number) => paginator.page(page),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuscaProcedimentoView)

interface BuscaFormProps {
    habilitado: boolean
    filtro: BuscaPacienteReportFiltro
    shouldClassificacao: boolean
    onSubmit(form)
}

class BuscaFormCmp extends React.Component<BuscaFormProps & InjectedFormProps<{}, BuscaFormProps>> {
    render() {
        return (
            <div>
                <div className='header-busca--container'>
                    <div className='header-busca'>
                        <PerfilRoute
                            perfis={[PerfilEnum.ANVISA]}
                            render={() => (
                                <BuscaProcedimentoForm
                                    onSubmit={this.props.onSubmit}
                                    shouldClassificacao={this.props.shouldClassificacao}
                                />
                            )}
                        />
                        <PerfilRoute
                            perfis={[PerfilEnum.GESTOR_SERVICO_SAUDE]}
                            render={() => (
                                <GestorServicoSaudeBuscaProcedimentoForm
                                    onSubmit={this.props.onSubmit}
                                    shouldClassificacao={this.props.shouldClassificacao}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='has-text-centered'>
                    <Button
                        className='btn-pesquisar'
                        disabled={!this.props.habilitado}
                        type='primary'
                        size='medium'
                        shadow
                        onClick={this.props.handleSubmit}
                    >
                        Pesquisar
                    </Button>
                </div>
            </div>
        )
    }
}

type OwnProps = {
    onSubmit(form): void
    filtro: any
}

const BuscaForm = form({ form: 'buscaProcedimento', hasLeaveModal: false, hasSuccessModal: false })(BuscaFormCmp)

const selector = formValueSelector(formName)

const ConnectedBuscaForm = connect((state, ownProps: OwnProps) => {
    const hospital = selector(state, 'hospital')
    const medico = selector(state, 'profissional')
    const paciente = selector(state, 'paciente')
    const superior = hospital || medico || paciente

    const tipoCirurgia = selector(state, 'tipoCirurgia')
    const classCirurgia = selector(state, 'classificacaoCirurgia')

    const shouldClassificacao = tipoCirurgia && (tipoCirurgia.includes('QUADRIL') || tipoCirurgia.includes('JOELHO'))
    const classf = shouldClassificacao === !!(classCirurgia && (classCirurgia.size > 0 || classCirurgia.length > 0))
    const tipCir = tipoCirurgia && (tipoCirurgia.size > 0 || tipoCirurgia.length > 0)

    const inferior = tipCir && classf
    const habilitado = superior && inferior
    return {
        shouldClassificacao,
        habilitado,
        initialValues:
            ownProps.filtro ||
            ({
                tipoCirurgia: ['JOELHO', 'QUADRIL', 'CORACAO'],
                classificacaoCirurgia: ['PRIMARIA', 'REVISAO'],
            } as BuscaPacienteReportFiltro),
    }
})(BuscaForm)

interface PageInfoProps {
    page: Page<any>
}

const PageInfo: React.SFC<PageInfoProps> = props => {
    const firstElementShown = props.page.totalElements === 0 ? 0 : props.page.number * props.page.size + 1
    const lastElementShown =
        firstElementShown + props.page.size > props.page.totalElements
            ? props.page.totalElements
            : firstElementShown + props.page.size - 1
    return (
        <Text color='black-bis' size='medium'>
            {firstElementShown}-{lastElementShown} de {props.page.totalElements}
        </Text>
    )
}
