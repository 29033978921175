import { connect } from 'react-redux'
import { State } from 'redux/modules'
import { DistribuicaoProdutosFiltro } from 'backend'
import { RastreabilidadeReportFilterView, Props } from './RastreabilidadeReportFilterView'
import { withRouter } from 'react-router-dom'
import { SubmissionError } from 'redux-form/immutable'
import * as rastreabilidade from 'redux/modules/report/rastreabilidade'
import { RouteComponentProps } from 'react-router-dom'

const mapStateToProps = (state: State, ownProps: RouteComponentProps) => ({
    goBack: () => ownProps.history.goBack(),
    produto: state.report.rastreabilidade.produto,
    filtro: state.report.rastreabilidade.filtro,
})

const mergeToProps = (stateProps, dispatchProps, ownProps: RouteComponentProps): Props => {
    const { dispatch } = dispatchProps
    const { filtro } = stateProps
    return {
        ...ownProps,
        ...stateProps,
        find: (values: any) => {
            let filterValue: DistribuicaoProdutosFiltro = {}
            let buscar = false
            const registro = values.get('registroAnvisa')

            if (filtro === 'CNPJ') {
                filterValue = { cnpj: values.get('cnpj') }
                buscar = filterValue.cnpj && filterValue.cnpj.length === 14
            } else if (filtro === 'REGISTRO_ANVISA') {
                filterValue = { registroAnvisa: registro && registro.registroAnvisa }
                buscar = filterValue.registroAnvisa && filterValue.registroAnvisa.length === 11
            }
            if (buscar) {
                return dispatch(rastreabilidade.findDistribuicaoProdutos(filterValue))
                    .then(data => {
                        if (registro && data.status === 200) {
                            return ownProps.history.push(
                                '/home/relatorios/rastreabilidade/registro/' + registro.registroAnvisa + '/dashboard'
                            )
                        }
                        return data
                    })
                    .catch(error => {
                        throw new SubmissionError(error)
                    })
            }
        },

        changeFiltro: (filtro: string) => {
            dispatch(rastreabilidade.changeFiltro(filtro))
        },

        clearResult: () => {
            dispatch(rastreabilidade.clearFindResult())
        },
    }
}

export default withRouter(connect(mapStateToProps, null, mergeToProps)(RastreabilidadeReportFilterView))
