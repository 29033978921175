import * as React from 'react'
import classnames from 'classnames'

interface Props {
    className?: string
}

export class NoWrap extends React.PureComponent<Props> {
    render() {
        return <span className={classnames('nowrap', this.props.className)}>{this.props.children}</span>
    }
}
