import * as React from 'react'
import * as C from 'libs/components'
import field from 'libs/redux-form/field'
import { WrappedFieldProps } from 'redux-form'
import { InstituicaoDto } from 'backend'
import { FormatOptionLabelContext } from 'react-select/src/Select'
import InputMask from 'inputmask-core'

interface InstituicaoFieldProps extends Partial<C.AsyncSelectProps>, C.FormFieldProps, Partial<WrappedFieldProps> {
    cidadeId?: string
    getRef?(ref): void
}

export class InstituicaoFieldCmp extends React.Component<InstituicaoFieldProps> {
    handleChange = (newValue: C.FixedSelectOption | C.FixedSelectOption[] | null): void => {
        this.props.input.onChange(newValue as any)
    }

    render() {
        // Remove o `onChange` do props.input (do redux-form) pois sua assinatura é incompatível
        // Tratamos o change event manualmente e enviamos ao `onChange` do redux-form via `handleChange`
        const { onChange, ...inputRest } = this.props.input

        return (
            <C.AsyncSelect
                {...inputRest}
                getRef={this.props.getRef}
                url='/api/public/instituicao/getInstituicaoComboPage'
                isClearable={this.props.clearable}
                isDisabled={this.props.isDisabled}
                onChange={this.handleChange}
                placeholder={this.props.placeholder as any}
                filterOption={() => true as any}
                getOptionValue={opt => opt.razaoSocial}
                formatOptionLabel={this.renderOption.bind(this)}
                customizeParams={params => {
                    return { ...params, cidadeId: this.props.cidadeId }
                }}
            />
        )
    }

    renderOption(option, meta) {
        return <InstituicaoFieldOption option={option} context={meta.context} />
    }
}

export const InstituicaoField = field()(InstituicaoFieldCmp)

interface InstituicaoFieldOptionProps {
    option: InstituicaoDto
    context: FormatOptionLabelContext
}

export class InstituicaoFieldOption extends React.Component<InstituicaoFieldOptionProps> {
    formatMask(): any {
        const { cnpj } = this.props.option
        const mask = '11.111.111/1111-11'
        const result = new InputMask({ pattern: mask, value: cnpj })
        return result.getValue()
    }

    render() {
        const { razaoSocial, nomeFantasia, cnpj } = this.props.option
        const { context } = this.props
        return context === 'menu' ? (
            <div className='spacing-one-half' data-testid='hospital-field-option-id'>
                <p>{razaoSocial}</p>
                <p className='is-small'>
                    <strong>Nome fantasia: </strong>
                    {nomeFantasia ? nomeFantasia : <span className='is-text-muted'>Não informado</span>}
                </p>
                <p>
                    <strong>CNPJ: </strong>
                    {cnpj ? this.formatMask() : <span className='is-text-muted'>Não informado</span>}
                </p>
            </div>
        ) : (
            <p>{razaoSocial}</p>
        )
    }
}
