import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import {
    BuscaPacienteReportFiltro,
    DistribuicaoCardiologiaDto,
    DistribuicaoFinanceiraDto,
    DistribuicaoProcedimentoFiltro,
    DistribuicaoProdutosBrasilDto,
    DistribuicaoProdutosDto,
    DistribuicaoProdutosFiltro,
    DistribuicaoProdutosPrimariasDto,
    DistribuicaoProdutosRevisaoDto,
    InfoProdutoDto,
    InfoProdutoFiltro,
    InfoProdutoRow,
    LocalDimension,
    LocalidadeSearchResultDto,
    MotivosProcedimentosRow,
    MotivosRevisoesRow,
    MotivosQuadroPadraoDto,
    MultiplosPacienteDto,
    MultiplosProcedimentosDto,
    PacienteReportDto,
    PacienteReportFiltro,
    ProdutoDto,
    ProdutosProcedimentosRow,
    QuantidadeProcedimentosDto,
    StatusProcedimentoRow,
    SumarioPacienteDto,
    SumarioProcedimentoDto,
    UsoSistemaFiltro,
    UsoSistemaProfissionaisDto,
    UsoSistemaRegistrosFinalizadosDto,
    TipoRevisaoRow,
    UsoSistemaHospitaisNacionalDto,
    UsoSistemaHospitaisRegiaoDto,
    UsoSistemaStatusProcedsNacionalDto,
    UsoSistemaStatusProcedsDto,
    DistribuicaoProcedimentoRow,
    DistribuicaoHospitalProcedimentoDto,
    DistribuicaoProdutosProcedimentosHospitalFiltro,
    DistribuicaoProdutosProcedimentosHospitalDto,
    InstituicaoDto,
    PacienteProcedimentoReportDto,
    PacienteProcedimentoReportFiltro,
} from 'backend' // (Sort: name)
import { LocalFiltro, MultiplosProcedimentosFiltro } from 'model' // (Sort: name)
import { Page, PageParams } from 'redux/requester'

function findDistribuicaoProcedimentos(filtro: DistribuicaoProcedimentoFiltro) {
    return axios.get('/api/report/distribuicaoProcedimentos', { params: filtro })
}

function findSumarioPacienteFiltrado(id: number, filtro: any): AxiosPromise<SumarioPacienteDto> {
    return axios.get('/api/report/sumarioPacienteFiltrado/' + id, {
        params: {
            registroAnvisa: filtro.registroAnvisa,
            procedimentoMode: filtro.procedimentoMode,
            tipoCirurgia: filtro.tipoCirurgia,
        },
    })
}

function findSumarioProcedimentos(filtro: BuscaPacienteReportFiltro): AxiosPromise<SumarioProcedimentoDto[]> {
    return axios.get('/api/report/sumarioProcedimentos', { params: filtro })
}

function getProduto(registroAnvisa: string): AxiosPromise<ProdutoDto> {
    return axios.get('/api/report/produto', { params: { registroAnvisa } })
}

function getMotivosProcedimentos(filtro: DistribuicaoProcedimentoFiltro): AxiosPromise<MotivosProcedimentosRow[]> {
    return axios.get('/api/report/motivosProcedimentos', { params: filtro })
}

function findDistribuicaoProdutos(filtro: DistribuicaoProdutosFiltro): AxiosPromise<DistribuicaoProdutosDto> {
    return axios.get('/api/report/produtos', { params: filtro })
}

function getMotivosRevisoes(filtro: DistribuicaoProcedimentoFiltro): AxiosPromise<MotivosRevisoesRow[]> {
    return axios.get('/api/report/motivosRevisoes', { params: filtro })
}

function getQuadroPadrao(filtro: DistribuicaoProcedimentoFiltro): AxiosPromise<MotivosQuadroPadraoDto[]> {
    return axios.get('/api/report/quadroClinicoPadraoObstrutivo', { params: filtro })
}

function getDadosFinanceiros(filtro?: DistribuicaoProcedimentoFiltro): AxiosPromise<DistribuicaoFinanceiraDto> {
    return axios.get('/api/report/dadosFinanceiros', { params: filtro })
}

function getPacientes(filtro: PacienteReportFiltro): AxiosPromise<Page<PacienteReportDto>> {
    return axios.get('/api/report/pacientes', { params: filtro })
}

function findPacientes(form: BuscaPacienteReportFiltro): AxiosPromise<Page<PacienteReportDto>> {
    return axios.get('/api/report/buscaProcedimentos', { params: form })
}

function searchLocalidade(query: string): AxiosPromise<LocalidadeSearchResultDto> {
    return axios.get('/api/report/localidade/search', { params: { q: query } })
}

function findLocalidade(filtro: LocalFiltro): AxiosPromise<LocalDimension> {
    return axios.get('/api/report/localidade/find', { params: filtro })
}

function findStatsMultiplosProcedimentos(
    filtro: MultiplosProcedimentosFiltro
): AxiosPromise<MultiplosProcedimentosDto> {
    return axios.get('/api/report/multiplosProcedimentos/stats', { params: filtro })
}

function findMapMultiplosProcedimentos(filtro: MultiplosProcedimentosFiltro): AxiosPromise<MultiplosProcedimentosDto> {
    return axios.get('/api/report/multiplosProcedimentos/map', { params: filtro })
}

function getMultiplosPacientesPage(
    filtro: MultiplosProcedimentosFiltro & PageParams,
    config: AxiosRequestConfig = {}
): AxiosPromise<Page<MultiplosPacienteDto>> {
    return axios.get('/api/report/multiplosProcedimentos/pacientes', { ...config, params: filtro })
}

function getDistribuicaoProcedimentosHospitaisPage(
    filtro: DistribuicaoProcedimentoFiltro & PageParams,
    config: AxiosRequestConfig = {}
): AxiosPromise<Page<DistribuicaoProcedimentoRow>> {
    return axios.get('/api/report/distribuicaoHospitais', { ...config, params: filtro })
}

function findQtdProcedimentos(): AxiosPromise<QuantidadeProcedimentosDto> {
    return axios.get('/api/report/qtdProcedimentos')
}

function getTipoRevisao(filtro: DistribuicaoProcedimentoFiltro): AxiosPromise<TipoRevisaoRow[]> {
    return axios.get('/api/report/tipoRevisao', { params: filtro })
}

function getInfoProdutoPage(
    filtro: InfoProdutoFiltro & PageParams,
    config: AxiosRequestConfig = {}
): AxiosPromise<Page<InfoProdutoRow>> {
    return axios.get('/api/report/infoProduto', { ...config, params: filtro })
}

function getInfoProdutoDetail(
    filtro: InfoProdutoFiltro,
    config: AxiosRequestConfig = {}
): AxiosPromise<InfoProdutoDto> {
    return axios.get('/api/report/infoProduto/' + filtro.registroAnvisa, { ...config, params: filtro })
}

function getDistribuicaoHospitalProcedimento(
    filtro: DistribuicaoProcedimentoFiltro,
    config: AxiosRequestConfig = {}
): AxiosPromise<DistribuicaoHospitalProcedimentoDto> {
    return axios.get('/api/report/distribuicaoProcedimentosHospital', { ...config, params: filtro })
}

function findDistribuicaoProdutosProcedimentosHospitalRegistro(
    filtro: DistribuicaoProdutosProcedimentosHospitalFiltro,
    config: AxiosRequestConfig = {}
): AxiosPromise<Page<DistribuicaoProdutosProcedimentosHospitalDto>> {
    return axios.get('/api/report/distribuicaoProdutosProcedimentosHospitalRegistro', { ...config, params: filtro })
}

function findDistribuicaoProdutosProcedimentosHospitalSemRegistro(
    filtro: DistribuicaoProdutosProcedimentosHospitalFiltro,
    config: AxiosRequestConfig = {}
): AxiosPromise<Page<DistribuicaoProdutosProcedimentosHospitalDto>> {
    return axios.get('/api/report/distribuicaoProdutosProcedimentosHospitalSemRegistro', { ...config, params: filtro })
}

function getInstituicao(
    filtro: DistribuicaoProcedimentoFiltro,
    config: AxiosRequestConfig = {}
): AxiosPromise<InstituicaoDto> {
    return axios.get('/api/public/instituicao/getInstituicao/' + filtro.idHospital, { ...config })
}

function getMotivosInfoProduto(
    filtro: InfoProdutoFiltro,
    config: AxiosRequestConfig = {}
): AxiosPromise<MotivosRevisoesRow[]> {
    return axios.get('/api/report/infoProduto/motivos', { ...config, params: filtro })
}

function getPacientePage(
    filtro: PacienteReportFiltro & PageParams,
    config: AxiosRequestConfig = {}
): AxiosPromise<Page<PacienteReportDto>> {
    return axios.get('/api/report/pacientes', { ...config, params: filtro })
}

function getPacientesProcedimentos(
    filtro?: PacienteProcedimentoReportFiltro & PageParams
): AxiosPromise<Page<PacienteProcedimentoReportDto>> {
    return axios.get('/api/report/pacientesProcedimentos', { params: filtro })
}

function getPacientePageAbrangencia(
    filtro: PacienteReportFiltro & PageParams,
    config: AxiosRequestConfig = {}
): AxiosPromise<Page<PacienteReportDto>> {
    return axios.get('/api/report/pacientes-abrangencia', { ...config, params: filtro })
}

function getProdutosProcedimentos(
    filtro?: DistribuicaoProcedimentoFiltro
): AxiosPromise<Page<ProdutosProcedimentosRow>> {
    return axios.get('/api/report/produtosProcedimentos', { params: filtro })
}

function getProdutosPrimarias(
    id: number,
    filtro: DistribuicaoProcedimentoFiltro
): AxiosPromise<DistribuicaoProdutosPrimariasDto> {
    return axios.get('/api/report/componentePrimarias/' + id, { params: filtro })
}

function getProdutosRevisao(
    id: number,
    filtro: DistribuicaoProcedimentoFiltro
): AxiosPromise<DistribuicaoProdutosRevisaoDto> {
    return axios.get('/api/report/componenteRevisao/' + id, { params: filtro })
}

function getProdutosBrasil(
    id: number,
    filtro: DistribuicaoProcedimentoFiltro
): AxiosPromise<DistribuicaoProdutosBrasilDto> {
    return axios.get('/api/report/componenteBrasil/' + id, { params: filtro })
}

function getDetalheProdutoTipoRevisao(
    filtro: DistribuicaoProcedimentoFiltro
): AxiosPromise<DistribuicaoProdutosBrasilDto> {
    return axios.get('/api/report/getDetalheProdutoTipoRevisao', { params: filtro })
}

function getProdutosCardiologia(
    id: number,
    filtro: DistribuicaoProcedimentoFiltro
): AxiosPromise<DistribuicaoCardiologiaDto> {
    return axios.get('/api/report/componenteCardiologia/' + id, { params: filtro })
}

function getPageStatusProcedimentos(filtro: UsoSistemaFiltro & PageParams): AxiosPromise<Page<StatusProcedimentoRow>> {
    return axios.get('/api/report/statusProcedimentos', { params: filtro })
}

function getUsoSistemaProfissionais(filtro: UsoSistemaFiltro): AxiosPromise<UsoSistemaProfissionaisDto> {
    return axios.get('/api/report/usoSistema/profissionais', { params: filtro })
}

function getUsoSistemaMediaTempo(filtro: UsoSistemaFiltro): AxiosPromise<{ [index: string]: number }> {
    return axios.get('/api/report/usoSistema/mediaTempo', { params: filtro })
}

function getUsoSistemaRegistrosFinalizados(filtro: UsoSistemaFiltro): AxiosPromise<UsoSistemaRegistrosFinalizadosDto> {
    return axios.get('/api/report/usoSistema/registrosFinalizados', { params: filtro })
}

function getUsoSistemaHospitaisNacional(filtro: UsoSistemaFiltro): AxiosPromise<UsoSistemaHospitaisNacionalDto> {
    return axios.get('/api/report/usoSistema/hospitaisNacional', { params: filtro })
}

function getUsoSistemaHospitaisRegiao(filtro: UsoSistemaFiltro): AxiosPromise<UsoSistemaHospitaisRegiaoDto> {
    return axios.get('/api/report/usoSistema/hospitaisRegiao', { params: filtro })
}

function getUsoSistemaHospitaisUf(filtro: UsoSistemaFiltro): AxiosPromise<number> {
    return axios.get('/api/report/usoSistema/hospitaisUf', { params: filtro })
}

function getUsoSistemaHospitaisCidade(filtro: UsoSistemaFiltro): AxiosPromise<number> {
    return axios.get('/api/report/usoSistema/hospitaisCidade', { params: filtro })
}

function getUsoSistemaStatusRegistrosNacional(
    filtro: UsoSistemaFiltro
): AxiosPromise<UsoSistemaStatusProcedsNacionalDto> {
    return axios.get('/api/report/usoSistema/statusRegistrosNacional', { params: filtro })
}

function getUsoSistemaStatusRegistrosRegiao(filtro: UsoSistemaFiltro): AxiosPromise<UsoSistemaStatusProcedsDto> {
    return axios.get('/api/report/usoSistema/statusRegistrosRegiao', { params: filtro })
}

function getUsoSistemaStatusRegistrosUf(filtro: UsoSistemaFiltro): AxiosPromise<UsoSistemaStatusProcedsDto> {
    return axios.get('/api/report/usoSistema/statusRegistrosUf', { params: filtro })
}

function getUsoSistemaStatusRegistrosCidade(filtro: UsoSistemaFiltro): AxiosPromise<UsoSistemaStatusProcedsDto> {
    return axios.get('/api/report/usoSistema/statusRegistrosCidade', { params: filtro })
}

function getUsoSistemaStatusRegistrosHospital(filtro: UsoSistemaFiltro): AxiosPromise<UsoSistemaStatusProcedsDto> {
    return axios.get('/api/report/usoSistema/statusRegistrosHospital', { params: filtro })
}

function getRelacaoEmpresasRespostasProfissionaisUrl(): string {
    return '/api/report/relacaoEmpresasRespostasProfissionais'
}

function getRelacaoEmpresas(id: number): string {
    return '/api/report/relacaoEmpresas/' + id
}

export const exportFunctions = {
    findDistribuicaoProcedimentos,
    findSumarioPacienteFiltrado,
    findSumarioProcedimentos,
    getProduto,
    getMotivosProcedimentos,
    findDistribuicaoProdutos,
    getMotivosRevisoes,
    getQuadroPadrao,
    getDadosFinanceiros,
    getPacientes,
    findPacientes,
    getPacientesProcedimentos,
    searchLocalidade,
    findLocalidade,
    findStatsMultiplosProcedimentos,
    findMapMultiplosProcedimentos,
    getMultiplosPacientesPage,
    findQtdProcedimentos,
    getTipoRevisao,
    getInfoProdutoPage,
    getInfoProdutoDetail,
    getMotivosInfoProduto,
    getPacientePage,
    getPacientePageAbrangencia,
    getProdutosProcedimentos,
    getProdutosPrimarias,
    getProdutosRevisao,
    getProdutosBrasil,
    getProdutosCardiologia,
    getPageStatusProcedimentos,
    getUsoSistemaProfissionais,
    getUsoSistemaMediaTempo,
    getUsoSistemaRegistrosFinalizados,
    getUsoSistemaHospitaisNacional,
    getUsoSistemaHospitaisRegiao,
    getUsoSistemaHospitaisUf,
    getUsoSistemaHospitaisCidade,
    getUsoSistemaStatusRegistrosNacional,
    getUsoSistemaStatusRegistrosRegiao,
    getUsoSistemaStatusRegistrosUf,
    getUsoSistemaStatusRegistrosCidade,
    getUsoSistemaStatusRegistrosHospital,
    getRelacaoEmpresasRespostasProfissionaisUrl,
    getRelacaoEmpresas,
    getDistribuicaoProcedimentosHospitaisPage,
    getDistribuicaoHospitalProcedimento,
    findDistribuicaoProdutosProcedimentosHospitalRegistro,
    findDistribuicaoProdutosProcedimentosHospitalSemRegistro,
    getInstituicao,
    getDetalheProdutoTipoRevisao,
}

export default exportFunctions
