import React from 'react'
import DataFabricacao from 'view/home/rastreabilidade/componentes/DataFabricacao'
import field from 'libs/redux-form/field'

class DataFabricacaoFieldCmp extends React.Component {
    render() {
        return <DataFabricacao meta={this.props.meta} input={this.props.input} anoCirurgia={this.props.anoCirurgia} />
    }
}
export const DataFabricacaoField = field({ hasWrapper: false })(DataFabricacaoFieldCmp)
