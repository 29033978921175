
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.66,"time":99600,"words":332}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Aguardando dados clínicos” e no procedimento desejado clique no botão `}<strong parentName="li">{`“Preencher”`}</strong>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` apenas profissionais com perfil de “Médico Ortopedista” e “Residente em ortopedia” que partiparam da equipe médica do procedimento, podem registrar dados clínicos de procedimentos de Artroplastia.`}</p>
    </blockquote>
    <p><strong parentName="p">{`MOTIVO DA REVISÃO`}</strong></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Informe o(s) motivo(s) da revisão`}</li>
      <li parentName="ol">{`Caso tenha como motivo “Infecção” informe se foi precoce ou crônica`}</li>
    </ol>
    <p><strong parentName="p">{`PROCEDIMENTO CIRÚRGICO ATUAL`}</strong></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Informe a data da cirurgia e a duração em horas`}</li>
      <li parentName="ol">{`Informe qual foi a abordagem cirúrgica`}</li>
      <li parentName="ol">{`Informe se houve uso de dreno`}</li>
      <li parentName="ol">{`Informe se houve transfusão sanguínea`}</li>
      <li parentName="ol">{`Caso tenha sido feita transfusão sanguínea informe se foi autóloga ou homóloga`}</li>
      <li parentName="ol">{`Informe se foi utilizado antibiótico`}</li>
      <li parentName="ol">{`Caso tenha sido utilizado antibiótico informe qual o tipo de antibiótico e o período `}</li>
    </ol>
    <p><strong parentName="p">{`TIPO DE REVISÃO`}</strong></p>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`Informe o tipo de revisão`}</li>
    </ol>
    <p><strong parentName="p">{`CIMENTO ÓSSEO`}</strong></p>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol">{`Informe se foi utilizado cimento ósseo`}</li>
      <li parentName="ol">{`Caso tenha sido utilizado cimento:`}<ul parentName="li">
          <li parentName="ul">{`Informe se foi convencional ou com antibiótico`}</li>
          <li parentName="ul">{`Informe se houve lavagem pulsátil`}</li>
        </ul></li>
    </ol>
    <p><strong parentName="p">{`ENXERTIA`}</strong></p>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol">{`Informe se foi utilizado enxerto`}</li>
      <li parentName="ol">{`Caso tenha sido utilizado enxerto informe se foi Femoral ou Tibial e qual o tipo de enxerto utilizado`}</li>
    </ol>
    <p><strong parentName="p">{`COMPONENTES UTILIZADOS`}</strong></p>
    <ol {...{
      "start": 17
    }}>
      <li parentName="ol">{`Informe quais foram os principais componente utilizados`}</li>
      <li parentName="ol">{`Informe também a quantidade de cada componente utilizado`}</li>
    </ol>
    <p>{`Para remover um componente da lista clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeRecusar.png#center",
        "alt": "Remover"
      }}></img>{` ao lado do componente desejado`}</p>
    <ol {...{
      "start": 19
    }}>
      <li parentName="ol">{`Informe quais foram os componentes complementares utilizados`}</li>
      <li parentName="ol">{`Informe também a quantidade de cada componente complementar utilizado`}</li>
    </ol>
    <p>{`Para remover um componente utilizado, na lista clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeRecusar.png#center",
        "alt": "Remover"
      }}></img>{` ao lado do componente desejado`}</p>
    <ol {...{
      "start": 21
    }}>
      <li parentName="ol">{`Clique no botão “Salvar registro”`}</li>
    </ol>
    <p>{`Após o registro dos dados clínicos este procedimento fica disponível para preenchimento dos dados de produto, ou seja, os dados dos componentes implantados no paciente.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona cada etapa do preenchimento do registro, `}<a parentName="p" {...{
          "href": "../fluxos/fluxoPreenchimentoRegistros"
        }}><strong parentName="a">{`clique aqui`}</strong></a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona o preenchimento de dados clínicos de artroplastia, consulte o vídeo explicativo abaixo:`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

