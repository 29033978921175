import Procedimento, { tiposProcedimentoUrlByEnum } from 'api/Procedimento'
import { ProcedimentoRow, RascunhoProcedimentoRow } from 'backend'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { createPaginator, getPage, isFetching } from 'redux/middleware/PaginationAction'
import { State } from 'redux/modules'
import { setIdProcedimento } from 'redux/modules/edicaoProcedimento'
import * as novoProcedimento from 'redux/modules/novoProcedimento'
import * as procedimento from 'redux/modules/procedimento'
import * as rastreabilidade from 'redux/modules/rastreabilidade'
import HomeMedicoView from 'view/home/medico/HomeMedicoView'

const url = '/api/procedimentos'

const mapStateToProps = (state: State) => {
    return {
        isFetchingAguardandoDadosClinicos: state.procedimento.dadosClinicos.readyState === 'request',
        isFetchingAguardandoDadosProduto: state.procedimento.dadosProduto.readyState === 'request',
        isFetchingAguardandoDadosFinanceiros: isFetching(url + '/aguardandoDadosFinanceiros')(state),
        isFetchingHistorico: isFetching(url + '/historico')(state),
        aguardandoDadosClinicos: state.procedimento.dadosClinicos.data,
        aguardandoDadosProduto: state.procedimento.dadosProduto.data,
        aguardandoDadosFinanceiros: getPage<ProcedimentoRow>(url + '/aguardandoDadosFinanceiros')(state),
        historico: getPage<ProcedimentoRow>(url + '/historico')(state),
        perfil: state.auth.user && state.auth.user.perfil,
        showNotFoundModalError: state.novoProcedimento.showNotFoundModalError,
    }
}

const mapDispatchToProps = (dispatch, ownProps: RouteComponentProps<any>) => {
    const aguardandoDadosFinanceirosPaginator = createPaginator(dispatch, url + '/aguardandoDadosFinanceiros')
    const historicoPaginator = createPaginator(dispatch, url + '/historico')
    return {
        loadDadosProduto: () => {
            return dispatch(procedimento.getAguardandoDadosProduto())
        },

        loadDadosClinicos: () => {
            return dispatch(procedimento.getAguardandoDadosClinicos())
        },

        loadDadosFinanceiro: (page: number) => {
            aguardandoDadosFinanceirosPaginator.page(page)
        },

        loadDadosHistorico: (page: number) => {
            historicoPaginator.page(page)
        },

        onSelecionarNovoProcedimento: () => {
            ownProps.history.push('/home/novoProcedimento')
        },

        preencherDadosClinicos: (ficha: RascunhoProcedimentoRow) => {
            dispatch(novoProcedimento.loadEquipeMedicaFromRascunho(ficha.rascunhoId))
                .then(() => {
                    const tipoProcedimento = tiposProcedimentoUrlByEnum[ficha.tipoProcedimento]
                    dispatch(novoProcedimento.selecionarPaciente(ficha.paciente))
                    dispatch(novoProcedimento.selecionarProcedimento(tipoProcedimento, ficha.lado))
                    dispatch(novoProcedimento.setIdRascunhoOrigem(ficha.rascunhoId))
                    ownProps.history.push(`/home/novoProcedimento/${ficha.rascunhoId}/${tipoProcedimento}`)
                })
                .catch(() => {
                    dispatch(novoProcedimento.showNotFoundModalError())
                    dispatch(procedimento.getAguardandoDadosClinicos())
                })
        },

        onCloseNotFoundModal: () => {
            dispatch(novoProcedimento.closeNotFoundModalError())
        },

        preencherDadosProduto: ficha => {
            return dispatch(rastreabilidade.findProcedimento({ idProcedimento: ficha.procedimentoId })).then(() => {
                ownProps.history.push('/home/dadosProduto')
            })
        },

        editarDadosProduto: ficha => {
            dispatch(setIdProcedimento(ficha.procedimentoId))
            return dispatch(rastreabilidade.findProcedimento({ idProcedimento: ficha.procedimentoId })).then(() => {
                ownProps.history.push('/home/edicaoProcedimento/dadosProduto/')
            })
        },

        removerRascunho: rascunhoId => {
            Procedimento.deleteRascunhoById(rascunhoId)
                .catch(() => {
                    dispatch(novoProcedimento.showNotFoundModalError())
                })
                .finally(() => {
                    return dispatch(procedimento.getAguardandoDadosClinicos())
                })
        },

        imprimirFicha: procedimentoId => {
            window.open('/api/procedimentos/impressaoRegistro/' + procedimentoId, '_blank')
        },

        visualizarFicha: (idProcedimento, proced) => {
            const procedimentoUrl = tiposProcedimentoUrlByEnum[proced]
            ownProps.history.push(`/home/visualizacao/${procedimentoUrl}/` + idProcedimento)
        },
    }
}

const HomeMedicoContainer = connect(mapStateToProps, mapDispatchToProps)(HomeMedicoView as any)

export default HomeMedicoContainer
