import Integracao from 'api/Integracao'
import { AutenticacaoIntegracaoDto, SistemaIntegracaoWrapperDto } from 'backend'
import { ReadyState } from 'redux/requester'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'

interface AutenticacaoReadyState {
    readyStateLogin: ReadyState
    readyStateSenha: ReadyState
}

export type AutenticacaoState = AutenticacaoIntegracaoDto & AutenticacaoReadyState

export interface IntegracaoState
    extends Readonly<{
        sistemasAtivos: PromiseRecord<SistemaIntegracaoWrapperDto>
        sistemasInativos: PromiseRecord<SistemaIntegracaoWrapperDto>
        autenticacoes: AutenticacaoState[]
    }> {}

export const GET_SISTEMAS_ATIVOS = 'rni/integracao/sistemas/GET_SISTEMAS_ATIVOS'
export const CLEAR_SISTEMAS_ATIVOS = 'rni/integracao/sistemas/CLEAR_SISTEMAS_ATIVOS'
export const GET_SISTEMAS_INATIVOS = 'rni/integracao/sistemas/GET_SISTEMAS_INATIVOS'
export const CLEAR_SISTEMAS_INATIVOS = 'rni/integracao/sistemas/CLEAR_SISTEMAS_INATIVOS'
export const GET_AUTENTICACOES = 'rni/integracao/autenticacao/GET_AUTENTICACOES'
export const CLEAR_AUTENTICACOES = 'rni/integracao/autenticacao/CLEAR_AUTENTICACOES'
export const GENERATE_LOGIN = 'rni/integracao/autenticacao/GENERATE_LOGIN'
export const GENERATE_SENHA = 'rni/integracao/autenticacao/GENERATE_SENHA'

export default function reducer(state: IntegracaoState = {} as IntegracaoState, action): IntegracaoState {
    switch (action.type) {
        case GET_SISTEMAS_ATIVOS:
            return { ...state, sistemasAtivos: promiseReducer(state.sistemasAtivos, action) }
        case CLEAR_SISTEMAS_ATIVOS:
            return { ...state, sistemasAtivos: {} }
        case GET_SISTEMAS_INATIVOS:
            return { ...state, sistemasInativos: promiseReducer(state.sistemasInativos, action) }
        case CLEAR_SISTEMAS_INATIVOS:
            return { ...state, sistemasInativos: {} }
        case GET_AUTENTICACOES:
            return {
                ...state,
                autenticacoes: action.result?.data.map(autenticacao => {
                    return {
                        ...autenticacao,
                        readyStateLogin: action.readyState,
                        readyStateSenha: action.readyState,
                    }
                }),
            }
        case GENERATE_LOGIN:
            return {
                ...state,
                autenticacoes: state.autenticacoes.map(autenticacao => {
                    if (autenticacao.sistema.id === action.idSistema) {
                        return {
                            ...autenticacao,
                            readyStateLogin: action.readyState,
                            login: action.result?.data.login,
                            dataRegistroLogin: action.result?.data.dataRegistroLogin,
                            ativo: action.result?.data.ativo,
                        }
                    }

                    return autenticacao
                }),
            }
        case GENERATE_SENHA:
            return {
                ...state,
                autenticacoes: state.autenticacoes.map(autenticacao => {
                    if (autenticacao.sistema.id === action.idSistema) {
                        return {
                            ...autenticacao,
                            readyStateSenha: action.readyState,
                            senha: action.result?.data.senha,
                            dataRegistroSenha: action.result?.data.dataRegistroSenha,
                            ativo: action.result?.data.ativo,
                        }
                    }

                    return autenticacao
                }),
            }
        case CLEAR_AUTENTICACOES:
            return { ...state, autenticacoes: [] }
        default:
            return state
    }
}

export const getSistemasAtivos = () => ({
    type: GET_SISTEMAS_ATIVOS,
    promise: Integracao.getSistemasAtivos(),
})

export const clearSistemasAtivos = () => ({
    type: CLEAR_SISTEMAS_ATIVOS,
})

export const getSistemasInativos = () => ({
    type: GET_SISTEMAS_INATIVOS,
    promise: Integracao.getSistemasInativos(),
})

export const clearSistemasInativos = () => ({
    type: CLEAR_SISTEMAS_INATIVOS,
})

export const getAutenticacoes = () => ({
    type: GET_AUTENTICACOES,
    promise: Integracao.getAutenticacoes(),
})

export const clearAutenticacoes = () => ({
    type: CLEAR_AUTENTICACOES,
})

export const generateLogin = (idSistema: number) => ({
    type: GENERATE_LOGIN,
    promise: Integracao.generateNovoLogin(idSistema),
    idSistema,
})

export const generateSenha = (idSistema: number) => ({
    type: GENERATE_SENHA,
    promise: Integracao.generateNovaSenha(idSistema),
    idSistema,
})
