import React from 'react'
import { FieldArray } from 'redux-form/immutable'
import { fromJS } from 'immutable'
import * as C from 'libs/components'

class ListItemField extends React.Component {
    constructor(props) {
        super(props)
        this.state = null
    }

    handleChange(value) {
        if (value) {
            value.quantidade = 1
            let contemComponente = false
            this.props.fields.forEach((componente, index) => {
                if (this.props.fields.get(index).get('id') === value.id) {
                    contemComponente = true
                    return
                }
            })
            if (!contemComponente) {
                this.props.fields.push(fromJS(value))
            }
            this.setState(null)
        }
    }

    render() {
        const fields = this.props.fields
        return (
            <div>
                <C.Columns>
                    <C.Column size='8'>
                        {this.props.combo &&
                            React.cloneElement(this.props.combo, {
                                onChangeComponente: this.handleChange.bind(this),
                                value: this.state,
                            })}
                        {this.props.children}
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column>
                        <C.Table
                            name={fields.name}
                            className='table-home componentes'
                            values={fields}
                            emptyText='Não há componentes registrados'
                        >
                            <C.TableColumnHeader
                                title='Componente'
                                size='7'
                                render={(value, index) => <label>{fields.get(index).get('nome')}</label>}
                            />
                            <C.TableColumnHeader
                                title='Qtd.'
                                className='qtd'
                                size='2'
                                render={(value, index) => (
                                    <C.SimpleCountField
                                        name={`${value}.quantidade`}
                                        qtdMax={fields.get(index).get('quantidadeMax')}
                                    />
                                )}
                            />
                            <C.TableColumnHeader
                                title=''
                                size='1'
                                render={(value, index) => (
                                    <C.IconButton onClick={() => fields.remove(index)} icon='excluir' iconOnly />
                                )}
                            />
                        </C.Table>
                    </C.Column>
                </C.Columns>
            </div>
        )
    }
}

export class ListComponenteFichaField extends React.Component {
    render() {
        return <FieldArray {...this.props} component={ListItemField} />
    }
}
