import * as React from 'react'
import { connect } from 'react-redux'
import * as C from 'libs/components'
import UrlParams from 'libs/router/UrlParams'
import { NavLink, Switch, Route } from 'react-router-dom'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
    DistribuicaoProcedimentoFiltro,
    DateInterval,
    TipoRevisaoEnum,
    TipoProcedimento,
    TipoCirurgia,
    TipoRevisaoRow,
} from 'backend'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import { State } from 'redux/modules'
import * as revisao from 'redux/modules/report/revisao'
import { SumarioTipoRevisaoCard } from 'view/componentes/reports/cards/SumarioTipoRevisaoCard'
import * as procedimento from 'redux/modules/report/procedimento'
import { DistClassificacaoSexo } from 'redux/modules/report/procedimento'
import { DadosUsoSexoCard } from 'view/componentes/reports/cards/DadosUsoSexoCard'
import { ProporcaoTipoRevisaoCard } from 'view/componentes/reports/cards/ProporcaoTipoRevisaoCard'
import { TipoRevisao } from 'redux/enums/index'
import { Situacao } from 'redux/enums/index'
import { TipoRevisaoDto } from 'redux/modules/report/revisao'
import * as filtroReport from 'redux/modules/report/filtro'
import { NumberLabel } from 'libs/components/label/NumberLabel'
import { ScrollToTop } from 'libs/components'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import BackButton from 'libs/components/presentational/elements/BackButton'

export interface Props extends RouteComponentProps<any> {
    filtro: DistribuicaoProcedimentoFiltro
    tipoRevisao: TipoRevisaoDto
    distClassificacaoSexo: DistClassificacaoSexo
    goBack(): void
    changeFilter(filtro: DistribuicaoProcedimentoFiltro): void
    loadTiposRevisao(): void
    goToDetail(mot: TipoRevisaoEnum): void
}

export class TipoRevisaoView extends React.Component<Props> {
    handlePeriodoChange = (interval: DateInterval) => {
        this.props.changeFilter({
            ...this.props.filtro,
            interval: interval,
        })
    }

    handleTabItemClick = (tipoProcedimento: TipoProcedimento[], tipoCirurgia: TipoCirurgia) => {
        this.props.changeFilter({
            ...this.props.filtro,
            tipoProcedimento: tipoProcedimento,
            tipoCirurgia: tipoCirurgia,
        })
    }

    refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        this.props.loadTiposRevisao()
    }

    getFilter = () => {
        const currentFilter = this.props.filtro || {}
        return {
            ...currentFilter,
            tipoCirurgia: currentFilter.tipoCirurgia || 'JOELHO',
            tipoProcedimento: currentFilter.tipoProcedimento || ['REVISAO_JOELHO'],
        } as DistribuicaoProcedimentoFiltro
    }

    render() {
        const filtro = this.getFilter()
        const tipos = this.props.tipoRevisao.data || []
        const situacoes = this.props.tipoRevisao.counts
        const total = situacoes ? Object.values(situacoes).reduce((total, curr) => (total += curr), 0) : 0

        return (
            <div>
                <ScrollToTop onMount />
                <UrlParams params={filtro} onChange={this.refresh} />
                <ReportClearFilter />

                <ReportHeader title='Tipos de revisão'>
                    <div>
                        <BackButton />
                    </div>

                    <HorizontalTabMenu>
                        <HorizontalTabMenuItem
                            title='Joelho'
                            onClick={() => this.handleTabItemClick(['REVISAO_JOELHO'], 'JOELHO')}
                            active={filtro.tipoCirurgia === 'JOELHO'}
                        />
                        <HorizontalTabMenuItem
                            title='Quadril'
                            onClick={() => this.handleTabItemClick(['REVISAO_QUADRIL'], 'QUADRIL')}
                            active={filtro.tipoCirurgia === 'QUADRIL'}
                        />
                    </HorizontalTabMenu>
                </ReportHeader>

                <div className='report-content'>
                    <C.Columns className='report-tabs align-center'>
                        <C.Column size='6'>
                            <div className='report-tabs-links'>
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}`,
                                        search: this.props.location.search,
                                    }}
                                    activeClassName='is-active'
                                >
                                    Dashboard
                                </NavLink>
                            </div>
                        </C.Column>
                        <C.Column size='6' textRight>
                            <C.PeriodoFilter value={filtro.interval} onChange={this.handlePeriodoChange} />
                        </C.Column>
                    </C.Columns>

                    <C.Columns auto>
                        <div className='flex-7'>
                            <DadosUsoSexoCard dist={this.props.distClassificacaoSexo} />
                        </div>
                        <div className='flex-5'>
                            {situacoes && (
                                <ProporcaoTipoRevisaoCard
                                    situacoes={situacoes}
                                    tipoRevisao={this.props.tipoRevisao}
                                    total={total}
                                    proced={filtro.tipoCirurgia}
                                />
                            )}
                        </div>
                    </C.Columns>

                    <Switch>
                        <Route
                            path={`${this.props.match.url}`}
                            render={() => (
                                <div>
                                    {situacoes &&
                                        Object.entries(situacoes).map(([situacaoKey, value]) => {
                                            const situacao = Situacao[situacaoKey]
                                            return (
                                                <div key={situacaoKey}>
                                                    {
                                                        <span
                                                            className='label'
                                                            style={{ paddingTop: '20px', paddingBottom: '7px' }}
                                                        >
                                                            {situacao.descricao}:{' '}
                                                            <NumberLabel
                                                                className='motivo-card-content'
                                                                value={value > 0 ? (value / total) * 100 : undefined}
                                                                maxDecimalPlaces={2}
                                                                sufix='%'
                                                                placeholder='- %'
                                                            />
                                                        </span>
                                                    }
                                                    {Object.entries(TipoRevisao)
                                                        .filter(
                                                            ([key, tipoValue]) => tipoValue.situacao === situacaoKey
                                                        )
                                                        .map(([key, tipoValue], index) => {
                                                            const tipoRow: TipoRevisaoRow =
                                                                tipos.find(tipo => tipo.tipoRevisao === key) ||
                                                                ({
                                                                    tipoRevisao: key,
                                                                    tipoRevisaoName: tipoValue.descricao,
                                                                    cont: 0,
                                                                } as TipoRevisaoRow)
                                                            return (
                                                                <SumarioTipoRevisaoCard
                                                                    tipo={tipoRow}
                                                                    key={index}
                                                                    total={value}
                                                                    onClick={() => {
                                                                        this.props.goToDetail(tipoRow.tipoRevisao)
                                                                    }}
                                                                />
                                                            )
                                                        })}
                                                </div>
                                            )
                                        })}
                                </div>
                            )}
                        />
                    </Switch>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: State, ownProps: RouteComponentProps) => ({
    filtro: state.report.filtro.filtro,
    tipoRevisao: state.report.revisao.tipoRevisao,
    distClassificacaoSexo: state.report.procedimento.distClassificacaoSexo,
    goBack: () => ownProps.history.goBack(),
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(filtroReport.change({ ...filtro }))
    },
    loadTiposRevisao: () => {
        dispatch(revisao.loadAll())
        dispatch(procedimento.loadTipoRevisao())
    },
    goToDetail: (tipo: TipoRevisaoEnum) => {
        ownProps.history.push({
            pathname: `${ownProps.match.url}/` + tipo,
            search: ownProps.location.search,
        })
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TipoRevisaoView))
