import { combineReducers } from 'redux'
import auth, { AuthState } from 'redux/modules/auth'
import alert, { AlertState } from 'redux/modules/alert'
import home, { HomeState } from 'redux/modules/home'
import paciente, { PacienteState } from 'redux/modules/paciente'
import profissional, { ProfissionalState } from 'redux/modules/profissional'
import { reducerEdicao as edicao, EdicaoState } from 'redux/modules/edicaoProcedimento'
import novoProcedimento, { NovoProcedimentoState } from 'redux/modules/novoProcedimento'
import report, { ReportState } from 'redux/modules/report'
import procedimento, { ProcedimentoState } from 'redux/modules/procedimento'
import { reducer as reduxFormReducer } from 'redux-form/immutable'
import forms from './forms'
import rastreabilidade from 'redux/modules/rastreabilidade'
import recaptcha, { RecaptchaState } from 'redux/modules/recaptcha'
import { reducer as paginationReducer, PaginationState } from 'redux/middleware/PaginationAction'
import requesterReducer, { RequesterState } from 'redux/requester'
import { connectRouter } from 'connected-react-router'
import info, { InfoState } from './info'
import antibioticos, { AntibioticosState } from './antibioticos'
import componentes, { ComponentesState } from './componentes'
import cadastro, { CadastroState } from './cadastro'
import integracao, { IntegracaoState } from './integracao'
import relacaoEmpresas, { RelacaoEmpresasState } from './relacaoEmpresas'
import termoUso, { TermoUsoState } from './termoUso'

export interface State {
    auth: AuthState
    alert: AlertState
    antibioticos: AntibioticosState
    componentes: ComponentesState
    cadastro: CadastroState
    home: HomeState
    info: InfoState
    integracao: IntegracaoState
    novoProcedimento: NovoProcedimentoState
    edicao: EdicaoState
    paciente: PacienteState
    pagination: PaginationState
    procedimento: ProcedimentoState
    form: any
    profissional: ProfissionalState
    rastreabilidade: any
    recaptcha: RecaptchaState
    relacaoEmpresas: RelacaoEmpresasState
    report: ReportState
    requester: RequesterState
    termoUso: TermoUsoState
}

export default history =>
    combineReducers({
        auth,
        alert,
        antibioticos,
        componentes,
        cadastro,
        home,
        info,
        integracao,
        novoProcedimento,
        edicao,
        paciente,
        procedimento,
        profissional,
        rastreabilidade,
        recaptcha,
        report,
        router: connectRouter(history),
        form: reduxFormReducer.plugin(forms),
        pagination: paginationReducer,
        relacaoEmpresas,
        requester: requesterReducer,
        termoUso,
    })
