import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import { PrimariaQuadrilForm } from 'backend'
import immutableProxy, { ImmutableRootProxy } from 'libs/immutableProxy'
import { isImmutable } from 'immutable'

const validaProcedimentoCirurgicoPrevio = (mutable: ImmutableRootProxy<FormState<PrimariaQuadrilForm>>) => {
    const proceds = mutable.values.procedimentoCirurgicoPrevio.procedimentoPrevio.get()
    if (proceds && proceds.indexOf('NENHUM') > -1) {
        mutable.values.procedimentoCirurgicoPrevio.procedimentoPrevio.set(['NENHUM'])
    }
    if (mutable.values.procedimentoCirurgicoPrevio.implantePrevio.get() !== 'SIM') {
        mutable.values.procedimentoCirurgicoPrevio.estadoImplantePrevio.delete()
    }
    return mutable
}
const validaProcedimentoCirurgicoAtual = (mutable: ImmutableRootProxy<FormState<PrimariaQuadrilForm>>) => {
    if (mutable.values.procedimentoCirurgicoAtual.transfusaoSanguinea.get() !== 'SIM') {
        mutable.values.procedimentoCirurgicoAtual.tipoTransfusaoSanguinea.delete()
    }

    if (mutable.values.procedimentoCirurgicoAtual.possuiAntibiotico.get() !== 'SIM') {
        mutable.values.procedimentoCirurgicoAtual.antibiotico.delete()
        mutable.values.procedimentoCirurgicoAtual.periodoAntibiotico.delete()
    }
    return mutable
}

const validaImplantesFemoral = (mutable: ImmutableRootProxy<FormState<PrimariaQuadrilForm>>) => {
    if (!mutable.values.implantes.femoral.get()) {
        mutable.values.implantes.modeloHaste.delete()
        mutable.values.implantes.centralizadorDistal.delete()
        mutable.values.implantes.centralizadorProximal.delete()
        mutable.values.implantes.femoralCimentado.delete()
        mutable.values.enxertia.enxertiaFemoral.delete()
    }
    if (mutable.values.implantes.femoralCimentado.get() !== 'SIM') {
        mutable.values.implantes.restritorCimento.delete()
    }
    if (mutable.values.implantes.restritorCimento.get() !== 'SIM') {
        mutable.values.implantes.tipoRestritorCimento.delete()
    }
    return mutable
}

const validaImplantesAcetabular = (mutable: ImmutableRootProxy<FormState<PrimariaQuadrilForm>>) => {
    if (!mutable.values.implantes.acetabular.get()) {
        mutable.values.implantes.polietileno.delete()
        mutable.values.implantes.metalico.delete()
        mutable.values.implantes.acetabularCimentado.delete()
        mutable.values.enxertia.enxertiaAcetabular.delete()
    }
    if (!mutable.values.implantes.polietileno.get()) {
        mutable.values.implantes.tipoAcetabularPolietileno.delete()
    }
    if (!mutable.values.implantes.metalico.get()) {
        mutable.values.implantes.tipoAcetabularMetalico.delete()
    }
    if (mutable.values.implantes.acetabularCimentado.get() !== 'NAO') {
        mutable.values.implantes.parafusos.delete()
    }
    return mutable
}
const primariaQuadril = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<PrimariaQuadrilForm>>(state)
        form = form.withMutations(mutable => {
            validaProcedimentoCirurgicoPrevio(mutable)
            validaProcedimentoCirurgicoAtual(mutable)

            const tipoArtroplastia = mutable.values.tipoArtroplastiaForm.tipoArtroplastia.get()
            let immTipoArtroplastiaId
            if (isImmutable(tipoArtroplastia)) {
                const tipoArtroDto = tipoArtroplastia.toJS() as any
                immTipoArtroplastiaId = tipoArtroDto.id
            } else {
                immTipoArtroplastiaId = tipoArtroplastia?.id
            }
            if (!tipoArtroplastia || immTipoArtroplastiaId !== 'CONVENCIONAL') {
                mutable.values.tipoArtroplastiaForm.parTribologico.delete()
            }

            validaImplantesFemoral(mutable)
            validaImplantesAcetabular(mutable)

            if (
                mutable.values.implantes.acetabularCimentado.get() !== 'SIM' &&
                mutable.values.implantes.femoralCimentado.get() !== 'SIM' &&
                mutable.values.cimentoOsseo.get() !== null
            ) {
                mutable.values.cimentoOsseo.tipoCimentoOsseo.delete()
                mutable.values.cimentoOsseo.tecnicaCimentacao.delete()
                mutable.values.cimentoOsseo.lavagemPulsatil.delete()
            }
            if (!mutable.values.enxertia.enxertiaFemoral.get()) {
                mutable.values.enxertia.tipoEnxertiaFemoral.delete()
            }
            if (!mutable.values.enxertia.enxertiaAcetabular.get()) {
                mutable.values.enxertia.tipoEnxertiaAcetabular.delete()
            }
        })
        return form.asMap()
    }
    return state
})

export default primariaQuadril
