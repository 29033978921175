import { EstadoVersaoFichaEnum } from 'backend'
import { Button, Column, Columns, Icon, Label } from 'libs/components'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import HistoricoFichaPopover from 'view/home/componentes/HistoricoFichaPopover'

interface AvisoVersaoFichaProps {
    estadoVersaoFicha: EstadoVersaoFichaEnum
    dataAtualizacao: string
    idFicha: number
    idAtual: number
}

export const AvisoVersaoFicha = (props: AvisoVersaoFichaProps) => {
    const { url } = useRouteMatch()
    const history = useHistory()
    if (props.estadoVersaoFicha === 'PRIMEIRA') {
        return null
    }

    function handleShowLatest() {
        history.push(url.slice(0, url.lastIndexOf('/')))
    }

    const isDesatualizada = props.estadoVersaoFicha === 'DESATUALIZADA'
    return (
        <div className={isDesatualizada ? 'aviso-versao-desatualizada-box' : 'aviso-versao-atualizada-box'}>
            <Columns>
                {isDesatualizada ? (
                    <>
                        <Column size='1' customStyle={{ width: '6%' }}>
                            <Icon icon='alerta' size='is-20px' className='alert-icon' />
                        </Column>
                        <Column customStyle={{ width: 'unset' }}>
                            <Label className='aviso-versao-titulo'>
                                Você está visualizando uma versão desatualizada
                            </Label>
                            <Label className='aviso-versao-subtitulo'>Atualizada em {props.dataAtualizacao}</Label>
                        </Column>
                        <Column size='3' customStyle={{ width: 'unset' }}>
                            <Button onClick={handleShowLatest} className='preencher' icon='vermais'>
                                Ver mais recente
                            </Button>
                        </Column>
                    </>
                ) : (
                    <Column>
                        <Label className='aviso-versao-titulo'>Visualizando a versão mais recente</Label>
                        <Label className='aviso-versao-subtitulo'>Atualizada em {props.dataAtualizacao}</Label>
                    </Column>
                )}
                <Column size='2'>
                    <HistoricoFichaPopover
                        placement='left'
                        registro={props.idFicha}
                        idAtual={isDesatualizada ? props.idAtual : undefined}
                    >
                        <Button className='preencher' icon='filtroProcedimento'>
                            Histórico
                        </Button>
                    </HistoricoFichaPopover>
                </Column>
            </Columns>
        </div>
    )
}
