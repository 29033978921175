import { tiposVisualizacaoHistoricoUrlByEnum } from 'api/Visualizacao'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import VisualizacaoStentCoronariaWrapper from './VisualizacaoStentCoronaria'

function VisualizacaoEdicaoStentCoronariaView(props: RouteComponentProps<any>) {
    const proced = tiposVisualizacaoHistoricoUrlByEnum['STENT_CORONARIA']
    const imprimirFicha = (idProcedimento: number) => {
        window.open('/api/procedimentos/impressaoRegistroHistorico/' + idProcedimento, '_blank')
    }
    return (
        <VisualizacaoStentCoronariaWrapper
            id={props.match.params.idEdicao}
            proced={proced}
            idProced={props.match.params.id}
            imprimirFicha={imprimirFicha}
            {...props}
        />
    )
}

export default VisualizacaoEdicaoStentCoronariaView
