import { ProdutoDto, DistribuicaoProdutosFiltro, DistribuicaoProdutosDto } from 'backend'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'
import Report from 'api/Report'

const CHANGE_FILTRO = 'rni/report/rastreabilidade/CHANGE_FILTRO'
const CLEAR_FIND_RESULT = 'rni/report/rastreabilidade/CLEAR_FIND_RESULT'
const CLEAR_FIND_PRODUTO = 'rni/report/rastreabilidade/CLEAR_FIND_PRODUTO'
export const FIND_PRODUTO = 'rni/report/rastreabilidade/FIND_PRODUTO'
export const FIND_DIST_FILTER = 'rni/report/rastreabilidade/FIND_DIST_FILTER'

export interface RastreabilidadeReportState {
    filtro: string
    produto: PromiseRecord<ProdutoDto>
    findDistribuicaoProdutos: PromiseRecord<DistribuicaoProdutosDto>
}

export const initialState: RastreabilidadeReportState = {
    filtro: 'REGISTRO_ANVISA',
    produto: {},
    findDistribuicaoProdutos: {},
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FIND_PRODUTO:
            return { ...state, produto: promiseReducer(state.produto, action) }
        case FIND_DIST_FILTER:
            return { ...state, findDistribuicaoProdutos: promiseReducer(state.findDistribuicaoProdutos, action) }
        case CHANGE_FILTRO:
            return { ...state, filtro: action.filtro }
        case CLEAR_FIND_RESULT:
            return { ...state, findDistribuicaoProdutos: {} }
        case CLEAR_FIND_PRODUTO:
            return { ...state, produto: {} }
        default:
            return state
    }
}

export default reducer

export const changeFiltro = (filtro: string) => ({
    type: CHANGE_FILTRO,
    filtro,
})

export const findProduto = (registroAnvisa: string) => ({
    type: FIND_PRODUTO,
    promise: Report.getProduto(registroAnvisa),
})

export const findDistribuicaoProdutos = (filtro: DistribuicaoProdutosFiltro) => ({
    type: FIND_DIST_FILTER,
    promise: Report.findDistribuicaoProdutos(filtro),
})

export const clearFindResult = () => ({
    type: CLEAR_FIND_RESULT,
})

export const clearFindProduto = () => ({
    type: CLEAR_FIND_PRODUTO,
})
