import React from 'react'
import { RecebimentoIntegracaoDto } from 'backend'
import { RecebimentoIntegracaoRowHeader } from '../row/RecebimentoIntegracaoRowHeader'
import { RecebimentoIntegracaoInnerRowRecebido } from './RecebimentoIntegracaoInnerRowRecebido'

interface RecebimentoIntegracaoRowRecebidoProps {
    date: string
    value: RecebimentoIntegracaoDto[]
}

const groupRecebidosById = (recebidos: RecebimentoIntegracaoDto[]): Map<string, RecebimentoIntegracaoDto[]> => {
    let result = new Map<string, RecebimentoIntegracaoDto[]>()
    for (let recebido of recebidos) {
        if (result.has(recebido.identificadorExterno)) {
            let value: RecebimentoIntegracaoDto[] = result.get(recebido.identificadorExterno)
            value.push(recebido)
            result.set(recebido.identificadorExterno, value)
        } else {
            result.set(recebido.identificadorExterno, [recebido])
        }
    }
    return result
}

export const RecebimentoIntegracaoRowRecebido = (props: RecebimentoIntegracaoRowRecebidoProps) => {
    const { date, value } = props
    const groupedValue: Map<string, RecebimentoIntegracaoDto[]> = groupRecebidosById(value)
    return (
        <div className='recebidos-row'>
            <RecebimentoIntegracaoRowHeader date={date} lenghtInnerRows={value.length} />
            {Array.from(groupedValue.keys()).map((key: string, _: number) => (
                <RecebimentoIntegracaoInnerRowRecebido key={key} recebidos={groupedValue.get(key)} />
            ))}
        </div>
    )
}
