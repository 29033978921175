import { DescricaoPerfil, ImagemPerfil } from 'api/Perfis'
import { VinculoProfissionalRow } from 'backend'
import { Image, MaskedLabel, UserInitials } from 'libs/components'
import React from 'react'

interface VinculoProfissionalCardProps {
    vinculoProfissional: VinculoProfissionalRow
    showResponsavelLabel?: boolean
}

export const VinculoProfissionalCard: React.FC<VinculoProfissionalCardProps> = ({
    vinculoProfissional,
    showResponsavelLabel,
}) => {
    return (
        <div className='vinculo-profissional--card is-horizontal-flow'>
            <UserInitials size='medium' skin='2' nome={vinculoProfissional.nome} />
            <div className='vinculo-profissional--card-text has-text-left'>
                <label className='nome'>{vinculoProfissional.nome}</label>
                {showResponsavelLabel && <label className='responsavel'>RESPONSÁVEL</label>}
                <div className='is-horizontal-flow'>
                    <MaskedLabel
                        mask='###.###.###-##'
                        title='CPF: '
                        labelClassName='cpf'
                        value={vinculoProfissional.cpf}
                        horizontal
                    />
                    <label className='crm'>
                        <b>{`${vinculoProfissional.nomeConselhoClasse}: `}</b>
                        {`${vinculoProfissional.numeroRegistro} ${vinculoProfissional.ufRegistro}`}
                    </label>
                </div>
                <div>
                    <Image className='imagem-perfil' src={ImagemPerfil[vinculoProfissional.perfil]} size='16x16' />
                    <label className='especialidade'>{DescricaoPerfil[vinculoProfissional.perfil]}</label>
                </div>
            </div>
        </div>
    )
}
