import { LoadingStateContainer } from 'libs/components'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { State } from 'redux/modules'
import * as multiplosProcedimentos from 'redux/modules/report/multiplos'
import { MultiplosProcedimentosResultView } from './MultiplosProcedimentosResultView'
import { MultiplosProcedimentosRouteParams } from './MultiplosProcedimentosView'
import { useReportFilter } from '../hooks/useReportUrlParams'

export function MultiplosProcedimentosDashboard() {
    const dispatch = useDispatch()
    const match = useRouteMatch<MultiplosProcedimentosRouteParams>()

    const stats = useSelector((state: State) => state.report.multiplos.stats)

    const filtro = useReportFilter()
    React.useEffect(() => {
        dispatch(multiplosProcedimentos.findStats({ ...filtro, tipoCirurgia: match.params.tipoCirurgia }))
    }, [dispatch, filtro, match.params.tipoCirurgia])

    return (
        <LoadingStateContainer readyState={stats.readyState}>
            <MultiplosProcedimentosResultView
                title='Primária e revisão'
                mode='PRIMARIA_REVISAO'
                filtro={filtro}
                result={stats.data && stats.data.primariaRevisao}
            />
            <br />
            <br />
            <MultiplosProcedimentosResultView
                title='Revisão e revisão'
                mode='REVISAO_REVISAO'
                filtro={filtro}
                result={stats.data && stats.data.revisaoRevisao}
            />
        </LoadingStateContainer>
    )
}
