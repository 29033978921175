import * as React from 'react'
import * as C from 'libs/components'
import { FormFieldProps } from 'libs/components'

export const normalize = value => {
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length < 3) {
        return onlyNums
    } else {
        return onlyNums.slice(0, 2) + ':' + onlyNums.slice(2, 4)
    }
}

export interface HourFieldProps extends FormFieldProps {
    placeholder?: string
    name: string
}

export class HourField extends React.Component<HourFieldProps> {
    render() {
        return <C.TextField {...this.props} normalize={normalize} />
    }
}
