import React from 'react'
import { Switch, Route } from 'react-router-dom'
import HomeConteudo from 'view/componentes/HomeConteudo'
import CadastroProfissionalContainer from 'view/public/profissional/CadastroProfissionalContainer'
import ManualUsuarioContainer from '../home/manualUsuario/ManualUsuarioContainer'
import ConfirmacaoEmailContainer from './confirmacaoEmail/ConfirmacaoEmailContainer'

export default class PublicView extends React.Component {
    render() {
        return (
            <HomeConteudo>
                <Switch>
                    <Route path={`${this.props.match.url}/profissional`} component={CadastroProfissionalContainer} />
                    <Route
                        path={`${this.props.match.url}/confirmacao/:idCadastro`}
                        component={ConfirmacaoEmailContainer}
                    />
                    <Route
                        path={`${this.props.match.url}/manualUsuario/:idPai?/:idFilho?/:btn?`}
                        component={ManualUsuarioContainer}
                    />
                </Switch>
            </HomeConteudo>
        )
    }
}
