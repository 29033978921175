import pessoaNaoEncontrada from 'images/home/novoProcedimento/pessoa-nao-encontrada.svg'
import { Button, Column, Columns } from 'libs/components'
import React from 'react'
import { CadastroPacienteForm } from 'backend'
import { CardPaciente } from './CardPaciente'

export interface ResultadoBuscaProps {
    submitting: boolean
    paciente: CadastroPacienteForm
    erro: any
    tipoFiltro: string
    adicionarPaciente(): void
}

export const ResultadoBusca = ({ submitting, paciente, tipoFiltro, adicionarPaciente, erro }: ResultadoBuscaProps) => {
    if (submitting) {
        return <p>Buscando...</p>
    } else if (!paciente && !erro) {
        return (
            <p className='text-search has-text-centered'>
                Realize a busca pelo paciente preenchendo CPF
                <br /> ou CNS, depois aperte em pesquisar e confirme
                <br /> o paciente para avançar.
            </p>
        )
    } else if (erro === 404) {
        return (
            <div className='has-text-centered'>
                <img src={pessoaNaoEncontrada} alt='pessoanaoencontrada' />
                <br />
                <p className='text-search'>Nenhum paciente encontrado.</p>
                <br />
                <Button size='medium' onClick={adicionarPaciente}>
                    Adicionar paciente
                </Button>
            </div>
        )
    } else if (erro) {
        return (
            <div className='has-text-centered'>
                <p className='text-search'>
                    Não foi possível encontrar um paciente na base de dados do <br />
                    RNI com o {tipoFiltro} informado. Além disso, não conseguimos <br />
                    estabelecer uma conexão com o serviço do CadSUS. Clique no <br />
                    botão abaixo para cadastrar o paciente.
                </p>
                <br />
                <Button size='medium' onClick={adicionarPaciente}>
                    Adicionar paciente
                </Button>
            </div>
        )
    } else if (paciente) {
        return (
            <Columns>
                <Column>
                    <CardPaciente paciente={paciente} />
                </Column>
            </Columns>
        )
    }
    return null
}
