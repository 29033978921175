import { IconButton, Text, AlertModalDelete, Hint } from 'libs/components'
import React from 'react'
import { isImmutable } from 'immutable'
import { buildTiposRelacoesText } from 'redux/enums'

const handlePossibleImmutableValue = value => {
    return isImmutable(value) ? value.toJS() : value
}

interface RelacaoEmpresasListCardProps {
    relacaoEmpresa: any // any to avoid some boring castings
    onClickEdit: () => void
    onOkDelete: () => void
}

export const RelacaoEmpresasListCard: React.FC<RelacaoEmpresasListCardProps> = props => {
    const { relacaoEmpresa, onClickEdit, onOkDelete } = props

    const [isModalDeleteOpen, setModalDeleteOpen] = React.useState<boolean>(false)

    const handleOnOk = () => {
        setModalDeleteOpen(false)
        onOkDelete()
    }

    const isVigenciaIndeterminada: boolean = relacaoEmpresa.get('vigenciaIndeterminada')
    const mesFim = handlePossibleImmutableValue(relacaoEmpresa.get('mesFim'))
    const mesInicio = handlePossibleImmutableValue(relacaoEmpresa.get('mesInicio'))

    const ateValue: string = isVigenciaIndeterminada
        ? ' data indeterminada'
        : ` ${mesFim.id}/${relacaoEmpresa.get('anoFim')}`

    return (
        <>
            <AlertModalDelete active={isModalDeleteOpen} onClose={() => setModalDeleteOpen(false)} onOk={handleOnOk} />
            <div className='content--list-card'>
                <div className='list-card--text-column'>
                    <Text className='list-card--empresa-nome' color='black'>
                        {relacaoEmpresa.get('nomeEmpresa')}
                    </Text>
                    <Text className='list-card--tempo-relacao' color='black'>
                        Relação de
                        <span className='is-bold'>{` ${mesInicio.id}/${relacaoEmpresa.get('anoInicio')}`}</span> até
                        <span className='is-bold'>{ateValue}</span>
                    </Text>
                    <Text className='list-card--tipos-relacao' color='grey-darker'>
                        {buildTiposRelacoesText(relacaoEmpresa.get('relacoes'), ', ')}
                    </Text>
                </div>
                <div>
                    <Hint
                        placement='top'
                        componente={<IconButton className='list-card--icon' icon='editar' onClick={onClickEdit} />}
                    >
                        Editar relação
                    </Hint>
                    <Hint
                        placement='top'
                        componente={
                            <IconButton
                                className='list-card--icon'
                                icon='remover'
                                onClick={() => setModalDeleteOpen(true)}
                            />
                        }
                    >
                        Excluir relação
                    </Hint>
                </div>
            </div>
        </>
    )
}
