import { useAxiosGet } from 'hooks/useApiRequest'
import { LoadingStateContainer } from 'libs/components'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'redux/modules'
import { EdicaoRastreabilidadeState, setEdicaoProcedimentoData } from 'redux/modules/edicaoProcedimento'
import { RastreabilidadeGeralView } from '../rastreabilidade/geral/RastreabilidadeGeralView'

export const EdicaoRastreabilidade = () => {
    const idProced = useSelector<State, number>(state => state.edicao.idProcedimento)
    const { data, requestState } = useAxiosGet<EdicaoRastreabilidadeState>(
        '/api/rastreabilidade/rastreabilidadeById/' + idProced
    )
    const dispatch = useDispatch()
    useEffect(() => {
        if (requestState === 'success') {
            dispatch(setEdicaoProcedimentoData(data))
        }
    }, [requestState, dispatch, data])
    return (
        <LoadingStateContainer readyState={requestState}>
            <RastreabilidadeGeralView editMode />
        </LoadingStateContainer>
    )
}
