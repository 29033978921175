import * as React from 'react'
import * as C from 'libs/components'

interface RiscoFieldProps {
    name: string
}

export class RiscoField extends React.Component<RiscoFieldProps> {
    render() {
        return (
            <C.ControlField label='Risco' name={this.props.name} verticalFlow>
                <C.ClearableRadioField name={this.props.name} label='Alto' value='ALTO' />
                <C.ClearableRadioField name={this.props.name} label='Médio' value='MEDIO' />
                <C.ClearableRadioField name={this.props.name} label='Baixo' value='BAIXO' />
                <C.ClearableRadioField name={this.props.name} label='Não disponível' value='NAO_DISPONIVEL' />
            </C.ControlField>
        )
    }
}
