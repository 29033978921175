import { connect } from 'react-redux'
import * as alert from 'redux/modules/alert'
import ShowcaseView from 'view/home/showcase/ShowcaseView'

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = () => {
    return {}
}

const ShowcaseContainer = connect(
    mapStateToProps,
    alert.compose(mapDispatchToProps)
)(ShowcaseView)

export default ShowcaseContainer
