import * as React from 'react'
import * as C from 'libs/components'
import { FixedSelectProps } from '../elements/FixedSelect'

const options2 = (ano, mes, maxDia) => {
    const options = []
    let i
    const mesAtual = new Date().getMonth() + 1
    if (new Date().getFullYear() === ano && mesAtual === mes && maxDia) {
        for (i = 0; i < new Date().getDate(); i++) {
            options.push({ id: i + 1, nome: i + 1 })
        }
        return options
    }
    for (i = 0; i < new Date(ano, mes, 0).getDate(); i++) {
        options.push({ id: i + 1, nome: i + 1 })
    }
    return options
}

export interface DiaSelectProps extends Partial<FixedSelectProps> {
    ano: number
    mes: number

    /**
     * Indica se o dia, no mês e ano atual, vai até a o próprio dia ou exibirá todos os dias do mês.
     * Exemplo: data de hoje é 12/01/2000, se maxDia = true exibirá até 12, se for maxDia=false exibirá todos os dias do mẽs, até 31.
     */
    maxDia?: boolean
}

export class DiaSelect extends React.Component<DiaSelectProps> {
    render() {
        return <C.FixedSelect {...this.props} options={options2(this.props.ano, this.props.mes, this.props.maxDia)} />
    }
}
