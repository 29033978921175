import { AlertModalError, AlertModalSuccess } from 'libs/components'
import { Icons } from 'libs/components/presentational/elements/icons'
import { isPromise } from 'libs/util/PromiseUtil'
import React from 'react'
import { Prompt } from 'react-router-dom'
import { ConfigProps as ReduxFormConfig, InjectedFormProps } from 'redux-form'
import { reduxForm, SubmissionError } from 'redux-form/immutable'

export interface FormModalProps {
    active?: boolean
    icon?: Icons
    onClose: () => void
    title?: string
}

export interface ErrorModalProps extends FormModalProps {
    error?: any
}

export interface SuccessModalProps extends FormModalProps {
    result?: any
}

type CustomConfig = {
    errorIcon?: Icons
    errorModal?: React.SFC<ErrorModalProps> | React.ComponentClass<ErrorModalProps>
    hasErrorModal?: boolean
    hasLeaveModal?: boolean
    hasSuccessModal?: boolean
    successContent?: JSX.Element
    successIcon?: Icons
    successModal?: React.SFC<SuccessModalProps> | React.ComponentClass<SuccessModalProps>
    successTitle?: string
}

export type FormConfig<FormData, Props> = ReduxFormConfig<FormData, Props> & CustomConfig

export const defaultConfig: FormConfig<any, any> = {
    form: null,
    errorIcon: 'modalErro',
    errorModal: AlertModalError,
    hasErrorModal: true,
    hasLeaveModal: true,
    hasSuccessModal: true,
    successContent: <span>Os dados foram salvos corretamente.</span>,
    successIcon: 'modalSucesso',
    successModal: AlertModalSuccess,
    successTitle: 'Cadastro realizado com sucesso!',
}

type ExternalFormProps = {
    onSubmit: any
    onSubmitSuccess: any
    onSubmitFail: any
    errorResolver(error: any): CustomModalConfig
    onErrorSubmitting?: any
}

export type CustomModalConfig = {
    modalTitle: string
    modalContent: JSX.Element
}

export const defaultErrorResolver = (error: any): CustomModalConfig => {
    if (error && error._error) {
        return {
            modalTitle: 'Erro inesperado!',
            modalContent: <span>Erro inesperado na aplicação.</span>,
        }
    } else if (typeof error === 'string') {
        const errorJson = JSON.parse(error)
        return {
            modalTitle: errorJson.title,
            modalContent: <span>{errorJson.text}</span>,
        }
    }

    return {
        modalTitle: 'Preenchimento incorreto!',
        modalContent: <span>Alguns dados podem ter sido preenchidos incorretamente.</span>,
    }
}

export type FormNewProps<FormData, Props> = Partial<FormConfig<FormData, Props> & ExternalFormProps & Props>
export default function form<FormData, Props>(userConfig: FormConfig<FormData, Props>) {
    return <WrapperComponentsProps extends object>(WrappedComponent: React.ComponentType<WrapperComponentsProps>) => {
        const resultConfig: FormConfig<FormData, Props> = (Object as any).assign({}, defaultConfig, userConfig)

        const {
            errorIcon,
            errorModal: ErrorModal,
            hasErrorModal,
            hasLeaveModal,
            hasSuccessModal,
            successContent,
            successIcon,
            successModal: SuccessModal,
            successTitle,
            ...reduxFormConfig
        } = resultConfig

        const WrappedForm = reduxForm(reduxFormConfig)(
            class extends React.Component<
                InjectedFormProps<FormData, Props & WrapperComponentsProps> & Props & WrapperComponentsProps
            > {
                render() {
                    const { pristine, submitSucceeded } = this.props

                    return (
                        <div className='is-full-height is-vertical-flow'>
                            <Prompt
                                when={hasLeaveModal && !pristine && !submitSucceeded}
                                message='mensagem não usada'
                            />
                            <WrappedComponent {...this.props} />
                        </div>
                    )
                }
            }
        )

        return class extends React.Component<FormNewProps<FormData, Props>, FormState> {
            constructor(props) {
                super(props)
                this.state = {
                    error: undefined,
                    result: undefined,
                    modalErrorActive: false,
                    modalSuccessActive: false,
                }
            }

            localOnSubmit = e => {
                const promise = this.props.onSubmit(e)
                let errors
                if (isPromise(promise)) {
                    return promise.catch(error => {
                        if (this.props.onErrorSubmitting) {
                            errors = this.props.onErrorSubmitting(error.response.data.errors)
                        } else {
                            errors = error.response.data.errors
                        }
                        if (error.response.status === 400) {
                            throw new SubmissionError(errors)
                        } else {
                            throw new SubmissionError({ _error: error.response.data })
                        }
                    })
                }
                return promise
            }

            localOnSubmitFail = error => {
                if (hasErrorModal) {
                    this.setState({
                        error: error,
                        modalErrorActive: true,
                    })
                } else {
                    this.props.onSubmitFail && this.props.onSubmitFail(error)
                }
            }

            localOnSubmitSuccess = result => {
                if (hasSuccessModal) {
                    this.setState({
                        result: result,
                        modalSuccessActive: true,
                    })
                } else {
                    this.props.onSubmitSuccess && this.props.onSubmitSuccess(result)
                }
            }

            closeError = () => {
                this.props.onSubmitFail && this.props.onSubmitFail(this.state.error)
                this.setState({
                    error: undefined,
                    modalErrorActive: false,
                })
            }

            closeSuccess = () => {
                this.props.onSubmitSuccess && this.props.onSubmitSuccess(this.state.result)
                this.setState({
                    modalSuccessActive: false,
                    result: undefined,
                })
            }

            resolveError = () => {
                if (this.props.errorResolver) {
                    return this.props.errorResolver(this.state.error)
                }
                return defaultErrorResolver(this.state.error)
            }

            render() {
                const { onSubmit, onSubmitFail, onSubmitSuccess, ...rest } = this.props as any
                let { modalTitle, modalContent } = this.resolveError()
                return (
                    <div className='is-full-height is-vertical-flow'>
                        <WrappedForm
                            {...rest}
                            onSubmit={this.localOnSubmit}
                            onSubmitFail={this.localOnSubmitFail}
                            onSubmitSuccess={this.localOnSubmitSuccess}
                        />
                        <ErrorModal
                            active={this.state.modalErrorActive}
                            error={this.state.error}
                            onClose={this.closeError}
                            icon={errorIcon}
                            title={modalTitle}
                        >
                            {modalContent}
                        </ErrorModal>
                        <SuccessModal
                            active={this.state.modalSuccessActive}
                            onClose={this.closeSuccess}
                            icon={successIcon}
                            result={this.state.result}
                            title={successTitle}
                        >
                            {successContent}
                        </SuccessModal>
                    </div>
                )
            }
        }
    }
}

type FormState = {
    error: any
    result: any
    modalErrorActive: boolean
    modalSuccessActive: boolean
}
