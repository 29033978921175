import * as React from 'react'
import { SimpleSelectField, Text } from 'libs/components'
import { ComponenteComboFiltro, ComponenteUtilizadoDto } from 'backend'
import * as enums from 'redux/enums'
import { FormatOptionLabelMeta } from 'react-select/src/Select'

export class ComponenteSelectFieldProps {
    filtro?: ComponenteComboFiltro
}

export class ComponenteSelectField extends React.Component<ComponenteSelectFieldProps> {
    render() {
        return (
            <SimpleSelectField
                isClearable
                name='componente'
                url='/api/procedimentos/componentes'
                customizeParams={() => this.props.filtro}
                formatOptionLabel={(
                    option: ComponenteUtilizadoDto,
                    meta: FormatOptionLabelMeta<ComponenteUtilizadoDto>
                ) => {
                    const { nome, tipoProcedimento, tipoArtroplastia } = option
                    return meta.context === 'menu' ? (
                        <div className='spacing-one-half is-small'>
                            <p>
                                <Text color='black'>{nome}</Text>
                            </p>
                            <p className='is-small-ter'>
                                <Text weight='semibold' color='black-ter'>
                                    Tipo procedimento:{' '}
                                </Text>
                                <Text>{enums.tipoProcedimentoName[tipoProcedimento]}</Text>
                            </p>
                            {tipoArtroplastia && (
                                <p className='is-small-ter'>
                                    <Text weight='semibold' color='black-ter'>
                                        Tipo de artroplastia:{' '}
                                    </Text>
                                    <Text>{enums.tipoArtroplastiaName[tipoArtroplastia]}</Text>
                                </p>
                            )}
                        </div>
                    ) : (
                        <p>{nome}</p>
                    )
                }}
            />
        )
    }
}
