import * as React from 'react'
import { InfoProdutoRow } from 'backend'
import { Table, TableColumnHeader, Text, PercentageLabel, LinkIconButton } from 'libs/components'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { TempoLabel } from '../components/TempoLabel'

export interface Props extends RouteComponentProps<any> {
    rows: InfoProdutoRow[]
}

class InfoProdutoTableComponent extends React.Component<Props> {
    render() {
        const { rows, match, location } = this.props

        return (
            <Table values={rows} className='is-small-bis is-spaced'>
                <TableColumnHeader
                    title='Registro'
                    render={(row: InfoProdutoRow) => {
                        return <Text weight='semibold'>{row.registroAnvisa}</Text>
                    }}
                />
                <TableColumnHeader
                    title='Nome'
                    render={(row: InfoProdutoRow) => {
                        return <Text>{row.nome}</Text>
                    }}
                />
                <TableColumnHeader
                    title='Revisões'
                    className='has-text-centered'
                    render={(row: InfoProdutoRow) => {
                        const quocient = row.countRevisoes / row.countProcedimentos
                        const className =
                            (quocient < 0.15 && 'is-neon') || (quocient < 0.5 && 'is-yellow-darker') || 'is-red'
                        return (
                            <Text className={className} weight='semibold'>
                                <PercentageLabel value={quocient} />
                            </Text>
                        )
                    }}
                />
                <TableColumnHeader
                    title='Tempo médio'
                    className='has-text-centered'
                    style={{ width: '120px' }}
                    render={(row: InfoProdutoRow) => {
                        return (
                            <Text weight='semibold'>
                                {row.mesesEntreProcedimentos !== null ? (
                                    <TempoLabel meses={row.mesesEntreProcedimentos} abbrev />
                                ) : (
                                    '-'
                                )}
                            </Text>
                        )
                    }}
                />
                <TableColumnHeader
                    title=''
                    className='has-text-right row-link'
                    style={{ width: '10px' }}
                    render={(row: InfoProdutoRow) => {
                        return (
                            <LinkIconButton
                                data-name='botao_detalhe_produto'
                                to={{
                                    pathname: `${match.url}/${row.registroAnvisa}`,
                                    search: location.search,
                                }}
                                icon='arrowRight'
                            />
                        )
                    }}
                />
            </Table>
        )
    }
}

export const InfoProdutoTable = withRouter(InfoProdutoTableComponent)
