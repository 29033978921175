
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.74,"time":44400,"words":148}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Médicos e médicos residentes podem informar a existência ou não de possíveis relações com fabricantes, importadores, distruibuidores e/ou forneceres de dispositivos médicos.`}</p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Na tela inicial clique no ícone `}<img parentName="li" {...{
          "src": "/images/iconeMenu.png#center",
          "alt": "..."
        }}></img>{` e depois selecione “Atualizar relações com empresas”`}</li>
      <li parentName="ol">{`No campo "Você possui/possuiu relação com empresas de produtos médicos?":`}</li>
    </ol>
    <p><strong parentName="p">{`NÃO POSSUI/POSSUIU RELAÇÃO`}</strong></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Clique na opção `}<strong parentName="li">{`Não`}</strong>{`, caso não possua relação`}</li>
      <li parentName="ol">{`Clique no botão “Salvar”`}</li>
    </ol>
    <p><strong parentName="p">{`POSSUA/POSSUIU RELAÇÃO`}</strong></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Clique na opção `}<strong parentName="li">{`Sim`}</strong>{`, caso possua relação`}</li>
      <li parentName="ol">{`Clique em “Adicionar relação”`}</li>
      <li parentName="ol">{`Preencha o campo “Nome da empresa”`}</li>
      <li parentName="ol">{`Selecione a(s) relação(ões) no campo “Tipos de relações”`}</li>
      <li parentName="ol">{`Informe o “Período de vigência”`}</li>
      <li parentName="ol">{`Clique no botão “Adicionar relação”`}</li>
      <li parentName="ol">{`Clique no botão “Salvar”`}</li>
    </ol>
    <p>{`Caso necessário, a relação pode ser editada através do botão “Editar relação”, na respectiva relação.`}</p>
    <p>{`A relação também pode ser excluída por meio do botão “Excluir relação”, na respectiva relação.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

