import React from 'react'
import * as C from 'libs/components'

const convenios: C.FixedSelectFieldOption[] = [
    { id: 'OPERADORA_SAUDE', nome: 'Operadora de saúde' },
    { id: 'PARTICULAR', nome: 'Particular' },
    { id: 'SUS', nome: 'SUS' },
]

interface Props {
    name: string
}

export class ConvenioField extends React.Component<Props, any> {
    render() {
        return <C.FixedSelectField {...this.props} options={convenios} />
    }
}
