import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import DistAngioplastiaContainer from 'view/home/anvisa/reports/angioplastia/DistAngioplastiaContainer'
import ReportVisualizacaoFicha from 'view/componentes/reports/ReportVisualizacaoFicha'
import DadosFinanceirosContainer from 'view/home/anvisa/reports/financeiro/DadosFinanceirosContainer'
import AlertasContainer from 'view/home/anvisa/alertas/AlertasContainer'
import AlertasConfigContainer from 'view/home/anvisa/alertas/AlertasConfigContainer'
import BuscaProcedimentoRootContainer from 'view/componentes/buscaProcedimento/BuscaProcedimentoRootContainer'
import DistProdutosContainer from 'view/home/anvisa/reports/produtos/DistProdutosContainer'
import DistComponenteProcedimentoContainer from 'view/home/anvisa/reports/produtos/DistComponenteProcedimentoContainer'
import DistComponenteBrasilContainer from 'view/home/anvisa/reports/produtos/DistComponenteBrasilContainer'
import HomeGestorSNVSContainer from './HomeGestorSNVSContainer'
import MotivosProcedimentoDashboardContainer from '../anvisa/reports/motivos/MotivosProcedimentoDashboardContainer'
import AntibioticosContainer from '../anvisa/antibioticos/AntibioticosContainer'
import AdicionarEntidadeView from '../anvisa/entidades/AdicionarEntidadeView'
import EdicaoEntidadeView from '../anvisa/entidades/EdicaoEntidadeView'
import EntidadesView from '../anvisa/entidades/EntidadesView'
import VisualizarEntidadeView from '../anvisa/entidades/VisualizarEntidadeView'
import InfoProdutoDetailContainer from '../anvisa/reports/info-produto/InfoProdutoDetailContainer'
import InfoProdutoListContainer from '../anvisa/reports/info-produto/InfoProdutoListContainer'
import MotivosProcedimentoContainer from '../anvisa/reports/motivos/MotivosProcedimentoContainer'
import { MultiplosProcedimentosView } from './reports/multiplos/MultiplosProcedimentosView'
import SumarioPacienteContainer from '../anvisa/reports/paciente/SumarioPacienteContainer'
import RastreabilidadeReportFilterContainer from '../anvisa/reports/rastreabilidade/RastreabilidadeReportFilterContainer'
import RelacaoEmpresasReportView from '../anvisa/reports/relacaoEmpresas/RelacaoEmpresasReportView'
import { AnvisaTipoRevisaoDashboardView } from '../anvisa/reports/revisao/AnvisaTipoRevisaoDashboardView'
import { AnvisaTipoRevisaoView } from '../anvisa/reports/revisao/AnvisaTipoRevisaoView'
import UsoSistemaReportView from '../anvisa/reports/usoSistema/UsoSistemaReportView'
import StentsContainer from '../anvisa/stents/StentsContainer'
import MultiplosPacientesContainerSNVS from './reports/multiplos/MultiplosPacientesContainerSNVS'
import RastreabilidadeSNVSReportContainer from './reports/rastreabilidade/RastreabilidadeSNVSReportContainer'
import ProcedimentosHospitalContainer from '../anvisa/reports/procedimentos/ProcedimentosHospitalContainer'
import DistArtroplastiaContainer from '../anvisa/reports/artroplastia/DistArtroplastiaContainer'
import ListaPacientesContainer from '../anvisa/reports/paciente/ListaPacientesContainer'
import TipoRevisaoProdutoDetailContainer from '../anvisa/reports/produtos/TipoRevisaoProdutoDetailContainer'

export class HomeGestorSNVS extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path={'/home'} component={HomeGestorSNVSContainer} />

                <Route path={'/home/buscaProcedimentos'} component={BuscaProcedimentoRootContainer} />

                <Route path={'/home/relatorios/artroplastia'} component={DistArtroplastiaContainer} />
                <Route path={'/home/relatorios/angioplastia'} component={DistAngioplastiaContainer} />
                <Route
                    exact
                    path={'/home/relatorios/procedimentos/hospitais'}
                    component={ProcedimentosHospitalContainer}
                />

                <Route exact path={'/home/relatorios/motivos'} component={MotivosProcedimentoContainer} />
                <Route
                    exact
                    path={'/home/relatorios/motivos/:motivo'}
                    component={MotivosProcedimentoDashboardContainer}
                />

                <Route
                    exact
                    path={'/home/relatorios/rastreabilidade'}
                    component={RastreabilidadeReportFilterContainer}
                />
                <Route path={'/home/relatorios/rastreabilidade/visualizacao'} component={ReportVisualizacaoFicha} />
                <Route path={'/home/relatorios/listaPacientes/visualizacao'} component={ReportVisualizacaoFicha} />
                <Route
                    path={'/home/relatorios/rastreabilidade/registro/:registroAnvisa/paciente/:pacienteId/visualizacao'}
                    component={ReportVisualizacaoFicha}
                />
                <Route
                    path={'/home/relatorios/rastreabilidade/registro/:registroAnvisa/paciente/:pacienteId'}
                    component={SumarioPacienteContainer}
                />
                <Route
                    path={'/home/relatorios/rastreabilidade/registro/:registroAnvisa'}
                    component={RastreabilidadeSNVSReportContainer}
                />

                <Route
                    path={
                        '/home/relatorios/multiplos/:tipoCirurgia/dashboard/:procedimentoMode/:tempoRevisao/pacientes/list/:pacienteId/visualizacao'
                    }
                    component={ReportVisualizacaoFicha}
                />
                <Route
                    path={
                        '/home/relatorios/multiplos/:tipoCirurgia/dashboard/:procedimentoMode/:tempoRevisao/pacientes/list/:pacienteId'
                    }
                    component={SumarioPacienteContainer}
                />
                <Route
                    path={
                        '/home/relatorios/multiplos/:tipoCirurgia/dashboard/:procedimentoMode/:tempoRevisao/pacientes'
                    }
                    component={MultiplosPacientesContainerSNVS}
                />
                <Route path={'/home/relatorios/multiplos/:tipoCirurgia'} component={MultiplosProcedimentosView} />

                <Route exact path={'/home/relatorios/financeiro'} component={DadosFinanceirosContainer} />

                <Route exact path={'/home/relatorios/info-produto'} component={InfoProdutoListContainer} />
                <Route
                    exact
                    path={'/home/relatorios/info-produto/:registroAnvisa'}
                    component={InfoProdutoDetailContainer}
                />

                <Route exact path={'/home/alarmes'} component={AlertasContainer} />
                <Route exact path={'/home/alarmes/detalhe/:id'} component={AlertasContainer} />
                <Route path={'/home/alarmes/visualizacaoProcedimento'} component={ReportVisualizacaoFicha} />
                <Route path={'/home/alarmes/configurar'} component={AlertasConfigContainer} />

                <Route exact path={'/home/relatorios/tipoRevisao'} component={AnvisaTipoRevisaoView} />
                <Route
                    exact
                    path={'/home/relatorios/tipoRevisao/:tipoRevisao'}
                    component={AnvisaTipoRevisaoDashboardView}
                />
                <Route
                    exact
                    path={'/home/relatorios/tipoRevisao/:tipoRevisao/registro/:registroAnvisa'}
                    component={TipoRevisaoProdutoDetailContainer}
                />
                <Route
                    exact
                    path={
                        '/home/relatorios/tipoRevisao/:tipoRevisao/identificador/:identificadorDispositivo/codigo/:referencia'
                    }
                    component={TipoRevisaoProdutoDetailContainer}
                />

                <Route exact path={'/home/relatorios/produtos'} component={DistProdutosContainer} />
                <Route
                    exact
                    path={'/home/relatorios/produtos/componente/:idComponente/:tipoProcedimento'}
                    component={DistComponenteProcedimentoContainer}
                />
                <Route
                    exact
                    path={
                        '/home/relatorios/produtos/componente/:idComponente/:tipoProcedimento/registro/:registroAnvisa'
                    }
                    component={DistComponenteBrasilContainer}
                />
                <Route path={'/home/relatorios/usoSistema'} component={UsoSistemaReportView} />
                <Route exact path={'/home/stents'} component={StentsContainer} />
                <Route exact path={'/home/antibioticos'} component={AntibioticosContainer} />
                <Route path={'/home/entidades/adicionarEntidade'} component={AdicionarEntidadeView} />
                <Route path={'/home/entidades/editarEntidade/:id'} component={EdicaoEntidadeView} />
                <Route path={'/home/entidades/visualizarEntidade/:id'} component={VisualizarEntidadeView} />
                <Route path={'/home/entidades'} component={EntidadesView} />
                <Route path={'/home/relatorios/relacaoMedicoEmpresa'}>
                    <RelacaoEmpresasReportView />
                </Route>
                <Route exact path={'/home/relatorios/listaPacientes'} component={ListaPacientesContainer} />
            </Switch>
        )
    }
}
