import React from 'react'
import * as C from 'libs/components'

const introdutorOptions = [
    { id: 'QUATRO_F', nome: '4F' },
    { id: 'CINCO_F', nome: '5F' },
    { id: 'SEIS_F', nome: '6F' },
    { id: 'SETE_F', nome: '7F' },
    { id: 'OITO_F', nome: '8F' },
    { id: 'OUTRO', nome: 'Outro' },
]

export class IntrodutorField extends React.Component {
    render() {
        return <C.FixedSelectField {...this.props} options={introdutorOptions} />
    }
}
