import * as React from 'react'
import classnames from 'classnames'
import * as H from 'history'
import { Link } from 'react-router-dom'
import { Icon } from 'libs/components'
import { Icons } from './icons'

export interface LinkIconButtonProps {
    to: H.LocationDescriptor
    icon: Icons
    replace?: boolean
    className?: string
}

export class LinkIconButton extends React.Component<LinkIconButtonProps> {
    render() {
        const { className, icon, ...rest } = this.props

        return (
            <Link {...rest} className={classnames('icon-button', 'button', className)}>
                <Icon icon={icon} />
            </Link>
        )
    }
}
