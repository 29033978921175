import { FieldProps } from 'libs/redux-form/field'
import * as React from 'react'
import { TextField, TextFieldProps } from './TextField'

export const onlyNumbers = value => value.replace(/[^\d]/g, '')

export interface NumberFieldProps extends TextFieldProps, FieldProps {
    name: string
    maxLength?: number
    disabled?: boolean
    testid?: string
}

export const NumberField = (props: NumberFieldProps) => <TextField normalize={onlyNumbers} {...props} />
