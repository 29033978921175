import * as React from 'react'
import { DadosCirurgiaVisualizacaoStentCoronariaDto } from 'backend'
import * as C from 'libs/components'
import * as FC from 'view/home/novoProcedimento/ficha/componentes'

interface DadosCirurgiaCardioProps {
    dadosCirurgia: DadosCirurgiaVisualizacaoStentCoronariaDto
}

export class DadosCardio extends React.Component<DadosCirurgiaCardioProps> {
    render() {
        const dados = this.props.dadosCirurgia
        return (
            <C.Conteudo>
                <C.Label className='with-border-bellow'>Lesões tratadas</C.Label>
                {dados.lesoesNativas.map(element => (
                    <FC.LesoesTratadasCard key={element.nome} lesao={element} />
                ))}
                {dados.lesoesEnxerto.map(element => (
                    <FC.LesoesTratadasCard key={element.nome} lesao={element} />
                ))}

                {dados.stents.length > 0 && (
                    <div>
                        <C.Label className='with-border-bellow'>Stents implantados com sucesso </C.Label>
                        {dados.stents.map((element, index) => (
                            <FC.StentsUtilizadosCard key={element.nome + index} stent={element} />
                        ))}
                    </div>
                )}

                {dados.stentsFalha.length > 0 && (
                    <div>
                        <C.Label className='with-border-bellow'>Stents implantados sem sucesso </C.Label>
                        {dados.stentsFalha.map((element, index) => (
                            <FC.StentsFalhosCard key={element.nome + index} stent={element} />
                        ))}
                    </div>
                )}

                {dados.essencial.map((element, idx) => {
                    return (
                        (element.quadroClinico || element.padraoObstrutivo) && (
                            <div key={idx}>
                                <C.Label className='with-border-bellow'>Apresentação clínica</C.Label>
                                <FC.ApresentacaoClinicaCard dadosComplementares={element} />
                            </div>
                        )
                    )
                })}

                <C.Label className='with-border-bellow'>Teste de isquemia/imagem</C.Label>
                {dados.essencial.map((element, idx) => (
                    <FC.IsquemiaImagemCard key={idx} dadosComplementares={element} />
                ))}
            </C.Conteudo>
        )
    }
}
