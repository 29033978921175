
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.3,"time":18000,"words":60}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "sem-etiqueta-anvisa"
    }}>{`Sem etiqueta ANVISA`}</h3>
    <ol>
      <li parentName="ol">{`Faça login no RNI. `}</li>
      <li parentName="ol">{`Na tela inicial selecione o Relatório de “Rastreabilidade de produtos”.`}</li>
      <li parentName="ol">{`Selecione a opção “Etiquetas sem registro”`}</li>
      <li parentName="ol">{`O RNI apresenta uma lista de todos os produtos registrados pelos hospitais em que foi informado que a embalagem não possuia o Registro da ANVISA.`}</li>
      <li parentName="ol">{`Selecione o produto que deseja visualizar.`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "sem-etiqueta-anvisa",
    level: 3,
    title: "Sem etiqueta ANVISA",
    children: [
        
      ]
  }
]

export const frontMatter = {}

