import Integracao from 'api/Integracao'
import { RecebimentoIntegracaoDto } from 'backend'
import { Paginator, Label } from 'libs/components'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'redux/modules'
import { Page, PageParams, PageRequester } from 'redux/requester'
import { groupRecebimentoContentByDate } from '../../common/groupRecebimentoContentByDate'
import { serverMoment } from 'libs/util/DateUtil'
import { RecebimentoIntegracaoRowRecebido } from './recebidos/RecebimentoIntegracaoRowRecebido'

const requester = new PageRequester<RecebimentoIntegracaoDto, PageParams>('recebidosIntegracao', params =>
    Integracao.getDadosRecebidos(params)
)

export const RecebimentoIntegracaoPageRecebidos = () => {
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(requester.request())
        return () => dispatch(requester.clearResult())
    }, [dispatch])

    const handlePageChange = (page: number) => {
        dispatch(requester.setPageNumber(page))
        dispatch(requester.request())
    }

    const isFetching = useSelector<State, boolean>(state => requester.getIsFetching(state))
    const result = useSelector<State, Page<RecebimentoIntegracaoDto>>(state => requester.getResult(state))
    const hasData: boolean = result?.numberOfElements !== undefined && result?.numberOfElements !== 0

    let groupedResult: Map<string, RecebimentoIntegracaoDto[]>
    if (hasData) {
        groupedResult = groupRecebimentoContentByDate(result.content)
    }

    return (
        <div className='tab-page'>
            {isFetching ? (
                <Label className='integracao-value-request'>Carregando...</Label>
            ) : (
                <>
                    {hasData ? (
                        <>
                            {Array.from(groupedResult.keys()).map((key: string, _: number) => (
                                <RecebimentoIntegracaoRowRecebido
                                    key={serverMoment(key).unix()}
                                    date={key}
                                    value={groupedResult.get(key)}
                                />
                            ))}
                            <Paginator
                                first={result.first}
                                last={result.last}
                                pageNumber={result.number}
                                totalPages={result.totalPages}
                                onChange={handlePageChange}
                            />
                        </>
                    ) : (
                        <span className='integracao-empty-label is-italic'>Nenhum dado recebido.</span>
                    )}
                </>
            )}
        </div>
    )
}
