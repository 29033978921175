
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.51,"time":30600,"words":102}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI.`}</li>
      <li parentName="ol">{`Na tela inicial selecione o Relatório que deseja visualizar.`}</li>
      <li parentName="ol">{`Caso o mesmo permita uma visualização por período, selecione o combo de período.`}</li>
      <li parentName="ol">{`Por padrão o RNI já oferece um acesso rápido a alguns filtros de período. Caso deseje utilizar um deles, basta selecioná-lo e clicar em “Aplicar”.`}</li>
      <li parentName="ol">{`Caso nenhum dos filtros rápidos atenda a sua necessidade, informe as datas de início e fim do período que deseja filtrar os dados.`}</li>
      <li parentName="ol">{`Clique em “Aplicar”.`}</li>
      <li parentName="ol">{`O RNI irá filtrar os dados dos relatórios em relação ao período informado.`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/images/comboDePeriodo.png",
        "alt": "Combo de seleção de período"
      }}></img></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

