import * as React from 'react'
import * as C from 'libs/components'
import { AlertItem } from 'redux/modules/alert'

export interface AlertBarItemProps {
    item: AlertItem
    removeAlert: (uuid: string) => void
}

export class AlertBarItem extends React.Component<AlertBarItemProps> {
    componentDidMount() {
        if (this.props.item.duration && this.props.item.duration > 0) {
            setTimeout(() => {
                this.onRemoveAlert()
            }, this.props.item.duration)
        }
    }

    render() {
        return (
            <div className={'alertbaritem ' + this.props.item.tipo}>
                <span className='icon'>
                    <C.Icon icon={this.props.item.icon} />
                </span>
                <p>{this.props.item.mensagem}</p>
                <span className='close' onClick={this.onRemoveAlert.bind(this)}>
                    &times;
                </span>
            </div>
        )
    }

    onRemoveAlert() {
        this.props.removeAlert(this.props.item.uuid)
    }
}
