import * as React from 'react'
import { NumberLabel } from './NumberLabel'
import * as C from 'libs/components'

export class CurrencyLabelProps {
    number?: number
    title?: string
}

export class CurrencyLabel extends React.Component<CurrencyLabelProps> {
    render() {
        return (
            <C.ComposedLabel title={this.props.title} placeholder='Não informado'>
                {this.props.number && (
                    <span>
                        R$ <NumberLabel value={this.props.number} minDecimalPlaces={2}></NumberLabel>
                    </span>
                )}
            </C.ComposedLabel>
        )
    }
}
