/* tslint:disable */
import React from 'react'

const SvgDadosproduto = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 36 36' {...props}>
        <path d='M18.125 4L6 11v14l12.125 7 12.124-7V11L18.125 4zm4.67 3.983l-10.454 6.035-4.671-2.696 10.455-6.037 4.67 2.698zm5.785 3.339l-10.455 6.035-4.672-2.696 10.455-6.036 4.672 2.697zm-16.796 7.477l1.114.643v-3.818l4.67 2.697v12.072L7.112 24.358V12.285l4.67 2.697v3.817zm17.351 5.559l-10.454 6.035V18.321l10.454-6.036v12.073z' />
    </svg>
)

export default SvgDadosproduto
