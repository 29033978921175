import { CadastroEntidadeConsumidoraForm } from 'backend'
import { FixedSelectOption } from 'libs/components'
import periodoVigenciaFormValidator from 'libs/components/presentational/form/composed/PeriodoVigenciaFormValidator'
import immutableProxy from 'libs/immutableProxy'
import { momentFromYYYYM } from 'libs/util/DateUtil'
import { alfaNumerico, cnpj, createValidator, email, maxLength, required } from 'libs/validation'
import moment, { Moment } from 'moment'
import { isImmutable } from 'immutable'

const AdicionarEntidadesValidator = createValidator(
    {
        nomeEntidade: [required, maxLength(120), alfaNumerico],
        emailEntidade: [required, email],
        cnpj: [cnpj],
    },
    formValidator
)

export function formValidator(formMap) {
    let errors: any = {}
    const form = immutableProxy<CadastroEntidadeConsumidoraForm>(formMap)

    const requiredMessage = 'É necessário preencher ao menos um dos campos abaixo'

    if (!form.esferaAcesso.get()) {
        errors.esferaAcessoSection = 'Selecione uma esfera de acesso'
    }

    if (form.esferaAcesso.get() === 'ESTADUAL' && (!form.ufsAcesso.get() || form.ufsAcesso.get().length === 0)) {
        errors.esferaAcesso = requiredMessage
    }

    if (
        form.esferaAcesso.get() === 'MUNICIPAL' &&
        (!form.municipiosAcesso.get() || form.municipiosAcesso.get().length === 0)
    ) {
        errors.esferaAcesso = 'É necessário adicionar ao menos um município!'
    }

    if (
        form.esferaAcesso.get() === 'HOSPITAL' &&
        (!form.hospitaisAcesso.get() || form.hospitaisAcesso.get().length === 0)
    ) {
        errors.esferaAcesso = 'É necessário adicionar ao menos um hospital!'
    }

    if (form.stHospital.get() === true) {
        if ((!form.dadosHospital.get() || form.dadosHospital.get().length === 0) && !form.enderecoHospitalAtivo.get()) {
            errors.stHospital = requiredMessage
        }

        if (form.enderecoHospitalAtivo.get() === true && !form.enderecoHospital.get()) {
            errors.stHospital = 'Selecione o tipo de endereço'
        }
    }

    if (form.stPaciente.get() === true) {
        if ((!form.dadosPaciente.get() || form.dadosPaciente.get().length === 0) && !form.enderecoPacienteAtivo.get()) {
            errors.stPaciente = requiredMessage
        }

        if (form.enderecoPacienteAtivo.get() === true && !form.enderecoPaciente.get()) {
            errors.stPaciente = 'Selecione o tipo de endereço'
        }
    }

    if (form.stMedicos.get() === true) {
        if ((!form.dadosMedicos.get() || form.dadosMedicos.get().length === 0) && !form.enderecoMedicoAtivo.get()) {
            errors.stMedicos = requiredMessage
        }

        if (form.enderecoMedicoAtivo.get() === true && !form.enderecoMedicos.get()) {
            errors.stMedicos = 'Selecione o tipo de endereço'
        }
    }

    if (form.stAngioStent.get() === true && (!form.dadosAngioStent.get() || form.dadosAngioStent.get().length === 0)) {
        errors.stAngioStent = requiredMessage
    }

    if (
        form.stArtroJoelho.get() === true &&
        (!form.dadosPrimariaJoelho.get() || form.dadosPrimariaJoelho.get().length === 0) &&
        (!form.dadosRevisaoJoelho.get() || form.dadosRevisaoJoelho.get().length === 0)
    ) {
        errors.stArtroJoelho = requiredMessage
    }

    if (
        form.stArtroQuadril.get() === true &&
        (!form.dadosPrimariaQuadril.get() || form.dadosPrimariaQuadril.get().length === 0) &&
        (!form.dadosRevisaoQuadril.get() || form.dadosRevisaoQuadril.get().length === 0)
    ) {
        errors.stArtroQuadril = requiredMessage
    }

    if (
        form.stDadosProduto.get() === true &&
        (!form.dadosProdutoIdentComponente.get() || form.dadosProdutoIdentComponente.get().length === 0) &&
        (!form.dadosProdutoIdentAnvisa.get() || form.dadosProdutoIdentAnvisa.get().length === 0)
    ) {
        errors.stDadosProduto = requiredMessage
    }

    if (
        form.stDadosFinanceiros.get() === true &&
        (!form.dadosFinanceiros.get() || form.dadosFinanceiros.get().length === 0)
    ) {
        errors.stDadosFinanceiros = requiredMessage
    }

    const periodoVigenciaErrors = periodoVigenciaFormValidator(form)

    errors = { ...errors, ...periodoVigenciaErrors }

    /*
     * Adiciona mais uma verificação relacionado ao form de período de vigência
     * Faz verificação para checar se a data final do período vigência é maior que a data atual
     */
    if (!errors.periodoVigencia && !form.vigenciaIndeterminada.get()) {
        const anoFimValue: string = form.anoFim.get() ? String(form.anoFim.get()) : undefined
        const mesFim: FixedSelectOption = form.mesFim.get()
        const mesFimValue = isImmutable(mesFim) ? mesFim.get('id') : mesFim?.id

        const dataAtual = moment()
        const dataFim: Moment = momentFromYYYYM(anoFimValue, mesFimValue)

        if (dataFim.diff(dataAtual, 'months') < 0) {
            errors.periodoVigencia = errors.mesFim = errors.anoFim =
                'A data final do período de vigência não pode ser anterior à data atual'
        }
    }

    return errors
}

export default AdicionarEntidadesValidator
