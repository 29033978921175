import React from 'react'
import { Icon, Text } from 'libs/components'
import classNames from 'classnames'

interface AvisoProps {
    color?: 'red' | 'grey'
    style?: React.CSSProperties
}

const Aviso: React.FC<AvisoProps> = props => {
    return (
        <div style={props.style} className={classNames('aviso', `aviso-${props.color || 'grey'}`)}>
            <Icon className='aviso--icon' icon='notificacaoAlertaComBorda' size='is-20px' />
            <div className='aviso--texts'>
                <Text className='aviso--text' color='black-bis'>
                    {props.children}
                </Text>
            </div>
        </div>
    )
}

export default Aviso
