import axios from 'axios'
import { AlertaMotivoRevisaoConfigForm, MarcarComoLidoForm } from 'backend'

class Alerta {
    public getConfig() {
        return axios.get('/api/alerta/config')
    }

    public getCountNaoLidos() {
        return axios.get('/api/alerta/countNaoLidos')
    }

    public saveConfigMotivoRevisaoJoelho(form: AlertaMotivoRevisaoConfigForm) {
        return axios.post('/api/alerta/config/motivoRevisaoJoelho', form)
    }

    public saveConfigMotivoRevisaoQuadril(form: AlertaMotivoRevisaoConfigForm) {
        return axios.post('/api/alerta/config/motivoRevisaoQuadril', form)
    }

    public marcarComoLido(form: MarcarComoLidoForm) {
        return axios.post('/api/alerta/marcarComoLido', form)
    }
}

export default new Alerta()
