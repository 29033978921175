import classnames from 'classnames'

export interface UtilProps {
    clearfix?: boolean
    expanded?: boolean
    pulledLeft?: boolean
    pulledRight?: boolean
    overlay?: boolean
    fullwidth?: boolean
    fullHeight?: boolean
    textCentered?: boolean
    textLeft?: boolean
    textRight?: boolean
    disabled?: boolean
    marginless?: boolean
    paddingless?: boolean
    unselectable?: 'on' | 'off'
    // props criadas
    alignedCenter?: boolean
    horizontalFlow?: boolean
    pulledBottom?: boolean
    verticalFlow?: boolean
    borderBottom?: boolean
}

const helperProps = [
    'clearfix',
    'expanded',
    'pulledLeft',
    'pulledRight',
    'overlay',
    'fullwidth',
    'fullHeight',
    'textCentered',
    'textLeft',
    'textRight',
    // Disabled é repassado para o componente
    // 'disabled',
    'marginless',
    'paddingless',
    'unselectable',
    // props criadas
    'alignedCenter',
    'horizontalFlow',
    'pulledBottom',
    'verticalFlow',
    'borderBottom',
]

export function helpersClassnames(props: UtilProps) {
    return classnames({
        'is-clearfix': props.clearfix,
        'is-expanded': props.expanded,
        'is-pulled-left': props.pulledLeft,
        'is-pulled-right': props.pulledRight,
        'is-overlay': props.overlay,
        'is-fullwidth': props.fullwidth,
        'is-full-height': props.fullHeight,
        'has-text-centered': props.textCentered,
        'has-text-left': props.textLeft,
        'has-text-right': props.textRight,
        'is-disabled': props.disabled,
        'is-marginless': props.marginless,
        'is-paddingless': props.paddingless,
        'is-unselectable': props.unselectable && props.unselectable === 'on',
        // props criadas
        'is-aligned-center': props.alignedCenter,
        'is-horizontal-flow': props.horizontalFlow,
        'is-pulled-bottom': props.pulledBottom,
        'is-vertical-flow': props.verticalFlow,
        'has-border-bottom': props.borderBottom,
    })
}

export function filterProps(props: UtilProps, ...exclude: string[]) {
    let finalExclude: string[] = [...helperProps, ...exclude]

    return Object.keys(props)
        .filter(propName => finalExclude.indexOf(propName) === -1)
        .reduce((obj, propName) => {
            obj[propName] = props[propName]

            return obj
        }, {})
}
