import React from 'react'
import { Columns, Column } from 'libs/components'
import { ProfissionalField } from 'libs/components/presentational/form/field/ProfissionalField'
import { PacienteField } from 'libs/components/presentational/form/field/PacienteField'
import { BuscaProcedimentoFiltroCirurgia } from 'view/componentes/buscaProcedimento/BuscaProcedimentoFiltroCirurgia'

interface BuscaProcedimentoFormProps {
    onSubmit(form)
    shouldClassificacao: boolean
}

export const GestorServicoSaudeBuscaProcedimentoForm: React.FC<BuscaProcedimentoFormProps> = ({
    onSubmit,
    shouldClassificacao,
}) => {
    return (
        <form onSubmit={onSubmit}>
            <Columns>
                <Column size='6'>
                    <ProfissionalField
                        name='profissional'
                        label='Profissional'
                        placeholder='Selecione o profissional'
                        clearable
                    />
                </Column>
                <Column size='6'>
                    <PacienteField name='paciente' label='Paciente' placeholder='Selecione o paciente' clearable />
                </Column>
            </Columns>
            <BuscaProcedimentoFiltroCirurgia shouldClassificacao={shouldClassificacao} />
        </form>
    )
}
