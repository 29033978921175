/* tslint:disable */
import React from 'react'

const SvgVinculoinativo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 120 120' {...props}>
        <path d='M82.394 63.915l8.75-21.756c3.813-9.49-.811-20.266-10.33-24.07-9.518-3.802-20.326.81-24.138 10.3l-8.75 21.756 5.652 2.259 8.75-21.756c2.574-6.367 9.828-9.46 16.224-6.919 6.385 2.566 9.488 9.795 6.944 16.171L76.74 61.664l5.654 2.251zm-39.872-9.951L25.659 70.2c-7.352 7.085-7.569 18.788-.485 26.14 7.084 7.353 18.788 7.57 26.14.486l16.861-16.237-4.206-4.367-16.861 16.239c-4.948 4.744-12.802 4.596-17.568-.333-4.746-4.945-4.602-12.796.323-17.564l16.87-16.24-4.211-4.36zM92.135 87L81 78.67 82.865 76 94 84.33zM81.39 96L74 84.716 76.612 83 84 94.284zm17.968-22L86 71.162 86.644 68 100 70.837zM37.743 47L28 37.257 30.257 35 40 44.743zm7.189-6L39 28.317 42.068 27 48 39.683zM32.863 54L20 49.023 21.138 46 34 50.977z' />
    </svg>
)

export default SvgVinculoinativo
