/* tslint:disable */
import React from 'react'

const SvgAntibiotico = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M19.238 4.93c-.847-.848-3.535-2.358-5.892 0l-8.25 8.25c-1.179 1.177-2.082 3.81 0 5.892 2.08 2.08 4.713 1.179 5.892 0l8.25-8.25c2.357-2.357.848-5.045 0-5.893zm-.954.9l.152.16c1.542 1.738.589 3.336 0 3.926-.393.392-1.645 1.632-3.756 3.718l-4.063-4.265 3.536-3.535c.59-.59 2.74-1.582 4.283.156z' />
    </svg>
)

export default SvgAntibiotico
