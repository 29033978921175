import axios, { AxiosPromise } from 'axios'
import { VinculoRecusadoForm, SolicitacaoVinculoForm, SelecaoPerfilDto } from 'backend'

const url = '/api/solicitacaoAcesso'

class SolicitacaoAcesso {
    vinculosPendentesUrl(): string {
        return url + '/vinculosPendentes'
    }

    vinculosRecusadosUrl(): string {
        return url + '/vinculosRecusados'
    }

    aprovarSolicitacaoVinculo(solicitacaoVinculoId: number): AxiosPromise<void> {
        return axios.post(url + '/aprovarSolicitacaoVinculo/' + solicitacaoVinculoId)
    }

    recusarSolicitacaoVinculo(recusaForm: VinculoRecusadoForm): AxiosPromise<void> {
        return axios.post(url + '/recusarSolicitacaoVinculo/', recusaForm)
    }

    solicitarVinculo(solicitacaoVinculoForm: SolicitacaoVinculoForm): AxiosPromise<SolicitacaoVinculoForm> {
        return axios.post(url + '/solicitacaoVinculo', solicitacaoVinculoForm)
    }

    desativarVinculo(vinculoId: number): AxiosPromise<void> {
        return axios.post(url + '/desativarVinculo/' + vinculoId)
    }

    desativarVinculoProprio(perfil: SelecaoPerfilDto): AxiosPromise<void> {
        return axios.post(url + '/desativarVinculo/', perfil)
    }

    reativarVinculo(perfil: SelecaoPerfilDto): AxiosPromise<void> {
        return axios.post(url + '/reativarVinculo/', perfil)
    }
}

export default new SolicitacaoAcesso()
