import * as React from 'react'
import { ChecklistItemField } from 'libs/components'
import { Columns, Column, ControlField } from 'libs/components'
import AlertaConfigModalForm, {
    PublicAlertaConfigModalFormProps,
} from 'view/home/anvisa/alertas/components/AlertaConfigModalForm'
import MotivoRevisaoModalFormValidator from './MotivoRevisaoModalFormValidator'

const formName = 'configuracaoAlertaMotivoRevisaoJoelho'

interface MotivoRevisaoJoelhoModalFormProps extends PublicAlertaConfigModalFormProps {}

export default class MotivoRevisaoJoelhoModalForm extends React.PureComponent<MotivoRevisaoJoelhoModalFormProps> {
    render() {
        return (
            <AlertaConfigModalForm
                formName={formName}
                validate={MotivoRevisaoModalFormValidator}
                {...this.props}
                renderChildren={ativo => (
                    <div>
                        <Columns>
                            <Column>
                                <ControlField label='Motivos da revisão' name='motivos' required />
                            </Column>
                        </Columns>
                        <Columns>
                            <Column size='6' verticalFlow>
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='DESGASTE_POLIETILENO_DEFEITO'
                                    label='Desgaste do polietileno / defeito'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='DOR_INEXPLICAVEL'
                                    label='Dor inexplicável'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='DOR_PATELAR'
                                    label='Dor patelar'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='FRATURA_PERIPROTETICA'
                                    label='Fratura periprotética'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='IMPLANTACAO_INADEQUADA_IMPLANTE'
                                    label='Implantação inadequada do implante'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='INFECCAO_JOELHO'
                                    label='Infecção'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='INSTABILIDADE_LIGAMENTO_CRUZADO_POSTERIOR'
                                    label='Instabilidade do ligamento cruzado posterior'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='INSTABILIDADE_LIGAMENTOS_COLATERAIS'
                                    label='Instabilidade dos ligamentos colaterais'
                                    disabled={!ativo}
                                />
                            </Column>
                            <Column verticalFlow>
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='LIMITACAO_AMPLITUDE_MOVIMENTO'
                                    label='Limitação da amplitude de movimento'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='LUXACAO'
                                    label='Luxação'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='LUXACAO_PATELA'
                                    label='Luxação da patela'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='NECROSE_PATELA'
                                    label='Necrose da patela'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='PROGRESSAO_ARTROSE'
                                    label='Progressão da artrose'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='QUEBRA_IMPLANTE'
                                    label='Quebra do implante'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='SOLTURA_COMPONENTE_FEMORAL'
                                    label='Soltura do componente femoral'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='SOLTURA_COMPONENTE_PATELAR'
                                    label='Soltura do componente patelar'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='SOLTURA_COMPONENTE_TIBIAL'
                                    label='Soltura do componente tibial'
                                    disabled={!ativo}
                                />
                                <ChecklistItemField
                                    name='motivos'
                                    optionValue='OUTRO_REVISAO_JOELHO'
                                    label='Outro'
                                    disabled={!ativo}
                                />
                            </Column>
                        </Columns>
                    </div>
                )}
            ></AlertaConfigModalForm>
        )
    }
}
