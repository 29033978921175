import React from 'react'
import * as C from 'libs/components'

const tiposFalha = [
    {
        id: 'IMPLANTE_ATIVACAO_POSICIONAMENTO_SEPARACAO',
        nome: 'Implante, ativação, posicionamento ou separação',
        descricao:
            'Problema associado com qualquer desvio do produto para a saúde - especificação documentada de desempenho, relacionadas à sequência de eventos para ativação ou posicionamento do produto para a saúde ou algum de seus componentes em uma localização específica do corpo.',
    },
    {
        id: 'FALHA_PRODUTO_SAUDE_IMPLANTAVEL',
        nome: 'Falha no produto para a saúde implantável',
        descricao: 'Migração, mau funcionamento ou falha de um produto para a saúde implantado (ativo ou não ativo).',
    },
    {
        id: 'INCOMPATIBILIDADE',
        nome: 'Incompatibilidade',
        descricao:
            'Problema associado com o produto para a saúde em função de incompatibilidade com outro componente do próprio produto, paciente, ou substância (medicação, fluido do corpo, etc.) que ela contém ou transporta.',
    },
    {
        id: 'MARCACAO_ROTULAGEM_INSTRUCOES',
        nome: 'Marcação, rotulagem ou instruções para uso',
        descricao:
            'Problema associado com a exatidão e adequação de qualquer dado escrito, impresso, gráfico ou áudio visual que é fornecido com um produto para a saúde ou em sua embalagem. Inclui marcações que aparecem no próprio produto para a saúde, componente do produto para a saúde, ou ambos.',
    },
]

export class TipoFalhaField extends React.Component {
    render() {
        return <C.FixedSelectField {...this.props} options={tiposFalha} />
    }
}
