import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const InternalPrivateRoute = ({ user, component, render, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (user) {
                if (render) {
                    return render(props)
                } else {
                    return React.createElement(component, props)
                }
            } else {
                return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }
        }}
    />
)

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
}

const PrivateRoute = connect(mapStateToProps)(InternalPrivateRoute)

export default PrivateRoute
