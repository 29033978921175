import * as React from 'react'
import * as C from 'libs/components'
import { FixedSelectProps } from '../../elements/FixedSelect'
import { FormFieldProps } from '../FormField'
import { WrappedFieldProps } from 'redux-form'
import { tipoProcedimentoName } from 'redux/enums'
import { TipoProcedimento } from 'backend'

const procedimentos = [
	{ id: 'PRIMARIA_JOELHO' as TipoProcedimento, nome: tipoProcedimentoName.PRIMARIA_JOELHO },
	{ id: 'PRIMARIA_QUADRIL' as TipoProcedimento, nome: tipoProcedimentoName.PRIMARIA_QUADRIL },
	{ id: 'REVISAO_JOELHO' as TipoProcedimento, nome: tipoProcedimentoName.REVISAO_JOELHO },
	{ id: 'REVISAO_QUADRIL' as TipoProcedimento, nome: tipoProcedimentoName.REVISAO_QUADRIL },
	{ id: 'STENT_CORONARIA' as TipoProcedimento, nome: tipoProcedimentoName.STENT_CORONARIA },
]

export interface TipoProcedimentoFieldProps extends Pick<FixedSelectProps, 'getRef'>, FormFieldProps, Partial<WrappedFieldProps> {
    name: string
    placeholder?: string
    label?: string
}
export class TipoProcedimentoField extends React.Component<TipoProcedimentoFieldProps> {
    render() {
        return <C.FixedSelectField {...this.props} options={procedimentos}/>
    }
}
