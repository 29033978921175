import { connect } from 'react-redux'
import * as C from 'libs/components'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { State } from 'redux/modules'
import * as info from 'redux/modules/info'

const mapStateToProps = (state: State, ownProps: RouteComponentProps<any>) => {
    return {
        user: state.auth.user,
        location: ownProps.history.location,
        versao: state.info.versao && state.info.versao.data,
    }
}

const mapDispatchToProps = (dispatch, ownProps: RouteComponentProps<any>) => {
    return {
        onLogout: () => {
            ownProps.history.push('/logout')
        },
        getVersao: () => {
            dispatch(info.getVersao())
        },
    }
}

export const HeaderContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        (stateProps, dispatchProps, ownProps): C.HeaderProps => {
            return { ...stateProps, ...dispatchProps }
        }
    )(C.Header)
)
