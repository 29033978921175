
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.26,"time":15600.000000000002,"words":52}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI.`}</li>
      <li parentName="ol">{`Na tela inicial selecione o Relatório de “Dados financeiros”.`}</li>
      <li parentName="ol">{`No campo de busca por “Registro ANVISA” informe o registro do produto que você deseja visualizar e clique em “Pesquisar”.`}</li>
      <li parentName="ol">{`Se já foram informados dados financeiros para o produto informado, eles serão exibidos na tela.`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

