import * as React from 'react'
import { ChartOptions, ChartCard } from 'libs/components'
import { SeriesColumnOptions } from 'highcharts'
import { DistLocalEtapa, DistLocalEtapaNacional } from 'redux/modules/report/usoSistema'

interface Props {
    readyState: 'request' | 'success' | 'failure'
    distLocalEtapa: DistLocalEtapa | DistLocalEtapaNacional
}

export class QuantidadeRegistrosEtapaLocalidadeChartCard extends React.Component<Props> {
    render() {
        let config: ChartOptions = {}

        if (this.props.readyState === 'success') {
            config = {
                chart: {
                    type: 'bar',
                },
                xAxis: {
                    categories: this.props.distLocalEtapa.result.categories.map(s => s.name),
                    title: {
                        text: null,
                    },
                },
                yAxis: {
                    min: 0,
                    minTickInterval: 1,
                    title: {
                        text: null,
                    },
                },
                title: {
                    text: 'Distribuição de registros por etapa e localidade',
                },
                legend: {
                    layout: 'horizontal',
                    align: 'right',
                    verticalAlign: 'bottom',
                    y: 20,
                    floating: true,
                },
                credits: {
                    enabled: false,
                },
                series: this.props.distLocalEtapa.result.series as SeriesColumnOptions[],
            }
        }
        return (
            <ChartCard
                defaultViewMode='bar'
                title='Quantidade de registros por etapas'
                readyState={this.props.readyState}
                config={config}
                renderActions={false}
                renderTools={false}
            />
        )
    }
}
