import * as React from 'react'
import * as C from 'libs/components'
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('pt-BR', ptBR)

export interface DatePickerInputProps extends ReactDatePickerProps {}

export class DatePickerInput extends React.Component<DatePickerInputProps> {
    render() {
        return (
            <DatePicker
                {...this.props}
                selected={this.props.value as any}
                className='input'
                todayButton='Hoje'
                locale='pt-BR'
                showYearDropdown
                dropdownMode='select'
                dateFormat='dd/MM/yyyy'
                customInput={<DateInput />}
            />
        )
    }
}

interface DateInputProps extends C.TextInputProps {}

class DateInput extends React.Component<DateInputProps> {
    private textInput: C.TextInput

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        e.target.value = this.normalize(e.target.value)

        if (this.props.onChange) {
            this.props.onChange(e)
        }
    }

    normalize(value) {
        const onlyNums = value.replace(/[^\d]/g, '')
        if (onlyNums.length < 3) {
            return onlyNums
        } else if (onlyNums.length < 5) {
            return onlyNums.slice(0, 2) + '/' + onlyNums.slice(2, 4)
        } else {
            return onlyNums.slice(0, 2) + '/' + onlyNums.slice(2, 4) + '/' + onlyNums.slice(4, 8)
        }
    }

    focus() {
        this.textInput.focus()
    }

    render() {
        return <C.TextInput ref={elem => (this.textInput = elem)} {...this.props} onChange={this.handleChange} />
    }
}
