import React from 'react'
import { WrappedFieldProps } from 'redux-form'
import field from 'libs/redux-form/field'
import { FormFieldProps, TextAreaProps, TextArea } from 'libs/components'

export interface TextAreaWithLengthProps extends FormFieldProps, TextAreaProps, Partial<WrappedFieldProps> {}

export const TextAreaWithLength = field()(
    ({ input, placeholder, disabled, maxLength, meta }: TextAreaWithLengthProps) => {
        const limit = maxLength ? maxLength : '-'
        const txtLength = (input.value as string).length
        return (
            <div className='textarea-with-length-ctnr'>
                <TextArea
                    {...input}
                    maxLength={!!maxLength && maxLength}
                    placeholder={placeholder}
                    disabled={disabled}
                    hasError={!!meta.error}
                />
                <div
                    className={`textarea-with-length-subtl ${
                        txtLength === maxLength ? 'textarea-with-length-subtl-error' : ''
                    }`}
                >
                    {txtLength}/{limit} caracteres
                </div>
            </div>
        )
    }
)
