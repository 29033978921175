import { connect } from 'react-redux'
import { State } from 'redux/modules'
import * as procedimento from 'redux/modules/report/procedimento'
import { withRouter } from 'react-router-dom'
import { HomeGestorSNVSView } from './HomeGestorSNVSView'
import { Requester } from 'redux/requester'
import Alerta from 'api/Alerta'

const alertasNaoLidos = new Requester<number>('alertasNaoLidos', Alerta.getCountNaoLidos)

const mapStateToProps = (state: State) => ({
    procedimentos: state.report.procedimento.qtdProcedimentos,
    hasAlertasNaoLidos: alertasNaoLidos.getResult(state) > 0,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadQtd: () => {
        return dispatch(procedimento.loadQtdProcedimentos())
    },
    refreshAlertasNaoLidos: () => dispatch(alertasNaoLidos.request()),
    goBuscaProcedimentos: () => ownProps.history.push('/home/buscaProcedimentos'),
    goAlarmes: () => ownProps.history.push('/home/alarmes'),
    goVinculos: () => ownProps.history.push('/vinculos'),
    goAlterarSenha: () => ownProps.history.push('/home/alterarSenha'),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeGestorSNVSView))
