import * as React from 'react'
import { CheckPanel } from '../CheckPanel'
import field from 'libs/redux-form/field'
import { WrappedFieldProps } from 'redux-form'
import { FormFieldProps } from '../FormField'

interface CheckPanelFieldProps extends Partial<WrappedFieldProps>, FormFieldProps {
    label: string
    name: string
    showErrors?: boolean
}

class CheckPanelFieldCmp extends React.Component<CheckPanelFieldProps> {
    render() {
        return <CheckPanel {...this.props} />
    }
}

export const CheckPanelField = field({ type: 'checkbox', haswrapper: false })(CheckPanelFieldCmp)
