import {
    ClassificacaoCirurgia,
    EntePagador,
    MotivoProcedimentoEnum,
    ProcedimentoMode,
    RegiaoEnum,
    RelacaoTipoEnum,
    RespostaEnum,
    Sexo,
    SimNao,
    StatusPreenchimentoEnum,
    PadraoObstrutivoEnum,
    QuadroClinicoEnum,
    StatusProcedimento,
    TipoArtroplastiaEnum,
    TipoCirurgia,
    TipoPeriodoAlertaEnum,
    TipoProcedimento,
    TipoRevisaoEnum,
} from 'backend'
import { MedicoMode, TempoAteRevisao } from 'model'
export const classificacaoCirurgiaName: { [key in ClassificacaoCirurgia]: string } = {
    PRIMARIA: 'Primária',
    REVISAO: 'Revisão',
    ANGIOPLASTIA: 'Angioplastia',
}

export const classificacaoCirurgiaPluralName: { [key in ClassificacaoCirurgia]: string } = {
    PRIMARIA: 'Primárias',
    REVISAO: 'Revisões',
    ANGIOPLASTIA: 'Angioplastias',
}

export const tipoProcedimentoReportColor: { [key in TipoProcedimento]: string } = {
    PRIMARIA_JOELHO: 'is-report-color-1',
    PRIMARIA_QUADRIL: 'is-report-color-1',
    REVISAO_JOELHO: 'is-report-color-2',
    REVISAO_QUADRIL: 'is-report-color-2',
    STENT_CORONARIA: 'is-report-color-3',
}

export const classificacaoCirurgiaReportClassName: { [key in ClassificacaoCirurgia]: string } = {
    PRIMARIA: 'is-report-color-1',
    REVISAO: 'is-report-color-2',
    ANGIOPLASTIA: 'is-report-color-3',
}

export const classificacaoCirurgiaReportColor: { [key in ClassificacaoCirurgia]: string } = {
    PRIMARIA: '#0099ff',
    REVISAO: '#c85d00',
    ANGIOPLASTIA: '#003a79',
}

export const tipoProcedimentoName: { [key in TipoProcedimento]: string } = {
    PRIMARIA_JOELHO: 'Primária de Joelho',
    PRIMARIA_QUADRIL: 'Primária de Quadril',
    REVISAO_JOELHO: 'Revisão de Joelho',
    REVISAO_QUADRIL: 'Revisão de Quadril',
    STENT_CORONARIA: 'Stent Coronária',
}

export const tipoProcedimentoPluralName: { [key in TipoProcedimento]: string } = {
    PRIMARIA_JOELHO: 'Primárias de joelho',
    PRIMARIA_QUADRIL: 'Primárias de quadril',
    REVISAO_JOELHO: 'Revisões de joelho',
    REVISAO_QUADRIL: 'Revisões de quadril',
    STENT_CORONARIA: 'Stents de coronária',
}

export const tipoPeriodoAlertaAlternateName: { [key in TipoPeriodoAlertaEnum]: string } = {
    MENSAL: 'mês',
    BIMESTRAL: 'bimestre',
    TRIMESTRAL: 'trimestre',
    SEMESTRAL: 'semestre',
    ANUAL: 'ano',
}

export const sexoName: { [key in Sexo]: string } = {
    AMBOS: 'Ambos',
    FEMININO: 'Feminino',
    IGNORADO: 'Ignorado',
    MASCULINO: 'Masculino',
    NAO_INFORMADO: 'Não informado',
}

export const regiaoName: { [key in RegiaoEnum]: string } = {
    NORTE: 'Norte',
    NORDESTE: 'Nordeste',
    CENTRO_OESTE: 'Centro-Oeste',
    SUDESTE: 'Sudeste',
    SUL: 'Sul',
}

export const regiaoUfs: { [key in RegiaoEnum]: string[] } = {
    NORTE: ['RO', 'AC', 'AM', 'RR', 'PA', 'AP', 'TO'],
    NORDESTE: ['MA', 'PI', 'CE', 'RN', 'PB', 'PE', 'AL', 'SE', 'BA'],
    CENTRO_OESTE: ['MS', 'MT', 'GO', 'DF'],
    SUDESTE: ['MG', 'ES', 'RJ', 'SP'],
    SUL: ['PR', 'SC', 'RS'],
}

export const regiaoColor: { [key in RegiaoEnum]: string } = {
    NORTE: '#ad5000',
    NORDESTE: '#ff8c54',
    CENTRO_OESTE: '#0069d0',
    SUDESTE: '#003a79',
    SUL: '#0099ff',
}

export const ufName = {
    AC: 'Acre',
    AL: 'Alagoas',
    AM: 'Amazonas',
    AP: 'Amapá',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MG: 'Minas Gerais',
    MS: 'Mato Grosso do Sul',
    MT: 'Mato Grosso',
    PA: 'Pará',
    PB: 'Paraíba',
    PE: 'Pernambuco',
    PI: 'Piauí',
    PR: 'Paraná',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RO: 'Rondônia',
    RR: 'Roraima',
    RS: 'Rio Grande do Sul',
    SC: 'Santa Catarina',
    SE: 'Sergipe',
    SP: 'São Paulo',
    TO: 'Tocantins',
}

export const ufColor = {
    AC: '#642B00',
    AM: '#AD5000',
    AP: '#C85D00',
    PA: '#E76F00',
    RO: '#FF8C54',
    RR: '#0099FF',
    TO: '#84CDFF',

    AL: '#421A00',
    BA: '#642B00',
    CE: '#AD5000',
    MA: '#C85D00',
    PB: '#E76F00',
    PE: '#FF8C54',
    PI: '#003A79',
    RN: '#0069D0',
    SE: '#0099FF',

    DF: '#0069D0',
    GO: '#0099ff',
    MS: '#84CDFF',
    MT: '#003A79',

    ES: '#0069d0',
    MG: '#0099ff',
    RJ: '#84CDFF',
    SP: '#003A79',

    PR: '#0069d0',
    RS: '#0099ff',
    SC: '#84CDFF',
}

export const procedimentoModeName: { [key in ProcedimentoMode]: string } = {
    PRIMARIA_REVISAO: 'Primária e Revisão',
    REVISAO_REVISAO: 'Revisão e Revisão',
}

export const medicoModeName: { [key in MedicoMode]: string } = {
    MESMO: 'Mesmo médico responsável',
    DIFERENTE: 'Médicos responsáveis diferentes',
}

export const tipoCirurgiaName: { [key in TipoCirurgia]: string } = {
    CORACAO: 'Coração',
    JOELHO: 'Joelho',
    QUADRIL: 'Quadril',
}

export const tempoAteRevisaoName: { [key in TempoAteRevisao]: string } = {
    MENOS_5_ANOS: 'Em menos de 5 anos',
    ENTRE_5_E_10_ANOS: 'Entre 5 e 10 anos',
    MAIS_10_ANOS: 'Em mais de 10 anos',
}

export const SituacaoFinanceiro: { [key in SimNao]: { descCard: string; descBody: string } } = {
    SIM: { descCard: 'Comprados pelo hospital e ressarcidos', descBody: 'Ressarcidos' },
    NAO: { descCard: 'Fornecido por terceiros', descBody: 'Fornecido por terceiros' },
}

export const SituacaoEntePagador: { [key in SimNao]: EntePagador[] } = {
    SIM: ['OPERADORA_SAUDE', 'PARTICULAR', 'SUS'],
    NAO: ['OPERADORA_SAUDE', 'PARTICULAR', 'SUS', 'DOACAO'],
}

export const DescricaoEntePagador: { [key in EntePagador]: string } = {
    OPERADORA_SAUDE: 'Operadora de saúde',
    PARTICULAR: 'Particular',
    SUS: 'SUS',
    DOACAO: 'Doação',
}

export const Situacao: { [key in Situacao]: { descricao: string; procedimento: TipoCirurgia } } = {
    JOELHO_CONVENCIONAL: { descricao: 'Convencional', procedimento: 'JOELHO' },
    JOELHO_NAO_CONV: { descricao: 'Não convencional', procedimento: 'JOELHO' },
    QUADRIL_TROCA: { descricao: 'Trocas', procedimento: 'QUADRIL' },
    QUADRIL_RETIRADA: { descricao: 'Retiradas', procedimento: 'QUADRIL' },
}

export type Situacao = 'JOELHO_CONVENCIONAL' | 'JOELHO_NAO_CONV' | 'QUADRIL_TROCA' | 'QUADRIL_RETIRADA'
export const TipoRevisao: {
    [key in TipoRevisaoEnum]: { descricao: string; situacao: Situacao; procedimento: TipoProcedimento }
} = {
    NAO_CONVENCIONAL: { descricao: 'Não convencional', situacao: 'JOELHO_NAO_CONV', procedimento: 'REVISAO_JOELHO' },
    TROCA_DO_COMPONENTE_FEMORAL: {
        descricao: 'Troca do componente femoral',
        situacao: 'JOELHO_CONVENCIONAL',
        procedimento: 'REVISAO_JOELHO',
    },
    TROCA_DO_COMPONENTE_PATELAR: {
        descricao: 'Troca do componente patelar',
        situacao: 'JOELHO_CONVENCIONAL',
        procedimento: 'REVISAO_JOELHO',
    },
    TROCA_DO_COMPONENTE_TIBIAL: {
        descricao: 'Troca do componente tibial',
        situacao: 'JOELHO_CONVENCIONAL',
        procedimento: 'REVISAO_JOELHO',
    },
    TROCA_DO_POLIETILENO_TIBIAL: {
        descricao: 'Troca do polietileno tibial',
        situacao: 'JOELHO_CONVENCIONAL',
        procedimento: 'REVISAO_JOELHO',
    },

    HASTE_FEMORAL: {
        descricao: 'Retirada da haste femoral',
        situacao: 'QUADRIL_RETIRADA',
        procedimento: 'REVISAO_QUADRIL',
    },
    CABECA_FEMORAL: {
        descricao: 'Retirada da cabeça femoral',
        situacao: 'QUADRIL_RETIRADA',
        procedimento: 'REVISAO_QUADRIL',
    },
    INSERTO_ACETABULAR: {
        descricao: 'Retirada do inserto acetabular',
        situacao: 'QUADRIL_RETIRADA',
        procedimento: 'REVISAO_QUADRIL',
    },
    COMPONENTE_ACETABULAR: {
        descricao: 'Retirada do componente acetabular',
        situacao: 'QUADRIL_RETIRADA',
        procedimento: 'REVISAO_QUADRIL',
    },

    TROCA_TODOS: {
        descricao: 'Troca de todos os componentes',
        situacao: 'QUADRIL_TROCA',
        procedimento: 'REVISAO_QUADRIL',
    },
    TROCA_FEMORAIS: {
        descricao: 'Troca de todos os componentes femorais',
        situacao: 'QUADRIL_TROCA',
        procedimento: 'REVISAO_QUADRIL',
    },
}

export const MotivosProcedimento: {
    [key in MotivoProcedimentoEnum]: { descricao: string; procedimento: TipoProcedimento }
} = {
    ARTRITE_REUMATOIDE_QUADRIL: { descricao: 'Artrite reumatóide', procedimento: 'PRIMARIA_QUADRIL' },

    FRATURA_COLO_FEMORAL: { descricao: 'Fratura do colo femoral', procedimento: 'PRIMARIA_QUADRIL' },
    LUXACAO_INVETERADA: { descricao: 'Luxação inveterada', procedimento: 'PRIMARIA_QUADRIL' },
    NECROSE_AVASCULAR_CABECA_FEMORAL: {
        descricao: 'Necrose avascular da cabeça femoral',
        procedimento: 'PRIMARIA_QUADRIL',
    },
    OSTEOARTROSE_PRIMARIA_QUADRIL: { descricao: 'Osteoartrose primária', procedimento: 'PRIMARIA_QUADRIL' },
    OUTRAS_DOENCAS_REUMATICAS_QUADRIL: { descricao: 'Outras doenças reumáticas', procedimento: 'PRIMARIA_QUADRIL' },
    POS_TRAUMATICA: { descricao: 'Pós traumática', procedimento: 'PRIMARIA_QUADRIL' },
    SEQUELA_DISPLASIA: { descricao: 'Sequela de displasia', procedimento: 'PRIMARIA_QUADRIL' },
    POS_FRATURA_ACETABULAR: { descricao: 'Sequela de fratura do acetábulo', procedimento: 'PRIMARIA_QUADRIL' },
    POS_FRATURA_FEMUR_PROXIMAL: { descricao: 'Sequela de fratura do fêmur proximal', procedimento: 'PRIMARIA_QUADRIL' },
    SEQUELA_PERTHES: { descricao: 'Sequela de Perthes', procedimento: 'PRIMARIA_QUADRIL' },
    OUTRO_PRIMARIA_QUADRIL: { descricao: 'Outro', procedimento: 'PRIMARIA_QUADRIL' },

    // RevisaoQuadril
    COTILOIDITE: { descricao: 'Cotiloidite', procedimento: 'REVISAO_QUADRIL' },
    DESGASTE_POLIETILENO: { descricao: 'Desgaste do polietileno', procedimento: 'REVISAO_QUADRIL' },
    DOR_COMO_CAUSA_ISOLADA: { descricao: 'Dor como causa isolada da revisão', procedimento: 'REVISAO_QUADRIL' },
    FRATURA_PERIPROTETICA_QUADRIL: { descricao: 'Fratura periprotética', procedimento: 'REVISAO_QUADRIL' },
    GRANDE_DEFEITO_OSSEO_ACETABULAR: { descricao: 'Grande defeito ósseo acetabular', procedimento: 'REVISAO_QUADRIL' },
    GRANDE_DEFEITO_OSSEO_FEMORAL: { descricao: 'Grande defeito ósseo femoral', procedimento: 'REVISAO_QUADRIL' },
    INFECCAO_QUADRIL: { descricao: 'Infecção', procedimento: 'REVISAO_QUADRIL' },
    OSTEOLISE_ACETABULO: { descricao: 'Osteólise do acetábulo', procedimento: 'REVISAO_QUADRIL' },
    OSTEOLISE_FEMUR: { descricao: 'Osteólise do fêmur', procedimento: 'REVISAO_QUADRIL' },
    OSSIFICACAO_HETEROTOPICA: { descricao: 'Ossificação heterotópica', procedimento: 'REVISAO_QUADRIL' },
    PROTUSAO_ACETABULAR: { descricao: 'Protrusão acetabular', procedimento: 'REVISAO_QUADRIL' },
    QUEBRA_IMPLANTE_QUADRIL: { descricao: 'Quebra do implante', procedimento: 'REVISAO_QUADRIL' },
    SOLTURA_COMPONENTE_ACETABULAR: { descricao: 'Soltura do componente acetabular', procedimento: 'REVISAO_QUADRIL' },
    SOLTURA_COMPONENTE_FEMORAL_QUADRIL: { descricao: 'Soltura do componente femoral', procedimento: 'REVISAO_QUADRIL' },
    OUTRO_REVISAO_QUADRIL: { descricao: 'Outro', procedimento: 'REVISAO_QUADRIL' },

    // PrimariaJoelho
    ARTRITE_REUMATOIDE_JOELHO: { descricao: 'Artrite reumatóide', procedimento: 'PRIMARIA_JOELHO' },
    OSTEOARTROSE_PRIMARIA_JOELHO: { descricao: 'Osteoartrose primária', procedimento: 'PRIMARIA_JOELHO' },
    OSTEOARTROSE_PRIMARIA_UNICOMPARTIMENTAL: {
        descricao: 'Osteoartrose primária unicompartimental',
        procedimento: 'PRIMARIA_JOELHO',
    },
    OSTEONECROSE: { descricao: 'Osteonecrose', procedimento: 'PRIMARIA_JOELHO' },
    OUTRAS_DOENCAS_REUMATICAS: { descricao: 'Outras doenças reumáticas', procedimento: 'PRIMARIA_JOELHO' },
    POS_LESAO_LIGAMENTAR: { descricao: 'Pós lesão ligamentar', procedimento: 'PRIMARIA_JOELHO' },
    OUTRO_PRIMARIA_JOELHO: { descricao: 'Outro', procedimento: 'PRIMARIA_JOELHO' },

    // RevisaoJoelho
    DESGASTE_POLIETILENO_DEFEITO: { descricao: 'Desgaste do polietileno/defeito', procedimento: 'REVISAO_JOELHO' },
    DOR_INEXPLICAVEL: { descricao: 'Dor inexplicável', procedimento: 'REVISAO_JOELHO' },
    DOR_PATELAR: { descricao: 'Dor patelar', procedimento: 'REVISAO_JOELHO' },
    FRATURA_PERIPROTETICA: { descricao: 'Fratura periprotética', procedimento: 'REVISAO_JOELHO' },
    IMPLANTACAO_INADEQUADA_IMPLANTE: {
        descricao: 'Implantação inadequada do implante',
        procedimento: 'REVISAO_JOELHO',
    },
    INFECCAO_JOELHO: { descricao: 'Infecção', procedimento: 'REVISAO_JOELHO' },
    INSTABILIDADE_LIGAMENTO_CRUZADO_POSTERIOR: {
        descricao: 'Instabilidade do ligamento cruzado posterior',
        procedimento: 'REVISAO_JOELHO',
    },
    INSTABILIDADE_LIGAMENTOS_COLATERAIS: {
        descricao: 'Instabilidade dos ligamentos colaterais',
        procedimento: 'REVISAO_JOELHO',
    },
    LIMITACAO_AMPLITUDE_MOVIMENTO: { descricao: 'Limitação da amplitude de movimento', procedimento: 'REVISAO_JOELHO' },
    LUXACAO: { descricao: 'Luxação', procedimento: 'REVISAO_JOELHO' },
    LUXACAO_PATELA: { descricao: 'Luxação da patela', procedimento: 'REVISAO_JOELHO' },
    NECROSE_PATELA: { descricao: 'Necrose da patela', procedimento: 'REVISAO_JOELHO' },
    PROGRESSAO_ARTROSE: { descricao: 'Progressão da artrose', procedimento: 'REVISAO_JOELHO' },
    QUEBRA_IMPLANTE: { descricao: 'Quebra do implante', procedimento: 'REVISAO_JOELHO' },
    SOLTURA_COMPONENTE_FEMORAL: { descricao: 'Soltura do componente femoral', procedimento: 'REVISAO_JOELHO' },
    SOLTURA_COMPONENTE_PATELAR: { descricao: 'Soltura do componente patelar', procedimento: 'REVISAO_JOELHO' },
    SOLTURA_COMPONENTE_TIBIAL: { descricao: 'Soltura do componente tibial', procedimento: 'REVISAO_JOELHO' },
    OUTRO_REVISAO_JOELHO: { descricao: 'Outro', procedimento: 'REVISAO_JOELHO' },
    // Quadro Clinico
	ANGINA_ESTAVEL: { descricao: 'Angina estável', procedimento: 'STENT_CORONARIA' },
	ANGINA_INSTAVEL: { descricao: 'Angina instável', procedimento: 'STENT_CORONARIA' },
	ASSINTOMATICO: { descricao: 'Assintomático', procedimento: 'STENT_CORONARIA' },
	DOR_TORACICA_ATIPICA: { descricao: 'Dor torácica atípica', procedimento: 'STENT_CORONARIA' },
	EQUIVALENTE_ISQUEMICO: { descricao: 'Equivalente isquêmico', procedimento: 'STENT_CORONARIA' },
	IAM_COM_SUPRA_ST: { descricao: 'IAM com supra ST', procedimento: 'STENT_CORONARIA' },
	IAM_SEM_SUPRA_ST: { descricao: 'IAM sem supra ST', procedimento: 'STENT_CORONARIA' },
	// Padrão obstrutivo
	UM_OU_DOIS_VASOS_SEM_DA_PROXIMAL: { descricao: '1 ou 2 vasos; sem DA proximal', procedimento: 'STENT_CORONARIA' },
	UM_VASO_COM_DA_PROXIMAL: { descricao: '1 vaso; com DA proximal', procedimento: 'STENT_CORONARIA' },
	DOIS_VASOS_COM_DA_PROXIMAL: { descricao: '2 vasos; com DA proximal', procedimento: 'STENT_CORONARIA' },
	TRES_VASOS_COM_DA_PROXIMAL: { descricao: '3 vasos; sem TCE', procedimento: 'STENT_CORONARIA' },
	TCE_ISOLADO: { descricao: 'TCE isolado', procedimento: 'STENT_CORONARIA' },
	TCE_UM_DOIS_VASOS: { descricao: 'TCE  1 ou 2 vasos', procedimento: 'STENT_CORONARIA' },
	TCE_TRES_VASOS: { descricao: 'TCE + 3 vasos', procedimento: 'STENT_CORONARIA' },
	OCLUSAO_CRONICA_ISOLADA: { descricao: 'Oclusão crônica isolada', procedimento: 'STENT_CORONARIA' },
	NAO_INFORMADO: { descricao: 'Não informado', procedimento: 'STENT_CORONARIA' },
}

export const tipoArtroplastiaName: { [key in TipoArtroplastiaEnum]: string } = {
    ARTROPLASTIA_TOTAL_COM_SUBSTITUICAO_PATELAR: 'Artroplastia total com substituição patelar',
    ARTROPLASTIA_TOTAL_SEM_SUBSTITUICAO_PATELAR: 'Artroplastia total sem substituição patelar',
    NAO_CONVENCIONAL: 'Não convencional',
    PATELO_FEMORAL: 'Patelo femoral',
    UNICOMPARTIMENTAL: 'Unicompartimental',
    CONVENCIONAL: 'Convencional',
    HEMIARTROPLASTIA_BIPOLAR: 'Hemiartroplastia bipolar',
    HEMIARTROPLASTIA_MONOPOLAR: 'Hemiartroplastia monopolar',
    RECAPEAMENTO: 'Recapeamento',
}

export const padraoObstrutivoEnum: { [key in PadraoObstrutivoEnum]: { name: string; color: string } } = {
    UM_OU_DOIS_VASOS_SEM_DA_PROXIMAL: { name: '1 ou 2 vasos; sem DA proximal', color: '#0099ff' },
    UM_VASO_COM_DA_PROXIMAL: { name: '1 vaso; com DA proximal', color: '#003a79' },
    DOIS_VASOS_COM_DA_PROXIMAL: { name: '2 vasos; com DA proximal', color: '#FF8C54' },
    TRES_VASOS_COM_DA_PROXIMAL: { name: '3 vasos; sem TCE', color: '#421A00' },
    TCE_ISOLADO: { name: 'TCE isolado', color: '#642B00' },
    TCE_UM_DOIS_VASOS: { name: 'TCE + 1 ou 2 vasos', color: '#AD5000' },
    TCE_TRES_VASOS: { name: 'TCE + 3 vasos', color: '#C85D00' },
    OCLUSAO_CRONICA_ISOLADA: { name: 'Oclusão crônica isolada', color: '#E76F00' },
    NAO_INFORMADO: { name: 'Não informado', color: '#0069D0' },
}

export const quadroClinicoEnum: { [key in QuadroClinicoEnum]: string} = {
	ANGINA_ESTAVEL: 'Angina estável',
	ANGINA_INSTAVEL: 'Angina instável',
	ASSINTOMATICO: 'Assintomático',
	DOR_TORACICA_ATIPICA: 'Dor torácica atípica',
	EQUIVALENTE_ISQUEMICO: 'Equivalente isquêmico',
	IAM_COM_SUPRA_ST: 'IAM com supra ST',
	IAM_SEM_SUPRA_ST: 'IAM sem supra ST',
}

export const statusProcedimentos: { [key in StatusProcedimento]: { name: string; color: string } } = {
    AGUARDANDO_DADOS_CLINICOS: { name: 'Aguardando dados clínicos', color: '#0099ff' },
    AGUARDANDO_DADOS_PRODUTO: { name: 'Aguardando dados de produtos', color: '#003a79' },
    AGUARDANDO_DADOS_FINANCEIRO: { name: 'Aguardando dados financeiros', color: '#c85d00' },
    FINALIZADO: { name: 'Finalizados', color: '#FF' },
}

export const tempoMedio: { [key in StatusPreenchimentoEnum]: string } = {
    DADOS_CLINICOS: 'Dados clínicos',
    DADOS_PRODUTO: 'Dados de produto',
    DADOS_FINANCEIRO: 'Dados financeiros',
    FINALIZADO: 'Registro finalizado',
}

export const statusProcedimentoHospitalByEnum: { [key in StatusProcedimento]: string } = {
    AGUARDANDO_DADOS_CLINICOS: 'Aguardando dados clínicos',
    AGUARDANDO_DADOS_PRODUTO: 'Aguardando dados de produtos',
    AGUARDANDO_DADOS_FINANCEIRO: 'Aguardando dados financeiros',
    FINALIZADO: 'Registro finalizado',
}

export const statusProcedimentoByEnum: { [key in StatusProcedimento]: string } = {
    AGUARDANDO_DADOS_CLINICOS: 'Aguardando dados clínicos',
    AGUARDANDO_DADOS_PRODUTO: 'Aguardando dados de produto e financeiros',
    AGUARDANDO_DADOS_FINANCEIRO: 'Aguardando dados financeiros',
    FINALIZADO: 'Registro finalizado',
}

export const respostaRelacaoComEmpresaByEnum: { [key in RespostaEnum]: string } = {
    SIM: 'Sim',
    NAO: 'Não',
    NAO_RESPONDEU: 'Não respondeu',
}

const relacaoTipoValueToDescription = (enumValue: RelacaoTipoEnum): string => {
    const enumToDescription: { [key in RelacaoTipoEnum]: string } = {
        CONSULTORIA_HONORARIOS: 'Consultoria e honorários',
        PROPRIEDADE_INTELECTUAL_PATENTES: 'Propriedade intelectual e patentes',
        ROYALTIES: 'Royalties',
        PARTICIPACAO_EVENTOS: 'Participação em eventos',
        PESQUISA_CIENTIFICA: 'Pesquisa científica',
        DESPESA_VIAGENS: 'Despesas de viagens',
        DOCENTE_EVENTOS_EDUCACIONAIS: 'Docente em eventos educacionais',
        PROPRIETARIO_EMPREGADO_INVESTIDOR: 'Proprietário, empregado ou investidor',
        OUTROS: 'Outros',
    }
    return enumToDescription[enumValue]
}

const relacaoTipoValueToChecklistOrder = (enumValue: RelacaoTipoEnum): number => {
    const enumToChecklistOrder: { [key in RelacaoTipoEnum]: number } = {
        CONSULTORIA_HONORARIOS: 0,
        PROPRIEDADE_INTELECTUAL_PATENTES: 1,
        ROYALTIES: 2,
        PARTICIPACAO_EVENTOS: 3,
        PESQUISA_CIENTIFICA: 4,
        DESPESA_VIAGENS: 5,
        DOCENTE_EVENTOS_EDUCACIONAIS: 6,
        PROPRIETARIO_EMPREGADO_INVESTIDOR: 7,
        OUTROS: 8,
    }
    return enumToChecklistOrder[enumValue]
}

export const buildTiposRelacoesText = (tiposRelacoes: RelacaoTipoEnum[], separator: string): string => {
    return tiposRelacoes
        .slice()
        .sort((itemA, itemB) => relacaoTipoValueToChecklistOrder(itemA) - relacaoTipoValueToChecklistOrder(itemB))
        .map(item => relacaoTipoValueToDescription(item))
        .join(separator)
}
