import { createValidator, required, hora, maxDate } from 'libs/validation'

export const ProcedCirurgicoAtualPrimValidator = createValidator(
    {
        horaInicio: [required, hora],
        horaTermino: [required, hora],
        dataCirurgia: [required, maxDate],
        abordagemCirurgica: [required],
        usoDreno: [required],
        transfusaoSanguinea: [required],
        possuiAntibiotico: [required],
    },
    formValidator
)

function formValidator(form) {
    const errors = {}
    if (form.get('transfusaoSanguinea') === 'SIM' && !form.get('tipoTransfusaoSanguinea')) {
        errors.transfusaoSanguinea = 'Preencha a subopção'
    }
    if (form.get('possuiAntibiotico') === 'SIM') {
        errors.antibiotico = required(form.get('antibiotico'))
        errors.periodoAntibiotico = required(form.get('periodoAntibiotico'))
    }
    if (form.get('horaInicio') && form.get('horaInicio') === form.get('horaTermino')) {
        errors.horaInicio = 'Início e término não podem ser iguais'
        errors.horaTermino = 'Início e término não podem ser iguais'
    }
    return errors
}
