import Visualizacao from 'api/Visualizacao'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'
import {
    VisualizacaoPrimariaQuadrilForm,
    VisualizacaoRevisaoQuadrilForm,
    VisualizacaoPrimariaJoelhoForm,
    VisualizacaoRevisaoJoelhoForm,
    VisualizacaoStentCoronariaForm,
    ProcedimentoRow,
} from 'backend'
import Procedimento from 'api/Procedimento'

export const FIND_VISUALIZACAO = 'rni/procedimento/FIND_VISUALIZACAO'
export const FIND_DADOS_PRODUTO = 'rni/procedimento/FIND_DADOS_PRODUTO'
export const FIND_DADOS_CLINICOS = 'rni/procedimento/FIND_DADOS_CLINICOS'
export const FIND_DADOS_FINANCEIROS = 'rni/procedimento/FIND_DADOS_FINANCEIROS'
export const CLEAR = 'rni/procedimento/CLEAR'

export interface ProcedimentoState
    extends Readonly<{
        visualizacaoProcedimento: PromiseRecord<
            | VisualizacaoPrimariaQuadrilForm
            | VisualizacaoRevisaoQuadrilForm
            | VisualizacaoPrimariaJoelhoForm
            | VisualizacaoRevisaoJoelhoForm
            | VisualizacaoStentCoronariaForm
        >
        dadosProduto: PromiseRecord<ProcedimentoRow[]>
        dadosClinicos: PromiseRecord<ProcedimentoRow[]>
        dadosFinanceiros: PromiseRecord<ProcedimentoRow[]>
    }> {}

const ProcedimentoInitialState: ProcedimentoState = {
    visualizacaoProcedimento: {},
    dadosProduto: {},
    dadosClinicos: {},
    dadosFinanceiros: {},
}

export default function reducer(state: ProcedimentoState = ProcedimentoInitialState, action): ProcedimentoState {
    switch (action.type) {
        case FIND_VISUALIZACAO:
            return { ...state, visualizacaoProcedimento: promiseReducer(state.visualizacaoProcedimento as any, action) }
        case FIND_DADOS_PRODUTO:
            return { ...state, dadosProduto: promiseReducer(state.dadosProduto as any, action) }
        case FIND_DADOS_CLINICOS:
            return { ...state, dadosClinicos: promiseReducer(state.dadosClinicos as any, action) }
        case FIND_DADOS_FINANCEIROS:
            return { ...state, dadosFinanceiros: promiseReducer(state.dadosFinanceiros as any, action) }
        case CLEAR:
            return { visualizacaoProcedimento: {}, dadosProduto: {}, dadosClinicos: {}, dadosFinanceiros: {} }
        default:
            return state
    }
}

export const findVisualizacao = (registro: number, urlProcedimento: string) => ({
    type: FIND_VISUALIZACAO,
    promise: Visualizacao.getVisualizacaoProcedimento(registro, urlProcedimento),
})

export const getAguardandoDadosProduto = () => ({
    type: FIND_DADOS_PRODUTO,
    promise: Procedimento.getAguardandoDadosProduto(),
})

export const getAguardandoDadosClinicos = () => ({
    type: FIND_DADOS_CLINICOS,
    promise: Procedimento.getAguardandoDadosClinicos(),
})

export const getAguardandoDadosFinanceiros = () => ({
    type: FIND_DADOS_FINANCEIROS,
    promise: Procedimento.getAguardandoDadosFinanceiros(),
})

export const clear = () => ({
    type: CLEAR,
})
