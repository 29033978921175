import * as React from 'react'
import { AlertaAumentoRegistroConfigForm, TipoPeriodoAlertaEnum } from 'backend'
import AlertaConfigModalForm, {
    PublicAlertaConfigModalFormProps,
} from 'view/home/anvisa/alertas/components/AlertaConfigModalForm'
import { InputPanel } from 'libs/components/presentational/form/InputPanel'
import { RadioField, NumberField, FormField, FormFieldProps } from 'libs/components'
import { Columns, Column, ControlField } from 'libs/components'
import { connect } from 'react-redux'
import { State } from 'redux/modules'
import { formValueSelector } from 'redux-form/immutable'
import AumentoRegistroModalFormValidator from './AumentoRegistroModalFormValidator'
import { Field } from 'redux-form/immutable'
import { change } from 'redux-form'

const formName = 'configuracaoAlertaAumentoRegistro'

interface AumentoRegistroModalFormProps extends PublicAlertaConfigModalFormProps {
    config: AlertaAumentoRegistroConfigForm
}

export default class AumentoRegistroModalForm extends React.PureComponent<AumentoRegistroModalFormProps> {
    render() {
        return (
            <AlertaConfigModalForm
                formName={formName}
                validate={AumentoRegistroModalFormValidator}
                {...this.props}
                renderChildren={ativo => (
                    <div>
                        <IndiceControl name='tipoPeriodo' label='Índice de aumento (%)' required />
                        <ConnectedOption label='Mensal' value='MENSAL' ativo={ativo} />
                        <ConnectedOption label='Bimestral' value='BIMESTRAL' ativo={ativo} />
                        <ConnectedOption label='Trimestral' value='TRIMESTRAL' ativo={ativo} />
                        <ConnectedOption label='Semestral' value='SEMESTRAL' ativo={ativo} />
                        <ConnectedOption label='Anual' value='ANUAL' ativo={ativo} />
                        <br />
                        <ControlField name='emRelacaoA' label='Em relação à' required>
                            <RadioField
                                name='emRelacaoA'
                                label='Período anterior'
                                value='PERIODO_ANTERIOR'
                                disabled={!ativo}
                            />
                            <RadioField
                                name='emRelacaoA'
                                label='Mesmo período do ano anterior'
                                value='MESMO_PERIODO_ANO_ANTERIOR'
                                disabled={!ativo}
                            />
                        </ControlField>
                    </div>
                )}
            />
        )
    }
}

const IndiceControl = (props: FormFieldProps) => {
    return (
        <Field
            {...props}
            component={props => {
                return <FormField error={props.meta.error} {...props} />
            }}
        />
    )
}

interface OptionProps {
    ativo: boolean
    label: string
    tipoPeriodoSelecionado: TipoPeriodoAlertaEnum
    value: TipoPeriodoAlertaEnum
    handleValueChange(value): void
}

class Option extends React.PureComponent<OptionProps> {
    render() {
        return (
            <InputPanel size='normal'>
                <Columns>
                    <Column>
                        <RadioField
                            name='tipoPeriodo'
                            label={this.props.label}
                            value={this.props.value}
                            disabled={!this.props.ativo}
                            onChange={this.props.handleValueChange}
                        />
                    </Column>
                    <Column className='flex-0'>
                        <div
                            style={{
                                display: this.props.tipoPeriodoSelecionado === this.props.value ? 'block' : 'none',
                            }}
                        >
                            <PercentualField disabled={!this.props.ativo} />
                        </div>
                    </Column>
                </Columns>
            </InputPanel>
        )
    }
}

const PercentualField = ({ disabled }) => {
    return (
        <div className='is-children-center'>
            <div style={{ marginRight: '5px', width: '43px' }}>
                <NumberField name='percentual' maxLength={3} disabled={disabled} />
            </div>
            <p>%</p>
        </div>
    )
}

const ConnectedOption = connect(
    (state: State) => {
        const selector = formValueSelector(formName)
        const tipoPeriodo = selector(state, 'tipoPeriodo')

        return {
            tipoPeriodoSelecionado: tipoPeriodo,
        }
    },
    dispatch => ({
        handleValueChange: () => {
            dispatch(change(formName, 'percentual', null, true, true))
        },
    })
)(Option)
