import * as React from 'react'
import { Page } from 'redux/middleware/PaginationAction'
import { Paginator, Card, Column, Columns, Icon } from 'libs/components'
import { PacienteReportDto, SumarioProcedimentoDto, TipoProcedimento, BuscaPacienteReportFiltro } from 'backend'
import Text from 'libs/components/presentational/typography/Text'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { RouteComponentProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import BuscaRow from './components/BuscaRow'
import { BuscaPacienteRow } from './components/BuscaPacienteRow'
import { stringify } from 'redux/modules/router'

interface State {
    detail: number
}

export interface BuscaProcedimentoListProps extends RouteComponentProps<any> {
    filtro: BuscaPacienteReportFiltro
    isFetching: boolean
    pacientes: Page<PacienteReportDto>
    procedimentos: PromiseRecord<SumarioProcedimentoDto[]>
    buscaProcedimentos(filtro: BuscaPacienteReportFiltro): void
    handlePageChange(page: number): void
    visualizarFicha(idProcedimento: number, proced: TipoProcedimento): void
}

export class BuscaProcedimentoListView extends React.Component<BuscaProcedimentoListProps, State> {
    constructor(props) {
        super(props)
        this.state = { detail: undefined }
    }

    render() {
        const { pacientes } = this.props
        return (
            <Card title={this.renderCardTitle()} headerAction={this.renderDownloadIcon()} noPadding>
                <BuscaRow>
                    <Columns>
                        <Column size='5'>
                            <Text size='small-bis' color='grey-darker' weight='semibold'>
                                Nome
                            </Text>
                        </Column>
                        <Column size='2'>
                            <Text size='small-bis' color='grey-darker' weight='semibold'>
                                Sexo
                            </Text>
                        </Column>
                        <Column size='2'>
                            <Text size='small-bis' color='grey-darker' weight='semibold'>
                                Proced.
                            </Text>
                        </Column>
                        <Column size='2'>
                            <Text size='small-bis' color='grey-darker' weight='semibold'>
                                Último proced.
                            </Text>
                        </Column>
                    </Columns>
                </BuscaRow>
                {pacientes.content.map(paciente => {
                    return (
                        <BuscaPacienteRow
                            key={paciente.pacienteId}
                            paciente={paciente}
                            openDetail={this.openDetail}
                            detail={paciente.pacienteId === this.state.detail}
                            closeDetail={this.closeDetail}
                            {...this.props}
                        />
                    )
                })}
                <BuscaRow className='busca-page-footer'>
                    <Paginator
                        first={pacientes.first}
                        last={pacientes.last}
                        pageNumber={pacientes.number}
                        totalPages={pacientes.totalPages}
                        onChange={this.props.handlePageChange}
                    />
                </BuscaRow>
            </Card>
        )
    }

    renderDownloadIcon() {
        return (
            <Link
                to={{
                    pathname: '/api/report/buscaPacientes/csv',
                    search: stringify(this.props.filtro),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }

    renderCardTitle() {
        return (
            <Text color='black-bis' size='medium' weight='semibold' transform='lowercase'>
                {this.props.pacientes.totalElements} pacientes &nbsp;
            </Text>
        )
    }

    openDetail = (id: number) => {
        this.setState({ detail: id })
        this.props.buscaProcedimentos({ ...this.props.filtro, pacienteId: id })
    }

    closeDetail = () => {
        this.setState({ detail: undefined })
    }
}
