import { promiseReducer, PromiseRecord } from 'redux/middleware/PromiseAction'
import Procedimento from 'api/Procedimento'
import SolicitacaoAcesso from 'api/SolicitacaoAcesso'
import { SelecaoPerfilDto, HistoricoProcedimentoDetailRow } from 'backend'

export const FIND_DETAIL_HISTORICO_POPOVER = 'rni/pendencias/FIND_DETAIL_HISTORICO_POPOVER'

// Home Gestor

export const APROVAR_SOLICITACAO_VINCULO = 'rni/home/gestor/APROVAR_SOLICITACAO_VINCULO'
export const DESATIVAR_VINCULO = 'rni/home/gestor/DESATIVAR_VINCULO'
export const REATIVAR_VINCULO = 'rni/home/gestor/REATIVAR_VINCULO'
export const DESATIVAR_VINCULO_PROPRIO = 'rni/home/gestor/DESATIVAR_VINCULO_PROPRIO'

export interface HomeState
    extends Readonly<{
        detailHistoricoPopover: PromiseRecord<HistoricoProcedimentoDetailRow[]>
        // Home Gestor
        aprovarSolicitacaoVinculo: PromiseRecord<any>
        desativarVinculo: PromiseRecord<any>
        reativarVinculo: PromiseRecord<any>
    }> {}

export const HomeInitialState: HomeState = {
    detailHistoricoPopover: {},
    // Home Gestor
    aprovarSolicitacaoVinculo: {},
    desativarVinculo: {},
    reativarVinculo: {},
}

export default function reducer(state = HomeInitialState, action): HomeState {
    switch (action.type) {
        case FIND_DETAIL_HISTORICO_POPOVER:
            return { ...state, detailHistoricoPopover: promiseReducer(state.detailHistoricoPopover, action) }
        // Home Gestor
        case APROVAR_SOLICITACAO_VINCULO:
            return { ...state, aprovarSolicitacaoVinculo: promiseReducer(state.aprovarSolicitacaoVinculo, action) }
        case DESATIVAR_VINCULO:
            return { ...state, desativarVinculo: promiseReducer(state.desativarVinculo, action) }
        case DESATIVAR_VINCULO_PROPRIO:
            return { ...state, desativarVinculo: promiseReducer(state.desativarVinculo, action) }
        case REATIVAR_VINCULO:
            return { ...state, reativarVinculo: promiseReducer(state.reativarVinculo, action) }
        default:
            return state
    }
}

export const findHistoricoDetail = (registro: number) => ({
    type: FIND_DETAIL_HISTORICO_POPOVER,
    promise: Procedimento.getHistoricoDetail(registro),
})

export const findHistoricoVersaoDetail = (registro: number) => ({
    type: FIND_DETAIL_HISTORICO_POPOVER,
    promise: Procedimento.getHistoricoVersaoDetail(registro),
})

// Home Gestor

export const aprovarSolicitacaoVinculo = (solicitacaoVinculoId: number) => ({
    type: APROVAR_SOLICITACAO_VINCULO,
    promise: SolicitacaoAcesso.aprovarSolicitacaoVinculo(solicitacaoVinculoId),
})

export const desativarVinculo = (vinculoId: number) => ({
    type: DESATIVAR_VINCULO,
    promise: SolicitacaoAcesso.desativarVinculo(vinculoId),
})

export const desativarVinculoProprio = (perfil: SelecaoPerfilDto) => ({
    type: DESATIVAR_VINCULO_PROPRIO,
    promise: SolicitacaoAcesso.desativarVinculoProprio(perfil),
})

export const reativarVinculo = (perfil: SelecaoPerfilDto) => ({
    type: REATIVAR_VINCULO,
    promise: SolicitacaoAcesso.reativarVinculo(perfil),
})
