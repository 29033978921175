import axios, { AxiosPromise } from 'axios'
import { StentDto } from 'backend'

class Componentes {
    loadStents(): AxiosPromise<StentDto[]> {
        return axios.get('/api/componente/stents')
    }

    saveStent(form: StentDto): AxiosPromise<StentDto> {
        return axios.post('/api/componente/saveStent', form)
    }
}

export default new Componentes()
