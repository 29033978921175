import React from 'react'
import * as C from 'libs/components'
import form from 'libs/redux-form/form'
import { createValidator, required } from 'libs/validation'

const validator = createValidator({
    motivo: required,
})

class MotivoRecusaModalCmp extends React.Component {
    render() {
        return (
            <C.ModalCard active={this.props.active} title='Justificativa para rejeição'>
                <C.Form {...this.props}>
                    <C.ControlField name='motivo' label='Justificativa' required verticalFlow>
                        <span className='modal-subtitle'>
                            Informe abaixo uma justificativa para a rejeição do vínculo solicitado. Essa justificativa
                            será enviada ao usuário por e-mail.
                        </span>
                        <C.RadioField
                            name='motivo'
                            label='O profissional não trabalha neste hospital'
                            value='NAO_TRABALHA_HOSPITAL'
                        />
                        <C.RadioField
                            name='motivo'
                            label='O perfil solicitado não condiz com a função do profissional no hospital'
                            value='PERFIL_DIFERENTE_FUNCAO'
                        />
                        <C.RadioField
                            name='motivo'
                            label='Os dados do cadastro estão incompletos/incorretos'
                            value='DADOS_INCOMPLETOS_INCORRETOS'
                        />
                    </C.ControlField>
                    <C.AlertModalButtonBar>
                        <C.Button size='medium' onClick={this.props.onClose}>
                            Cancelar
                        </C.Button>
                        <C.Button
                            type='primary'
                            size='medium'
                            onClick={this.props.handleSubmit}
                            loading={this.props.submitting}
                        >
                            Salvar
                        </C.Button>
                    </C.AlertModalButtonBar>
                </C.Form>
            </C.ModalCard>
        )
    }
}

export const MotivoRecusaModal = form({
    form: 'motivoRecusa',
    hasLeaveModal: false,
    hasSuccessModal: false,
    validate: validator,
})(MotivoRecusaModalCmp)
