import * as React from 'react'
import { DistribuicaoProcedimentoFiltro, DateInterval, DistribuicaoProdutosProcedimentosHospitalDto } from 'backend'
import {
    DistClassificacaoPeriodo,
    DistClassificacaoSexo,
    DistClassificacaoIdade,
    DistClassificacao,
} from 'redux/modules/report/procedimento'
import { PeriodoProcedimentoChartCard, SexoCard, FaixaEtariaChartCard } from 'view/componentes/reports/cards/'
import { Link, RouteComponentProps } from 'react-router-dom'
import UrlParams from 'libs/router/UrlParams'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import { DadosUsoCard } from 'view/componentes/reports/cards/DadosUsoCard'
import { NavLink } from 'react-router-dom'
import { MotivoReportHeader } from 'view/componentes/reports/components/MotivoReportHeader'
import { Column, Columns, Icon, PeriodoFilter, ScrollToTop, Text } from 'libs/components'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { ResultadoProdutosComRegistro } from 'view/componentes/reports/fragments/ResultadoProdutosComRegistro'
import { ResultadoProdutosSemRegistro } from 'view/componentes/reports/fragments/ResultadoProdutosSemRegistro'
import { ResultadoProdutosTotal } from 'view/componentes/reports/fragments/ResultadoProdutosTotal'
import { RequestState } from 'redux/requester'
import { Page } from 'redux/middleware/PaginationAction'
import { stringify } from 'redux/modules/router'

export interface Props extends RouteComponentProps<any> {
    filtro: DistribuicaoProcedimentoFiltro
    distClassificacao: DistClassificacao
    distClassificacaoPeriodo: DistClassificacaoPeriodo
    distClassificacaoSexo: DistClassificacaoSexo
    distClassificacaoIdade: DistClassificacaoIdade

    produtosRegistro: RequestState<Page<DistribuicaoProdutosProcedimentosHospitalDto>>
    produtosSemRegistro: RequestState<Page<DistribuicaoProdutosProcedimentosHospitalDto>>
    isFetchingProdutosRegistro: any
    isFetchingProdutosSemRegistro: any
    changeFilter(filtro: DistribuicaoProcedimentoFiltro): void
    loadDetail(filter: DistribuicaoProcedimentoFiltro): void
    handlePageChangeRegistro(page: number): void
    handlePageChangeSemRegistro(page: number): void
}

export class MotivosProcedimentoDashboardView extends React.Component<Props> {
    voltar = () => {
        this.props.history.push({
            pathname: '/home/relatorios/motivos',
            search: this.props.location.search,
        })
    }

    handlePeriodoChange = (interval: DateInterval) => {
        this.props.changeFilter({
            ...this.props.filtro,
            interval: interval,
        })
    }

    refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        this.props.loadDetail(filter)
    }

    renderDownloadIconComRegistro = () => {
        const currentFilter = this.props.filtro || {}
        currentFilter.motivo = this.props.match.params.motivo
        return (
            <Link
                to={{
                    pathname: '/api/report/distribuicaoProdutosProcedimentosHospitalRegistro/csv',
                    search: stringify(currentFilter),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }

    renderDownloadIconSemRegistro = () => {
        const currentFilter = this.props.filtro || {}
        currentFilter.motivo = this.props.match.params.motivo
        return (
            <Link
                to={{
                    pathname: '/api/report/distribuicaoProdutosProcedimentosHospitalSemRegistro/csv',
                    search: stringify(currentFilter),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }

    render() {
        const { ...publishFilter } = this.props.filtro
        return (
            <div>
                <ScrollToTop onMount />
                <UrlParams params={publishFilter} onChange={this.refresh} />
                <ReportClearFilter />

                <LocalHeaderContainer />
                <MotivoReportHeader motivo={this.props.match.params.motivo} onVoltar={this.voltar} />
                <div className='report-content'>
                    <Columns className='report-tabs align-center'>
                        <Column size='6'>
                            <div className='report-tabs-links'>
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}`,
                                        search: this.props.location.search,
                                    }}
                                    activeClassName='is-active'
                                >
                                    Dashboard
                                </NavLink>
                            </div>
                        </Column>
                        <Column size='6' textRight>
                            <PeriodoFilter value={this.props.filtro.interval} onChange={this.handlePeriodoChange} />
                        </Column>
                    </Columns>

                    <Columns auto>
                        <div className='flex-6'>
                            <DadosUsoCard
                                distClassificacao={this.props.distClassificacao}
                                showStatus={true}
                                title='Número de procedimentos'
                            />
                        </div>
                        <div className='flex-4'>
                            <SexoCard dist={this.props.distClassificacaoSexo} />
                        </div>
                    </Columns>

                    <Columns auto>
                        <PeriodoProcedimentoChartCard dist={this.props.distClassificacaoPeriodo} />
                    </Columns>

                    <Columns auto>
                        <FaixaEtariaChartCard dist={this.props.distClassificacaoIdade} />
                    </Columns>
                    <Columns auto>
                        <FaixaEtariaChartCard dist={this.props.distClassificacaoIdade} />
                    </Columns>
                    <Columns auto>
                        <ResultadoProdutosTotal
                            handlePageChange={this.props.handlePageChangeRegistro}
                            isFetching={this.props.isFetchingProdutosRegistro}
                            produtosRegistro={this.props.produtosRegistro}
                            produtosSemRegistro={this.props.produtosSemRegistro}
                        />
                    </Columns>

                    <Columns auto>
                        <ResultadoProdutosComRegistro
                            handlePageChange={this.props.handlePageChangeRegistro}
                            isFetching={this.props.isFetchingProdutosRegistro}
                            produtos={this.props.produtosRegistro}
                            report={this.renderDownloadIconComRegistro}
                            showDetalheProduto={false}
                        />
                    </Columns>

                    <Columns auto>
                        <ResultadoProdutosSemRegistro
                            handlePageChange={this.props.handlePageChangeSemRegistro}
                            isFetching={this.props.isFetchingProdutosSemRegistro}
                            produtos={this.props.produtosSemRegistro}
                            report={this.renderDownloadIconSemRegistro}
                            showDetalheProduto={false}
                        />
                    </Columns>
                </div>
            </div>
        )
    }
}
