import Report from 'api/Report'
import {
    UsoSistemaFiltro,
    UsoSistemaHospitaisNacionalDto,
    UsoSistemaHospitaisRegiaoDto,
    UsoSistemaProfissionaisDto,
    UsoSistemaRegistrosFinalizadosDto,
    UsoSistemaStatusProcedsDto,
    UsoSistemaStatusProcedsNacionalDto,
} from 'backend'
import { SeriesMapDataOptions } from 'highcharts'
import * as enums from 'redux/enums'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'
import { groupFormatter } from './procedimento'

export const FIND_PROFISSIONAIS = 'rni/report/usoSistema/FIND_PROFISSIONAIS'
export const FIND_MEDIA_TEMPO = 'rni/report/usoSistema/FIND_MEDIA_TEMPO'
export const FIND_REGISTROS_FINALIZADOS = 'rni/report/usoSistema/FIND_REGISTROS_FINALIZADOS'

export const FIND_HOSPITAIS_NACIONAL = 'rni/report/usoSistema/FIND_HOSPITAIS_NACIONAL'
export const FIND_HOSPITAIS_REGIAO = 'rni/report/usoSistema/FIND_HOSPITAIS_REGIAO'
export const FIND_HOSPITAIS_UF = 'rni/report/usoSistema/FIND_HOSPITAIS_UF'
export const FIND_HOSPITAIS_CIDADE = 'rni/report/usoSistema/FIND_HOSPITAIS_CIDADE'

export const FIND_STATUS_REG_NACIONAL = 'rni/report/usoSistema/FIND_STATUS_REG_NACIONAL'
export const FIND_STATUS_REG_REGIAO = 'rni/report/usoSistema/FIND_STATUS_REG_REGIAO'
export const FIND_STATUS_REG_UF = 'rni/report/usoSistema/FIND_STATUS_REG_UF'
export const FIND_STATUS_REG_CIDADE = 'rni/report/usoSistema/FIND_STATUS_REG_CIDADE'
export const FIND_STATUS_REG_HOSPITAL = 'rni/report/usoSistema/FIND_STATUS_REG_HOSPITAL'

export const CLEAR_ALL = 'rni/report/usoSistema/CLEAR_ALL'

// SEMI COMMON
export interface DistLocalEtapa extends PromiseRecord<UsoSistemaStatusProcedsDto> {
    result?: {
        categories: any[]
        series: { name: string; data: any[]; color: string }[]
    }
}

// COMMON
export interface DistTempoMedio extends PromiseRecord<{ [index: string]: number }> {
    result?: {
        categories: any[]
        series: { name: string; data: any[]; color: string }[]
    }
}

export interface DistFinalizadas extends PromiseRecord<UsoSistemaRegistrosFinalizadosDto> {
    result?: {
        categories: any[]
        series: { name: string; data: any[]; color: string }[]
    }
}

// NATIONAL
export interface DistHospRegiao extends PromiseRecord<UsoSistemaHospitaisNacionalDto> {
    result?: {
        series?: {
            data: SeriesMapDataOptions
        }[]
    }
}

export interface DistLocalEtapaNacional extends PromiseRecord<UsoSistemaStatusProcedsNacionalDto> {
    result?: {
        categories: any[]
        series: { name: string; data: any[]; color: string }[]
    }
}

// REGION
export interface DistHospEstado extends PromiseRecord<UsoSistemaHospitaisRegiaoDto> {
    result?: {
        series?: {
            data: SeriesMapDataOptions
        }[]
    }
}

export interface UsoSistemaReportState
    extends Readonly<{
        // nacional
        hospitaisNacional: PromiseRecord<UsoSistemaHospitaisNacionalDto>
        distHospRegiao: DistHospRegiao

        statusNacional: PromiseRecord<UsoSistemaStatusProcedsNacionalDto>
        distLocalEtapaNacional: DistLocalEtapaNacional

        // regiao
        hospitaisRegiao: PromiseRecord<UsoSistemaHospitaisRegiaoDto>
        distHospEstado: DistHospEstado

        // uf
        hospitaisUf: PromiseRecord<number>
        hospitaisCidade: PromiseRecord<number>

        // todos
        profissionais: PromiseRecord<UsoSistemaProfissionaisDto>

        tempoMedio: PromiseRecord<{ [index: string]: number }>
        distTempoMedio: DistTempoMedio

        finalizadas: PromiseRecord<UsoSistemaRegistrosFinalizadosDto>
        distFinalizadas: DistFinalizadas

        statusGeral: PromiseRecord<UsoSistemaStatusProcedsDto>
        distLocalEtapa: DistLocalEtapa
    }> {}

const UsoSistemaInitialState: UsoSistemaReportState = {
    hospitaisNacional: {},
    distHospRegiao: {},
    statusNacional: {},
    distLocalEtapaNacional: {},
    hospitaisRegiao: {},
    distHospEstado: {},
    hospitaisUf: {},
    hospitaisCidade: {},
    profissionais: {},
    tempoMedio: {},
    distTempoMedio: {},
    finalizadas: {},
    distFinalizadas: {},
    statusGeral: {},
    distLocalEtapa: {},
}

export default function reducer(state: UsoSistemaReportState = UsoSistemaInitialState, action): UsoSistemaReportState {
    switch (action.type) {
        case FIND_HOSPITAIS_NACIONAL:
            return {
                ...state,
                distHospRegiao: reduceDistHospRegiao(promiseReducer(state.hospitaisNacional, action)),
                hospitaisNacional: promiseReducer(state.hospitaisNacional, action),
            }
        case FIND_HOSPITAIS_REGIAO:
            return {
                ...state,
                distHospEstado: reduceDistHospEstado(promiseReducer(state.hospitaisRegiao, action)),
                hospitaisRegiao: promiseReducer(state.hospitaisRegiao, action),
            }
        case FIND_HOSPITAIS_UF:
            return {
                ...state,
                hospitaisUf: promiseReducer(state.hospitaisUf, action),
            }
        case FIND_HOSPITAIS_CIDADE:
            return {
                ...state,
                hospitaisCidade: promiseReducer(state.hospitaisCidade, action),
            }
        case FIND_PROFISSIONAIS:
            return {
                ...state,
                profissionais: promiseReducer(state.profissionais, action),
            }
        case FIND_MEDIA_TEMPO:
            return {
                ...state,
                distTempoMedio: reduceDistTempoMedio(promiseReducer(state.tempoMedio, action)),
            }
        case FIND_REGISTROS_FINALIZADOS:
            return {
                ...state,
                distFinalizadas: reduceDistFinalizadas(promiseReducer(state.finalizadas, action)),
            }
        case FIND_STATUS_REG_NACIONAL:
            return {
                ...state,
                distLocalEtapaNacional: reduceDistLocalEtapaNacional(promiseReducer(state.statusNacional, action)),
                statusNacional: promiseReducer(state.statusNacional, action),
            }
        case FIND_STATUS_REG_REGIAO:
            return {
                ...state,
                distLocalEtapa: reduceDistLocalidadeEtapa(promiseReducer(state.statusGeral, action)),
                statusGeral: promiseReducer(state.statusGeral, action),
            }
        case FIND_STATUS_REG_UF:
        case FIND_STATUS_REG_CIDADE:
        case FIND_STATUS_REG_HOSPITAL:
            return {
                ...state,
                statusGeral: promiseReducer(state.statusGeral, action),
            }
        case CLEAR_ALL:
            return {
                ...UsoSistemaInitialState,
            }
        default:
            return state
    }
}

export const getDadosUsoSistema = (filtro: UsoSistemaFiltro) => dispatch => {
    dispatch(findHospitaisNacional(filtro))
    dispatch(findProfissionais(filtro))
    dispatch(findTempoMedio(filtro))
    dispatch(findStatusNacional(filtro))
    dispatch(findRegistrosFinalizados(filtro))
}

export const getDadosUsoSistemaRegiao = (filtro: UsoSistemaFiltro) => dispatch => {
    dispatch(findHospitaisRegiao(filtro))
    dispatch(findProfissionais(filtro))
    dispatch(findTempoMedio(filtro))
    dispatch(findStatusRegiao(filtro))
    dispatch(findRegistrosFinalizados(filtro))
}

export const getDadosUsoSistemaUf = (filtro: UsoSistemaFiltro) => dispatch => {
    dispatch(findHospitaisUf(filtro))
    dispatch(findProfissionais(filtro))
    dispatch(findTempoMedio(filtro))
    dispatch(findStatusUf(filtro))
    dispatch(findRegistrosFinalizados(filtro))
}

export const getDadosUsoSistemaCidade = (filtro: UsoSistemaFiltro) => dispatch => {
    dispatch(findHospitaisCidade(filtro))
    dispatch(findProfissionais(filtro))
    dispatch(findTempoMedio(filtro))
    dispatch(findStatusCidade(filtro))
    dispatch(findRegistrosFinalizados(filtro))
}

export const getDadosUsoSistemaHospital = (filtro: UsoSistemaFiltro) => dispatch => {
    dispatch(findProfissionais(filtro))
    dispatch(findTempoMedio(filtro))
    dispatch(findStatusHospital(filtro))
    dispatch(findRegistrosFinalizados(filtro))
}

export const findHospitaisNacional = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_HOSPITAIS_NACIONAL,
        promise: Report.getUsoSistemaHospitaisNacional(filtro),
    }
}

export const findHospitaisRegiao = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_HOSPITAIS_REGIAO,
        promise: Report.getUsoSistemaHospitaisRegiao(filtro),
    }
}

export const findHospitaisUf = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_HOSPITAIS_UF,
        promise: Report.getUsoSistemaHospitaisUf(filtro),
    }
}

export const findHospitaisCidade = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_HOSPITAIS_CIDADE,
        promise: Report.getUsoSistemaHospitaisCidade(filtro),
    }
}

export const findStatusNacional = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_STATUS_REG_NACIONAL,
        promise: Report.getUsoSistemaStatusRegistrosNacional(filtro),
    }
}

export const findStatusRegiao = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_STATUS_REG_REGIAO,
        promise: Report.getUsoSistemaStatusRegistrosRegiao(filtro),
    }
}

export const findStatusUf = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_STATUS_REG_UF,
        promise: Report.getUsoSistemaStatusRegistrosUf(filtro),
    }
}

export const findStatusCidade = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_STATUS_REG_CIDADE,
        promise: Report.getUsoSistemaStatusRegistrosCidade(filtro),
    }
}

export const findStatusHospital = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_STATUS_REG_HOSPITAL,
        promise: Report.getUsoSistemaStatusRegistrosHospital(filtro),
    }
}

export const findProfissionais = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_PROFISSIONAIS,
        promise: Report.getUsoSistemaProfissionais(filtro),
    }
}

export const findTempoMedio = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_MEDIA_TEMPO,
        promise: Report.getUsoSistemaMediaTempo(filtro),
    }
}

export const findRegistrosFinalizados = (filtro: UsoSistemaFiltro) => {
    return {
        type: FIND_REGISTROS_FINALIZADOS,
        promise: Report.getUsoSistemaRegistrosFinalizados(filtro),
    }
}

export const clearAll = () => {
    return {
        type: CLEAR_ALL,
    }
}

// NATIONAL
export const reduceDistLocalEtapaNacional = (
    promiseRecord: PromiseRecord<UsoSistemaStatusProcedsNacionalDto>
): DistLocalEtapaNacional => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }
    const data = promiseRecord.data
    const result = {
        categories: [],
        series: [],
    }

    if (Object.keys(data.regiaoEtapa).length > 0) {
        result.categories = Object.keys(data.regiaoEtapa).map(regiao => ({
            regiao: regiao,
            name: enums.regiaoName[regiao],
        }))

        result.series = Object.keys(enums.statusProcedimentos).map(status => ({
            name: enums.statusProcedimentos[status].name,
            data: [],
            color: enums.statusProcedimentos[status].color,
        }))

        result.categories.forEach(cat => {
            Object.keys(enums.statusProcedimentos).forEach(statis => {
                const index = result.series.map(s => s.name).indexOf(enums.statusProcedimentos[statis].name)
                const total = data.regiaoEtapa[cat.regiao][statis]
                if (total) {
                    result.series[index].data.push([cat, total])
                } else {
                    result.series[index].data.push([cat, 0])
                }
            })
        })
        result.series.splice(result.series.indexOf('FINALIZADO'), 1)
    }
    return {
        ...promiseRecord,
        result: result,
    }
}

export const reduceDistHospRegiao = (promiseRecord: PromiseRecord<UsoSistemaHospitaisNacionalDto>): DistHospRegiao => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }
    const result = {
        series: [],
    }

    const data = promiseRecord.data
    if (Object.keys(data.hospitaisRegiao).length > 0) {
        result.series = [
            {
                data: Object.keys(data.hospitaisRegiao).map(reg => ({
                    name: enums.regiaoName[reg],
                    y: data.hospitaisRegiao[reg],
                    color: enums.regiaoColor[reg],
                })),
            },
        ]
    }

    return {
        ...promiseRecord,
        result: result,
    }
}

// REGION
export const reduceDistHospEstado = (promiseRecord: PromiseRecord<UsoSistemaHospitaisRegiaoDto>): DistHospEstado => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }
    const result = {
        series: [],
    }

    const data = promiseRecord.data
    if (Object.keys(data.hospitaisEstado).length > 0) {
        result.series = [
            {
                data: Object.keys(data.hospitaisEstado).map(reg => ({
                    name: enums.ufName[reg],
                    y: data.hospitaisEstado[reg],
                    color: enums.ufColor[reg],
                })),
            },
        ]
    }

    return {
        ...promiseRecord,
        result: result,
    }
}

export const reduceDistLocalidadeEtapa = (promiseRecord: PromiseRecord<UsoSistemaStatusProcedsDto>): DistLocalEtapa => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }
    const data = promiseRecord.data
    const result = {
        categories: [],
        series: [],
    }

    if (Object.keys(data.localidadeEtapa).length > 0) {
        result.categories = Object.keys(data.localidadeEtapa).map(uf => ({
            uf: uf,
            name: enums.ufName[uf],
        }))

        result.series = Object.keys(enums.statusProcedimentos).map(status => ({
            name: enums.statusProcedimentos[status].name,
            data: [],
            color: enums.statusProcedimentos[status].color,
        }))

        result.categories.forEach(cat => {
            Object.keys(enums.statusProcedimentos).forEach(statis => {
                const index = result.series.map(s => s.name).indexOf(enums.statusProcedimentos[statis].name)
                const total = data.localidadeEtapa[cat.uf][statis]
                if (total) {
                    result.series[index].data.push([cat, total])
                } else {
                    result.series[index].data.push([cat, 0])
                }
            })
        })
        result.series.splice(result.series.indexOf('FINALIZADO'), 1)
    }
    return {
        ...promiseRecord,
        result: result,
    }
}

// COMMON
export const reduceDistFinalizadas = (
    promiseRecord: PromiseRecord<UsoSistemaRegistrosFinalizadosDto>
): DistFinalizadas => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }

    const data = promiseRecord.data
    const result = {
        categories: [],
        series: [],
    }

    if (data.registrosFinalizados.length > 0) {
        const periodos = data.registrosFinalizados
        result.categories = periodos.map(value => groupFormatter[data.timeGranularity](value.periodo))
        result.series = [
            {
                name: 'Registros finalizados',
                data: [],
                color: '#0099ff',
            },
        ]

        periodos.forEach(periodo => {
            const category = groupFormatter[data.timeGranularity](periodo.periodo)
            result.series[0].data.push([category, periodo.total])
        })
    }

    return {
        ...promiseRecord,
        result: result,
    }
}

export const reduceDistTempoMedio = (promiseRecord: PromiseRecord<{ [index: string]: number }>): DistTempoMedio => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }
    const data = promiseRecord.data
    const result = {
        categories: [],
        series: [],
    }
    if (Object.keys(data).length > 0) {
        result.categories = Object.keys(data).map(itm => enums.tempoMedio[itm])
        result.series = [
            {
                name: 'Tempo médio em dias',
                data: [],
            },
        ]

        Object.keys(data).forEach(periodo => {
            result.series[0].data.push([enums.tempoMedio[periodo], data[periodo]])
        })
    }
    return {
        ...promiseRecord,
        result: result,
    }
}
