import axios, { AxiosPromise } from 'axios'
import {
    AutenticacaoIntegracaoDto,
    RecebimentoIntegracaoDto,
    SistemaIntegracaoForm,
    SistemaIntegracaoWrapperDto,
} from 'backend'
import { Page, PageParams } from 'redux/requester'

const url = '/api/integracao'

class Integracao {
    saveSistema(form: SistemaIntegracaoForm): AxiosPromise<SistemaIntegracaoForm> {
        return axios.post(url + '/sistema', form)
    }

    updateSistema(id: number, form: SistemaIntegracaoForm): AxiosPromise<SistemaIntegracaoForm> {
        return axios.put(url + '/sistema/' + id, form)
    }

    getSistemasAtivos(): AxiosPromise<SistemaIntegracaoWrapperDto> {
        return axios.get(url + '/sistemasAtivos')
    }

    getSistemasInativos(): AxiosPromise<SistemaIntegracaoWrapperDto> {
        return axios.get(url + '/sistemasInativos')
    }

    inativarSistema(id: number): AxiosPromise<void> {
        return axios.post(url + '/inativarSistema/' + id)
    }

    reativarSistema(id: number): AxiosPromise<void> {
        return axios.post(url + '/reativarSistema/' + id)
    }

    getAutenticacoes(): AxiosPromise<AutenticacaoIntegracaoDto[]> {
        return axios.get(url + '/autenticacoes')
    }

    generateNovoLogin(idSistema: number): AxiosPromise<AutenticacaoIntegracaoDto> {
        return axios.post(url + '/novoLogin/' + idSistema)
    }

    generateNovaSenha(idSistema: number): AxiosPromise<AutenticacaoIntegracaoDto> {
        return axios.post(url + '/novaSenha/' + idSistema)
    }

    getDadosRecebidos(params: PageParams): AxiosPromise<Page<RecebimentoIntegracaoDto>> {
        return axios.get(url + '/dadosRecebidos', { params })
    }

    getDadosRecusados(params: PageParams): AxiosPromise<Page<RecebimentoIntegracaoDto>> {
        return axios.get(url + '/dadosRecusados', { params })
    }
}

export default new Integracao()
