import * as React from 'react'
import * as C from 'libs/components'
import { FixedSelectProps } from '../../elements/FixedSelect'
import { FormFieldProps } from '../FormField'
import { WrappedFieldProps } from 'redux-form'

const estados = [
    { id: 'AC', nome: 'AC' },
    { id: 'AL', nome: 'AL' },
    { id: 'AM', nome: 'AM' },
    { id: 'AP', nome: 'AP' },
    { id: 'BA', nome: 'BA' },
    { id: 'CE', nome: 'CE' },
    { id: 'DF', nome: 'DF' },
    { id: 'ES', nome: 'ES' },
    { id: 'GO', nome: 'GO' },
    { id: 'MA', nome: 'MA' },
    { id: 'MG', nome: 'MG' },
    { id: 'MS', nome: 'MS' },
    { id: 'MT', nome: 'MT' },
    { id: 'PA', nome: 'PA' },
    { id: 'PB', nome: 'PB' },
    { id: 'PE', nome: 'PE' },
    { id: 'PI', nome: 'PI' },
    { id: 'PR', nome: 'PR' },
    { id: 'RJ', nome: 'RJ' },
    { id: 'RN', nome: 'RN' },
    { id: 'RO', nome: 'RO' },
    { id: 'RR', nome: 'RR' },
    { id: 'RS', nome: 'RS' },
    { id: 'SC', nome: 'SC' },
    { id: 'SE', nome: 'SE' },
    { id: 'SP', nome: 'SP' },
    { id: 'TO', nome: 'TO' },
]

export interface UfFieldProps extends Pick<FixedSelectProps, 'getRef'>, FormFieldProps, Partial<WrappedFieldProps> {
    name: string
    placeholder?: string
    label?: string
}
export class UfField extends React.Component<UfFieldProps> {
    render() {
        return <C.FixedSelectField {...this.props} maxLength={2} options={estados} />
    }
}
