import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ButtonBar, CheckboxField } from 'libs/components'
import { Button } from 'libs/components'
import { UserDTO, AlertaConfig, TipoAlerta } from 'backend'
import InscritosField from '../components/InscritosField'
import { formValueSelector } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { State } from 'redux/modules'
import classnames from 'classnames'
import moment from 'moment'
import { AlertaModal } from 'view/home/anvisa/alertas/components/AlertaModal'
import form from 'libs/redux-form/form'

interface FormProps {
    // connect props
    ativo: boolean
    dataUltimaAlteracao: any
    usuarioUltimaAlteracao: string
    user: UserDTO & { perfilUnico: boolean }

    // external props
    fechar()
    initialValues: AlertaConfig
    handleSubmit?: () => Promise<void>
    onSubmit(form)
    onSubmitSuccess()
    renderChildren?(ativo: boolean)
    tipoAlerta: TipoAlerta
    titulo: string
}

class Form extends React.PureComponent<FormProps> {
    render() {
        const modalClasses = classnames('modal-alerta-config', {
            disabled: !this.props.ativo,
        })
        const dataUltimaAlteracao = moment(this.props.dataUltimaAlteracao)
        return (
            <AlertaModal
                active
                className={modalClasses}
                fechar={this.props.fechar}
                tipoAlerta={this.props.tipoAlerta}
                titulo={this.props.titulo}
                subtitulo={
                    this.props.dataUltimaAlteracao &&
                    'Última alteração em ' +
                        dataUltimaAlteracao.format('DD/MM/YY') +
                        ' as ' +
                        dataUltimaAlteracao.format('H:mm') +
                        ' por ' +
                        this.props.usuarioUltimaAlteracao
                }
                renderFooter={() => (
                    <ButtonBar>
                        <Button size='medium' onClick={this.props.fechar}>
                            Cancelar
                        </Button>
                        <Button size='medium' type='primary' onClick={this.props.handleSubmit}>
                            Salvar
                        </Button>
                    </ButtonBar>
                )}
            >
                <CheckboxField name='ativo' label='Ativo' />
                <InscritosField name='inscritos' disabled={!this.props.ativo} user={this.props.user} />
                {this.props.renderChildren && this.props.renderChildren(this.props.ativo)}
            </AlertaModal>
        )
    }
}

interface OwnProps {
    fechar()
    formName: string
    initialValues: AlertaConfig
    onSubmit(form)
    onSubmitSuccess()
}

const ConnectedForm = connect((state: State, ownProps: OwnProps) => {
    const selector = formValueSelector(ownProps.formName)
    const ativo = selector(state, 'ativo')
    const dataUltimaAlteracao = selector(state, 'dataUltimaAlteracao')
    const usuarioUltimaAlteracao = selector(state, 'usuarioUltimaAlteracao.nome')

    return {
        ativo,
        dataUltimaAlteracao,
        usuarioUltimaAlteracao,
        user: state.auth.user,
    }
})(Form)

export interface PublicAlertaConfigModalFormProps {
    config: AlertaConfig
    fechar()
    onSubmit(form)
    onSubmitSuccess()
    renderChildren?(ativo: boolean)
    titulo: string
    tipoAlerta: TipoAlerta
}

interface AlertaConfigModalFormProps extends PublicAlertaConfigModalFormProps, Partial<RouteComponentProps<any>> {
    formName: string
    validate?: any
    renderChildren?(ativo: boolean)
}

export default class AlertaConfigModalForm extends React.PureComponent<AlertaConfigModalFormProps> {
    render() {
        const WrappedForm: any = form({
            form: this.props.formName,
            validate: this.props.validate,
            hasErrorModal: false,
            hasSuccessModal: false,
        })(ConnectedForm)
        return (
            <WrappedForm
                initialValues={this.props.config}
                fechar={this.props.fechar}
                formName={this.props.formName}
                onSubmit={this.props.onSubmit}
                onSubmitSuccess={this.props.onSubmitSuccess}
                renderChildren={this.props.renderChildren}
                tipoAlerta={this.props.tipoAlerta}
                titulo={this.props.titulo}
            />
        )
    }
}
