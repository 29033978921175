import { useAxiosGet } from 'hooks/useApiRequest'
import { LoadingStateContainer } from 'libs/components'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'redux/modules'
import { EdicaoDadosFinanceirosState, setEdicaoProcedimentoData } from 'redux/modules/edicaoProcedimento'
import { FinanceiroRastreabilidadeView } from '../rastreabilidade/dadosFinanceiro/FinanceiroRastreabilidadeView'

export const EdicaoDadosFinanceiro = () => {
    const idProced = useSelector<State, number>(state => state.edicao.idProcedimento)
    const { data, requestState } = useAxiosGet<EdicaoDadosFinanceirosState>(
        '/api/rastreabilidade/dadosFinanceirosById/' + idProced
    )
    const dispatch = useDispatch()
    useEffect(() => {
        if (requestState === 'success') {
            dispatch(setEdicaoProcedimentoData(data))
        }
    }, [requestState, dispatch, data])
    return (
        <LoadingStateContainer readyState={requestState}>
            <FinanceiroRastreabilidadeView editMode />
        </LoadingStateContainer>
    )
}
