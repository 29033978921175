import React from 'react'
import { Text } from '@react-pdf/renderer'
import { mdxComponents, styles } from './ManualUsuarioPDFView'
import { PDFLayout } from './PDFLayout'
import { MDXProvider } from '@mdx-js/tag'
import { ManualCapaSumarioPDFView } from './ManualCapaSumarioPDFView'
import { PerfilEnum } from 'api/Perfis'
import * as Markdown from './ArquivosMarkdown'

export interface ManualCompletoUsuarioViewProps {
    versao: string
}

export function ManualCompletoUsuarioView(props: ManualCompletoUsuarioViewProps) {
    return (
        <PDFLayout versao={props.versao} capa={<ManualCapaSumarioPDFView />}>
            <MDXProvider components={mdxComponents}>
                <>
                    {arquivosPdf.map(arquivo => (
                        <MarkdownPDF key={arquivo.path} arquivo={arquivo} />
                    ))}
                </>
            </MDXProvider>
        </PDFLayout>
    )
}

export const todosUsuarios = [...Object.keys(PerfilEnum), undefined]

export const arquivosPdf = [
    {
        titulo: 'Central de ajuda RNI',
        perfil: todosUsuarios,
        tituloBusca: 'Central de ajuda RNI',
        path: 'home.md',
        component: Markdown.Home,
        link: '/manualUsuario/inicio',
    },
    {
        topico: 'Acesso, conta, vínculos',
        titulo: 'Como me cadastro no RNI?',
        perfil: todosUsuarios,
        tituloBusca: 'Como me cadastro no RNI? - Técnico',
        path: 'acesso/cadastroTecnico.md',
        component: Markdown.CadastroTecnico,
        link: '/manualUsuario/acesso/cadastro/tecnico',
        iframe: ['https://www.youtube.com/embed/WQdMQy1OPrc'],
    },
    {
        tituloBusca: 'Como me cadastro no RNI? - Médico',
        perfil: todosUsuarios,
        path: 'acesso/cadastroMedico.md',
        component: Markdown.CadastroMedico,
        link: '/manualUsuario/acesso/cadastro/medico',
        iframe: ['https://www.youtube.com/embed/WQdMQy1OPrc'],
    },
    {
        tituloBusca: 'Como me cadastro no RNI? - Médico Residente',
        perfil: todosUsuarios,
        path: 'acesso/cadastroMedicoResidente.md',
        component: Markdown.CadastroMedicoResidente,
        link: '/manualUsuario/acesso/cadastro/medicoResidente',
        iframe: ['https://www.youtube.com/embed/WQdMQy1OPrc'],
    },
    {
        tituloBusca: 'Como me cadastro no RNI? - Financeiro',
        perfil: todosUsuarios,
        path: 'acesso/cadastroAdministrativo.md',
        component: Markdown.CadastroAdministrativo,
        link: '/manualUsuario/acesso/cadastro/financeiro',
        iframe: ['https://www.youtube.com/embed/WQdMQy1OPrc'],
    },
    {
        tituloBusca: 'Como me cadastro no RNI? - Anvisa',
        perfil: todosUsuarios,
        path: 'acesso/cadastroColaboradorAnvisa.md',
        component: Markdown.CadastroColaboradorAnvisa,
        link: '/manualUsuario/acesso/cadastro/anvisa',
    },
    {
        tituloBusca: 'Como me cadastro no RNI? - Gestor Integração',
        perfil: todosUsuarios,
        path: 'acesso/cadastroGestorIntegracao.md',
        component: Markdown.CadastroGestorIntegracao,
        link: '/manualUsuario/acesso/cadastro/gestorIntegracao',
    },
    {
        tituloBusca: 'Como me cadastro no RNI? - Gestor Segurança',
        perfil: todosUsuarios,
        path: 'acesso/cadastroGestorSeguranca.md',
        component: Markdown.CadastroGestorSeguranca,
        link: '/manualUsuario/acesso/cadastro/gestorSeguranca',
    },
    {
        tituloBusca: 'Como me cadastro no RNI? - Gestor do Serviço de Saúde',
        perfil: todosUsuarios,
        path: 'acesso/cadastroGestorServicoSaude.md',
        component: Markdown.CadastroGestorServicoSaude,
        link: '/manualUsuario/acesso/cadastro/gestorServicoSaude',
    },
    {
        tituloBusca: 'Como me cadastro no RNI? - SNVS',
        perfil: todosUsuarios,
        path: 'acesso/cadastroSnvs.md',
        component: Markdown.CadastroSnvs,
        link: '/manualUsuario/acesso/cadastro/snvs',
        iframe: ['https://www.youtube.com/embed/WQdMQy1OPrc'],
    },
    {
        tituloBusca: 'Como faço login no RNI? - Técnico',
        perfil: todosUsuarios,
        path: 'acesso/fazerLoginTecnico.md',
        component: Markdown.FazerLoginTecnico,
        link: '/manualUsuario/acesso/login/tecnico',
    },
    {
        tituloBusca: 'Como faço login no RNI? - Médico',
        perfil: todosUsuarios,
        path: 'acesso/fazerLoginMedico.md',
        component: Markdown.FazerLoginMedico,
        link: '/manualUsuario/acesso/login/medico',
    },
    {
        tituloBusca: 'Como faço login no RNI? - Médico Residente',
        perfil: todosUsuarios,
        path: 'acesso/fazerLoginMedicoResidente.md',
        component: Markdown.fazerLoginMedicoResidente,
        link: '/manualUsuario/acesso/login/medicoResidente',
    },
    {
        tituloBusca: 'Como faço login no RNI? - Financeiro',
        perfil: todosUsuarios,
        path: 'acesso/fazerLoginFinanceiro.md',
        component: Markdown.FazerLoginFinanceiro,
        link: '/manualUsuario/acesso/login/financeiro',
    },
    {
        tituloBusca: 'Como faço login no RNI? - Gestor de segurança',
        perfil: todosUsuarios,
        path: 'acesso/fazerLoginGestorSeguranca.md',
        component: Markdown.FazerLoginGestorSeguranca,
        link: '/manualUsuario/acesso/login/gestorSeguranca',
    },
    {
        tituloBusca: 'Como faço login no RNI? - Gestor de integração',
        perfil: todosUsuarios,
        path: 'acesso/fazerLoginGestorIntegracao.md',
        component: Markdown.FazerLoginGestorIntegracao,
        link: '/manualUsuario/acesso/login/gestorIntegracao',
    },
    {
        tituloBusca: 'Como faço login no RNI? - Colaborador ANVISA',
        perfil: todosUsuarios,
        path: 'acesso/fazerLoginAnvisa.md',
        component: Markdown.FazerLoginAnvisa,
        link: '/manualUsuario/acesso/login/anvisa',
    },
    {
        tituloBusca: 'Como faço login no RNI? - Gestor do Serviço de Saúde',
        perfil: todosUsuarios,
        path: 'acesso/fazerLoginGestorServicoSaude.md',
        component: Markdown.FazerLoginGestorServicoSaude,
        link: '/manualUsuario/acesso/login/gestorServicoSaude',
    },
        {
        tituloBusca: 'Como faço login no RNI? - SNVS',
        perfil: todosUsuarios,
        path: 'acesso/fazerLoginSnvs.md',
        component: Markdown.FazerLoginSnvs,
        link: '/manualUsuario/acesso/login/snvs',
    },
    {
        titulo: 'Como atualizar meus dados pessoais?',
        perfil: todosUsuarios,
        tituloBusca: 'Como atualizar meus dados pessoais?',
        path: 'acesso/alterarDadosPessoais.md',
        component: Markdown.AlterarDadosPessoais,
        link: '/manualUsuario/acesso/dadosPessoais',
    },
    {
        titulo: 'Como recuperar minha senha?',
        perfil: todosUsuarios,
        tituloBusca: 'Como recuperar minha senha?',
        path: 'acesso/recuperarSenha.md',
        component: Markdown.RecuperarSenha,
        link: '/manualUsuario/acesso/recuperarSenha',
    },
    {
        titulo: 'Como alterar minha senha?',
        perfil: todosUsuarios,
        tituloBusca: 'Como alterar minha senha?',
        path: 'acesso/alterarSenha.md',
        component: Markdown.AlterarSenha,
        link: '/manualUsuario/acesso/alterarSenha',
    },
    {
        titulo: 'Como adicionar vínculo a outra instituição?',
        perfil: todosUsuarios,
        tituloBusca: 'Como adicionar vínculo a outra instituição?',
        path: 'acesso/adicionarVinculo.md',
        component: Markdown.AdicionarVinculo,
        link: '/manualUsuario/acesso/adicionarVinculo',
    },
    {
        titulo: 'Como mudar instituição/vínculo?',
        perfil: todosUsuarios,
        tituloBusca: 'Como mudar instituição/vínculo?',
        path: 'acesso/alterarVinculo.md',
        component: Markdown.AlterarVinculo,
        link: '/manualUsuario/acesso/mudarVinculo',
    },

    {
        topico: 'Criando um procedimento',
        titulo: 'Como criar procedimento de cardiologia?',
        perfil: todosUsuarios,
        tituloBusca: 'Como criar procedimento de cardiologia? - Técnico',
        path: 'criarProcedimento/criarCardiologiaTecnico.md',
        component: Markdown.CriarCardiologiaTecnico,
        link: '/manualUsuario/criarProcedimento/procedimentoCardiologia/tecnico',
        iframe: ['https://www.youtube.com/embed/E8b_cilokgY'],
    },
    {
        tituloBusca: 'Como criar procedimento de cardiologia? - Médico',
        perfil: todosUsuarios,
        path: 'criarProcedimento/criarCardiologiaMedico.md',
        component: Markdown.CriarCardiologiaMedico,
        link: '/manualUsuario/criarProcedimento/procedimentoCardiologia/medico',
        iframe: ['https://www.youtube.com/embed/u6A-tiXTKz4'],
    },
    {
        tituloBusca: 'Como criar procedimento de cardiologia? - Médico Residente',
        perfil: todosUsuarios,
        path: 'criarProcedimento/criarCardiologiaMedicoResidente.md',
        component: Markdown.CriarCardiologiaMedicoResidente,
        link: '/manualUsuario/criarProcedimento/procedimentoCardiologia/medicoResidente',
        iframe: ['https://www.youtube.com/embed/u6A-tiXTKz4'],
    },
    {
        titulo: 'Como criar procedimento de artroplastia primária de joelho?',
        perfil: todosUsuarios,
        tituloBusca: 'Como criar procedimento de artroplastia primária de joelho? - Técnico',
        path: 'criarProcedimento/criarJoelhoPrimariaTecnico.md',
        component: Markdown.CriarJoelhoPrimariaTecnico,
        link: '/manualUsuario/criarProcedimento/procedimentoJoelhoPrimaria/tecnico',
        iframe: ['https://www.youtube.com/embed/kKhGBEOf1DY'],
    },
    {
        tituloBusca: 'Como criar procedimento de artroplastia primária de joelho? - Médico',
        perfil: todosUsuarios,
        path: 'criarProcedimento/criarJoelhoPrimariaMedico.md',
        component: Markdown.CriarJoelhoPrimariaMedico,
        link: '/manualUsuario/criarProcedimento/procedimentoJoelhoPrimaria/medico',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },
    {
        tituloBusca: 'Como criar procedimento de artroplastia primária de joelho? - Médico Residente',
        perfil: todosUsuarios,
        path: 'criarProcedimento/criarJoelhoPrimariaMedicoResidente.md',
        component: Markdown.criarJoelhoPrimariaMedicoResidente,
        link: '/manualUsuario/criarProcedimento/procedimentoJoelhoPrimaria/medicoResidente',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },
    {
        titulo: 'Como criar procedimento de revisão de artroplastia de joelho?',
        perfil: todosUsuarios,
        tituloBusca: 'Como criar procedimento de revisão de artroplastia de joelho? - Técnico',
        path: 'criarProcedimento/criarJoelhoRevisaoTecnico.md',
        component: Markdown.CriarJoelhoRevisaoTecnico,
        link: '/manualUsuario/criarProcedimento/procedimentoJoelhoRevisao/tecnico',
        iframe: ['https://www.youtube.com/embed/kKhGBEOf1DY'],
    },
    {
        tituloBusca: 'Como criar procedimento de revisão de artroplastia de joelho? - Médico',
        perfil: todosUsuarios,
        path: 'criarProcedimento/criarJoelhoRevisaoMedico.md',
        component: Markdown.CriarJoelhoRevisaoMedico,
        link: '/manualUsuario/criarProcedimento/procedimentoJoelhoRevisao/medico',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },
    {
        tituloBusca: 'Como criar procedimento de revisão de artroplastia de joelho? - Médico Residente',
        perfil: todosUsuarios,
        path: 'criarProcedimento/criarJoelhoRevisaoMedicoResidente.md',
        component: Markdown.criarJoelhoRevisaoMedicoResidente,
        link: '/manualUsuario/criarProcedimento/procedimentoJoelhoRevisao/medicoResidente',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },
    {
        titulo: 'Como criar procedimento de artroplastia primária de quadril?',
        perfil: todosUsuarios,
        tituloBusca: 'Como criar procedimento de artroplastia primária de quadril? - Técnico',
        path: 'criarProcedimento/criarQuadrilPrimariaTecnico.md',
        component: Markdown.CriarQuadrilPrimariaTecnico,
        link: '/manualUsuario/criarProcedimento/procedimentoQuadrilPrimaria/tecnico',
        iframe: ['https://www.youtube.com/embed/kKhGBEOf1DY'],
    },
    {
        tituloBusca: 'Como criar procedimento de artroplastia primária de quadril? - Médico',
        perfil: todosUsuarios,
        path: 'criarProcedimento/criarQuadrilPrimariaMedico.md',
        component: Markdown.CriarQuadrilPrimariaMedico,
        link: '/manualUsuario/criarProcedimento/procedimentoQuadrilPrimaria/medico',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },
    {
        tituloBusca: 'Como criar procedimento de artroplastia primária de quadril? - Médico Residente',
        perfil: todosUsuarios,
        path: 'criarProcedimento/criarQuadrilPrimariaMedicoResidente.md',
        component: Markdown.CriarQuadrilPrimariaMedicoResidente,
        link: '/manualUsuario/criarProcedimento/procedimentoQuadrilPrimaria/medicoResidente',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },
    {
        titulo: 'Como criar procedimento de revisão de artroplastia de quadril?',
        perfil: todosUsuarios,
        tituloBusca: 'Como criar procedimento de revisão de artroplastia de quadril? - Técnico',
        path: 'criarProcedimento/criarQuadrilRevisaoTecnico.md',
        component: Markdown.CriarQuadrilRevisaoTecnico,
        link: '/manualUsuario/criarProcedimento/procedimentoQuadrilRevisao/tecnico',
        iframe: ['https://www.youtube.com/embed/kKhGBEOf1DY'],
    },
    {
        tituloBusca: 'Como criar procedimento de revisão de artroplastia de quadril? - Médico',
        perfil: todosUsuarios,
        path: 'criarProcedimento/criarQuadrilRevisaoMedico.md',
        component: Markdown.CriarQuadrilRevisaoMedico,
        link: '/manualUsuario/criarProcedimento/procedimentoQuadrilRevisao/medico',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },
    {
        tituloBusca: 'Como criar procedimento de revisão de artroplastia de quadril? - Médico Residente',
        perfil: todosUsuarios,
        path: 'criarProcedimento/criarQuadrilRevisaoMedicoResidente.md',
        component: Markdown.CriarQuadrilRevisaoMedicoResidente,
        link: '/manualUsuario/criarProcedimento/procedimentoQuadrilRevisao/medicoResidente',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },
    {
        titulo: 'Como excluir um procedimento?',
        perfil: todosUsuarios,
        tituloBusca: 'Como excluir um procedimento?',
        path: 'criarProcedimento/excluirProcedimento.md',
        component: Markdown.ExcluirProcedimento,
        link: '/manualUsuario/criarProcedimento/excluirProcedimento',
    },
    {
        titulo: 'Como editar os dados de um procedimento? - Pré-ficha',
        perfil: todosUsuarios,
        tituloBusca: 'Como editar os dados de um procedimento? - Pré-ficha',
        path: 'criarProcedimento/editarPreFicha.md',
        component: Markdown.EditarPreFicha,
        link: '/manualUsuario/criarProcedimento/editarProcedimento/preFicha',
    },
    {
        titulo: 'Como editar os dados de um procedimento? - Dados Clínicos',
        perfil: todosUsuarios,
        tituloBusca: 'Como editar os dados de um procedimento? - Dados Clínicos',
        path: 'criarProcedimento/editarDadosClinicos.md',
        component: Markdown.EditarDadosClinicos,
        link: '/manualUsuario/criarProcedimento/editarProcedimento/dadosClinicos',
    },
    {
        titulo: 'Como editar os dados de um procedimento? - Dados de Produto',
        perfil: todosUsuarios,
        tituloBusca: 'Como editar os dados de um procedimento? - Dados de Produto',
        path: 'criarProcedimento/editarDadosProduto.md',
        component: Markdown.EditarDadosProduto,
        link: '/manualUsuario/criarProcedimento/editarProcedimento/dadosProduto',
    },
    {
        titulo: 'Como editar os dados de um procedimento? - Dados Financeiros',
        perfil: todosUsuarios,
        tituloBusca: 'Como editar os dados de um procedimento? - Dados Financeiros',
        path: 'criarProcedimento/editarDadosFinanceiros.md',
        component: Markdown.EditarDadosFinanceiros,
        link: '/manualUsuario/criarProcedimento/editarProcedimento/dadosFinanceiros',
    },
    {
        titulo: 'Como editar os dados de um procedimento? - Dados de Produto e Financeiros',
        perfil: todosUsuarios,
        tituloBusca: 'Como editar os dados de um procedimento? - Dados de Produto e Financeiros',
        path: 'criarProcedimento/editarDadosProdutoFinanceiros.md',
        component: Markdown.EditarDadosProdutoFinanceiros,
        link: '/manualUsuario/criarProcedimento/editarProcedimento/dadosProdutoFinanceiros',
    },
    {
        topico: 'Registrando dados clínicos',
        titulo: 'Como registro dados de cardiologia?',
        perfil: todosUsuarios,
        tituloBusca: 'Como registro dados de cardiologia?',
        path: 'dadosClinicos/dadosCardiologia.md',
        component: Markdown.DadosCardiologia,
        link: '/manualUsuario/dadosClinicos/dadosCardiologia',
        iframe: ['https://www.youtube.com/embed/u6A-tiXTKz4'],
    },
    {
        titulo: 'Como registro dados da artroplastia primária de joelho?',
        perfil: todosUsuarios,
        tituloBusca: 'Como registro dados da artroplastia primária de joelho?',
        path: 'dadosClinicos/dadosJoelhoPrimaria.md',
        component: Markdown.DadosJoelhoPrimaria,
        link: '/manualUsuario/dadosClinicos/dadosArtoplastiaJoelhoPrimaria',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },
    {
        titulo: 'Como registro dados da revisão de artroplastia de joelho?',
        perfil: todosUsuarios,
        tituloBusca: 'Como registro dados da revisão de artroplastia de joelho?',
        path: 'dadosClinicos/dadosJoelhoRevisao.md',
        component: Markdown.DadosJoelhoRevisao,
        link: '/manualUsuario/dadosClinicos/dadosArtoplastiaJoelhoRevisao',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },
    {
        titulo: 'Como registro dados da artroplastia primária de quadril?',
        perfil: todosUsuarios,
        tituloBusca: 'Como registro dados da artroplastia primária de quadril?',
        path: 'dadosClinicos/dadosQuadrilPrimaria.md',
        component: Markdown.DadosQuadrilPrimaria,
        link: '/manualUsuario/dadosClinicos/dadosArtoplastiaQuadrilPrimaria',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },
    {
        titulo: 'Como registro dados da revisão de artroplastia de quadril?',
        perfil: todosUsuarios,
        tituloBusca: 'Como registro dados da revisão de artroplastia de quadril?',
        path: 'dadosClinicos/dadosQuadrilRevisao.md',
        component: Markdown.DadosQuadrilRevisao,
        link: '/manualUsuario/dadosClinicos/dadosArtoplastiaQuadrilRevisao',
        iframe: ['https://www.youtube.com/embed/UIcl1O3C5ok'],
    },

    {
        topico: 'Registrando dados do produto e financeiros',
        titulo: 'Como preencho os dados de produto?',
        perfil: todosUsuarios,
        tituloBusca: 'Como preencho os dados de produto? - Técnico',
        path: 'dadosProdutoFinanceiro/dadosProdutoTecnico.md',
        component: Markdown.DadosProdutoTecnico,
        link: '/manualUsuario/dadosProdutoFinanceiro/dadosProduto/tecnico',
        iframe: ['https://www.youtube.com/embed/0aT1pgzK1W0', 'https://www.youtube.com/embed/5XBha-RFI5o'],
    },
    {
        tituloBusca: 'Como preencho os dados de produto? - Médico',
        perfil: todosUsuarios,
        path: 'dadosProdutoFinanceiro/dadosProdutoMedico.md',
        component: Markdown.DadosProdutoMedico,
        link: '/manualUsuario/dadosProdutoFinanceiro/dadosProduto/medico',
        iframe: ['https://www.youtube.com/embed/0aT1pgzK1W0', 'https://www.youtube.com/embed/5XBha-RFI5o'],
    },
    {
        tituloBusca: 'Como preencho os dados de produto? - Financeiro',
        perfil: todosUsuarios,
        path: 'dadosProdutoFinanceiro/dadosProdutoFinanceiro.md',
        component: Markdown.DadosProdutoFinanceiro,
        link: '/manualUsuario/dadosProdutoFinanceiro/dadosProduto/financeiro',
        iframe: ['https://www.youtube.com/embed/0aT1pgzK1W0', 'https://www.youtube.com/embed/5XBha-RFI5o'],
    },
    {
        tituloBusca: 'Como preencho os dados de produto? - Médico Residente',
        perfil: todosUsuarios,
        path: 'dadosProdutoFinanceiro/dadosProdutoMedicoResidente.md',
        component: Markdown.DadosProdutoMedicoResidente,
        link: '/manualUsuario/dadosProdutoFinanceiro/dadosProduto/medicoResidente',
        iframe: ['https://www.youtube.com/embed/0aT1pgzK1W0', 'https://www.youtube.com/embed/5XBha-RFI5o'],
    },
    {
        titulo: 'Como preencho os dados financeiros?',
        perfil: todosUsuarios,
        tituloBusca: 'Como preencho os dados financeiros?',
        path: 'dadosProdutoFinanceiro/dadosFinanceiros.md',
        component: Markdown.DadosFinanceiros,
        link: '/manualUsuario/dadosProdutoFinanceiro/dadosFinanceiros',
        iframe: ['https://www.youtube.com/embed/BB-RKf9aQpU'],
    },
    {
        titulo: 'Não encontro/possui registro ANVISA na etiqueta',
        perfil: todosUsuarios,
        tituloBusca: 'Não encontro/possui registro ANVISA na etiqueta',
        path: 'dadosProdutoFinanceiro/semRegistroAnvisa.md',
        component: Markdown.SemRegistroAnvisa,
        link: '/manualUsuario/dadosProdutoFinanceiro/semRegistroAnvisa',
    },

    {
        topico: 'Gerenciando vínculos',
        titulo: 'Como aceitar/recusar vínculos?',
        perfil: todosUsuarios,
        tituloBusca: 'Como aceitar/recusar vínculos?',
        path: 'vinculo/parecerVinculo.md',
        component: Markdown.ParecerVinculo,
        link: '/manualUsuario/vinculo/aceitarRejeitarVinculos',
        iframe: ['https://www.youtube.com/embed/_OkxUArBV3o'],
    },
    {
        titulo: 'Como desativo vínculos?',
        perfil: todosUsuarios,
        tituloBusca: 'Como desativo vínculos?',
        path: 'vinculo/desativacaoVinculo.md',
        component: Markdown.DesativacaoVinculo,
        link: '/manualUsuario/vinculo/desativarVinculos',
    },
    {
        titulo: 'Como visualizo vínculos recusados?',
        perfil: todosUsuarios,
        tituloBusca: 'Como visualizo vínculos recusados?',
        path: 'vinculo/vinculosRecusados.md',
        component: Markdown.VinculosRecusados,
        link: '/manualUsuario/vinculo/visualizarVinculos',
    },

    {
        topico: 'Buscar procedimentos',
        titulo: 'Como visualizo os procedimentos registrados em um hospital?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como visualizo os procedimentos registrados em um hospital?',
        path: 'buscaProcedimentos/buscaProcedimentoHospital.md',
        component: Markdown.BuscaProcedimentoHospital,
        link: '/manualUsuario/buscaProcedimentos/porHospital',
    },
    {
        titulo: 'Como visualizo os procedimentos registrados por um profissional?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como visualizo os procedimentos registrados por um profissional?',
        path: 'buscaProcedimentos/buscaProcedimentoProfissional.md',
        component: Markdown.BuscaProcedimentoProfissional,
        link: '/manualUsuario/buscaProcedimentos/porProfissional',
    },
    {
        titulo: 'Como visualizo os procedimentos que um paciente foi submetido?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como visualizo os procedimentos que um paciente foi submetido?',
        path: 'buscaProcedimentos/buscaProcedimentoPaciente.md',
        component: Markdown.BuscaProcedimentoPaciente,
        link: '/manualUsuario/buscaProcedimentos/porPaciente',
    },

    {
        topico: 'Alarmes',
        titulo: 'Como ativo o alarme para etiquetas sem registro ANVISA?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como ativo o alarme para etiquetas sem registro ANVISA?',
        path: 'alarmes/definirAlarmeEtiquetaSemRegistro.md',
        component: Markdown.DefinirAlarmeEtiquetaSemRegistro,
        link: '/manualUsuario/alarmes/etiquetaSemRegistro',
    },
    {
        titulo: 'Como ativo o alarme para motivos de revisão?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como ativo o alarme para motivos de revisão?',
        path: 'alarmes/definirAlarmeMotivoRevisao.md',
        component: Markdown.DefinirAlarmeMotivoRevisao,
        link: '/manualUsuario/alarmes/motivoRevisao',
    },
    {
        titulo: 'Como ativo o alarme para aumento de registro de procedimentos?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como ativo o alarme para aumento de registro de procedimentos?',
        path: 'alarmes/definirAlarmeAumentoDeRegistros.md',
        component: Markdown.DefinirAlarmeAumentoDeRegistros,
        link: '/manualUsuario/alarmes/aumentoDeRegistros',
    },
    {
        titulo: 'Como desativo um alarme ativo?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como desativo um alarme ativo?',
        path: 'alarmes/desativarAlarme.md',
        component: Markdown.DesativarAlarme,
        link: '/manualUsuario/alarmes/desativarAlarme',
    },
    {
        titulo: 'Como me inscrevo em um alarme para ser notificado?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como me inscrevo em um alarme para ser notificado?',
        path: 'alarmes/inscricaoEmAlarme.md',
        component: Markdown.InscricaoEmAlarme,
        link: '/manualUsuario/alarmes/inscricaoEmAlarme',
    },
    {
        titulo: 'Como faço para deixar de ser notificado quando ocorrer o evento de um alarme?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como faço para deixar de ser notificado quando ocorrer o evento de um alarme?',
        path: 'alarmes/desinscricao.md',
        component: Markdown.Desinscricao,
        link: '/manualUsuario/alarmes/desinscricao',
    },
    {
        titulo: 'Como visualizo um alarme que recebi?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como visualizo um alarme que recebi?',
        path: 'alarmes/visualizarListaDeAlarmes.md',
        component: Markdown.VisualizarListaDeAlarmes,
        link: '/manualUsuario/alarmes/visualizarListaDeAlarmes',
    },

    {
        topico: 'Relatórios Gerenciais - Anvisa',
        titulo: 'Como sei quais pacientes receberam um determinado produto implantável?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como sei quais pacientes receberam um determinado produto implantável? - Por registro ANVISA',
        path: 'dashboard/usoDeProdutoRegistro.md',
        component: Markdown.UsoDeProdutoRegistro,
        link: '/manualUsuario/relatoriosGerenciaisAnvisa/usoDeProdutos/registro',
    },
    {
        tituloBusca: 'Como sei quais pacientes receberam um determinado produto implantável? - CNPJ',
        perfil: [PerfilEnum.ANVISA],
        path: 'dashboard/usoDeProdutoCnpj.md',
        component: Markdown.UsoDeProdutoCnpj,
        link: '/manualUsuario/relatoriosGerenciaisAnvisa/usoDeProdutos/cnpj',
    },
    {
        tituloBusca: 'Como sei quais pacientes receberam um determinado produto implantável? - Sem etiqueta ANVISA',
        perfil: [PerfilEnum.ANVISA],
        path: 'dashboard/usoDeProdutoSemEtiqueta.md',
        component: Markdown.UsoDeProdutoSemEtiqueta,
        link: '/manualUsuario/relatoriosGerenciaisAnvisa/usoDeProdutos/semEtiqueta',
    },
    {
        titulo: 'Como sei se um determinado produto está demandando muitas revisões?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como sei se um determinado produto está demandando muitas revisões?',
        path: 'dashboard/informacoesDeProduto.md',
        component: Markdown.InformacoesDeProduto,
        link: '/manualUsuario/relatoriosGerenciaisAnvisa/informacoesProduto',
    },
    {
        titulo: 'Como visualizo as informações financeiras de um determinado produto?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como visualizo as informações financeiras de um determinado produto?',
        path: 'dashboard/informacoesFinanceiras.md',
        component: Markdown.InformacoesFinanceiras,
        link: '/manualUsuario/relatoriosGerenciaisAnvisa/informacoesFinanceiras',
    },
    {
        titulo: 'Como filtro os dados de um relatório por um período de tempo?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como filtro os dados de um relatório por um período de tempo?',
        path: 'dashboard/filtrarPeriodos.md',
        component: Markdown.FiltrarPeriodo,
        link: '/manualUsuario/relatoriosGerenciaisAnvisa/filtrarPeriodos',
    },

    {
        topico: 'Cadastrando antibióticos e stents',
        titulo: 'Como realizo o cadastro de novos antibióticos?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como realizo o cadastro de novos antibióticos?',
        path: 'cadastroAntibioticoStents/cadastroAntibiotico.md',
        component: Markdown.CadastroAntibiotico,
        link: '/manualUsuario/cadastroAntibioticoStents/cadastroAntibiotico',
    },
    {
        titulo: 'Como realizo o cadastro de novos stents?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como realizo o cadastro de novos stents?',
        path: 'cadastroAntibioticoStents/cadastroStent.md',
        component: Markdown.CadastroStent,
        link: '/manualUsuario/cadastroAntibioticoStents/cadastroStent',
    },

    {
        topico: 'Processos de uso do sistema',
        titulo: 'Como funciona o processo de cadastro no RNI?',
        perfil: todosUsuarios,
        tituloBusca: 'Como funciona o processo de cadastro no RNI?',
        path: 'fluxos/fluxoCadastro.md',
        component: Markdown.FluxoCadastro,
        link: '/manualUsuario/fluxos/fluxoCadastro',
    },
    {
        titulo: 'Como funciona o processo de preenchimento dos registros no RNI?',
        perfil: todosUsuarios,
        tituloBusca: 'Como funciona o processo de preenchimento dos registros no RNI?',
        path: 'fluxos/fluxoPreenchimentoRegistros.md',
        component: Markdown.FluxoPreenchimentoRegistros,
        link: '/manualUsuario/fluxos/fluxoPreenchimentoRegistros',
    },

    {
        topico: 'Integração de Dados',
        titulo: 'Como gerar uma chave para realizar a integração de dados no RNI?',
        perfil: [PerfilEnum.GESTOR_INTEGRACAO],
        tituloBusca: 'Como gerar uma chave para realizar a integração de dados no RNI?',
        path: 'integracao/gerarChave.md',
        component: Markdown.GerarChave,
        link: '/manualUsuario/integracao/gerarChave',
    },
    {
        titulo: 'Como visualizar os registros enviados para o RNI na integração de dados?',
        perfil: [PerfilEnum.GESTOR_INTEGRACAO],
        tituloBusca: 'Como visualizar os registros enviados para o RNI na integração de dados?',
        path: 'integracao/visualizarRegistros.md',
        component: Markdown.VisualizarRegistros,
        link: '/manualUsuario/integracao/visualizarRegistros',
    },
    {
        titulo: 'Como cadastrar sistemas que realizarão a integração de dados no RNI?',
        perfil: [PerfilEnum.GESTOR_INTEGRACAO],
        tituloBusca: 'Como cadastrar sistemas que realizarão a integração de dados no RNI?',
        path: 'integracao/cadastrarSistema.md',
        component: Markdown.CadastrarSistema,
        link: '/manualUsuario/integracao/cadastrarSistema',
    },

    {
        topico: 'Consumo de Dados',
        titulo: 'Como gerar uma chave para as entidades que consumirão dados do RNI?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como gerar uma chave para as entidades que consumirão dados do RNI?',
        path: 'consumo/gerarChaveConsumo.md',
        component: Markdown.GerarChaveConsumo,
        link: '/manualUsuario/consumo/gerarChaveConsumo',
    },
    {
        titulo: 'Como cadastrar entidades que realizarão o consumo de dados do RNI?',
        perfil: [PerfilEnum.ANVISA],
        tituloBusca: 'Como cadastrar entidades que realizarão o consumo de dados do RNI?',
        path: 'consumo/cadastrarEntidade.md',
        component: Markdown.CadastrarEntidade,
        link: '/manualUsuario/consumo/cadastrarEntidade',
    },

    {
        topico: 'Relatórios Gerenciais',
        titulo: 'Como sei quais pacientes receberam um determinado produto implantável?',
        perfil: [PerfilEnum.GESTOR_SERVICO_SAUDE],
        tituloBusca: 'Como sei quais pacientes receberam um determinado produto implantável? - Por registro ANVISA',
        path: 'dashboard/usoDeProdutoRegistro.md',
        component: Markdown.UsoDeProdutoRegistro,
        link: '/manualUsuario/relatoriosGerenciais/usoDeProdutos/registro',
    },
    {
        tituloBusca: 'Como sei quais pacientes receberam um determinado produto implantável? - CNPJ',
        perfil: [PerfilEnum.GESTOR_SERVICO_SAUDE],
        path: 'dashboard/usoDeProdutoCnpj.md',
        component: Markdown.UsoDeProdutoCnpj,
        link: '/manualUsuario/relatoriosGerenciais/usoDeProdutos/cnpj',
    },
    {
        tituloBusca: 'Como sei quais pacientes receberam um determinado produto implantável? - Sem etiqueta ANVISA',
        perfil: [PerfilEnum.GESTOR_SERVICO_SAUDE],
        path: 'dashboard/usoDeProdutoSemEtiqueta.md',
        component: Markdown.UsoDeProdutoSemEtiqueta,
        link: '/manualUsuario/relatoriosGerenciais/usoDeProdutos/semEtiqueta',
    },

    {
        topico: 'Atualizando relações',
        titulo: 'Como atualizar relações com empresas de produtos médicos?',
        perfil: todosUsuarios,
        tituloBusca: 'Como atualizar relações com empresas de produtos médicos?',
        path: 'relacao/atualizarRelacao.md',
        component: Markdown.AtualizarRelacao,
        link: '/manualUsuario/relacao/atualizarRelacao',
    },

    {
        titulo: 'FAQ',
        perfil: todosUsuarios,
        tituloBusca: 'FAQ',
        path: 'faq.md',
        component: Markdown.FAQ,
        link: '/manualUsuario/faq',
    },
]

export interface MarkdownPDFProps {
    arquivo: typeof arquivosPdf[0]
}

export function MarkdownPDF(props: MarkdownPDFProps) {
    const {
        arquivo: { topico, titulo, component: Component },
    } = props
    return (
        <>
            {topico && <Text style={styles.topico}>{topico}</Text>}
            {titulo && <Text style={styles.heading}>{titulo}</Text>}
            {Component && <Component />}
        </>
    )
}
