import { createValidator, range, maxRange, minRange, maxLength, required } from 'libs/validation'
import { StentCoronariaComplementarForm } from 'backend'
import immutableProxy from 'libs/immutableProxy'

const FichaStentCoronariaComplementarValidator = createValidator(
    {
        antecedentes: antecedentes(),
        icpComplementar: icpComplementar(),
        acessoArterialContraste: acessoArterialContraste(),
        conclusao: conclusao(),
    },
    formValidator
)

export function formValidator(formMap, errors) {
    const form = immutableProxy<StentCoronariaComplementarForm>(formMap)

    const icpComplementar = form.icpComplementar
    if (icpComplementar.get()) {
        errors.icpComplementar = errors.icpComplementar || {}

        if (
            !icpComplementar.dispositivosAdjuntos.get()?.includes('DISPOSITIVO_ASSISTENCIA_MECANICA') &&
            icpComplementar.tipoDispositivo.get()
        ) {
            errors.icpComplementar.dispositivosAdjuntos = 'Preencha a subopção'
        }

        const airKerma = icpComplementar.airKerma.get()
        const unidadeAirKerma = icpComplementar.unidadeAirKerma.get()
        if (airKerma && !errors.icpComplementar.airKerma && !unidadeAirKerma) {
            errors.icpComplementar.airKerma =
                'Ao preencher este campo, torna-se obrigatório o preenchimento da respectiva unidade'
            errors.icpComplementar.unidadeAirKerma = required(unidadeAirKerma)
        }

        const dap = icpComplementar.dap.get()
        const primeiraUnidadeDap = icpComplementar.primeiraUnidadeDap.get()
        const segundaUnidadeDap = icpComplementar.segundaUnidadeDap.get()
        if (dap && !errors.icpComplementar.dap && (!primeiraUnidadeDap || !segundaUnidadeDap)) {
            errors.icpComplementar.dap =
                'Ao preencher este campo, torna-se obrigatório o preenchimento da respectiva unidade'
            errors.icpComplementar.primeiraUnidadeDap = required(primeiraUnidadeDap)
            errors.icpComplementar.segundaUnidadeDap = required(segundaUnidadeDap)
        }
    }

    return errors
}

export default FichaStentCoronariaComplementarValidator

function antecedentes() {
    return createValidator({
        funcaoVEConhecidaPercentilFE: [range(0, 100)],
        insuficienciaRenalCreatinina: [range(0, 50)],
        altura: [maxRange(250), minRange(20)],
        peso: [maxRange(500), minRange(0.5)],
    })
}

function icpComplementar() {
    return createValidator({
        fluoroscopiaMinuto: range(0, 99),
        fluoroscopiaSegundo: range(0, 59),
        airKerma: range(0, 9999),
        dap: range(1, 999),
    })
}

function acessoArterialContraste() {
    return createValidator({
        volume: range(1, 999),
    })
}

function conclusao() {
    return createValidator({
        conclusao: maxLength(1000),
    })
}

export const exportFunctions = {
    formValidator,
}
