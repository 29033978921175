import { connect } from 'react-redux'
import Procedimento from 'api/Procedimento'
import FichaPrimariaQuadrilView from 'view/home/novoProcedimento/ficha/quadril/FichaPrimariaQuadrilView'
import { RouteComponentProps } from 'react-router-dom'

interface FichaPrimariaQuadrilContainerProps extends RouteComponentProps<any> {
    editMode?: boolean
}

const mapStateToProps = (state, ownProps: FichaPrimariaQuadrilContainerProps) => {
    if (ownProps?.editMode) {
        return {
            lado: state.edicao?.lado,
            paciente: state.paciente.form,
            procedimento: 'primariaQuadril',
            editMode: true,
            vinculoProfissionalMedicoResponsavel: state.edicao?.medico,
            equipeMedica: state.edicao?.equipeMedica,
        }
    } else {
        return {
            lado: state.novoProcedimento.lado,
            paciente: state.novoProcedimento.paciente,
            procedimento: state.novoProcedimento.procedimento,
            vinculoProfissionalMedicoResponsavel: state.novoProcedimento.vinculoProfissionalMedicoResponsavel,
            equipeMedica: state.novoProcedimento.equipeMedica,
            idRascunho: state.novoProcedimento.idRascunhoOrigem,
        }
    }
}

const mapDispatchToProps = (dispatch, ownProps: FichaPrimariaQuadrilContainerProps) => {
    const pushHome = () => ownProps.history.push('/home')
    return {
        goHome: () => {
            pushHome()
        },
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { paciente, lado, equipeMedica, vinculoProfissionalMedicoResponsavel } = stateProps

    return {
        ...ownProps,
        ...stateProps,
        onSave: form => {
            const mutableForm = form.toJS()
            mutableForm.pacienteId = paciente.id
            mutableForm.lado = lado
            mutableForm.idVinculoProfResponsavel = vinculoProfissionalMedicoResponsavel?.id
            mutableForm.idsVinculosProfsEquipeMedica = equipeMedica.map(vinculoProfissional => {
                return vinculoProfissional.id
            })

            if (ownProps.editMode) {
                return Procedimento.updatePrimariaQuadril(mutableForm)
            } else {
                mutableForm.idRascunho = stateProps.idRascunho
                return Procedimento.savePrimariaQuadril(mutableForm)
            }
        },
        goHome: dispatchProps.goHome,
    }
}

const FichaPrimariaQuadrilContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(FichaPrimariaQuadrilView)

export default FichaPrimariaQuadrilContainer
