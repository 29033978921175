import { Hint } from 'libs/components'
import React, { FunctionComponent } from 'react'
import { NivelSatisfacao, SeletorSatisfacaoIconProps } from './icons'

export interface NivelSatisfacaoWithIcon extends NivelSatisfacao {
    icon: FunctionComponent<SeletorSatisfacaoIconProps>
}

export interface SeletorSatisfacaoProps {
    niveisSatisfacao: NivelSatisfacaoWithIcon[]
    error?: string
    active?: number
    onNvSatisfacaoClick: (nivelSatisfacao: NivelSatisfacao) => void
}

export const SeletorSatisfacao = ({ error, niveisSatisfacao, onNvSatisfacaoClick, active }: SeletorSatisfacaoProps) => {
    if (!niveisSatisfacao || niveisSatisfacao.length < 5) {
        // eslint-disable-next-line
        console.error('Você deve fornecer um array com 5 níveis de satisfação válidos')
        return null
    }
    return (
        <div className='seletor-satisfacao'>
            {error && <div className='seletor-satisfacao-error'>* {error}</div>}
            <div className='seletor-satisfacao-icons'>
                {niveisSatisfacao.map(({ icon: SvgIcon, ...rest }, i) => (
                    <Hint
                        key={rest.id}
                        componente={
                            // Div necessária para que a prop 'ref' passada pelo 'Hint' funcione
                            <div>
                                <SvgIcon
                                    nivel={rest}
                                    onClick={onNvSatisfacaoClick}
                                    active={rest.id === active}
                                    error={!!error}
                                />
                            </div>
                        }
                        placement='top'
                    >
                        {rest.title}
                    </Hint>
                ))}
            </div>
            <div className={`seletor-satisfacao-legenda ${error && 'seletor-satisfacao-legenda-error'}`}>
                <p>{niveisSatisfacao[0].title}</p>
                <p>{niveisSatisfacao[4].title}</p>
            </div>
        </div>
    )
}
