/* tslint:disable */
import React from 'react'

const SvgAlterarperfil = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 18 18' {...props}>
        <path d='M10.27 12.496c-.471-.236-1.255-.628-1.445-.808.797-.978 1.258-2.083 1.258-3.05 0-.655 0-1.472-.367-2.2C9.385 5.781 8.645 5 7 5c-1.645 0-2.385.782-2.716 1.437-.367.728-.367 1.545-.367 2.202 0 .967.46 2.071 1.257 3.05-.19.18-.973.571-1.445.806-.933.468-1.526.777-1.88 1.13-.786.786-.845 2.103-.849 2.36A.998.998 0 002 17h10a1 1 0 001-1.016c-.005-.255-.064-1.573-.848-2.358-.355-.354-.948-.663-1.882-1.13zM2 16c.007-.42.131-1.244.555-1.668.246-.245.827-.545 1.621-.943.94-.47 1.432-.735 1.685-.975l.675-.638-.586-.72c-.657-.806-1.034-1.688-1.034-2.418 0-.58 0-1.235.26-1.75C5.48 6.29 6.076 6 7 6s1.521.29 1.823.888c.26.516.26 1.172.26 1.75 0 .731-.376 1.613-1.033 2.42l-.587.72.675.638c.253.239.745.504 1.685.974.794.398 1.376.698 1.622.943.424.425.549 1.248.555 1.667l-10 .001zm13.152-5.375c-.354-.354-.947-.663-1.881-1.13-.472-.236-1.256-.628-1.446-.807.796-.979 1.258-2.083 1.258-3.05 0-.657 0-1.473-.367-2.202C12.385 2.782 11.645 2 10 2c-1.645 0-2.385.782-2.716 1.437-.146.29-.287.895-.287.895l1.18-.444C8.478 3.291 9.075 3 10 3c.925 0 1.521.29 1.823.888.26.516.26 1.172.26 1.75 0 .731-.377 1.613-1.033 2.42l-.586.72.675.638c.252.239.744.504 1.684.974.795.398 1.376.698 1.621.943.425.425.55 1.248.556 1.667h-2.5l1.5 1h1a1.001 1.001 0 001-1.015c-.004-.256-.064-1.574-.848-2.359z' />
    </svg>
)

export default SvgAlterarperfil
