import * as React from 'react'
import UrlParams from 'libs/router/UrlParams'
import { Switch, Route, NavLink, Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import { MultiplosProcedimentosFiltro } from 'model'
import { Columns, Column, ScrollToTop, Text } from 'libs/components'
import { Button, Icon } from 'libs/components'
import * as enums from 'redux/enums'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import LocalHeaderContainer from 'view/home/anvisa/reports/components/LocalHeaderContainer'
import MultiplosPacientesList from 'view/home/anvisa/reports/multiplos/MultiplosPacientesList'
import { IconTempoAteRevisao } from 'view/home/anvisa/reports/multiplos/IconTempoAteRevisao'
import MultiplosPacientesMap from 'view/home/anvisa/reports/multiplos/MultiplosPacientesMap'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import { UserDTO } from 'backend'
import AbrangenciaSNVS from '../../AbrangenciaSNVS'

export interface Props extends RouteComponentProps<any> {
    filtro: MultiplosProcedimentosFiltro
    user: UserDTO
    changeFilter(filtro: MultiplosProcedimentosFiltro): void
}

export class MultiplosPacientesViewSNVS extends React.Component<Props> {
    refresh = (filter: MultiplosProcedimentosFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
    }

    handleVoltar = () => {
        this.props.history.push(`/home/relatorios/multiplos/${this.props.match.params.tipoCirurgia}/dashboard`)
    }

    render() {
        let { filtro } = this.props
        let { user } = this.props

        AbrangenciaSNVS(filtro, user)

        return (
            <div>
                <ScrollToTop onMount />
                <UrlParams params={this.props.filtro} onChange={this.refresh} />
                <ReportClearFilter />

                <LocalHeaderContainer />
                {filtro && <MultiplosHeader onVoltar={this.handleVoltar} params={this.props.match.params} />}

                <div className='report-content'>
                    <Columns className='report-tabs align-center'>
                        <Column size='6'>
                            <div className='report-tabs-links'>
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}/list`,
                                        search: this.props.location.search,
                                    }}
                                    activeClassName='is-active'
                                >
                                    Lista de pacientes
                                </NavLink>
                                <NavLink
                                    to={{
                                        pathname: `${this.props.match.url}/map`,
                                        search: this.props.location.search,
                                    }}
                                    activeClassName='is-active'
                                >
                                    Mapa de pointers
                                </NavLink>
                            </div>
                        </Column>
                    </Columns>

                    <Switch>
                        <Route
                            exact
                            path={`${this.props.match.url}`}
                            render={() => (
                                <Redirect
                                    to={{
                                        pathname: `${this.props.match.url}/list`,
                                        search: this.props.location.search,
                                    }}
                                />
                            )}
                        />
                        <Route
                            exact
                            path='/home/relatorios/multiplos/:tipoCirurgia/dashboard/:procedimentoMode/:tempoRevisao/pacientes/list'
                            component={MultiplosPacientesList}
                        />
                        <Route
                            exact
                            path={
                                '/home/relatorios/multiplos/:tipoCirurgia/dashboard/:procedimentoMode/:tempoRevisao/pacientes/map'
                            }
                            component={MultiplosPacientesMap}
                        />
                    </Switch>
                </div>
            </div>
        )
    }
}

export interface MultiplosHeaderProps {
    onVoltar(): void
    params: any
}

export const MultiplosHeader = (props: MultiplosHeaderProps) => {
    const { params, onVoltar } = props

    return (
        <HeaderContainer className='report-header-rastreabilidade'>
            <Button className='button-round button-back-motivos' onClick={onVoltar}>
                <Icon icon='voltar' />
            </Button>
            <Text weight='semibold' color='grey-darker' size='small' transform='uppercase'>
                Múltiplos Procedimentos
            </Text>
            <p>
                <Text size='large'>
                    {enums.procedimentoModeName[params.procedimentoMode]} -{' '}
                    {enums.tipoCirurgiaName[params.tipoCirurgia]}
                </Text>{' '}
                <Text size='small-ter'>
                    {enums.tempoAteRevisaoName[params.tempoRevisao]}{' '}
                    {params.tempoRevisao && <IconTempoAteRevisao tempoAteRevisao={params.tempoRevisao} />}
                </Text>
            </p>
        </HeaderContainer>
    )
}
