import * as React from 'react'
import { StyledRadioButton, StyledRadioButtonProps } from 'libs/components'
import { WrappedFieldProps } from 'redux-form'
import field from 'libs/redux-form/field'

interface StyledRadioFieldProps extends StyledRadioButtonProps, Partial<WrappedFieldProps> {}

class StyledRadioFieldCmp extends React.Component<StyledRadioFieldProps> {
    render() {
        return (
            <StyledRadioButton
                {...this.props.input}
                className={this.props.className}
                innerClassName={this.props.innerClassName}
                skin={this.props.skin}
                disabled={this.props.disabled}
                size={this.props.size}
                useStyledButtonClassName={this.props.useStyledButtonClassName}
            >
                {this.props.children}
            </StyledRadioButton>
        )
    }
}

export const StyledRadioField = field({ type: 'radio', haswrapper: false })(StyledRadioFieldCmp)
