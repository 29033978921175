import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as filtroReport from 'redux/modules/report/filtro'

export function useUrlParams(url: string) {
    const location = useLocation()
    const dispatch = useDispatch()

    const splited = location.pathname.split(url)
    const params = splited[1]?.split('/')
    const type: filtroReport.LocalType[] = ['REGIAO', 'UF', 'CIDADE', 'HOSPITAL']

    useEffect(() => {
        if (params?.length > 0) {
            dispatch(filtroReport.changeLocal(params[params.length - 1], type[params.length - 1]))
        }
        // Parametros [params e type] causam multiplos reloads na tela
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
}
