import { createValidator, required } from 'libs/validation'
import { MotivoRevisaoValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/revisao/MotivoRevisaoValidator'
import { ProcedCirurgicoAtualPrimValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/primaria/ProcedCirurgicoAtualPrimValidator'
import { CimentoOsseoValidator } from 'view/home/novoProcedimento/ficha/joelho/validators/CimentoOsseoValidator'
import { EnxertiaValidator } from 'view/home/novoProcedimento/ficha/joelho/validators/EnxertiaValidator'
import { ComponentesValidator } from 'view/home/novoProcedimento/ficha/joelho/validators/ComponentesValidator'

const FichaRevisaoJoelhoValidator = createValidator({
    motivoRevisao: MotivoRevisaoValidator,
    procedimentoCirurgicoAtualRevisao: ProcedCirurgicoAtualPrimValidator,
    tipoRevisao: required,
    cimentoOsseo: CimentoOsseoValidator,
    enxertia: EnxertiaValidator,
    componentesUtilizadosForm: ComponentesValidator,
})

export default FichaRevisaoJoelhoValidator
