import moment from 'moment'
import { DateInterval } from 'backend'
import humanizeDuration from 'humanize-duration'

export const SERVER_FORMAT = 'YYYY-MM-DD'
export const CLIENT_FORMAT = 'DD/MM/YYYY'

export interface MomentInterval {
    start?: Date
    end?: Date
}

export const momentFromYYYYM = (ano: string, mes: number): moment.Moment => {
    return moment(`${ano}-${mes}`, 'YYYY-M')
}

export function serverMoment(serverDateString: string): moment.Moment {
    return moment(serverDateString, SERVER_FORMAT)
}

export function asClientFormat(serverDateString: string): string {
    return moment(serverDateString).format(CLIENT_FORMAT)
}

/**
 * data no formato YYYY-MM-DD
 * @param data no formato YYYY-MM-DD
 */
export function getIdadePorExtenso(data: string): string {
    let hoje: Date = new Date()
    let dataNasc: Date = createDateFromString(data)

    let dias: number = hoje.getDate() - dataNasc.getDate()
    let meses: number = hoje.getMonth() - dataNasc.getMonth()
    let anos: number = hoje.getFullYear() - dataNasc.getFullYear()

    if (meses === 0) {
        if (dias < 0) {
            anos--
        }
    } else if (meses < 0) {
        anos--
    }

    let idade: string
    if (anos > 1) {
        idade = anos + ' anos'
    } else {
        idade = anos + ' ano'
    }

    return idade
}

export function getPeriodBetweenDates(dateStart: string, timeStart, dateEnd: string, timeEnd): string {
    const momentStart = moment.utc(`${dateStart} ${timeStart}`, 'YYYY-MM-DD HH:mm', true)
    const momentEnd = moment.utc(`${dateEnd} ${timeEnd}`, 'YYYY-MM-DD HH:mm', true)

    if (!momentStart.isValid() || !momentEnd.isValid() || momentEnd.isBefore(momentStart)) {
        return ''
    }

    const duration = moment.duration(momentStart.diff(momentEnd))

    return humanizeDuration(duration.asMilliseconds(), {
        language: 'pt',
        conjunction: ' e ',
        serialComma: false,
        units: ['d', 'h', 'm'],
    })
}

export function toMomentInterval(interval: DateInterval) {
    return {
        start: interval.start && moment(interval.start, SERVER_FORMAT).toDate(),
        end: interval.end && moment(interval.end, SERVER_FORMAT).toDate(),
    }
}

export function createDateFromString(input: string) {
    return moment(input).toDate()
}

export function toDateInterval(interval: MomentInterval) {
    return {
        start: interval.start && moment(interval.start).format(SERVER_FORMAT),
        end: interval.end && moment(interval.end).format(SERVER_FORMAT),
    }
}

export function formatPeriodo(inicio: Date, fim: Date) {
    if (!inicio && !fim) {
        return null
    }

    if (!fim) {
        return 'A partir de ' + moment(inicio).format(CLIENT_FORMAT)
    }

    if (!inicio) {
        return 'Até ' + moment(fim).format(CLIENT_FORMAT)
    }

    return moment(inicio).format(CLIENT_FORMAT) + ' - ' + moment(fim).format(CLIENT_FORMAT)
}

export function getDuracaoPorExtenso(duracao) {
    const hora = duracao.split(':')[0]
    const minuto = duracao.split(':')[1]

    let tempo = ''

    if (hora !== '00') {
        if (hora > 1) {
            tempo = hora + ' horas'
        } else {
            tempo = hora + ' hora'
        }
    }

    if (hora !== '00' && minuto !== '00') {
        tempo = tempo + ' e '
    }

    if (minuto !== '00') {
        if (minuto > 1) {
            tempo = tempo + minuto + ' minutos'
        } else {
            tempo = tempo + minuto + ' minuto'
        }
    }
    return tempo
}

// Verifica se é a mesma data, ignorando o horario
export const isSame = (date1: Date, date2: Date) => {
    if (!date1 || !date2) {
        return false
    }
    date1.setHours(0, 0, 0, 0)
    date2.setHours(0, 0, 0, 0)
    return date1.getTime() === date2.getTime()
}

export const getDifference = (date1: Date, date2: Date) => {
    const diffTime = Math.abs(date2.getTime() - date1.getTime())
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export function calculateDuracaoHoras(inicio, termino) {
    const horaInicio = inicio.split(':')[0]
    const minutoInicio = inicio.split(':')[1]
    const horaTermino = termino.split(':')[0]
    const minutoTermno = termino.split(':')[1]
    const date1 = '2020-01-01'
    let date2
    if (horaInicio > horaTermino || (horaInicio === horaTermino && minutoInicio > minutoTermno)) {
        date2 = '2020-01-02'
    } else {
        date2 = '2020-01-01'
    }
    return getPeriodBetweenDates(date1, inicio, date2, termino)
}
