
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.325,"time":19500,"words":65}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Na tela inicial clique no ícone `}<img parentName="li" {...{
          "src": "/images/iconeMenu.png#center",
          "alt": "..."
        }}></img>{` e depois selecione “Alterar senha”`}</li>
      <li parentName="ol">{`Digite sua senha atual, sua nova senha e logo após confirme a sua nova senha`}</li>
      <li parentName="ol">{`Clique no botão “Confirmar”`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para os perfis de gestor de segurança e colaborador ANVISA a alteração de senha deve ser feita através do sistema de cadastro da ANVISA.`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

