import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Entidades from 'api/Entidades'
import { AlertModalConfirmacao, Hint, IconButton, MaskedLabel, Text } from 'libs/components'
import { EntidadeConsumidoraSelectDto } from 'backend'

interface EntidadeRowProps {
    entidade: EntidadeConsumidoraSelectDto
    ativo: boolean
    onInactiveEntity: (entidadeId: number) => void
}

const EntidadeRow: React.FC<EntidadeRowProps> = props => {
    const history = useHistory()

    /**
     * States
     */
    const [showInativarEntidadeModal, setShowInativarEntidadeModal] = useState<boolean>(false)

    /**
     * Handles
     */
    const handleVisualizarEntidade = useCallback(() => {
        history.push(`/home/entidades/visualizarEntidade/${props.entidade.id}`)
    }, [history, props.entidade.id])

    const handleEditarEntidade = useCallback(() => {
        history.push(`/home/entidades/editarEntidade/${props.entidade.id}`)
    }, [history, props.entidade.id])

    const handleInativarEntidade = useCallback(() => {
        setShowInativarEntidadeModal(false)

        Entidades.inativarEntidade(props.entidade.id).then(response => {
            if (response.status === 200) {
                props.onInactiveEntity(props.entidade.id)
            }
        })
    }, [props])

    return (
        <li className='entidade-row'>
            <div className={'entidade-inner-row is-horizontal-flow'}>
                <div className='is-vertical-flow'>
                    <Text className='nome-entidade' weight='bold'>
                        {props.entidade.nomeEntidade}
                    </Text>
                    <div className='infos'>
                        {props.entidade.cnpj && (
                            <MaskedLabel
                                className='cnpj'
                                title={null}
                                value={props.entidade.cnpj}
                                mask='##.###.###/####-##'
                            />
                        )}{' '}
                        <Text size='small-bis'>{props.entidade.emailEntidade}</Text>
                    </div>
                    <Text className='periodo-acesso' size='small-bis' color='grey-darker'>
                        Período de acesso de {props.entidade.periodoAcesso}
                    </Text>
                </div>
                <div className='acoes'>
                    <Hint
                        placement='top'
                        componente={
                            <IconButton
                                data-name='botao_visualizar'
                                icon='vermais'
                                className='com-hover'
                                size='small'
                                pulledRight
                                onClick={handleVisualizarEntidade}
                            />
                        }
                    >
                        Visualizar
                    </Hint>
                    {props.ativo && (
                        <>
                            <Hint
                                placement='top'
                                componente={
                                    <IconButton
                                        data-name='botao_editar'
                                        icon='editar'
                                        className='com-hover'
                                        size='small'
                                        pulledRight
                                        onClick={handleEditarEntidade}
                                    />
                                }
                            >
                                Editar
                            </Hint>
                            <Hint
                                placement='top'
                                componente={
                                    <IconButton
                                        data-name='botao_inativar'
                                        icon='remover'
                                        className='com-hover'
                                        size='small'
                                        pulledRight
                                        onClick={() => setShowInativarEntidadeModal(true)}
                                    />
                                }
                            >
                                Inativar
                            </Hint>
                        </>
                    )}
                </div>
            </div>
            <AlertModalConfirmacao
                active={showInativarEntidadeModal}
                title='Inativar cadastro da entidade!'
                onClose={() => setShowInativarEntidadeModal(false)}
                onOk={handleInativarEntidade}
            >
                Ao confirmar, o cadastro da entidade {props.entidade.nomeEntidade} será inativado e a mesma não poderá
                mais realizar consumo de informações do RNI. Tem certeza que deseja desativar o cadastro?
            </AlertModalConfirmacao>
        </li>
    )
}

export default EntidadeRow
