import { connect } from 'react-redux'
import RootView from 'RootView'
import * as auth from 'redux/modules/auth'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { State } from 'redux/modules/index'

const mapStateToProps = (state: State, ownProps: RouteComponentProps<any>) => {
    return {
        user: state.auth.user,
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps

    return {
        ...ownProps,
        ...stateProps,
        logout: () => {
            stateProps.user && dispatch(auth.logout())
        },
        finishLogout: () => {
            dispatch(auth.logoutSuccess())
            if (ownProps.location.pathname !== '/') {
                ownProps.history.push('/')
            }
        },
    }
}

export default withRouter(connect(mapStateToProps, null, mergeProps)(RootView))
