import React from 'react'
import { Columns, Column } from 'libs/components'
import { TabMenu, TabItem } from '../componentes/HomeMenu'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AutenticacaoIntegracaoView } from './AutenticacaoIntegracaoView'
import { RecebimentoIntegracaoView } from './RecebimentoIntegracaoView'
import { SistemasIntegracaoView } from './SistemasIntegracaoView'

export const HomeGestorIntegracaoView = () => {
    return (
        <div className='home-perfil-container is-vertical-flow is-full-height has-text-centered'>
            <Columns fullHeight>
                <Column verticalFlow size='2'>
                    <TabMenu>
                        <TabItem exact={false} sizeIcon='is-36px' icon='dadospessoais' path={'/home/sistemas'}>
                            Gerenciar sistemas
                        </TabItem>
                        <TabItem sizeIcon='is-36px' icon='key' path={'/home/chaves'}>
                            <div>Chave para</div>
                            <div>envio de dados</div>
                        </TabItem>
                        <TabItem
                            sizeIcon='is-36px'
                            exact={false}
                            icon='controleRecebimento'
                            path={'/home/recebimentos'}
                        >
                            <div>Controle de</div>
                            <div>recebimento</div>
                            <div>de dados</div>
                        </TabItem>
                    </TabMenu>
                </Column>
                <Column offset='1' size='8'>
                    <Switch>
                        <Redirect exact from='/home' to='/home/sistemas' />
                        <Route
                            path={'/home/sistemas'}
                            render={() => {
                                return <SistemasIntegracaoView />
                            }}
                        />
                        <Route
                            exact
                            path={'/home/chaves'}
                            render={() => {
                                return <AutenticacaoIntegracaoView />
                            }}
                        />
                        <Route
                            path={'/home/recebimentos'}
                            render={() => {
                                return <RecebimentoIntegracaoView />
                            }}
                        />
                    </Switch>
                </Column>
            </Columns>
        </div>
    )
}
