import React from 'react'
import { reduxForm, formValueSelector, FieldArray } from 'redux-form/immutable'
import { connect } from 'react-redux'
import * as C from 'libs/components'
import * as FichaComponents from 'view/home/novoProcedimento/ficha/componentes'
import * as UUID from 'libs/util/UUID'
import LesoesEnxertoModalValidator from 'view/home/novoProcedimento/ficha/cardio/validators/LesoesEnxertoModalValidator'
import { isImmutable } from 'immutable'

const tipoLesaoOptions = {
    DE_NOVO: 'De novo',
    OCLUSAO_CRONICA: 'Oclusão crônica',
    REESTENOSE: 'Reestenose',
    TROMBOSE: 'Trombose',
}

const tipoEnxerto = {
    ARTERIAL: 'Aorto-coronário - arterial',
    VENOSO: 'Aorto-coronário - venoso',
    MAMARIA_DIREITA: 'In situ - mamária interna direita',
    MAMARIA_ESQUERDA: 'In situ - mamária interna esquerda',
}

const tipoLesaoCompostaOptions = {
    AGUDA: 'Aguda',
    SUBAGUDA: 'Subaguda',
    TARDIA: 'Tardia',
    BMS: 'BMS',
    BVS: 'BVS',
    DES: 'DES',
    STENT_PREVIO_DESCONHECIDO: 'Stent prévio não conhecido',
}

export class AdicionarLesaoEnxerto extends React.Component {
    render() {
        return (
            <C.Conteudo>
                <C.ControlField name={this.props.name} label='Lesões em enxertos' />
                <FieldArray
                    name={this.props.name}
                    disabled={this.props.disabled}
                    lesoesRelacionadas={this.props.lesoesRelacionadas}
                    component={LesaoTratadas}
                />
            </C.Conteudo>
        )
    }
}

class LesaoTratadas extends React.Component {
    constructor() {
        super()
        this.state = {
            openForm: false,
        }
        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.save = this.save.bind(this)
        this.delete = this.delete.bind(this)
    }

    render() {
        return (
            <ul>
                {(!this.props.fields || this.props.fields.length === 0 || this.props.fields.size === 0) && (
                    <C.EmptyListItem label='Não há lesões adicionadas.' />
                )}
                {this.props.fields.map((name, index) => (
                    <LesaoCard
                        chave={index}
                        key={index}
                        values={this.props.fields.get(index)}
                        onClickDelete={this.delete(index)}
                        lesoesRelacionadas={
                            this.props.lesoesRelacionadas &&
                            this.props.lesoesRelacionadas.indexOf(this.props.fields.get(index).get('id'))
                        }
                    />
                ))}
                {
                    <C.Button
                        type='grey'
                        square
                        dashed
                        size='large'
                        fullwidth
                        disabled={this.props.disabled}
                        onClick={this.open}
                    >
                        + ADICIONAR LESÃO EM ENXERTO
                    </C.Button>
                }
                {this.state.openForm && <LesaoTratadaForm onSubmit={this.save} close={this.close} />}
            </ul>
        )
    }

    open() {
        this.setState({ openForm: true })
    }

    close() {
        this.setState({ openForm: false })
    }

    save(form) {
        form = form.set('id', UUID.generateUUID())
        this.props.fields.push(form)
        this.close()
    }

    delete(index) {
        return () => {
            this.props.fields.remove(index)
        }
    }
}

class LesaoCard extends React.Component {
    render() {
        const disabled = this.props.lesoesRelacionadas !== -1
        const tipoEnxertoSelecionado =
            this.props.values.get('enxertoTipo') === 'IN_SITU'
                ? this.props.values.get('enxertoTipoInsitu')
                : this.props.values.get('enxertoTipoAorto')
        const coronariaDestino = this.props.values.get('coronariaDestino')
        const labelCoronaria = isImmutable(coronariaDestino) ? coronariaDestino.get('nome') : coronariaDestino.nome
        return (
            <li name={'lesao_' + (this.props.chave + 1)}>
                <C.Panel>
                    <C.PanelHeader>
                        {'Lesão ' + (this.props.chave + 1)}
                        <C.PanelSubHeader>{' (' + tipoEnxerto[tipoEnxertoSelecionado] + ')'}</C.PanelSubHeader>
                        <C.Hint
                            placement='top'
                            componente={
                                <C.IconButton
                                    pulledRight
                                    onClick={this.props.onClickDelete}
                                    disabled={disabled}
                                    icon='delete'
                                    iconOnly
                                />
                            }
                        >
                            {disabled
                                ? 'Remova os stents relacionados a ela para poder excluir a lesão'
                                : 'Excluir lesão'}
                        </C.Hint>
                    </C.PanelHeader>
                    <C.PanelContent>
                        <C.StyledCheckboxLabel key={this.props.chave} label={labelCoronaria} />
                        <C.Linha />
                        <C.Columns>
                            <C.Column size='3'>
                                <C.TextLabel
                                    title='AHA/ACC:'
                                    value={this.props.values.get('ahaacc')}
                                    placeholder={'Não informado'}
                                />
                            </C.Column>
                            <C.Column size='3'>
                                <C.TextLabel
                                    title='Tipo de lesão:'
                                    value={
                                        this.props.values.get('tipoLesao')
                                            ? tipoLesaoOptions[this.props.values.get('tipoLesao')]
                                            : ''
                                    }
                                    placeholder={'Não informado'}
                                />
                            </C.Column>
                            {this.props.values.get('tipoLesao') === 'TROMBOSE' && (
                                <C.Column size='5'>
                                    <C.TextLabel
                                        title='Tipo de trombose:'
                                        value={
                                            this.props.values.get('tipoTrombose')
                                                ? tipoLesaoCompostaOptions[this.props.values.get('tipoTrombose')]
                                                : ''
                                        }
                                        placeholder={'Não informado'}
                                    />
                                </C.Column>
                            )}
                            {this.props.values.get('tipoLesao') === 'REESTENOSE' && (
                                <C.Column size='5'>
                                    <C.TextLabel
                                        title='Tipo de reestenose:'
                                        value={
                                            this.props.values.get('tipoReestenose')
                                                ? tipoLesaoCompostaOptions[this.props.values.get('tipoReestenose')]
                                                : ''
                                        }
                                        placeholder={'Não informado'}
                                    />
                                </C.Column>
                            )}
                        </C.Columns>
                    </C.PanelContent>
                </C.Panel>
            </li>
        )
    }
}

class LesaoTratadaFormCmp extends React.Component {
    render() {
        return (
            <C.ModalCard title='Adicionar lesão em enxerto' active onClose={this.props.close}>
                <FichaComponents.LesaoEnxerto
                    name='enxertos'
                    hasReestenose={this.props.hasReestenose}
                    hasTrombose={this.props.hasTrombose}
                    aorto={this.props.aorto}
                    insitu={this.props.insitu}
                />
                <C.Linha />
                <C.ButtonBar>
                    <C.Button size='medium' onClick={this.props.close}>
                        Cancelar
                    </C.Button>
                    <C.Button
                        pulledRight
                        disabled={this.props.pristine}
                        type='primary'
                        size='medium'
                        onClick={this.props.handleSubmit}
                    >
                        Salvar
                    </C.Button>
                </C.ButtonBar>
            </C.ModalCard>
        )
    }
}

const selector = formValueSelector('stentCoronaria')
const selector2 = formValueSelector('adicionarLesaoEnxerto')

const LesaoTratadaForm = reduxForm({ form: 'adicionarLesaoEnxerto', validate: LesoesEnxertoModalValidator })(
    connect(state => {
        const dominancia = selector(state, 'essencial.lesoesTratadas.dominancia')
        const hasTrombose = selector2(state, 'tipoLesao') === 'TROMBOSE'
        const hasReestenose = selector2(state, 'tipoLesao') === 'REESTENOSE'
        const aorto = selector2(state, 'enxertoTipo') === 'AORTO_CORONARIO'
        const insitu = selector2(state, 'enxertoTipo') === 'IN_SITU'

        return {
            dominancia,
            hasTrombose,
            hasReestenose,
            aorto,
            insitu,
        }
    })(LesaoTratadaFormCmp)
)
