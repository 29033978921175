import Integracao from 'api/Integracao'
import { SistemaIntegracaoDto } from 'backend'
import { Label, Modal } from 'libs/components'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getSistemasAtivos, getSistemasInativos } from 'redux/modules/integracao'
import { CadastrarSistemaForm } from './CadastrarSistemaForm'

interface ModalCadastrarSistemaProps {
    isOpen: boolean
    pathAtivo: string
    onClickClose: () => void
    editValue: SistemaIntegracaoDto
}

export const ModalCadastrarSistema = (props: ModalCadastrarSistemaProps) => {
    const { isOpen, pathAtivo, onClickClose, editValue } = props

    const location = useLocation()
    const dispatch = useDispatch()

    const handleSuccessManual = () => {
        if (location.pathname === pathAtivo) {
            dispatch(getSistemasAtivos())
        } else {
            dispatch(getSistemasInativos())
        }
        onClickClose()
    }

    const handleSubmit = formValues => {
        if (editValue) {
            return Integracao.updateSistema(editValue.id, formValues.toJS()).then(handleSuccessManual)
        } else {
            return Integracao.saveSistema(formValues.toJS()).then(handleSuccessManual)
        }
    }

    return (
        <Modal active={isOpen}>
            <div className='modal-cadastrar-sistema-wrapper'>
                <div className='modal-card modal-cadastrar-sistema'>
                    <header className='modal-cadastrar-sistema-header'>
                        <Label className='header-title'>Cadastrar sistema para envio de dados</Label>
                    </header>
                    <CadastrarSistemaForm
                        onSubmit={handleSubmit}
                        onClickCancelar={onClickClose}
                        initialValues={{ empresa: editValue?.empresaNome, sistema: editValue?.sistemaNome }}
                    />
                </div>
                <button className='delete' onClick={onClickClose} />
            </div>
        </Modal>
    )
}
