import * as React from 'react'
import * as C from 'libs/components'
import { DadosCirurgiaVisualizacaoRevisaoJoelhoDto, ComponenteVisualizacaoDto } from 'backend'
import * as FC from 'view/home/novoProcedimento/ficha/componentes'

interface DadosCirurgiaVisualizacaoProps {
    dadosCirurgia: DadosCirurgiaVisualizacaoRevisaoJoelhoDto
    componentes: ComponenteVisualizacaoDto
}

export class DadosRevisaoJoelho extends React.Component<DadosCirurgiaVisualizacaoProps, any> {
    render() {
        const dadosCirurgia = this.props.dadosCirurgia
        const componentes = this.props.componentes

        return (
            <C.Conteudo>
                <C.Label className='with-border-bellow'>Motivos da revisão</C.Label>
                <C.TextLabel title='Motivos de artroplastia' value={dadosCirurgia.motivosRevisao} />

                <C.Label className='with-border-bellow'>Procedimento cirúrgico atual</C.Label>
                <C.Columns>
                    <C.Column>
                        <C.TextLabel title='Abordagem cirúrgica' value={dadosCirurgia.abordagemCirurgica} />
                    </C.Column>
                    <C.Column>
                        <C.TextLabel title='Uso de dreno' value={dadosCirurgia.usoDreno} />
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column>
                        <C.TextLabel title='Transfusão sanguínea' value={dadosCirurgia.transfusaoSanguinea} />
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column>
                        <C.TextLabel title='Antibiótico' value={dadosCirurgia.antibiotico} />
                    </C.Column>
                    <C.Column>
                        {dadosCirurgia.periodoAntibiotico && (
                            <C.TextLabel title='Período' value={dadosCirurgia.periodoAntibiotico} />
                        )}
                    </C.Column>
                </C.Columns>

                <C.Label className='with-border-bellow'>Tipo de revisão</C.Label>
                <C.TextLabel title='Tipo' value={dadosCirurgia.tipoRevisao} />

                <C.Label className='with-border-bellow'>Cimento ósseo</C.Label>
                <C.Columns>
                    <C.Column>
                        <C.TextLabel title='Foi utilizado' value={dadosCirurgia.cimentoOsseo} />
                    </C.Column>
                    <C.Column>
                        {dadosCirurgia.lavagemPulsatil && (
                            <C.TextLabel title='Lavagem pulsátil' value={dadosCirurgia.lavagemPulsatil} />
                        )}
                    </C.Column>
                </C.Columns>

                <C.Label className='with-border-bellow'>Enxertia</C.Label>
                {componentes.enxertiaFemoralOpcao || componentes.enxertiaTibialOpcao ? (
                    <C.Columns>
                        <C.Column>
                            <C.TextLabel title='Femoral' value={componentes.enxertiaFemoralOpcao} />
                        </C.Column>
                        <C.Column>
                            <C.TextLabel title='Tibial' value={componentes.enxertiaTibialOpcao} />
                        </C.Column>
                    </C.Columns>
                ) : (
                    <C.Columns>
                        <C.Column>
                            <C.TextLabel title='Foi realizada' value={'Não'} />
                        </C.Column>
                    </C.Columns>
                )}

                <FC.ComponentesUtilizadosArtroplastia
                    principais={componentes.componentesPrincipais}
                    complementares={componentes.componentesComplementares}
                />
            </C.Conteudo>
        )
    }
}
