import React, { ReactNode, useEffect, useState } from 'react'

export interface TwoStepPopupProps {
    hiddenHeight: number
    popupHeight: number
    children(expandPopup: () => void, closePopup: () => Promise<void>): ReactNode
}
export const SemiHiddenPopup = ({ hiddenHeight, popupHeight, children }: TwoStepPopupProps) => {
    const [bottom, setBottom] = useState<number>(popupHeight * -1)
    const [visible, setVisible] = useState<boolean>(true)
    useEffect(() => {
        setTimeout(() => setBottom(hiddenHeight * -1), 1000)
    }, [hiddenHeight])

    if (!visible) {
        return null
    }

    const closePopup = () => {
        setBottom(popupHeight * -1)
        return new Promise<void>((res, _) =>
            setTimeout(() => {
                setVisible(false)
                res()
            }, 600)
        )
    }
    const expandPopup = () => setBottom(0)
    return (
        <div className={'two-step-popup'} style={{ bottom: bottom }}>
            {children(expandPopup, closePopup)}
        </div>
    )
}
