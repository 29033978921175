
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.585,"time":35099.99999999999,"words":117}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Para realizar a integração de dados de outros hospitais com o RNI, é necessário gerar uma chave para autenticar o envio dos dados, para cada um dos sistemas cadastrados e ativos. `}</p>
    <p>{`Uma chave é composta por um login e uma senha, que podem ser gerados pelo perfil de Gestor de Integração através dos passos abaixo:`}</p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Chave para envio de dados”`}</li>
      <li parentName="ol">{`Na aba de “Login” clique no botão `}<strong parentName="li">{`Gerar novo login`}</strong></li>
      <li parentName="ol">{`Na aba de “Senha” clique no botão `}<strong parentName="li">{`Gerar nova senha`}</strong></li>
    </ol>
    <p>{`Após este processo, você terá uma nova chave gerada. Você poderá copiar o login clicando em `}<strong parentName="p">{`Copiar login`}</strong>{` e copiar a senha clicando em `}<strong parentName="p">{`Copiar senha`}</strong>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

