
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.22,"time":13200,"words":44}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "colaborador-anvisa"
    }}>{`Colaborador ANVISA`}</h3>
    <p>{`Perfil atribuído para os colaboradores da ANVISA que acessam o RNI para quaisquer fins. São cadastrados através do sistema de cadastro da ANVISA e poderão acessar o RNI com o mesmo usuário e senha utilizados para acessar outros sistemas da ANVISA.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "colaborador-anvisa",
    level: 3,
    title: "Colaborador ANVISA",
    children: [
        
      ]
  }
]

export const frontMatter = {}

