import { PerfilEnum } from 'api/Perfis'
import { createValidator, required } from 'libs/validation'

const SolicitacaoVinculoFormSectionValidator = createValidator(
    {
        instituicao: required,
        perfil: required,
        ufInstituicao: required,
        cidadeInstituicao: required,
    },
    form => {
        const errors = {}

        if (
            form.get('perfil') === PerfilEnum.MEDICO_CARDIOLOGISTA ||
            form.get('perfil') === PerfilEnum.MEDICO_ORTOPEDISTA
        ) {
            errors.numeroRegistro = required(form.get('numeroRegistro'))
            errors.ufRegistro = required(form.get('ufRegistro'))
        }

        return errors
    }
)

export default SolicitacaoVinculoFormSectionValidator
