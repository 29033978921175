import * as React from 'react'
import { MotivosRevisoesRow } from 'backend'
import { Card, LoadingContainer, Column } from 'libs/components'
import { MotivosRevisaoTable } from '../components/MotivosRevisaoTable'

interface Props {
    motivos: MotivosRevisoesRow[]
    isFetching: boolean
    report(): JSX.Element
}

export class MotivosRevisaoCard extends React.Component<Props> {
    render() {
        const { motivos, isFetching } = this.props

        return (
            <Card title='Motivos de revisão' noPadding={!isFetching} headerAction={this.props.report()}>
                <LoadingContainer isFetching={isFetching}>
                    {motivos && motivos.length > 0 && <MotivosRevisaoTable rows={motivos} />}
                    {!motivos ||
                        (motivos.length < 1 && (
                            <Column textCentered borderBottom>
                                <p style={{ lineHeight: 2 }} className='is-text-muted'>
                                    Nenhum motivo encontrado.
                                </p>
                            </Column>
                        ))}
                </LoadingContainer>
            </Card>
        )
    }
}
