import * as React from 'react'
import {
    DateInterval,
    TipoCirurgia,
    DistribuicaoFinanceiraDto,
    DistribuicaoProcedimentoFiltro,
    ProdutoDto,
} from 'backend'
import { ScrollToTop } from 'libs/components'
import UrlParams from 'libs/router/UrlParams'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import { RouteComponentProps } from 'react-router-dom'
import { Columns, Column } from 'libs/components'
import { PeriodoFilter } from 'libs/components/presentational/PeriodoFilter'
import { NavLink } from 'react-router-dom'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { ProporcaoFinanceiroCard } from '../cards/ProporcaoFinanceiroCard'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import * as C from 'libs/components'
import * as enums from 'redux/enums'
import { ValorMedioCard } from '../cards/ValorMedioCard'
import { DadosFinanceirosFilter } from './DadosFinanceirosFilter'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import Text from 'libs/components/presentational/typography/Text'
import { parse } from 'redux/modules/router'
import BackButton from 'libs/components/presentational/elements/BackButton'

const InformacoesProduto = props => {
    return (
        <div className='report-content informacoes-fabricante'>
            <div className='inf-fab-content'>
                <span className='titulo'>REG. ANVISA</span>{' '}
                <Text weight='semibold'>{props.registro.registroAnvisa} </Text>
            </div>
            <div>
                <label className='nome-fabricante'>{props.registro.nomeProduto}</label>
            </div>
            <div>
                <label className='nome-fabricante is-grey-darker'>{props.registro.nomeFabricanteImportador}</label>
            </div>
        </div>
    )
}

export interface DadosFinanceirosProps extends RouteComponentProps<any> {
    dados: PromiseRecord<DistribuicaoFinanceiraDto>
    filtro: DistribuicaoProcedimentoFiltro
    produtoAnvisa: PromiseRecord<ProdutoDto>
    goBack(): void
    changeFilter(filtro: DistribuicaoProcedimentoFiltro): void
    doLoad(): void
    loadProdutoAnvisa(registroAnvisa: string): void
    clearProdutoAnvisa(): void
}

interface DadosFinanceirosState {
    registroAnvisa: any
}

export class DadosFinanceirosView extends React.Component<DadosFinanceirosProps, DadosFinanceirosState> {
    constructor(props) {
        super(props)
        this.state = { registroAnvisa: undefined }
    }

    componentWillUnmount() {
        this.props.clearProdutoAnvisa()
    }

    handleLoadProdutoAnvisa() {
        const search = parse(this.props.location.search)
        if (!this.state.registroAnvisa && search.registroAnvisa) {
            this.props.loadProdutoAnvisa(search.registroAnvisa)
        }
    }

    handlePeriodoChange = (interval: DateInterval) => {
        this.props.changeFilter({
            ...this.props.filtro,
            interval: interval,
        })
    }

    handleTabItemClick = (tipoCirurgia: TipoCirurgia) => {
        this.props.changeFilter({
            ...this.props.filtro,
            tipoCirurgia: tipoCirurgia,
        })
    }

    handleSearchChange = data => {
        const registro = data.get('registroAnvisa')

        if (!registro && this.props.produtoAnvisa.data) {
            this.props.clearProdutoAnvisa()
        }

        this.setState({ registroAnvisa: registro })
        this.props.changeFilter({
            ...this.props.filtro,
            registroAnvisa: registro && registro.registroAnvisa,
        })
    }

    refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
            this.setState({ registroAnvisa: undefined }, () => {
                this.handleLoadProdutoAnvisa()
            })
        }
        this.props.doLoad()
    }

    getTitle = numeroProcedimentos => {
        let cirurgia = numeroProcedimentos > 1 ? ' cirurgias' : ' cirurgia'
        return `Produtos utilizados em ${numeroProcedimentos} ${cirurgia}`
    }

    getFilter = () => {
        const currentFilter = this.props.filtro || {}
        return {
            ...currentFilter,
            tipoCirurgia: currentFilter.tipoCirurgia || 'JOELHO',
        } as DistribuicaoProcedimentoFiltro
    }

    render() {
        const filtro = this.getFilter()
        const dados = this.props.dados
        const data = dados && dados.data
        const registro = this.state.registroAnvisa || this.props.produtoAnvisa.data
        return (
            <div>
                <ScrollToTop onMount />
                <UrlParams params={filtro} onChange={this.refresh} />
                <ReportClearFilter />

                <LocalHeaderContainer />
                <ReportHeader title='Dados financeiros'>
                    <div>
                        <BackButton />
                    </div>

                    <HorizontalTabMenu>
                        <HorizontalTabMenuItem
                            title='Joelho'
                            onClick={() => this.handleTabItemClick('JOELHO')}
                            active={filtro.tipoCirurgia === 'JOELHO'}
                        />
                        <HorizontalTabMenuItem
                            title='Quadril'
                            onClick={() => this.handleTabItemClick('QUADRIL')}
                            active={filtro.tipoCirurgia === 'QUADRIL'}
                        />
                        <HorizontalTabMenuItem
                            title='Cardiologia'
                            onClick={() => this.handleTabItemClick('CORACAO')}
                            active={filtro.tipoCirurgia === 'CORACAO'}
                        />
                    </HorizontalTabMenu>
                </ReportHeader>
                <div className='report-content'>
                    <DadosFinanceirosFilter onSubmit={this.handleSearchChange} />
                    {filtro.registroAnvisa && registro && <InformacoesProduto registro={registro} />}
                    <Columns verticalFlow>
                        <Column>
                            <Columns className='report-tabs align-center'>
                                <Column size='6'>
                                    <div className='report-tabs-links'>
                                        <NavLink
                                            to={{
                                                pathname: `${this.props.match.url}`,
                                                search: this.props.location.search,
                                            }}
                                            activeClassName='is-active'
                                        >
                                            Dashboard
                                        </NavLink>
                                    </div>
                                </Column>
                                <Column size='6' textRight>
                                    <PeriodoFilter value={filtro.interval} onChange={this.handlePeriodoChange} />
                                </Column>
                            </Columns>
                        </Column>
                        <Column className='has-text-centered'>
                            <C.Card title='Dados de uso'>
                                <C.LoadingStateContainer readyState={dados.readyState}>
                                    <Columns>
                                        <Column>
                                            {data && (
                                                <C.ComposedLabel
                                                    title={this.getTitle(data.totalProcedimentos)}
                                                    labelClassName='is-muted'
                                                    inverted
                                                    big
                                                >
                                                    <C.NumberLabel value={data.totalProdutos} placeholder='0' />
                                                </C.ComposedLabel>
                                            )}
                                        </Column>
                                    </Columns>
                                </C.LoadingStateContainer>
                            </C.Card>
                        </Column>
                        {filtro.registroAnvisa && data && <ValorMedioCard dados={dados} />}
                        {data && (
                            <Column>
                                <Columns>
                                    {data.totalByTipo &&
                                        Object.entries(data.totalByTipo)
                                            .reverse()
                                            .map(([key, value]) => {
                                                return (
                                                    <Column key={key} size='6'>
                                                        <ProporcaoFinanceiroCard
                                                            dados={dados}
                                                            title={enums.SituacaoFinanceiro[key].descCard}
                                                            situacao={key}
                                                        />
                                                    </Column>
                                                )
                                            })}
                                </Columns>
                            </Column>
                        )}
                    </Columns>
                </div>
            </div>
        )
    }
}
