import * as React from 'react'
import * as C from 'libs/components'
import { MotivosProcedimentosRow, FiltroCirurgia } from 'backend'
import { Progress } from 'libs/components/presentational/elements/Progress'
import { Icon } from 'libs/components'
import { NumberLabel } from 'libs/components/label/NumberLabel'
import { classificacaoCirurgiaReportClassName } from 'redux/enums/index'

interface Props {
    motivo: MotivosProcedimentosRow
    filtro: FiltroCirurgia
    total: number
    onClick: () => void
}

export class SumarioMotivoProcedimentoCard extends React.Component<Props> {
    render() {
        const motivo = this.props.motivo
        return (
            <div className='padded-botton'>
                <C.Card>
                    <C.Columns className='align-center'>
                        <C.Column size='5'>
                            <C.Label className='is-black-bis'> {motivo.motivoName}</C.Label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: (motivo.cont / this.props.total) * 100 + '%',
                                        marginRight: '10px',
                                    }}
                                >
                                    <Progress
                                        value={1}
                                        className={classificacaoCirurgiaReportClassName[this.props.filtro]}
                                        size='x-small'
                                    />
                                </span>
                                <NumberLabel
                                    className='percent'
                                    value={(motivo.cont / this.props.total) * 100}
                                    maxDecimalPlaces={1}
                                    sufix='%'
                                    placeholder='- %'
                                />
                            </div>
                        </C.Column>
                        <C.Column className='has-border-right'>
                            <span className='total-proced-count'>{motivo.cont}</span>
                        </C.Column>
                        <C.Column size='2'>
                            <C.Label className='motivos-card-label'> Média de idade</C.Label>
                            <NumberLabel
                                className='motivo-card-content'
                                value={motivo.avg}
                                maxDecimalPlaces={1}
                                sufix=' anos'
                                placeholder='-'
                            />
                        </C.Column>
                        <C.Column size='3' verticalFlow>
                            <span>
                                <Icon size='is-x-small' icon='masculino' />
                                <span className='motivos-card-label'>
                                    {' '}
                                    Masculino{' '}
                                    <NumberLabel
                                        className='motivo-card-content'
                                        value={(motivo.contMasc / motivo.cont) * 100}
                                        maxDecimalPlaces={1}
                                        sufix='%'
                                        placeholder='- %'
                                    />
                                </span>
                            </span>
                            <span>
                                <Icon size='is-x-small' icon='feminino' />
                                <span className='motivos-card-label'>
                                    {' '}
                                    Feminino{' '}
                                    <NumberLabel
                                        className='motivo-card-content'
                                        value={(motivo.contFem / motivo.cont) * 100}
                                        maxDecimalPlaces={1}
                                        sufix='%'
                                        placeholder='- %'
                                    />
                                </span>
                            </span>
                        </C.Column>
                        <C.Column size='1'>
                            <C.Hint
                                placement='top'
                                componente={
                                    <C.IconButton
                                        disabled={motivo.cont <= 0}
                                        data-name={'botao_' + motivo.motivo}
                                        icon='arrowRight'
                                        size='is-x-small'
                                        pulledRight
                                        onClick={this.props.onClick}
                                    />
                                }
                            >
                                Visualizar
                            </C.Hint>
                        </C.Column>
                    </C.Columns>
                </C.Card>
            </div>
        )
    }
}
