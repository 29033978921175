import React from 'react'
import * as C from 'libs/components'
import * as FichaComponents from 'view/home/novoProcedimento/ficha/componentes'

export class DataValidade extends React.Component {
    render() {
        return (
            <C.Columns>
                <C.Column size='4'>
                    <FichaComponents.AnoDataValidadeField
                        anoBase={this.props.anoCirurgia}
                        name='anoValidade'
                        placeholder='Ano'
                        disabled={this.props.disabledAno}
                        maxMenuHeight={145}
                    />
                </C.Column>
                <C.Column size='4'>
                    <C.MesField
                        name='mesValidade'
                        placeholder='Mês'
                        disabled={this.props.disabledMes}
                        maxMenuHeight={145}
                    />
                </C.Column>
                <C.Column size='3'>
                    <C.DiaField
                        name='diaValidade'
                        placeholder='Dia'
                        disabled={this.props.disabledDia}
                        ano={this.props.ano}
                        mes={this.props.mes}
                        maxDia={this.props.maxDia}
                        maxMenuHeight={145}
                    />
                </C.Column>
            </C.Columns>
        )
    }
}
