import * as React from 'react'
import { Modal } from 'libs/components'
import IconeTipoAlerta from '../components/IconeTipoAlerta'
import Text from 'libs/components/presentational/typography/Text'
import { TipoAlerta } from 'backend'
import classnames from 'classnames'

interface AlertaModalProps {
    active: boolean
    className?: string
    fechar()
    renderFooter(): JSX.Element
    subtitulo: string
    tipoAlerta: TipoAlerta
    titulo: string
}

export class AlertaModal extends React.PureComponent<AlertaModalProps> {
    render() {
        const modalClasses = classnames('modal-card modal-alerta', this.props.className)
        return (
            <Modal active={this.props.active}>
                <div className={modalClasses}>
                    <header className='modal-alerta-head'>
                        <button className='delete' onClick={this.props.fechar}></button>
                        <div className='info'>
                            <IconeTipoAlerta size='large' tipoAlerta={this.props.tipoAlerta} />
                            <Text size='x-large'>{this.props.titulo}</Text>
                            {this.props.subtitulo && (
                                <Text color='grey-darker' size='x-small' weight='semibold'>
                                    {this.props.subtitulo}
                                </Text>
                            )}
                        </div>
                    </header>
                    <section className='modal-card-body'>
                        <div className='modal-card-body-content'>{this.props.children}</div>
                    </section>
                    <footer className='modal-card-footer'>{this.props.renderFooter()}</footer>
                </div>
            </Modal>
        )
    }
}
