import { PerfilEnum, PerfisRni, PerfisWithLargerHeader, PerfisMedico } from 'api/Perfis'
import { Perfil } from 'backend'
import * as C from 'libs/components'
import PerfilRoute from 'libs/router/PerfilRoute'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import { PesquisaSatisfacao } from 'view/componentes/pesquisaSatisfacao/PesquisaSatisfacao'
import { PesquisaSatisfacaoProvider } from 'view/componentes/pesquisaSatisfacao/PesquisaSatisfacaoContext'
import HomeAdministrativoContainer from 'view/home/administrativo/HomeAdministrativoContainer'
import AlterarSenhaContainer from 'view/home/alterarSenha/AlterarSenhaContainer'
import HomeHeader from 'view/home/componentes/HomeHeader'
import AlterarDadosPessoaisContainer from 'view/home/dadosPessoais/AlterarDadosPessoaisContainer'
import HomeGestorSegurancaContainer from 'view/home/gestorSeguranca/HomeGestorSegurancaContainer'
import HomeMedicoContainer from 'view/home/medico/HomeMedicoContainer'
import NovoProcedimentoContainer from 'view/home/novoProcedimento/NovoProcedimentoContainer'
import RastreabilidadePerfilRoute from 'view/home/rastreabilidade/route/RastreabilidadePerfilRoute'
import ShowcaseContainer from 'view/home/showcase/ShowcaseContainer'
import HomeTecnicoContainer from 'view/home/tecnico/HomeTecnicoContainer'
import VisualizacaoContainer from 'view/home/visualizacao/VisualizacaoContainer'
import { HomeAnvisa } from './anvisa/HomeAnvisa'
import { HomeGestorIntegracaoView } from './gestorIntegracao/HomeGestorIntegracaoView'
import { HomeGestorServicoSaude } from './gestorServicoSaude/HomeGestorServicoSaude'
import { ManualHeader } from './manualUsuario/ManualHeader'
import ManualUsuarioContainer from './manualUsuario/ManualUsuarioContainer'
import { FinanceiroRastreabilidadeView } from './rastreabilidade/dadosFinanceiro/FinanceiroRastreabilidadeView'
import { ProdutoRastreabilidadeView } from './rastreabilidade/dadosProduto/ProdutoRastreabilidadeView'
import { RastreabilidadeGeralView } from './rastreabilidade/geral/RastreabilidadeGeralView'
import { EdicaoProcedimento } from './edicaoProcedimento/EdicaoProcedimento'
import { RelacaoEmpresasView } from './relacaoEmpresas/RelacaoEmpresasView'
import { RelacaoEmpresasModalNotificacao } from './relacaoEmpresas/RelacaoEmpresasModalNotificacao'
import { HomeGestorSNVS } from './gestorSistemaNacionalVigilanciaSanitaria/HomeGestorSNVS'

interface HomeViewProps extends RouteComponentProps<any> {
    perfil: Perfil
    updateLogin: () => void
}

class HomeView extends React.Component<HomeViewProps> {
    constructor(props) {
        super(props)

        this.autoSelectPerfil = this.autoSelectPerfil.bind(this)
    }

    autoSelectPerfil(eve) {
        if (eve.key === 'perfilSelecionado') {
            this.props.updateLogin()
        }
    }

    componentDidMount() {
        window.addEventListener('storage', this.autoSelectPerfil, false)
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.autoSelectPerfil, false)
    }

    render() {
        const containerClassName: string = this.constructContainerClassName(
            this.props.perfil,
            this.props.location.pathname
        )

        return (
            <div className='is-full-height is-vertical-flow'>
                <Switch>
                    <Route component={ManualHeader} path={'/home/manualUsuario'} />
                    <Route component={C.IdentificacaoSistemaHeader} />
                </Switch>
                <div className='is-full-height is-vertical-flow'>
                    <Route component={C.Breadcrumbs} />
                    <PesquisaSatisfacaoProvider>
                        <div className={containerClassName}>
                            {this.constructHomePerfil(this.props.perfil)}
                            {process.env.NODE_ENV === 'development' && (
                                <Route path={`${this.props.match.url}/showcase`} component={ShowcaseContainer} />
                            )}
                            <PerfilRoute
                                perfis={PerfisMedico}
                                path={this.props.match.url}
                                component={RelacaoEmpresasModalNotificacao}
                            />
                            <PerfilRoute
                                perfis={PerfisMedico}
                                path={`${this.props.match.url}/relacaoEmpresas`}
                                component={RelacaoEmpresasView}
                            />
                            <PerfilRoute
                                perfis={PerfisRni}
                                path={`${this.props.match.url}/alterarSenha`}
                                component={AlterarSenhaContainer}
                            />
                            <PerfilRoute
                                perfis={PerfisRni}
                                path={`${this.props.match.url}/atualizarDadosPessoais`}
                                component={AlterarDadosPessoaisContainer}
                            />
                            <Route
                                path={`${this.props.match.url}/manualUsuario/:idPai?/:idFilho?/:btn?`}
                                component={ManualUsuarioContainer}
                            />
                            <Route path={`${this.props.match.url}/edicaoProcedimento`} component={EdicaoProcedimento} />
                            <PerfilRoute
                                perfis={[...PerfisMedico, PerfilEnum.TECNICO]}
                                path={`${this.props.match.url}/novoProcedimento/:idFicha([0-9]+)?`}
                                component={NovoProcedimentoContainer}
                            />
                            <RastreabilidadePerfilRoute
                                path={`${this.props.match.url}/dadosProduto/:index?`}
                                perfis={[...PerfisMedico, PerfilEnum.TECNICO]}
                                component={ProdutoRastreabilidadeView}
                            />
                            <PerfilRoute
                                perfis={[...PerfisMedico, PerfilEnum.TECNICO, PerfilEnum.ADMINISTRATIVO]}
                                path={`${this.props.match.url}/visualizacao`}
                                component={VisualizacaoContainer}
                            />
                            <RastreabilidadePerfilRoute
                                path={`${this.props.match.url}/dadosFinanceiro`}
                                perfis={[PerfilEnum.ADMINISTRATIVO]}
                                component={FinanceiroRastreabilidadeView}
                            />
                            <RastreabilidadePerfilRoute
                                path={`${this.props.match.url}/dadosProdutoFinanceiro`}
                                perfis={[PerfilEnum.ADMINISTRATIVO]}
                                component={RastreabilidadeGeralView}
                            />
                        </div>
                        <PesquisaSatisfacao />
                    </PesquisaSatisfacaoProvider>
                </div>
            </div>
        )
    }

    constructContainerClassName(perfilUsuario: Perfil, pathname: string): string {
        let resultContainerClassName: string = ''

        if (pathname.startsWith('/home/relacaoEmpresas')) {
            resultContainerClassName = 'container is-vertical-flow is-full-height home-conteudo-without-padding'
        } else if (!PerfisWithLargerHeader.includes(perfilUsuario) || this.pagesWithContainerDependency(pathname)) {
            resultContainerClassName = 'container is-vertical-flow is-full-height home-conteudo'
        }

        return resultContainerClassName
    }

    pagesWithContainerDependency(pathname: string): boolean {
        return (
            pathname.startsWith('/home/manualUsuario') ||
            pathname.startsWith('/home/alterarSenha') ||
            pathname.startsWith('/home/atualizarDadosPessoais') ||
            pathname.startsWith('/home/showcase')
        )
    }

    constructHomePerfil(perfilUsuario: Perfil): any {
        const handleViewToPerfil: { [key in Perfil]: any } = {
            ADMINISTRATIVO: this.administrativo(),
            GESTOR_SEGURANCA: this.gestorSeguranca(),
            MEDICO_ORTOPEDISTA: this.medico(),
            MEDICO_CARDIOLOGISTA: this.medico(),
            TECNICO: this.tecnico(),
            GESTOR_INTEGRACAO: this.gestorIntegracao(),
            GESTOR_SERVICO_SAUDE: <HomeGestorServicoSaude />,
            ANVISA: <HomeAnvisa />,
            RESIDENTE_CARDIOLOGISTA: this.medico(),
            RESIDENTE_ORTOPEDISTA: this.medico(),
            SNVS: <HomeGestorSNVS />,
        }
        return handleViewToPerfil[perfilUsuario]
    }

    administrativo() {
        const urls = [
            `${this.props.match.url}`,
            `${this.props.match.url}/aguardandoDadosProdutoFinanceiros`,
            `${this.props.match.url}/historico`,
        ]

        return this.createPerfilHomeRoutes(urls, HomeAdministrativoContainer)
    }

    gestorSeguranca() {
        const urls = [
            `${this.props.match.url}`,
            `${this.props.match.url}/vinculosPendentes`,
            `${this.props.match.url}/vinculosRecusados`,
        ]

        return this.createPerfilHomeRoutes(urls, HomeGestorSegurancaContainer)
    }

    medico() {
        const urls = [
            `${this.props.match.url}`,
            `${this.props.match.url}/aguardandoDadosProduto`,
            `${this.props.match.url}/aguardandoDadosFinanceiros`,
            `${this.props.match.url}/historico`,
        ]

        return this.createPerfilHomeRoutes(urls, HomeMedicoContainer)
    }

    tecnico() {
        const urls = [
            `${this.props.match.url}`,
            `${this.props.match.url}/aguardandoDadosClinicos`,
            `${this.props.match.url}/aguardandoDadosFinanceiros`,
            `${this.props.match.url}/historico`,
        ]

        return this.createPerfilHomeRoutes(urls, HomeTecnicoContainer)
    }

    gestorIntegracao() {
        const urls = [
            `${this.props.match.url}`,
            `${this.props.match.url}/sistemas`,
            `${this.props.match.url}/sistemas/inativos`,
            `${this.props.match.url}/chaves`,
            `${this.props.match.url}/recebimentos`,
            `${this.props.match.url}/recebimentos/recusados`,
        ]

        return this.createPerfilHomeRoutes(urls, HomeGestorIntegracaoView)
    }

    createPerfilHomeRoutes(urls, component) {
        return urls.map((url, index) => [
            <Route key={index + 'home'} exact path={url} component={HomeHeader} />,
            <Route key={index} exact path={url} component={component} />,
        ])
    }
}

export default HomeView
