import * as React from 'react'
import * as Util from 'libs/components/Util'
import classnames from 'classnames'

interface Props extends Util.UtilProps {
    className?: string
}

export class Conteudo extends React.Component<Props, any> {
    render() {
        const classes = classnames('conteudo', this.props.className, Util.helpersClassnames(this.props))
        return <div className={classes}>{this.props.children}</div>
    }
}
