/* tslint:disable */
import React from 'react'

const SvgAlertasAumentoregistros = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M18.536 11.434l1.977 1.154a.983.983 0 00.581.13.998.998 0 00.902-1.083l-.489-5.72a1.009 1.009 0 00-.09-.338.987.987 0 00-1.317-.483L14.95 7.526a1.007 1.007 0 00-.076 1.774l1.502.875-4.362 5.988-3.31-4.373a1.231 1.231 0 00-1.943-.031l-4.483 5.543a1.26 1.26 0 00.176 1.76 1.23 1.23 0 001.743-.177l3.491-4.317 3.361 4.44a1.231 1.231 0 001.982-.018l5.504-7.556z' />
    </svg>
)

export default SvgAlertasAumentoregistros
