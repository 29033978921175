import * as React from 'react'

interface Props {
    loading?: boolean
    handleSubmit?: React.EventHandler<React.FormEvent<HTMLFormElement>>
}

export class Form extends React.Component<Props, any> {
    render() {
        if (this.props.loading) {
            return <span>Carregando...</span>
        } else {
            return <form onSubmit={this.props.handleSubmit}>{this.props.children}</form>
        }
    }
}
