import classnames from 'classnames'
import { filterProps, helpersClassnames, UtilProps } from 'libs/components/Util'
import * as React from 'react'
import { IconMap, Icons } from './icons'

export type IconSize =
    | 'small'
    | 'medium'
    | 'large'
    | 'is-12px'
    | 'gigantic'
    | 'is-x-small'
    | 'auto'
    | 'is-20px'
    | 'is-24px'
    | 'is-36px'

export interface IconProps extends UtilProps, React.SVGProps<SVGSVGElement> {
    icon: Icons
    size?: IconSize
}

export class Icon extends React.Component<IconProps, any> {
    render() {
        const classes: string = classnames(
            'rnicons',
            'rnicons-' + this.props.icon,
            this.props.className,
            helpersClassnames(this.props),
            {
                'is-small': this.props.size && this.props.size === 'small',
                'is-medium': this.props.size && this.props.size === 'medium',
                'is-large': this.props.size && this.props.size === 'large',
                'is-gigantic': this.props.size && this.props.size === 'gigantic',
                'is-12px': this.props.size && this.props.size === 'is-12px',
                'is-x-small': this.props.size && this.props.size === 'is-x-small',
                'is-auto': this.props.size && this.props.size === 'auto',
                'is-20px': this.props.size && this.props.size === 'is-20px',
                'is-24px': this.props.size && this.props.size === 'is-24px',
                'is-36px': this.props.size && this.props.size === 'is-36px',
            }
        )

        const filteredProps = filterProps(this.props, 'icon', 'size')
        const SelectedIcon = IconMap[this.props.icon]

        return <SelectedIcon {...filteredProps} className={classes} />
    }
}
