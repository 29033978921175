import * as React from 'react'
import * as C from 'libs/components'
import classnames from 'classnames'
import * as Util from 'libs/components/Util'

interface Props extends C.IconProps {
    id?: any
    border?: boolean
    iconOnly?: boolean
    name?: string
    onClick?: any
    type?: string
    // next two lines are necessary to overwrite props from React.SVGProps
    onPointerEnter?: React.PointerEventHandler<HTMLButtonElement | SVGSVGElement>
    onPointerLeave?: React.PointerEventHandler<HTMLButtonElement | SVGSVGElement>
}

export class IconButton extends React.Component<Props, any> {
    render() {
        const classes: string = classnames(
            'icon-button button',
            this.props.className,
            Util.helpersClassnames(this.props),
            {
                'is-light': this.props.type && this.props.type === 'light',
                'is-grey': this.props.type && this.props.type === 'grey',
                'is-grey-darker': this.props.type && this.props.type === 'grey-darker',
                'is-primary-hover': this.props.type && this.props.type === 'primary-hover',
                'is-success': this.props.type && this.props.type === 'success',
                'is-icon-only': this.props.iconOnly,
                'has-border': this.props.border,
            }
        )

        const { onClick, onPointerEnter, onPointerLeave, ...rest } = this.props

        return (
            <button
                id={this.props.id}
                name={this.props.name}
                type='button'
                className={classes}
                disabled={this.props.disabled}
                onClick={onClick}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
            >
                <C.Icon icon={this.props.icon} {...Util.filterProps(rest, ...excludedProps)} />
            </button>
        )
    }
}

const excludedProps = ['border', 'iconOnly', 'type']
