import * as React from 'react'
import { Popover, Placement } from './elements/Popover'

interface AutoPopoverState {
    opened: boolean
}

interface AutoPopoverProps {
    className?: string
    placement?: Placement
    rootClose?: boolean
    renderTrigger(open: () => void, close: () => void)
}

export default class AutoPopover extends React.Component<AutoPopoverProps, AutoPopoverState> {
    private triggerRef

    constructor(props) {
        super(props)

        this.state = {
            opened: false,
        }
    }

    render() {
        const trigger = this.props.renderTrigger(this.open, this.close)
        return (
            <div>
                <Popover
                    className={this.props.className}
                    show={this.state.opened}
                    placement={this.props.placement}
                    rootClose={this.props.rootClose}
                    onHide={this.close}
                    target={this.triggerRef}
                >
                    {this.props.children}
                </Popover>
                {React.cloneElement(trigger, { ref: elem => (this.triggerRef = elem) })}
            </div>
        )
    }

    public open = () => {
        this.setState({ opened: true })
    }

    public close = () => {
        this.setState({ opened: false })
    }
}
