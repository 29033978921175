import { createSelector } from 'reselect'
import { State } from 'redux/modules'
import { TermoUsoDTO } from 'backend'
import { PromiseRecord } from 'redux/middleware/PromiseAction'

const getTermoUso = (state: State) => state.termoUso.termoUso

export const getTermoUsoVigente = createSelector(getTermoUso, (termoUso: PromiseRecord<TermoUsoDTO>) => {
    return termoUso.data
})

export const isFetchingTermoUso = createSelector(getTermoUso, (termoUso: PromiseRecord<TermoUsoDTO>) => {
    return termoUso.readyState
})
