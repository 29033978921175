import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import VisualizacaoPrimariaQuadrilView from 'view/home/visualizacao/fichas/VisualizacaoPrimariaQuadrilView'
import VisualizacaoRevisaoJoelhoView from 'view/home/visualizacao/fichas/VisualizacaoRevisaoJoelhoView'
import VisualizacaoRevisaoQuadrilView from 'view/home/visualizacao/fichas/VisualizacaoRevisaoQuadrilView'
import VisualizacaoStentCoronariaView from 'view/home/visualizacao/fichas/VisualizacaoStentCoronariaView'
import VisualizacaoEdicaoPrimariaJoelhoView from './fichas/VisualizacaoEdicaoPrimariaJoelhoView'
import VisualizacaoEdicaoPrimariaQuadrilView from './fichas/VisualizacaoEdicaoPrimariaQuadrilView'
import VisualizacaoEdicaoRevisaoJoelhoView from './fichas/VisualizacaoEdicaoRevisaoJoelhoView'
import VisualizacaoEdicaoRevisaoQuadrilView from './fichas/VisualizacaoEdicaoRevisaoQuadrilView'
import VisualizacaoEdicaoStentCoronariaView from './fichas/VisualizacaoEdicaoStentCoronariaView'
import VisualizacaoPrimariaJoelhoView from './fichas/VisualizacaoPrimariaJoelhoView'

interface VisualizacaoViewProps extends RouteComponentProps<any> {
    clear: () => void
}

class VisualizacaoView extends React.Component<VisualizacaoViewProps> {
    componentWillUnmount() {
        this.props.clear()
    }

    render() {
        return (
            <Switch>
                <Route
                    path={`${this.props.match.url}/primariaQuadril/:id/:idEdicao`}
                    render={props => <VisualizacaoEdicaoPrimariaQuadrilView {...props} />}
                />
                <Route
                    path={`${this.props.match.url}/primariaQuadril/:id`}
                    render={props => <VisualizacaoPrimariaQuadrilView {...props} />}
                />
                <Route
                    path={`${this.props.match.url}/revisaoQuadril/:id/:idEdicao`}
                    render={props => <VisualizacaoEdicaoRevisaoQuadrilView {...props} />}
                />
                <Route
                    path={`${this.props.match.url}/revisaoQuadril/:id`}
                    render={props => <VisualizacaoRevisaoQuadrilView {...props} />}
                />
                <Route
                    path={`${this.props.match.url}/primariaJoelho/:id/:idEdicao`}
                    render={props => <VisualizacaoEdicaoPrimariaJoelhoView {...props} />}
                />
                <Route
                    path={`${this.props.match.url}/primariaJoelho/:id`}
                    render={props => <VisualizacaoPrimariaJoelhoView {...props} />}
                />
                <Route
                    path={`${this.props.match.url}/revisaoJoelho/:id/:idEdicao`}
                    render={props => <VisualizacaoEdicaoRevisaoJoelhoView {...props} />}
                />
                <Route
                    path={`${this.props.match.url}/revisaoJoelho/:id`}
                    render={props => <VisualizacaoRevisaoJoelhoView {...props} />}
                />
                <Route
                    path={`${this.props.match.url}/stentCoronaria/:id/:idEdicao`}
                    render={props => <VisualizacaoEdicaoStentCoronariaView {...props} />}
                />
                <Route
                    path={`${this.props.match.url}/stentCoronaria/:id`}
                    render={props => <VisualizacaoStentCoronariaView {...props} />}
                />
            </Switch>
        )
    }
}

export default VisualizacaoView
