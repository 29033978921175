import * as React from 'react'
import * as C from 'libs/components'
import * as BridgeReact from 'libs/components'

interface PrintIconProps {
    onClick: () => void
}

export default class PrintIcon extends React.Component<PrintIconProps> {
    render() {
        return (
            <p key='3' onClick={this.props.onClick}>
                <BridgeReact.Hint
                    placement='top'
                    componente={<C.IconButton type='light' pulledRight icon='imprimir' />}
                >
                    Imprimir
                </BridgeReact.Hint>
            </p>
        )
    }
}
