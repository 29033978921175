import React from 'react'
import * as C from 'libs/components'
import { isImmutable } from 'immutable'

const DetalhesSemRegistroAnvisa = () => {
    return (
        <div>
            <C.Columns>
                <C.Column size='6'>
                    <C.MaskedField
                        mask='99.999.999/9999-99'
                        placeholder={'__.___.___/____-__'}
                        label='CNPJ do Fabricante/Importador'
                        name='cnpj'
                    />
                </C.Column>
            </C.Columns>
            <C.Columns>
                <C.Column size='6'>
                    <C.TextField
                        label='Fabricante/Importador'
                        maxLength='120'
                        name='nomeFabricanteImportador'
                        required
                    />
                </C.Column>
            </C.Columns>
            <C.Linha />
        </div>
    )
}

const ListaFabricantes = props => (
    <C.Columns auto>
        {props.registroAnvisa ? (
            <C.ComposedLabel title='Fabricante(s):' name='fabricante' placeholder='Não cadastrado'>
                {props.fabricantes && props.fabricantes.length > 0 && (
                    <ul className='list-circle'>
                        {props.fabricantes.map((data, index) => (
                            <li key={index}> {data.fabricante}</li>
                        ))}
                    </ul>
                )}
            </C.ComposedLabel>
        ) : (
            <C.TextLabel title='Fabricante(s):' name='fabricante' value='-' />
        )}
    </C.Columns>
)

const NomeProduto = props => (
    <div>
        <C.Columns auto>
            <C.TextLabel
                title='Nome do produto:'
                name='nomeProduto'
                value={props.registroAnvisa?.nomeProduto ? props.registroAnvisa.nomeProduto : '-'}
            />
        </C.Columns>
        <C.Columns auto>
            <C.TextLabel
                title='Nome técnico do produto:'
                name='nomeTecnicoProduto'
                value={props.registroAnvisa?.nomeTecnico ? props.registroAnvisa.nomeTecnico : '-'}
            />
        </C.Columns>
    </div>
)

const DetalhesComRegistroAnvisa = props => {
    const regAnvisa = isImmutable(props.registroAnvisa) ? props.registroAnvisa.toJS() : props.registroAnvisa
    return (
        <div>
            <ListaFabricantes fabricantes={props.fabricantes} registroAnvisa={props.registroAnvisa} />
            <C.Columns auto>
                <C.TextLabel
                    title='CNPJ do Detentor do registro:'
                    name='cnpj'
                    value={regAnvisa?.cnpj ? regAnvisa?.cnpj : '-'}
                />
            </C.Columns>
            <C.Columns>
                <C.Column size='10'>
                    <C.TextLabel
                        title='Razão social do Detentor do registro:'
                        name='nomeFabricanteImportador'
                        value={regAnvisa?.nomeFabricanteImportador ? regAnvisa?.nomeFabricanteImportador : '-'}
                    />
                </C.Column>
            </C.Columns>
            <NomeProduto registroAnvisa={regAnvisa} />
        </div>
    )
}

export class ProdutoDetalhes extends React.Component {
    render() {
        return this.props.semRegistroAnvisa ? (
            <DetalhesSemRegistroAnvisa />
        ) : (
            <DetalhesComRegistroAnvisa
                fabricantes={this.props.fabricantes}
                registroAnvisa={this.props.registroAnvisa}
            />
        )
    }
}
