import { connect } from 'react-redux'
import { InfoProdutoFiltro, InfoProdutoDto, MotivosRevisoesRow } from 'backend'
import { withRouter } from 'react-router-dom'
import { State } from 'redux/modules'
import { InfoProdutoDetailView, Props } from './InfoProdutoDetailView'
import * as filtroReport from 'redux/modules/report/filtro'
import { Requester } from 'redux/requester'
import Report from 'api/Report'

const infoProdutoDetail = new Requester<InfoProdutoDto, InfoProdutoFiltro>('infoProdutoDetail', (filtro, config) => {
    return Report.getInfoProdutoDetail(filtro, config)
})

const infoProdutoMotivos = new Requester<MotivosRevisoesRow[], InfoProdutoFiltro>(
    'infoProdutoMotivos',
    (filtro, config) => {
        return Report.getMotivosInfoProduto(filtro, config)
    }
)

const mapStateToProps = (state: State, ownProps: Props) => ({
    filtro: state.report.filtro.filtro as InfoProdutoFiltro,
    info: infoProdutoDetail.getRequestState(state),
    motivosRevisoes: infoProdutoMotivos.getRequestState(state),
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    changeFilter: (filtro: InfoProdutoFiltro) => {
        dispatch(
            filtroReport.change({
                registroAnvisa: ownProps.match.params.registroAnvisa,
                ...filtro,
            } as InfoProdutoFiltro)
        )
    },
    load: (filtro: InfoProdutoFiltro) => {
        const f = {
            ...filtro,
            registroAnvisa: ownProps.match.params.registroAnvisa,
        }

        dispatch(infoProdutoDetail.setParams(f))
        dispatch(infoProdutoDetail.request())

        dispatch(infoProdutoMotivos.setParams(f))
        dispatch(infoProdutoMotivos.request())
    },
    resetFilter: (currentFilter: InfoProdutoFiltro) => {
        const { registroAnvisa, ...rest } = currentFilter
        dispatch(filtroReport.change({ ...rest }))
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoProdutoDetailView))
