import React from 'react'
import {
    CheckboxField,
    ChecklistItemField,
    CheckPanelField,
    Column,
    Columns,
    Conteudo,
    ControlField,
    Linha,
} from 'libs/components'

const PermissoesDadosProcedimentoFormSection: React.FC<any> = () => {
    return (
        <>
            <ControlField name='dadosProcedimento' label={'Dados dos procedimentos'} required />
            <CheckPanelField label='Angioplastia' name='stAngioStent' showErrors={true} testid='checkbox-angioplastia'>
                <Conteudo>
                    <CheckboxField
                        name='todosDadosAngioplastia'
                        label='Selecionar todos'
                        testid='checkbox-todos-angioplastia'
                    />
                    <Linha />
                    <Columns>
                        <Column size='5' verticalFlow>
                            <ChecklistItemField
                                name='dadosAngioStent'
                                label='Dados do procedimento'
                                optionValue='DADOS_DO_PROCEDIMENTO'
                                testid='checkbox-dados-procedimento-angioplastia'
                            />
                            <ChecklistItemField
                                name='dadosAngioStent'
                                label='Lesão tratada'
                                optionValue='LESAO_TRATADA'
                                testid='checkbox-lesao-tratada-angioplastia'
                            />
                            <ChecklistItemField
                                name='dadosAngioStent'
                                label='Stents utilizados'
                                optionValue='STENTS_UTILIZADOS'
                                testid='checkbox-stents-utilizados-angioplastia'
                            />
                            <ChecklistItemField
                                name='dadosAngioStent'
                                label='Apresentação clínica'
                                optionValue='APRESENTACAO_CLINICA'
                                testid='checkbox-apresentacao-clinica-angioplastia'
                            />
                            <ChecklistItemField
                                name='dadosAngioStent'
                                label='Teste isquemia/imagem'
                                optionValue='TESTE_ISQUEMIA_IMAGEM'
                                testid='checkbox-isquemia-imagem-angioplastia'
                            />
                            <ChecklistItemField
                                name='dadosAngioStent'
                                label='Conclusão'
                                optionValue='CONCLUSAO'
                                testid='checkbox-conclusao-angioplastia'
                            />
                        </Column>
                        <Column size='5' verticalFlow>
                            <ChecklistItemField
                                name='dadosAngioStent'
                                label='Antecedentes'
                                optionValue='ANTECEDENTES'
                                testid='checkbox-antecedentes-angioplastia'
                            />
                            <ChecklistItemField
                                name='dadosAngioStent'
                                label='ICP - Complementar'
                                optionValue='ICP_COMPLEMENTAR'
                                testid='checkbox-icpcomplementar-angioplastia'
                            />
                            <ChecklistItemField
                                name='dadosAngioStent'
                                label='Acesso arterial/contraste'
                                optionValue='ACESSO_ARTERIAL_CONTRASTE'
                                testid='checkbox-arterial-contraste-angioplastia'
                            />
                            <ChecklistItemField
                                name='dadosAngioStent'
                                label='Medicação do procedimento'
                                optionValue='MEDICACAO_PROCEDIMENTO'
                                testid='checkbox-medicacao-angioplastia'
                            />
                            <ChecklistItemField
                                name='dadosAngioStent'
                                label='Complicações clínicas'
                                optionValue='COMPLICACOES_CLINICAS'
                                testid='checkbox-complicacoes-angioplastia'
                            />
                        </Column>
                    </Columns>
                </Conteudo>
            </CheckPanelField>
            <CheckPanelField
                label='Artroplastia de joelho'
                name='stArtroJoelho'
                showErrors={true}
                testid='checkbox-artroplastia-joelho'
            >
                <Conteudo>
                    <CheckboxField
                        name='todosDadosArtroJoelho'
                        label='Selecionar todos'
                        testid='checkbox-todos-joelho'
                    />
                    <Linha />

                    <span className='bolder'>
                        <CheckboxField
                            name='todosDadosPrimariaJoelho'
                            label='Artroplastia primária de joelho'
                            testid='checkbox-todos-primJoelho'
                        />
                    </span>

                    <div className='pl-20 is-vertical-flow'>
                        <Columns>
                            <Column size='5' verticalFlow>
                                <ChecklistItemField
                                    name='dadosPrimariaJoelho'
                                    label='Diagnósticos'
                                    optionValue='DIAGNOSTICOS'
                                    testid='checkbox-diagnosticos-primJoelho'
                                />
                                <ChecklistItemField
                                    name='dadosPrimariaJoelho'
                                    label='Procedimento cirúrgico prévio'
                                    optionValue='PROCEDIMENTO_CIRURGICO_PREVIO'
                                    testid='checkbox-procedPrevio-primJoelho'
                                />
                                <ChecklistItemField
                                    name='dadosPrimariaJoelho'
                                    label='Procedimento cirúrgico atual'
                                    optionValue='PROCEDIMENTO_CIRURGICO_ATUAL'
                                    testid='checkbox-procedAtual-primJoelho'
                                />
                                <ChecklistItemField
                                    name='dadosPrimariaJoelho'
                                    label='Tipo de artroplastia'
                                    optionValue='TIPO_ARTROPLASTIA'
                                    testid='checkbox-tipoArtro-primJoelho'
                                />
                            </Column>
                            <Column size='5' verticalFlow>
                                <ChecklistItemField
                                    name='dadosPrimariaJoelho'
                                    label='Cimento ósseo'
                                    optionValue='CIMENTO_OSSEO'
                                    testid='checkbox-cimento-primJoelho'
                                />
                                <ChecklistItemField
                                    name='dadosPrimariaJoelho'
                                    label='Enxertia'
                                    optionValue='ENXERTIA'
                                    testid='checkbox-enxertia-primJoelho'
                                />
                                <ChecklistItemField
                                    name='dadosPrimariaJoelho'
                                    label='Componentes utilizados'
                                    optionValue='COMPONENTES_UTILIZADOS'
                                    testid='checkbox-componentes-primJoelho'
                                />
                            </Column>
                        </Columns>
                    </div>
                    <Linha />

                    <span className='bolder'>
                        <CheckboxField
                            name='todosDadosRevisaoJoelho'
                            label='Artroplastia de revisão de joelho'
                            testid='checkbox-todos-revJoelho'
                        />
                    </span>

                    <div className='pl-20 is-vertical-flow'>
                        <Columns>
                            <Column size='5' verticalFlow>
                                <ChecklistItemField
                                    name='dadosRevisaoJoelho'
                                    label='Motivos da revisão'
                                    optionValue='MOTIVOS_REVISAO'
                                    testid='checkbox-motivos-revJoelho'
                                />
                                <ChecklistItemField
                                    name='dadosRevisaoJoelho'
                                    label='Procedimento cirúrgico atual'
                                    optionValue='PROCEDIMENTO_CIRURGICO_ATUAL'
                                    testid='checkbox-procedimentoAtual-revJoelho'
                                />
                                <ChecklistItemField
                                    name='dadosRevisaoJoelho'
                                    label='Tipo de revisao'
                                    optionValue='TIPO_REVISAO'
                                    testid='checkbox-tipoRevisao-revJoelho'
                                />
                            </Column>
                            <Column size='5' verticalFlow>
                                <ChecklistItemField
                                    name='dadosRevisaoJoelho'
                                    label='Cimento ósseo'
                                    optionValue='CIMENTO_OSSEO'
                                    testid='checkbox-cimento-revJoelho'
                                />
                                <ChecklistItemField
                                    name='dadosRevisaoJoelho'
                                    label='Enxertia'
                                    optionValue='ENXERTIA'
                                    testid='checkbox-enxertia-revJoelho'
                                />
                                <ChecklistItemField
                                    name='dadosRevisaoJoelho'
                                    label='Componentes utilizados'
                                    optionValue='COMPONENTES_UTILIZADOS'
                                    testid='checkbox-componentes-revJoelho'
                                />
                            </Column>
                        </Columns>
                    </div>
                </Conteudo>
            </CheckPanelField>
            <CheckPanelField
                label='Artroplastia de quadril'
                name='stArtroQuadril'
                showErrors={true}
                testid='checkbox-artroplastia-quadril'
            >
                <Conteudo>
                    <CheckboxField
                        name='todosDadosArtroQuadril'
                        label='Selecionar todos'
                        testid='checkbox-todos-quadril'
                    />
                    <Linha />

                    <span className='bolder'>
                        <CheckboxField
                            name='todosDadosPrimariaQuadril'
                            label='Artroplastia primária de quadril'
                            testid='checkbox-todos-primQuadril'
                        />
                    </span>

                    <div className='pl-20 is-vertical-flow'>
                        <Columns>
                            <Column size='5' verticalFlow>
                                <ChecklistItemField
                                    name='dadosPrimariaQuadril'
                                    label='Diagnósticos'
                                    optionValue='DIAGNOSTICOS'
                                    testid='checkbox-diagnosticos-primQuadril'
                                />
                                <ChecklistItemField
                                    name='dadosPrimariaQuadril'
                                    label='Procedimento cirúrgico prévio'
                                    optionValue='PROCEDIMENTO_CIRURGICO_PREVIO'
                                    testid='checkbox-procedPrevio-primQuadril'
                                />
                                <ChecklistItemField
                                    name='dadosPrimariaQuadril'
                                    label='Procedimento cirúrgico atual'
                                    optionValue='PROCEDIMENTO_CIRURGICO_ATUAL'
                                    testid='checkbox-procedAtual-primQuadril'
                                />
                                <ChecklistItemField
                                    name='dadosPrimariaQuadril'
                                    label='Tipo de artroplastia'
                                    optionValue='TIPO_ARTROPLASTIA'
                                    testid='checkbox-tipoArtro-primQuadril'
                                />
                            </Column>
                            <Column size='5' verticalFlow>
                                <ChecklistItemField
                                    name='dadosPrimariaQuadril'
                                    label='Implantes'
                                    optionValue='IMPLANTES'
                                    testid='checkbox-implantes-primQuadril'
                                />
                                <ChecklistItemField
                                    name='dadosPrimariaQuadril'
                                    label='Cimento ósseo'
                                    optionValue='CIMENTO_OSSEO'
                                    testid='checkbox-cimento-primQuadril'
                                />
                                <ChecklistItemField
                                    name='dadosPrimariaQuadril'
                                    label='Enxertia'
                                    optionValue='ENXERTIA'
                                    testid='checkbox-enxertia-primQuadril'
                                />
                                <ChecklistItemField
                                    name='dadosPrimariaQuadril'
                                    label='Componentes utilizados'
                                    optionValue='COMPONENTES_UTILIZADOS'
                                    testid='checkbox-componentes-primQuadril'
                                />
                            </Column>
                        </Columns>
                    </div>
                    <Linha />

                    <span className='bolder'>
                        <CheckboxField
                            name='todosDadosRevisaoQuadril'
                            label='Artroplastia de revisão de quadril'
                            testid='checkbox-todos-revQuadril'
                        />
                    </span>

                    <div className='pl-20 is-vertical-flow'>
                        <Columns>
                            <Column size='5' verticalFlow>
                                <ChecklistItemField
                                    name='dadosRevisaoQuadril'
                                    label='Motivos da revisão'
                                    optionValue='MOTIVOS_REVISAO'
                                    testid='checkbox-motivos-revQuadril'
                                />
                                <ChecklistItemField
                                    name='dadosRevisaoQuadril'
                                    label='Procedimento cirúrgico atual'
                                    optionValue='PROCEDIMENTO_CIRURGICO_ATUAL'
                                    testid='checkbox-procedAtual-revQuadril'
                                />
                                <ChecklistItemField
                                    name='dadosRevisaoQuadril'
                                    label='Tipo de revisão'
                                    optionValue='TIPO_REVISAO'
                                    testid='checkbox-tipoRevisao-revQuadril'
                                />
                                <ChecklistItemField
                                    name='dadosRevisaoQuadril'
                                    label='Técnica operatória'
                                    optionValue='TECNICA_OPERATORIA'
                                    testid='checkbox-tecnica-revQuadril'
                                />
                            </Column>
                            <Column size='5' verticalFlow>
                                <ChecklistItemField
                                    name='dadosRevisaoQuadril'
                                    label='Cimento ósseo'
                                    optionValue='CIMENTO_OSSEO'
                                    testid='checkbox-cimento-revQuadril'
                                />
                                <ChecklistItemField
                                    name='dadosRevisaoQuadril'
                                    label='Enxertia'
                                    optionValue='ENXERTIA'
                                    testid='checkbox-enxertia-revQuadril'
                                />
                                <ChecklistItemField
                                    name='dadosRevisaoQuadril'
                                    label='Componentes utilizados'
                                    optionValue='COMPONENTES_UTILIZADOS'
                                    testid='checkbox-componentes-revQuadril'
                                />
                            </Column>
                        </Columns>
                    </div>
                </Conteudo>
            </CheckPanelField>
        </>
    )
}

export default PermissoesDadosProcedimentoFormSection
