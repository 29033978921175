import React from 'react'
import * as C from 'libs/components'
import iconPaciente from 'images/home/cidadao/ic-paciente.svg'
import iconVinculos from 'images/profissional/vinculos.svg'
import form from 'libs/redux-form/form'
import { formValueSelector } from 'redux-form/immutable'
import { connect } from 'react-redux'
import CadastroProfissionalValidator from 'view/public/profissional/CadastroProfissionalValidator'
import ContatoProfissionalFormSection from 'view/public/profissional/ContatoProfissionalForm'
import SolicitacaoVinculoFormSection from 'view/public/profissional/components/SolicitacaoVinculoFormSection'

import env from 'env.json'
const formName = 'cadastroProfissional'

class CadastroProfissionalForm extends React.Component {
    render() {
        return (
            <C.Form {...this.props}>
                <C.Columns>
                    <C.Column verticalFlow size='2'>
                        <C.Image src={iconPaciente} alignedCenter size='96x96' />
                    </C.Column>
                    <C.Column>
                        <C.Columns>
                            <C.Column size='3'>
                                <C.MaskedField
                                    required
                                    label='CPF'
                                    name='cpf'
                                    placeholder='CPF'
                                    mask='999.999.999-99'
                                />
                            </C.Column>
                            <C.Column size='3'>
                                <C.NumberField maxLength='15' label='CNS' name='cns' placeholder='CNS' />
                            </C.Column>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='6'>
                                <C.TextField
                                    maxLength='70'
                                    required
                                    label='Nome completo'
                                    name='nome'
                                    placeholder='Nome e sobrenome do profissional'
                                />
                            </C.Column>
                            <C.Column size='3'>
                                <C.DatePickerField
                                    required
                                    name='dataNasc'
                                    placeholder='dd/mm/aaaa'
                                    label='Data de nascimento'
                                />
                            </C.Column>
                        </C.Columns>
                        <C.Columns>
                            <C.Column>
                                <C.SexoField required label='Sexo' name='sexo' />
                            </C.Column>
                        </C.Columns>
                    </C.Column>
                </C.Columns>
                <C.SectionTitle title='ENDEREÇO' />
                <C.EnderecoForm form={formName} name='enderecoForm' public />
                <C.SectionTitle title='INFORMAÇÕES DE CONTATO' />
                <ContatoProfissionalFormSection
                    name='contatoForm'
                    disableConfirmarEmail={!this.props.enableConfirmacaoEmail}
                />
                <C.SectionTitle title='INFORMAÇÕES DE ACESSO' />
                <C.Columns>
                    <C.Column verticalFlow size='2'>
                        <C.Image src={iconVinculos} alignedCenter size='96x96' />
                    </C.Column>
                    <C.Column>
                        <SolicitacaoVinculoFormSection form={formName} name='solicitacaoVinculo' />
                    </C.Column>
                </C.Columns>
                <hr />
                <C.ButtonBar>
                    <C.Button size='medium' onClick={this.props.cancelar}>
                        Cancelar
                    </C.Button>
                    <C.Button
                        pulledRight
                        type='primary'
                        size='medium'
                        loading={this.props.submitting}
                        onClick={() => {
                            if (env.modified['CAPTCHA_DISABLED'] === 'true') {
                                this.props.handleSubmit()
                            } else {
                                Promise.resolve(this.recaptcha.execute())
                            }
                        }}
                    >
                        Salvar
                    </C.Button>
                </C.ButtonBar>
                <C.ReCaptchaField
                    name='recaptchaToken'
                    inputRef={elem => (this.recaptcha = elem)}
                    size='invisible'
                    onChange={() => {
                        this.props.handleSubmit()
                        this.recaptcha.reset()
                    }}
                />
            </C.Form>
        )
    }
}

export default class CadastroProfissionalView extends React.Component {
    render() {
        return (
            <div className='cadastro-paciente'>
                <C.SectionTitle title='IDENTIFICAÇÃO' />
                <CadastroProfissionalForm
                    cancelar={this.props.onCancel}
                    onSubmit={this.props.onSave}
                    onSubmitSuccess={this.props.afterSave}
                />
            </div>
        )
    }
}

CadastroProfissionalForm = form({
    form: formName,
    validate: CadastroProfissionalValidator,
    successContent: (
        <span>
            Suas informações de cadastro foram enviadas com sucesso para o Registro Nacional de Implantes (RNI). Será
            enviado ao e-mail informado as orientações necessárias para que você possa dar continuidade no seu processo
            de cadastro.
        </span>
    ),
    successTitle: 'Cadastro enviado com sucesso!',
})(CadastroProfissionalForm)

const selector = formValueSelector(formName)

CadastroProfissionalForm = connect(state => {
    const enableConfirmacaoEmail = selector(state, 'contatoForm.email')
    return {
        enableConfirmacaoEmail,
    }
})(CadastroProfissionalForm)
