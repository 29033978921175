
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"4 min read","minutes":3.2,"time":192000,"words":640}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "médico-residente"
    }}>{`Médico Residente`}</h3>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique no botão “Registrar novo procedimento”`}</li>
      <li parentName="ol">{`Busque o profissional que participou da equipe médica do procedimento, por meio do “Nome” ou “Nº do registro” e clique no botão “Pesquisar”`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` O médico/médico residente que está criando o procedimento é automaticamente adicionado na equipe médica.`}</p>
    </blockquote>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Selecione o profissional e clique em “Adicionar profissional"`}</li>
      <li parentName="ol">{`Repita os passos 3 e 4 até adicionar todos os profissionais na equipe médica`}</li>
      <li parentName="ol">{`A partir dos profissionais adicionados, selecione o médico responsável pelo procedimento e clique em “Confirmar equipe médica”`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` O médico residente não pode ser o responsável pelo procedimento.`}</p>
    </blockquote>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Procure o paciente através do “CPF” ou “CNS” e clique no botão “Pesquisar”`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Se o sistema não encontrar o paciente, você deverá cadastrá-lo acionando a opção “Adicionar paciente”.`}</p>
    </blockquote>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Verifique se o paciente é o correto e clique no botão “Confirmar paciente”`}</li>
      <li parentName="ol">{`Clique sobre o quadril que foi realizado o procedimento e indique que o procedimento é “Revisão”`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/images/quadrilRevisao.png",
        "alt": "Local da artroplastia"
      }}></img></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Neste momento o RNI não suporta cadastrar dois locais no mesmo procedimento, você deve criar dois procedimentos separados para cada local.`}</p>
    </blockquote>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Clique em “Confirmar procedimento”`}</li>
    </ol>
    <p><strong parentName="p">{`MOTIVO DA REVISÃO`}</strong></p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Informe o(s) motivos da revisão`}</li>
      <li parentName="ol">{`Caso tenha como motivo “Infecção” informe se foi precoce ou crônica`}</li>
    </ol>
    <p><strong parentName="p">{`PROCEDIMENTO CIRÚRGICO ATUAL`}</strong></p>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol">{`Informe a data da cirurgia e a duração em horas`}</li>
      <li parentName="ol">{`Informe qual foi a abordagem cirúrgica`}</li>
      <li parentName="ol">{`Informe se houve uso de dreno`}</li>
      <li parentName="ol">{`Informe se houve osteotomia femoral`}</li>
      <li parentName="ol">{`Informe se houve exame anatomopatológico`}</li>
      <li parentName="ol">{`Informe se houve transfusão sanguínea`}</li>
      <li parentName="ol">{`Caso tenha sido feita transfusão sanguínea informe se foi autóloga ou homóloga`}</li>
      <li parentName="ol">{`Informe se houve cultura durante o ato cirúrgico`}</li>
      <li parentName="ol">{`Caso tenha sido feita cultura informe quantas`}</li>
      <li parentName="ol">{`Informe se foi utilizado antibiótico`}</li>
      <li parentName="ol">{`Caso tenha sido utilizado antibiótico informe qual o tipo de antibiótico e o período`}</li>
    </ol>
    <p><strong parentName="p">{`TIPO DE REVISÃO`}</strong></p>
    <ol {...{
      "start": 24
    }}>
      <li parentName="ol">{`Informe o tipo de revisão ou se houve retirada`}</li>
    </ol>
    <p><strong parentName="p">{`TÉCNICA OPERATÓRIA`}</strong></p>
    <ol {...{
      "start": 25
    }}>
      <li parentName="ol">
        <p parentName="li">{`Informe a técnica utilizada`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Caso a técnica utilizada tenha sido "Femoral":`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Informe se foi utilizado tela de suporte`}</li>
          <li parentName="ul">{`Informe se foi utilizado centralizador`}</li>
          <li parentName="ul">{`Informe se foi cimentado`}</li>
          <li parentName="ul">{`Caso foi cimentado informe se foi utilizado restritor de cimento`}</li>
          <li parentName="ul">{`Informe qual o tipo de prótese`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Caso a técnica utilizada tenha sido "Acetabular":`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Informe se foi utilizado tela de suporte`}</li>
          <li parentName="ul">{`Informe se foi utilizado anel de reforço`}</li>
          <li parentName="ul">{`Informe se foi utilizado metal trabeculado`}</li>
          <li parentName="ul">{`Informe qual o par tribológico`}</li>
          <li parentName="ul">{`Informe se foi cimentado`}</li>
        </ul>
      </li>
    </ol>
    <p><strong parentName="p">{`CIMENTO ÓSSEO`}</strong></p>
    <ol {...{
      "start": 28
    }}>
      <li parentName="ol">{`Caso informado que foi utilizado cimento em algum dos implantes:`}<ul parentName="li">
          <li parentName="ul">{`Informe qual tipo de cimento`}</li>
          <li parentName="ul">{`Informe a técnica de cimentação utilizada`}</li>
          <li parentName="ul">{`Informe se houve lavagem pulsátil`}</li>
        </ul></li>
    </ol>
    <p><strong parentName="p">{`ENXERTIA`}</strong></p>
    <ol {...{
      "start": 29
    }}>
      <li parentName="ol">{`Caso tenha sido realizado implante femoral informe qual o enxerto utilizado`}</li>
      <li parentName="ol">{`Caso tenha sido realizado implante acetabular informe qual o enxerto utilizado`}</li>
    </ol>
    <p><strong parentName="p">{`COMPONENTES UTILIZADOS`}</strong></p>
    <ol {...{
      "start": 31
    }}>
      <li parentName="ol">{`Informe quais componentes foram implantados no paciente`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Caso o componente não seja apresentado, verifique se o tipo de artroplastia foi selecionado corretamente`}</p>
    </blockquote>
    <ol {...{
      "start": 32
    }}>
      <li parentName="ol">{`Informe também a quantidade de cada componente utilizado`}</li>
    </ol>
    <p>{`Para remover um componente da lista clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeRecusar.png#center",
        "alt": "Remover"
      }}></img>{` ao lado do componente desejado`}</p>
    <ol {...{
      "start": 33
    }}>
      <li parentName="ol">{`Informe quais componentes complementares foram utilizados `}</li>
      <li parentName="ol">{`Informe também a quantidade de cada componente complementar utilizado`}</li>
    </ol>
    <p>{`Para remover um componente complementar da lista clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeRecusar.png#center",
        "alt": "Remover"
      }}></img>{` ao lado do componente desejado`}</p>
    <ol {...{
      "start": 35
    }}>
      <li parentName="ol">{`Clique no botão “Salvar registro” para registrar o procedimento.`}</li>
    </ol>
    <p>{`Após o registro dos dados clínicos este procedimento fica disponível para preenchimento dos dados de produto, ou seja, os dados dos componentes implantados no paciente.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona cada etapa do preenchimento do registro, `}<a parentName="p" {...{
          "href": "../../fluxos/fluxoPreenchimentoRegistros"
        }}><strong parentName="a">{`clique aqui`}</strong></a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como criar um novo registro e realizar o preenchimento de dados clínicos de artroplastia, consulte o vídeo explicativo abaixo:`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "médico-residente",
    level: 3,
    title: "Médico Residente",
    children: [
        
      ]
  }
]

export const frontMatter = {}

