import * as React from 'react'
import { IdentificacaoAnvisaFragment } from '../componentes/IdentificacaoAnvisaFragment'
import { useState } from 'react'
import { FormSection, getFormValues } from 'redux-form/immutable'
import { AlertModalConfirmacao, Form, Panel, Linha, ButtonBar, Button } from 'libs/components'
import { ComponenteCopySection } from '../componentes'
import { useSelector, useDispatch } from 'react-redux'
import { IdentificacaoComponenteFragment } from '../componentes/IdentificacaoComponenteFragment'
import { error } from 'redux/modules/alert'
import { ProdutoRastreabilidadeIdCmpFormDto, useDadosProdutoForm } from '../common/useDadosProdutoForm'
import { State } from 'redux/modules'
import { AvisoPreenchimentoFicha } from 'view/home/novoProcedimento/ficha/componentes/visualizacao/AvisoPreenchimentoFicha'

export interface ProdutoRastreabilidadeFormProps {
    formSectionName: string
    showModalCopy: boolean
    showModalCopyBack: boolean
    componente: any
    index: number
    canSave: boolean
    canCopy: boolean
    submitting: boolean
    cancelar(e: any): void
    handleSubmit(): void
    change(formSectionName: string, cmp: {}): void
    editMode: boolean
    origemExterna: boolean
}
export const ProdutoRastreabilidadeForm = (props: ProdutoRastreabilidadeFormProps) => {
    const { formSectionName, change, showModalCopy, showModalCopyBack, componente } = props

    const [cmpCopy, setCmpCopy] = useState<{ cmp: {}; openModal: boolean }>({ cmp: null, openModal: false })

    const dispatch = useDispatch()
    const formValues: any = useSelector(state => getFormValues('dadosProdutoForm')(state))
    const fabricantes = useSelector((state: any) => state.rastreabilidade && state.rastreabilidade.loadFabricantes.data)
    const dtCirurgia = useSelector((state: State) => state.rastreabilidade?.findProcedimento?.data?.dtCirurgia)

    const alertError = (mensagem: string) => dispatch(error({ mensagem }))

    const copyComponente = (cmp, id?) => {
        // Remove fields não reutilizáveis
        cmp = cmp.delete('idRegistroProcedimentoComp')
        cmp = cmp.delete('nomeComponente')
        cmp = cmp.delete('etiqueta')

        // Altera o formulário com o novo cmp
        change(formSectionName, cmp)
        id && change(`${formSectionName}.id`, id)
    }
    const confirmCopyComponente = cmp => {
        if ((!showModalCopy || showModalCopyBack) && !formValues.toJS()[formSectionName]) {
            copyComponente(cmp)
        } else {
            setCmpCopy({ cmp, openModal: true })
        }
    }
    const okModalCopyComponente = () => {
        const id = formValues.toJS()[formSectionName].id
        copyComponente(cmpCopy.cmp, id)
        setCmpCopy(prev => ({ ...prev, openModal: false }))
    }
    const closeModalCopyComponente = () =>
        setCmpCopy({
            cmp: null,
            openModal: false,
        })
    const handleChangeLinhaDigitavel = (dadosEtiqueta: Partial<ProdutoRastreabilidadeIdCmpFormDto>) => {
        if (!dadosEtiqueta) {
            return
        }
        const {
            identificadorDispositivo,
            codigoReferencia,
            lote,
            numeroSerie,
            validade,
            dataFabricacao,
            anoValidade,
            mesValidade,
            diaValidade,
        } = dadosEtiqueta
        identificadorDispositivo && change(`${formSectionName}.identificadorDispositivo`, identificadorDispositivo)
        codigoReferencia && change(`${formSectionName}.codigoReferencia`, codigoReferencia)
        lote && change(`${formSectionName}.lote`, lote)
        numeroSerie && change(`${formSectionName}.numeroSerie`, numeroSerie)
        validade && change(`${formSectionName}.validade`, validade)
        if (dataFabricacao) {
            dataFabricacao.ano && change(`${formSectionName}.dataFabricacao.ano`, dataFabricacao.ano)
            dataFabricacao.mes && change(`${formSectionName}.dataFabricacao.mes`, dataFabricacao.mes)
            dataFabricacao.dia && change(`${formSectionName}.dataFabricacao.dia`, dataFabricacao.dia)
        }
        anoValidade && change(`${formSectionName}.anoValidade`, anoValidade)
        mesValidade && change(`${formSectionName}.mesValidade`, mesValidade)
        diaValidade && change(`${formSectionName}.diaValidade`, diaValidade)
    }

    const formFragment = useDadosProdutoForm(props.formSectionName)

    return (
        <Form {...props}>
            <FormSection name={props.formSectionName}>
                <AlertModalConfirmacao
                    active={cmpCopy.openModal}
                    title='Deseja copiar os dados do componente?'
                    onOk={okModalCopyComponente}
                    onClose={closeModalCopyComponente}
                >
                    Você preencheu alguns dados deste componente. Caso confirme a cópia, esses dados serão perdidos.
                </AlertModalConfirmacao>
                <Panel className='nome-componente'>{componente && componente.nome}</Panel>
                {!props.origemExterna && !props.editMode && (
                    <AvisoPreenchimentoFicha preenchendo='dados de produto' preenchido='dados clínicos' />
                )}
                {props.canCopy && (
                    <div>
                        <Linha />
                        <ComponenteCopySection
                            indexComponenteAtual={props.index}
                            cacheComponentes={formValues.toArray()}
                            fillInComponente={confirmCopyComponente}
                        />
                        <Linha />
                    </div>
                )}
                <IdentificacaoAnvisaFragment formFragment={formFragment} fabricantes={fabricantes} />
                <IdentificacaoComponenteFragment
                    formFragment={formFragment}
                    alertError={alertError}
                    handleChangeLinhaDigitavel={handleChangeLinhaDigitavel}
                    dtCirurgia={dtCirurgia}
                />
                <Linha />
                <ButtonBar>
                    <Button size='medium' disabled={props.submitting} onClick={props.cancelar}>
                        Cancelar
                    </Button>
                    <Button
                        size='medium'
                        pulledRight
                        type={props.canSave ? 'success' : 'primary'}
                        loading={props.submitting}
                        onClick={props.handleSubmit}
                    >
                        {props.canSave ? 'Salvar' : 'Próximo componente'}
                    </Button>
                </ButtonBar>
            </FormSection>
        </Form>
    )
}
