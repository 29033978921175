import { ReactComponent as CampoComponenteCompradoSim } from 'images/ajuda/financeiro/componentecomprado.svg'
import { ReactComponent as CampoComponenteCompradoNao } from 'images/ajuda/financeiro/componentenaocomprado.svg'
import componenteComprado from 'images/ajuda/financeiro/ic-componentecomprado.svg'
import componenteNaoComprado from 'images/ajuda/financeiro/ic-componentenaocomprado.svg'
import { BodyItem, CarouselItem, ModalCardAjuda, ModalCardAjudaProps } from 'libs/components'
import React from 'react'

interface AjudaFinanceiroModalProps extends Pick<ModalCardAjudaProps, 'active' | 'onClose'> {}

export const AjudaFinanceiro = (props: AjudaFinanceiroModalProps) => {
    const headerItems = [
        <CarouselItem key='car-item-comprado' image={componenteComprado} text='Componente comprado pelo hospital' />,
        <CarouselItem
            key='car-item-ncomprado'
            image={componenteNaoComprado}
            text='Componente não comprado pelo hospital'
        />,
    ]

    const bodyItems = [
        <BodyItem key='body-item-finan-1'>
            <div className='is-vertical-flow vertical-gutter'>
                <p>Se o componente for comprado pelo hospital, informe “sim” nos dados financeiros.</p>
                <CampoComponenteCompradoSim />
            </div>
            <p>Informe o ente responsável pelo ressarcimento ao hospital.</p>
            <p>Preencha o valor pago pelo hospital ao fornecedor.</p>
            <p>Preencha o valor cobrado no ressarcimento.</p>
        </BodyItem>,
        <BodyItem key='body-item-finan-2'>
            <div className='is-vertical-flow vertical-gutter'>
                <p>Se o componente não for comprado pelo hospital, informe “não” nos dados financeiros.</p>
                <CampoComponenteCompradoNao />
            </div>
            <p>Informe quem forneceu o produto.</p>
        </BodyItem>,
    ]
    return <ModalCardAjuda {...props} hasCloseButton bodyItems={bodyItems} headerItems={headerItems} />
}
