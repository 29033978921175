import React from 'react'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Store from 'redux/Store'
import RootContainer from 'RootContainer'
import { history } from 'config/history'
// import { Button } from 'libs/components'

export const App = () => {
    return (
        <Provider store={Store}>
            <ConnectedRouter history={history}>
                <Route path='/' component={RootContainer} />
            </ConnectedRouter>
        </Provider>
    )
}
