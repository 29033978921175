import * as React from 'react'
import classnames from 'classnames'
import { WrappedFieldInputProps } from 'redux-form'
import { Input } from './Input'
import { ErrorIndicator } from '../form/ErrorIndicator'

export interface CheckboxProps extends Partial<WrappedFieldInputProps> {
    checked?: boolean
    disabled?: boolean
    label?: string
    error?: string
    testid?: string
}

export class Checkbox extends React.Component<CheckboxProps, any> {
    render() {
        const classes = classnames('checkbox', {
            disabled: this.props.disabled,
        })
        const { label, children, ...rest } = this.props
        return (
            <label className={classes}>
                <Input {...rest} type='checkbox' />
                <span className='check-indicator' />
                <span className='check-label'>{label || children}</span>
                {this.props.error && <ErrorIndicator error={this.props.error} />}
            </label>
        )
    }
}
