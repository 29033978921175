import * as React from 'react'
import classnames from 'classnames'
import { Checkbox } from 'libs/components'

interface CheckPanelProps {
    input?: {
        value?: any
    }
    label: string
    disabled?: boolean
    meta?: {
        error?: string
    }
    showErrors?: boolean
    testid?: string
}

export class CheckPanel extends React.Component<CheckPanelProps> {
    render() {
        let classes = classnames('checkpanel', {
            active: this.props.input.value,
        })

        const error = this.props.showErrors && this.props.meta?.error

        return (
            <div className={classes}>
                <div className='checkpanel-header'>
                    <Checkbox
                        {...this.props.input}
                        label={this.props.label}
                        disabled={this.props.disabled}
                        testid={this.props.testid}
                        error={error}
                    />
                </div>
                <div className='checkpanel-content'>{this.props.children}</div>
            </div>
        )
    }
}
