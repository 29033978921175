import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useAxiosGet } from 'hooks/useApiRequest'
import { EntidadeConsumidoraVisualizacaoForm } from 'backend'
import Entidades from 'api/Entidades'
import {
    Column,
    Columns,
    Conteudo,
    Linha,
    LoadingStateContainer,
    MaskedLabel,
    SectionTitle,
    TextLabel,
    Text,
    ButtonBar,
    Button,
    AlertModalSuccess,
} from 'libs/components'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import ListSection from './components/ListSection'
import { asClientFormat } from 'libs/util/DateUtil'

interface VisualizarEntidadeViewRouteParams {
    id: string
}

const EsferasAcessoLabel = {
    ESTADUAL: 'Unidade Federativa',
    MUNICIPAL: 'Municipal',
    HOSPITAL: 'Hospital',
}

function getAddressInArray(address: string | null) {
    if (address) {
        return [address]
    } else {
        return []
    }
}

const VisualizarEntidadeView: React.FC = () => {
    const { goBack } = useHistory()

    const routeParams = useParams<VisualizarEntidadeViewRouteParams>()
    const { data, requestState } = useAxiosGet<EntidadeConsumidoraVisualizacaoForm>(
        Entidades.getEntidade(Number(routeParams.id), 'visualizacao')
    )

    const [showModalSuccessSendingEmail, setShowModalSuccessSendingEmail] = useState<boolean>(false)

    let hasDadosHospitais: boolean = false
    let hasDadosPacientesMedicos: boolean = false
    let hasDadosProcedimentos: boolean = false
    let hasDadosProdutoFinanceiros: boolean = false

    async function enviarEmailEntidade() {
        await Entidades.generateEmail(data.id)
        setShowModalSuccessSendingEmail(true)
    }

    if (data) {
        hasDadosHospitais = !!data.dadosHospital || !!data.enderecoHospital
        hasDadosPacientesMedicos =
            !!data.dadosPaciente || !!data.enderecoPaciente || !!data.dadosMedicos || !!data.enderecoMedicos
        hasDadosProcedimentos =
            !!data.dadosAngioStent ||
            !!data.dadosPrimariaJoelho ||
            !!data.dadosRevisaoJoelho ||
            !!data.dadosPrimariaQuadril ||
            !!data.dadosRevisaoQuadril
        hasDadosProdutoFinanceiros =
            !!data.dadosProdutoIdentAnvisa || !!data.dadosProdutoIdentComponente || !!data.dadosFinanceiros
    }

    return (
        <div className='visualizarEntidade--page'>
            <ReportHeader title='Visualizar entidade' />

            <LoadingStateContainer readyState={requestState}>
                {data && (
                    <>
                        <div className='content-wrapper'>
                            <div className='identificacao'>
                                <SectionTitle title='INFORMAÇÕES DA ENTIDADE' />
                                <Conteudo>
                                    <TextLabel
                                        title='Nome da entidade'
                                        value={data.nomeEntidade}
                                        style={{ marginBottom: 20 }}
                                        testid='textLabel-nomeEntidade'
                                    />
                                    <Columns>
                                        <Column>
                                            <MaskedLabel
                                                title='CNPJ da entidade'
                                                placeholder='Sem registros'
                                                value={data.cnpj}
                                                mask='##.###.###/####-##'
                                                className='entity-info-item'
                                                testid='maskedLabel-cnpj'
                                            />

                                            <TextLabel
                                                title='Data de autorização de acesso'
                                                value={asClientFormat(data.dtCadastro)}
                                                className='entity-info-item'
                                                testid='textLabel-dataAutorizacao'
                                            />

                                            <TextLabel
                                                title='Data da última edição'
                                                placeholder='Sem registros'
                                                value={data.dtAtualizacao ? asClientFormat(data.dtAtualizacao) : null}
                                                testid='textLabel-dataEdicao'
                                            />
                                        </Column>
                                        <Column>
                                            <TextLabel
                                                title='E-mail da entidade'
                                                placeholder='Sem registros'
                                                value={data.emailEntidade}
                                                className='entity-info-item'
                                                testid='textLabel-emailEntidade'
                                            />
                                            <TextLabel
                                                title='Profissional que autorizou acesso'
                                                placeholder='Sem registros'
                                                value={data.profCadastro}
                                                className='entity-info-item'
                                                testid='textLabel-profissionalAutorizacao'
                                            />
                                            <TextLabel
                                                title='Profissional que realizou a última edição'
                                                placeholder='Sem registros'
                                                value={data.profAtualizacao}
                                                testid='textLabel-profissionalEdicao'
                                            />
                                        </Column>
                                    </Columns>
                                </Conteudo>
                            </div>
                            <div className='permissoes-compartilhamento'>
                                <SectionTitle title='PERMISSÕES DE COMPARTILHAMENTO ' />
                                <Conteudo>
                                    <div>
                                        <Text weight='bold' testid='text-esferas-acesso'>
                                            Esferas de acesso
                                        </Text>
                                        <Linha />
                                        <Conteudo>
                                            <div className='list-item'>
                                                <Text weight='bold' testid='text-esferaEntidade'>
                                                    {EsferasAcessoLabel[data.esferaAcesso]}
                                                </Text>
                                                <ul
                                                    className={
                                                        data.acessos.length > 10 && data.esferaAcesso === 'ESTADUAL'
                                                            ? 'break-list'
                                                            : null
                                                    }
                                                    data-testid='ul-esfera-acesso-entidade'
                                                >
                                                    {data.acessos.map(acesso => (
                                                        <li key={acesso}>{acesso}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Conteudo>
                                    </div>

                                    {hasDadosHospitais && (
                                        <div style={{ marginTop: 48 }}>
                                            <Text weight='bold' testid='text-hospitais'>
                                                Hospitais
                                            </Text>
                                            <Linha />
                                            <Conteudo>
                                                <Text weight='bold' testid='text-dados-hospitais'>
                                                    Dados dos hospitais
                                                </Text>

                                                <Columns>
                                                    <ListSection
                                                        list={{
                                                            Institucional: data.dadosHospital,
                                                        }}
                                                        wrapper={children => <Column>{children}</Column>}
                                                        testid='listSection-institucionalHospital'
                                                    />
                                                    <ListSection
                                                        list={{
                                                            Endereço: getAddressInArray(data.enderecoHospitalDesc),
                                                        }}
                                                        wrapper={children => <Column>{children}</Column>}
                                                        testid='listSection-enderecoHospital'
                                                    />
                                                </Columns>
                                            </Conteudo>
                                        </div>
                                    )}

                                    {hasDadosPacientesMedicos && (
                                        <div style={{ marginTop: 48 }}>
                                            <Text weight='bold' testid='text-dados-pacientes-medicos'>
                                                Dados dos pacientes e dados dos médicos
                                            </Text>
                                            <Linha />
                                            <Conteudo>
                                                <Columns>
                                                    <ListSection
                                                        label='Dados dos pacientes'
                                                        list={{
                                                            Pessoal: data.dadosPaciente,
                                                            'Endereço Residencial': getAddressInArray(
                                                                data.enderecoPacienteDesc
                                                            ),
                                                        }}
                                                        wrapper={children => <Column>{children}</Column>}
                                                        testid='listSection-dadosPaciente'
                                                    />

                                                    <ListSection
                                                        label='Dados dos médicos'
                                                        list={{
                                                            Pessoal: data.dadosMedicos,
                                                            'Endereço Residencial': getAddressInArray(
                                                                data.enderecoMedicosDesc
                                                            ),
                                                        }}
                                                        wrapper={children => <Column>{children}</Column>}
                                                        testid='listSection-dadosMedico'
                                                    />
                                                </Columns>
                                            </Conteudo>
                                        </div>
                                    )}

                                    {hasDadosProcedimentos && (
                                        <div style={{ marginTop: 48 }}>
                                            <Text weight='bold' testid='text-dadosProcedimentos'>
                                                Dados dos procedimentos
                                            </Text>
                                            <Linha />
                                            <Conteudo className='conteudo-grid'>
                                                <ListSection
                                                    label='Angioplastia'
                                                    list={data.dadosAngioStent}
                                                    testid='listSection-angioplastia'
                                                />
                                                <ListSection
                                                    label='Artroplastia primária de joelho'
                                                    list={data.dadosPrimariaJoelho}
                                                    testid='listSection-primariaJoelho'
                                                />
                                                <ListSection
                                                    label='Artroplastia de revisão de joelho'
                                                    list={data.dadosRevisaoJoelho}
                                                    testid='listSection-revisaoJoelho'
                                                />
                                                <ListSection
                                                    label='Artroplastia primária de quadril'
                                                    list={data.dadosPrimariaQuadril}
                                                    testid='listSection-primariaQuadril'
                                                />
                                                <ListSection
                                                    label='Artroplastia de revisão de quadril'
                                                    list={data.dadosRevisaoQuadril}
                                                    testid='listSection-revisaoQuadril'
                                                />
                                            </Conteudo>
                                        </div>
                                    )}

                                    {hasDadosProdutoFinanceiros && (
                                        <div style={{ marginTop: 48 }}>
                                            <Text weight='bold' testid='text-dados-produto-financeiros'>
                                                Dados de produto e financeiros
                                            </Text>
                                            <Linha />
                                            <Conteudo>
                                                <Columns>
                                                    <ListSection
                                                        label='Dados de produto'
                                                        list={{
                                                            'Identificação do componente':
                                                                data.dadosProdutoIdentComponente,
                                                            'Identificação Anvisa': data.dadosProdutoIdentAnvisa,
                                                        }}
                                                        wrapper={children => <Column>{children}</Column>}
                                                        testid='listSection-dadosProduto'
                                                    />

                                                    <ListSection
                                                        label='Dados financeiros'
                                                        list={data.dadosFinanceiros}
                                                        wrapper={children => <Column>{children}</Column>}
                                                        testid='listSection-dadosFinanceiros'
                                                    />
                                                </Columns>
                                            </Conteudo>
                                        </div>
                                    )}

                                    <div style={{ marginTop: 48 }}>
                                        <Text weight='bold' testid='text-periodoVigencia'>
                                            Período de vigência
                                        </Text>
                                        <Linha />
                                        <Text testid='text-periodoAcesso'>De {data.periodoAcesso}</Text>
                                    </div>
                                </Conteudo>
                            </div>
                        </div>
                        <ButtonBar style={{ marginTop: 24 }}>
                            <Button pulledLeft size='medium' className='simple-button' onClick={goBack}>
                                Voltar
                            </Button>
                            <Button pulledRight type='primary' size='medium' onClick={enviarEmailEntidade}>
                                Enviar para entidade
                            </Button>
                        </ButtonBar>
                    </>
                )}
            </LoadingStateContainer>

            <AlertModalSuccess
                title='Sucesso'
                active={showModalSuccessSendingEmail}
                onClose={() => setShowModalSuccessSendingEmail(false)}
            >
                As informações de cadastro foram enviadas por e-mail para a entidade!
            </AlertModalSuccess>
        </div>
    )
}

export default VisualizarEntidadeView
