import { connect } from 'react-redux'
import { SumarioPacienteView, SumarioPacienteViewProps } from './SumarioPacienteView'
import { State } from 'redux/modules'
import { withRouter } from 'react-router-dom'
import * as paciente from 'redux/modules/report/paciente'
import { tiposProcedimentoUrlByEnum } from 'api/Procedimento'
import { RouteComponentProps } from 'react-router-dom'

const mapStateToProps = (state: State, ownProps: RouteComponentProps) => ({
    sumarioPaciente: state.report.paciente.sumario,
})

const mapDispatchToProps = (dispatch, ownProps: SumarioPacienteViewProps) => ({
    loadSumarioPaciente: () => {
        dispatch(
            paciente.findSumarioPacienteFiltrado(ownProps.match.params.pacienteId, {
                registroAnvisa: ownProps.match.params.registroAnvisa,
                procedimentoMode: ownProps.match.params.procedimentoMode,
                tipoCirurgia: ownProps.match.params.tipoCirurgia,
            })
        )
    },
    visualizarFicha: (idProcedimento, proced) => {
        const procedimentoUrl = tiposProcedimentoUrlByEnum[proced]
        ownProps.history.push({
            pathname: `${ownProps.match.url}/visualizacao/${procedimentoUrl}/${idProcedimento}`,
            search: ownProps.location.search,
        })
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SumarioPacienteView))
