import { createValidator, required } from 'libs/validation'

export const TecnicaOperatoriaRevValidator = createValidator({}, formValidator)

function formValidator(form) {
    const errors = {}
    if (!form.get('femoral') && !form.get('acetabular')) {
        errors.femoral = required(undefined)
    }
    if (form.get('femoral')) {
        errors.femoralTelaSuporte = required(form.get('femoralTelaSuporte'))
        errors.femoralCentralizador = required(form.get('femoralCentralizador'))
        errors.femoralCimentado = required(form.get('femoralCimentado'))
        errors.tipoProtese = required(form.get('tipoProtese'))

        if (form.get('femoralCimentado') === 'SIM') {
            errors.restritorCimento = required(form.get('restritorCimento'))
        }
    }
    if (form.get('acetabular')) {
        errors.acetabularTelaSuporte = required(form.get('acetabularTelaSuporte'))
        errors.acetabularAnelReforco = required(form.get('acetabularAnelReforco'))
        errors.acetabularMetalTrabeculado = required(form.get('acetabularMetalTrabeculado'))
        errors.parTribologico = required(form.get('parTribologico'))

        errors.acetabularCimentado = required(form.get('acetabularCimentado'))
    }
    return errors
}
