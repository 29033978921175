import { connect } from 'react-redux'
import { State } from 'redux/modules'
import * as componentes from 'redux/modules/componentes'
import StentsView from './StentsView'
import { StentDto } from 'backend'

const mapStateToProps = (state: State, ownProps) => {
    return {
        openModal: state.componentes.openModal,
        stents: state.componentes.stents && state.componentes.stents.data,
        readyState: state.componentes.stents.readyState,
        voltar: () => ownProps.history.push('/home'),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        load: () => dispatch(componentes.loadStents()),
        edit: (item: StentDto) => dispatch(componentes.setStentEdition(item)),
        clear: () => dispatch(componentes.clearEditin()),
        saveAtivoStent: (id: number) => {
            dispatch(componentes.otimistChangeAtivoStent(id))
            dispatch(componentes.effectiveChangeAtivoStent(id))
        },
    }
}

const StentsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StentsView)

export default StentsContainer
