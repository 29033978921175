import copy from 'copy-to-clipboard'
import { Columns, IconSize } from 'libs/components'
import { FeedbackButton } from 'libs/components/presentational/elements/FeedbackButton'
import { Icons } from 'libs/components/presentational/elements/icons'
import React from 'react'
import { ReadyState } from 'redux/requester'
import { LabelsColumn } from './row/LabelsColumn'
import { IntegracaoRowIconColumn } from '../common/IntegracaoRowIconColumn'

export type AutenticacaoIntegracaoInnerRowType = 'Login' | 'Senha'

export enum AutenticacaoIntegracaoInnerRowViewStatus {
    BLOCKED,
    FIRST_TIME,
}

export interface AutenticacaoIntegracaoInnerRowConfig {
    icon: Icons
    iconSize: IconSize
    emptyText: string
    seuTitleText: string
    gerarText: string
    copiedText: string
    gerarWord: string
    failureWords: string
}

interface AutenticacaoIntegracaoInnerRowProps {
    title: AutenticacaoIntegracaoInnerRowType
    value: string
    date: string
    generateFunction: () => void
    specialStatus: AutenticacaoIntegracaoInnerRowViewStatus
    readyState: ReadyState
}

export const AutenticacaoIntegracaoInnerRow = (props: AutenticacaoIntegracaoInnerRowProps) => {
    const { title, value, date, generateFunction, specialStatus, readyState } = props

    const titleLowerCase = title.toLowerCase()
    let rowConfig: AutenticacaoIntegracaoInnerRowConfig = {} as AutenticacaoIntegracaoInnerRowConfig
    if (title === 'Login') {
        rowConfig.icon = 'link'
        rowConfig.iconSize = 'is-20px'
        rowConfig.emptyText = `Nenhum ${titleLowerCase} gerado`
        rowConfig.seuTitleText = `seu ${titleLowerCase}`
        rowConfig.gerarText = `Gerar novo ${titleLowerCase}`
        rowConfig.copiedText = 'Copiado!'
        rowConfig.gerarWord = 'gerado'
        rowConfig.failureWords = 'o login'
    } else {
        rowConfig.icon = 'key'
        rowConfig.iconSize = 'medium'
        rowConfig.emptyText = `Nenhuma ${titleLowerCase} gerada`
        rowConfig.seuTitleText = `sua ${titleLowerCase}`
        rowConfig.gerarText = `Gerar nova ${titleLowerCase}`
        rowConfig.copiedText = 'Copiada!'
        rowConfig.gerarWord = 'gerada'
        rowConfig.failureWords = 'a senha'
    }

    const isBlocked: boolean = specialStatus === AutenticacaoIntegracaoInnerRowViewStatus.BLOCKED
    const isFirstTime: boolean = specialStatus === AutenticacaoIntegracaoInnerRowViewStatus.FIRST_TIME
    const isFailure: boolean = readyState === 'failure'
    const isSuccess: boolean = readyState === 'success'
    const isRequest: boolean = readyState === 'request'

    const disableCopy: boolean = isFirstTime || isBlocked || !isSuccess
    const disableGenerate: boolean = isBlocked && title === 'Senha'

    return (
        <Columns className='autenticacao-integracao-inner-row'>
            <IntegracaoRowIconColumn
                status={isFailure || isBlocked ? 'fail' : 'success'}
                icon={rowConfig.icon}
                iconSize={rowConfig.iconSize}
            />{' '}
            <LabelsColumn
                title={title}
                value={value}
                date={date}
                rowConfig={rowConfig}
                isFailure={isFailure}
                isBlocked={isBlocked}
                isRequest={isRequest}
                isSuccess={isSuccess}
                isFirstTime={isFirstTime}
            />
            <FeedbackButton
                normalText={`Copiar ${titleLowerCase}`}
                normalClassName={
                    disableCopy
                        ? 'integracao-button-common-action copy disabled'
                        : 'integracao-button-common-action copy'
                }
                feedbackText={rowConfig.copiedText}
                feedbackClassName='button-common-action-pressed copy'
                icon='copyOutline'
                onClick={() => copy(value)}
                disabled={disableCopy}
                testid='feedback-button-copiar-login-senha'
            />
            <FeedbackButton
                normalText={rowConfig.gerarText}
                normalClassName={
                    disableGenerate
                        ? 'integracao-button-common-action generate disabled'
                        : 'integracao-button-common-action generate'
                }
                feedbackText={`${title} ${rowConfig.gerarWord}!`}
                feedbackClassName='button-common-action-pressed generate'
                icon='gerar'
                onClick={generateFunction}
                disabled={disableGenerate}
                loading={isRequest}
                failure={isFailure}
                testid='feedback-button-gerar-login-senha'
            />
        </Columns>
    )
}
