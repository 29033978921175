import * as React from 'react'
import { Map } from './Map'
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer'
import { NumberLabel } from 'libs/components'

interface Props {
    data: google.maps.visualization.WeightedLocation[]
}

export class HeatMap extends React.Component<Props> {
    render() {
        return (
            <Map>
                <HeatmapLayer
                    options={{
                        data: this.props.data,
                        radius: 36,
                        dissipating: true,
                        gradient: gradient,
                    }}
                />
            </Map>
        )
    }
}

export class HeatMapLegendProps {
    maxIntensity: number
}

export class HeatMapLegend extends React.Component<HeatMapLegendProps> {
    static defaultProps: HeatMapLegendProps = {
        maxIntensity: 1,
    }

    render() {
        const maxIntensity = this.props.maxIntensity

        return (
            <div className='heatmap-legend'>
                {maxIntensity && maxIntensity > 0 && (
                    <div>
                        +<NumberLabel value={maxIntensity} abbrev />
                    </div>
                )}
                <div className='heatmap-legend--gradient' style={{ background: gradientBackground }}></div>
                <div>0</div>
            </div>
        )
    }
}

const gradient = [
    'rgba(0, 153, 255, 0)',
    'rgba(0, 153, 255, 1)',
    'rgba(0, 105, 255, 1)',
    'rgba(0, 44, 255, 1)',
    'rgba(82, 0, 188, 1)',
    'rgba(127, 1, 140, 1)',
    'rgba(226, 0, 81, 1)',
]

const gradientBackground = `-webkit-linear-gradient(bottom, ${gradient.join(', ')})`
