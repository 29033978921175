import React from 'react'
import * as C from 'libs/components'

export class FuncaoVEConhecidaCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Função VE conhecida'
                name='funcaoVEConhecida'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='funcaoVEConhecida' />}
            >
                <C.Conteudo>
                    <C.Columns>
                        <C.Column size='5'>
                            <C.ControlField
                                label='FE (semiquantitativo)'
                                name='fcVEConhecidaSemiQuantitativo'
                                verticalFlow
                            >
                                <C.ClearableRadioField
                                    name='fcVEConhecidaSemiQuantitativo'
                                    label='Normal'
                                    value='NORMAL'
                                />
                                <C.ClearableRadioField
                                    name='fcVEConhecidaSemiQuantitativo'
                                    label='Discreta'
                                    value='DISCRETA'
                                />
                                <C.ClearableRadioField
                                    name='fcVEConhecidaSemiQuantitativo'
                                    label='Moderada'
                                    value='MODERADA'
                                />
                                <C.ClearableRadioField
                                    name='fcVEConhecidaSemiQuantitativo'
                                    label='Acentuada'
                                    value='ACENTUADA'
                                />
                            </C.ControlField>
                        </C.Column>
                        <C.Column size='3'>
                            <C.NumberField
                                placeholder='(0-100)'
                                maxLength='3'
                                label='FE (%)'
                                name='funcaoVEConhecidaPercentilFE'
                                unit={'%'}
                            />
                        </C.Column>
                    </C.Columns>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
