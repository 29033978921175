import { Button, Label, Linha, Hint } from 'libs/components'
import React, { ComponentElement } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { State } from 'redux/modules'
import { IntegracaoHorizontalTabMenu } from './componentes/common/IntegracaoHorizontalTabMenu'
import { ModalCadastrarSistema } from './componentes/sistemas/modal/ModalCadastrarSistema'
import { SistemasIntegracaoPageAtivos } from './componentes/sistemas/SistemasIntegracaoPageAtivos'
import { SistemasIntegracaoPageInativos } from './componentes/sistemas/SistemasIntegracaoPageInativos'
import { SistemaIntegracaoDto } from 'backend'
import { ButtonProps, ButtonComponent } from 'libs/components/presentational/elements/Button'

export const maxAtivosMessage: string = 'Apenas 3 sistemas podem estar ativos simultaneamente.'

export const SistemasIntegracaoView = () => {
    const [isModalCadastrarOpen, setModalCadastrarOpen] = React.useState<boolean>(false)
    const [editValue, setEditValue] = React.useState<SistemaIntegracaoDto>()
    const pathFirstTab = '/home/sistemas'
    const pathSecondTab = pathFirstTab + '/inativos'

    const handleClickEditar = (sistema: SistemaIntegracaoDto) => {
        setEditValue(sistema)
        setModalCadastrarOpen(true)
    }

    const handleClickCloseModal = () => {
        setEditValue(undefined)
        setModalCadastrarOpen(false)
    }

    const isLoadingAtivos = useSelector<State, any>(state => state.integracao.sistemasAtivos?.readyState)
    const hasMaxAtivosFromAtivosTab = useSelector<State, boolean>(
        state => state.integracao.sistemasAtivos?.data?.hasMaxAtivos
    )

    const isLoadingInativos = useSelector<State, any>(state => state.integracao.sistemasInativos?.readyState)
    const hasMaxAtivosFromInativosTab = useSelector<State, boolean>(
        state => state.integracao.sistemasInativos?.data?.hasMaxAtivos
    )
    const blockLoading: boolean = isLoadingAtivos === 'request' || isLoadingInativos === 'request'
    const blockNewAtivos: boolean = hasMaxAtivosFromAtivosTab || hasMaxAtivosFromInativosTab

    const buttonCadastrar: ComponentElement<ButtonProps, ButtonComponent> = (
        <Button
            className='btn-principal sistemas-btn-cadastrar'
            alignedCenter
            size='medium'
            type='primary'
            shadow={!blockNewAtivos && !blockLoading}
            disabled={blockNewAtivos || blockLoading}
            onClick={() => setModalCadastrarOpen(true)}
        >
            Cadastrar novo sistema
        </Button>
    )

    return (
        <div className='sistemas-integracao'>
            <ModalCadastrarSistema
                isOpen={isModalCadastrarOpen}
                pathAtivo={pathFirstTab}
                onClickClose={handleClickCloseModal}
                editValue={editValue}
            />
            {blockNewAtivos ? (
                <div className='sistemas-box-hint-btn-cadastrar'>
                    <Hint placement='top' componente={buttonCadastrar}>
                        {maxAtivosMessage}
                    </Hint>
                </div>
            ) : (
                buttonCadastrar
            )}
            <Label className='integracao-header-title'>Gerenciar sistemas</Label>
            <Linha className='integracao-header-line' />
            <IntegracaoHorizontalTabMenu
                pathFirstTab={pathFirstTab}
                titleFirstTab='SISTEMAS ATIVOS'
                pathSecondTab={pathSecondTab}
                titleSecondTab='SISTEMAS INATIVOS'
            />
            <Switch>
                <Route
                    exact
                    path={pathFirstTab}
                    render={() => {
                        return <SistemasIntegracaoPageAtivos handleClickEditar={handleClickEditar} />
                    }}
                />
                <Route
                    exact
                    path={pathSecondTab}
                    render={() => {
                        return <SistemasIntegracaoPageInativos />
                    }}
                />
            </Switch>
        </div>
    )
}
