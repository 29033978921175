import React from 'react'
import * as C from 'libs/components'
import { TipoCaraterIcpField } from './TipoCaraterIcpField'
import { isImmutable } from 'immutable'

const SemCaraterIcpMessage = props => {
    return (
        props.naoShowCaraterIcp && (
            <C.Conteudo textCentered>
                <C.TextLabel name='iam' placeholder='Informe quadro clínico primeiro.' />
            </C.Conteudo>
        )
    )
}

const ShowEletivoAdHoc = props => {
    return (
        props.eletivoAdhoc && (
            <C.Conteudo>
                <C.ControlField label='' name='eletivaAdhoc' horizontalFLow>
                    <C.RadioField name='eletivaAdhoc' label='Eletiva' value='ELETIVA' />
                    <C.RadioField name='eletivaAdhoc' label='AD-HOC' value='AD_HOC' />
                    <C.ClearRadioFieldButton name='eletivaAdhoc' />
                </C.ControlField>
            </C.Conteudo>
        )
    )
}

const ShowUrgenciaInvasiva = props => {
    return (
        props.urgenciaInvasiva && (
            <C.Conteudo>
                <C.ControlField label='' name='urgenciaInvasiva' verticalFlow>
                    <C.ClearableRadioField
                        name='urgenciaInvasiva'
                        label='Urgência (nas primeiras 2 horas de admissão)'
                        value='URGENCIA'
                    />
                    <C.ClearableRadioField
                        name='urgenciaInvasiva'
                        label='Invasiva precoce (entre 2 e 24 horas de admissão)'
                        value='INVASIVA_PRECOCE'
                    />
                    <C.ClearableRadioField
                        name='urgenciaInvasiva'
                        label='Invasiva retardada/eletiva (após 24 horas da admissão, na mesma internação)'
                        value='INVASIVA_RETARDADA_ELETIVA'
                    />
                </C.ControlField>
            </C.Conteudo>
        )
    )
}
const ShowDataContatoHospitalOrigem = props => {
    return (
        props.houveTransferenciaIcp && (
            <C.Columns>
                <C.Column size='4'>
                    <C.Label className='icp-composed-label wrapped'>Contato do hospital de origem</C.Label>
                </C.Column>
                <C.Column size='3'>
                    <C.DatePickerField name='dataContatoHospital' label='Data' placeholder='dd/mm/aaaa' />
                </C.Column>
                <C.Column size='2'>
                    <C.HourField
                        name='horaContatoHospital'
                        label='Hora'
                        placeholder='00:00'
                        disabled={!props.diaSaidaTransferencia}
                    />
                </C.Column>
            </C.Columns>
        )
    )
}
const ShowDataChegadaIcp = props => {
    return (
        props.houveTransferenciaIcp && (
            <C.Columns>
                <C.Column size='4'>
                    <C.Label className='icp-composed-label'>Chegada no destino ICP</C.Label>
                </C.Column>
                <C.Column size='3'>
                    <C.DatePickerField name='dataChegadaIcp' label='Data' placeholder='dd/mm/aaaa' />
                </C.Column>
                <C.Column size='2'>
                    <C.HourField
                        name='horaChegadaIcp'
                        label='Hora'
                        placeholder='00:00'
                        disabled={!props.diaChegadaTransferencia}
                    />
                </C.Column>
            </C.Columns>
        )
    )
}
const CalculaTransferencia = props => {
    return (
        props.houveTransferenciaIcp && (
            <C.Columns>
                <C.Column size='4'></C.Column>
                <C.Column size='4'>
                    <C.TextLabel
                        title='Tempo de transferência'
                        value={props.tempoTransferencia}
                        placeholder='Informe data/horário'
                    />
                </C.Column>
            </C.Columns>
        )
    )
}

const ShowTipoIcp = props => {
    return (
        props.show && (
            <C.StyledControlPanel
                component={<TipoCaraterIcpField name='tipoCaraterICP' label='Tipo' />}
                hasContent={props.showTipoExtended}
            >
                <C.SimNaoRadioField name='transferenciaIcpPrimaria' label='Transferência para ICP primária' />
                <ShowDataContatoHospitalOrigem
                    houveTransferenciaIcp={props.houveTransferenciaIcp}
                    diaSaidaTransferencia={props.diaSaidaTransferencia}
                />
                <ShowDataChegadaIcp
                    houveTransferenciaIcp={props.houveTransferenciaIcp}
                    diaChegadaTransferencia={props.diaChegadaTransferencia}
                />
                <CalculaTransferencia
                    houveTransferenciaIcp={props.houveTransferenciaIcp}
                    tempoTransferencia={props.tempoTransferencia}
                />
                <C.Linha />
                <C.Columns>
                    <C.Column size='4'>
                        <C.Label className='icp-composed-label'>Início dos sintomas </C.Label>
                    </C.Column>
                    <C.Column size='3'>
                        <C.DatePickerField name='dataInicioSintomas' label='Data' placeholder='dd/mm/aaaa' />
                    </C.Column>
                    <C.Column size='2'>
                        <C.HourField
                            name='horaInicioSintomas'
                            label='Hora'
                            placeholder='00:00'
                            disabled={!props.diaInicioSintomas}
                        />
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column size='4'>
                        <C.Label className='icp-composed-label'> Admissão no hospital</C.Label>
                    </C.Column>
                    <C.Column size='3'>
                        <C.DatePickerField name='dataAdmissao' label='Data' placeholder='dd/mm/aaaa' />
                    </C.Column>
                    <C.Column size='2'>
                        <C.HourField
                            name='horaAdmissao'
                            label='Hora'
                            placeholder='00:00'
                            disabled={!props.diaAdmissaoHospital}
                        />
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column size='4'>
                        <C.Label className='icp-composed-label'> Abertura vaso/balão </C.Label>
                    </C.Column>
                    <C.Column size='3'>
                        <C.DatePickerField name='dataAberturaVaso' label='Data' placeholder='dd/mm/aaaa' />
                    </C.Column>
                    <C.Column size='2'>
                        <C.HourField
                            name='horaAberturaVaso'
                            label='Hora'
                            placeholder='00:00'
                            disabled={!props.diaAberturaVaso}
                        />
                    </C.Column>
                </C.Columns>
                <C.Columns>
                    <C.Column size='4'></C.Column>
                    <C.Column size='3'>
                        <C.TextLabel
                            title='Dor/porta'
                            name='dorPorta'
                            placeholder='Informe data/horário'
                            value={props.dorPorta}
                        />
                    </C.Column>
                    <C.Column size='3'>
                        <C.TextLabel
                            title='Porta/balão'
                            name='portaBalao'
                            placeholder='Informe data/horário'
                            value={props.portaBalao}
                        />
                    </C.Column>
                </C.Columns>
            </C.StyledControlPanel>
        )
    )
}

export class CaraterIcp extends React.Component {
    render() {
        let qC
        if (isImmutable(this.props.quadroClinico)) {
            qC = this.props.quadroClinico?.get('id')
        } else {
            qC = this.props.quadroClinico?.id
        }
        const eletivoAdhoc =
            qC === 'ANGINA_ESTAVEL' ||
            qC === 'EQUIVALENTE_ISQUEMICO' ||
            qC === 'DOR_TORACICA_ATIPICA' ||
            qC === 'ASSINTOMATICO'
        const urgenciaInvasiva = qC === 'ANGINA_INSTAVEL' || qC === 'IAM_SEM_SUPRA_ST'
        const showTipo = qC === 'IAM_COM_SUPRA_ST'
        let showTipoExtended
        if (isImmutable(this.props.tipoCaraterICP)) {
            showTipoExtended =
                this.props.tipoCaraterICP?.get('id') === 'PRIMARIA_MENOS_12_HORAS_SINTOMA' ||
                this.props.tipoCaraterICP?.get('id') === 'PRIMARIA_MAIS_12_HORAS_SINTOMA'
        } else {
            showTipoExtended =
                this.props.tipoCaraterICP?.id === 'PRIMARIA_MENOS_12_HORAS_SINTOMA' ||
                this.props.tipoCaraterICP?.id === 'PRIMARIA_MAIS_12_HORAS_SINTOMA'
        }
        const houveTransferenciaIcp = this.props.transferenciaIcp
        return (
            <C.InputPanel fallback size='medium'>
                <SemCaraterIcpMessage naoShowCaraterIcp={!this.props.quadroClinico} />
                <ShowEletivoAdHoc eletivoAdhoc={eletivoAdhoc} />
                <ShowUrgenciaInvasiva urgenciaInvasiva={urgenciaInvasiva} />
                <ShowTipoIcp
                    show={showTipo}
                    showTipoExtended={showTipoExtended}
                    tempoTransferencia={this.props.tempoTransferencia}
                    houveTransferenciaIcp={houveTransferenciaIcp}
                    dorPorta={this.props.dorPorta}
                    portaBalao={this.props.portaBalao}
                    diaSaidaTransferencia={this.props.diaSaidaTransferencia}
                    diaChegadaTransferencia={this.props.diaChegadaTransferencia}
                    diaInicioSintomas={this.props.diaInicioSintomas}
                    diaAdmissaoHospital={this.props.diaAdmissaoHospital}
                    diaAberturaVaso={this.props.diaAberturaVaso}
                />
            </C.InputPanel>
        )
    }
}
