import * as React from 'react'

export interface TextAreaProps {
    disabled?: boolean
    placeholder?: string
    maxLength?: number
    hasError?: boolean
}

export class TextArea extends React.Component<TextAreaProps> {
    render() {
        const { hasError, ...rest } = this.props
        return <textarea className={`textarea${this.props.hasError ? ' textarea-error' : ''}`} {...rest} />
    }
}
