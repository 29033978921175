
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.505,"time":30300,"words":101}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI.`}</li>
      <li parentName="ol">{`Na tela inicial selecione o Relatório de “Informações do produto”.`}</li>
      <li parentName="ol">{`Busque na lista o registro que você procura ou clique no ícone `}<img parentName="li" {...{
          "src": "/images/botaoFiltro.png#center",
          "alt": "Botão de filtro"
        }}></img>{` para ativar um campo de busca.`}</li>
      <li parentName="ol">{`Na lista já será exibido porcentagem de revisões registradas para o produto e o tempo médio para revisões.`}</li>
      <li parentName="ol">{`Selecione o produto desejado e clique no ícone `}<img parentName="li" {...{
          "src": "/images/botaoVisualizarAlarme.png#center",
          "alt": "Botão de Visualizar"
        }}></img>{`.`}</li>
      <li parentName="ol">{`Na tela de detalhamento você poderá visualizar a porcentagem de usos do componente que demandaram revisão, o tempo médio para as revisões e a lista com os principais motivos de revisão.`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

