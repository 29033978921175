import React from 'react'
import { ControlField } from 'libs/components'
import EsferaAcessoFederativaPanel from '../EsferaAcessoFederativaPanel'
import EsferaAcessoHospitalPanel from '../EsferaAcessoHospitalPanel'
import EsferaAcessoMunicipalPanel from '../EsferaAcessoMunicipalPanel'

const PermissoesEsferasAcessoFormSection: React.FC<any> = () => {
    return (
        <>
            <ControlField name='esferaAcessoSection' label={'Esferas de acesso'} required />

            <EsferaAcessoFederativaPanel />
            <EsferaAcessoMunicipalPanel />
            <EsferaAcessoHospitalPanel />
        </>
    )
}

export default PermissoesEsferasAcessoFormSection
