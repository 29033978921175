import React, { useEffect, useState } from 'react'
import * as C from 'libs/components'
import { LoadingContainer } from 'libs/components'
import { Icon } from '../../../libs/components'
import classnames from 'classnames'
import { pdf } from '@react-pdf/renderer'
import { ManualUsuarioPDFView } from './ManualUsuarioPDFView'
import { TopicoManual } from './TopicoManual'
import { arquivosPdf } from './ManualCompletoUsuarioView'
import * as info from '../../../redux/modules/info'
import { PerfilEnum } from 'api/Perfis'
import { printFile } from 'libs/util/PrintFileUtil'

export interface ManualContentProps {
    titulo: string
    arquivoPath: string
    topicoSelecionado: TopicoManual
    subtopicoSelecionado: TopicoManual
    botaoSelecionado: string
    homeOrPublic: string
    history: any
    perfil: string
}

export function ManualContent(props: ManualContentProps) {
    const subTopico = props.subtopicoSelecionado !== undefined

    const [versao, setVersao] = useState()
    useEffect(() => {
        info.getVersao().promise.then(v => setVersao(v.data))
    }, [])

    return (
        <div className='markdown-body'>
            <h2>{props.titulo}</h2>
            {versao && (
                <C.Button
                    size='small'
                    className='manual-text-imprimir'
                    onClick={() =>
                        printPdf(props.titulo, versao, props.arquivoPath, props.topicoSelecionado, props.perfil)
                    }
                >
                    <span>
                        {' '}
                        <Icon icon='imprimir' size='is-x-small' />
                    </span>
                    &nbsp; Gerar PDF da página
                </C.Button>
            )}

            <div className='markdown-body'>
                <div>{subTopico && props.subtopicoSelecionado.possuiBotao && fileButtons(props)}</div>
                <div className='manual-text-content'>
                    {arquivosPdf
                        .filter(a => a.path === props.arquivoPath)
                        .filter(p => p.perfil.includes(PerfilEnum[props.perfil]))
                        .map(arq => (
                            <div>
                                <LoadingContainer key={arq.path} isFetching={!arq}>
                                    <Markdown arquivo={arq} />
                                </LoadingContainer>
                                {arq.iframe
                                    ? React.Children.map(arq.iframe, value => {
                                        return (
                                            <iframe
                                                className={'manual-iframe'}
                                                title={arq.titulo}
                                                src={value}
                                                width='560'
                                                height='315'
                                                frameBorder='0'
                                                allow='accelerometer autoplay encrypted-media gyroscope picture-in-picture'
                                                allowFullScreen
                                            />
                                        )
                                    })
                                    : null}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

interface MarkdownProps {
    arquivo: typeof arquivosPdf[0]
}

function Markdown(props: MarkdownProps) {
    const {
        arquivo: { component: Component },
    } = props
    return <>{Component && <Component />}</>
}

const printPdf = (
    titulo: string,
    versao: string,
    arquivoPath: string,
    topicoSelecionado: TopicoManual,
    perfil: string
) => {
    const topico = topicoSelecionado ? topicoSelecionado.titulo : ''
    pdf(<ManualUsuarioPDFView md={arquivoPath} titulo={titulo} topico={topico} versao={versao} perfil={perfil} />)
        .toBlob()
        .then(blob => {
            convertPdf(blob, titulo)
        })
}

const convertPdf = (blob: Blob, titulo: string) => printFile(blob, titulo)

const fileButtons = (props: ManualContentProps) => {
    const subTopico = props.subtopicoSelecionado !== undefined
    const botaoManual = props.subtopicoSelecionado.botaoManual
    return (
        <div>
            <div>{props.subtopicoSelecionado && <h4>Exibir:</h4>}</div>

            <div className='manual-side-buttom'>
                {subTopico && botaoManual.tecnico && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'tecnico' })}
                        onClick={onFileButtonClicked('tecnico', props)}
                    >
                        Técnico
                    </C.Button>
                )}
                {subTopico && botaoManual.medico && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'medico' })}
                        onClick={onFileButtonClicked('medico', props)}
                    >
                        Médico
                    </C.Button>
                )}
                {subTopico && botaoManual.medicoResidente && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'medicoResidente' })}
                        onClick={onFileButtonClicked('medicoResidente', props)}
                    >
                        Médico Residente
                    </C.Button>
                )}
                {subTopico && botaoManual.financeiro && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'financeiro' })}
                        onClick={onFileButtonClicked('financeiro', props)}
                    >
                        Adm/Financeiro
                    </C.Button>
                )}
                {subTopico && botaoManual.anvisa && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'anvisa' })}
                        onClick={onFileButtonClicked('anvisa', props)}
                    >
                        Colaborador ANVISA
                    </C.Button>
                )}
                {subTopico && botaoManual.gestorSeguranca && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'gestorSeguranca' })}
                        onClick={onFileButtonClicked('gestorSeguranca', props)}
                    >
                        Gestor de Segurança
                    </C.Button>
                )}
                {subTopico && botaoManual.gestorServicoSaude && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'gestorServicoSaude' })}
                        onClick={onFileButtonClicked('gestorServicoSaude', props)}
                    >
                        Gestor do Serviço de Saúde
                    </C.Button>
                )}
                {subTopico && botaoManual.gestorIntegracao && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'gestorIntegracao' })}
                        onClick={onFileButtonClicked('gestorIntegracao', props)}
                    >
                        Gestor de Integração
                    </C.Button>
                )}
                {subTopico && botaoManual.snvs && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'snvs' })}
                        onClick={onFileButtonClicked('snvs', props)}
                    >
                        SNVS
                    </C.Button>
                )}
                {subTopico && botaoManual.registro && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'registro' })}
                        onClick={onFileButtonClicked('registro', props)}
                    >
                        Por registro ANVISA
                    </C.Button>
                )}
                {subTopico && botaoManual.cnpj && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'cnpj' })}
                        onClick={onFileButtonClicked('cnpj', props)}
                    >
                        Por CNPJ
                    </C.Button>
                )}
                {subTopico && botaoManual.semEtiqueta && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'semEtiqueta' })}
                        onClick={onFileButtonClicked('semEtiqueta', props)}
                    >
                        Sem etiqueta ANVISA
                    </C.Button>
                )}
                {subTopico && botaoManual.preFicha && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'preFicha' })}
                        onClick={onFileButtonClicked('preFicha', props)}
                    >
                        Pré-ficha
                    </C.Button>
                )}
                {subTopico && botaoManual.dadosClinicos && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'dadosClinicos' })}
                        onClick={onFileButtonClicked('dadosClinicos', props)}
                    >
                        Dados Clínicos
                    </C.Button>
                )}
                {subTopico && botaoManual.dadosProduto && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'dadosProduto' })}
                        onClick={onFileButtonClicked('dadosProduto', props)}
                    >
                        Dados de Produto
                    </C.Button>
                )}
                {subTopico && botaoManual.dadosFinanceiros && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'dadosFinanceiros' })}
                        onClick={onFileButtonClicked('dadosFinanceiros', props)}
                    >
                        Dados Financeiros
                    </C.Button>
                )}
                {subTopico && botaoManual.dadosProdutoFinanceiros && (
                    <C.Button
                        size='medium'
                        className={classnames({ active: props.botaoSelecionado === 'dadosProdutoFinanceiros' })}
                        onClick={onFileButtonClicked('dadosProdutoFinanceiros', props)}
                    >
                        Dados de Produto e Financeiros
                    </C.Button>
                )}
            </div>
        </div>
    )
}

const onFileButtonClicked = (perfil: string, props: ManualContentProps) => {
    return () =>
        props.history.push({
            pathname: `${props.homeOrPublic}/manualUsuario/${props.topicoSelecionado.path}/${props.subtopicoSelecionado.path}/${perfil}`,
        })
}
