import { Label, Linha } from 'libs/components'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { IntegracaoHorizontalTabMenu } from './componentes/common/IntegracaoHorizontalTabMenu'
import { RecebimentoIntegracaoPageRecebidos } from './componentes/recebimento/RecebimentoIntegracaoPageRecebidos'
import { RecebimentoIntegracaoPageRecusados } from './componentes/recebimento/RecebimentoIntegracaoPageRecusados'

export const RecebimentoIntegracaoView = () => {
    const pathFirstTab = '/home/recebimentos'
    const pathSecondTab = pathFirstTab + '/recusados'

    return (
        <div className='recebimento-integracao'>
            <Label className='integracao-header-title'>Controle de recebimento de dados</Label>
            <Linha className='integracao-header-line' />
            <IntegracaoHorizontalTabMenu
                pathFirstTab={pathFirstTab}
                titleFirstTab='DADOS RECEBIDOS'
                pathSecondTab={pathSecondTab}
                titleSecondTab='DADOS RECUSADOS'
            />
            <Switch>
                <Route
                    exact
                    path={pathFirstTab}
                    render={() => {
                        return <RecebimentoIntegracaoPageRecebidos />
                    }}
                />
                <Route
                    exact
                    path={pathSecondTab}
                    render={() => {
                        return <RecebimentoIntegracaoPageRecusados />
                    }}
                />
            </Switch>
        </div>
    )
}
