import axios, { AxiosPromise } from 'axios'
import { CadastroPacienteForm, PacienteSeletor } from 'backend'

class Paciente {
    save(pacienteForm: CadastroPacienteForm): AxiosPromise<CadastroPacienteForm> {
        return axios.post('/api/pacientes', pacienteForm)
    }

    getByFiltro(filtro: PacienteSeletor): AxiosPromise<CadastroPacienteForm> {
        return axios.get('/api/pacientes/getByFiltro', { params: filtro })
    }
}

export default new Paciente()
