import * as React from 'react'
import { InjectedFormProps } from 'redux-form'
import { InfoProdutoFiltro } from 'backend'
import { ControlField, Button, Columns, Column } from 'libs/components'
import { Map } from 'immutable'
import { ComponenteSelectField } from './ComponenteSelectField'
import form from 'libs/redux-form/form'

export interface InfoProdutoListFilterProps extends InjectedFormProps<any, any> {
    filtro: InfoProdutoFiltro
    onSubmit(values: Map<string, any>): void
}

class InfoProdutoListFilterCmp extends React.Component<InfoProdutoListFilterProps> {
    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <Columns className='align-end'>
                    <Column size='10'>
                        <ControlField label='Componente' name='componente'>
                            <ComponenteSelectField
                                filtro={{
                                    tipoCirurgia: this.props.filtro.tipoCirurgia,
                                }}
                            />
                        </ControlField>
                    </Column>
                    <Column size='2'>
                        <Button onClick={this.props.handleSubmit} type='neon' square>
                            APLICAR
                        </Button>
                    </Column>
                </Columns>
            </form>
        )
    }
}
export const InfoProdutoListFilter = form({
    form: 'infoProdutoListFilter',
    hasSuccessModal: false,
    hasErrorModal: false,
    hasLeaveModal: false,
    initialValues: {
        classificacaoCirurgia: ['PRIMARIA', 'REVISAO'],
    } as InfoProdutoFiltro,
})(InfoProdutoListFilterCmp) as any
