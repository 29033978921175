import { shallowEqual, useSelector } from 'react-redux'
import { formValueSelector } from 'redux-form/immutable'

export const useFormValues = <T extends object>(formName: string, sectionName: string, fields: string[]) =>
    useSelector<any, T>(state => {
        const selector = formValueSelector(formName)
        const section = sectionName ? `${sectionName}.` : ''
        const buildObject = (field: string, fieldName: string) => {
            if (!field.includes('.')) {
                return { [field]: selector(state, fieldName) }
            }
            const splitted = field.split('.')
            return {
                [splitted[0]]: buildObject(splitted[1], fieldName),
            }
        }
        return fields
            .map(field => buildObject(field, section + field))
            .reduce((prev, curr) => {
                const currProperty = Object.getOwnPropertyNames(curr)[0]
                if (Object.getOwnPropertyNames(prev).includes(currProperty)) {
                    prev[currProperty] = { ...prev[currProperty], ...curr[currProperty] }
                    return prev
                }
                return { ...prev, ...curr }
            }) as T
    }, shallowEqual)
