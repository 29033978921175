import React from 'react'
import * as C from 'libs/components'

const anticoagulacaoOptions = [
    { id: 'FONDAPARINUX', nome: 'Fondaparinux' },
    { id: 'HBPM', nome: 'HBPM' },
    { id: 'HNF', nome: 'HNF' },
    { id: 'OUTRO', nome: 'Outro' },
]

export class AnticoagulacaoField extends React.Component {
    render() {
        return <C.FixedSelectField {...this.props} options={anticoagulacaoOptions} />
    }
}
