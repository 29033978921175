import * as React from 'react'
import AlertaCard from 'view/home/anvisa/alertas/components/AlertaCard'
import { AlertaDto, AlertaFiltro, TipoAlerta, SituacaoAlerta } from 'backend'
import { Page } from 'redux/middleware/PaginationAction'
import TipoAlertaSelect from 'view/home/anvisa/alertas/components/TipoAlertaSelect'
import AlertaRow from 'view/home/anvisa/alertas/components/AlertaRow'
import Text from 'libs/components/presentational/typography/Text'
import { RouteComponentProps } from 'react-router-dom'
import { Paginator, Card, Tag } from 'libs/components'
import SituacaoAlertaSelect from 'view/home/anvisa/alertas/components/SituacaoAlertaSelect'
import { Button } from 'libs/components'

interface AlertasViewProps extends RouteComponentProps<any> {
    alertas: Page<AlertaDto>
    countAlertasNaoLidos: number
    filtro: AlertaFiltro
    configurar()
    marcarComoLido(alertaId: number)
    onPageChanged(page?: number)
    onFilter(filtro: AlertaFiltro)
    refreshAlertasNaoLidos: () => void
}

export default class AlertasView extends React.PureComponent<AlertasViewProps> {
    componentDidMount() {
        this.props.onPageChanged()
        this.props.refreshAlertasNaoLidos()
    }

    render() {
        return (
            <div className='container alertas-page'>
                <div className='alertas-page-header'>
                    <Text color='black-bis' size='x-large'>
                        Central de alarmes{this.renderNaoLidosTag()}
                    </Text>
                    <div className='flex-spacer'></div>
                    {this.props.alertas && <PageInfo page={this.props.alertas} />}
                    {this.props.alertas && <div className='separator'></div>}
                    <Button icon='configuracao' onClick={this.props.configurar}>
                        Configurar
                    </Button>
                </div>
                <div className='alertas-page-body'>
                    <Card noPadding>
                        <AlertaRow className='alertas-page-header'>
                            <TipoAlertaSelect
                                tipoSelecionado={this.props.filtro && this.props.filtro.tipo}
                                onChange={this.onTipoAlertaSelecionado}
                            />
                            <div className='flex-spacer'></div>
                            <SituacaoAlertaSelect
                                situacaoSelecionada={this.props.filtro && this.props.filtro.situacao}
                                onChange={this.onSituacaoAlertaSelecionada}
                            />
                        </AlertaRow>
                        {this.props.alertas &&
                            this.props.alertas.content.map(alerta => (
                                <AlertaCard
                                    key={alerta.id}
                                    alerta={alerta}
                                    marcarComoLido={this.props.marcarComoLido}
                                />
                            ))}
                        {this.props.alertas && this.props.alertas.totalElements === 0 && (
                            <AlertaRow>Nenhum alarme encontrado.</AlertaRow>
                        )}
                        <AlertaRow className='alertas-page-footer'>
                            {this.props.alertas && (
                                <Paginator
                                    first={this.props.alertas.first}
                                    last={this.props.alertas.last}
                                    pageNumber={this.props.alertas.number}
                                    totalPages={this.props.alertas.totalPages}
                                    onChange={this.props.onPageChanged}
                                />
                            )}
                        </AlertaRow>
                    </Card>
                </div>
            </div>
        )
    }

    private renderNaoLidosTag = () => {
        if (!this.props.countAlertasNaoLidos || this.props.countAlertasNaoLidos === 0) {
            return null
        } else if (this.props.countAlertasNaoLidos === 1) {
            return (
                <Tag className='tag-nao-lidos'>
                    <Text color='grey-darker' weight='semibold'>
                        {this.props.countAlertasNaoLidos} não lido
                    </Text>
                </Tag>
            )
        } else {
            return (
                <Tag className='tag-nao-lidos'>
                    <Text color='grey-darker' weight='semibold'>
                        {this.props.countAlertasNaoLidos} não lidos
                    </Text>
                </Tag>
            )
        }
    }

    onTipoAlertaSelecionado = (tipoAlerta: TipoAlerta) => {
        this.props.onFilter({ ...this.props.filtro, tipo: tipoAlerta })
    }

    onSituacaoAlertaSelecionada = (situacaoAlerta: SituacaoAlerta) => {
        this.props.onFilter({ ...this.props.filtro, situacao: situacaoAlerta })
    }
}

interface PageInfoProps {
    page: Page<any>
}

const PageInfo: React.SFC<PageInfoProps> = props => {
    const firstElementShown = props.page.totalElements === 0 ? 0 : props.page.number * props.page.size + 1
    const lastElementShown =
        firstElementShown + props.page.size > props.page.totalElements
            ? props.page.totalElements
            : firstElementShown + props.page.size - 1
    return (
        <Text color='black-bis' size='medium'>
            {firstElementShown}-{lastElementShown} de {props.page.totalElements}
        </Text>
    )
}
