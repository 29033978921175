import * as React from 'react'
import { Icon } from './elements/Icon'

interface SectionTitleProps {
    id?: string
    title: string
    onAjudaClick?(): void
}

export class SectionTitle extends React.Component<SectionTitleProps, any> {
    render() {
        return (
            <div id={this.props.id} className='section-title is-children-center'>
                <div className='flex-1'>{this.props.title}</div>
                {this.props.onAjudaClick && <AjudaButton handleClick={this.props.onAjudaClick} />}
            </div>
        )
    }
}

interface AjudaButtonProps {
    handleClick(): void
}

const AjudaButton = (props: AjudaButtonProps) => {
    return (
        <div className='help flex-0' onClick={props.handleClick}>
            AJUDA
            <Icon icon='ajuda24' />
        </div>
    )
}
