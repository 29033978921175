import * as React from 'react'
import field from 'libs/redux-form/field'
import { WrappedFieldProps } from 'redux-form'
import { FormFieldProps } from '../FormField'
import { RadioPanel } from '../RadioPanel'

interface RadioPanelFieldProps extends Partial<WrappedFieldProps>, FormFieldProps {
    label: string
    name: string
    value: string
    showErrors?: boolean
}

class RadioPanelFieldCmp extends React.Component<RadioPanelFieldProps> {
    render() {
        return <RadioPanel {...this.props} />
    }
}

export const RadioPanelField = field({ type: 'radio', haswrapper: false })(RadioPanelFieldCmp)
