import { DescricaoPerfilCaptalize } from 'api/Perfis'
import { DadosGeraisDto, DadosGeraisProfissionalDto } from 'backend'
import { Column, Columns, Conteudo, DateLabel, Linha, Text, TextLabel } from 'libs/components'
import * as DateUtil from 'libs/util/DateUtil'
import * as React from 'react'

interface DadosGeraisProfissionalHeaderProps {
    isResponsavel: boolean
}

const DadosGeraisProfissionalHeader: React.FC<DadosGeraisProfissionalHeaderProps> = ({ isResponsavel }) => {
    return (
        <Columns>
            <Column className='dados-gerais-prof-subtitle-column' size='4'>
                <Text className='dados-gerais-prof-subtitle-text' weight='bold'>
                    {isResponsavel ? 'Profissional responsável' : 'Profissional'}
                </Text>
            </Column>
            <Column className='dados-gerais-prof-subtitle-column'>
                <Text className='dados-gerais-prof-subtitle-text' weight='bold'>
                    Registro em conselho
                </Text>
            </Column>
            <Column className='dados-gerais-prof-subtitle-column'>
                <Text className='dados-gerais-prof-subtitle-text' weight='bold'>
                    Especialidade
                </Text>
            </Column>
        </Columns>
    )
}

interface DadosGeraisProfissionalContentProps {
    profissional: DadosGeraisProfissionalDto
}

const DadosGeraisProfissionalContent: React.FC<DadosGeraisProfissionalContentProps> = ({ profissional }) => {
    return (
        <Columns className='dados-gerais-profissional-content-row'>
            <Column size='4'>
                <Text>{profissional.nomeMedico}</Text>
            </Column>
            <Column>
                <Text>
                    {profissional.nomeConselhoClasse +
                        ' ' +
                        profissional.numeroRegistroConselho +
                        '/' +
                        profissional.ufRegistro}
                </Text>
            </Column>
            <Column>
                <Text>{DescricaoPerfilCaptalize[profissional.perfil]}</Text>
            </Column>
        </Columns>
    )
}

interface DadosGeraisVisualizacaoProps {
    geral: DadosGeraisDto
}

export const DadosGerais: React.FC<DadosGeraisVisualizacaoProps> = ({ geral }) => {
    return (
        <Conteudo>
            <Columns>
                <Column>
                    <TextLabel title='Hospital' value={geral.nomeHospital} />
                </Column>
            </Columns>
            <Text weight='bold'>Equipe médica</Text>
            <Linha className='equipe-medica-linha' />
            <DadosGeraisProfissionalHeader isResponsavel={true} />
            <DadosGeraisProfissionalContent profissional={geral.profissionalResponsavel} />
            {geral.equipeMedica.length > 0 && (
                <div className='dados-gerais-equipe-medica-integrantes-box'>
                    <DadosGeraisProfissionalHeader isResponsavel={false} />
                    {geral.equipeMedica.map((value: DadosGeraisProfissionalDto) => {
                        return <DadosGeraisProfissionalContent key={value.idVinculoProfissional} profissional={value} />
                    })}
                </div>
            )}
            <Linha />
            <Columns>
                <Column size='3'>
                    <DateLabel title='Data da cirurgia' value={geral.dataCirurgia} />
                </Column>
                <Column size='3'>
                    <TextLabel title='Hora de início' value={geral.horaInicio} />
                </Column>
                <Column>
                    <TextLabel title='Hora de término' value={geral.horaTermino} />
                </Column>
            </Columns>
            <Columns>
                <Column size='3' />
                <Column>
                    <p className='is-italic is-muted'>
                        {'Duração: ' + DateUtil.calculateDuracaoHoras(geral.horaInicio, geral.horaTermino)}
                    </p>
                </Column>
            </Columns>
        </Conteudo>
    )
}
