import * as React from 'react'

interface FloatingContainerProps {
    anchor?: string
    stopElement: string
    offsetHeight?: number
    stopOffset?: number
}

interface FloatingContainerState {
    fixed: boolean
    containerHeight: number
    navWidth: number
    top: number
    bottom: number
}

export class FloatingContainer extends React.Component<FloatingContainerProps, FloatingContainerState> {
    private divRef: HTMLDivElement
    private navRef: HTMLElement

    static defaultProps: FloatingContainerProps = {
        anchor: 'top',
        stopOffset: 0,
        offsetHeight: undefined,
        stopElement: undefined,
    }

    constructor(props) {
        super(props)
        this.scrollFunction = this.scrollFunction.bind(this)

        this.state = {
            fixed: false,
            containerHeight: undefined,
            navWidth: undefined,
            top: undefined,
            bottom: undefined,
        }
    }

    scrollFunction() {
        if (this.divRef) {
            const divElemPos = this.divRef.getBoundingClientRect()

            const newState = {} as FloatingContainerState

            if (this.props.anchor === 'top') {
                let top = 0

                if (this.props.stopElement) {
                    const stopElem = document.getElementById(this.props.stopElement)
                    const stopElemPos = stopElem.getBoundingClientRect()

                    top = this.navRef.offsetHeight - stopElemPos.top + this.props.stopOffset

                    if (top <= 0) {
                        top = 0
                    } else {
                        top = -top
                    }
                }

                newState.fixed = divElemPos.top < 0
                newState.top = top
            } else if (this.props.anchor === 'bottom') {
                let bottom = 0

                if (this.props.stopElement) {
                    const stopElem = document.getElementById(this.props.stopElement)
                    const stopElemPos = stopElem.getBoundingClientRect()

                    bottom = window.innerHeight - stopElemPos.top + this.props.stopOffset

                    if (bottom <= 0) {
                        bottom = 0
                    }
                }

                newState.fixed = window.scrollY + window.innerHeight > divElemPos.bottom + this.divRef.offsetHeight

                newState.bottom = bottom
            }

            if (!this.state.fixed && newState.fixed) {
                newState.navWidth = divElemPos.width
                newState.containerHeight = this.divRef.offsetHeight
            } else if (!newState.fixed) {
                newState.navWidth = undefined
                newState.containerHeight = undefined
            }

            this.setState(newState)
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollFunction)
        window.addEventListener('resize', this.scrollFunction)
        // executa depois de renderizado no browser
        setTimeout(this.scrollFunction)
    }

    componentDidUpdate() {
        // executa depois de renderizado no browser
        setTimeout(this.scrollFunction)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollFunction)
        window.removeEventListener('resize', this.scrollFunction)
    }

    render() {
        const position = this.state.fixed ? 'fixed' : undefined
        const top = this.state.fixed && this.state.top
        const bottom = this.state.fixed && this.state.bottom

        return (
            <div ref={elem => (this.divRef = elem)} style={{ height: this.state.containerHeight }}>
                <nav
                    ref={elem => (this.navRef = elem)}
                    style={{
                        position: position,
                        top: top,
                        bottom: bottom,
                        width: this.state.fixed && this.state.navWidth,
                    }}
                >
                    {this.props.children}
                </nav>
            </div>
        )
    }
}
