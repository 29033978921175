/* tslint:disable */
import React from 'react'

const SvgInvalido = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 12 12' {...props}>
        <path d='M7.57 3.005a33.77 33.77 0 01-.319 1.08c-.122.39-.264.877-.417 1.461-.16.582-.324 1.304-.5 2.161h-.671a31.83 31.83 0 00-.501-2.166 40.201 40.201 0 00-.418-1.45 30.69 30.69 0 01-.305-1.052c-.094-.343-.139-.6-.139-.775 0-.347.163-.645.496-.894.334-.248.731-.37 1.197-.37.459 0 .858.122 1.195.37.341.25.512.547.512.894 0 .188-.045.435-.13.74zm-.676 7.627a1.243 1.243 0 01-.89.368c-.345 0-.646-.123-.898-.368a1.191 1.191 0 01-.381-.885c0-.34.127-.635.381-.887.252-.252.553-.375.898-.375.338 0 .636.123.89.375s.381.547.381.887c0 .343-.127.637-.381.885z' />
    </svg>
)

export default SvgInvalido
