import * as React from 'react'
import classnames from 'classnames'
import { RadioButton, RadioButtonProps } from '../input/RadioButton'
import { WrappedFieldProps } from 'redux-form'

interface RadioPanelProps extends RadioButtonProps, Partial<WrappedFieldProps> {
    required?: boolean
    showErrors?: boolean
    testid?: string
}

export const RadioPanel: React.FC<RadioPanelProps> = props => {
    const classes = classnames('radiopanel', {
        active: props.input.checked,
    })

    const error = !!props.showErrors && props.meta?.error

    return (
        <div className={classes}>
            <div className='radiopanel-header'>
                <RadioButton
                    {...props.input}
                    label={props.label}
                    disabled={props.disabled}
                    error={error}
                    testid={props.testid}
                />
            </div>
            <div className='radiopanel-content'>{props.children}</div>
        </div>
    )
}
