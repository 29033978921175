/* tslint:disable */
import React from 'react'

const SvgDistProdutos = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
        <path d='M22.334 7.687L11.637 12.91v3.353l-1.866-.861v-3.354l10.697-5.222-3.593-1.66c-.481-.222-1.269-.222-1.75 0L6.278 9.253C5.573 9.577 4.998 10.499 5 11.3l.022 9.577c.002.81.59 1.732 1.306 2.05l8.824 3.918c.466.207 1.227.207 1.693 0l8.827-3.917c.716-.318 1.304-1.241 1.306-2.05L27 11.3c.002-.801-.573-1.723-1.278-2.048l-3.388-1.565z' />
    </svg>
)

export default SvgDistProdutos
