import { useRouter } from 'hooks/useRouter'
import { Column, Columns, FloatingContainer, ModalCard, ScrollToTop, Step, Steps } from 'libs/components'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, Redirect, Route, Switch } from 'react-router-dom'
import { change, destroy, initialize } from 'redux-form'
import { hasSubmitSucceeded, isPristine } from 'redux-form/immutable'
import {
    clearRastreabilidade,
    deleteImagemEtiqueta,
    loadDadosProduto,
    saveDadosFinanceiroAdministrativo,
    updateDadosFinanceiroAdministrativo,
    validateDadosFinanceiroAdministrativo,
} from 'redux/modules/rastreabilidade'
import DadosFinanceirosValidator from 'view/home/rastreabilidade/dadosFinanceiro/DadosFinanceirosValidator'
import RastreabilidadeForm from 'view/home/rastreabilidade/RastreabilidadeForm'
import { InformacoesProcedimento } from '../componentes'
import ModalInformacoesProcedimento from '../componentes/ModalInformacoesProcedimento'
import { FinanceiroRastreabilidadeForm } from './FinanceiroRastreabilidadeForm'
import { usePesquisaSatisfacaoPopup } from 'view/componentes/pesquisaSatisfacao/usePesquisaSatisfacaoPopup'
import { clearEdicaoProcedimentoData, EdicaoDadosFinanceirosState } from 'redux/modules/edicaoProcedimento'
import { State } from 'redux/modules'
import { handleErrorResolver, handleErrorSubmiting } from '../common/RastreabilidadeErrorUtil'

const formName = 'dadosFinanceirosForm'

const ModalShowImage = props => {
    return (
        <ModalCard title='Imagem etiqueta' active={props.open} onClose={props.close} hasCloseButton>
            <div className='modal-etiqueta'>
                <img src={'/api/arquivo/imagem/' + props.idImagem} alt='etiqueta' />
            </div>
        </ModalCard>
    )
}

export const FinanceiroRastreabilidadeView = ({ editMode = false }) => {
    const path = editMode ? '/home/edicaoProcedimento/dadosFinanceiro/' : '/home/dadosFinanceiro/'
    const dispatch = useDispatch()
    const rastreabilidade = useSelector((state: any) => state.rastreabilidade)
    const procedimento = rastreabilidade?.findProcedimento.data
    const { history, location } = useRouter()
    const [openModalInformacoesProcedimento, setOpenModalInformacoesProcedimento] = useState<boolean>(false)
    const submitSucceeded = useSelector(state => hasSubmitSucceeded(formName)(state))
    const pristine = useSelector(state => isPristine(formName)(state))
    const showModalLeave = useRef<boolean>(true)
    const [openModalShowImage, setOpenModalShowImage] = useState<boolean>(false)
    const { showPesquisa } = usePesquisaSatisfacaoPopup()
    const idImagem = rastreabilidade?.loadDadosProduto.data?.imagem?.id

    // mimics componentWillUnmount
    useEffect(
        () => () => {
            dispatch(destroy(formName))
            dispatch(clearRastreabilidade())
            dispatch(clearEdicaoProcedimentoData())
        },
        [dispatch]
    )

    const componentesSteps = () => {
        return (
            <Steps title='Componentes utilizados'>
                {procedimento.componentes.map((componente, index) => {
                    const url = location.pathname
                    const urlParam = Number(url.charAt(url.length - 1))
                    return (
                        <Step
                            key={index}
                            name={componente.nome}
                            hasLink
                            linkActive={index < urlParam}
                            path={path + `${index}`}
                        />
                    )
                })}
            </Steps>
        )
    }

    const promptCallback = useCallback(
        location => {
            if (!submitSucceeded && location.pathname.indexOf(path) === -1 && !pristine && showModalLeave.current) {
                return 'mensagem não usada'
            }
        },
        [path, pristine, submitSucceeded]
    )

    const [atualFormItemName, setAtualFormItemName] = useState<string>('')
    const next = (index: number) => form => {
        const componente = procedimento.componentes[index]
        const formItemName = 'componente-' + componente.id
        setAtualFormItemName(formItemName)

        let formItem = form.get(formItemName)
        formItem = formItem.set('idProcedimento', procedimento.id)

        return dispatch(validateDadosFinanceiroAdministrativo(formItem) as any).then(() => {
            formItem = formItem.set('idRegistroProcedimentoComp', componente.id)
            formItem = formItem.set('nomeComponente', componente.nome)
            form = form.set(formItemName, formItem)
            dispatch(change(formName, formItemName, formItem))

            if (index === procedimento.componentes.length - 1) {
                if (editMode) {
                    return dispatch<Promise<any>>(
                        updateDadosFinanceiroAdministrativo({
                            dadosFinanceiros: form.toArray(),
                            idProcedimento: procedimento.id,
                        }) as any
                    )
                } else {
                    return dispatch<Promise<any>>(
                        saveDadosFinanceiroAdministrativo({
                            dadosFinanceiros: form.toArray(),
                            idProcedimento: procedimento.id,
                        }) as any
                    )
                }
            }
        })
    }

    const afterSave = (index: number) => () => {
        if (index < procedimento.componentes.length - 1) {
            history.replace(path + (index + 1))
        } else {
            showPesquisa()
            history.push('/home')
        }
        if (!editMode) {
            dispatch(initialize(formName, {}, { keepDirty: true, keepSubmitSucceeded: false }))
        }
    }

    const onCancel = () => history.push('/home')

    const onDeleteImagemEtiqueta = (idDadosProduto: number, idRegistroProcedimentoComponente: number) => {
        dispatch(deleteImagemEtiqueta(idDadosProduto) as any).then(() => {
            dispatch(
                loadDadosProduto({
                    idRegistroProcedimentoComponente: idRegistroProcedimentoComponente,
                })
            )
        })
    }

    const onLoadDadosProduto = (idRegistroProcedimentoComponente: number) => {
        dispatch(loadDadosProduto({ idRegistroProcedimentoComponente: idRegistroProcedimentoComponente }))
    }

    const handleSubmitFail = e => {
        // generic error here always send to /home
        if (e && typeof e[atualFormItemName] === 'string') {
            showModalLeave.current = false
            history.push('/home')
        }
    }

    const localHandleErrorSubmiting = error => {
        return handleErrorSubmiting(error, atualFormItemName)
    }

    const localHandleErrorResolver = error => {
        return handleErrorResolver(error, atualFormItemName)
    }

    const editingValues: EdicaoDadosFinanceirosState = useSelector(
        (state: State) => state.edicao as EdicaoDadosFinanceirosState
    )

    let thevalues = {}
    // eslint-disable-next-line
    editingValues?.dadosFinanceiros?.forEach(prod => {
        return (thevalues[`componente-${prod.idRegistroProcedimentoComp}`] = prod)
    })

    return (
        <>
            <ScrollToTop />
            <Columns fullHeight>
                <Column verticalFlow size='4'>
                    <div className='rastreabilidade-sidebar is-full-height'>
                        <InformacoesProcedimento
                            open={() => setOpenModalInformacoesProcedimento(true)}
                            procedimento={procedimento}
                        />
                        <FloatingContainer stopElement='footer'>{componentesSteps()}</FloatingContainer>
                    </div>
                </Column>
                <Column className='procedimento-conteudo'>
                    <Prompt message={promptCallback} />
                    <Switch>
                        <Route exact path={path} render={() => <Redirect to={path + '0'} />} />

                        {procedimento.componentes.map((componente, index) => (
                            <Route
                                key={index}
                                path={path + `${index}`}
                                render={renderProps => (
                                    <RastreabilidadeForm
                                        {...renderProps}
                                        editinValues={thevalues}
                                        destroyOnUnmount={false}
                                        forceUnregisterOnUnmount={true}
                                        formComponent={FinanceiroRastreabilidadeForm}
                                        formName={formName}
                                        formSectionName={`componente-${componente.id}`}
                                        validator={DadosFinanceirosValidator}
                                        onSubmit={next(index)}
                                        onSubmitSuccess={afterSave(index)}
                                        canSave={index + 1 === procedimento.componentes.length}
                                        componente={componente}
                                        cancelar={onCancel}
                                        deleteImagemEtiqueta={onDeleteImagemEtiqueta}
                                        open={() => setOpenModalShowImage(true)}
                                        loadDadosProduto={onLoadDadosProduto}
                                        onSubmitFail={handleSubmitFail}
                                        onErrorSubmitting={localHandleErrorSubmiting}
                                        errorResolver={localHandleErrorResolver}
                                        editMode={editMode}
                                        origemExterna={procedimento.origemExterna}
                                    />
                                )}
                            />
                        ))}
                    </Switch>
                </Column>
                <ModalInformacoesProcedimento
                    open={openModalInformacoesProcedimento}
                    procedimento={procedimento}
                    close={() => setOpenModalInformacoesProcedimento(false)}
                />
                <ModalShowImage
                    open={openModalShowImage}
                    idImagem={idImagem}
                    close={() => setOpenModalShowImage(false)}
                />
            </Columns>
        </>
    )
}
