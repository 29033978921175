import { isImmutable } from 'immutable'
import {
    Button,
    ButtonBar,
    ChecklistItemField,
    CheckPanelField,
    ClearableRadioField,
    Column,
    Columns,
    Conteudo,
    ControlField,
    DatePickerField,
    Form,
    FormField,
    InputPanel,
    Linha,
    PeriodoField,
    RadioField,
    SectionTitle,
    SimNaoRadioField,
} from 'libs/components'
import { InicioTerminoPanel } from 'libs/components/presentational/form/InicioTerminoPanel'
import form from 'libs/redux-form/form'
import * as DateUtil from 'libs/util/DateUtil'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Scroll from 'react-scroll'
import { FormSection, formValueSelector } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { clearEdicaoProcedimentoData, EdicaoState } from 'redux/modules/edicaoProcedimento'
import { clear as clearNovoProcedimento } from 'redux/modules/novoProcedimento'
import { actionCreators as pacienteActionCreators } from 'redux/modules/paciente'
import { usePesquisaSatisfacaoPopup } from 'view/componentes/pesquisaSatisfacao/usePesquisaSatisfacaoPopup'
import {
    AntibioticoField,
    CardPaciente,
    ComponenteComplementar,
    ComponentePrincipal,
    ListComponenteFichaField,
    ModalSave,
    TipoEnxertiaField,
} from 'view/home/novoProcedimento/ficha/componentes'
import FichaRevisaoJoelhoValidator from 'view/home/novoProcedimento/ficha/joelho/FichaRevisaoJoelhoValidator'
import { FichaFormCommonProps, FichaFormWrapProps } from '../../procedimentos/ProcedimentosView'
import FichaPromptLeave from '../componentes/FichaPromptLeave'
import { handleErrorResolver } from '../DadoClinicoErrorUtil'

const formName = 'revisaoJoelho'

const FichaRevisaoJoelhoView = props => {
    const dispatch = useDispatch()
    useEffect(
        () => () => {
            dispatch(clearNovoProcedimento())
        },
        [dispatch]
    )
    const { showPesquisa } = usePesquisaSatisfacaoPopup()
    const edicaoRevisaoJoelhoData = useSelector<State, EdicaoState>(state => state.edicao)

    const showModalLeave = React.useRef<boolean>(true)

    const handleSubmitFail = error => {
        if (typeof error === 'string' || error?._error?.status === 404) {
            showModalLeave.current = false
            props.goHome()
        }
    }

    if (props.editMode && isEmpty(edicaoRevisaoJoelhoData)) {
        return null
    }

    return (
        <>
            <FichaPromptLeave formName={formName} showModalLeave={showModalLeave} />
            <FichaForm
                onSubmit={props.onSave}
                onSubmitSuccess={() => {
                    props.goHome()
                    showPesquisa()
                }}
                onSubmitFail={handleSubmitFail}
                errorResolver={handleErrorResolver}
                onCancel={props.goHome}
                lado={props.lado}
                paciente={props.paciente}
                procedimento={props.procedimento}
                initialValues={edicaoRevisaoJoelhoData}
                editMode={props.editMode}
            />
        </>
    )
}
export default FichaRevisaoJoelhoView

const MotivoRevisaoFormSection = props => {
    return (
        <FormSection name='motivoRevisao'>
            <Conteudo>
                <Columns>
                    <Column>
                        <ControlField label={'Motivos da revisão'} name='motivoRevisao' required />
                    </Column>
                </Columns>
                <Columns>
                    <Column size='6' verticalFlow>
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='DESGASTE_POLIETILENO_DEFEITO'
                            label='Desgaste do polietileno / defeito'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='DOR_INEXPLICAVEL'
                            label='Dor inexplicável'
                        />
                        <ChecklistItemField name='motivoRevisao' optionValue='DOR_PATELAR' label='Dor patelar' />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='FRATURA_PERIPROTETICA'
                            label='Fratura periprotética'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='IMPLANTACAO_INADEQUADA_IMPLANTE'
                            label='Implantação inadequada do implante'
                        />
                        <ChecklistItemField name='motivoRevisao' optionValue='INFECCAO_JOELHO' label='Infecção' />
                        <InputPanel>
                            <FormField>
                                <RadioField
                                    name='tipoInfeccao'
                                    label='Precoce'
                                    value='PRECOCE'
                                    disabled={!props.disabledMotivoRevisao}
                                />
                                <RadioField
                                    name='tipoInfeccao'
                                    label='Crônica'
                                    value='CRONICA'
                                    disabled={!props.disabledMotivoRevisao}
                                />
                            </FormField>
                        </InputPanel>
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='INSTABILIDADE_LIGAMENTO_CRUZADO_POSTERIOR'
                            label='Instabilidade do ligamento cruzado posterior'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='INSTABILIDADE_LIGAMENTOS_COLATERAIS'
                            label='Instabilidade dos ligamentos colaterais'
                        />
                    </Column>
                    <Column verticalFlow>
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='LIMITACAO_AMPLITUDE_MOVIMENTO'
                            label='Limitação da amplitude de movimento'
                        />
                        <ChecklistItemField name='motivoRevisao' optionValue='LUXACAO' label='Luxação' />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='LUXACAO_PATELA'
                            label='Luxação da patela'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='NECROSE_PATELA'
                            label='Necrose da patela'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='PROGRESSAO_ARTROSE'
                            label='Progressão da artrose'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='QUEBRA_IMPLANTE'
                            label='Quebra do implante'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='SOLTURA_COMPONENTE_FEMORAL'
                            label='Soltura do componente femoral'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='SOLTURA_COMPONENTE_PATELAR'
                            label='Soltura do componente patelar'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='SOLTURA_COMPONENTE_TIBIAL'
                            label='Soltura do componente tibial'
                        />
                        <ChecklistItemField name='motivoRevisao' optionValue='OUTRO_REVISAO_JOELHO' label='Outro' />
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const ProcedimentoCirurgicoAtualFormSection = props => {
    return (
        <FormSection name='procedimentoCirurgicoAtualRevisao'>
            <Conteudo>
                <Columns>
                    <Column size='4'>
                        <DatePickerField
                            required
                            label='Data da cirurgia'
                            placeholder='dd/mm/aaaa'
                            name='dataCirurgia'
                        />
                    </Column>
                </Columns>

                <InicioTerminoPanel duracao={props.duracao} />

                <Columns>
                    <Column verticalFlow>
                        <ControlField label={'Abordagem cirúrgica'} name='abordagemCirurgica' required verticalFlow>
                            <ClearableRadioField
                                name='abordagemCirurgica'
                                label='Desinserção da tuberosidade anterior da tíbia'
                                value='DESINSERCAO_TUBEROSIDADE_ANTERIOR_TIBIA'
                            />
                            <ClearableRadioField
                                name='abordagemCirurgica'
                                label='Parapatelar lateral'
                                value='PARAPATELAR_LATERAL'
                            />
                            <ClearableRadioField
                                name='abordagemCirurgica'
                                label='Parapatelar medial'
                                value='PARAPATELAR_MEDIAL'
                            />
                            <ClearableRadioField
                                name='abordagemCirurgica'
                                label='Quadríceps SNIP'
                                value='QUADRICEPS_SNIP'
                            />
                            <ClearableRadioField name='abordagemCirurgica' label='Outra' value='OUTRA' />
                        </ControlField>

                        <Linha size='medium' />

                        <SimNaoRadioField label='Uso de dreno' name='usoDreno' required />
                        <SimNaoRadioField label='Transfusão sanguínea' name='transfusaoSanguinea' required />
                        <InputPanel fallback size='medium'>
                            <RadioField
                                name='tipoTransfusaoSanguinea'
                                label='Autóloga'
                                value='AUTOLOGA'
                                disabled={props.disabledTipoTransfusaoSanguinea}
                            />
                            <RadioField
                                name='tipoTransfusaoSanguinea'
                                label='Homóloga'
                                value='HOMOLOGA'
                                disabled={props.disabledTipoTransfusaoSanguinea}
                            />
                        </InputPanel>
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <Linha size='medium' />
                        <SimNaoRadioField label='Foi utilizado antibiótico' name='possuiAntibiotico' required />
                        <InputPanel fallback size='medium'>
                            <Columns>
                                <Column size='6'>
                                    <AntibioticoField
                                        label='Antibiótico'
                                        name='antibiotico'
                                        isDisabled={props.disabledAntibiotico}
                                        required={!props.disabledAntibiotico}
                                    />
                                </Column>
                                <Column size='4'>
                                    <PeriodoField
                                        label='Período'
                                        name='periodoAntibiotico'
                                        disabled={props.disabledAntibiotico}
                                        required={!props.disabledAntibiotico}
                                    />
                                </Column>
                            </Columns>
                        </InputPanel>
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const TipoRevisaoFormSection = props => {
    return (
        <Conteudo>
            <Columns>
                <Column verticalFlow size='5'>
                    <ControlField label={'Tipo'} name='tipoRevisao' required />
                    <InputPanel fallback>
                        <ChecklistItemField
                            name='tipoRevisao'
                            optionValue='NAO_CONVENCIONAL'
                            label='Não convencional'
                        />
                    </InputPanel>
                    <ChecklistItemField
                        name='tipoRevisao'
                        optionValue='TROCA_DO_COMPONENTE_FEMORAL'
                        label='Troca do componente femoral'
                        disabled={props.disabledTipoRevisao}
                    />
                    <ChecklistItemField
                        name='tipoRevisao'
                        optionValue='TROCA_DO_COMPONENTE_PATELAR'
                        label='Troca do componente patelar'
                        disabled={props.disabledTipoRevisao}
                    />
                    <ChecklistItemField
                        name='tipoRevisao'
                        optionValue='TROCA_DO_COMPONENTE_TIBIAL'
                        label='Troca do componente tibial'
                        disabled={props.disabledTipoRevisao}
                    />
                    <ChecklistItemField
                        name='tipoRevisao'
                        optionValue='TROCA_DO_POLIETILENO_TIBIAL'
                        label='Troca do polietileno tibial'
                        disabled={props.disabledTipoRevisao}
                    />
                </Column>
            </Columns>
        </Conteudo>
    )
}

const CimentoOsseoFormSection = props => {
    return (
        <FormSection name='cimentoOsseo'>
            <Conteudo verticalFlow>
                <SimNaoRadioField label='Foi utilizado' name='possuiCimentoOsseo' required />
                <InputPanel horizontalFlow fallback size='medium'>
                    <RadioField
                        name='tipoCimentoOsseo'
                        label='Convencional'
                        value='CONVENCIONAL'
                        disabled={props.disabledTipoCimentoOsseo}
                    />
                    <RadioField
                        name='tipoCimentoOsseo'
                        label='Com antibiótico'
                        value='COM_ANTIBIOTICO'
                        disabled={props.disabledTipoCimentoOsseo}
                    />
                </InputPanel>
                <SimNaoRadioField
                    label='Lavagem pulsátil'
                    name='lavagemPulsatil'
                    disabled={props.disabledTipoCimentoOsseo}
                    required={!props.disabledTipoCimentoOsseo}
                />
            </Conteudo>
        </FormSection>
    )
}

const EnxertiaFormSection = props => {
    return (
        <FormSection name='enxertia'>
            <Conteudo>
                <SimNaoRadioField label='Foi realizada' name='foiRealizada' required />
                <CheckPanelField label='Femoral' name='femoral' disabled={props.disabledEnxertia}>
                    <Conteudo>
                        <TipoEnxertiaField label='Qual?' name='tipoEnxertiaFemoral' disabled={props.disabledEnxertia} />
                    </Conteudo>
                </CheckPanelField>
                <CheckPanelField label='Tibial' name='tibial' disabled={props.disabledEnxertia}>
                    <Conteudo>
                        <TipoEnxertiaField label='Qual?' name='tipoEnxertiaTibial' disabled={props.disabledEnxertia} />
                    </Conteudo>
                </CheckPanelField>
            </Conteudo>
        </FormSection>
    )
}

const ComponentesUtilizadosFormSection = () => {
    return (
        <FormSection name='componentesUtilizadosForm'>
            <Conteudo>
                <ListComponenteFichaField
                    name='componentesPrincipais'
                    combo={
                        <ComponentePrincipal
                            label='Principais'
                            name='componentesPrincipaisLabel'
                            required
                            tipoProcedimento='REVISAO_JOELHO'
                        />
                    }
                />
                <hr />
                <ListComponenteFichaField
                    name='componentesComplementares'
                    combo={
                        <ComponenteComplementar
                            name='componentesComplementaresLabel'
                            tipoProcedimento='REVISAO_JOELHO'
                            label='Complementares'
                        />
                    }
                />
            </Conteudo>
        </FormSection>
    )
}

interface FichaFormCmpProps extends FichaFormCommonProps {
    disabledEnxertia: boolean
    disabledInfeccao: boolean
    disabledTipoRevisao: boolean
    disabledTransfusaoSanguinea: boolean
    disabledPossuiCimentoOsseo: boolean
    disabledAntibiotico: boolean
    duracao: string
}

const FichaFormCmp = (props: FichaFormCmpProps) => {
    const dispatch = useDispatch()

    // mimics componentWillUnmount
    useEffect(
        () => () => {
            dispatch(clearEdicaoProcedimentoData())
            dispatch(pacienteActionCreators.clear(''))
        },
        [dispatch]
    )

    return (
        <div>
            <Form>
                <CardPaciente paciente={props.paciente} lado={props.lado} procedimento={props.procedimento} />
                <Scroll.Element name='motivoRevisao'>
                    <SectionTitle title='MOTIVO DA REVISÃO' />
                    <MotivoRevisaoFormSection disabledMotivoRevisao={props.disabledInfeccao} />
                </Scroll.Element>
                <Scroll.Element name='procedimentoCirurgicoAtualRevisao'>
                    <SectionTitle title='PROCEDIMENTO CIRÚRGICO ATUAL' />
                    <ProcedimentoCirurgicoAtualFormSection
                        duracao={props.duracao}
                        disabledTipoTransfusaoSanguinea={props.disabledTransfusaoSanguinea}
                        disabledAntibiotico={props.disabledAntibiotico}
                    />
                </Scroll.Element>
                <Scroll.Element name='tipoRevisaoForm'>
                    <SectionTitle title='TIPO DE REVISÃO' />
                    <TipoRevisaoFormSection disabledTipoRevisao={props.disabledTipoRevisao} />
                </Scroll.Element>
                <Scroll.Element name='cimentoOsseo'>
                    <SectionTitle title='CIMENTO ÓSSEO' />
                    <CimentoOsseoFormSection disabledTipoCimentoOsseo={props.disabledPossuiCimentoOsseo} />
                </Scroll.Element>
                <Scroll.Element name='enxertia'>
                    <SectionTitle title='ENXERTIA' />
                    <EnxertiaFormSection disabledEnxertia={props.disabledEnxertia} />
                </Scroll.Element>
                <Scroll.Element name='componentesUtilizados'>
                    <SectionTitle title='COMPONENTES UTILIZADOS' />
                    <ComponentesUtilizadosFormSection />
                </Scroll.Element>
            </Form>
            <Conteudo>
                <Linha size='medium' />
                <ButtonBar>
                    <Button pulledLeft size='medium' className='simple-button' onClick={props.onCancel}>
                        Cancelar
                    </Button>
                    <Button
                        pulledRight
                        type='success'
                        size='medium'
                        loading={props.submitting}
                        onClick={props.handleSubmit}
                        disabled={props.pristine}
                    >
                        Salvar registro
                    </Button>
                </ButtonBar>
            </Conteudo>
        </div>
    )
}

const selector = formValueSelector(formName)

const FichaForm = ({ editMode, ...rest }: FichaFormWrapProps) => {
    const Form = useMemo(
        () =>
            form<{}, FichaFormCmpProps>({
                form: formName,
                validate: FichaRevisaoJoelhoValidator as any,
                successTitle: editMode ? 'Edição realizada!' : 'Ficha registrada!',
                successModal: ModalSave,
                successContent: (
                    <span>
                        O registro foi salvo com sucesso. Se preferir, você pode imprimi-lo ou voltar à página inicial
                        de seu perfil.
                    </span>
                ),
                hasLeaveModal: false,
            })(
                connect(state => {
                    const disabledEnxertia = selector(state, 'enxertia.foiRealizada') !== 'SIM'

                    const motivoRevisao = selector(state, 'motivoRevisao.motivoRevisao')
                    let disabledInfeccao
                    if (isImmutable(motivoRevisao)) {
                        disabledInfeccao = motivoRevisao?.toJS().includes('INFECCAO_JOELHO')
                    } else {
                        disabledInfeccao = motivoRevisao?.includes('INFECCAO_JOELHO')
                    }

                    const disabledTransfusaoSanguinea =
                        selector(state, 'procedimentoCirurgicoAtualRevisao.transfusaoSanguinea') !== 'SIM'

                    const disabledAntibiotico =
                        selector(state, 'procedimentoCirurgicoAtualRevisao.possuiAntibiotico') !== 'SIM'

                    const tipoRevisao = selector(state, 'tipoRevisao')
                    const disabledTipoRevisao = tipoRevisao && tipoRevisao.includes('NAO_CONVENCIONAL')

                    const disabledPossuiCimentoOsseo = selector(state, 'cimentoOsseo.possuiCimentoOsseo') !== 'SIM'

                    const inicio = selector(state, 'procedimentoCirurgicoAtualRevisao.horaInicio')
                    const termino = selector(state, 'procedimentoCirurgicoAtualRevisao.horaTermino')
                    const hasHoras = inicio && termino
                    const duracao = hasHoras && DateUtil.calculateDuracaoHoras(inicio, termino)

                    return {
                        disabledEnxertia,
                        disabledInfeccao,
                        disabledTipoRevisao,
                        disabledTransfusaoSanguinea,
                        disabledPossuiCimentoOsseo,
                        disabledAntibiotico,
                        duracao,
                    }
                })(FichaFormCmp)
            ),
        [editMode]
    )
    return <Form {...rest} />
}
