/* eslint-disable no-undef */
import * as React from 'react'
import { Card, LoadingStateContainer, HeatMap, HeatMapLegend } from 'libs/components'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { DistribuicaoProcedimentoDto } from 'backend'

interface Props {
    dist: PromiseRecord<DistribuicaoProcedimentoDto>
}

export class HeatMapCard extends React.Component<Props> {
    render() {
        const data = []
        let maxIntensity = 0
        if (this.props.dist.data) {
            this.props.dist.data.rows.forEach(row => {
                if (row.local.hospital.lat && row.local.hospital.lng) {
                    // LatLng pode ser nula para hospitais cuja geolocalização ainda não foi definida
                    data.push({
                        location: new google.maps.LatLng(row.local.hospital.lat, row.local.hospital.lng),
                        weight: row.total,
                    })
                }
            })
            maxIntensity = Math.max(...data.map(p => p.weight || 1))
        }

        return (
            <Card
                title='Mapa de calor por número de procedimentos'
                noPadding={this.props.dist.readyState !== 'request'}
            >
                <LoadingStateContainer readyState={this.props.dist.readyState}>
                    <div className='heatmap-with-legend'>
                        <div className='heatmap-with-legend--map'>
                            <HeatMap data={data} />
                        </div>
                        <div className='heatmap-with-legend--legend'>
                            <HeatMapLegend maxIntensity={maxIntensity} />
                        </div>
                    </div>
                </LoadingStateContainer>
            </Card>
        )
    }
}
