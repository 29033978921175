import { createValidator, required } from 'libs/validation'
import { ProcedCirurgicoPrevioPrimValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/primaria/ProcedCirurgicoPrevioPrimValidator'
import { ProcedCirurgicoAtualPrimValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/primaria/ProcedCirurgicoAtualPrimValidator'
import { TipoArtroplastiaValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/primaria/TipoArtroplastiaValidator'
import { ImplantesValidator } from 'view/home/novoProcedimento/ficha/quadril/validators/primaria/ImplantesValidator'
import { ComponentesValidator } from 'view/home/novoProcedimento/ficha/joelho/validators/ComponentesValidator'
import { PrimariaQuadrilForm } from 'backend'
import immutableProxy, { ImmutableRootProxy } from 'libs/immutableProxy'

const FichaPrimariaQuadrilValidator = createValidator(
    {
        motivoArtroplastia: required,
        procedimentoCirurgicoPrevio: ProcedCirurgicoPrevioPrimValidator,
        procedimentoCirurgicoAtual: ProcedCirurgicoAtualPrimValidator,
        tipoArtroplastiaForm: TipoArtroplastiaValidator,
        implantes: ImplantesValidator,
        componentesUtilizadosForm: ComponentesValidator,
    },
    formValidator
)

const validaAcetabular = (form: ImmutableRootProxy<PrimariaQuadrilForm>, errorsValidator) => {
    const tipoArtroplastia = form.tipoArtroplastiaForm
    const implantes = form.implantes
    if (implantes.acetabular.get()) {
        const parTribologico = tipoArtroplastia.parTribologico.get()
        const ceramica = parTribologico && parTribologico.id === 'CERAMICA_CERAMICA'
        if (implantes.acetabularCimentado.get() === 'NAO' && !ceramica) {
            errorsValidator.implantes = !errorsValidator.implantes ? {} : errorsValidator.implantes
            errorsValidator.implantes.parafusos = required(implantes.parafusos.get())
        }
    }
}

const validaCimentoOsseo = (form: ImmutableRootProxy<PrimariaQuadrilForm>, errors) => {
    const implantes = form.implantes
    if (implantes.femoralCimentado.get() === 'SIM' || implantes.acetabularCimentado.get() === 'SIM') {
        errors.cimentoOsseo = {}
        const cimento = form.cimentoOsseo
        errors.cimentoOsseo.tipoCimentoOsseo = required(cimento.tipoCimentoOsseo.get())
        errors.cimentoOsseo.tecnicaCimentacao = required(cimento.tecnicaCimentacao.get())
        errors.cimentoOsseo.lavagemPulsatil = required(cimento.lavagemPulsatil.get())
    }
}

function formValidator(formMap, errorsValidator) {
    const errors: any = {}
    const form = immutableProxy<PrimariaQuadrilForm>(formMap)
    const implantes = form.implantes

    if (implantes) {
        validaCimentoOsseo(form, errors)

        validaAcetabular(form, errorsValidator)

        errors.enxertia = {}
        const enxertia = form.enxertia
        if (enxertia) {
            if (implantes.femoral.get() && enxertia.enxertiaFemoral.get()) {
                errors.enxertia.tipoEnxertiaFemoral = required(enxertia.tipoEnxertiaFemoral.get())
            }
            if (implantes.acetabular.get() && enxertia.enxertiaAcetabular.get()) {
                errors.enxertia.tipoEnxertiaAcetabular = required(enxertia.tipoEnxertiaAcetabular.get())
            }
        }
    }

    return errors
}

export default FichaPrimariaQuadrilValidator

export const exportFunctions = {
    formValidator,
    validaCimentoOsseo,
    validaAcetabular,
}
