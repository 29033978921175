import * as React from 'react'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import TipoRevisaoView from 'view/componentes/reports/revisao/TipoRevisaoView'

export const AnvisaTipoRevisaoView = () => {
    return (
        <>
            <LocalHeaderContainer />
            <TipoRevisaoView />
        </>
    )
}
