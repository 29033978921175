import React, { MutableRefObject } from 'react'
import { useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { hasSubmitSucceeded, isPristine } from 'redux-form/immutable'

interface FichaPromptLeaveProps {
    formName: string
    showModalLeave: MutableRefObject<boolean>
}

const FichaPromptLeave: React.FC<FichaPromptLeaveProps> = ({ formName, showModalLeave }) => {
    const submitSucceeded = useSelector(state => hasSubmitSucceeded(formName)(state))
    const pristine = useSelector(state => isPristine(formName)(state))

    const shouldAllowNavigation = React.useCallback(() => {
        if (showModalLeave.current && !pristine && !submitSucceeded) {
            return 'mensagem para fazer react-router apresentar prompt'
        }
        return true
    }, [pristine, showModalLeave, submitSucceeded])

    return <Prompt message={shouldAllowNavigation} />
}

export default FichaPromptLeave
