import * as React from 'react'
import { RelatorioAnvisaCard } from './components/RelatorioAnvisaCard'
import { Columns, Linha } from 'libs/components'
import Text from 'libs/components/presentational/typography/Text'
import { QuantidadeProcedimentosDto } from 'backend'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { RouteComponentProps } from 'react-router-dom'
import HeaderAnvisa from './components/HeaderAnvisa'

export interface HomeAnvisaProps extends RouteComponentProps<any> {
    hasAlertasNaoLidos: boolean
    procedimentos: PromiseRecord<QuantidadeProcedimentosDto>
    loadQtd: () => void
    refreshAlertasNaoLidos: () => void
    goStents: () => void
    goAntibioticos: () => void
    goBuscaProcedimentos: () => void
    goAlarmes: () => void
    goEntidades: () => void
}

export class HomeAnvisaView extends React.Component<HomeAnvisaProps> {
    componentDidMount() {
        this.props.loadQtd()
        this.props.refreshAlertasNaoLidos()
    }

    render() {
        const procedimentos = this.props.procedimentos && this.props.procedimentos.data
        return (
            <div className='is-vertical-flow is-full-height'>
                <HeaderAnvisa
                    hasAlertasNaoLidos={this.props.hasAlertasNaoLidos}
                    goStents={this.props.goStents}
                    goAntibioticos={this.props.goAntibioticos}
                    goBuscaProcedimentos={this.props.goBuscaProcedimentos}
                    goAlarmes={this.props.goAlarmes}
                    goEntidades={this.props.goEntidades}
                />

                <div className='has-text-centered report-content'>
                    <Text size='x-large' color='black-bis' className='margin-bottom-40'>
                        Relatórios
                    </Text>

                    <Columns auto className='margin-top-24'>
                        <RelatorioAnvisaCard
                            dataName='button-acesso-artroplastias'
                            titulo='Distribuição de artroplastias'
                            destino='/home/relatorios/artroplastia/dashboard'
                            procedimentos={procedimentos && procedimentos.qtdArtroplastias}
                            isBig
                            icon='distArtro'
                        />
                        <RelatorioAnvisaCard
                            dataName='button-acesso-angioplastias'
                            titulo='Distribuição de angioplastias'
                            destino='/home/relatorios/angioplastia/dashboard'
                            procedimentos={procedimentos && procedimentos.qtdAngioplastias}
                            isBig
                            icon='distCardio'
                        />
                    </Columns>
                    <Linha />
                    <Columns auto>
                        <RelatorioAnvisaCard
                            dataName='button-acesso-motivos'
                            titulo='Motivos de procedimentos'
                            destino='/home/relatorios/motivos'
                            icon='motivoProcedimento'
                        />
                        <RelatorioAnvisaCard
                            dataName='button-acesso-rastreabilidade'
                            titulo='Rastreabilidade de produtos'
                            destino='/home/relatorios/rastreabilidade'
                            icon='rastProdutos'
                        />
                        <RelatorioAnvisaCard
                            dataName='button-acesso-procedimentos'
                            titulo='Múltiplos procedimentos'
                            destino='/home/relatorios/multiplos/JOELHO'
                            icon='multProcedimentos'
                        />
                    </Columns>
                    <Columns auto>
                        <RelatorioAnvisaCard
                            dataName='button-acesso-distribuicaoProdutos'
                            titulo='Distribuição de produtos'
                            destino='/home/relatorios/produtos'
                            icon='distProdutos'
                        />
                        <RelatorioAnvisaCard
                            dataName='button-acesso-revisao'
                            titulo='Tipos de revisão'
                            destino='/home/relatorios/tipoRevisao'
                            icon='tiposRevisao'
                        />
                        <RelatorioAnvisaCard
                            dataName='button-acesso-informacoesProduto'
                            titulo='Informações do produto'
                            destino='/home/relatorios/info-produto'
                            icon='infoProduto'
                        />
                    </Columns>
                    <Columns auto>
                        <RelatorioAnvisaCard
                            dataName='button-acesso-dadosFinanceiros'
                            titulo='Dados financeiros'
                            destino='/home/relatorios/financeiro'
                            icon='financeiro'
                        />
                        <RelatorioAnvisaCard
                            dataName='button-acesso-uso-sistema'
                            titulo='Uso do sistema'
                            destino='/home/relatorios/usoSistema'
                            icon='usoSistema'
                        />
                        <RelatorioAnvisaCard
                            titulo='Relação médico-empresa'
                            destino='/home/relatorios/relacaoMedicoEmpresa'
                            icon='reportRelacaoEmpresas'
                        />
                     </Columns>
                     <Columns auto>
                        <RelatorioAnvisaCard
                            titulo='Lista de Pacientes'
                            destino='/home/relatorios/listaPacientes'
                            icon='listaPacientes'
                        />
                        <div />
                        {/* Hue*/}
                        <div />
                    </Columns>
                </div>
            </div>
        )
    }
}
