import * as React from 'react'
import * as C from 'libs/components'
import { CadastroPacienteForm } from 'backend'
import * as DateUtil from 'libs/util/DateUtil'
import iconPaciente from 'images/icons/identificacao.svg'
import iconEndereco from 'images/icons/endereco.svg'
import iconContato from 'images/icons/contato.svg'
import { capitalizeName } from 'libs/util/StringUtil'

interface ModalPacienteProps {
    active: boolean
    paciente: CadastroPacienteForm
    close: () => void
}

export class ModalInformacoesPaciente extends React.Component<ModalPacienteProps> {
    render() {
        const paciente = this.props.paciente

        return (
            <C.Modal active={this.props.active}>
                <div className='modal-card'>
                    <header className='modal-card-head'>
                        <div data-name='pacienteModal' className='modal-card-title'>
                            <C.Columns>
                                <C.Column size='8'>
                                    <C.Label className='paciente-name'>{paciente.nome} </C.Label>
                                </C.Column>
                                <C.Column size='2'>
                                    <C.ComposedLabel title='Idade'>
                                        <C.Label className='is-black-bis'>
                                            {DateUtil.getIdadePorExtenso(paciente.dataNascimento)}{' '}
                                        </C.Label>
                                    </C.ComposedLabel>
                                </C.Column>
                                <C.Column size='2'>
                                    <C.ComposedLabel title='Sexo'>
                                        <C.Label className='is-black-bis'>{capitalizeName(paciente.sexo)} </C.Label>
                                    </C.ComposedLabel>
                                </C.Column>
                            </C.Columns>
                        </div>
                        <C.Button pulledRight className='delete' onClick={this.props.close}></C.Button>
                    </header>
                    <section className='modal-card-body'>
                        <div className='modal-card-body-content'>
                            <C.Columns>
                                <C.Column verticalFlow size='1'>
                                    <C.Image src={iconPaciente} alignedCenter className='ver-cadastro-modal-icon' />
                                </C.Column>
                                <C.Column>
                                    <C.Label className='sumario-title'> Identificação do paciente</C.Label>
                                    <C.Columns>
                                        <C.Column>
                                            <C.MaskedLabel title='CPF:' value={paciente.cpf} mask='###.###.###-##' />
                                        </C.Column>
                                        <C.Column>
                                            <C.MaskedLabel
                                                title='CNS:'
                                                value={paciente.cns}
                                                mask='###.####.####.####'
                                            />
                                        </C.Column>
                                        <C.Column>
                                            <C.TextLabel
                                                title='Data de nascimento:'
                                                value={DateUtil.asClientFormat(paciente.dataNascimento)}
                                            />
                                        </C.Column>
                                    </C.Columns>
                                    <C.TextLabel title='Nome da mãe:' value={paciente.nomeMae} />
                                </C.Column>
                            </C.Columns>

                            <C.Linha />
                            <C.Columns>
                                <C.Column verticalFlow size='1'>
                                    <C.Image src={iconEndereco} alignedCenter className='ver-cadastro-modal-icon' />
                                </C.Column>
                                <C.Column>
                                    <C.Label className='sumario-title'>Endereço do paciente</C.Label>
                                    <C.Columns>
                                        <C.Column>
                                            <C.MaskedLabel
                                                title='CEP:'
                                                value={paciente.enderecoForm.cep}
                                                mask='#####-###'
                                            />
                                        </C.Column>
                                    </C.Columns>
                                    <C.Columns>
                                        <C.Column size='1'>
                                            <C.TextLabel
                                                title='UF:'
                                                value={paciente.enderecoForm.uf && paciente.enderecoForm.uf.nome}
                                            />
                                        </C.Column>
                                        <C.Column size='7'>
                                            <C.TextLabel
                                                title='Cidade:'
                                                value={
                                                    paciente.enderecoForm.cidade && paciente.enderecoForm.cidade.nome
                                                }
                                            />
                                        </C.Column>
                                        <C.Column>
                                            <C.TextLabel title='Bairro:' value={paciente.enderecoForm.bairro} />
                                        </C.Column>
                                    </C.Columns>
                                    <C.Columns>
                                        <C.Column size='8'>
                                            <C.TextLabel
                                                title='Logradouro:'
                                                value={paciente.enderecoForm.logradouroNome}
                                            />
                                        </C.Column>
                                        <C.Column>
                                            <C.TextLabel title='Número:' value={paciente.enderecoForm.numero} />
                                        </C.Column>
                                    </C.Columns>
                                    <C.TextLabel
                                        title='Complemento:'
                                        value={paciente.enderecoForm.complemento}
                                        placeholder='Sem complementos'
                                    />
                                </C.Column>
                            </C.Columns>

                            <C.Linha />
                            <C.Columns>
                                <C.Column verticalFlow size='1'>
                                    <C.Image src={iconContato} alignedCenter className='ver-cadastro-modal-icon' />
                                </C.Column>
                                <C.Column>
                                    <C.Label className='sumario-title'>Contato do paciente</C.Label>

                                    <C.Columns>
                                        <C.Column size='4'>
                                            <C.MaskedLabel
                                                title='Telefone:'
                                                value={paciente.contatoForm.fone1}
                                                mask={
                                                    paciente.contatoForm.fone1.length === 10
                                                        ? '(##) ####-####'
                                                        : '(##) #####-####'
                                                }
                                            />
                                        </C.Column>
                                        <C.Column size='4'>
                                            {paciente.contatoForm.fone2 && (
                                                <C.MaskedLabel
                                                    title='Telefone 2:'
                                                    value={paciente.contatoForm.fone2}
                                                    mask={
                                                        paciente.contatoForm.fone2.length === 10
                                                            ? '(##) ####-####'
                                                            : '(##) #####-####'
                                                    }
                                                />
                                            )}
                                        </C.Column>
                                    </C.Columns>
                                    <C.TextLabel title='E-mail:' value={paciente.contatoForm.email} />
                                </C.Column>
                            </C.Columns>
                        </div>
                    </section>
                </div>
            </C.Modal>
        )
    }
}
