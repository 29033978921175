/* tslint:disable */
import React from 'react'

const SvgGerar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 21 21' {...props}>
        <path d='M15.855 10.471c-.34 0-.484.148-.55.445l-.058.332c-.457 2.398-2.336 4.074-5.05 4.075-1.671 0-3.033-.62-3.955-1.723l-.413-.492 1.976-.001a.509.509 0 000-1.018H4.51a.51.51 0 00-.509.509v3.293a.51.51 0 001.018 0l-.001-2.268.526.603a6.173 6.173 0 0010.776-3.29c.032-.264-.097-.465-.465-.465zM15.893 4a.51.51 0 00-.51.509l.002 2.326-.53-.631a6.171 6.171 0 00-10.85 3.202c-.033.265.096.465.465.465.378 0 .513-.182.569-.55.378-2.518 2.29-4.3 5.09-4.302 1.702 0 3.083.645 4.005 1.785l.395.49h-1.93a.51.51 0 100 1.017h3.294c.28 0 .508-.228.508-.509V4.509A.51.51 0 0015.893 4z' />
    </svg>
)

export default SvgGerar
