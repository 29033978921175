import * as React from 'react'
import * as C from 'libs/components'

interface RespiratoriaProps {
    disabled?: boolean
}

export class RespiratoriaCard extends React.Component<RespiratoriaProps> {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Respiratória'
                name='respiratoria'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='respiratoria' disabled={this.props.disabled} />}
            >
                <C.Conteudo>
                    <C.ControlField label={'Tipo'} name='respiratoriaTipoComplicacao' />
                    <C.Columns>
                        <C.Column size='5' verticalFlow>
                            <C.ChecklistItemField
                                name='respiratoriaTipoComplicacao'
                                optionValue='EDEMA_AGUDO_PULMAO'
                                label='Edema agudo de pulmão'
                            />
                            <C.ChecklistItemField
                                name='respiratoriaTipoComplicacao'
                                optionValue='INICIO_VENTILACAO_MECANICA'
                                label='Início ventilação mecânica'
                            />
                        </C.Column>
                    </C.Columns>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
