import * as React from 'react'
import { scroller } from 'react-scroll'

export class SideNav extends React.Component<any, any> {
    render() {
        return (
            <div>
                <nav className='side-nav'>
                    <ul>{this.props.children}</ul>
                </nav>
            </div>
        )
    }
}

export interface SideNavItemProps {
    anchor: string
    className?: string
}

export class SideNavItem extends React.Component<SideNavItemProps> {
    constructor(props) {
        super(props)
        this.scrollTo = this.scrollTo.bind(this)
    }

    scrollTo(anchor: string) {
        scroller.scrollTo(anchor, {
            duration: 300,
            smooth: true,
            offset: -20,
        })
    }

    render() {
        return (
            <li className={this.props.className}>
                <a onClick={() => this.scrollTo(this.props.anchor)} {...this.props}>
                    {this.props.children}
                </a>
            </li>
        )
    }
}

export class SideNavTitleItem extends React.Component<any, any> {
    render() {
        return <li className='title'>{this.props.children}</li>
    }
}
