import { connect } from 'react-redux'
import { SolicitacaoVinculoView } from 'view/perfis/SolicitacaoVinculoView'
import SolicitacaoAcesso from 'api/SolicitacaoAcesso'
import * as auth from 'redux/modules/auth'

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onCancel: () => ownProps.history.push('/vinculos'),
        onSubmit: form => SolicitacaoAcesso.solicitarVinculo(form.toJS()),
        onSubmitSuccess: () => {
            dispatch(auth.findPerfis())
            ownProps.history.push('/vinculos')
        },
    }
}

const SolicitacaoVinculoContainer = connect(null, mapDispatchToProps)(SolicitacaoVinculoView)

export default SolicitacaoVinculoContainer
