import { Column, Columns, FloatingContainer, ScrollToTop } from 'libs/components'
import React from 'react'

export interface FormViewProps {
    sidebarContent: any
    mainContent: any
}

export const FormView = (props: FormViewProps) => {
    return (
        <Columns fullHeight>
            <ScrollToTop />
            <Column verticalFlow size='4'>
                <div className='procedimento-sidebar is-full-height'>
                    <FloatingContainer stopElement='footer' stopOffset={88}>
                        {props.sidebarContent}
                    </FloatingContainer>
                </div>
            </Column>
            <Column className='procedimento-conteudo'>{props.mainContent}</Column>
        </Columns>
    )
}
