import { Columns, Text, Linha } from 'libs/components'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'redux/modules'
import { loadQtdProcedimentos, ProcedimentoReportState, clearQtdProcedimentos } from 'redux/modules/report/procedimento'
import { RelatorioAnvisaCard } from '../anvisa/components/RelatorioAnvisaCard'
import { HomeGestorServicoSaudeHeader } from './HomeGestorServicoSaudeHeader'

export const HomeGestorServicoSaudeView = () => {
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(loadQtdProcedimentos())
        return () => dispatch(clearQtdProcedimentos())
    }, [dispatch])

    const procedimentos: ProcedimentoReportState = useSelector<State, ProcedimentoReportState>(
        state => state.report.procedimento
    )

    return (
        <div className='is-vertical-flow is-full-height'>
            <HomeGestorServicoSaudeHeader />
            <div className='has-text-centered gestor-servico-saude--report-content'>
                <Text size='x-large' color='black-bis'>
                    Relatórios
                </Text>
                <Columns auto className='margin-top-24'>
                    <RelatorioAnvisaCard
                        titulo='Distribuição de artroplastias'
                        destino='/home/relatorios/artroplastia'
                        procedimentos={procedimentos?.qtdProcedimentos.data?.qtdArtroplastias}
                        isBig
                        icon='distArtro'
                    />
                    <RelatorioAnvisaCard
                        titulo='Distribuição de angioplastias'
                        destino='/home/relatorios/angioplastia'
                        procedimentos={procedimentos?.qtdProcedimentos.data?.qtdAngioplastias}
                        isBig
                        icon='distCardio'
                    />
                </Columns>
                <Linha />
                <Columns auto>
                    <RelatorioAnvisaCard
                        titulo='Motivos de procedimentos'
                        destino='/home/relatorios/motivos'
                        icon='motivoProcedimento'
                    />
                    <RelatorioAnvisaCard
                        titulo='Rastreabilidade de produtos'
                        destino='/home/relatorios/rastreabilidade'
                        icon='rastProdutos'
                    />
                    <RelatorioAnvisaCard
                        titulo='Tipos de revisão'
                        destino='/home/relatorios/tipoRevisao'
                        icon='tiposRevisao'
                    />
                </Columns>
                <Columns auto>
                    <RelatorioAnvisaCard
                        dataName='button-acesso-uso-sistema'
                        titulo='Uso do sistema'
                        destino='/home/relatorios/usoSistema'
                        icon='usoSistema'
                    />
                    <RelatorioAnvisaCard
                    	titulo='Lista de Pacientes'
                        destino='/home/relatorios/listaPacientes'
                        icon='listaPacientes'
                    />
                    <div />
                    {/* Hue*/}
                    <div />
                </Columns>
            </div>
        </div>
    )
}
