/* tslint:disable */
import React from 'react'

const SvgIdentificacao = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
        <path d='M10.543 5.478C10.543 6.852 9.4 7.957 8 7.957c-1.4 0-2.543-1.105-2.543-2.479S6.6 3 8 3c1.4 0 2.543 1.104 2.543 2.478zm-1.173 0c0-.714-.609-1.301-1.37-1.301-.761 0-1.37.587-1.37 1.301S7.24 6.78 8 6.78c.761 0 1.37-.588 1.37-1.302zM11.913 13H4.087a.588.588 0 01-.587-.588c0-2.07 1.727-3.739 3.848-3.739h1.304c2.12 0 3.848 1.67 3.848 3.739a.588.588 0 01-.587.588zm-3.26-3.15H7.347c-1.27 0-2.326.845-2.603 1.973h6.51C10.977 10.695 9.92 9.85 8.651 9.85z' />
    </svg>
)

export default SvgIdentificacao
