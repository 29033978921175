import { tiposProcedimentoDescricaoByEnum } from 'api/Procedimento'
import { SumarioProcedimentoDto } from 'backend'
import * as C from 'libs/components'
import { Column, Columns } from 'libs/components'
import Text from 'libs/components/presentational/typography/Text'
import * as DateUtil from 'libs/util/DateUtil'
import * as React from 'react'
import { tipoProcedimentoReportColor, statusProcedimentoByEnum } from 'redux/enums/index'
import BuscaRow from '../components/BuscaRow'
import { DescricaoPerfilCaptalize } from 'api/Perfis'

interface Props {
    procedimento: SumarioProcedimentoDto
    onClick: () => void
}

export class BuscaPacienteSumarioProcedimentoCard extends React.Component<Props> {
    render() {
        const proced = this.props.procedimento
        const hasLado = proced.tipoProcedimento !== 'STENT_CORONARIA'
        const titulo =
            tiposProcedimentoDescricaoByEnum[proced.tipoProcedimento] + (hasLado ? ' ' + proced.lado.toLowerCase() : '')
        const status = statusProcedimentoByEnum[proced.statusProcedimento]
        const hospital = proced.hospital + ' - ' + proced.cidade + '/' + proced.uf
        return (
            <BuscaRow className='dark-row'>
                <Columns>
                    <Column className='flex-0 pad-0-right'>
                        <C.CircleProgress
                            type='primary'
                            size={10}
                            progress={{ [tipoProcedimentoReportColor[proced.tipoProcedimento]]: 1 }}
                        />
                    </Column>
                    <Column className='pad-0-left'>
                        <div style={{}}>
                            <Text size='small-ter' color='black-bis'>
                                {titulo}
                            </Text>
                            <Text size='x-small' color='black-bis'>
                                {' / ' + status}
                            </Text>
                        </div>
                        <div style={{}}>
                            <Text weight='semibold' size='x-small' color='black-bis'>
                                {hospital}
                            </Text>
                        </div>
                        <div>
                            <Text weight='semibold' size='x-small' color='black-bis'>
                                Médico {DescricaoPerfilCaptalize[proced.medicoResponsavel.perfil]} responsável{' '}
                            </Text>
                            <Text size='x-small' color='black-bis'>
                                {proced.medicoResponsavel.nomeMedico}{' '}
                            </Text>
                            {proced.equipeMedica?.map((item, index) => {
                                return (
                                    <span key={index}>
                                        <Text weight='semibold' size='x-small' color='black-bis'>
                                            / {DescricaoPerfilCaptalize[item.perfil]}{' '}
                                        </Text>
                                        <Text size='x-small' color='black-bis'>
                                            {item.nomeMedico}{' '}
                                        </Text>
                                    </span>
                                )
                            })}
                        </div>
                    </Column>
                    <Column size='1'>
                        <div className='data is-bold'>{DateUtil.asClientFormat(proced.dataCirurgia)}</div>
                    </Column>
                    <Column size='2'>
                        <C.Hint
                            placement='top'
                            componente={
                                <C.IconButton
                                    data-name='botao_abrir'
                                    className='arrow'
                                    icon='arrowRight'
                                    size='is-x-small'
                                    pulledRight
                                    onClick={this.props.onClick}
                                />
                            }
                        >
                            Visualizar registro
                        </C.Hint>
                    </Column>
                </Columns>
            </BuscaRow>
        )
    }
}
