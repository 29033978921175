/* tslint:disable */
import React from 'react'

const SvgFinanceiro = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
        <path d='M17.024 24.533v1.098c0 1.826-2.35 1.824-2.35 0v-1.129C11.668 24.132 9 22.48 9 19.602c0-1.808 3.185-1.804 3.185 0 0 1.136 1.113 1.838 2.49 2.106V17.29a6.503 6.503 0 01-.529-.078c-2.961-.59-5.666-2.775-5.031-5.648.528-2.387 2.912-3.728 5.56-4.062V6.37c0-1.828 2.35-1.825 2.35 0v1.095C20.15 7.76 23 9.425 23 12.397c0 1.809-3.185 1.805-3.185 0 0-1.217-1.28-1.936-2.79-2.157v4.44c.307.029.588.062.826.11 2.962.588 5.667 2.774 5.034 5.647-.548 2.477-3.095 3.827-5.86 4.096zm0-2.773c1.512-.22 2.791-.94 2.791-2.157 0-1.429-1.335-1.889-2.79-2.091v4.248zm-2.35-11.468c-1.376.268-2.49.97-2.49 2.105 0 1.33 1.157 1.82 2.49 2.045v-4.15z' />
    </svg>
)

export default SvgFinanceiro
