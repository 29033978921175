import React from 'react'
import Dropzone from 'react-dropzone'
import { WrappedFieldInputProps } from 'redux-form'
import * as C from 'libs/components'
import axios, { CancelTokenSource } from 'axios'
import { formatBytes } from 'libs/util/ByteUtil'
import { isImmutable } from 'immutable'

export interface ImageUploaderProps extends Partial<WrappedFieldInputProps> {
    text?: string
    multiple?: boolean
    accept?: string
    maxSize?: number
    onRemove?: any
    alertError?(mensagem: string): void
}

interface ImageUploaderState {
    modalOpened: boolean
}

export class ImageUploader extends React.Component<ImageUploaderProps, ImageUploaderState> {
    private cancelTokenSource: CancelTokenSource
    private dropzone: any

    static defaultProps: ImageUploaderProps = {
        multiple: false,
        maxSize: 5242880,
        accept: 'image/png, image/jpeg',
    }

    constructor(props) {
        super(props)

        this.state = {
            modalOpened: false,
        }

        this.isImage = this.isImage.bind(this)
        this.onDrop = this.onDrop.bind(this)
        this.uploadImage = this.uploadImage.bind(this)
        this.removeImage = this.removeImage.bind(this)
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    uploadImage(file) {
        const data = new FormData()
        data.append('file', file)
        this.props.onChange({ rawFile: file, uploading: true, nome: file.name, tamanho: file.size } as any)

        this.cancelTokenSource = axios.CancelToken.source()

        axios
            .post('/api/arquivo/imagem', data, { cancelToken: this.cancelTokenSource.token })
            .then(result => {
                this.props.onChange({ ...result.data, tamanho: this.props.value.tamanho })
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    this.props.onChange(null)
                } else {
                    this.props.onChange({ ...this.props.value, uploading: false, erro: true })
                }
            })
    }

    isImage(file) {
        return new Promise((resolve, reject) => {
            const blob = file.slice(0, 4)
            const filereader = new FileReader()
            filereader.onloadend = () => {
                if (filereader.readyState === FileReader.DONE) {
                    const uint = new Uint8Array(filereader.result as ArrayBuffer)
                    const hex = uint
                        .reduce((acc, curr) => {
                            acc.push(curr.toString(16))
                            return acc
                        }, [])
                        .join('')
                        .toUpperCase()
                    if (validTypes.includes(hex)) {
                        resolve(file)
                    } else {
                        reject()
                    }
                }
            }
            filereader.readAsArrayBuffer(blob)
        })
    }

    onDrop(acceptedFiles, rejectedFiles?) {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const selectedFile = acceptedFiles[0]
            this.isImage(selectedFile)
                .then(res => this.uploadImage(res))
                .catch(
                    () =>
                        this.props.alertError &&
                        this.props.alertError('A foto enviada possui formato e/ou tamanho inválido.')
                )
        } else if (rejectedFiles && rejectedFiles.length > 0 && this.props.alertError) {
            this.props.alertError('A foto enviada possui formato e/ou tamanho inválido.')
        }
    }

    removeImage() {
        if (this.props.value.id) {
            axios.post('/api/arquivo/imagem/delete/' + this.props.value.id).then(() => {
                this.props.onChange(null)
            })
        } else {
            this.props.onChange(null)
        }
    }

    openModal() {
        this.setState({ modalOpened: true })
    }

    closeModal() {
        this.setState({ modalOpened: false })
    }

    render() {
        let value = isImmutable(this.props.value) ? this.props.value.toJS() : this.props.value

        return value ? (
            <div className='image-dropzone'>
                <div className='imagem-upload'>
                    <Dropzone
                        accept={this.props.accept}
                        maxSize={this.props.maxSize}
                        multiple={this.props.multiple}
                        onDrop={this.onDrop}
                        ref={dropzone => (this.dropzone = dropzone)}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className='dropzone-upload' {...getRootProps()}>
                                <input {...getInputProps()} />
                            </div>
                        )}
                    </Dropzone>
                    {!value.uploading && !value.erro && (
                        <span className='image-placeholder sucesso rnicons rnicons-vermais' onClick={this.openModal}>
                            <img src={'/api/arquivo/thumbnail/' + value.id} alt='' />
                        </span>
                    )}
                    {!value.uploading && value.erro && (
                        <span className='image-placeholder erro'>
                            <span className='placeholder-row'>
                                <C.Icon icon='invalido' />
                            </span>
                        </span>
                    )}
                    {value.uploading && (
                        <span className='image-placeholder'>
                            <span className='placeholder-row'>
                                <C.LoadingAnimation />
                            </span>
                        </span>
                    )}
                    <span className='file-info'>
                        <span className='nome-imagem'>{value.nome}</span>
                        {value.tamanho && <span className='tamanho-imagem'>({formatBytes(value.tamanho)})</span>}
                        {value.uploading && <span className='fazendo-upload'>Fazendo o upload da imagem...</span>}
                        {!value.uploading && !value.erro && (
                            <span className='upload-finalizado'>Upload finalizado</span>
                        )}
                        {!value.uploading && value.erro && (
                            <span className='upload-erro'>Erro no upload, tente novamente</span>
                        )}
                    </span>

                    {!value.uploading &&
                        !value.erro && [
                            <C.Hint
                                key='0'
                                placement='top'
                                componente={
                                    <C.IconButton
                                        key='1'
                                        name='trocar'
                                        icon='trocar'
                                        onClick={() => this.dropzone.open()}
                                    />
                                }
                            >
                                Alterar imagem
                            </C.Hint>,
                            <C.Hint
                                key='2'
                                placement='top'
                                componente={
                                    <C.IconButton key='3' name='remover' icon='remover' onClick={this.removeImage} />
                                }
                            >
                                Remover imagem
                            </C.Hint>,
                        ]}
                    {!value.uploading &&
                        value.erro && [
                            <C.Hint
                                key='0'
                                placement='top'
                                componente={
                                    <C.IconButton
                                        key='1'
                                        name='trocar'
                                        icon='retornar'
                                        onClick={() => this.onDrop([value.rawFile])}
                                    />
                                }
                            >
                                Tentar novamente
                            </C.Hint>,
                            <C.Hint
                                key='2'
                                placement='top'
                                componente={
                                    <C.IconButton key='3' name='remover' icon='remover' onClick={this.removeImage} />
                                }
                            >
                                Remover imagem
                            </C.Hint>,
                        ]}
                    {value.uploading && (
                        <C.Hint
                            key='2'
                            placement='top'
                            componente={
                                <C.IconButton key='2' icon='excluir' onClick={() => this.cancelTokenSource.cancel()} />
                            }
                        >
                            Cancelar upload
                        </C.Hint>
                    )}
                    <C.ModalCard
                        title='Imagem etiqueta'
                        active={this.state.modalOpened}
                        onClose={this.closeModal}
                        hasCloseButton
                    >
                        <div className='modal-etiqueta'>
                            <C.LazyImage src={'/api/arquivo/imagem/' + value.id} />
                        </div>
                    </C.ModalCard>
                </div>
            </div>
        ) : (
            <Dropzone
                maxSize={this.props.maxSize}
                accept={this.props.accept}
                onDrop={this.onDrop}
                multiple={this.props.multiple}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className='image-uploader' {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className='is-vertical-flow body-empty justify-center'>
                            <C.Icon className='ic-upload' icon='upload' />
                            <p>{this.props.text}</p>
                        </div>
                    </div>
                )}
            </Dropzone>
        )
    }
}

export const validTypes = ['FFD8FFDB', 'FFD8FFE0', 'FFD8FFE1', '89504E47', 'FFD8FFE2']
