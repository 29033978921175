/* tslint:disable */
import React from 'react'

const SvgModalErro = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 128 128' {...props}>
        <path d='M63.09 64.504L39.104 40.518a1 1 0 011.414-1.414L64.504 63.09 88.49 39.104a1 1 0 011.414 1.414L65.918 64.504 89.904 88.49a1 1 0 01-1.414 1.414L64.504 65.918 40.518 89.904a1 1 0 01-1.414-1.414L63.09 64.504zM64 0c35.29 0 64 28.709 64 64s-28.71 64-64 64S0 99.291 0 64 28.71 0 64 0zm0 1.707C29.652 1.707 1.707 29.652 1.707 64S29.652 126.293 64 126.293 126.293 98.348 126.293 64 98.348 1.707 64 1.707z' />
    </svg>
)

export default SvgModalErro
