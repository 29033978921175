import React from 'react'
import * as C from 'libs/components'

export class PeriodoValidade extends React.Component {
    render() {
        return (
            <C.Columns className='validade-periodo'>
                <C.Column size='3'>
                    <C.SimpleCountField name='periodoValidade' qtdMax={10} />
                </C.Column>
                <C.Column>
                    <label>Ano(s) a partir da data de fabricação.</label>
                </C.Column>
            </C.Columns>
        )
    }
}
