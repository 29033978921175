import { DescricaoCompletaPerfil, ImagemPerfil, PerfilEnum, PerfisMedico } from 'api/Perfis'
import {
    CidadeField,
    Column,
    Columns,
    ControlField,
    Hint,
    InstituicaoField,
    Image,
    NumberField,
    StyledRadioField,
    UfField,
} from 'libs/components'
import React from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { FormSection, formValueSelector } from 'redux-form/immutable'
import { getPerfisAtivos, getLoggedUser } from 'redux/selectors/user'

const PerfilButton = props => (
    <StyledRadioField
        name='perfil'
        skin='square-button'
        className='perfil'
        value={props.perfil}
        disabled={props.disabled}
    >
        <Image className='imagem-perfil' src={ImagemPerfil[props.perfil]} size='20x20' />
        <span className='perfil-descricao'>{DescricaoCompletaPerfil[props.perfil]}</span>
    </StyledRadioField>
)

class SolicitacaoVinculoFormSection extends React.Component {
    cidadeRef
    instituicaoRef

    onChangeUf = () => {
        this.cidadeRef.blur()
        this.instituicaoRef.blur()
        this.props.handleUFInstituicao()
    }

    onChangeMunicipio = () => {
        this.instituicaoRef.blur()
        this.props.handleMunInstituicao()
    }

    isDisabled = perfil => {
        if (
            !this.props.instituicao ||
            this.props.instituicao.tipoCategoria === 'ERRO' ||
            this.exibirMensagemErroAdicionarVinculoPerfilDiferenteSNVS() ||
            this.exibirMensagemErroAdicionarVinculoPerfilSNVS()
        ) {
            return true
        }
        if (this.props.instituicao && perfil === PerfilEnum.SNVS) {
            return !(this.props.instituicao.tipoCategoria === 'VISA')
        } else if (this.props.instituicao && perfil !== PerfilEnum.SNVS) {
            return this.props.instituicao.tipoCategoria === 'VISA'
        }
        return true
    }

    exibirMensagemErro = () => {
        if (this.props.instituicao && this.props.instituicao.tipoCategoria === 'ERRO') {
            return true
        } else {
            return false
        }
    }

    exibirMensagemErroAdicionarVinculoPerfilDiferenteSNVS = () => {
        if (
            this.props.instituicao &&
            !this.temPerfilSNVS() &&
            this.isLogado() &&
            this.props.instituicao.tipoCategoria === 'VISA'
        ) {
            return true
        } else {
            return false
        }
    }

    exibirMensagemErroAdicionarVinculoPerfilSNVS = () => {
        if (
            this.props.instituicao &&
            this.temPerfilSNVS() &&
            this.isLogado() &&
            this.props.instituicao.tipoCategoria !== 'VISA'
        ) {
            return true
        } else {
            return false
        }
    }

    temPerfilSNVS = () => {
        if (this.props.perfisUser) {
            return this.props.perfisUser.some(p => p.perfil === 'SNVS')
        }
        return false
    }

    isLogado = () => {
        if (this.props.loggedUser) {
            return true
        } else {
            return false
        }
    }

    render() {
        return (
            <FormSection name={this.props.name} className='solicitacao-vinculo'>
                <Columns>
                    <Column size='2'>
                        <UfField label='UF' required name='ufInstituicao' placeholder='UF' onChange={this.onChangeUf} />
                    </Column>
                    <Column size='6'>
                        <CidadeField
                            isDisabled={!this.props.ufInstituicao}
                            ufId={
                                this.props.ufInstituicao && this.props.ufInstituicao.get
                                    ? this.props.ufInstituicao.get('id')
                                    : this.props.ufInstituicao && this.props.ufInstituicao.id
                            }
                            required
                            label='Município da instituição'
                            name='cidadeInstituicao'
                            placeholder='Município'
                            onChange={this.onChangeMunicipio}
                            getRef={ref => (this.cidadeRef = ref)}
                        />
                    </Column>
                </Columns>
                <Columns>
                    <Column size='8'>
                        <InstituicaoField
                            getRef={ref => (this.instituicaoRef = ref)}
                            isDisabled={!this.props.cidadeInstituicao}
                            label='Instituição na qual trabalha'
                            name='instituicao'
                            placeholder='Digite o nome da instituição para pesquisar'
                            required
                            cidadeId={this.props.cidadeInstituicao && this.props.cidadeInstituicao.id}
                        />
                    </Column>
                </Columns>
                <Columns>
                    <Column size='8'>
                        {this.exibirMensagemErro() && (
                            <p className='report-info-block'>
                                O cadastro da instituição selecionada possui duas categorias incompatíveis entre si. Por
                                gentileza, entre em contato com a central de atendimento da Anvisa solicitando correção.
                                Após a correção, a seleção da instituição estará disponível. Canais de atendimento da
                                Anvisa: https://www.gov.br/anvisa/ptbr/canais_atendimento Central de Atendimento: 0800
                                642 9782
                            </p>
                        )}

                        {this.exibirMensagemErroAdicionarVinculoPerfilSNVS() && (
                            <p className='report-info-block'>
                                Usuários com perfil SNVS não possuem permissão para solicitar vínculo com instituições
                                que não sejam vigilância sanitária. Selecione outra instituição.
                            </p>
                        )}

                        {this.exibirMensagemErroAdicionarVinculoPerfilDiferenteSNVS() && (
                            <p className='report-info-block'>
                                Apenas usuários com perfil SNVS possuem permissão para solicitar vínculo com instituição
                                que sejam vigilância sanitária. Selecione outra instituição.
                            </p>
                        )}
                        <ControlField className='perfis' name='perfil' label='Perfil' required>
                            <PerfilButton
                                disabled={this.isDisabled(PerfilEnum.ADMINISTRATIVO)}
                                perfil={PerfilEnum.ADMINISTRATIVO}
                            />
                            <PerfilButton disabled={this.isDisabled(PerfilEnum.TECNICO)} perfil={PerfilEnum.TECNICO} />
                            <Hint
                                key='1'
                                placement='top'
                                componente={
                                    <div>
                                        <PerfilButton
                                            disabled={this.isDisabled(PerfilEnum.GESTOR_INTEGRACAO)}
                                            perfil={PerfilEnum.GESTOR_INTEGRACAO}
                                        />
                                    </div>
                                }
                            >
                                Perfil capaz de cadastrar sistemas terceiros da instituição, que enviarão registros para
                                o RNI. Poderá também visualizar registros enviados.
                            </Hint>
                            <Hint
                                key='2'
                                placement='top'
                                componente={
                                    <div>
                                        <PerfilButton
                                            disabled={this.isDisabled(PerfilEnum.GESTOR_SERVICO_SAUDE)}
                                            perfil={PerfilEnum.GESTOR_SERVICO_SAUDE}
                                        />
                                    </div>
                                }
                            >
                                Perfil capaz de acessar relatórios gerenciais com informações sobre procedimentos,
                                profissionais e produtos utilizados na instituição.
                            </Hint>
                            <PerfilButton
                                disabled={this.isDisabled(PerfilEnum.MEDICO_CARDIOLOGISTA)}
                                perfil={PerfilEnum.MEDICO_CARDIOLOGISTA}
                            />
                            <PerfilButton
                                disabled={this.isDisabled(PerfilEnum.MEDICO_ORTOPEDISTA)}
                                perfil={PerfilEnum.MEDICO_ORTOPEDISTA}
                            />
                            <PerfilButton
                                disabled={this.isDisabled(PerfilEnum.RESIDENTE_CARDIOLOGISTA)}
                                perfil={PerfilEnum.RESIDENTE_CARDIOLOGISTA}
                            />
                            <PerfilButton
                                disabled={this.isDisabled(PerfilEnum.RESIDENTE_ORTOPEDISTA)}
                                perfil={PerfilEnum.RESIDENTE_ORTOPEDISTA}
                            />
                            <PerfilButton disabled={this.isDisabled(PerfilEnum.SNVS)} perfil={PerfilEnum.SNVS} />
                        </ControlField>
                    </Column>
                </Columns>
                <Columns>
                    <Column size='4'>
                        <NumberField
                            required={this.props.dadosRegistroEnabled}
                            label='Registro em conselho'
                            placeholder='Número do registro'
                            maxLength={10}
                            name='numeroRegistro'
                            disabled={!this.props.dadosRegistroEnabled}
                        />
                    </Column>
                    <Column size='2'>
                        <UfField
                            required={this.props.dadosRegistroEnabled}
                            label='UF'
                            placeholder='UF'
                            name='ufRegistro'
                            disabled={!this.props.dadosRegistroEnabled}
                        />
                    </Column>
                </Columns>
            </FormSection>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(ownProps.form)
    const perfil = selector(state, ownProps.name + '.perfil')
    const dadosRegistroEnabled = PerfisMedico.includes(perfil)
    const ufInstituicao = selector(state, ownProps.name + '.ufInstituicao')
    const cidadeInstituicao = selector(state, ownProps.name + '.cidadeInstituicao')
    const instituicao = selector(state, ownProps.name + '.instituicao')
    return {
        dadosRegistroEnabled,
        ufInstituicao,
        cidadeInstituicao,
        instituicao,
        perfisUser: getPerfisAtivos(state),
        loggedUser: getLoggedUser(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleUFInstituicao: () => {
            dispatch(change(ownProps.form, ownProps.name + '.cidadeInstituicao', null, false, false))
            dispatch(change(ownProps.form, ownProps.name + '.instituicao', null, false, false))
        },
        handleMunInstituicao: () => dispatch(change(ownProps.form, ownProps.name + '.instituicao', null, false, false)),
    }
}

SolicitacaoVinculoFormSection = connect(mapStateToProps, mapDispatchToProps)(SolicitacaoVinculoFormSection)

export default SolicitacaoVinculoFormSection
