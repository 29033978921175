import { connect } from 'react-redux'
import CadastroProfissionalView from 'view/public/profissional/CadastroProfissionalView'
import PublicSolicitacaoAcesso from 'api/PublicSolicitacaoAcesso'

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSave: form => {
            return PublicSolicitacaoAcesso.solicitarAcesso(form.toJS())
        },
        afterSave: () => ownProps.history.push('/'),
        onCancel: () => ownProps.history.push('/'),
    }
}

const CadastroProfissionalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CadastroProfissionalView as any)

export default CadastroProfissionalContainer
