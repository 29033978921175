import * as React from 'react'
import { Icon } from 'libs/components'
import { CardProps, CardHeader, CardContent } from './Card'
import { Text } from '../typography/Text'

export interface CardFilterableProps extends CardProps {
    filter: React.ReactElement<any>
    defaultVisible?: boolean
    report?: React.ReactElement<any>
}

interface State {
    filterVisible: boolean
}

export class CardFilterable extends React.Component<CardFilterableProps, State> {
    static props: CardFilterableProps = {
        filter: null,
        defaultVisible: false,
        report: null,
    }

    constructor(props: CardFilterableProps) {
        super(props)
        this.state = {
            filterVisible: props.defaultVisible,
        }
    }

    toggleFilterVisibility = () => {
        this.setState({ filterVisible: !this.state.filterVisible })
    }

    render() {
        const { filter, children, noPadding, ...headerProps } = this.props

        return (
            <div className='card'>
                {this.props.title && (
                    <CardHeader
                        headerAction={
                            <FilterAction
                                filterVisible={this.state.filterVisible}
                                onClick={this.toggleFilterVisibility}
                            />
                        }
                        {...headerProps}
                        headerAction2={this.props.report}
                    />
                )}

                <div className='card-body is-vertical-flow'>
                    <div className='card-filter' hidden={!this.state.filterVisible}>
                        {filter}
                    </div>

                    <CardContent noPadding={noPadding}>{children}</CardContent>
                </div>
            </div>
        )
    }
}

const FilterAction = ({ filterVisible, onClick }) => {
    return (
        <p data-name='botao_filtro' onClick={onClick}>
            <Text color={filterVisible ? 'neon' : null}>
                <Icon icon='filtro' />
            </Text>
        </p>
    )
}
