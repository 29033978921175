import * as React from 'react'
import { WrappedFieldProps } from 'redux-form'
import * as C from 'libs/components'
import field, { FieldProps } from 'libs/redux-form/field'

export interface TextAreaFieldProps extends C.FormFieldProps, C.TextAreaProps, Partial<WrappedFieldProps> {
    showError?: boolean
}

class TextAreaFieldCmp extends React.Component<TextAreaFieldProps> {
    render() {
        return (
            <div className='textarea-container'>
                {this.props.showError && this.props.meta.error && (
                    <span className='textarea-container-error-txt'>{this.props.meta.error}</span>
                )}
                <C.TextArea
                    {...this.props.input}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    hasError={!!this.props.meta.error}
                />
            </div>
        )
    }
}

export const TextAreaField: React.ComponentClass<FieldProps & TextAreaFieldProps> = field()(TextAreaFieldCmp)
