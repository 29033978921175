import { connect } from 'react-redux'
import LoginView from 'view/login/LoginView'
import { SubmissionError } from 'redux-form/immutable'
import * as auth from 'redux/modules/auth'
import * as alert from 'redux/modules/alert'
import PublicUsuario from 'api/PublicUsuario'

const resolveTargetUrl = (location, result) => {
    const { from } = location.state ||
        (result.data.perfil && { from: { pathname: '/home' } }) || { from: { pathname: '/vinculos' } }

    return from
}

const mapStateToProps = state => {
    return {
        error: state.auth.error,
        isAuthenticating: state.auth.isAuthenticating,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        checkLogin: () => {
            const action = dispatch(auth.checkLogin())
            if (action.then) {
                action
                    .then(result => {
                        ownProps.history.replace(resolveTargetUrl(ownProps.location, result))
                    })
                    .catch(() => {
                        // erro já é logado pelo axios, não é necessário fazer algo.
                    })
            }
        },
        onLogin: credentials => {
            return dispatch(auth.login(credentials.toJS(), ownProps))
                .then(result => {
                    ownProps.history.push(resolveTargetUrl(ownProps.location, result))
                })
                .catch(() => {
                    dispatch(alert.error({ mensagem: 'O usuário ou a senha informados são inválidos' }))
                    throw new SubmissionError({
                        username: 'Campo inválido',
                        password: 'Campo inválido',
                    })
                })
        },
        onRecuperarSenha: form => PublicUsuario.recuperarSenha(form),
        onRecuperarSenhaError: () => dispatch(alert.error({ mensagem: 'Os dados informados são inválidos.' })),
        afterRecuperarSenha: () => ownProps.history.push('/'),
    }
}

const LoginContainer = connect(mapStateToProps, alert.compose(mapDispatchToProps))(LoginView)

export default LoginContainer
