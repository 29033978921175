/* tslint:disable */
import React from 'react'

const SvgPreencher = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M4.2 19.798h14v.6a.6.6 0 01-.6.6h-14a.6.6 0 01-.6-.6v-14a.6.6 0 01.6-.6h.6v14zm14.75-2.99l.04-6.3 1.2-1.2v8.14a.6.6 0 01-.6.6h-13a.6.6 0 01-.6-.6v-13a.6.6 0 01.6-.6h8.1l-1.2 1.2h-6.3v11.76h11.76zm-8.36-5.24a.2.2 0 01.06-.15l6.21-6.21a.2.2 0 01.28 0l1.69 1.7a.2.2 0 010 .28l-6.21 6.21a.2.2 0 01-.14.06h-1.89v-1.89zm10.26-7.16a.5.5 0 010 .71l-.77.77a.2.2 0 01-.28 0l-1.69-1.69a.2.2 0 010-.28l.77-.77a.5.5 0 01.71 0l1.26 1.26z' />
    </svg>
)

export default SvgPreencher
