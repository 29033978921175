import Report from 'api/Report'
import { StatusProcedimentoRow, UsoSistemaFiltro } from 'backend'
import { Card, LoadingContainer, PagedTable, TableColumnHeader } from 'libs/components'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'redux/modules'
import * as filtroReport from 'redux/modules/report/filtro'
import { PageRequester } from 'redux/requester'

interface Props {
    nome: string
}

const paginator = new PageRequester<StatusProcedimentoRow, UsoSistemaFiltro>('statusProcedimentos', filtro => {
    return Report.getPageStatusProcedimentos(filtro)
})

export const StatusRegistrosTable = (props: Props) => {
    const filtro = useSelector((state: State) => state.report.filtro.filtro)
    const local = useSelector((state: State) => state.report.filtro.local)

    const dispatch = useDispatch()
    const dimm = filtroReport.getCurrentDimension(local?.data || {})

    useEffect(() => {
        if (
            Object.keys(filtro).length > 0 &&
            local.readyState === 'success' &&
            (dimm === 'LOCAL_UF' || dimm === 'LOCAL_CIDADE')
        ) {
            dispatch(
                paginator.setParams({
                    ...filtro,
                })
            )
            dispatch(paginator.request())
        }
    }, [dimm, dispatch, filtro, local])

    const results = useSelector((state: State) => paginator.getResult(state))

    const isFetching = useSelector((state: State) => paginator.getIsFetching(state))

    const handlePageChange = (page: number) => {
        dispatch(paginator.setPageNumber(page))
        dispatch(paginator.request())
    }

    return (
        <div className='report-content'>
            <Card title={'Quantidade de registros por etapa e localidade'} noPadding={!isFetching}>
                <LoadingContainer isFetching={isFetching}>
                    <PagedTable
                        page={results}
                        values={results?.content}
                        onChangePage={handlePageChange}
                        className='is-spaced is-small-bis'
                        emptyText='Nenhum registro pendente.'
                    >
                        <TableColumnHeader
                            title={props.nome}
                            style={{ width: '41%' }}
                            render={(item: StatusProcedimentoRow) => {
                                return <div>{item.nome}</div>
                            }}
                        />
                        <TableColumnHeader
                            title='Dados clínicos'
                            style={{ width: '16%' }}
                            render={(item: StatusProcedimentoRow) => {
                                return <div>{item.contClinicos}</div>
                            }}
                        />
                        <TableColumnHeader
                            title='Dados de produto'
                            style={{ width: '19%' }}
                            render={(item: StatusProcedimentoRow) => {
                                return <div>{item.contProduto}</div>
                            }}
                        />
                        <TableColumnHeader
                            title='Dados financeiros'
                            style={{ width: '19%' }}
                            render={(item: StatusProcedimentoRow) => {
                                return <div>{item.contFinanceiro}</div>
                            }}
                        />
                        {/* NAVEGAÇÃO A SER INSERIDA FUTURAMENTE, QUANDO CONSEGUIR COLOCAR TAMBÉM NOS GRAFICOS */}
                        {/* <TableColumnHeader
                            title=''
                            render={(item: StatusProcedimentoRow) => {
                                return (
                                    <div
                                        className='link-navigate'
                                        data-name={'row-' + results.content.indexOf(item)}
                                        onClick={() =>
                                            dispatch(
                                                filtroReport.changeLocal(
                                                    item.idMunicipio ? item.idMunicipio : item.idHospital,
                                                    item.idMunicipio ? 'CIDADE' : 'HOSPITAL'
                                                )
                                            )
                                        }
                                    >
                                        >
                                    </div>
                                )
                            }}
                        /> */}
                    </PagedTable>
                </LoadingContainer>
            </Card>
        </div>
    )
}
