import { EspecialidadesMedicas, PerfisMedico } from 'api/Perfis'
import Profissional from 'api/Profissional'
import { Perfil, VinculoProfissionalRow } from 'backend'
import { useAxiosGet } from 'hooks/useApiRequest'
import { List } from 'immutable'
import {
    Button,
    ButtonBar,
    ControlField,
    InformationIconPopover,
    PageTitle,
    Text,
    LoadingContainer,
} from 'libs/components'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { change, FieldArrayFieldsProps, InjectedFormProps } from 'redux-form'
import { FieldArray, getFormValues, reduxForm } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { EdicaoPreFichaState } from 'redux/modules/edicaoProcedimento'
import { definirEquipeMedica } from 'redux/modules/novoProcedimento'
import {
    clearFindResult as profissionalClearFindResult,
    findMedicos as profissionalFindMedicos,
} from 'redux/modules/profissional'
import { DefinirMedicoForm, definirMedicoFormName } from './componentes/DefinirMedicoForm'
import { EquipeMedicaList } from './componentes/EquipeMedicaList'

export const definirEquipeMedicaFormName = 'definirEquipeMedica'

interface DefinirEquipeMedicaCmpProps {
    next(): void
}

const DefinirEquipeMedicaCmp = ({
    next,
}: DefinirEquipeMedicaCmpProps & InjectedFormProps<any, DefinirEquipeMedicaCmpProps>) => {
    const { push } = useHistory()
    const dispatch = useDispatch()

    const [especialidadeEquipeMedica, setEspecialidadeEquipeMedica] = React.useState<EspecialidadesMedicas>()
    const [equipeMedicaFields, setEquipeMedicaFields] = React.useState<FieldArrayFieldsProps<any>>()

    React.useEffect(() => {
        return () => dispatch(profissionalClearFindResult())
    }, [dispatch])

    const userPerfil = useSelector<State, Perfil>(state => state.auth.user.perfil)
    const userIsMedico: boolean = PerfisMedico.includes(userPerfil)

    const { data, requestState } = useAxiosGet<VinculoProfissionalRow>(Profissional.vinculoUrl())

    React.useEffect(() => {
        if (userIsMedico && requestState === 'success' && equipeMedicaFields.length === 0) {
            equipeMedicaFields.push(data)
        }
    }, [data, equipeMedicaFields, requestState, userIsMedico])

    const formValues = useSelector<State, any>(state => getFormValues(definirEquipeMedicaFormName)(state))
    const medicoResponsavelString: string = formValues?.get('medicoResponsavel')
    const medicoResponsavel: number = medicoResponsavelString && parseInt(medicoResponsavelString, 10)

    const disableConfirmarButton: boolean = medicoResponsavel === undefined || medicoResponsavel === null
    const equipeMedica: List<VinculoProfissionalRow> = formValues?.get('equipeMedica')

    const definirEquipeMedicaAndPushNextRoute = (
        vinculoProfissionalMedicoResponsavel: number,
        equipeMedica: List<VinculoProfissionalRow>
    ) => {
        dispatch(definirEquipeMedica(vinculoProfissionalMedicoResponsavel, equipeMedica))
        next()
    }

    const handlePesquisar = values => {
        const hasMedicoSelecionado: boolean = values.get('medico')
        if (hasMedicoSelecionado) {
            dispatch(change(definirMedicoFormName, 'medico', null))
        }

        const filtro = { nome: '', numeroRegistro: '', primeiroPerfilEquipeMedica: equipeMedica?.get(0)?.perfil }
        if (values.get('tipoFiltro') === 'NOME') {
            filtro.nome = values.get('nome')
        }
        if (values.get('tipoFiltro') === 'NUMEROREGISTRO') {
            filtro.numeroRegistro = values.get('numeroRegistro')
        }

        return dispatch(profissionalFindMedicos(filtro))
    }

    return (
        <div className='consulta-paciente'>
            <PageTitle
                title='DEFINIR EQUIPE MÉDICA'
                subtitle='Busque pelo nome ou nº de registro do(a) médico(a) que participou do procedimento.'
            />
            <hr />
            <DefinirMedicoForm
                initialValues={{ tipoFiltro: 'NOME' }}
                onSubmit={handlePesquisar}
                equipeMedicaFields={equipeMedicaFields}
                equipeMedica={equipeMedica}
                setEspecialidadeEquipeMedica={setEspecialidadeEquipeMedica}
                especialidadeEquipeMedica={especialidadeEquipeMedica}
            />
            <Text className='profissionais-adicionados-subtitle' color='black' weight='regular'>
                Profissionais adicionados
            </Text>
            {userIsMedico && (
                <InformationIconPopover
                    information='Como médico, você será adicionado automaticamente na equipe.'
                    spaced
                    size='is-24px'
                />
            )}
            <hr />
            <ControlField name='equipeMedica' label='Selecione o médico responsável' required />
            <FieldArray
                name='equipeMedica'
                component={EquipeMedicaList}
                setEquipeMedicaFields={setEquipeMedicaFields}
                setEspecialidadeEquipeMedica={setEspecialidadeEquipeMedica}
                userMedicoVinculoId={data?.id}
            />
            <hr />
            <ButtonBar>
                <Button size='medium' onClick={() => push('/home')}>
                    Cancelar
                </Button>
                <Button
                    pulledRight
                    type='primary'
                    size='medium'
                    disabled={disableConfirmarButton}
                    onClick={() => definirEquipeMedicaAndPushNextRoute(medicoResponsavel, equipeMedica)}
                >
                    Confirmar equipe médica
                </Button>
            </ButtonBar>
        </div>
    )
}

const DefinirEquipeMedica = reduxForm<any, DefinirEquipeMedicaCmpProps>({
    form: definirEquipeMedicaFormName,
})(DefinirEquipeMedicaCmp)

interface DefinirEquipeMedicaFormProps {
    next(): void
    isEdicao: boolean
}

export const DefinirEquipeMedicaForm: React.FC<DefinirEquipeMedicaFormProps> = ({ next, isEdicao }) => {
    const edicao = useSelector<State, EdicaoPreFichaState>(state => state.edicao)

    const medicoResponsavel: VinculoProfissionalRow = edicao?.medico
    const equipeMedica: VinculoProfissionalRow[] = edicao?.equipeMedica

    const initialValues = !!edicao
        ? { equipeMedica: List([medicoResponsavel, ...equipeMedica]), medicoResponsavel: '0' }
        : {}

    const fetching = isEdicao && !edicao

    return (
        <LoadingContainer isFetching={fetching}>
            <DefinirEquipeMedica initialValues={initialValues} next={next} />
        </LoadingContainer>
    )
}
