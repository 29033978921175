import { DateInterval, UsoSistemaFiltro } from 'backend'
import { Column, Columns, PeriodoFilter, ScrollToTop } from 'libs/components'
import UrlParams from 'libs/router/UrlParams'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, RouteComponentProps, useLocation } from 'react-router-dom'
import { State } from 'redux/modules'
import * as filtroReport from 'redux/modules/report/filtro'
import * as usoSistema from 'redux/modules/report/usoSistema'
import { FinalizadosXNaoFinalizadosCard } from 'view/componentes/reports/cards/FinalizadosXNaoFinalizadosCard'
import { NaoFinalizadosCard } from 'view/componentes/reports/cards/NaoFinalizadosCard'
import { RegistrosFinalizadosChartCard } from 'view/componentes/reports/cards/RegistrosFinalizadosChartCard'
import { TempoMedioChartCard } from 'view/componentes/reports/cards/TempoMedioChartCard'
import { TotalHospitaisCard } from 'view/componentes/reports/cards/TotalHospitaisCard'
import { TotalProfissionaisCard } from 'view/componentes/reports/cards/TotalProfissionaisCard'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import { StatusRegistrosTable } from '../components/StatusRegistrosTable'
import { useLoadUsoSistema } from '../hooks/useLoadUsoSistema'
import { useNavigateUsoSistema } from '../hooks/useNavigateUsoSistema'
import { UsoSistemaRouteParams } from './UsoSistemaReportView'
import BackButton from 'libs/components/presentational/elements/BackButton'

export type UsoSistemaCidadeProps = RouteComponentProps<UsoSistemaRouteParams>

export function UsoSistemaCidadeDashboard(props: UsoSistemaCidadeProps) {
    const reducer = useSelector((state: State) => state.report.usoSistema)

    const location = useLocation()
    useNavigateUsoSistema()

    const dispatch = useDispatch()
    const load = (filtro: UsoSistemaFiltro) => {
        dispatch(usoSistema.getDadosUsoSistemaCidade(filtro))
    }
    useLoadUsoSistema(load, 'LOCAL_CIDADE')

    const filtro = useSelector((state: State) => state.report.filtro.filtro)
    const handlePeriodoChange = (interval: DateInterval) => {
        dispatch(filtroReport.change({ ...filtro, interval: interval }))
    }

    const refresh = (filter: UsoSistemaFiltro, filterChanged: boolean) => {
        if (!filterChanged && filter.interval) {
            handlePeriodoChange(filter.interval)
        }
    }

    const { local, ...publishFilter } = filtro

    return (
        <div>
            <ScrollToTop onMount />

            <LocalHeaderContainer />
            <ReportHeader title='Uso do sistema'>
                <div>
                    <BackButton />
                </div>
            </ReportHeader>

            <UrlParams params={publishFilter} onChange={refresh} />
            <div className='report-content'>
                <Columns className='report-tabs align-center'>
                    <Column size='6'>
                        <div className='report-tabs-links'>
                            <NavLink
                                to={{
                                    pathname: `${props.match.url}`,
                                    search: location.search,
                                }}
                                activeClassName='is-active'
                            >
                                Dashboard
                            </NavLink>
                        </div>
                    </Column>
                    <Column size='6' textRight>
                        <PeriodoFilter value={filtro?.interval} onChange={handlePeriodoChange} />
                    </Column>
                </Columns>
                <Columns>
                    <Column size='5' verticalFlow>
                        <TotalHospitaisCard
                            readyState={reducer.hospitaisCidade.readyState}
                            data={reducer.hospitaisCidade.data}
                        />
                    </Column>
                    <Column>
                        <TotalProfissionaisCard
                            readyState={reducer.profissionais.readyState}
                            report={reducer.profissionais.data}
                        />
                    </Column>
                </Columns>

                <Columns auto>
                    <TempoMedioChartCard dist={reducer.distTempoMedio} readyState={reducer.distTempoMedio.readyState} />
                </Columns>
                <Columns>
                    {reducer.statusGeral.data && (
                        <Column size='5'>
                            <FinalizadosXNaoFinalizadosCard
                                finalizados={reducer.statusGeral.data?.finalizados}
                                naoFinal={reducer.statusGeral.data?.naoFinalizados}
                                readyState={reducer.statusGeral.readyState}
                            />
                        </Column>
                    )}
                    <Column>
                        <NaoFinalizadosCard
                            naoFinalizados={reducer.statusGeral.data?.naoFinalizados}
                            countStatus={reducer.statusGeral.data?.countStatus}
                            readyState={reducer.statusGeral.readyState}
                        />
                    </Column>
                </Columns>
                <Columns auto>
                    <StatusRegistrosTable nome='Hospitais' />
                </Columns>
                <Columns auto>
                    <RegistrosFinalizadosChartCard
                        dist={reducer.distFinalizadas}
                        readyState={reducer.distFinalizadas.readyState}
                    />
                </Columns>
            </div>
        </div>
    )
}
