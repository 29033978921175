import * as React from 'react'
import classnames from 'classnames'
import { UtilProps } from 'libs/components/Util'

export interface LinhaProps extends UtilProps {
    className?: string
    size?: 'medium'
}

export class Linha extends React.Component<LinhaProps, any> {
    render() {
        const classes = classnames(this.props.className, {
            medium: this.props.size && this.props.size === 'medium',
        })
        return <hr className={classes} />
    }
}
