
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.84,"time":50400,"words":168}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`O hospital que desejar realizar o envio de dados para o RNI, deverá realizar o cadastro dos sistemas, utilizados pelo hospital, e que realizarão o envio de dados de registros para o RNI.`}</p>
    <p>{`O cadastro dos sistemas é realizado pelo Gestor de Integração, através dos passos abaixo:`}</p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na aba “Gerenciar sistemas”`}</li>
      <li parentName="ol">{`Clique no botão `}<strong parentName="li">{`Cadastrar novo sistema`}</strong></li>
      <li parentName="ol">{`Preencha o “Nome da empresa” e o “Nome do sistema”`}</li>
      <li parentName="ol">{`Clique em `}<strong parentName="li">{`Cadastrar`}</strong></li>
    </ol>
    <p>{`Ao finalizar o cadastro, o sistema estará automaticamente ativo. Caso necessite, o cadastro do sistema poderá ser editado, através do botão `}<strong parentName="p">{`Editar sistema`}</strong>{`, no respectivo sistema.`}</p>
    <p>{`Caso seja necessário inativar um sistema ativo, esta ação pode ser realizada através do botão de `}<strong parentName="p">{`Inativar sistema`}</strong>{`, no respectivo sistema. Ao inativar um sistema, o mesmo será realocado para a aba de “Sistemas inativos”.`}</p>
    <p>{`Caso seja necessário reativar um sistema inativo, esta ação pode ser realizada acessando a aba de “Sistemas inativos”, através do botão de `}<strong parentName="p">{`Reativar sistema`}</strong>{`, no respectivo sistema.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

