/* tslint:disable */
import React from 'react'

const SvgHistoricoalteracoes = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 12 12' {...props}>
        <path d='M10.358 2.293a.494.494 0 010 .693.479.479 0 01-.683 0l-.02-.02-.369.372a4.49 4.49 0 011.045 2.888c0 2.467-1.98 4.474-4.415 4.474S1.5 8.693 1.5 6.226c0-2.122 1.466-3.904 3.426-4.36a.487.487 0 01.478-.565L6.428 1.3a.487.487 0 01.477.565c.62.144 1.19.421 1.68.799l.386-.391-.02-.02a.494.494 0 010-.693.479.479 0 01.683 0l.358.362a.328.328 0 01.004.004l.005.005.357.362zm-4.442.438c-1.902 0-3.45 1.568-3.45 3.495S4.015 9.72 5.917 9.72c1.901 0 3.449-1.567 3.449-3.494 0-1.927-1.548-3.495-3.45-3.495zm1.023 3.005c.267 0 .484.22.484.49s-.217.49-.484.49H5.916a.487.487 0 01-.484-.49V4.34c0-.27.217-.49.484-.49.266 0 .483.22.483.49v1.395h.54z' />
    </svg>
)

export default SvgHistoricoalteracoes
