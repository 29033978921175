
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"3 min read","minutes":2.075,"time":124500.00000000001,"words":415}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Aguardando dados clínicos” e no procedimento desejado clique no botão `}<strong parentName="li">{`“Preencher”`}</strong>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` apenas profissionais com perfil de “Médico Ortopedista” e “Residente em ortopedia” que partiparam da equipe médica do procedimento, podem registrar dados clínicos de procedimentos de Artroplastia.`}</p>
    </blockquote>
    <p><strong parentName="p">{`DIAGNÓSTICO`}</strong></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Informe o(s) motivo(s) da artroplastia`}</li>
    </ol>
    <p><strong parentName="p">{`PROCEDIMENTO CIRÚRGICO PRÉVIO`}</strong></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Informe se o paciente possui procedimento cirúrgico prévio`}</li>
      <li parentName="ol">{`Informe se o paciente possui implante prévio`}</li>
      <li parentName="ol">{`Caso o paciente possua implante prévio, informe se ainda está presente ou se foi removido previamente`}</li>
    </ol>
    <p><strong parentName="p">{`PROCEDIMENTO CIRÚRGICO ATUAL`}</strong></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Informe a data da cirurgia e a duração em horas`}</li>
      <li parentName="ol">{`Informe a abordagem cirúrgica utilizada`}</li>
      <li parentName="ol">{`Informe se foi utilizado dreno`}</li>
      <li parentName="ol">{`Informe se houve transfusão sanguínea `}</li>
      <li parentName="ol">{`Caso tenha sido feita transfusão sanguínea informe se foi autóloga ou homóloga`}</li>
      <li parentName="ol">{`Informe se foi utilizado antibiótico`}</li>
      <li parentName="ol">{`Caso tenha sido utilizado antibiótico informe qual foi o tipo de antibiótico e qual o período`}</li>
    </ol>
    <p><strong parentName="p">{`TIPO DE ARTROPLASTIA`}</strong></p>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol">{`Informe qual o tipo de artroplastia`}</li>
      <li parentName="ol">{`Informe se a fixação do fêmur foi cimentada ou não`}</li>
      <li parentName="ol">{`Informe se a fixação da tíbia foi cimentada ou não`}</li>
      <li parentName="ol">{`Caso o tipo de artroplastia for “Patelo femoral” informe se houve fixação de patela ou não`}</li>
    </ol>
    <p><strong parentName="p">{`CIMENTO ÓSSEO`}</strong></p>
    <ol {...{
      "start": 18
    }}>
      <li parentName="ol">{`Informe se foi utilizado cimento ósseo`}</li>
      <li parentName="ol">{`Caso tenha sido utilizado cimento:`}<ul parentName="li">
          <li parentName="ul">{`Informe se foi convencional ou com antibiótico`}</li>
          <li parentName="ul">{`Informe se houve lavagem pulsátil`}</li>
        </ul></li>
    </ol>
    <p><strong parentName="p">{`ENXERTIA`}</strong></p>
    <ol {...{
      "start": 20
    }}>
      <li parentName="ol">{`Informe se foi utilizado enxerto`}</li>
      <li parentName="ol">{`Caso tenha sido utilizado enxerto informe se foi Femoral ou Tibial e qual o tipo de enxerto utilizado`}</li>
    </ol>
    <p><strong parentName="p">{`COMPONENTES UTILIZADOS`}</strong></p>
    <ol {...{
      "start": 22
    }}>
      <li parentName="ol">{`Informe quais foram os principais componente utilizados`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Caso o componente não seja apresentado, verifique se o tipo de artroplastia foi selecionado corretamente`}</p>
    </blockquote>
    <ol {...{
      "start": 23
    }}>
      <li parentName="ol">{`Informe também a quantidade de cada componente utilizado`}</li>
    </ol>
    <p>{`Para remover um componente da lista clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeRecusar.png#center",
        "alt": "Remover"
      }}></img>{` ao lado do componente desejado`}</p>
    <ol {...{
      "start": 24
    }}>
      <li parentName="ol">{`Informe quais foram os componentes complementares utilizados`}</li>
      <li parentName="ol">{`Informe também a quantidade de cada componente utilizado`}</li>
    </ol>
    <p>{`Para remover um componente utilizado na lista clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeRecusar.png#center",
        "alt": "Remover"
      }}></img>{` ao lado do componente desejado`}</p>
    <ol {...{
      "start": 26
    }}>
      <li parentName="ol">{`Clique no botão “Salvar registro” para registrar o procedimento`}</li>
    </ol>
    <p>{`Após o registro dos dados clínicos este procedimento fica disponível para preenchimento dos dados de produto, ou seja, os dados dos componentes implantados no paciente.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona cada etapa do preenchimento do registro, `}<a parentName="p" {...{
          "href": "../fluxos/fluxoPreenchimentoRegistros"
        }}><strong parentName="a">{`clique aqui`}</strong></a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona o preenchimento de dados clínicos de artroplastia, consulte o vídeo explicativo abaixo:`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

