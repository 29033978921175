import * as React from 'react'
import { Card, Column, Columns, LoadingStateContainer, NumberLabel } from 'libs/components'
import { InvertedLabel } from 'view/componentes/reports/components/InvertedLabel'
import { ValuePercentLabel } from 'view/componentes/reports/components/ValuePercentLabel'

interface Props {
    naoFinalizados: number
    countStatus: { [index: string]: number }
    readyState: 'request' | 'success' | 'failure'
}

export class NaoFinalizadosCard extends React.Component<Props> {
    render() {
        const { naoFinalizados, countStatus, readyState } = this.props
        return (
            <Card title={'Não finalizados'}>
                <LoadingStateContainer readyState={readyState}>
                    {readyState === 'success' && (
                        <Columns>
                            <Column size='3'>
                                <InvertedLabel title='Registros não finalizados'>
                                    <NumberLabel value={naoFinalizados} placeholder='-' />
                                </InvertedLabel>
                            </Column>
                            <Column className='is-semibold is-black-ter'>
                                <ValuePercentLabel
                                    value={countStatus['AGUARDANDO_DADOS_CLINICOS']}
                                    total={naoFinalizados}
                                    text={'Aguardando dados clínicos'}
                                />

                                <ValuePercentLabel
                                    value={countStatus['AGUARDANDO_DADOS_PRODUTO']}
                                    total={naoFinalizados}
                                    text={'Aguardando dados de produto'}
                                />
                                <ValuePercentLabel
                                    value={countStatus['AGUARDANDO_DADOS_FINANCEIRO']}
                                    total={naoFinalizados}
                                    text={'Aguardando dados financeiros'}
                                />
                            </Column>
                        </Columns>
                    )}
                </LoadingStateContainer>
            </Card>
        )
    }
}
