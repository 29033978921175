import { createValidator, required } from 'libs/validation'

export const ImplantesValidator = createValidator({}, formValidator)

function formValidator(form) {
    const errors = {}
    if (!form.get('femoral') && !form.get('acetabular')) {
        errors.femoral = required(undefined)
    }
    if (form.get('femoral')) {
        errors.modeloHaste = required(form.get('modeloHaste'))
        errors.centralizadorDistal = required(form.get('centralizadorDistal'))
        errors.centralizadorProximal = required(form.get('centralizadorProximal'))
        errors.femoralCimentado = required(form.get('femoralCimentado'))

        if (form.get('femoralCimentado') === 'SIM') {
            errors.restritorCimento = required(form.get('restritorCimento'))

            if (form.get('restritorCimento') === 'SIM' && !form.get('tipoRestritorCimento')) {
                errors.restritorCimento = 'Preencha a subopção'
            }
        }
    }
    if (form.get('acetabular')) {
        errors.acetabularCimentado = required(form.get('acetabularCimentado'))
    }
    return errors
}
