export const printFile = (blob: Blob, titulo: string) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.hidden = true
    a.download = titulo
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
}
