import React from 'react'

export const PesquisaSatisfacaoHeaderIcon = () => (
    <svg
        className='pesquisa-satisfacao-header-icon'
        xmlns='http://www.w3.org/2000/svg'
        width='72'
        height='72'
        viewBox='0 0 72 72'
    >
        <g fill='none' fillRule='evenodd'>
            <path fill='#FFF' fillOpacity='.01' d='M0 0h72v72H0z' />
            <g transform='translate(3 11)'>
                <path
                    fill='#F7F7F7'
                    fillRule='nonzero'
                    d='M8.25 47.75a4.25 4.25 0 1 0 0-8.5 4.25 4.25 0 0 0 0 8.5zm0-1.5a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5zM56.75 10.75a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5zm0-1.5a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5z'
                />
                <path
                    fill='#E6E6E6'
                    fillRule='nonzero'
                    d='M2.75 29.75a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5zm0-1.5a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5z'
                />
                <circle cx='21.75' cy='2' r='2' fill='#E6E6E6' />
                <circle cx='64.75' cy='27' r='2' fill='#E6E6E6' />
                <path
                    fill='#D9D9D9'
                    fillRule='nonzero'
                    d='M6.812 10.182l.938.939.938-.939a.622.622 0 0 1 .794-.072l.086.072a.622.622 0 0 1 0 .88L8.629 12l.939.938a.622.622 0 1 1-.88.88l-.938-.939-.938.939a.622.622 0 0 1-.794.072l-.086-.072a.622.622 0 0 1 0-.88L6.871 12l-.939-.938a.622.622 0 1 1 .88-.88z'
                />
                <path
                    fill='#E6E6E6'
                    fillRule='nonzero'
                    d='M56.457 43.293l1.508 1.507 1.508-1.507a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 0 1.414l-1.507 1.508 1.507 1.508a1 1 0 0 1-1.414 1.414l-1.508-1.507-1.508 1.507a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1 0-1.414l1.507-1.508-1.507-1.508a1 1 0 0 1 1.414-1.414z'
                />
                <circle cx='34' cy='25' r='18' fill='#F7F7F7' />
            </g>
            <g transform='translate(21 22)'>
                <path
                    fill='#3C638A'
                    d='M21.384 15.108l10.292 17.826-6.308-.926-2.352 5.926L16 25.78 8.984 37.934l-2.352-5.926-6.308.926 10.292-17.826L16 18.217l5.384-3.109z'
                />
                <circle cx='16' cy='14' r='14' fill='#09F' />
                <circle cx='16' cy='14' r='10' fill='#FFF' />
                <path
                    fill='#09F'
                    fillRule='nonzero'
                    d='M18 20h-3.272v-6.353c0-.771.017-1.477.05-2.118a7.02 7.02 0 0 1-.751.772l-1.355 1.124L11 11.357 15.096 8H18v12z'
                />
            </g>
        </g>
    </svg>
)
