import { connect } from 'react-redux'
import HomeGestorSegurancaView from 'view/home/gestorSeguranca/HomeGestorSegurancaView'
import * as home from 'redux/modules/home'
import * as alert from 'redux/modules/alert'
import { State } from 'redux/modules'
import { createPaginator, getPage, isFetching, Paginator } from 'redux/middleware/PaginationAction'
import {
    VinculoProfissionalRow,
    SolicitacaoVinculoRow,
    SolicitacaoVinculoFiltro,
    VinculoProfissionalFiltro,
} from 'backend'
import SolicitacaoAcesso from 'api/SolicitacaoAcesso'

const urlProfissionais = '/api/profissional/vinculos'
const urlPendentes = '/api/solicitacaoAcesso/vinculosPendentes'
const urlRecusados = '/api/solicitacaoAcesso/vinculosRecusados'

const mapStateToProps = (state: State) => {
    return {
        isFetchingProfissionaisHospital: isFetching(urlProfissionais)(state),
        isFetchingVinculosPendentesHospital: isFetching(urlPendentes)(state),
        isFetchingVinculosRecusadosHospital: isFetching(urlRecusados)(state),
        profissionaisHospital: getPage<VinculoProfissionalRow>(urlProfissionais)(state),
        vinculosPendentesHospital: getPage<SolicitacaoVinculoRow>(urlPendentes)(state),
        vinculosRecusadosHospital: getPage<SolicitacaoVinculoRow>(urlRecusados)(state),
    }
}

const mapDispatchToProps = dispatch => {
    const profissionaisHospitalPaginator = createPaginator(dispatch, urlProfissionais)
    const vinculosPendentesPaginator = createPaginator(dispatch, urlPendentes)
    const vinculosRecusadosPaginator = createPaginator(dispatch, urlRecusados)
    return {
        profissionaisHospitalPaginator,
        vinculosPendentesPaginator,
        vinculosRecusadosPaginator,
        clearFilters: (paginator: Paginator) => {
            paginator.clear()
        },
        loadProfissionaisHospital: (page: number) => {
            profissionaisHospitalPaginator.page(page)
        },
        filterProfissionaisHospital: (filtro: VinculoProfissionalFiltro) => {
            profissionaisHospitalPaginator.filter(filtro)
        },
        loadVinculosPendentesHospital: (page: number) => {
            vinculosPendentesPaginator.page(page)
        },
        filterVinculosPendentesHospital: (filter: SolicitacaoVinculoFiltro) => {
            vinculosPendentesPaginator.filter(filter)
        },
        loadVinculosRecusadosHospital: (page: number) => {
            vinculosRecusadosPaginator.page(page)
        },
        filterVinculosRecusadosHospital: (filter: SolicitacaoVinculoFiltro) => {
            vinculosRecusadosPaginator.filter(filter)
        },
        aprovarSolicitacaoVinculo: solicitacaoVinculoId => {
            dispatch(home.aprovarSolicitacaoVinculo(solicitacaoVinculoId)).then(() => {
                vinculosPendentesPaginator.page()
                dispatch(alert.success({ mensagem: 'Novo vínculo aceito com sucesso!' }))
            })
        },
        recusarSolicitacaoVinculo: form => {
            return SolicitacaoAcesso.recusarSolicitacaoVinculo(form).then(() => {
                vinculosPendentesPaginator.page()
                dispatch(alert.success({ mensagem: 'Rejeição de vínculo realizada com sucesso!' }))
            })
        },
        desativarVinculo: id => {
            return dispatch(home.desativarVinculo(id)).then(() => {
                profissionaisHospitalPaginator.page()
                dispatch(alert.success({ mensagem: 'Este vínculo foi desativado com sucesso!' }))
            })
        },
    }
}

const HomeGestorSegurancaContainer = connect(mapStateToProps, mapDispatchToProps)(HomeGestorSegurancaView as any)

export default HomeGestorSegurancaContainer
