
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.76,"time":105600,"words":352}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Fiz o meu cadastro no RNI, onde encontro a minha senha de acesso?`}</strong><br parentName="p"></br>{`
`}{`Sua senha de acesso é enviada para o seu e-mail cadastrado, assim que o Gestor de segurança da instituição aceitar a sua solicitação de acesso. Após acessar você poderá trocar a sua senha por outra que seja mais fácil para você lembrar.`}</p>
    <p><strong parentName="p">{`Como sei quem são os gestores de segurança da instituição em que trabalho?`}</strong><br parentName="p"></br>{`
`}{`Durante a solicitação de cadastro, o RNI enviará um e-mail para o endereço de e-mail utilizado no seu cadastro, informando quais são os Gestores de segurança responsáveis por avaliar a sua solicitação de cadastro.`}</p>
    <p><strong parentName="p">{`Quais procedimentos podem ser registrados no RNI?`}</strong><br parentName="p"></br>{`
`}{`O RNI permite o registro de procedimentos de Artroplastias de Quadril e Joelho, sejam elas primárias ou revisões, e também de Angioplastias coronárias com colocação de stent. `}</p>
    <p><strong parentName="p">{`Trabalho em mais de um hospital, posso registrar procedimentos feitos em todos os hospitais que trabalho?`}</strong><br parentName="p"></br>{`
`}{`Sim, no momento do cadastro o RNI solicita que você informe uma instituição no qual você realize procedimentos. Após o gestor de segurança da instituição autorizar o seu acesso, você poderá adicionar vínculos com outros hospitais. Todos os vínculos solicitados deverão ser aprovados pelos gestores de segurança dos seus respectivos hospitais.`}</p>
    <p><strong parentName="p">{`Posso imprimir os dados clínicos preenchidos por mim, para entregar ao paciente, ou anexar ao prontuário da instituição?`}</strong><br parentName="p"></br>{`
`}{`Sim, o RNI permite que o médico ou médico residente imprimam os dados do procedimento para posterior entrega ao paciente ou manuseio.  Caso os dados das etiquetas dos produtos já tenham sido informados, eles são incluídos na impressão.`}</p>
    <p><strong parentName="p">{`Posso visualizar os procedimentos que informei no RNI?`}</strong><br parentName="p"></br>{`
`}{`Sim, os procedimentos registrados no RNI podem ser consultados através da aba “Histórico”. Eles ficam sempre disponíveis para consulta.`}</p>
    <p><strong parentName="p">{`Posso visualizar registros de procedimentos nos quais eu fui o paciente?`}</strong><br parentName="p"></br>{`
`}{`Não, no entanto, o médico ou médico residente que particaparam da equipe médica do procedimento, poderam visualizá-lo e imprimi-lo para lhe entregar uma cópia.`}</p>
    <p><strong parentName="p">{`Preciso registrar um procedimento no RNI para cada stent implantado em um paciente?`}</strong><br parentName="p"></br>{`
`}{`Não, o RNI permite que você registre quantos e quais stents foram implantados no paciente no mesmo procedimento.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

