import React from 'react'
import { AutenticacaoState, generateLogin, generateSenha } from 'redux/modules/integracao'
import {
    AutenticacaoIntegracaoInnerRowViewStatus,
    AutenticacaoIntegracaoInnerRow,
} from './AutenticacaoIntegracaoInnerRow'
import { useDispatch } from 'react-redux'
import { IntegracaoRowItemHeader } from '../common/IntegracaoRowItemHeader'
import { asClientFormat } from 'libs/util/DateUtil'
import { Linha } from 'libs/components'
import Aviso from 'view/componentes/Aviso'

interface AutenticacaoIntegracaoRowProps {
    autenticacao: AutenticacaoState
}

export const AutenticacaoIntegracaoRow = ({ autenticacao }: AutenticacaoIntegracaoRowProps) => {
    const dispatch = useDispatch()

    let statusLogin, statusSenha: AutenticacaoIntegracaoInnerRowViewStatus
    if (autenticacao?.ativo === false) {
        statusLogin = statusSenha = AutenticacaoIntegracaoInnerRowViewStatus.BLOCKED
    } else {
        if (autenticacao?.login === null) {
            statusLogin = AutenticacaoIntegracaoInnerRowViewStatus.FIRST_TIME
        }
        if (autenticacao?.senha === null) {
            statusSenha = AutenticacaoIntegracaoInnerRowViewStatus.FIRST_TIME
        }
    }

    return (
        <>
            <div className='autenticacao-integracao-row'>
                <IntegracaoRowItemHeader
                    title={
                        autenticacao.sistema.empresaNome.toUpperCase() +
                        ' - ' +
                        autenticacao.sistema.sistemaNome.toUpperCase()
                    }
                    subtitle={'Cadastrada em ' + asClientFormat(autenticacao.sistema.dataCadastro)}
                />
                <div className='autenticacao-integracao-row-content'>
                    <AutenticacaoIntegracaoInnerRow
                        title='Login'
                        value={autenticacao?.login}
                        date={autenticacao?.dataRegistroLogin}
                        generateFunction={() => dispatch(generateLogin(autenticacao.sistema.id))}
                        specialStatus={statusLogin}
                        readyState={autenticacao?.readyStateLogin}
                    />
                    <Linha className='autenticacao-integracao-row-content-linha' />
                    <AutenticacaoIntegracaoInnerRow
                        title='Senha'
                        value={autenticacao?.senha}
                        date={autenticacao?.dataRegistroSenha}
                        generateFunction={() => dispatch(generateSenha(autenticacao.sistema.id))}
                        specialStatus={statusSenha}
                        readyState={autenticacao?.readyStateSenha}
                    />
                </div>
            </div>
            {statusLogin === AutenticacaoIntegracaoInnerRowViewStatus.BLOCKED && statusLogin === statusSenha && (
                <Aviso color='red' style={{ marginTop: 10 }}>
                    Foram realizadas 5 tentativas de envio de dados utilizando uma senha incorreta. É necessário gerar
                    um novo login.
                </Aviso>
            )}
        </>
    )
}
