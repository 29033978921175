/* tslint:disable */
import React from 'react'

const SvgRelacaoEmpresas = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 20 20' {...props}>
        <path
            clipRule='evenodd'
            d='M7.327 2.5h3.332c.76 0 1.367.598 1.367 1.346v1.936h3.138c.759 0 1.222.599 1.222 1.346v1.204a5.461 5.461 0 00-.922-.248V7.95l-.004-.135c-.029-.612-.268-.981-.848-.981H3.418l-.119.006c-.535.054-.772.486-.772 1.11v7.385l.004.13c.034.596.31.986.887.986h6.44c.244.385.536.738.867 1.05H2.827c-.76 0-1.227-.598-1.227-1.346V7.128c0-.75.47-1.346 1.227-1.346H5.96V3.846A1.35 1.35 0 017.327 2.5zm3.632 3.282V3.55H7.027v2.232h3.932z'
        />
        <path d='M14.556 9.5c-1.91.004-3.544 1.393-3.88 3.3l-.641-.904a.304.304 0 00-.528.047.314.314 0 00.027.308l1.092 1.523a.3.3 0 00.195.122h.05c.063 0 .124-.02.175-.056l1.503-1.107a.313.313 0 00.075-.431.303.303 0 00-.426-.076l-.936.726c.287-1.779 1.897-3.019 3.664-2.823 1.767.196 3.075 1.76 2.976 3.559-.098 1.799-1.568 3.206-3.346 3.203a3.593 3.593 0 01-2.478-1.112.299.299 0 00-.51.113.309.309 0 00.064.298 4.156 4.156 0 002.92 1.31c2.179.001 3.946-1.788 3.948-3.998.001-2.209-1.764-4-3.944-4.002z' />
        <path
            clipRule='evenodd'
            d='M14.557 9.3c2.292.001 4.144 1.886 4.143 4.203-.002 2.317-1.857 4.198-4.149 4.197h-.005a4.355 4.355 0 01-3.06-1.372l-.003-.004a.508.508 0 01.265-.833.499.499 0 01.476.152 3.393 3.393 0 002.336 1.048c1.668.001 3.05-1.32 3.143-3.014.092-1.696-1.14-3.165-2.8-3.349-1.472-.163-2.831.736-3.307 2.112l.486-.377a.503.503 0 01.706.125.513.513 0 01-.122.707l-1.503 1.108a.497.497 0 01-.293.093h-.065l-.016-.003a.5.5 0 01-.326-.202L9.37 12.365a.513.513 0 01-.043-.506.506.506 0 01.873-.078l.383.54c.51-1.768 2.117-3.017 3.975-3.021zm-4.094 4.59zm4.094-4.19c-1.812.004-3.364 1.322-3.684 3.134l-.083.472-.92-1.296a.104.104 0 00-.095-.046.106.106 0 00-.086.062.113.113 0 00.009.11l1.09 1.522a.101.101 0 00.054.038h.03c.02 0 .04-.006.057-.018l1.504-1.108a.113.113 0 00.026-.154.103.103 0 00-.143-.029l-1.333 1.034.081-.501c.304-1.882 2.008-3.197 3.884-2.99 1.875.208 3.258 1.865 3.154 3.768-.104 1.904-1.66 3.396-3.546 3.393h-.006a3.793 3.793 0 01-2.617-1.174l-.004-.004a.099.099 0 00-.095-.031.103.103 0 00-.075.07.109.109 0 00.02.103 3.955 3.955 0 002.775 1.245c2.065 0 3.744-1.697 3.746-3.798.001-2.1-1.677-3.8-3.743-3.802z'
        />
    </svg>
)

export default SvgRelacaoEmpresas
