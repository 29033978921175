
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"3 min read","minutes":2.285,"time":137100.00000000003,"words":457}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "médico"
    }}>{`Médico`}</h3>
    <p>{`Os dados dos produtos implantáveis podem ser preenchidos pelo técnico, médico, médico residente e o administrativo/financeiro. São os dados das etiquetas dos produtos, como por exemplo, o registro dos mesmos na ANVISA, a validade, o lote, entre outros.`}</p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Aguardando dados de produto”`}</li>
      <li parentName="ol">{`No procedimento desejado clique no botão `}<strong parentName="li">{`“Preencher”`}</strong></li>
    </ol>
    <p>{`Para cada um dos produtos implantados no paciente, os seguintes dados deverão ser informados:`}</p>
    <p><strong parentName="p">{`IDENTIFICAÇÃO ANVISA`}</strong></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Será apresentado no topo da página o nome do componente utilizado, informe o registro ANVISA`}</li>
      <li parentName="ol">{`Confira se os dados do componente estão certos`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` O registro ANVISA também pode ser encontrado como “Registro no MS” ou “Registro MS”.`}</p>
    </blockquote>
    <p>{`Caso não encontre o registro ANVISA `}<a parentName="p" {...{
        "href": "../semRegistroAnvisa"
      }}>{`Clique aqui`}</a></p>
    <p><strong parentName="p">{`IDENTIFICAÇÃO DO COMPONENTE`}</strong></p>
    <h4 {...{
      "id": "com-código-de-barras"
    }}>{`Com código de barras`}</h4>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">
        <p parentName="li">{`Clique no botão `}<strong parentName="p">{`Deseja utilizar o leitor de código de barras?`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Aponte o leitor para o código de barras na caixa do produto implantado.`}</p>
      </li>
    </ol>
    <p>{`O RNI aceita os seguintes padrões de código de barras`}</p>
    <p><img parentName="p" {...{
        "src": "/images/codigoBarras.png",
        "alt": "Padrões de código de barras"
      }}></img></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Os dados existentes no código de barras serão incluídos nos respectivos campos. Confira se os dados estão certos, caso necessário efetue correções.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Antes de salvar é importante verificar se os dados preenchidos a partir da leitura do código de barras estão corretos, pois não será possível alterá-los após o salvamento.`}</p>
    </blockquote>
    <h4 {...{
      "id": "sem-código-de-barras"
    }}>{`Sem código de barras`}</h4>
    <p>{`Caso não possua o código de barras preencha as seguintes informações:`}</p>
    <ul>
      <li parentName="ul">{`Informe o identificador do dispositivo ou código de referência`}</li>
      <li parentName="ul">{`Informe o lote ou número de série`}</li>
      <li parentName="ul">{`Informe a data de fabricação`}</li>
      <li parentName="ul">{`Informe a validade`}</li>
    </ul>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Clique no botão “Salvar”`}</li>
    </ol>
    <p>{`Caso tenha mais de um componente clique no botão “Próximo componente”. Informe os dados de todos os componentes implantados e ao final clique no botão “Salvar”.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Caso tenham sido utilizados dois ou mais componentes exatamente iguais, é possível copiar os dados preenchidos em um para o outro. Para isso, preencha os dados de produto de um dos componentes e nos próximos utilize a opção “Deseja copiar dados de um componente anterior?”, selecionando o componente que terá os dados copiados para o componente que está sendo preenchido.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona cada etapa do preenchimento do registro, `}<a parentName="p" {...{
          "href": "../../fluxos/fluxoPreenchimentoRegistros"
        }}><strong parentName="a">{`clique aqui`}</strong></a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona o preenchimento dos dados de produto com o uso de um leitor de código de barras e com produtos que possuam código de barras nos padrões aceitos pelo RNI, consulte o vídeo explicativo abaixo:`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Caso não possua um leitor de código de barras, ou o código de barras não esteja nos padrões aceitos pelo RNI, você pode visualizar como funciona o preenchimento dos dados de produto através do vídeo abaixo:`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "médico",
    level: 3,
    title: "Médico",
    children: [
        
      ]
  }
]

export const frontMatter = {}

