import React from 'react'
import * as C from 'libs/components'
import field from 'libs/redux-form/field'

class SearchInputFieldCmp extends React.Component {
    input

    constructor() {
        super()
        this.state = {
            loading: false,
        }
    }

    onClickSearch() {
        this.refs['input'].focus()
        this.setState({ loading: true })
        const promise = this.props.onClick()
        if (promise && promise.then) {
            promise.then(() => this.setState({ loading: false })).catch(() => this.setState({ loading: false }))
        } else {
            this.setState({ loading: false })
        }
    }

    onKeyPress(e) {
        if (e.charCode === 13) {
            this.input.blur()
            this.onClickSearch()
        }
    }

    render() {
        return (
            <C.FormField
                disabled={this.props.disabled}
                error={this.props.meta.touched && this.props.meta.error}
                icon={this.props.icon}
                label={this.props.label}
                name={this.props.input.name}
                required={this.props.required}
                unit={this.props.unit}
                hasAddons={true}
                hasControl={false}
            >
                <C.Control expanded>
                    <C.TextInput
                        ref={input => (this.input = input)}
                        {...this.props.input}
                        placeholder={this.props.placeholder}
                        maxLength={this.props.size}
                        disabled={this.props.disabled}
                        onKeyPress={this.onKeyPress.bind(this)}
                    />
                </C.Control>
                <C.Control>
                    <C.Button
                        className='search-icon'
                        icon='pesquisar'
                        onClick={this.onClickSearch.bind(this)}
                        loading={this.state.loading}
                        disabled={this.props.disabled}
                        tabIndex={-1}
                    />
                </C.Control>
            </C.FormField>
        )
    }
}
export const SearchInputField = field({ hasWrapper: false })(SearchInputFieldCmp)
