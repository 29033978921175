import * as React from 'react'
import { DistribuicaoFinanceiraDto } from 'backend'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { Column, Card, LoadingStateContainer, NumberLabel } from 'libs/components'
import * as C from 'libs/components'
import { Columns } from 'libs/components/presentational/layout/grid/Columns'
import Text from 'libs/components/presentational/typography/Text'

interface ValorMedioProps {
    dados: PromiseRecord<DistribuicaoFinanceiraDto>
}

export class ValorMedioCard extends React.Component<ValorMedioProps> {
    render() {
        const dados = this.props.dados
        const data = dados && dados.data

        const valorTotalPagamento = data.rows
            .filter(row => row.compradoHospital === 'SIM')
            .map(value => value.valorPagamento != null && value.valorPagamento)
            .reduce((a, b) => a + b, 0)

        const valorTotalRessarcimento = data.rows
            .filter(row => row.compradoHospital === 'SIM')
            .map(value => value.valorRessarcimento != null && value.valorRessarcimento)
            .reduce((a, b) => a + b, 0)

        const qtdPagamento = data.rows
            .filter(row => row.compradoHospital === 'SIM')
            .map(value => value.qtdPagamentos)
            .reduce((a, b) => a + b, 0)

        const qtdRessarcimento = data.rows
            .filter(row => row.compradoHospital === 'SIM')
            .map(value => value.qtdRessarcimentos)
            .reduce((a, b) => a + b, 0)

        return (
            <Column>
                <Card title='Valor médio ressarcido'>
                    <LoadingStateContainer readyState={dados.readyState}>
                        <Columns>
                            <Column className='has-text-centered'>
                                <C.ComposedLabel
                                    title={'Preço médio de compra em ' + qtdPagamento + ' reg.'}
                                    labelClassName='is-muted'
                                    inverted
                                    big
                                >
                                    <Text color='grey-darker' size='large'>
                                        R${' '}
                                        <NumberLabel
                                            placeholder={'0,00'}
                                            className='is-big is-black'
                                            value={valorTotalPagamento / qtdPagamento}
                                            minDecimalPlaces={2}
                                        ></NumberLabel>
                                    </Text>
                                </C.ComposedLabel>
                            </Column>
                            <Column className='has-text-centered'>
                                <C.ComposedLabel
                                    title={'Preço médio ressarcido em ' + qtdRessarcimento + ' reg.'}
                                    labelClassName='is-muted'
                                    inverted
                                    big
                                >
                                    <Text color='grey-darker' size='large'>
                                        R${' '}
                                        <NumberLabel
                                            placeholder={'0,00'}
                                            className='is-big is-black'
                                            value={valorTotalRessarcimento / qtdRessarcimento}
                                            minDecimalPlaces={2}
                                        ></NumberLabel>
                                    </Text>
                                </C.ComposedLabel>
                            </Column>
                        </Columns>
                    </LoadingStateContainer>
                </Card>
            </Column>
        )
    }
}
