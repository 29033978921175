import React from 'react'

const SensivelBadge: React.FC = () => {
    return (
        <div className='sensivel-badge'>
            <span>Sensível</span>
        </div>
    )
}

export default SensivelBadge
