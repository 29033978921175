import pessoaNaoEncontrada from 'images/home/novoProcedimento/pessoa-nao-encontrada.svg'
import { Column, Columns } from 'libs/components'
import React from 'react'
import { CardMedico } from './CardMedico'
import { VinculoProfissionalRow } from 'backend'

export interface ResultadoBuscaMedicoProps {
    submitting: boolean
    listaMedico: VinculoProfissionalRow[]
}

export const ResultadoBuscaMedico = ({ submitting, listaMedico }: ResultadoBuscaMedicoProps) => {
    if (submitting) {
        return <p>Buscando...</p>
    }
    if (!listaMedico) {
        return (
            <p className='text-search has-text-centered'>
                Realize a busca preenchendo nome ou nº de
                <br />
                registro, depois aperte em pesquisar, selecione o(a)
                <br />
                médico(a) e adicione o profissional para avançar.
            </p>
        )
    }
    if (listaMedico.length < 1) {
        return (
            <div className='has-text-centered'>
                <img src={pessoaNaoEncontrada} alt='pessoa404' />
                <br />
                <p className='text-search'>Nenhum profissional encontrado.</p>
            </div>
        )
    }
    if (listaMedico) {
        return (
            <Columns>
                <Column>
                    <CardMedico listaMedico={listaMedico} />
                </Column>
            </Columns>
        )
    }
    return null
}
