import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

export interface ScrollToTopProps extends RouteComponentProps<any> {
    onMount?: boolean
}

class ScrollToTopComp extends React.Component<ScrollToTopProps, any> {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    componentDidMount() {
        if (this.props.onMount) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return null
    }
}

export const ScrollToTop = withRouter(ScrollToTopComp)
