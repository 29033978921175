/* tslint:disable */
import React from 'react'

const SvgEntidades = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 20 20' {...props}>
        <path d='M14.101 2.25c.458 0 .829.369.829.824v5.26l-.078.031a1.652 1.652 0 00-.875-.883V3.197H4.283v12.106h3.653a1.652 1.652 0 00-.074.947H4.159a.826.826 0 01-.829-.823V3.074c0-.455.37-.824.829-.824H14.1z' />
        <path d='M8.985 8.75H6.488a.5.5 0 000 1h1.589c.073-.123.162-.238.269-.34l.64-.66zM7.269 11.75c-.211.276-.336.62-.339.99v.01h-.442a.5.5 0 010-1h.78zM5.988 6.25a.5.5 0 01.5-.5h5.284a.5.5 0 110 1H6.488a.5.5 0 01-.5-.5z' />
        <path
            clipRule='evenodd'
            d='M14.78 13.25a1.95 1.95 0 11-3.9 0 1.95 1.95 0 013.9 0zm-.9 0a1.05 1.05 0 11-2.1 0 1.05 1.05 0 012.1 0z'
        />
        <path
            clipRule='evenodd'
            d='M13.33 8.55h-1l-.081.01a.45.45 0 00-.356.368l-.16.977-.166.059a3.54 3.54 0 00-.32.142l-.8-.574-.07-.044a.45.45 0 00-.527.094l-.663.684-.059.064a.451.451 0 00-.012.502l.575.813-.077.163c-.049.11-.092.221-.129.335l-.978.165-.078.02a.45.45 0 00-.299.42v1.002l.016.09c.046.175.182.301.354.332l.976.174.061.166c.044.11.093.218.147.323l-.572.798-.043.07a.45.45 0 00.092.52l.733.723a.45.45 0 00.518.023l.823-.58.161.075c.108.048.219.09.332.126l.16.982.02.079a.45.45 0 00.42.299h1.02l.085-.017a.45.45 0 00.32-.362l.173-.981.167-.06c.11-.044.219-.092.325-.146l.824.561.08.044a.45.45 0 00.495-.099l.684-.678.058-.072a.45.45 0 00-.011-.506l-.578-.787.073-.157c.046-.106.087-.214.123-.324l.994-.174.077-.021a.45.45 0 00.293-.42V12.75l-.01-.081a.45.45 0 00-.368-.351l-.992-.165-.058-.165a3.571 3.571 0 00-.14-.32l.564-.836.044-.08a.45.45 0 00-.1-.494l-.676-.702-.071-.05a.45.45 0 00-.455-.01l-.84.613-.159-.076a3.498 3.498 0 00-.328-.127l-.169-.985-.021-.078a.45.45 0 00-.42-.293zm-.758 1.789l.148-.889h.212l.156.88c.061.197.2.327.374.37.263.066.515.175.747.32a.442.442 0 00.493-.005l.719-.528.155.153-.476.759a.45.45 0 00-.027.425l.025.094c.133.22.231.46.293.71l-.006.002c.055.216.186.338.35.37l.095.005.8.134v.213l-.885.156a.523.523 0 00-.37.374 2.708 2.708 0 01-.315.748.448.448 0 00.002.483l.53.731-.149.15-.749-.487a.52.52 0 00-.517-.006c-.24.145-.494.25-.76.315a.523.523 0 00-.34.402l-.145.832h-.218l-.152-.884a.517.517 0 00-.376-.37 2.623 2.623 0 01-.565-.212l-.253-.14a.451.451 0 00-.42.045l-.74.515-.146-.149.53-.727a.46.46 0 00.046-.413l-.058-.112a2.635 2.635 0 01-.316-.76.523.523 0 00-.402-.34l-.832-.15v-.213l.872-.143c.2-.061.33-.2.372-.374.067-.267.176-.523.321-.758a.448.448 0 00-.003-.49l-.505-.743.139-.145.73.507a.516.516 0 00.517.01 2.67 2.67 0 01.762-.304.451.451 0 00.337-.361z'
        />
    </svg>
)

export default SvgEntidades
