import { Text } from 'libs/components'
import React from 'react'
import { useSelector } from 'react-redux'
import { State } from 'redux/modules'

export const RelacaoEmpresasHeader: React.FC = () => {
    const doctorName = useSelector<State, string>(state => state.auth.user.nome)
    return (
        <div className='relacao-empresas--header'>
            <Text className='text-hello' weight='regular' color='black'>
                Olá, Dr. {doctorName}!
            </Text>
            <Text className='text-description' weight='regular' color='black-bis'>
                Para registrar a existência ou não de relações com empresas de produtos médicos, preencha as informações
                abaixo.
            </Text>
        </div>
    )
}
