/* tslint:disable */
import React from 'react'

const SvgLink = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 23 23' {...props}>
        <g>
            <path d='M7.737 9.484l-5.76 5.756c-1.96 1.96-1.96 3.798 0 5.758s3.798 1.96 5.758 0l2.759-2.759c.343-.343.343-.566 0-.91-.344-.343-.566-.343-.91 0l-2.759 2.76c-1.272 1.273-2.666 1.273-3.94 0-1.223-1.224-1.27-2.56-.14-3.792l.141-.148 5.76-5.756c1.224-1.224 2.56-1.27 3.793-.141l.23.22c.299.262.512.236.826-.079l.08-.087c.185-.23.169-.44.025-.679a1.032 1.032 0 00-.062-.092l-.043-.051c-1.96-1.96-3.798-1.96-5.758 0z' />
            <path d='M15.114 2.105l-2.759 2.759c-.343.343-.343.566 0 .91.344.343.566.343.91 0l2.758-2.76c1.273-1.273 2.667-1.273 3.94 0 1.224 1.224 1.27 2.56.141 3.792l-.14.147-5.76 5.764c-1.224 1.224-2.56 1.271-3.793.141l-.23-.22c-.299-.262-.512-.236-.826.08-.326.325-.25.633-.007.9l.184.181c1.894 1.785 3.68 1.728 5.58-.173l5.76-5.763c1.96-1.96 1.96-3.798 0-5.758s-3.798-1.96-5.758 0z' />
        </g>
    </svg>
)

export default SvgLink
