
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"6 min read","minutes":5.26,"time":315599.99999999994,"words":1052}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Aguardando dados clínicos” e no procedimento desejado clique no botão `}<strong parentName="li">{`“Preencher”`}</strong>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` apenas profissionais com perfil de “Médico Cardiologista” e “Residente em cardiologia” que partiparam da equipe médica do procedimento, podem registrar dados clínicos de procedimentos de Angioplastia.`}</p>
    </blockquote>
    <p><strong parentName="p">{`DADOS DO PROCEDIMENTO`}</strong></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Informe a data da cirurgia, hora de início e de término`}</li>
    </ol>
    <p><strong parentName="p">{`LESÃO TRATADA`}</strong></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Informe a dominância coronariana do paciente`}</li>
      <li parentName="ol">{`Adicione as lesões tratadas em coronárias nativas clicando no botão “Adicionar lesão em coronária nativa” e informe sobre a lesão:      `}<ul parentName="li">
          <li parentName="ul">{`Selecione na imagem os segmentos que se localiza a lesão
`}<img parentName="li" {...{
              "src": "/images/selecao-de-segmentos.gif#center",
              "alt": "Segmentos da coronária"
            }}></img></li>
          <li parentName="ul">{`Informe a classificação da lesão de acordo com a classificação AHA/ACC`}</li>
          <li parentName="ul">{`Informe o tipo da lesão`}</li>
          <li parentName="ul">{`Caso o tipo da lesão seja “Reestenose” informe o tipo de reestenose`}</li>
          <li parentName="ul">{`Caso o tipo da lesão seja “Trombose” informe o tipo de trombose`}</li>
          <li parentName="ul">{`Clique no botão “Salvar”`}</li>
        </ul></li>
    </ol>
    <p>{`Para excluir uma lesão, clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeExcluirLesao.png#center",
        "alt": "Excluir lesão"
      }}></img>{` na lesão desejada.`}</p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Adicione as lesões tratadas em enxerto clicando no botão “Adicionar lesão em enxerto” e:`}<ul parentName="li">
          <li parentName="ul">{`Informe o tipo de enxerto`}</li>
          <li parentName="ul">{`Se o tipo de enxerto for “Aorto-coronário” informe se é venoso ou arterial`}</li>
          <li parentName="ul">{`Se o tipo de enxerto for “In situ” informe se é Mamária interna esquerda ou direita.`}</li>
          <li parentName="ul">{`Informe a coronária de destino`}</li>
          <li parentName="ul">{`Informe a classificação da lesão de acordo com a classificação AHA/ACC`}</li>
          <li parentName="ul">{`Informe o tipo da lesão`}</li>
          <li parentName="ul">{`Caso o tipo da lesão seja “Reestenose” informe o tipo de reestenose`}</li>
          <li parentName="ul">{`Caso o tipo da lesão seja “Trombose” informe o tipo de trombose`}</li>
          <li parentName="ul">{`Clique no botão “Salvar”`}</li>
        </ul></li>
    </ol>
    <p>{`Para excluir uma lesão em enxerto clique no ícone `}<img parentName="p" {...{
        "src": "/images/iconeExcluirLesao.png#center",
        "alt": "Excluir lesão"
      }}></img>{` na lesão desejada.`}</p>
    <p><strong parentName="p">{`STENTS UTILIZADOS`}</strong></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Adicione os stents utilizados clicando no botão “ADICIONAR STENT”`}<ul parentName="li">
          <li parentName="ul">{`Informe o nome do stent`}</li>
          <li parentName="ul">{`Informe o diâmetro e o comprimento`}</li>
          <li parentName="ul">{`Informe em quais lesões/segmentos/enxertos o stent foi aplicado`}</li>
          <li parentName="ul">{`Informe se o stent foi implantado com sucesso`}</li>
          <li parentName="ul">{`Caso o stent não tenha sido implantado com sucesso informe o motivo da falha, o tipo e especificação da mesma`}</li>
          <li parentName="ul">{`Clique no botão “Salvar”`}</li>
        </ul></li>
    </ol>
    <p><strong parentName="p">{`APRESENTAÇÃO CLÍNICA`}</strong></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Informe o quadro clínico`}</li>
      <li parentName="ol">{`Caso o quadro clínico seja “Angina estável” informe a classe funcional`}</li>
      <li parentName="ol">{`Informe o carácter ICP`}</li>
      <li parentName="ol">{`Informe o padrão obstrutivo`}</li>
    </ol>
    <p><strong parentName="p">{`TESTE ISQUEMIA/IMAGEM`}</strong></p>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`Informe se foi realizado teste não invasivo de isquemia/imagem`}</li>
      <li parentName="ol">{`Caso tenha sido realizado algum teste:`}<ul parentName="li">
          <li parentName="ul">{`Informe se foi realizada uma Angio TC de coronária. Se sim, o tipo dela e se foi realizado o escore de cálcio, caso tenha sido informe o valor e o percentil;`}</li>
          <li parentName="ul">{`Informe se foi realizada uma Cintilografia miocárdica, ou um Eco de Estresse, ou um Teste ergométrico. Se sim, para cada caso informe a avaliação e o risco`}</li>
        </ul></li>
      <li parentName="ol">{`Clique no botão “Salvar registro”`}</li>
    </ol>
    <p><strong parentName="p">{`ANTECEDENTES`}</strong></p>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol">{`Informe se houve CRM prévia`}</li>
      <li parentName="ol">{`Informe se o paciente possui Diabetes mellitus`}</li>
      <li parentName="ol">{`Caso possua diabetes mellitus informe qual(is) o(s) tipo(s) de terapia utilizado`}</li>
      <li parentName="ol">{`Informe se o paciente possui Dislipidemia`}</li>
      <li parentName="ol">{`Caso possua Dislipidemia informe  qual(is) o(s) tipo(s) de terapia medicamentosa`}</li>
      <li parentName="ol">{`Informe se o paciente possui Doença cerebrovascular`}</li>
      <li parentName="ol">{`Informe se o paciente possui Doença pulmonar crônica`}</li>
      <li parentName="ol">{`Caso possua Doença pulmonar crônica informe se é dependente de O2`}</li>
      <li parentName="ol">{`Informe se o paciente possui Doença vascular periférica`}</li>
      <li parentName="ol">{`Informe se o paciente possui Fibrilação atrial crônica`}</li>
      <li parentName="ol">{`Caso possua fibrilação atrial crônica informe se teve anticoagulação oral`}</li>
      <li parentName="ol">{`Caso tome anticoagulação oral informe se é cumarínico, fator xa ou inibidor de trombina`}</li>
      <li parentName="ol">{`Informe se possui Função VE conhecida`}</li>
      <li parentName="ol">{`Caso possua Função VE conhecida informe o FE(quantitativo) e o FE em porcentagem`}</li>
      <li parentName="ol">{`Informe se o paciente possui Hipertensão`}</li>
      <li parentName="ol">{`Informe se o paciente possui IAM prévio, ou seja, não o da internação atual`}</li>
      <li parentName="ol">{`Informe se o paciente possui ICC`}</li>
      <li parentName="ol">{`Caso possua ICC informe a classe funcional`}</li>
      <li parentName="ol">{`Informe se o paciente possui ICP prévia`}</li>
      <li parentName="ol">{`Informe se o paciente possui Insuficiência renal`}</li>
      <li parentName="ol">{`Caso possua Insuficiência renal informe o IRC e a creatinina`}</li>
      <li parentName="ol">{`Informe se houve intervenção valvar prévia`}</li>
      <li parentName="ol">{`Caso possua intervenção valvar prévia informe o(s) tipo(s)`}</li>
      <li parentName="ol">{`Informe o Tabagismo, mesmo se o paciente for ex-tabagista.`}</li>
      <li parentName="ol">{`Caso o Tabagismo for marcado como 'Sim’, informe se é ativo ou ex-tabagista`}</li>
      <li parentName="ol">{`Informe se foi avaliado o índice de massa corporal`}</li>
      <li parentName="ol">{`Caso tenha avaliado o IMC, informe a altura e peso do paciente`}</li>
    </ol>
    <p><strong parentName="p">{`ICP-COMPLEMENTAR`}</strong></p>
    <ol {...{
      "start": 42
    }}>
      <li parentName="ol">{`Informe os dispositivos adjuntos, se houver`}</li>
      <li parentName="ol">{`Caso a opção “Dispositivo de assistência mecânica” for marcada informe se é Balão intra-aórtico, Impela ou Outro`}</li>
      <li parentName="ol">{`Informe os métodos auxiliares, se houver`}</li>
      <li parentName="ol">{`Se houve radiação informe o tempo de fluoroscopia, Air Kerma e DAP`}</li>
    </ol>
    <p><strong parentName="p">{`ACESSO ARTERIAL/CONTRASTE`}</strong></p>
    <ol {...{
      "start": 46
    }}>
      <li parentName="ol">{`Informe a via de acesso e o lado`}</li>
      <li parentName="ol">{`Informe se houve Crossover, marque apenas se houve mudança na via de acesso, e no campo “Via de acesso” marque a via de acesso final`}</li>
      <li parentName="ol">{`Informe o introdutor`}</li>
      <li parentName="ol">{`Informe o tipo de contraste, o uso do tipo de contraste habilita o campo “Valor total”`}</li>
      <li parentName="ol">{`Informe o volume total`}</li>
    </ol>
    <p><strong parentName="p">{`MEDICAÇÃO DO PROCEDIMENTO`}</strong></p>
    <ol {...{
      "start": 51
    }}>
      <li parentName="ol">{`Informe a medicação antiplaquetário`}</li>
      <li parentName="ol">{`Informe a medicação anticoagulação`}</li>
      <li parentName="ol">{`Informe o inibidor GB IIb/IIIa`}</li>
    </ol>
    <p><strong parentName="p">{`COMPLICAÇÕES CLÍNICAS`}</strong></p>
    <ol {...{
      "start": 54
    }}>
      <li parentName="ol">{`Informe se houve complicações em sala no procedimento`}</li>
      <li parentName="ol">{`Caso marcado que houve complicações:`}<ul parentName="li">
          <li parentName="ul">{`Informe se houve complicação cardíaca, se sim informe a gravidade `}</li>
          <li parentName="ul">{`Informe se houve complicação hemorrágica, se sim informe o sangramento`}</li>
          <li parentName="ul">{`Informe se houve complicação neurológica, se sim informe o tipo AVE`}</li>
          <li parentName="ul">{`Informe se houve complicação de reação ao contraste, se sim informe a severidade`}</li>
          <li parentName="ul">{`Informe se houve complicação respiratória, se sim informe o tipo`}</li>
          <li parentName="ul">{`Informe se houve complicação vascular, se sim informe quais`}</li>
          <li parentName="ul">{`Caso tenha ocorrido complicação vascular do tipo Hematoma, informe o tamanho do hematoma`}</li>
          <li parentName="ul">{`Informe se houve óbito, se sim informe se a causa foi cardíaca`}</li>
          <li parentName="ul">{`Informe a conclusão do procedimento`}</li>
        </ul></li>
      <li parentName="ol">{`Após preenchido os campos clique no botão “Salvar registro”`}</li>
    </ol>
    <p>{`Após o registro dos dados clínicos este procedimento fica disponível para preenchimento dos dados de produto, ou seja, os dados dos stents implantados no paciente.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona cada etapa do preenchimento do registro, `}<a parentName="p" {...{
          "href": "../fluxos/fluxoPreenchimentoRegistros"
        }}><strong parentName="a">{`clique aqui`}</strong></a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona o preenchimento de dados clínicos de angioplastia, consulte o vídeo explicativo abaixo:`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

