import * as GS1 from 'gs1-barcode-parser-mod'
import moment, { Moment } from 'moment'

// Objeto retornado pela library antes de ser normalizado.
//
// Como a library não é tipada via Typescript, esta estrutura
// foi inferida de acordo com o formato do output em um console.log
// e leitura do código-fonte.
interface UnormalizedObject {
    codeName: string
    parsedCodeItems: {
        ai: string
        dataTitle: string
        data: unknown
    }[]
}

// Interface para expor os dados para uso no RNI
export interface DecodedGS1 {
    GTIN: string
    fabricacao: Moment
    validade: Moment
    lote: string
    serial: string
    referencia: string
}

// Espécie de 'map' para converte um codigo AI para o seu respectivo
// nome, no contexto do RNI
const convertAiToName = {
    '01': 'GTIN', // [GTIN]: Código GTIN
    '11': 'fabricacao', // [PROD DATE]: Data de fabricação
    '17': 'validade', // [USE BY ou EXPIRY]: Data de validade
    '10': 'lote', // [BATCH/LOT]: Número Lote
    '21': 'serial', // [SERIAL]: Número de série
    '251': 'referencia', // [REF. TO SOURCE]: Código de referência da entidade de origem
}

// decodeGS1 realiza a decodificação de códigos de barra no formato GS1-128
export const decodeGS1 = (barcode: string): DecodedGS1 => {
    const libReturnedObj: UnormalizedObject = GS1.parseBarcode(barcode)
    const parsedItems = libReturnedObj && libReturnedObj.parsedCodeItems

    let normalizedObj: DecodedGS1 = {
        GTIN: undefined,
        fabricacao: undefined,
        lote: undefined,
        referencia: undefined,
        serial: undefined,
        validade: undefined,
    }
    for (let i = 0; i < parsedItems.length; i++) {
        const field = convertAiToName[parsedItems[i].ai]
        if (field === 'fabricacao' || field === 'validade') {
            normalizedObj[field] = moment(parsedItems[i].data)
            continue
        }
        normalizedObj[field] = parsedItems[i].data
    }

    return normalizedObj
}
