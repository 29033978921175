import * as React from 'react'
import InputMask from 'inputmask-core'
import { Columns, Column, Icon, Button, Text } from 'libs/components'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import { ProdutoDto } from 'backend'
import * as StringUtil from 'libs/util/StringUtil'

interface Props {
    produto: ProdutoDto
    onVoltar(): void
}

interface State {
    more: boolean
}

export class RastreabilidadeReportHeader extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = { more: false }
    }

    formatCnpj = (cnpj: string) => {
        const result = new InputMask({ pattern: '##.###.###/####-##', value: cnpj })
        return result.getValue()
    }

    toggleMore = () => {
        this.setState({ more: !this.state.more })
    }

    render() {
        const { produto } = this.props

        if (!produto) {
            return null
        }

        return (
            <HeaderContainer className='report-header-rastreabilidade'>
                <Button className='button-round button-back' onClick={this.props.onVoltar}>
                    <Icon icon='voltar' />
                </Button>
                <Columns>
                    <Column size='10'>
                        <p className='is-semibold is-grey-darker is-small is-uppercase'>Rastreabilidade de Produtos</p>
                        <p className=''>
                            <span className='is-semibold'>{produto.registroAnvisa}</span>{' '}
                            <span>{produto.nomeProduto}</span>
                        </p>
                        <p className='is-semibold is-grey-darker is-small'>
                            {StringUtil.capitalizeName(produto.nomeTecnico)}
                        </p>

                        {this.state.more && (
                            <div>
                                <br />
                                <p className='is-semibold is-grey-darker is-small-ter is-uppercase'>
                                    Detentor do registro
                                </p>
                                <p>
                                    <span data-name='cnpj' className='is-semibold'>
                                        {this.formatCnpj(produto.cnpj)}
                                    </span>{' '}
                                    - {produto.nomeFabricanteImportador}
                                </p>
                                <br />
                                <p className='is-semibold is-grey-darker is-small-ter is-uppercase'>Fabricante(s)</p>
                                {produto.fabricantes?.length > 0 ? (
                                    <ul className='list-circle'>
                                        {produto.fabricantes.map((data, index) => (
                                            <li key={'fabricante-' + index}> {data.fabricante}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <Text color='grey-darker' italic>
                                        Não informado
                                    </Text>
                                )}
                            </div>
                        )}
                    </Column>
                    <Column className='has-text-right'>
                        <p onClick={this.toggleMore} className='report-ver-mais'>
                            {this.state.more ? (
                                <span>
                                    Ver menos <Icon icon='arrowTop' />
                                </span>
                            ) : (
                                <span>
                                    Ver mais <Icon icon='arrowBottom' />
                                </span>
                            )}
                        </p>
                    </Column>
                </Columns>
            </HeaderContainer>
        )
    }
}
