
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.24,"time":14399.999999999998,"words":48}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Apenas os procedimentos que ainda não possuem dados clínicos cadastrados poderão ser excluídos.`}</p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Aguardando dados clínicos”`}</li>
      <li parentName="ol">{`No procedimento que você deseja excluir clique no ícone  `}<img parentName="li" {...{
          "src": "/images/iconeExcluir.png#center",
          "alt": "Excluir"
        }}></img></li>
      <li parentName="ol">{`Na mensagem de confirmação exibida pelo sistema, clique no botão “Confirmar”.`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

