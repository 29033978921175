import React from 'react'
import { Font, Image, Link, StyleSheet, Text } from '@react-pdf/renderer'
import OpenSans from './fontes/open.ttf'
import OpenSansBold from './fontes/OpenSans-Bold.ttf'
import { PDFLayout } from './PDFLayout'
import { MDXProvider } from '@mdx-js/react'
import { arquivosPdf } from './ManualCompletoUsuarioView'
import { PerfilEnum } from 'api/Perfis'

export interface ManualUsuarioPDFViewProps {
    md: string
    titulo: string
    topico: string
    versao: string
    perfil: string
}

export function ManualUsuarioPDFView(props: ManualUsuarioPDFViewProps) {
    return (
        <PDFLayout versao={props.versao}>
            <MDXProvider components={mdxComponents}>
                <>
                    {arquivosPdf
                        .filter(a => a.path === props.md)
                        .filter(p => p.perfil.includes(PerfilEnum[props.perfil]))
                        .map(arq => (
                            <Markdown key={arq.path} arquivo={arq} titulo={props.titulo} topico={props.topico} />
                        ))}
                </>
            </MDXProvider>
        </PDFLayout>
    )
}

interface MarkdownProps {
    arquivo: typeof arquivosPdf[0]
    titulo: string
    topico: string
}

function Markdown(props: MarkdownProps) {
    const {
        arquivo: { component: Component },
    } = props
    return (
        <>
            {props.topico ? <Text style={styles.topico}>{props.topico}</Text> : null}
            {props.titulo && <Text style={styles.heading}>{props.titulo}</Text>}
            {Component && <Component />}
        </>
    )
}

Font.register({
    family: 'Open Sans',
    src: OpenSans,
})

Font.register({
    family: 'Open Sans-Bold',
    src: OpenSansBold,
})

export const IMAGE_DIMENSIONS = {
    '/images/codigoBarras.png': { width: 440, height: 85 },
    '/images/quadrilRevisao.png': { width: 410, height: 370 },
    '/images/comboDePeriodo.png': { width: 220, height: 220 },
    '/images/preenchimentoFicha.png': { width: 430, height: 550 },
    '/images/cadastroUsuario.png': { width: 480, height: 230 },
}

export const IMAGE_POSITIONS = {
    '/images/codigoBarras.png': { textAlign: 'right' },
    '/images/quadrilRevisao.png': { textAlign: 'right' },
    '/images/comboDePeriodo.png': { textAlign: 'center' },
    '/images/preenchimentoFicha.png': { textAlign: 'right' },
    '/images/cadastroUsuario.png': { textAlign: 'right' },
}

export const mdxComponents = {
    wrapper: props => props.children,
    h1: props => <Text {...props} style={styles.heading} />,
    h2: props => <Text {...props} style={styles.heading2} />,
    h3: props => <Text {...props} style={styles.heading3} />,
    h4: props => <Text {...props} style={styles.heading4} />,
    p: props => {
        const childs = React.Children.toArray(props.children)
        if (childs.length === 1) {
            const firstChild = childs[0] as any
            if (firstChild.props && firstChild.props.name === 'img') {
                return firstChild
            }
        }
        return <Text style={styles.paragraph} {...props} />
    },
    a: props => <Link {...props} />,
    img: props => {
        if (props.src.endsWith('.gif#center')) {
            return null
        } else {
            return (
                <Text style={IMAGE_POSITIONS[props.src]}>
                    &nbsp;&nbsp;
                    <Image {...props} style={IMAGE_DIMENSIONS[props.src]} />
                    &nbsp;
                </Text>
            )
        }
    },
    ul: props => {
        return React.Children.map(props.children, child => {
            return (
                <Text style={styles.list} {...props}>
                    {'\n'} &nbsp;&nbsp;&nbsp;&nbsp;&#149;&nbsp;&nbsp;{child}
                </Text>
            )
        })
    },
    ol: props => {
        return React.Children.map(props.children, (child, index) => {
            return (
                <Text style={styles.list} {...props}>
                    {'\n'}
                    {props.start ? props.start + index + '.' : index + 1 + '.'}
                    <Text>&nbsp;&nbsp;</Text>
                    {child}
                </Text>
            )
        })
    },
    li: props => {
        return <Text {...props} style={styles.listItem} />
    },
    strong: props => <Text style={styles.strong} {...props} />,
    blockquote: props => <Text style={styles.blockquote} {...props} />,
    br: props => <Text {...props}>{'\n'}</Text>,
}

export const styles = StyleSheet.create({
    heading: {
        fontSize: 11,
        marginTop: 20,
        marginBottom: 5,
        fontFamily: 'Open Sans-Bold',
        marginLeft: 30,
    },
    heading2: {
        fontSize: 12,
        marginTop: 10,
        fontFamily: 'Open Sans-Bold',
        marginLeft: 30,
    },
    heading3: {
        fontSize: 10,
        marginTop: 10,
        fontFamily: 'Open Sans-Bold',
        marginLeft: 30,
    },
    heading4: {
        fontSize: 10,
        marginTop: 10,
        fontFamily: 'Open Sans-Bold',
        marginLeft: 30,
    },
    paragraph: {
        marginTop: 10,
        fontSize: 9,
        marginLeft: 30,
        marginRight: 30,
    },
    topico: {
        fontFamily: 'Open Sans-Bold',
        backgroundColor: '#f7f7f7',
        padding: '7px',
        marginTop: 20,
        border: '1pt solid #e6e6e6',
        fontSize: 10,
        marginLeft: 15,
        marginRight: 15,
    },
    strong: {
        fontFamily: 'Open Sans-Bold',
    },
    listItem: {
        fontSize: 9,
    },
    list: {
        marginLeft: 40,
        marginRight: 40,
        fontSize: 9,
    },
    blockquote: {
        backgroundColor: '#f7f7f7',
        padding: '7px',
        marginTop: 15,
        marginBottom: 5,
        border: '1pt solid #e6e6e6',
        fontSize: 9,
        marginLeft: 30,
        marginRight: 30,
    },
})
