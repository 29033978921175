import * as React from 'react'
import { DistribuicaoHospitalProcedimentoDto } from 'backend'
import { Card, LoadingContainer, Column, Columns, NumberLabel } from 'libs/components'
import { InvertedLabel } from 'view/componentes/reports/components/InvertedLabel'
import { ValuePercentLabel } from 'view/componentes/reports/components/ValuePercentLabel'

interface Props {
    info: DistribuicaoHospitalProcedimentoDto
    isFetching: boolean
}

export class InfoDadosProcedimentosCard extends React.Component<Props> {
    render() {
        const { info, isFetching } = this.props

        return (
            <Card title='Dados de procedimentos'>
                <LoadingContainer isFetching={isFetching}>
                    {info && (
                        <Columns>
                            <Column size='2' className='has-border-right'>
                                <InvertedLabel title='Procedimentos'>
                                    <NumberLabel value={info.total} placeholder='-' />
                                </InvertedLabel>
                            </Column>
                            <Column size='10'>
                                <Columns className='is-small-bis is-black-bis spacing-one-half'>
                                    <Column size='6'>
                                        <ValuePercentLabel
                                            value={info.countStatus['AGUARDANDO_DADOS_CLINICOS']}
                                            total={info.total}
                                            text={'Aguardando dados clínicos'}
                                        />
                                    </Column>
                                    <Column size='6'>
                                        <ValuePercentLabel
                                            value={info.countStatus['AGUARDANDO_DADOS_FINANCEIRO']}
                                            total={info.total}
                                            text={'Aguardando dados financeiros'}
                                        />
                                    </Column>
                                </Columns>
                                <hr style={{ marginTop: '1rem' }} />
                                <Columns className='is-small-bis is-black-bis spacing-one-half'>
                                    <Column size='6'>
                                        <ValuePercentLabel
                                            value={info.countStatus['AGUARDANDO_DADOS_PRODUTO']}
                                            total={info.total}
                                            text={'Aguardando dados de produtos'}
                                        />
                                    </Column>
                                    <Column size='6'>
                                        <ValuePercentLabel
                                            value={info.countStatus['FINALIZADO']}
                                            total={info.total}
                                            text={'Finalizados'}
                                        />
                                    </Column>
                                </Columns>
                            </Column>
                        </Columns>
                    )}
                </LoadingContainer>
            </Card>
        )
    }
}
