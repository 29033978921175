import { createValidator } from 'libs/validation'
import DadosProdutoValidator from 'view/home/rastreabilidade/dadosProduto/DadosProdutoValidator'
import DadosFinanceirosValidator from 'view/home/rastreabilidade/dadosFinanceiro/DadosFinanceirosValidator'

const RastreabilidadeGeralValidator = createValidator({
    dadosProdutoForm: DadosProdutoValidator,
    dadosFinanceirosForm: DadosFinanceirosValidator,
})

export default RastreabilidadeGeralValidator
