import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as enums from 'redux/enums'
import remove from 'lodash/remove'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)
require('highcharts/modules/offline-exporting')(Highcharts)

export type ChartOptions = Highcharts.Options

export interface ChartProps {
    config: ChartOptions
}

export class Chart extends React.Component<ChartProps> {
    public chart: Highcharts.Chart

    getChart(): Highcharts.Chart {
        return this.chart
    }

    render() {
        return <HighchartsReact highcharts={Highcharts} options={this.props.config} />
    }
}

// Hightchart default options

const exportingLangOptions = {
    downloadCSV: 'Baixar tabela CSV',
    downloadXLS: 'Baixar tabela XLS',
    viewData: 'Ver tabela de dados',
}

const visibleMenuItems = [
    'printChart',
    // 'downloadJPEG',
    // 'downloadPDF',
    'downloadPNG',
    'downloadSVG',
    'downloadCSV',
    'downloadXLS',
    // 'viewData',
]

const menuItems = remove(
    Highcharts.getOptions().exporting.buttons.contextButton.menuItems,
    (item: any) => visibleMenuItems.indexOf(item) >= 0
)

Highcharts.setOptions({
    credits: {
        enabled: false,
    },
    chart: {
        style: {
            fontFamily: 'Open Sans',
        },
    },
    colors: [...Object.values(enums.classificacaoCirurgiaReportColor), '#434348'],
    plotOptions: {
        area: {
            fillOpacity: 0.05,
        },
    },
    exporting: {
        buttons: {
            contextButton: {
                menuItems: menuItems,
            },
        },
    },
    lang: {
        ...(exportingLangOptions as any),
        decimalPoint: ',',
        printChart: 'Imprimir gráfico',
        downloadJPEG: 'Baixar imagem JPEG',
        downloadPDF: 'Baixar documento PDF',
        downloadPNG: 'Baixar imagem PNG',
        downloadSVG: 'Baixar vetor SVG',
        drillUpText: 'Voltar para {series.name}',
        invalidDate: '',
        loading: 'Carregando...',
        months: [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
        ],
        noData: 'Não há data a mostrar',
        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        thousandsSep: '.',
        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    },
})
