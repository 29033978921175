/* tslint:disable */
import React from 'react'

const SvgEstrela = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M6.314 20.75L7.4 14.418 2.8 9.934l6.357-.923L12 3.25l2.843 5.76 6.358.924-4.6 4.484 1.085 6.332L12 17.76l-5.686 2.99zm1.988-2.736L12 16.07l3.698 1.944-.706-4.118 2.992-2.916-4.135-.601L12 6.632l-1.849 3.747-4.135.6 2.992 2.917-.706 4.118z' />
    </svg>
)

export default SvgEstrela
