import * as React from 'react'
import { StentUtilizadoVisualizacaoDto } from 'backend'
import * as C from 'libs/components'

interface StentsUtilizadosProps {
    stent: StentUtilizadoVisualizacaoDto
}

export class StentsUtilizadosCard extends React.Component<StentsUtilizadosProps> {
    render() {
        return (
            <div className='with-border-left'>
                <C.ComposedLabel
                    horizontal
                    title={this.props.stent.nome}
                    placeholder={' (' + this.props.stent.tipoStent + ')'}
                />
                {this.props.stent.lesoesCobertas.map((element, index) => (
                    <C.ComposedLabel key={element.nomeLesao + index} horizontal title={element.nomeLesao}>
                        {' '}
                        {element.segmentos}{' '}
                    </C.ComposedLabel>
                ))}
            </div>
        )
    }
}
