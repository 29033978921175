import { AutenticacaoEntidadeData } from '../GerenciarChavesView'
import { Linha } from 'libs/components'
import { asClientFormat } from 'libs/util/DateUtil'
import React from 'react'
import {
    AutenticacaoIntegracaoInnerRow,
    AutenticacaoIntegracaoInnerRowViewStatus,
} from 'view/home/gestorIntegracao/componentes/autenticacao/AutenticacaoIntegracaoInnerRow'
import { IntegracaoRowItemHeader } from 'view/home/gestorIntegracao/componentes/common/IntegracaoRowItemHeader'
import Aviso from 'view/componentes/Aviso'

interface AutenticacaoEntidadeRowProps {
    autenticacao: AutenticacaoEntidadeData
    generateLogin: (entidadeId: number) => void
    generateSenha: (entidadeId: number) => void
}

export const AutenticacaoEntidadeRow: React.FC<AutenticacaoEntidadeRowProps> = React.memo(
    ({ autenticacao, generateLogin, generateSenha }) => {
        let statusLogin, statusSenha: AutenticacaoIntegracaoInnerRowViewStatus
        if (autenticacao?.ativo === false) {
            statusLogin = statusSenha = AutenticacaoIntegracaoInnerRowViewStatus.BLOCKED
        } else {
            if (autenticacao?.login === null) {
                statusLogin = AutenticacaoIntegracaoInnerRowViewStatus.FIRST_TIME
            }
            if (autenticacao?.senha === null) {
                statusSenha = AutenticacaoIntegracaoInnerRowViewStatus.FIRST_TIME
            }
        }
        return (
            <>
                <div className='autenticacao-integracao-row'>
                    <IntegracaoRowItemHeader
                        title={autenticacao.entidadeConsumidora.entidadeNome.toUpperCase()}
                        subtitle={'Cadastrada em ' + asClientFormat(autenticacao.entidadeConsumidora.dataCadastro)}
                    />
                    <div className='autenticacao-integracao-row-content'>
                        <AutenticacaoIntegracaoInnerRow
                            title='Login'
                            value={autenticacao?.login}
                            date={autenticacao?.dataRegistroLogin}
                            generateFunction={() => generateLogin(autenticacao.entidadeConsumidora.id)}
                            specialStatus={statusLogin}
                            readyState={autenticacao?.readyStateLogin || 'success'}
                        />
                        <Linha className='autenticacao-integracao-row-content-linha' />
                        <AutenticacaoIntegracaoInnerRow
                            title='Senha'
                            value={autenticacao?.senha}
                            date={autenticacao?.dataRegistroSenha}
                            generateFunction={() => generateSenha(autenticacao.entidadeConsumidora.id)}
                            specialStatus={statusSenha}
                            readyState={autenticacao?.readyStateSenha || 'success'}
                        />
                    </div>
                </div>

                {statusLogin === AutenticacaoIntegracaoInnerRowViewStatus.BLOCKED && statusLogin === statusSenha && (
                    <Aviso color='red' style={{ marginTop: 10 }}>
                        Foram realizadas 5 tentativas de consumo de dados utilizando uma senha incorreta. É necessário
                        gerar um novo login.
                    </Aviso>
                )}
            </>
        )
    }
)
