import * as React from 'react'
import classnames from 'classnames'
import * as Util from 'libs/components/Util'

interface InputPanelProps extends Util.UtilProps {
    dataName?: string
    fallback?: boolean
    size?: 'medium' | 'normal'
    className?: string
}

export class InputPanel extends React.Component<InputPanelProps> {
    render() {
        const classes = classnames('inputpanel', this.props.className, Util.helpersClassnames(this.props), {
            medium: this.props.size && this.props.size === 'medium',
            normal: this.props.size && this.props.size === 'normal',
            fallback: this.props.fallback,
        })
        return (
            <span data-name={this.props.dataName} className={classes}>
                {this.props.children}
            </span>
        )
    }
}
