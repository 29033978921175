import * as React from 'react'
import classnames from 'classnames'

interface Props {
    className?: string
    title?: string
}

export class Tag extends React.PureComponent<Props> {
    render() {
        return (
            <span title={this.props.title} className={classnames('tag', this.props.className)}>
                {this.props.children}
            </span>
        )
    }
}

export const Tags = ({ children }) => {
    return <div className='tags'>{children}</div>
}
