import { connect } from 'react-redux'
import HomeAdministrativoView from 'view/home/administrativo/HomeAdministrativoView'
import * as rastreabilidade from 'redux/modules/rastreabilidade'
import * as procedimento from 'redux/modules/procedimento'
import { RouteComponentProps } from 'react-router-dom'
import { State } from 'redux/modules'
import { tiposProcedimentoUrlByEnum } from 'api/Procedimento'
import { createPaginator, getPage, isFetching } from 'redux/middleware/PaginationAction'
import { ProcedimentoRow } from 'backend'
import { setIdProcedimento } from 'redux/modules/edicaoProcedimento'

const url = '/api/procedimentos'

const mapStateToProps = (state: State) => {
    return {
        isFetchingAguardandoDadosProduto: state.procedimento.dadosProduto.readyState === 'request',
        isFetchingAguardandoDadosFinanceiros: state.procedimento.dadosFinanceiros.readyState === 'request',
        isFetchingHistorico: isFetching(url + '/historico')(state),
        aguardandoDadosProduto: state.procedimento.dadosProduto.data,
        aguardandoDadosFinanceiros: state.procedimento.dadosFinanceiros.data,
        historico: getPage<ProcedimentoRow>(url + '/historico')(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps: RouteComponentProps<any>) => {
    const historicoPaginator = createPaginator(dispatch, url + '/historico')
    return {
        loadDadosProdutoAdministrativo: () => {
            return dispatch(procedimento.getAguardandoDadosProduto())
        },

        loadDadosFinanceiroAdministrativo: () => {
            return dispatch(procedimento.getAguardandoDadosFinanceiros())
        },

        loadDadosHistorico: (page: number) => {
            historicoPaginator.page(page)
        },

        preencherDadosProdutoFinanceiro: (ficha: ProcedimentoRow) => {
            return dispatch(rastreabilidade.findProcedimento({ idProcedimento: ficha.procedimentoId })).then(() => {
                dispatch(rastreabilidade.isOnlyFinanceiro(false))
                ownProps.history.push('/home/dadosProdutoFinanceiro')
            })
        },

        editarDadosProduto: (ficha: ProcedimentoRow) => {
            dispatch(setIdProcedimento(ficha.procedimentoId))
            return dispatch(rastreabilidade.findProcedimento({ idProcedimento: ficha.procedimentoId })).then(() => {
                ownProps.history.push('/home/edicaoProcedimento/dadosProduto/')
            })
        },

        preencherDadosFinanceiro: (ficha: ProcedimentoRow) => {
            return dispatch(rastreabilidade.findProcedimento({ idProcedimento: ficha.procedimentoId })).then(() => {
                dispatch(rastreabilidade.isOnlyFinanceiro(true))
                ownProps.history.push('/home/dadosFinanceiro')
            })
        },

        editarDadosFinanceiro: (ficha: ProcedimentoRow) => {
            dispatch(setIdProcedimento(ficha.procedimentoId))
            return dispatch(rastreabilidade.findProcedimento({ idProcedimento: ficha.procedimentoId })).then(() => {
                if (ficha.rastreabilidadeUnica) {
                    ownProps.history.push('/home/edicaoProcedimento/dadosProdutoFinanceiro/')
                } else {
                    ownProps.history.push('/home/edicaoProcedimento/dadosFinanceiro/')
                }
            })
        },

        imprimirFicha: procedimentoId => {
            window.open('/api/procedimentos/impressaoRegistro/' + procedimentoId, '_blank')
        },

        visualizarFicha: (idProcedimento, proced) => {
            const procedimentoUrl = tiposProcedimentoUrlByEnum[proced]
            ownProps.history.push(`/home/visualizacao/${procedimentoUrl}/` + idProcedimento)
        },
    }
}

const HomeAdministrativoContainer = connect(mapStateToProps, mapDispatchToProps)(HomeAdministrativoView as any)

export default HomeAdministrativoContainer
