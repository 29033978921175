import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'

interface IntegracaoHorizontalTabMenuProps {
    pathFirstTab: string
    titleFirstTab: string
    pathSecondTab: string
    titleSecondTab: string
}

export const IntegracaoHorizontalTabMenu = (props: IntegracaoHorizontalTabMenuProps) => {
    const { pathFirstTab, titleFirstTab, pathSecondTab, titleSecondTab } = props
    const location = useLocation()
    const history = useHistory()
    const isTabActive = (pathname: string) => location.pathname === pathname
    return (
        <HorizontalTabMenu className='integracao-tab-menu'>
            <HorizontalTabMenuItem
                title={titleFirstTab}
                active={isTabActive(pathFirstTab)}
                onClick={() => {
                    if (!isTabActive(pathFirstTab)) {
                        history.push(pathFirstTab)
                    }
                }}
            />
            <HorizontalTabMenuItem
                title={titleSecondTab}
                active={isTabActive(pathSecondTab)}
                onClick={() => {
                    if (!isTabActive(pathSecondTab)) {
                        history.push(pathSecondTab)
                    }
                }}
            />
        </HorizontalTabMenu>
    )
}
