import * as React from 'react'
import * as C from 'libs/components'
import { connect } from 'react-redux'
import { createPaginator, getPage, isFetching } from 'redux/middleware/PaginationAction'
import { formValueSelector } from 'redux-form/immutable'
import { DistribuicaoProdutosFiltro, DistribuicaoProdutosSemRegistroRow, TipoProcedimento } from 'backend'
import { tiposProcedimentoUrlByEnum } from 'api/Procedimento'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import cardiologia from 'images/home/perfil/ic-cardiologia.svg'
import ortopedia from 'images/home/perfil/ic-ortopedia.svg'
import { State } from 'redux/modules'
import { Dispatch, Action } from 'redux'
import {
    Image,
    LoadingContainer,
    CardFilterable,
    Columns,
    Column,
    TextField,
    Button,
    PagedTable,
    TableColumnHeader,
    Tag,
    Text,
    IconButton,
} from 'libs/components'

interface Props extends RouteComponentProps<any> {
    empresa: any
    isFetching: boolean
    distProdutosSemRegistro: any
    distProdutosSemRegistroFilter: any
    loadProdutosSemRegistro(pageable: any): any
    loadProdutosSemRegistroFilter(filter: DistribuicaoProdutosFiltro): any
    visualizarFicha(idProcedimento: number, tipoProcedimento: TipoProcedimento): any
    clear(): any
}

export class RastreabilidadeTableSemRegistro extends React.Component<Props> {
    componentDidMount() {
        this.props.loadProdutosSemRegistroFilter({})
        this.loadTable({})
    }

    loadTable(pageable) {
        this.props.loadProdutosSemRegistro(pageable)
    }

    render() {
        const distProdutos = this.props.distProdutosSemRegistroFilter
            ? this.props.distProdutosSemRegistroFilter
            : this.props.distProdutosSemRegistro
        const filtrar = () =>
            this.props.loadProdutosSemRegistroFilter({
                nomeEmpresa: this.props.empresa,
            })
        return (
            <div className='report-content'>
                <LoadingContainer isFetching={this.props.isFetching}>
                    <CardFilterable
                        title={distProdutos && distProdutos.totalElements + ' etiqueta(s) sem registro'}
                        noPadding={true}
                        defaultVisible={true}
                        report={
                            <Link
                                to={{
                                    pathname: url + '/csv',
                                }}
                                target='_blank'
                                title='Exportar para CSV'
                            >
                                <C.Text color='grey-darker'>
                                    <C.Icon icon='exportar' />
                                </C.Text>
                            </Link>
                        }
                        filter={
                            <Columns>
                                <Column size='10'>
                                    <TextField
                                        name='empresa'
                                        placeholder='Nome da empresa'
                                        onKeyPress={e => (e.key === 'Enter' ? filtrar() : null)}
                                    />
                                </Column>
                                <Column>
                                    <Button onClick={() => filtrar()} type='primary'>
                                        Pesquisar
                                    </Button>
                                </Column>
                            </Columns>
                        }
                    >
                        <PagedTable
                            className='report-table-row is-small-bis is-spaced'
                            name='produtosSemRegistro'
                            values={null}
                            onChangePage={this.loadTable.bind(this)}
                            page={distProdutos}
                            emptyText='Nenhum resultado encontrado.'
                        >
                            <TableColumnHeader
                                title='Empresa'
                                render={value => <Text weight='semibold'>{value.nomeEmpresa}</Text>}
                            />
                            <TableColumnHeader
                                title='Procedimento'
                                render={value =>
                                    value.tipoProcedimento === 'STENT_CORONARIA' ? (
                                        <span>
                                            <Image className='imagem-perfil' src={cardiologia} size='14x14' />{' '}
                                            Angioplastia
                                        </span>
                                    ) : (
                                        <span>
                                            <Image className='imagem-perfil' src={ortopedia} size='14x14' />{' '}
                                            Artroplastia
                                        </span>
                                    )
                                }
                            />
                            <TableColumnHeader title='Hospital' render={value => value.nomeHospital} />
                            <TableColumnHeader
                                title='Data do procedimento'
                                render={value => {
                                    return <Tag>{value.dataRegistro}</Tag>
                                }}
                            />
                            <TableColumnHeader
                                title=''
                                render={value => (
                                    <C.Hint
                                        placement='top'
                                        componente={
                                            <IconButton
                                                disabled={value.showMore ? false : true}
                                                icon='arrowRight'
                                                size='is-x-small'
                                                pulledRight
                                                onClick={() =>
                                                    this.props.visualizarFicha(
                                                        value.idProcedimento,
                                                        value.tipoProcedimento
                                                    )
                                                }
                                            />
                                        }
                                    >
                                        {value.showMore
                                            ? 'Visualizar registro'
                                            : 'Dados de paciente não correspondentes à abrangência desta vigilância.'}
                                    </C.Hint>
                                )}
                            />
                        </PagedTable>
                    </CardFilterable>
                </LoadingContainer>
            </div>
        )
    }
}

const selector = formValueSelector('distribuicaoProdutos')
const url: string = '/api/report/produtosSemRegistro'

export default withRouter(
    connect(
        (state: State) => ({
            distProdutosSemRegistro: getPage<DistribuicaoProdutosSemRegistroRow>(url)(state),
            distProdutosSemRegistroFilter: getPage<DistribuicaoProdutosSemRegistroRow>(url)(state),
            isFetching: isFetching(url)(state),
            empresa: selector(state, 'empresa'),
        }),

        (dispatch: Dispatch<Action<any>>, ownProps: Props) => {
            const distProdutosSemRegistroPaginator = createPaginator(dispatch, '/api/report/produtosSemRegistro')

            return {
                loadProdutosSemRegistro: (page: number) => {
                    distProdutosSemRegistroPaginator.page(page)
                },

                loadProdutosSemRegistroFilter: (filtro: DistribuicaoProdutosFiltro) => {
                    distProdutosSemRegistroPaginator.filter(filtro)
                },

                clear: () => {
                    distProdutosSemRegistroPaginator.clear()
                },

                visualizarFicha: (idProcedimento: number, tipoProcedimento: TipoProcedimento) => {
                    const procedimentoUrl = tiposProcedimentoUrlByEnum[tipoProcedimento]
                    ownProps.history.push(`${ownProps.match.url}/visualizacao/${procedimentoUrl}/` + idProcedimento)
                },
            }
        }
    )(RastreabilidadeTableSemRegistro)
) as any
