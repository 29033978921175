import * as React from 'react'
import * as C from 'libs/components'

interface LazyImageProps {
    src: string
}

interface LazyImageState {
    loaded: boolean
    error: boolean
}

export class LazyImage extends React.Component<LazyImageProps, LazyImageState> {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            error: false,
        }
    }

    componentDidMount() {
        const img = new Image()
        img.onload = () => {
            this.setState({ loaded: true })
        }
        img.onerror = () => {
            this.setState({ error: true })
        }
        img.src = this.props.src
    }

    render() {
        if (this.state.error) {
            return <span>Erro ao carregar imagem.</span>
        } else if (!this.state.loaded) {
            return <C.LoadingAnimation />
        }
        return <img {...this.props} alt='' />
    }
}
