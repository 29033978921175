/* tslint:disable */
import React from 'react'

const SvgCheckboxCheck = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 14 14' {...props}>
        <defs>
            <filter
                x='-35%'
                y='-31.8%'
                width='170%'
                height='189.1%'
                filterUnits='objectBoundingBox'
                id='checkbox-check_svg__a'
            >
                <feOffset dy={1} in='SourceAlpha' result='shadowOffsetOuter1' />
                <feGaussianBlur stdDeviation={1} in='shadowOffsetOuter1' result='shadowBlurOuter1' />
                <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0' in='shadowBlurOuter1' />
            </filter>
            <path
                d='M5.812 7.502l-2.129-2.13-1.68 1.681 3.805 3.805.249-.249.005.005 5.934-5.934-1.68-1.681-4.504 4.503z'
                id='checkbox-check_svg__b'
            />
        </defs>
        <g>
            <use filter='url(#checkbox-check_svg__a)' xlinkHref='#checkbox-check_svg__b' />
            <use xlinkHref='#checkbox-check_svg__b' />
        </g>
    </svg>
)

export default SvgCheckboxCheck
