import { createValidator, required } from 'libs/validation'

export const TipoArtroplastiaValidator = createValidator(
    {
        tipoArtroplastia: [required],
    },
    formValidator
)

function formValidator(form) {
    const errors = {}

    const tipoArt = form.get('tipoArtroplastia')
    if (tipoArt && tipoArt.id === 'CONVENCIONAL') {
        errors.parTribologico = required(form.get('parTribologico'))
    }
    return errors
}
