import { connect } from 'react-redux'
import { State } from 'redux/modules'
import { DistProdutosView, Props } from './DistProdutosView'
import { withRouter } from 'react-router-dom'
import * as produtos from 'redux/modules/report/produto'
import { DistribuicaoProcedimentoFiltro, ProdutosProcedimentosRow } from 'backend'
import * as filtroReport from 'redux/modules/report/filtro'
import { PageRequester } from 'redux/requester'
import Report from 'api/Report'

const paginator = new PageRequester<ProdutosProcedimentosRow, DistribuicaoProcedimentoFiltro>(
    'produtosProcedimentos',
    filtro => {
        return Report.getProdutosProcedimentos(filtro)
    }
)

const mapStateToProps = (state: State, ownProps: Props) => ({
    filtro: state.report.filtro.filtro,
    componentes: paginator.getResult(state),
    page: paginator.getResult(state),
    isFetching: paginator.getIsFetching(state),
})
const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    find: value => {
        if (value) {
            dispatch(produtos.clearFindResultProdutos())
            const filtro: DistribuicaoProcedimentoFiltro = value
            dispatch(paginator.setParams(filtro))
            dispatch(paginator.request())
        }
    },
    changeFilter: (filtro: DistribuicaoProcedimentoFiltro) => {
        dispatch(filtroReport.change({ ...filtro }))
    },
    clearFindResultProdutos: () => {
        dispatch(paginator.clearResult())
    },
    handlePageChange: (page: number) => {
        dispatch(paginator.setPageNumber(page))
        dispatch(paginator.request())
    },
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DistProdutosView as any))
