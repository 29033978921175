import React from 'react'
import { reduxForm, formValueSelector, FieldArray } from 'redux-form/immutable'
import { connect } from 'react-redux'
import * as C from 'libs/components'
import * as FichaComponents from 'view/home/novoProcedimento/ficha/componentes'
import * as UUID from 'libs/util/UUID'
import LesoesNativasModalValidator from 'view/home/novoProcedimento/ficha/cardio/validators/LesoesNativasModalValidator'

const tipoLesaoOptions = {
    DE_NOVO: 'De novo',
    OCLUSAO_CRONICA: 'Oclusão crônica',
    REESTENOSE: 'Reestenose',
    TROMBOSE: 'Trombose',
}

const tipoLesaoCompostaOptions = {
    BMS: 'BMS',
    BVS: 'BVS',
    DES: 'DES',
    STENT_PREVIO_DESCONHECIDO: 'Stent prévio não conhecido',
    AGUDA: 'Aguda',
    SUBAGUDA: 'Subaguda',
    TARDIA: 'Tardia',
}

export class AdicionarLesaoNativa extends React.Component {
    render() {
        return (
            <C.Conteudo>
                <C.ControlField name={this.props.name} label='Lesões em coronárias nativas' />
                <FieldArray
                    name={this.props.name}
                    disabled={this.props.disabled}
                    lesoesRelacionadas={this.props.lesoesRelacionadas}
                    component={LesaoTratadas}
                    options={this.props.options}
                />
            </C.Conteudo>
        )
    }
}

class LesaoTratadas extends React.Component {
    constructor() {
        super()
        this.state = {
            openForm: false,
        }
        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.save = this.save.bind(this)
        this.delete = this.delete.bind(this)
    }

    render() {
        return (
            <ul>
                {(!this.props.fields || this.props.fields.length === 0 || this.props.fields.size === 0) && (
                    <C.EmptyListItem label='Não há lesões adicionadas.' />
                )}
                {this.props.fields.map((name, index) => (
                    <LesaoCard
                        chave={index}
                        key={index}
                        values={this.props.fields.get(index)}
                        onClickDelete={this.delete(index)}
                        options={this.props.options}
                        lesoesRelacionadas={
                            this.props.lesoesRelacionadas &&
                            this.props.lesoesRelacionadas.indexOf(this.props.fields.get(index).get('id'))
                        }
                    />
                ))}
                {
                    <C.Button
                        type='grey'
                        square
                        dashed
                        size='large'
                        fullwidth
                        disabled={this.props.disabled}
                        onClick={this.open}
                    >
                        + ADICIONAR LESÃO EM CORONÁRIA NATIVA
                    </C.Button>
                }
                {this.state.openForm && (
                    <LesaoTratadaForm onSubmit={this.save} close={this.close} options={this.props.options} />
                )}
            </ul>
        )
    }

    open() {
        this.setState({ openForm: true })
    }

    close() {
        this.setState({ openForm: false })
    }

    save(form) {
        form = form.set('id', UUID.generateUUID())
        this.props.fields.push(form)
        this.close()
    }

    delete(index) {
        return () => {
            this.props.fields.remove(index)
        }
    }
}

class LesaoCard extends React.Component {
    render() {
        const disabled = this.props.lesoesRelacionadas !== -1
        return (
            <li name={'lesao_' + (this.props.chave + 1)}>
                <C.Panel>
                    <C.PanelHeader>
                        {' '}
                        {'Lesão ' + (this.props.chave + 1)}
                        <C.Hint
                            placement='top'
                            componente={
                                <C.IconButton
                                    pulledRight
                                    onClick={this.props.onClickDelete}
                                    disabled={disabled}
                                    icon='delete'
                                    iconOnly
                                />
                            }
                        >
                            {disabled
                                ? 'Remova os stents relacionados a ela para poder excluir a lesão'
                                : 'Excluir lesão'}
                        </C.Hint>
                    </C.PanelHeader>
                    <C.PanelContent>
                        {this.props.values.get('segmentos').map((value, index) => (
                            <C.StyledCheckboxLabel key={index} label={this.props.options[transformLower(value)]} />
                        ))}

                        <C.Linha />
                        <C.Columns>
                            <C.Column size='3'>
                                <C.TextLabel
                                    title='AHA/ACC:'
                                    value={this.props.values.get('ahaacc')}
                                    placeholder={'Não informado'}
                                />
                            </C.Column>
                            <C.Column size='3'>
                                <C.TextLabel
                                    title='Tipo de lesão:'
                                    value={
                                        this.props.values.get('tipoLesao')
                                            ? tipoLesaoOptions[this.props.values.get('tipoLesao')]
                                            : ''
                                    }
                                    placeholder={'Não informado'}
                                />
                            </C.Column>
                            {this.props.values.get('tipoLesao') === 'TROMBOSE' && (
                                <C.Column size='5'>
                                    <C.TextLabel
                                        title='Tipo de trombose:'
                                        value={
                                            this.props.values.get('tipoTrombose')
                                                ? tipoLesaoCompostaOptions[this.props.values.get('tipoTrombose')]
                                                : ''
                                        }
                                        placeholder={'Não informado'}
                                    />
                                </C.Column>
                            )}
                            {this.props.values.get('tipoLesao') === 'REESTENOSE' && (
                                <C.Column size='5'>
                                    <C.TextLabel
                                        title='Tipo de reestenose:'
                                        value={
                                            this.props.values.get('tipoReestenose')
                                                ? tipoLesaoCompostaOptions[this.props.values.get('tipoReestenose')]
                                                : ''
                                        }
                                        placeholder={'Não informado'}
                                    />
                                </C.Column>
                            )}
                        </C.Columns>
                    </C.PanelContent>
                </C.Panel>
            </li>
        )
    }
}

class LesaoTratadaFormCmp extends React.Component {
    render() {
        return (
            <C.ModalCard title='Adicionar lesão' active onClose={this.props.close}>
                <FichaComponents.LesaoSvgPanel
                    name='segmentos'
                    dominio={this.props.dominancia}
                    hasTrombose={this.props.hasTrombose}
                    hasReestenose={this.props.hasReestenose}
                    options={this.props.options}
                />
                <C.Linha />
                <C.ButtonBar>
                    <C.Button size='medium' onClick={this.props.close}>
                        Cancelar
                    </C.Button>
                    <C.Button
                        pulledRight
                        disabled={this.props.pristine}
                        type='primary'
                        size='medium'
                        onClick={this.props.handleSubmit}
                    >
                        Salvar
                    </C.Button>
                </C.ButtonBar>
            </C.ModalCard>
        )
    }
}

function transformLower(value) {
    return value.toLowerCase().replace('_', '-')
}

const selector = formValueSelector('stentCoronaria')
const selector2 = formValueSelector('adicionarLesaoNativa')

const LesaoTratadaForm = reduxForm({ form: 'adicionarLesaoNativa', validate: LesoesNativasModalValidator })(
    connect(state => {
        const dominancia = selector(state, 'essencial.lesoesTratadas.dominancia')
        const hasTrombose = selector2(state, 'tipoLesao') === 'TROMBOSE'
        const hasReestenose = selector2(state, 'tipoLesao') === 'REESTENOSE'

        return {
            dominancia,
            hasTrombose,
            hasReestenose,
        }
    })(LesaoTratadaFormCmp)
)
