/* tslint:disable */
import React from 'react'

const SvgRastProdutos = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
        <path d='M26 24a.733.733 0 01-.359.637l-2.201 1.272a.67.67 0 01-.674 0l-1.864-1.077-1.864 1.077a.67.67 0 01-.674 0L16.5 24.832l-1.864 1.077a.673.673 0 01-.674 0l-1.864-1.077-1.864 1.077a.67.67 0 01-.674 0l-2.201-1.272A.733.733 0 017 24V5.727C7 5.328 7.309 5 7.696 5h17.608c.387 0 .696.328.696.727v18.274zm-4.082-13.167H11.012c-.248 0-.45.249-.45.556 0 .307.202.555.45.555h10.906c.249 0 .45-.248.45-.555 0-.307-.201-.556-.45-.556zm-.011 4.667H11.023c-.254 0-.46.249-.46.556 0 .306.206.555.46.555h10.884c.254 0 .46-.249.46-.555 0-.307-.206-.556-.46-.556zm.003-2.265H11.02c-.253 0-.457.249-.457.556 0 .307.204.555.457.555h10.89c.253 0 .458-.248.458-.555 0-.307-.205-.556-.458-.556zm-5.878 4.598H10.96c-.22 0-.398.249-.398.556 0 .307.178.555.398.555h5.072c.22 0 .398-.248.398-.555 0-.307-.178-.556-.398-.556z' />
    </svg>
)

export default SvgRastProdutos
