import React from 'react'
import { IconButton } from 'libs/components'
import { Hospital } from 'backend'

interface HospitalBadge {
    hospital: Hospital | any
    handleDeleteHospital: (hospital: Hospital) => void
}

const HospitalBadge: React.FC<HospitalBadge> = props => {
    return (
        <li className='hospital-badge is-horizontal-flow'>
            <span>
                {props.hospital.nome} - {props.hospital.cidade}/{props.hospital.uf}
            </span>
            <IconButton icon='excluir' onClick={() => props.handleDeleteHospital(props.hospital)} />
        </li>
    )
}

export default HospitalBadge
