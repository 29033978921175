import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import immutableProxy, { ImmutableRootProxy } from 'libs/immutableProxy'
import { RevisaoQuadrilForm } from 'backend'

const validaMotivoRevisao = (mutable: ImmutableRootProxy<FormState<RevisaoQuadrilForm>>) => {
    const motivo = mutable.values.motivoRevisao.motivoRevisao.get()
    if (motivo && motivo.indexOf('INFECCAO_QUADRIL') === -1) {
        mutable.values.motivoRevisao.tipoInfeccao.delete()
    }
    return mutable
}

const validaProcedimentoCirurgicoAtualRevisao = (mutable: ImmutableRootProxy<FormState<RevisaoQuadrilForm>>) => {
    if (mutable.values.procedimentoCirurgicoAtualRevisao.transfusaoSanguinea.get() !== 'SIM') {
        mutable.values.procedimentoCirurgicoAtualRevisao.tipoTransfusaoSanguinea.delete()
    }
    return mutable
}

const validaTipoRevisaoForm = (mutable: ImmutableRootProxy<FormState<RevisaoQuadrilForm>>) => {
    if (mutable.values.tipoRevisaoForm.tipoRevisao.get()) {
        mutable.values.tipoRevisaoForm.componentesRetiradosQuadril.delete()
        mutable.fields.tipoRevisaoForm.componentesRetiradosQuadril.delete()
    }
    if (mutable.values.tipoRevisaoForm.componentesRetiradosQuadril.get()) {
        mutable.values.tipoRevisaoForm.tipoRevisao.delete()
        mutable.fields.tipoRevisaoForm.tipoRevisao.delete()
    }
    return mutable
}
const revisaoQuadril = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<RevisaoQuadrilForm>>(state)
        form = form.withMutations(mutable => {
            validaMotivoRevisao(mutable)
            validaProcedimentoCirurgicoAtualRevisao(mutable)
            if (mutable.values.procedimentoCirurgicoAtualRevisao.culturaDuranteAto.get() !== 'SIM') {
                mutable.values.procedimentoCirurgicoAtualRevisao.tipoCulturaDuranteAto.delete()
            }

            if (mutable.values.procedimentoCirurgicoAtualRevisao.possuiAntibiotico.get() !== 'SIM') {
                mutable.values.procedimentoCirurgicoAtualRevisao.antibiotico.delete()
                mutable.values.procedimentoCirurgicoAtualRevisao.periodoAntibiotico.delete()
            }

            validaTipoRevisaoForm(mutable)
            if (!mutable.values.tecnicaOperatoria.femoral.get()) {
                mutable.values.tecnicaOperatoria.femoralTelaSuporte.delete()
                mutable.values.tecnicaOperatoria.femoralCentralizador.delete()
                mutable.values.tecnicaOperatoria.femoralCimentado.delete()
                mutable.values.tecnicaOperatoria.restritorCimento.delete()
                mutable.values.tecnicaOperatoria.tipoProtese.delete()
                mutable.values.enxertia.enxertiaFemoral.delete()
            }
            if (mutable.values.tecnicaOperatoria.femoralCimentado.get() !== 'SIM') {
                mutable.values.tecnicaOperatoria.restritorCimento.delete()
            }
            if (!mutable.values.tecnicaOperatoria.acetabular.get()) {
                mutable.values.tecnicaOperatoria.acetabularTelaSuporte.delete()
                mutable.values.tecnicaOperatoria.acetabularAnelReforco.delete()
                mutable.values.tecnicaOperatoria.parTribologico.delete()
                mutable.values.tecnicaOperatoria.acetabularMetalTrabeculado.delete()
                mutable.values.tecnicaOperatoria.acetabularCimentado.delete()
                mutable.values.enxertia.enxertiaAcetabular.delete()
            }
            if (
                mutable.values.tecnicaOperatoria.acetabularCimentado.get() !== 'SIM' &&
                mutable.values.tecnicaOperatoria.femoralCimentado.get() !== 'SIM' &&
                mutable.values.cimentoOsseo.get() !== null
            ) {
                mutable.values.cimentoOsseo.tipoCimentoOsseo.delete()
                mutable.values.cimentoOsseo.tecnicaCimentacao.delete()
                mutable.values.cimentoOsseo.lavagemPulsatil.delete()
            }
            if (!mutable.values.enxertia.enxertiaFemoral.get()) {
                mutable.values.enxertia.tipoEnxertiaFemoral.delete()
            }
            if (!mutable.values.enxertia.enxertiaAcetabular.get()) {
                mutable.values.enxertia.tipoEnxertiaAcetabular.delete()
            }
        })
        return form.asMap()
    }
    return state
})

export default revisaoQuadril
