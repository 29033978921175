/* tslint:disable */
import React from 'react'

const SvgColunas = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M7 17v-6h2v6zm4 0V7h2v10zm4 0V9h2v8z' />
    </svg>
)

export default SvgColunas
