import { PacienteProcedimentoReportDto, TipoProcedimento } from 'backend'
import { Column, Columns, Tag, Text, Hint, IconButton } from 'libs/components'
import { maskCpf } from 'libs/util/CpfUtil'
import * as DateUtil from 'libs/util/DateUtil'
import React from 'react'
import { tipoProcedimentoName } from 'redux/enums'

interface ListaPacientesRowProps {
    content: PacienteProcedimentoReportDto
    visualizarFicha(idProcedimento: number, tipoProcedimento: TipoProcedimento): any
}

const ListaPacientesRow: React.FC<ListaPacientesRowProps> = props => {

    return (
        <div className='busca-row'>
            <Columns>
                <Column className='is-narrow' customStyle={{ width: '12%' }}>
                    <Text size='small-bis' color='black-bis'>
                        {maskCpf(props.content.cpf)}
                    </Text>
                </Column>
                <Column className='is-narrow' customStyle={{ width: '12%' }}>
                    <Text size='small-bis' color='black-bis'>
                        {props.content.nome}
                    </Text>
                </Column>
                <Column className='is-narrow' customStyle={{ width: '12%' }}>
                    <Text size='small-bis' color='black-bis'>
                        {tipoProcedimentoName[props.content.tipoProcedimento]}
                    </Text>
                </Column>
                <Column size='3'>
                    <Text size='small-bis' color='black-bis'>
                        {props.content.hospital}
                    </Text>
                </Column>
                <Column className='is-narrow' customStyle={{ width: '12%' }}>
                    <Text size='small-bis' color='black-bis'>
                        {props.content.procCidade + '/' + props.content.procUf}
                    </Text>
                </Column>
                <Column className='is-narrow' customStyle={{ width: '12%' }}>
                    <Text size='small-bis' color='black-bis'>
                        {props.content.pacienteCidade + '/' + props.content.pacienteUf}
                    </Text>
                </Column>
                <Column className='is-narrow' customStyle={{ width: '10%' }}>
                    <Text size='small-bis' color='black-bis'>
                        <Tag>{DateUtil.asClientFormat(props.content.dataCirurgia)}</Tag>
                    </Text>
                </Column>
                <Column>
					<Hint
						placement='top'
						componente={
							<IconButton
							 	data-testid='botao-visualizar-ficha'
                                icon='arrowRight'
                                size='is-x-small'
                                pulledRight
                                onClick={() =>
                                    props.visualizarFicha(
                                        props.content.idProcedimento,
                                        props.content.tipoProcedimento
                                    )
                                }
                            />
						}
					>
						Visualizar
					</Hint>
                </Column>
            </Columns>
        </div>
    )
}

export default ListaPacientesRow
