import { tiposVisualizacaoProcedimentoUrlByEnum } from 'api/Visualizacao'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import VisualizacaoRevisaoQuadrilWrapper from './VisualizacaoRevisaoQuadril'

function VisualizacaoRevisaoQuadrilView(props: RouteComponentProps<any>) {
    const proced = tiposVisualizacaoProcedimentoUrlByEnum['REVISAO_QUADRIL']
    const imprimirFicha = (idProcedimento: number) => {
        window.open('/api/procedimentos/impressaoRegistro/' + idProcedimento, '_blank')
    }
    return (
        <VisualizacaoRevisaoQuadrilWrapper
            id={props.match.params.id}
            proced={proced}
            idProced={props.match.params.id}
            imprimirFicha={imprimirFicha}
            {...props}
        />
    )
}

export default VisualizacaoRevisaoQuadrilView
