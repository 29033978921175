import Integracao from 'api/Integracao'
import { SistemaIntegracaoDto } from 'backend'
import { AlertModalConfirmacao, Column, Columns, Hint, IconButton, Label } from 'libs/components'
import { asClientFormat } from 'libs/util/DateUtil'
import React from 'react'
import { IntegracaoRowIconColumn, IntegracaoRowIconColumnStatus } from '../../common/IntegracaoRowIconColumn'
import { IntegracaoRowItemHeader } from '../../common/IntegracaoRowItemHeader'
import { getSistemasInativos, getSistemasAtivos } from 'redux/modules/integracao'
import { useDispatch } from 'react-redux'
import { maxAtivosMessage } from 'view/home/gestorIntegracao/SistemasIntegracaoView'

interface SistemasIntegracaoRowConfig {
    titleModalConfirm: string
    textModalConfirm: string
    handleClickOkModal: () => void
    statusRowIcon: IntegracaoRowIconColumnStatus
}

interface SistemasIntegracaoRowProps {
    content: SistemaIntegracaoDto
    isAtivo: boolean
    handleClickEditar?: (sistema: SistemaIntegracaoDto) => void // only need set this in 'ativo' page
    disableReativarButton?: boolean // only need set this in 'inativo' page
}

export const SistemasIntegracaoRow = (props: SistemasIntegracaoRowProps) => {
    const { content, isAtivo, disableReativarButton = false, handleClickEditar } = props

    const dispatch = useDispatch()
    const [isModalConfirmOpen, setModalConfirmOpen] = React.useState<boolean>(false)

    const handleClickInativar = () => {
        Integracao.inativarSistema(content.id).then(() => {
            setModalConfirmOpen(false)
            dispatch(getSistemasAtivos())
        })
    }

    const handleClickReativar = () => {
        Integracao.reativarSistema(content.id).then(() => {
            setModalConfirmOpen(false)
            dispatch(getSistemasInativos())
        })
    }

    let rowConfig: SistemasIntegracaoRowConfig = {} as SistemasIntegracaoRowConfig
    if (isAtivo) {
        rowConfig.titleModalConfirm = 'Desativar sistema!'
        rowConfig.textModalConfirm =
            'Ao confirmar, o sistema ' +
            content.sistemaNome +
            ', da empresa ' +
            content.empresaNome +
            ' será desativado. Tem certeza que deseja desativar o sistema?'
        rowConfig.handleClickOkModal = handleClickInativar
        rowConfig.statusRowIcon = 'success'
    } else {
        rowConfig.titleModalConfirm = 'Solicitar reativação?'
        rowConfig.textModalConfirm =
            'Ao confirmar, o sistema ' +
            content.sistemaNome +
            ', da empresa ' +
            content.empresaNome +
            ' será reativado. Tem certeza que deseja reativar?'
        rowConfig.handleClickOkModal = handleClickReativar
        rowConfig.statusRowIcon = 'inactive'
    }

    return (
        <div className='sistemas-row'>
            <AlertModalConfirmacao
                active={isModalConfirmOpen}
                title={rowConfig.titleModalConfirm}
                onClose={() => setModalConfirmOpen(false)}
                onOk={rowConfig.handleClickOkModal}
            >
                {rowConfig.textModalConfirm}
            </AlertModalConfirmacao>
            <IntegracaoRowItemHeader
                title={content.empresaNome.toUpperCase()}
                subtitle={'Cadastrada em ' + asClientFormat(content.dataCadastro)}
            />
            <Columns className='sistemas-row-content'>
                <IntegracaoRowIconColumn status={rowConfig.statusRowIcon} icon='dadospessoais' iconSize='medium' />
                <Column size='8' className='sistemas-row-content-labels'>
                    <Label className='sistemas-row-content-label-sistema'>{content.sistemaNome}</Label>
                    <Label className='sistemas-row-content-label-envio'>
                        {content.dataUltimoEnvio
                            ? 'Último envio em ' + asClientFormat(content.dataUltimoEnvio)
                            : 'Nenhum envio realizado'}
                    </Label>
                </Column>
                <Column className='sistemas-row-content-icons'>
                    {isAtivo ? (
                        <>
                            <Hint
                                placement='top'
                                componente={
                                    <IconButton
                                        icon='editar'
                                        type='grey-darker'
                                        onClick={() => handleClickEditar(content)}
                                    />
                                }
                            >
                                Editar
                            </Hint>
                            <Hint
                                placement='top'
                                componente={
                                    <IconButton
                                        icon='excluir'
                                        type='grey-darker'
                                        onClick={() => setModalConfirmOpen(true)}
                                    />
                                }
                            >
                                Inativar sistema
                            </Hint>
                        </>
                    ) : (
                        <>
                            <IconButton icon='editar' type='grey' disabled />
                            {disableReativarButton ? (
                                <Hint
                                    placement='top'
                                    componente={<IconButton icon='reativar' type='grey-darker' disabled />}
                                >
                                    {maxAtivosMessage}
                                </Hint>
                            ) : (
                                <Hint
                                    placement='top'
                                    componente={
                                        <IconButton
                                            icon='reativar'
                                            type='grey-darker'
                                            onClick={() => setModalConfirmOpen(true)}
                                        />
                                    }
                                >
                                    Reativar sistema
                                </Hint>
                            )}
                        </>
                    )}
                </Column>
            </Columns>
        </div>
    )
}
