import React from 'react'
import * as C from 'libs/components'

const implante = [
    {
        id: 'DIFICULDADE_POSICIONAMENTO',
        nome: 'Dificuldade de posicionamento',
        descricao:
            'Problema associado com a dificuldade ou desconforto (por exemplo, dificuldade na ergonomia) do usuário para ativar  um produto para a saúde, seu componente, ou ambos, em uma localização específica.',
    },
    {
        id: 'FALHA_ATIVAR',
        nome: 'Falha para ativar',
        descricao: 'Problema associado com a incapacidade do produto para a saúde, ou seu componente, de ser ativado.',
    },
    {
        id: 'FALHA_SEPARAR',
        nome: 'Falha para separar',
        descricao:
            'Item associado com a falha do produto para a saúde, ou um de seus componentes, em se desconectar ou separar como pretendido.',
    },
    {
        id: 'ATIVACAO_PREMATURA',
        nome: 'Ativação prematura',
        descricao:
            'Problema associado com a ativação precoce do produto para a saúde, seu componente, ou ambos, a partir do sistema.',
    },
    {
        id: 'ATIVACAO_ATRASADA',
        nome: 'Ativação atrasada',
        descricao:
            'Problema associado com a ativação atrasada do produto para a saúde, seu componente, ou ambos, a partir do sistema.',
    },
]

const falha = [
    {
        id: 'MIGRACAO_PRODUTO_SAUDE_IMPLANTADO',
        nome: 'Migração de produto para a saúde implantado ou componente do produto',
        descricao:
            'Problema associado com  um movimento indesejado de um produto para a saúde implantado, componente de um produto para a saúde, ou ambos, relacionado com o seu afastamento ou desalojamento a partir do seu sítio de implantação original.',
    },
    {
        id: 'ALTERACAO_OSSEA',
        nome: 'Alteração óssea',
        descricao:
            'Problema associado com a interligação entre o osso e o produto para a saúde, componente de um produto para saúde implantado, ou ambos.',
    },
]

const incompatibilidade = [
    {
        id: 'INCOMPATIBILIDADE_COMPONENTE_ACESSORIO',
        nome: 'Incompatibilidade do componente ou acessório',
        descricao:
            'Problema associado com a incompatibilidade de qualquer produto para a saúde, componente de produto, ou ambos, durante utilização no mesmo ambiente, que resulta em disfunção entre o produto para a saúde e seus componentes.',
    },
    {
        id: 'INCOMPATIBILIDADE_PRODUTOS_SAUDE',
        nome: 'Incompatibilidade entre produtos para a saúde',
        descricao:
            'Problema associado com a utilização no mesmo ambiente, que resulta em disfunção de mais de um produto para a saúde. ',
    },
    {
        id: 'INCOMPATIBILIDADE_PRODUTOS_SAUDE_PACIENTE',
        nome: 'Incompatibilidade entre produto para a saúde e paciente',
        descricao:
            'Problema associado com a interação entre o produto para a saúde e as condições anatômicas e fisiológicas do paciente, que afeta o paciente ou o produto (por exemplo, problemas de biocompatibilidade ou problemas imunológicos). ',
    },
]

const instrucoes = [
    {
        id: 'PROBLEMAS_INSTRUCOES',
        nome: 'Problemas de instruções para uso',
        descricao:
            'Problema associado com qualquer informação que acompanha um produto para a saúde, incluindo instruções relativas à identificação, descrição técnica e de uso do produto para a saúde, fornecida por seu fabricante/importador (exemplo: instruções de uso, manual do usuário)“',
    },
    {
        id: 'PROBLEMAS_MARCACOES',
        nome: 'Problemas de marcações',
        descricao:
            'Problema associado com o material escrito, impresso ou gráfico que é afixado ou gravado em um produto para a saúde... ou materiais acompanhantes (exemplo: etiquetas para rastreabilidade de produtos implantáveis, dizeres de rotulagem).',
    },
]

function chooseOptions(tipo) {
    if (tipo) {
        if (tipo === 'IMPLANTE_ATIVACAO_POSICIONAMENTO_SEPARACAO') {
            return implante
        } else if (tipo === 'FALHA_PRODUTO_SAUDE_IMPLANTAVEL') {
            return falha
        } else if (tipo === 'INCOMPATIBILIDADE') {
            return incompatibilidade
        } else if (tipo === 'MARCACAO_ROTULAGEM_INSTRUCOES') {
            return instrucoes
        }
    }
}

export class EspecificacaoFalhaField extends React.Component {
    render() {
        return <C.FixedSelectField {...this.props} options={chooseOptions(this.props.tipoFalha)} />
    }
}
