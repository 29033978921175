import React from 'react'
import * as C from 'libs/components'
import * as FC from 'view/home/novoProcedimento/ficha/componentes'
import { StentCoronariaComplementarVisualizacaoDto } from 'backend'
import { dadosComplementaresByEnum } from 'api/Visualizacao'
import { isNullOrUndefined } from 'util'

interface AntecedentesProps {
    dadosComplementares: StentCoronariaComplementarVisualizacaoDto
}

export class AntecedentesCard extends React.Component<AntecedentesProps> {
    render() {
        const data = this.props.dadosComplementares
        const peso = data.peso && data.peso + ' kg'
        const altura = data.altura && data.altura + ' cm'
        const imc = data.imc && data.imc + ' kg/m²'
        const percentilFeFuncaoVeConhecida = data.funcaoVEConhecidaPercentilFE + '%'

        return (
            <div>
                {data.crmPrevia === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>CRM prévia</C.Label>
                        </li>
                    </ul>
                )}
                {data.diabetes === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Diabetes Mellitus</C.Label>
                            <C.ComposedLabel title={'Terapia'}>{data.diabetesTerapia}</C.ComposedLabel>
                        </li>
                    </ul>
                )}
                {data.dislipidemia === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Dislipidemia</C.Label>
                            <C.ComposedLabel title={'Terapia medicamentosa'}>
                                {data.dislipidemiaTerapia}
                            </C.ComposedLabel>
                        </li>
                    </ul>
                )}
                {data.doencaCerebrovascular === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Doença cerebrovascular</C.Label>
                        </li>
                    </ul>
                )}
                {data.doencaPulmonar === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Doença pulmonar crônica</C.Label>
                            <C.ComposedLabel title={'Dependente de O2'}>
                                {data.doencaPulmonarDependeODois &&
                                    dadosComplementaresByEnum[data.doencaPulmonarDependeODois]}
                            </C.ComposedLabel>
                        </li>
                    </ul>
                )}
                {data.doencaVascularPeriferica === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Doença vascular periférica</C.Label>
                        </li>
                    </ul>
                )}
                {data.fibrilacaoAtrialCronica === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Fibrilação atrial crônica</C.Label>
                            <C.ComposedLabel title={'Anticoagulação oral'}>
                                {isNullOrUndefined(data.fibrilacaoTipoCoagulacao)
                                    ? dadosComplementaresByEnum[data.fibrilacaoAnticoagulacao]
                                    : dadosComplementaresByEnum[data.fibrilacaoAnticoagulacao] +
                                      ' - ' +
                                      dadosComplementaresByEnum[data.fibrilacaoTipoCoagulacao]}
                            </C.ComposedLabel>
                        </li>
                    </ul>
                )}
                {data.funcaoVEConhecida === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Função VE conhecida</C.Label>
                            <C.Columns auto>
                                <C.TextLabel
                                    title='Fração de ejeção - semi-quantitativo'
                                    value={dadosComplementaresByEnum[data.funcaoVEConhecidaSemiQuantitativo]}
                                />
                                {!isNullOrUndefined(data.funcaoVEConhecidaPercentilFE) ? (
                                    <C.TextLabel title='Percentil' value={percentilFeFuncaoVeConhecida} />
                                ) : (
                                    <C.TextLabel title='Percentil' value={data.funcaoVEConhecidaPercentilFE} />
                                )}
                            </C.Columns>
                        </li>
                    </ul>
                )}
                {data.hipertensao === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Hipertensão</C.Label>
                        </li>
                    </ul>
                )}
                {data.iamPrevio === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>IAM prévio</C.Label>
                        </li>
                    </ul>
                )}
                {data.iccPrevia === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>ICC</C.Label>
                            <C.ComposedLabel title={'Classe funcional'}>
                                {data.iccPreviaClasseFuncional}
                            </C.ComposedLabel>
                        </li>
                    </ul>
                )}
                {data.icpPrevia === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>ICP prévia</C.Label>
                        </li>
                    </ul>
                )}
                {data.insuficienciaRenal === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Insuficiência renal</C.Label>
                            <C.Columns auto>
                                <C.TextLabel
                                    title='IRC'
                                    value={dadosComplementaresByEnum[data.insuficienciaRenalIrc]}
                                />
                                {!isNullOrUndefined(data.insuficienciaRenalCreatinina) ? (
                                    <C.TextLabel
                                        title='Creatinina'
                                        value={data.insuficienciaRenalCreatinina + 'mg/dL'}
                                    />
                                ) : (
                                    <C.TextLabel title='Creatinina' value={data.insuficienciaRenalCreatinina} />
                                )}
                            </C.Columns>
                        </li>
                    </ul>
                )}
                {data.intervencaoValvar === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Intervenção valvar prévia</C.Label>
                            <C.ComposedLabel title={'Tipo'}>{data.intervencaoValvarTipo}</C.ComposedLabel>
                        </li>
                    </ul>
                )}
                {data.tabagismo === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Tabagismo</C.Label>
                            <C.ComposedLabel title={'Situação'}>
                                {dadosComplementaresByEnum[data.tabagismoSituacao]}
                            </C.ComposedLabel>
                        </li>
                    </ul>
                )}
                <FC.ComposedLabelVisualizacao title='Sem antecedentes'>
                    {data.textoAntecedentesAvaliadosNao}
                </FC.ComposedLabelVisualizacao>
                {data.textoAntecedentesNaoAvaliados && (
                    <C.Columns auto>
                        <C.ComposedLabel
                            title='Antecedentes não avaliados'
                            placeholder={data.textoAntecedentesNaoAvaliados}
                        ></C.ComposedLabel>
                    </C.Columns>
                )}

                <C.ComposedLabel className='paddingbottom' title='Índice de massa corporal'>
                    {dadosComplementaresByEnum[data.indiceMassaCorporalAvaliado]}
                </C.ComposedLabel>

                {data.indiceMassaCorporalAvaliado === 'SIM' && (
                    <C.Columns auto>
                        <C.ComposedLabel title='Altura'>{altura}</C.ComposedLabel>

                        <C.ComposedLabel title='Peso'>{peso}</C.ComposedLabel>

                        <C.ComposedLabel title='IMC'>{imc}</C.ComposedLabel>
                    </C.Columns>
                )}
            </div>
        )
    }
}
