
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.45,"time":27000,"words":90}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Faça login no RNI.`}</li>
      <li parentName="ol">{`Clique no botão `}<img parentName="li" {...{
          "src": "/images/icone-alarmes.png#center",
          "alt": "..."
        }}></img>{`.`}</li>
      <li parentName="ol">{`Na central de alarmes selecione a opção “Configurar”.`}</li>
      <li parentName="ol">{`No card de “Etiqueta s/ registro ANVISA” clique no botão “Configurar”.`}</li>
      <li parentName="ol">{`No modal de configuração, marque a opção “Ativo” para ativar o envio de alarmes sempre que for registrado o uso de um componente que não possua uma etiqueta com o registro da ANVISA.`}</li>
      <li parentName="ol">{`Clique no botão “Salvar”.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para ser notificado quando ocorrer o registro de produto sem etiqueta ANVISA, você deve se inscrever no alarme.`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

