import { connect } from 'react-redux'
import { InfoProdutoFiltro, InfoProdutoRow } from 'backend'
import { withRouter } from 'react-router-dom'
import { State } from 'redux/modules'
import { InfoProdutoListView, Props } from './InfoProdutoListView'
import * as filtroReport from 'redux/modules/report/filtro'
import Report from 'api/Report'
import { PageRequester } from 'redux/requester'

const paginator = new PageRequester<InfoProdutoRow, InfoProdutoFiltro>('infoProduto', (filtro, config) => {
    return Report.getInfoProdutoPage(filtro, config)
})

const mapStateToProps = (state: State, ownProps: Props) => ({
    filtro: state.report.filtro.filtro as InfoProdutoFiltro,
    page: paginator.getResult(state),
    isFetching: paginator.getIsFetching(state),
})

const mapDispatchToProps = dispatch => ({
    changeFilter: (filtro: InfoProdutoFiltro) => {
        dispatch(filtroReport.change({ ...filtro }))
    },
    load: (filtro: InfoProdutoFiltro) => {
        dispatch(paginator.setParams(filtro))
        dispatch(paginator.request())
    },
    handlePageChange: (page: number) => {
        dispatch(paginator.setPageNumber(page))
        dispatch(paginator.request())
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoProdutoListView))
