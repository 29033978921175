import React from 'react'
import * as C from 'libs/components'

export class EcoEstresseCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Eco de estresse'
                name='estresse'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='estresse' disabled={this.props.disabled} />}
            >
                <C.Conteudo>
                    <C.Columns>
                        <C.Column size='5' verticalFlow>
                            <C.ControlField label='Avaliação' name='estresseAvaliacao' verticalFlow>
                                <C.ClearableRadioField name='estresseAvaliacao' label='Negativo' value='NEGATIVO' />
                                <C.ClearableRadioField name='estresseAvaliacao' label='Positivo' value='POSITIVO' />
                                <C.ClearableRadioField
                                    name='estresseAvaliacao'
                                    label='Indeterminado'
                                    value='INDETERMINADO'
                                />
                                <C.ClearableRadioField
                                    name='estresseAvaliacao'
                                    label='Não disponível'
                                    value='NAO_DISPONIVEL'
                                />
                            </C.ControlField>
                        </C.Column>
                        <C.Column size='5' verticalFlow>
                            <C.RiscoField name='estresseRisco' />
                        </C.Column>
                    </C.Columns>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
