import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import { RastreabilidadeForm } from 'backend'
import dadosFinanceiro from 'redux/modules/forms/section/dadosFinanceiro'
import dadosProduto from 'redux/modules/forms/section/dadosProduto'
import immutableProxy from 'libs/immutableProxy'

const rastreabilidadeForm = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let wizardName = action.meta.field.split('.')[0]
        let form = immutableProxy<FormState<RastreabilidadeForm>>(state)
        form = form.withMutations(mutable => {
            dadosProduto(
                mutable.values[wizardName].dadosProdutoForm,
                mutable.fields[wizardName].dadosProdutoForm,
                action
            )

            const dadosForm = mutable.values[wizardName].dadosProdutoForm.get()
            if (!dadosForm || dadosForm.size === 0) {
                mutable.values[wizardName].dadosProdutoForm.delete()
            }

            dadosFinanceiro(
                mutable.values[wizardName].dadosFinanceirosForm,
                mutable.fields[wizardName].dadosFinanceirosForm,
                action
            )
        })
        return form.asMap()
    }

    return state
})

export default rastreabilidadeForm
