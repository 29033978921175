import React from 'react'
import { procedimentoDuplicadoTitle, procedimentoDuplicadoMessage } from '../../procedimentoDuplicado'
import { ButtonBar, Button, AlertModalError } from 'libs/components'
import { CadastroPacienteForm, RascunhoProcedimentoForm } from 'backend'
import { AxiosPromise } from 'axios'
import { useHistory } from 'react-router-dom'
import { notFoundErrorTitle, notFoundErrorText } from 'libs/util/NotFoundErrorUtil'

export interface ButtonsFormPacienteProps {
    paciente: CadastroPacienteForm
    handleRascunhoSuccess(result: AxiosPromise<RascunhoProcedimentoForm>): void
    showAlertError(): void
    selecionarPaciente(paciente: any): AxiosPromise<RascunhoProcedimentoForm>
    guardarPaciente(): void
    cancelar(): void
}

export const ButtonsFormPaciente = ({ paciente, handleRascunhoSuccess, ...props }: ButtonsFormPacienteProps) => {
    const { showAlertError, selecionarPaciente, guardarPaciente, cancelar } = props
    const { push } = useHistory()
    const [showErrorDuplicado, setShowErrorDuplicado] = React.useState(false)
    const [showErrorNaoEncontrado, setShowErrorNaoEncontrado] = React.useState(false)
    const resolvePromise = promise => {
        promise &&
            promise
                .then(result => {
                    handleRascunhoSuccess(result)
                })
                .catch(e => {
                    if (typeof e.response.data.errors === 'string') {
                        setShowErrorDuplicado(true)
                    } else if (e.response.status === 404) {
                        setShowErrorNaoEncontrado(true)
                    } else {
                        showAlertError()
                    }
                })
    }

    return (
        <ButtonBar>
            <Button pulledLeft size='medium' onClick={cancelar}>
                Cancelar
            </Button>
            {(!paciente || !paciente.isCadsus) && (
                <Button
                    pulledRight
                    type='primary'
                    size='medium'
                    disabled={!paciente}
                    onClick={() => resolvePromise(selecionarPaciente(paciente))}
                >
                    Confirmar paciente
                </Button>
            )}
            {paciente && paciente.isCadsus && (
                <Button pulledRight type='primary' size='medium' onClick={guardarPaciente}>
                    Editar e confirmar paciente
                </Button>
            )}
            {showErrorDuplicado && (
                <AlertModalError
                    title={procedimentoDuplicadoTitle}
                    active={showErrorDuplicado}
                    onClose={() => {
                        setShowErrorDuplicado(false)
                        push('/home')
                    }}
                >
                    {procedimentoDuplicadoMessage}
                </AlertModalError>
            )}
            {showErrorNaoEncontrado && (
                <AlertModalError
                    active={showErrorNaoEncontrado}
                    title={notFoundErrorTitle}
                    onClose={() => {
                        setShowErrorNaoEncontrado(false)
                        push('/home')
                    }}
                >
                    {notFoundErrorText}
                </AlertModalError>
            )}
        </ButtonBar>
    )
}
