import React from 'react'
import * as C from 'libs/components'

export default class FichaRevisaoJoelhoSidebar extends React.Component {
    render() {
        return (
            <C.SideNav>
                <C.SideNavItem anchor='motivoRevisao'>Motivo da revisão</C.SideNavItem>
                <C.SideNavItem anchor='procedimentoCirurgicoAtualRevisao'>Procedimento cirúrgico atual</C.SideNavItem>
                <C.SideNavItem anchor='tipoRevisaoForm'>Tipo de Revisão</C.SideNavItem>
                <C.SideNavItem anchor='cimentoOsseo'>Cimento ósseo</C.SideNavItem>
                <C.SideNavItem anchor='enxertia'>Enxertia</C.SideNavItem>
                <C.SideNavItem anchor='componentesUtilizados'>Componentes utilizados</C.SideNavItem>
            </C.SideNav>
        )
    }
}
