/* tslint:disable */
import React from 'react'

const SvgAguardandoSelected = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 36 36' {...props}>
        <path d='M27.339 10.303c2.36 2.4 3.661 5.592 3.661 8.986 0 3.395-1.3 6.587-3.66 8.987C24.978 30.677 21.84 32 18.5 32s-6.479-1.323-8.84-3.724C7.3 25.876 6 22.684 6 19.289c0-3.394 1.3-6.586 3.66-8.986 2.2-2.237 5.08-3.54 8.174-3.703V5.35h-2.113V4h5.558v1.35h-2.113V6.6c3.094.164 5.975 1.466 8.173 3.703zm-16.736.954a11.357 11.357 0 00-3.25 7.357H8.6v1.35H7.353c.16 2.786 1.3 5.376 3.25 7.358 1.949 1.982 4.494 3.142 7.231 3.305V29.36h1.332v1.267c2.737-.163 5.282-1.323 7.23-3.305a11.359 11.359 0 003.25-7.357H28.4v-1.351h1.247a11.357 11.357 0 00-3.25-7.357c-1.949-1.982-4.494-3.142-7.231-3.305V9.22h-1.332V7.952c-2.737.162-5.282 1.322-7.23 3.305zm-.114.842l.94-.956 1.539 1.564-.94.956-1.539-1.564zm13.543 13.772l.94-.956 1.539 1.565-.94.956-1.539-1.565zm0-13.163l1.538-1.565.94.957-1.537 1.564-.94-.956zM10.49 26.48l1.538-1.565.94.956-1.538 1.565-.94-.956zm9.944-7.19c0 1.083-.867 1.964-1.934 1.964s-1.934-.881-1.934-1.965a1.96 1.96 0 011.268-1.838v-4.425h1.332v4.425a1.96 1.96 0 011.268 1.838zm-1.934-.615a.609.609 0 00-.602.614c0 .34.27.615.602.615a.609.609 0 00.602-.615.609.609 0 00-.602-.614z' />
    </svg>
)

export default SvgAguardandoSelected
