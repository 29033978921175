import React, { useState } from 'react'
import { Button, CidadeField, Column, Columns, Conteudo, EmptyListItem, Linha, UfField } from 'libs/components'
import { RadioPanelField } from 'libs/components/presentational/form/field/RadioPanelField'
import { useDispatch, useSelector } from 'react-redux'
import { Cidade, Uf } from 'backend'
import { change } from 'redux-form'
import { adicionarEntidadeFormName, selector } from '../AdicionarEntidadeView'
import MunicipioBadge from './MunicipioBadge'
import { isImmutable } from 'immutable'

const EsferaAcessoMunicipalPanel: React.FC = () => {
    const state = useSelector(state => state)
    const dispatch = useDispatch()

    let listaMunicipios = selector(state, 'municipiosAcesso') || []

    if (isImmutable(listaMunicipios)) {
        listaMunicipios = listaMunicipios.toJSON()
    }

    const [selectedUf, setSelectedUf] = useState<Uf>(null)
    const [selectedMunicipio, setSelectedMunicipio] = useState<Cidade>(null)
    const [canAdd, setCanAdd] = useState<boolean>(false)

    function handleChangeUf(uf) {
        setSelectedUf(uf)
        setCanAdd(false)
        setSelectedMunicipio(null)
        dispatch(change(adicionarEntidadeFormName, 'esferaMunicipalCidade', null))
    }

    function handleChangeMunicipio(municipio) {
        setSelectedMunicipio(municipio)

        if (municipio !== null) {
            const wasAdded = listaMunicipios.findIndex(item => item.id === municipio.id)
            setCanAdd(wasAdded === -1)
        } else {
            setCanAdd(false)
        }
    }

    function handleAddMunicipios() {
        const municipio = {
            ...selectedMunicipio,
            uf: selectedUf.nome,
        }

        dispatch(change(adicionarEntidadeFormName, 'municipiosAcesso', [...listaMunicipios, municipio]))
        dispatch(change(adicionarEntidadeFormName, 'esferaMunicipalUf', null))
        dispatch(change(adicionarEntidadeFormName, 'esferaMunicipalCidade', null))
        setSelectedUf(null)
        setSelectedMunicipio(null)
        setCanAdd(false)
    }

    function handleDeleteMunicipio(municipio) {
        dispatch(
            change(
                adicionarEntidadeFormName,
                'municipiosAcesso',
                listaMunicipios.filter(muni => muni.id !== municipio.id)
            )
        )
    }

    function handleClearMunicipios() {
        dispatch(change(adicionarEntidadeFormName, 'municipiosAcesso', []))
    }

    return (
        <RadioPanelField
            label='Municipal'
            name='esferaAcesso'
            value='MUNICIPAL'
            showErrors={true}
            testid='radio-municipal-esfera'
        >
            <Conteudo>
                <Columns>
                    <Column size='3'>
                        <UfField
                            label='UF'
                            name='esferaMunicipalUf'
                            placeholder='UF'
                            required
                            // @ts-ignore
                            onChange={handleChangeUf}
                        />
                    </Column>
                    <Column size='4'>
                        <CidadeField
                            isDisabled={selectedUf === null}
                            ufId={selectedUf ? selectedUf.id : ''}
                            required
                            label='Município'
                            name='esferaMunicipalCidade'
                            placeholder='Município'
                            onChange={handleChangeMunicipio}
                        />
                    </Column>
                    <Column className='align-end' size='4'>
                        <Button
                            type='primary'
                            size='normal'
                            onClick={handleAddMunicipios}
                            disabled={!canAdd}
                            testid='button-add-municipio-esfera'
                        >
                            Adicionar
                        </Button>
                    </Column>
                </Columns>
                <Linha />
                <div className='lista-municipios'>
                    <div className='lista-header is-horizontal-flow'>
                        <h1>Municípios adicionados</h1>
                        {listaMunicipios.length > 0 && (
                            <Button className='btn-clear' type='grey' size='small' onClick={handleClearMunicipios}>
                                LIMPAR TODOS
                            </Button>
                        )}
                    </div>
                    <ul className='lista-content is-horizontal-flow'>
                        {listaMunicipios.map(municipio => (
                            <MunicipioBadge
                                key={municipio.id}
                                municipio={municipio}
                                handleDeleteMunicipio={handleDeleteMunicipio}
                            />
                        ))}
                        {listaMunicipios.length === 0 && <EmptyListItem label='Nenhum município adicionado' />}
                    </ul>
                </div>
            </Conteudo>
        </RadioPanelField>
    )
}

export default EsferaAcessoMunicipalPanel
