import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import dadosProduto from 'redux/modules/forms/section/dadosProduto'
import { DadosProdutoForm } from 'backend'
import immutableProxy from 'libs/immutableProxy'

const dadosProdutoForm = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let wizardName = action.meta.field.split('.')[0]
        let form = immutableProxy<FormState<DadosProdutoForm>>(state)
        form = form.withMutations(mutable => {
            dadosProduto(mutable.values[wizardName], mutable.fields[wizardName], action)
        })

        return form.asMap()
    }

    return state
})

export default dadosProdutoForm
