import { AlfaNumberField, Button, Linha, ModalCard } from 'libs/components'
import PeriodoVigenciaForm from 'libs/components/presentational/form/composed/PeriodoVigenciaForm'
import form from 'libs/redux-form/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InjectedFormProps, updateSyncErrors } from 'redux-form'
import { getFormValues, getFormSubmitErrors } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { RelacaoEmpresasModalValidator } from '../RelacaoEmpresasFormValidator'
import { RelacaoEmpresasTiposRelacoesChecklist } from './modal/RelacaoEmpresasTiposRelacoesChecklist'

const formName = 'empresa'

interface RelacaoEmpresasFormModalProps {
    onClickCancelar: () => void
}

const RelacaoEmpresasFormModalCmp: React.FC<RelacaoEmpresasFormModalProps &
    InjectedFormProps<any, RelacaoEmpresasFormModalProps>> = props => {
        const { onClickCancelar } = props

        const dispatch = useDispatch()

        const formValues = useSelector<State, any>(state => getFormValues(formName)(state))
        const isEditMode: boolean = formValues?.get('id')

        const formErrors = useSelector<State, any>(state => getFormSubmitErrors(formName)(state))

        React.useEffect(() => {
            if (formErrors.get('anoInicio') !== undefined || formErrors.get('mesInicio') !== undefined) {
                dispatch(updateSyncErrors(formName, {
                    periodoVigencia: formErrors.get('anoInicio') || formErrors.get('mesInicio')
                }, null))
            }
        }, [formErrors, dispatch])

        return (
            <ModalCard active hasCloseButton={false} onClose={onClickCancelar} title='Adicionar relação com empresa'>
                <div className='relacao-empresas--modal-content'>
                    <AlfaNumberField
                        required={!isEditMode}
                        className='modal-content--nome-empresa-input'
                        name='nomeEmpresa'
                        label='Nome da empresa'
                        placeholder='Nome da empresa'
                        maxLength={100}
                        disabled={isEditMode}
                    />
                    <RelacaoEmpresasTiposRelacoesChecklist />
                    <PeriodoVigenciaForm formName={formName} />
                    <Linha className='modal-content--linha' />
                    <div className='modal-content--footer'>
                        <Button className='footer--btn-cancelar' onClick={onClickCancelar}>
                            Cancelar
                        </Button>
                        <Button className='footer--btn-add' type='primary' onClick={props.handleSubmit}>
                            Adicionar relação
                        </Button>
                    </div>
                </div>
            </ModalCard>
        )
    }

// any here to dont be necessary make cast from interface type to immutable.js in RelacaoEmpresasList
export const RelacaoEmpresasFormModal = form<any, RelacaoEmpresasFormModalProps>({
    form: formName,
    validate: RelacaoEmpresasModalValidator as any,
    hasSuccessModal: false,
})(RelacaoEmpresasFormModalCmp)
