import * as React from 'react'
import * as C from 'libs/components'
import { PacienteReportDto, SumarioProcedimentoDto, TipoProcedimento } from 'backend'
import * as enums from 'redux/enums'
import * as DateUtil from 'libs/util/DateUtil'
import Text from 'libs/components/presentational/typography/Text'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import BuscaRow from './BuscaRow'
import { Columns, Column, Tag, IconButton } from 'libs/components'
import { BuscaPacienteSumarioProcedimentoCard } from '../cards/BuscaPacienteSumarioProcedimentoCard'

interface BuscaPacienteRowProps {
    detail: boolean
    paciente: PacienteReportDto
    procedimentos?: PromiseRecord<SumarioProcedimentoDto[]>
    openDetail: (id: number) => void
    closeDetail(): void
    visualizarFicha(idProcedimento: number, proced: TipoProcedimento): void
}

export class BuscaPacienteRow extends React.Component<BuscaPacienteRowProps> {
    render() {
        const { paciente, procedimentos, openDetail, detail, closeDetail } = this.props
        const proc = procedimentos && procedimentos.data
        return (
            <div>
                <BuscaRow className='busca-card'>
                    <Columns>
                        <Column size='5'>
                            <Text size='small-bis'>{paciente.nome}</Text>
                        </Column>
                        <Column size='2'>
                            <Text size='small-bis'>{enums.sexoName[paciente.sexo]}</Text>
                        </Column>
                        <Column size='2'>{this.renderProcedimentos(paciente)}</Column>
                        <Column size='2'>
                            <Tag>{DateUtil.asClientFormat(paciente.ultimoProcedimento)}</Tag>
                        </Column>
                        <Column>
                            <C.Hint
                                key='2'
                                placement='top'
                                componente={
                                    <IconButton
                                        data-name='botao_detalhe'
                                        icon='acessar'
                                        size='is-x-small'
                                        className={detail ? 'rotate-135' : 'rotate-45'}
                                        onClick={() => (detail ? closeDetail() : openDetail(paciente.pacienteId))}
                                    />
                                }
                            >
                                {detail ? 'Ver menos' : 'Ver mais'}
                            </C.Hint>
                        </Column>
                    </Columns>
                </BuscaRow>
                {detail &&
                    proc &&
                    proc.map((item, index) => {
                        return (
                            <BuscaPacienteSumarioProcedimentoCard
                                procedimento={item}
                                key={index}
                                onClick={() => this.props.visualizarFicha(item.procedimentoId, item.tipoProcedimento)}
                            />
                        )
                    })}
            </div>
        )
    }

    renderProcedimentos(row: PacienteReportDto) {
        const r = []
        for (let i = 0; i < row.countPrimaria; i++) {
            r.push(
                <C.Hint
                    key={`HP${i}`}
                    placement='top'
                    componente={
                        <C.CircleProgress
                            key={`P${i}`}
                            type='primary'
                            size={10}
                            progress={{ 'is-report-color-1': 1 }}
                        />
                    }
                >
                    Primária
                </C.Hint>
            )
        }
        for (let i = 0; i < row.countRevisao; i++) {
            r.push(
                <C.Hint
                    key={`HR${i}`}
                    placement='top'
                    componente={
                        <C.CircleProgress
                            key={`R${i}`}
                            type='primary'
                            size={10}
                            progress={{ 'is-report-color-2': 1 }}
                        />
                    }
                >
                    Revisão
                </C.Hint>
            )
        }
        for (let i = 0; i < row.countAngio; i++) {
            r.push(
                <C.Hint
                    key={`HC${i}`}
                    placement='top'
                    componente={
                        <C.CircleProgress
                            key={`C${i}`}
                            type='primary'
                            size={10}
                            progress={{ 'is-report-color-3': 1 }}
                        />
                    }
                >
                    Angioplastia
                </C.Hint>
            )
        }
        return <>{r}</>
    }
}
