import * as React from 'react'
import { DistribuicaoProdutosBrasilDto, DistribuicaoProcedimentoFiltro, DateInterval } from 'backend'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { Link, RouteComponentProps } from 'react-router-dom'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import {
    Button,
    Card,
    Column,
    Columns,
    Icon,
    LoadingStateContainer,
    PeriodoFilter,
    ScrollToTop,
    Text,
} from 'libs/components'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import UrlParams from 'libs/router/UrlParams'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import { ComponenteBrasilTable } from '../components/ComponenteBrasilTable'
import { stringify } from 'redux/modules/router'

interface RouteParams {
    tipoRevisao: string
    registroAnvisa: string
    identificadorDispositivo: string
    referencia: string
}

export interface Props extends RouteComponentProps<RouteParams> {
    filtro: DistribuicaoProcedimentoFiltro
    detalhe: PromiseRecord<DistribuicaoProdutosBrasilDto>
    goBack(): void
    loadComponente(filtro: DistribuicaoProcedimentoFiltro): void
    clearFindResult(): void
    changeFilter(filtro: DistribuicaoProcedimentoFiltro): void
}

export class TipoRevisaoProdutoDetailView extends React.Component<Props> {
    refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        this.props.loadComponente(filter)
    }

    onVoltar = () => {
        this.props.clearFindResult()
        this.props.goBack()
    }

    onLoad = (filter: DistribuicaoProcedimentoFiltro) => {
        this.props.loadComponente(filter)
    }

    render() {
        const { ...publishFilter } = this.props.filtro
        const { registroAnvisa, identificadorDispositivo, referencia } = this.props.match.params
        publishFilter.registroAnvisa = null
        publishFilter.identificadorDispositivo = null
        publishFilter.referencia = null

        if (registroAnvisa) {
            publishFilter.registroAnvisa = registroAnvisa
        } else {
            if (identificadorDispositivo) {
                publishFilter.identificadorDispositivo = identificadorDispositivo
            }
            if (referencia) {
                publishFilter.referencia = referencia
            }
        }

        return (
            <div>
                <ScrollToTop />
                <UrlParams params={publishFilter} onChange={this.refresh} />
                <ReportClearFilter />

                <LocalHeaderContainer />
                {this.props.detalhe?.readyState === 'success' && (
                    <DistribuicaoProdutosFormComponent
                        filtro={publishFilter}
                        loadComponenteFilter={this.onLoad}
                        detalhe={this.props.detalhe}
                        onVoltar={this.onVoltar}
                    />
                )}
            </div>
        )
    }
}

interface DistribuicaoProdutosFormProps {
    filtro: DistribuicaoProcedimentoFiltro
    onVoltar(): void
    loadComponenteFilter(filtro: DistribuicaoProcedimentoFiltro): void
    detalhe: PromiseRecord<DistribuicaoProdutosBrasilDto>
}

const DistribuicaoProdutosFormComponent: React.FC<DistribuicaoProdutosFormProps> = ({
    filtro,
    detalhe,
    onVoltar,
    loadComponenteFilter,
}) => {
    const interval: DateInterval = filtro.interval
    const handleSearchPeriodo = (interval: DateInterval) => {
        const newFiltro = { ...filtro, interval }
        loadComponenteFilter(newFiltro)
    }

    return (
        <div>
            <HeaderDistribuicaoProdutos
                nomeComponente={detalhe.data.nomeComponente}
                nomeProduto={detalhe.data.nome}
                registroAnvisa={detalhe.data.registro}
                onVoltar={onVoltar}
            />
            <div className='report-content'>
                <Columns className='report-tabs align-center'>
                    <Column size='6'>
                        <div className='report-tabs-links'>LISTA DE HOSPITAIS</div>
                    </Column>
                    <Column size='6' textRight>
                        <PeriodoFilter value={interval} onChange={handleSearchPeriodo} />
                    </Column>
                </Columns>

                <DadosUsoComponenteCard quantidade={detalhe.data.quantidade} />
                <br />
                <ComponenteBrasilTable
                    distProdutos={detalhe.data.produtos}
                    report={() => (
                        <Link
                            to={{
                                pathname: '/api/report/getDetalheProdutoTipoRevisao/csv',
                                search: stringify(filtro),
                            }}
                            target='_blank'
                            title='Exportar para CSV'
                        >
                            <Text color='grey-darker'>
                                <Icon icon='exportar' />
                            </Text>
                        </Link>
                    )}
                />
            </div>
        </div>
    )
}

let HeaderDistribuicaoProdutos = props => {
    const registroAnvisa = props.registroAnvisa ? props.registroAnvisa : 'Sem registro anvisa'
    return (
        <div>
            <HeaderContainer className='report-header-rastreabilidade'>
                <Button className='button-round button-back' onClick={props.onVoltar}>
                    <Icon icon='voltar' />
                </Button>
                <Columns>
                    <Column size='10'>
                        <div>
                            <p className='is-grey-darker is-small is-uppercase'>TIPOS DE REVISÃO</p>
                            <p>
                                {registroAnvisa && <span className='is-semibold is-uppercase'>{registroAnvisa}</span>}
                                {props.nomeProduto && (
                                    <span className='is-grey-darker is-small'>{' ' + props.nomeProduto}</span>
                                )}
                            </p>
                            <p className='is-semibold is-grey-darker is-small'>em {props.nomeComponente}</p>
                        </div>
                    </Column>
                </Columns>
            </HeaderContainer>
        </div>
    )
}

let DadosUsoComponenteCard = props => {
    return (
        <Card title='Dados de uso registro'>
            <LoadingStateContainer readyState='success'>
                <div className='has-text-centered'>
                    <Text size='x-large' className='is-semibold is-big'>
                        {props.quantidade}
                    </Text>
                    <p className='is-semibold is-grey-darker is-small'>Procedimentos</p>
                </div>
            </LoadingStateContainer>
        </Card>
    )
}
