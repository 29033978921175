import { AlertModal, AlertModalButtonBar, AlertModalContent, Button, Linha } from 'libs/components'
import * as React from 'react'

interface ModalPreencherFichaEdicaoProps {
    active: boolean
    onClose: () => void
    onOk: () => void
    mensagem: string
}

export class ModalPreencherFichaEdicao extends React.Component<ModalPreencherFichaEdicaoProps> {
    render() {
        return (
            <AlertModal active={this.props.active} success>
                <AlertModalContent title='Edição realizada com sucesso!'>{this.props.mensagem}</AlertModalContent>
                <AlertModalButtonBar>
                    <Linha />
                    <Button size='medium' onClick={this.props.onClose}>
                        Sair
                    </Button>
                    <Button type='success' size='medium' className='link-button' onClick={this.props.onOk}>
                        Preencher
                    </Button>
                </AlertModalButtonBar>
            </AlertModal>
        )
    }
}
