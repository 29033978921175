import React from 'react'
import { WrappedFieldArrayProps } from 'redux-form'
import { RelacaoEmpresasListCard } from './RelacaoEmpresasListCard'
import { RelacaoEmpresasFormModal } from './RelacaoEmpresasFormModal'
import RelacaoEmpresas from 'api/RelacaoEmpresas'

interface RelacaoEmpresasListProps extends WrappedFieldArrayProps {
    openModal: boolean
    onClickEditCard: () => void
    onCloseModal: () => void
}

export const RelacaoEmpresasList: React.FC<RelacaoEmpresasListProps> = props => {
    const { fields, openModal, onClickEditCard, onCloseModal } = props

    const [editIndex, setEditIndex] = React.useState<number>()

    const handleClickCancelar = () => {
        setEditIndex(undefined)
        onCloseModal()
    }

    const handleSaveModal = modalForm => {
        const mutableForm = modalForm.toJS()

        return RelacaoEmpresas.validateRelacao(mutableForm).then((response) => {
            if (Number.isInteger(editIndex)) {
                fields.remove(editIndex)
            }
            fields.push(modalForm)
            handleClickCancelar()
            return response
        })
    }

    const handleEditCard = (index: number) => {
        setEditIndex(index)
        onClickEditCard()
    }

    const handleInitialValuesTypes = initialValues => {
        if (initialValues) {
            const initialValuesInJs = initialValues.toJS()
            return {
                ...initialValuesInJs,
                anoInicio: initialValuesInJs.anoInicio?.toString(),
                anoFim: initialValuesInJs.anoFim?.toString(),
            }
        } else {
            return {}
        }
    }

    return (
        <>
            {openModal && (
                <RelacaoEmpresasFormModal
                    initialValues={handleInitialValuesTypes(fields.get(editIndex))}
                    onClickCancelar={handleClickCancelar}
                    onSubmit={handleSaveModal}
                />
            )}
            {fields.map((_, index: number) => {
                const reverseIndex: number = fields.length - 1 - index
                return (
                    <RelacaoEmpresasListCard
                        key={reverseIndex}
                        relacaoEmpresa={fields.get(reverseIndex)}
                        onOkDelete={() => fields.remove(reverseIndex)}
                        onClickEdit={() => handleEditCard(reverseIndex)}
                    />
                )
            })}
        </>
    )
}
