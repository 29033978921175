
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.555,"time":33300.00000000001,"words":111}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Acesse o RNI`}</li>
      <li parentName="ol">{`Clique em `}<strong parentName="li">{`“Esqueceu a senha? clique aqui”`}</strong></li>
      <li parentName="ol">{`Informe seu CPF e Data de nascimento`}</li>
      <li parentName="ol">{`Clique no botão “Solicitar nova senha”`}</li>
      <li parentName="ol">{`Você irá receber um e-mail com o assunto “Nova senha gerada” com uma nova senha gerada pelo RNI.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Alguns servidores de e-mail podem classificar os e-mails do RNI como “Spam” ou “Lixo eletrônico”, caso você demore a receber o e-mail com a sua nova senha, verifique a sua caixa de “Spam/lixo”.`}</p>
    </blockquote>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Acesse o RNI e faça o login com a senha recebida no e-mail`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Dica:`}</strong>{` Após o acesso, altere a senha para uma que seja mais fácil de lembrar.`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

