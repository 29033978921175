import * as React from 'react'
import * as C from 'libs/components'
// import * as DateUtil from 'libs/util/DateUtil'
import { DadosCirurgiaVisualizacaoPrimariaJoelhoDto, ComponenteVisualizacaoDto } from 'backend'
import * as FC from 'view/home/novoProcedimento/ficha/componentes'

interface DadosCirurgiaVisualizacaoProps {
    dadosCirurgia: DadosCirurgiaVisualizacaoPrimariaJoelhoDto
    componentes: ComponenteVisualizacaoDto
}

export class DadosPrimariaJoelho extends React.Component<DadosCirurgiaVisualizacaoProps, any> {
    render() {
        const dadosCirurgia = this.props.dadosCirurgia
        const componentes = this.props.componentes

        return (
            <C.Conteudo>
                <C.Label className='with-border-bellow'>Diagnóstico</C.Label>
                <C.TextLabel title='Motivos de artroplastia' value={dadosCirurgia.motivosArtroplastia} />

                <C.Label className='with-border-bellow'>Procedimento cirúrgico prévio</C.Label>
                <C.Columns auto>
                    <C.TextLabel title='Procedimentos prévios' value={dadosCirurgia.procedimentosPrevios} />
                </C.Columns>
                <C.Columns auto>
                    <C.TextLabel title='Implante prévio' value={dadosCirurgia.implantePrevio} />
                </C.Columns>

                <C.Label className='with-border-bellow'>Procedimento cirúrgico atual</C.Label>
                <C.Columns auto>
                    <C.TextLabel title='Abordagem cirúrgica' value={dadosCirurgia.abordagemCirurgica} />
                    <C.TextLabel title='Uso de dreno' value={dadosCirurgia.usoDreno} />
                </C.Columns>
                <C.Columns auto>
                    <C.TextLabel title='Transfusão sanguínea' value={dadosCirurgia.transfusaoSanguinea} />
                </C.Columns>
                <C.Columns auto>
                    <C.TextLabel title='Antibiótico' value={dadosCirurgia.antibiotico} />
                    {dadosCirurgia.periodoAntibiotico && (
                        <C.TextLabel title='Período' value={dadosCirurgia.periodoAntibiotico} />
                    )}
                </C.Columns>

                <C.Label className='with-border-bellow'>Tipo de artroplastia</C.Label>
                <C.Columns auto>
                    <C.TextLabel title='Tipo de artroplastia' value={dadosCirurgia.tipoArtroplastia} />
                </C.Columns>
                <C.Columns auto>
                    {dadosCirurgia.fixacao && <C.TextLabel title='Fixação' value={dadosCirurgia.fixacao} />}
                </C.Columns>
                <C.Label className='with-border-bellow'>Cimento ósseo</C.Label>
                <C.Columns auto>
                    <C.TextLabel title='Foi utilizado' value={dadosCirurgia.cimentoOsseo} />
                    {dadosCirurgia.lavagemPulsatil && (
                        <C.TextLabel title='Lavagem pulsátil' value={dadosCirurgia.lavagemPulsatil} />
                    )}
                </C.Columns>

                <C.Label className='with-border-bellow'>Enxertia</C.Label>
                {componentes.enxertiaFemoralOpcao || componentes.enxertiaTibialOpcao ? (
                    <C.Columns>
                        <C.Column>
                            <C.TextLabel title='Femoral' value={componentes.enxertiaFemoralOpcao} />
                        </C.Column>
                        <C.Column>
                            <C.TextLabel title='Tibial' value={componentes.enxertiaTibialOpcao} />
                        </C.Column>
                    </C.Columns>
                ) : (
                    <C.Columns>
                        <C.Column>
                            <C.TextLabel title='Foi realizada' value={'Não'} />
                        </C.Column>
                    </C.Columns>
                )}

                <FC.ComponentesUtilizadosArtroplastia
                    principais={componentes.componentesPrincipais}
                    complementares={componentes.componentesComplementares}
                />
            </C.Conteudo>
        )
    }
}
