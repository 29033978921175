/* tslint:disable */
import React from 'react'

const SvgAlerta = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M12.431 18.004h-.862a.354.354 0 01-.368-.363v-.916c0-.206.151-.374.368-.374h.862c.218 0 .368.168.368.373v.918c0 .209-.156.362-.368.362zM11.223 15.12c0 .209.156.362.368.362h.808l.029.003c.024 0 .037 0 .05-.002a.478.478 0 00.064-.017.367.367 0 00.235-.346V9.867a.354.354 0 00-.368-.363h-.818a.354.354 0 00-.367.363v5.253z' />
        <path
            clipRule='evenodd'
            d='M12 4.998L3.616 19.504h16.768L12 4.998zm.864-1.499a.998.998 0 00-1.728 0l-9.25 16.004a1 1 0 00.864 1.501h18.5a1 1 0 00.864-1.5l-9.25-16.005z'
        />
    </svg>
)

export default SvgAlerta
