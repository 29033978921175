
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.59,"time":95400,"words":318}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Ao realizar o preenchimento de registros no sistema RNI, as etapas descritas e ilustradas abaixo deverão ser executadas:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`No sistema RNI, tanto o Médico, Médico Residente ou Técnico podem dar início ao preenchimento do registro. Sendo assim, existem duas maneiras de iniciar o preenchimento, ilustrados abaixo como fluxo 1a e 1b:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`1a:`}</strong>{` O Técnico informa qual procedimento foi executado, qual a equipe médica participante, o médico responsável pelo procedimento e quem foi o paciente deste procedimento.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`1b:`}</strong>{` O Médico ou Médico Residente informam os profissionais que participaram da equipe médica, o médico responsável pelo procedimento, quem foi o paciente, qual o procedimento executado e os dados clínicos referente ao procedimento executado.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Caso o Técnico tenha iniciado o registro (fluxo 1a), é necessário que o Médico ou o Médico Residente preencham os dados clínicos.`}</p>
      </li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Caso o Médico ou Médico residente tenham iniciado o registro (fluxo 1b), este passo não é necessário, visto que estas informações já foram preenchidas.`}</p>
    </blockquote>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Após informados os dados do paciente, da equipe médica e os dados clínicos do procedimento, é necessário realizar o preenchimento dos dados dos produtos utilizados no procedimento, e os dados financeiros referente aos produtos utilizados. Este preenchimento pode ocorrer de duas maneiras:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`3a:`}</strong>{` O Técnico, o Médico ou o Médico Residente realizam o preenchimento dos dados de produtos.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`3b:`}</strong>{` O Administrativo realiza o preenchimento tanto dos dados de produtos, quanto dos dados financeiros.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Caso o Técnico, o Médico ou o Médico Residente tenham realizado o preenchimento dos dados de produto (fluxo 3a), é necessário que o Administrativo preencha os dados financeiros.`}</p>
      </li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Caso o Administrativo tenha feito o preenchimento dos dados de produto e financeiro (fluxo 3b), este passo não é necessário, visto que estas informações já foram preenchidas.`}</p>
    </blockquote>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Após o preenchimento dos dados financeiros, o registro estará finalizado, não necessitando do preenchimento de mais informações.`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/images/preenchimentoFicha.png",
        "alt": "Preenchimento de Registro"
      }}></img></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

