import * as React from 'react'
import * as C from 'libs/components'

interface SimNaoRadioFieldProps {
    label: string
    name: string
    limpar?: boolean
    required?: boolean
    disabled?: boolean
}

export class SimNaoRadioField extends React.Component<SimNaoRadioFieldProps> {
    static defaultProps: SimNaoRadioFieldProps = {
        label: '',
        name: '',
        limpar: true,
        required: false,
        disabled: false,
    }

    render() {
        return (
            <C.ControlField
                label={this.props.label}
                name={this.props.name}
                required={this.props.required}
                disabled={this.props.disabled}
            >
                <C.RadioField {...this.props} label='Sim' value='SIM' />
                <C.RadioField {...this.props} label='Não' value='NAO' />

                {this.props.limpar && <C.ClearRadioFieldButton name={this.props.name} />}
            </C.ControlField>
        )
    }
}
