import Report from 'api/Report'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'
import moment from 'moment'
import { State } from '../index'
import isEqual from 'lodash/isEqual'
import * as enums from 'redux/enums'
import * as DateUtil from 'libs/util/DateUtil'
import {
    DistribuicaoProcedimentoRow,
    ProcedimentoDimensions,
    DistribuicaoProcedimentoDto,
    ClassificacaoCirurgia,
    TipoProcedimento,
    StatusProcedimento,
    Sexo,
    PeriodoDimension,
    LocalDimension,
    DistribuicaoProcedimentoFiltro,
    QuantidadeProcedimentosDto,
    MotivoProcedimentoEnum,
    TipoRevisaoEnum,
} from 'backend'

export const FIND_DIST_CLASSIFICACAO = 'rni/report/procedimento/FIND_DIST_CLASSIFICACAO'
export const FIND_DIST_CLASSIFICACAO_PERIODO = 'rni/report/procedimento/FIND_DIST_CLASSIFICACAO_PERIODO'
export const FIND_DIST_CLASSIFICACAO_SEXO = 'rni/report/procedimento/FIND_DIST_CLASSIFICACAO_SEXO'
export const FIND_DIST_CLASSIFICACAO_LOCAL = 'rni/report/procedimento/FIND_DIST_CLASSIFICACAO_LOCAL'
export const FIND_DIST_HOSPITAIS = 'rni/report/procedimento/FIND_DIST_HOSPITAIS'
export const FIND_DIST_IDADE = 'rni/report/procedimento/FIND_DIST_IDADE'
export const FIND_QTD_PROCEDIMENTOS = 'rni/report/procedimento/FIND_QTD_PROCEDIMENTOS'
export const CLEAR_QTD_PROCEDIMENTOS = 'rni/report/procedimento/CLEAR_QTD_PROCEDIMENTOS'
export const CLEAR_ALL_DISTRIBUICOES = 'rni/report/procedimento/CLEAR_ALL_DISTRIBUICOES'

export interface ClassificacaoCount {
    total: number
    classificacoes: {
        [key in ClassificacaoCirurgia]: {
            title: string
            total: number
            quocient: number
            className: string
        }
    }
}

export interface ClassificacaoStatusCount extends ClassificacaoCount{
    statusProcedimento: {
        [key in StatusProcedimento]: {
            title: string
            total: number
        }
    }
}

export interface DistClassificacao extends PromiseRecord<DistribuicaoProcedimentoDto> {
    result?: ClassificacaoStatusCount
}

export interface ResumoLocalItem extends ClassificacaoCount {
    local: LocalDimension
    quocient: number
}

export interface DistClassificacaoLocal extends PromiseRecord<DistribuicaoProcedimentoDto> {
    result?: ResumoLocalItem[]
}

export interface DistClassificacaoPeriodo extends PromiseRecord<DistribuicaoProcedimentoDto> {
    result?: {
        categories: any[]
        series: { name: string; data: any[]; color: string }[]
    }
}

export interface DistClassificacaoSexo extends PromiseRecord<DistribuicaoProcedimentoDto> {
    result?: {
        masculino: ClassificacaoCount
        feminino: ClassificacaoCount
    }
}

export interface DistClassificacaoIdade extends PromiseRecord<DistribuicaoProcedimentoDto> {
    result?: {
        categories: any[]
        series: { name: string; data: any[]; color: string }[]
    }
}

export interface ProcedimentoReportState
    extends Readonly<{
        distClassificacao: DistClassificacao
        distClassificacaoPeriodo: DistClassificacaoPeriodo
        distClassificacaoLocal: DistClassificacaoLocal
        distClassificacaoSexo: DistClassificacaoSexo
        distHospitais: PromiseRecord<DistribuicaoProcedimentoDto>
        distClassificacaoIdade: DistClassificacaoIdade
        qtdProcedimentos: PromiseRecord<QuantidadeProcedimentosDto>
    }> {}

export const initialState: ProcedimentoReportState = {
    distClassificacao: {},
    distClassificacaoPeriodo: {},
    distClassificacaoLocal: {},
    distClassificacaoSexo: {},
    distHospitais: {},
    distClassificacaoIdade: {},
    qtdProcedimentos: {},
}

export default function reducer(state: ProcedimentoReportState = initialState, action): ProcedimentoReportState {
    switch (action.type) {
        case FIND_DIST_CLASSIFICACAO:
            return {
                ...state,
                distClassificacao: exportFunctions.reduceDistClassificacao(
                    promiseReducer(state.distClassificacao, action),
                    action.meta
                ),
            }
        case FIND_DIST_CLASSIFICACAO_PERIODO:
            return {
                ...state,
                distClassificacaoPeriodo: reduceDistClassificacaoPeriodo(
                    promiseReducer(state.distClassificacaoPeriodo, action),
                    action.meta.granularity
                ),
            }
        case FIND_DIST_CLASSIFICACAO_SEXO:
            return {
                ...state,
                distClassificacaoSexo: exportFunctions.reduceDistClassificacaoSexo(
                    promiseReducer(state.distClassificacaoSexo, action),
                    action.meta
                ),
            }
        case FIND_DIST_CLASSIFICACAO_LOCAL:
            return {
                ...state,
                distClassificacaoLocal: exportFunctions.reduceDistClassificacaoLocal(
                    promiseReducer(state.distClassificacaoLocal, action),
                    action.meta
                ),
            }
        case FIND_DIST_HOSPITAIS:
            return {
                ...state,
                distHospitais: promiseReducer(state.distClassificacaoLocal, action),
            }
        case FIND_DIST_IDADE:
            return {
                ...state,
                distClassificacaoIdade: reduceDistClassificacaoIdade(
                    promiseReducer(state.distClassificacaoIdade, action)
                ),
            }
        case FIND_QTD_PROCEDIMENTOS:
            return {
                ...state,
                qtdProcedimentos: promiseReducer(state.qtdProcedimentos, action),
            }
        case CLEAR_QTD_PROCEDIMENTOS:
            return {
                ...state,
                qtdProcedimentos: {},
            }
        case CLEAR_ALL_DISTRIBUICOES:
            const { qtdProcedimentos, ...sliceInitialState } = initialState
            return {
                ...state,
                ...sliceInitialState,
            }
        default:
            return state
    }
}

// Helpers

/**
 * Obtém o total de procedimentos a partir de um filtro.
 *
 * @param data
 * @param filter
 */
export const getTotalFiltered = (
    rows: DistribuicaoProcedimentoRow[],
    filter: (row: DistribuicaoProcedimentoRow) => boolean
) => {
    if (!rows || rows.length === 0) {
        return 0
    }

    return rows
        .filter(filter)
        .map(row => row.total)
        .reduce((t1, t2) => t1 + t2, 0)
}

export const groupFormatter: { [key in ProcedimentoDimensions]: (value: any, range?: string) => any } = {
    CLASSIFICACAO_CIRURGIA: (value: ClassificacaoCirurgia) => value,
    IDADE: (value: number, faixa: string) => {
        if (value >= 90 && '90+' === faixa) {
            return faixa
        }

        const range: string[] = faixa.split('-')

        if (value >= parseInt(range[0]) && value <= parseInt(range[1])) {
            return faixa
        }
    },
    SEXO: (value: Sexo) => enums.sexoName[value],
    LOCAL_CIDADE: (value: LocalDimension) => value.cidade.nome,
    LOCAL_HOSPITAL: (value: LocalDimension) => value.hospital.nome,
    LOCAL_REGIAO: (value: LocalDimension) => enums.regiaoName[value.regiao],
    LOCAL_UF: (value: LocalDimension) => value.uf,
    PERIODO_ANO: (value: PeriodoDimension) => value.ano,
    PERIODO_MES: (value: PeriodoDimension) => moment(value.mes + '/' + value.ano, 'MM/YYYY').format('MMM/YYYY'),
    PERIODO_DIA: (value: PeriodoDimension) =>
        moment(value.dia + '/' + value.mes + '/' + value.ano, 'DD/MM/YYYY').format('DD/MMM'),
    TIPO_PROCEDIMENTO: (value: TipoProcedimento) => enums.tipoProcedimentoName[value],
    STATUS_PROCEDIMENTO: (value: StatusProcedimento) => enums.statusProcedimentos[value].name,
}

// Action creators

export const loadAll = (requiredDomains?: { [index: string]: any[] }, onlyHospital = false) => (dispatch, getState) => {
    const state: State = getState()
    const filtro = state.report.filtro.filtro

    dispatch(findDistClassificacao(filtro, requiredDomains))
    dispatch(findDistClassificacaoIdade(filtro))
    dispatch(findDistClassificacaoPeriodo(filtro))
    dispatch(findDistClassificacaoSexo(filtro, requiredDomains))
    if (!onlyHospital) {
        dispatch(findDistClassificacaoLocal(filtro, requiredDomains))
    }
}

export const loadMotivo = (mot: MotivoProcedimentoEnum) => (dispatch, getState) => {
    const state: State = getState()
    const filtro = { ...state.report.filtro.filtro, motivo: mot }

    dispatch(findDistClassificacao(filtro))
    dispatch(findDistClassificacaoIdade(filtro))
    dispatch(findDistClassificacaoPeriodo(filtro))
    dispatch(findDistClassificacaoSexo(filtro))
}

export const loadTipoRevisao = (tipo?: TipoRevisaoEnum) => (dispatch, getState) => {
    const state: State = getState()
    const filtro = { ...state.report.filtro.filtro, tipoRevisao: tipo }

    dispatch(findDistClassificacao(filtro))
    dispatch(findDistClassificacaoIdade(filtro))
    dispatch(findDistClassificacaoPeriodo(filtro))
    dispatch(findDistClassificacaoSexo(filtro))
}

export const findDistClassificacao = (
    filtro?: DistribuicaoProcedimentoFiltro,
    requiredDomains?: { [index: string]: any[] }
) => {
    return {
        type: FIND_DIST_CLASSIFICACAO,
        meta: requiredDomains,
        promise: Report.findDistribuicaoProcedimentos({
            group: ['CLASSIFICACAO_CIRURGIA','STATUS_PROCEDIMENTO'],
            ...filtro,
        }),
    }
}

export const findDistClassificacaoLocal = (
    filtro?: DistribuicaoProcedimentoFiltro,
    requiredDomains?: { [index: string]: any[] }
) => {
    let group: ProcedimentoDimensions[] = ['CLASSIFICACAO_CIRURGIA']
    const local = filtro.local || {}

    if (local.hospitalId) {
        // Não há agrupamento
    } else if (local.cidadeId) {
        group.push('LOCAL_HOSPITAL')
    } else if (local.uf) {
        group.push('LOCAL_CIDADE')
    } else if (local.regiao) {
        group.push('LOCAL_UF')
    } else {
        group.push('LOCAL_REGIAO')
    }

    return {
        type: FIND_DIST_CLASSIFICACAO_LOCAL,
        meta: requiredDomains,
        promise: Report.findDistribuicaoProcedimentos({
            group: group,
            ...filtro,
        }),
    }
}

export const findDistClassificacaoSexo = (
    filtro?: DistribuicaoProcedimentoFiltro,
    requiredDomains?: { [index: string]: any[] }
) => {
    return {
        type: FIND_DIST_CLASSIFICACAO_SEXO,
        meta: requiredDomains,
        promise: Report.findDistribuicaoProcedimentos({
            group: ['CLASSIFICACAO_CIRURGIA', 'SEXO'],
            ...filtro,
        }),
    }
}

export const findDistClassificacaoPeriodo = (filtro?: DistribuicaoProcedimentoFiltro) => {
    let granularity: ProcedimentoDimensions
    if (!filtro.interval || !filtro.interval.start || !filtro.interval.end) {
        granularity = 'PERIODO_ANO'
    } else {
        const momentInterval = DateUtil.toMomentInterval(filtro.interval)
        const days = DateUtil.getDifference(momentInterval.end, momentInterval.start)

        if (days <= 60) {
            granularity = 'PERIODO_DIA'
        } else if (days <= 730) {
            granularity = 'PERIODO_MES'
        } else {
            granularity = 'PERIODO_ANO'
        }
    }

    return {
        type: FIND_DIST_CLASSIFICACAO_PERIODO,
        meta: {
            granularity: granularity,
        },
        promise: Report.findDistribuicaoProcedimentos({
            group: ['CLASSIFICACAO_CIRURGIA', granularity],
            ...filtro,
        }),
    }
}

export const findDistClassificacaoIdade = (filtro?: DistribuicaoProcedimentoFiltro) => {
    return {
        type: FIND_DIST_IDADE,
        promise: Report.findDistribuicaoProcedimentos({
            group: ['CLASSIFICACAO_CIRURGIA', 'IDADE'],
            ...filtro,
        }),
    }
}

export const findDistHospitais = (filtro?: DistribuicaoProcedimentoFiltro) => {
    return {
        type: FIND_DIST_HOSPITAIS,
        promise: Report.findDistribuicaoProcedimentos({
            group: ['LOCAL_HOSPITAL'],
            ...filtro,
        }),
    }
}

// Reducers

const reduceDistClassificacao = (
    promiseRecord: PromiseRecord<DistribuicaoProcedimentoDto>,
    requiredDomains: { [index: string]: any[] } = {}
): DistClassificacao => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }

    const mergedClassificacoes = exportFunctions.mergeDomains(
        requiredDomains['CLASSIFICACAO_CIRURGIA'],
        promiseRecord.data.domain['CLASSIFICACAO_CIRURGIA']
    )
    const mergedStatus = exportFunctions.mergeDomains(
        requiredDomains['STATUS_PROCEDIMENTO'],
        promiseRecord.data.domain['STATUS_PROCEDIMENTO']
    )

    return {
        ...promiseRecord,
        result: {
            total: promiseRecord.data && promiseRecord.data.totalProcedimentos,
            classificacoes: exportFunctions.reduceDistClassificacaoCount(
                promiseRecord.data.rows,
                promiseRecord.data.totalProcedimentos,
                mergedClassificacoes
            ),
            statusProcedimento: exportFunctions.reduceDistStatusCount(
                promiseRecord.data.rows,
                mergedStatus
            ),
        },
    }
}

const reduceDistClassificacaoCount = (
    rows: DistribuicaoProcedimentoRow[],
    totalProcedimentos: number,
    classificacoes: ClassificacaoCirurgia[] = []
) => {
    const f = (key: ClassificacaoCirurgia) => {
        const total = getTotalFiltered(rows, row => row.classificacaoCirurgia === key)
        return {
            title: enums.classificacaoCirurgiaName[key],
            total: total,
            quocient: totalProcedimentos > 0 ? total / totalProcedimentos : 0,
            className: enums.classificacaoCirurgiaReportClassName[key],
        }
    }

    const res = {
        PRIMARIA: f('PRIMARIA'),
        REVISAO: f('REVISAO'),
        ANGIOPLASTIA: f('ANGIOPLASTIA'),
    }

    Object.keys(res).forEach((k: ClassificacaoCirurgia) => {
        if (classificacoes.indexOf(k) < 0) {
            delete res[k]
        }
    })

    return res
}

const reduceDistStatusCount = (
    rows: DistribuicaoProcedimentoRow[],
    status: StatusProcedimento[] = []
) => {
    const f = (key: StatusProcedimento) => {
        const total = getTotalFiltered(rows, row => row.statusProcedimento === key)
        return {
            title: enums.statusProcedimentos[key].name,
            total: total,
        }
    }

    const res = {
        AGUARDANDO_DADOS_PRODUTO: f('AGUARDANDO_DADOS_PRODUTO'),
        AGUARDANDO_DADOS_FINANCEIRO: f('AGUARDANDO_DADOS_FINANCEIRO'),
        AGUARDANDO_DADOS_CLINICOS: f('AGUARDANDO_DADOS_CLINICOS'),
        FINALIZADO: f('FINALIZADO'),
    }

    Object.keys(res).forEach((k: StatusProcedimento) => {
        if (status.indexOf(k) < 0) {
            delete res[k]
        }
    })

    return res
}

const reduceDistClassificacaoLocal = (
    promiseRecord: PromiseRecord<DistribuicaoProcedimentoDto>,
    requiredDomains: { [index: string]: any[] } = {}
): DistClassificacaoLocal => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }

    const groups = promiseRecord.data.filtro.group
    let domain: ProcedimentoDimensions
    if (groups.indexOf('LOCAL_REGIAO') >= 0) {
        domain = 'LOCAL_REGIAO'
    } else if (groups.indexOf('LOCAL_UF') >= 0) {
        domain = 'LOCAL_UF'
    } else if (groups.indexOf('LOCAL_CIDADE') >= 0) {
        domain = 'LOCAL_CIDADE'
    } else if (groups.indexOf('LOCAL_HOSPITAL') >= 0) {
        domain = 'LOCAL_HOSPITAL'
    }

    if (!domain) {
        return promiseRecord
    }

    const locals = requiredDomains[domain] ? requiredDomains[domain] : promiseRecord.data.domain[domain]

    return {
        ...promiseRecord,
        result: locals.map((local: LocalDimension) => {
            const localRows = promiseRecord.data.rows.filter(row => isEqual(row.local, local))
            let total = 0
            let quocient

            if (localRows.length > 0) {
                total = exportFunctions.getTotalFiltered(localRows, row => true)
                quocient = total / promiseRecord.data.totalProcedimentos
            }

            const mergedDomains = exportFunctions.mergeDomains(
                requiredDomains['CLASSIFICACAO_CIRURGIA'],
                promiseRecord.data.domain['CLASSIFICACAO_CIRURGIA']
            )
            let classificacoes = mergedDomains
            if (mergedDomains.includes('PRIMARIA') || mergedDomains.includes('REVISAO')) {
                classificacoes = exportFunctions.mergeDomains(mergedDomains, ['PRIMARIA', 'REVISAO'])
            }

            return {
                local,
                total,
                quocient,
                classificacoes: exportFunctions.reduceDistClassificacaoCount(localRows, total, classificacoes),
            }
        }),
    }
}

export const reduceDistClassificacaoSexo = (
    promiseRecord: PromiseRecord<DistribuicaoProcedimentoDto>,
    requiredDomains: { [index: string]: any[] } = {}
): DistClassificacaoSexo => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }

    const mascRows = promiseRecord.data.rows.filter(row => row.sexo === 'MASCULINO')
    const femRows = promiseRecord.data.rows.filter(row => row.sexo === 'FEMININO')

    const mascTotal = exportFunctions.getTotalFiltered(mascRows, row => true)
    const femTotal = exportFunctions.getTotalFiltered(femRows, row => true)
    const total = mascTotal + femTotal

    const mergedClassificacoes = exportFunctions.mergeDomains(
        requiredDomains['CLASSIFICACAO_CIRURGIA'],
        promiseRecord.data.domain['CLASSIFICACAO_CIRURGIA']
    )

    return {
        ...promiseRecord,
        result: {
            masculino: {
                total: mascTotal,
                classificacoes: exportFunctions.reduceDistClassificacaoCount(mascRows, total, mergedClassificacoes),
            },
            feminino: {
                total: femTotal,
                classificacoes: exportFunctions.reduceDistClassificacaoCount(femRows, total, mergedClassificacoes),
            },
        },
    }
}

export const reduceDistClassificacaoPeriodo = (
    promiseRecord: PromiseRecord<DistribuicaoProcedimentoDto>,
    granularity: ProcedimentoDimensions
): DistClassificacaoPeriodo => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }

    const data = promiseRecord.data
    const result = {
        categories: [],
        series: [],
    }

    if (data.rows.length > 0) {
        const periodos = data.domain[granularity]
        result.categories = periodos.map(value => groupFormatter[granularity](value))
        result.series = data.domain['CLASSIFICACAO_CIRURGIA'].map(classificacao => ({
            name: enums.classificacaoCirurgiaName[classificacao],
            data: [],
            color: enums.classificacaoCirurgiaReportColor[classificacao],
        }))

        periodos.forEach(periodo => {
            const category = groupFormatter[granularity](periodo)

            data.domain['CLASSIFICACAO_CIRURGIA'].forEach(classificacao => {
                const total = getTotalFiltered(
                    data.rows,
                    row => isEqual(row.periodo, periodo) && row.classificacaoCirurgia === classificacao
                )
                const index = result.series.map(s => s.name).indexOf(enums.classificacaoCirurgiaName[classificacao])
                result.series[index].data.push([category, total])
            })
        })
    }

    return {
        ...promiseRecord,
        result: result,
    }
}

export const reduceDistClassificacaoIdade = (
    promiseRecord: PromiseRecord<DistribuicaoProcedimentoDto>
): DistClassificacaoIdade => {
    if (promiseRecord.readyState !== 'success') {
        return promiseRecord
    }
    const granularity = 'IDADE'
    const data = promiseRecord.data
    const result = {
        categories: [],
        series: [],
    }

    if (data.rows.length > 0) {
        result.series = data.domain['CLASSIFICACAO_CIRURGIA'].map(classificacao => ({
            name: enums.classificacaoCirurgiaName[classificacao],
            data: [],
            color: enums.classificacaoCirurgiaReportColor[classificacao],
        }))
        result.categories = ['0-11', '12-18', '19-28', '29-38', '39-48', '49-59', '60-69', '70-79', '80-89', '90+']
        result.categories.forEach(cat => {
            data.domain['CLASSIFICACAO_CIRURGIA'].forEach(classificacao => {
                const total = getTotalFiltered(
                    data.rows,
                    row => groupFormatter[granularity](row.idade, cat) && row.classificacaoCirurgia === classificacao
                )
                const index = result.series.map(s => s.name).indexOf(enums.classificacaoCirurgiaName[classificacao])
                result.series[index].data.push([cat, total])
            })
        })
    }

    return {
        ...promiseRecord,
        result: result,
    }
}

export const loadQtdProcedimentos = () => {
    return {
        type: FIND_QTD_PROCEDIMENTOS,
        promise: Report.findQtdProcedimentos(),
    }
}

export const clearQtdProcedimentos = () => {
    return {
        type: CLEAR_QTD_PROCEDIMENTOS,
    }
}

export const clearAllDistribuicoes = () => {
    return {
        type: CLEAR_ALL_DISTRIBUICOES,
    }
}

const mergeDomains = (firstDomain?: any[], secondDomain?: any[]) => {
    const merged = []

    const fillBlanks = (domain: any[]) => {
        domain.forEach(domain => {
            if (merged.indexOf(domain) < 0) {
                merged.push(domain)
            }
        })
    }

    firstDomain && fillBlanks(firstDomain)
    secondDomain && fillBlanks(secondDomain)

    return merged
}

export const exportFunctions = {
    mergeDomains,
    getTotalFiltered,
    reduceDistClassificacao,
    reduceDistClassificacaoSexo,
    reduceDistClassificacaoLocal,
    reduceDistClassificacaoCount,
    reduceDistStatusCount,
}
