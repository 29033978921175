import React from 'react'
import { RecebimentoIntegracaoDto } from 'backend'
import { Button, Columns, Column, Label } from 'libs/components'
import { dataTypeByEnum } from 'view/home/gestorIntegracao/common/handleRecebimentoEnums'
import { IntegracaoRowIconColumn } from '../../common/IntegracaoRowIconColumn'

interface RecebimentoIntegracaoInnerRowRecusadoProps {
    value: RecebimentoIntegracaoDto
    onClickButton: (event: React.MouseEvent) => void
}

export const RecebimentoIntegracaoInnerRowRecusado = (props: RecebimentoIntegracaoInnerRowRecusadoProps) => {
    const { value, onClickButton } = props
    return (
        <Columns className='recusados-inner-row'>
            <IntegracaoRowIconColumn status='fail' icon='erroExclamacao' iconSize='is-20px' />
            <Column className='recusados-labels-column' size='8'>
                <div className='recebimento-integracao-first-line-labels'>
                    <Label className='recebimento-integracao-label-tipo-recebimento'>
                        {dataTypeByEnum(value.tipoRecebimento)}
                    </Label>
                    <Label className='recebimento-integracao-label-sistema'>
                        Enviado por <span className='is-bold'>{value.nomeSistema}</span>
                    </Label>
                </div>
                <Label className='recebimento-integracao-label-mid-text'>Identificador do procedimento</Label>
                <Label className='recusados-label-id-value'>{value.identificadorExterno}</Label>
            </Column>
            <Button className='integracao-button-common-action recusados-button-detalhes-erros' onClick={onClickButton}>
                Detalhe dos erros
            </Button>
        </Columns>
    )
}
