/* tslint:disable */
import React from 'react'

const SvgDetalhes = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M17.047 15.59l3.652 3.65a1.03 1.03 0 11-1.46 1.458l-3.65-3.65-.287.2a7.785 7.785 0 01-4.474 1.409 7.828 7.828 0 117.829-7.829c0 1.62-.497 3.166-1.41 4.474l-.2.287zm-6.219 1.548a6.31 6.31 0 100-12.62 6.31 6.31 0 000 12.62zm-.76-9.425h1.52v2.356h2.356v1.519h-2.356v2.356h-1.52v-2.356H7.714v-1.52h2.356V7.714z' />
    </svg>
)

export default SvgDetalhes
