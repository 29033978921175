import { PerfilEnum } from 'api/Perfis'
import { tiposProcedimentoDescricaoByEnum } from 'api/Procedimento'
import { Perfil, VisualizacaoPrimariaJoelhoForm } from 'backend'
import {
    Button,
    ButtonBar,
    Column,
    Columns,
    Conteudo,
    FloatingContainer,
    Linha,
    LoadingStateContainer,
    SectionTitle,
    SideNav,
    SideNavItem,
    Step,
    Steps,
} from 'libs/components'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import * as Scroll from 'react-scroll'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { State } from 'redux/modules'
import * as procedimento from 'redux/modules//procedimento'
import {
    CabecalhoFicha,
    DadosGerais,
    DadosPaciente,
    DadosPrimariaJoelho,
    DadosProduto,
} from 'view/home/novoProcedimento/ficha/componentes'
import { AvisoVersaoFicha } from 'view/home/novoProcedimento/ficha/componentes/visualizacao/AvisoVersaoFicha'

interface VisualizacaoPrimariaJoelhoWrapperProps extends RouteComponentProps<any> {
    id: number
    idProced: number
    proced: string
    imprimirFicha: (id: number) => void
}

export default function VisualizacaoPrimariaJoelhoWrapper(props: VisualizacaoPrimariaJoelhoWrapperProps) {
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch(procedimento.findVisualizacao(props.id, props.proced))
    }, [dispatch, props.id, props.proced])

    const detail: PromiseRecord<VisualizacaoPrimariaJoelhoForm> = useSelector<
        State,
        PromiseRecord<VisualizacaoPrimariaJoelhoForm>
    >(state => state.procedimento.visualizacaoProcedimento as PromiseRecord<VisualizacaoPrimariaJoelhoForm>)
    const perfil = useSelector<State, Perfil>(state => state.auth.user.perfil)

    const isAdm = perfil === PerfilEnum.ADMINISTRATIVO
    const produtoText = isAdm ? 'Dados de produto e financeiro' : 'Dados de produto'
    return (
        <LoadingStateContainer readyState={detail?.readyState}>
            <Columns fullHeight>
                <Column verticalFlow size='4'>
                    <div className='procedimento-sidebar is-full-height'>
                        <FloatingContainer stopElement='footer' stopOffset={88}>
                            <Steps>
                                <Step name='PRIMÁRIA DE JOELHO' path={`${props.match.url}`}>
                                    {detail?.data && (
                                        <SideNav>
                                            <SideNavItem anchor='paciente'>Dados do paciente</SideNavItem>
                                            <SideNavItem anchor='gerais'>Dados gerais</SideNavItem>
                                            {detail.data.dadosCirurgia && (
                                                <SideNavItem anchor='cirurgia'>Dados de cirurgia</SideNavItem>
                                            )}
                                            {detail.data.dadosProduto.length > 0 && (
                                                <SideNavItem anchor='produto'>{produtoText}</SideNavItem>
                                            )}
                                        </SideNav>
                                    )}
                                </Step>
                            </Steps>
                        </FloatingContainer>
                    </div>
                </Column>
                <Column className='procedimento-conteudo'>
                    {detail.data && (
                        <VisualizacaoPrimariaJoelho
                            detail={detail}
                            history={props.history}
                            imprimir={props.imprimirFicha}
                            id={props.id}
                            idProced={props.idProced}
                            perfilAdm={isAdm}
                        />
                    )}
                </Column>
                {detail.error && <Redirect to='/forbidden' />}
            </Columns>
        </LoadingStateContainer>
    )
}

interface VisualizacaoPrimariaJoelhoProps {
    detail: PromiseRecord<VisualizacaoPrimariaJoelhoForm>
    id: number
    idProced: number
    history: any
    perfilAdm: boolean
    imprimir: (id: number) => void
}

function VisualizacaoPrimariaJoelho(props: VisualizacaoPrimariaJoelhoProps) {
    const dados = props.detail.data

    const imprimir = idProced => {
        return () => {
            props.imprimir(idProced)
        }
    }

    const tipoProcedimento =
        tiposProcedimentoDescricaoByEnum[dados.dadosGerais.tipoProcedimento] +
        ' ' +
        dados.dadosGerais.lado.toLowerCase()

    return (
        <div>
            <CabecalhoFicha tipoRegistro='Registro de artroplastia' tipoProcedimento={tipoProcedimento} />
            <AvisoVersaoFicha
                idFicha={props.idProced}
                idAtual={props.id}
                dataAtualizacao={dados.dadosGerais.dataAtualizacao}
                estadoVersaoFicha={dados.dadosGerais.estadoFicha}
            />

            <Scroll.Element name='paciente'>
                <SectionTitle title='DADOS DO PACIENTE' />
                <DadosPaciente paciente={dados.dadosGerais} />
            </Scroll.Element>
            <Scroll.Element name='gerais'>
                <SectionTitle title='DADOS GERAIS' />
                <DadosGerais geral={dados.dadosGerais} />
            </Scroll.Element>
            {dados.dadosCirurgia && !props.perfilAdm && (
                <Scroll.Element name='cirurgia'>
                    <SectionTitle title='DADOS DA CIRURGIA' />
                    <DadosPrimariaJoelho
                        dadosCirurgia={dados.dadosCirurgia}
                        componentes={dados.componentesUtilizados}
                    />
                </Scroll.Element>
            )}
            {dados.dadosProduto.length > 0 && (
                <Scroll.Element name='produto'>
                    <SectionTitle title={props.perfilAdm ? 'DADOS DE PRODUTO E FINANCEIRO' : 'DADOS DE PRODUTO'} />
                    <DadosProduto dadosProduto={dados.dadosProduto} isAdm={props.perfilAdm} />
                </Scroll.Element>
            )}
            <Conteudo>
                <Linha size='medium' />
                <ButtonBar>
                    <Button pulledLeft size='medium' className='simple-button' onClick={() => props.history.goBack()}>
                        Voltar
                    </Button>
                    <Button pulledRight type='success' size='medium' onClick={imprimir(props.id)}>
                        Gerar PDF
                    </Button>
                </ButtonBar>
            </Conteudo>
        </div>
    )
}
