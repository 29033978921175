import * as React from 'react'
import { useState } from 'react'
import * as C from 'libs/components'
import { Link } from 'react-router-dom'
import { ManualSideBarProps } from './ManualSideBar'

export function ManualHeader(props: ManualSideBarProps) {
    const [search, setSearch] = useState('')
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    const keyHandler = e => {
        if (e.key === 'Enter') {
            return props.history.push(`${homeOrPublic}/manualUsuario/search?s=${search}`)
        }
    }

    const homeOrPublic = props.history.location.pathname.startsWith('/home') ? '/home' : '/public'
    return (
        <div className='identificao-sistema-wrapper'>
            <div className='container'>
                <C.Columns className='align-center'>
                    <C.Column size='5'>
                        <h1 className='identificacao-sistema'>RNI - Central de ajuda</h1>
                    </C.Column>
                    <C.Column size='5'>
                        <C.TextInput
                            value={search}
                            onChange={handleSearchChange}
                            onKeyPress={e => keyHandler(e)}
                            className='manual-header-input'
                            maxLength={100}
                            placeholder='Pesquisar...'
                        />
                    </C.Column>
                    <C.Column size='2'>
                        <Link to={`${homeOrPublic}/manualUsuario/search?s=${search}`}>
                            <C.Button className='manual-header-button' pulledRight type='primary'>
                                Pesquisar
                            </C.Button>
                        </Link>
                    </C.Column>
                </C.Columns>
            </div>
        </div>
    )
}
