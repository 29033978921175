import Report from 'api/Report'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'
import { SumarioPacienteDto } from 'backend'

export const FIND_SUMARIO_FILTRADO = 'rni/report/paciente/FIND_SUMARIO_FILTRADO'

export interface PacienteReportState
    extends Readonly<{
        sumario: PromiseRecord<SumarioPacienteDto>
    }> {}

const PacienteInitialState: PacienteReportState = {
    sumario: {},
}

export default function reducer(state: PacienteReportState = PacienteInitialState, action): PacienteReportState {
    if (action.type === FIND_SUMARIO_FILTRADO) {
        return { ...state, sumario: promiseReducer(state.sumario, action) }
    }
    return state
}

export const findSumarioPacienteFiltrado = (id: number, filtro: any) => ({
    type: FIND_SUMARIO_FILTRADO,
    promise: Report.findSumarioPacienteFiltrado(id, filtro),
})
