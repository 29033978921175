import React from 'react'
import { css } from 'emotion'
import { FixedSelectField, FixedSelectFieldOption } from 'libs/components'

const options: FixedSelectFieldOption[] = [
    { id: 'GY', nome: 'Gy' },
    { id: 'DGY', nome: 'dGy' },
    { id: 'CGY', nome: 'cGy' },
    { id: 'MGY', nome: 'mGy' },
    { id: 'UGY', nome: 'μGy' },
    { id: 'NGY', nome: 'nGy' },
]

export const GrayField = props => (
    <FixedSelectField
        {...props}
        styles={{
            option: (defaulStyles, { isFocused }) => ({
                ...defaulStyles,
                backgroundColor: isFocused ? '#f2f9ff' : defaulStyles.backgroundColor,
            }),
            clearIndicator: () => ({ marginRight: 3, '&:hover': { cursor: 'pointer' } }),
            singleValue: defaultStyles => ({ ...defaultStyles, color: '#595959' }),
        }}
        components={{ ClearIndicator: GrayFieldClearIndicator }}
        options={options}
    />
)

export const GrayFieldClearIndicator = props => (
    <svg
        {...props.innerProps}
        ref={props.innerRef}
        className={css(props.getStyles('clearIndicator', props))}
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
    >
        <path
            fill='#999'
            fillRule='nonzero'
            d='M13.044 11.963l4.976-4.968a.744.744 0 0 0-.04-1.041.747.747 0 0 0-1.045.018l-4.976 4.967-4.976-4.967a.744.744 0 1 0-.995 1.053l4.976 4.967-4.976 4.968a.744.744 0 0 0 .324 1.267.747.747 0 0 0 .73-.214l4.977-4.967 4.976 4.967a.747.747 0 0 0 1.036-.018.744.744 0 0 0 .019-1.035l-5.006-4.997z'
        />
    </svg>
)
