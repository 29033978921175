import React from 'react'
import { Form, Columns, Column, TabField, TabPane, MaskedField, Button } from 'libs/components'

export interface FormBuscaProps {
    tipoFiltro: string
    submitting: boolean
    buttonDisabled: boolean
    handleSubmit: any
}

export const FormBusca = ({ handleSubmit, tipoFiltro, submitting, buttonDisabled }: FormBuscaProps) => (
    <Form handleSubmit={handleSubmit}>
        <Columns>
            <Column>
                <TabField activeKey={tipoFiltro} name='tipoFiltro'>
                    <TabPane key='CPF' title='CPF' disabled={submitting}>
                        <MaskedField
                            name='cpf'
                            disabled={submitting}
                            mask='999.999.999-99'
                            placeholder={'___.___.___-__'}
                        />
                    </TabPane>
                    <TabPane key='CNS' title='CNS' disabled={submitting}>
                        <MaskedField
                            name='cns'
                            disabled={submitting}
                            mask='999.9999.9999.9999'
                            placeholder={'___.____.____.____'}
                        />
                    </TabPane>
                </TabField>
            </Column>
            <Column pulledBottom>
                <Button
                    size='normal'
                    type='primary'
                    className='btn-pesquisar'
                    disabled={buttonDisabled}
                    loading={submitting}
                    title={buttonDisabled && !submitting ? 'Informe um CPF ou CNS para iniciar a busca' : ''}
                    onClick={handleSubmit}
                >
                    Pesquisar
                </Button>
            </Column>
        </Columns>
    </Form>
)
