import * as React from 'react'
import * as C from 'libs/components'
import field from 'libs/redux-form/field'
import { WrappedFieldProps } from 'redux-form'
import { FormLabelProps } from '../FormLabel'

interface ImageUploaderFieldProps extends C.ImageUploaderProps, Partial<WrappedFieldProps>, FormLabelProps {}

class ImageUploaderFieldComponent extends React.Component<ImageUploaderFieldProps, any> {
    render() {
        return (
            <C.ImageUploader
                {...this.props.input}
                text={this.props.text}
                multiple={this.props.multiple}
                alertError={this.props.alertError}
            />
        )
    }
}

export const ImageUploaderField = field()(ImageUploaderFieldComponent)
