import { VinculoProfissionalRow } from 'backend'
import { StyledRadioField } from 'libs/components'
import React from 'react'
import { VinculoProfissionalCard } from './VinculoProfissionalCard'

interface VinculoProfissionalRadioFieldProps {
    name: string
    value: number
    vinculoProfissional: VinculoProfissionalRow
}

export const VinculoProfissionalRadioField: React.FC<VinculoProfissionalRadioFieldProps> = props => {
    return (
        <StyledRadioField
            {...props}
            name={props.name}
            value={props.value?.toString()}
            skin='square-button'
            className='medico-radio-button'
        >
            <VinculoProfissionalCard vinculoProfissional={props.vinculoProfissional} />
        </StyledRadioField>
    )
}
