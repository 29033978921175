import { connect } from 'react-redux'
import { DistribuicaoProcedimentoFiltro } from 'backend'
import { MultiplosProcedimentosFiltro } from 'model'
import { MultiplosPacientesView, Props } from './MultiplosPacientesView'
import { State } from 'redux/modules'
import { withRouter } from 'react-router-dom'
import * as filtroReport from 'redux/modules/report/filtro'

const baseFilter: MultiplosProcedimentosFiltro = {}

const mapStateToProps = (state: State, ownProps: Props) => ({
    filtro:
        Object.keys(state.report.filtro.filtro).length === 0 && state.report.filtro.filtro.constructor === Object
            ? state.report.filtro.filtroBkp
            : state.report.filtro.filtro,
})

const mapDispatchToProps = (dispatch, ownProps: Props) => ({
    changeFilter: (filtro: MultiplosProcedimentosFiltro) => {
        dispatch(
            filtroReport.change({
                ...baseFilter,
                ...filtro,
            } as DistribuicaoProcedimentoFiltro)
        )
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiplosPacientesView))
