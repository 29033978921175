import * as React from 'react'
import * as C from 'libs/components'

const zerosAEsquerdaRegex = /\b0+/g
const notNumbersRegex = /[^\d]/g

export const format = value => {
    if (value) {
        let onlyNums
        if (typeof value === 'number') {
            // Formula mágica para tirar virgula, ou adicionar 2 zeros ao final
            onlyNums = value * 100 + ''
        }
        if (typeof value === 'string') {
            onlyNums = value.replace(zerosAEsquerdaRegex, '') && value.replace(notNumbersRegex, '')
        }
        if (onlyNums.length < 3) {
            return onlyNums
        }
        onlyNums = onlyNums.substring(0, 8)
        const tam = onlyNums.length
        return onlyNums.slice(0, tam - 2) + ',' + onlyNums.slice(tam - 2, tam)
    }

    return ''
}

export const parse = value => value.replace(',', '.')

export interface PriceFieldProps {
    name: string
}

export class PriceField extends React.Component<PriceFieldProps> {
    render() {
        return <C.TextField {...this.props} parse={parse} format={format} />
    }
}
