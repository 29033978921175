import { isImmutable } from 'immutable'
import {
    Button,
    ButtonBar,
    ChecklistItemField,
    CheckPanelField,
    ClearableRadioField,
    ClearRadioFieldButton,
    Column,
    Columns,
    Conteudo,
    ControlField,
    DatePickerField,
    Form,
    FormField,
    InputPanel,
    Linha,
    PeriodoField,
    RadioField,
    SectionTitle,
    SimNaoRadioField,
    StyledRadioField,
} from 'libs/components'
import { InformationIconPopover } from 'libs/components/presentational/form/InformationIconPopover'
import { InicioTerminoPanel } from 'libs/components/presentational/form/InicioTerminoPanel'
import form from 'libs/redux-form/form'
import * as DateUtil from 'libs/util/DateUtil'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Scroll from 'react-scroll'
import { FormSection, formValueSelector } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { clearEdicaoProcedimentoData, EdicaoState } from 'redux/modules/edicaoProcedimento'
import { clear as clearNovoProcedimento } from 'redux/modules/novoProcedimento'
import { actionCreators as pacienteActionCreators } from 'redux/modules/paciente'
import { usePesquisaSatisfacaoPopup } from 'view/componentes/pesquisaSatisfacao/usePesquisaSatisfacaoPopup'
import { AbordagemCirurgicaRevisaoQuadrilField, ModalSave } from 'view/home/novoProcedimento/ficha/componentes'
import FichaRevisaoQuadrilValidator from 'view/home/novoProcedimento/ficha/quadril/FichaRevisaoQuadrilValidator'
import { FichaFormCommonProps, FichaFormWrapProps } from 'view/home/novoProcedimento/procedimentos/ProcedimentosView'
import {
    AntibioticoField,
    CardPaciente,
    ComponenteComplementar,
    ComponentePrincipal,
    ListComponenteFichaField,
    ParTribologicoField,
    TipoEnxertiaField,
} from '../componentes'
import FichaPromptLeave from '../componentes/FichaPromptLeave'
import { handleErrorResolver } from '../DadoClinicoErrorUtil'

const formName = 'revisaoQuadril'

const FichaRevisaoQuadrilView = props => {
    const dispatch = useDispatch()
    useEffect(
        () => () => {
            dispatch(clearNovoProcedimento())
        },
        [dispatch]
    )
    const { showPesquisa } = usePesquisaSatisfacaoPopup()
    const edicaoRevisaoQuadrilData = useSelector<State, EdicaoState>(state => state.edicao)

    const showModalLeave = React.useRef<boolean>(true)

    const handleSubmitFail = error => {
        if (typeof error === 'string' || error?._error?.status === 404) {
            showModalLeave.current = false
            props.goHome()
        }
    }

    if (props.editMode && isEmpty(edicaoRevisaoQuadrilData)) {
        return null
    }
    return (
        <>
            <FichaPromptLeave formName={formName} showModalLeave={showModalLeave} />
            <FichaForm
                onSubmit={props.onSave}
                onSubmitSuccess={() => {
                    props.goHome()
                    showPesquisa()
                }}
                onSubmitFail={handleSubmitFail}
                errorResolver={handleErrorResolver}
                onCancel={props.goHome}
                lado={props.lado}
                paciente={props.paciente}
                procedimento={props.procedimento}
                initialValues={edicaoRevisaoQuadrilData}
                editMode={props.editMode}
            />
        </>
    )
}
export default FichaRevisaoQuadrilView

const MotivoRevisaoFormSection = props => {
    return (
        <FormSection name='motivoRevisao'>
            <Conteudo>
                <Columns>
                    <Column>
                        <ControlField label={'Motivos da revisão'} name={'motivoRevisao'} required />
                    </Column>
                </Columns>
                <Columns>
                    <Column size='6' verticalFlow>
                        <ChecklistItemField name='motivoRevisao' optionValue='COTILOIDITE' label='Cotiloidite' />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='DESGASTE_POLIETILENO'
                            label='Desgaste de polietileno'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='DOR_COMO_CAUSA_ISOLADA'
                            label='Dor como causa isolada da revisão'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='FRATURA_PERIPROTETICA_QUADRIL'
                            label='Fratura periprotética'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='GRANDE_DEFEITO_OSSEO_ACETABULAR'
                            label='Grande defeito ósseo acetabular'
                        />
                        <div className='is-horizontal-flow align-center'>
                            <ChecklistItemField
                                name='motivoRevisao'
                                optionValue='GRANDE_DEFEITO_OSSEO_FEMORAL'
                                label='Grande defeito ósseo femoral'
                            />
                            <InformationIconPopover information='distal ao trocanter menor' spaced />
                        </div>
                        <ChecklistItemField name='motivoRevisao' optionValue='INFECCAO_QUADRIL' label='Infecção' />
                        <InputPanel fallback>
                            <FormField>
                                <RadioField
                                    name='tipoInfeccao'
                                    label='Precoce'
                                    value='PRECOCE'
                                    disabled={!props.disabledInfeccao}
                                />
                                <RadioField
                                    name='tipoInfeccao'
                                    label='Crônica'
                                    value='CRONICA'
                                    disabled={!props.disabledInfeccao}
                                />
                            </FormField>
                        </InputPanel>
                    </Column>
                    <Column verticalFlow>
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='OSTEOLISE_ACETABULO'
                            label='Osteólise do acetábulo'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='OSTEOLISE_FEMUR'
                            label='Osteólise do fêmur'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='OSSIFICACAO_HETEROTOPICA'
                            label='Ossificação heterotópica'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='PROTUSAO_ACETABULAR'
                            label='Protrusão acetabular'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='QUEBRA_IMPLANTE_QUADRIL'
                            label='Quebra do implante'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='SOLTURA_COMPONENTE_ACETABULAR'
                            label='Soltura do componente acetabular'
                        />
                        <ChecklistItemField
                            name='motivoRevisao'
                            optionValue='SOLTURA_COMPONENTE_FEMORAL_QUADRIL'
                            label='Soltura do componente femoral'
                        />
                        <ChecklistItemField name='motivoRevisao' optionValue='OUTRO_REVISAO_QUADRIL' label='Outro' />
                    </Column>
                </Columns>
            </Conteudo>
        </FormSection>
    )
}

const ProcedimentoCirurgicoAtualFormSection = props => {
    return (
        <FormSection name='procedimentoCirurgicoAtualRevisao'>
            <Conteudo>
                <Columns>
                    <Column size='4'>
                        <DatePickerField
                            required
                            label='Data da cirurgia'
                            placeholder='dd/mm/aaaa'
                            name='dataCirurgia'
                        />
                    </Column>
                </Columns>

                <InicioTerminoPanel duracao={props.duracao} />

                <AbordagemCirurgicaRevisaoQuadrilField required label='Abordagem cirúrgica' name='abordagemCirurgica' />
                <Linha size='medium' />
                <SimNaoRadioField required label='Uso de dreno' name='usoDreno' />
                <SimNaoRadioField required label='Osteotomia femoral' name='osteotomiaFemoral' />
                <SimNaoRadioField required label='Anatomopatológico' name='anatomopatologico' />
                <SimNaoRadioField required label='Transfusão sanguínea' name='transfusaoSanguinea' />
                <InputPanel fallback size='medium'>
                    <RadioField
                        name='tipoTransfusaoSanguinea'
                        label='Autóloga'
                        value='AUTOLOGA'
                        disabled={props.disabledTipoTransfusaoSanguinea}
                    />
                    <RadioField
                        name='tipoTransfusaoSanguinea'
                        label='Homóloga'
                        value='HOMOLOGA'
                        disabled={props.disabledTipoTransfusaoSanguinea}
                    />
                </InputPanel>
                <SimNaoRadioField label='Cultura durante o ato cirúrgico' required name='culturaDuranteAto' />
                <FormField>
                    <StyledRadioField
                        skin='square-button'
                        name='tipoCulturaDuranteAto'
                        value='UM'
                        disabled={props.disabledCulturaDuranteAtoCirur}
                    >
                        1
                    </StyledRadioField>
                    <StyledRadioField
                        skin='square-button'
                        name='tipoCulturaDuranteAto'
                        value='DOIS'
                        disabled={props.disabledCulturaDuranteAtoCirur}
                    >
                        2
                    </StyledRadioField>
                    <StyledRadioField
                        skin='square-button'
                        name='tipoCulturaDuranteAto'
                        value='TRES'
                        disabled={props.disabledCulturaDuranteAtoCirur}
                    >
                        3
                    </StyledRadioField>
                    <StyledRadioField
                        skin='square-button'
                        name='tipoCulturaDuranteAto'
                        value='MAIS_DE_TRES'
                        disabled={props.disabledCulturaDuranteAtoCirur}
                    >
                        + de 3
                    </StyledRadioField>
                </FormField>
                <Linha size='medium' />
                <SimNaoRadioField label='Foi utilizado antibiótico' name='possuiAntibiotico' required />
                <InputPanel fallback size='medium'>
                    <Columns>
                        <Column size='6'>
                            <AntibioticoField
                                label='Antibiótico'
                                name='antibiotico'
                                isDisabled={props.disabledAntibiotico}
                                required={!props.disabledAntibiotico}
                            />
                        </Column>
                        <Column size='4'>
                            <PeriodoField
                                label='Período'
                                name='periodoAntibiotico'
                                disabled={props.disabledAntibiotico}
                                required={!props.disabledAntibiotico}
                            />
                        </Column>
                    </Columns>
                </InputPanel>
            </Conteudo>
        </FormSection>
    )
}

const TipoRevisaoFormSection = props => {
    return (
        <FormSection name='tipoRevisaoForm'>
            <Conteudo>
                <ControlField
                    label='Tipo de revisão'
                    name='tipoRevisao'
                    verticalFlow
                    required={!props.disabledComponenteRetirado}
                    disabled={props.disabledComponenteRetirado}
                >
                    <ClearableRadioField
                        name='tipoRevisao'
                        label='Troca de todos os componentes'
                        value='TROCA_TODOS'
                        disabled={props.disabledComponenteRetirado}
                    />
                    <ClearableRadioField
                        name='tipoRevisao'
                        label='Troca de todos os componentes femorais'
                        value='TROCA_FEMORAIS'
                        disabled={props.disabledComponenteRetirado}
                    />
                </ControlField>
                <ControlField
                    name='componentesRetiradosQuadril'
                    label='Retirada'
                    verticalFlow
                    required={!props.disabledTodosComponentesRetirados}
                    disabled={props.disabledTodosComponentesRetirados}
                >
                    <ChecklistItemField
                        name='componentesRetiradosQuadril'
                        optionValue='HASTE_FEMORAL'
                        label='Haste femoral'
                        disabled={props.disabledTodosComponentesRetirados}
                    />
                    <ChecklistItemField
                        name='componentesRetiradosQuadril'
                        optionValue='CABECA_FEMORAL'
                        label='Cabeça femoral'
                        disabled={props.disabledTodosComponentesRetirados}
                    />
                    <ChecklistItemField
                        name='componentesRetiradosQuadril'
                        optionValue='INSERTO_ACETABULAR'
                        label='Inserto acetabular'
                        disabled={props.disabledTodosComponentesRetirados}
                    />
                    <ChecklistItemField
                        name='componentesRetiradosQuadril'
                        optionValue='COMPONENTE_ACETABULAR'
                        label='Componente acetabular'
                        disabled={props.disabledTodosComponentesRetirados}
                    />
                </ControlField>
            </Conteudo>
        </FormSection>
    )
}

const TecnicaOperatoriaFormSection = props => {
    return (
        <FormSection name='tecnicaOperatoria'>
            <Conteudo>
                <ControlField name='femoral' label={'Técnica utilizada'} required />
                <CheckPanelField label='Femoral' name='femoral'>
                    <Conteudo>
                        <SimNaoRadioField required label='Tela de suporte' name='femoralTelaSuporte' />
                        <SimNaoRadioField required label='Centralizador' name='femoralCentralizador' />
                    </Conteudo>
                    <Linha />
                    <Conteudo>
                        <SimNaoRadioField required label='Cimentado' name='femoralCimentado' />
                        <InputPanel fallback horizontalFlow size='medium'>
                            <SimNaoRadioField
                                label='Restritor de cimento'
                                disabled={props.disabledRestritorCimentoFemoral}
                                required={!props.disabledRestritorCimentoFemoral}
                                name='restritorCimento'
                                limpar={false}
                            />
                        </InputPanel>
                    </Conteudo>
                    <Linha />
                    <Conteudo horizontalFlow>
                        <ControlField label='Tipo de prótese' name='tipoProtese' required>
                            <RadioField name='tipoProtese' label='Modular' value='MODULAR' />
                            <RadioField name='tipoProtese' label='Não convencional' value='NAO_CONVENCIONAL' />
                            <ClearRadioFieldButton name='tipoProtese' />
                        </ControlField>
                    </Conteudo>
                </CheckPanelField>

                <CheckPanelField label='Acetabular' name='acetabular'>
                    <Conteudo>
                        <SimNaoRadioField required label='Tela de suporte' name='acetabularTelaSuporte' />
                        <SimNaoRadioField required label='Anel de reforço' name='acetabularAnelReforco' />
                        <SimNaoRadioField required label='Metal trabeculado' name='acetabularMetalTrabeculado' />
                        <Column size='9'>
                            <ParTribologicoField label='Par tribológico' name='parTribologico' required />
                        </Column>
                    </Conteudo>
                    <Linha />
                    <Conteudo>
                        <SimNaoRadioField required label='Cimentado' name='acetabularCimentado' />
                    </Conteudo>
                </CheckPanelField>
            </Conteudo>
        </FormSection>
    )
}

const CimentoOsseoFormSection = props => {
    return (
        <FormSection name='cimentoOsseo'>
            <Conteudo>
                <ControlField
                    label='Tipo'
                    name='tipoCimentoOsseo'
                    disabled={props.disabledPossuiCimentoOsseo}
                    required={!props.disabledPossuiCimentoOsseo}
                >
                    <RadioField
                        name='tipoCimentoOsseo'
                        label='Convencional'
                        value='CONVENCIONAL'
                        disabled={props.disabledPossuiCimentoOsseo}
                    />
                    <RadioField
                        name='tipoCimentoOsseo'
                        label='Com antibiótico'
                        value='COM_ANTIBIOTICO'
                        disabled={props.disabledPossuiCimentoOsseo}
                    />
                    <ClearRadioFieldButton name='tipoCimentoOsseo' />
                </ControlField>
                <ControlField
                    name='tecnicaCimentacao'
                    disabled={props.disabledPossuiCimentoOsseo}
                    required={!props.disabledPossuiCimentoOsseo}
                    label='Técnica de cimentação'
                >
                    <RadioField
                        name='tecnicaCimentacao'
                        label='Manual'
                        value='MANUAL'
                        disabled={props.disabledPossuiCimentoOsseo}
                        required={!props.disabledPossuiCimentoOsseo}
                    />
                    <RadioField
                        name='tecnicaCimentacao'
                        label='Retrógrada'
                        value='RETROGRADA'
                        disabled={props.disabledPossuiCimentoOsseo}
                        required={!props.disabledPossuiCimentoOsseo}
                    />
                    <ClearRadioFieldButton name='tecnicaCimentacao' />
                </ControlField>
                <SimNaoRadioField
                    label='Lavagem pulsátil'
                    name='lavagemPulsatil'
                    disabled={props.disabledPossuiCimentoOsseo}
                    required={!props.disabledPossuiCimentoOsseo}
                />
            </Conteudo>
        </FormSection>
    )
}

const EnxertiaFormSection = props => {
    return (
        <FormSection name='enxertia'>
            <Conteudo>
                <CheckPanelField label='Femoral' name='enxertiaFemoral' disabled={props.disabledPossuiEnxertiaFemoral}>
                    <Conteudo>
                        <TipoEnxertiaField label='Qual?' name='tipoEnxertiaFemoral' />
                    </Conteudo>
                </CheckPanelField>
                <CheckPanelField
                    label='Acetabular'
                    name='enxertiaAcetabular'
                    disabled={props.disabledPossuiEnxertiaAcetabular}
                >
                    <Conteudo>
                        <TipoEnxertiaField label='Qual?' name='tipoEnxertiaAcetabular' />
                    </Conteudo>
                </CheckPanelField>
            </Conteudo>
        </FormSection>
    )
}

const ComponentesUtilizadosFormSection = () => {
    return (
        <FormSection name='componentesUtilizadosForm'>
            <Conteudo>
                <ListComponenteFichaField
                    name='componentesPrincipais'
                    combo={
                        <ComponentePrincipal
                            label='Principais'
                            name='componentesPrincipaisLabel'
                            tipoProcedimento='REVISAO_QUADRIL'
                            required
                        />
                    }
                />
                <hr />
                <ListComponenteFichaField
                    name='componentesComplementares'
                    combo={
                        <ComponenteComplementar
                            name='componentesComplementaresLabel'
                            tipoProcedimento='REVISAO_QUADRIL'
                            label='Complementares'
                        />
                    }
                />
            </Conteudo>
        </FormSection>
    )
}

interface FichaFormCmpProps extends FichaFormCommonProps {
    disabledInfeccao
    disabledTipoTransfusaoSanguinea: boolean
    disabledCulturaDuranteAtoCirur: boolean
    disabledTodosComponentesRetirados: boolean
    disabledComponenteRetirado: boolean
    disabledRestritorCimentoFemoral: boolean

    disabledPossuiCimentoOsseo: boolean

    disabledPossuiEnxertiaFemoral: boolean
    disabledPossuiEnxertiaAcetabular: boolean
    disabledAntibiotico: boolean
    duracao: string
}

const FichaFormCmp = (props: FichaFormCmpProps) => {
    const dispatch = useDispatch()

    // mimics componentWillUnmount
    useEffect(
        () => () => {
            dispatch(clearEdicaoProcedimentoData())
            dispatch(pacienteActionCreators.clear(''))
        },
        [dispatch]
    )
    return (
        <div>
            <Form>
                <CardPaciente paciente={props.paciente} lado={props.lado} procedimento={props.procedimento} />
                <Scroll.Element name='motivoRevisao'>
                    <SectionTitle title='MOTIVO DA REVISÃO' />
                    <MotivoRevisaoFormSection disabledInfeccao={props.disabledInfeccao} />
                </Scroll.Element>
                <Scroll.Element name='procedimentoCirurgicoAtualRevisao'>
                    <SectionTitle title='PROCEDIMENTO CIRÚRGICO ATUAL' />
                    <ProcedimentoCirurgicoAtualFormSection
                        duracao={props.duracao}
                        disabledTipoTransfusaoSanguinea={props.disabledTipoTransfusaoSanguinea}
                        disabledCulturaDuranteAtoCirur={props.disabledCulturaDuranteAtoCirur}
                        disabledAntibiotico={props.disabledAntibiotico}
                    />
                </Scroll.Element>
                <Scroll.Element name='tipoRevisaoForm'>
                    <SectionTitle title='TIPO DE REVISÃO' />
                    <TipoRevisaoFormSection
                        disabledComponenteRetirado={props.disabledComponenteRetirado}
                        disabledTodosComponentesRetirados={props.disabledTodosComponentesRetirados}
                    />
                </Scroll.Element>
                <Scroll.Element name='tecnicaOperatoria'>
                    <SectionTitle title='TÉCNICA OPERATÓRIA' />
                    <TecnicaOperatoriaFormSection
                        disabledRestritorCimentoFemoral={props.disabledRestritorCimentoFemoral}
                    />
                </Scroll.Element>
                <Scroll.Element name='cimentoOsseo'>
                    <SectionTitle title='CIMENTO ÓSSEO' />
                    <CimentoOsseoFormSection disabledPossuiCimentoOsseo={props.disabledPossuiCimentoOsseo} />
                </Scroll.Element>
                <Scroll.Element name='enxertia'>
                    <SectionTitle title='ENXERTIA' />
                    <EnxertiaFormSection
                        disabledPossuiEnxertiaAcetabular={props.disabledPossuiEnxertiaAcetabular}
                        disabledPossuiEnxertiaFemoral={props.disabledPossuiEnxertiaFemoral}
                    />
                </Scroll.Element>
                <Scroll.Element name='componentesUtilizados'>
                    <SectionTitle title='COMPONENTES UTILIZADOS' />
                    <ComponentesUtilizadosFormSection />
                </Scroll.Element>
            </Form>
            <Conteudo>
                <Linha size='medium' />
                <ButtonBar>
                    <Button pulledLeft size='medium' className='simple-button' onClick={props.onCancel}>
                        Cancelar
                    </Button>
                    <Button
                        pulledRight
                        type='success'
                        size='medium'
                        loading={props.submitting}
                        onClick={props.handleSubmit}
                        disabled={props.pristine}
                    >
                        Salvar registro
                    </Button>
                </ButtonBar>
            </Conteudo>
        </div>
    )
}

const selector = formValueSelector(formName)

const FichaForm = ({ editMode, ...rest }: FichaFormWrapProps) => {
    const Form = useMemo(
        () =>
            form<{}, FichaFormCmpProps>({
                form: formName,
                validate: FichaRevisaoQuadrilValidator as any,
                successTitle: editMode ? 'Edição realizada!' : 'Ficha registrada!',
                successModal: ModalSave,
                successContent: (
                    <span>
                        O registro foi salvo com sucesso. Se preferir, você pode imprimi-lo ou voltar à página inicial
                        de seu perfil.
                    </span>
                ),
                hasLeaveModal: false,
            })(
                connect(state => {
                    const motivoRevisao = selector(state, 'motivoRevisao.motivoRevisao')
                    let disabledInfeccao
                    if (isImmutable(motivoRevisao)) {
                        disabledInfeccao = motivoRevisao?.toJS().includes('INFECCAO_QUADRIL')
                    } else {
                        disabledInfeccao = motivoRevisao?.includes('INFECCAO_QUADRIL')
                    }

                    const disabledTipoTransfusaoSanguinea =
                        selector(state, 'procedimentoCirurgicoAtualRevisao.transfusaoSanguinea') !== 'SIM'

                    const disabledCulturaDuranteAtoCirur =
                        selector(state, 'procedimentoCirurgicoAtualRevisao.culturaDuranteAto') !== 'SIM'

                    const disabledAntibiotico =
                        selector(state, 'procedimentoCirurgicoAtualRevisao.possuiAntibiotico') !== 'SIM'

                    const disabledTodosComponentesRetirados = selector(state, 'tipoRevisaoForm.tipoRevisao')

                    const hasComponentes = selector(state, 'tipoRevisaoForm.componentesRetiradosQuadril')

                    let disabledComponenteRetirado
                    if (isImmutable(hasComponentes)) {
                        disabledComponenteRetirado = hasComponentes?.toJS().length > 0
                    } else {
                        disabledComponenteRetirado = hasComponentes?.length > 0
                    }

                    const disabledRestritorCimentoFemoral =
                        selector(state, 'tecnicaOperatoria.femoralCimentado') !== 'SIM'

                    const disabledPossuiCimentoOsseo =
                        selector(state, 'tecnicaOperatoria.acetabularCimentado') !== 'SIM' &&
                        selector(state, 'tecnicaOperatoria.femoralCimentado') !== 'SIM'

                    const disabledPossuiEnxertiaFemoral = !selector(state, 'tecnicaOperatoria.femoral')

                    const disabledPossuiEnxertiaAcetabular = !selector(state, 'tecnicaOperatoria.acetabular')

                    const inicio = selector(state, 'procedimentoCirurgicoAtualRevisao.horaInicio')
                    const termino = selector(state, 'procedimentoCirurgicoAtualRevisao.horaTermino')
                    const hasHoras = inicio && termino
                    const duracao = hasHoras && DateUtil.calculateDuracaoHoras(inicio, termino)

                    return {
                        disabledInfeccao,
                        disabledTipoTransfusaoSanguinea,
                        disabledCulturaDuranteAtoCirur,
                        disabledTodosComponentesRetirados,
                        disabledComponenteRetirado,
                        disabledRestritorCimentoFemoral,

                        disabledPossuiCimentoOsseo,

                        disabledPossuiEnxertiaFemoral,
                        disabledPossuiEnxertiaAcetabular,
                        disabledAntibiotico,
                        duracao,
                    }
                })(FichaFormCmp)
            ),
        [editMode]
    )
    return <Form {...rest} />
}
