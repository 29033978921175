import * as React from 'react'
import classnames from 'classnames'
import * as H from 'history'
import { Link } from 'react-router-dom'

export interface LinkButtonProps {
    to: H.LocationDescriptor
    replace?: boolean
    className?: string
    disabled?: boolean
}

export class LinkButton extends React.Component<LinkButtonProps> {
    handleClick = e => {
        if (this.props.disabled) {
            e.preventDefault()
        }
    }

    render() {
        const { className, ...rest } = this.props

        return (
            <Link {...rest} className={classnames('button', className)} onClick={this.handleClick}>
                {this.props.children}
            </Link>
        )
    }
}
