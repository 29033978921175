import * as React from 'react'
import { Card, Columns, LoadingStateContainer, NumberLabel } from 'libs/components'
import { DistClassificacao } from 'redux/modules/report/procedimento'
import * as enums from 'redux/enums'
import { InvertedLabel } from 'view/componentes/reports/components/InvertedLabel'
import { ClassificacaoCirurgia, StatusProcedimento } from 'backend'
import { ValuePercentLabel } from 'view/componentes/reports/components/ValuePercentLabel'

interface Props {
    title: string
    distClassificacao: DistClassificacao
    showPrimariaRevisao?: boolean
    showStatus?: boolean
}

export class DadosUsoCard extends React.Component<Props> {
    render() {
        const dist = this.props.distClassificacao
        const result = dist.result
        return (
            <Card title={this.props.title}>
                <LoadingStateContainer readyState={dist.readyState}>
                    {result && (
                        <Columns auto className={'justify-space-around'}>
                            <div className='flex-0'>
                                <InvertedLabel title='Procedimentos' className={this.props.showStatus?'column':''}>
                                    <NumberLabel value={result.total} placeholder='-' />
                                </InvertedLabel>
                            </div>
                            {this.props.showPrimariaRevisao && (
                                <LabelQuantidade id={'PRIMARIA'} total={result.classificacoes['PRIMARIA']?.total} />
                            )}
                            {this.props.showPrimariaRevisao && (
                                <LabelQuantidade id={'REVISAO'} total={result.classificacoes['REVISAO']?.total} />
                            )}
                            {this.props.showStatus && (
								<div className='flex-1'>
									<LabelStatus value={result.statusProcedimento['AGUARDANDO_DADOS_PRODUTO']?.total}
										total={result.total} id={'AGUARDANDO_DADOS_PRODUTO'} />
									<LabelStatus value={result.statusProcedimento['AGUARDANDO_DADOS_FINANCEIRO']?.total}
										total={result.total} id={'AGUARDANDO_DADOS_FINANCEIRO'} />
									<LabelStatus value={result.statusProcedimento['FINALIZADO']?.total}
										total={result.total} id={'FINALIZADO'} />
								</div>
                            )}
                        </Columns>
                    )}
                </LoadingStateContainer>
            </Card>
        )
    }
}

interface LabelQuantidadeProps {
    id: ClassificacaoCirurgia
    total: number
}

interface LabelStatusProps {
	id: StatusProcedimento
	value: number
    total: number
}

const LabelQuantidade = (props: LabelQuantidadeProps) => (
    <div key={props.id} className='flex-0'>
        <InvertedLabel title={enums.classificacaoCirurgiaPluralName[props.id]}>
            <NumberLabel value={props.total ?? 0} placeholder='-' />
        </InvertedLabel>
    </div>
)
const LabelStatus = (props: LabelStatusProps) => (
    <ValuePercentLabel
        value={props.value ?? 0}
        total={props.total}
        text={enums.statusProcedimentos[props.id].name}
    />
)