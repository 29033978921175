import { HistoricoProcedimentoDetailRow } from 'backend'
import classnames from 'classnames'
import { ControlProps, LoadingStateContainer, Placement, Popover } from 'libs/components'
import { IconButton } from 'libs/components/presentational/elements/IconButton'
import * as Util from 'libs/components/Util'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { State } from 'redux/modules'
import * as home from 'redux/modules/home'

interface HistoricoPopoverProps extends ControlProps {
    registro: number
    placement?: Placement
}

const HistoricoPopover: React.FunctionComponent<React.PropsWithChildren<HistoricoPopoverProps>> = props => {
    const detail = useSelector<State, PromiseRecord<HistoricoProcedimentoDetailRow[]>>(
        (state: State) => state.home.detailHistoricoPopover
    )

    const classes = classnames('label historico', props.className, Util.helpersClassnames(props))

    const [openedPopover, setOpenedPopover] = React.useState<number>(undefined)

    const elemRef = React.useRef()

    const dispatch = useDispatch()
    const openPopover = () => {
        dispatch(home.findHistoricoDetail(props.registro))
        setOpenedPopover(props.registro)
    }

    const closePopover = () => {
        setOpenedPopover(undefined)
    }
    return (
        <span>
            <div ref={elemRef} className={classes} onClick={openPopover}>
                {props.children}
            </div>
            <Popover
                className='historico-popover'
                onHide={closePopover}
                show={openedPopover === props.registro}
                placement={props.placement}
                rootClose={true}
                target={elemRef}
            >
                <LoadingStateContainer readyState={detail.readyState}>
                    <IconButton pulledRight onClick={closePopover} icon='excluir' />
                    <div className='historico-popover-wrapper'>
                        {detail.readyState === 'success' &&
                            detail.data.map((element, index) => (
                                <div key={index} className='content'>
                                    <div className='header'> {element.textoRegistro} </div>
                                    <span className='middle'> {element.usuarioCadastroNome} </span>
                                    <span className='specification'> {element.perfil} </span>
                                    <div className='foot'> {element.dataUltimaModificacao} </div>
                                </div>
                            ))}
                    </div>
                </LoadingStateContainer>
            </Popover>
        </span>
    )
}

HistoricoPopover.defaultProps = {
    placement: 'right',
}

export default HistoricoPopover
