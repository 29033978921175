import * as React from 'react'
import * as C from 'libs/components'

interface ClearableRadioFieldProps {
    name: string
    label: string
    value: string
    disabled?: boolean
}

export class ClearableRadioField extends React.Component<ClearableRadioFieldProps> {
    render() {
        return (
            <span>
                <C.RadioField {...this.props} />
                <C.ClearRadioFieldButton {...this.props} visibleValue={this.props.value} />
            </span>
        )
    }
}
