import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import { DadosPessoaisForm } from 'backend'
import immutableProxy from 'libs/immutableProxy'

const alterarDadosPessoais = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<DadosPessoaisForm>>(state)
        form = form.withMutations(mutable => {
            if (!mutable.values.contatoForm.email.get()) {
                mutable.values.contatoForm.confirmacaoEmail.delete()
            }
        })
        return form.asMap()
    }
    return state
})

export default alterarDadosPessoais
