import { DescricaoPerfilCaptalize } from 'api/Perfis'
import { PacienteDto, RegistroProcedimentoComponenteDto, VinculoProfissionalRow } from 'backend'
import { Column, Columns, Linha, MaskedLabel, ModalCard, TextLabel } from 'libs/components'
import { asClientFormat } from 'libs/util/DateUtil'
import React from 'react'

interface DadosPacientesProps {
    paciente: PacienteDto
}

const DadosPacientes: React.FC<DadosPacientesProps> = ({ paciente }) => {
    return (
        <>
            <Columns>
                <Column verticalFlow>
                    <label className='title-modal-inf-proced'>Paciente:</label>
                    <label className='modal-nome'>{paciente.nome}</label>
                </Column>
            </Columns>
            <Columns>
                <Column verticalFlow>
                    {paciente.cpf ? (
                        <MaskedLabel
                            mask='###.###.###-##'
                            title='CPF:'
                            value={paciente.cpf}
                            labelClassName='title-modal-inf-proced'
                        />
                    ) : (
                        <TextLabel title='CPF:' value={null} labelClassName='title-modal-inf-proced' />
                    )}
                </Column>
                <Column verticalFlow>
                    {paciente.cns ? (
                        <MaskedLabel
                            mask='###.####.####.####'
                            title='CNS:'
                            value={paciente.cns}
                            labelClassName='title-modal-inf-proced'
                        />
                    ) : (
                        <TextLabel title='CNS:' value={null} labelClassName='title-modal-inf-proced' />
                    )}
                </Column>
            </Columns>
            <Columns>
                <Column verticalFlow>
                    <label className='title-modal-inf-proced'>Data de nascimento:</label>
                    <label className='modal-subtitulos'>{asClientFormat(paciente.dataNascimento)}</label>
                </Column>
            </Columns>
        </>
    )
}

interface DadosProfissionalProps {
    profissional: VinculoProfissionalRow
    isResponsavel: boolean
}

export const DadosProfissional: React.FC<DadosProfissionalProps> = ({ profissional, isResponsavel }) => {
    return (
        <div className='dados-profissional-row'>
            <Columns>
                <Column verticalFlow>
                    <label className='title-modal-inf-proced'>
                        {isResponsavel ? 'Profissional responsável:' : 'Profissional:'}
                    </label>
                    <label className='modal-nome'>{profissional.nome}</label>
                </Column>
            </Columns>
            <Columns>
                <Column verticalFlow>
                    <label className='title-modal-inf-proced'>Registro em conselho:</label>
                    <label className='modal-subtitulos'>
                        {profissional.nomeConselhoClasse +
                            ' ' +
                            profissional.numeroRegistro +
                            ' ' +
                            profissional.ufRegistro}
                    </label>
                </Column>
                <Column verticalFlow>
                    <label className='title-modal-inf-proced'>Especialidade:</label>
                    <label className='modal-subtitulos'>{DescricaoPerfilCaptalize[profissional.perfil]}</label>
                </Column>
            </Columns>
            <Columns>
                <Column verticalFlow>
                    {profissional.cpf ? (
                        <MaskedLabel
                            mask='###.###.###-##'
                            title='CPF:'
                            value={profissional.cpf}
                            labelClassName='title-modal-inf-proced'
                        />
                    ) : (
                        <TextLabel title='CPF:' value={null} labelClassName='title-modal-inf-proced' />
                    )}
                </Column>
                <Column verticalFlow>
                    {profissional.cns ? (
                        <MaskedLabel
                            mask='###.####.####.####'
                            title='CNS:'
                            value={profissional.cns}
                            labelClassName='title-modal-inf-proced'
                        />
                    ) : (
                        <TextLabel title='CNS:' value={null} labelClassName='title-modal-inf-proced' />
                    )}
                </Column>
            </Columns>
        </div>
    )
}

interface ModalInformacoesProcedimentoProps {
    procedimento: RegistroProcedimentoComponenteDto
    open: boolean
    close: () => void
}

const ModalInformacoesProcedimento: React.FC<ModalInformacoesProcedimentoProps> = props => {
    const { procedimento, open, close } = props

    let procedimentoName = 'Não informado'

    if (procedimento.tipoProcedimento === 'PRIMARIA_QUADRIL') {
        procedimentoName = 'PRIMÁRIA DE QUADRIL ' + procedimento.lado
    } else if (procedimento.tipoProcedimento === 'REVISAO_QUADRIL') {
        procedimentoName = 'REVISÃO DE QUADRIL ' + procedimento.lado
    } else if (procedimento.tipoProcedimento === 'PRIMARIA_JOELHO') {
        procedimentoName = 'PRIMÁRIA DE JOELHO ' + procedimento.lado
    } else if (procedimento.tipoProcedimento === 'REVISAO_JOELHO') {
        procedimentoName = 'REVISÃO DE JOELHO ' + procedimento.lado
    } else if (procedimento.tipoProcedimento === 'STENT_CORONARIA') {
        procedimentoName = 'STENT DE CORONÁRIA'
    }

    return (
        <ModalCard title='Dados gerais do procedimento' active={open} onClose={close} hasCloseButton>
            <label className='title-modal-inf-proced'>Equipe médica</label>
            <Linha className='equipe-medica-linha' />
            <DadosProfissional profissional={procedimento.profissionalResponsavel} isResponsavel={true} />
            {procedimento.equipeMedica.map((value: VinculoProfissionalRow) => {
                return <DadosProfissional key={value.id} profissional={value} isResponsavel={false} />
            })}
            <Linha />
            <DadosPacientes paciente={procedimento.paciente} />
            <Linha />
            <Columns>
                <Column verticalFlow>
                    <label className='title-modal-inf-proced'>Procedimento realizado:</label>
                    <label className='modal-procedimento'>{procedimentoName}</label>
                </Column>
            </Columns>
        </ModalCard>
    )
}

export default ModalInformacoesProcedimento
