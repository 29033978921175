import * as React from 'react'
import Horizontal from 'libs/components/presentational/layout/Horizontal'
import { StyledCheckboxLabel } from 'libs/components'
import { Button } from 'libs/components'
import { WrappedFieldProps } from 'redux-form'
import Text from 'libs/components/presentational/typography/Text'
import { UserDTO } from 'backend'
import { Map, List } from 'immutable'
import field, { FieldProps } from 'libs/redux-form/field'

interface InscritosFieldProps extends Partial<WrappedFieldProps> {
    user: UserDTO
    disabled?: boolean
}

class InscritosFieldCmp extends React.PureComponent<InscritosFieldProps> {
    render() {
        return (
            <div>
                <Horizontal>
                    <Text color='black-bis' weight='bold'>
                        Lista de participantes
                    </Text>
                    <Button
                        type='success'
                        onClick={this.inscreverSe}
                        disabled={this.props.disabled || this.isInscrito()}
                    >
                        Inscrever-se
                    </Button>
                </Horizontal>
                <Horizontal>
                    {this.props.input.value &&
                        this.props.input.value.map(inscrito => (
                            <StyledCheckboxLabel
                                key={inscrito.get('id')}
                                label={inscrito.get('nome')}
                                excludable={!this.props.disabled}
                                onClick={() => this.removerInscrito(inscrito)}
                            />
                        ))}
                </Horizontal>
            </div>
        )
    }

    private isInscrito = () => {
        if (!this.props.input.value) {
            return false
        }
        const inscrito = this.props.input.value.filter(inscrito => {
            return inscrito.get('id') === this.props.user.id
        })
        return inscrito.size > 0
    }

    private inscreverSe = () => {
        let values = this.props.input.value

        if (!values) {
            values = List()
        }

        values = values.push(Map({ id: this.props.user.id, nome: this.props.user.nome }))

        return this.props.input.onChange(values)
    }

    private removerInscrito(value) {
        let values = List()

        if (this.props.input.value) {
            values = this.props.input.value
        }

        values = values.filter(inscrito => {
            return inscrito.get('id') !== value.get('id')
        })
        return this.props.input.onChange(values as any)
    }
}

const InscritosField: React.ComponentClass<FieldProps & InscritosFieldProps> = field()(InscritosFieldCmp)

export default InscritosField
