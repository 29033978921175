import * as React from 'react'
import { ControlField } from './ControlField'
import { RadioField } from 'libs/components'

interface SexoFieldProps {
    label: string
    name: string
    limpar?: boolean
    required?: boolean
    disabled?: boolean
}

export class SexoField extends React.Component<SexoFieldProps> {
    render() {
        return (
            <ControlField label={this.props.label} name={this.props.name} required={this.props.required}>
                <RadioField {...this.props} label='Feminino' value='FEMININO' />
                <RadioField {...this.props} label='Masculino' value='MASCULINO' />
            </ControlField>
        )
    }
}
