import * as React from 'react'
import { FixedSelectField, FixedSelectFieldOption } from './FixedSelectField'
import { FormFieldProps } from '../FormField'

const periodos: FixedSelectFieldOption[] = [
    { id: 'VINTE_QUATRO_H', nome: '24h' },
    { id: 'QUARENTA_OITO_H', nome: '48h' },
    { id: 'SETENTA_DUAS_H', nome: '72h' },
    { id: 'MAIS_DE_72_H', nome: 'mais de 72h' },
]

interface PeriodoProps extends FormFieldProps {
    name: string
}

export class PeriodoField extends React.Component<PeriodoProps> {
    render() {
        return <FixedSelectField {...this.props} options={periodos} />
    }
}
