/* tslint:disable */
import React from 'react'

const SvgDadospessoais = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 21 21' {...props}>
        <path d='M17 17H4c-.828 0-1.5-.676-1.5-1.51V5.51C2.5 4.676 3.172 4 4 4h13c.828 0 1.5.676 1.5 1.51v9.98c0 .834-.672 1.51-1.5 1.51zM4 5.007c-.276 0-.5.225-.5.503v9.98c0 .278.224.503.5.503h13c.276 0 .5-.225.5-.503V5.51a.502.502 0 00-.5-.503H4zm6.93 9.063H5.2a.502.502 0 01-.5-.504V12.56a.504.504 0 01.22-.423l1.66-1.087a2.192 2.192 0 01-.69-1.601v-.594a2.201 2.201 0 01.775-1.67A2.17 2.17 0 018.43 6.7a2.252 2.252 0 011.81 2.245v.534a2.191 2.191 0 01-.74 1.57l1.71 1.149c.14.095.222.253.22.423v1.007a.501.501 0 01-.5.443zM5.7 13.063h4.73v-.182l-1.82-1.228a.676.676 0 01-.3-.604.665.665 0 01.34-.564 1.18 1.18 0 00.6-1.007v-.534a1.247 1.247 0 00-1-1.258 1.174 1.174 0 00-.946.27 1.192 1.192 0 00-.414.898v.624c.01.419.238.801.6 1.007.2.117.326.33.33.564a.686.686 0 01-.32.604l-1.8 1.228v.182zm9.87-4.723H12.5a.502.502 0 01-.5-.503c0-.279.224-.504.5-.504h3.09c.276 0 .5.225.5.504a.502.502 0 01-.5.503h-.02zm0 2.709H12.5a.502.502 0 01-.5-.504c0-.278.224-.503.5-.503h3.09c.276 0 .5.225.5.503a.502.502 0 01-.5.504h-.02zm0 2.618H12.5a.502.502 0 01-.5-.504c0-.278.224-.503.5-.503h3.09c.276 0 .5.225.5.503a.502.502 0 01-.5.504h-.02z' />
    </svg>
)

export default SvgDadospessoais
