import React from 'react'
import * as C from 'libs/components'

export class DislipidemiaCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Dislipidemia'
                name='dislipidemia'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='dislipidemia' />}
            >
                <C.Conteudo>
                    <C.ControlField verticalFlow label='Terapia medicamentosa' name='dislipidemiaTerapia'>
                        <C.Columns>
                            <C.Column size='3'>
                                <C.InputPanel fallback>
                                    <C.ChecklistItemField
                                        name='dislipidemiaTerapia'
                                        optionValue='NENHUMA'
                                        label='Nenhuma'
                                    />
                                </C.InputPanel>
                                <C.ChecklistItemField
                                    name='dislipidemiaTerapia'
                                    optionValue='ESTATINA'
                                    label='Estatina'
                                    disabled={this.props.disabledNenhuma}
                                />
                                <C.ChecklistItemField
                                    name='dislipidemiaTerapia'
                                    optionValue='FIBRATO'
                                    label='Fibrato'
                                    disabled={this.props.disabledNenhuma}
                                />
                                <C.ChecklistItemField
                                    name='dislipidemiaTerapia'
                                    optionValue='OUTRA'
                                    label='Outra'
                                    disabled={this.props.disabledNenhuma}
                                />
                            </C.Column>
                        </C.Columns>
                    </C.ControlField>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
