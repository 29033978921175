import React, { useState, useEffect } from 'react'
import { Button } from './Button'
import { Icons } from './icons'
import { Icon } from './Icon'

interface FeedbackButtonProps {
    normalText: string
    normalClassName: string
    feedbackText: string
    feedbackClassName: string
    onClick: () => void
    icon?: Icons
    feedbackTime?: number
    disabled?: boolean
    loading?: boolean
    failure?: boolean
    testid?: string
}

export const FeedbackButton = (props: FeedbackButtonProps) => {
    const {
        normalText,
        normalClassName,
        feedbackText,
        feedbackClassName,
        onClick,
        icon,
        feedbackTime = 3000,
        disabled = false,
        loading = false,
        failure = false,
        testid,
    } = props

    const [inFeedback, setInFeedback] = useState<boolean>(false)
    const wrapperOnClick = () => {
        onClick()
        setInFeedback(true)
    }
    useEffect(() => {
        if (inFeedback && !loading) {
            const timer = setTimeout(() => setInFeedback(false), feedbackTime)
            return () => clearTimeout(timer)
        }
    }, [feedbackTime, inFeedback, loading])

    return (
        <>
            {!inFeedback || failure ? (
                <Button
                    className={normalClassName}
                    onClick={wrapperOnClick}
                    disabled={disabled}
                    loading={loading}
                    testid={testid}
                >
                    <span className='feedback-button-inner'>
                        {icon && <Icon icon={icon} className='button-icon' />}
                        {normalText}
                    </span>
                </Button>
            ) : (
                <Button className={feedbackClassName} disabled={disabled} loading={loading} testid={testid}>
                    {feedbackText}
                </Button>
            )}
        </>
    )
}
