import { createValidator, required, cpf, cns, nome, dataNasc, maxLength } from 'libs/validation'
import * as C from 'libs/components'
import ContatoProfissionalValidator from 'view/public/profissional/ContatoProfissionalValidator'
import SolicitacaoVinculoFormSectionValidator from 'view/public/profissional/components/SolicitacaoVinculoFormSectionValidator'
import { PerfilEnum } from 'api/Perfis'

const CadastroProfissionalValidator = createValidator(
    {
        cns: cns,
        contatoForm: ContatoProfissionalValidator,
        cpf: [required, cpf],
        dataNasc: [required, dataNasc],
        enderecoForm: C.ProfissionalEnderecoFormValidator,
        nome: [required, nome],
        numeroRegistro: maxLength(10),
        sexo: [required],
        solicitacaoVinculo: SolicitacaoVinculoFormSectionValidator,
    },
    form => {
        const errors = {}

        if (
            form.get('perfil') === PerfilEnum.MEDICO_CARDIOLOGISTA ||
            form.get('perfil') === PerfilEnum.MEDICO_ORTOPEDISTA
        ) {
            errors.numeroRegistro = required(form.get('numeroRegistro'))
            errors.ufRegistro = required(form.get('ufRegistro'))
        }

        return errors
    }
)

export default CadastroProfissionalValidator
