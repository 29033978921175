import * as React from 'react'
import { Card, Circle, CircleProgress, Column, Columns, LoadingStateContainer, NumberLabel } from 'libs/components'
import { DistClassificacao } from 'redux/modules/report/procedimento'

interface Props {
    distClassificacao: DistClassificacao
}

export class PrimariaRevisaoCard extends React.Component<Props> {
    render() {
        const dist = this.props.distClassificacao
        const result = dist.result

        const classPrimaria = 'is-report-color-1'
        const classRevisao = 'is-report-color-2'
        const quocients = result && {
            [classPrimaria]: result.classificacoes['PRIMARIA']?.quocient,
            [classRevisao]: result.classificacoes['REVISAO']?.quocient,
        }

        return (
            <Card title='Primária x revisão' fullHeight>
                <LoadingStateContainer readyState={dist.readyState}>
                    {result && (
                        <Columns className='is-small spacing-double align-center'>
                            <Column size='5'>
                                <div className='is-semibold is-black-ter'>
                                    <p key={'PRIMARIA'}>
                                        <Circle size={12} circleClass={classPrimaria} /> {'Primária'}
                                    </p>
                                    <p key={'REVISAO'}>
                                        <Circle size={12} circleClass={classRevisao} /> {'Revisão'}
                                    </p>
                                </div>
                            </Column>
                            <Column size='4'>
                                <div className='is-semibold'>
                                    <p key={'PRIMARIA'}>
                                        <NumberLabel value={(result.classificacoes['PRIMARIA']?.quocient ?? 0) * 100} />
                                        %
                                    </p>
                                    <p key={'REVISAO'}>
                                        <NumberLabel value={(result.classificacoes['REVISAO']?.quocient ?? 0) * 100} />%
                                    </p>
                                </div>
                            </Column>
                            <Column size='3'>
                                <CircleProgress type='primary' size={46} progress={quocients} />
                            </Column>
                        </Columns>
                    )}
                </LoadingStateContainer>
            </Card>
        )
    }
}
