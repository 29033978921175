
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.42,"time":85199.99999999999,"words":284}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "técnico"
    }}>{`Técnico`}</h3>
    <p>{`O perfil de técnico pode ser atribuído para diversos profissionais tais como enfermeiros, técnicos de enfermagem, instrumentador cirúrgico entre outros. Sua principal função é registrar procedimentos para os médicos e fazer o registro dos dados de produto, dos componentes que foram implantados nos pacientes.`}</p>
    <ol>
      <li parentName="ol">{`Acesse `}<a parentName="li" {...{
          "href": "https://rni.anvisa.gov.br/"
        }}>{`https://rni.anvisa.gov.br/`}</a></li>
      <li parentName="ol">{`Clique no botão `}<strong parentName="li">{`“Cadastre-se aqui”`}</strong></li>
      <li parentName="ol">{`Informe seus dados de identificação`}</li>
      <li parentName="ol">{`Informe seu endereço`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para facilitar o preenchimento informe o CEP e clique no botão “Consultar CEP”.`}</p>
    </blockquote>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Forneça as informações de contato`}</li>
      <li parentName="ol">{`Informe a UF da instituição em que trabalha`}</li>
      <li parentName="ol">{`Selecione a instituição em que trabalha através da lista das instituições`}</li>
      <li parentName="ol">{`Selecione o perfil “Técnico”`}</li>
      <li parentName="ol">{`Clique no botão “Salvar”`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Caso você trabalhe em mais de uma instituição, não se preocupe, você poderá solicitar outros vínculos após a aprovação do seu cadastro.`}</p>
    </blockquote>
    <p>{`Após enviar seu cadastro, você receberá um e-mail solicitando a confirmação do seu endereço de e-mail. Esta confirmação deverá ocorrer dentro de 24 horas após o recebimento de e-mail de confirmação. Caso você não realize a confirmação dentro do prazo, suas informações serão excluídas do sistema, e será necessário que você preencha e envie as informações de cadastro novamente.`}</p>
    <p>{`Após você confirmar o endereço de e-mail, o formulário de cadastro é enviado para o gestor de segurança da instituição, que analisará a solicitação. Esta é uma medida de segurança para garantir que apenas os funcionários da instituição terão acesso aos dados.`}</p>
    <p>{`Você receberá um email informando se o cadastro foi aceito ou recusado, além de demais instruções.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Observação:`}</strong>{` Para visualizar como funciona o processo de cada etapa do seu cadastro, `}<a parentName="p" {...{
          "href": "../../fluxos/fluxoCadastro"
        }}><strong parentName="a">{`clique aqui`}</strong></a>{`, ou consulte o vídeo explicativo do cadastro do usuário abaixo:`}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "técnico",
    level: 3,
    title: "Técnico",
    children: [
        
      ]
  }
]

export const frontMatter = {}

