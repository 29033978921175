import { connect } from 'react-redux'
import { destroy } from 'redux-form'
import { isPristine, hasSubmitSucceeded } from 'redux-form/immutable'
import { FichaStentCoronariaView } from 'view/home/novoProcedimento/ficha/cardio/FichaStentCoronariaView'
import { RouteComponentProps } from 'react-router-dom'

const formName = 'stentCoronaria'

interface FichaStentCoronariaProps extends RouteComponentProps<any> {
    editMode?: boolean
}

const mapStateToProps = (state, ownProps: FichaStentCoronariaProps) => {
    if (ownProps?.editMode) {
        return {
            paciente: state.paciente.form,
            showLeaving: !isPristine(formName)(state) && !hasSubmitSucceeded(formName)(state),
            editMode: true,
        }
    } else {
        return {
            paciente: state.novoProcedimento.paciente,
            showLeaving: !isPristine(formName)(state) && !hasSubmitSucceeded(formName)(state),
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        destroy: () => dispatch(destroy(formName)),
    }
}

const FichaStentCoronariaContainer = connect(mapStateToProps, mapDispatchToProps)(FichaStentCoronariaView as any)

export default FichaStentCoronariaContainer
