import * as React from 'react'
import { Columns, Column, Card, NumberLabel, Progress, Text, LinkButton } from 'libs/components'
import { ProcedimentoMode, MultiplosProcedimentosResult } from 'backend'
import { MultiplosProcedimentosFiltro } from 'model'
import { Oval } from '../components/Oval'
import { InvertedLabel } from 'view/componentes/reports/components/InvertedLabel'
import { IconTempoAteRevisao } from './IconTempoAteRevisao'
import { TempoLabel } from '../components/TempoLabel'
import { RouteComponentProps, withRouter } from 'react-router-dom'

export interface Props extends RouteComponentProps {
    title: string
    filtro: MultiplosProcedimentosFiltro
    result: MultiplosProcedimentosResult
    mode: ProcedimentoMode
}

export class MultiplosProcedimentosResultComponent extends React.Component<Props> {
    render() {
        if (!this.props.result) {
            return null
        }

        return (
            <div>
                <h1 className='is-bold'>
                    {this.props.title}{' '}
                    <Oval
                        className={this.props.mode === 'PRIMARIA_REVISAO' ? 'is-report-color-1' : 'is-report-color-2'}
                    />
                    <Oval className='is-report-color-2' />
                </h1>
                <br />

                <Columns auto>
                    <ProcedimentosCard {...this.props} />
                </Columns>

                <Columns auto>
                    <PacientesCard {...this.props} />
                </Columns>

                <Columns auto>
                    <TempoMedioCard {...this.props} />
                </Columns>
            </div>
        )
    }
}

export const MultiplosProcedimentosResultView = withRouter(MultiplosProcedimentosResultComponent)

export const NA = () => {
    return (
        <span title='Não se aplica' className='is-text-muted'>
            N/A
        </span>
    )
}

export const ProcedimentosCard = (props: Props) => {
    const percentualMesmoHospital = props.result.procedimentoCountMesmoHospital / props.result.procedimentoCount
    const percentualHospitaisDiferentes =
        props.result.procedimentoCountHospitalDiferente / props.result.procedimentoCount

    const percentualMesmoHospitalMesmoMedico =
        props.result.procedimentoCountMesmoMedicoMesmoHospital / props.result.procedimentoCountMesmoHospital
    const percentualMesmoHospitalMedicosDiferentes =
        props.result.procedimentoCountMedicoDiferenteMesmoHospital / props.result.procedimentoCountMesmoHospital

    const percentualHospitaisDiferentesMesmoMedico =
        props.result.procedimentoCountMesmoMedicoHospitalDiferente / props.result.procedimentoCountHospitalDiferente
    const percentualHospitaisDiferentesMedicosDiferentes =
        props.result.procedimentoCountMedicoDiferenteHospitalDiferente / props.result.procedimentoCountHospitalDiferente

    return (
        <Card title='Procedimentos'>
            <Columns className='align-center'>
                <Column size='3'>
                    <InvertedLabel title='Procedimentos'>
                        <NumberLabel value={props.result.procedimentoCount} />
                    </InvertedLabel>
                </Column>

                <Column customStyle={{ paddingRight: '0px' }} size='3' className='has-border-left'>
                    <InvertedLabel title='Mesmo hospital' size='x-large'>
                        <div className='multiplos--hospital-count'>
                            <NumberLabel value={props.result.procedimentoCountMesmoHospital} />
                            <Text size='small-ter' color='grey-darker'>
                                <NumberLabel
                                    value={percentualMesmoHospital > 0 ? percentualMesmoHospital * 100 : undefined}
                                    sufix='%'
                                    placeholder='- %'
                                />
                            </Text>
                        </div>
                    </InvertedLabel>
                    <br />
                    <InvertedLabel title='Hospitais diferentes' size='x-large'>
                        {props.result.procedimentoCountHospitalDiferente !== null ? (
                            <div className='multiplos--hospital-count'>
                                <NumberLabel value={props.result.procedimentoCountHospitalDiferente} />
                                <Text size='small-ter' color='grey-darker'>
                                    <NumberLabel
                                        value={
                                            percentualHospitaisDiferentes > 0
                                                ? percentualHospitaisDiferentes * 100
                                                : undefined
                                        }
                                        sufix='%'
                                        placeholder='- %'
                                    />
                                </Text>
                            </div>
                        ) : (
                            <NA />
                        )}
                    </InvertedLabel>
                </Column>

                <Column customStyle={{ padding: '0px' }} size='1' className='has-text-centered is-grey is-big'>
                    <p style={{ fontSize: '2em', lineHeight: 1, paddingBottom: '10px' }}>&rarr;</p>
                    <p style={{ fontSize: '2em', lineHeight: 1, paddingBottom: '35px' }}>&rarr;</p>
                </Column>

                <Column size='5'>
                    <dl className='is-horizontal is-value-strong is-small-bis spacing-double'>
                        <dt>Com médicos responsáveis diferentes</dt>
                        <dd>
                            <NumberLabel value={props.result.procedimentoCountMedicoDiferenteMesmoHospital} />{' '}
                            <Text size='small-ter' color='grey-darker' weight='regular'>
                                <NumberLabel
                                    value={
                                        percentualMesmoHospitalMedicosDiferentes > 0
                                            ? percentualMesmoHospitalMedicosDiferentes * 100
                                            : undefined
                                    }
                                    sufix='%'
                                    placeholder='- %'
                                />
                            </Text>
                        </dd>

                        <dt>Com o mesmo médico responsável</dt>
                        <dd>
                            <NumberLabel value={props.result.procedimentoCountMesmoMedicoMesmoHospital} />{' '}
                            <Text size='small-ter' color='grey-darker' weight='regular'>
                                <NumberLabel
                                    value={
                                        percentualMesmoHospitalMesmoMedico > 0
                                            ? percentualMesmoHospitalMesmoMedico * 100
                                            : undefined
                                    }
                                    sufix='%'
                                    placeholder='- %'
                                />
                            </Text>
                        </dd>

                        <br />

                        <dt>Com médicos responsáveis diferentes</dt>
                        <dd>
                            {props.result.procedimentoCountMedicoDiferenteHospitalDiferente !== null ? (
                                <div>
                                    <NumberLabel
                                        value={props.result.procedimentoCountMedicoDiferenteHospitalDiferente}
                                    />{' '}
                                    <Text size='small-ter' color='grey-darker' weight='regular'>
                                        <NumberLabel
                                            value={
                                                percentualHospitaisDiferentesMedicosDiferentes > 0
                                                    ? percentualHospitaisDiferentesMedicosDiferentes * 100
                                                    : undefined
                                            }
                                            sufix='%'
                                            placeholder='- %'
                                        />
                                    </Text>
                                </div>
                            ) : (
                                <NA />
                            )}
                        </dd>

                        <dt>Com o mesmo médico responsável</dt>
                        <dd>
                            {props.result.procedimentoCountMesmoMedicoHospitalDiferente !== null ? (
                                <div>
                                    <NumberLabel value={props.result.procedimentoCountMesmoMedicoHospitalDiferente} />{' '}
                                    <Text size='small-ter' color='grey-darker' weight='regular'>
                                        <NumberLabel
                                            value={
                                                percentualHospitaisDiferentesMesmoMedico > 0
                                                    ? percentualHospitaisDiferentesMesmoMedico * 100
                                                    : undefined
                                            }
                                            sufix='%'
                                            placeholder='- %'
                                        />
                                    </Text>
                                </div>
                            ) : (
                                <NA />
                            )}
                        </dd>
                    </dl>
                </Column>
            </Columns>
        </Card>
    )
}

export const PacientesCard = (props: Props) => {
    const progress = props.result.mesesEntreProcedimentos / (20 * 12)
    const progressStyle = (progress >= 0.5 && 'is-primary') || (progress >= 0.25 && 'is-warning') || 'is-danger'

    return (
        <Card title='Pacientes'>
            <Columns className='align-center'>
                <Column size='3'>
                    <InvertedLabel title='Pacientes'>
                        <NumberLabel value={props.result.pacienteCount} />
                    </InvertedLabel>
                </Column>

                <Column size='4' className='has-border-left has-border-right'>
                    <dl
                        className='is-horizontal is-value-strong is-small spacing-double'
                        style={{ width: '75%', margin: '0 auto' }}
                    >
                        <dt>Homens</dt>
                        <dd>
                            <NumberLabel value={props.result.pacienteMasculinoCount} />
                        </dd>

                        <dt>Mulheres</dt>
                        <dd>
                            <NumberLabel value={props.result.pacienteFemininoCount} />
                        </dd>
                    </dl>
                </Column>

                <Column size='5'>
                    <InvertedLabel title='Tempo médio entre procedimentos' size='large' maxWidth={200}>
                        <TempoLabel meses={props.result.mesesEntreProcedimentos} />
                        <Progress value={progress} size='small' className={progressStyle} />
                    </InvertedLabel>
                </Column>
            </Columns>
        </Card>
    )
}

export const TempoMedioCard = (props: Props) => {
    return (
        <Card title='Procedimentos X tempo até a revisão'>
            <Columns className='align-center'>
                <Column className='has-text-centered'>
                    <InvertedLabel title='Revisões com menos de 5 anos' maxWidth={110}>
                        <NumberLabel
                            data-name={props.mode.toLowerCase() + '_qtd_menos_de_cinco'}
                            value={props.result.procedimentoCountMenos5Anos}
                        />
                        <Text size='small-bis'>
                            <IconTempoAteRevisao tempoAteRevisao='MENOS_5_ANOS' />
                        </Text>
                    </InvertedLabel>
                    <br />
                    <LinkButton
                        data-name={props.mode.toLowerCase() + '_menos_de_cinco'}
                        disabled={props.result.procedimentoCountMenos5Anos < 1}
                        to={{
                            pathname: `${props.match.url}/${props.mode}/MENOS_5_ANOS/pacientes/list`,
                            search: props.location.search,
                        }}
                    >
                        Lista de pacientes
                    </LinkButton>
                </Column>

                <Column className='has-border-left has-text-centered'>
                    <InvertedLabel title='Revisões entre 5 e 10 anos' maxWidth={110}>
                        <NumberLabel
                            data-name={props.mode.toLowerCase() + '_qtd_entre_cinco_dez'}
                            value={props.result.procedimentoCountEntre5e10Anos}
                        />
                        <Text size='small-bis'>
                            <IconTempoAteRevisao tempoAteRevisao='ENTRE_5_E_10_ANOS' />
                        </Text>
                    </InvertedLabel>
                    <br />
                    <LinkButton
                        data-name={props.mode.toLowerCase() + '_entre_cinco_dez'}
                        disabled={props.result.procedimentoCountEntre5e10Anos < 1}
                        to={{
                            pathname: `${props.match.url}/${props.mode}/ENTRE_5_E_10_ANOS/pacientes/list`,
                            search: props.location.search,
                        }}
                    >
                        Lista de pacientes
                    </LinkButton>
                </Column>

                <Column className='has-border-left has-text-centered'>
                    <InvertedLabel title='Revisões com mais de 10 anos' maxWidth={110}>
                        <NumberLabel
                            data-name={props.mode.toLowerCase() + '_qtd_mais_de_dez'}
                            value={props.result.procedimentoCountMais10Anos}
                        />
                        <Text size='small-bis'>
                            <IconTempoAteRevisao tempoAteRevisao='MAIS_10_ANOS' />
                        </Text>
                    </InvertedLabel>
                    <br />
                    <LinkButton
                        data-name={props.mode.toLowerCase() + '_mais_de_dez'}
                        disabled={props.result.procedimentoCountMais10Anos < 1}
                        to={{
                            pathname: `${props.match.url}/${props.mode}/MAIS_10_ANOS/pacientes/list`,
                            search: props.location.search,
                        }}
                    >
                        Lista de pacientes
                    </LinkButton>
                </Column>
            </Columns>
        </Card>
    )
}
