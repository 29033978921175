import { createFormReducer, FormState } from 'redux/modules/forms'
import { actionTypes } from 'redux-form'
import { StentCoronariaForm } from 'backend'
import immutableProxy, { ImmutableRootProxy } from 'libs/immutableProxy'
import { isImmutable } from 'immutable'

const antecedentes = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>) => {
    const terapia = mutable.values.complementar.antecedentes.diabetesTerapia.get()
    if (terapia && terapia.indexOf('NENHUMA') > -1) {
        mutable.values.complementar.antecedentes.diabetesTerapia.set(['NENHUMA'])
    }
    const terapiaMedicamentosa = mutable.values.complementar.antecedentes.dislipidemiaTerapia.get()
    if (terapiaMedicamentosa && terapiaMedicamentosa.indexOf('NENHUMA') > -1) {
        mutable.values.complementar.antecedentes.dislipidemiaTerapia.set(['NENHUMA'])
    }
    if (mutable.values.complementar.antecedentes.doencaPulmonar.get() !== 'SIM') {
        mutable.values.complementar.antecedentes.doencaPulmonarDependeODois.delete()
    }
    if (mutable.values.complementar.antecedentes.indiceMassaCorporalAvaliado.get() !== 'SIM') {
        mutable.values.complementar.antecedentes.altura.delete()
        mutable.values.complementar.antecedentes.peso.delete()
    }
    if (mutable.values.complementar.antecedentes.diabetes.get() !== 'SIM') {
        mutable.values.complementar.antecedentes.diabetesTerapia.delete()
    }
    if (mutable.values.complementar.antecedentes.dislipidemia.get() !== 'SIM') {
        mutable.values.complementar.antecedentes.dislipidemiaTerapia.delete()
    }
    return mutable
}

const antecedentesContinuado = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>) => {
    if (mutable.values.complementar.antecedentes.fibrilacaoAnticoagulacao.get() !== 'SIM') {
        mutable.values.complementar.antecedentes.fibrilacaoTipoCoagulacao.delete()
    }
    if (mutable.values.complementar.antecedentes.fibrilacaoAtrialCronica.get() !== 'SIM') {
        mutable.values.complementar.antecedentes.fibrilacaoAnticoagulacao.delete()
    }
    if (mutable.values.complementar.antecedentes.funcaoVEConhecida.get() !== 'SIM') {
        mutable.values.complementar.antecedentes.fcVEConhecidaSemiQuantitativo.delete()
        mutable.values.complementar.antecedentes.funcaoVEConhecidaPercentilFE.delete()
    }
    if (mutable.values.complementar.antecedentes.iccPrevia.get() !== 'SIM') {
        mutable.values.complementar.antecedentes.iccPreviaClasseFuncional.delete()
    }
    if (mutable.values.complementar.antecedentes.insuficienciaRenal.get() !== 'SIM') {
        mutable.values.complementar.antecedentes.insuficienciaRenalIrc.delete()
        mutable.values.complementar.antecedentes.insuficienciaRenalCreatinina.delete()
    }
    if (mutable.values.complementar.antecedentes.intervencaoValvar.get() !== 'SIM') {
        mutable.values.complementar.antecedentes.intervencaoValvarTipo.delete()
    }
    if (mutable.values.complementar.antecedentes.tabagismo.get() !== 'SIM') {
        mutable.values.complementar.antecedentes.tabagismoSituacao.delete()
    }
    return mutable
}

const isquemia = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>, action) => {
    if (
        action.meta.field === 'essencial.isquemia.testeNaoInvasivo' &&
        mutable.values.essencial.isquemia.testeNaoInvasivo.get() === 'SIM'
    ) {
        mutable.values.essencial.isquemia.angioCoronaria.delete()
        mutable.values.essencial.isquemia.cintilografia.delete()
        mutable.values.essencial.isquemia.estresse.delete()
        mutable.values.essencial.isquemia.ergometrico.delete()
    }
    if (mutable.values.essencial.isquemia.testeNaoInvasivo.get() === 'NAO') {
        mutable.values.essencial.isquemia.angioCoronaria.set('NAO')
        mutable.values.essencial.isquemia.cintilografia.set('NAO')
        mutable.values.essencial.isquemia.estresse.set('NAO')
        mutable.values.essencial.isquemia.ergometrico.set('NAO')
    } else if (mutable.values.essencial.isquemia.testeNaoInvasivo.get() === null) {
        mutable.values.essencial.isquemia.angioCoronaria.delete()
        mutable.values.essencial.isquemia.cintilografia.delete()
        mutable.values.essencial.isquemia.estresse.delete()
        mutable.values.essencial.isquemia.ergometrico.delete()
    }

    if (mutable.values.essencial.isquemia.angioCoronaria.get() !== 'SIM') {
        mutable.values.essencial.isquemia.angioCoronariaTipo.delete()
        mutable.values.essencial.isquemia.escoreCalcio.delete()
        mutable.values.essencial.isquemia.valorEscoreCalcio.delete()
        mutable.values.essencial.isquemia.percentilEscoreCalcio.delete()
    }
    if (mutable.values.essencial.isquemia.escoreCalcio.get() !== 'SIM') {
        mutable.values.essencial.isquemia.valorEscoreCalcio.delete()
        mutable.values.essencial.isquemia.percentilEscoreCalcio.delete()
    }

    if (mutable.values.essencial.isquemia.cintilografia.get() !== 'SIM') {
        mutable.values.essencial.isquemia.cintilografiaAvaliacao.delete()
        mutable.values.essencial.isquemia.cintilografiaRisco.delete()
    }
    if (mutable.values.essencial.isquemia.estresse.get() !== 'SIM') {
        mutable.values.essencial.isquemia.estresseAvaliacao.delete()
        mutable.values.essencial.isquemia.estresseRisco.delete()
    }
    if (mutable.values.essencial.isquemia.ergometrico.get() !== 'SIM') {
        mutable.values.essencial.isquemia.ergometricoAvaliacao.delete()
        mutable.values.essencial.isquemia.ergometricoRisco.delete()
    }
    return mutable
}
const possuiQuadroClinico = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>, quadroClinico) => {
    const hasUrgenciaInvasiva = quadroClinico.id === 'ANGINA_INSTAVEL' || quadroClinico.id === 'IAM_SEM_SUPRA_ST'
    const hasEletivaAdhoc =
        quadroClinico.id === 'ANGINA_ESTAVEL' ||
        quadroClinico.id === 'ASSINTOMATICO' ||
        quadroClinico.id === 'EQUIVALENTE_ISQUEMICO' ||
        quadroClinico.id === 'DOR_TORACICA_ATIPICA'
    const eletivaAdhoc = mutable.values.essencial.apresentacaoClinica.eletivaAdhoc.get()
    const urgenciaInvasiva = mutable.values.essencial.apresentacaoClinica.urgenciaInvasiva.get()
    if (urgenciaInvasiva && hasEletivaAdhoc) {
        mutable.values.essencial.apresentacaoClinica.urgenciaInvasiva.delete()
    } else if (eletivaAdhoc && hasUrgenciaInvasiva) {
        mutable.values.essencial.apresentacaoClinica.eletivaAdhoc.delete()
    } else if (quadroClinico.id === 'IAM_COM_SUPRA_ST') {
        mutable.values.essencial.apresentacaoClinica.urgenciaInvasiva.delete()
        mutable.values.essencial.apresentacaoClinica.eletivaAdhoc.delete()
    }
    return mutable
}

const zeraOpcoesCaraterICP = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>) => {
    const quadroClinico = mutable.values.essencial.apresentacaoClinica.quadroClinico.get()
    if (quadroClinico) {
        possuiQuadroClinico(mutable, quadroClinico)
    } else {
        mutable.values.essencial.apresentacaoClinica.urgenciaInvasiva.delete()
        mutable.values.essencial.apresentacaoClinica.eletivaAdhoc.delete()
    }
    return mutable
}

const apresentacaoClinica = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>, action) => {
    const quadroClinico = mutable.values.essencial.apresentacaoClinica.quadroClinico.get()
    let quadroClinicoId
    if (isImmutable(quadroClinico)) {
        quadroClinicoId = quadroClinico.get('id')
    } else {
        quadroClinicoId = quadroClinico?.id
    }

    zeraOpcoesCaraterICP(mutable)
    if (quadroClinicoId !== 'ANGINA_ESTAVEL') {
        mutable.values.essencial.apresentacaoClinica.classeFuncional.delete()
    }
    if (action.meta.field === 'essencial.apresentacaoClinica.quadroClinico') {
        mutable.values.essencial.apresentacaoClinica.urgenciaInvasiva.delete()
        mutable.values.essencial.apresentacaoClinica.eletivaAdhoc.delete()
    }
    if (quadroClinicoId !== 'IAM_COM_SUPRA_ST') {
        mutable.values.essencial.apresentacaoClinica.tipoCaraterICP.delete()

        mutable.values.essencial.apresentacaoClinica.dataAdmissao.delete()
        mutable.values.essencial.apresentacaoClinica.horaAdmissao.delete()
        mutable.values.essencial.apresentacaoClinica.dataAberturaVaso.delete()
        mutable.values.essencial.apresentacaoClinica.horaAberturaVaso.delete()
        mutable.values.essencial.apresentacaoClinica.dataInicioSintomas.delete()
        mutable.values.essencial.apresentacaoClinica.horaInicioSintomas.delete()
        mutable.values.essencial.apresentacaoClinica.transferenciaIcpPrimaria.delete()
        mutable.values.essencial.apresentacaoClinica.dataContatoHospital.delete()
        mutable.values.essencial.apresentacaoClinica.horaContatoHospital.delete()
        mutable.values.essencial.apresentacaoClinica.dataChegadaIcp.delete()
        mutable.values.essencial.apresentacaoClinica.horaChegadaIcp.delete()
    }
    return mutable
}

const zerarHorasSemData = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>) => {
    if (!mutable.values.essencial.apresentacaoClinica.dataAdmissao.get()) {
        mutable.values.essencial.apresentacaoClinica.horaAdmissao.delete()
    }

    if (!mutable.values.essencial.apresentacaoClinica.dataAberturaVaso.get()) {
        mutable.values.essencial.apresentacaoClinica.horaAberturaVaso.delete()
    }
    if (!mutable.values.essencial.apresentacaoClinica.dataInicioSintomas.get()) {
        mutable.values.essencial.apresentacaoClinica.horaInicioSintomas.delete()
    }

    if (!mutable.values.essencial.apresentacaoClinica.dataContatoHospital.get()) {
        mutable.values.essencial.apresentacaoClinica.horaContatoHospital.delete()
    }
    if (!mutable.values.essencial.apresentacaoClinica.dataChegadaIcp.get()) {
        mutable.values.essencial.apresentacaoClinica.horaChegadaIcp.delete()
    }

    return mutable
}

const apresentacaoClinicaContinuada = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>) => {
    const tipoCaraterICP = mutable.values.essencial.apresentacaoClinica.tipoCaraterICP.get()
    if (
        !tipoCaraterICP ||
        (tipoCaraterICP.id !== 'PRIMARIA_MENOS_12_HORAS_SINTOMA' &&
            tipoCaraterICP.id !== 'PRIMARIA_MAIS_12_HORAS_SINTOMA')
    ) {
        mutable.values.essencial.apresentacaoClinica.dataAdmissao.delete()
        mutable.values.essencial.apresentacaoClinica.horaAdmissao.delete()
        mutable.values.essencial.apresentacaoClinica.dataAberturaVaso.delete()
        mutable.values.essencial.apresentacaoClinica.horaAberturaVaso.delete()
        mutable.values.essencial.apresentacaoClinica.dataInicioSintomas.delete()
        mutable.values.essencial.apresentacaoClinica.horaInicioSintomas.delete()
        mutable.values.essencial.apresentacaoClinica.transferenciaIcpPrimaria.delete()
        mutable.values.essencial.apresentacaoClinica.dataContatoHospital.delete()
        mutable.values.essencial.apresentacaoClinica.horaContatoHospital.delete()
        mutable.values.essencial.apresentacaoClinica.dataChegadaIcp.delete()
        mutable.values.essencial.apresentacaoClinica.horaChegadaIcp.delete()
    } else {
        zerarHorasSemData(mutable)
    }
    const transfIcpPrimaria = mutable.values.essencial.apresentacaoClinica.transferenciaIcpPrimaria.get()
    if (!transfIcpPrimaria || transfIcpPrimaria !== 'SIM') {
        mutable.values.essencial.apresentacaoClinica.dataContatoHospital.delete()
        mutable.values.essencial.apresentacaoClinica.horaContatoHospital.delete()
        mutable.values.essencial.apresentacaoClinica.dataChegadaIcp.delete()
        mutable.values.essencial.apresentacaoClinica.horaChegadaIcp.delete()
    }
    return mutable
}

const icpComplementarAcessoArterial = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>) => {
    // ICP COMPLEMENTAR
    const dispositivos = mutable.values.complementar.icpComplementar.dispositivosAdjuntos.get()
    if (mutable.values.complementar.icpComplementar.get() !== null) {
        if (!dispositivos || dispositivos.indexOf('DISPOSITIVO_ASSISTENCIA_MECANICA') === -1) {
            mutable.values.complementar.icpComplementar.tipoDispositivo.delete()
        }
        if (!mutable.values.complementar.icpComplementar.airKerma.get()) {
            mutable.values.complementar.icpComplementar.unidadeAirKerma.delete()
        }
        if (!mutable.values.complementar.icpComplementar.dap.get()) {
            mutable.values.complementar.icpComplementar.primeiraUnidadeDap.delete()
            mutable.values.complementar.icpComplementar.segundaUnidadeDap.delete()
        }
    }

    // ACESSO ARTERIAL
    if (mutable.values.complementar.acessoArterialContraste.get() !== null) {
        if (!mutable.values.complementar.acessoArterialContraste.viaAcesso.get()) {
            mutable.values.complementar.acessoArterialContraste.crossover.delete()
        }
        if (!mutable.values.complementar.acessoArterialContraste.tipoContraste.get()) {
            mutable.values.complementar.acessoArterialContraste.volume.delete()
        }
    }
    return mutable
}

const complicacoesClinicas = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>, action) => {
    if (
        action.meta.field === 'complementar.complicacoesClinicas.houveComplicacoes' &&
        mutable.values.complementar.complicacoesClinicas.houveComplicacoes.get() === 'SIM'
    ) {
        mutable.values.complementar.complicacoesClinicas.cardiaca.delete()
        mutable.values.complementar.complicacoesClinicas.hemorragica.delete()
        mutable.values.complementar.complicacoesClinicas.neurologica.delete()
        mutable.values.complementar.complicacoesClinicas.reacaoContraste.delete()
        mutable.values.complementar.complicacoesClinicas.respiratoria.delete()
        mutable.values.complementar.complicacoesClinicas.vascular.delete()
        mutable.values.complementar.complicacoesClinicas.obito.delete()
    }
    if (mutable.values.complementar.complicacoesClinicas.houveComplicacoes.get() === 'NAO') {
        mutable.values.complementar.complicacoesClinicas.cardiaca.set('NAO')
        mutable.values.complementar.complicacoesClinicas.hemorragica.set('NAO')
        mutable.values.complementar.complicacoesClinicas.neurologica.set('NAO')
        mutable.values.complementar.complicacoesClinicas.reacaoContraste.set('NAO')
        mutable.values.complementar.complicacoesClinicas.respiratoria.set('NAO')
        mutable.values.complementar.complicacoesClinicas.vascular.set('NAO')
        mutable.values.complementar.complicacoesClinicas.obito.set('NAO')
    } else if (mutable.values.complementar.complicacoesClinicas.houveComplicacoes.get() === null) {
        mutable.values.complementar.complicacoesClinicas.cardiaca.delete()
        mutable.values.complementar.complicacoesClinicas.hemorragica.delete()
        mutable.values.complementar.complicacoesClinicas.neurologica.delete()
        mutable.values.complementar.complicacoesClinicas.reacaoContraste.delete()
        mutable.values.complementar.complicacoesClinicas.respiratoria.delete()
        mutable.values.complementar.complicacoesClinicas.vascular.delete()
        mutable.values.complementar.complicacoesClinicas.obito.delete()
    }
    return mutable
}

const complicacoesClinicasContinuado = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>) => {
    if (mutable.values.complementar.complicacoesClinicas.cardiaca.get() !== 'SIM') {
        mutable.values.complementar.complicacoesClinicas.cardiacaSeveridade.delete()
    }
    if (mutable.values.complementar.complicacoesClinicas.hemorragica.get() !== 'SIM') {
        mutable.values.complementar.complicacoesClinicas.hemorragicaSangramento.delete()
    }
    if (mutable.values.complementar.complicacoesClinicas.neurologica.get() !== 'SIM') {
        mutable.values.complementar.complicacoesClinicas.neurologicaTipoAve.delete()
    }
    if (mutable.values.complementar.complicacoesClinicas.reacaoContraste.get() !== 'SIM') {
        mutable.values.complementar.complicacoesClinicas.reacaoContrasteSeveridade.delete()
    }
    if (mutable.values.complementar.complicacoesClinicas.respiratoria.get() !== 'SIM') {
        mutable.values.complementar.complicacoesClinicas.respiratoriaTipoComplicacao.delete()
    }
    return mutable
}

const complicacoesClinicasFinal = (mutable: ImmutableRootProxy<FormState<StentCoronariaForm>>) => {
    if (mutable.values.complementar.complicacoesClinicas.vascular.get() !== 'SIM') {
        mutable.values.complementar.complicacoesClinicas.vascularQuaisComplicacoes.delete()
    }
    if (mutable.values.complementar.complicacoesClinicas.obito.get() !== 'SIM') {
        mutable.values.complementar.complicacoesClinicas.obitoCausa.delete()
    }
    const hematoma = mutable.values.complementar.complicacoesClinicas.vascularQuaisComplicacoes.get()
    if (!hematoma || hematoma.indexOf('HEMATOMA') === -1) {
        mutable.values.complementar.complicacoesClinicas.vascularHematoma.delete()
    }
    return mutable
}

const stentCoronaria = createFormReducer((state, action) => {
    if (action.type === actionTypes.CHANGE) {
        let form = immutableProxy<FormState<StentCoronariaForm>>(state)
        form = form.withMutations(mutable => {
            // ESSENCIAL
            // ISQUEMIA
            if (mutable.values.essencial.isquemia.get() !== null) {
                isquemia(mutable, action)
            }

            // APRESENTAÇÃO CLINICA
            apresentacaoClinica(mutable, action)
            apresentacaoClinicaContinuada(mutable)

            // COMPLEMENTAR
            // ANTECEDENTES
            if (mutable.values.complementar.get() !== null) {
                if (mutable.values.complementar.antecedentes.get() !== null) {
                    antecedentes(mutable)
                    antecedentesContinuado(mutable)
                }

                icpComplementarAcessoArterial(mutable)

                // COMPLICACOES CLINICAS
                if (mutable.values.complementar.complicacoesClinicas.get() !== null) {
                    complicacoesClinicas(mutable, action)
                    complicacoesClinicasContinuado(mutable)
                    complicacoesClinicasFinal(mutable)
                }
            }
            // ALELUIA!
        })
        return form.asMap()
    }
    return state
})

export default stentCoronaria
