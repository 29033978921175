import { PerfisResidentes } from 'api/Perfis'
import { VinculoProfissionalRow } from 'backend'
import { Hint, IconButton, StyledRadioField } from 'libs/components'
import React from 'react'
import { useSelector } from 'react-redux'
import { getFormValues } from 'redux-form/immutable'
import { State } from 'redux/modules'
import { definirEquipeMedicaFormName } from '../DefinirEquipeMedicaForm'
import { VinculoProfissionalCard } from './VinculoProfissionalCard'

interface EquipeMedicaCardProps {
    cardValue: number
    vinculoProfissional: VinculoProfissionalRow
    onClickDelete: () => void
    userMedicoVinculoId: number
}

export const EquipeMedicaCard: React.FC<EquipeMedicaCardProps> = props => {
    const { cardValue, vinculoProfissional, onClickDelete, userMedicoVinculoId } = props

    const cardValueToCheckedWorks: string = cardValue.toString()
    const disableRadioField: boolean = PerfisResidentes.includes(vinculoProfissional.perfil)

    const formValues = useSelector<State, any>(state => getFormValues(definirEquipeMedicaFormName)(state))
    const idMedicoResponsavel: number = parseInt(formValues?.get('medicoResponsavel'), 10)
    const cardOfMedicoResponsavel: boolean = idMedicoResponsavel === cardValue

    const canDeleteCard: boolean = vinculoProfissional.id !== userMedicoVinculoId

    const radioField: JSX.Element = (
        <div className='equipe-medica--wrapper-radio'>
            <StyledRadioField
                value={cardValueToCheckedWorks}
                name='medicoResponsavel'
                className='equipe-medica--radio-box'
                innerClassName='equipe-medica--radio'
                skin='square-button'
                useStyledButtonClassName={false}
                disabled={disableRadioField}
            />
        </div>
    )

    return (
        <div className='equipe-medica--card-box'>
            {disableRadioField ? (
                <Hint placement='top' componente={radioField}>
                    O médico responsável não pode ser residente.
                </Hint>
            ) : (
                radioField
            )}
            <VinculoProfissionalCard
                vinculoProfissional={vinculoProfissional}
                showResponsavelLabel={cardOfMedicoResponsavel}
            />
            {canDeleteCard && (
                <div className='equipe-medica--remover-box'>
                    <IconButton icon='remover' size='is-24px' onClick={onClickDelete} />
                </div>
            )}
        </div>
    )
}
