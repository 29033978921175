import * as React from 'react'
import { Switch, Route } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import VisualizacaoContainer from 'view/home/visualizacao/VisualizacaoContainer'
import BuscaProcedimentoView from './BuscaProcedimentoView'

interface BuscaProcedimentoRootViewProps extends RouteComponentProps<any> {
    clear(): void
}

class BuscaProcedimentoRootView extends React.Component<BuscaProcedimentoRootViewProps> {
    componentWillUnmount() {
        this.props.clear()
    }

    render() {
        return (
            <Switch>
                <Route
                    path={'/home/buscaProcedimentos/visualizacao/'}
                    render={props => {
                        return (
                            <div className='container home-conteudo is-vertical-flow is-full-height'>
                                <VisualizacaoContainer {...props} />
                            </div>
                        )
                    }}
                />
                <Route path={'/home/buscaProcedimentos'} component={BuscaProcedimentoView} />
            </Switch>
        )
    }
}

export default BuscaProcedimentoRootView
