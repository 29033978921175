import * as React from 'react'
import { InjectedFormProps } from 'redux-form'
import { MultiplosProcedimentosFiltro } from 'model'
import { ControlField, StyledRadioField, Button, Columns, Column } from 'libs/components'
import { Map } from 'immutable'
import form from 'libs/redux-form/form'

export interface MultiplosProcedimentosMapFilterProps extends InjectedFormProps<any, any> {
    onSubmit(values: Map<string, any>): void
}

class MultiplosProcedimentosMapFilterCmp extends React.Component<MultiplosProcedimentosMapFilterProps> {
    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <Columns className='align-end'>
                    <Column size='6'>
                        <ControlField label='Procedimentos' name='procedimentoMode'>
                            <StyledRadioField
                                name='procedimentoMode'
                                value='PRIMARIA_REVISAO'
                                size='small'
                                skin='square-button'
                            >
                                <label>Primária + Revisão</label>
                            </StyledRadioField>
                            <StyledRadioField
                                name='procedimentoMode'
                                value='REVISAO_REVISAO'
                                size='small'
                                skin='square-button'
                            >
                                <label>Revisão + Revisão</label>
                            </StyledRadioField>
                        </ControlField>
                    </Column>
                    <Column size='4'>
                        <ControlField label='Médico Responsável' name='medicoMode'>
                            <StyledRadioField name='medicoMode' value='MESMO' size='small' skin='square-button'>
                                <label>Mesmo</label>
                            </StyledRadioField>
                            <StyledRadioField name='medicoMode' value='DIFERENTE' size='small' skin='square-button'>
                                <label>Diferente</label>
                            </StyledRadioField>
                        </ControlField>
                    </Column>
                    <Column size='2'>
                        <Button onClick={this.props.handleSubmit} type='neon' square>
                            APLICAR
                        </Button>
                    </Column>
                </Columns>
            </form>
        )
    }
}
export const MultiplosProcedimentosMapFilter = form({
    form: 'multiplosProcedimentosMapFilter',
    hasSuccessModal: false,
    hasErrorModal: false,
    hasLeaveModal: false,
    initialValues: {
        procedimentoMode: 'PRIMARIA_REVISAO',
        medicoMode: 'MESMO',
    } as MultiplosProcedimentosFiltro,
})(MultiplosProcedimentosMapFilterCmp) as any
