import * as React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import { Columns, Column, Button } from 'libs/components'
import { Link, RouteComponentProps } from 'react-router-dom'
import * as C from 'libs/components'
import { reduxForm, formValueSelector } from 'redux-form/immutable'
import { RastreabilidadeTable } from 'view/componentes/reports/components/RastreabilidadeTable'
import RastreabilidadeTableSemRegistro from 'view/componentes/reports/components/RastreabilidadeTableSemRegistro'
import semResultado from 'images/home/reports/rastreabilidade-de-produtos-busca-sem-resultados.svg'
import { ProdutoField } from 'view/home/rastreabilidade/componentes/ProdutoField'
import { State } from 'redux/modules'
import { InjectedFormProps } from 'redux-form'
import { DistribuicaoProdutosDto } from 'backend'
import BackButton from 'libs/components/presentational/elements/BackButton'
import { stringify } from 'querystring'

const InformacoesFabricante = props => {
    return (
        <div className='report-content informacoes-fabricante'>
            <div className='inf-fab-content'>
                <span className='titulo'>CNPJ</span>{' '}
                <C.MaskedLabel title='' value={props.cnpj} mask='11.111.111/1111-11' is-bold />
            </div>
            <div>
                <label className='nome-fabricante'>{props.nomeFabricante}</label>
            </div>
        </div>
    )
}

const ErroBusca = props => {
    if (props.submitting) {
        return <p className='report-content has-text-centered'>Buscando...</p>
    } else if (!props.distProdutos && !props.erro) {
        return <div></div>
    } else {
        return (
            <div className='resultado-busca report-content'>
                <div className='has-text-centered sem-resultados report-content'>
                    <img src={semResultado} alt='semresultado' />
                    <br />
                </div>
                <p className='text-result has-text-centered report-content'>Sem resultados</p>
            </div>
        )
    }
}

const ResultadoBusca = props => {
    if (props.distProdutos && props.cnpj) {
        return (
            <div>
                <InformacoesFabricante
                    cnpj={props.distProdutos.cnpj}
                    nomeFabricante={props.distProdutos.nomeFabricante}
                />
                <C.Columns>
                    <C.Column>
                        <RastreabilidadeTable
                            distProdutos={props.distProdutos.distribuicaoProdutosRow}
                            titulo={'Registros deste CNPJ (' + props.distProdutos.totalRegistro + ')'}
                            report={props.report}
                        />
                    </C.Column>
                </C.Columns>
            </div>
        )
    } else {
        return <ErroBusca submitting={props.submitting} erro={props.erro} distProdutos={props.distProdutos} />
    }
}

const FiltroPreenchido = props => {
    return (
        <div>
            <div className='report-content'>
                <Columns>
                    <Column size='2' />
                    <Column size='6'>
                        {props.tipoFiltro === 'REGISTRO_ANVISA' ? (
                            <ProdutoField maxLength={11} name='registroAnvisa' placeholder='Registro ANVISA' />
                        ) : (
                            <C.MaskedField mask='99.999.999/9999-99' placeholder={'__.___.___/____-__'} name='cnpj' />
                        )}
                    </Column>
                    <Column size='2'>
                        <Button
                            type='primary'
                            loading={props.submitting}
                            onClick={props.handleSubmit}
                            disabled={props.buttonDisabled}
                            fullwidth
                        >
                            Pesquisar
                        </Button>
                    </Column>
                    <Column size='2' />
                </Columns>
            </div>
            <ResultadoBusca
                distProdutos={props.distProdutos}
                erro={props.erro}
                submitting={props.submitting}
                cnpj={props.cnpj}
                report={props.report}
            />
        </div>
    )
}

interface DistribuicaoProdutosFormProps {
    erro?: any
    cnpj: any
    registroAnvisa: any
    empresa: any
    tipoFiltro: any
    produto: any
    distProdutos: DistribuicaoProdutosDto
    report(): JSX.Element
}

interface DistribuicaoProdutosOwnProps {
    filtro: string
    clearResult?: any
    onSubmit(value: any): any
    changeFiltro(filtro: string): any
}

interface DistribuicaoFormComponentProps extends DistribuicaoProdutosFormProps, DistribuicaoProdutosOwnProps {}

class DistribuicaoProdutosFormComponent extends React.Component<
    DistribuicaoFormComponentProps & InjectedFormProps<{}, DistribuicaoFormComponentProps>
> {
    render() {
        const { report, handleSubmit, submitting, pristine, erro, cnpj, registroAnvisa } = this.props
        const tipoCNPJ = this.props.tipoFiltro === 'CNPJ' && cnpj && cnpj.length === 14
        const tipoRegistroAnvisa =
            this.props.tipoFiltro === 'REGISTRO_ANVISA' && registroAnvisa && registroAnvisa.registroAnvisa.length === 11
        const buttonDisabled = pristine || submitting || !(tipoCNPJ || tipoRegistroAnvisa || this.props.empresa)
        const reportBody =
            this.props.tipoFiltro === 'ETIQUETAS_SEM_REGISTRO' ? (
                <RastreabilidadeTableSemRegistro />
            ) : (
                <FiltroPreenchido
                    tipoFiltro={this.props.tipoFiltro}
                    distProdutos={this.props.distProdutos}
                    submitting={submitting}
                    handleSubmit={handleSubmit}
                    buttonDisabled={buttonDisabled}
                    erro={erro}
                    cnpj={cnpj}
                    report={report}
                />
            )

        const handleTabItemClick = (tipo: string) => {
            this.props.changeFiltro(tipo)
            this.props.change('registroAnvisa', '')
            this.props.change('cnpj', '')
            this.props.change('empresa', '')
            this.props.clearResult()
        }

        return (
            <C.Form handleSubmit={handleSubmit}>
                <ReportHeader
                    title='Rastreabilidade de produtos'
                    subtitle='Pesquise por um Registro ANVISA ou CNPJ'
                    containerClass='report-rastreabilidade-container'
                >
                    <div>
                        <BackButton />
                    </div>

                    <HorizontalTabMenu>
                        <HorizontalTabMenuItem
                            title='Registro ANVISA'
                            onClick={() => handleTabItemClick('REGISTRO_ANVISA')}
                            active={this.props.tipoFiltro === 'REGISTRO_ANVISA'}
                        />
                        <HorizontalTabMenuItem
                            title='CNPJ'
                            onClick={() => handleTabItemClick('CNPJ')}
                            active={this.props.tipoFiltro === 'CNPJ'}
                        />
                        <HorizontalTabMenuItem
                            title='ETIQUETAS SEM REGISTRO'
                            onClick={() => handleTabItemClick('ETIQUETAS_SEM_REGISTRO')}
                            active={this.props.tipoFiltro === 'ETIQUETAS_SEM_REGISTRO'}
                        />
                    </HorizontalTabMenu>
                </ReportHeader>
                {reportBody}
            </C.Form>
        )
    }
}

let DistribuicaoProdutosForm = reduxForm<{}, DistribuicaoFormComponentProps>({
    form: 'distribuicaoProdutos',
})(DistribuicaoProdutosFormComponent)

const selector = formValueSelector('distribuicaoProdutos')

const mapStateToProps: MapStateToProps<DistribuicaoProdutosFormProps, DistribuicaoProdutosOwnProps, State> = (
    state: State
) => ({
    produto: state.report.rastreabilidade.produto,
    cnpj: selector(state, 'cnpj'),
    tipoFiltro: state.report.rastreabilidade.filtro,
    erro: state.report.rastreabilidade.findDistribuicaoProdutos.error,
    registroAnvisa: selector(state, 'registroAnvisa'),
    distProdutos: state.report.rastreabilidade.findDistribuicaoProdutos.data,
    empresa: selector(state, 'empresa'),
    report: () =>
        renderDownloadIcon(
            selector(state, 'cnpj'),
            selector(state, 'registroAnvisa'),
            state.report.rastreabilidade.filtro
        ),
})

const renderDownloadIcon = (cnpj: string, registroAnvisa: string, filtro: any): JSX.Element => {
    let filterValue: any = {}

    if (filtro === 'CNPJ') {
        filterValue = { cnpj: cnpj }
    } else if (filtro === 'REGISTRO_ANVISA') {
        filterValue = { registroAnvisa: registroAnvisa }
    }

    return (
        <Link
            to={{
                pathname: '/api/report/produtos/csv',
                search: stringify(filterValue),
            }}
            target='_blank'
            title='Exportar para CSV'
        >
            <C.Text color='grey-darker'>
                <C.Icon icon='exportar' />
            </C.Text>
        </Link>
    )
}

const mergeProps = (
    stateProps: DistribuicaoProdutosFormProps,
    dispatchProps: any,
    ownProps: DistribuicaoProdutosOwnProps
) => ({
    ...stateProps,
    ...ownProps,
})

export const ConnectedDistribuicaoProdutosForm = connect<
    DistribuicaoProdutosFormProps,
    null,
    DistribuicaoProdutosOwnProps,
    DistribuicaoFormComponentProps
>(
    mapStateToProps,
    null,
    mergeProps
)(DistribuicaoProdutosForm)

export interface Props extends RouteComponentProps<any> {
    filtro: string
    find(searchMode: string): any
    changeFiltro(filtro: string): any
    clearResult?: any
}

export class RastreabilidadeReportFilterView extends React.Component<Props> {
    componentWillUnmount() {
        this.props.clearResult()
    }

    render() {
        return (
            <ConnectedDistribuicaoProdutosForm
                onSubmit={this.props.find}
                changeFiltro={this.props.changeFiltro}
                filtro={this.props.filtro}
                clearResult={this.props.clearResult}
            />
        )
    }
}
