import { useEffect, useRef, useMemo } from 'react'
import * as filtroReport from 'redux/modules/report/filtro'
import { parse, stringify } from 'redux/modules/router'
import { useSelector, useDispatch } from 'react-redux'
import { State } from 'redux/modules'
import isEqual from 'lodash/isEqual'
import { useHistory, useLocation } from 'react-router-dom'

export function useReportFilterUrlPublisher() {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const filtro = useSelector((state: State) => state.report.filtro.filtro, isEqual)

    const firstRender = useRef(true)
    const firstRender2 = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            const qsParams = parse(location.search)
            dispatch(filtroReport.change(qsParams))
        }
    }, [dispatch, location.search])

    useEffect(() => {
        if (!firstRender2.current) {
            const search = stringify(filtro)
            history.push({ search })
        } else {
            firstRender2.current = false
        }
    }, [filtro, history])
}

export function useReportFilter() {
    const location = useLocation()
    return useMemo(() => parse(location.search), [location.search])
}
