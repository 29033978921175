/* tslint:disable */
import React from 'react'

const SvgNotificacaoAlertaComBorda = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 21 21' {...props}>
        <path
            clipRule='evenodd'
            d='M18 10.5a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zm-14 0a6.5 6.5 0 1013 0 6.5 6.5 0 00-13 0zm7.132 3.137a.9.9 0 01-.63.25.904.904 0 01-.636-.25.802.802 0 010-1.2.889.889 0 01.636-.254c.24 0 .45.083.63.254a.801.801 0 010 1.2zm.043-4.445c.069-.279.127-.52.172-.728.046-.206.07-.372.07-.499a.787.787 0 00-.276-.602.92.92 0 00-.645-.25.913.913 0 00-.645.25.794.794 0 00-.268.602c0 .117.024.29.075.522.05.232.105.467.165.709.066.263.14.59.225.977a26 26 0 01.27 1.459h.362a29.316 29.316 0 01.495-2.44z'
        />
    </svg>
)

export default SvgNotificacaoAlertaComBorda
