import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSistemasAtivos, clearSistemasAtivos } from 'redux/modules/integracao'
import { State } from 'redux/modules'
import { SistemaIntegracaoDto, SistemaIntegracaoWrapperDto } from 'backend'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { Label } from 'libs/components'
import { SistemasIntegracaoRow } from './common/SistemasIntegracaoRow'
import { SistemasIntegracaoAtivosAviso } from './common/SistemasIntegracaoAtivosAviso'

interface SistemasIntegracaoPageAtivosProps {
    handleClickEditar: (sistema: SistemaIntegracaoDto) => void
}

export const SistemasIntegracaoPageAtivos = ({ handleClickEditar }: SistemasIntegracaoPageAtivosProps) => {
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(getSistemasAtivos())
        return () => dispatch(clearSistemasAtivos())
    }, [dispatch])

    const sistemasAtivos = useSelector<State, PromiseRecord<SistemaIntegracaoWrapperDto>>(
        state => state.integracao.sistemasAtivos
    )
    const isFetching: boolean = sistemasAtivos?.readyState === 'request'
    const resultLenght: number = sistemasAtivos?.data?.sistemas.length
    const hasData: boolean = !!resultLenght && resultLenght > 0

    return (
        <div className='tab-page'>
            {isFetching ? (
                <Label className='integracao-value-request'>Carregando...</Label>
            ) : (
                <>
                    {hasData ? (
                        <>
                            {sistemasAtivos.data.sistemas.map((sistema: SistemaIntegracaoDto) => (
                                <SistemasIntegracaoRow
                                    key={sistema.id}
                                    content={sistema}
                                    isAtivo={true}
                                    handleClickEditar={handleClickEditar}
                                />
                            ))}
                            {sistemasAtivos.data.hasMaxAtivos && <SistemasIntegracaoAtivosAviso />}
                        </>
                    ) : (
                        <span className='integracao-empty-label is-italic'>Não há sistemas ativos.</span>
                    )}
                </>
            )}
        </div>
    )
}
