import { promiseReducer, PromiseRecord } from 'redux/middleware/PromiseAction'
import Info from 'api/Info'

export const GET_VERSAO = 'rni/info/GET_VERSAO'

export interface InfoState
    extends Readonly<{
        versao: PromiseRecord<string>
    }> {}

export const InfoInitialState: InfoState = {
    versao: {},
}

export default function reducer(state: InfoState = InfoInitialState, action): InfoState {
    if (action.type === GET_VERSAO) {
        return { ...state, versao: promiseReducer(state.versao, action) }
    } else {
        return state
    }
}

export const getVersao = () => ({
    type: GET_VERSAO,
    promise: Info.getVersao(),
})
