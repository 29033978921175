/* global google */
import * as React from 'react'
import {
    GoogleMap,
    withGoogleMap,
    GoogleMapProps,
    WithGoogleMapProps,
    withScriptjs,
    WithScriptjsProps,
} from 'react-google-maps'
import SearchBox, { SearchBoxProps } from 'react-google-maps/lib/components/places/SearchBox'
import { LoadingContainer } from '../layout/LoadingContainer'

interface Props {}

interface State {
    center: google.maps.LatLng
    bounds: google.maps.LatLngBounds
    zoom: number
}

export class Map extends React.Component<Props, State> {
    private map: google.maps.Map
    private searchBox: google.maps.places.SearchBox

    constructor(props) {
        super(props)
        this.state = {
            bounds: null,
            center: new google.maps.LatLng(-15.8883351, -57.4864992),
            zoom: 4,
        }
    }

    handleMapMounted = map => {
        this.map = map
    }

    handleSearchBoxMounted = searchBox => {
        this.searchBox = searchBox
    }

    handleBoundsChanged = () => {
        this.setState({
            bounds: this.map.getBounds(),
            center: this.map.getCenter() || new google.maps.LatLng(-15.8883351, -57.4864992),
        })
    }

    handleZoomChanged = () => {
        this.setState({ zoom: this.map.getZoom() })
    }

    handlePlacesChanged = () => {
        const places = this.searchBox.getPlaces()
        if (places.length === 0) {
            return
        }

        const place = places[0]
        const mapCenter = place.geometry.location
        const bounds = new google.maps.LatLngBounds()
        bounds.union(place.geometry.viewport)
        this.map.fitBounds(bounds)
        this.setState({ center: mapCenter })
    }

    render() {
        return (
            <GMap
                center={this.state.center}
                bounds={this.state.bounds}
                zoom={this.state.zoom}
                containerElement={<div style={{ height: '600px' }} />}
                mapElement={<div style={{ height: '100%' }} />}
                onMapMounted={this.handleMapMounted}
                onSearchBoxMounted={this.handleSearchBoxMounted}
                onPlacesChanged={this.handlePlacesChanged}
                onBoundsChanged={this.handleBoundsChanged}
                onZoomChanged={this.handleZoomChanged}
                loadingElement={<LoadingContainer isFetching={true} />}
                googleMapURL={
                    'https://maps.googleapis.com/maps/api/js?v=weekly&language=pt-BR&libraries=places,visualization&key=AIzaSyCajmkCKQ-thZFp9HAfQT0-g2ytOpLEbpo'
                }
            >
                {this.props.children}
            </GMap>
        )
    }
}

type GMapProps = GoogleMapProps &
    SearchBoxProps &
    React.Props<any> &
    WithGoogleMapProps & {
        onMapMounted(ref: any): void
        onSearchBoxMounted(ref: any): void
    } & WithScriptjsProps

const GMap: React.ComponentClass<GMapProps & WithGoogleMapProps> = withScriptjs(
    withGoogleMap((props: GMapProps) => (
        <GoogleMap
            defaultOptions={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.TERRAIN],
                },
            }}
            ref={props.onMapMounted}
            zoom={props.zoom}
            center={props.center}
            onIdle={props.onBoundsChanged}
            onZoomChanged={props.onZoomChanged}
        >
            <div>
                <SearchBox
                    ref={props.onSearchBoxMounted}
                    bounds={props.bounds}
                    onPlacesChanged={props.onPlacesChanged}
                    controlPosition={google.maps.ControlPosition.TOP_LEFT}
                >
                    <input type='text' placeholder='Pesquisar por locais...' style={INPUT_STYLE} />
                </SearchBox>
                {props.children}
            </div>
        </GoogleMap>
    ))
)

const INPUT_STYLE: React.CSSProperties = {
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    border: '1px solid transparent',
    width: '320px',
    height: '29px',
    marginTop: '10px',
    padding: '0 12px',
    borderRadius: '1px',
    boxShadow: '0 0 5px -1px rgba(0, 0, 0, 0.3)',
    fontSize: '11px',
    outline: 'none',
    textOverflow: 'ellipses',
}
