import React from 'react'
import * as C from 'libs/components'
import * as FC from 'view/home/novoProcedimento/ficha/componentes'
import { StentCoronariaEssencialVisualizacaoDto } from 'backend'
import { dadosComplementaresByEnum } from 'api/Visualizacao'
import { isNullOrUndefined } from 'util'

interface IsquemiaImagemCardProps {
    dadosComplementares: StentCoronariaEssencialVisualizacaoDto
}

export class IsquemiaImagemCard extends React.Component<IsquemiaImagemCardProps> {
    render() {
        const data = this.props.dadosComplementares
        const formatAvaliadosNao = data.testesAvaliadosNao.join(', ')
        const formatNaoAvaliados = data.testesNaoAvaliados.join(', ')
        const percentilEscoreCalcio = data.percentilEscoreCalcio + '%'
        const labelTestesRealizados = (data.angioCoronaria === 'SIM' ||
            data.cintilografia === 'SIM' ||
            data.ergometrico === 'SIM' ||
            data.estresse === 'SIM') && (
            <C.Columns auto>
                <C.Label>Testes realizados</C.Label>
            </C.Columns>
        )

        return (
            <div>
                {labelTestesRealizados}
                {data.angioCoronaria === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Angio TC de coronária</C.Label>
                            <C.Columns>
                                <C.Column size='3'>
                                    <C.TextLabel
                                        title='Tipo'
                                        value={dadosComplementaresByEnum[data.angioCoronariaTipo]}
                                    />
                                </C.Column>
                                <C.Column size='3'>
                                    <C.TextLabel
                                        title='Escore de cálcio'
                                        value={dadosComplementaresByEnum[data.escoreCalcio]}
                                    />
                                </C.Column>

                                {data.escoreCalcio === 'SIM' && (
                                    <>
                                        <C.Column size='3'>
                                            <C.TextLabel title='Valor' value={data.valorEscoreCalcio} />
                                        </C.Column>

                                        {!isNullOrUndefined(data.percentilEscoreCalcio) ? (
                                            <C.Column size='3'>
                                                <C.TextLabel title='Percentil' value={percentilEscoreCalcio} />
                                            </C.Column>
                                        ) : (
                                            <C.Column size='3'>
                                                <C.TextLabel title='Percentil' value={data.percentilEscoreCalcio} />
                                            </C.Column>
                                        )}
                                    </>
                                )}
                            </C.Columns>
                        </li>
                    </ul>
                )}
                {data.cintilografia === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Cintilografia miocárdica</C.Label>
                            <C.Columns auto>
                                <C.TextLabel
                                    title='Avaliação'
                                    value={dadosComplementaresByEnum[data.cintilografiaAvaliacao]}
                                />

                                <C.TextLabel title='Risco' value={dadosComplementaresByEnum[data.cintilografiaRisco]} />
                            </C.Columns>
                        </li>
                    </ul>
                )}
                {data.estresse === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Eco de estresse</C.Label>
                            <C.Columns auto>
                                <C.TextLabel
                                    title='Avaliação'
                                    value={dadosComplementaresByEnum[data.estresseAvaliacao]}
                                />
                                <C.TextLabel title='Risco' value={dadosComplementaresByEnum[data.estresseRisco]} />
                            </C.Columns>
                        </li>
                    </ul>
                )}
                {data.ergometrico === 'SIM' && (
                    <ul className='with-border-left'>
                        <li>
                            <C.Label className='group-title'>Teste ergométrico</C.Label>
                            <C.Columns auto>
                                <C.TextLabel
                                    title='Avaliação'
                                    value={dadosComplementaresByEnum[data.ergometricoAvaliacao]}
                                />

                                <C.TextLabel title='Risco' value={dadosComplementaresByEnum[data.ergometricoRisco]} />
                            </C.Columns>
                        </li>
                    </ul>
                )}
                <FC.ComposedLabelVisualizacao title='Testes não realizados'>
                    {formatAvaliadosNao}
                </FC.ComposedLabelVisualizacao>
                {data.testesNaoAvaliados.length > 0 && (
                    <C.Columns auto>
                        <C.ComposedLabel
                            title='Testes não avaliados'
                            placeholder={
                                'Os testes ' +
                                formatNaoAvaliados +
                                ' não foram avaliados no procedimento ou não foram informados no registro.'
                            }
                        ></C.ComposedLabel>
                    </C.Columns>
                )}
            </div>
        )
    }
}
