import { LoadingStateContainer } from 'libs/components'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { clearEdicaoProcedimentoData, setEdicaoProcedimentoData } from 'redux/modules/edicaoProcedimento'
import { actionCreators as pacienteActionCreators } from 'redux/modules/paciente'
import { clearFindResult } from 'redux/modules/profissional'
import NovoProcedimentoContainer from '../novoProcedimento/NovoProcedimentoContainer'
import { usePreFichaData } from './usePreFichaData'
import * as novoProcedimento from 'redux/modules/novoProcedimento'

export const EdicaoPreFicha = (props: RouteComponentProps<{ idFicha: string }>) => {
    const { goBack } = useHistory()
    const { data, requestState } = usePreFichaData(props.match.params.idFicha)
    const dispatch = useDispatch()
    useEffect(() => {
        if (requestState === 'success') {
            dispatch(setEdicaoProcedimentoData(data))
            dispatch(pacienteActionCreators.findSuccess(data.paciente))
        } else if (requestState === 'failure') {
            goBack()
            dispatch(novoProcedimento.showNotFoundModalError())
        }
        return () => {
            dispatch(clearEdicaoProcedimentoData())
            dispatch(clearFindResult())
            dispatch(pacienteActionCreators.clearFindResult(''))
        }
    }, [requestState, dispatch, data, goBack])

    return (
        <LoadingStateContainer readyState={requestState}>
            <NovoProcedimentoContainer isEdicao {...props} />
        </LoadingStateContainer>
    )
}
