import React, { useEffect, useState } from 'react'
import * as C from 'libs/components'
import { Icon } from '../../../libs/components'
import { TodosTopicos, TopicoManual } from './TopicoManual'
import { PerfilEnum } from '../../../api/Perfis'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

export interface ManualSideBarProps {
    homeOrPublic: string
    perfil: string
    history: any
    match: any
    paiUrl: string
}

export function ManualSideBar(props: ManualSideBarProps) {
    const topicos = () => {
        return TodosTopicos.filter(p => p.perfil.includes(PerfilEnum[props.perfil]))
    }
    const [topicoSelecionado, setTopicoSelecionado] = useState(undefined)
    const handleSetTopicoSelecionado = (topico: TopicoManual) => {
        let nextTopico = topicoSelecionado === topico ? undefined : topico
        setTopicoSelecionado(nextTopico)
    }

    useEffect(() => {
        if (props.paiUrl === 'faq' || props.paiUrl === 'inicio') {
            setTopicoSelecionado(undefined)
        }
    }, [props.paiUrl])

    const onFaqClick = () => {
        setTopicoSelecionado(undefined)
        props.history.push({ pathname: `${props.homeOrPublic}/manualUsuario/faq` })
    }

    const faqClassName = classnames('manual-side-item', {
        active: props.match.params.idPai === 'faq' && topicoSelecionado === undefined,
    })

    return (
        <div className='manual-side-bar'>
            {topicos().map((topico, index) => {
                return (
                    <ManualItem
                        key={index}
                        topico={topico}
                        onClick={() => handleSetTopicoSelecionado(topico)}
                        visibleItem={topicoSelecionado === topico}
                        homeOrPublic={props.homeOrPublic}
                    />
                )
            })}
            <ul className='manual-side-columns'>
                <C.Columns className={'manual-side-column-item'} onClick={onFaqClick}>
                    <C.Column size='10'>
                        <div className={faqClassName}>
                            <p>FAQ</p>
                        </div>
                    </C.Column>
                </C.Columns>
            </ul>
        </div>
    )
}

export interface ManualItemProps {
    topico: TopicoManual
    onClick(topico: TopicoManual): void
    visibleItem: boolean
    homeOrPublic: string
}

export const ManualItem = (props: ManualItemProps) => {
    const className = classnames('manual-side-item', {
        active: props.visibleItem,
    })

    let iconDesc
    if (props.visibleItem) {
        iconDesc = 'arrowTop'
    } else {
        iconDesc = 'arrowBottom'
    }
    return (
        <ul className='manual-side-columns'>
            <C.Columns onClick={() => props.onClick(props.topico)} className={'manual-side-column-item'}>
                <C.Column size='10'>
                    <div className={className}>
                        <p>{props.topico.titulo}</p>
                    </div>
                </C.Column>
                <C.Column size='2'>
                    <Icon icon={iconDesc} size='is-20px' />
                </C.Column>
            </C.Columns>
            {props.visibleItem && (
                <ManualSubItem
                    paiPath={props.topico.path}
                    subItems={props.topico.subTopicos}
                    homeOrPublic={props.homeOrPublic}
                />
            )}
        </ul>
    )
}

export interface SubProps {
    subItems: TopicoManual[]
    paiPath: string
    homeOrPublic: string
}

export const ManualSubItem = (props: SubProps) => {
    return (
        <ul>
            {props.subItems &&
                props.subItems.map((item, index) => (
                    <div className={'manual-side-subitem'} key={index}>
                        <li>
                            <Link
                                to={`${props.homeOrPublic}/manualUsuario/${props.paiPath}/${item.path}${item.btnPathPadrao}`}
                            >
                                {' '}
                                {item.titulo}
                            </Link>
                        </li>
                    </div>
                ))}
        </ul>
    )
}
