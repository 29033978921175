import * as React from 'react'
import { pluralize } from 'libs/components/label/PluralLabel'

interface Props {
    meses: number
    abbrev?: boolean
}

export class TempoLabel extends React.Component<Props> {
    render() {
        const anos = Math.floor(this.props.meses / 12)
        const meses = Math.floor(this.props.meses % 12)

        const anoStr = anos > 0 ? pluralize('ano', anos, true) : null
        const mesStr = meses > 0 ? pluralize('mês', meses, true) : null
        const str = [anoStr, mesStr].filter(str => !!str).join(' e ')

        if (this.props.abbrev) {
            if (anos > 0 && meses === 0) {
                return <span title={str}>{anos}a</span>
            } else if (anos > 0) {
                return (
                    <span title={str}>
                        {anos}a {meses}m
                    </span>
                )
            } else {
                return <span title={str}>{meses}m</span>
            }
        }

        if (anos === 0 && meses === 0) {
            return <span>0 meses</span>
        }

        return <span>{str}</span>
    }
}
