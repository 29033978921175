import { default as Acessar } from './Acessar'
import { default as Adicionar } from './Adicionar'
import { default as AguardandoSelected } from './AguardandoSelected'
import { default as Ajuda24 } from './Ajuda24'
import { default as Alarme } from './Alarme'
import { default as Alerta } from './Alerta'
import { default as AlertasAumentoregistros } from './AlertasAumentoregistros'
import { default as AlertasMotivorevisao } from './AlertasMotivorevisao'
import { default as AlertasOpcaovisualizacao } from './AlertasOpcaovisualizacao'
import { default as Alterarperfil } from './Alterarperfil'
import { default as Alterarsenha } from './Alterarsenha'
import { default as Ampulheta } from './Ampulheta'
import { default as Antibiotico } from './Antibiotico'
import { default as Aprovar } from './Aprovar'
import { default as ArrowBottom } from './ArrowBottom'
import { default as ArrowLeft } from './ArrowLeft'
import { default as ArrowRight } from './ArrowRight'
import { default as ArrowTop } from './ArrowTop'
import { default as Barcode } from './Barcode'
import { default as CheckboxCheck } from './CheckboxCheck'
import { default as Colunas } from './Colunas'
import { default as Configuracao } from './Configuracao'
import { default as ConfirmacaoExpirado } from './ConfirmacaoExpirado'
import { default as Contato } from './Contato'
import { default as ControleRecebimento } from './ControleRecebimento'
import { default as CopyOutline } from './CopyOutline'
import { default as Dadosclinicos } from './Dadosclinicos'
import { default as Dadosfinanceiros } from './Dadosfinanceiros'
import { default as Dadospessoais } from './Dadospessoais'
import { default as Dadosproduto } from './Dadosproduto'
import { default as Data } from './Data'
import { default as Delete } from './Delete'
import { default as Desativar } from './Desativar'
import { default as Desbloquear } from './Desbloquear'
import { default as Detalhes } from './Detalhes'
import { default as DistArtro } from './DistArtro'
import { default as DistCardio } from './DistCardio'
import { default as DistProdutos } from './DistProdutos'
import { default as Download } from './Download'
import { default as Editar } from './Editar'
import { default as EmailConfirmado } from './EmailConfirmado'
import { default as EmailEnviado } from './EmailEnviado'
import { default as Endereco } from './Endereco'
import { default as Entidades } from './Entidades'
import { default as ErroExclamacao } from './ErroExclamacao'
import { default as ErroLink } from './ErroLink'
import { default as Estrela } from './Estrela'
import { default as Excluir } from './Excluir'
import { default as Exportar } from './Exportar'
import { default as Feminino } from './Feminino'
import { default as Filtro } from './Filtro'
import { default as FiltroProcedimento } from './FiltroProcedimento'
import { default as Financeiro } from './Financeiro'
import { default as Forbidden } from './Forbidden'
import { default as Gerar } from './Gerar'
import { default as Grafico } from './Grafico'
import { default as Help } from './Help'
import { default as Historico } from './Historico'
import { default as Historicoalteracoes } from './Historicoalteracoes'
import { default as Home } from './Home'
import { default as Hospital } from './Hospital'
import { default as Identificacao } from './Identificacao'
import { default as Imprimir } from './Imprimir'
import { default as Info } from './Info'
import { default as InfoProduto } from './InfoProduto'
import { default as Invalido } from './Invalido'
import { default as Key } from './Key'
import { default as Link } from './Link'
import { default as Login } from './Login'
import { default as Masculino } from './Masculino'
import { default as ModalAtencao } from './ModalAtencao'
import { default as ModalErro } from './ModalErro'
import { default as ModalSucesso } from './ModalSucesso'
import { default as MotivoProcedimento } from './MotivoProcedimento'
import { default as MultProcedimentos } from './MultProcedimentos'
import { default as NomeModelo } from './NomeModelo'
import { default as NotificacaoAlerta } from './NotificacaoAlerta'
import { default as NotificacaoAlertaComBorda } from './NotificacaoAlertaComBorda'
import { default as NotificacaoErro } from './NotificacaoErro'
import { default as NotificacaoInformativo } from './NotificacaoInformativo'
import { default as NotificacaoSucesso } from './NotificacaoSucesso'
import { default as Obrigatorio } from './Obrigatorio'
import { default as Opcaovisualizacao } from './Opcaovisualizacao'
import { default as OrdenadorCrescente } from './OrdenadorCrescente'
import { default as OrdenadorDecrescente } from './OrdenadorDecrescente'
import { default as Pesquisar } from './Pesquisar'
import { default as Preencher } from './Preencher'
import { default as Produtofinanceiros } from './Produtofinanceiros'
import { default as Profissionaishospital } from './Profissionaishospital'
import { default as RastProdutos } from './RastProdutos'
import { default as Reativar } from './Reativar'
import { default as Recusar } from './Recusar'
import { default as RelacaoEmpresas } from './RelacaoEmpresas'
import { default as Remover } from './Remover'
import { default as RemoverVinculo } from './RemoverVinculo'
import { default as ReportRelacaoEmpresas } from './ReportRelacaoEmpresas'
import { default as Retornar } from './Retornar'
import { default as Sair } from './Sair'
import { default as Sobre } from './Sobre'
import { default as Stent } from './Stent'
import { default as Tabela } from './Tabela'
import { default as TiposRevisao } from './TiposRevisao'
import { default as Trocar } from './Trocar'
import { default as Upload } from './Upload'
import { default as UsoSistema } from './UsoSistema'
import { default as ListaPacientes } from './ListaPacientes'
import { default as Vermais } from './Vermais'
import { default as Vinculoinativo } from './Vinculoinativo'
import { default as Vinculospendentes } from './Vinculospendentes'
import { default as Vinculosrecusados } from './Vinculosrecusados'
import { default as Voltar } from './Voltar'

export type Icons =
    | 'acessar'
    | 'adicionar'
    | 'aguardandoSelected'
    | 'ajuda24'
    | 'alarme'
    | 'alerta'
    | 'alertasAumentoregistros'
    | 'alertasMotivorevisao'
    | 'alertasOpcaovisualizacao'
    | 'alterarperfil'
    | 'alterarsenha'
    | 'ampulheta'
    | 'antibiotico'
    | 'aprovar'
    | 'arrowBottom'
    | 'arrowLeft'
    | 'arrowRight'
    | 'arrowTop'
    | 'barcode'
    | 'checkboxCheck'
    | 'colunas'
    | 'configuracao'
    | 'confirmacaoExpirado'
    | 'contato'
    | 'controleRecebimento'
    | 'copyOutline'
    | 'dadosclinicos'
    | 'dadosfinanceiros'
    | 'dadospessoais'
    | 'dadosproduto'
    | 'data'
    | 'delete'
    | 'desativar'
    | 'desbloquear'
    | 'detalhes'
    | 'distArtro'
    | 'distCardio'
    | 'distProdutos'
    | 'download'
    | 'editar'
    | 'emailConfirmado'
    | 'emailEnviado'
    | 'endereco'
    | 'entidades'
    | 'erroExclamacao'
    | 'erroLink'
    | 'estrela'
    | 'excluir'
    | 'exportar'
    | 'feminino'
    | 'filtro'
    | 'filtroProcedimento'
    | 'financeiro'
    | 'forbidden'
    | 'gerar'
    | 'grafico'
    | 'help'
    | 'historico'
    | 'historicoalteracoes'
    | 'home'
    | 'hospital'
    | 'identificacao'
    | 'imprimir'
    | 'info'
    | 'infoProduto'
    | 'invalido'
    | 'key'
    | 'link'
    | 'login'
    | 'masculino'
    | 'modalAtencao'
    | 'modalErro'
    | 'modalSucesso'
    | 'motivoProcedimento'
    | 'multProcedimentos'
    | 'nomeModelo'
    | 'notificacaoAlerta'
    | 'notificacaoAlertaComBorda'
    | 'notificacaoErro'
    | 'notificacaoInformativo'
    | 'notificacaoSucesso'
    | 'obrigatorio'
    | 'opcaovisualizacao'
    | 'ordenadorCrescente'
    | 'ordenadorDecrescente'
    | 'pesquisar'
    | 'preencher'
    | 'produtofinanceiros'
    | 'profissionaishospital'
    | 'rastProdutos'
    | 'reativar'
    | 'recusar'
    | 'relacaoEmpresas'
    | 'remover'
    | 'removerVinculo'
    | 'reportRelacaoEmpresas'
    | 'retornar'
    | 'sair'
    | 'sobre'
    | 'stent'
    | 'tabela'
    | 'tiposRevisao'
    | 'trocar'
    | 'upload'
    | 'usoSistema'
    | 'listaPacientes'
    | 'vermais'
    | 'vinculoinativo'
    | 'vinculospendentes'
    | 'vinculosrecusados'
    | 'voltar'

export const IconMap: {
    [key in Icons]: React.ComponentType<React.SVGProps<SVGSVGElement>>
} = {
    'acessar': Acessar,
    'adicionar': Adicionar,
    'aguardandoSelected': AguardandoSelected,
    'ajuda24': Ajuda24,
    'alarme': Alarme,
    'alerta': Alerta,
    'alertasAumentoregistros': AlertasAumentoregistros,
    'alertasMotivorevisao': AlertasMotivorevisao,
    'alertasOpcaovisualizacao': AlertasOpcaovisualizacao,
    'alterarperfil': Alterarperfil,
    'alterarsenha': Alterarsenha,
    'ampulheta': Ampulheta,
    'antibiotico': Antibiotico,
    'aprovar': Aprovar,
    'arrowBottom': ArrowBottom,
    'arrowLeft': ArrowLeft,
    'arrowRight': ArrowRight,
    'arrowTop': ArrowTop,
    'barcode': Barcode,
    'checkboxCheck': CheckboxCheck,
    'colunas': Colunas,
    'configuracao': Configuracao,
    'confirmacaoExpirado': ConfirmacaoExpirado,
    'contato': Contato,
    'controleRecebimento': ControleRecebimento,
    'copyOutline': CopyOutline,
    'dadosclinicos': Dadosclinicos,
    'dadosfinanceiros': Dadosfinanceiros,
    'dadospessoais': Dadospessoais,
    'dadosproduto': Dadosproduto,
    'data': Data,
    'delete': Delete,
    'desativar': Desativar,
    'desbloquear': Desbloquear,
    'detalhes': Detalhes,
    'distArtro': DistArtro,
    'distCardio': DistCardio,
    'distProdutos': DistProdutos,
    'download': Download,
    'editar': Editar,
    'emailConfirmado': EmailConfirmado,
    'emailEnviado': EmailEnviado,
    'endereco': Endereco,
    'entidades': Entidades,
    'erroExclamacao': ErroExclamacao,
    'erroLink': ErroLink,
    'estrela': Estrela,
    'excluir': Excluir,
    'exportar': Exportar,
    'feminino': Feminino,
    'filtro': Filtro,
    'filtroProcedimento': FiltroProcedimento,
    'financeiro': Financeiro,
    'forbidden': Forbidden,
    'gerar': Gerar,
    'grafico': Grafico,
    'help': Help,
    'historico': Historico,
    'historicoalteracoes': Historicoalteracoes,
    'home': Home,
    'hospital': Hospital,
    'identificacao': Identificacao,
    'imprimir': Imprimir,
    'info': Info,
    'infoProduto': InfoProduto,
    'invalido': Invalido,
    'key': Key,
    'link': Link,
    'login': Login,
    'masculino': Masculino,
    'modalAtencao': ModalAtencao,
    'modalErro': ModalErro,
    'modalSucesso': ModalSucesso,
    'motivoProcedimento': MotivoProcedimento,
    'multProcedimentos': MultProcedimentos,
    'nomeModelo': NomeModelo,
    'notificacaoAlerta': NotificacaoAlerta,
    'notificacaoAlertaComBorda': NotificacaoAlertaComBorda,
    'notificacaoErro': NotificacaoErro,
    'notificacaoInformativo': NotificacaoInformativo,
    'notificacaoSucesso': NotificacaoSucesso,
    'obrigatorio': Obrigatorio,
    'opcaovisualizacao': Opcaovisualizacao,
    'ordenadorCrescente': OrdenadorCrescente,
    'ordenadorDecrescente': OrdenadorDecrescente,
    'pesquisar': Pesquisar,
    'preencher': Preencher,
    'produtofinanceiros': Produtofinanceiros,
    'profissionaishospital': Profissionaishospital,
    'rastProdutos': RastProdutos,
    'reativar': Reativar,
    'recusar': Recusar,
    'relacaoEmpresas': RelacaoEmpresas,
    'remover': Remover,
    'removerVinculo': RemoverVinculo,
    'reportRelacaoEmpresas': ReportRelacaoEmpresas,
    'retornar': Retornar,
    'sair': Sair,
    'sobre': Sobre,
    'stent': Stent,
    'tabela': Tabela,
    'tiposRevisao': TiposRevisao,
    'trocar': Trocar,
    'upload': Upload,
    'usoSistema': UsoSistema,
    'listaPacientes': ListaPacientes,
    'vermais': Vermais,
    'vinculoinativo': Vinculoinativo,
    'vinculospendentes': Vinculospendentes,
    'vinculosrecusados': Vinculosrecusados,
    'voltar': Voltar,
}
