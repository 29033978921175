/* tslint:disable */
import React from 'react'

const SvgContato = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
        <path d='M3.734 6.024v4.693c0 .04.035.075.08.075h8.372c.045 0 .08-.034.08-.075V6.392L8.618 8.974a.628.628 0 01-.722 0l-4.113-2.91a.622.622 0 01-.05-.04zm.946-.816L8.257 7.74l3.577-2.532H4.68zM12.186 12H3.814C3.09 12 2.5 11.426 2.5 10.717V5.283C2.5 4.573 3.09 4 3.814 4h8.372c.725 0 1.314.574 1.314 1.283v5.434c0 .71-.589 1.283-1.314 1.283z' />
    </svg>
)

export default SvgContato
