import * as React from 'react'
import * as C from 'libs/components'
import { State } from 'redux/modules'
import {
    DadosUsoCard,
    PrimariaRevisaoCard,
    PeriodoProcedimentoChartCard,
    SexoCard,
    FaixaEtariaChartCard,
} from 'view/componentes/reports/cards'
import { useSelector } from 'react-redux'
import { ProcedimentoReportState } from 'redux/modules/report/procedimento'

export interface Props {
    dadosUsoTitle: string
}

export const ReportDashboardView = (props: Props) => {
    const procedimento = useSelector<State, ProcedimentoReportState>(state => state.report.procedimento)
    const distClassificacao = procedimento.distClassificacao
    const distClassificacaoPeriodo = procedimento.distClassificacaoPeriodo
    const distClassificacaoSexo = procedimento.distClassificacaoSexo
    const distClassificacaoIdade = procedimento.distClassificacaoIdade
    const result = distClassificacao.result
    const showPrimariaRevisao =
        result &&
        (Object.keys(result.classificacoes).includes('PRIMARIA') ||
            Object.keys(result.classificacoes).includes('REVISAO'))

    return (
        <div>
            <C.Columns auto>
                <div className='flex-7'>
                    <DadosUsoCard
                        distClassificacao={distClassificacao}
                        title={props.dadosUsoTitle}
                        showPrimariaRevisao={showPrimariaRevisao}
                    />
                </div>
                {showPrimariaRevisao && (
                    <div className='flex-5'>
                        <PrimariaRevisaoCard distClassificacao={distClassificacao} />
                    </div>
                )}
            </C.Columns>

            <C.Columns auto>
                <PeriodoProcedimentoChartCard dist={distClassificacaoPeriodo} />
            </C.Columns>

            <C.Columns auto>
                <FaixaEtariaChartCard dist={distClassificacaoIdade} />
            </C.Columns>

            <C.Columns auto>
                <SexoCard dist={distClassificacaoSexo} showPrimariaRevisaoRatio={showPrimariaRevisao} />
            </C.Columns>
        </div>
    )
}
