import React from 'react'
import * as C from 'libs/components'
import * as FichaComponents from 'view/home/novoProcedimento/ficha/componentes'

export class LesaoEnxerto extends React.Component {
    render() {
        return (
            <div>
                <C.ControlField label='Enxerto' name='enxertoTipo' required horizontalFlow>
                    <C.RadioField name='enxertoTipo' label='Aorto-coronário' value='AORTO_CORONARIO' />
                    <C.RadioField name='enxertoTipo' label='In situ' value='IN_SITU' />
                    <C.ClearRadioFieldButton name='enxertoTipo' />
                </C.ControlField>

                <C.InputPanel size='medium'>
                    {!this.props.aorto && !this.props.insitu && (
                        <C.Conteudo textCentered>
                            <C.TextLabel name='iam' placeholder='Informe o tipo de enxerto.' />
                        </C.Conteudo>
                    )}
                    {this.props.aorto && <C.RadioField name='enxertoTipoAorto' label='Venoso' value='VENOSO' />}
                    {this.props.aorto && <C.RadioField name='enxertoTipoAorto' label='Arterial' value='ARTERIAL' />}
                    {this.props.insitu && (
                        <C.RadioField
                            name='enxertoTipoInsitu'
                            label='Mamária interna esquerda'
                            value='MAMARIA_ESQUERDA'
                        />
                    )}
                    {this.props.insitu && (
                        <C.RadioField
                            name='enxertoTipoInsitu'
                            label='Mamária interna direita'
                            value='MAMARIA_DIREITA'
                        />
                    )}
                </C.InputPanel>

                <C.Columns>
                    <C.Column size='6'>
                        <C.ControlField label='Coronária de destino' name='coronariaDestino' required>
                            <FichaComponents.CoronariaDestinoField name='coronariaDestino' />
                        </C.ControlField>
                    </C.Column>
                </C.Columns>
                <C.Linha />
                <FichaComponents.OpcionaisLesaoCardio
                    name={'opcionaisEnxerto'}
                    hasReestenose={this.props.hasReestenose}
                    hasTrombose={this.props.hasTrombose}
                />
            </div>
        )
    }
}
