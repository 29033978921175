import * as React from 'react'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import TipoRevisaoDashboardView from 'view/componentes/reports/revisao/TipoRevisaoDashboardView'

export const AnvisaTipoRevisaoDashboardView = () => {
    return (
        <>
            <LocalHeaderContainer />
            <TipoRevisaoDashboardView />
        </>
    )
}
