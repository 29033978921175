
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.485,"time":29099.999999999996,"words":97}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`O registro ANVISA é o ato legal que reconhece a adequação de um produto à legislação sanitária, e sua concessão é dada pela ANVISA. É um controle feito antes da comercialização, sendo utilizado no caso de produtos que possam apresentar eventuais riscos à saúde. O registro deve ser informado no RNI nos procedimentos de artroplastia de joelho e quadril e nos implantes de stent coronário.`}</p>
    <p>{`Caso não consiga identificar o registro da ANVISA na etiqueta:`}</p>
    <ul>
      <li parentName="ul">{`Informe o CNPJ do Fabricante/Importador se houver`}</li>
      <li parentName="ul">{`Informe o Nome do Fabricante/Importador`}</li>
      <li parentName="ul">{`Insira uma foto da etiqueta do produto`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

