/* tslint:disable */
import React from 'react'

const SvgAmpulheta = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
        <path d='M11.5 2v4.484L9.636 8.033 11.5 9.509V14h-7V9.51l1.902-1.513L4.5 6.492V2h7zM8.635 8.526H7.358L5.503 9.999l-.001 1.29h4.995v-1.29L8.635 8.527zm1.862-5.515H5.503V6l1.863 1.474h1.367l1.764-1.467V3.011z' />
    </svg>
)

export default SvgAmpulheta
