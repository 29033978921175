import * as React from 'react'
import * as C from 'libs/components'

interface CardiacaProps {
    disabled?: boolean
}

export class CardiacaCard extends React.Component<CardiacaProps> {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Cardíaca'
                name='cardiaca'
                buttonSet={<C.SimNaoNAvaliadoRadioField name='cardiaca' disabled={this.props.disabled} />}
            >
                <C.Conteudo>
                    <C.Columns>
                        <C.Column>
                            <C.ControlField label={'Gravidade'} name='cardiacaSeveridade' />
                        </C.Column>
                    </C.Columns>
                    <C.Columns>
                        <C.Column size='5' verticalFlow>
                            <C.ChecklistItemField
                                name='cardiacaSeveridade'
                                optionValue='CHOQUE_CARDIOGENICO'
                                label='Choque cardiogênico'
                            />
                            <C.ChecklistItemField
                                name='cardiacaSeveridade'
                                optionValue='CIRURGIA_DE_EMERGENCIA'
                                label='Cirurgia de emergência'
                            />
                            <C.ChecklistItemField name='cardiacaSeveridade' optionValue='ICC' label='ICC' />
                            <C.ChecklistItemField
                                name='cardiacaSeveridade'
                                optionValue='IAM_POS_PROCEDIMENTO'
                                label='IAM pós procedimento'
                            />
                        </C.Column>
                        <C.Column verticalFlow>
                            <C.ChecklistItemField
                                name='cardiacaSeveridade'
                                optionValue='PERFURACAO_CORONARIA'
                                label='Perfuração coronária'
                            />
                            <C.ChecklistItemField
                                name='cardiacaSeveridade'
                                optionValue='TAMPONAMENTO'
                                label='Tamponamento'
                            />
                            <C.ChecklistItemField
                                name='cardiacaSeveridade'
                                optionValue='TROMBOSE_DE_STENT'
                                label='Trombose de STENT'
                            />
                            <C.ChecklistItemField name='cardiacaSeveridade' optionValue='OUTRO' label='Outro' />
                        </C.Column>
                    </C.Columns>
                </C.Conteudo>
            </C.MultiButtonPanelField>
        )
    }
}
