import { State } from 'redux/modules'
import Report from 'api/Report'
import { MultiplosProcedimentosDto, DistribuicaoProcedimentoRow } from 'backend'
import { MultiplosProcedimentosFiltro } from 'model'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'

export const FIND_STATS = 'rni/report/multiplos/FIND_STATS'
export const FIND_MAP = 'rni/report/multiplos/FIND_MAP'

export interface MultiplosProcedimentosReportState {
    stats: PromiseRecord<MultiplosProcedimentosDto>
    map: PromiseRecord<DistribuicaoProcedimentoRow[]>
}

export const initialState: MultiplosProcedimentosReportState = {
    stats: {},
    map: {},
}

export const reducer = (
    state: MultiplosProcedimentosReportState = initialState,
    action
): MultiplosProcedimentosReportState => {
    switch (action.type) {
        case FIND_STATS:
            return {
                ...state,
                stats: promiseReducer(state.stats, action),
            }
        case FIND_MAP:
            return {
                ...state,
                map: promiseReducer(state.map, action),
            }
        default:
            return state
    }
}

export default reducer

export const findStats = filtro => (dispatch, getState) => {
    dispatch({
        type: FIND_STATS,
        promise: Report.findStatsMultiplosProcedimentos(filtro),
    })
}

export const findMap = (filtro: MultiplosProcedimentosFiltro) => (dispatch, getState) => {
    const state: State = getState()
    const reportFilter = state.report.filtro.filtro
    dispatch({
        type: FIND_MAP,
        promise: Report.findMapMultiplosProcedimentos({
            local: reportFilter.local,
            ...filtro,
        }),
    })
}
