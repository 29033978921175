import * as React from 'react'
import { TipoAlerta } from 'backend'
import { Icon } from 'libs/components'
import classnames from 'classnames'
import { Icons } from 'libs/components/presentational/elements/icons'

const iconesTiposAlerta: { [key in TipoAlerta]: Icons } = {
    AUMENTO_DE_REGISTROS: 'alertasAumentoregistros',
    ETIQUETA_SEM_REGISTRO: 'rastProdutos',
    MOTIVO_REVISAO: 'motivoProcedimento',
}

interface IconeTipoAlertaProps {
    tipoAlerta: TipoAlerta
    size?: 'normal' | 'large'
    transparency?: '' | '50'
}

export default class IconeTipoAlerta extends React.PureComponent<IconeTipoAlertaProps> {
    static defaultProps: Partial<IconeTipoAlertaProps> = {
        size: 'normal',
        transparency: '',
    }

    render() {
        const iconeClass = iconesTiposAlerta[this.props.tipoAlerta]
        const classes = classnames('icon-tipo-alerta', iconeClass, this.props.size, {
            'half-transparent': this.props.transparency === '50',
        })
        return <Icon className={classes} icon={iconeClass} size='small' />
    }
}
