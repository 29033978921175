import * as React from 'react'
import { TipoAlerta } from 'backend'
import { DropdownSelect } from 'view/home/anvisa/alertas/components/DropdownSelect'

const descricoesTipos: { [key in TipoAlerta]: string } = {
    MOTIVO_REVISAO: 'Motivo de revisão',
    ETIQUETA_SEM_REGISTRO: 'Etiqueta sem registro ANVISA',
    AUMENTO_DE_REGISTROS: 'Aumento de registros',
}

interface TipoAlertaSelectProps {
    tipoSelecionado?: TipoAlerta | ''
    onChange(tipoAlerta: TipoAlerta)
}

export default class TipoAlertaSelect extends React.PureComponent<TipoAlertaSelectProps> {
    render() {
        return (
            <DropdownSelect
                allOptionsLabel='Todos os alarmes'
                value={this.props.tipoSelecionado}
                valueDescriptionMap={descricoesTipos}
                onChange={this.props.onChange}
            />
        )
    }
}
