/* tslint:disable */
import React from 'react'

const SvgFiltro = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M20.38 11.39c.343 0 .62.275.62.615 0 .34-.277.615-.62.615h-2.103a2.564 2.564 0 01-2.492 1.937 2.564 2.564 0 01-2.491-1.937H3.619A.617.617 0 013 12.005c0-.34.277-.615.62-.615h9.674a2.564 2.564 0 012.491-1.937c1.18 0 2.208.799 2.492 1.937h2.104zm-4.59 2.35h-.01c.968 0 1.752-.779 1.752-1.74a1.74 1.74 0 00-1.084-1.61 1.76 1.76 0 00-1.91.383c-.5.5-.648 1.25-.373 1.9.274.65.916 1.072 1.625 1.068zm4.59 4.093c.343 0 .62.275.62.615 0 .34-.277.615-.62.615h-8.586A2.564 2.564 0 019.302 21a2.564 2.564 0 01-2.492-1.937H3.62a.617.617 0 01-.62-.615c0-.34.277-.615.62-.615H6.8a2.564 2.564 0 012.492-1.937c1.18 0 2.208.799 2.492 1.937h8.597zm-11.073 2.35h-.01c.466.003.914-.179 1.245-.506a1.735 1.735 0 00-.565-2.842 1.76 1.76 0 00-1.909.377 1.733 1.733 0 00-.38 1.897c.272.65.91 1.075 1.619 1.075zM3.619 6.168A.617.617 0 013 5.552c0-.34.277-.615.62-.615h3.19A2.564 2.564 0 019.302 3c1.18 0 2.208.799 2.492 1.937h8.587c.342 0 .619.275.619.615 0 .34-.277.615-.62.615h-8.586a2.564 2.564 0 01-2.492 1.937A2.564 2.564 0 016.81 6.167H3.62zm5.678-2.35c-.967 0-1.752.779-1.752 1.74 0 .962.785 1.741 1.752 1.741s1.752-.78 1.752-1.74c0-.962-.785-1.742-1.752-1.742z' />
    </svg>
)

export default SvgFiltro
