import * as React from 'react'
import { WrappedFieldInputProps } from 'redux-form'
import classnames from 'classnames'
import * as C from 'libs/components'
import * as Util from 'libs/components/Util'

export interface StyledRadioButtonProps extends Partial<WrappedFieldInputProps> {
    className?: string
    innerClassName?: string
    disabled?: boolean
    skin?: 'round-button' | 'square-button'
    size?: string
    useStyledButtonClassName?: boolean
}

export class StyledRadioButton extends React.Component<StyledRadioButtonProps, any> {
    public static defaultProps: Partial<StyledRadioButtonProps> = {
        skin: 'round-button',
        useStyledButtonClassName: true,
    }

    render() {
        const classes = classnames(
            'styled-radio-button',
            this.props.className,
            this.props.skin,
            Util.helpersClassnames(this.props),
            {
                'is-small': this.props.size && this.props.size === 'small',
                'is-medium': this.props.size && this.props.size === 'medium',
                'is-large': this.props.size && this.props.size === 'large',
            }
        )

        const innerClasses = classnames(
            { 'styled-button': this.props.useStyledButtonClassName },
            this.props.innerClassName
        )

        return (
            <label className={classes}>
                <C.Input
                    type='radio'
                    id='radio'
                    disabled={this.props.disabled}
                    name={this.props.name}
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    value={this.props.value}
                />
                <span className={innerClasses}>{this.props.children}</span>
            </label>
        )
    }
}
