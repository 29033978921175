
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.245,"time":14700,"words":49}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "gestor-de-segurança"
    }}>{`Gestor de Segurança`}</h3>
    <p>{`Perfil dos gestores de segurança dos hospitais, fazem o controle dos cadastros das suas equipes técnicas. Os gestores são cadastrados através do sistema de cadastro da ANVISA e poderão acessar o RNI com o mesmo usuário e senhas utilizados para acessar outros sistemas da ANVISA.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "gestor-de-segurança",
    level: 3,
    title: "Gestor de Segurança",
    children: [
        
      ]
  }
]

export const frontMatter = {}

