import * as React from 'react'
import * as C from 'libs/components'
import classnames from 'classnames'
import * as Util from 'libs/components/Util'
import { Route, Switch } from 'react-router-dom'
import { ManualHeader } from '../home/manualUsuario/ManualHeader'

interface Props extends Util.UtilProps {
    className?: string
}

export default class HomeConteudo extends React.Component<Props, any> {
    render() {
        const classes = classnames(
            'is-full-height is-vertical-flow',
            this.props.className,
            Util.helpersClassnames(this.props)
        )
        return (
            <div className={classes}>
                <Switch>
                    <Route component={ManualHeader} path={'/public/manualUsuario'} />
                    <Route component={C.IdentificacaoSistemaHeader} />
                </Switch>
                <div className='container home-conteudo is-vertical-flow is-full-height'>{this.props.children}</div>
            </div>
        )
    }
}
