import React from 'react'
import classnames from 'classnames'
import * as C from 'libs/components'
import logoRni from 'images/common/logo_rni.png'
import imgRecuperarSenha from 'images/login/recuperarsenha.png'
import form from 'libs/redux-form/form'
import { reduxForm } from 'redux-form/immutable'
import { Route } from 'react-router-dom'
import LoginValidator from 'view/login/LoginValidator'
import RecuperarSenhaValidator from 'view/login/RecuperarSenhaValidator'

class LoginFormCmp extends React.Component {
    render() {
        const { goProfissional, goRecuperarSenha, handleSubmit, submitting } = this.props

        const onLogarGestorHospitalar = () => {
            window.location.href = '/loginGestorHospitalar'
        }

        const onLogarFuncionarioAnvisa = () => {
            window.location.href = '/loginFuncionarioAnvisa'
        }

        const btnEntrarClasses = classnames('button btn-entrar is-medium has-shadow is-primary is-fullwidth', {
            'is-loading': submitting,
        })

        return (
            <div className='login'>
                <C.Columns>
                    <C.Column textCentered size='6' offset='3'>
                        <form onSubmit={handleSubmit}>
                            <div className='logo is-vertical-flow'>
                                <C.Image src={logoRni} />
                            </div>
                            <C.TextField name='username' placeholder='CPF' icon='login' />
                            <C.PasswordField name='password' placeholder='Senha' icon='desbloquear' />
                            <div className='texto-esqueceu-senha'>
                                Esqueceu a senha? <span onClick={goRecuperarSenha}>clique aqui</span>
                            </div>
                            <button
                                type='submit'
                                className={btnEntrarClasses}
                                disabled={submitting}
                                onClick={handleSubmit}
                            >
                                Entrar
                            </button>
                            <div className='texto-cadastre-se'>
                                <p onClick={goProfissional}>Cadastre-se aqui.</p>
                            </div>
                        </form>
                    </C.Column>
                </C.Columns>
                <div className='acesse-como'>
                    <div className='line'>
                        <span className='line-text'>OU ACESSE COMO</span>
                    </div>
                    <p className='btn-login-anvisa' onClick={onLogarFuncionarioAnvisa}>
                        COLABORADOR ANVISA
                    </p>
                    <p className='btn-login-anvisa' onClick={onLogarGestorHospitalar}>
                        GESTOR DE SEGURANÇA INSTITUCIONAL
                    </p>
                </div>
            </div>
        )
    }
}
const LoginForm = reduxForm({ form: 'login', validate: LoginValidator })(LoginFormCmp)

class RecuperarSenhaFormCmp extends React.Component {
    render() {
        const { goBack, handleSubmit, submitting } = this.props

        const btnSolicitarNovaSenha = classnames(
            'button btn-solicitar-nova-senha is-medium has-shadow is-primary is-fullwidth',
            {
                'is-loading': submitting,
            }
        )

        return (
            <div className='recuperar-senha'>
                <C.Columns>
                    <C.Column size='3'>
                        <C.IconButton icon='voltar' type='light' className='voltar' size='medium' onClick={goBack} />
                    </C.Column>
                    <C.Column textCentered size='6'>
                        <form onSubmit={handleSubmit}>
                            <div className='logo is-vertical-flow'>
                                <C.Image src={imgRecuperarSenha} size='110x110' />
                            </div>
                            <div className='titulo'>RECUPERAR SENHA</div>
                            <div className='descricao'>
                                Informe seu CPF e sua data de nascimento para redefinir sua senha.
                            </div>
                            <C.MaskedField name='cpf' placeholder='CPF' mask='999.999.999-99' icon='login' />
                            <C.DatePickerField name='dataNascimento' placeholder='Data de nascimento' icon='estrela' />
                            <button
                                type='submit'
                                className={btnSolicitarNovaSenha}
                                disabled={submitting}
                                onClick={handleSubmit}
                            >
                                Solicitar nova senha
                            </button>
                        </form>
                    </C.Column>
                </C.Columns>
            </div>
        )
    }
}
const RecuperarSenhaForm = form({
    form: 'recuperarSenha',
    validate: RecuperarSenhaValidator,
    hasErrorModal: false,
    hasLeaveModal: false,
    successIcon: 'emailEnviado',
    successContent: <span>Uma nova senha foi gerada para seu usuário e enviada para você por e-mail.</span>,
    successTitle: 'Nova senha gerada!',
})(RecuperarSenhaFormCmp)

export default class LoginView extends React.Component {
    constructor(props) {
        super(props)

        this.autoLogin = this.autoLogin.bind(this)
    }

    autoLogin(eve) {
        if (eve.key === 'login') {
            this.props.checkLogin()
        }
    }

    componentDidMount() {
        this.props.checkLogin()
        window.addEventListener('storage', this.autoLogin, false)
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.autoLogin, false)
    }

    render() {
        return (
            <div className='is-full-height is-vertical-flow login-root'>
                <section className='container'>
                    <div className='login-container'>
                        <Route
                            exact
                            path={'/'}
                            render={() => {
                                return (
                                    <LoginForm
                                        goRecuperarSenha={() => this.props.history.push('/recuperarSenha')}
                                        goProfissional={() => this.props.history.push('/public/profissional')}
                                        onSubmit={this.props.onLogin}
                                    />
                                )
                            }}
                        />
                        <Route
                            path={'/recuperarSenha'}
                            render={() => {
                                return (
                                    <RecuperarSenhaForm
                                        goBack={() => this.props.history.goBack()}
                                        onSubmit={this.props.onRecuperarSenha}
                                        onSubmitFail={this.props.onRecuperarSenhaError}
                                        onSubmitSuccess={this.props.afterRecuperarSenha}
                                    />
                                )
                            }}
                        />
                    </div>
                </section>
            </div>
        )
    }
}
