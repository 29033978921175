import * as C from 'libs/components'
import field, { FieldProps } from 'libs/redux-form/field'
import * as React from 'react'
import { WrappedFieldProps } from 'redux-form'

export interface RadioFieldProps extends C.RadioButtonProps, Partial<WrappedFieldProps> {
    label: string
    required?: boolean
}

class RadioFieldCmp extends React.Component<RadioFieldProps, any> {
    render() {
        return (
            <C.RadioButton
                {...this.props.input}
                label={this.props.label}
                disabled={this.props.disabled}
                testid={this.props.testid}
            />
        )
    }
}

export const RadioField: React.ComponentClass<FieldProps & RadioFieldProps> = field({
    type: 'radio',
    haswrapper: false,
})(RadioFieldCmp)
