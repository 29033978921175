/* tslint:disable */
import React from 'react'

const SvgRetornar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M13.18 4C17.492 4 21 7.478 21 11.759c0 4.28-3.508 7.758-7.816 7.758a7.796 7.796 0 01-5.526-2.271.781.781 0 010-1.113.79.79 0 011.115 0 6.212 6.212 0 004.41 1.814c3.441 0 6.239-2.776 6.239-6.188s-2.8-6.188-6.242-6.188c-3.204 0-5.85 2.406-6.199 5.493l1.435-1.286a.795.795 0 011.114.06.785.785 0 01-.06 1.11l-2.994 2.676a.782.782 0 01-.529.204c-.075-.002-.075-.002-.1-.006a.791.791 0 01-.558-.343L3.13 10.26a.784.784 0 01.223-1.089.792.792 0 011.092.22l.972 1.453C5.876 6.994 9.182 4 13.18 4z' />
    </svg>
)

export default SvgRetornar
