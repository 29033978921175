import * as React from 'react'
import { connect } from 'react-redux'
import * as C from 'libs/components'
import { Link, RouteComponentProps } from 'react-router-dom'
import {
    DateInterval,
    DistribuicaoProcedimentoFiltro,
    FiltroCirurgia,
    TipoCirurgia,
    ProdutosProcedimentosRow,
    TipoProcedimento,
} from 'backend'
import { HorizontalTabMenu, HorizontalTabMenuItem } from 'view/componentes/HorizontalTabMenu'
import { ReportHeader } from 'view/componentes/reports/components/ReportHeader'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import { ProdutosProcedimentosTable } from '../components/ProdutosProcedimentosTable'
import semResultado from 'images/home/reports/rastreabilidade-de-produtos-busca-sem-resultados.svg'
import UrlParams from 'libs/router/UrlParams'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { ScrollToTop } from 'libs/components'
import { State } from 'redux/modules'
import BackButton from 'libs/components/presentational/elements/BackButton'
import { stringify } from 'redux/modules/router'

const ResultadoBusca = props => {
    if (!props.produtosProcedimentos && !props.erro) {
        return <div></div>
    } else if (!props.produtosProcedimentos || props.produtosProcedimentos.length < 1) {
        return (
            <div className='resultado-busca report-content'>
                <div className='has-text-centered sem-resultados report-content'>
                    <img src={semResultado} alt='semresultado' />
                    <br />
                </div>
                <p className='text-result has-text-centered report-content'>Sem resultados</p>
            </div>
        )
    } else if (props.produtosProcedimentos) {
        return (
            <div>
                <C.Columns>
                    <C.Column>
                        <ProdutosProcedimentosTable
                            handlePageChange={props.handlePageChange}
                            isFetching={props.isFetching}
                            distProdutos={props.produtosProcedimentos}
                            tipoProcedimento={props.tipoProcedimento}
                            report={props.report}
                        />
                    </C.Column>
                </C.Columns>
            </div>
        )
    }
}

interface DistribuicaoProdutosFormStateProps {
    erro?: any
}

interface ProdutosFormOwnProps {
    handlePageChange: any
    componentes: any
    isFetching: any
    loadComponenteFilter: any
    filtro: any
}

type FormFullProps = DistribuicaoProdutosFormStateProps & ProdutosFormOwnProps

class DistribuicaoProdutosFormComponent extends React.Component<FormFullProps> {
    renderDownloadIcon = () => {
        const currentFilter = this.props.filtro || {}
        return (
            <Link
                to={{
                    pathname: '/api/report/produtosProcedimentos/csv',
                    search: stringify(currentFilter),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <C.Text color='grey-darker'>
                    <C.Icon icon='exportar' />
                </C.Text>
            </Link>
        )
    }

    render() {
        const { erro } = this.props
        const interval: DateInterval = this.props.filtro.interval

        const handleSearchPeriodo = (interval: DateInterval) => {
            const filtro = {
                ...this.props.filtro,
                interval: interval,
            }
            this.props.loadComponenteFilter(filtro)
        }

        return (
            <div>
                <div className='report-content'>
                    <C.Columns className='report-tabs align-center'>
                        <C.Column size='6'>
                            <div className='report-tabs-links'>
                                <C.Text className='is-bold'>Lista de componentes</C.Text>
                            </div>
                        </C.Column>
                        <C.Column size='6' textRight>
                            <C.PeriodoFilter value={interval} onChange={handleSearchPeriodo} />
                        </C.Column>
                    </C.Columns>
                </div>
                <div>
                    {this.props.filtro && (
                        <ResultadoBusca
                            handlePageChange={this.props.handlePageChange}
                            isFetching={this.props.isFetching}
                            produtosProcedimentos={this.props.componentes}
                            erro={erro}
                            tipoProcedimento={this.props.filtro.procedimento}
                            report={this.renderDownloadIcon}
                        />
                    )}
                </div>
            </div>
        )
    }
}

let ConnectedDistribuicaoProdutosForm = connect<
    DistribuicaoProdutosFormStateProps,
    null,
    ProdutosFormOwnProps,
    FormFullProps
>(
    (state: State) => {
        const erro = state.report.produto.produtos.error
        return {
            erro,
        }
    },
    null,
    (stateProps: DistribuicaoProdutosFormStateProps, dispatchProps: any, ownProps: ProdutosFormOwnProps) => {
        return {
            ...stateProps,
            ...ownProps,
        }
    }
)(DistribuicaoProdutosFormComponent)

export interface Props extends RouteComponentProps<any> {
    filtro: DistribuicaoProcedimentoFiltro
    componentes: ProdutosProcedimentosRow
    goBack(): void
    changeFilter(filtro: DistribuicaoProcedimentoFiltro): void
    find(tipoProcedimento: any): any
    clearFindResult(): void
    clearFindResultProdutos(): void
    handlePageChange(page: number): void
    isFetching: boolean
}

export class DistProdutosView extends React.Component<Props> {
    refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        const filteredFilter = {
            ...filter,
            filtroCirurgia: filter.tipoCirurgia === 'CORACAO' ? undefined : filter.filtroCirurgia,
        }
        if (!filterChanged) {
            this.props.changeFilter(filteredFilter)
        }
        this.props.find(filteredFilter)
    }

    handleTabItemClick = (tipoCirurgia: TipoCirurgia) => {
        const filter = {
            ...this.props.filtro,
            tipoCirurgia: tipoCirurgia,
            filtroCirurgia: this.getFiltroCirurgia(tipoCirurgia),
            procedimento: this.getProcedimento(tipoCirurgia),
        } as DistribuicaoProcedimentoFiltro

        this.props.clearFindResultProdutos()
        this.props.changeFilter(filter)
    }

    handleSubTabItemClick = (filtroCirurgia: FiltroCirurgia) => {
        this.props.changeFilter({
            ...this.props.filtro,
            filtroCirurgia: filtroCirurgia,
            procedimento: (filtroCirurgia + '_' + this.props.filtro.tipoCirurgia) as TipoProcedimento,
        })
    }

    onLoad = (filter: DistribuicaoProcedimentoFiltro) => {
        this.refresh(filter, false)
    }

    getFiltroCirurgia = (tipoCirurgia: TipoCirurgia) => {
        return tipoCirurgia === 'CORACAO' ? undefined : 'PRIMARIA'
    }

    getProcedimento = (tipoCirurgia: TipoCirurgia) => {
        return tipoCirurgia === 'CORACAO' ? 'STENT_CORONARIA' : (('PRIMARIA_' + tipoCirurgia) as TipoProcedimento)
    }

    getFilter = () => {
        const currentFilter = this.props.filtro || {}
        const tipoCirurgia = currentFilter.tipoCirurgia || 'JOELHO'
        return {
            ...currentFilter,
            tipoCirurgia,
            registroAnvisa: undefined,
            filtroCirurgia: currentFilter.filtroCirurgia || this.getFiltroCirurgia(tipoCirurgia),
            procedimento: currentFilter.procedimento || this.getProcedimento(tipoCirurgia),
        } as DistribuicaoProcedimentoFiltro
    }

    render() {
        const filtro = this.getFilter()
        const componentes = this.props.componentes
        return (
            <div>
                <ScrollToTop />
                <UrlParams params={filtro} onChange={this.refresh} />
                <ReportClearFilter />
                <LocalHeaderContainer />

                <ReportHeader title='Distribuição de produtos'>
                    <div>
                        <BackButton />
                    </div>

                    <HorizontalTabMenu>
                        <HorizontalTabMenuItem
                            title='Joelho'
                            onClick={() => this.handleTabItemClick('JOELHO')}
                            active={filtro.tipoCirurgia === 'JOELHO'}
                        />
                        <HorizontalTabMenuItem
                            title='Quadril'
                            onClick={() => this.handleTabItemClick('QUADRIL')}
                            active={filtro.tipoCirurgia === 'QUADRIL'}
                        />
                        <HorizontalTabMenuItem
                            title='CARDIOLOGIA'
                            onClick={() => this.handleTabItemClick('CORACAO')}
                            active={filtro.tipoCirurgia === 'CORACAO'}
                        />
                    </HorizontalTabMenu>
                </ReportHeader>

                <div>
                    {filtro.tipoCirurgia && filtro.tipoCirurgia !== 'CORACAO' && (
                        <div className='report-content'>
                            <HeaderContainer>
                                <HorizontalTabMenu>
                                    <HorizontalTabMenuItem
                                        title='Primária'
                                        active={filtro.filtroCirurgia === 'PRIMARIA'}
                                        onClick={() => this.handleSubTabItemClick('PRIMARIA')}
                                    />
                                    <HorizontalTabMenuItem
                                        title='Revisão'
                                        active={filtro.filtroCirurgia === 'REVISAO'}
                                        onClick={() => this.handleSubTabItemClick('REVISAO')}
                                    />
                                </HorizontalTabMenu>
                            </HeaderContainer>
                        </div>
                    )}
                </div>

                <ConnectedDistribuicaoProdutosForm
                    handlePageChange={this.props.handlePageChange}
                    componentes={componentes}
                    isFetching={this.props.isFetching}
                    loadComponenteFilter={this.onLoad}
                    filtro={filtro}
                />
            </div>
        )
    }
}
