import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { State } from 'redux/modules'
import * as termoUso from 'redux/modules/termoUso'
import * as auth from 'redux/modules/auth'
import TermoUsoView from './TermoUsoView'
import * as alert from 'redux/modules/alert'
import { getTermoUsoVigente, isFetchingTermoUso } from 'redux/selectors/termoUso'

const mapStateToProps = (state: State) => {
    return {
        user: state.auth.user,
        termoUso: getTermoUsoVigente(state),
        isFetchingTermoUso: isFetchingTermoUso(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps: RouteComponentProps<any>) => {
    return {
        carregarTermoUsoVigente: () => {
            return dispatch(termoUso.carregarTermoUsoVigente())
        },
        carregarAceiteTermoUso: () => {
            return dispatch(auth.carregarAceiteTermoUso())
                .then(resp => {
                    if (resp.data.aceitouTermo) {
                        ownProps.history.push('/home')
                    }
                })
                .catch(error => {
                    dispatch(alert.error({ mensagem: 'Erro ao carregar aceite termo de uso' }))
                    throw new Error(error)
                })
        },
        aceitarTermoUso: () => {
            return dispatch(auth.aceitarTermoUso())
                .then(() => {
                    ownProps.history.push('/home')
                })
                .catch(error => {
                    dispatch(alert.error({ mensagem: 'Erro ao aprovar termo de uso' }))
                    throw new Error(error)
                })
        },
    }
}

const TermoUsoContainer = connect(mapStateToProps, mapDispatchToProps)(TermoUsoView)

export default TermoUsoContainer
