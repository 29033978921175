import React from 'react'
import * as C from 'libs/components'

export class DoencaCerebrovascularCard extends React.Component {
    render() {
        return (
            <C.MultiButtonPanelField
                label='Doença cerebrovascular'
                name='doencaCerebrovascular'
                hasContent={false}
                buttonSet={<C.SimNaoNAvaliadoRadioField name='doencaCerebrovascular' />}
            />
        )
    }
}
