import * as React from 'react'
import { ChartOptions, ChartCard } from 'libs/components'
import { SeriesPieOptions } from 'highcharts'

interface Props {
    item: any
}

export class QuadroPadraoChartCard extends React.Component<Props> {
    render() {
        let config: ChartOptions = {}

        config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            tooltip: {
                pointFormat: '{point.y} | {point.percentage:.1f}%',
            },
			title: {
				text: ''
			},
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        useHTML: true,
                        format:
                            '{point.name} <br> <span style="color:{point.color}">{point.y} | {point.percentage:.1f}%</span>',
                    },
                },
            },
            series: this.props.item.series as SeriesPieOptions[],
        }
        return (
            <ChartCard
                defaultViewMode='pie'
                title= {this.props.item.quadroClinico}
                config={config}
                renderActions={false}
                renderTools={false}
            />
        )
    }
}
