import { LocalidadeSearchResultDto } from 'backend'
import { promiseStaleReducer, PromiseRecord } from 'redux/middleware/PromiseAction'
import Report from 'api/Report'

export const SEARCH = 'rni/report/buscaLocalidade/SEARCH'
export const CLEAR = 'rni/report/buscaLocalidade/CLEAR'

export type BuscaLocalidadeState = PromiseRecord<LocalidadeSearchResultDto>

export const initialState: BuscaLocalidadeState = {
    data: {
        regioes: [],
        ufs: [],
        cidades: [],
        hospitais: [],
    },
}

export const reducer = (state: BuscaLocalidadeState = initialState, action) => {
    if (action.type === SEARCH) {
        return promiseStaleReducer(state, action)
    } else if (action.type === CLEAR) {
        return initialState
    }
    return state
}

export default reducer

export const search = (query: string) => ({
    type: SEARCH,
    promise: Report.searchLocalidade(query),
})

export const clear = () => ({
    type: CLEAR,
})
