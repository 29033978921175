/* tslint:disable */
import React from 'react'

const SvgData = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M19.25 3.5c.966 0 1.75.784 1.75 1.75v13.53a1.75 1.75 0 01-1.75 1.72H4.75A1.75 1.75 0 013 18.78V5.25c0-.966.784-1.75 1.75-1.75h14.5zm0 15.54v-.01a.25.25 0 00.25-.25V9.72h-15v9.07c0 .138.112.25.25.25h14.5zm-6.22-7.28H17a.5.5 0 01.5.5v3.41a.5.5 0 01-.5.5h-3.97a.5.5 0 01-.5-.5v-3.41a.5.5 0 01.5-.5z' />
    </svg>
)

export default SvgData
