import * as React from 'react'
import { ComponenteUtilizadoVisualizacaoDto } from 'backend'
import * as C from 'libs/components'

interface ComponentesUtilizadosArtroplastiaProps {
    principais: ComponenteUtilizadoVisualizacaoDto[]
    complementares?: ComponenteUtilizadoVisualizacaoDto[]
}

export class ComponentesUtilizadosArtroplastia extends React.Component<ComponentesUtilizadosArtroplastiaProps> {
    render() {
        return (
            <div>
                <C.Label className='with-border-bellow'>Componentes utilizados</C.Label>
                <C.Columns>
                    <C.Column size='10'>
                        <C.Label> Principais </C.Label>
                    </C.Column>
                    <C.Column>
                        <C.Label> Qtd </C.Label>
                    </C.Column>
                </C.Columns>
                {this.props.principais.map(element => (
                    <div key={element.nome}>
                        <C.Columns>
                            <C.Column size='10'>
                                <C.TextLabel title='' value={element.nome}></C.TextLabel>
                            </C.Column>
                            <C.Column>
                                <C.NumberLabel title='' value={element.quantidade}></C.NumberLabel>
                            </C.Column>
                        </C.Columns>
                    </div>
                ))}
                {this.props.complementares.length > 0 && (
                    <div>
                        <C.Linha />
                        <C.Columns>
                            <C.Column size='10'>
                                <C.Label> Complementares </C.Label>
                            </C.Column>
                            <C.Column>
                                <C.Label> Qtd </C.Label>
                            </C.Column>
                        </C.Columns>
                        {this.props.complementares.map(element => (
                            <div key={element.nome}>
                                <C.Columns>
                                    <C.Column size='10'>
                                        <C.TextLabel title='' value={element.nome}></C.TextLabel>
                                    </C.Column>
                                    <C.Column>
                                        <C.NumberLabel title='' value={element.quantidade}></C.NumberLabel>
                                    </C.Column>
                                </C.Columns>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
}
