import * as React from 'react'
import { TextField } from 'libs/components'
import { DoubleFieldGroup } from './DoubleFieldGroup'

export const IdentificacaoProdutoField = () => (
    <DoubleFieldGroup name='idDispositivo' label='Identificação do produto' required>
        <TextField
            maxLength={23}
            label='Identificador do dispositivo'
            name='identificadorDispositivo'
            placeholder='GTIN / HIBCC'
        />
        <TextField
            maxLength={30}
            label='Código de referência'
            name='codigoReferencia'
            placeholder='Código do produto'
        />
    </DoubleFieldGroup>
)
