import * as React from 'react'
import { DadosCirurgiaVisualizacaoRevisaoQuadrilDto, ComponenteVisualizacaoDto } from 'backend'
import * as C from 'libs/components'
import * as FC from 'view/home/novoProcedimento/ficha/componentes'

interface DadosRevisaoQuadrilProps {
    dadosCirurgia: DadosCirurgiaVisualizacaoRevisaoQuadrilDto
    componentes: ComponenteVisualizacaoDto
}

export class DadosRevisaoQuadril extends React.Component<DadosRevisaoQuadrilProps> {
    render() {
        const dados = this.props.dadosCirurgia
        const componentes = this.props.componentes
        return (
            <C.Conteudo>
                <C.Label className='with-border-bellow'>Motivos da revisão</C.Label>
                <C.TextLabel title='Motivos da revisão' value={dados.motivosRevisao} />
                <C.Label className='with-border-bellow'>Procedimento cirúrgico atual</C.Label>
                <C.Columns auto>
                    <C.TextLabel title='Abordagem cirúrgica' value={dados.abordagemCirurgica} />
                    <C.TextLabel title='Uso de dreno' value={dados.usoDreno} />
                </C.Columns>
                <C.Columns auto>
                    <C.TextLabel title='Osteotomia femoral' value={dados.osteotomiaFemoral} />
                    <C.TextLabel title='Anatomopatológico' value={dados.anatomopatologico} />
                </C.Columns>
                <C.Columns auto>
                    <C.TextLabel title='Transfusão sanguínea' value={dados.transfusaoSanguinea} />
                </C.Columns>
                <C.Columns auto>
                    <C.TextLabel title='Cultura durante o ato cirúrgico' value={dados.culturaDuranteAto} />
                </C.Columns>
                <C.Columns auto>
                    <C.TextLabel title='Antibiótico' value={dados.antibiotico} />
                    {dados.periodoAntibiotico && <C.TextLabel title='Período' value={dados.periodoAntibiotico} />}
                </C.Columns>
                <C.Label className='with-border-bellow'>Tipo de revisão</C.Label>
                <C.TextLabel
                    title='Tipo de revisão'
                    value={dados.componentesRetirados ? 'Retirada: ' + dados.componentesRetirados : dados.tipoRevisao}
                />
                <C.Label className='with-border-bellow'>Técnica operatória</C.Label>
                {dados.implanteFemoral && (
                    <div className='with-border-left'>
                        <C.Columns auto>
                            <C.Label>Femoral</C.Label>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='4'>
                                <C.TextLabel title='Tela de suporte' value={dados.implanteFemoralTelaSuporte} />
                            </C.Column>
                            <C.Column size='4'>
                                <C.TextLabel title='Centralizador' value={dados.implanteFemoralCentralizador} />
                            </C.Column>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='4'>
                                <C.TextLabel title='Cimentado' value={dados.implanteFemoralCimentado} />
                            </C.Column>
                            <C.Column size='4'>
                                {dados.implanteFemoralRestritorCimento && (
                                    <C.TextLabel
                                        title='Restritor de cimento'
                                        value={dados.implanteFemoralRestritorCimento}
                                    />
                                )}
                            </C.Column>
                        </C.Columns>
                        <C.TextLabel title='Tipo de prótese' value={dados.implanteFemoralTipoProtese} />
                    </div>
                )}
                <br /> {/* hue*/}
                {dados.implanteAcetabular && (
                    <div className='with-border-left'>
                        <C.Columns auto>
                            <C.Label>Acetabular</C.Label>
                        </C.Columns>
                        <C.Columns>
                            <C.Column size='4'>
                                <C.TextLabel title='Tela de suporte' value={dados.implanteAcetabularTelaSuporte} />
                            </C.Column>
                            <C.Column size='7'>
                                <C.TextLabel title='Anel de reforço' value={dados.implanteAcetabularAnelReforco} />
                            </C.Column>
                        </C.Columns>

                        <C.Columns>
                            <C.Column size='4'>
                                <C.TextLabel
                                    title='Metal trabeculado'
                                    value={dados.implanteAcetabularMetalTrabeculado}
                                />
                            </C.Column>
                            <C.Column size='7'>
                                <C.TextLabel title='Par tribológico' value={dados.implanteAcetabularParTribologico} />
                            </C.Column>
                        </C.Columns>
                        <C.TextLabel title='Cimentado' value={dados.implanteAcetabularCimentado} />
                    </div>
                )}
                {dados.tipoCimentoOsseo && (
                    <div>
                        <C.Label className='with-border-bellow'>Cimento ósseo</C.Label>

                        <C.Columns auto>
                            <C.TextLabel title='Tipo' value={dados.tipoCimentoOsseo} />
                        </C.Columns>
                        <C.Columns auto>
                            <C.TextLabel title='Técnica de cimentação' value={dados.tecnicaCimentacao} />
                            <C.TextLabel title='Lavagem pulsátil' value={dados.lavagemPulsatil} />
                        </C.Columns>
                    </div>
                )}
                <C.Label className='with-border-bellow'>Enxertia</C.Label>
                <C.Columns auto>
                    {dados.implanteFemoral && <C.TextLabel title='Femoral' value={componentes.enxertiaFemoralOpcao} />}
                    {dados.implanteAcetabular && (
                        <C.TextLabel title='Acetabular' value={componentes.enxertiaAcetabularOpcao} />
                    )}
                </C.Columns>
                <FC.ComponentesUtilizadosArtroplastia
                    principais={componentes.componentesPrincipais}
                    complementares={componentes.componentesComplementares}
                />
            </C.Conteudo>
        )
    }
}
