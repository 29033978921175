import React from 'react'
import { connect } from 'react-redux'
import { Route, withRouter, RouteProps, RouteComponentProps } from 'react-router-dom'
import { State } from 'redux/modules'
import { Perfil } from 'backend'

interface StateProps {
    usuarioPerfil: Perfil
}

interface Props {
    perfis: Perfil[]
}

export type PerfilRouteProps = Props & StateProps & RouteProps & RouteComponentProps

const InternalPerfilRoute = (props: PerfilRouteProps) => {
    const { usuarioPerfil, perfis, ...rest } = props
    if (perfis.includes(usuarioPerfil)) {
        return <Route {...rest} />
    }
    return null
}

const mapStateToProps = (state: State) => {
    return {
        usuarioPerfil: state.auth.user.perfil,
    }
}

const PerfilRoute = connect<StateProps>(mapStateToProps)(InternalPerfilRoute)

export default withRouter(PerfilRoute)
