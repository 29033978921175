/* tslint:disable */
import React from 'react'

const SvgTrocar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
        <path d='M4.6 8.157v.01a.597.597 0 01-.6-.595c0-.328.268-.594.6-.594h13.383l-2.997-2.924a.592.592 0 01.24-1.037.602.602 0 01.6.194l3.994 3.965a.592.592 0 010 .842l-3.925 3.885a.604.604 0 01-.849 0 .592.592 0 010-.842l2.937-2.904H4.599zm14.8 7.641a.6.6 0 01.52.297.59.59 0 010 .595.6.6 0 01-.52.297H6.058l2.937 2.924a.593.593 0 01-.202 1.075.602.602 0 01-.617-.233L4.18 16.79a.592.592 0 010-.842l3.965-3.885a.604.604 0 01.849 0 .592.592 0 010 .842l-2.996 2.894H19.4z' />
    </svg>
)

export default SvgTrocar
