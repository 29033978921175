import * as React from 'react'
import { DistribuicaoHospitalProcedimentoDto } from 'backend'
import { ChartCard, ChartOptions } from 'libs/components'
import * as enums from 'redux/enums'
import { SeriesColumnOptions } from 'highcharts'

interface Props {
    info: DistribuicaoHospitalProcedimentoDto
    readyState: 'request' | 'success' | 'failure'
}

export class InfoQuantidadeProcedimentosEtapaCard extends React.Component<Props> {
    render() {
        const { info, readyState } = this.props
        const result = {
            categories: [],
            series: [],
        }

        let config: ChartOptions = {}
        if (readyState === 'success') {
            result.categories = Object.keys(info.countStatus).map(itm => enums.statusProcedimentoHospitalByEnum[itm])
            result.series = [
                {
                    name: 'Procedimentos',
                    data: [],
                },
            ]

            Object.keys(info.countStatus).forEach(countStatus => {
                result.series[0].data.push([
                    enums.statusProcedimentoHospitalByEnum[countStatus],
                    info.countStatus[countStatus],
                ])
            })

            config = {
                chart: {
                    type: 'bar',
                },
                xAxis: {
                    categories: result.categories,
                    title: {
                        text: null,
                    },
                },
                yAxis: {
                    min: 0,
                    minTickInterval: 1,
                    title: {
                        text: null,
                    },
                },
                title: {
                    text: 'Distribuição de procedimentos por etapa',
                },
                legend: {
                    layout: 'horizontal',
                    align: 'right',
                    verticalAlign: 'bottom',
                    y: 20,
                    floating: true,
                },
                credits: {
                    enabled: false,
                },
                series: result.series as SeriesColumnOptions[],
            }
        }

        return (
            <ChartCard
                defaultViewMode='bar'
                title='Quantidade de procedimento por etapa'
                readyState={readyState}
                config={config}
                renderActions={false}
                renderTools={false}
            />
        )
    }
}
