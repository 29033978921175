import * as React from 'react'
import { AlertaEtiquetaSemRegistroConfigForm } from 'backend'
import AlertaConfigModalForm, {
    PublicAlertaConfigModalFormProps,
} from 'view/home/anvisa/alertas/components/AlertaConfigModalForm'

const formName = 'configuracaoAlertaEtiquetaSemRegistro'

interface EtiquetaSemRegistroModalFormProps extends PublicAlertaConfigModalFormProps {
    config: AlertaEtiquetaSemRegistroConfigForm
}

export default class EtiquetaSemRegistroModalForm extends React.PureComponent<EtiquetaSemRegistroModalFormProps> {
    render() {
        return <AlertaConfigModalForm formName={formName} {...this.props} />
    }
}
