/* tslint:disable */
import React from 'react'

const SvgFeminino = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='1em' height='1em' viewBox='0 0 18 19' {...props}>
        <path d='M3.225 7.674c0-3.142 2.59-5.699 5.775-5.699 3.185 0 5.775 2.557 5.775 5.7 0 2.835-2.124 5.236-4.944 5.639l-.193.027v1.528h1.288c.353 0 .638.281.638.627a.633.633 0 01-.638.627H9.638v1.275a.633.633 0 01-.638.627.633.633 0 01-.638-.627V16.123H7.074a.633.633 0 01-.638-.627c0-.346.285-.627.638-.627h1.288v-1.528l-.193-.027c-2.82-.403-4.944-2.804-4.944-5.64zm10.274 0C13.5 5.222 11.48 3.23 9 3.23c-2.48 0-4.5 1.993-4.5 4.445S6.52 12.12 9 12.12s4.5-1.993 4.5-4.445z' />
    </svg>
)

export default SvgFeminino
