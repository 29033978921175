
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.48,"time":28799.999999999996,"words":96}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`O RNI permite que seja feita a correção/edição dos dados clínicos preenchidos pelo médico com algumas condições.`}</p>
    <p><strong parentName="p">{`Regras:`}</strong></p>
    <ol>
      <li parentName="ol">{`Os dados de produto não podem ter sido preenchidos.`}</li>
      <li parentName="ol">{`Apenas o `}<strong parentName="li">{`Médico`}</strong>{` ou o `}<strong parentName="li">{`Residente`}</strong>{` cadastrado como parte da equipe médica do procedimento poderão fazer a edição dos dados clínicos.`}</li>
    </ol>
    <p><strong parentName="p">{`Fluxo:`}</strong></p>
    <ol>
      <li parentName="ol">{`Faça login no RNI`}</li>
      <li parentName="ol">{`Clique na lista “Aguardando dados de produto”`}</li>
      <li parentName="ol">{`No procedimento que deseja editar, clique no ícone `}<img parentName="li" {...{
          "src": "/images/ic-editar-21-px.png#center",
          "alt": "Editar"
        }}></img></li>
      <li parentName="ol">{`Na ficha clínica edite os dados que precisam de edição`}</li>
      <li parentName="ol">{`Após o preenchimento dos campos, clique no botão “Salvar registro”.`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

