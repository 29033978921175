import axios, { AxiosPromise } from 'axios'
import { PesquisaSatisfacaoForm, NivelSatisfacaoEnum, PesquisaSatisfacaoDto } from 'backend'
import { useAxiosGet, useLazyAxiosGet } from 'hooks/useApiRequest'
import { NivelSatisfacao } from 'view/componentes/pesquisaSatisfacao/seletorSatisfacao/icons'

export const convertToPesquisaSatisfacaoEnum = (nivelSatisfacao: NivelSatisfacao): NivelSatisfacaoEnum => {
    if (!nivelSatisfacao) {
        return null
    }
    switch (nivelSatisfacao.id) {
        case 0:
            return 'MUITO_INSATISFEITO'
        case 1:
            return 'INSATISFEITO'
        case 2:
            return 'INDIFERENTE'
        case 3:
            return 'SATISFEITO'
        case 4:
            return 'MUITO_SATISFEITO'
        default:
            return null
    }
}

export const usePesquisaSatisfacaoNiveis = () => {
    const { data, ...rest } = useAxiosGet<NivelSatisfacaoEnum[]>('/api/pesquisaSatisfacao/niveisSatisfacao')
    return {
        niveisSatisfacao: data,
        ...rest,
    }
}

export const useUltimaPesquisaSatisfacao = () =>
    useLazyAxiosGet<PesquisaSatisfacaoDto>('/api/pesquisaSatisfacao/ultimaPesquisa')

class PesquisaSatisfacao {
    save(form: PesquisaSatisfacaoForm): AxiosPromise<void> {
        return axios.post('/api/pesquisaSatisfacao/save', form)
    }
}

export default new PesquisaSatisfacao()
