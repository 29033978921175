import React from 'react'
import { arquivosPdf } from './ManualCompletoUsuarioView'
import Fuse from 'fuse.js'
import { RouteComponentProps } from 'react-router-dom'
import qs from 'qs'
import { renderToString } from 'react-dom/server'
import { PerfilEnum } from 'api/Perfis'

export interface ManualUsuarioSearchViewProps extends RouteComponentProps<any> {
    homeOrPublic: string
    perfil: string
}

const files = arquivosPdf.map(({ component: Component, ...rest }) => ({
    ...rest,
    result: stripHtml(renderToString(<Component />)),
}))

const fuse = new Fuse(files, {
    keys: ['result', 'tituloBusca'],
    includeMatches: true,
    includeScore: true,
    matchAllTokens: true,
    tokenize: true,
    threshold: 0.2,
})

function stripHtml(html: string) {
    const htmlWithNewLines = html.replace(/<\/\w+>/g, '\n')
    const tmp = document.createElement('DIV')
    tmp.innerHTML = htmlWithNewLines
    const text = tmp.textContent || tmp.innerText || ''
    return text
}

export function ManualUsuarioSearchView(props: ManualUsuarioSearchViewProps) {
    const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    const search = searchParams.s

    let results
    results = fuse.search(search).filter(result => result.item.perfil.includes(PerfilEnum[props.perfil]))

    return (
        <div>
            <h2 className='manual-text-search-title'>Buscas relacionadas</h2>
            {results.length > 0
                ? results.map(result => {
                      const match = result.item.result
                      let init
                      let end
                      if (result.matches.length > 0) {
                          const matches = result.matches[0]
                          init = matches.indices[0][0] - 100
                          end = matches.indices[matches.indices.length - 1][1] + 150
                      } else {
                          init = 0
                          end = 160
                      }
                      return (
                          <div className={'manual-text-search-found'} key={match.length}>
                              <h2>
                                  <a href={`${props.homeOrPublic}${result.item.link}`}>{result.item.tituloBusca}</a>
                              </h2>

                              {match.value === result.item.tituloBusca ? (
                                  <p>{extractResumo(result.item.result, 0, 160)}</p>
                              ) : (
                                  <p>{extractResumo(match, init, end)}</p>
                              )}
                          </div>
                      )
                  })
                : semResultados()}
        </div>
    )
}

const extractResumo = (str: string, init: number, end: number) => {
    let currInit = init < 0 ? 0 : init
    let currEnd = end > str.length - 1 ? str.length - 1 : end

    while (currInit > 0 && str[currInit] !== ' ') {
        currInit--
    }
    while (currEnd < str.length - 1 && str[currEnd] !== ' ') {
        currEnd++
    }

    let resumo = str.substr(currInit, currEnd - currInit + 1)

    if (currInit > 0) {
        resumo = '...' + resumo
    }
    if (currEnd < str.length - 1) {
        resumo = resumo + '...'
    }

    return resumo
}

const semResultados = () => {
    return (
        <div className={'manual-text-search-body'}>
            <img src='/images/manual-nao-encontrado.png' alt='Nenhum resultado encontrado' />
            <div className={'manual-text-search-desc'}>
                <p>Nenhum resultado encontrado</p>
                <p>Modifique sua busca ou navegue pelo menu ao lado</p>
            </div>
        </div>
    )
}
