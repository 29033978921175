import * as React from 'react'
import { MultiButtonPanel } from '../MultiButtonPanel'
import field from 'libs/redux-form/field'
import { WrappedFieldProps } from 'redux-form'

interface MultiButtonPanelFieldProps extends Partial<WrappedFieldProps> {
    label: string
    buttonSet: any
    hasContent?: boolean
    input?: any
    name: string
}

export class MultiButtonPanelFieldCmp extends React.Component<MultiButtonPanelFieldProps> {
    render() {
        return <MultiButtonPanel {...this.props} />
    }
}

export const MultiButtonPanelField = field({ haswrapper: false })(MultiButtonPanelFieldCmp)
