import * as React from 'react'
import { WrappedFieldInputProps } from 'redux-form'
import { Checkbox } from './Checkbox'
import classnames from 'classnames'

export interface StyledCheckboxProps extends Partial<WrappedFieldInputProps> {
    checked?: boolean
    disabled?: boolean
    label?: string
    className?: string
}

export class StyledCheckbox extends React.Component<StyledCheckboxProps, any> {
    render() {
        const { label, className, ...rest } = this.props
        const classes = classnames(className, {
            checked: this.props.checked,
        })
        return (
            <label className={classes}>
                <Checkbox {...rest} />
                <span>{label}</span>
            </label>
        )
    }
}
