import { createValidator, required, range } from 'libs/validation'

const AumentoRegistroModalFormValidator = createValidator({}, formValidator)

function formValidator(form) {
    const errors: any = {}

    if (form.get('ativo')) {
        const percentual = form.get('percentual')
        errors.tipoPeriodo = required(form.get('tipoPeriodo'))
        errors.emRelacaoA = required(form.get('emRelacaoA'))
        errors.percentual = required(percentual)
        if (!errors.percentual) {
            errors.percentual = range(1, 999)(percentual)
        }
        if (!errors.tipoPeriodo && errors.percentual) {
            errors.tipoPeriodo = errors.percentual
        }
    }
    return errors
}

export default AumentoRegistroModalFormValidator
