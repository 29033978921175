import { connect } from 'react-redux'
import HomeView from 'view/home/HomeView'
import { State } from 'redux/modules'
import * as auth from 'redux/modules/auth'

const mapStateToProps = (state: State) => {
    return {
        perfil: state.auth.user.perfil,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateLogin: () => {
            dispatch(auth.updateLogin())
        },
    }
}

const HomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeView as any)

export default HomeContainer
