import * as React from 'react'
import {
    TipoProcedimento,
    DistribuicaoProdutosPrimariasDto,
    DistribuicaoProcedimentoFiltro,
    DistribuicaoProdutosRevisaoDto,
    DistribuicaoCardiologiaDto,
    DateInterval,
} from 'backend'
import { PorcentagemTipoArtroplastiaPrimariasCard } from '../cards/PorcentagemTipoArtroplastiaPrimariasCard'
import { PorcentagemTipoArtroplastiaRevisaoCard } from '../cards/PorcentagemTipoArtroplastiaRevisaoCard'
import { PromiseRecord } from 'redux/middleware/PromiseAction'
import { Link, RouteComponentProps } from 'react-router-dom'
import { HeaderContainer } from 'view/componentes/reports/components/HeaderContainer'
import { ComponenteProcedimentoTable } from '../components/ComponenteProcedimentoTable'
import LocalHeaderContainer from '../components/LocalHeaderContainer'
import CardNumeroProdutosProcedimentos from '../cards/CardNumeroProdutosProcedimentos'
import UrlParams from 'libs/router/UrlParams'
import {
    Columns,
    Column,
    Icon,
    Button,
    Card,
    LoadingContainer,
    Text,
    PeriodoFilter,
    ScrollToTop,
} from 'libs/components'
import { ReportClearFilter } from 'view/componentes/reports/components/ReportClearFilter'
import { stringify } from 'redux/modules/router'

interface RouteParams {
    idComponente: string
    tipoProcedimento: TipoProcedimento
}

export interface Props extends RouteComponentProps<RouteParams> {
    filtro: DistribuicaoProcedimentoFiltro
    tipoProcedimento: TipoProcedimento
    goBack(): void
    clearFindResult(): void
    changeFilter(filtro: DistribuicaoProcedimentoFiltro): void
    loadComponente(filtro: DistribuicaoProcedimentoFiltro): void
    componentePrimaria?: PromiseRecord<DistribuicaoProdutosPrimariasDto>
    componenteRevisao?: PromiseRecord<DistribuicaoProdutosRevisaoDto>
    componenteCardiologia: PromiseRecord<DistribuicaoCardiologiaDto>
    idComponente: any
}

let HeaderDistribuicaoProdutos = props => {
    let procedimentoName = 'Não informado'
    if (props.tipoProcedimento === 'PRIMARIA_QUADRIL') {
        procedimentoName = 'em PRIMÁRIA DE QUADRIL'
    } else if (props.tipoProcedimento === 'REVISAO_QUADRIL') {
        procedimentoName = 'em REVISÃO DE QUADRIL'
    } else if (props.tipoProcedimento === 'PRIMARIA_JOELHO') {
        procedimentoName = 'em PRIMÁRIA DE JOELHO'
    } else if (props.tipoProcedimento === 'REVISAO_JOELHO') {
        procedimentoName = 'em REVISÃO DE JOELHO'
    } else if (props.tipoProcedimento === 'STENT_CORONARIA') {
        procedimentoName = 'em STENT DE CORONARIA'
    }
    return (
        <div>
            <HeaderContainer className='report-header-rastreabilidade'>
                <Button className='button-round button-back' onClick={props.onVoltar}>
                    <Icon icon='voltar' />
                </Button>
                <Columns>
                    <Column size='10'>
                        <div>
                            <p className='is-semibold is-grey-darker is-small is-uppercase'>DISTRIBUIÇÃO DE PRODUTOS</p>
                            <p className='is-semibold'>{props.nomeComponente}</p>
                            <p className='is-semibold is-grey-darker is-small'>{procedimentoName}</p>
                        </div>
                    </Column>
                </Columns>
            </HeaderContainer>
        </div>
    )
}

let DadosUsoComponenteCard = props => {
    const tipoProcedimento = props.tipoProcedimento
    const primaria = tipoProcedimento === 'PRIMARIA_JOELHO' || tipoProcedimento === 'PRIMARIA_QUADRIL'

    return (
        <Card title='Dados de uso componente'>
            <LoadingContainer isFetching={props.isFetching}>
                {primaria ? (
                    <PorcentagemTipoArtroplastiaPrimariasCard
                        tiposPrimarias={props.tipos}
                        tipoProcedimento={tipoProcedimento}
                    />
                ) : (
                    <PorcentagemTipoArtroplastiaRevisaoCard
                        quantidadeProcedimentos={props.quantidadeProcedimentos}
                        tiposRevisoes={props.tipos}
                        tipoProcedimento={tipoProcedimento}
                    />
                )}
            </LoadingContainer>
        </Card>
    )
}

let DadosUsoComponenteCardiologiaCard = props => {
    return (
        <Card title='Dados de uso registro'>
            <LoadingContainer isFetching={props.isFetching}>
                <div className='has-text-centered'>
                    <Text size='x-large' className='is-semibold has-text-centered is-big'>
                        {props.quantidadeProcedimentos}
                    </Text>
                    <p className='is-semibold is-grey-darker is-small'>Angioplastias</p>
                </div>
            </LoadingContainer>
        </Card>
    )
}

interface ProdutosFormProps {
    componente: any
    loadComponenteFilter: any
    filtro: any
    onVoltar: any
    idComponente: any
}

class DistribuicaoProdutosFormComponent extends React.Component<ProdutosFormProps> {
    renderDownloadIcon = () => {
        const currentFilter = this.props.filtro || {}
        let url = ''
        if (currentFilter.procedimento === 'PRIMARIA_JOELHO' || currentFilter.procedimento === 'PRIMARIA_QUADRIL') {
            url = `/api/report/componentePrimarias/${this.props.idComponente}/csv`
        } else if (
            currentFilter.procedimento === 'REVISAO_JOELHO' ||
            currentFilter.procedimento === 'REVISAO_QUADRIL'
        ) {
            url = `/api/report/componenteRevisao/${this.props.idComponente}/csv`
        } else if (currentFilter.procedimento === 'STENT_CORONARIA') {
            url = `/api/report/componenteCardiologia/${this.props.idComponente}/csv`
        }

        return (
            <Link
                to={{
                    pathname: url,
                    search: stringify(currentFilter),
                }}
                target='_blank'
                title='Exportar para CSV'
            >
                <Text color='grey-darker'>
                    <Icon icon='exportar' />
                </Text>
            </Link>
        )
    }

    render() {
        const componente = this.props.componente

        const interval: DateInterval = this.props.filtro.interval
        const handleSearchPeriodo = (interval: DateInterval) => {
            const filtro = this.props.filtro
            filtro.interval = interval
            this.props.loadComponenteFilter(filtro)
        }
        const isFetching = componente.readyState === 'sucess'
        const componentesComRegistro = componente.pageProdutos && componente.pageProdutos.totalElements > 0
        return (
            <div>
                <HeaderDistribuicaoProdutos
                    nomeComponente={componente.nome}
                    tipoProcedimento={componente.tipoProcedimento}
                    onVoltar={this.props.onVoltar}
                />
                <div className='report-content'>
                    <Columns className='report-tabs align-center'>
                        <Column size='6'>
                            <div className='report-tabs-links'>LISTA DE REGISTRO</div>
                        </Column>
                        <Column size='6' textRight>
                            <PeriodoFilter value={interval} onChange={handleSearchPeriodo} />
                        </Column>
                    </Columns>

                    {componente.tipoProcedimento !== 'STENT_CORONARIA' ? (
                        <DadosUsoComponenteCard
                            isFetching={isFetching}
                            quantidadeProcedimentos={componente.quantidadeProcedimentos}
                            tipos={componente.tipos}
                            tipoProcedimento={componente.tipoProcedimento}
                        />
                    ) : (
                        <DadosUsoComponenteCardiologiaCard
                            quantidadeProcedimentos={componente.quantidadeProcedimentos}
                        />
                    )}
                    <br />
                    <CardNumeroProdutosProcedimentos
                        isFetching={isFetching}
                        numerosComponentesSemRegistro={componente.quantidadeComponentesSemRegistro}
                        numerosComponentesComRegistro={componente.quantidadeComponentesComRegistro}
                    />
                    <br />
                    {componentesComRegistro && (
                        <ComponenteProcedimentoTable
                            isFetching={isFetching}
                            idComponente={componente.idComponente}
                            tipoProcedimento={componente.tipoProcedimento}
                            distProdutos={componente.pageProdutos}
                            report={this.renderDownloadIcon}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export class DistComponenteProcedimentoView extends React.Component<Props> {
    refresh = (filter: DistribuicaoProcedimentoFiltro, filterChanged: boolean) => {
        if (!filterChanged) {
            this.props.changeFilter(filter)
        }
        this.props.loadComponente(filter)
    }

    onVoltar() {
        this.props.clearFindResult()
        this.props.goBack()
    }

    onLoad(filter: DistribuicaoProcedimentoFiltro) {
        this.props.loadComponente(filter)
    }

    render() {
        const componente =
            this.props.componentePrimaria || this.props.componenteRevisao || this.props.componenteCardiologia
        const { ...publishFilter } = this.props.filtro
        return (
            <div>
                <ScrollToTop />
                <UrlParams params={publishFilter} onChange={this.refresh} />
                <ReportClearFilter />

                <LocalHeaderContainer />
                {componente && (
                    <DistribuicaoProdutosFormComponent
                        componente={componente}
                        filtro={this.props.filtro}
                        loadComponenteFilter={this.onLoad.bind(this)}
                        onVoltar={this.onVoltar.bind(this)}
                        idComponente={this.props.idComponente}
                    />
                )}
            </div>
        )
    }
}
