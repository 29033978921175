import Profissional from 'api/Profissional'
import { DadosPessoaisForm, VinculoProfissionalFiltro, VinculoProfissionalRow } from 'backend'
import { PromiseRecord, promiseReducer } from 'redux/middleware/PromiseAction'

export const FIND_MEDICOS = 'rni/profissional/FIND_MEDICOS'
export const CLEAR_FIND_RESULT = 'rni/profissional/CLEAR_FIND_RESULT'
export const CLEAR_DADOS_PROFISSIONAIS = 'rni/profissional/CLEAR_DADOS_PROFISSIONAIS'
export const FIND_DADOS_PROFISSIONAIS = 'rni/profissional/FIND_DADOS_PROFISSIONAIS'

export interface ProfissionalState
    extends Readonly<{
        find: PromiseRecord<VinculoProfissionalRow[]>
        dadosPessoais: PromiseRecord<DadosPessoaisForm>
    }> {}

const ProfissionalInitialState: ProfissionalState = {
    find: {},
    dadosPessoais: {},
}

export default function reducer(state = ProfissionalInitialState, action): ProfissionalState {
    switch (action.type) {
        case FIND_MEDICOS:
            return { ...state, find: promiseReducer(state.find, action) }
        case CLEAR_FIND_RESULT:
            return { ...state, find: {} }
        case FIND_DADOS_PROFISSIONAIS:
            return { ...state, dadosPessoais: promiseReducer(state.dadosPessoais, action) }
        case CLEAR_DADOS_PROFISSIONAIS:
            return { ...state, dadosPessoais: {} }
        default:
            return state
    }
}

export const clearFindResult = () => ({
    type: CLEAR_FIND_RESULT,
})

export const clearDadosPessoais = () => ({
    type: CLEAR_DADOS_PROFISSIONAIS,
})

export const findMedicos = (filtro: VinculoProfissionalFiltro) => ({
    type: FIND_MEDICOS,
    promise: Profissional.getMedicosByFiltro(filtro),
})

export const findDadosPessoais = () => ({
    type: FIND_DADOS_PROFISSIONAIS,
    promise: Profissional.findDadosPessoais(),
})
